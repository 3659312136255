import React from 'react';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography,
  Breadcrumbs,
  Link
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Page from 'src/components/Page';
import ProductPos from './ProductPos';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AddProductPos = () => {
  const classes = useStyles();
  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Breadcrumbs aria-label="breadcrumb">
          {/* <Link underline="hover" color="inherit" href="/app/product-management">
        product management
        </Link> */}
          <Typography >Add Product POS</Typography>
        </Breadcrumbs>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Product POS Creation
          </Typography>
        </Box>
        <ProductPos />
      </Container>
    </Page>
  );
};

export default AddProductPos;