import React, { useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Button
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Sales from '../Sales';
import { useDispatch, useSelector } from 'react-redux';
import {
  brandsStatistics
} from '../../../../store/actions/reports/statisticsActions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ReportBrands = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector(state => state.dash.BrandsS.data);


  // componentDidMount
  useEffect(() => {
    dispatch(brandsStatistics());
  }, []);

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Button style={{marginBottom:"10px"}}
          component={RouterLink}
          to="/app/reports"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={9}
            xs={12}
          >
            <Sales

              statistics={data}
              title={"Brands"}
            />
          </Grid>

        </Grid>
      </Container>
    </Page>
  );
};

export default ReportBrands;
