import {
  SETTING_CREATE_REQUEST,
  SETTING_CREATE_SUCCESS,
  SETTING_CREATE_FAIL,
  SETTING_UPDATE_REQUEST,
  SETTING_UPDATE_SUCCESS,
  SETTING_UPDATE_FAIL,
} from '../actions/settingAction'

const initialState = {
  settings: { all: [], error: '', isLoading: false },
  setting: { setting: {}, error: '', isLoading: false },
  settingCreate: { setting: {}, error: '', isLoading: false },
  settingUpdate: { setting: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SETTING_CREATE_REQUEST:
      return { ...state, settingCreate: { setting: {}, error: '', isLoading: true } };
    case SETTING_CREATE_SUCCESS:
      return { ...state, settingCreate: { setting: state.settingCreate.setting, error: '', isLoading: false } };
    case SETTING_CREATE_FAIL:
      return { ...state, settingCreate: { setting: {}, error: action.payload, isLoading: false } };
    case SETTING_UPDATE_REQUEST:
      return { ...state, settingUpdate: { setting: {}, error: '', isLoading: true } };
    case SETTING_UPDATE_SUCCESS:
      return { ...state, settingUpdate: { setting: state.settingUpdate.setting, error: '', isLoading: false } };
    case SETTING_UPDATE_FAIL:
      return { ...state, settingUpdate: { setting: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}