import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles, Avatar,
  FormControlLabel,
  Checkbox, Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { UpdateBiddingServiceAction, singleImageUploadUpdate, imageDelete, imageDefaultUpdate, getServiceImages } from 'src/store/actions/biddingservicesAction';
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';

const statusOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const discountType = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'A',
    label: 'Amount'
  },
  {
    value: 'P',
    label: 'Percentage'
  },
];

const useStyles = makeStyles(() => ({
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  },
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
}));

const EditBiddingServices = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  let { state } = location;

  const isSubmitted = useSelector(state => state.biddingservices.biddingserviceUpdate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const createdBy = useSelector(state => state.auth.user.Email_Id);
  const [errorMessage, setErrorMessage] = useState({ Service_Type: { key: '', message: '' }, global: { key: '', message: '' } });

  // Richtext editor
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Description || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [rhDiscription, setRhDiscription] = useState(state.Description);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // Multiple pic upload
  const [blobArrayPics, setBlobArrayPics] = useState([{
    key: 1, preview: null, Bidding_Service_Image_Id: null, Is_Default: 0
  },
  {
    key: 2, preview: null, Bidding_Service_Image_Id: null, Is_Default: 0
  },
  {
    key: 3, preview: null, Bidding_Service_Image_Id: null, Is_Default: 0
  },
  {
    key: 4, preview: null, Bidding_Service_Image_Id: null, Is_Default: 0
  },
  {
    key: 5, preview: null, Bidding_Service_Image_Id: null, Is_Default: 0
  }]);

  const [imageError, setImageError] = useState();

  const callBackUploadImage = (status) => {
    setImageDefault(true);
    if (status) {
      dispatch(getServiceImages(state.Bidding_service_Id, callBackGetImages));
    }
    else {
      setImageError("Unable to upload image please try again.");
    }
  };
  const [imageDefault, setImageDefault] = useState(false);

  function handleDefaultChange(status, key, imageId) {
    setImageDefault(true);
    dispatch(imageDefaultUpdate(state.Bidding_service_Id, createdBy, imageId, callBackUploadImage));
  };

  const handleImage = (e, key) => {
    setImageError("");
    dispatch(
      singleImageUploadUpdate(new Blob([e.target.files[0]]), state.Bidding_service_Id, createdBy, key, callBackUploadImage)
    );
  };

  function handleRemoveImage(e, key, imageId) {
    if (imageId) {
      dispatch(imageDelete(imageId, callBackDeleteImage, key));
    }
    else {
      callBackDeleteImage(imageId, true, key);
    }
  };

  const callBackDeleteImage = (imageId, status, key) => {
    if (status) {
      dispatch(getServiceImages(state.Bidding_service_Id, callBackGetImages, key));
    }
  };

  const callBackGetImages = (images, key) => {
    setImageDefault(false);
    let count = 0;
    const blobArrayPicsTemp = JSON.parse(JSON.stringify(blobArrayPics.map((record) => key ? { ...record, preview: null, Bidding_Service_Image_Id: null } : record)));
    if (images.length > 5) {
      for (let i = 0; i <= images.length - 6; i++) {
        blobArrayPicsTemp.push({
          key: i + 6, preview: null, Bidding_Service_Image_Id: null, Is_Default: 0
        })
      }
    }
    images.map(image => {
      blobArrayPicsTemp[count].preview = image.Service_Image;
      blobArrayPicsTemp[count].Bidding_Service_Image_Id = image.Bidding_Service_Image_Id;
      blobArrayPicsTemp[count].Is_Default = image.Is_Default;
      count++
    })
    setBlobArrayPics(blobArrayPicsTemp);
  };

  useEffect(() => {
    dispatch(getServiceImages(state.Bidding_service_Id, callBackGetImages));
  }, []);

  return (
    <Formik
      initialValues={{
        Bidding_Service_Id: state.Bidding_service_Id ? state.Bidding_service_Id : "",
        Description: state.Description ? state.Description : "",
        Modified_By: loginEmail,
        Service_Type: state.Service_Type ? state.Service_Type : "",
        Status: state.Status ? state.Status : "",
        ESI_Commission: state.Esi_Commission ? state.Esi_Commission : "",
        ESI_Commission_Type: state.Esi_Commission_Type ? state.Esi_Commission_Type : "",
        Service_Sub_Types: state.Service_Sub_Types ? state.Service_Sub_Types : "",
      }}
      validationSchema={
        Yup.object().shape({
          Bidding_Service_Id: Yup.string().required('Bidding Service ID is required.'),
          Service_Type: Yup.string().min(3, "Service Type must be at least 3 characters.").notOneOf([errorMessage.Service_Type.key, null], errorMessage.Service_Type.message).required('Service Type is required.'),
          Status: Yup.string().required('Status is required.'),
          ESI_Commission_Type: Yup.string().test('oneOfRequired',
            'ESI Commission Type is required.',
            function (item) {
              return (this.parent.ESI_Commission_Type)
            }
          ),
          ESI_Commission: Yup.number().when("ESI_Commission_Type", (ESI_Commission_Type, schema) => {
            if (ESI_Commission_Type === "A") {
              return schema.min(1, "ESI Commission must be at least 1.").required('ESI Commission is required.');
            } else if (ESI_Commission_Type === "P") {
              return schema.min(1, "ESI Commission Percentage must be at least 1.").max(100, "ESI Commission Percentage must be less then 100.").required('ESI Commission Percentage is required.');
            }
          }),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        if (blobArrayPics.filter(item => { return !(!item.preview) }).length <= 0) {
          submitTest = false;
          setImageError("Image is required.");
        }
        values.Description = rhDiscription;
        if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(UpdateBiddingServiceAction(formValues, navigate, setErrorMessage, setErrors));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Service_Type && errors.Service_Type)}
                        fullWidth
                        helperText={touched.Service_Type && errors.Service_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Service Type"
                        name="Service_Type"
                        value={values.Service_Type}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Service Sub Types"
                        name="Service_Sub_Types"
                        value={values.Service_Sub_Types}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/*  ESI Commission Type */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.ESI_Commission_Type && errors.ESI_Commission_Type)}
                        fullWidth
                        helperText={touched.ESI_Commission_Type && errors.ESI_Commission_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="ESI Commission Type"
                        name="ESI_Commission_Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.ESI_Commission_Type}
                        variant="outlined"
                      >
                        {discountType.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/*  ESI Commission */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.ESI_Commission && errors.ESI_Commission)}
                        fullWidth
                        helperText={touched.ESI_Commission && errors.ESI_Commission}
                        onBlur={handleBlur}
                        label="ESI Commission"
                        name="ESI_Commission"
                        type="number"
                        onChange={handleChange}
                        value={values.ESI_Commission}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                      </span>
                    </Grid>

                  </Grid>
                </Grid>
                {/* upload photo */}
                <Grid item md={4} xs={12}  >
                  {blobArrayPics.filter(item => {
                    if (item.key === 1) {
                      return true;
                    } else {
                      return blobArrayPics[item.key - 2].preview !== null;
                    }
                  }).map(option => (
                    <Card style={{ marginBottom: "5px", marginTop: "5px" }} key={"image-card" + option.key}>
                      <CardContent>
                        <Box display="flex" justifyContent="center">
                          {option.preview ? (
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src={option.preview}
                              variant="square"
                            />
                          ) : (
                            <>
                              <Avatar
                                className={classes.images}
                                alt="Brand Upload"
                                src="/static/images/image-placeholder.png"
                                variant="square"
                              />
                            </>
                          )}
                        </Box>

                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          {option.preview ?
                            <> <FormControlLabel
                              disabled={Number(option.Is_Default) === 1 ? true : false}
                              key={"imageUpload" + option.key}
                              control={
                                <Checkbox
                                  onBlur={handleBlur}
                                  checked={Number(option.Is_Default) === 1 ? true : false}
                                  onChange={(e, status) => handleDefaultChange(status, option.key, option.Bidding_Service_Image_Id)}
                                  name="Is_Default"
                                  color="primary"
                                />
                              }
                              label={<Typography style={{ fontSize: "0.875rem" }}>Is Default</Typography>}
                            /> <Button
                              style={{ fontSize: '10', color: ' #425A70', textTransform: 'none' }}
                              onClick={(e) => handleRemoveImage(e, option.key, option.Bidding_Service_Image_Id)}
                              disabled={option.Is_Default === 1 ? true : false}
                            >
                                Remove Picture
                              </Button> </>
                            : <input
                              style={{ display: 'block', width: '80%' }}
                              id={"upload-photo" + option.key}
                              name="image"
                              type="file"
                              accept="image/*"
                              onChange={(e) => handleImage(e, option.key)}
                            />}
                          <br />
                        </Box>

                      </CardContent>
                    </Card>
                  ))}
                  <span style={{ color: "red" }}>
                    {blobArrayPics.filter(item => { return !(!item.preview) }).length <= 0 && imageError}
                  </span>
                </Grid>
              </Grid>

            </CardContent>
            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmitted ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmitted}
                  type="submit"
                  variant="contained"
                  style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                >
                  <span style={{ color: 'white' }}>Save Details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditBiddingServices.propTypes = {
  className: PropTypes.string
};

export default EditBiddingServices;