import config from './config';
import { toast } from 'react-toastify';
import { nodeGetApi, boomiAdminApi } from './commonAxios';
export const DELI_BID_QUOTES_CREATE_REQUEST = "DELI_BID_QUOTES_CREATE_REQUEST";
export const DELI_BID_QUOTES_CREATE_SUCCESS = "DELI_BID_QUOTES_CREATE_SUCCESS";
export const DELI_BID_QUOTES_CREATE_FAIL = "DELI_BID_QUOTES_CREATE_FAIL";

export const DELI_BID_QUOTES_UPDATE_REQUEST = "DELI_BID_QUOTES_UPDATE_REQUEST";
export const DELI_BID_QUOTES_UPDATE_SUCCESS = "DELI_BID_QUOTES_UPDATE_SUCCESS";
export const DELI_BID_QUOTES_UPDATE_FAIL = "DELI_BID_QUOTES_UPDATE_FAIL";

export const DELI_USERS_GET_REQUEST = "DELI_USERS_GET_REQUEST";
export const DELI_USERS_GET_SUCCESS = "DELI_USERS_GET_SUCCESS";
export const DELI_USERS_GET_FAIL = "DELI_USERS_GET_FAIL";

export const BID_DELI_MULTI_VEHI_GET_REQUEST = "BID_DELI_MULTI_VEHI_GET_REQUEST";
export const BID_DELI_MULTI_VEHI_GET_SUCCESS = "BID_DELI_MULTI_VEHI_GET_SUCCESS";
export const BID_DELI_MULTI_VEHI_GET_FAIL = "BID_DELI_MULTI_VEHI_GET_FAIL";

export const BID_DELI_MULTI_QUOTE_GET_REQUEST = "BID_DELI_MULTI_QUOTE_GET_REQUEST";
export const BID_DELI_MULTI_QUOTE_GET_SUCCESS = "BID_DELI_MULTI_QUOTE_GET_SUCCESS";
export const BID_DELI_MULTI_QUOTE_GET_FAIL = "BID_DELI_MULTI_QUOTE_GET_FAIL";

export const BID_DELI_MULTI_BIDS_GET_REQUEST = "BID_DELI_MULTI_BIDS_GET_REQUEST";
export const BID_DELI_MULTI_BIDS_GET_SUCCESS = "BID_DELI_MULTI_BIDS_GET_SUCCESS";
export const BID_DELI_MULTI_BIDS_GET_FAIL = "BID_DELI_MULTI_BIDS_GET_FAIL";

export const BID_DELI_BIDS_COMMISSION_GET_REQUEST = "BID_DELI_BIDS_COMMISSION_GET_REQUEST";
export const BID_DELI_BIDS_COMMISSION_GET_SUCCESS = "BID_DELI_BIDS_COMMISSION_GET_SUCCESS";
export const BID_DELI_BIDS_COMMISSION_GET_FAIL = "BID_DELI_BIDS_COMMISSION_GET_FAIL";

//create Delivery Bidding Quotes Action
export const createDeliveryBiddingQuotes = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: DELI_BID_QUOTES_CREATE_REQUEST
    });
    boomiAdminApi.post(`/Bidding_Ride_Quotes/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: DELI_BID_QUOTES_CREATE_SUCCESS
                });
                toast('Delivery Bidding Quote created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/delivery-bidding-quotes', state = { state });
            } else {
                dispatch({
                    type: DELI_BID_QUOTES_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: DELI_BID_QUOTES_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

//update Delivery Bidding Quotes Action
export const updateDeliveryBiddingQuotes = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: DELI_BID_QUOTES_UPDATE_REQUEST
    });
    boomiAdminApi.put(`/Bidding_Ride_Quotes/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: DELI_BID_QUOTES_UPDATE_SUCCESS
                });
                toast('Delivery Bidding Quote updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/delivery-bidding-quotes', state = { state });
            } else {

                dispatch({
                    type: DELI_BID_QUOTES_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: DELI_BID_QUOTES_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//get all Bidding Delivery Users
// export const getAllBiddingDeliveryUsers = formData => async dispatch => {
//     try {
//         dispatch({
//             type: DELI_USERS_GET_REQUEST
//         });
//         let { data } = await nodeGetApi.post(`/Delivery_Profile/Get_All_Profile?PageNo=1&PageSize=${config.pageSize}&SortBy=Del_User_Email_Id&SortOrder=ASC`, formData)
//         if (data) {
//             if (data.Success_Response.Is_Data_Exist === '0') {
//                 dispatch({
//                     type: DELI_USERS_GET_SUCCESS,
//                     payload: []
//                 });
//             } else {
//                 dispatch({
//                     type: DELI_USERS_GET_SUCCESS,
//                     payload: data.results
//                 });
//             }
//         }
//     } catch (err) {
//         dispatch({
//             type: DELI_USERS_GET_FAIL,
//             payload: err
//         });
//     }
// };

//get all Bidding Delivery multiple Vehilces
export const getAllBidDeliMultipleVehicles = formData => async dispatch => {
    try {
        dispatch({
            type: BID_DELI_MULTI_VEHI_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/User_Rides_Multi_Bidding/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=User_Ride_Bidding_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: BID_DELI_MULTI_VEHI_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BID_DELI_MULTI_VEHI_GET_SUCCESS,
                    payload: data.results[0]?.Bidding_Vehicle_Id
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BID_DELI_MULTI_VEHI_GET_FAIL,
            payload: err
        });
    }
};

//get all Bidding Delivery multiple Quotes
export const getAllBidDeliMultiQuotes = formData => async dispatch => {
    try {
        dispatch({
            type: BID_DELI_MULTI_QUOTE_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/User_Rides_Multi_Bidding/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=User_Ride_Bidding_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: BID_DELI_MULTI_QUOTE_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BID_DELI_MULTI_QUOTE_GET_SUCCESS,
                    payload: data.results[0]?.Bidding_Vehicle_Id
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BID_DELI_MULTI_QUOTE_GET_FAIL,
            payload: err
        });
    }
};

//get all Bidding Delivery multiple Bids
export const getAllBidDeliMultiBids = formData => async dispatch => {
    try {
        dispatch({
            type: BID_DELI_MULTI_BIDS_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/User_Ride_Bidding_Vehicle_Quatation/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=User_Ride_Bidding_Vehicle_Del_Quotation_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: BID_DELI_MULTI_BIDS_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BID_DELI_MULTI_BIDS_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BID_DELI_MULTI_BIDS_GET_FAIL,
            payload: err
        });
    }
};

//get all Bidding Delivery Bids Commission
export const getAllBidDeliBidsCommission = formData => async dispatch => {
    try {
        dispatch({
            type: BID_DELI_BIDS_COMMISSION_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/User_Rides_Multi_Bidding/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=User_Ride_Bidding_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: BID_DELI_BIDS_COMMISSION_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BID_DELI_BIDS_COMMISSION_GET_SUCCESS,
                    payload: data.results[0]?.Bidding_Vehicle_Id
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BID_DELI_BIDS_COMMISSION_GET_FAIL,
            payload: err
        });
    }
};


//get all Bidding Ride commissons
export const getAllBidRideDeliveryESICommissions = (formData, callBackData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/User_Rides_Multi_Bidding/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=User_Ride_Bidding_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.Is_Data_Exist === "0") {
                callBackData([])
            } else {
                callBackData(data.results[0]?.Bidding_Vehicle_Id)
            }
        }
    } catch (err) {
        dispatch({
            payload: err
        });
    }
};

//Get Bidding Ride Quote 
export const getBiddingRideQuoteAction = (formData, callBackBiddingRideQuote) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/Bidding_Rides_Quotes/Get`, formData);
        if (data) {
            callBackBiddingRideQuote(data)
        }
    } catch (err) {
    }
};


//get all Bidding Delivery Users
export const getAllBiddingDeliveryUsers = (emailid) => async dispatch => {
    try {
        let formData = {
            "Del_User_Email_Id": emailid,
            "Records_Filter": "FILTER"
          };
        dispatch({
            type: DELI_USERS_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Delivery_Profile/Get_All_Profile_dropdown_search?PageNo=1&PageSize=${config.pageSize}&SortBy=Del_User_Email_Id&SortOrder=ASC`, formData)
        if (data) {
            if (data.Success_Response.Is_Data_Exist === '0') {
                dispatch({
                    type: DELI_USERS_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: DELI_USERS_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: DELI_USERS_GET_FAIL,
            payload: err
        });
    }
};