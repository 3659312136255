import { toast } from 'react-toastify';
import { paginationAllCorpCMSGet } from './node-actions/nodepagination';
import { nodeCorpMongoApi } from './commonAxios';

export const CONTENTMANAGEMENTS_STATIC_REQUEST = "CONTENTMANAGEMENTS_STATIC_REQUEST";
export const CONTENTMANAGEMENTS_STATIC_SUCCESS = "CONTENTMANAGEMENTS_STATIC_SUCCESS";
export const CONTENTMANAGEMENTS_STATIC_FAIL = "CONTENTMANAGEMENTS_STATIC_FAIL";

export const CONTENTMANAGEMENT_STATIC_GET_REQUEST = "CONTENTMANAGEMENT_STATIC_GET_REQUEST";
export const CONTENTMANAGEMENT_STATIC_GET_SUCCESS = "CONTENTMANAGEMENT_STATIC_GET_SUCCESS";
export const CONTENTMANAGEMENT_STATIC_GET_FAIL = "CONTENTMANAGEMENT_STATIC_GET_FAIL";

export const CONTENTMANAGEMENT_STATIC_CREATE_REQUEST = "CONTENTMANAGEMENT_STATIC_CREATE_REQUEST";
export const CONTENTMANAGEMENT_STATIC_CREATE_SUCCESS = "CONTENTMANAGEMENT_STATIC_CREATE_SUCCESS";
export const CONTENTMANAGEMENT_STATIC_CREATE_FAIL = "CONTENTMANAGEMENT_STATIC_CREATE_FAIL";

export const CONTENTMANAGEMENT_STATIC_UPDATE_REQUEST = "CONTENTMANAGEMENT_STATIC_UPDATE_REQUEST";
export const CONTENTMANAGEMENT_STATIC_UPDATE_SUCCESS = "CONTENTMANAGEMENT_STATIC_UPDATE_SUCCESS";
export const CONTENTMANAGEMENT_STATIC_UPDATE_FAIL = "CONTENTMANAGEMENT_STATIC_UPDATE_FAIL";

/*=============================================================
                  Add CMS Action
===============================================================*/
export const addContentManagementStaticAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CONTENTMANAGEMENT_STATIC_CREATE_REQUEST
    });
    nodeCorpMongoApi.post(`Corp_CMS/create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.code === 201 && successResponse?.message === "successfully created cms") {
                dispatch({
                    type: CONTENTMANAGEMENT_STATIC_CREATE_SUCCESS
                });
                toast('Content added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corporate-content-management');
            } else {
                dispatch({
                    type: CONTENTMANAGEMENT_STATIC_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CONTENTMANAGEMENT_STATIC_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};

/*=============================================================
            Update CMS Action
===============================================================*/
export const updateContentManagementStaticAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CONTENTMANAGEMENT_STATIC_UPDATE_REQUEST
    });
    nodeCorpMongoApi.post(`/Corp_CMS/update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.code === 200 && successResponse?.message === "cms updated successfully") {
                dispatch({
                    type: CONTENTMANAGEMENT_STATIC_UPDATE_SUCCESS
                });
                toast('Content updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corporate-content-management');
            } else {
                dispatch({
                    type: CONTENTMANAGEMENT_STATIC_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CONTENTMANAGEMENT_STATIC_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

/*=============================================================
                  Delete CMS Action
===============================================================*/
export const deleteContentManagementStaticAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
    try {
        let { data } = await nodeCorpMongoApi.post(`/Corp_CMS/delete`, formData);
        if (data) {
            toast('Content deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            dispatch(paginationAllCorpCMSGet(filters, pagination, callBackPagination));
        }
    } catch (err) {
    }
};