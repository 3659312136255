import { toast } from "react-toastify";
import { nodeCorpMongoApi } from "./commonAxios";
import { paginationCorpPendingUsers } from "./node-actions/nodepagination";

export const CORP_USER_APPROVAL_REQUEST = "CORP_USER_APPROVAL_REQUEST";
export const CORP_USER_APPROVAL_SUCCESS = "CORP_USER_APPROVAL_SUCCESS";
export const CORP_USER_APPROVAL_FAIL = "CORP_USER_APPROVAL_FAIL";

export const CORP_USER_REJECTED_REQUEST = "CORP_USER_REJECTED_REQUEST";
export const CORP_USER_REJECTED_SUCCESS = "CORP_USER_REJECTED_SUCCESS";
export const CORP_USER_REJECTED_FAIL = "CORP_USER_REJECTED_FAIL";

export const CorpUserApprovalAction = (formData,navigate) => async dispatch => {
    dispatch({
      type: CORP_USER_APPROVAL_REQUEST
    });
    nodeCorpMongoApi.post(`/corpEnquiry/update`, formData)
      .then(response => {
        let successResponse = response.data;
        if (successResponse?.code === 200 && successResponse?.message === "Enquiry Form Updated Successfully.") {
          dispatch({
            type: CORP_USER_APPROVAL_SUCCESS
          });
          toast('Approved Successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
          const formData = {
            Search: '',
            Search_By_Filter: '',
            Is_SA_Approved: true,
            Is_SA_Rejected: false
          };
          dispatch(paginationCorpPendingUsers(formData));
          navigate('/app/approved-corporate-user-management');
        } else {
          dispatch({
            type: CORP_USER_APPROVAL_FAIL,
            payload: successResponse.UI_Display_Message
          });
        }
      })
      .catch(error => {
        dispatch({
          type: CORP_USER_APPROVAL_FAIL,
          payload: "Please try again later.Currently server is not working"
        });
      })
};
export const CorpUserRejectedAction = (formData,navigate) => async dispatch => {
  dispatch({
    type: CORP_USER_REJECTED_REQUEST
  });
  nodeCorpMongoApi.post(`/corpEnquiry/update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse?.code === 200 && successResponse?.message === "Enquiry Form Updated Successfully.") {
        dispatch({
          type: CORP_USER_REJECTED_SUCCESS
        });
        toast('Rejected Successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        const formData = {
          Search: '',
          Search_By_Filter: '',
          Is_SA_Approved: false,
          Is_SA_Rejected: true
        };
        dispatch(paginationCorpPendingUsers(formData));
        navigate('/app/rejected-corporate-user-management');
      } else {
        dispatch({
          type: CORP_USER_REJECTED_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: CORP_USER_REJECTED_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};