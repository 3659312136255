import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  makeStyles,
  Button,
  Avatar,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import DeliveryAgentsListView from '../delivery-Agent-Details-View/delivery-Agent-Details';

// useStyles we use a hook to consume the styles
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  images: {
    width: 120,
    height: 120,
  },
}));

const PartnerApprovalView = () => {
  // classes contains the styles
  const classes = useStyles();
  const location = useLocation();
  let { state } = location;

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/approved-delivery-agent-view"
          variant="text"
          size="small"
          color="inherit">
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)", marginTop: '1rem', marginBottom: '1rem' }}>
          <div style={{ marginLeft: '1.5rem' }}> <Avatar
            alt="profile"
            src={state?.Profile_pic_path}
            sx={{ width: 24, height: 24 }}
          /></div>
          <div style={{ color: "blue", fontWeight: 'bold', marginTop: '0.7rem', marginLeft: '-4rem' }}>{state?.Full_name}</div>
          <div></div><div></div> <div></div><div></div><div>
            <div style={{ fontWeight: 'bold', marginTop: "1%", marginLeft: '-4rem' }}>{state?.Del_user_email_id}</div>
            <div style={{ fontWeight: 'bold', marginTop: "2%", marginLeft: '-4rem' }}>{state?.Mobile}</div>
          </div> </div>
        <DeliveryAgentsListView />
      </Container>
    </Page>
  );
};

export default PartnerApprovalView;