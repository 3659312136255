import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import RazorpayhistoryViewList from './RazorpayhistoryView';
import isEmpty from 'src/store/validations/is-empty';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const RazorpayhistoryView = () => {
  const classes = useStyles();
  const location = useLocation();
  const { state } = location;
  const { data } = state.data;

  const [alltransactionhistory, setTransactionhistory] = useState([]);
  let transactionhistory = useSelector(state => state.razorpaytransaction.alltransactionhistory);
  useEffect(() => {
    if (!isEmpty(transactionhistory)) {
      setTransactionhistory(transactionhistory.razorpaytransaction);
    }
  }, [transactionhistory]);

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/razorpay-history"
          variant="text"
          size="small"
          color="inherit"
          state={state.state}
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            View Razorpay History Details
          </Typography>
        </Box>
        <RazorpayhistoryViewList state={state} data={state.data} alltransactionhistory={alltransactionhistory} />
      </Container>
    </Page>
  );
};

export default RazorpayhistoryView;