import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../store/validations/is-empty';
import { getAllSubPackLocationsAction, subPackLocationFilter } from 'src/store/actions/subspacklocationsAction';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const SubsPackLocationsListView = () => {
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();
    const { state } = location;

    const [allSubsPackLocations, setSubsPackLocations] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);

    const handleLimitChange = event => {
        setLimit(+event.target.value);
        setPage(0);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    let subPackLocationsList = useSelector(state => state.subspacklocations.subspacklocationFilter.select);
    let subPackLocationsSearch = useSelector(state => state.subspacklocations.subspacklocationFilter.search);

    // Sorting 
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Subcription_Package_Locations_ID');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {
        try {
            if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
                return -1;
            }
            if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
                return 1;
            }
            return 0;
        } catch (error) {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        }

    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {

            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    // componentDidMount
    useEffect(() => {
        const formData = {
            Records_Filter: 'FILTER',
            Subcription_Package_ID: state.Subscription_Package_ID,
        };
        dispatch(getAllSubPackLocationsAction(formData));
        dispatch(subPackLocationFilter('search', ''));
        dispatch(subPackLocationFilter('select', 'All'));
    }, []);

    const subPackLocations = useSelector(state => state.subspacklocations.subspacklocations.all);
    const [oldSearch, setOldSearch] = useState(0);

    useEffect(() => {
        if (!isEmpty(subPackLocations)) {
            setSubsPackLocations(subPackLocations);
        } else {
            setSubsPackLocations([]);
        }
    }, [subPackLocations]);

    const checkSubsPackLocationsForTable = () => {
        if (subPackLocationsSearch) {
            try {
                if (subPackLocationsList === "All") {
                    return allSubsPackLocations.filter(x => {
                        let colummns = ["Subcription_Package_Locations_ID", "Subcription_Package_ID", "City_ID", "Partner_Details_ID", "Partner_Store_Address_ID"];
                        for (let i = 0; i < colummns.length; i++) {
                            const mainString = typeof x[colummns[i]] === "string" ? x[colummns[i]]?.toLowerCase() : x[colummns[i]];
                            if (typeof x[colummns[i]] === "string") {
                                if (mainString.includes(subPackLocationsSearch?.toLowerCase())) {
                                    return true;
                                }
                            }
                            else {
                                if (mainString === subPackLocationsSearch) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    });
                } else {
                    return allSubsPackLocations.filter(x => {
                        let mainString = typeof x[subPackLocationsList] == "string" ? x[subPackLocationsList]?.toLowerCase() : x[subPackLocationsList];
                        let subString = typeof x[subPackLocationsList] == "string" ? subPackLocationsSearch?.toLowerCase() : subPackLocationsSearch;
                        if (typeof x[subPackLocationsList] === "string") {
                            return mainString.includes(subString);
                        }
                        else {
                            if (mainString === subPackLocationsSearch) {
                                return true;
                            }
                        }
                    })
                }
            } finally {
                if (page > 0) {
                    if (oldSearch !== subPackLocationsSearch.length) {
                        setOldSearch(subPackLocationsSearch.length);
                        setPage(0);
                    }
                }
                else if (oldSearch !== subPackLocationsSearch.length) {
                    setOldSearch(subPackLocationsSearch.length);
                    setPage(0);
                }
            }
        }
        else {
            return allSubsPackLocations
        }
    }

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/subscription-packages"
                    variant="text"
                    size="small"
                    color="inherit"
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        Subscription Package Locations
                    </Typography>
                </Box>

                <Toolbar state={state} />
                <Box mt={3}>
                    <Results
                        allSubsPackLocations={stableSort(
                            checkSubsPackLocationsForTable(), getComparator(order, orderBy))}
                        allSubsPackLocationsNoFilter={allSubsPackLocations}
                        page={page}
                        limit={limit}
                        setPage={setPage}
                        handleLimitChange={handleLimitChange}
                        handlePageChange={handlePageChange}
                        order={order}
                        orderBy={orderBy}
                        createSortHandler={createSortHandler}
                        state={state}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default SubsPackLocationsListView;