import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'News_Letter_Id', numeric: false, disablePadding: false, label: 'Newsletter ID' },
  { id: 'News_Letter_Email', numeric: false, disablePadding: false, label: 'Newsletter Email' },
  { id: 'Subscribe', numeric: false, disablePadding: false, label: ' Subscribe ' },
  { id: 'Modified_Date', numeric: false, disablePadding: false, label: 'Modified Date  ' },
  { id: 'Created_Date', numeric: false, disablePadding: false, label: 'Created Date  ' },
  { id: 'Status', numeric: false, disablePadding: false, label: ' Status' },
];

const Results = ({
  className,
  allNewsletter,
  deleteNewsletterHandler,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allNewsletterNoFilter,
  RolePermissions,
  roleUserType,
  pagination,
  setPagination,
  ...rest
}) => {

  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedNewsletterIds, setSelectedNewsletterIds] = useState([]);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedNewsletterIds;
    if (event.target.checked) {
      newSelectedNewsletterIds = allNewsletter.map(newsletter => newsletter.News_Letter_Id);
    } else {
      newSelectedNewsletterIds = [];
    }
    setSelectedNewsletterIds(newSelectedNewsletterIds);
  };

  const handleSelectOne = (event, id) => {
    setSelectedNewsletterIds(selectedNewsletterIds.filter(record => {
      for (let i = 0; i < allNewsletterNoFilter.length; i++) {
        if (record === allNewsletterNoFilter[i].News_Letter_Id) {
          return true;
        }
      }
      return false;
    }))
    const selectedIndex = selectedNewsletterIds.indexOf(id);
    let newSelectedNewsletterIds = [];
    if (selectedIndex === -1) {
      newSelectedNewsletterIds = newSelectedNewsletterIds.concat(selectedNewsletterIds, id);
    } else if (selectedIndex === 0) {
      newSelectedNewsletterIds = newSelectedNewsletterIds.concat(
        selectedNewsletterIds.slice(1)
      );
    } else if (selectedIndex === selectedNewsletterIds.length - 1) {
      newSelectedNewsletterIds = newSelectedNewsletterIds.concat(
        selectedNewsletterIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedNewsletterIds = newSelectedNewsletterIds.concat(
        selectedNewsletterIds.slice(0, selectedIndex),
        selectedNewsletterIds.slice(selectedIndex + 1)
      );
    }
    setSelectedNewsletterIds(newSelectedNewsletterIds);
  };

  const deleteSelectedNewsletterHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const editNewsletterHandler = newsletterData => e => {
    navigate('/app/edit-newsletter', { state: newsletterData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedNewsletterIds.filter(record => {
                      for (let i = 0; i < allNewsletterNoFilter.length; i++) {
                        if (record === allNewsletterNoFilter[i].News_Letter_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allNewsletter.length}
                    color="primary"
                    indeterminate={
                      selectedNewsletterIds.filter(record => {
                        for (let i = 0; i < allNewsletterNoFilter.length; i++) {
                          if (record === allNewsletterNoFilter[i].News_Letter_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedNewsletterIds.filter(record => {
                        for (let i = 0; i < allNewsletterNoFilter.length; i++) {
                          if (record === allNewsletterNoFilter[i].News_Letter_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allNewsletter.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions&nbsp;
                  {selectedNewsletterIds.filter(record => {
                    for (let i = 0; i < allNewsletterNoFilter.length; i++) {
                      if (record === allNewsletterNoFilter[i].News_Letter_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedNewsletterIds.filter(record => {
                    for (let i = 0; i < allNewsletterNoFilter.length; i++) {
                      if (record === allNewsletterNoFilter[i].News_Letter_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedNewsletterHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allNewsletter) &&
                (allNewsletter).map(newsletter => (
                  <TableRow
                    hover
                    key={newsletter.News_Letter_Id}
                    selected={
                      selectedNewsletterIds.indexOf(newsletter.News_Letter_Id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedNewsletterIds.indexOf(newsletter.News_Letter_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, newsletter.News_Letter_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell>{newsletter.News_Letter_Id}</TableCell>
                    < TableCell style={{ color: '#1665D8' }} > {newsletter.News_Letter_Email?.substring(0, 10)} ... </ TableCell >
                    <TableCell >&nbsp;&nbsp;&nbsp;{newsletter.Subscribe}</TableCell>
                    <TableCell>{newsletter.Modified_Date}</TableCell>
                    <TableCell>{newsletter.Created_Date}</TableCell>
                    <TableCell style={{ color: '#1665D8' }} >{newsletter.Status}
                    </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Newsletter_Write?.Value === "Newsletter_Write" && RolePermissions?.Newsletter_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Button
                          onClick={editNewsletterHandler(newsletter)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                          <DeleteIcon
                            onClick={deleteNewsletterHandler(newsletter)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                      : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allNewsletter) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allNewsletter: PropTypes.array.isRequired
};

export default Results;