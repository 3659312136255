
import config from './config';
import { toast } from 'react-toastify';
import { paginationRole } from './node-actions/nodepagination';
import {  nodeGetApi,boomiAdminApi } from './commonAxios';

export const ROLES_REQUEST = 'ROLES_REQUEST';
export const ROLES_SUCCESS = "ROLES_SUCCESS";
export const ROLES_FAIL = "ROLES_FAIL";
export const ROLE_GET_REQUEST = "ROLE_GET_REQUEST";
export const ROLE_GET_SUCCESS = "ROLE_GET_SUCCESS";
export const ROLE_GET_FAIL = "ROLE_GET_FAIL";
export const ROLE_CREATE_REQUEST = "ROLE_CREATE_REQUEST";
export const ROLE_CREATE_SUCCESS = "ROLE_CREATE_SUCCESS";
export const ROLE_CREATE_FAIL = "ROLE_CREATE_FAIL";
export const ROLE_UPDATE_REQUEST = "ROLE_UPDATE_REQUEST";
export const ROLE_UPDATE_SUCCESS = "ROLE_UPDATE_SUCCESS";
export const ROLE_UPDATE_FAIL = "ROLE_UPDATE_FAIL";


/*=============================================================
                  Add Ticket Action
===============================================================*/
export const createRoleAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: ROLE_CREATE_REQUEST
  });
  boomiAdminApi.post(`/Admin/Role_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: ROLE_GET_SUCCESS
        });
        toast('Role added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/role-management');
      } else {
        dispatch({
          type: ROLE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: ROLE_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                edit Role Action
===============================================================*/
export const updateRoleAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: ROLE_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Admin/Role_Update`, formData)
    .then(response => {
      localStorage.removeItem("results");
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: ROLE_UPDATE_SUCCESS
        });
        toast('Role updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/role-management');

      } else {
        dispatch({
          type: ROLE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: ROLE_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};
/*=============================================================
                  Get All Role Action
===============================================================*/
export const getAllRoleAction = formData => async dispatch => {
  try {
    dispatch({
      type: ROLES_REQUEST
    });
    let { data } = await 
    nodeGetApi.post(`/Admin/Roles_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=ROLE_NAME&SortOrder=ASC`,
      formData,
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ROLES_SUCCESS,
          payload: []
        });
      } else {
       dispatch({
          type: ROLES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: ROLES_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                 Delete Ticket Action
===============================================================*/
export const deleteRoleAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/Admin/Role_Delete`, formData
    );
    if (data) {
      toast('Role deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationRole(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};