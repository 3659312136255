import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import React, { useState } from 'react';
import {
  createPartneraddressAction,
} from './../../store/actions/partneraddressAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { ConnectedFocusError } from "focus-formik-error";
import PhoneInput from 'react-phone-input-2'
import "../../App.scss"
import ESICity from 'src/components/country-state-city/city';
import ESIState from 'src/components/country-state-city/state';
import ESICountry from 'src/components/country-state-city/country';
import { CircularProgress } from '@mui/material';

const AddresstypeOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Home',
    label: 'Home'
  },
  {
    value: 'Business',
    label: 'Business'
  },
  {
    value: 'Office',
    label: 'Office'
  }
];

const AddPartneraddress = ({ className, state, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState({ global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.partneraddress.partneraddressCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  const [mobile, setMobile] = useState();

  return (
    <Formik
      initialValues={{
        Partner_Details_Id: state.Partner_Details_Id,
        Address_Type: '',
        Location: '',
        Google_Map_Location: '',
        Door_No: '',
        Land_Mark: '',
        Street: '',
        City: '',
        State: '',
        Country: '',
        Zip: '',
        Mobile: '',
        Landline: '',
        Email_Id: '',
        Latitude: '',
        Longitude: '',
        Is_Default: false,
        Is_Active: false,
        Is_Pickup_Available: false,
        Is_Shipping_Available: false,
        Created_By: loginEmail,
      }}

      validationSchema={
        Yup.object().shape({
          Address_Type: Yup.string().required('Address Type is required.'),
          Location: Yup.string().required('Location is required.'),
          Google_Map_Location: Yup.string()
            .matches(/^https?:\/\/(www\.)?google\.[a-z]+\/maps\b/,
              'Please provide valid URL.'
            )
            .required('Google Map Location URL is required.'),
          Door_No: Yup.string().required('Door No is required.'),
          Land_Mark: Yup.string().required('Land Mark is required.'),
          Street: Yup.string().required('Street is required.'),
          City: Yup.string().required('City is required.'),
          State: Yup.string().required('State is required.'),
          Country: Yup.string().required('Country is required.'),
          Email_Id: Yup.string().email("Email ID must be valid.").required('Email ID is required.'),
          Zip: Yup.string()
            .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/,
              'Please provide valid Pin Code.'
            )
            .required('Pin Code is required.'),
          Mobile: Yup.string()
            .min(14, "Please provide a valid Mobile Number.").required('Mobile Number is required.'),
          Landline: Yup.string().min(6, "Landline number must contain at least 6 digits.").max(12, "Landline number should not be more than 12 digits."),
          Latitude: Yup.string().matches(/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/, 'Please provide valid Latitude.').required('Latitude is required.'),
          Longitude: Yup.string().matches(/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/, 'Please provide valid Longitude.').required('Longitude is required.'),
        })}

      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        if (values) {
          let formValues = JSON.parse(JSON.stringify(values));

          if (values.Is_Default) {
            formValues.Is_Default = 1;
          } else {
            formValues.Is_Default = 0;
          }
          if (values.Is_Active) {
            formValues.Is_Active = 1;
          } else {
            formValues.Is_Active = 0;
          }
          if (values.Is_Pickup_Available) {
            formValues.Is_Pickup_Available = 1;
          } else {
            formValues.Is_Pickup_Available = 0;
          }
          if (values.Is_Shipping_Available) {
            formValues.Is_Shipping_Available = 1;
          } else {
            formValues.Is_Shipping_Available = 0;
          }
          dispatch(createPartneraddressAction(formValues, navigate, state, setErrorMessage, setErrors));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (<form onSubmit={handleSubmit}>
        <ConnectedFocusError />
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={8} xs={12}>
                <Grid container spacing={3}>
                  {/* Address_Type */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Address_Type && errors.Address_Type)}
                      fullWidth
                      helperText={touched.Address_Type && errors.Address_Type}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      label="Address Type"
                      name="Address_Type"
                      value={values.Address_Type}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    >
                      {AddresstypeOption.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <ESICountry
                      error={Boolean(touched.Country && errors.Country)}
                      fullWidth
                      helperText={touched.Country && errors.Country}
                      placeholder="Country"
                      label="Country"
                      name="Country"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.Country}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <ESIState
                      error={Boolean(touched.State && errors.State)}
                      fullWidth
                      helperText={touched.State && errors.State}
                      label="State"
                      name="State"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.State}
                      countrycode={values.Country}
                      select
                      SelectProps={{ native: true }}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <ESICity
                      error={Boolean(touched.City && errors.City)}
                      fullWidth
                      helperText={touched.City && errors.City}
                      label="City"
                      name="City"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.City}
                      countrycode={values.Country}
                      statecode={values.State}
                      select
                      SelectProps={{ native: true }}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  {/* Street */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Street && errors.Street)}
                      fullWidth
                      helperText={touched.Street && errors.Street}
                      label="Street"
                      name="Street"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Street}
                      variant="outlined"
                    />
                  </Grid>

                  {/* Location */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Location && errors.Location)}
                      fullWidth
                      helperText={touched.Location && errors.Location}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Location"
                      name="Location"
                      value={values.Location}
                      variant="outlined"
                    />
                  </Grid>

                  {/* Door_No */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Door_No && errors.Door_No)}
                      fullWidth
                      helperText={touched.Door_No && errors.Door_No}
                      label="Door No"
                      name="Door_No"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Door_No}
                      variant="outlined"
                    />
                  </Grid>

                  {/* Land_Mark */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Land_Mark && errors.Land_Mark)}
                      fullWidth
                      helperText={touched.Land_Mark && errors.Land_Mark}
                      label="Land Mark"
                      name="Land_Mark"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Land_Mark}
                      variant="outlined"
                    />
                  </Grid>

                  {/* Google_Map_Location */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Google_Map_Location && errors.Google_Map_Location)}
                      fullWidth
                      helperText={touched.Google_Map_Location && errors.Google_Map_Location}
                      onBlur={handleBlur}
                      type="url"
                      onChange={handleChange}
                      label="Google Map Location URL"
                      name="Google_Map_Location"
                      value={values.Google_Map_Location}
                      variant="outlined"
                    />
                  </Grid>

                  {/* Zip */}
                  <Grid item md={6} xs={12} className="partner">
                    <TextField
                      error={Boolean(touched.Zip && errors.Zip)}
                      fullWidth
                      helperText={touched.Zip && errors.Zip}
                      label="Pin Code"
                      name="Zip"
                      type="number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Zip}
                      variant="outlined"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                      }}
                      min={0}
                    />
                  </Grid>

                  {/* Latitude */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Latitude && errors.Latitude)}
                      fullWidth
                      helperText={touched.Latitude && errors.Latitude}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Latitude"
                      name="Latitude"
                      value={values.Latitude}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    >
                    </TextField>
                  </Grid>

                  {/* Longitude */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Longitude && errors.Longitude)}
                      fullWidth
                      helperText={touched.Longitude && errors.Longitude}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Longitude"
                      name="Longitude"
                      value={values.Longitude}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    >
                    </TextField>
                  </Grid>

                  {/* Mobile */}
                  <Grid item md={6} xs={12}>
                    <PhoneInput
                      containerClass={touched.Mobile && errors.Mobile ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                      inputClass={"form-control MuiOutlinedInput-input"}
                      error={true}
                      country={"in"}
                      fullWidth
                      inputStyle={{
                        width: "100%",
                        height: "30%",
                        borderRadius: "5px"
                      }}
                      inputProps={{
                        name: "Mobile",
                      }}
                      specialLabel="Mobile Number"
                      name="Mobile"
                      placeholder="+91 XXXXX XXXXX"
                      onBlur={handleBlur}
                      onChange={(value, country, e, formattedValue) => {
                        setFieldValue("Mobile", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                        setMobile(formattedValue)
                      }}
                      value={mobile}
                    />
                    <p style={{ color: "red" }} className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Mobile && errors.Mobile}​​​​​​​​</p>

                  </Grid>

                  {/* Landline */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Landline && errors.Landline)}
                      fullWidth
                      helperText={touched.Landline && errors.Landline}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      label="Landline"
                      name="Landline"
                      value={values.Landline}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  {/* Email_Id */}
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.Email_Id && errors.Email_Id)}
                      fullWidth
                      helperText={touched.Email_Id && errors.Email_Id}
                      label="Email ID"
                      name="Email_Id"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Email_Id}
                      variant="outlined"
                    />
                  </Grid>

                  {/* Is_Default */}
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onBlur={handleBlur}
                          checked={values.Is_Default}
                          onChange={handleChange}
                          name="Is_Default"
                          color="primary"
                        />
                      }
                      label="Is Address Default"
                    />
                  </Grid>

                  {/* Is_Active */}
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onBlur={handleBlur}
                          checked={values.Is_Active}
                          onChange={handleChange}
                          name="Is_Active"
                          color="primary"
                        />
                      }
                      label="Is Address Active"
                    />
                  </Grid>

                  {/* Is_Pickup_Available */}
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onBlur={handleBlur}
                          checked={values.Is_Pickup_Available}
                          onChange={handleChange}
                          name="Is_Pickup_Available"
                          color="primary"
                        />
                      }
                      label="Is Pickup Available"
                    />
                  </Grid>

                  {/* Is_Shipping_Available */}
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onBlur={handleBlur}
                          checked={values.Is_Shipping_Available}
                          onChange={handleChange}
                          name="Is_Shipping_Available"
                          color="primary"
                        />
                      }
                      label="Is Shipping Available"
                    />
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Box display="flex" pl={2} >
          <span style={{ color: "red" }}>{errorMessage.global.message}</span>
        </Box>
        <Box display="flex" p={2}>
          {isSubmited ?
            <CircularProgress />
            :
            <Button
              disabled={isSubmited}
              type="submit"
              style={{ backgroundColor: '#27B6CC' }}
              variant="contained"
            >
              {/* Save details */}
              <span style={{ color: 'white' }}>Save details</span>
            </Button>
          }
        </Box>
      </form>
      )}
    </Formik>
  );
};

AddPartneraddress.propTypes = {
  className: PropTypes.string
};

export default AddPartneraddress;