import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    makeStyles
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    }
}));

const headCells = [
    { id: 'Subcription_Package_Locations_ID', numeric: false, disablePadding: true, label: 'Subscription Package Location ID' },
    { id: 'Subcription_Package_ID', numeric: false, disablePadding: false, label: 'Subcription Package ID' },
    { id: 'City_ID', numeric: false, disablePadding: false, label: 'City ID' },
    { id: 'Partner_Details_ID', numeric: false, disablePadding: false, label: 'Partner Details ID' },
    { id: 'Partner_Store_Address_ID', numeric: false, disablePadding: false, label: 'Partner Store Address ID' },
];

const Results = ({
    className,
    allSubsPackLocations,
    page,
    setPage,
    limit,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    createSortHandler,
    allSubsPackLocationsNoFilter,
    state,
    ...rest
}) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [selectedSubsPackLocationIds, setSelectedSubsPackLocationIds] = useState([]);

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const handleClose = (value) => {
        setOpen(false);
    };

    const handleSelectAll = event => {
        let newselectedSubsPackLocationIds;
        if (event.target.checked) {
            newselectedSubsPackLocationIds = allSubsPackLocations.map(subspackLocations => subspackLocations.Subcription_Package_Locations_ID);
        } else {
            newselectedSubsPackLocationIds = [];
        }
        setSelectedSubsPackLocationIds(newselectedSubsPackLocationIds);
    };

    const handleSelectOne = (event, id) => {
        setSelectedSubsPackLocationIds(selectedSubsPackLocationIds.filter(record => {
            for (let i = 0; i < allSubsPackLocationsNoFilter.length; i++) {
                if (record == allSubsPackLocationsNoFilter[i].Subcription_Package_Locations_ID) {
                    return true;
                }
            }
            return false;
        }))
        const selectedIndex = selectedSubsPackLocationIds.indexOf(id);
        let newselectedSubsPackLocationIds = [];
        if (selectedIndex === -1) {
            newselectedSubsPackLocationIds = newselectedSubsPackLocationIds.concat(selectedSubsPackLocationIds, id);
        } else if (selectedIndex === 0) {
            newselectedSubsPackLocationIds = newselectedSubsPackLocationIds.concat(
                selectedSubsPackLocationIds.slice(1)
            );
        } else if (selectedIndex === selectedSubsPackLocationIds.length - 1) {
            newselectedSubsPackLocationIds = newselectedSubsPackLocationIds.concat(
                selectedSubsPackLocationIds.slice(0, -1)
            );
        } else if (selectedIndex > 0) {
            newselectedSubsPackLocationIds = newselectedSubsPackLocationIds.concat(
                selectedSubsPackLocationIds.slice(0, selectedIndex),
                selectedSubsPackLocationIds.slice(selectedIndex + 1)
            );
        }
        setSelectedSubsPackLocationIds(newselectedSubsPackLocationIds);
    };

    const deleteSelectedSubsPackLocationHandler = () => async e => {
        setOpen(true);
        setTitle("Alert!");
        setMessage("This feature is under development.");
    };

    const editSubsPackLocationHandler = SubsPackLocationData => e => {
        navigate('/app/edit-subs-package-location', { state: { state: state, data: SubsPackLocationData } });
    };

    const visibleSubsPackLocationHandler = SubsPackLocationData => e => {
        navigate("/app/view-subs-package-location", { state: { state: state, data: SubsPackLocationData } });
    };

    function checkingPagination(array) {
        if (array.slice(page * limit, page * limit + limit).length <= 0) {
            setPage(page - 1);
        }
        else {
            return array.slice(page * limit, page * limit + limit);
        }
        return array;
    }

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedSubsPackLocationIds.filter(record => {
                                            for (let i = 0; i < allSubsPackLocationsNoFilter.length; i++) {
                                                if (record == allSubsPackLocationsNoFilter[i].Subcription_Package_Locations_ID) {
                                                    return true;
                                                }
                                            }
                                            return false;
                                        }).length === allSubsPackLocations.length}
                                        color="primary"
                                        indeterminate={
                                            selectedSubsPackLocationIds.filter(record => {
                                                for (let i = 0; i < allSubsPackLocationsNoFilter.length; i++) {
                                                    if (record == allSubsPackLocationsNoFilter[i].Subcription_Package_Locations_ID) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            }).length > 0 &&
                                            selectedSubsPackLocationIds.filter(record => {
                                                for (let i = 0; i < allSubsPackLocationsNoFilter.length; i++) {
                                                    if (record == allSubsPackLocationsNoFilter[i].Subcription_Package_Locations_ID) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            }).length < allSubsPackLocations.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-1rem' }} >Actions
                                    {selectedSubsPackLocationIds.filter(record => {
                                        for (let i = 0; i < allSubsPackLocationsNoFilter.length; i++) {
                                            if (record == allSubsPackLocationsNoFilter[i].Subcription_Package_Locations_ID) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }).length > 0 && <Button disabled={selectedSubsPackLocationIds.filter(record => {
                                        for (let i = 0; i < allSubsPackLocationsNoFilter.length; i++) {
                                            if (record == allSubsPackLocationsNoFilter[i].Subcription_Package_Locations_ID) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }).length <= 0} onClick={deleteSelectedSubsPackLocationHandler()}>
                                            <DeleteSweepIcon
                                                style={{ color: '#ff7a7a', fontSize: 25 }}
                                            />
                                        </Button>}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allSubsPackLocations) &&
                                (limit > 0 ? checkingPagination(allSubsPackLocations) : allSubsPackLocations).map(subpacklocations => (
                                    <TableRow
                                        hover
                                        key={subpacklocations.Subcription_Package_Locations_ID}
                                        selected={
                                            selectedSubsPackLocationIds.indexOf(subpacklocations.Subcription_Package_Locations_ID) !== -1
                                        }
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={
                                                    selectedSubsPackLocationIds.indexOf(subpacklocations.Subcription_Package_Locations_ID) !== -1
                                                }
                                                onChange={event =>
                                                    handleSelectOne(event, subpacklocations.Subcription_Package_Locations_ID)
                                                }
                                                value="true"
                                            />
                                        </TableCell>
                                        <TableCell >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{subpacklocations.Subcription_Package_Locations_ID}</TableCell>
                                        <TableCell >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{subpacklocations.Subcription_Package_ID}</TableCell>
                                        <TableCell style={{ color: '#1665D8' }}>{subpacklocations.City_ID} </TableCell>
                                        <TableCell >{subpacklocations.Partner_Details_ID}  </TableCell>
                                        <TableCell >{subpacklocations.Partner_Store_Address_ID}  </TableCell>
                                        <TableCell>
                                            <Tooltip title="Subpackage Details">
                                                <Button
                                                    onClick={visibleSubsPackLocationHandler(subpacklocations)}
                                                    color="primary" >
                                                    <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                            </Tooltip>
                                            <Button
                                                onClick={editSubsPackLocationHandler(subpacklocations)}>
                                                <CreateIcon style={{ color: '#9EA0A5' }} />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allSubsPackLocations) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt=""/>
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <TablePagination
                component="div"
                count={allSubsPackLocations.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 20]}
            />
            <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    allSubsPackLocations: PropTypes.array.isRequired
};

export default Results;