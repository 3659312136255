import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
} from '@material-ui/core';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import EditorContainer from '../../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import htmlToDraft from 'html-to-draftjs';
import {
  updatecorporateFaqAction,
} from '../../../store/actions/corporatefaqAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const faqOptions = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const UpdateCorporateFAQ = ({ className, ...rest }) => {
  const location = useLocation();
  let { state } = location;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const decodeHTMLEntities = (text) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  };
  
  const removePTags = (htmlContent) => {
    if (!htmlContent) return '';
  
    const decodedContent = decodeHTMLEntities(htmlContent);
    return decodedContent.replace(/<\/?p[^>]*>/g, '');
  };

  const isSubmited = useSelector(state => state.corporatefaq.corporatefaqUpdate.isLoading);
  const errorMessage = useSelector(state => state.corporatefaq.corporatefaqUpdate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  const [descError, setDescError] = useState()

  // Richtext editor
  const contentState = ContentState.createFromBlockArray(htmlToDraft(removePTags(state.Answer) || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(removePTags(state.Answer));

  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const values = {
    FAQ_Id: state?.id ? state?.id : "",
    Question: state?.Question ? state?.Question : "",
    Category: state?.Category ? state?.Category : "",
    Answer: removePTags(state?.Answer) ? removePTags(state?.Answer) : "",
    Modified_By: loginEmail,
    Status: state?.Status ? state?.Status : ""
  }

  const Form_Validation = Yup.object().shape({
    Category: Yup.string().matches(/^[a-zA-Z][\sa-zA-Z]*/, "Category should not start with Spaces and Special Characters.").required('Category is required.'),
    Status: Yup.string().required('Status is required.'),
    Question: Yup.string().matches(/^[a-zA-Z][\sa-zA-Z]*/, "Question should not start with Spaces and Special Characters.").required('Question is required.'),
  })

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};

    const plainTextDescription = rhDiscription.replace(/(<([^>]+)>)/ig, '').trim();
    const onlySpacesRegex = /^(|\s|&nbsp;)*$/;

    if (!plainTextDescription) {
      setDescError(!plainTextDescription)
      submitTest = false;
      errors.Answer = "Answer is required.";
    }
    else if (plainTextDescription.length < rhDiscriptionMin) {
      setDescError(plainTextDescription.length < rhDiscriptionMin)
      submitTest = false;
      errors.Answer = `Please provide at least ${rhDiscriptionMin} characters.`;
    }
    else if (onlySpacesRegex.test(plainTextDescription)) {
      setDescError(onlySpacesRegex.test(plainTextDescription))
      submitTest = false;
      errors.Answer = "Answer cannot consist of only spaces.";
    }


    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      formValues.Answer = plainTextDescription;
      dispatch(updatecorporateFaqAction(formValues, navigate));
    }
  }

  return (
    <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
      }) => (
        <Form>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Category && errors.Category)}
                        fullWidth
                        helperText={touched.Category && errors.Category}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Category"
                        name="Category"
                        value={values.Category}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Question && errors.Question)}
                        fullWidth
                        helperText={touched.Question && errors.Question}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Question"
                        name="Question"
                        value={values.Question}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Answer
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>{
                        descError && touched.Answer && errors.Answer
                      }
                      </span>

                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        placeholder="Status"
                        name="Status"
                        onChange={handleChange}
                        label="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        helperText={<ErrorMessage name="Status" />}
                      >
                        {faqOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateCorporateFAQ;