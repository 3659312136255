import {
BIDDING_PARTNER_SERVICE_QUOTE_COMMENT_UPDATE_REQUEST,
BIDDING_PARTNER_SERVICE_QUOTE_COMMENT_UPDATE_SUCCESS,
BIDDING_PARTNER_SERVICE_QUOTE_COMMENT_UPDATE_FAIL,
    
} from '../actions/partnerbiddingservicequotecommentsAction';

const initialState = {
    // biddingservicesquotecommentapproval: { biddingservicecommentquote: {}, error: '', isLoading: false },
    // biddingservicesquotecommentreject: { biddingservicecommentquote: {}, error: '', isLoading: false },

    partnerbidservicecommentUpdate: { biddingservicecommentquote: {}, error: '', isLoading: false },
};


export default function (state = initialState, action) {
    switch (action.type) {
        case BIDDING_PARTNER_SERVICE_QUOTE_COMMENT_UPDATE_REQUEST:
            return { ...state, partnerbidservicecommentUpdate: { biddingservicecommentquote: {}, error: '', isLoading: true } };
        case BIDDING_PARTNER_SERVICE_QUOTE_COMMENT_UPDATE_SUCCESS:
            return { ...state, partnerbidservicecommentUpdate: { biddingservicecommentquote: action.payload, error: '', isLoading: false } };
        case BIDDING_PARTNER_SERVICE_QUOTE_COMMENT_UPDATE_FAIL:
            return { ...state, partnerbidservicecommentUpdate: { biddingservicecommentquote: {}, error: action.payload, isLoading: false } };

         
        default:
            return state;
    }
}











