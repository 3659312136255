import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Divider
} from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  images: {
    width: 100,
    height: 100
  }
}));

const ServicesViewvarientService = ({ className, ...rest }) => {
  const location = useLocation();
  let { state } = location;
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Grid container spacing={3}>
              {/* User_Service_Request_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Variant ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Sv_Id}
                </Typography>
              </Grid>
              {/*Service_Person_Name */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Service Id
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Service_Id}
                </Typography>
              </Grid>

              {/*Subservice_Person_Name */}

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Subservice Id
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Ss_Id}
                </Typography>
              </Grid>
              {/* User_Email_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Variant Status
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Status}
                </Typography>
              </Grid>

              {/*Service_person_Mobile_Number */}

              {/* Created_By */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Created By
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  <span title={state?.Created_By}>
                    {' '}
                    {state?.Created_By?.length > 25 ? (
                      <span>{state?.Created_By?.substring(0, 25)}...</span>
                    ) : (
                      state?.Created_By
                    )}
                  </span>
                </Typography>
              </Grid>
              {/*Service_Type */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Created Datetime
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  <span title={state?.Created_Datetime}>
                    {' '}
                    {state?.Created_Datetime?.length > 25 ? (
                      <span>
                        {state?.Created_Datetime?.substring(0, 25)}...
                      </span>
                    ) : (
                      state?.Created_Datetime
                    )}
                  </span>
                </Typography>
              </Grid>
              {/*Service_person_Mobile_Number */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Charge For Special Days Timeslot %
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Service_Charge_For_Special_Days_Timeslot}
                </Typography>
              </Grid>
              {/*Service_person_Mobile_Number */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Charge For Timeslot %
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Service_Charge_For_Timeslot}
                </Typography>
              </Grid>
              {/*Service_person_Mobile_Number */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Charge For Weekend Timeslot %
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Service_Charge_For_Weekend_Timeslot}
                </Typography>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={2} />
              <Grid item xs={7} />
            </Grid>
          </Grid>

          {/*  column 2 */}
          <Grid item md={6}>
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Variant Name
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Sv_Name}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Service Name
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Service_Name}
                </Typography>
              </Grid>
              {/*Partner_Detail_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Sub Service Name
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Ss_Name}
                </Typography>
              </Grid>
              {/*Service_person_Mobile_Number */}

              {/* Payment_Method */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Modified By
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Modified_By}
                </Typography>
              </Grid>
              {/*Service_Request_Address_Id*/}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Modified Datetime
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Modified_Datetime}
                </Typography>
              </Grid>

              {/*Service_person_Mobile_Number */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Is Enquiry
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Is_Enquiry}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Image
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  <a target="_blank" href={state.Image}>
                    <img
                      className={classes.images}
                      alt="Vehicle Image Path"
                      src={state.Image}
                      variant="square"
                    />
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            style={{ color: '#66788A' }}
          >
            Description
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            style={{ paddingBottom: '1rem' }}
          >
            <span
              dangerouslySetInnerHTML={{ __html: state.Description }}
            ></span>
          </Typography>
        </Grid>
        <Grid>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            style={{ color: '#66788A' }}
          >
            Sub Description
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            style={{ paddingBottom: '1rem' }}
          >
            <span
              dangerouslySetInnerHTML={{ __html: state.Sub_Description }}
            ></span>
          </Typography>
        </Grid>
        <Divider />
        {/*Service_person_Mobile_Number */}
        <Grid>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            style={{ color: '#66788A' }}
          >
            Partner What's Included
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            style={{ paddingBottom: '1rem' }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: state.Partner_Whats_Include
              }}
            ></span>
          </Typography>
        </Grid>
        {/*Service_person_Mobile_Number */}
        <Grid>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            style={{ color: '#66788A' }}
          >
            Partner What's Excluded
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            style={{ paddingBottom: '1rem' }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: state.Partner_Whats_Excluded
              }}
            ></span>
          </Typography>
        </Grid>
        {/*Service_person_Mobile_Number */}
        <Grid>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            style={{ color: '#66788A' }}
          >
            Partner What's Expect From Us
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            style={{ paddingBottom: '1rem' }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: state.Partner_Whats_Expect_From_Us
              }}
            ></span>
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ServicesViewvarientService;
