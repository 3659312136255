import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import CircularProgress from '@mui/material/CircularProgress';
import { updateSubPackageAction, uploadSubsPackageImage } from 'src/store/actions/subscriptionpackagesAction';
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';

const useStyles = makeStyles(() => ({
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditSubscriptionPackage = ({ className, ...rest }) => {
  const location = useLocation();
  let { state } = location;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const isSubmitted = useSelector(state => state.subscriptionpackages.subspackageUpdate.isLoading);
  const errorMessage = useSelector(state => state.subscriptionpackages.subspackageUpdate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Package_Description).contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Package_Description);

  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // img upload
  const [image, setImage] = useState({ preview: state.Package_Image, raw: state.Package_Image });
  const [imgUrl, setImgUrl] = useState(state.Package_Image);
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadSubsPackageImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        Subscription_Package_ID: state.Subscription_Package_ID ? state.Subscription_Package_ID : "",
        Package_Name: state.Package_Name ? state.Package_Name : "",
        Package_Advance_Amount: state.Package_Advance_Amount ? state.Package_Advance_Amount : "",
        Package_Description: state.Package_Description ? state.Package_Description : "",
        Is_Active: state.Is_Active > 0 ? true : false,
        Package_Image: imgUrl,
        Email_Id: loginEmail,
      }}
      validationSchema={
        Yup.object().shape({
          Package_Name: Yup.string().min(3, "Package Name must be at least 3 characters.").required('Package Name is required.'),
          Package_Advance_Amount: Yup.string().required('Package Advance Amount is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        values.Package_Image = imgUrl;
        values.Package_Description = rhDiscription;

        // if (!values.Package_Image) {
        //   submitTest = false;
        //   errors.Package_Image = "Package Image is required.";
        // }
        if (!values.Package_Description || values.Package_Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Package_Description = "Description is required.";
        }
        else if (values.Package_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Package_Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          if (values.Is_Active) {
            formValues.Is_Active = 1;
          } else {
            formValues.Is_Active = 0;
          }
          dispatch(updateSubPackageAction(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Package_Name && errors.Package_Name)}
                        fullWidth
                        helperText={touched.Package_Name && errors.Package_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Package Name"
                        name="Package_Name"
                        value={values.Package_Name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Package_Advance_Amount && errors.Package_Advance_Amount)}
                        fullWidth
                        helperText={touched.Package_Advance_Amount && errors.Package_Advance_Amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Package Advance Amount"
                        name="Package_Advance_Amount"
                        value={values.Package_Advance_Amount}
                        variant="outlined"
                        type='number'
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Package_Description && errors.Package_Description}
                      </span>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Active}
                              onChange={handleChange}
                              name="Is_Active"
                              color="primary"
                            />
                          }
                          label="Is Active"
                        />
                      </Box>
                    </Grid>

                  </Grid>
                </Grid>

                {/* upload photo */}
                <Grid item md={4} xs={12}>
                  <Card>
                    &nbsp;Package Image
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Package Image Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>

                    </CardContent>
                  </Card><br />
                  {/* <span style={{ color: "red" }}>
                    {!imgUrl && touched.Package_Image && errors.Package_Image}
                  </span> */}
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmitted ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmitted || uploadPic}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditSubscriptionPackage.propTypes = {
  className: PropTypes.string
};

export default EditSubscriptionPackage;