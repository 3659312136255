import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import isEmpty from '../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import PaymentIcon from '@mui/icons-material/Payment';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  buttonRoot: {
    minWidth: '7px',
    padding: '6px'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));

const headCells = [
  { id: 'Contact_Id', numeric: false, disablePadding: false, label: 'Contact ID' },
  { id: 'Name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'Mobile', numeric: false, disablePadding: false, label: 'Mobile' },
  { id: 'Email', numeric: false, disablePadding: false, label: 'Email' },
];

const Results = ({
  className,
  allRazorpays,
  deleteTemplateHandler,
  page,
  limit,
  handleLimitChange,
  handlePageChange,
  setPage,
  order,
  orderBy,
  allRazorpayNoFilter,
  createSortHandler,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedRazorpayIds, setSelectedRazorpayIds] = useState([]);

  const paymentsRazorpayHandler = templateData => e => {
    navigate("/app/razorpay-history", { state: templateData });
  };
  const visibleRazorpayHandler = templateData => e => {
    navigate("/app/view-razorpaydetails", { state: templateData });
  };

  //pagination
  function checkingPagination(array) {
    if (array.slice(page * limit, page * limit + limit).length <= 0) {
      setPage(page - 1);
    }
    else {
      return array.slice(page * limit, page * limit + limit);
    }
    return array;
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Razorpay_Payments_Write?.Value === "Razorpay_Payments_Write" && RolePermissions?.Razorpay_Payments_Write?.Is_Write === 1) ?
                  <TableCell>Payment History</TableCell>
                  : null}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Actions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allRazorpays) &&
                (limit > 0 ? checkingPagination(allRazorpays) : allRazorpays).map(razorpay => (
                  <TableRow
                    hover
                    key={razorpay.Contact_Id}
                    selected={
                      selectedRazorpayIds.indexOf(razorpay.Contact_Id) !== -1
                    }
                  >
                    <TableCell>{razorpay.Contact_Id}</TableCell>
                    <TableCell>{razorpay.Name}</TableCell>
                    <TableCell>{razorpay.Mobile}</TableCell>
                    <TableCell style={{ color: '#1665D8' }} >
                      {razorpay.Email?.substring(0, 25)}...
                    </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Razorpay_Payments_Write?.Value === "Razorpay_Payments_Write" && RolePermissions?.Razorpay_Payments_Write?.Is_Write === 1) ?
                      <TableCell >
                        <Tooltip title="Payment History">
                          <Button
                            onClick={paymentsRazorpayHandler(razorpay)}
                            color="primary"
                          >
                            <PaymentIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                      : null}
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Razorpay_Payments_Write?.Value === "Razorpay_Payments_Write" && RolePermissions?.Razorpay_Payments_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Button
                          onClick={visibleRazorpayHandler(razorpay)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Razorpay_Payments_Read?.Value === "Razorpay_Payments_Read" && RolePermissions?.Razorpay_Payments_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Button
                          onClick={visibleRazorpayHandler(razorpay)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allRazorpays) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={allRazorpays.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 20]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;