import {
    CORPORATE_FAQ_CREATE_REQUEST,
    CORPORATE_FAQ_CREATE_SUCCESS,
    CORPORATE_FAQ_CREATE_FAIL,
    CORPORATE_FAQ_UPDATE_REQUEST,
    CORPORATE_FAQ_UPDATE_SUCCESS,
    CORPORATE_FAQ_UPDATE_FAIL,
    RESET
} from '../actions/corporatefaqAction'

const initialState = {
    corporatefaqCreate: { corporatefaq: {}, error: '', isLoading: false },
    corporatefaqUpdate: { corporatefaq: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CORPORATE_FAQ_CREATE_REQUEST:
            return { ...state, corporatefaqCreate: { corporatefaq: {}, error: '', isLoading: true } };
        case CORPORATE_FAQ_CREATE_SUCCESS:
            return { ...state, corporatefaqCreate: { corporatefaq: state.corporatefaqCreate.corporatefaq, error: '', isLoading: false } };
        case CORPORATE_FAQ_CREATE_FAIL:
            return { ...state, corporatefaqCreate: { corporatefaq: {}, error: action.payload, isLoading: false } };
        case CORPORATE_FAQ_UPDATE_REQUEST:
            return { ...state, corporatefaqUpdate: { corporatefaq: {}, error: '', isLoading: true } };
        case CORPORATE_FAQ_UPDATE_SUCCESS:
            return { ...state, corporatefaqUpdate: { corporatefaq: action.payload, error: '', isLoading: false } };
        case CORPORATE_FAQ_UPDATE_FAIL:
            return { ...state, corporatefaqUpdate: { corporatefaq: {}, error: action.payload, isLoading: false } };

        case RESET:
            return { ...state, faqCreate: { faq: {}, error: '', isLoading: false } };

        default:
            return state;
    }
}