import config from './config';
import { toast } from 'react-toastify';
import { paginationCityLocation } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const CITY_LOCATION_REQUEST = 'CITY_LOCATION_REQUEST';
export const CITY_LOCATION_SUCCESS = "CITY_LOCATION_SUCCESS";
export const CITY_LOCATION_FAIL = "CITY_LOCATION_FAIL";
export const CITY_LOCATION_CREATE_REQUEST = "CITY_LOCATION_CREATE_REQUEST";
export const CITY_LOCATION_CREATE_SUCCESS = "CITY_LOCATION_CREATE_SUCCESS";
export const CITY_LOCATION_CREATE_FAIL = "CITY_LOCATION_CREATE_FAIL";
export const CITY_LOCATION_UPDATE_REQUEST = "CITY_LOCATION_UPDATE_REQUEST";
export const CITY_LOCATION_UPDATE_SUCCESS = "CITY_LOCATION_UPDATE_SUCCESS";
export const CITY_LOCATION_UPDATE_FAIL = "CITY_LOCATION_UPDATE_FAIL";

/*=============================================================
                  Add city location Action
===============================================================*/
export const addCitylocationAction = (formData, state, navigate, setErrorMessage) => async dispatch => {
  setErrorMessage('');
  dispatch({
    type: CITY_LOCATION_CREATE_REQUEST
  });
  nodeCudApi.post(`/Location_For_City/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: CITY_LOCATION_CREATE_SUCCESS
        });
        toast('City Location added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/city-location', state = { state });
      } else {
        setErrorMessage(successResponse.UI_Display_Message);
        dispatch({
          type: CITY_LOCATION_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage("Currently server is not working. Please try again later.");
      dispatch({
        type: CITY_LOCATION_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};
/*=============================================================
                   Get All city location Action
===============================================================*/
export const getAllCitylocations = formData => async dispatch => {
  try {
    dispatch({
      type: CITY_LOCATION_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Location_For_City/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Location_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: CITY_LOCATION_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CITY_LOCATION_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CITY_LOCATION_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                  Delete city location Action
===============================================================*/
export const deleteCitylocation = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`${config.nodecudurl}/Location_For_City/Delete`, formData);
    if (data) {
      toast('City Location deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationCityLocation(filters, pagination, callBackPagination));
    }
  } catch (err) { }
};

/*=============================================================
                Update city location Action
===============================================================*/
export const updateCitylocation = (formData, state, navigate) => async dispatch => {
  dispatch({
    type: CITY_LOCATION_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Location_For_City/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: CITY_LOCATION_UPDATE_SUCCESS
        });
        toast('City Location updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/city-location', state = { state });
      } else {
        dispatch({
          type: CITY_LOCATION_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: CITY_LOCATION_UPDATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};