import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Checkbox,
    FormControlLabel, ListItemText
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { UpdateAvailableBiddingServiceAction, getAllPartnerDetails, getAllPartneraddressesAction, getAllBiddingServices } from 'src/store/actions/biddingpartneravailableserviceAction';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";

const statusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const discountType = [
    {
        value: '',
        label: '-Please Select-'
    },
    {
        value: 'A',
        label: 'Amount'
    },
    {
        value: 'P',
        label: 'Percentage'
    },
];

const EditBiddingPartnerAvailServices = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let { state } = location;

    const isSubmitted = useSelector(state => state.biddingpartneravailableservices.biddingavailableserviceUpdate.isLoading);
    const errorMessage = useSelector(state => state.biddingpartneravailableservices.biddingavailableserviceUpdate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const partners = useSelector(state => state.biddingpartneravailableservices.PhysicalPartners.partners);
    const services = useSelector(state => state.biddingpartneravailableservices.BiddingServices.services);

    const [addressdata, setAddressData] = React.useState([]);
    let USERADDRESS = []
    if (addressdata) {
        for (let i = 0; i < addressdata?.length; i++) {
            USERADDRESS.push(
                {
                    label: addressdata[i].Partner_Address_Id,
                    value: addressdata[i].Partner_Address_Id,

                })
        }
    }

    const callBackUserAddrData = (data) => {
        setAddressData(data)
    }

    useEffect(() => {
        let data = {
            search_by_filter: "",
            search: "",
            Records_Filter: "FILTER",
            Partner_Details_Id: state.Partner_Detail_Id
        }
        dispatch(getAllPartnerDetails())
        dispatch(getAllBiddingServices())
        dispatch(getAllPartneraddressesAction(data, callBackUserAddrData))
    }, []);

    const setAddrType = (value) => {
        if (value !== "") {
            let data = {
                search_by_filter: "",
                search: "",
                Records_Filter: "FILTER",
                Partner_Details_Id: value
            }
            dispatch(getAllPartneraddressesAction(data, callBackUserAddrData))
        }
    }

    return (
        <Formik
            initialValues={{
                Bidding_Service_Id: state.Bidding_Service_Id ? state.Bidding_Service_Id : "",
                Is_Service_Available: state.Is_Service_Available > 0 ? true : false,
                Modified_By: loginEmail,
                Partner_Address_Id: state.Partner_Address_Id ? state.Partner_Address_Id : "",
                Partner_Bidding_Available_Service_Id: state.Partner_Bidding_Available_Service_Id ? state.Partner_Bidding_Available_Service_Id : "",
                Partner_Detail_Id: state.Partner_Detail_Id ? state.Partner_Detail_Id : "",
                Status: state.Status ? state.Status : "",
                ESI_Commission: state.ESI_Commission ? state.ESI_Commission : "",
                ESI_Commission_Type: state.ESI_Commission_Type ? state.ESI_Commission_Type : "",
            }}
            validationSchema={
                Yup.object().shape({
                    Partner_Detail_Id: Yup.string().required('Partner Details ID is required.'),
                    Bidding_Service_Id: Yup.string().required('Bidding Service ID is required.'),
                    Partner_Address_Id: Yup.string().required('Partner Address ID is required.'),
                    Status: Yup.string().required('Status is required.'),
                    ESI_Commission_Type: Yup.string().test('oneOfRequired',
                        'ESI Commission Type is required.',
                        function (item) {
                            return (this.parent.ESI_Commission_Type)
                        }
                    ),
                    ESI_Commission: Yup.number().when("ESI_Commission_Type", (ESI_Commission_Type, schema) => {
                        if (ESI_Commission_Type === "A") {
                            return schema.min(1, "ESI Commission must be at least 1.").required('ESI Commission is required.');
                        } else if (ESI_Commission_Type === "P") {
                            return schema.min(1, "ESI Commission Percentage must be at least 1.").max(100, "ESI Commission Percentage must be less then 100.").required('ESI Commission Percentage is required.');
                        }
                    }),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    if (values.Is_Service_Available) {
                        formValues.Is_Service_Available = 1;
                    } else {
                        formValues.Is_Service_Available = 0;
                    }
                    dispatch(UpdateAvailableBiddingServiceAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Detail_Id && errors.Partner_Detail_Id)}
                                                fullWidth
                                                helperText={touched.Partner_Detail_Id && errors.Partner_Detail_Id}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setAddrType(e.target.value)
                                                }}
                                                label="Partner Details ID"
                                                name="Partner_Detail_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Partner_Detail_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {partners?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                                                    <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                                                        {option.Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Autocomplete
                                                freeSolo
                                                options={addressdata}
                                                groupBy={(option) => option?.firstLetter}
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option?.Location || ""}
                                                onChange={(e, value) => {
                                                    setFieldValue("Partner_Address_Id", value?.Partner_Address_Id ? value?.Partner_Address_Id : "");
                                                }}
                                                value={addressdata?.length > 0 ? addressdata?.filter((item) => item?.Partner_Address_Id === values?.Partner_Address_Id)[0] : ""}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                        <ListItemText
                                                            primary={option?.Location}
                                                            secondary={option?.Address_Type + " - " + option?.Street + " - " + option?.City + " - " + option?.State + " - " + option?.Country + " - " + option?.Zip}
                                                        />
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        error={Boolean(touched.Partner_Address_Id && errors.Partner_Address_Id)}
                                                        fullWidth
                                                        helperText={touched.Partner_Address_Id && errors.Partner_Address_Id}
                                                        {...params}
                                                        placeholder="Search"
                                                        variant="outlined"
                                                        label="Partner Address ID"
                                                        name="Partner_Address_Id"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off', // disable autocomplete and autofill,
                                                            startAdornment: (
                                                                <React.Fragment>
                                                                    <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                    {params.InputProps.startAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Service_Id && errors.Bidding_Service_Id)}
                                                fullWidth
                                                helperText={touched.Bidding_Service_Id && errors.Bidding_Service_Id}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                label="Service Type"
                                                name="Bidding_Service_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Service_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">-Please Select-</option>
                                                {services?.sort((a, b) => a.Service_Type?.localeCompare(b.Service_Type))?.map(option => (
                                                    <option key={option.Bidding_service_Id} value={option.Bidding_service_Id
                                                    }>
                                                        {option.Service_Type}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        {/*  ESI Commission Type */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.ESI_Commission_Type && errors.ESI_Commission_Type)}
                                                fullWidth
                                                helperText={touched.ESI_Commission_Type && errors.ESI_Commission_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ESI Commission Type"
                                                name="ESI_Commission_Type"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.ESI_Commission_Type}
                                                variant="outlined"
                                            >
                                                {discountType.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        {/*  ESI Commission */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.ESI_Commission && errors.ESI_Commission)}
                                                fullWidth
                                                helperText={touched.ESI_Commission && errors.ESI_Commission}
                                                onBlur={handleBlur}
                                                label="ESI Commission"
                                                name="ESI_Commission"
                                                type="number"
                                                onChange={handleChange}
                                                value={values.ESI_Commission}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onBlur={handleBlur}
                                                            checked={values.Is_Service_Available}
                                                            onChange={handleChange}
                                                            name="Is_Service_Available"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Is Service Available"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditBiddingPartnerAvailServices.propTypes = {
    className: PropTypes.string
};

export default EditBiddingPartnerAvailServices;