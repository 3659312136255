import config from './config';
import { toast } from 'react-toastify';
import { paginationPendingProducts, paginationProducts } from './node-actions/nodepagination';
import { nodeGetApi, boomiAdminApi } from './commonAxios';

export const PRODUCTS_REQUEST = "PRODUCTS_REQUEST";
export const PRODUCTS_SUCCESS = "PRODUCTS_SUCCESS";
export const PRODUCTS_FAIL = "PRODUCTS_FAIL";

export const PRODUCT_GET_REQUEST = "PRODUCT_GET_REQUEST";
export const PRODUCT_GET_SUCCESS = "PRODUCT_GET_SUCCESS";
export const PRODUCT_GET_FAIL = "PRODUCT_GET_FAIL";

export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";

export const PRODUCT_BRANDS_REQUEST = "PRODUCT_BRANDS_REQUEST";
export const PRODUCT_BRANDS_SUCCESS = "PRODUCT_BRANDS_SUCCESS";
export const PRODUCT_BRANDS_FAIL = "PRODUCT_BRANDS_FAIL";

export const PRODUCT_CATEGORIES_REQUEST = "PRODUCT_CATEGORIES_REQUEST";
export const PRODUCT_CATEGORIES_SUCCESS = "PRODUCT_CATEGORIES_SUCCESS";
export const PRODUCT_CATEGORIES_FAIL = "PRODUCT_CATEGORIES_FAIL";

export const PRODUCT_SUBCATEGORIES_REQUEST = "PRODUCT_SUBCATEGORIES_REQUEST";
export const PRODUCT_SUBCATEGORIES_SUCCESS = "PRODUCT_SUBCATEGORIES_SUCCESS";
export const PRODUCT_SUBCATEGORIES_FAIL = "PRODUCT_SUBCATEGORIES_FAIL";

export const PRODUCT_COLOURS_REQUEST = "PRODUCT_COLOURS_REQUEST";
export const PRODUCT_COLOURS_SUCCESS = "PRODUCT_COLOURS_SUCCESS";
export const PRODUCT_COLOURS_FAIL = "PRODUCT_COLOURS_FAIL";

export const PRODUCT_ROLES_REQUEST = "PRODUCT_ROLES_REQUEST";
export const PRODUCT_ROLES_SUCCESS = "PRODUCT_ROLES_SUCCESS";
export const PRODUCT_ROLES_FAIL = "PRODUCT_ROLES_FAIL";

export const PRODUCT_MASTER_REQUEST = "PRODUCT_MASTER_REQUEST";
export const PRODUCT_MASTER_SUCCESS = "PRODUCT_MASTER_SUCCESS";
export const PRODUCT_MASTER_FAIL = "PRODUCT_MASTER_FAIL";

export const PRODUCT_SIZE_REQUEST = "PRODUCT_SIZE_REQUEST";
export const PRODUCT_SIZE_SUCCESS = "PRODUCT_SIZE_SUCCESS";
export const PRODUCT_SIZE_FAIL = "PRODUCT_SIZE_FAIL";

export const PRODUCTS_APPROVE_REQUEST = "PRODUCTS_APPROVE_REQUEST";
export const PRODUCTS_APPROVE_SUCCESS = "PRODUCTS_APPROVE_SUCCESS";
export const PRODUCTS_APPROVE_FAIL = "PRODUCTS_APPROVE_FAIL";

export const PRODUCT_MASTER_FILTER_REQUEST = "PRODUCT_MASTER_FILTER_REQUEST";
export const PRODUCT_MASTER_FILTER_SUCCESS = "PRODUCT_MASTER_FILTER_SUCCESS";
export const PRODUCT_MASTER_FILTER_FAIL = "PRODUCT_MASTER_FILTER_FAIL";

export const PRODUCTS_FILTER_REQUEST = "PRODUCTS_FILTER_REQUEST";
export const PRODUCTS_FILTER_SUCCESS = "PRODUCTS_FILTER_SUCCESS";
export const PRODUCTS_FILTER_FAIL = "PRODUCTS_FILTER_FAIL";

/*=============================================================
                  Add Product Action
===============================================================*/
export const createProductAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: PRODUCT_CREATE_REQUEST
  });
  boomiAdminApi.post(`/Products/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PRODUCT_CREATE_SUCCESS
        });
        toast('Product added sucessfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/product-management/products');
      } else {
        dispatch({
          type: PRODUCT_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PRODUCT_CREATE_FAIL,
        payload: "Please Try Sometime Later.Currently Server is Not Working."
      });
    })
};

/*=============================================================
                  Update Product Action
===============================================================*/
export const updateProductAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: PRODUCT_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Products/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PRODUCT_UPDATE_SUCCESS
        });
        toast('Product updated successfully', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/product-management/products');
      } else {
        dispatch({
          type: PRODUCT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PRODUCT_CREATE_FAIL,
        payload: "Please Try Sometime Later.Currently Server is Not Working"
      });
    })
};

/*=============================================================
                  Product Image Delete Action
===============================================================*/
export const imageDelete = (input, callBackfunction, key) => async dispatch => {
  let payload = {
    "Product_Image_Id": input,
    "Record_Filter": "H"
  };
  boomiAdminApi.post(`/Products/Product_Image_Delete`, payload
  )
    .then(response => {
      let data = response.data;
      callBackfunction(input, data.Response_Status === "Success" ? true : false, key);
    })
}

/*=============================================================
                 getProductImages Action
===============================================================*/
export const getProductImages = (productId, callBackfunction, key) => async dispatch => {
  let payload = {
    "Records_Filter": "FILTER",
    "Product_Image_Id": "",
    "Status": "",
    "Product_Id": productId
  };
  nodeGetApi.post(`/Products/Product_Image_Get?PageNo=1&PageSize=10&SortBy=Product_Id&SortOrder=ASC`, payload)
    .then(response => {
      let data = response.data;
      if (data.Success_Response.Is_Data_Exist === "0" && data.Success_Response.Response_Status === "Success") {
        callBackfunction([])
      }
      else if (data.Success_Response.Is_Data_Exist === "1" && data.Success_Response.Response_Status === "Success") {
        callBackfunction(data.results[0].Product_Image, key);
      } else {
        callBackfunction([]);
      }
    })
}

/*=============================================================
                 singleImageUploadCreate Action
===============================================================*/
export const singleImageUploadCreate = (image, callBackfunction, key) => async dispatch => {
  boomiAdminApi.post(`/SA_Upload/Upload?functionality=Products&fileextension=png&filetype=Images&filename=Products`, image
  )
    .then(response => {
      let data = response.data;
      if (data.File_URL) {
        callBackfunction(true, data.File_URL, key);
      }
      else {
        callBackfunction(false, data.File_URL, key);
      }
    }).catch(error => {
      callBackfunction(false, "", key);
    })
}

export const singleImageUploadUpdate = (image, productId, modifiedBy, key, callBackfunction) => async dispatch => {
  boomiAdminApi.post(`/SA_Upload/Upload?functionality=Products&fileextension=png&filetype=Images&filename=Products`, image)
    .then(response => {
      let successResponse = response.data;
      let data = response.data;
      if (successResponse.Response_Status === "Success") {
        let imageCreate = {
          "Created_By": modifiedBy,
          "Product_Id": productId
        }
        let Images = [];
        Images.push({
          "Image_Url": data.File_URL,
          "Is_Default": "0",
          "Sort": key
        });
        imageCreate.Images = Images;
        boomiAdminApi.post(`/Multiproductimage/Images_Create`, imageCreate
        )
          .then(response => {
            callBackfunction(true);
          }).catch(error => {
            callBackfunction(false);
          })
      }
    }).catch(error => {
      callBackfunction(false);
    })
}

/*=============================================================
                 imageDefaultUpdate Action
===============================================================*/
export const imageDefaultUpdate = (productId, modifiedBy, Product_Image_Id, callBackfunction) => async dispatch => {
  boomiAdminApi.put(`/Products/Product_Image_Update`,
    {
      "Is_Default": 1,
      "Modified_By": modifiedBy,
      "Product_Id": productId,
      "Product_Image_Id": Product_Image_Id,
    }
  )
    .then(response => {
      let data = response.data;
      if (data.Response_Status === "Success") {
        callBackfunction(true);
      }
      else {
        callBackfunction(false);
      }
    }).catch(error => {
      callBackfunction(false);
    })
}

/*=============================================================
                  Get All Product Action
===============================================================*/
export const getAllProductAction = formData => async dispatch => {
  try {
    dispatch({
      type: PRODUCTS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Products/Get_Admin?PageNo=1&PageSize=20&SortBy=PRODUCT_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCTS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCTS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCTS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  delete product Action
===============================================================*/
export const deleteProductAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/Products/Delete`, formData
    );
    if (data) {
      toast('Product deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationProducts(filters, pagination, callBackPagination));
    }
  } catch (err) { }
};

/*=============================================================
                  Get Single Product Action
===============================================================*/
export const getSingleProduct = (formData, callBackProductData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Products/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Product_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBackProductData(data)
    }
  } catch (err) {
  }
};

/*=============================================================
                  Get All Brands Action
===============================================================*/
export const getAllBrands = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Brand_Status: "Active",
      search_by_filter: "",
      search: "",
    };
    dispatch({
      type: PRODUCT_BRANDS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Brand/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=BRAND_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_BRANDS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_BRANDS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_BRANDS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                   Get All Categories Action
===============================================================*/
export const getAllCategories = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Status: "Active",
      search_by_filter: "",
      search: "",
    };
    dispatch({
      type: PRODUCT_CATEGORIES_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Categories/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=CATEGORY_ID&SortOrder=ASC`,
      formData,

    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_CATEGORIES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_CATEGORIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_CATEGORIES_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Get All Sub Categories Action
===============================================================*/
export const getAllSubCategories = () => async dispatch => {
  try {
    let formData =
    {
      Records_Filter: "FILTER",
      Status: "Active",
      search_by_filter: "",
      search: "",
    };
    dispatch({
      type: PRODUCT_SUBCATEGORIES_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Categories/Sub_Category_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Sub_Category_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_SUBCATEGORIES_SUCCESS,
          payload: []
        });
      } else {

        dispatch({
          type: PRODUCT_SUBCATEGORIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_SUBCATEGORIES_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Get All Colours Action
===============================================================*/
export const getAllColours = (Product_Master_id) => async dispatch => {
  try {
    let formData = {
      Product_Master_Id: Product_Master_id,
    };
    dispatch({
      type: PRODUCT_COLOURS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Products/Product_Remain_Color_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=PRODUCT_MASTER_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_COLOURS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_COLOURS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_COLOURS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Get All Masters Action
===============================================================*/
export const getAllMasters = (Product_Master_Id) => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Status: "",
      search_by_filter: "",
      search: "",
      Product_Master_Id: Product_Master_Id
    };
    dispatch({
      type: PRODUCT_MASTER_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Products/Product_Master_Get_Admin?PageNo=1&PageSize=20&SortBy=PRODUCT_MASTER_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_MASTER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_MASTER_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_MASTER_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Get All Sizes Action
===============================================================*/
export const getAllSizes = (Product_Master_id) => async dispatch => {
  try {
    let formData = {
      Product_Master_Id: Product_Master_id,
      Records_Filter: "FILTER"
    };
    dispatch({
      type: PRODUCT_SIZE_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Products/Product_Remain_Size_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Product_Size_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_SIZE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_SIZE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_SIZE_FAIL,
      payload: err
    });
  }
};

/*=============================================================
              get All Approval Products Action
===============================================================*/
export const getAllApprovalProductsAction = (formData) => async dispatch => {
  try {
    dispatch({
      type: PRODUCTS_APPROVE_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Products/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Product_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCTS_APPROVE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCTS_APPROVE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCTS_APPROVE_FAIL,
      payload: err
    });
  }
};
/*=============================================================
             Update approved product Action
===============================================================*/
export const updateProductApprovalAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  dispatch({
    type: PRODUCT_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Products/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PRODUCT_UPDATE_SUCCESS
        });
        toast('Product has been Accepted.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        dispatch(paginationPendingProducts(filters, pagination, callBackPagination));
      } else {
        dispatch({
          type: PRODUCT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PRODUCT_UPDATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};

/*=============================================================
              get All Approval Products Action
===============================================================*/
export const getAllFilterMasters = (ProductName) => async dispatch => {
  try {
    let formData = {
      Product_Name: ProductName,
      Records_Filter: "FILTER"
    };
    dispatch({
      type: PRODUCT_MASTER_FILTER_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Products/Product_Master_Get_On_Name?PageNo=1&PageSize=${config.pageSize}&SortBy=Product_Master_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_MASTER_FILTER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_MASTER_FILTER_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_MASTER_FILTER_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  Get All Product Action
===============================================================*/
export const getAllProductsSearchAction = (ProductName) => async dispatch => {
  try {
    let formData = {
      Product_Name: ProductName,
      Records_Filter: "FILTER"
    };
    dispatch({
      type: PRODUCTS_FILTER_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Products/Product_Admin_Get_Dropdown_Search?PageNo=1&PageSize=${config.pageSize}&SortBy=PRODUCT_NAME&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCTS_FILTER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCTS_FILTER_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCTS_FILTER_FAIL,
      payload: err
    });
  }
};

//Get Product
export const getProductAction = (formData, callBackProductsData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Products/Get_Admin`,
      formData
    );
    if (data) {
      callBackProductsData(data)
    }
  } catch (err) {
  }
};

/*=============================================================
                  Copy product Action
===============================================================*/
export const CopyProductAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/Product_Copy/Product_Create`,
      formData
    );
    if (data) {
      dispatch(paginationProducts(filters, pagination, callBackPagination));
      toast('Copy Product created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    }
  } catch (err) {
  }
};