import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from 'src/store/validations/is-empty';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Del_user_detail_id', numeric: false, disablePadding: false, label: 'Delivery User Detail ID' },
  { id: 'Del_user_email_id', numeric: false, disablePadding: false, label: 'Delivery User Email ID' },
  { id: 'First_name', numeric: false, disablePadding: false, label: 'First Name' },
  { id: 'Mobile', numeric: false, disablePadding: false, label: 'Mobile' },
];

const Results = ({
  className,
  alldelivery,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  alldeliveryNoFilter,
  RolePermissions,
  roleUserType,
  pagination,
  setPagination,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const editDeliveryHandler = deliveryData => e => {
    navigate('/app/edit-approved-delivery-agent-details', { state: deliveryData });
  };
  const viewAgentDtailsHandler = viewData => e => {
    navigate("/app/view-approved-delivery-agent-details", { state: viewData });
  };
  const deliveryvehicledetailshandler = viewData => e => {
    navigate("/app/delivery-vehicle-details", { state: viewData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}>
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}>
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Approved_Delivery_Agents_Write?.Value === "Approved_Delivery_Agents_Write" && RolePermissions?.Approved_Delivery_Agents_Write?.Is_Write === 1) ?
                  <TableCell >Vehicle Details</TableCell>
                  : null}
                <TableCell > <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >&emsp;Actions&nbsp;</div></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(alldelivery) &&
                (alldelivery)?.map(delivery => (
                  <TableRow
                    hover
                    key={delivery.Del_user_detail_id}>
                    <TableCell>{delivery.Del_user_detail_id}</TableCell>
                    < TableCell>{delivery.Del_user_email_id}</ TableCell >
                    <TableCell >{delivery.First_name}</TableCell>
                    <TableCell >{delivery.Mobile}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Approved_Delivery_Agents_Write?.Value === "Approved_Delivery_Agents_Write" && RolePermissions?.Approved_Delivery_Agents_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Tooltip title="Vehicle Details">
                          <Button>
                            <TwoWheelerIcon
                              onClick={deliveryvehicledetailshandler(delivery)}
                              style={{ display: 'flex', justifyContent: 'center', color: '#9EA0A5', fontSize: 20, }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                      : null}
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Approved_Delivery_Agents_Write?.Value === "Approved_Delivery_Agents_Write" && RolePermissions?.Approved_Delivery_Agents_Write?.Is_Write === 1) ?
                      <TableCell><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <Tooltip title="View Agent Details">
                          <Button
                            onClick={viewAgentDtailsHandler(delivery)}
                            color="primary">
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        <Button onClick={editDeliveryHandler(delivery)}><CreateIcon style={{ color: '#9EA0A5' }} /></Button></div>
                      </TableCell>
                      : null}
                    {RolePermissions?.Approved_Delivery_Agents_Read?.Value === "Approved_Delivery_Agents_Read" && RolePermissions?.Approved_Delivery_Agents_Read?.Is_Read === 1 &&
                      <TableCell><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <Tooltip title="View Agent Details">
                          <Button
                            onClick={viewAgentDtailsHandler(delivery)}
                            color="primary">
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </div>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(alldelivery) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  alldelivery: PropTypes.array.isRequired
};

export default Results;