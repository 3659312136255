import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    Avatar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { createBidVehicleBrandAction, uploadBiddingVehicleBrandImage } from 'src/store/actions/biddingvehiclebrandsAction';

const useStyles = makeStyles(() => ({
    images: {
        width: "100%",
        height: 100,
        borderRadius: 5,
        marginBottom: 15
    }
}));

const AddBidVehicleBrand = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    const isSubmitted = useSelector(state => state.bidvehiclebrands.bidvehiclebrandCreate.isLoading);
    const errorMessage = useSelector(state => state.bidvehiclebrands.bidvehiclebrandCreate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    // img upload
    const [image, setImage] = useState({ preview: '', raw: '' });
    const [imgUrl, setImgUrl] = useState('');
    const [uploadPic, setUploadPic] = useState(false);
    const callBackUploadImage = data => {
        if (data) {
            setImgUrl(data.File_URL);
        }
        setUploadPic(false);
    };

    const handleImage = e => {
        setUploadPic(true);
        e.preventDefault();
        setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0]
        });
        const data = new FormData();
        data.append('file', e.target.files[0]);
        dispatch(
            uploadBiddingVehicleBrandImage(new Blob([e.target.files[0]]), callBackUploadImage)
        );
    };
    const handleRemoveImage = e => {
        setImgUrl('');
        setImage({
            preview: '',
            raw: ''
        });
    };

    return (
        <Formik
            initialValues={{
                Brand_Name: '',
                Brand_Logo: imgUrl,
                Created_By: loginEmail,
            }}
            validationSchema={
                Yup.object().shape({
                    Brand_Name: Yup.string().required('Brand Name is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                values.Brand_Logo = imgUrl;

                if (!values.Brand_Logo) {
                    submitTest = false;
                    errors.Brand_Logo = "Brand Logo is required.";
                }

                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(createBidVehicleBrandAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={7} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Brand_Name && errors.Brand_Name)}
                                                fullWidth
                                                helperText={touched.Brand_Name && errors.Brand_Name}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Brand Name"
                                                name="Brand_Name"
                                                value={values.Brand_Name}
                                                variant="outlined"
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>

                                {/* Brand_Logo */}
                                <Grid item md={5} xs={12}>
                                    <Card>
                                        &nbsp;Brand Logo
                                        <CardContent>
                                            <Box display="flex" justifyContent="center">
                                                {image.preview ? (
                                                    <Avatar
                                                        className={classes.images}
                                                        alt="Vehicle Upload"
                                                        src={image.preview}
                                                        variant="square"
                                                    />
                                                ) : (
                                                    <>
                                                        <Avatar
                                                            className={classes.images}
                                                            alt="Vehicle Upload"
                                                            src="/static/images/image-placeholder.png"
                                                            variant="square"
                                                        />
                                                    </>
                                                )}
                                            </Box>

                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            >
                                                {image.preview ? <Button
                                                    style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                                                    onClick={handleRemoveImage}
                                                >
                                                    Remove Picture
                                                </Button> : <input
                                                    style={{ display: 'block', width: '80%' }}
                                                    id="upload-photo"
                                                    name="image"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImage}
                                                />}
                                                <br />
                                            </Box>
                                        </CardContent>
                                    </Card><br />
                                    <span style={{ color: "red" }}>
                                        {!imgUrl && touched.Brand_Logo && errors.Brand_Logo}
                                    </span>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted || uploadPic}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddBidVehicleBrand.propTypes = {
    className: PropTypes.string
};

export default AddBidVehicleBrand;