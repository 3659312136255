import {
    NOTIFICATIONS_REQUEST,
    NOTIFICATIONS_SUCCESS,
    NOTIFICATIONS_FAIL,

    NOTIFICATION_GET_REQUEST,
    NOTIFICATION_GET_SUCCESS,
    NOTIFICATION_GET_FAIL,

    NOTIFICATION_UPDATE_REQUEST,
    NOTIFICATION_UPDATE_SUCCESS,
    NOTIFICATION_UPDATE_FAIL,
} from '../actions/notificationAction';

const initialState = {
    notifications: { all: [], error: '', isLoading: false },
    notification: { notification: {}, error: '', isLoading: false },
    notificationUpdate: { notification: {}, error: '', isLoading: false },
    notificationDelete: { notification: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case NOTIFICATIONS_REQUEST:
            return { ...state, notifications: { all: state.notifications.all, error: '', isLoading: true } };
        case NOTIFICATIONS_SUCCESS:
            return { ...state, notifications: { all: action.payload, error: '', isLoading: false } };
        case NOTIFICATIONS_FAIL:
            return { ...state, notifications: { all: [], error: action.payload, isLoading: false } };
        case NOTIFICATION_GET_REQUEST:
            return { ...state, notification: { notification: {}, error: '', isLoading: true } };
        case NOTIFICATION_GET_SUCCESS:
            return { ...state, notification: { notification: action.payload, error: '', isLoading: false } };
        case NOTIFICATION_GET_FAIL:
            return { ...state, notification: { notification: {}, error: action.payload, isLoading: false } };
        case NOTIFICATION_UPDATE_REQUEST:
            return { ...state, notificationUpdate: { notification: {}, error: '', isLoading: true } };
        case NOTIFICATION_UPDATE_SUCCESS:
            return { ...state, notificationUpdate: { notification: state.notificationUpdate.notification, error: '', isLoading: false } };
        case NOTIFICATION_UPDATE_FAIL:
            return { ...state, notificationUpdate: { notification: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}