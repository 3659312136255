import {
    USER_DATEANDCOUNT_REQUEST,
    USER_DATEANDCOUNT_SUCCESS,
    USER_DATEANDCOUNT_FAIL,

} from '../actions/userdynamicdateandcountAction'

const initialState = {
    usermaincenterdateandcounts: { all: [], error: '', isLoading: false },
};
export default function (state = initialState, action) {
    switch (action.type) {
        case USER_DATEANDCOUNT_REQUEST:
            return { ...state, usermaincenterdateandcounts: { all: state.usermaincenterdateandcounts.all, error: '', isLoading: true } };
        case USER_DATEANDCOUNT_SUCCESS:
            return { ...state, usermaincenterdateandcounts: { all: action.payload, error: '', isLoading: false } };
        case USER_DATEANDCOUNT_FAIL:
            return { ...state, usermaincenterdateandcounts: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}