import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { nodeServiceApi, urlGenarator } from 'src/store/actions/commonAxios';

const ESICities = (props) => {
    const { State_Id, ...rest } = props;
    const [city, setCity] = useState([]);

    useEffect(() => {
        let statesData = {};
        if (State_Id) {
            statesData['State_Id'] = State_Id;
        }
        getCities(statesData);
    }, [State_Id]);

    const getCities = (statesData) => {
        nodeServiceApi.post(urlGenarator(`/admin/csc_cities/Get`, { PageNo: 1, PageSize: 1000 }), statesData)
            .then(response => {
                const sortedCities = response?.data?.results.sort((a, b) =>
                    a.Name.localeCompare(b.Name)
                );
                setCity(sortedCities);
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <TextField
            {...props}
            select // Added this to indicate that TextField is being used as a select dropdown
        >
            <option key="" value="">--Please Select--</option>
            {city?.map(option => (
                <option key={option.Id} value={option.Id}>
                    {option.Name}
                </option>
            ))}
        </TextField>
    );
};

export default ESICities;
