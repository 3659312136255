import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  InputAdornment,
  SvgIcon,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  formFilterControl: {
    margin: theme.spacing(1),
    minWidth: 200
  }
}));

const Toolbar = ({ className, filters, setfilters, RolePermissions, roleUserType, ...rest }) => {
  const classes = useStyles();

  const handleChange = event => {
    setfilters({
      search: filters?.search,
      search_by_filter: event.target.value
    })
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        mb={2}
      >
        <Box display="flex"
          flexWrap="wrap" mb={2}>
          <FormControl variant="outlined"
            className={classes.formFilterControl}>
            <Select
              value={filters.search_by_filter}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Product_Id">Product ID</MenuItem>
              <MenuItem value="Product_Name">Product Name</MenuItem>
              <MenuItem value="Esi_Reviews">ESI Reviews</MenuItem>
              <MenuItem value="Esi_Rating">ESI Rating</MenuItem>
            </Select>
          </FormControl>
          <Box width={500}>
            <TextField
              onChange={(event) => {
                setfilters({
                  search: event.target.value,
                  search_by_filter: filters.search_by_filter
                })
              }}
              className={classes.formControl}
              value={filters?.search}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search Product Reviews & Ratings"
              variant="outlined"
            />
          </Box>
        </Box>
        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
          (RolePermissions?.Reviews_Ratings_Read?.Value === "Reviews_Ratings_Read" && RolePermissions?.Reviews_Ratings_Read?.Is_Read === 1) ?
          <Box display="flex"
            flexWrap="wrap" mb={2}>
            <FormControl variant="outlined" className={classes.formFilterControl}>
              <Select
                value="Product_review"
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="Product_review" href="/app/product/reviews-and-ratings" component="a">Product Reviews</MenuItem>
                <MenuItem value="Partner_Review" href="/app/partner/reviews-and-ratings" component="a">Partner Reviews</MenuItem>
                <MenuItem value="Brand_reviews" href="/app/brand/reviews-and-ratings" component="a">Brand Reviews</MenuItem>
              </Select>
            </FormControl>
          </Box>
          : null}
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;