import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';

import './App.scss';
import { Provider } from 'react-redux';
import store from './store/store';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from './Auth'

const App = () => {


  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Auth />
      </ThemeProvider>
      <ToastContainer
        progressClassName="toastProgress"
        bodyClassName="toastBody"
      />
    </Provider>
  );
};

export default App;
