import config from './config';
import { toast } from 'react-toastify';
import { boomiAdminApi, nodeCudApi, nodeGetApi } from './commonAxios';

export const BIDDING_VEHICLE_CREATE_REQUEST = "BIDDING_VEHICLE_CREATE_REQUEST";
export const BIDDING_VEHICLE_CREATE_SUCCESS = "BIDDING_VEHICLE_CREATE_SUCCESS";
export const BIDDING_VEHICLE_CREATE_FAIL = "BIDDING_VEHICLE_CREATE_FAIL";

export const BIDDING_VEHICLE_UPDATE_REQUEST = "BIDDING_VEHICLE_UPDATE_REQUEST";
export const BIDDING_VEHICLE_UPDATE_SUCCESS = "BIDDING_VEHICLE_UPDATE_SUCCESS";
export const BIDDING_VEHICLE_UPDATE_FAIL = "BIDDING_VEHICLE_UPDATE_FAIL";

export const createBiddingRideVehicleAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: BIDDING_VEHICLE_CREATE_REQUEST
    });
    boomiAdminApi.post(`/Bidding_Vehicle/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: BIDDING_VEHICLE_CREATE_SUCCESS
                });
                toast('Bidding Ride Vehicle created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/bidding-vehicles');
            } else {
                dispatch({
                    type: BIDDING_VEHICLE_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: BIDDING_VEHICLE_CREATE_FAIL,
                payload: "Please Try Sometime Later.Currently Server is Not Working."
            });
        })
};

//update Bidding Ride Vehicle Action
export const updateBiddingRideVehicleAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: BIDDING_VEHICLE_UPDATE_REQUEST
    });
    nodeCudApi.post(`/Bidding_Vehicle/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: BIDDING_VEHICLE_UPDATE_SUCCESS
                });
                toast('Bidding Ride Vehicle updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/bidding-vehicles');
            } else {

                dispatch({
                    type: BIDDING_VEHICLE_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: BIDDING_VEHICLE_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//single image upload
export const singleRideImageCreate = (image, callBackfunction, key) => async dispatch => {
    boomiAdminApi.post(`/SA_Upload/Upload?functionality=RideVehicle&fileextension=png&filetype=Images&filename=RideVehicle`, image)
        .then(response => {
            let data = response.data;
            if (data.File_URL) {
                callBackfunction(true, data.File_URL, key);
            }
            else {
                callBackfunction(false, data.File_URL, key);
            }
        }).catch(error => {
            callBackfunction(false, "", key);
        })
}

//Get all Vehicle Images
export const getAllRideVehicleImages = (vehicleid, callBackfunction) => async dispatch => {
    let payload = {
        "search": "",
        "search_by_filter": "All",
        "Records_Filter": "FILTER",
        "Bidding_Vehicle_Image_Id": "",
        "Bidding_Vehicle_Id": vehicleid
    };
    nodeGetApi.post(`/Bidding_Ride_Images/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Created_By&SortOrder=ASC`, payload)
        .then(response => {
            let data = response.data;
            if (data.Success_Response?.Is_Data_Exist === "0" && data.Success_Response?.Response_Status === "Success") {
                callBackfunction([])
            }
            else if (data.Success_Response?.Is_Data_Exist === "1" && data.Success_Response?.Response_Status === "Success") {
                callBackfunction(data.results[0]?.Vehicle_Image);
            } else {
                callBackfunction([]);
            }
        })
}

//update
export const rideimageDefaultUpdate = (vehicleid, modifiedBy, vehicleimageid, callBackfunction) => async dispatch => {
    boomiAdminApi.put(`/Bidding_Ride_Images/Update`,
        {
            "Is_Default": 1,
            "Modified_By": modifiedBy,
            "Bidding_Vehicle_Id": vehicleid,
            "Bidding_Vehicle_Image_Id": vehicleimageid,
        })
        .then(response => {
            let data = response.data;
            if (data.Response_Status === "Success") {
                callBackfunction(true);
            }
            else {
                callBackfunction(false);
            }
        }).catch(error => {
            callBackfunction(false);
        })
}

export const singleRideImageUpdate = (image, vehicleid, modifiedBy, key, callBackfunction) => async dispatch => {
    boomiAdminApi.post(`/SA_Upload/Upload?functionality=Products&fileextension=png&filetype=Images&filename=Products`, image)
        .then(response => {
            let successResponse = response.data;
            let data = response.data;
            if (successResponse.Response_Status === "Success") {
                let imageCreate = {
                    "Created_By": modifiedBy,
                    "Bidding_Vehicle_Id": vehicleid
                }
                let Image = [];
                Image.push({
                    "Vehicle_Image": data.File_URL,
                    "Is_Default": "0",
                    "Image_Sort": key
                });
                imageCreate.Image = Image;
                boomiAdminApi.post(`/Bidding_Ride_Images/MultiImage_Create`, imageCreate)
                    .then(response => {
                        callBackfunction(true);
                    }).catch(error => {
                        callBackfunction(false);
                    })
            }
        }).catch(error => {
            callBackfunction(false);
        })
}

//delete
export const rideimageDelete = (input, callBackfunction, key) => async dispatch => {
    let payload = {
        "Bidding_Vehicle_Image_Id": input,
        "Record_Filter": "H"
    };
    boomiAdminApi.post(`/Bidding_Ride_Images/Delete`, JSON.stringify(payload))
        .then(response => {
            let data = response.data;
            callBackfunction(input, data.Response_Status === "Success" ? true : false, key);
        })
}