import { Card, CardContent, Grid } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

const TestimonialHistoryDetails = ({ className, ...rest }) => {
  const location = useLocation();
  let { state } = location;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={6}>
          <Grid item md={6}>
            <Grid container spacing={3}>
              {/* payment method */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Testmonial ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Testmonial_Id}
                </Typography>
              </Grid>

              {/* Title */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Title
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Title}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  User Email ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  <span title={state.User_Email_Id}> {state.User_Email_Id?.length > 25 ?
                    <span>{state.User_Email_Id?.substring(0, 25)}...</span> : state.User_Email_Id}</span>
                </Typography>
              </Grid>

              {/*  Rating */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Rating
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Rating}
                </Typography>
              </Grid>

              {/*  User Name */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  User Name
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.User_Name}
                </Typography>
              </Grid>

              {/*  Description */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Description
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {/* {state.Description} */}
                  <span dangerouslySetInnerHTML={{ __html: state.Description }}></span>
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Created By
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  <span title={state.Created_By}> {state.Created_By?.length > 25 ?
                    <span>{state.Created_By?.substring(0, 25)}...</span> : state.Created_By}</span>
                </Typography>
              </Grid>

              {/*Modified By */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Modified By
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  <span title={state.Modified_By}> {state.Modified_By?.length > 25 ?
                    <span>{state.Modified_By?.substring(0, 25)}...</span> : state.Modified_By}</span>
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Created Date
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Created_Date}
                </Typography>
              </Grid>

              {/* Modified Date */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Modified Date
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Modified_Date}
                </Typography>
              </Grid>

              {/* Status */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Status
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Status}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TestimonialHistoryDetails;