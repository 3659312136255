import React from 'react';
import {
  Box,
  InputAdornment,
  SvgIcon,
  TextField,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  formFilterControl: {
    margin: theme.spacing(1),
    minWidth: 200
  }
}));

const Toolbar = ({ className, filters, setfilters, ...rest }) => {
  const classes = useStyles();

  const handleChange = event => {
    setfilters({
      Search: filters?.Search,
      Search_By_Filter: event.target.value,
    })
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" flexWrap="wrap" mb={2}>
          <FormControl variant="outlined" className={classes.formFilterControl}>
            <Select
              value={filters.Search_By_Filter}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Order_Id">Order id</MenuItem>
              <MenuItem value="Company_Name">Company name</MenuItem>
              <MenuItem value="Order_By">Order by</MenuItem>
              <MenuItem value="Delivery_Type">Delivery type</MenuItem>
              <MenuItem value="Quantity">Quantity</MenuItem>
              <MenuItem value="Ordered_Date">Ordered date</MenuItem>
              <MenuItem value="Payment_Type">Payment type</MenuItem>
              <MenuItem value="Order_Status">Status</MenuItem>
            </Select>
          </FormControl>

          <Box width={500}>
            <TextField
              onChange={(event) => {
                setfilters({
                  Search: event.target.value,
                  Search_By_Filter: filters.Search_By_Filter
                })
              }}
              className={classes.formControl}
              value={filters?.Search}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search Orders"
              variant="outlined"
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;