import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
  Chip,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { addCategoryAction, getAllStoretypes, uploadCategoryImage } from 'src/store/actions/corpcategoryAction';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
  images: {
    width: "100%",
    height: 200,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddCategory = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  //all store type names
  useEffect(() => {
    dispatch(getAllStoretypes());
  }, []);

  const [errorMessage, setErrorMessage] = useState({ global: { key: '', message: '' } });
  const isSubmitted = useSelector(state => state.category.categoryCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  //get store type names
  const storetypenames = useSelector(state => state.corpcategoy.storetypes.all);
  // Units management
  const [units, setUnits] = useState([]);
  const [validation, setValidation] = useState();
  const [inputValue, setInputValue] = useState('');

  const handleAddUnit = () => {
    const trimmedValue = inputValue.trim();
    let requiredError = !inputValue && units.length === 0 ? 'Available Units is required' : null;
    let spaceError = inputValue && !trimmedValue ? 'Available Units cannot contain only spaces' : null;
    setValidation({
      ...validation, Available_Capacity: requiredError || spaceError,
    });
    if (!requiredError && !spaceError && !units.includes(trimmedValue)) {
      setUnits([...units, trimmedValue]);
      setInputValue('');
    }
  };

  const handleDeleteUnit = (unitToDelete) => {
    setUnits(units.filter(unit => unit !== unitToDelete));
  };

  // img upload
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.path);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    e.preventDefault();
    const file = e.target.files[0]
    if (file) {
      setImage({
        preview: URL.createObjectURL(file),
        raw: file
      });
      const data = new FormData();
      data.append('file', file);
      dispatch(
        uploadCategoryImage(data, callBackUploadImgae)
      );
    }
  };
  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  const handleChangeAvailableUnits = (newValue) => {
    if (Array.isArray(newValue)) {
      const trimmedValues = newValue.map((value) => value.trim());
      const hasSpaceOnlyValue = trimmedValues.some((value) => !value);
      const hasNoValue = trimmedValues.length === 0;
      let requiredError = hasNoValue && units.length === 0 ? 'Available Units is required' : null;
      let spaceError = hasSpaceOnlyValue ? 'Available Units cannot contain only spaces' : null;
      setValidation({
        ...validation, Available_Capacity: requiredError || spaceError,
      });
      if (!requiredError && !spaceError) {
        setUnits(trimmedValues);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        Category_Name: '',
        Category_Units_Id: "",
        Available_Capacity: units,
        Created_By: loginEmail,
        Category_Image: imgUrl,
        SGST_Percentage: 0,
        CGST_Percentage: 0,
        IGST_Percentage: 0,
        VAT_Percentage: 0,
        GST_Percentage: 0,
      }}
      validationSchema={
        Yup.object().shape({
          Category_Name: Yup.string()
            .min(3, "Category Name must be at least 3 characters.")
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Category Name cannot start or end with a space and can have only single spaces in between')
            .required('Category Name is required.'),
          Category_Units_Id: Yup.string().required('Measurement unit is required.'),
          GST_Percentage: Yup.number().positive('GST Percentage must be positive.').min(1, "GST Percentage must be at least 1.").max(100, "GST Percentage must be less than 100.").required('GST Percentage is required.'),
          VAT_Percentage: Yup.number().positive('VAT Percentage must be positive.').min(0, 'VAT Percentage must be 0 or more.').max(100, "VAT Percentage must be less than 100."),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let formValuesdata = { ...values, Available_Capacity: units, Category_Image: imgUrl };
        let submitTest = true;
        setSubmitting(false);
        values.Category_Image = imgUrl;
        const errors = {};
        setErrors(errors);
        if (!values.Category_Image) {
          submitTest = false;
          errors.Category_Image = "Image is required.";
        }
        if (formValuesdata.Available_Capacity.length <= 0) {
          submitTest = false;
          errors.Available_Capacity = "Available Units is required.";
        }
        setValidation(errors)
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(formValuesdata));
          dispatch(addCategoryAction(formValues, navigate, setErrorMessage, setErrors));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.Category_Name && errors.Category_Name)}
                        fullWidth
                        helperText={touched.Category_Name && errors.Category_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Category Name"
                        name="Category_Name"
                        value={values.Category_Name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.Category_Units_Id && errors.Category_Units_Id)}
                        fullWidth
                        helperText={touched.Category_Units_Id && errors.Category_Units_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Measurement unit"
                        name="Category_Units_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Category_Units_Id}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {storetypenames?.sort((a, b) => a.Units?.localeCompare(b.Units))?.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.Units}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center">
                        <TextField
                          label="Available Units"
                          variant="outlined"
                          placeholder="Please type in the available units"
                          fullWidth
                          value={inputValue}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => {
                            setInputValue(e.target.value);
                            handleChangeAvailableUnits(e.target.value);
                          }}
                          inputProps={{ min: 0 }}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAddUnit}
                          style={{
                            height: '55px', borderRadius: ' 0px var(--borderRadius, 4px) var(--borderRadius, 4px) 0px',
                            background: 'var(--primary-main, #2196F3)'
                          }}
                        >
                          ADD
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center">
                        <Autocomplete
                          multiple
                          options={[]}
                          freeSolo
                          error={validation?.Available_Capacity}
                          fullWidth
                          label="Available Units"
                          value={units}
                          onChange={(event, newValue) => {
                            const trimmedValues = newValue.map((value) => value.trim());
                            const hasSpaceOnlyValue = trimmedValues.some((value) => !value);
                            let spaceError = hasSpaceOnlyValue ? 'Available Units cannot contain only spaces' : null;
                            setValidation({
                              ...validation, Available_Capacity: spaceError,
                            });
                            if (!spaceError) {
                              setUnits(trimmedValues);
                            }
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                key={index}
                                label={option}
                                deleteIcon={<CloseIcon style={{ color: '#2196F3', background: "#E6E6E6", fontSize: '16px', borderRadius: '50px' }} />}
                                {...getTagProps({ index })}
                                onDelete={() => handleDeleteUnit(option)}
                                style={{ margin: 2, color: 'white', background: "#2196F3" }}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(validation?.Available_Capacity)}
                              helperText={validation?.Available_Capacity}
                              fullWidth
                              variant="outlined"
                              label="Available Units"
                              style={{ flexGrow: 1 }}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Card style={{
                    border: `2px dashed blue`,
                    textAlign: 'center',
                    background: 'rgba(33, 150, 243, 0.08)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: '516px',
                    height: '270px',
                    minWidth: '360px'
                  }}>
                    <CardContent >
                      <Box display="flex" justifyContent="center"  >
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Brand Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>

                    </CardContent>
                  </Card><br />
                  {!imgUrl && validation?.Category_Image ? <span style={{ color: "red", display: "flex", justifyContent: "flex-end" }}>
                    {validation?.Category_Image}
                  </span> : null}
                  <div style={{ marginBottom: '5%' }}></div>
                </Grid>

                <Grid item md={12} xs={12}>
                  <Typography style={{
                    marginTop: '48px',
                    color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '157%', // or '21.98px' if you prefer the pixel value
                    letterSpacing: '0.1px',
                  }}>Taxes</Typography>
                  <Grid container spacing={3} style={{ marginTop: '5px' }}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.GST_Percentage && errors.GST_Percentage)}
                        fullWidth
                        helperText={touched.GST_Percentage && errors.GST_Percentage}
                        onBlur={handleBlur}
                        label="GST Percentage"
                        name="GST_Percentage"
                        value={values.GST_Percentage}
                        variant="outlined"
                        type="number"
                        onChange={(e) => {
                          const gstValue = e.target.value;
                          if (gstValue.includes('.')) {
                            const parts = gstValue.split('.');
                            if (parts[1]?.length > 2) {
                              gstValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
                            }
                          }
                          handleChange(e);
                          setFieldValue('GST_Percentage', gstValue);
                          setFieldValue('IGST_Percentage', gstValue);
                          setFieldValue('SGST_Percentage', (gstValue / 2).toFixed(2));
                          setFieldValue('CGST_Percentage', (gstValue / 2).toFixed(2));
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.VAT_Percentage && errors.VAT_Percentage)}
                        fullWidth
                        helperText={touched.VAT_Percentage && errors.VAT_Percentage}
                        onBlur={handleBlur}
                        label="VAT Percentage"
                        name="VAT_Percentage"
                        value={values.VAT_Percentage}
                        variant="outlined"
                        type="number"
                        onChange={(e) => {
                          let vatValue = e.target.value;
                          if (vatValue.includes('.')) {
                            const parts = vatValue.split('.');
                            if (parts[1]?.length > 2) {
                              vatValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
                            }
                          }
                          handleChange(e);
                          setFieldValue('VAT_Percentage', vatValue);
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="CGST Percentage"
                        name="CGST_Percentage"
                        value={values.CGST_Percentage}
                        variant="outlined"
                        type="number"
                        disabled
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="SGST Percentage"
                        name="SGST_Percentage"
                        value={values.SGST_Percentage}
                        variant="outlined"
                        type="number"
                        disabled
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="IGST Percentage"
                        name="IGST_Percentage"
                        value={values.IGST_Percentage}
                        variant="outlined"
                        type="number"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
              <Box display="flex" pl={2} >
                <span style={{ color: "red" }}>{errorMessage?.global?.message}</span>
              </Box>
              <Grid item xs={12}>
                {validation?.Available_Capacity ? <span style={{ color: "red", display: "flex", justifyContent: "flex-end" }}>
                  {validation?.Available_Capacity}
                </span> : !imgUrl && validation?.Category_Image ?
                  <span style={{ color: "red", display: "flex", justifyContent: "flex-end" }}>
                    {validation?.Category_Image}
                  </span> : null
                }
                <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
                  {isSubmitted ?
                    <CircularProgress />
                    :
                    <Button variant="contained" style={{ backgroundColor: "#2196F3", color: 'white' }} type="submit">
                      SAVE
                    </Button>
                  }
                </Box>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddCategory.propTypes = {
  className: PropTypes.string
};

export default AddCategory;