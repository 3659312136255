import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/refer-earn',
    createdAt: '',
    description: 'ESI Cashback Refer and Earn program in which all new users will get free Rs.50 ESI Cashback voucher as welcome bonus and if you referred any of your friend to download and make referral user purchase at ESI Cashback app you will earn Rs.10 per each referral.',
    media: '/static/Dashboard/refer_and_earn.jpg',
    title: 'Refer & Earn',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/newsletter',
    createdAt: '',
    description: 'News is information about current events. There’s a reason you’re posting news—make your point early and clearly. People tend to skim stories on the Web. When the news in the story is hard to find, readers may get frustrated and stop reading.',
    media: '/static/Dashboard/newletter.jpg',
    title: 'Newsletter',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/ads-management',
    createdAt: '',
    description: 'Advertising management is a planned managerial process designed to oversee and control the various advertising activities involved in a program to communicate with a firm target market and which is ultimately designed to influence the consumer purchase decisions.',
    media: '/static/Dashboard/Ads_Management.jpg',
    title: 'Ads Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/promotional-management',
    createdAt: '',
    description: 'Promotions refer to the entire set of activities, which communicate the product, brand or service to the user. The idea is to make people aware, attract and induce to buy the product, in preference over others. Sales promotion is a part of the overall promotion effort.',
    media: '/static/Dashboard/promotional_management.jpg',
    title: 'Promotional Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/popup-management',
    createdAt: '',
    description: 'Offering first-purchase discount codes while the user is shopping. Collecting customer feedback with survey pop-ups. Promoting webinars or new products.',
    media: '/static/Dashboard/popup_management.jpg',
    title: 'Popup Management',
    total: ''
  }
];