import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  updateGiftAction,
  getAllGiftvendorids,
  getAllUserimageids
} from './../../store/actions/giftAction';
import { Formik } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import PhoneInput from 'react-phone-input-2'

const purposeOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Birthday',
    label: 'Birthday'
  },
  {
    value: 'Anniversary',
    label: 'Anniversary'
  },
  {
    value: 'Festival',
    label: 'Festival'
  },
  {
    value: 'Occasion',
    label: 'Occasion'
  },
];

const typeOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Card',
    label: 'Card'
  },
  {
    value: 'Voucher',
    label: 'Voucher'
  },
];

const useStyles = makeStyles(() => ({
  images: {
    width: 120,
    height: 120,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  },
}));

const EditGift = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  let { state } = location;

  const [mobile, setMobile] = useState(state.Order_To_Moblie);

  // componentDidMount
  useEffect(() => {
    dispatch(getAllGiftvendorids(state.Gift_Card_Vendor_Id));
    dispatch(getAllUserimageids(state.Gift_Image_Id));
  }, []);

  const giftVendorid = useSelector(state => state.gift.giftVendorid.vendorid);
  const giftImageid = useSelector(state => state.gift.giftImageid.imageid);
  const isSubmitted = useSelector(state => state.gift.giftUpdate.isLoading);
  const globalError = useSelector(state => state.gift.giftUpdate.error);

  // Richtext editor
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Gift_Message || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Gift_Message);

  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);

  return (
    <Formik
      initialValues={{
        Gift_Voucher_Id: state.Gift_Voucher_Id ? state.Gift_Voucher_Id : "",
        Gift_Card_Vendor_Id: state.Gift_Card_Vendor_Id ? state.Gift_Card_Vendor_Id : "",
        Gift_Image_Id: state.Gift_Image_Id ? state.Gift_Image_Id : "",
        Voucher_Name: state.Voucher_Name ? state.Voucher_Name : "",
        Gift_Message: state.Gift_Message ? state.Gift_Message : "",
        Order_By_Email_Id: state.Order_By_Email_Id ? state.Order_By_Email_Id : "",
        Order_By_Name: state.Order_By_Name ? state.Order_By_Name : "",
        Order_To_Email_Id: state.Order_To_Email_Id ? state.Order_To_Email_Id : "",
        Order_To_Name: state.Order_To_Name ? state.Order_To_Name : "",
        Order_To_Moblie: state.Order_To_Moblie ? state.Order_To_Moblie : "",
        Is_Approved: state.Is_Approved > 0 ? true : false,
        Purpose: state.Purpose ? state.Purpose : "",
        Total_Value: state.Total_Value ? state.Total_Value : "",
        Type: state.Type ? state.Type : ""
      }}

      onSubmit={(values, { setErrors, setSubmitting }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};

        values.Gift_Message = rhDiscription
        setErrors(errors);

        if (submitTest)
          if (values) {
            let formValues = JSON.parse(JSON.stringify(values));
            if (values.Is_Approved) {
              formValues.Is_Approved = 1;
            } else {
              formValues.Is_Approved = 0;
            }
            dispatch(updateGiftAction(formValues, navigate));
          }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={5} xs={12}>

                  {/*Gift_Card_Vendor_Id */}
                  <TextField
                    error={Boolean(touched.Gift_Card_Vendor_Id && errors.Gift_Card_Vendor_Id)}
                    fullWidth
                    helperText={touched.Gift_Card_Vendor_Id && errors.Gift_Card_Vendor_Id}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Gift Card Vendor"
                    InputLabelProps={{ shrink: true }}
                    name="Gift_Card_Vendor_Id"
                    disabled
                    select
                    SelectProps={{ native: true }}
                    value={values.Gift_Card_Vendor_Id}
                    variant="outlined"
                  ><option key="" value="">--Please Select--</option>
                    {giftVendorid.map(option => (
                      <option key={option.Vendor_Id} value={option.Vendor_Id}>
                        {option.Vendor_Name}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                {/*Gift_Image_Id */}
                <Grid item md={5} xs={12}>
                  <TextField
                    error={Boolean(touched.Gift_Image_Id && errors.Gift_Image_Id)}
                    fullWidth
                    helperText={touched.Gift_Image_Id && errors.Gift_Image_Id}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Gift Image ID"
                    InputLabelProps={{ shrink: true }}
                    name="Gift_Image_Id"
                    select
                    disabled
                    SelectProps={{ native: true }}
                    value={values.Gift_Image_Id}
                    variant="outlined"
                  ><option key="" value="">--Please Select--</option>
                    {giftImageid.map(option => (
                      <option key={option.Gift_Image_Id} value={option.Gift_Image_Id}>
                        {option.Gift_Image_Id}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                {/* Voucher_Name */}
                <Grid item md={5} xs={12}>
                  <TextField
                    error={Boolean(touched.Voucher_Name && errors.Voucher_Name)}
                    fullWidth
                    helperText={touched.Voucher_Name && errors.Voucher_Name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Voucher Name"
                    name="Voucher_Name"
                    disabled
                    value={values.Voucher_Name}
                    variant="outlined"
                  />
                </Grid>

                {/* Purpose */}
                <Grid item md={5} xs={12}>
                  <TextField
                    error={Boolean(touched.Purpose && errors.Purpose)}
                    fullWidth
                    helperText={touched.Purpose && errors.Purpose}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Purpose"
                    name="Purpose"
                    select
                    disabled
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: true }}
                    value={values.Purpose}
                    variant="outlined"
                  >
                    {purposeOption.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={5} xs={12}>
                  {/*Order_By_Email_Id */}
                  <TextField
                    error={Boolean(touched.Order_By_Email_Id && errors.Order_By_Email_Id)}
                    fullWidth
                    helperText={touched.Order_By_Email_Id && errors.Order_By_Email_Id}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Order By Email ID"
                    InputLabelProps={{ shrink: true }}
                    name="Order_By_Email_Id"
                    disabled
                    value={values.Order_By_Email_Id}
                    variant="outlined"
                  />
                </Grid>

                {/* Order_By_Name */}
                <Grid item md={5} xs={12}>
                  <TextField
                    error={Boolean(touched.Order_By_Name && errors.Order_By_Name)}
                    fullWidth
                    helperText={touched.Order_By_Name && errors.Order_By_Name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Order By Name"
                    name="Order_By_Name"
                    disabled
                    value={values.Order_By_Name}
                    variant="outlined"
                  />
                </Grid>

                {/* Order_To_Email_Id */}
                <Grid item md={5} xs={12}>
                  <TextField
                    error={Boolean(touched.Order_To_Email_Id && errors.Order_To_Email_Id)}
                    fullWidth
                    helperText={touched.Order_To_Email_Id && errors.Order_To_Email_Id}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Order To Email ID"
                    name="Order_To_Email_Id"
                    disabled
                    value={values.Order_To_Email_Id}
                    variant="outlined"
                  />
                </Grid>

                {/* Order_To_Name */}
                <Grid item md={5} xs={12}>
                  <TextField
                    error={Boolean(touched.Order_To_Name && errors.Order_To_Name)}
                    fullWidth
                    helperText={touched.Order_To_Name && errors.Order_To_Name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Order To Name"
                    name="Order_To_Name"
                    disabled
                    value={values.Order_To_Name}
                    variant="outlined"
                  />
                </Grid>


                <Grid item md={5} xs={12}>
                  <TextField
                    error={Boolean(touched.Order_To_Moblie && errors.Order_To_Moblie)}
                    fullWidth
                    helperText={touched.Order_To_Moblie && errors.Order_To_Moblie}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Order To Moblie"
                    name="Order_To_Moblie"
                    disabled
                    value={values.Order_To_Moblie}
                    variant="outlined"
                  />
                </Grid>

                {/* Type */}
                <Grid item md={5} xs={12}>
                  <TextField
                    error={Boolean(touched.Type && errors.Type)}
                    fullWidth
                    helperText={touched.Type && errors.Type}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Gift Type"
                    name="Type"
                    select
                    disabled
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: true }}
                    value={values.Type}
                    variant="outlined"
                  >
                    {typeOption.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                {/*Total_Value */}
                <Grid item md={5} xs={12}>
                  <TextField
                    error={Boolean(touched.Total_Value && errors.Total_Value)}
                    fullWidth
                    helperText={touched.Total_Value && errors.Total_Value}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    label="Voucher Value"
                    name="Total_Value"
                    disabled
                    value={values.Total_Value}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={5} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onBlur={handleBlur}
                        checked={values.Is_Approved}
                        onChange={handleChange}
                        name="Is_Approved"
                        color="primary"
                      />
                    }
                    label="Is Approved"
                  />
                </Grid>

                {/* Gift_Message  */}
                <Grid item md={10} xs={12}>
                  <Box className={classes.description}>
                    Gift Message
                    <EditorContainer editorState={editorState} />
                  </Box>
                  <span style={{ color: "red" }}>
                    {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Gift_Message && errors.Gift_Message}
                  </span>
                </Grid>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                </Box>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{globalError}</span>
            </Box>

            <Box display="flex" p={2}>
              <Button
                disabled={isSubmitted}
                type="submit"
                style={{ backgroundColor: '#27B6CC' }}
                variant="contained"
              >
                {/* Save details */}
                <span style={{ color: 'white' }}>Save details</span>
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditGift.propTypes = {
  className: PropTypes.string
};

export default EditGift;