import {
  NEWSEVENTS_CREATE_REQUEST,
  NEWSEVENTS_CREATE_SUCCESS,
  NEWSEVENTS_CREATE_FAIL,
  NEWSEVENTS_UPDATE_REQUEST,
  NEWSEVENTS_UPDATE_SUCCESS,
  NEWSEVENTS_UPDATE_FAIL,
} from '../actions/newseventsAction'

const initialState = {
  newseventCreate: { newsevent: {}, error: '', isLoading: false },
  newseventUpdate: { newsevent: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NEWSEVENTS_CREATE_REQUEST:
      return { ...state, newseventCreate: { newsevent: {}, error: '', isLoading: true } };
    case NEWSEVENTS_CREATE_SUCCESS:
      return { ...state, newseventCreate: { newsevent: state.newseventCreate.newsevent, error: '', isLoading: false } };
    case NEWSEVENTS_CREATE_FAIL:
      return { ...state, newsevnentCreate: { newsevent: {}, error: action.payload, isLoading: false } };
    case NEWSEVENTS_UPDATE_REQUEST:
      return { ...state, newseventUpdate: { newsevent: {}, error: '', isLoading: true } };
    case NEWSEVENTS_UPDATE_SUCCESS:
      return { ...state, newseventUpdate: { newsevent: state.newseventUpdate.newsevent, error: '', isLoading: false } };
    case NEWSEVENTS_UPDATE_FAIL:
      return { ...state, newseventUpdate: { newsevent: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}