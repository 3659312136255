import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/reports/dashboard',
    description: 'A retail dashboard is a performance tool to visualize and report all important retail KPIs in one central interface and turn this collected data into actionable insights.',
    media: '/static/Dashboard/dashboard.jpg',
    title: 'Dashboard'
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/reports/orders',
    description: 'Order report can calculate and display the delay of sales orders, production and process orders, planned orders etc.,',
    media: '/static/Dashboard/budget.jpg',
    title: 'Budget'
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/reports/users',
    description: 'User report is a set of free, easy-to-use tools, that tell you who your users are, and what they want to achieve.',
    media: '/static/Dashboard/users.jpg',
    title: 'Users'
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/reports/partners',
    description: 'When done right, retail partnerships can result in additional exposure and revenue, and both parties end up happy with more customers and sales.',
    media: '/static/Dashboard/partners.jpg',
    title: 'Partners'
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/reports/brands',
    description: 'A Brand report card is a process, which may be used by the companies to periodically audit their brands strengths and weaknesses on certain relevant product characteristics and comparing these with the strong brands in the same product category.',
    media: '/static/Dashboard/brands.png',
    title: 'Brands'
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/reports/coupons',
    description: 'The Coupons report aggregates data from each coupon that is used during a specific date range. ',
    media: '/static/Dashboard/coupon.jpg',
    title: 'Coupons'
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/reports/deals',
    description: 'The Deals report aggregates data from each deal that is used during a specific date range.',
    media: '/static/Dashboard/Deal.jpg',
    title: 'Deals'
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/reports/products',
    description: 'The Product views report shows the products that have been viewed during a time interval or range of dates. The report includes the product name, price, and number of views.',
    media: '/static/Dashboard/products.jpg',
    title: 'Products'
  }
];