import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    makeStyles
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    }
}));

const headCells = [
    { id: 'Subscription_Package_ID', numeric: false, disablePadding: true, label: 'Subscription Package ID' },
    { id: 'Package_Name', numeric: false, disablePadding: false, label: 'Package Name' },
    { id: 'Package_Advance_Amount', numeric: false, disablePadding: false, label: 'Package Advance Amount' },
    { id: 'Is_Active', numeric: false, disablePadding: false, label: 'Is Active' },
];

const Results = ({
    className,
    allSubsPackages,
    page,
    setPage,
    limit,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    createSortHandler,
    allSubsPackagesNoFilter,
    ...rest
}) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [selectedSubsPackageIds, setSelectedSubsPackageIds] = useState([]);

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const handleClose = (value) => {
        setOpen(false);
    };

    const handleSelectAll = event => {
        let newSelectedSubsPackageIds;
        if (event.target.checked) {
            newSelectedSubsPackageIds = allSubsPackages.map(subspackages => subspackages.Subscription_Package_ID);
        } else {
            newSelectedSubsPackageIds = [];
        }
        setSelectedSubsPackageIds(newSelectedSubsPackageIds);
    };

    const handleSelectOne = (event, id) => {
        setSelectedSubsPackageIds(selectedSubsPackageIds.filter(record => {
            for (let i = 0; i < allSubsPackagesNoFilter.length; i++) {
                if (record == allSubsPackagesNoFilter[i].Subscription_Package_ID) {
                    return true;
                }
            }
            return false;
        }))
        const selectedIndex = selectedSubsPackageIds.indexOf(id);
        let newSelectedSubsPackageIds = [];
        if (selectedIndex === -1) {
            newSelectedSubsPackageIds = newSelectedSubsPackageIds.concat(selectedSubsPackageIds, id);
        } else if (selectedIndex === 0) {
            newSelectedSubsPackageIds = newSelectedSubsPackageIds.concat(
                selectedSubsPackageIds.slice(1)
            );
        } else if (selectedIndex === selectedSubsPackageIds.length - 1) {
            newSelectedSubsPackageIds = newSelectedSubsPackageIds.concat(
                selectedSubsPackageIds.slice(0, -1)
            );
        } else if (selectedIndex > 0) {
            newSelectedSubsPackageIds = newSelectedSubsPackageIds.concat(
                selectedSubsPackageIds.slice(0, selectedIndex),
                selectedSubsPackageIds.slice(selectedIndex + 1)
            );
        }
        setSelectedSubsPackageIds(newSelectedSubsPackageIds);
    };

    const deleteSelectedSubsPackageHandler = () => async e => {
        setOpen(true);
        setTitle("Alert!");
        setMessage("This feature is under development.");
    };

    const OpenPackageLocationHandler = SubsPackageData => e => {
        navigate('/app/subscription-package-locations', { state: SubsPackageData });
    };

    const editSubsPackageHandler = SubsPackageData => e => {
        navigate('/app/edit-subscription-package', { state: SubsPackageData });
    };

    const visibleSubsPackageHandler = SubsPackageData => e => {
        navigate("/app/view-subscription-package", { state: SubsPackageData });
    };

    function checkingPagination(array) {
        if (array.slice(page * limit, page * limit + limit).length <= 0) {
            setPage(page - 1);
        }
        else {
            return array.slice(page * limit, page * limit + limit);
        }
        return array;
    }

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedSubsPackageIds.filter(record => {
                                            for (let i = 0; i < allSubsPackagesNoFilter.length; i++) {
                                                if (record == allSubsPackagesNoFilter[i].Subscription_Package_ID) {
                                                    return true;
                                                }
                                            }
                                            return false;
                                        }).length === allSubsPackages.length}
                                        color="primary"
                                        indeterminate={
                                            selectedSubsPackageIds.filter(record => {
                                                for (let i = 0; i < allSubsPackagesNoFilter.length; i++) {
                                                    if (record == allSubsPackagesNoFilter[i].Subscription_Package_ID) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            }).length > 0 &&
                                            selectedSubsPackageIds.filter(record => {
                                                for (let i = 0; i < allSubsPackagesNoFilter.length; i++) {
                                                    if (record == allSubsPackagesNoFilter[i].Subscription_Package_ID) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            }).length < allSubsPackages.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell >Package Locations</TableCell>
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-3rem' }} >Actions
                                    {selectedSubsPackageIds.filter(record => {
                                        for (let i = 0; i < allSubsPackagesNoFilter.length; i++) {
                                            if (record == allSubsPackagesNoFilter[i].Subscription_Package_ID) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }).length > 0 && <Button disabled={selectedSubsPackageIds.filter(record => {
                                        for (let i = 0; i < allSubsPackagesNoFilter.length; i++) {
                                            if (record == allSubsPackagesNoFilter[i].Subscription_Package_ID) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }).length <= 0} onClick={deleteSelectedSubsPackageHandler()}>
                                            <DeleteSweepIcon
                                                style={{ color: '#ff7a7a', fontSize: 25 }}
                                            />
                                        </Button>}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allSubsPackages) &&
                                (limit > 0 ? checkingPagination(allSubsPackages) : allSubsPackages).map(subpackages => (
                                    <TableRow
                                        hover
                                        key={subpackages.Subscription_Package_ID}
                                        selected={
                                            selectedSubsPackageIds.indexOf(subpackages.Subscription_Package_ID) !== -1
                                        }
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={
                                                    selectedSubsPackageIds.indexOf(subpackages.Subscription_Package_ID) !== -1
                                                }
                                                onChange={event =>
                                                    handleSelectOne(event, subpackages.Subscription_Package_ID)
                                                }
                                                value="true"
                                            />
                                        </TableCell>
                                        <TableCell >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{subpackages.Subscription_Package_ID}</TableCell>
                                        <TableCell >{subpackages.Package_Name}</TableCell>
                                        <TableCell style={{ color: '#1665D8' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{subpackages.Package_Advance_Amount} </TableCell>
                                        <TableCell >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{subpackages.Is_Active}</TableCell>
                                        <TableCell >
                                            <Tooltip title="Package Locations">
                                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <Button>
                                                        <PersonPinCircleIcon
                                                            onClick={OpenPackageLocationHandler(subpackages)}
                                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#9EA0A5', fontSize: 25, }} />
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Subpackage Details">
                                                <Button
                                                    onClick={visibleSubsPackageHandler(subpackages)}
                                                    color="primary" >
                                                    <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                            </Tooltip>
                                            <Button
                                                onClick={editSubsPackageHandler(subpackages)}>
                                                <CreateIcon style={{ color: '#9EA0A5' }} />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allSubsPackages) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt=""/>
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <TablePagination
                component="div"
                count={allSubsPackages.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 20]}
            />
            <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    allSubsPackages: PropTypes.array.isRequired
};

export default Results;