
import config from './config';
import { toast } from 'react-toastify';
import { nodeGetApi,boomiAdminApi } from './commonAxios';


export const SERVICES_REQUEST = "SERVICES_REQUEST";
export const SERVICES_SUCCESS = "SERVICES_SUCCESS";
export const SERVICES_FAIL = "SERVICES_FAIL";
export const SERVICES_GET_REQUEST = "SERVICES_GET_REQUEST";
export const SERVICES_GET_SUCCESS = "SERVICES_GET_SUCCESS";
export const SERVICES_GET_FAIL = "SERVICES_GET_FAIL";

export const SERVICES_CREATE_REQUEST = "SERVICES_CREATE_REQUEST";
export const SERVICES_CREATE_SUCCESS = "SERVICES_CREATE_SUCCESS";
export const SERVICES_CREATE_FAIL = "SERVICES_CREATE_FAIL";

export const SERVICES_UPDATE_REQUEST = "SERVICES_UPDATE_REQUEST";
export const SERVICES_UPDATE_SUCCESS = "SERVICES_UPDATE_SUCCESS";
export const SERVICES_UPDATE_FAIL = "SERVICES_UPDATE_FAIL";

export const BUSSINESS_SERVICES_REQUEST = "BUSSINESS_SERVICES_REQUEST";
export const BUSSINESS_SERVICES_SUCCESS = "BUSSINESS_SERVICES_SUCCESS";
export const BUSSINESS_SERVICES_FAIL = "BUSSINESS_SERVICES_FAIL";

export const BUSSINESS_SUBTYPE_REQUEST = "BUSSINESS_SUBTYPE_REQUEST";
export const BUSSINESS_SUBTYPE_SUCCESS = "BUSSINESS_SUBTYPE_SUCCESS";
export const BUSSINESS_SUBTYPE_FAIL = "BUSSINESS_SUBTYPE_FAIL";
export const PARTNER_SERVICES_REQUEST = "PARTNER_SERVICES_REQUEST";
export const PARTNER_SERVICES_SUCCESS = "PARTNER_SERVICES_SUCCESS";
export const PARTNER_SERVICES_FAIL = "PARTNER_SERVICES_FAIL";
export const PARTNERS_SERVICE_REQUEST = "PARTNERS_SERVICE_REQUEST";
export const PARTNERS_SERVICE_SUCCESS = "PARTNERS_SERVICE_SUCCESS";
export const PARTNERS_SERVICE_FAIL = "PARTNERS_SERVICE_FAIL";
export const USER_SERVICE_USERPAY_REQUEST = "USER_SERVICE_USERPAY_REQUEST";
export const USER_SERVICE_USERPAY_SUCCESS = "USER_SERVICE_USERPAY_SUCCESS";
export const USER_SERVICE_USERPAY_FAIL = "USER_SERVICE_USERPAY_FAIL";
export const USER_SERVICE_PARTNERPAY_REQUEST = "USER_SERVICE_PARTNERPAY_REQUEST";
export const USER_SERVICE_PARTNERPAY_SUCCESS = "USER_SERVICE_PARTNERPAY_SUCCESS";
export const USER_SERVICE_PARTNERPAY_FAIL = "USER_SERVICE_PARTNERPAY_FAIL";
export const SERVICES_FILTER = "SERVICES_FILTER";
export const ADDRESS_REQUEST = "ADDRESS_REQUEST";
export const ADDRESS_SUCCESS = "ADDRESS_SUCCESS";
export const ADDRESS_FAIL = "ADDRESS_FAIL";
export const EMPLOYEE_SERVICES_REQUEST = "EMPLOYEE_SERVICES_REQUEST";
export const EMPLOYEE_SERVICES_SUCCESS = "EMPLOYEE_SERVICES_SUCCESS";
export const EMPLOYEE_SERVICES_FAIL = "EMPLOYEE_SERVICES_FAIL";
export const USER_SERVICE_EMAILS_REQUEST = "USER_SERVICE_EMAILS_REQUEST";
export const USER_SERVICE_EMAILS_SUCCESS = "USER_SERVICE_EMAILS_SUCCESS";
export const USER_SERVICE_EMAILS_FAIL = "USER_SERVICE_EMAILS_FAIL";


/*=============================================================
                  Update Service Action
===============================================================*/
export const updateServiceAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: SERVICES_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Super_Admin_User_Service_Request/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICES_UPDATE_SUCCESS
        });
        toast('User Service updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/services-management');
      } else {

        dispatch({
          type: SERVICES_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: SERVICES_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

export const updatepaymrentServiceAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: SERVICES_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Super_Admin_User_Service_Request/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICES_UPDATE_SUCCESS
        });
        toast('User Service updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/edit-service', state = { state });
      } else {

        dispatch({
          type: SERVICES_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: SERVICES_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                  Add Service Action
===============================================================*/
export const addServiceAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: SERVICES_CREATE_REQUEST
  });
  boomiAdminApi.post(`/User_Service/Super_Admin_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICES_CREATE_SUCCESS
        });
        toast('User Service added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/services-management');
      } else {
        dispatch({
          type: SERVICES_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: SERVICES_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

export const getbussinessubtypeAction = (formData, callBacksubtypeData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Partner_Registration/Get`,
      formData
    );
    if (data) {
      callBacksubtypeData(data.results)
    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};
/*=============================================================
               Get All Bussiness subtype Action
===============================================================*/
export const getAllBussinessSubtype = formData => async dispatch => {
  try {
    dispatch({
      type: BUSSINESS_SUBTYPE_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Super_Admin_Partner_Business_Type/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_BUSINESS_TYPE_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BUSSINESS_SUBTYPE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BUSSINESS_SUBTYPE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again", 3000);
    dispatch({
      type: BUSSINESS_SUBTYPE_FAIL,
      payload: err
    });
  }
};

/*=============================================================
               Get All Services Action
===============================================================*/
export const getAllServices = () => async dispatch => {
  try {
    let formData = {
      search_by_filter: "",
      search: "",
      Records_Filter: "ALL",
    };

    dispatch({
      type: BUSSINESS_SERVICES_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partner_Service/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_SERVICE_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BUSSINESS_SERVICES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BUSSINESS_SERVICES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again", 3000);
    dispatch({
      type: BUSSINESS_SERVICES_FAIL,
      payload: err
    });
  }
};

/*=============================================================
               Get All Services Action
===============================================================*/
export const getAllPartnerServices = (Partner_Detail_Id, Service_Type) => async dispatch => {
  try {
    let formData = {
      Partner_Detail_Id: Partner_Detail_Id,
      Records_Filter: "FILTER",
      Service_Type: Service_Type
    };
    dispatch({
      type: PARTNER_SERVICES_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partner_Service_Super_Admin_Get/Partner_Service_Get`,
      formData
    );
    if (data) {
      if (data.Success_Response?.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNER_SERVICES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNER_SERVICES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PARTNER_SERVICES_FAIL,
      payload: err
    });
  }
};


export const getAllPartnerDetails = () => async dispatch => {
  try {
    let formData = {
      "ExtraCashBack": "",
      "Name": "",
      "Partner_Details_Id": "",
      "Records_Filter": "FILTER",
      "Status": "",
      "Top_Stores": "",
      "Buisness_Type": "SERVICES"
    };
    dispatch({
      type: PARTNERS_SERVICE_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partners/Physical_Partner_Profile_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.successResponse?.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNERS_SERVICE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNERS_SERVICE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PARTNERS_SERVICE_FAIL,
      payload: err
    });
  }
};

export const getAllUserAddress = (formData, callBacksubtypeData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/User/Address_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=User_Address_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === "0") {
        callBacksubtypeData([])
      } else {
        callBacksubtypeData(data.results)
      }
    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};

//Get User Service Action
export const getUserServiceAction = (formData, callBackUserServiceData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Super_Admin_User_Service_Request/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=User_Service_request_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBackUserServiceData(data)
    }
  } catch (err) {
  }
};

//UserPayments User Service Action
export const UserServicesUserPayAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: USER_SERVICE_USERPAY_REQUEST
  });
  boomiAdminApi.put(`/Super_Admin_User_Service_Request/Service_Return_Payment_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USER_SERVICE_USERPAY_SUCCESS
        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/services-management');
      } else {
        dispatch({
          type: USER_SERVICE_USERPAY_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USER_SERVICE_USERPAY_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

//PartnerPayments PArtner Service Action
export const UserServicesPartnerPayAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: USER_SERVICE_PARTNERPAY_REQUEST
  });
  boomiAdminApi.put(`/Super_Admin_User_Service_Request/Partner_Payments`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USER_SERVICE_PARTNERPAY_SUCCESS
        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/services-management');
      } else {
        dispatch({
          type: USER_SERVICE_PARTNERPAY_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USER_SERVICE_PARTNERPAY_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};


export const getAllUserAddressServices = (formData, callBacksubtypeservicesData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Super_Admin_User_Service_Request/Address_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=User_Address_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data?.Success_Response?.Is_Data_Exist === "0") {
        callBacksubtypeservicesData([])
      } else {
        callBacksubtypeservicesData(data.results)
      }
    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};


export const getAllServiceAdd = formData => async dispatch => {
  try {
    dispatch({
      type: ADDRESS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Super_Admin_User_Service_Request/Address_Get`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ADDRESS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: ADDRESS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: ADDRESS_FAIL,
      payload: err
    });
  }
};

export const getAllEmployeeServicesAction = (Partner_Details_Id) => async dispatch => {
  try {
    let formData = {
      Partner_Details_Id: Partner_Details_Id,
      Records_Filter: "FILTER",
    };
    dispatch({
      type: EMPLOYEE_SERVICES_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Super_Admin_Partner_Employee/Get`,
      formData
     
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: EMPLOYEE_SERVICES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({

          type: EMPLOYEE_SERVICES_SUCCESS,
          payload: data.results

        });
      }
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_SERVICES_FAIL,
      payload: err
    });
  }
};

//get user mails
export const getServiceEmailUserAction = (emailid) => async dispatch => {
  try {
    let formData = {
      Email_Id: emailid,
      Records_Filter: "FILTER"
    };
    dispatch({
      type: USER_SERVICE_EMAILS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Super_Admin/User_Get_dropdown_search?PageNo=1&PageSize=${config.pageSize}&SortBy=Email_Id&SortOrder=ASC`,
      formData
      
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: USER_SERVICE_EMAILS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: USER_SERVICE_EMAILS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: USER_SERVICE_EMAILS_FAIL,
      payload: err
    });
  }
};