import {
THEME_CREATE_REQUEST,
THEME_CREATE_SUCCESS,
THEME_CREATE_FAIL,
THEME_UPDATE_REQUEST,
THEME_UPDATE_SUCCESS,
THEME_UPDATE_FAIL,
} from '../actions/meepaisathemeAction';


const initialState = {
    themeCreate: { theme: {}, error: '', isLoading: false },
    themeUpdate: { theme: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case THEME_CREATE_REQUEST:
            return { ...state, themeCreate: { theme: {}, error: '', isLoading: true } };
        case THEME_CREATE_SUCCESS:
            return { ...state, themeCreate: { theme: state.themeCreate.theme, error: '', isLoading: false } };
        case THEME_CREATE_FAIL:
            return { ...state, themeCreate: { theme: {}, error: action.payload, isLoading: false } };
        case THEME_UPDATE_REQUEST:
            return { ...state, themeUpdate: { theme: {}, error: '', isLoading: true } };
        case THEME_UPDATE_SUCCESS:
            return { ...state, themeUpdate: { theme: action.payload, error: '', isLoading: false } };
        case THEME_UPDATE_FAIL:
            return { ...state, themeUpdate: { theme: {}, error: action.payload, isLoading: false } };
        
        default:
            return state;
    }
}