import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Box,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { createpartnervehicleAction } from 'src/store/actions/partnervehicledetailsAction';
import ESICountry from 'src/components/country-state-city/country';
import ESIState from 'src/components/country-state-city/state';
import ESICity from 'src/components/country-state-city/city';
import { CircularProgress } from '@mui/material';
import { getAllUserRideFaresAction } from 'src/store/actions/userridefaresAction';
import { getAllRidesVehiTypesAction } from 'src/store/actions/ridevehicletypesAction';

const AddPartnerVehicleDetails = ({ className, state, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //get all vehicle fares
    useEffect(() => {
        const formData = {
            search_by_filter: "",
            search: "",
            Record_Filter: 'FILTER',
            Status: 'Active'
        };
        const formData1 = {
            Record_Filter: 'ALL',
            search_by_filter: "",
            search: "",
        };
        dispatch(getAllUserRideFaresAction(formData));
        dispatch(getAllRidesVehiTypesAction(formData1));
    }, []);

    const isSubmited = useSelector(state => state.partnervehicledetail.partnervehicledetailCreate.isLoading);
    const errorMessage = useSelector(state => state.partnervehicledetail.partnervehicledetailCreate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const userridefares = useSelector(state => state.userrideFares.userrideFares.all);
    const ridevehitypes = useSelector(state => state?.ridevehicletypes?.ridevehitypes?.all);

    return (
        <Formik
            initialValues={{
                Del_User_Email_ID: state?.Del_User_Email_Id,
                State: '',
                Country: '',
                Vehicle_Number: '',
                Vehicle_Availability_City_Id: '',
                Type: '',
                Vehicle_Color: '',
                Vehicle_Seating_Capacity: '',
                User_Ride_Vehicle_Fare_Id: '',
                Is_Active_Vehicle: false,
                Created_By: loginEmail
            }}
            validationSchema={
                Yup.object().shape({
                    Del_User_Email_ID: Yup.string().required('Delivery User Email ID is required.'),
                    Type: Yup.string().required('Vehicle Type is required.'),
                    Vehicle_Color: Yup.string().required('Vehicle Color is required.'),
                    Vehicle_Seating_Capacity: Yup.number().min(1, "Seating Capacity must be at least 1.").max(50, "Seating Capacity must be less than 50.").required('Vehicle Seating Capacity is required.'),
                    Country: Yup.string().required('Country is required.'),
                    State: Yup.string().required('State is required.'),
                    Vehicle_Number: Yup.string().required('Vehicle Number is required.'),
                    User_Ride_Vehicle_Fare_Id: Yup.string().required('User Ride Vehicle Fare is required.'),
                })
            }
            onSubmit={(values) => {
                let formValues = JSON.parse(JSON.stringify(values));
                if (values.Is_Active_Vehicle) {
                    formValues.Is_Active_Vehicle = 1;
                } else {
                    formValues.Is_Active_Vehicle = 0;
                }
                dispatch(createpartnervehicleAction(formValues, navigate, state));
            }
            }
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        {/*Del_User_Mail_Id */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Del_User_Email_ID && errors.Del_User_Email_ID)}
                                                fullWidth
                                                helperText={touched.Del_User_Email_ID && errors.Del_User_Email_ID}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Del User Email ID"
                                                name="Del_User_Email_ID"
                                                value={values.Del_User_Email_ID}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        {/* Country Field */}
                                        <Grid item md={6} xs={12}>
                                            <ESICountry
                                                error={Boolean(touched.Country && errors.Country)}
                                                fullWidth
                                                helperText={touched.Country && errors.Country}
                                                placeholder="Country"
                                                label="Country"
                                                name="Country"
                                                onChange={handleChange}
                                                select
                                                SelectProps={{ native: true }}
                                                value={values.Country}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>

                                        {/* State field */}
                                        <Grid item md={6} xs={12}>
                                            <ESIState
                                                error={Boolean(touched.State && errors.State)}
                                                fullWidth
                                                helperText={touched.State && errors.State}
                                                label="State"
                                                name="State"
                                                onChange={handleChange}
                                                value={values.State}
                                                countrycode={values.Country}
                                                select
                                                SelectProps={{ native: true }}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>

                                        {/*City */}
                                        <Grid item md={6} xs={12}>
                                            <ESICity
                                                error={Boolean(touched.Vehicle_Availability_City_Id && errors.Vehicle_Availability_City_Id)}
                                                fullWidth
                                                helperText={touched.Vehicle_Availability_City_Id && errors.Vehicle_Availability_City_Id}
                                                label="City"
                                                name="Vehicle_Availability_City_Id"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.Vehicle_Availability_City_Id}
                                                countrycode={values.Country}
                                                statecode={values.State}
                                                select
                                                SelectProps={{ native: true }}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.User_Ride_Vehicle_Fare_Id && errors.User_Ride_Vehicle_Fare_Id)}
                                                fullWidth
                                                helperText={touched.User_Ride_Vehicle_Fare_Id && errors.User_Ride_Vehicle_Fare_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="User Ride Vehicle Fare"
                                                name="User_Ride_Vehicle_Fare_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.User_Ride_Vehicle_Fare_Id}
                                                variant="outlined"
                                            ><option key="" value="">--Please Select--</option>
                                                {userridefares?.sort((a, b) => a.Store_Type_Name?.localeCompare(b.Store_Type_Name))?.map(option => (
                                                    <option key={option.User_Ride_Vehicle_Fare_Id} value={option.User_Ride_Vehicle_Fare_Id}>
                                                        {option.Vehicle_Type + " - " + option.Vehicle_Comfort_Type + " - " + option.City_Name + " - " + option.Minimum_Fare}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {/*Vehicle_Type */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Type && errors.Type)}
                                                fullWidth
                                                helperText={touched.Type && errors.Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Vehicle Type"
                                                name="Type"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Type}
                                                variant="outlined"
                                            ><option key="" value="">--Please Select--</option>
                                                {ridevehitypes.map(option => (
                                                    <option key={option.Ride_Vehicle_Type_Id} value={option.Vehicle_Type}>
                                                        {option.Vehicle_Type + " - " + option.Vehicle_Comfort_Type}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {/*Vehicle Number */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Vehicle_Number && errors.Vehicle_Number)}
                                                fullWidth
                                                helperText={touched.Vehicle_Number && errors.Vehicle_Number}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Vehicle Number"
                                                name="Vehicle_Number"
                                                value={values.Vehicle_Number}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        {/*Vehicle_Color */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Vehicle_Color && errors.Vehicle_Color)}
                                                fullWidth
                                                helperText={touched.Vehicle_Color && errors.Vehicle_Color}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Vehicle Color"
                                                name="Vehicle_Color"
                                                value={values.Vehicle_Color}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        {/*Vehicle_Seating_Capacity */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Vehicle_Seating_Capacity && errors.Vehicle_Seating_Capacity)}
                                                fullWidth
                                                helperText={touched.Vehicle_Seating_Capacity && errors.Vehicle_Seating_Capacity}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Vehicle Seating Capacity"
                                                name="Vehicle_Seating_Capacity"
                                                value={values.Vehicle_Seating_Capacity}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        {/* Is Active*/}
                                        <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.Is_Active_Vehicle}
                                                        onChange={handleChange}
                                                        name="Is_Active_Vehicle"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Active Vehicle"
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>

                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddPartnerVehicleDetails.propTypes = {
    className: PropTypes.string
};

export default AddPartnerVehicleDetails;