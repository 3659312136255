
import {
  BIDDING_PRODUCT_CREATE_REQUEST,
  BIDDING_PRODUCT_CREATE_SUCCESS,
  BIDDING_PRODUCT_CREATE_FAIL,
  BIDDING_PRODUCT_UPDATE_REQUEST,
  BIDDING_PRODUCT_UPDATE_SUCCESS,
  BIDDING_PRODUCT_UPDATE_FAIL,
  BIDDING_PRODUCT_CATEGORIES_REQUEST,
  BIDDING_PRODUCT_CATEGORIES_SUCCESS,
  BIDDING_PRODUCT_CATEGORIES_FAIL,
} from '../actions/biddingproductsAction'

const initialState = {
  biddingproductCreate: { biddingproducts: {}, error: '', isLoading: false },
  biddingproductUpdate: { biddingproducts: {}, error: '', isLoading: false },
  BidProductCategories: { categories: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BIDDING_PRODUCT_CREATE_REQUEST:
      return { ...state, biddingproductCreate: { biddingproducts: {}, error: '', isLoading: true } };
    case BIDDING_PRODUCT_CREATE_SUCCESS:
      return { ...state, biddingproductCreate: { biddingproducts: state.biddingproductCreate.biddingproducts, error: '', isLoading: false } };
    case BIDDING_PRODUCT_CREATE_FAIL:
      return { ...state, biddingproductCreate: { biddingproducts: {}, error: action.payload, isLoading: false } };
    case BIDDING_PRODUCT_UPDATE_REQUEST:
      return { ...state, biddingproductUpdate: { biddingproducts: {}, error: '', isLoading: true } };
    case BIDDING_PRODUCT_UPDATE_SUCCESS:
      return { ...state, biddingproductUpdate: { biddingproducts: action.payload, error: '', isLoading: false } };
    case BIDDING_PRODUCT_UPDATE_FAIL:
      return { ...state, biddingproductUpdate: { biddingproducts: {}, error: action.payload, isLoading: false } };
    case BIDDING_PRODUCT_CATEGORIES_REQUEST:
      return { ...state, BidProductCategories: { categories: state.BidProductCategories.categories, error: '', isLoading: true }, };
    case BIDDING_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, BidProductCategories: { categories: action.payload, error: '', isLoading: false } };
    case BIDDING_PRODUCT_CATEGORIES_FAIL:
      return { ...state, BidProductCategories: { categories: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}