import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SimpleDialog from '../../components/dialogs/dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import isEmpty from '../../store/validations/is-empty';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Sub_Category_Id', numeric: false, disablePadding: true, label: 'SubCategory ID' },
  { id: 'Sub_Category_Name', numeric: false, disablePadding: false, label: 'SubCategory Name' },
  { id: 'Category_Name', numeric: false, disablePadding: false, label: 'Category Name' },
  { id: 'Description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allSubCategory,
  deleteSubCategoryHandler,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allSubCategoryNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedSubCategoryIds, setSelectedSubCategoryIds] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const handleClose = (value) => {
    setOpen(false);
  };

  /*===============================
           handler     
=================================*/
  const handleSelectAll = event => {
    let newSelectedSubCategoryIds;
    if (event.target.checked) {
      newSelectedSubCategoryIds = allSubCategory.map(subcategory => subcategory.Sub_Category_Id);
    } else {
      newSelectedSubCategoryIds = [];
    }
    setSelectedSubCategoryIds(newSelectedSubCategoryIds);
  };

  const handleSelectOne = (event, id) => {
    setSelectedSubCategoryIds(selectedSubCategoryIds.filter(record => {
      for (let i = 0; i < allSubCategoryNoFilter.length; i++) {
        if (record == allSubCategoryNoFilter[i].Sub_Category_Id) {
          return true;
        }
      }
      return false;
    }))
    const selectedIndex = selectedSubCategoryIds.indexOf(id);
    let newSelectedSubCategoryIds = [];
    if (selectedIndex === -1) {
      newSelectedSubCategoryIds = newSelectedSubCategoryIds.concat(
        selectedSubCategoryIds, id);
    } else if (selectedIndex === 0) {
      newSelectedSubCategoryIds = newSelectedSubCategoryIds.concat(
        selectedSubCategoryIds.slice(1)

      );
    } else if (selectedIndex === selectedSubCategoryIds.length - 1) {
      newSelectedSubCategoryIds = newSelectedSubCategoryIds.concat(
        selectedSubCategoryIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedSubCategoryIds = newSelectedSubCategoryIds.concat(
        selectedSubCategoryIds.slice(0, selectedIndex),
        selectedSubCategoryIds.slice(selectedIndex + 1),
      );
    }
    setSelectedSubCategoryIds(newSelectedSubCategoryIds);
  };

  const deleteSelectedSubCategoryHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const editSubCategoryHandler = subcategoryData => e => {
    navigate('/app/edit-subcategory', { state: subcategoryData });
  };
  const visibleSubcategoryHandler = subcategoryData => e => {
    navigate('/app/subcategory-details', { state: subcategoryData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedSubCategoryIds.filter(record => {
                      for (let i = 0; i < allSubCategoryNoFilter.length; i++) {
                        if (record == allSubCategoryNoFilter[i].Sub_Category_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allSubCategory.length}
                    color="primary"
                    indeterminate={
                      selectedSubCategoryIds.filter(record => {
                        for (let i = 0; i < allSubCategoryNoFilter.length; i++) {
                          if (record == allSubCategoryNoFilter[i].Sub_Category_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedSubCategoryIds.filter(record => {
                        for (let i = 0; i < allSubCategoryNoFilter.length; i++) {
                          if (record == allSubCategoryNoFilter[i].Sub_Category_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allSubCategory.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.id !== "Description" ? (
                      <TableSortLabel
                        className={classes.headerColumn}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>) : (<span style={{ fontSize: "13px", fontWeight: "bold" }} > {headCell.label}</span>)
                    }
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} > Actions&nbsp;&nbsp;&nbsp;&nbsp;

                  {selectedSubCategoryIds.filter(record => {
                    for (let i = 0; i < allSubCategoryNoFilter.length; i++) {
                      if (record == allSubCategoryNoFilter[i].Sub_Category_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedSubCategoryIds.filter(record => {
                    for (let i = 0; i < allSubCategoryNoFilter.length; i++) {
                      if (record == allSubCategoryNoFilter[i].Sub_Category_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedSubCategoryHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allSubCategory) &&
                (allSubCategory).map(subcategory => (
                  <TableRow
                    hover
                    key={subcategory.Sub_Category_Id}
                    selected={
                      selectedSubCategoryIds.indexOf(subcategory.Sub_Category_Id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedSubCategoryIds.indexOf(subcategory.Sub_Category_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, subcategory.Sub_Category_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell width="16%">{subcategory.Sub_Category_Id}</TableCell>
                    <TableCell width="16%">{subcategory.Sub_Category_Name}</TableCell>
                    <TableCell width="16%">{subcategory.Category_Name}</TableCell>
                    <TableCell width="15%" ><div style={{ maxHeight: "40px", overflowY: "hidden", lor: '#1665D8' }} dangerouslySetInnerHTML={{ __html: subcategory.Description }}></div>
                    </TableCell>
                    <TableCell style={{ width: "10%", color: '#1665D8' }}>
                      {subcategory.Status}
                    </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.SubCategory_Management_Write?.Value === "SubCategory_Management_Write" && RolePermissions?.SubCategory_Management_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Button
                          onClick={editSubCategoryHandler(subcategory)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                          <DeleteIcon
                            onClick={deleteSubCategoryHandler(subcategory)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                        <Button
                          onClick={visibleSubcategoryHandler(subcategory)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.SubCategory_Management_Read?.Value === "SubCategory_Management_Read" && RolePermissions?.SubCategory_Management_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Button
                          onClick={visibleSubcategoryHandler(subcategory)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allSubCategory) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allSubCategory: PropTypes.array.isRequired
};

export default Results;