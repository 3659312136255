import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  makeStyles,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  images: {
    width: 70,
    height: 70,
    cursor: 'pointer'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));

const headCells = [
  { id: 'Subscription_User_Products_Id', numeric: false, disablePadding: false, label: 'Subscription User Product ID' },
  { id: 'Product_Id', numeric: false, disablePadding: false, label: 'Product ID' },
  { id: 'Partner_Details_Id', numeric: false, disablePadding: false, label: 'Partner Details ID' },
  { id: 'Partner_Product_Id', numeric: true, disablePadding: false, label: 'Partner Product ID' },
  { id: 'Quantity', numeric: false, disablePadding: false, label: 'Quantity' },
];

const Results = ({
  className,
  allSubscriptionuserproducts,
  page,
  limit,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  state,
  setPage,
  allSubscriptionuserproductsNoFilter,
  createSortHandler,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedSubscriptionUserIds, setSelectedSubscriptionUserIds] = useState([]);

  const visibleUserSubscriptionHandler = subscriptionuserproductData => e => {
    navigate("/app/view-subscriptionuser", { state: { state: state, data: subscriptionuserproductData } });
  };
  const editUserSubscriptionHandler = subscriptionuserproductData => e => {
    navigate("/app/edit-subscriptionuser", { state: { state: state, data: subscriptionuserproductData } });
  };

  // const userProductSubscriptionHandler = subscriptionuserData => e => {
  //   navigate("/app/view-subscriptionuser", { state: subscriptionuserData });
  // };

  // pagination
  function checkingPagination(array) {
    if (array.slice(page * limit, page * limit + limit).length <= 0) {
      setPage(page - 1);
    }
    else {
      return array.slice(page * limit, page * limit + limit);
    }
    return array;
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.id !== "Profile_Pic" ? (
                      <TableSortLabel style={{ fontSize: "13px", fontWeight: "bold" }}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>) : (<span style={{ fontSize: "13px", fontWeight: "bold" }} > {headCell.label}</span>)
                    }
                  </TableCell>
                ))}
                <TableCell
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className={classes.headerColumn}>Actions &nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allSubscriptionuserproducts) &&
                (limit > 0 ? checkingPagination(allSubscriptionuserproducts) : allSubscriptionuserproducts).map(subscriptionproduct => (
                  <TableRow
                    hover
                    key={subscriptionproduct.Subscription_User_Products_Id}
                  >
                    <TableCell >{subscriptionproduct.Subscription_User_Products_Id}</TableCell>
                    {/* <TableCell style={{ color: '#1665D8' }}>
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href={subscriptionproduct.User_Email_Id}
                      >
                        {subscriptionproduct.User_Email_Id?.substring(0, 20)}...

                      </Link>
                    </TableCell> */}
                    <TableCell>{subscriptionproduct.Product_Id}</TableCell>
                    <TableCell>{subscriptionproduct.Partner_Details_Id}</TableCell>
                    <TableCell>{subscriptionproduct.Partner_Product_Id}</TableCell>
                    <TableCell>{subscriptionproduct.Quantity}</TableCell>
                    <TableCell>
                      <Tooltip title="View Service History">
                        <Button
                          onClick={visibleUserSubscriptionHandler(subscriptionproduct)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </Tooltip>
                      <Button
                        onClick={editUserSubscriptionHandler(subscriptionproduct)}
                      >
                        <CreateIcon style={{ color: '#9EA0A5' }} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allSubscriptionuserproducts) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={allSubscriptionuserproducts.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 20]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allSubscriptionuserproducts: PropTypes.array.isRequired
};

export default Results;