
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createProductColorVariantAction,
} from '../../../../store/actions/productcolorvariantAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const AddProductColorVariant = ({ className, state, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSubmited = useSelector(state => state.productcolorvariant.productcolorvariantCreate.isLoading);
  const errorMessage = useSelector(state => state.productcolorvariant.productcolorvariantCreate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        Product_Master_Id: state.Product_Master_Id,
        Color: '',
        Created_By: loginEmail,
        Status:''
      }}
      validationSchema={
        Yup.object().shape({
          Color: Yup.string().required('Color is required.'),
          Status: Yup.string().required('Status is required.'),
        })
      }
      onSubmit={(values) => {
        if (values) {
          let formData = JSON.parse(JSON.stringify(values));
          dispatch(createProductColorVariantAction(formData, state, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Color && errors.Color)}
                        fullWidth
                        helperText={touched.Color && errors.Color}
                        onBlur={handleBlur}
                        label="Color"
                        name="Color"
                        onChange={handleChange}
                        value={values.Color}
                        variant="outlined"
                      />
                    </Grid>
                      {/* Status */}
                      <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" p={2}>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddProductColorVariant.propTypes = {
  className: PropTypes.string
};

export default AddProductColorVariant;