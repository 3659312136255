import {
    CONTENTMANAGEMENTS_STATIC_REQUEST,
    CONTENTMANAGEMENTS_STATIC_SUCCESS,
    CONTENTMANAGEMENTS_STATIC_FAIL,
    CONTENTMANAGEMENT_STATIC_GET_REQUEST,
    CONTENTMANAGEMENT_STATIC_GET_SUCCESS,
    CONTENTMANAGEMENT_STATIC_GET_FAIL,
    CONTENTMANAGEMENT_STATIC_CREATE_REQUEST,
    CONTENTMANAGEMENT_STATIC_CREATE_SUCCESS,
    CONTENTMANAGEMENT_STATIC_CREATE_FAIL,
    CONTENTMANAGEMENT_STATIC_UPDATE_REQUEST,
    CONTENTMANAGEMENT_STATIC_UPDATE_SUCCESS,
    CONTENTMANAGEMENT_STATIC_UPDATE_FAIL,
} from '../actions/privacypolicyTermsconditionsAction'

const initialState = {
    PrivacyPoliciesTermsConditions: { all: [], error: '', isLoading: false },
    privacypolicytermscondition: { privacypolicytermscondition: {}, error: '', isLoading: false },
    privacypolicytermsconditionCreate: { privacypolicytermscondition: {}, error: '', isLoading: false },
    privacypolicytermsconditionUpdate: { privacypolicytermscondition: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CONTENTMANAGEMENTS_STATIC_REQUEST:
            return { ...state, PrivacyPoliciesTermsConditions: { all: state.PrivacyPoliciesTermsConditions.all, error: '', isLoading: true } };
        case CONTENTMANAGEMENTS_STATIC_SUCCESS:
            return { ...state, PrivacyPoliciesTermsConditions: { all: action.payload, error: '', isLoading: false } };
        case CONTENTMANAGEMENTS_STATIC_FAIL:
            return { ...state, PrivacyPoliciesTermsConditions: { all: [], error: action.payload, isLoading: false } };
        case CONTENTMANAGEMENT_STATIC_GET_REQUEST:
            return {
                ...state, privacypolicytermscondition: { privacypolicytermscondition: {}, error: '', isLoading: true }
            };
        case CONTENTMANAGEMENT_STATIC_GET_SUCCESS:
            return {
                ...state, privacypolicytermscondition: { privacypolicytermscondition: action.payload, error: '', isLoading: false }
            };
        case CONTENTMANAGEMENT_STATIC_GET_FAIL:
            return { ...state, privacypolicytermscondition: { privacypolicytermscondition: {}, error: action.payload, isLoading: false } };
        case CONTENTMANAGEMENT_STATIC_CREATE_REQUEST:
            return { ...state, privacypolicytermsconditionCreate: { privacypolicytermscondition: {}, error: '', isLoading: true } };
        case CONTENTMANAGEMENT_STATIC_CREATE_SUCCESS:
            return {
                ...state, privacypolicytermsconditionCreate: {
                    privacypolicytermscondition: state.privacypolicytermsconditionCreate.privacypolicytermscondition
                    , error: '', isLoading: false
                }
            };
        case CONTENTMANAGEMENT_STATIC_CREATE_FAIL:
            return { ...state, privacypolicytermsconditionCreate: { privacypolicytermscondition: {}, error: action.payload, isLoading: false } };
        case CONTENTMANAGEMENT_STATIC_UPDATE_REQUEST:
            return { ...state, privacypolicytermsconditionUpdate: { privacypolicytermscondition: {}, error: '', isLoading: true } };
        case CONTENTMANAGEMENT_STATIC_UPDATE_SUCCESS:
            return { ...state, privacypolicytermsconditionUpdate: { privacypolicytermscondition: action.payload, error: '', isLoading: false } };
        case CONTENTMANAGEMENT_STATIC_UPDATE_FAIL:
            return { ...state, privacypolicytermsconditionUpdate: { privacypolicytermscondition: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}