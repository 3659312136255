import {
  STORETYPE_REQUEST,
  STORETYPE_SUCCESS,
  STORETYPE_FAIL,
  STORETYPE_GET_REQUEST,
  STORETYPE_GET_SUCCESS,
  STORETYPE_GET_FAIL,
  STORETYPE_CREATE_REQUEST,
  STORETYPE_CREATE_SUCCESS,
  STORETYPE_CREATE_FAIL,
  STORETYPE_UPDATE_REQUEST,
  STORETYPE_UPDATE_SUCCESS,
  STORETYPE_UPDATE_FAIL,
} from '../actions/storetypeActions'

const initialState = {
  storetypes: { all: [], error: '', isLoading: false },
  storetype: { storetype: {}, error: '', isLoading: false },
  storetypeCreate: { storetype: {}, error: '', isLoading: false },
  storetypeUpdate: { storetype: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STORETYPE_REQUEST:
      return { ...state, storetypes: { all: state.storetypes.all, error: '', isLoading: true } };
    case STORETYPE_SUCCESS:
      return { ...state, storetypes: { all: action.payload, error: '', isLoading: false } };
    case STORETYPE_FAIL:
      return { ...state, storetypes: { all: [], error: action.payload, isLoading: false } };
    case STORETYPE_GET_REQUEST:
      return { ...state, storetype: { storetype: {}, error: '', isLoading: true } };
    case STORETYPE_GET_SUCCESS:
      return { ...state, storetype: { storetype: action.payload, error: '', isLoading: false } };
    case STORETYPE_GET_FAIL:
      return { ...state, storetype: { storetype: {}, error: action.payload, isLoading: false } };
    case STORETYPE_CREATE_REQUEST:
      return { ...state, storetypeCreate: { storetype: {}, error: '', isLoading: true } };
    case STORETYPE_CREATE_SUCCESS:
      return { ...state, storetypeCreate: { storetype: state.storetypeCreate.storetype, error: '', isLoading: false } };
    case STORETYPE_CREATE_FAIL:
      return { ...state, storetypeCreate: { storetype: {}, error: action.payload, isLoading: false } };
    case STORETYPE_UPDATE_REQUEST:
      return { ...state, storetypeUpdate: { storetype: {}, error: '', isLoading: true } };
    case STORETYPE_UPDATE_SUCCESS:
      return { ...state, storetypeUpdate: { storetype: state.storetypeUpdate.storetype, error: '', isLoading: false } };
    case STORETYPE_UPDATE_FAIL:
      return { ...state, storetypeUpdate: { storetype: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}