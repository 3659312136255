import {
  SERVICE_SUB_CATEGORY_TYPE_CREATE_REQUEST,
  SERVICE_SUB_CATEGORY_TYPE_CREATE_SUCCESS,
  SERVICE_SUB_CATEGORY_TYPE_CREATE_FAIL,
  SUB_CATEGORY_UPDATE_REQUEST,
  SUB_CATEGORY_UPDATE_SUCCESS,
  SUB_CATEGORY_UPDATE_FAIL,
  SERVICE_CATEGORY_TYPE_CREATE_REQUEST,
  SERVICE_CATEGORY_TYPE_CREATE_SUCCESS,
  SERVICE_CATEGORY_TYPE_CREATE_FAIL,
  SUB_SERVICES_GET_SUCCESSFULL,
  SUB_SERVICES_GET_REQUEST,
  SUB_SERVICES_GET_FAILED,
} from '../actions/servicessubcategorieAction'

const initialState = {
  subcategoryCreate: { subcategory: {}, error: '', isLoading: false },
  subcategoryUpdate: { subcategory: {}, error: '', isLoading: false },
  subcategory: { subcategories: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SERVICE_SUB_CATEGORY_TYPE_CREATE_REQUEST:
      return { ...state, subcategoryCreate: { subcategory: {}, error: '', isLoading: true } };
    case SERVICE_SUB_CATEGORY_TYPE_CREATE_SUCCESS:
      return { ...state, subcategoryCreate: { subcategory: state.subcategoryCreate.subcategory, error: '', isLoading: false } };
    case SERVICE_SUB_CATEGORY_TYPE_CREATE_FAIL:
      return { ...state, subcategoryCreate: { subcategory: {}, error: action.payload, isLoading: false } };
    case SUB_CATEGORY_UPDATE_REQUEST:
      return { ...state, subcategoryUpdate: { subcategory: {}, error: '', isLoading: true } };
    case SUB_CATEGORY_UPDATE_SUCCESS:
      return { ...state, subcategoryUpdate: { subcategory: action.payload, error: '', isLoading: false } };
    case SUB_CATEGORY_UPDATE_FAIL:
      return { ...state, subcategoryUpdate: { subcategory: {}, error: action.payload, isLoading: false } };

    case SERVICE_CATEGORY_TYPE_CREATE_REQUEST:
      return { ...state, servicecategoryCreate: { Servicecategory: {}, error: '', isLoading: true } };
    case SERVICE_CATEGORY_TYPE_CREATE_SUCCESS:
      return { ...state, servicecategoryCreate: { Servicecategory: action.payload, error: '', isLoading: false } };
    case SERVICE_CATEGORY_TYPE_CREATE_FAIL:
      return { ...state, servicecategoryCreate: { Servicecategory: {}, error: action.payload, isLoading: false } };

    case SUB_SERVICES_GET_REQUEST:
      return { ...state, subcategory: { subcategories: [], error: "", isLoading: true } }
    case SUB_SERVICES_GET_SUCCESSFULL:
      return { ...state, subcategory: { subcategories: action.payload, error: "", isLoading: false } }
    case SUB_SERVICES_GET_FAILED:
      return { ...state, subcategory: { error: action.payload, subcategories: [], isLoading: false } }


    default:
      return state;
  }
}