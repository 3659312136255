import React from 'react';
import {
    Box,
    InputAdornment,
    SvgIcon,
    TextField,
    makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    formFilterControl: {
        margin: theme.spacing(1),
        minWidth: 200
    }
}));

const Toolbar = ({ className, state, filters, setfilters, RolePermissions, roleUserType, ...rest }) => {
    const classes = useStyles();

    const handleChange = event => {
        setfilters({
            search: filters?.search,
            search_by_filter: event.target.value,
            Records_Filter: 'FILTER',
            Delivery_Bidding_Ride_Quote_Id: state.Delivery_Bidding_Quote_Id
        })
    };

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                alignItems="center"
                mb={2}
            >
                <Box display="flex" flexWrap="wrap" mb={2}>
                    <FormControl variant="outlined" className={classes.formFilterControl}>
                        <Select
                            value={filters.search_by_filter}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="Bidding_Ride_Comment_Id">Bidding Ride Comment ID</MenuItem>
                            <MenuItem value="Delivery_Bidding_Ride_Quote_Id">Delivery Bidding Ride Quote ID</MenuItem>
                            <MenuItem value="Is_Admin_Approval">Is Admin Approval</MenuItem>
                            <MenuItem value="Commented_By">Commented By</MenuItem>
                        </Select>
                    </FormControl>
                    <Box width={500}>
                        <TextField
                            onChange={(event) => {
                                setfilters({
                                    search: event.target.value,
                                    search_by_filter: filters.search_by_filter,
                                    Records_Filter: 'FILTER',
                                    Delivery_Bidding_Ride_Quote_Id: state.Delivery_Bidding_Quote_Id
                                })
                            }}
                            className={classes.formControl}
                            value={filters?.search}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon fontSize="small" color="action">
                                            <SearchIcon />
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}
                            placeholder="Search Delivery Bidding Comments"
                            variant="outlined"
                        />
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default Toolbar;