import { toast } from 'react-toastify';
import { nodeCorpMongoApi } from './commonAxios';
import { paginationAllCorpFAQGet } from './node-actions/nodepagination';

export const CORPORATE_FAQ_CREATE_REQUEST = "CORPORATE_FAQ_CREATE_REQUEST";
export const CORPORATE_FAQ_CREATE_SUCCESS = "CORPORATE_FAQ_CREATE_SUCCESS";
export const CORPORATE_FAQ_CREATE_FAIL = "CORPORATE_FAQ_CREATE_FAIL";
export const CORPORATE_FAQ_UPDATE_REQUEST = "CORPORATE_FAQ_UPDATE_REQUEST";
export const CORPORATE_FAQ_UPDATE_SUCCESS = "CORPORATE_FAQ_UPDATE_SUCCESS";
export const CORPORATE_FAQ_UPDATE_FAIL = "CORPORATE_FAQ_UPDATE_FAIL";
export const RESET = "RESET";

export const invalid_data = () => {
    return {
        type: RESET
    }
}

/*=============================================================
                  Add Corporate FAQ Action
===============================================================*/
export const addcorporateFaqAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CORPORATE_FAQ_CREATE_REQUEST
    });
    nodeCorpMongoApi.post(`/FAQ/create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.code === 201 && successResponse?.message === "FAQ created successfully") {
                dispatch({
                    type: CORPORATE_FAQ_CREATE_SUCCESS
                });
                toast('Corporate FAQ added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corporatefaq-management');
            } else {
                dispatch({
                    type: CORPORATE_FAQ_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CORPORATE_FAQ_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

/*=============================================================
                Delete Corporate FAQ ById Action
===============================================================*/
export const deletecorporateFaqById = (formData, filters, pagination, callBackPagination) => async dispatch => {
    try {
        let { data } = await nodeCorpMongoApi.post(`/FAQ/delete`, formData);
        if (data) {
            toast('Corporate FAQ deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            dispatch(paginationAllCorpFAQGet(filters, pagination, callBackPagination));
        }
    } catch (err) {
    }
};

/*=============================================================
                 Update Corporate Faq Action
===============================================================*/
export const updatecorporateFaqAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CORPORATE_FAQ_UPDATE_REQUEST
    });
    nodeCorpMongoApi.post(`/FAQ/update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.code === 200 && successResponse?.message === "FAQ updated") {
                dispatch({
                    type: CORPORATE_FAQ_UPDATE_SUCCESS
                });
                toast('Corporate FAQ updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corporatefaq-management');
            } else {
                dispatch({
                    type: CORPORATE_FAQ_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CORPORATE_FAQ_UPDATE_FAIL,
                payload: "Please try again later.Currently server is not working"
            });
        })
};