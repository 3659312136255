import React, { useEffect } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import {
  createProfileAction,
  getUserProfileAction,
} from "../../../store/actions/profileActions";
import { getLogedInUser } from '../../../store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import ChangePassword from './ChangePassword';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = () => {
  const classes = useStyles();
  const [userProfile, setUserProfile] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [profileExist, setProfileExist] = React.useState(false);
  const [profileResponse, setProfileResponse] = React.useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    let data = {
      Email_Id: user.Email_Id,
      Records_Filter: "FILTER",
      search_by_filter: "",
      search: "",
    };
    dispatch(getUserProfileAction(updateStates, data));
  }, [user.Email_Id, dispatch]);

  const updateStates = (data, loading, isError) => {
    if (isError) {
      callGetUser();
    } else {
      if (Number(data.Success_Response ? data.Success_Response.Is_Data_Exist : 0) > 0 || Number(data.Is_Data_Exist ? data.Is_Data_Exist : 0) > 0) {
        setProfileExist(true);
        setUserProfile(data.results[0]);
        setLoading(false);
      } else {
        createNewProfile();
      }
      
    }
  };

  const updateStatesCreate = (data, loading, isError) => {
    if (isError) {
      createNewProfile();
    } else {
      if (data.Response_Status === "Failure") {
        setProfileResponse("Please contact administrator,Unable featch profile.");
      } else {
        callGetUser();
        setLoading(false);
      }
    }
  };
  const createNewProfile = () => {
    setLoading(true);
    let formData = {
      "Created_By": user.Email_Id,
      "Dob": "",
      "Email_Id": user.Email_Id,
      "Facebook_Login_Id": "",
      "First_Name": "",
      "Gender": "",
      "Google_Login_Id": "",
      "Id_Proof_Number": "",
      "Id_Proof_Type": "",
      "Last_Name": "",
      "Mobile": "",
      "Profile_Pic_Path": "",
      "Status": "Active"
    }
    dispatch(createProfileAction(updateStatesCreate, formData));
  };

  const callGetUser = () => {
    setLoading(true);
    let data = {
      Email_Id: user.Email_Id,
      Records_Filter: "FILTER",
      search_by_filter: "",
      search: "",
    };
    dispatch(getUserProfileAction(updateStates, data));
    dispatch(getLogedInUser(user.Email_Id, false));
  };



  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Grid item lg={12} md={12} xs={12}>
              <Profile profiletemp={userProfile} callgetuser={callGetUser}
                loading={loading}
                setLoading={setLoading}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <ChangePassword
                loading={loading}
                setLoading={setLoading}
              />
            </Grid>
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            {profileResponse ?
              <center style={{ color: "red" ,marginTop:"30%" }} > {profileResponse} </center> : <ProfileDetails profiletemp={userProfile} callgetuser={callGetUser}
                loading={loading}
                setLoading={setLoading} />}

          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
