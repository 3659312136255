import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/store-type',
    createdAt: '',
    description:
      'Store Type means the classification of WIC Retail Vendors by the number of active customer check-out lanes/cash registers. One or two lanes is a type 1 Vendor Site. Three or four lanes is a type 2 Vendor Site. Five to seven lanes is type 3 Vendor Site. Eight or more lanes is a type 4 Vendor Site.',
    media: '/static/Dashboard/catergory.png',
    title: 'Store Type Management',
    total: ''
  },
];