import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  makeStyles
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import isEmpty from 'src/store/validations/is-empty';

// useStyles we use a hook to consume the styles
const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

//headCells are the values in header
const headCells = [
  { id: 'Shipping_Id', numeric: false, disablePadding: false, label: 'Shipping ID' },
  { id: 'Order_Id', numeric: false, disablePadding: false, label: 'Order ID' },
  { id: 'Shipping_Channel_Partner', numeric: false, disablePadding: false, label: 'Shipping Channel Partner' },
  { id: 'Shipping_Status', numeric: false, disablePadding: false, label: 'Shipping Status' },
];

const Results = ({
  className,
  allreturnShipping,
  page,
  setPage,
  limit,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allReturnedshippingNoFilter,
  ...rest
}) => {
  // useNavigation is a hook which gives access to navigation object
  const navigate = useNavigate();
  // classes contains the styles
  const classes = useStyles();

  //visibleReturnshippingHandler contains the naviagtion for view page
  const visibleReturnshippingHandler = viewData => e => {
    navigate("/app/view-returnedshippings", { state: viewData });
  };

  //checkingPagination contains pagination properties
  function checkingPagination(array) {
    if (array.slice(page * limit, page * limit + limit).length <= 0) {
      setPage(page - 1);
    }
    else {
      return array.slice(page * limit, page * limit + limit);
    }
    return array;
  }

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-45%' }} >Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allreturnShipping) &&
                (limit > 0 ? checkingPagination(allreturnShipping) : allreturnShipping).map(returnshipping => (
                  <TableRow hover key={returnshipping.Shipping_Id}>
                    <TableCell>{returnshipping.Shipping_Id}</TableCell>
                    <TableCell>{returnshipping.Order_Id}</TableCell>
                    <TableCell>{returnshipping.Shipping_Channel_Partner}</TableCell>
                    <TableCell style={{ color: '#1665D8' }}>{returnshipping.Shipping_Status}</TableCell>
                    <TableCell>
                      <Tooltip title="View Shipping">
                        <Button
                          onClick={visibleReturnshippingHandler(returnshipping)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allreturnShipping) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={allreturnShipping.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 20]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allreturnShipping: PropTypes.array.isRequired
};

export default Results;