import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
  Avatar,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  images: {
    width: 100,
    height: 30
  },
  buttonRoot: {
    minWidth: '7px',
    padding: '6px'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));

const headCells = [
  { id: 'Store_Type_Name', numeric: false, disablePadding: false, label: 'Store Type Name' },
  { id: 'Image_Path', numeric: false, disablePadding: false, label: 'Image Path' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allStoretypes,
  deleteStoretypeHandler,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allStoretypesNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedStoretypeIds, setSelectedStoretypeIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const handleClose = (value) => {
    setOpen(false);
  };

  /*===============================
             handler     
  =================================*/

  const handleSelectAll = event => {
    let newSelectedStoretypeIds;
    if (event.target.checked) {
      newSelectedStoretypeIds = allStoretypes.map(storetype => storetype.Store_Type_Name);
    } else {
      newSelectedStoretypeIds = [];
    }
    setSelectedStoretypeIds(newSelectedStoretypeIds);
  };
  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedStoretypeIds.indexOf(id);
    let newselectedStoretypeIds = [];
    if (selectedIndex === -1) {
      newselectedStoretypeIds = newselectedStoretypeIds.concat(selectedStoretypeIds, id);
    } else if (selectedIndex === 0) {
      newselectedStoretypeIds = newselectedStoretypeIds.concat(
        selectedStoretypeIds.slice(1));
    } else if (selectedIndex === newselectedStoretypeIds.length - 1) {
      newselectedStoretypeIds = newselectedStoretypeIds.concat(
        selectedStoretypeIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newselectedStoretypeIds = newselectedStoretypeIds.concat(
        selectedStoretypeIds.slice(0, selectedIndex),
        selectedStoretypeIds.slice(selectedIndex + 1)
      );
    }
    setSelectedStoretypeIds(newselectedStoretypeIds);
  };

  const deleteSelectedStoretypeHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };
  const editStoretypeHandler = storetypeData => e => {
    navigate('/app/store-type-edit-storetype', { state: storetypeData });
  };
  const StoretypeLinkHandler = storetypeData => e => {
    navigate('/app/store-type-linking', { state: storetypeData });
  };
  const visibleStoretypeHandler = storetypeData => e => {
    navigate('/app/storetype-details', { state: storetypeData });
  };
  const StoretypePartnerHandler = storetypeData => e => {
    navigate('/app/store-type-partner-linking', { state: storetypeData });
  };

  /*=================================
              handler end
  =================================*/

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedStoretypeIds.filter(record => {
                      for (let i = 0; i < allStoretypesNoFilter.length; i++) {
                        if (record == allStoretypesNoFilter[i].Store_Type_Name) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allStoretypes.length}
                    color="primary"
                    indeterminate={
                      selectedStoretypeIds.filter(record => {
                        for (let i = 0; i < allStoretypesNoFilter.length; i++) {
                          if (record == allStoretypesNoFilter[i].Store_Type_Name) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedStoretypeIds.filter(record => {
                        for (let i = 0; i < allStoretypesNoFilter.length; i++) {
                          if (record == allStoretypesNoFilter[i].Store_Type_Name) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allStoretypes.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.id !== "Image_Path" ? (
                      <TableSortLabel className={classes.headerColumn}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>) : (<span className={classes.headerColumn} > {headCell.label}</span>)
                    }
                  </TableCell>
                ))}
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Store_Type_Management_Write?.Value === "Store_Type_Management_Write" && RolePermissions?.Store_Type_Management_Write?.Is_Write === 1) ?
                <TableCell> Category Link </TableCell>
                  :null}
                  {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Store_Type_Management_Write?.Value === "Store_Type_Management_Write" && RolePermissions?.Store_Type_Management_Write?.Is_Write === 1) ?
                <TableCell> Partner Link </TableCell>
                  :null}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedStoretypeIds.filter(record => {
                    for (let i = 0; i < allStoretypesNoFilter.length; i++) {
                      if (record == allStoretypesNoFilter[i].Store_Type_Name) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedStoretypeIds.filter(record => {
                    for (let i = 0; i < allStoretypesNoFilter.length; i++) {
                      if (record == allStoretypesNoFilter[i].Store_Type_Name) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedStoretypeHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allStoretypes) &&
                (allStoretypes).map(storetype => (
                  <TableRow
                    hover
                    key={storetype.Store_Type_Name}
                    selected={
                      selectedStoretypeIds.indexOf(storetype.Store_Type_Name) !== -1} >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedStoretypeIds.indexOf(storetype.Store_Type_Name) !== -1}
                        onChange={event => handleSelectOne(event, storetype.Store_Type_Name)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>{storetype.Store_Type_Name}</TableCell>
                    <TableCell>
                      <Avatar
                        className={classes.images}
                        alt="Product"
                        src={storetype.Image_Path}
                        variant="square"
                      />
                    </TableCell>
                    <TableCell >{storetype.Status}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Store_Type_Management_Write?.Value === "Store_Type_Management_Write" && RolePermissions?.Store_Type_Management_Write?.Is_Write === 1) ?
                      <TableCell >
                        <Button>
                          <LinkIcon onClick={StoretypeLinkHandler(storetype)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                      : null}
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Store_Type_Management_Write?.Value === "Store_Type_Management_Write" && RolePermissions?.Store_Type_Management_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Button>
                          <LinkIcon
                            onClick={StoretypePartnerHandler(storetype)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                      : null}
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Store_Type_Management_Write?.Value === "Store_Type_Management_Write" && RolePermissions?.Store_Type_Management_Write?.Is_Write === 1) ?
                      <TableCell >
                        <Button
                          onClick={visibleStoretypeHandler(storetype)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button
                          onClick={editStoretypeHandler(storetype)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                          <DeleteIcon
                            onClick={deleteStoretypeHandler(storetype.Store_Type_Name)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Store_Type_Management_Read?.Value === "Store_Type_Management_Read" && RolePermissions?.Store_Type_Management_Read?.Is_Read === 1 &&
                      <TableCell >
                        <Button
                          onClick={visibleStoretypeHandler(storetype)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allStoretypes) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;