import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
  TextField
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import isEmpty from 'src/store/validations/is-empty';
import CorpPagination from 'src/components/pagination/corppagination';
import { useDispatch, useSelector } from 'react-redux';
import { updateCorpOrderStatusAction } from 'src/store/actions/corporderAction';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
  textField: {
    width: '170px',
    height: '40px',
    borderRadius: '50px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      height: '40px',
      '& fieldset': {
        borderColor: 'inherit',
      },
      '&:hover fieldset': {
        borderColor: 'inherit',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'inherit',
      },
    },
    '& .MuiSelect-icon': {
      color: 'inherit',
      left: '75%',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: '16px',
      width: '2em',
      height: '2em',
    },
  },
}));

const statusOption = [
  {
    value: 'Order confirmed',
    label: 'Order confirmed'
  },
  {
    value: 'Shipped',
    label: 'Shipped'
  },
  {
    value: 'On process',
    label: 'On process'
  },
  {
    value: 'Out for delivery',
    label: 'Out for delivery'
  },
  {
    value: 'Delivered',
    label: 'Delivered'
  }
];

const headCells = [
  { id: 'Order_Id', numeric: false, disablePadding: true, label: 'Order id' },
  { id: 'Company_Name', numeric: false, disablePadding: false, label: 'Company name' },
  { id: 'Order_By', numeric: false, disablePadding: false, label: 'Order by' },
  { id: 'Delivery_Type', numeric: false, disablePadding: false, label: 'Delivery type' },
  { id: 'Quantity', numeric: false, disablePadding: false, label: 'Quantity' },
  { id: 'Ordered_Date', numeric: false, disablePadding: false, label: 'Ordered date' },
  { id: 'Payment_Type', numeric: false, disablePadding: false, label: 'Payment type' },
  { id: 'Order_Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allCorpOrder,
  pagination,
  setPagination,
  pagesdata,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allCorpOrderNoFilter,
  RolePermissions,
  roleUserType,
  filters,
  callBackPagination,
  ...rest
}) => {

  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const Login_Details = useSelector(state => state.auth.user);
  const pagetype = "main"

  const visibleOrderHandler = (orderData) => {
    if (orderData?.Delivery_Type?.toLowerCase() === "doorstep delivery") {
      navigate("/app/corp-order-doorstep-delivery-management", { state: orderData });
    } else {
      navigate("/app/corp-order-info", { state: orderData });
    }
  };

  const handleStatusChange = (e, corporder) => {
    const newStatus = e.target.value;
    let formData = {
      "Order_Id": corporder?.Order_Id,
      "Delivery_Type": corporder?.Delivery_Type,
      "Order_Status": newStatus,
      "Modified_By": Login_Details?.Email_Id
    }
    dispatch(updateCorpOrderStatusAction(formData, filters, pagination, callBackPagination, pagetype));
  };

  const getStatusStyles = (status) => {
    switch (status) {
      case 'Order confirmed':
        return {
          borderColor: '#000000',
          color: '#000000',
        };
      case 'Shipped':
        return {
          borderColor: '#9C27B0',
          color: '#9C27B0',
        };
      case 'On process':
        return {
          borderColor: '#EF6C00',
          color: '#EF6C00',
        };
      case 'Out for delivery':
        return {
          borderColor: '#EF6C00',
          color: '#EF6C00',
        };
      case 'Delivered':
        return {
          borderColor: '#2E7D32',
          color: '#2E7D32',
        };
      case 'Incomplete':
        return {
          borderColor: '#D32F2F',
          color: '#D32F2F',
        };
      case 'Completed':
        return {
          borderColor: '#2E7D32',
          color: '#2E7D32',
        };
      default:
        return {
          borderColor: '#000000',
          color: '#000000',
        };
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1500}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.id !== "Order_By" ? (
                      <TableSortLabel className={classes.headerColumn}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>) : (<span style={{ fontSize: "13px", fontWeight: "bold" }} > {headCell.label}</span>)
                    }
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allCorpOrder) &&
                allCorpOrder.map((corporder) => {
                  const statusStyles = getStatusStyles(corporder?.Order_Status);
                  return (
                    <TableRow
                      hover
                      key={corporder?.Order_Id}
                    >
                      <TableCell>{corporder?.Order_Id}</TableCell>
                      <TableCell width="20%">{corporder?.Company_Name?.length > 25 ? (corporder?.Company_Name?.substring(0, 25) + "...") : corporder?.Company_Name}</TableCell>
                      <TableCell width="18%">{corporder?.Order_By?.length > 25 ? (corporder?.Order_By?.substring(0, 25) + "...") : corporder?.Order_By}</TableCell>
                      <TableCell width="18%">{corporder?.Delivery_Type}</TableCell>
                      <TableCell>{corporder?.Quantity}</TableCell>
                      <TableCell width="15%">{corporder?.Ordered_Date}</TableCell>
                      <TableCell width="15%">{corporder?.Payment_Type}</TableCell>
                      <TableCell width="15%">
                        {corporder?.Delivery_Type?.toLowerCase() === "delivery to company" ?
                          <TextField
                            fullWidth
                            value={corporder?.Order_Status}
                            onChange={(e) => handleStatusChange(e, corporder)}
                            select
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            className={classes.textField}
                            InputProps={{
                              style: {
                                borderColor: statusStyles.borderColor,
                                color: statusStyles.color,
                              },
                            }}
                            SelectProps={{
                              native: true,
                              style: {
                                color: statusStyles.color,
                              },
                              classes: {
                                icon: classes.textField,
                              },
                            }}
                          >
                            {statusOption.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                          :
                           <TextField
                           fullWidth
                           value={corporder?.Order_Status}
                           variant="outlined"
                           className={classes.textField}
                           InputProps={{
                             style: {
                               borderColor: statusStyles.borderColor,
                               color: statusStyles.color,
                             },
                           }}
                           SelectProps={{
                             native: true,
                             style: {
                               color: statusStyles.color,
                             },
                             classes: {
                               icon: classes.textField,
                             },
                           }}
                         >
                         </TextField>
                        }
                      </TableCell>
                      {!corporder?.Is_Credit_Point_Top_Up &&
                      <>
                      {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                        (RolePermissions?.Corporate_Order_Write?.Value === "Corporate_Order_Write" && RolePermissions?.Corporate_Order_Write?.Is_Write === 1) ?
                        <TableCell>
                          <Tooltip title="Corp Order Details">
                            <Button
                              onClick={() => visibleOrderHandler(corporder)}
                              color="primary"
                            >
                              <VisibilityIcon style={{ color: '#9EA0A5' }} />
                            </Button>
                          </Tooltip>
                        </TableCell>
                        : null}
                      </>
                      }
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allCorpOrder) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <CorpPagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allCorpOrder: PropTypes.array.isRequired
};

export default Results;