import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { shippingFilter } from 'src/store/actions/shippingAction';

// useStyles we use a hook to consume the styles
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  formFilterControl: {
    margin: theme.spacing(1),
    minWidth: 200
  }
}));

const Toolbar = ({ className, ...rest }) => {
  // classes contains the styles
  const classes = useStyles();
  // useDispatch hook is used to dispatch an action
  const dispatch = useDispatch();
  // returnshippingMenu, returnshippingSearch contains the data for filter and search
  let returnshippingMenu = useSelector(state => state.shippings.shippingFilter.select);
  let returnshippingSearch = useSelector(state => state.shippings.shippingFilter.search);

  //dispatch is used to call the API Action 
  // handleChange, handleSearch performs the action for filter and search
  const handleChange = event => {
    dispatch(shippingFilter('select', event.target.value));
  };

  const handleSearch = event => {
    dispatch(shippingFilter('search', event.target.value));
  };

  return (
    <div {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" flexWrap="wrap" mb={2}>
          <FormControl variant="outlined" className={classes.formFilterControl}>
            <Select
              value={returnshippingMenu}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Shipping_Id">Shipping ID</MenuItem>
              <MenuItem value="Order_Id">Order ID</MenuItem>
              <MenuItem value="Shipping_Channel_Partner">Shipping Channel Partner</MenuItem>
            </Select>
          </FormControl>
          <Box width={400}>
            <TextField onChange={handleSearch}
              className={classes.formControl}
              value={returnshippingSearch}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search Order Shippings"
              variant="outlined"
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;