
import config from './config';
import { toast } from 'react-toastify';
import { paginationStoreTypePartner } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi} from './commonAxios';



export const STORETYPE_PARTNER_LINKING_REQUEST = "STORETYPE_PARTNER_LINKING_REQUEST ";
export const STORETYPE_PARTNER_LINKING_SUCCESS = "STORETYPE_PARTNER_LINKING_SUCCESS";
export const STORETYPE_PARTNER_LINKING_FAIL = "STORETYPE_PARTNER_LINKING_FAIL";
export const STORETYPE_PARTNER_LINKING_GET_REQUEST = "STORETYPE_PARTNER_LINKING_GET_REQUEST ";
export const STORETYPE_PARTNER_LINKING_GET_SUCCESS = "STORETYPE_PARTNER_LINKING_GET_SUCCESS";
export const STORETYPE_PARTNER_LINKING_GET_FAIL = "STORETYPE_PARTNER_LINKING_GET_FAIL";
export const STORETYPE_PARTNER_LINKING_CREATE_REQUEST = "STORETYPE_PARTNER_LINKING_CREATE_REQUEST";
export const STORETYPE_PARTNER_LINKING_CREATE_SUCCESS = "STORETYPE_PARTNER_LINKING_CREATE_SUCCESS";
export const STORETYPE_PARTNER_LINKING_CREATE_FAIL = "STORETYPE_PARTNER_LINKING_CREATE_FAIL";
export const STORETYPE_PARTNER_LINKING_UPDATE_REQUEST = "STORETYPE_PARTNER_LINKING_UPDATE_REQUEST";
export const STORETYPE_PARTNER_LINKING_UPDATE_SUCCESS = "STORETYPE_PARTNER_LINKING_UPDATE_SUCCESS";
export const STORETYPE_PARTNER_LINKING_UPDATE_FAIL = "STORETYPE_PARTNER_LINKING_UPDATE_FAIL ";

export const GET_PARTNERS_REQUEST = 'GET_PARTNERS_REQUEST';
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS";
export const GET_PARTNERS_FAIL = "GET_PARTNERS_FAIL";


/*=============================================================
                  Add Store type Action
===============================================================*/

export const AddStoretypepartnerlinkingAction = (formData, navigate, state,setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: STORETYPE_PARTNER_LINKING_CREATE_REQUEST
  });
  setErrorMessage({ Partner_Detail_Id: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Store_Type_Partner/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: STORETYPE_PARTNER_LINKING_CREATE_SUCCESS
        });
        toast('Store Type Partner created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/store-type-partner-linking', state = { state });
      } else {
        const errors = {};
        if (successResponse.Partner_Detail_Id) {
          errors.Partner_Detail_Id = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Partner_Detail_Id: { key: successResponse.Partner_Detail_Id ? successResponse.Partner_Detail_Id : '', message: successResponse.Partner_Detail_Id ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Partner_Detail_Id) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: STORETYPE_PARTNER_LINKING_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Partner_Detail_Id: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: STORETYPE_PARTNER_LINKING_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Update Store type Action
===============================================================*/
export const EditStoretypepartnerlinkingAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: STORETYPE_PARTNER_LINKING_UPDATE_REQUEST
  });
  nodeCudApi.post(`${config.nodecudurl}/Store_Type_Partner/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: STORETYPE_PARTNER_LINKING_UPDATE_SUCCESS
        });
        toast('Store Type Partner updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/store-type-partner-linking', state = { state });
      } else {
        dispatch({
          type: STORETYPE_PARTNER_LINKING_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: STORETYPE_PARTNER_LINKING_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Get All Store type Partner Action
 ===============================================================*/
export const getAllStoretypepartnerlinkingAction = formData => async dispatch => {
  try {
    dispatch({
      type: STORETYPE_PARTNER_LINKING_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Store_Type_Partner/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=STORE_TYPE_PARTNER_ID&SortOrder=ASC `,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: STORETYPE_PARTNER_LINKING_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: STORETYPE_PARTNER_LINKING_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: STORETYPE_PARTNER_LINKING_FAIL,
      payload: err
    });
  }
};

/*=============================================================
              Delete  Delivery Payment Action
 ===============================================================*/
export const deletestoretypepartnerlinkingAction = (formData, filters, pagination, callBackPagination) => async dispatch => {

  try {
    let { data } = await nodeCudApi.post(`/Store_Type_Partner/Delete`, formData);
    if (data) {
      toast(' Store Type Partner deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationStoreTypePartner(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};
/*=============================================================
                  Get All partners Action
===============================================================*/
export const getAllPartners = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: 'FILTER',
      Status: "Active"
    };
    dispatch({
      type: GET_PARTNERS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partners/Physical_Partner_Profile_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.successResponse?.Is_Data_Exist === '0') {
        dispatch({
          type: GET_PARTNERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: GET_PARTNERS_SUCCESS,
          payload: data.results ? data.results : []
        });
      }
    }
  } catch (err) {
    dispatch({
      type: GET_PARTNERS_FAIL,
      payload: JSON.stringify(err)
    });
  }
};