import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
 
import { ConnectedFocusError } from 'focus-formik-error';
import CircularProgress from '@mui/material/CircularProgress';
import {
  EditServiceVarienttypeAction,
  VarientuploadImagepath
} from 'src/store/actions/servicesvarientAction';
import { TextareaAutosize } from '@mui/material';
import {
  paginationsubcategoryServices,
  paginationServicescategory
} from 'src/store/actions/node-actions/nodepagination';
import EditorContainer from '../../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
 
const StatusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'ACTIVE',
    label: 'ACTIVE'
  },
  {
    value: 'IN-ACTIVE',
    label: 'IN-ACTIVE'
  }
];
 
const EnquiryOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 1,
    label: 'YES'
  },
  {
    value: 0,
    label: 'NO'
  }
];
 
const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  Description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  },
  Sub_Description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  },
  Partner_Whats_Excluded: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  },
  Partner_Whats_Expect_From_Us: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  },
  Partner_Whats_Include: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));
 
const EditServiceVarient = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  let { state } = location;
 
  const [errorMessage, setErrorMessage] = useState({
    Sv_Name: { key: '', message: '' },
    global: { key: '', message: '' }
  });
  const isSubmitted = useSelector(
    state => state.category.categoryUpdate.isLoading
  );
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);
  // img upload
  const [image, setImage] = useState({
    preview: state.Image,
    raw: state.Image
  });
  const [imgUrl, setImgUrl] = useState(state.Image);
  const [uploadPic, setUploadPic] = useState(false);
  const [subServices, setSubServices] = useState([]);
  const [services, setServices] = useState([]);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };
 
  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      VarientuploadImagepath(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };
 
  //richertext editor
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Description).contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Description);
 
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
 
 
  useEffect(() => {
    // Fetch services
    dispatch(
      paginationServicescategory({}, {}, (status, data) => {
        if (status) {
          setServices(data);
        }
      })
    );
 
 
    if (state.Service_Id) {
      dispatch(
        paginationsubcategoryServices({ Service_Id: state.Service_Id }, {}, (status, data) => {
          if (status) {
            setSubServices(data);
          }
        })
      );
    }
  }, [state.Service_Id, dispatch]);
 
 
  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };
 
  return (
    <Formik
      initialValues={{
        Image: imgUrl,
        Sv_Id: state.Sv_Id ? state.Sv_Id : '',
        Sv_Name: state.Sv_Name ? state.Sv_Name : '',
        Service_Id: state.Service_Id ? state.Service_Id : '',
        Ss_Id: state.Ss_Id ? state.Ss_Id : '',
        Description: state.Description ? state.Description : '',
        Sub_Description: state.Sub_Description ? state.Sub_Description : '',
        Status: state.Status ? state.Status : '',
        Partner_Whats_Include: state.Partner_Whats_Include
          ? state.Partner_Whats_Include
          : '',
        Partner_Whats_Excluded: state.Partner_Whats_Excluded
          ? state.Partner_Whats_Excluded
          : '',
        Partner_Whats_Expect_From_Us: state.Partner_Whats_Expect_From_Us
          ? state.Partner_Whats_Expect_From_Us
          : '',
        Service_Charge_For_Timeslot: state.Service_Charge_For_Timeslot
          ? state.Service_Charge_For_Timeslot
          : '',
        Service_Charge_For_Weekend_Timeslot: state.Service_Charge_For_Weekend_Timeslot
          ? state.Service_Charge_For_Weekend_Timeslot
          : '',
        Service_Charge_For_Special_Days_Timeslot: state.Service_Charge_For_Special_Days_Timeslot
          ? state.Service_Charge_For_Special_Days_Timeslot
          : '',
        Is_Enquiry: state.Is_Enquiry > 0 ? true : false,
        Is_Top_Category: state.Is_Top_Category > 0 ? true : false
      }}
      validationSchema={Yup.object().shape({
        Sv_Name: Yup.string()
          .min(3, 'Category name must be at least 3 characters.')
          .required('Category name is required.'),
        Status: Yup.string().required('Status is required.'),
        Service_Id: Yup.string().required('Service name is required.'),
        Ss_Id: Yup.string().required('Sub service name is required.'),
        Description: Yup.string()
          .min(20, 'Description must be at least 20 characters.')
          .required('Description is required.'),
        Sub_Description: Yup.string()
          .min(20, 'Sub description must be at least 20 characters.')
          .required('Sub description is required.'),
        Partner_Whats_Excluded: Yup.string()
          .min(20, 'Partner whats excluded  must be at least 20 characters.')
          .required('Partner whats excluded is required.'),
        Partner_Whats_Include: Yup.string()
          .min(20, 'Partner whats include must be at least 20 characters.')
          .required('Partner whats include is required.'),
        Partner_Whats_Expect_From_Us: Yup.string()
          .min(
            20,
            'Partner whats expect from Us must be at least 20 characters.'
          )
          .required('Partner whats expect from Us is required.'),
        Service_Charge_For_Special_Days_Timeslot: Yup.number().required(
          'Charge for special days timeslot is required.'
        ),
 
        Service_Charge_For_Timeslot: Yup.number().required(
          'Charge for timeslot is required.'
        ),
 
        Service_Charge_For_Weekend_Timeslot: Yup.number().required(
          'Charge for weekend timeslot is required.'
        )
      })}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        values.Image = imgUrl;
        if (!values.Image) {
          submitTest = false;
          errors.Image = ' Web image is required.';
        }
        values.Description = rhDiscription;
        if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
 
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          if (values.Is_Top_Category) {
            formValues.Is_Top_Category = 1;
          } else {
            formValues.Is_Top_Category = 0;
          }
          dispatch(
            EditServiceVarienttypeAction(
              formValues,
              navigate,
              state
            )
          );
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Sv_Name && errors.Sv_Name)}
                        fullWidth
                        helperText={touched.Sv_Name && errors.Sv_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Variant Name"
                        name="Sv_Name"
                        value={values.Sv_Name}
                        variant="outlined"
                      />
                      {touched.Sv_Name && errors.Sv_Name && (
                        <span style={{ color: 'red' }}>{errors.Sv_Name}</span>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Service_Id && errors.Service_Id)}
                        fullWidth
                        helperText={touched.Service_Id && errors.Service_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Service Name"
                        name="Service_Id"
                        value={services.find(service => service.Service_Id === values.Service_Id)?.Service_Name || ''}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        variant="outlined"
                      >
 
                      </TextField>
                    </Grid>
 
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Ss_Id && errors.Ss_Id)}
                        fullWidth
                        helperText={touched.Ss_Id && errors.Ss_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Sub service Name"
                        name="Ss_Id"
                        value={subServices.find(variant => variant.Ss_Id === values.Ss_Id)?.Ss_Name || ''}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        variant="outlined"
                      />
                    </Grid>
 
 
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.Service_Charge_For_Timeslot &&
                          errors.Service_Charge_For_Timeslot
                        )}
                        fullWidth
                        helperText={
                          touched.Service_Charge_For_Timeslot &&
                          errors.Service_Charge_For_Timeslot
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        label=" Charge For Timeslot(%)"
                        name="Service_Charge_For_Timeslot"
                        value={values.Service_Charge_For_Timeslot}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.Service_Charge_For_Weekend_Timeslot &&
                          errors.Service_Charge_For_Weekend_Timeslot
                        )}
                        fullWidth
                        helperText={
                          touched.Service_Charge_For_Weekend_Timeslot &&
                          errors.Service_Charge_For_Weekend_Timeslot
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        label=" Charge For Weekend Timeslot(%)"
                        name="Service_Charge_For_Weekend_Timeslot"
                        value={values.Service_Charge_For_Weekend_Timeslot}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.Service_Charge_For_Special_Days_Timeslot &&
                          errors.Service_Charge_For_Special_Days_Timeslot
                        )}
                        fullWidth
                        helperText={
                          touched.Service_Charge_For_Special_Days_Timeslot &&
                          errors.Service_Charge_For_Special_Days_Timeslot
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        label=" Charge For Special Days Timeslot(%)"
                        name="Service_Charge_For_Special_Days_Timeslot"
                        value={values.Service_Charge_For_Special_Days_Timeslot}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Enquiry}
                              onChange={handleChange}
                              name="Is_Enquiry"
                              color="primary"
                            />
                          }
                          label="Is Enquiry"
                        />
                      </Box>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Box>
                        <TextareaAutosize
                          className={classes.Sub_Description}
                          minRows={10}
                          placeholder="Sub_Description"
                          value={values.Sub_Description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="Sub_Description"
                        />
                      </Box>
                      {touched.Sub_Description && errors.Sub_Description && (
                        <span style={{ color: 'red' }}>
                          {errors.Sub_Description}
                        </span>
                      )}
                    </Grid>
 
                    <Grid item md={12} xs={12}>
                      <Box className={classes.Description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                      </span>
                    </Grid>
 
                    <Grid item md={12} xs={12}>
                      <TextareaAutosize
                        fullWidth
                        className={classes.Partner_Whats_Include}
                        minRows={10}
                        placeholder="Partner Whats Include"
                        value={values.Partner_Whats_Include}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="Partner_Whats_Include"
                      />
                      {touched.Partner_Whats_Include &&
                        errors.Partner_Whats_Include && (
                          <span style={{ color: 'red' }}>
                            {errors.Partner_Whats_Include}
                          </span>
                        )}
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextareaAutosize
                        className={classes.Partner_Whats_Excluded}
                        minRows={10}
                        placeholder="Partner Whats Excluded"
                        value={values.Partner_Whats_Excluded}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="Partner_Whats_Excluded"
                      />
                      {touched.Partner_Whats_Excluded &&
                        errors.Partner_Whats_Excluded && (
                          <span style={{ color: 'red' }}>
                            {errors.Partner_Whats_Excluded}
                          </span>
                        )}
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextareaAutosize
                        className={classes.Partner_Whats_Expect_From_Us}
                        minRows={10}
                        placeholder="Partner Whats Expect from Us"
                        value={values.Partner_Whats_Expect_From_Us}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="Partner_Whats_Expect_From_Us"
                      />
                      {touched.Partner_Whats_Expect_From_Us &&
                        errors.Partner_Whats_Expect_From_Us && (
                          <span style={{ color: 'red' }}>
                            {errors.Partner_Whats_Expect_From_Us}
                          </span>
                        )}
                    </Grid>
 
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {StatusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
 
                {/* upload photo */}
                <Grid item md={4} xs={12}>
                  <Card>
                    &nbsp;Web Image
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Brand Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>
 
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {image.preview ? (
                          <Button
                            style={{
                              fontSize: '10',
                              color: ' #425A70 ',
                              textTransform: 'none'
                            }}
                            onClick={handleRemoveImage}
                          >
                            Remove Picture
                          </Button>
                        ) : (
                          <input
                            style={{ display: 'block', width: '80%' }}
                            id="upload-photo"
                            name="image"
                            type="file"
                            accept="image/*"
                            onChange={handleImage}
                          />
                        )}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                  <br />
                  <span style={{ color: 'red' }}>
                    {!imgUrl && touched.Image && errors.Image}
                  </span>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" pl={2}>
              <span style={{ color: 'red' }}>
                {errorMessage.global.message}
              </span>
            </Box>
 
            <Box display="flex" p={2}>
              {isSubmitted ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={isSubmitted || uploadPic}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              )}
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};
 
EditServiceVarient.propTypes = {
  className: PropTypes.string
};
 
export default EditServiceVarient;