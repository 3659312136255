import {

    PART_BID_COMMENTS_UPDATE_REQUEST,
    PART_BID_COMMENTS_UPDATE_SUCCESS,
    PART_BID_COMMENTS_UPDATE_FAIL,

} from '../actions/partnerbiddingcommentsAction'

const initialState = {
    partnerbidcommentUpdate: { partnerbidcomment: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case PART_BID_COMMENTS_UPDATE_REQUEST:
            return { ...state, partnerbidcommentUpdate: { partnerbidcomment: {}, error: '', isLoading: true } };
        case PART_BID_COMMENTS_UPDATE_SUCCESS:
            return { ...state, partnerbidcommentUpdate: { partnerbidcomment: state.partnerbidcommentUpdate.partnerbidcomment, error: '', isLoading: false } };
        case PART_BID_COMMENTS_UPDATE_FAIL:
            return { ...state, partnerbidcommentUpdate: { partnerbidcomment: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}