import {
    ALL_COMMENTS_REQUEST,
    ALL_COMMENTS_SUCCESS,
    ALL_COMMENTS_FAIL,
    COMMENTS_REQUEST,
    COMMENTS_SUCCESS,
    COMMENTS_FAIL,

    COMMENTS_CREATE_REQUEST,
    COMMENTS_CREATE_SUCCESS,
    COMMENTS_CREATE_FAIL,

    COMMENTS_UPDATE_REQUEST,
    COMMENTS_UPDATE_SUCCESS,
    COMMENTS_UPDATE_FAIL,
} from '../actions/partnercommentsAction'

const initialState = {
    partnercomments: { all: [], error: '', isLoading: false },
    partnercomment: { partnercomment: {}, error: '', isLoading: false },
    commentCreate: { partnercomment: {}, error: '', isLoading: false },
    commentUpdate: { partnercomment: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case COMMENTS_REQUEST:
            return { ...state, partnercomments: { all: state.partnercomments.all, error: '', isLoading: true } };
        case COMMENTS_SUCCESS:
            return { ...state, partnercomments: { all: action.payload, error: '', isLoading: false } };
        case COMMENTS_FAIL:
            return { ...state, partnercomments: { all: [], error: action.payload, isLoading: false } };
        case ALL_COMMENTS_REQUEST:
            return { ...state, partnercomment: { partnercomment: {}, error: '', isLoading: true } };
        case ALL_COMMENTS_SUCCESS:
            return { ...state, partnercomment: { partnercomment: action.payload, error: '', isLoading: false } };
        case ALL_COMMENTS_FAIL:
            return { ...state, partnercomment: { partnercomment: {}, error: action.payload, isLoading: false } };
        case COMMENTS_CREATE_REQUEST:
            return { ...state, commentCreate: { partnercomment: {}, error: '', isLoading: true } };
        case COMMENTS_CREATE_SUCCESS:
            return { ...state, commentCreate: { partnercomment: state.commentCreate.partnercomment, error: '', isLoading: false } };
        case COMMENTS_CREATE_FAIL:
            return { ...state, commentCreate: { partnercomment: {}, error: action.payload, isLoading: false } };
        case COMMENTS_UPDATE_REQUEST:
            return { ...state, commentUpdate: { partnercomment: {}, error: '', isLoading: true } };
        case COMMENTS_UPDATE_SUCCESS:
            return { ...state, commentUpdate: { partnercomment: state.commentUpdate.partnercomment, error: '', isLoading: false } };
        case COMMENTS_UPDATE_FAIL:
            return { ...state, commentUpdate: { partnercomment: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}