import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    makeStyles,
    TextareaAutosize,
    TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { createB2BUserProdBiddingQuotes, getAllB2BBiddingQuotePartners, getAllB2BBidQuotePartLocation } from 'src/store/actions/b2buserprodbidquotesAction';

const statusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const bidstatusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'INITIATED',
        label: 'INITIATED'
    },
    {
        value: 'PROCESSING',
        label: 'PROCESSING'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
    {
        value: 'COMPLETED',
        label: 'COMPLETED'
    }
];

const useStyles = makeStyles(() => ({
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const AddB2BUserProdBiddingQuote = ({ className, state, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        let formData = {
            search_by_filter: "",
            search: "",
            Records_Filter: 'FILTER',
            Product_Id: state.Bidding_Product_Id,
        };
        dispatch(getAllB2BBiddingQuotePartners(formData));
    }, []);

    const [locationdata, setLocationData] = React.useState([]);
    let USERLOCATION = []
    if (locationdata) {
        for (let i = 0; i < locationdata?.length; i++) {
            USERLOCATION.push(
                {
                    label: locationdata[i].Partner_Address_Id,
                    value: locationdata[i].Partner_Address_Id,
                })
        }
    }

    const callBackUserLocationData = (data) => {
        setLocationData(data)
    }

    const setLocationType = (value) => {
        if (value !== "") {
            let data = {
                Records_Filter: "FILTER",
                Partner_Details_Id: value,
                search_by_filter: "",
                search: "",
            }
            dispatch(getAllB2BBidQuotePartLocation(data, callBackUserLocationData))
        }
    }

    const isSubmitted = useSelector(state => state.b2buserprodbidquote.b2buserprodbidquoteCreate.isLoading);
    const errorMessage = useSelector(state => state.b2buserprodbidquote.b2buserprodbidquoteCreate.error);
    const bidpartners = useSelector(state => state.b2buserprodbidquote.b2buserprodbidquotepartners.all);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    return (
        <Formik
            initialValues={{
                B2B_User_Products_Bidding_Id: state.B2B_User_Products_Bidding_Id,
                Partner_Detail_Id: '',
                Advance_Token_Amount: '',
                Quotation_Amount: '',
                Bidding_Status: '',
                Partner_Comments: '',
                Partner_Location: '',
                Status: '',
                Created_By: loginEmail,
            }}
            validationSchema={
                Yup.object().shape({
                    Partner_Detail_Id: Yup.string().required('Partner Name is required.'),
                    Bidding_Status: Yup.string().required('Bidding Status is required.'),
                    Partner_Location: Yup.string().required('Partner Location is required.'),
                    Status: Yup.string().required('Status is required.'),
                    Advance_Token_Amount: Yup.number().positive('Advance Token Amount must be a positive number.')
                        .required('Advance Token Amount is required.'),
                    Quotation_Amount: Yup.number().positive("Quotation Amount must be a positive number.").moreThan(Yup.ref('Advance_Token_Amount'), 'Require Quotation Amount greater than Advance Token Amount.')
                    .required('Quotation Amount is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(createB2BUserProdBiddingQuotes(formValues, navigate, state));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Detail_Id && errors.Partner_Detail_Id)}
                                                fullWidth
                                                helperText={touched.Partner_Detail_Id && errors.Partner_Detail_Id}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setLocationType(e.target.value)
                                                }}
                                                label="Partner Name"
                                                name="Partner_Detail_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Partner_Detail_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {bidpartners?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map((option, i) => (
                                                    <option key={i} value={option.Partner_Detail_Id}>
                                                        {option.Partner_Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Location && errors.Partner_Location)}
                                                fullWidth
                                                helperText={touched.Partner_Location && errors.Partner_Location}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Location"
                                                name="Partner_Location"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Partner_Location}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {locationdata?.map(option => (
                                                    <option key={option.Partner_Address_Id} value={option.Partner_Address_Id}>
                                                        {option.Location}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Advance_Token_Amount && errors.Advance_Token_Amount)}
                                                fullWidth
                                                helperText={touched.Advance_Token_Amount && errors.Advance_Token_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Token Amount"
                                                name="Advance_Token_Amount"
                                                value={values.Advance_Token_Amount}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Quotation_Amount && errors.Quotation_Amount)}
                                                fullWidth
                                                helperText={touched.Quotation_Amount && errors.Quotation_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Quotation Amount"
                                                name="Quotation_Amount"
                                                value={values.Quotation_Amount}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Status && errors.Bidding_Status)}
                                                fullWidth
                                                helperText={touched.Bidding_Status && errors.Bidding_Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Status"
                                                name="Bidding_Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Status}
                                                variant="outlined"
                                            >
                                                {bidstatusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            Partner Comments
                                            <TextareaAutosize
                                                value={values.Partner_Comments}
                                                onChange={handleChange}
                                                name="Partner_Comments"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Partner Comments"
                                            >
                                            </TextareaAutosize>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddB2BUserProdBiddingQuote.propTypes = {
    className: PropTypes.string
};

export default AddB2BUserProdBiddingQuote;