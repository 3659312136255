import { nodeGetApi } from './commonAxios';

export const CORPORATEDATEANDCOUNT_REQUEST = 'CORPORATEDATEANDCOUNT_REQUEST';
export const CORPORATEDATEANDCOUNT_SUCCESS = "CORPORATEDATEANDCOUNT_SUCCESS";
export const CORPORATEDATEANDCOUNT_FAIL = "CORPORATEDATEANDCOUNT_FAIL";

/*=============================================================
          Get ESI Main Center Date and count Action
===============================================================*/
export const corporateDateandCount = () => async dispatch => {
  try {
    dispatch({
      type: CORPORATEDATEANDCOUNT_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Total_Count_And_Date/Corporate_Management`);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CORPORATEDATEANDCOUNT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CORPORATEDATEANDCOUNT_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CORPORATEDATEANDCOUNT_FAIL,
      payload: err
    });
  }
};