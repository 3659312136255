import {
    BID_RIDE_BRAND_VEHI_CREATE_REQUEST,
    BID_RIDE_BRAND_VEHI_CREATE_SUCCESS,
    BID_RIDE_BRAND_VEHI_CREATE_FAIL,

    BID_RIDE_BRAND_VEHI_UPDATE_REQUEST,
    BID_RIDE_BRAND_VEHI_UPDATE_SUCCESS,
    BID_RIDE_BRAND_VEHI_UPDATE_FAIL,

    BID_RIDE_VEHICLES_GET_REQUEST,
    BID_RIDE_VEHICLES_GET_SUCCESS,
    BID_RIDE_VEHICLES_GET_FAIL,

    BID_RIDE_VEHI_BRANDS_GET_REQUEST,
    BID_RIDE_VEHI_BRANDS_GET_SUCCESS,
    BID_RIDE_VEHI_BRANDS_GET_FAIL,
} from '../actions/biddingridebrandvehiclesAction'

const initialState = {
    bidridebrandvehicleCreate: { bidridebrandvehicle: {}, error: '', isLoading: false },
    bidridebrandvehicleUpdate: { bidridebrandvehicle: {}, error: '', isLoading: false },
    bidridevehicles: { all: [], error: '', isLoading: false },
    bidridevehibrands: { all: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case BID_RIDE_BRAND_VEHI_CREATE_REQUEST:
            return { ...state, bidridebrandvehicleCreate: { bidridebrandvehicle: {}, error: '', isLoading: true } };
        case BID_RIDE_BRAND_VEHI_CREATE_SUCCESS:
            return { ...state, bidridebrandvehicleCreate: { bidridebrandvehicle: state.bidridebrandvehicleCreate.bidridebrandvehicle, error: '', isLoading: false } };
        case BID_RIDE_BRAND_VEHI_CREATE_FAIL:
            return { ...state, bidridebrandvehicleCreate: { bidridebrandvehicle: {}, error: action.payload, isLoading: false } };

        case BID_RIDE_BRAND_VEHI_UPDATE_REQUEST:
            return { ...state, bidridebrandvehicleUpdate: { bidridebrandvehicle: {}, error: '', isLoading: true } };
        case BID_RIDE_BRAND_VEHI_UPDATE_SUCCESS:
            return { ...state, bidridebrandvehicleUpdate: { bidridebrandvehicle: state.bidridebrandvehicleUpdate.bidridebrandvehicle, error: '', isLoading: false } };
        case BID_RIDE_BRAND_VEHI_UPDATE_FAIL:
            return { ...state, bidridebrandvehicleUpdate: { bidridebrandvehicle: {}, error: action.payload, isLoading: false } };

        case BID_RIDE_VEHICLES_GET_REQUEST:
            return { ...state, bidridevehicles: { all: state.bidridevehicles.all, error: '', isLoading: true } };
        case BID_RIDE_VEHICLES_GET_SUCCESS:
            return { ...state, bidridevehicles: { all: action.payload, error: '', isLoading: false } };
        case BID_RIDE_VEHICLES_GET_FAIL:
            return { ...state, bidridevehicles: { all: [], error: action.payload, isLoading: false } };

        case BID_RIDE_VEHI_BRANDS_GET_REQUEST:
            return { ...state, bidridevehibrands: { all: state.bidridevehibrands.all, error: '', isLoading: true } };
        case BID_RIDE_VEHI_BRANDS_GET_SUCCESS:
            return { ...state, bidridevehibrands: { all: action.payload, error: '', isLoading: false } };
        case BID_RIDE_VEHI_BRANDS_GET_FAIL:
            return { ...state, bidridevehibrands: { all: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}