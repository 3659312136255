import {
    BUSSINESS_SUBTYPE_CREATE_REQUEST,
    BUSSINESS_SUBTYPE_CREATE_SUCCESS,
    BUSSINESS_SUBTYPE_CREATE_FAIL,
    BUSSINESS_SUBTYPE_UPDATE_REQUEST,
    BUSSINESS_SUBTYPE_UPDATE_SUCCESS,
    BUSSINESS_SUBTYPE_UPDATE_FAIL,
} from '../actions/bussinesssubtypeAction';

const initialState = {
    bussinessSubtypeCreate: { bussinessSubtype: {}, error: '', isLoading: false },
    bussinessSubtypeUpdate: { bussinessSubtype: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case BUSSINESS_SUBTYPE_CREATE_REQUEST:
            return { ...state, bussinessSubtypeCreate: { bussinessSubtype: {}, error: '', isLoading: true } };
        case BUSSINESS_SUBTYPE_CREATE_SUCCESS:
            return { ...state, bussinessSubtypeCreate: { bussinessSubtype: state.bussinessSubtypeCreate.bussinessSubtype, error: '', isLoading: false } };
        case BUSSINESS_SUBTYPE_CREATE_FAIL:
            return { ...state, bussinessSubtypeCreate: { bussinessSubtype: {}, error: action.payload, isLoading: false } };
        case BUSSINESS_SUBTYPE_UPDATE_REQUEST:
            return { ...state, bussinessSubtypeUpdate: { bussinessSubtype: {}, error: '', isLoading: true } };
        case BUSSINESS_SUBTYPE_UPDATE_SUCCESS:
            return { ...state, bussinessSubtypeUpdate: { bussinessSubtype: state.bussinessSubtypeUpdate.bussinessSubtype, error: '', isLoading: false } };
        case BUSSINESS_SUBTYPE_UPDATE_FAIL:
            return { ...state, bussinessSubtypeUpdate: { bussinessSubtype: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}