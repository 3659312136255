import { v4 as uuid } from 'uuid';

export default [
    {
        id: uuid(),
        hide: 0,
        link: '/app/user-ride-biddings',
        createdAt: '',
        description: 'Bidding is an offer to set a price tag by an individual or business for a product that something be done. Bidding is used to determine the cost or value of something.',
        media: '/static/Dashboard/bidding.png',
        title: 'User Ride Biddings',
        total: ''
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/bidding-vehicles',
        createdAt: '',
        description: 'Bidding is an offer to set a price tag by an individual or business for a Service that something be done. Bidding is used to determine the cost or value of something.',
        media: '/static/Dashboard/bidding.png',
        title: "Bidding Ride Vehicles",
        total: ""
    },  
    {
        id: uuid(),
        hide: 0,
        link: '/app/bidding-ride-vehicle-brands',
        createdAt: '',
        description: 'Bidding is an offer to set a price tag by an individual or business for a Service that something be done. Bidding is used to determine the cost or value of something.',
        media: '/static/Dashboard/bidding.png',
        title: "Bidding Ride Vehicle Brands",
        total: ""
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/bidding-ride-brand-vehicles',
        createdAt: '',
        description: 'Bidding is an offer to set a price tag by an individual or business for a Service that something be done. Bidding is used to determine the cost or value of something.',
        media: '/static/Dashboard/bidding.png',
        title: "Bidding Ride Brand Vehicles",
        total: ""
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/delivery-bidding-vehicle-commission',
        createdAt: '',
        description: 'Bidding is an offer to set a price tag by an individual or business for a Service that something be done. Bidding is used to determine the cost or value of something.',
        media: '/static/Dashboard/bidding.png',
        title: "Delivery Bidding Vehicle Commission",
        total: ""
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/delivery-bidding-success-stories',
        createdAt: '',
        description: 'Bidding is an offer to set a price tag by an individual or business for a Ride that something be done. Bidding is used to determine the cost or value of something.',
        media: '/static/Dashboard/bidding.png',
        title: 'Delivery Bidding Success Stories',
        total: ''
    }
];