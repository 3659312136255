import { toast } from 'react-toastify';
import config from './config';
import { paginationGiftImage } from './node-actions/nodepagination';
import { nodeGetApi, boomiAdminApi } from './commonAxios';

export const GIFTIMAGE_CREATE_REQUEST = "GIFTIMAGE_CREATE_REQUEST";
export const GIFTIMAGE_CREATE_SUCCESS = "GIFTIMAGE_CREATE_SUCCESS";
export const GIFTIMAGE_CREATE_FAIL = "GIFTIMAGE_CREATE_FAIL";
export const GIFTIMAGE_UPDATE_REQUEST = "GIFTIMAGE_UPDATE_REQUEST";
export const GIFTIMAGE_UPDATE_SUCCESS = "GIFTIMAGE_UPDATE_SUCCESS";
export const GIFTIMAGE_UPDATE_FAIL = "GIFTIMAGE_UPDATE_FAIL";

export const GIFT_VENDORID_REQUEST = "GIFT_VENDORID_REQUEST";
export const GIFT_VENDORID_SUCCESS = "GIFT_VENDORID_SUCCESS";
export const GIFT_VENDORID_FAIL = "GIFT_VENDORID_FAIL";

export const uploadcreateGiftImage = (formData, image, navigate, setError, setGlobalError) => async dispatch => {
  dispatch({
    type: GIFTIMAGE_CREATE_REQUEST
  });
  boomiAdminApi.post(`/Gift_Voucher/Image_Upload?filename=BTS&file_extension=jpg&filetype=Image&
      created_by=${formData.Created_By}&occassion=${formData.Occassion}&status=${formData.Status}&type=${formData.Type}&vendor_id=${formData.Vendor_Id}`,
    image,
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: GIFTIMAGE_CREATE_SUCCESS
        });
        toast('Gift image added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/gift-images');
      } else {
        setGlobalError(successResponse.UI_Display_Message);
        dispatch({
          type: GIFTIMAGE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setGlobalError("Currently server is not working. Please try again later.");
      dispatch({
        type: GIFTIMAGE_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
}

/*=============================================================
                Update gift image Action
===============================================================*/
export const updateGiftImageAction = (formData, image, navigate, setError, setGlobalError) => async dispatch => {
  dispatch({
    type: GIFTIMAGE_UPDATE_REQUEST
  });
  if (image) {
    boomiAdminApi.post(`/Gift_Voucher/Image_Update?filename=BTS&file_extension=jpg&filetype=Image&
      modified_by=${formData.Modified_By}&occassion=${formData.Occassion}&status=${formData.Status}&type=${formData.Type}&vendor_id=${formData.Vendor_Id}&gift_image_id=${formData.Gift_Image_Id}`,
      image,
    )
      .then(response => {
        let successResponse = response.data;
        if (successResponse.Response_Status === "Success") {
          dispatch({
            type: GIFTIMAGE_UPDATE_SUCCESS
          });
          toast('Gift image updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
          navigate('/app/gift-images');
        } else {
          setGlobalError(successResponse.UI_Display_Message);
          dispatch({
            type: GIFTIMAGE_UPDATE_FAIL,
            payload: successResponse.UI_Display_Message
          });
        }
      })
      .catch(error => {
        setGlobalError("Currently server is not working. Please try again later.");
        dispatch({
          type: GIFTIMAGE_UPDATE_FAIL,
          payload: "Currently server is not working. Please try again later."
        });
      })
  }
  else {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    try {
      let { data } = await boomiAdminApi.post(`/Gift_Voucher/Gift_Image_Upload_Update`,
        {
          "Gift_Image_Id": formData.Gift_Image_Id,
          "Gift_Vendor_Id": formData.Vendor_Id,
          "Modified_By": formData.Modified_By,
          "Modified_Date": today,
          "Occassion": formData.Occassion,
          "Status": formData.Status,
          "Type": formData.Type
        },
      );
      if (data) {
        if (data.Response_Status === "Success") {
          dispatch({
            type: GIFTIMAGE_UPDATE_SUCCESS
          });
          toast('Gift image updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
          navigate('/app/gift-images');
        } else {
          setGlobalError(data.UI_Display_Message);
          dispatch({
            type: GIFTIMAGE_UPDATE_FAIL,
            payload: data.UI_Display_Message
          });
        }
      }
    } catch (err) {
      setGlobalError("Currently server is not working. Please try again later.");
      dispatch({
        type: GIFTIMAGE_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    }
  }
}

/*=============================================================
              Delete  gift image Action
===============================================================*/
export const deleteGiftImageAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/Gift_Voucher/Image_Delete`, formData);
    if (data) {
      toast('Gift image deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationGiftImage(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

//node API
export const getAllgiftvendorsids = () => async dispatch => {
  try {
    let formData = {
      "Records_Filter": "FILTER",
      "Status": "Active",
      search: '',
      search_by_filter: ''
    };
    dispatch({
      type: GIFT_VENDORID_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Gift_Voucher/Vendor_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=VENDOR_NAME&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: GIFT_VENDORID_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: GIFT_VENDORID_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: GIFT_VENDORID_FAIL,
      payload: JSON.stringify(err)
    });
  }
};