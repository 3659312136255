import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createRazorpayPaymentsAction } from 'src/store/actions/razorpaypaymentsActions';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/material.css'

const AddRazorpay = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSubmited = useSelector(state => state.razorpaypayment.razorpaypaymentsCreate.isLoading);
  const errorMessage = useSelector(state => state.razorpaypayment.razorpaypaymentsCreate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        Name: '',
        Email: '',
        Mobile: '',
        Created_By: loginEmail,
        UPI_ID: '',
      }}
      validationSchema={Yup.object().shape({
        Name: Yup.string().required('Name is required.'),
        Email: Yup.string().email("Please provide a valid Email ID. ").required('Email ID is required.'),
        Mobile: Yup.string().min(14, "Please provide a valid Mobile Number.").required('Mobile Number is required.'),
        UPI_ID: Yup.string().required('UPI ID is required.'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        if (values) {
          let formData = JSON.parse(JSON.stringify(values));
          dispatch(createRazorpayPaymentsAction(formData, navigate));
        }
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Name && errors.Name)}
                        fullWidth
                        helperText={touched.Name && errors.Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Name"
                        name="Name"
                        value={values.Name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Email && errors.Email)}
                        fullWidth
                        helperText={touched.Email && errors.Email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Email"
                        name="Email"
                        value={values.Email}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Mobile */}
                    <Grid item md={6} xs={12}>
                      <PhoneInput
                        containerClass={touched.Mobile && errors.Mobile ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                        inputClass={"form-control MuiOutlinedInput-input"}
                        error={true}
                        country={"in"}
                        fullWidth
                        inputStyle={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px"
                        }}
                        inputProps={{
                          name: "Mobile",
                        }}
                        specialLabel="Mobile Number"
                        placeholder="+91 XXXXX XXXXX"
                        name="Mobile"
                        onBlur={handleBlur}
                        onChange={(value, country, e, formattedValue) => {
                          setFieldValue("Mobile", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                        }}
                        value={values.Mobile}
                      />
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Mobile && errors.Mobile}</p>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.UPI_ID && errors.UPI_ID)}
                        fullWidth
                        helperText={touched.UPI_ID && errors.UPI_ID}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="UPI ID"
                        name="UPI_ID"
                        value={values.UPI_ID}
                        variant="outlined"
                      />
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              <Button
                disabled={isSubmited}
                type="submit"
                style={{ backgroundColor: '#27B6CC' }}
                variant="contained"
              >
                {/* Save details */}
                <span style={{ color: 'white' }}>Save details</span>
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddRazorpay.propTypes = {
  className: PropTypes.string
};

export default AddRazorpay;