import {
    BIDDING_SERVICE_SUCCESS_STORIES_UPDATE_REQUEST,
    BIDDING_SERVICE_SUCCESS_STORIES_UPDATE_SUCCESS,
    BIDDING_SERVICE_SUCCESS_STORIES_UPDATE_FAIL,
    BIDDING_SERVICE_SUCCESS_STORIES_CREATE_REQUEST,
    BIDDING_SERVICE_SUCCESS_STORIES_CREATE_SUCCESS,
    BIDDING_SERVICE_SUCCESS_STORIES_CREATE_FAIL,
    PHYSICAL_PARTNERS_REQUEST,
    PHYSICAL_PARTNERS_SUCCESS,
    PHYSICAL_PARTNERS_FAIL,
    BIDDING_SERVICES_REQUEST,
    BIDDING_SERVICES_SUCCESS,
    BIDDING_SERVICES_FAIL,

} from '../actions/biddingservicesuccessstoriesAction';

const initialState = {
    biddingservicesuccessstoryUpdate: { biddingservicesuccessstories: {}, error: '', isLoading: false },
    biddingservicesuccessstoryCreate: { biddingservicesuccessstories: {}, error: '', isLoading: false },
    PhysicalPartners: { partners: [], error: '', isLoading: false  },
    BiddingServices: { services: [], error: '', isLoading: false },
};


export default function (state = initialState, action) {
    switch (action.type) {
        case BIDDING_SERVICE_SUCCESS_STORIES_CREATE_REQUEST:
            return { ...state, biddingservicesuccessstoryCreate: { biddingservicesuccessstory: {}, error: '', isLoading: true } };
        case BIDDING_SERVICE_SUCCESS_STORIES_CREATE_SUCCESS:
            return { ...state, biddingservicesuccessstoryCreate: { biddingservicesuccessstory: state.biddingservicesuccessstoryCreate.biddingservicesuccessstory, error: '', isLoading: false } };
        case BIDDING_SERVICE_SUCCESS_STORIES_CREATE_FAIL:
            return { ...state, biddingservicesuccessstoryCreate: { biddingservicesuccessstory: {}, error: action.payload, isLoading: false } };
        case BIDDING_SERVICE_SUCCESS_STORIES_UPDATE_REQUEST:
            return { ...state, biddingservicesuccessstoryUpdate: { biddingservicesuccessstory: {}, error: '', isLoading: true } };
        case BIDDING_SERVICE_SUCCESS_STORIES_UPDATE_SUCCESS:
            return { ...state, biddingservicesuccessstoryUpdate: { biddingservicesuccessstory: state.biddingservicesuccessstoryUpdate.biddingservicesuccessstory, error: '', isLoading: false } };
        case BIDDING_SERVICE_SUCCESS_STORIES_UPDATE_FAIL:
            return { ...state, biddingservicesuccessstoryUpdate: { biddingservicesuccessstory: {}, error: action.payload, isLoading: false } };
        case PHYSICAL_PARTNERS_REQUEST:
            return { ...state, PhysicalPartners: { partners: state.PhysicalPartners.partners, error: '', isLoading: true }, };
        case PHYSICAL_PARTNERS_SUCCESS:
            return { ...state, PhysicalPartners: { partners: action.payload, error: '', isLoading: false } };
        case PHYSICAL_PARTNERS_FAIL:
            return { ...state, PhysicalPartners: { partners: [], error: action.payload, isLoading: false } };
        case BIDDING_SERVICES_REQUEST:
            return { ...state, BiddingServices: { services: state.BiddingServices.services, error: '', isLoading: true }, };
        case BIDDING_SERVICES_SUCCESS:
            return { ...state, BiddingServices: { services: action.payload, error: '', isLoading: false } };
        case BIDDING_SERVICES_FAIL:
            return { ...state, BiddingServices: { services: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}