import config from './config';
import { toast } from 'react-toastify';
import { paginationCoupons, paginationPendingCoupons } from './node-actions/nodepagination';
import { boomiAdminApi, nodeCudApi, nodeGetApi } from './commonAxios';

export const COUPONS_REQUEST = "COUPONS_REQUEST";
export const COUPONS_SUCCESS = "COUPONS_SUCCESS";
export const COUPONS_FAIL = "COUPONS_FAIL";
export const COUPON_GET_REQUEST = "COUPON_GET_REQUEST";
export const COUPON_GET_SUCCESS = "COUPON_GET_SUCCESS";
export const COUPON_GET_FAIL = "COUPON_GET_FAIL";
export const COUPON_CREATE_REQUEST = "COUPON_CREATE_REQUEST";
export const COUPON_CREATE_SUCCESS = "COUPON_CREATE_SUCCESS";
export const COUPON_CREATE_FAIL = "COUPON_CREATE_FAIL";
export const COUPON_UPDATE_REQUEST = "COUPON_UPDATE_REQUEST";
export const COUPON_UPDATE_SUCCESS = "COUPON_UPDATE_SUCCESS";
export const COUPON_UPDATE_FAIL = "COUPON_UPDATE_FAIL";
export const COUPON_PARTNERS_REQUEST = "COUPON_PARTNERS_REQUEST";
export const COUPON_PARTNERS_SUCCESS = "COUPON_PARTNERS_SUCCESS";
export const COUPON_PARTNERS_FAIL = "COUPON_PARTNERS_FAIL";
export const COUPON_BRANDS_REQUEST = "COUPON_BRANDS_REQUEST";
export const COUPON_BRANDS_SUCCESS = "COUPON_BRANDS_SUCCESS";
export const COUPON_BRANDS_FAIL = "COUPON_BRANDS_FAIL";
export const COUPONS_APPROVE_REQUEST = "COUPONS_APPROVE_REQUEST";
export const COUPONS_APPROVE_SUCCESS = "COUPONS_APPROVE_SUCCESS";
export const COUPONS_APPROVE_FAIL = "COUPONS_APPROVE_FAIL";

/*=============================================================
                  Add Coupon Action
===============================================================*/
export const createCouponAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: COUPON_CREATE_REQUEST
  });
  setErrorMessage({ Coupon_Code: { key: '', message: '' }, Coupon_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Coupons/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: COUPON_CREATE_SUCCESS
        });
        toast('Coupon added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/coupon-management');
      } else {
        const errors = {};
        if (successResponse.Coupon_Code) {
          errors.Code = successResponse.UI_Display_Message;
        }
        if (successResponse.Coupon_Name) {
          errors.Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Coupon_Code: { key: successResponse.Coupon_Code ? successResponse.Coupon_Code : '', message: successResponse.Coupon_Code ? successResponse.UI_Display_Message : '' }, Coupon_Name: { key: successResponse.Coupon_Name ? successResponse.Coupon_Name : '', message: successResponse.Coupon_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Coupon_Name || successResponse.Coupon_Code) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: COUPON_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Coupon_Code: { key: '', message: '' }, Coupon_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: COUPON_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                  Update Coupon Action
===============================================================*/
export const updateCouponAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: COUPON_UPDATE_REQUEST
  });
  setErrorMessage({ Coupon_Code: { key: '', message: '' }, Coupon_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Coupons/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: COUPON_UPDATE_SUCCESS
        });
        toast('Coupon updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/coupon-management');
      } else {
        const errors = {};
        if (successResponse.Coupon_Code) {
          errors.Code = successResponse.UI_Display_Message;
        }
        if (successResponse.Coupon_Name) {
          errors.Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Coupon_Code: { key: successResponse.Coupon_Code ? successResponse.Coupon_Code : '', message: successResponse.Coupon_Code ? successResponse.UI_Display_Message : '' }, Coupon_Name: { key: successResponse.Coupon_Name ? successResponse.Coupon_Name : '', message: successResponse.Coupon_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Coupon_Name || successResponse.Coupon_Code) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: COUPON_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Coupon_Code: { key: '', message: '' }, Coupon_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: COUPON_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Coupon Image Upload Action
===============================================================*/
export const uploadCouponImage = (
  formData,
  callBackUploadImgae
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=Coupons&fileextension=png&filetype=Images&filename=Coupon`, formData);
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};

/*=============================================================
                  Get All Coupons Action
===============================================================*/
export const getAllCouponAction = formData => async dispatch => {
  try {
    dispatch({
      type: COUPONS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Coupons/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Coupon_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: COUPONS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: COUPONS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: COUPONS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Delete Coupons Action
===============================================================*/
export const deleteCouponAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Coupons/Delete`, formData);
    if (data) {
      toast('Coupon deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationCoupons(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

// Node API
export const getSingleCoupon = (formData, callBackCouponData) => async dispatch => {
  try {
    let { data, status } = await nodeGetApi.post(`/Coupons/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Coupon_Id&SortOrder=ASC`, formData);
    if (data) {
      callBackCouponData(data)
    }
  } catch (err) {
  }
};

/*=============================================================
                  Get All partners Action
===============================================================*/
export const getAllPartners = () => async dispatch => {
  try {
    let formData = {
      search: "",
      search_by_filter: "",
      Records_Filter: 'FILTER',
      Status: "Active"
    };
    dispatch({
      type: COUPON_PARTNERS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Partners/OnlineAndPhysical_Details_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: COUPON_PARTNERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: COUPON_PARTNERS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: COUPON_PARTNERS_FAIL,
      payload: JSON.stringify(err)
    });
  }
};

/*=============================================================
                  Get All Brands Action
===============================================================*/
export const getAllBrands = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Brand_Status: "Active",
      search_by_filter: "",
      search: "",
    };
    dispatch({
      type: COUPON_BRANDS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Brand/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=BRAND_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: COUPON_BRANDS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: COUPON_BRANDS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: COUPON_BRANDS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  Get All ApprovalCoupon Action
===============================================================*/
export const getAllApprovalCouponAction = formData => async dispatch => {
  try {
    dispatch({
      type: COUPONS_APPROVE_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Coupons/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Coupon_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: COUPONS_APPROVE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: COUPONS_APPROVE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: COUPONS_APPROVE_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  Update ApprovalCoupon Action
===============================================================*/
export const updateCouponApprovalAction = (formData, approvecouponData, filters, pagination, callBackPagination) => async dispatch => {
  dispatch({
    type: COUPON_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Coupons/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: COUPON_UPDATE_SUCCESS
        });
        toast('Coupon has been Accepted.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        dispatch(paginationPendingCoupons(filters, pagination, callBackPagination));
        dispatch(getAllCouponAction(approvecouponData));
      } else {
        const errors = {};
        dispatch({
          type: COUPON_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: COUPON_UPDATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};