import { toast } from 'react-toastify';
import { boomiAdminApi, nodeCudApi } from './commonAxios';

export const BIDDING_PRODUCTS_CATEGORY_CREATE_REQUEST = "BIDDING_PRODUCTS_CATEGORY_CREATE_REQUEST";
export const BIDDING_PRODUCTS_CATEGORY_CREATE_SUCCESS = "BIDDING_PRODUCTS_CATEGORY_CREATE_SUCCESS";
export const BIDDING_PRODUCTS_CATEGORY_CREATE_FAIL = "BIDDING_PRODUCTS_CATEGORY_CREATE_FAIL";

export const BIDDING_PRODUCTS_CATEGORY_UPDATE_REQUEST = "BIDDING_PRODUCTS_CATEGORY_UPDATE_REQUEST";
export const BIDDING_PRODUCTS_CATEGORY_UPDATE_SUCCESS = "BIDDING_PRODUCTS_CATEGORY_UPDATE_SUCCESS";
export const BIDDING_PRODUCTS_CATEGORY_UPDATE_FAIL = "BIDDING_PRODUCTS_CATEGORY_UPDATE_FAIL";

/*=============================================================
                  Add Bidding Products Category Action
===============================================================*/
export const addBiddingProductsCategoryAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: BIDDING_PRODUCTS_CATEGORY_CREATE_REQUEST
  });
  setErrorMessage({ Bidding_Products_Category_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Bidding_Products_Category/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BIDDING_PRODUCTS_CATEGORY_CREATE_SUCCESS
        });
        toast('Bidding Products Category added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/bidding-category');
      } else {
        const errors = {};
        if (successResponse.Bidding_Products_Category_Name) {
          errors.Bidding_Products_Category_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Bidding_Products_Category_Name: { key: successResponse.Bidding_Products_Category_Name ? successResponse.Bidding_Products_Category_Name : '', message: successResponse.Bidding_Products_Category_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Bidding_Products_Category_Name) ? successResponse.UI_Display_Message : '' } });

        dispatch({
          type: BIDDING_PRODUCTS_CATEGORY_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Bidding_Products_Category_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: BIDDING_PRODUCTS_CATEGORY_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};

/*=============================================================
                Update Category Action
===============================================================*/
export const updateBiddingProductsCategory = (formData, navigate) => async dispatch => {
  dispatch({
    type: BIDDING_PRODUCTS_CATEGORY_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Bidding_Products_Category/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BIDDING_PRODUCTS_CATEGORY_UPDATE_SUCCESS
        });
        toast('Bidding Products Category updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/bidding-category');
      } else {
        dispatch({
          type: BIDDING_PRODUCTS_CATEGORY_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: BIDDING_PRODUCTS_CATEGORY_UPDATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};

/*=============================================================
        Bidding Products Category Image Upload Action
===============================================================*/
export const uploadCategoryImage = (
  formData,
  callBackUploadImgae
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=BiddingCategory&fileextension=png&filetype=Images&filename=BiddingCategory`, formData);
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};