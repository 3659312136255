import React from 'react';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button,
  Card,
  CardContent
} from '@material-ui/core';
import Page from 'src/components/Page';
import AddCorporateAward from './AddCorporateAward';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AddCorporateAwardView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Card>
          <CardContent>
            <Button
              component={RouterLink}
              to="/app/corporate-award-management"
              variant="text"
              size="small"
              color="inherit"
            >
              <KeyboardBackspaceIcon />
              Go back
            </Button>
            <Box py={2}>
              <Typography variant="h2" gutterBottom>
                Award template creation
              </Typography>
            </Box>
            <AddCorporateAward />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AddCorporateAwardView;