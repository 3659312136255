import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import {useSelector } from 'react-redux';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import { useLocation } from 'react-router-dom';
import isEmpty from 'src/store/validations/is-empty';
import PartnerWalletHistoryView from './PartnerWalletHistoryView';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const PartnerWalletHistoryList = () => {
    const location = useLocation();
    const classes = useStyles();
    const { state } = location;
    const [alldeliwallethistory, setDeliwallethistory] = useState([]);
    let deliwallethistory = useSelector(state => state.deliverywallet.alldeliwallethistory);

    useEffect(() => {
        if (!isEmpty(deliwallethistory)) {
            setDeliwallethistory(deliwallethistory.deliverywallet);
        }
    }, [deliwallethistory]);

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/partnerwallethistory-management"
                    variant="text"
                    size="small"
                    color="inherit"
                    state={state?.state}
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        View Partner Wallet History
                    </Typography>
                </Box>
                <PartnerWalletHistoryView state={state?.state} data={state?.data} alldeliwallethistory={alldeliwallethistory} />
            </Container>
        </Page>
    );
};

export default PartnerWalletHistoryList;