import { toast } from 'react-toastify';
import { paginationAllCorpPopupGet } from './node-actions/nodepagination';
import { nodeCorpMongoApi } from './commonAxios';

export const CORP_POPUP_CREATE_REQUEST = "CORP_POPUP_CREATE_REQUEST";
export const CORP_POPUP_CREATE_SUCCESS = "CORP_POPUP_CREATE_SUCCESS";
export const CORP_POPUP_CREATE_FAIL = "CORP_POPUP_CREATE_FAIL";

export const CORP_POPUP_UPDATE_REQUEST = "CORP_POPUP_UPDATE_REQUEST";
export const CORP_POPUP_UPDATE_SUCCESS = "CORP_POPUP_UPDATE_SUCCESS";
export const CORP_POPUP_UPDATE_FAIL = "CORP_POPUP_UPDATE_FAIL";

//Add CMS Action
export const addCorpPopupAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CORP_POPUP_CREATE_REQUEST
    });
    nodeCorpMongoApi.post(`/Corp_User_Policy/create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.code === 201) {
                dispatch({
                    type: CORP_POPUP_CREATE_SUCCESS
                });
                toast('Corporate Popup added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corporate-popup-management');
            } else {
                dispatch({
                    type: CORP_POPUP_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CORP_POPUP_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};

//  Update CMS Action
export const updateCorpPopupAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CORP_POPUP_UPDATE_REQUEST
    });
    nodeCorpMongoApi.post(`Corp_User_Policy/update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.code === 200) {
                dispatch({
                    type: CORP_POPUP_UPDATE_SUCCESS
                });
                toast('Corporate Popup updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corporate-popup-management');
            } else {
                dispatch({
                    type: CORP_POPUP_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CORP_POPUP_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//   Delete CMS Action
export const deleteCorpPopupAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
    try {
        let { data } = await nodeCorpMongoApi.post(`/Corp_User_Policy/delete`, formData);
        if (data) {
            toast('Corporate Popup deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            dispatch(paginationAllCorpPopupGet(filters, pagination, callBackPagination));
        }
    } catch (err) {
    }
};