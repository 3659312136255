import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableSortLabel,
    TableRow,
    makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../store/validations/is-empty';
import CommentIcon from '@mui/icons-material/Comment';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    images: {
        width: 100,
        height: 50
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    }
}));

const headCells = [
    { id: 'Bidding_Ride_Comment_Id', numeric: false, disablePadding: true, label: 'Bidding Ride Comment ID' },
    { id: 'Delivery_Bidding_Ride_Quote_Id', numeric: false, disablePadding: false, label: 'Delivery Bidding Ride Quote ID' },
    { id: 'Is_Admin_Approval', numeric: false, disablePadding: false, label: 'Is Admin Approval' },
    { id: 'Commented_By', numeric: false, disablePadding: false, label: 'Commented By' },
];

const Results = ({
    className,
    allDeliveryBiddingComments,
    order,
    orderBy,
    createSortHandler,
    handleLimitChange,
    handlePageChange,
    allDeliveryBiddingCommentsNoFilter,
    pagination,
    setPagination,
    RolePermissions,
    roleUserType,
    state,
    ...rest
}) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [selectedDeliveryBiddingCommentIds, setSelectedDeliveryBiddingCommentIds] = useState([]);

    const viewDeliveryBiddingCommentsHandler = deliverybidCommentsData => e => {
        navigate("/app/update-delivery-bidding-comments", { state: { state: state, data: deliverybidCommentsData } });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Comments &nbsp; &nbsp;
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allDeliveryBiddingComments) &&
                                (allDeliveryBiddingComments).map(Deliverybidcomment => (
                                    <TableRow
                                        hover
                                        key={Deliverybidcomment.Bidding_Ride_Comment_Id}
                                        selected={selectedDeliveryBiddingCommentIds.indexOf(Deliverybidcomment.Bidding_Ride_Comment_Id) !== -1}
                                    >
                                        <TableCell>{Deliverybidcomment.Bidding_Ride_Comment_Id}</TableCell>
                                        <TableCell>{Deliverybidcomment.Delivery_Bidding_Ride_Quote_Id}</TableCell>
                                        <TableCell>{Deliverybidcomment.Is_Admin_Approval}</TableCell>
                                        <TableCell>{Deliverybidcomment.Commented_By}</TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Delivery_Bidding_Comments_Write?.Value === "Delivery_Bidding_Comments_Write" && RolePermissions?.Delivery_Bidding_Comments_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="Delivery Bidding Comments">
                                                    <Button
                                                        onClick={viewDeliveryBiddingCommentsHandler(Deliverybidcomment)}
                                                        color="primary" >
                                                        <CommentIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            : null}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allDeliveryBiddingComments) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <Pagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
};

export default Results;