import {
    ZONE_THEME_PRODUCTS_CREATE_REQUEST,
    ZONE_THEME_PRODUCTS_CREATE_SUCCESS,
    ZONE_THEME_PRODUCTS_CREATE_FAIL,
    ZONE_THEME_PRODUCTS_UPDATE_REQUEST,
    ZONE_THEME_PRODUCTS_UPDATE_SUCCESS,
    ZONE_THEME_PRODUCTS_UPDATE_FAIL,
    ZONE_THEME_GET_REQUEST,
    ZONE_THEME_GET_SUCCESS,
    ZONE_THEME_GET_FAIL,
    MFH_PRODUCTS_GET_REQUEST,
    MFH_PRODUCTS_GET_SUCCESS,
    MFH_PRODUCTS_GET_FAIL
} from '../actions/meepaisazonethemeproductsAction';


const initialState = {
    zonethemeproductCreate: { zonethemeproduct: {}, error: '', isLoading: false },
    zonethemeproductUpdate: { zonethemeproduct: {}, error: '', isLoading: false },
    mfhproducts: { all: [], error: '', isLoading: false },
    zonetheme: { all: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ZONE_THEME_PRODUCTS_CREATE_REQUEST:
            return { ...state, zonethemeproductCreate: { zonethemeproduct: {}, error: '', isLoading: true } };
        case ZONE_THEME_PRODUCTS_CREATE_SUCCESS:
            return { ...state, zonethemeproductCreate: { zonethemeproduct: state.zonethemeproductCreate.zonethemeproduct, error: '', isLoading: false } };
        case ZONE_THEME_PRODUCTS_CREATE_FAIL:
            return { ...state, zonethemeproductCreate: { zonethemeproduct: {}, error: action.payload, isLoading: false } };
        case ZONE_THEME_PRODUCTS_UPDATE_REQUEST:
            return { ...state, zonethemeproductUpdate: { zonethemeproduct: {}, error: '', isLoading: true } };
        case ZONE_THEME_PRODUCTS_UPDATE_SUCCESS:
            return { ...state, zonethemeproductUpdate: { zonethemeproduct: action.payload, error: '', isLoading: false } };
        case ZONE_THEME_PRODUCTS_UPDATE_FAIL:
            return { ...state, zonethemeproductUpdate: { zonethemeproduct: {}, error: action.payload, isLoading: false } };

        case MFH_PRODUCTS_GET_REQUEST:
            return { ...state, mfhproducts: { all: state.mfhproducts.all, error: '', isLoading: true } };
        case MFH_PRODUCTS_GET_SUCCESS:
            return { ...state, mfhproducts: { all: action.payload, error: '', isLoading: false } };
        case MFH_PRODUCTS_GET_FAIL:
            return { ...state, mfhproducts: { all: [], error: action.payload, isLoading: false } };

        case ZONE_THEME_GET_REQUEST:
            return { ...state, zonetheme: { all: state.zonetheme.all, error: '', isLoading: true } };
        case ZONE_THEME_GET_SUCCESS:
            return { ...state, zonetheme: { all: action.payload, error: '', isLoading: false } };
        case ZONE_THEME_GET_FAIL:
            return { ...state, zonetheme: { all: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}