import {
    SERVICES_PARTNER_REQUEST,
    SERVICES_PARTNER_SUCCESS,
    SERVICES_PARTNER_FAIL,
    SERVICES_PARTNER_GET_REQUEST,
    SERVICES_PARTNER_GET_SUCCESS,
    SERVICES_PARTNER_GET_FAIL,
    SERVICES_PARTNER_CREATE_REQUEST,
    SERVICES_PARTNER_CREATE_SUCCESS,
    SERVICES_PARTNER_CREATE_FAIL,
    SERVICES_PARTNER_UPDATE_REQUEST,
    SERVICES_PARTNER_UPDATE_SUCCESS,
    SERVICES_PARTNER_UPDATE_FAIL,
    PARTNERS_BUSINESSTYPE_REQUEST,
    PARTNERS_BUSINESSTYPE_SUCCESS,
    PARTNERS_BUSINESSTYPE_FAIL,
    PARTNERS_SERVICE_REQUEST,
    PARTNERS_SERVICE_SUCCESS,
    PARTNERS_SERVICE_FAIL,

} from '../actions/partnerserviceAction';

const initialState = {
    partnerservices: { all: [], error: '', isLoading: false },
    partnerserviceUpdate: { partnerservice: {}, error: '', isLoading: false },
    partnerserviceCreate: { partnerservice: {}, error: '', isLoading: false },
    partnerserviceType: { servicetype: {}, error: '', isLoading: false },
    PhysicalPartners: { partners: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SERVICES_PARTNER_REQUEST:
            return { ...state, partnerservices: { all: state.partnerservices.all, error: '', isLoading: true } };
        case SERVICES_PARTNER_SUCCESS:
            return { ...state, partnerservices: { all: action.payload, error: '', isLoading: false } };
        case SERVICES_PARTNER_FAIL:
            return { ...state, partnerservices: { all: [], error: action.payload, isLoading: false } };
        case SERVICES_PARTNER_GET_REQUEST:
            return { ...state, partnerservice: { partnerservice: {}, error: '', isLoading: true } };
        case SERVICES_PARTNER_GET_SUCCESS:
            return { ...state, partnerservice: { partnerservice: action.payload, error: '', isLoading: false } };
        case SERVICES_PARTNER_GET_FAIL:
            return { ...state, partnerservice: { partnerservice: {}, error: action.payload, isLoading: false } };
        case SERVICES_PARTNER_CREATE_REQUEST:
            return { ...state, partnerserviceCreate: { partnerservice: {}, error: '', isLoading: true } };
        case SERVICES_PARTNER_CREATE_SUCCESS:
            return { ...state, partnerserviceCreate: { partnerservice: state.partnerserviceCreate.partnerservice, error: '', isLoading: false } };
        case SERVICES_PARTNER_CREATE_FAIL:
            return { ...state, partnerserviceCreate: { partnerservice: {}, error: action.payload, isLoading: false } };
        case SERVICES_PARTNER_UPDATE_REQUEST:
            return { ...state, partnerserviceUpdate: { partnerservice: {}, error: '', isLoading: true } };
        case SERVICES_PARTNER_UPDATE_SUCCESS:
            return { ...state, partnerserviceUpdate: { partnerservice: state.partnerserviceUpdate.partnerservice, error: '', isLoading: false } };
        case SERVICES_PARTNER_UPDATE_FAIL:
            return { ...state, partnerserviceUpdate: { partnerservice: {}, error: action.payload, isLoading: false } };
        case PARTNERS_BUSINESSTYPE_REQUEST:
            return { ...state, partnerserviceType: { servicetype: state.partnerserviceType.servicetype, error: '', isLoading: true }, };
        case PARTNERS_BUSINESSTYPE_SUCCESS:
            return { ...state, partnerserviceType: { servicetype: action.payload, error: '', isLoading: false } };
        case PARTNERS_BUSINESSTYPE_FAIL:
            return { ...state, partnerserviceType: { servicetype: {}, error: action.payload, isLoading: false } };
        case PARTNERS_SERVICE_REQUEST:
            return { ...state, PhysicalPartners: { partners: state.PhysicalPartners.partners, error: '', isLoading: true }, };
        case PARTNERS_SERVICE_SUCCESS:
            return { ...state, PhysicalPartners: { partners: action.payload, error: '', isLoading: false } };
        case PARTNERS_SERVICE_FAIL:
            return { ...state, PhysicalPartners: { partners: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}