import {

    BID_PROD_DATEANDCOUNT_REQUEST,
    BID_PROD_DATEANDCOUNT_SUCCESS,
    BID_PROD_DATEANDCOUNT_FAIL,

    BID_SERV_DATEANDCOUNT_REQUEST,
    BID_SERV_DATEANDCOUNT_SUCCESS,
    BID_SERV_DATEANDCOUNT_FAIL,

    BID_RIDE_DATEANDCOUNT_REQUEST,
    BID_RIDE_DATEANDCOUNT_SUCCESS,
    BID_RIDE_DATEANDCOUNT_FAIL,

    BID_B2B_PROD_DATEANDCOUNT_REQUEST,
    BID_B2B_PROD_DATEANDCOUNT_SUCCESS,
    BID_B2B_PROD_DATEANDCOUNT_FAIL,

} from '../actions/biddingdateandcountAction';

const initialState = {
    bidproddateandcounts: { all: [], error: '', isLoading: false },
    bidservdateandcounts: { all: [], error: '', isLoading: false },
    bidridedateandcounts: { all: [], error: '', isLoading: false },
    bidb2bproddateandcounts: { all: [], error: '', isLoading: false },
};


export default function (state = initialState, action) {
    switch (action.type) {
        case BID_PROD_DATEANDCOUNT_REQUEST:
            return { ...state, bidproddateandcounts: { all: state.bidproddateandcounts.all, error: '', isLoading: true } };
        case BID_PROD_DATEANDCOUNT_SUCCESS:
            return { ...state, bidproddateandcounts: { all: action.payload, error: '', isLoading: false } };
        case BID_PROD_DATEANDCOUNT_FAIL:
            return { ...state, bidproddateandcounts: { all: [], error: action.payload, isLoading: false } };

        case BID_SERV_DATEANDCOUNT_REQUEST:
            return { ...state, bidservdateandcounts: { all: state.bidservdateandcounts.all, error: '', isLoading: true } };
        case BID_SERV_DATEANDCOUNT_SUCCESS:
            return { ...state, bidservdateandcounts: { all: action.payload, error: '', isLoading: false } };
        case BID_SERV_DATEANDCOUNT_FAIL:
            return { ...state, bidservdateandcounts: { all: [], error: action.payload, isLoading: false } };

        case BID_RIDE_DATEANDCOUNT_REQUEST:
            return { ...state, bidridedateandcounts: { all: state.bidridedateandcounts.all, error: '', isLoading: true } };
        case BID_RIDE_DATEANDCOUNT_SUCCESS:
            return { ...state, bidridedateandcounts: { all: action.payload, error: '', isLoading: false } };
        case BID_RIDE_DATEANDCOUNT_FAIL:
            return { ...state, bidridedateandcounts: { all: [], error: action.payload, isLoading: false } };

        case BID_B2B_PROD_DATEANDCOUNT_REQUEST:
            return { ...state, bidb2bproddateandcounts: { all: state.bidb2bproddateandcounts.all, error: '', isLoading: true } };
        case BID_B2B_PROD_DATEANDCOUNT_SUCCESS:
            return { ...state, bidb2bproddateandcounts: { all: action.payload, error: '', isLoading: false } };
        case BID_B2B_PROD_DATEANDCOUNT_FAIL:
            return { ...state, bidb2bproddateandcounts: { all: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}