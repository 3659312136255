import { toast } from 'react-toastify';
import { boomiAdminApi, nodeGetApi } from './commonAxios';
import config from './config';
import { paginationUserManagement } from './node-actions/nodepagination';

export const USERS_REQUEST = "USERS_REQUEST";
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_FAIL = "USERS_FAIL";
export const USER_GET_REQUEST = "USER_GET_REQUEST";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_FAIL = "USER_GET_FAIL";
export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAIL = "USER_CREATE_FAIL";
export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const ROLES_REQUEST = 'ROLES_REQUEST';
export const ROLES_SUCCESS = "ROLES_SUCCESS";
export const ROLES_FAIL = "ROLES_FAIL";

/*=============================================================
                   Add User Action
 ===============================================================*/
export const AddUserAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: USER_CREATE_REQUEST
  });
  setErrorMessage({ Email_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
  boomiAdminApi.post(`/Super_Admin/User_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USER_CREATE_SUCCESS
        });
        toast('User details added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/admin-user-management');
      } else {
        const errors = {};
        if (successResponse.Email_Id) {
          errors.Email_Id = successResponse.UI_Display_Message;
        }
        if (successResponse.Mobile_Number) {
          errors.Mobile = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Email_Id: { key: successResponse.Email_Id ? successResponse.Email_Id : '', message: successResponse.Email_Id ? successResponse.UI_Display_Message : '' }, Mobile: { key: successResponse.Mobile_Number ? successResponse.Mobile_Number : '', message: successResponse.Mobile_Number ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Mobile_Number || successResponse.Email_Id) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: USER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Email_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: USER_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                 Update User Action
===============================================================*/
export const EditUserAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: USER_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Super_Admin/User_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USER_UPDATE_SUCCESS
        });
        toast('User details updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/admin-user-management');
      } else {
        dispatch({
          type: USER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USER_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working."
      });
    })
};
/*=============================================================
                   Get All User Action
===============================================================*/
export const getAllUserAction = formData => async dispatch => {
  try {
    dispatch({
      type: USERS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/User/Profile_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=EMAIL_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: USERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: USERS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: USERS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                 Delete User Action
===============================================================*/
export const deleteUserAction = (formData, filters, pagination, callBackPaginatio) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/Super_Admin/User_Delete`, formData);
    if (data) {
      toast('User details deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationUserManagement(filters, pagination, callBackPaginatio));
    }
  } catch (err) {
  }
};

//Get Roles
export const getAllRoles = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "ALL",
    };
    dispatch({
      type: ROLES_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Admin/Roles_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=ROLE_NAME&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ROLES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: ROLES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: ROLES_FAIL,
      payload: err
    });
  }
};

//Get User Profile Action
export const getUsersignupAction = (formData, callBacksignupData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/User/Profile_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=EMAIL_ID&SortOrder=ASC`, formData);
    if (data) {
      callBacksignupData(data)
    }
  } catch (err) {
  }
};