import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  makeStyles,
  Avatar
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  images: {
    width: 138,
    height: 42
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Banner_Id', numeric: false, disablePadding: true, label: 'Banner ID' },
  { id: 'Banner_Title', numeric: false, disablePadding: false, label: 'Banner Title' },
  { id: 'Banner_Image', numeric: false, disablePadding: false, label: 'Banner Image' },
  { id: 'Banner_Offer_Type', numeric: false, disablePadding: false, label: 'Banner Offer Type' },
  { id: 'Banner_Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allBanners,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  deleteBannerHandler,
  allBannersNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedBannerIds, setSelectedBannerIds] = useState([]);
  const navigate = useNavigate();
  /*===============================
             check box handler     
  =================================*/

  const handleSelectAll = event => {
    let newSelectedBannerIds;
    if (event.target.checked) {
      newSelectedBannerIds = allBanners.map(banner => banner.Banner_Id);
    } else {
      newSelectedBannerIds = [];
    }
    setSelectedBannerIds(newSelectedBannerIds);
  };
  const handleSelectOne = (event, id) => {
    setSelectedBannerIds(selectedBannerIds.filter(record => {
      for (let i = 0; i < allBannersNoFilter.length; i++) {
        if (record == allBannersNoFilter[i].Banner_Id) {
          return true;
        }
      }
      return false;
    }))
    const selectedIndex = selectedBannerIds.indexOf(id);
    let newselectedBannerIds = [];
    if (selectedIndex === -1) {
      newselectedBannerIds = newselectedBannerIds.concat(selectedBannerIds, id);
    } else if (selectedIndex === 0) {
      newselectedBannerIds = newselectedBannerIds.concat(
        selectedBannerIds.slice(1)
      );
    } else if (selectedIndex > 0) {
      newselectedBannerIds = newselectedBannerIds.concat(
        selectedBannerIds.slice(0, selectedIndex),
        selectedBannerIds.slice(selectedIndex + 1)
      );
    }
    setSelectedBannerIds(newselectedBannerIds);
  };

  const deleteSelectedBannerHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development");
  };

  /*=================================
                Alert Popup
    =================================*/
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const handleClose = (value) => {
    setOpen(false);
  };

  const editBannerHandler = bannerData => e => {
    navigate('/app/edit-banner', { state: bannerData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedBannerIds.filter(record => {
                      for (let i = 0; i < allBannersNoFilter.length; i++) {
                        if (record == allBannersNoFilter[i].Banner_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allBanners.length}
                    color="primary"
                    indeterminate={
                      selectedBannerIds.filter(record => {
                        for (let i = 0; i < allBannersNoFilter.length; i++) {
                          if (record == allBannersNoFilter[i].Banner_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedBannerIds.filter(record => {
                        for (let i = 0; i < allBannersNoFilter.length; i++) {
                          if (record == allBannersNoFilter[i].Banner_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allBanners.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.id !== "Banner_Image" ? (
                      <TableSortLabel className={classes.headerColumn}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>) : (<span className={classes.headerColumn} > {headCell.label}</span>)
                    }
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions &nbsp;
                  {selectedBannerIds.filter(record => {
                    for (let i = 0; i < allBannersNoFilter.length; i++) {
                      if (record == allBannersNoFilter[i].Banner_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedBannerIds.filter(record => {
                    for (let i = 0; i < allBannersNoFilter.length; i++) {
                      if (record == allBannersNoFilter[i].Banner_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedBannerHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allBanners) &&
                (allBanners).map(banner => (
                  <TableRow
                    hover
                    key={banner.Banner_Id}
                    selected={selectedBannerIds.indexOf(banner.Banner_Id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedBannerIds.indexOf(banner.Banner_Id) !== -1}
                        onChange={event => handleSelectOne(event, banner.Banner_Id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>{banner.Banner_Id}</TableCell>
                    <TableCell>{banner.Banner_Title}</TableCell>
                    <TableCell>
                      <Avatar
                        className={classes.images}
                        alt="Brand"
                        src={banner.Banner_Image}
                        variant="square"
                      />
                    </TableCell>
                    <TableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{banner.Banner_Offer_Type}</TableCell>
                    <TableCell style={{ color: '#1665D8' }}>
                      {banner.Banner_Status}
                    </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Banner_Management_Write?.Value === "Banner_Management_Write" && RolePermissions?.Banner_Management_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Button
                          onClick={editBannerHandler(banner)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>&nbsp;&nbsp;&nbsp;
                          <DeleteIcon
                            onClick={deleteBannerHandler(banner)}
                            style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allBanners) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allBanners: PropTypes.array.isRequired
};

export default Results;