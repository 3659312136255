import { Card, CardContent, Grid } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

const UserPaymentSettlementsDetails = ({ className, ...rest }) => {
  const location = useLocation();
  let { state } = location;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Grid container spacing={3}>

              {/* Order ID */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Order ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Order_Id}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Order Status
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }} >
                  {state.Payment_Status}
                </Typography>
              </Grid>

              {/* Final Settlement Amount */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Final Settlement Amount
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Final_Order_Amount}
                </Typography>
              </Grid>

            </Grid>
          </Grid>

          {/*  column 2 */}
          <Grid item md={6} xs={12}>
            <Grid container spacing={3}>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Transaction ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Transaction_Id}
                </Typography>
              </Grid>

              {/* Total Order Amount */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Total Order Amount
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}>
                  {state.Total_Order_Amount}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  ESI Commission Amount
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }} >
                  {state.Esi_Commission_Amount}
                </Typography>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserPaymentSettlementsDetails;