import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from '../../components/dialogs/confirmDialog'
import { deleteContentManagementStaticAction} from 'src/store/actions/deliverycmspageActions';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';
import { paginationContentManagement } from 'src/store/actions/node-actions/nodepagination';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const ContentListView = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    //permissions
    const RolePermissions = useSelector(state => state.rolepermission.Roles);
    const roleUserType = useSelector(state => state.auth.user);
    const Login_Details = useSelector(state => state.auth.user);

    const [deliverycontent, setDeliveryContent] = useState([]);
    const [filters, setFilters] = React.useState({
      "search": '', 
      "search_by_filter": 'All',
      Records_Filter: "FILTER",
      Module_Type: "DELIVERY"
    
  
    });
  
    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = useState({ PageNo: 1, PageSize: 10, SortBy: "Cms_Static_Id", SortOrder: "ASC" })
  
    const handleLimitChange = event => {
      setPagination({ ...pagination, PageSize: +event.target.value, PageNo: 1 });
    };
    const handlePageChange = (event, newPage) => {
      setPagination({ ...pagination, PageSize: pagination.PageSize, PageNo: newPage + 1 });
    };
  
    const callBackPagination = async (status, data, pagination) => {
      if (status) {
        setDeliveryContent(data);
      }
      setPageOld(true);
      if (pagination) {
        setPagination(pagination);
      } else {
        setPagination({ PageNo: 1, PageSize: 10, SortBy: "Cms_Static_Id", SortOrder: "ASC" });
      }
    }
  
    useEffect(() => {
      if (pageOld) {
        setPageOld(false);
      } else {
        deliverycontentCall();
      }
    }, [pagination])
  
    const [testFilters, setTestFilters] = useState({});
    useEffect(() => {
      if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
        setTestFilters(filters);
        searchFunction();
      }
    }, [filters]);
    const searchFunction = () => {
      const searchpagination = {
        PageNo: 1, PageSize: 10, SortBy: "Cms_Static_Id", SortOrder: "ASC"
      }
      dispatch(paginationContentManagement(filters, searchpagination, callBackPagination))
    }
  
    const deliverycontentCall = () => {
      dispatch(paginationContentManagement(filters, pagination, callBackPagination))
    };
  
  

    // confirm dailogbox
    const [copen, setCopen] = useState(false);
    const [ctitle, setCtitle] = useState('');
    const [cmessage, setCmessage] = useState('');
    const [contentmanagementIdle, setContentManagementIdle] = useState('');

    const chandleClose = (value) => {
        setCopen(false);

        if (value) {
            const deleteData = {
                Cms_Static_Id: contentmanagementIdle.Cms_Static_Id,
                ModifiedBy: Login_Details.Email_Id
            };
            dispatch(deleteContentManagementStaticAction(deleteData,filters, pagination, callBackPagination));
        }
    };

    //Delete handler
    const deleteContentManagementHandler = contentmanagementData => async e => {
        setCopen(true);
        setCtitle("Alert!");
        setCmessage("Are you sure to delete?");
        setContentManagementIdle(contentmanagementData);
    };

   
    // Sorting 
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Cms_Static_Id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPagination({ PageNo: 1, PageSize: pagination?.PageSize, SortBy: property, SortOrder: order === 'asc' ? "ASC" : "DESC" })
    };

    const createSortHandler = (property) => (event) => {
      handleRequestSort(event, property);
    };
  

    
   

    // componentDidMount
    useEffect(() => {
        dispatch(paginationContentManagement(filters, pagination, callBackPagination));
        dispatch(getRolePermissions(Login_Details));
    }, []);

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/delivery-management"
                    variant="text"
                    size="small"
                    color="inherit"
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        Delivery Content Management
                    </Typography>
                </Box>
                <Toolbar filters={filters} setfilters={setFilters} RolePermissions={RolePermissions} roleUserType={roleUserType} />
                <Box mt={3}>
                    <Results
                        allContentManagement={deliverycontent}
                        allContentManagementNoFilter={deliverycontent}
                        deleteContentManagementHandler={deleteContentManagementHandler}
                        handleLimitChange={handleLimitChange}
                        handlePageChange={handlePageChange}
                        order={order}
                        orderBy={orderBy}
                        pagination={pagination}
                        setPagination={setPagination}
                        createSortHandler={createSortHandler}
                        RolePermissions={RolePermissions}
                        roleUserType={roleUserType}
                    />
                </Box>
            </Container>
            <ConfirmDialog inputMessage={cmessage} titleMsg={ctitle} open={copen} onClose={chandleClose} />
        </Page>
    );
};

export default ContentListView;