import { Card, CardContent, Divider, Grid, Link, Button } from '@material-ui/core';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import ConfirmDialog from 'src/components/dialogs/confirmDialog';
import { useDispatch } from 'react-redux';
import { updatePartnerApprovalAction } from 'src/store/actions/partnerAction';

const PartnerApprovalList = ({ className, ...rest }) => {
    //useLocation returns the location object that contains information about the current URL
    const location = useLocation();
    //state represent an information about the component's current situation
    let { state } = location;
    const dispatch = useDispatch();
    const [partnerappopen, setPartnerappopen] = useState(false);
    const [partnerapptitle, setPartnerapptitle] = useState('');
    const [partnerappmessage, setPartnerappmessage] = useState('');
    const [partnerappIdle, setPartnerAppIdle] = useState('');
    const partnerapphandleClose = (value) => {
        setPartnerappopen(false);

        if (value) {
            const approveData = {
                Email_Id: partnerappIdle.Email_Id,
                Partner_Details_Id: partnerappIdle.Partner_Details_Id,
                Status: 1
            };
            const approvepartnerData = {
                Records_Filter: 'FILTER',
                Status: "Active"
            }
            dispatch(updatePartnerApprovalAction(approveData, approvepartnerData));
        }
    };

    //Approve handler
    const updatePartnerApprovalHandler = approveData => async e => {
        setPartnerappopen(true);
        setPartnerapptitle("Alert!");
        setPartnerappmessage("Are you sure to Accept?");
        setPartnerAppIdle(approveData);
    };


    return (
        <Card>
            <CardContent>
                {/* Grid creates visual consistency between layouts and adapts to screen size */}
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            {/* Partner_Details_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Partner Details ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.Partner_Details_Id}
                                </Typography>
                            </Grid>
                            {/* Name */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Name
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.Name}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Mobile
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.Mobile}
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Land Phone
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.Land_Phone}
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Email ID
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    <span title={state?.Email_Id}> {state?.Email_Id?.length > 25 ?
                                        <span>{state?.Email_Id?.substring(0, 25)}...</span> : state?.Email_Id}</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Type
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.Type}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    GST
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.GST}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    PAN
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.PAN}
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    User Tips
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.User_Tips}
                                </Typography>
                            </Grid>


                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Popular
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.Is_Popular}
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Whats Hot
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.Whats_Hot}
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Todays Hot
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.Todays_Hot}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Extra Cashback
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.Extra_Cashback}
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Top Store
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.Top_Stories}
                                </Typography>
                            </Grid>

                            <Grid item xs={2} />
                            <Grid item xs={2} />
                            <Grid item xs={7} />
                        </Grid>
                    </Grid>

                    {/*  column 2 */}
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Company Name
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.Company_Name}
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Authorised Person Name
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.Authorised_Person_Name}
                                </Typography>
                            </Grid>

                            {/* Logo_Path */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Logo Path
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {/* Link component allows you to easily customize anchor elements with your theme colors and typography styles. */}
                                    {/* span element is used for inline organization and styling. */}
                                    <Link href={state?.Logo_Path} underline="none" target="_blank">
                                        <span title={state?.Logo_Path}> {state?.Logo_Path?.length > 25 ?
                                            <span>{state?.Logo_Path?.substring(0, 25)}...</span> : state?.Logo_Path}</span>
                                    </Link>
                                </Typography>
                            </Grid>

                            {/* Banner_Path */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Banner Path
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {/* Link component allows you to easily customize anchor elements with your theme colors and typography styles. */}
                                    {/* span element is used for inline organization and styling. */}
                                    <Link href={state?.Banner_Path} underline="none" target="_blank">
                                        <span title={state?.Banner_Path}> {state?.Banner_Path?.length > 25 ?
                                            <span>{state?.Banner_Path?.substring(0, 25)}...</span> : state?.Banner_Path}</span>
                                    </Link>
                                </Typography>
                            </Grid>


                            {/* Web_Redirect_Url */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Web Redirection URL
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {/* Link component allows you to easily customize anchor elements with your theme colors and typography styles. */}
                                    {/* span element is used for inline organization and styling. */}
                                    <Link href={state?.Web_Redirection_Url} underline="none" target="_blank">
                                        <span title={state?.Web_Redirection_Url}> {state?.Web_Redirection_Url?.length > 25 ?
                                            <span>{state?.Web_Redirection_Url?.substring(0, 25)}...</span> : state?.Web_Redirection_Url}</span>
                                    </Link>
                                </Typography>
                            </Grid>

                            {/* Mobile_Redirect_Url */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Mobile Redirection URL
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {/* Link component allows you to easily customize anchor elements with your theme colors and typography styles. */}
                                    {/* span element is used for inline organization and styling. */}
                                    <Link href={state?.Mobile_Redirect_Url} underline="none" target="_blank">
                                        <span title={state?.Mobile_Redirect_Url}> {state?.Mobile_Redirect_Url?.length > 25 ?
                                            <span>{state?.Mobile_Redirect_Url?.substring(0, 25)}...</span> : state?.Mobile_Redirect_Url}</span>
                                    </Link>
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    ESI Rating
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.Esi_Ratting}
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    ESI Commission
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.Esi_Comission}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Status
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.Status}
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created Date
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    {state?.Created_Date}
                                </Typography>
                            </Grid>
                            {/* Created_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    <span title={state?.Created_By}> {state?.Created_By?.length > 25 ?
                                        <span>{state?.Created_By?.substring(0, 25)}...</span> : state?.Created_By}</span>
                                </Typography>
                            </Grid>


                            {/* Created_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    <span title={state?.Updated_Date}> {state?.Updated_Date?.length > 25 ?
                                        <span>{state?.Updated_Date?.substring(0, 25)}...</span> : state?.Updated_Date}</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '2rem' }}
                                >
                                    <span title={state?.Modified_By}> {state?.Modified_By?.length > 25 ?
                                        <span>{state?.Modified_By?.substring(0, 25)}...</span> : state?.Modified_By}</span>
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Divider />
                {/* Description */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A' }}
                    >
                        Description
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ paddingBottom: '1rem' }}
                    >
                        {/* dangerouslySetInnerHTML is a property that you can use on HTML elements in a React application to programmatically set their content. */}
                        <span dangerouslySetInnerHTML={{ __html: state?.Description }}></span>
                    </Typography>
                </Grid>
                <Divider />
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A' }}
                    >
                        ESI Reviews
                    </Typography>
                </Grid>
                <Grid item >
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                    >
                        {/* dangerouslySetInnerHTML is a property that you can use on HTML elements in a React application to programmatically set their content. */}
                        <span dangerouslySetInnerHTML={{ __html: state?.Esi_Reviews }}></span>
                    </Typography>
                </Grid>
            </CardContent>
            <div style={{ paddingLeft: '20rem', margin: '1rem' }}>
                <Button
                    onClick={updatePartnerApprovalHandler(state)}
                    style={{ backgroundColor: '#12824C', color: '#FFFFFF' }}
                >
                    ACCEPT
                </Button>

            </div>
            <ConfirmDialog inputMessage={partnerappmessage} titleMsg={partnerapptitle} open={partnerappopen} onClose={partnerapphandleClose} />
        </Card>
    );
};

export default PartnerApprovalList;