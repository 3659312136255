
import config from './config';
import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi,boomiAdminApi } from './commonAxios';


export const RIDE_VEHI_TYPE_REQUEST = "RIDE_VEHI_TYPE_REQUEST";
export const RIDE_VEHI_TYPE_SUCCESS = "RIDE_VEHI_TYPE_SUCCESS";
export const RIDE_VEHI_TYPE_FAIL = "RIDE_VEHI_TYPE_FAIL";

export const RIDE_VEHI_TYPE_CREATE_REQUEST = "RIDE_VEHI_TYPE_CREATE_REQUEST";
export const RIDE_VEHI_TYPE_CREATE_SUCCESS = "RIDE_VEHI_TYPE_CREATE_SUCCESS";
export const RIDE_VEHI_TYPE_CREATE_FAIL = "RIDE_VEHI_TYPE_CREATE_FAIL";

export const RIDE_VEHI_TYPE_UPDATE_REQUEST = "RIDE_VEHI_TYPE_UPDATE_REQUEST";
export const RIDE_VEHI_TYPE_UPDATE_SUCCESS = "RIDE_VEHI_TYPE_UPDATE_SUCCESS";
export const RIDE_VEHI_TYPE_UPDATE_FAIL = "RIDE_VEHI_TYPE_UPDATE_FAIL";

export const RIDE_VEHI_TYPE_EMAILS_REQUEST = "RIDE_VEHI_TYPE_EMAILS_REQUEST";
export const RIDE_VEHI_TYPE_EMAILS_SUCCESS = "RIDE_VEHI_TYPE_EMAILS_SUCCESS";
export const RIDE_VEHI_TYPE_EMAILS_FAIL = "RIDE_VEHI_TYPE_EMAILS_FAIL";

export const RIDE_VEHI_TYPE_VEHICLE_COMFORT_REQUEST = "RIDE_VEHI_TYPE_VEHICLE_COMFORT_REQUEST";
export const RIDE_VEHI_TYPE_VEHICLE_COMFORT_SUCCESS = "RIDE_VEHI_TYPE_VEHICLE_COMFORT_SUCCESS";
export const RIDE_VEHI_TYPE_VEHICLE_COMFORT_FAIL = "RIDE_VEHI_TYPE_VEHICLE_COMFORT_FAIL";



//get All Rides Vehicle Types Action
export const getAllRidesVehiTypesAction = formData => async dispatch => {
    try {
        dispatch({
            type: RIDE_VEHI_TYPE_REQUEST
        });
        let { data } = await nodeGetApi.post(
            `${config.nodeurl}/Rides_Vehicle_Types/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Ride_Vehicle_Type_Id&SortOrder=ASC`,
            formData
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: RIDE_VEHI_TYPE_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: RIDE_VEHI_TYPE_SUCCESS,
                    payload: data.results

                });
            }
        }
    } catch (err) {
        dispatch({
            type: RIDE_VEHI_TYPE_FAIL,
            payload: err
        });
    }
};

//create Ride Vehicle Type Action
export const createRideVehiTypesAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: RIDE_VEHI_TYPE_CREATE_REQUEST
    });
    nodeCudApi.post(`/Rides_Vehicle_Types/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: RIDE_VEHI_TYPE_CREATE_SUCCESS
                });
                toast('Ride Vehicle Type created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/ride-vehicle-types');
            } else {
                dispatch({
                    type: RIDE_VEHI_TYPE_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: RIDE_VEHI_TYPE_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

//update Ride Vehicle Types Action
export const updateRideVehiTypesAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: RIDE_VEHI_TYPE_UPDATE_REQUEST
    });
    nodeCudApi.post(`/Rides_Vehicle_Types/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: RIDE_VEHI_TYPE_UPDATE_SUCCESS
                });
                toast('Ride Vehicle Type updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/ride-vehicle-types');
            } else {

                dispatch({
                    type: RIDE_VEHI_TYPE_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: RIDE_VEHI_TYPE_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//Rides Vehicle Type Image Upload
export const uploadVehicleTypeImage = (
    formData,
    callBackUploadImage,
) => async dispatch => {
    try {
        let { data } = await boomiAdminApi.post(
            `/SA_Upload/Upload?functionality=RideVehicleType&fileextension=png&filetype=Images&filename=RideVehicleType`,
            formData
        );
        if (data) {
            callBackUploadImage(data);
        }
    } catch (err) { }
};