import {

    CORP_POPUP_CREATE_REQUEST,
    CORP_POPUP_CREATE_SUCCESS,
    CORP_POPUP_CREATE_FAIL,

    CORP_POPUP_UPDATE_REQUEST,
    CORP_POPUP_UPDATE_SUCCESS,
    CORP_POPUP_UPDATE_FAIL,

} from '../actions/corporatepopupActions'

const initialState = {
    corppopupCreate: { corppopup: {}, error: '', isLoading: false },
    corppopupUpdate: { corppopup: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case CORP_POPUP_CREATE_REQUEST:
            return { ...state, corppopupCreate: { corppopup: {}, error: '', isLoading: true } };
        case CORP_POPUP_CREATE_SUCCESS:
            return { ...state, corppopupCreate: { corppopup: state.corppopupCreate.corppopup, error: '', isLoading: false } };
        case CORP_POPUP_CREATE_FAIL:
            return { ...state, corppopupCreate: { corppopup: {}, error: action.payload, isLoading: false } };

        case CORP_POPUP_UPDATE_REQUEST:
            return { ...state, corppopupUpdate: { corppopup: {}, error: '', isLoading: true } };
        case CORP_POPUP_UPDATE_SUCCESS:
            return { ...state, corppopupUpdate: { corppopup: action.payload, error: '', isLoading: false } };
        case CORP_POPUP_UPDATE_FAIL:
            return { ...state, corppopupUpdate: { corppopup: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}