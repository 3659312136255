import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateDeliveryPaymentAction,
} from '../../../store/actions/deliverypaymentsettlementsAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const paymentOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'PENDING',
    label: 'PENDING'
  },
  {
    value: 'IN-PROGRESS',
    label: 'IN-PROGRESS'
  },
  {
    value: 'HOLD',
    label: 'HOLD'
  },
  {
    value: 'SETTLED',
    label: 'SETTLED'
  },
  {
    value: 'REJECTED',
    label: 'REJECTED'
  }

];

const paymentMethod = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'PHONEPE',
    label: 'PHONEPE'
  },
  {
    value: 'GOOGLE PAY',
    label: 'GOOGLE PAY'
  },
  {
    value: 'CREDIT CARD',
    label: 'CREDIT CARD'
  },
  {
    value: 'DEBIT CARD',
    label: 'DEBIT CARD'
  },

];

const DeliveryPaymentSettlementsEdit = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = location;

  const errorMessage = useSelector(state => state.deliverypayment.deliverypaymentUpdate.error);
  const isSubmited = useSelector(state => state.deliverypayment.deliverypaymentUpdate.isLoading);

  return (
    <Formik
      initialValues={{
        Del_Payment_Settlement_Id: state.Del_Payment_Settlement_Id ? state.Del_Payment_Settlement_Id : "",
        Del_User_Email_Id: state.Del_User_Email_Id ? state.Del_User_Email_Id : "",
        Delivery_Person_Fee: state.Delivery_Person_Fee ? state.Delivery_Person_Fee : "",
        Order_Id: state.Order_Id ? state.Order_Id : "",
        Order_Line_Id: state.Order_Line_Id ? state.Order_Line_Id : "",
        Payment_Transaction_Id: state.Payment_Transaction_Id ? state.Payment_Transaction_Id : "",
        Payment_Date: state.Payment_Date ? state.Payment_Date : "",
        Payment_Method: state.Payment_Method ? state.Payment_Method : "",
        Payment_Settlement_Status: state.Payment_Settlement_Status ? state.Payment_Settlement_Status : "",
        Order_Line_Item_Amount: state.Order_Line_Item_Amount ? state.Order_Line_Item_Amount : "",
      }}

      validationSchema={
        Yup.object().shape({
          Delivery_Person_Fee: Yup.string().required('Delivery Person Fee is required.'),
          Payment_Date: Yup.string().required('Payment Date is required.'),
          Payment_Method: Yup.string().required('Payment Method is required.'),
          Payment_Settlement_Status: Yup.string().required('Payment Status is required.'),
        })
      }
      onSubmit={(values) => {
        if (values) {
          let formData = JSON.parse(JSON.stringify(values));
          dispatch(updateDeliveryPaymentAction(formData, navigate));
        }
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled
                        label="Delivery User Email ID"
                        name="Del_User_Email_Id"
                        value={values.Del_User_Email_Id}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled
                        label="Order ID"
                        name="Order_Id"
                        value={values.Order_Id}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled
                        label="Payment Transaction ID"
                        name="Payment_Transaction_Id"
                        value={values.Payment_Transaction_Id}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled
                        label="Order Line Item ID"
                        name="Order_Line_Id"
                        value={values.Order_Line_Id}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Payment_Date && errors.Payment_Date)}
                        fullWidth
                        helperText={touched.Payment_Date && errors.Payment_Date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="datetime-local"
                        label="Payment Date"
                        name="Payment_Date"
                        value={values.Payment_Date}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Order_Line_Item_Amount && errors.Order_Line_Item_Amount)}
                        fullWidth
                        helperText={touched.Order_Line_Item_Amount && errors.Order_Line_Item_Amount}
                        disabled={true}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Order Line Item Amount"
                        name="Order_Line_Item_Amount"
                        value={values.Order_Line_Item_Amount}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Delivery_Person_Fee && errors.Delivery_Person_Fee)}
                        fullWidth
                        helperText={touched.Delivery_Person_Fee && errors.Delivery_Person_Fee}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Delivery Person Fee"
                        name="Delivery_Person_Fee"
                        value={values.Delivery_Person_Fee}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Payment_Method && errors.Payment_Method)}
                        fullWidth
                        placeholder="Payment_Method"
                        name="Payment_Method"
                        onChange={handleChange}
                        label="Payment Method"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Payment_Method}
                        variant="outlined"
                        helperText={touched.Payment_Method && errors.Payment_Method} >
                        {paymentMethod.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Payment_Settlement_Status && errors.Payment_Settlement_Status)}
                        fullWidth
                        placeholder="Payment Settlement Status"
                        name="Payment_Settlement_Status"
                        onChange={handleChange}
                        label="Payment Settlement Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Payment_Settlement_Status}
                        variant="outlined"
                        helperText={touched.Payment_Settlement_Status && errors.Payment_Settlement_Status} >
                        {paymentOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

DeliveryPaymentSettlementsEdit.propTypes = {
  className: PropTypes.string
};

export default DeliveryPaymentSettlementsEdit;