import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    makeStyles,
    TextareaAutosize,
    TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { getAllBidDeliMultiBids, getAllBidDeliMultipleVehicles, getAllBidDeliMultiQuotes, getAllBiddingDeliveryUsers, getAllBidRideDeliveryESICommissions, updateDeliveryBiddingQuotes } from 'src/store/actions/deliverybiddingquotesAction';

const statusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const bidstatusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'INITIATED',
        label: 'INITIATED'
    },
    {
        value: 'PROCESSING',
        label: 'PROCESSING'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
    {
        value: 'COMPLETED',
        label: 'COMPLETED'
    }
];

const finalpaystatus = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'SUCCESS',
        label: 'SUCCESS'
    },
    // {
    //     value: 'PROCESSING',
    //     label: 'PROCESSING'
    // },
    {
        value: 'PENDING',
        label: 'PENDING'
    },
    // {
    //     value: 'FAILED',
    //     label: 'FAILED'
    // }
];

const paymentmethod = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'CASH',
        label: 'CASH'
    },
    {
        value: 'ONLINE',
        label: 'ONLINE'
    }
];

const useStyles = makeStyles(() => ({
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const EditDeliveryBiddingQuote = ({ className, state, data, ...rest }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();

    const isSubmitted = useSelector(state => state.deliverybiddingquotes.deliverybidquoteUpdate.isLoading);
    const errorMessage = useSelector(state => state.deliverybiddingquotes.deliverybidquoteUpdate.error);
    const biddeliusers = useSelector(state => state.deliverybiddingquotes.biddeliveryusers.all);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    const [multideldata, setmultideldata] = React.useState([]);

    const callBackMultiDelData = (data) => {
        setmultideldata(data)
        setVehicleQuotes(data)
        setArray(data)
    }

    useEffect(() => {
        // let formData = {
        //     search_by_filter: "",
        //     search: ""
        // };
        let quoteData = {
            search_by_filter: "",
            search: "",
            Records_Filter: "FILTER",
            Delivery_Bidding_Quote_Id: data?.Delivery_Bidding_Quote_Id
        };
        let multiformData = {
            Records_Filter: "FILTER",
            User_Ride_Bidding_Id: state.User_Ride_Bidding_Id
        };
        let bidData = {
            Records_Filter: "FILTER",
            Delivery_Bidding_Quote_Id: data?.Delivery_Bidding_Quote_Id
        };
        dispatch(getAllBiddingDeliveryUsers())
        dispatch(getAllBidDeliMultipleVehicles(multiformData));
        dispatch(getAllBidDeliMultiQuotes(quoteData))
        dispatch(getAllBidDeliMultiBids(bidData))
        dispatch(getAllBidRideDeliveryESICommissions(multiformData, callBackMultiDelData))
    }, []);

    const [vehicleQuotes, setVehicleQuotes] = useState(
        multideldata?.map((quote) => ({
            Quotation_Amount: quote.Quatation_Amount,
            User_Ride_Bidding_Id: quote?.User_Ride_Bidding_Id,
            User_Ride_Bidding_Vehile_Id: quote?.User_Ride_Bidding_Vehicle_Id,
            Meepaisa_Commission_Type: quote?.Meepaisa_Commission_Type,
            Meepaisa_Commission: quote?.Meepaisa_Commission,
            Quantity: quote?.Quantity
        }))
    );

    const [array, setArray] = useState(
        data?.User_Ride_Bidding_Vehicle_Del_Quotation_Id?.map((quote) => ({
            Quotation_Amount: quote.Del_Quotation_Amount,
            User_Ride_Bidding_Vehicle_Del_Quotation_Amount_Id: quote?.User_Ride_Bidding_Vehicle_Del_Quotation_Id
        }))
    );

    const handleQuotationAmountChange = (value, index) => {
        const updatedQuotes = [...vehicleQuotes];
        const arrayQuotes = [...array];
        updatedQuotes[index].Quotation_Amount = value;
        arrayQuotes[index].Quotation_Amount = value;
        setVehicleQuotes(updatedQuotes);
        setArray(arrayQuotes)
    };

    const calculateTotalESICommission = () => {
        const totalESICommissionA = vehicleQuotes.reduce((sum, quote) => {
            if (quote.Meepaisa_Commission_Type === "A") {
                return sum + parseFloat(quote.Meepaisa_Commission || 0) * parseFloat(quote.Quantity || 0);
            } else {
                return sum
            }
        }, 0);
        const totalESICommissionP = vehicleQuotes.reduce((sum, quote) => {
            if (quote.Meepaisa_Commission_Type === "P") {
                const esiCommissionAmount = (parseFloat(quote.Quotation_Amount || 0) * parseFloat(quote.Meepaisa_Commission || 0)) / 100;
                return sum + esiCommissionAmount * parseFloat(quote.Quantity || 0);
            } else {
                return sum;
            }
        }, 0);
        const totalESICommission = totalESICommissionA + totalESICommissionP;
        return totalESICommission.toFixed(2);
    };

    let multivehicleIDs = []
    if (multideldata) {
        if (multideldata[0]) {
            for (let i = 0; i < multideldata.length; i++) {
                multivehicleIDs.push({
                    label: multideldata[i].Vehicle_Type + " - " + multideldata[i].Vehicle_Comfort_Type,
                    value: multideldata[i].User_Ride_Bidding_Vehicle_Id
                })
            }
        }
    }

    return (
        <Formik
            initialValues={{
                Delivery_Bidding_Quote_Id: data.Delivery_Bidding_Quote_Id ? data.Delivery_Bidding_Quote_Id : "",
                User_Ride_Bidding_Id: data.User_Ride_Bidding_Id ? data.User_Ride_Bidding_Id : "",
                Del_User_Email_Id: data.Del_User_Email_Id ? data.Del_User_Email_Id : "",
                Quotation_Amount: data.Quotation_Amount ? data.Quotation_Amount : "",
                Advance_Token_Amount: data.Advance_Token_Amount ? data.Advance_Token_Amount : "",
                Price_Per_Km: data.Price_Per_Km ? data.Price_Per_Km : "",
                Extra_Charges: data.Extra_Charges ? data.Extra_Charges : "",
                Fare_Per_Day: data.Fare_Per_Day ? data.Fare_Per_Day : "",
                Delivery_Comments: data.Delivery_Comments ? data.Delivery_Comments : "",
                Bidding_Status: data.Bidding_Status ? data.Bidding_Status : "",
                Status: data.Status ? data.Status : "",
                Esi_Commission_Amount: data.ESI_Commision_Amount ? data.ESI_Commision_Amount : "",
                Extra_Days_Per_Ride: data.Extra_Days_Per_Ride ? data.Extra_Days_Per_Ride : "",
                Extra_Kms_Per_Ride: data.Extra_Kms_Per_Ride ? data.Extra_Kms_Per_Ride : "",
                Final_Payment_Status: data.Final_Payment_Status ? data.Final_Payment_Status : "",
                Payment_Method: data.Payment_Method ? data.Payment_Method : "",
                Modified_By: loginEmail,
                Vehicle_Quotes: array
            }}
            validationSchema={
                Yup.object().shape({
                    Bidding_Status: Yup.string().required('Bidding Status is required.'),
                    Price_Per_Km: Yup.number().min(0, "Price Per Km should not be less than 0.").required('Price Per Km is required.'),
                    Fare_Per_Day: Yup.number().min(0, "Fare Per Day should not be less than 0."),
                    Status: Yup.string().required('Status is required.'),
                    Quotation_Amount: Yup.number().positive('Advance Token Amount must be a positive number.')
                        .required('Quotation Amount is required.')
                        .min(Yup.ref('Advance_Token_Amount'), 'Quotation Amount must be greater than Advance Token Amount'),
                    Advance_Token_Amount: Yup.number().positive('Advance Token Amount must be a positive number.')
                        .required('Advance Token Amount is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(updateDeliveryBiddingQuotes(formValues, navigate, state));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                setValues,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Del User Email ID"
                                                name="Del_User_Email_Id"
                                                // select
                                                // InputLabelProps={{ shrink: true }}
                                                // SelectProps={{ native: true }}
                                                value={values.Del_User_Email_Id}
                                                variant="outlined"
                                                disabled
                                            >
                                                {/* <option key="" value="">--Please Select--</option>
                                                {biddeliusers?.map(option => (
                                                    <option key={option.Del_User_Detail_Id} value={option.Del_User_Email_Id}>
                                                        {option.Del_User_Email_Id}
                                                    </option>
                                                ))} */}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Token Amount"
                                                name="Advance_Token_Amount"
                                                value={values.Advance_Token_Amount}
                                                variant="outlined"
                                                type='number'
                                                disabled
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Price_Per_Km && errors.Price_Per_Km)}
                                                fullWidth
                                                helperText={touched.Price_Per_Km && errors.Price_Per_Km}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Price Per Km"
                                                name="Price_Per_Km"
                                                value={values.Price_Per_Km}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Fare_Per_Day && errors.Fare_Per_Day)}
                                                fullWidth
                                                helperText={touched.Fare_Per_Day && errors.Fare_Per_Day}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Fare Per Day"
                                                name="Fare_Per_Day"
                                                value={values.Fare_Per_Day}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Extra Days Per Ride"
                                                name="Extra_Days_Per_Ride"
                                                value={values.Extra_Days_Per_Ride}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Extra Kms Per Ride"
                                                name="Extra_Kms_Per_Ride"
                                                value={values.Extra_Kms_Per_Ride}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Final Payment Status"
                                                name="Final_Payment_Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Final_Payment_Status}
                                                variant="outlined"
                                            >
                                                {finalpaystatus.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Payment Method"
                                                name="Payment_Method"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Payment_Method}
                                                variant="outlined"
                                            >
                                                {paymentmethod.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Status && errors.Bidding_Status)}
                                                fullWidth
                                                helperText={touched.Bidding_Status && errors.Bidding_Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Status"
                                                name="Bidding_Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Status}
                                                variant="outlined"
                                            >
                                                {bidstatusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {array?.map((multi, index) => (
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    error={Boolean(touched.Quotation_Amount && errors.Quotation_Amount)}
                                                    fullWidth
                                                    helperText={touched.Quotation_Amount && errors.Quotation_Amount}
                                                    name="multi.Quotation_Amount"
                                                    label="Quotation Amount"
                                                    value={multi?.Quotation_Amount}
                                                    onChange={(e) => {
                                                        setValues((prevValues) => ({
                                                            ...prevValues,
                                                            Vehicle_Quotes: vehicleQuotes,
                                                        }));
                                                        handleQuotationAmountChange(Number(e.target.value), index);
                                                        let totalQuotationAmount = vehicleQuotes.reduce((total, quote) => total + Number(quote.Quotation_Amount), 0);
                                                        const totalESICommission = calculateTotalESICommission();
                                                        setFieldValue("ESI_Commission", Number(totalESICommission));
                                                        setFieldValue("Quotation_Amount", Number(totalQuotationAmount));
                                                    }}
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                    type="number"
                                                >
                                                </TextField>
                                            </Grid>
                                        ))}

                                        <Grid item md={12} xs={12}>
                                            Delivery Comments
                                            <TextareaAutosize
                                                value={values.Delivery_Comments}
                                                onChange={handleChange}
                                                name="Delivery_Comments"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Delivery Comments"
                                            >
                                            </TextareaAutosize>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ESI Commission Amount"
                                                name="Esi_Commission_Amount"
                                                value={String(values.Esi_Commission_Amount)}
                                                variant="outlined"
                                                type='number'
                                                disabled
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Quotation Amount"
                                                name="Quotation_Amount"
                                                value={String(values.Quotation_Amount)}
                                                variant="outlined"
                                                type='number'
                                                disabled
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Extra_Charges && errors.Extra_Charges)}
                                                fullWidth
                                                helperText={touched.Extra_Charges && errors.Extra_Charges}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Extra Charges"
                                                name="Extra_Charges"
                                                value={values.Extra_Charges}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditDeliveryBiddingQuote.propTypes = {
    className: PropTypes.string
};

export default EditDeliveryBiddingQuote;