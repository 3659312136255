import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletestoretypeAction,
} from '../../store/actions/storetypeActions';
import ConfirmDialog from '../../components/dialogs/confirmDialog';
import { paginationStoreTypeManagement } from 'src/store/actions/node-actions/nodepagination';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const StoreListView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [store, setStore] = useState([]);

  //permissions
  const RolePermissions = useSelector(state => state.rolepermission.Roles);
  const roleUserType = useSelector(state => state.auth.user);
  const Login_Details = useSelector(state => state.auth.user);

  const [filters, setFilters] = React.useState({
    search: '',
    search_by_filter: 'All',
  });
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = useState({ PageNo: 1, PageSize: 10, SortBy: "Store_Type_Name", SortOrder: "ASC" })

  const handleLimitChange = event => {
    setPagination({ ...pagination, PageSize: +event.target.value, PageNo: 1 });
  };

  const handlePageChange = (event, newPage) => {
    setPagination({ ...pagination, PageSize: pagination.PageSize, PageNo: newPage + 1 });
  };
  const callBackPagination = async (status, data, pagination) => {
    if (status) {
      setStore(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({ PageNo: 1, PageSize: 10, SortBy: "Store_Type_Name", SortOrder: "ASC" });
    }
  }

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      storeCall();
    }
  }, [pagination])

  const [testFilters, setTestFilters] = useState({});
  useEffect(() => {
    if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
      setTestFilters(filters);
      searchFunction();
    }
  }, [filters]);

  const searchFunction = () => {
    const searchpagination = {
      PageNo: 1, PageSize: 10, SortBy: "Store_Type_Name", SortOrder: "ASC"
    }
    dispatch(paginationStoreTypeManagement(filters, searchpagination, callBackPagination))
  }

  const storeCall = () => {
    dispatch(paginationStoreTypeManagement(filters, pagination, callBackPagination))
  };

  useEffect(() => {
    dispatch(paginationStoreTypeManagement(filters, pagination, callBackPagination))
    dispatch(getRolePermissions(Login_Details));
  }, []);

  // confirm dailogbox
  const [storetype, setStoretype] = useState(false);
  const [ttitle, setTtitle] = useState('');
  const [tmessage, setTmessage] = useState('');
  const [storetypeIdle, setStoretypeIdle] = useState('');

  const chandleClose = (value) => {
    setStoretype(false);
    if (value) {
      const deleteData = {
        Store_Type_Name: storetypeIdle
      };
      dispatch(deletestoretypeAction(deleteData,filters, pagination, callBackPagination));
    }
  };

  //Delete handler
  const deleteStoretypeHandler = storetypeData => async e => {
    setStoretype(true);
    setTtitle("Alert!");
    setTmessage("Are you sure to delete?");
    setStoretypeIdle(storetypeData);
  };

  // Sorting 
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Store_Type_Name');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPagination({ PageNo: 1, PageSize: pagination?.PageSize, SortBy: property, SortOrder: order === 'asc' ? "ASC" : "DESC" })
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <Page className={classes.root} title="Store Type">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/store-type-management"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Store Type
          </Typography>
        </Box>
        <Toolbar filters={filters} setfilters={setFilters} RolePermissions={RolePermissions} roleUserType={roleUserType} />
        <Box mt={3}>
          <Results
            allStoretypes={store}
            deleteStoretypeHandler={deleteStoretypeHandler}
            allStoretypesNoFilter={store}
            pagination={pagination}
            setPagination={setPagination}
            handleLimitChange={handleLimitChange}
            handlePageChange={handlePageChange}
            order={order}
            orderBy={orderBy}
            createSortHandler={createSortHandler}
            RolePermissions={RolePermissions}
            roleUserType={roleUserType}
          />
        </Box>
      </Container>
      <ConfirmDialog inputMessage={tmessage} titleMsg={ttitle} open={storetype} onClose={chandleClose} />
    </Page>
  );
};

export default StoreListView;