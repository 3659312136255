import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  buttonRoot: {
    minWidth: '7px',
    padding: '6px'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));

const headCells = [
  { id: 'Inventory_Id', numeric: false, disablePadding: false, label: 'Inventory ID' },
  { id: 'Availability_Stock', numeric: true, disablePadding: false, label: 'Availability Stock' },
  { id: 'Total_Stock', numeric: true, disablePadding: false, label: 'Total Stock' },
  { id: 'Name', numeric: false, disablePadding: false, label: 'Partner Name' },
  { id: 'Product_Name', numeric: false, disablePadding: false, label: 'Product Name' },
  { id: 'Partner_Product_Id', numeric: false, disablePadding: false, label: 'Partner Product ID' },
];

const Results = ({
  className,
  allInventories,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  allInventoriesNoFilter,
  createSortHandler,
  pagination,
  setPagination,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedInventoryIds, setSelectedInventoryIds] = useState([])

  const editInventoryHandler = inventoryData => e => {
    navigate('/app/edit-inventory', { state: inventoryData });
  };
  const visibleInventoryHandler = inventoryData => e => {
    navigate("/app/inventory-history", { state: inventoryData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allInventories) &&
                (allInventories).map(inventory => (
                  <TableRow
                    hover
                    key={inventory.Inventory_Id}
                    selected={
                      selectedInventoryIds.indexOf(inventory.Inventory_Id) !== -1
                    }
                  >
                    <TableCell >{inventory.Inventory_Id}</TableCell>
                    <TableCell>&nbsp; &nbsp;&nbsp; {inventory.Availability_Stock}</TableCell>
                    <TableCell>&nbsp; &nbsp;{inventory.Total_Stock}</TableCell>
                    <TableCell > {inventory.Name}</TableCell>
                    <TableCell > {inventory.Product_Name?.substring(0, 25)}...</TableCell>
                    <TableCell > {inventory.Partner_Product_Id}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Inventory_Management_Write?.Value === "Inventory_Management_Write" && RolePermissions?.Inventory_Management_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Tooltip title="Inventory History">
                          <Button
                            onClick={visibleInventoryHandler(inventory)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        <Button
                          onClick={editInventoryHandler(inventory)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Inventory_Management_Read?.Value === "Inventory_Management_Read" && RolePermissions?.Inventory_Management_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="Inventory History">
                          <Button
                            onClick={visibleInventoryHandler(inventory)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allInventories) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;