import config from './config';
import { toast } from 'react-toastify';
import { paginationUserWallet } from './node-actions/nodepagination';
import { boomiAdminApi, nodeCudApi, nodeGetApi } from './commonAxios';

export const WALLETS_REQUEST = "WALLETS_REQUEST";
export const WALLETS_SUCCESS = "WALLETS_SUCCESS";
export const WALLETS_FAIL = "WALLETS_FAIL";
export const WALLET_GET_REQUEST = "WALLET_GET_REQUEST";
export const WALLET_GET_SUCCESS = "WALLET_GET_SUCCESS";
export const WALLET_GET_FAIL = "WALLET_GET_FAIL";
export const WALLET_CREATE_REQUEST = "WALLET_CREATE_REQUEST";
export const WALLET_CREATE_SUCCESS = "WALLET_CREATE_SUCCESS";
export const WALLET_CREATE_FAIL = "WALLET_CREATE_FAIL";
export const WALLET_UPDATE_REQUEST = "WALLET_UPDATE_REQUEST";
export const WALLET_UPDATE_SUCCESS = "WALLET_UPDATE_SUCCESS";
export const WALLET_UPDATE_FAIL = "WALLET_UPDATE_FAIL";
export const WALLET_USER_EMAIL_REQUEST = "WALLET_USER_EMAIL_REQUEST";
export const WALLET_USER_EMAIL_SUCCESS = "WALLET_USER_EMAIL_SUCCESS";
export const WALLET_USER_EMAIL_FAIL = "WALLET_USER_EMAIL_FAIL";

/*=============================================================
                  Add Wallet Action
===============================================================*/
export const createWalletAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: WALLET_CREATE_REQUEST
  });
  boomiAdminApi.post(`/User_Wallet/Admin_Wallet_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: WALLET_CREATE_SUCCESS
        });
        toast('Wallet added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/wallet-management');
      } else {
        dispatch({
          type: WALLET_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: WALLET_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Update Wallet Action
===============================================================*/
export const updateWalletAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: WALLET_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/User_Wallet/Admin_Wallet_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: WALLET_UPDATE_SUCCESS
        });
        toast('Wallet updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/wallet-management');
      } else {
        dispatch({
          type: WALLET_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: WALLET_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

//node get
export const getAllWalletAction = formData => async dispatch => {
  try {
    dispatch({
      type: WALLETS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/User_Wallet/Admin_Wallet_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=WALLET_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: WALLETS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: WALLETS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: WALLETS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
              Delete  Wallet Action
===============================================================*/
export const deleteWalletAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/User_Wallet/Admin_Wallet_Delete`, formData);
    if (data) {
      toast('Wallet deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationUserWallet(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

//node API
export const getAllEmails = (emailid) => async dispatch => {
  try {
    let formData = {
      Email_Id: emailid,
      Records_Filter: "FILTER"
    };
    dispatch({
      type: WALLET_USER_EMAIL_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin/User_Get_dropdown_search?PageNo=1&PageSize=${config.pageSize}&SortBy=Email_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: WALLET_USER_EMAIL_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: WALLET_USER_EMAIL_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: WALLET_USER_EMAIL_FAIL,
      payload: err
    });
  }
};

//Get User Wallet Action
export const getUserWalletAction = (formData, callBackWalletData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/User_Wallet/Admin_Wallet_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Wallet_Id&SortOrder=ASC`, formData);
    if (data) {
      callBackWalletData(data)
    }
  } catch (err) {
  }
};