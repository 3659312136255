import {
  FAQ_CREATE_REQUEST,
  FAQ_CREATE_SUCCESS,
  FAQ_CREATE_FAIL,
  FAQ_UPDATE_REQUEST,
  FAQ_UPDATE_SUCCESS,
  FAQ_UPDATE_FAIL,
  RESET
} from '../actions/FAQActions'

const initialState = {
  faqCreate: { faq: {}, error: '', isLoading: false },
  faqUpdate: { faq: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FAQ_CREATE_REQUEST:
      return { ...state, faqCreate: { faq: {}, error: '', isLoading: true } };
    case FAQ_CREATE_SUCCESS:
      return { ...state, faqCreate: { faq: state.faqCreate.faq, error: '', isLoading: false } };
    case FAQ_CREATE_FAIL:
      return { ...state, faqCreate: { faq: {}, error: action.payload, isLoading: false } };
    case FAQ_UPDATE_REQUEST:
      return { ...state, faqUpdate: { faq: {}, error: '', isLoading: true } };
    case FAQ_UPDATE_SUCCESS:
      return { ...state, faqUpdate: { faq: action.payload, error: '', isLoading: false } };
    case FAQ_UPDATE_FAIL:
      return { ...state, faqUpdate: { faq: {}, error: action.payload, isLoading: false } };

    case RESET:
      return { ...state, faqCreate: { faq: {}, error: '', isLoading: false } };

    default:
      return state;
  }
}