import {
  SUB_CATEGORY_CREATE_REQUEST,
  SUB_CATEGORY_CREATE_SUCCESS,
  SUB_CATEGORY_CREATE_FAIL,
  SUB_CATEGORY_UPDATE_REQUEST,
  SUB_CATEGORY_UPDATE_SUCCESS,
  SUB_CATEGORY_UPDATE_FAIL,
  CATEGORIES_REQUEST,
  CATEGORIES_SUCCESS,
  CATEGORIES_FAIL,
} from '../actions/subcategoryAction'

const initialState = {
  subcategoryCreate: { subcategory: {}, error: '', isLoading: false },
  subcategoryUpdate: { subcategory: {}, error: '', isLoading: false },
  Categories: { categories: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SUB_CATEGORY_CREATE_REQUEST:
      return { ...state, subcategoryCreate: { subcategory: {}, error: '', isLoading: true } };
    case SUB_CATEGORY_CREATE_SUCCESS:
      return { ...state, subcategoryCreate: { subcategory: state.subcategoryCreate.subcategory, error: '', isLoading: false } };
    case SUB_CATEGORY_CREATE_FAIL:
      return { ...state, subcategoryCreate: { subcategory: {}, error: action.payload, isLoading: false } };
    case SUB_CATEGORY_UPDATE_REQUEST:
      return { ...state, subcategoryUpdate: { subcategory: {}, error: '', isLoading: true } };
    case SUB_CATEGORY_UPDATE_SUCCESS:
      return { ...state, subcategoryUpdate: { subcategory: action.payload, error: '', isLoading: false } };
    case SUB_CATEGORY_UPDATE_FAIL:
      return { ...state, subcategoryUpdate: { subcategory: {}, error: action.payload, isLoading: false } };

    case CATEGORIES_REQUEST:
      return { ...state, Categories: { categories: state.Categories.categories, error: '', isLoading: true }, };
    case CATEGORIES_SUCCESS:
      return { ...state, Categories: { categories: action.payload, error: '', isLoading: false } };
    case CATEGORIES_FAIL:
      return { ...state, Categories: { categories: [], error: action.payload, isLoading: false } };
    default:
      return state;
  }
}