import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/partner-management',
    createdAt: '',
    description:
      'Partner Management is the process where organizations guide and manage partnerships, so they can provide effective information about their organization details.',
    media: '/static/Dashboard/partnermanagement.jpg',
    title: 'Partner Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/partner-ticketing-management',
    createdAt: '',
    description:
      'A ticket management system can be defined as a robust software used to register, organize, prioritize, and resolve support tickets. These tickets can be issues or requests requested by your employees or your customers. ',
    media: '/static/Dashboard/ticketing_management.jpg',
    title: 'Partner Ticketing Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/partnerwallet-management',
    createdAt: '',
    description:
      'Wallet Management module allows the seller to create wallets for the customer in POS Session. Customer can get their wallet recharge to add wallet credits at any time and ... Maintain the translation history at the backend.',
    media: '/static/Dashboard/wallet_management.jpg',
    title: 'Partner Wallet Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/partnerservices-management',
    createdAt: '',
    description:
      'A Service Management helps organizers plan, execute and report on events, driving success for their business.',
    media: '/static/Dashboard/catergory.png',
    title: 'Partner Services Management',
    total: ''
  },
];