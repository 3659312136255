import {
    PART_BID_AVAIL_PROD_CREATE_REQUEST,
    PART_BID_AVAIL_PROD_CREATE_SUCCESS,
    PART_BID_AVAIL_PROD_CREATE_FAIL,

    PART_BID_AVAIL_PROD_UPDATE_REQUEST,
    PART_BID_AVAIL_PROD_UPDATE_SUCCESS,
    PART_BID_AVAIL_PROD_UPDATE_FAIL,

    PART_BID_PRODUCTS_GET_REQUEST,
    PART_BID_PRODUCTS_GET_SUCCESS,
    PART_BID_PRODUCTS_GET_FAIL,

    PART_BID_PARTNERS_GET_REQUEST,
    PART_BID_PARTNERS_GET_SUCCESS,
    PART_BID_PARTNERS_GET_FAIL,
} from '../actions/bidpartneravailproductsAction'

const initialState = {
    partbidavailprodCreate: { partbidavailprod: {}, error: '', isLoading: false },
    partbidavailprodUpdate: { partbidavailprod: {}, error: '', isLoading: false },
    partbidproducts: { all: [], error: '', isLoading: false },
    partbidpartners: { all: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case PART_BID_AVAIL_PROD_CREATE_REQUEST:
            return { ...state, partbidavailprodCreate: { partbidavailprod: {}, error: '', isLoading: true } };
        case PART_BID_AVAIL_PROD_CREATE_SUCCESS:
            return { ...state, partbidavailprodCreate: { partbidavailprod: state.partbidavailprodCreate.partbidavailprod, error: '', isLoading: false } };
        case PART_BID_AVAIL_PROD_CREATE_FAIL:
            return { ...state, partbidavailprodCreate: { partbidavailprod: {}, error: action.payload, isLoading: false } };

        case PART_BID_AVAIL_PROD_UPDATE_REQUEST:
            return { ...state, partbidavailprodUpdate: { partbidavailprod: {}, error: '', isLoading: true } };
        case PART_BID_AVAIL_PROD_UPDATE_SUCCESS:
            return { ...state, partbidavailprodUpdate: { partbidavailprod: state.partbidavailprodUpdate.partbidavailprod, error: '', isLoading: false } };
        case PART_BID_AVAIL_PROD_UPDATE_FAIL:
            return { ...state, partbidavailprodUpdate: { partbidavailprod: {}, error: action.payload, isLoading: false } };

        case PART_BID_PRODUCTS_GET_REQUEST:
            return { ...state, partbidproducts: { all: state.partbidproducts.all, error: '', isLoading: true } };
        case PART_BID_PRODUCTS_GET_SUCCESS:
            return { ...state, partbidproducts: { all: action.payload, error: '', isLoading: false } };
        case PART_BID_PRODUCTS_GET_FAIL:
            return { ...state, partbidproducts: { all: [], error: action.payload, isLoading: false } };

        case PART_BID_PARTNERS_GET_REQUEST:
            return { ...state, partbidpartners: { all: state.partbidpartners.all, error: '', isLoading: true } };
        case PART_BID_PARTNERS_GET_SUCCESS:
            return { ...state, partbidpartners: { all: action.payload, error: '', isLoading: false } };
        case PART_BID_PARTNERS_GET_FAIL:
            return { ...state, partbidpartners: { all: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}