import { nodeGetApi } from '../commonAxios';

export const DEL_DASHBOARD_TOTAL_ORDERS = "DEL_DASHBOARD_TOTAL_ORDERS";
export const DEL_DASHBOARD_DELIVERED_ORDERS = "DEL_DASHBOARD_DELIVERED_ORDERS";
export const DEL_DASHBOARD_OUTFORDELIVERY_ORDERS = "DEL_DASHBOARD_OUTFORDELIVERY_ORDERS";
export const DEL_DASHBOARD_RETURNED_ORDERS = "DEL_DASHBOARD_RETURNED_ORDERS";
export const DEL_DASHBOARD_CANCELLED_ORDERS = "DEL_DASHBOARD_CANCELLED_ORDERS";
export const DEL_DASHBOARD_TOP_DELIVERYPERSONS = "DEL_DASHBOARD_TOP_DELIVERYPERSONS";

/*=============================================================
                 Total Orders Count Action
  =============================================================*/
export const deliTotalorderCount = (formData) => {
    return function (dispatch) {
        nodeGetApi.post(`/Reports/Order_Delivered_Count`, formData)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DEL_DASHBOARD_TOTAL_ORDERS,
                        data: data.results[0],
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DEL_DASHBOARD_TOTAL_ORDERS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DEL_DASHBOARD_TOTAL_ORDERS,
                    data: String(error),
                    success: false
                });
            })
    }
}

/*=============================================================
                 Delivered Orders Count Action
  =============================================================*/
export const deliDeliveredorderCount = (formData) => {
    return function (dispatch) {
        nodeGetApi.post(`/Reports/Order_Delivered_Count`, formData)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DEL_DASHBOARD_DELIVERED_ORDERS,
                        data: data.results[0],
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DEL_DASHBOARD_DELIVERED_ORDERS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DEL_DASHBOARD_DELIVERED_ORDERS,
                    data: String(error),
                    success: false
                });
            })
    }
}

/*=============================================================
         Out for Delivery Orders Count Action
  =============================================================*/
export const deliOutfordeliveryorderCount = (formData) => {
    return function (dispatch) {
        nodeGetApi.post(`/Reports/Order_Delivered_Count`, formData)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DEL_DASHBOARD_OUTFORDELIVERY_ORDERS,
                        data: data.results[0],
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DEL_DASHBOARD_OUTFORDELIVERY_ORDERS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DEL_DASHBOARD_OUTFORDELIVERY_ORDERS,
                    data: String(error),
                    success: false
                });
            })
    }
}

/*=============================================================
        Returned Orders Count Action
  =============================================================*/
export const deliReturnedorderCount = (formData) => {
    return function (dispatch) {
        nodeGetApi.post(`/Reports/Order_Delivered_Count`, formData)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DEL_DASHBOARD_RETURNED_ORDERS,
                        data: data.results[0],
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DEL_DASHBOARD_RETURNED_ORDERS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DEL_DASHBOARD_RETURNED_ORDERS,
                    data: String(error),
                    success: false
                });
            })
    }
}

/*=============================================================
       Cancelled Orders Count Action
  =============================================================*/
export const deliCancelledorderCount = (formData) => {
    return function (dispatch) {
        nodeGetApi.post(`/Reports/Order_Delivered_Count`, formData)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DEL_DASHBOARD_CANCELLED_ORDERS,
                        data: data.results[0],
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DEL_DASHBOARD_CANCELLED_ORDERS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DEL_DASHBOARD_CANCELLED_ORDERS,
                    data: String(error),
                    success: false
                });
            })
    }
}

/*=============================================================
       top10 delivery persons Action
  =============================================================*/
export const topDeliverypersons = (formData) => {
    return function (dispatch) {
        nodeGetApi.post(`/Reports/TOP10_Delivery_Persons`, formData)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DEL_DASHBOARD_TOP_DELIVERYPERSONS,
                        data: data.results,
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DEL_DASHBOARD_TOP_DELIVERYPERSONS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DEL_DASHBOARD_TOP_DELIVERYPERSONS,
                    data: String(error),
                    success: false
                });
            })
    }
}