import React from 'react';
import {
    Box,
    InputAdornment,
    SvgIcon,
    TextField,
    makeStyles,
    Button
} from '@material-ui/core';
import clsx from 'clsx';
import { Search as SearchIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { subPackLocationFilter } from 'src/store/actions/subspacklocationsAction';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    formFilterControl: {
        margin: theme.spacing(1),
        minWidth: 200
    }
}));

const Toolbar = ({ className, state, ...rest }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    let subPackLocationsList = useSelector(state => state.subspacklocations.subspacklocationFilter.select);
    let subPackLocationsSearch = useSelector(state => state.subspacklocations.subspacklocationFilter.search);

    const handleChange = event => {
        dispatch(subPackLocationFilter('select', event.target.value));
    };
    const handleSearch = event => {
        dispatch(subPackLocationFilter('search', event.target.value));
    };

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                alignItems="center"
                mb={2}
            >
                <Box display="flex" flexWrap="wrap" mb={2}>
                    <FormControl variant="outlined" className={classes.formFilterControl}>
                        <Select
                            value={subPackLocationsList}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="Subcription_Package_Locations_ID">Subscription Package Location ID</MenuItem>
                            <MenuItem value="Subcription_Package_ID">Subscription Package ID</MenuItem>
                            <MenuItem value="City_ID">City ID</MenuItem>
                            <MenuItem value="Partner_Details_ID">Partner Details ID</MenuItem>
                            <MenuItem value="Partner_Store_Address_ID">Partner Store Address ID</MenuItem>
                        </Select>
                    </FormControl>

                    <Box width={500}>
                        <TextField
                            onChange={handleSearch}
                            className={classes.formControl}
                            value={subPackLocationsSearch}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon fontSize="small" color="action">
                                            <SearchIcon />
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}
                            placeholder="Search Subscription Package Locations"
                            variant="outlined"
                        />
                    </Box>
                </Box>
                <Button
                    component={RouterLink}
                    to="/app/add-subs-package-locations"
                    color="primary"
                    variant="contained"
                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                    state={state}
                >
                    <span style={{ color: "white" }}>
                        + ADD SUBSC PACK LOCATION
                    </span>
                </Button>
            </Box>
        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;