import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  updateGiftImageAction,
  getAllgiftvendorsids,
} from './../../store/actions/giftimageAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const purposeOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Birthday',
    label: 'Birthday'
  },
  {
    value: 'Anniversary',
    label: 'Anniversary'
  },
  {
    value: 'Festival',
    label: 'Festival'
  },
  {
    value: 'Occasion',
    label: 'Occasion'
  },
];

const typeOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Card',
    label: 'Card'
  },
  {
    value: 'Voucher',
    label: 'Voucher'
  },
];

const useStyles = makeStyles(() => ({
  root: {},
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  },
}));

const EditGiftimage = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const { state } = location;

  // componentDidMount
  useEffect(() => {
    dispatch(getAllgiftvendorsids());
  }, []);

  const giftvendorid = useSelector(state => state.giftimage.giftVendorid.giftvendorid);
  const isSubmitted = useSelector(state => state.giftimage.giftimageUpdate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  // img upload
  const [image, setImage] = useState({ preview: state.Image_URL, raw: '' });
  const [imgUrl, setImgUrl] = useState(state.Image_URL);
  const [uploadPic, setUploadPic] = useState(false);
  const [imageError, setImageError] = useState();
  const [globalError, setGlobalError] = useState();

  const handleImage = e => {
    setImgUrl(URL.createObjectURL(e.target.files[0]));
    setUploadPic(new Blob([e.target.files[0]]));
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: ''
    });
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
    setUploadPic("");
  };

  return (
    <Formik
      initialValues={{
        Gift_Image_Id: state.Gift_Image_Id ? state.Gift_Image_Id : "",
        Vendor_Id: state.Vendor_Id ? state.Vendor_Id : "",
        Type: state.Type ? state.Type : "",
        Occassion: state.Occassion ? state.Occassion : "",
        Status: state.Status ? state.Status : "",
        Modified_By: loginEmail
      }}

      validationSchema={
        Yup.object().shape({
          Vendor_Id: Yup.string().required('Vendor Name is required.'),
          Type: Yup.string().required('Type is required.'),
          Occassion: Yup.string().required('Occasion is required.'),
          Status: Yup.string().required('Status is required.'),
        })
      }

      onSubmit={(values, { setErrors, setSubmitting }) => {
        let submitTest = true;
        setSubmitting(false);
        setImageError("");

        if (imgUrl.length <= 0) {
          submitTest = false;
          setImageError("Image is required.");
        }
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(updateGiftImageAction(formValues, uploadPic ? uploadPic : false, navigate, setErrors, setGlobalError));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}
        >
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={5} xs={12}>
                  {/* vendor_id */}
                  <TextField
                    error={Boolean(touched.Vendor_Id && errors.Vendor_Id)}
                    fullWidth
                    helperText={touched.Vendor_Id && errors.Vendor_Id}
                    disabled
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Vendor Name"
                    InputLabelProps={{ shrink: true }}
                    name="Vendor_Id"
                    select
                    SelectProps={{ native: true }}
                    value={values.Vendor_Id}
                    variant="outlined"
                  ><option key="" value="">--Please Select--</option>
                    {giftvendorid.map(option => (
                      <option key={option.Vendor_Id} value={option.Vendor_Id}>
                        {option.Vendor_Name}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                {/* type */}
                <Grid item md={5} xs={12}>
                  <TextField
                    error={Boolean(touched.Type && errors.Type)}
                    fullWidth
                    helperText={touched.Type && errors.Type}
                    placeholder="Type"
                    label="Type"
                    name="Type"
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    value={values.Type}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {typeOption.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                {/* occassion */}
                <Grid item md={5} xs={12}>
                  <TextField
                    error={Boolean(touched.Occassion && errors.Occassion)}
                    fullWidth
                    helperText={touched.Occassion && errors.Occassion}
                    placeholder="Occassion"
                    label="Occasion"
                    name="Occassion"
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    value={values.Occassion}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {purposeOption.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                {/* Status */}
                <Grid item md={5} xs={12}>
                  <TextField
                    error={Boolean(touched.Status && errors.Status)}
                    fullWidth
                    helperText={touched.Status && errors.Status}
                    placeholder="Status"
                    label="Status"
                    name="Status"
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    value={values.Status}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {statusOption.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                {/* upload photo */}
                <Grid item md={4} xs={12}>
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Image Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Image Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                  <span style={{ color: "red" }}>
                    {!imgUrl && imageError}
                  </span>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{globalError}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmitted ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmitted}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditGiftimage.propTypes = {
  className: PropTypes.string
};

export default EditGiftimage;