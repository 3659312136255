import { Accordion, AccordionDetails, AccordionSummary, Card, Grid, makeStyles, Divider } from '@material-ui/core';
import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { getAllBidDeliMultipleVehicles } from 'src/store/actions/deliverybiddingquotesAction';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'src/store/validations/is-empty';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(() => ({
    images: {
        width: 100,
        height: 100
    },
    content: {
        justifyContent: "center"
    },
}));

const MultiVehicleData = ({ className, state, ...rest }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        let formData = {
            Records_Filter: "FILTER",
            User_Ride_Bidding_Id: state.User_Ride_Bidding_Id
        };
        dispatch(getAllBidDeliMultipleVehicles(formData));
    }, []);

    const multivehicledata = useSelector(state => state.deliverybiddingquotes.biddelimultivehicles.all);

    return (
        <Card>
            <div style={{ marginTop: '1rem' }}>
                <Accordion>
                    <div className="vehicalsDetailCard">
                        <AccordionSummary
                            className={classes.content}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"  >
                            {<div style={{ marginTop: "-1%", fontSize: "16px" }}>Vehicle Details</div>}
                        </AccordionSummary>
                    </div>
                    <Divider />
                    <div >
                        {!isEmpty(multivehicledata) ? (multivehicledata?.Is_Data_Exist === "0" ? ("No Details Found") : (multivehicledata?.map((multivehicledata, index) =>
                            <div key={index}>
                                <AccordionDetails>
                                    <div>
                                        <Grid container spacing={3}>
                                            <Grid item md={6}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={5}>
                                                        <Typography
                                                            variant="h5"
                                                            component="h2"
                                                            gutterBottom
                                                            style={{ color: '#66788A' }}
                                                        >
                                                            Vehicle Type
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Typography
                                                            variant="h5"
                                                            component="h2"
                                                            gutterBottom
                                                            style={{ paddingLeft: '3rem' }}
                                                        >
                                                            {multivehicledata?.Vehicle_Type}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={5}>
                                                        <Typography
                                                            variant="h5"
                                                            component="h2"
                                                            gutterBottom
                                                            style={{ color: '#66788A' }}
                                                        >
                                                            Vehicle Comfort Type
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Typography
                                                            variant="h5"
                                                            component="h2"
                                                            gutterBottom
                                                            style={{ paddingLeft: '3rem' }}
                                                        >
                                                            {multivehicledata?.Vehicle_Comfort_Type}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={5}>
                                                        <Typography
                                                            variant="h5"
                                                            component="h2"
                                                            gutterBottom
                                                            style={{ color: '#66788A' }}
                                                        >
                                                            Brand Name
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Typography
                                                            variant="h5"
                                                            component="h2"
                                                            gutterBottom
                                                            style={{ paddingLeft: '3rem' }}
                                                        >
                                                            {multivehicledata?.Brand_Name}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={2} />
                                                    <Grid item xs={2} />
                                                    <Grid item xs={7} />
                                                </Grid>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Grid container spacing={3}>

                                                    <Grid item xs={5}>
                                                        <Typography
                                                            variant="h5"
                                                            component="h2"
                                                            gutterBottom
                                                            style={{ color: '#66788A' }}
                                                        >
                                                            Seating Capacity
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Typography
                                                            variant="h5"
                                                            component="h2"
                                                            gutterBottom
                                                            style={{ paddingLeft: '3rem' }}
                                                        >
                                                            {multivehicledata?.Seating_Capacity}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={5}>
                                                        <Typography
                                                            variant="h5"
                                                            component="h2"
                                                            gutterBottom
                                                            style={{ color: '#66788A' }}
                                                        >
                                                            Quantity
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Typography
                                                            variant="h5"
                                                            component="h2"
                                                            gutterBottom
                                                            style={{ paddingLeft: '3rem' }}
                                                        >
                                                            {multivehicledata?.Quantity}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={5}>
                                                        <Typography
                                                            variant="h5"
                                                            component="h2"
                                                            gutterBottom
                                                            style={{ color: '#66788A' }}
                                                        >
                                                            Vehicle Image
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Typography
                                                            variant="h5"
                                                            component="h2"
                                                            gutterBottom
                                                            style={{ paddingLeft: '3rem' }}
                                                        >
                                                            <a target="_blank" href={multivehicledata?.Vehicle_Image}>
                                                                <img
                                                                    className={classes.images}
                                                                    alt="Vehicle Image Path"
                                                                    src={multivehicledata?.Vehicle_Image}
                                                                    variant="square"
                                                                />
                                                            </a>
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </AccordionDetails>
                            </div>))) : <div style={{ textAlign: "center", marginBottom: "20px" }}>No Details Found</div>}
                    </div>
                </Accordion>
            </div>
        </Card >
    );
};

export default MultiVehicleData;