import { nodeGetApi } from './commonAxios';

export const USER_DATEANDCOUNT_REQUEST = 'USER_DATEANDCOUNT_REQUEST';
export const USER_DATEANDCOUNT_SUCCESS = "USER_DATEANDCOUNT_SUCCESS";
export const USER_DATEANDCOUNT_FAIL = "USER_DATEANDCOUNT_FAIL";

export const getUsermaincenterDateandCount = () => async dispatch => {
    try {
        dispatch({
            type: USER_DATEANDCOUNT_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Total_Count_And_Date/User_Management`);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: USER_DATEANDCOUNT_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: USER_DATEANDCOUNT_SUCCESS,
                    payload: data.results[0]
                });
            }
        }
    } catch (err) {
        dispatch({
            type: USER_DATEANDCOUNT_FAIL,
            payload: err
        });
    }
};