import * as deliverystatistics from 'src/store/actions/delivery-reports/deliverystatisticsActions';
import * as deliverydashboard from '../../actions/delivery-reports/deliverydashboardAction';

const initialState = {
    DeliTotalOrders: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    DeliDeliveredOrders: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    DeliOutfordeliveryOrders: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    DeliReturnedOrders: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    DeliCancelledOrders: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    TopDeliveryPersons: { data: [], error: "" },

    DeliveryOrderStat: { data: [], error: "" },
}

const deliverydashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case deliverydashboard.DEL_DASHBOARD_TOTAL_ORDERS:
            return { ...state, DeliTotalOrders: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } }

        case deliverydashboard.DEL_DASHBOARD_DELIVERED_ORDERS:
            return { ...state, DeliDeliveredOrders: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } }

        case deliverydashboard.DEL_DASHBOARD_OUTFORDELIVERY_ORDERS:
            return { ...state, DeliOutfordeliveryOrders: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } }

        case deliverydashboard.DEL_DASHBOARD_RETURNED_ORDERS:
            return { ...state, DeliReturnedOrders: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } }

        case deliverydashboard.DEL_DASHBOARD_CANCELLED_ORDERS:
            return { ...state, DeliCancelledOrders: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } }

        case deliverydashboard.DEL_DASHBOARD_TOP_DELIVERYPERSONS:
            return { ...state, TopDeliveryPersons: { data: action.success ? action.data : [], error: action.success ? "" : action.data } };


        case deliverystatistics.DEL_STATISTICS_TOTAL_ORDERS:
            return { ...state, DeliveryOrderStat: { data: action.success ? action.data : [], error: action.success ? "" : action.data } }

        default:
            return state;
    }
}


export default deliverydashboardReducer;