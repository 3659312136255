import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const Sales = ({ className, statistics, title, ...rest }) => {
  const theme = useTheme();
  const [data, setData] = useState({
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        data: [18, 5, 19, 27, 29, 19, 20],
        label: 'This year'
      },
      {
        backgroundColor: colors.grey[200],
        data: [11, 20, 12, 29, 30, 25, 13],
        label: 'Last year'
      }
    ],
    labels: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug']
  });

  function statisticData(statistics, diff) {
    let colorsSample = [
      colors.amber[500],
      colors.blue[500], colors.blueGrey[500], colors.brown[500], colors.common[500], colors.common[500], colors.cyan[500], colors.deepOrange[500],
      colors.deepOrange[500], colors.deepPurple[500], colors.green[500], colors.grey[500], colors.indigo[500], colors.lightBlue[500], colors.lightGreen[500], colors.lime[500],
      colors.orange[500], colors.pink[500], colors.purple[500], colors.red[500], colors.teal[500], colors.yellow[500]];
    let response = []
    try {
      for (let i = 0; i < statistics.length; i++) {
        if (diff === "All" || diff === statistics[i].Year) {
          response.push({
            backgroundColor: colorsSample[i],
            data: [
              statistics[i].Months.Jan ? statistics[i].Months.Jan : 0,
              statistics[i].Months.Feb ? statistics[i].Months.Feb : 0,
              statistics[i].Months.Mar ? statistics[i].Months.Mar : 0,
              statistics[i].Months.April ? statistics[i].Months.April : 0,
              statistics[i].Months.May ? statistics[i].Months.May : 0,
              statistics[i].Months.Jun ? statistics[i].Months.Jun : 0,
              statistics[i].Months.July ? statistics[i].Months.July : 0,
              statistics[i].Months.Aug ? statistics[i].Months.Aug : 0,
              statistics[i].Months.Sep ? statistics[i].Months.Sep : 0,
              statistics[i].Months.Oct ? statistics[i].Months.Oct : 0,
              statistics[i].Months.Nov ? statistics[i].Months.Nov : 0,
              statistics[i].Months.Dec ? statistics[i].Months.Dec : 0,
            ],
            label: statistics[i].Year
          })
        }
      }
    }
    catch (error) {
    }
    return {
      datasets: response,
      labels: ["Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]
    };
  }

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const [timeSpan, setTimeSpan] = React.useState('All');

  const handleChange = event => {
    setTimeSpan(event.target.value);
  };

  return (
    <Card {...rest}>
      <CardHeader
        action={
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={timeSpan}
            onChange={handleChange}
            style={{ width: "100px" }}
          >
            <MenuItem key={"All"} value={'All'}>All</MenuItem>

            {statistics ? statistics.map(option => (
              <MenuItem key={option.Year} value={option.Year}>{option.Year}</MenuItem>
            )) : ""}
          </Select>
        }
        title={title}
      />
      <Divider />
      <CardContent>
        <Box height={400} position="relative">
          <Bar data={statisticData(statistics, timeSpan)} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string
};

export default Sales;