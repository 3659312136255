import {
  CITYPARTNERCOUPON_GET_REQUEST,
  CITYPARTNERCOUPON_GET_SUCCESS,
  CITYPARTNERCOUPON_GET_FAIL,
  CITYPARTNERCOUPON_CREATE_REQUEST,
  CITYPARTNERCOUPON_CREATE_SUCCESS,
  CITYPARTNERCOUPON_CREATE_FAIL,
  CITYPARTNERCOUPON_UPDATE_REQUEST,
  CITYPARTNERCOUPON_UPDATE_SUCCESS,
  CITYPARTNERCOUPON_UPDATE_FAIL,

  CITYPARTNERCOUPON_COUPONID_REQUEST,
  CITYPARTNERCOUPON_COUPONID_SUCCESS,
  CITYPARTNERCOUPON_COUPONID_FAIL,
  CITYPARTNERCOUPON_COUPONID_SEARCH_REQUEST,
  CITYPARTNERCOUPON_COUPONID_SEARCH_SUCCESS,
  CITYPARTNERCOUPON_COUPONID_SEARCH_FAIL,

} from '../actions/citypartnercouponAction'

const initialState = {
  citypartnercoupons: { all: [], error: '', isLoading: false },
  citypartnercoupon: { citypartnercoupon: {}, error: '', isLoading: false },
  citypartnercouponCreate: { citypartnercoupon: {}, error: '', isLoading: false },
  citypartnercouponUpdate: { citypartnercoupon: {}, error: '', isLoading: false },
  citypartnercouponsearchids: { couponsearchids: [], error: '', isLoading: false },
  citypartnercouponids: { couponids: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CITYPARTNERCOUPON_GET_REQUEST:
      return { ...state, citypartnercoupon: { citypartnercoupon: {}, error: '', isLoading: true } };
    case CITYPARTNERCOUPON_GET_SUCCESS:
      return { ...state, citypartnercoupon: { citypartnercoupon: action.payload, error: '', isLoading: false } };
    case CITYPARTNERCOUPON_GET_FAIL:
      return { ...state, citypartnercoupon: { citypartnercoupon: {}, error: action.payload, isLoading: false } };
    case CITYPARTNERCOUPON_CREATE_REQUEST:
      return { ...state, citypartnercouponCreate: { citypartnercoupon: {}, error: '', isLoading: true } };
    case CITYPARTNERCOUPON_CREATE_SUCCESS:
      return { ...state, citypartnercouponCreate: { citypartnercoupon: action.payload, error: '', isLoading: false } };
    case CITYPARTNERCOUPON_CREATE_FAIL:
      return { ...state, citypartnercouponCreate: { citypartnercoupon: {}, error: action.payload, isLoading: false } };
    case CITYPARTNERCOUPON_UPDATE_REQUEST:
      return { ...state, citypartnercouponUpdate: { citypartnercoupon: {}, error: '', isLoading: true } };
    case CITYPARTNERCOUPON_UPDATE_SUCCESS:
      return { ...state, citypartnercouponUpdate: { citypartnercoupon: action.payload, error: '', isLoading: false } };
    case CITYPARTNERCOUPON_UPDATE_FAIL:
      return { ...state, citypartnercouponUpdate: { citypartnercoupon: {}, error: action.payload, isLoading: false } };

    case CITYPARTNERCOUPON_COUPONID_REQUEST:
      return { ...state, citypartnercouponids: { couponids: state.citypartnercouponids.couponids, error: '', isLoading: true }, };
    case CITYPARTNERCOUPON_COUPONID_SUCCESS:
      return { ...state, citypartnercouponids: { couponids: action.payload, error: '', isLoading: false } };
    case CITYPARTNERCOUPON_COUPONID_FAIL:
      return { ...state, citypartnercouponids: { couponids: [], error: action.payload, isLoading: false } };
    case CITYPARTNERCOUPON_COUPONID_SEARCH_REQUEST:
      return { ...state, citypartnercouponsearchids: { couponsearchids: state.citypartnercouponsearchids.couponsearchids, error: '', isLoading: true }, };
    case CITYPARTNERCOUPON_COUPONID_SEARCH_SUCCESS:
      return { ...state, citypartnercouponsearchids: { couponsearchids: action.payload, error: '', isLoading: false } };
    case CITYPARTNERCOUPON_COUPONID_SEARCH_FAIL:
      return { ...state, citypartnercouponsearchids: { couponsearchids: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}
