import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Avatar
} from '@material-ui/core';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { updatePartnerDeliveryAgentAction, uploadPartnerDeliAgentpicImage } from 'src/store/actions/partnerdeliveryagentsAction';
import { CircularProgress } from '@mui/material';

const GenderOptions = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Male',
    label: 'Male'
  },
  {
    value: 'Female',
    label: 'Female'
  }
];

const DeliveryOptions = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'PARTNER_DELIVERY',
    label: 'PARTNER_DELIVERY'
  }
];

const delipersontypeOptions = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'DELIVERY',
    label: 'DELIVERY'
  },
];

const useStyles = makeStyles(() => ({
  root: {},
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditPartnerDeliveryAgentDetails = ({ className, state, data, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const [mobile, setMobile] = useState(data.Mobile);

  const isSubmited = useSelector(state => state.partnerdeliveryagent.partnerdeliveryagentUpdate.isLoading);
  const errorMessage = useSelector(state => state.partnerdeliveryagent.partnerdeliveryagentUpdate.error);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);

  // img upload
  const [image, setImage] = useState({ preview: data.Profile_Pic_Path, raw: data.Profile_Pic_Path });
  const [imgUrl, setImgUrl] = useState(data.Profile_Pic_Path);
  const [uploadPic, setUploadPic] = useState(false);

  const callBackUploadImage = data1 => {
    if (data1) {
      setImgUrl(data1.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    let data1 = new FormData();
    data1.append('file', e.target.files[0]);
    dispatch(
      uploadPartnerDeliAgentpicImage(data.Del_User_Email_Id, new Blob([e.target.files[0]]), callBackUploadImage)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  const values = {
    Partner_Id: data.Partner_Id ? data.Partner_Id : "",
    Del_User_Detail_Id: data.Del_User_Detail_Id ? data.Del_User_Detail_Id : "",
    First_Name: data.First_Name ? data.First_Name : "",
    Last_Name: data.Last_Name ? data.Last_Name : "",
    Full_Name: data.Full_Name ? data.Full_Name : "",
    Del_User_Email_Id: data.Del_User_Email_Id ? data.Del_User_Email_Id : "",
    Del_User_Type: data.Del_User_Type ? data.Del_User_Type : "",
    DOB: data.DOB ? data.DOB : "",
    Gender: data.Gender ? data.Gender : "",
    Role: data.Role ? data.Role : "",
    Mobile: data.Mobile ? data.Mobile : "",
    Profile_Pic_Path: imgUrl,
    Aadhar: data.Aadhar ? data.Aadhar : "",
    License: data.License ? data.License : "",
    Is_Active: data.Is_Active > 0 ? true : false,
    Is_Email_Verified: data.Is_Email_Verified > 0 ? true : false,
    Is_Mobile_Verified: data.Is_Mobile_Verified > 0 ? true : false,
    T_and_C_Aggreed: data.T_and_C_Agreed > 0 ? true : false,
    Marketing_Agreed: data.Marketing_Agreed > 0 ? true : false,
    Updated_By: modifiedBy
  }

  const Form_Validation = Yup.object().shape({
    First_Name: Yup.string().required('First Name is required.'),
    Last_Name: Yup.string().required('Last Name is required.'),
    Full_Name: Yup.string().required('Full Name is required.'),
    Del_User_Type: Yup.string().required('Delivery User Type is required.'),
    Del_User_Email_Id: Yup.string().email('Please provide valid Delivery Agent Email ID.').required('Delivery Agent Email ID is required.'),
    DOB: Yup.date()
      .max(new Date(Date.now() - 567648000000), "Delivery Person must be at least 18 years old.")
      .required("Date of Birth is required."),
    Gender: Yup.string().required('Gender is required.'),
    Role: Yup.string().required('Role is required.'),
    Mobile: Yup.string().min(14, "Please provide a valid Mobile Number.").required('Mobile Number is required.'),
    Aadhar: Yup.string().min(12, "Aadhaar Number should contain 12 characters.").max(12, "Aadhaar Number should contain 12 characters.").matches(/^[0-9]*$/, "Aadhaar Number is not valid.").required("Aadhaar Number is required."),
    License: Yup.string().min(16, "Driving License Number is not valid.").max(16, "Driving License Number is not valid.").required("Driving License Number is required."),
  })

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};
    values.Profile_Pic_Path = imgUrl;
    // if (!values.Profile_Pic_Path) {
    //   submitTest = false;
    //   errors.Profile_Pic_Path = "Image is required.";
    // }
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      if (values.T_and_C_Aggreed) {
        formValues.T_and_C_Aggreed = 1;
      } else {
        formValues.T_and_C_Aggreed = 0;
      }
      if (values.Is_Mobile_Verified) {
        formValues.Is_Mobile_Verified = 1;
      } else {
        formValues.Is_Mobile_Verified = 0;
      }
      if (values.Is_Active) {
        formValues.Is_Active = 1;
      } else {
        formValues.Is_Active = 0;
      }
      if (values.Is_Email_Verified) {
        formValues.Is_Email_Verified = 1;
      } else {
        formValues.Is_Email_Verified = 0;
      }
      if (values.Marketing_Agreed) {
        formValues.Marketing_Agreed = 1;
      } else {
        formValues.Marketing_Agreed = 0;
      }
      formValues.Updated_By = modifiedBy;
      dispatch(updatePartnerDeliveryAgentAction(formValues, navigate, state));
    }
  }

  return (
    <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.First_Name && errors.First_Name)}
                        label="First Name"
                        name="First_Name"
                        onChange={handleChange}
                        value={values.First_Name}
                        variant="outlined"
                        helperText={<ErrorMessage name="First_Name" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Last_Name && errors.Last_Name)}
                        label="Last Name"
                        name="Last_Name"
                        onChange={handleChange}
                        value={values.Last_Name}
                        variant="outlined"
                        helperText={<ErrorMessage name="Last_Name" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Full_Name && errors.Full_Name)}
                        label="Full Name"
                        name="Full_Name"
                        onChange={handleChange}
                        value={values.Full_Name}
                        variant="outlined"
                        helperText={<ErrorMessage name="Full_Name" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Del_User_Email_Id && errors.Del_User_Email_Id)}
                        label="Delivery Agent Email ID"
                        name="Del_User_Email_Id"
                        onChange={handleChange}
                        value={values.Del_User_Email_Id}
                        variant="outlined"
                        disabled
                        helperText={<ErrorMessage name="Del_User_Email_Id" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.DOB && errors.DOB)}
                        fullWidth
                        helperText={touched.DOB && errors.DOB}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="date"
                        label="Date of Birth"
                        name="DOB"
                        value={values.DOB}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Gender && errors.Gender)}
                        fullWidth
                        placeholder="Gender"
                        name="Gender"
                        onChange={handleChange}
                        label="Gender"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Gender}
                        variant="outlined"
                        helperText={<ErrorMessage name="Gender" />}
                      >
                        {GenderOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Role && errors.Role)}
                        fullWidth
                        placeholder="Role"
                        name="Role"
                        onChange={handleChange}
                        label="Role"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Role}
                        variant="outlined"
                        helperText={<ErrorMessage name="Role" />}
                      >
                        {DeliveryOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Aadhar && errors.Aadhar)}
                        label="Aadhaar Number"
                        name="Aadhar"
                        onChange={handleChange}
                        value={values.Aadhar}
                        variant="outlined"
                        inputProps={{ maxLength: 12 }}
                        helperText={<ErrorMessage name="Aadhar" />}
                      />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.License && errors.License)}
                        label="Driving License Number"
                        name="License"
                        onChange={handleChange}
                        value={values.License}
                        variant="outlined"
                        inputProps={{ maxLength: 16 }}
                        helperText={<ErrorMessage name="License" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <PhoneInput
                        containerClass={touched.Mobile && errors.Mobile ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                        inputClass={"form-control MuiOutlinedInput-input"}
                        error={true}
                        country={"in"}
                        fullWidth
                        inputStyle={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px"
                        }}
                        inputProps={{
                          name: "Mobile",
                        }}
                        specialLabel="Mobile Number"
                        name="Mobile"
                        onBlur={handleBlur}
                        placeholder="+91 XXXXX XXXXX"
                        onChange={(value, country, e, formattedValue) => {
                          setFieldValue("Mobile", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                          setMobile(formattedValue)
                        }}
                        value={mobile}
                      />
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Mobile && errors.Mobile}</p>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Del_User_Type && errors.Del_User_Type)}
                        fullWidth
                        placeholder="Del User Type"
                        name="Del_User_Type"
                        onChange={handleChange}
                        label="Delivery User Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Del_User_Type}
                        variant="outlined"
                        helperText={<ErrorMessage name="Del_User_Type" />}
                      >
                        {delipersontypeOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.T_and_C_Aggreed}
                            onChange={handleChange}
                            name="T_and_C_Aggreed"
                            color="primary"
                          />
                        }
                        label="T&C Agreed"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Active}
                            onChange={handleChange}
                            name="Is_Active"
                            color="primary"
                          />
                        }
                        label="Is Active"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Mobile_Verified}
                            onChange={handleChange}
                            name="Is_Mobile_Verified"
                            color="primary"
                          />
                        }
                        label="Is Mobile Verified"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Marketing_Agreed}
                            onChange={handleChange}
                            name="Marketing_Agreed"
                            color="primary"
                          />
                        }
                        label="Marketing Agreed"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Email_Verified}
                            onChange={handleChange}
                            name="Is_Email_Verified"
                            color="primary"
                          />
                        }
                        label="Is Email Verified"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Profile pic Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Profile pic Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                  {/* <span style={{ color: "red" }}>
                      {!imgUrl && touched.Profile_Pic_Path && errors.Profile_Pic_Path}
                    </span> */}
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited || uploadPic}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default EditPartnerDeliveryAgentDetails;