import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from '../../components/dialogs/confirmDialog'
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';
import { paginationCorporateTicket } from 'src/store/actions/node-actions/nodepagination';
import { deletedCorpTicketAction } from 'src/store/actions/corporateticketAction';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CorporateTicketsListView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  //permissions
  const RolePermissions = useSelector(state => state.rolepermission.Roles);
  const roleUserType = useSelector(state => state.auth.user);
  const Login_Details = useSelector(state => state.auth.user);
  
  const [corporateticket, setCorporateTicket] = useState([]);

  const [filters, setFilters] = React.useState({
    "Search": '',
    "Search_By_Filter": 'All'
  });

  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = useState({ page: 1, limit: 10, sortBy: "createdAt:desc" })

  const handleLimitChange = event => {
    setPagination({ ...pagination, limit: +event.target.value, page: 1, sortBy: "createdAt:desc" });
  };

  const handlePageChange = (event, newPage) => {
    setPagination({ ...pagination, limit: pagination.limit, page: newPage + 1, sortBy: "createdAt:desc"});
  };

  const callBackPagination = async (status, data, pagination) => {
    if (status) {
      setCorporateTicket(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({ page: 1, limit: 10, sortBy: "createdAt:desc" });
    }
  }

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      deliveryticketCall();
    }
  }, [pagination])

  const [testFilters, setTestFilters] = useState({});
  useEffect(() => {
    if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
      setTestFilters(filters);
      searchFunction();
    }
  }, [filters]);
  const searchFunction = () => {
    const searchpagination = {
      page: 1, limit: 10, Search_By_Filter: filters.Search_By_Filter, Search:filters.Search, sortBy: "createdAt:desc"
    }
    dispatch(paginationCorporateTicket(filters, searchpagination, callBackPagination))
  }

  const deliveryticketCall = () => {
    dispatch(paginationCorporateTicket(filters, pagination, callBackPagination))
  };


  // confirm dailogbox
  const [copen, setCopen] = useState(false);
  const [ctitle, setCtitle] = useState('');
  const [cmessage, setCmessage] = useState('');
  const [corporateticketIdle, setcorporateTicketIdle] = useState([]);

  const chandleClose = (value) => {
    setCopen(false);

    if (value) {
      const deleteData = {
        Ticket_Id: corporateticketIdle?.id ? [corporateticketIdle?.id] : corporateticketIdle
      };
      dispatch(deletedCorpTicketAction(deleteData, filters, { page: 1, limit: 10, sortBy: "createdAt:desc" }, callBackPagination));
    }
  };

  //Delete handler
  const deletecorporateTickethandler = corporateticketData => async e => {
    setCopen(true);
    setCtitle("Alert!");
    setCmessage("Are you sure to delete?");
    setcorporateTicketIdle(corporateticketData);
  };


  // Sorting 
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setPagination({ page: 1, limit: pagination?.limit, sortBy: order === 'asc' ? "createdAt:asc" : "createdAt:desc" })
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };


  // componentDidMount
  useEffect(() => {

    dispatch(paginationCorporateTicket(filters, pagination, callBackPagination));
    dispatch(getRolePermissions(Login_Details));
  }, []);

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/corporate-management"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Corporate Ticket Management
          </Typography>
        </Box>
        <Toolbar filters={filters} setfilters={setFilters} RolePermissions={RolePermissions} roleUserType={roleUserType} />
        <Box mt={3}>
          <Results
            allcorporateicket={corporateticket}
            allcorporateTicketNoFilter={corporateticket}
            deletecorporateTickethandler={deletecorporateTickethandler}
            handleLimitChange={handleLimitChange}
            handlePageChange={handlePageChange}
            order={order}
            orderBy={orderBy}
            pagination={pagination}
            setPagination={setPagination}
            createSortHandler={createSortHandler}
            RolePermissions={RolePermissions}
            roleUserType={roleUserType}
          />
        </Box>
      </Container>
      <ConfirmDialog inputMessage={cmessage} titleMsg={ctitle} open={copen} onClose={chandleClose} />
    </Page>
  );
};

export default CorporateTicketsListView;