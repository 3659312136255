import { toast } from 'react-toastify';
import { paginationDeliveryPayment } from './node-actions/nodepagination';
import { nodeCudApi,boomiAdminApi } from './commonAxios';
export const DELIVERY_PAYMENT_CREATE_REQUEST = "DELIVERY_PAYMENT_CREATE_REQUEST";
export const DELIVERY_PAYMENT_CREATE_SUCCESS = "DELIVERY_PAYMENT_CREATE_SUCCESS";
export const DELIVERY_PAYMENT_CREATE_FAIL = "DELIVERY_PAYMENT_CREATE_FAIL";
export const DELIVERY_PAYMENT_UPDATE_REQUEST = "DELIVERY_PAYMENT_UPDATE_REQUEST";
export const DELIVERY_PAYMENT_UPDATE_SUCCESS = "DELIVERY_PAYMENT_UPDATE_SUCCESS";
export const DELIVERY_PAYMENT_UPDATE_FAIL = "DELIVERY_PAYMENT_UPDATE_FAIL";

/*=============================================================
                  Add Delivery Payment Action
===============================================================*/
export const AddDeliveryPaymentAction = (formData, navigate,) => async dispatch => {
  dispatch({
    type: DELIVERY_PAYMENT_CREATE_REQUEST
  });
  nodeCudApi.post(`/Payment_Settlement/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DELIVERY_PAYMENT_CREATE_SUCCESS
        });
        toast('Delivery Payment added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/Delivery-payment-settlements');
      } else {
        dispatch({
          type: DELIVERY_PAYMENT_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: DELIVERY_PAYMENT_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Update Delivery Payment Action
===============================================================*/
export const updateDeliveryPaymentAction = (formData, navigate,) => async dispatch => {
  dispatch({
    type: DELIVERY_PAYMENT_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Payment_Settlement/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DELIVERY_PAYMENT_UPDATE_SUCCESS
        });
        toast('Delivery Payment updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/Delivery-payment-settlements');
      } else {
        dispatch({
          type: DELIVERY_PAYMENT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: DELIVERY_PAYMENT_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
              Delete  Delivery Payment Action
 ===============================================================*/
export const deleteDeliveryPaymentAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Payment_Settlement/Delete`, formData);
    if (data) {
      toast(' Delivery payment deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationDeliveryPayment(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};