import axios from 'axios';
import config from './config';
import { toast } from 'react-toastify';

export const SUB_PACK_REQUEST = "SUB_PACK_REQUEST";
export const SUB_PACK_SUCCESS = "SUB_PACK_SUCCESS";
export const SUB_PACK_FAIL = "SUB_PACK_FAIL";

export const SUB_PACK_CREATE_REQUEST = "SUB_PACK_CREATE_REQUEST";
export const SUB_PACK_CREATE_SUCCESS = "SUB_PACK_CREATE_SUCCESS";
export const SUB_PACK_CREATE_FAIL = "SUB_PACK_CREATE_FAIL";

export const SUB_PACK_UPDATE_REQUEST = "SUB_PACK_UPDATE_REQUEST";
export const SUB_PACK_UPDATE_SUCCESS = "SUB_PACK_UPDATE_SUCCESS";
export const SUB_PACK_UPDATE_FAIL = "SUB_PACK_UPDATE_FAIL";

export const SUB_PACK_FILTER = "SUB_PACK_FILTER";

const headers = config.headersCommon;

//Get All Subscription Packages
export const getAllSubPackagesAction = formData => async dispatch => {
    try {
        dispatch({
            type: SUB_PACK_REQUEST
        });
        let { data } = await axios.post(
            `${config.url}/Subscription_Packages/Subscription_Packages_Get?PageNo=0&PageSize=${config.pageSize}&SortBy=SUBSCRIPTION_PACKAGE_ID&SortOrder=ASC`,
            formData,
            {
                headers: headers
            }
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: SUB_PACK_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: SUB_PACK_SUCCESS,
                    payload: data.Subscription_Packages
                });
            }
        }
    } catch (err) {
        dispatch({
            type: SUB_PACK_FAIL,
            payload: err
        });
    }
};

//create Subscription Packages
export const createSubPackageAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: SUB_PACK_CREATE_REQUEST
    });
    axios.post(`${config.url}/Subscription_Packages/Subscription_Packages_Create`, formData, {
        headers: headers
    })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: SUB_PACK_CREATE_SUCCESS
                });
                toast('Subscription Package added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/subscription-packages');
            } else {
                dispatch({
                    type: SUB_PACK_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SUB_PACK_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};

//update Subscription Packages
export const updateSubPackageAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: SUB_PACK_UPDATE_REQUEST
    });
    axios.put(`${config.url}/Subscription_Packages/Subscription_Packages_Update`, formData, {
        headers: headers
    })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: SUB_PACK_UPDATE_SUCCESS
                });
                toast('Subscription Package updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/subscription-packages');
            } else {
                dispatch({
                    type: SUB_PACK_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SUB_PACK_UPDATE_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};

//Subscription Package filter
export const subPackageFilter = (fType, value) => {
    return function (dispatch) {
        dispatch({
            type: SUB_PACK_FILTER,
            fType: fType,
            value: value,
        });
    }
}

//Subscription Package image upload
export const uploadSubsPackageImage = (
    formData,
    callBackUploadImgae,
) => async dispatch => {
    try {
        let { data } = await axios.post(
            `${config.url}/Categories/Image_Upload?filename=test&file_extension=jpg&filetype=image&category_id=C-0000369&Device=WEB`,
            formData,
            {
                headers: config.headersCommon,
            }
        );
        if (data) {
            callBackUploadImgae(data);
        }
    } catch (err) { }
};