import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createBussinessSubTypeAction,
} from '../../../store/actions/bussinesssubtypeAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EditorContainer from '../../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const BussinessOpt = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'RETAIL',
    label: 'RETAIL'
  },
  {
    value: 'FOOD',
    label: 'FOOD'
  },
  {
    value: 'GROCERY',
    label: 'GROCERY'
  },
  {
    value: 'PROPERTIES',
    label: 'PROPERTIES'
  },
  {
    value: 'SERVICES',
    label: 'SERVICES'
  },
  {
    value: 'FARMER',
    label: 'FARMER'
  },
  {
    value: 'MFH',
    label: 'MFH' 
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddBussinessSubtype = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState({ Business_Sub_Type: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmitted = useSelector(state => state.bussinessSubtype.bussinessSubtypeCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [rhDiscription, setRhDiscription] = useState('');
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <Formik
      initialValues={{
        Business_Type: '',
        Description: '',
        Created_By: loginEmail,
        Status: '',
        Business_Sub_Type: ''
      }}

      validationSchema={
        Yup.object().shape({
          Business_Type: Yup.string().required("Business Service Type is required."),
          Status: Yup.string().required('Status is required.'),
          Business_Sub_Type: Yup.string().notOneOf([errorMessage.Business_Sub_Type.key, null], errorMessage.Business_Sub_Type.message).required('Business Service Subtype is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        values.Description = rhDiscription;
        if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(createBussinessSubTypeAction(formValues, navigate, setErrorMessage, setErrors));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Business_Type && errors.Business_Type)}
                        fullWidth
                        helperText={touched.Business_Type && errors.Business_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Business Service Type"
                        name="Business_Type"
                        value={values.Business_Type}
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        variant="outlined"
                      >
                        {BussinessOpt.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Business_Sub_Type && errors.Business_Sub_Type)}
                        fullWidth
                        helperText={touched.Business_Sub_Type && errors.Business_Sub_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Business Service Subtype"
                        name="Business_Sub_Type"
                        InputLabelProps={{ shrink: true }}
                        value={values.Business_Sub_Type}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                      </span>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmitted ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmitted}
                  type="submit"
                  variant="contained"
                  style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddBussinessSubtype.propTypes = {
  className: PropTypes.string
};

export default AddBussinessSubtype;