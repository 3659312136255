import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/retail-order-management',
    createdAt: '',
    description:
      'Order history is a convenient way for a buyer to keep track of all current and past orders and their status in the order process. It allows the buyer to get up-to-date information on each order placed online, including shipping updates, delivery, and payment details.',
    media: '/static/Dashboard/orderhistory.png',
    title: 'Retail Order History',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/neworder-ratingsandreviews',
    createdAt: '',
    description:
      'Review module includes to write a review that lets users sign in, assign a rating, and write a review of a product. This module also lets users edit a rating or review that they previously submitted.',
    media: '/static/Dashboard/reviews_and_ratings.png',
    title: 'New Reviews & Ratings',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/neworder-transactionhistory',
    createdAt: '',
    description:
      'Transaction history differs from a bank statement in that it is a record of all transactions for that bank account for a set period that you have chosen.  Unlike a bank statement, a transaction history allows you to choose the period you want reflected.',
    media: '/static/Dashboard/transcation_history.png',
    title: 'New Transaction History',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/transactions',
    createdAt: '',
    description:
      'Transaction history differs from a bank statement in that it is a record of all transactions for that bank account for a set period that you have chosen.  Unlike a bank statement, a transaction history allows you to choose the period you want reflected.',
    media: '/static/Dashboard/transcation_history.png',
    title: 'Transactions',
    total: ''
  },

  {
    id: uuid(),
    hide: 0,
    link: '/app/partner-cupn-utlze',
    createdAt: '',
    description:
      'Transaction history differs from a bank statement in that it is a record of all transactions for that bank account for a set period that you have chosen.  Unlike a bank statement, a transaction history allows you to choose the period you want reflected.',
    media: '/static/Dashboard/payment_and_settlemet.png',
    title: 'Partner Coupon Utilization',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/neworder-paymentsandsettlements',
    createdAt: '',
    description:
      'Payment is the process of giving money that is owed. Settlement is an agreement to resolve a dispute. A common form of settlement is a set, monetary amount.',
    media: '/static/Dashboard/payment_and_settlemet.png',
    title: 'New Payments & Settlements',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/neworder-shipping',
    createdAt: '',
    description:
      'The Order Shipping module, located in the sales group, facilitates the process of packing and shipping items that have already been picked. Typically, orders are packed before they are shipped, but these steps can be performed simultaneously in Fishbowl if necessary. ',
    media: '/static/Dashboard/order_shipping.jpg',
    title: 'New Order Shipping',
    total: ''
  },

];