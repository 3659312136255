import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
  Link,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../store/validations/is-empty';
import { updatePhysicalPartner } from './../../store/actions/partnerAction';
import { useDispatch } from 'react-redux';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import SimpleDialog from '../../components/dialogs/dialog';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Truck as DeliveryIcon,
  Users as UsersIcon,
  Home as HomeIcon
} from 'react-feather';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
    width: '200px'
  }
}));

const headCells = [
  { id: 'Partner_Details_Id', numeric: false, disablePadding: true, label: 'Partner ID' },
  { id: 'Name', numeric: false, disablePadding: false, label: 'Partner Name' },
  { id: 'Authorised_Person_Name', numeric: false, disablePadding: false, label: 'Authorised Person Name' },
  { id: 'Type', numeric: false, disablePadding: false, label: 'Partner Type' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'Website_Url', numeric: false, disablePadding: false, label: 'Website URL' },
  { id: 'Is_Open', numeric: false, disablePadding: false, label: 'Is Open' },
];

const Results = ({
  className,
  allPartners,
  deletePartnerHandler,
  handleLimitChange,
  handlePageChange,
  pagination,
  setPagination,
  order,
  orderBy,
  createSortHandler,
  allPartnersNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedPartnerIds, setSelectedPartnerIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const handleClose = (value) => {
    setOpen(false);
  };

  const updatePhysicalPartnerYes = (value) => e => {
    const formvalues = {
      Partner_Details_Id: value.Partner_Details_Id,
      Name: value.Name,
      Is_Open: '0',
    };

    dispatch(updatePhysicalPartner(formvalues, navigate));
  };

  const updatePhysicalPartnerNo = (value) => e => {
    const formvalues = {
      Partner_Details_Id: value.Partner_Details_Id,
      Name: value.Name,
      Is_Open: '1',
    };
    dispatch(updatePhysicalPartner(formvalues, navigate));
  };

  const handleSelectAll = event => {
    let newSelectedPartnerIds;
    if (event.target.checked) {
      newSelectedPartnerIds = allPartners.map(partner => partner.Partner_Details_Id);
    } else {
      newSelectedPartnerIds = [];
    }
    setSelectedPartnerIds(newSelectedPartnerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedPartnerIds.indexOf(id);
    let newSelectedPartnerIds = [];
    if (selectedIndex === -1) {
      newSelectedPartnerIds = newSelectedPartnerIds.concat(selectedPartnerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedPartnerIds = newSelectedPartnerIds.concat(
        selectedPartnerIds.slice(1)
      );
    } else if (selectedIndex === selectedPartnerIds.length - 1) {
      newSelectedPartnerIds = newSelectedPartnerIds.concat(
        selectedPartnerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedPartnerIds = newSelectedPartnerIds.concat(
        selectedPartnerIds.slice(0, selectedIndex),
        selectedPartnerIds.slice(selectedIndex + 1)
      );
    }
    setSelectedPartnerIds(newSelectedPartnerIds);
  };

  const deleteSelectedPartnerHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const editPartnerHandler = partnerData => e => {
    navigate('/app/edit-partner', { state: partnerData });
  };

  const OpenPartneraddressHandler = PartnerData => e => {
    navigate('/app/partneraddress-management', { state: PartnerData });
  };

  const OpenPartnerEmployeeHandler = PartnerData => e => {
    navigate('/app/partner-employee-management', { state: PartnerData });
  };

  const OpenPartnerDeliveryAgentHandler = PartnerData => e => {
    navigate('/app/partner-deliveryagent-management', { state: PartnerData });
  };
  const OpenPartnerBankDetailsHandler = PartnerData => e => {
    navigate('/app/partnerbank-details', { state: PartnerData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedPartnerIds.filter(record => {
                      for (let i = 0; i < allPartnersNoFilter.length; i++) {
                        if (record == allPartnersNoFilter[i].Partner_Details_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allPartners.length}
                    color="primary"
                    indeterminate={
                      selectedPartnerIds.filter(record => {
                        for (let i = 0; i < allPartnersNoFilter.length; i++) {
                          if (record == allPartnersNoFilter[i].Partner_Details_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedPartnerIds.filter(record => {
                        for (let i = 0; i < allPartnersNoFilter.length; i++) {
                          if (record == allPartnersNoFilter[i].Partner_Details_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allPartners.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn} style={{ width: '200px' }}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Partner_Management_Main_Write?.Value === "Partner_Management_Main_Write" && RolePermissions?.Partner_Management_Main_Write?.Is_Write === 1) ?
                  <TableCell >Address</TableCell>
                  : null}
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Partner_Management_Main_Write?.Value === "Partner_Management_Main_Write" && RolePermissions?.Partner_Management_Main_Write?.Is_Write === 1) ?
                  <TableCell >Employees</TableCell>
                  : null}
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Partner_Management_Main_Write?.Value === "Partner_Management_Main_Write" && RolePermissions?.Partner_Management_Main_Write?.Is_Write === 1) ?
                  <TableCell >Delivery Agents</TableCell>
                  : null}
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Partner_Management_Main_Write?.Value === "Partner_Management_Main_Write" && RolePermissions?.Partner_Management_Main_Write?.Is_Write === 1) ?
                  <TableCell >Bank Details</TableCell>
                  : null}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions&nbsp;&nbsp;&nbsp;
                  {selectedPartnerIds.filter(record => {
                    for (let i = 0; i < allPartnersNoFilter.length; i++) {
                      if (record == allPartnersNoFilter[i].Partner_Details_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedPartnerIds.filter(record => {
                    for (let i = 0; i < allPartnersNoFilter.length; i++) {
                      if (record == allPartnersNoFilter[i].Partner_Details_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedPartnerHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allPartners) &&
                (allPartners).map(partner => (
                  <TableRow
                    hover
                    key={partner.Partner_Details_Id}
                    selected={
                      selectedPartnerIds.indexOf(partner.Partner_Details_Id) !== -1
                    }>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedPartnerIds.indexOf(partner.Partner_Details_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, partner.Partner_Details_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell >{partner.Partner_Details_Id}</TableCell>
                    <TableCell style={{ overflowX: "hidden" }}>{partner.Name}</TableCell>
                    <TableCell style={{ overflowX: "hidden" }}>{partner.Authorised_Person_Name}</TableCell>
                    <TableCell >{partner.Type}</TableCell>
                    <TableCell style={{ color: '#1665D8' }}>
                      {partner.Status}
                    </TableCell>
                    <TableCell style={{ color: '#1665D8', overflowX: "hidden" }}>
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href={partner.Website_Url}
                      >
                        {partner.Website_Url?.substring(0, 20)}...
                      </Link>
                    </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Partner_Management_Main_Write?.Value === "Partner_Management_Main_Write" && RolePermissions?.Partner_Management_Main_Write?.Is_Write === 1) ?
                      <TableCell>
                        <span>
                          <Button disabled={partner.Type === "Online" ? true : false} style={{ backgroundColor: '#27B6CC' }}>
                            {partner?.Type === "Physical" && partner?.Is_Open === 1 ?
                              <span style={{ marginTop: "3%", backgroundColor: '#27B6CC' }}
                                onClick={updatePhysicalPartnerYes(partner)}>YES</span> :
                              partner?.Type === "Physical" && partner?.Is_Open === 0 ?
                                <span style={{ marginTop: "3%", backgroundColor: '#27B6CC' }}
                                  onClick={updatePhysicalPartnerNo(partner)}>NO</span> :
                                <div style={{ marginTop: "3%", color: 'green' }} >Online</div>
                            }
                          </Button>
                        </span>
                      </TableCell>
                      : null}
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Partner_Management_Main_Write?.Value === "Partner_Management_Main_Write" && RolePermissions?.Partner_Management_Main_Write?.Is_Write === 1) ?
                      <TableCell >
                        <Tooltip title="Partner Address">
                          <span>
                            <Button disabled={partner.Type === "Online" ? true : false}>
                              < PersonPinCircleIcon
                                onClick={OpenPartneraddressHandler(partner)}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#9EA0A5', fontSize: 25, }} />
                            </Button>
                          </span>
                        </Tooltip>
                      </TableCell>
                      : null}
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Partner_Management_Main_Write?.Value === "Partner_Management_Main_Write" && RolePermissions?.Partner_Management_Main_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Tooltip title="Partner Employees">
                          <span>
                            <Button disabled={partner.Type === "Online" ? true : false}>
                              < UsersIcon
                                onClick={OpenPartnerEmployeeHandler(partner)}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#9EA0A5', fontSize: 25, }} />
                            </Button>
                          </span>
                        </Tooltip>
                      </TableCell>
                      : null}
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Partner_Management_Main_Write?.Value === "Partner_Management_Main_Write" && RolePermissions?.Partner_Management_Main_Write?.Is_Write === 1) ?
                      <TableCell >
                        <Tooltip title="Partner Delivery Agents">
                          <span>
                            <Button disabled={partner.Type === "Online" ? true : false}>
                              < DeliveryIcon
                                onClick={OpenPartnerDeliveryAgentHandler(partner)}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#9EA0A5', fontSize: 25, width: '100px' }} />
                            </Button>
                          </span>
                        </Tooltip>
                      </TableCell>
                      : null}
                    {/* Bank */}
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Partner_Bank_Details_Read?.Value === "Partner_Bank_Details_Read" && RolePermissions?.Partner_Bank_Details_Read?.Is_Read === 1) ?
                      <TableCell >
                        <Tooltip title="Partner Bank Details">
                          <span>
                            <Button disabled={partner.Type === "Online" ? true : false}>
                              <HomeIcon
                                onClick={OpenPartnerBankDetailsHandler(partner)}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#9EA0A5', fontSize: 25, width: '100px' }} />
                            </Button>
                          </span>
                        </Tooltip>
                      </TableCell>
                      : null}
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Partner_Management_Main_Write?.Value === "Partner_Management_Main_Write" && RolePermissions?.Partner_Management_Main_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Button
                          onClick={editPartnerHandler(partner)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                          <DeleteIcon
                            onClick={deletePartnerHandler(partner)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                      : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allPartners) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allPartners: PropTypes.array.isRequired
};

export default Results;