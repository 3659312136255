import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Box,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { ConnectedFocusError } from "focus-formik-error";
import { getAlldeliveryEmails, updateDeliveryWalletAction } from 'src/store/actions/deliverywalletAction';
import { CircularProgress } from '@mui/material';

const statusOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const EditDeliveryWallet = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let { state } = location;

    // componentDidMount
    useEffect(() => {
        const formData = {
            // "search_by_filter": "",
            // "search": "",
            // Records_Filter: 'ALL'
        };
        dispatch(getAlldeliveryEmails());
    }, []);

    const isSubmited = useSelector(state => state.deliverywallet.deliverywalletUpdate.isLoading);
    const delemails = useSelector(state => state.deliverywallet.deliveryEmails.deliveryemails);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const errorMessage = useSelector(state => state.deliverywallet.deliverywalletUpdate.error);

    return (
        <Formik
            initialValues={{
                Del_Wallet_Id: state.Wallet_Id ? state.Wallet_Id : "",
                Wallet_Owner: state.Wallet_Owner ? state.Wallet_Owner : "",
                Is_Eligible_For_Withdraw: state.Is_Eligible_For_Withdraw > 0 ? true : false,
                Status: state.Status ? state.Status : "",
                Modified_By: loginEmail
            }}

            validationSchema={
                Yup.object().shape({
                    Status: Yup.string().required('Status is required.'),
                })
            }
            onSubmit={(values) => {
                let formValues = JSON.parse(JSON.stringify(values));
                if (values.Is_Eligible_For_Withdraw) {
                    formValues.Is_Eligible_For_Withdraw = 1;
                } else {
                    formValues.Is_Eligible_For_Withdraw = 0;
                }
                dispatch(updateDeliveryWalletAction(formValues, navigate));
            }
            }
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        {/*Wallet_Owner */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Wallet_Owner && errors.Wallet_Owner)}
                                                fullWidth
                                                helperText={touched.Wallet_Owner && errors.Wallet_Owner}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Delivery User Email ID"
                                                name="Wallet_Owner"
                                                value={values.Wallet_Owner}
                                                variant="outlined"
                                                // select
                                                // InputLabelProps={{ shrink: true }}
                                                // SelectProps={{ native: true }}
                                                disabled
                                            >
                                                {/* <option key="" value="">--Please Select--</option>
                                                {delemails?.sort((a, b) => a.Del_User_Email_Id?.localeCompare(b.Del_User_Email_Id))?.map(option => (
                                                    <option key={option.Del_User_Detail_Id} value={option.Del_User_Email_Id}>
                                                        {option.Del_User_Email_Id}
                                                    </option>
                                                ))} */}
                                            </TextField>
                                        </Grid>

                                        {/* Status */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {/* Is Eligible For Withdraw */}
                                        <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        // disabled
                                                        checked={values.Is_Eligible_For_Withdraw}
                                                        onChange={handleChange}
                                                        name="Is_Eligible_For_Withdraw"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Eligible For Withdrawl"
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};


EditDeliveryWallet.propTypes = {
    className: PropTypes.string
};

export default EditDeliveryWallet;