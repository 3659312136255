import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableSortLabel,
    TableRow,
    makeStyles,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'src/components/pagination/pagination';
import CommentIcon from '@material-ui/icons/Comment';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    images: {
        width: 100,
        height: 50
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    }
}));

const headCells = [
    { id: 'B2B_Partner_Bidding_Quote_Id', numeric: false, disablePadding: true, label: 'B2B User Product Quote ID' },
    { id: 'B2B_User_Products_Bidding_Id', numeric: false, disablePadding: false, label: 'B2B User Products Bidding ID' },
    { id: 'Partner_Name', numeric: false, disablePadding: false, label: 'Partner Name' },
    { id: 'Quotation_Amount', numeric: false, disablePadding: false, label: 'Quotation Amount' },
    { id: 'Bidding_Status', numeric: false, disablePadding: false, label: 'Bidding Status' },
];

const Results = ({
    className,
    allB2BUserProdBidQuotes,
    order,
    orderBy,
    createSortHandler,
    handleLimitChange,
    handlePageChange,
    allB2BUserProdBidQuotesNoFilter,
    pagination,
    setPagination,
    RolePermissions,
    roleUserType,
    state,
    ...rest
}) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const viewB2BUserProdBidQuotesHandler = b2buserprodbidquotesData => e => {
        navigate("/app/view-b2b-user-product-bidding-quote", { state: { state: state, data: b2buserprodbidquotesData } });
    };
    const editB2BUserProdBidQuotesHandler = b2buserprodbidquotesData => e => {
        navigate('/app/edit-b2b-user-product-bidding-quote', { state: { state: state, data: b2buserprodbidquotesData } });
    };

    const OpenB2BUserProdBidCommentsHandler = b2buserprodbidquotesData => e => {
        navigate('/app/b2b-user-product-bidding-comments', { state: b2buserprodbidquotesData });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1250}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                    (RolePermissions?.B2B_User_Products_Bidding_Quotes_Write?.Value === "B2B_User_Products_Bidding_Quotes_Write" && RolePermissions?.B2B_User_Products_Bidding_Quotes_Write?.Is_Write === 1) ?
                                    <TableCell >Bidding Comments</TableCell>
                                    : null}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-2rem' }} >Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allB2BUserProdBidQuotes) &&
                                (allB2BUserProdBidQuotes).map(b2buserbidprodquote => (
                                    <TableRow
                                        hover
                                        key={b2buserbidprodquote.B2B_Partner_Bidding_Quote_Id}
                                    >
                                        <TableCell>{b2buserbidprodquote.B2B_Partner_Bidding_Quote_Id}</TableCell>
                                        <TableCell>{b2buserbidprodquote.B2B_User_Products_Bidding_Id}</TableCell>
                                        <TableCell>{b2buserbidprodquote.Partner_Name}</TableCell>
                                        <TableCell>{b2buserbidprodquote.Quotation_Amount}</TableCell>
                                        <TableCell>{b2buserbidprodquote.Bidding_Status}</TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.B2B_User_Products_Bidding_Quotes_Write?.Value === "B2B_User_Products_Bidding_Quotes_Write" && RolePermissions?.B2B_User_Products_Bidding_Quotes_Write?.Is_Write === 1) ?
                                            <TableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Tooltip title="Bidding Comments">
                                                    <Button>
                                                        <CommentIcon
                                                            onClick={OpenB2BUserProdBidCommentsHandler(b2buserbidprodquote)}
                                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#9EA0A5', fontSize: 20, }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            : null}
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.B2B_User_Products_Bidding_Quotes_Write?.Value === "B2B_User_Products_Bidding_Quotes_Write" && RolePermissions?.B2B_User_Products_Bidding_Quotes_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="B2B User Bidding Quotes">
                                                    <Button
                                                        onClick={viewB2BUserProdBidQuotesHandler(b2buserbidprodquote)}
                                                        color="primary" >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                                <Button
                                                    onClick={editB2BUserProdBidQuotesHandler(b2buserbidprodquote)}
                                                >
                                                    <CreateIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                            </TableCell>
                                            : null}
                                        {RolePermissions?.B2B_User_Products_Bidding_Quotes_Read?.Value === "B2B_User_Products_Bidding_Quotes_Read" && RolePermissions?.B2B_User_Products_Bidding_Quotes_Read?.Is_Read === 1 &&
                                            <TableCell>
                                                <Tooltip title="B2B User Bidding Quotes">
                                                    <Button
                                                        onClick={viewB2BUserProdBidQuotesHandler(b2buserbidprodquote)}
                                                        color="primary" >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allB2BUserProdBidQuotes) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <Pagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
};

export default Results;