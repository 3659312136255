import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getAllMyCompanyProfileAction, updateMyCompanyProfileAction, uploadMyProfileImage
} from './../../store/actions/mycompanyprofileAction';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import csc from 'country-state-city';
import { CircularProgress } from '@mui/material';
import isEmpty from 'src/store/validations/is-empty';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';

const countryOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'IN',
    label: 'India'
  },
  {
    value: 'US',
    label: 'United States'
  },
  {
    value: 'GB',
    label: 'United Kingdom'
  },
  {
    value: 'SG',
    label: 'Singapore'
  },
  {
    value: 'PH',
    label: 'Phillipines'
  },
  {
    value: 'MY',
    label: 'Malaysia'
  },
  {
    value: 'AU',
    label: 'Australia'
  },
  {
    value: 'ID',
    label: 'Indonesia'
  },
  {
    value: 'VN',
    label: 'Vietnam'
  },
  {
    value: 'NP',
    label: 'Nepal'
  },
  {
    value: 'SA',
    label: 'Saudi Arabia'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const ProfileDetails = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  //permissions
  const RolePermissions = useSelector(state => state.rolepermission.Roles);
  const roleUserType = useSelector(state => state.auth.user);
  const Login_Details = useSelector(state => state.auth.user);

  useEffect(() => {
    dispatch(getRolePermissions(Login_Details));
    dispatch(getAllMyCompanyProfileAction());
  }, []);

  const isSubmited = useSelector(state => state.myprofiles.myprofileUpdate.isLoading);
  const errorMessage = useSelector(state => state.myprofiles.myprofileUpdate.error);
  const allprofiles = useSelector(state => state.myprofiles.myprofiles.all);
  const createdBy = useSelector(state => state.auth.user.Email_Id);
  const profiles = useSelector(state => state.myprofiles.myprofiles.isLoading);

  // img upload
  const [image, setImage] = useState({ preview: allprofiles?.Logo_Path, raw: allprofiles?.Logo_Path });
  const [imgUrl, setImgUrl] = useState(allprofiles?.Logo_Path);
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });

    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadMyProfileImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };
  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  const [mobile, setMobile] = useState(allprofiles?.LandPhone);
  useEffect(() => {
    if (isEmpty(imgUrl && mobile && image)) {
      setMobile(allprofiles?.LandPhone)
      setImgUrl(allprofiles?.Logo_Path)
      setImage({ preview: allprofiles?.Logo_Path, raw: allprofiles?.Logo_Path })
    }
  }, [allprofiles]);

  return (
    <>
      {!profiles &&
        <Formik
          initialValues={{
            City: allprofiles.City,
            Company_Details_Id: allprofiles.Company_Profile_Id ? allprofiles.Company_Profile_Id : "",
            Company_Name: allprofiles.Company_Name ? allprofiles.Company_Name : "",
            Country: allprofiles.Country ? allprofiles.Country : "",
            Email_Id: allprofiles.Email_Id ? allprofiles.Email_Id : "",
            Facebook: allprofiles.Facebook ? allprofiles.Facebook : "",
            GST: allprofiles.GST ? allprofiles.GST : "",
            Google_Map_Location_URL: allprofiles.Google_Map_Location_URL ? allprofiles.Google_Map_Location_URL : "",
            Google_URL: allprofiles.Google_URL ? allprofiles.Google_URL : "",
            Instagram: allprofiles.Instagram ? allprofiles.Instagram : "",
            LandPhone: allprofiles.LandPhone ? allprofiles.LandPhone : "",
            Landmark: allprofiles.Landmark ? allprofiles.Landmark : "",
            LinkedIn: allprofiles.LinkedIn ? allprofiles.LinkedIn : "",
            Location: allprofiles.Location ? allprofiles.Location : "",
            Logo_Path: imgUrl,
            PAN: allprofiles.PAN ? allprofiles.PAN : "",
            Pincode: allprofiles.Pincode ? allprofiles.Pincode : "",
            State: allprofiles.State ? allprofiles.State : "",
            Twitter: allprofiles.Twitter ? allprofiles.Twitter : "",
            Modified_By: createdBy,

          }}
          validationSchema={
            Yup.object().shape({
              Company_Name: Yup.string().required('Company Name is required.'),
              LandPhone: Yup.string()
                .min(12, "Please provide a valid Landline Number.").required('Landline Number is required.'),
              PAN: Yup.string().when('Type', {
                is: "Online",
                then: Yup.string().length(10, 'PAN must be of 10 characters.')
                  .matches("[A-Z]{5}[0-9]{4}[A-Z]{1}", 'Please provide valid PAN Number without any spaces and special characters.')
                  .required('PAN Number is required.'),
              }),
              GST: Yup.string().when('Type', {
                is: "Online",
                then: Yup.string().length(15, 'GSTIN must be of 15 characters.')
                  .matches("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z{1}$]",
                    'Please provide valid GST Number without any spaces and special characters.'
                  ).required('GSTIN is required.'),
              }),
              Google_Map_Location_URL: Yup.string().matches(/^https?\:\/\/(www\.)?google\.[a-z]+\/maps\b/,
                'Please provide valid URL.'
              ).required('Google Map Location URL is required.'),
              Google_URL: Yup.string().matches(
                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
                'Please provide valid URL.'
              ).required('Google URL is required.'),
              Pincode: Yup.string().matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/,
                'Please provide valid Pin Code.')
                .required('Pin Code is required.'),
              Landmark: Yup.string().required('Land Mark is required.'),
              City: Yup.string().required('City is required.'),
              State: Yup.string().required('State is required.'),
              Country: Yup.string().required('Country is required.'),
              Email_Id: Yup.string().email('Please provide valid  Email ID.').required('Email ID is required.'),
              Location: Yup.string().required('Location is required.'),
              Twitter: Yup.string().matches(
                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
                'Please provide valid URL.'
              ).required('Twitter is required.'),
              Instagram: Yup.string().matches(
                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
                'Please provide valid URL.'
              ).required('Instagram is required.'),
              LinkedIn: Yup.string().matches(
                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
                'Please provide valid URL.'
              ).required('LinkedIn is required.'),
              Facebook: Yup.string().matches(
                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
                'Please provide valid URL.'
              ).required('Facebook is required.'),
            })
          }
          onSubmit={(values, { setSubmitting, setErrors }) => {
            let submitTest = true;
            setSubmitting(false);
            const errors = {};
            values.Logo_Path = imgUrl;
            if (!values.Logo_Path) {
              handleRemoveImage()
              errors.Logo_Path = "Logo Path or Logo Image is required.";
              submitTest = false;
            }
            setErrors(errors);
            if (submitTest) {
              let formValues = JSON.parse(JSON.stringify(values));
              dispatch(updateMyCompanyProfileAction(formValues, navigate));
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <ConnectedFocusError />
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={8} xs={12}>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Company_Name && errors.Company_Name)}
                            fullWidth
                            helperText={touched.Company_Name && errors.Company_Name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Company Name"
                            name="Company_Name"
                            value={values.Company_Name}
                            variant="outlined"
                          >
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          {/* patner name */}
                          <TextField
                            error={Boolean(touched.Email_Id && errors.Email_Id)}
                            fullWidth
                            helperText={touched.Email_Id && errors.Email_Id}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Email ID"
                            name="Email_Id"
                            value={values.Email_Id}
                            variant="outlined"
                          >
                          </TextField>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Country && errors.Country)}
                            fullWidth
                            helperText={touched.Country && errors.Country}
                            placeholder="Country"
                            label="Country"
                            name="Country"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            select
                            SelectProps={{ native: true }}
                            value={values.Country}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                          >
                            {countryOption.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.State && errors.State)}
                            fullWidth
                            helperText={touched.State && errors.State}
                            label="State"
                            name="State"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.State}
                            select
                            SelectProps={{ native: true }}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                          >
                            <option key="" value="">--Please Select--</option>
                            {csc.getStatesOfCountry(values.Country).map(option => (
                              <option key={option.isoCode} value={option.isoCode}>
                                {option.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.City && errors.City)}
                            fullWidth
                            helperText={touched.City && errors.City}
                            label="City"
                            name="City"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.City}
                            select
                            SelectProps={{ native: true }}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                          >
                            <option key="" value="">--Please Select--</option>
                            {csc.getCitiesOfState(values.Country, values.State).map(option => (
                              <option key={option.name} value={option.name}>
                                {option.name}
                              </option>
                            ))}

                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Pincode && errors.Pincode)}
                            fullWidth
                            helperText={touched.Pincode && errors.Pincode}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Pin Code"
                            name="Pincode"
                            value={values.Pincode}
                            variant="outlined"
                          >
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Google_Map_Location_URL && errors.Google_Map_Location_URL)}
                            fullWidth
                            helperText={touched.Google_Map_Location_URL && errors.Google_Map_Location_URL}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Google Map Location URL"
                            name="Google_Map_Location_URL"
                            value={values.Google_Map_Location_URL}
                            variant="outlined"
                          >
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Google_URL && errors.Google_URL)}
                            fullWidth
                            helperText={touched.Google_URL && errors.Google_URL}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Google URL"
                            name="Google_URL"
                            value={values.Google_URL}
                            variant="outlined"
                          >
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Location && errors.Location)}
                            fullWidth
                            helperText={touched.Location && errors.Location}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Location"
                            name="Location"
                            value={values.Location}
                            variant="outlined"
                          >
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Landmark && errors.Landmark)}
                            fullWidth
                            helperText={touched.Landmark && errors.Landmark}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Landmark"
                            name="Landmark"
                            value={values.Landmark}
                            variant="outlined"
                          >
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <PhoneInput
                            containerClass={touched.LandPhone && errors.LandPhone ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                            inputClass={"form-control MuiOutlinedInput-input"}
                            error={true}
                            country={"in"}
                            fullWidth
                            inputStyle={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "5px"
                            }}
                            inputProps={{
                              name: "LandPhone",
                            }}
                            specialLabel="Land Phone"
                            name="LandPhone"
                            onBlur={handleBlur}
                            placeholder="+91 XXXXX XXXXX"
                            onChange={(value, country, e, formattedValue) => {
                              setFieldValue("LandPhone", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                              setMobile(formattedValue)
                            }}
                            value={mobile}
                          />
                          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.LandPhone && errors.LandPhone}</p>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Logo_Path && errors.Logo_Path)}
                            fullWidth
                            helperText={touched.Logo_Path && errors.Logo_Path}
                            onBlur={handleBlur}
                            type="url"
                            onChange={(data) => {
                              setImgUrl(data.target.value);
                            }}
                            label="Logo Path"
                            name="Logo_Path"
                            value={imgUrl}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.GST && errors.GST)}
                            fullWidth
                            helperText={touched.GST && errors.GST}
                            label="GSTIN"
                            name="GST"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.GST}
                            variant="outlined"
                            inputProps={{ maxLength: 15 }}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.PAN && errors.PAN)}
                            fullWidth
                            helperText={touched.PAN && errors.PAN}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="PAN Number"
                            name="PAN"
                            value={values.PAN}
                            variant="outlined"
                            inputProps={{ maxLength: 10 }}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.LinkedIn && errors.LinkedIn)}
                            fullWidth
                            helperText={touched.LinkedIn && errors.LinkedIn}
                            label="LinkedIn"
                            name="LinkedIn"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.LinkedIn}
                            variant="outlined"
                            inputProps={{ maxLength: 50 }}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Twitter && errors.Twitter)}
                            fullWidth
                            helperText={touched.Twitter && errors.Twitter}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Twitter"
                            name="Twitter"
                            value={values.Twitter}
                            variant="outlined"
                            inputProps={{ maxLength: 30 }}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Facebook && errors.Facebook)}
                            fullWidth
                            helperText={touched.Facebook && errors.Facebook}
                            label="Facebook"
                            name="Facebook"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.Facebook}
                            variant="outlined"
                            inputProps={{ maxLength: 45 }}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Instagram && errors.Instagram)}
                            fullWidth
                            helperText={touched.Instagram && errors.Instagram}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Instagram"
                            name="Instagram"
                            value={values.Instagram}
                            variant="outlined"
                            inputProps={{ maxLength: 45 }}
                          />
                        </Grid>
                      </Grid>

                    </Grid>
                    {/* upload photo */}
                    <Grid item md={4} xs={12}>
                      <Card>
                        &nbsp;Logo Image
                        <CardContent>
                          <Box display="flex" justifyContent="center">
                            {image.preview ? (
                              <Avatar
                                className={classes.images}
                                alt="logo Upload"
                                src={image.preview}
                                variant="square"
                              />
                            ) : (
                              <>
                                <Avatar
                                  className={classes.images}
                                  alt="logo Upload"
                                  src="/static/images/image-placeholder.png"
                                  variant="square"
                                />
                              </>
                            )}
                          </Box>

                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                          >
                            {image.preview ? <Button
                              style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                              onClick={handleRemoveImage}
                            >
                              Remove Picture
                            </Button> : <input
                              style={{ display: 'block', width: '80%' }}
                              id="upload-photo"
                              name="image"
                              type="file"
                              accept="image/*"
                              onChange={handleImage}
                            />}
                            <br />
                          </Box>

                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>

                <Box display="flex" pl={2} >
                  <span style={{ color: "red" }}>{errorMessage}</span>
                </Box>

                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Company_Profile_Management_Write?.Value === "Company_Profile_Management_Write" && RolePermissions?.Company_Profile_Management_Write?.Is_Write ===  1) ?
                  <Box display="flex" p={2}>
                    {isSubmited ?
                      <CircularProgress />
                      :
                      <Button
                        disabled={isSubmited || uploadPic}
                        type="submit"
                        style={{ backgroundColor: '#27B6CC' }}
                        variant="contained"
                      >
                        <span style={{ color: 'white' }}>Save details</span>
                      </Button>
                    }
                  </Box>
                  : null}
              </Card>
            </form>
          )}
        </Formik>
      }
    </>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;