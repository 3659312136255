import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createWalletAction,
  getAllWalletAction,
  getAllEmails
} from './../../store/actions/walletAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const AddWallet = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // componentDidMount
  useEffect(() => {
    const formData = {
      search: "",
      search_by_filter: "All",
      Records_Filter: 'ALL'
    };
    dispatch(getAllWalletAction(formData));
    dispatch(getAllEmails());
  }, []);

  const allWallets = useSelector(state => state.wallet.wallets.all);
  const isSubmited = useSelector(state => state.wallet.walletCreate.isLoading);
  const emails = useSelector(state => state.wallet.userEmails.emails);
  const errorMessage = useSelector(state => state.wallet.walletCreate.error);

  return (
    <Formik
      initialValues={{
        User_Id: '',
        Is_Eligible_For_Withdraw: false,
        Status: '',
      }}

      validationSchema={
        Yup.object().shape({
          User_Id: Yup.string().required('Email ID is required.'),
          Status: Yup.string().required('Status is required.'),
        })
      }
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);

        let formValues = JSON.parse(JSON.stringify(values));
        if (values.Is_Eligible_For_Withdraw) {
          formValues.Is_Eligible_For_Withdraw = 1;
        } else {
          formValues.Is_Eligible_For_Withdraw = 0;
        }
        dispatch(createWalletAction(formValues, navigate));
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    {/* User Id */}
                    <Grid item md={6} xs={12}>
                      {/* <TextField
                        error={Boolean(touched.User_Id && errors.User_Id)}
                        fullWidth
                        helperText={touched.User_Id && errors.User_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Email ID"
                        name="User_Id"
                        value={values.User_Id}
                        variant="outlined"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                      >
                        <option key="" value="">--Please Select--</option>

                        {emails?.filter(option => {

                          let test = true;
                          for (let i = 0; i < allWallets?.length; i++) {

                            try {
                              if (option.Email_Id === allWallets[i]?.Email_Id) {
                                test = false;
                                break;
                              }
                            } catch (error) {
                            }
                          }
                          return test
                        })?.sort((a, b) => a.Email_Id?.localeCompare(b.Email_Id))?.map(option => (
                          <option key={option.User_Details_Id} value={option.Email_Id}>
                            {option.Email_Id}</option>
                        ))}
                      </TextField> */}
                      <Autocomplete
                        freeSolo
                        options={emails}
                        groupBy={(option) => option.firstLetter}
                        id="combo-box-demo"
                        getOptionLabel={(option) => option.Email_Id}
                        renderInput={(params) => <TextField {...params}
                          error={Boolean(touched.User_Id && errors.User_Id)}
                          helperText={touched.User_Id && errors.User_Id}
                          onBlur={handleBlur}
                          label="User Email ID"
                          name="Email_Id"
                          variant="outlined"
                          value={values.Email_Id}
                          onChange={() => {
                            dispatch(getAllEmails(params.inputProps.value))
                          }}
                          InputProps={{
                            ...params.InputProps,
                            autoComplete: 'off', // disable autocomplete and autofill,
                            startAdornment: (
                              <React.Fragment>
                                <SearchIcon color="inherit" size={20}></SearchIcon>
                                {params.InputProps.startAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />}
                        onChange={(e, value) => {
                          setFieldValue("User_Id", value?.Email_Id ? value.Email_Id : "");
                        }}
                      />
                    </Grid>
                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Is Eligible For Withdraw */}
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            disabled
                            checked={values.Is_Eligible_For_Withdraw}
                            onChange={handleChange}
                            name="Is_Eligible_For_Withdraw"
                            color="primary"
                          />
                        }
                        label="Is Eligible For Withdrawl"
                      />
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>
            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>

  );
};

AddWallet.propTypes = {
  className: PropTypes.string
};


export default AddWallet;