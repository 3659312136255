import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from 'src/store/validations/is-empty';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Partner_Product_Id', numeric: false, headerTitleAlign: 'center', disablePadding: true, label: 'Partner  Product ID' },
  { id: 'Partner_Name', numeric: false, disablePadding: false, label: 'Partner Name' },
  { id: 'Partner_Price', numeric: true, disablePadding: false, label: 'Partner Price' },
  { id: 'Discount', numeric: true, disablePadding: false, label: 'Discount Amount' },
  { id: 'Partner_Type', numeric: false, disablePadding: false, label: 'Partner Type' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allProdPartners,
  handlePageChange,
  handleLimitChange,
  allProdPartnersNoFilter,
  order,
  orderBy,
  pagination,
  setPagination,
  createSortHandler,
  state,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedProdPartnerIds, setSelectedProdPartnerIds] = useState([]);
  const navigate = useNavigate();

  const editPartnerHandler = partnerData => e => {
    navigate('/app/edit-pending-productpartner', { state: { state: state, data: partnerData } });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}>
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions &nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allProdPartners) &&
                (allProdPartners).map(partner => (
                  <TableRow
                    hover
                    key={partner.Partner_Product_Id}
                    selected={
                      selectedProdPartnerIds.indexOf(partner.Partner_Product_Id) !== -1
                    }
                  >
                    <TableCell  >{partner.Partner_Product_Id}</TableCell>
                    <TableCell >{partner.Partner_Name}</TableCell>
                    <TableCell >{partner.Price}</TableCell>
                    <TableCell >{partner.Discount_Type === "A" ? "₹" + partner.Discount : partner.Discount + "%"}</TableCell>
                    <TableCell >{partner.Partner_Type}</TableCell>
                    <TableCell style={{ color: '#1665D8' }}>
                      {partner.Status}
                    </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Pending_Product_Partner_Write?.Value === "Pending_Product_Partner_Write" && RolePermissions?.Pending_Product_Partner_Write?.Is_Write === 1) ?
                      <TableCell style={{ textAlign: "center", }}>
                        <Button
                          onClick={editPartnerHandler(partner)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allProdPartners) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allProdPartners: PropTypes.array.isRequired
};

export default Results;