import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ConnectedFocusError } from "focus-formik-error";
import { Formik, Form, ErrorMessage } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import {
  addBrandReviewAction,
} from './../../store/actions/branduserreviewAction';
import { useDispatch, useSelector } from 'react-redux';
import Rating from '@material-ui/lab/Rating';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { getAllUserEmails } from 'src/store/actions/userreviewAction';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";

const Status = [
  {
    value: '',
    label: '--Please select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  review: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddBrandReview = ({ className, state, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // componentDidMount
  useEffect(() => {
    dispatch(getAllUserEmails());
  }, []);

  const errorMessage = useSelector(state => state.branduserreview.userCreate.error);
  const isSubmited = useSelector(state => state.branduserreview.userCreate.isLoading);
  const useremails = useSelector(state => state.userreview.usersget.all);

  // Richtext editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [rhDiscription, setRhDiscription] = useState('');
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(2);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const values = {
    Brand_Id: state.Brand_Id,
    Brand_Name: "",
    User_Email_Id: "",
    Comments: "",
    Rating: 0,
    Status: ""
  }

  const Form_Validation = Yup.object().shape({
    Brand_Name: Yup.string().min(2, "Name should have atleast 2 characters.").required("Reviewer Name is required."),
    Status: Yup.string().required('Status is required.'),
    User_Email_Id: Yup.string().required('Reviewer Email ID is required.'),
    Rating: Yup.number().required('Please provide Rating.').nullable(),
  })

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(false);
    let submitTest = true;
    const errors = {};
    values.Comments = rhDiscription;

    if (!values.Comments || values.Comments.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
      submitTest = false;
      errors.Comments = "Brand Review is required.";
    }
    else if (values.Comments.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
      submitTest = false;
      errors.Comments = "Please provide at least " + rhDiscriptionMin + " characters.";
    }
    setErrors(errors)
    if (submitTest) {
      let formData = JSON.parse(JSON.stringify(values));
      dispatch(addBrandReviewAction(formData, state, navigate));
    }
  }

  return (
    <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.Brand_Name && errors.Brand_Name)}
                        label="Reviewer Name"
                        name="Brand_Name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.Brand_Name}
                        variant="outlined"
                        helperText={touched.Brand_Name && errors.Brand_Name}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        freeSolo
                        options={useremails}
                        groupBy={(option) => option.firstLetter}
                        id="combo-box-demo"
                        getOptionLabel={(option) => option.Email_Id}
                        renderInput={(params) => <TextField {...params}
                          error={Boolean(touched.User_Email_Id && errors.User_Email_Id)}
                          helperText={touched.User_Email_Id && errors.User_Email_Id}
                          onBlur={handleBlur}
                          label="Reviewer Email ID"
                          name="User_Email_Id"
                          variant="outlined"
                          value={values.User_Email_Id}
                          onChange={() => {
                            dispatch(getAllUserEmails(params.inputProps.value))
                          }}
                          InputProps={{
                            ...params.InputProps,
                            autoComplete: 'off', // disable autocomplete and autofill,
                            startAdornment: (
                              <React.Fragment>
                                <SearchIcon color="inherit" size={20}></SearchIcon>
                                {params.InputProps.startAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />}
                        onChange={(e, value) => {
                          setFieldValue("User_Email_Id", value?.Email_Id ? value.Email_Id : "");
                        }}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Box className={classes.review}>
                        Brand Review
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Comments && errors.Comments}
                      </span>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        placeholder="Status"
                        name="Status"
                        onChange={handleChange}
                        label="Status"
                        select
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        helperText={<ErrorMessage name="Status" />}
                      >
                        {Status.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={2} xs={12}>
                      <Box>Rating</Box>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <Rating
                        name="Rating"
                        onChange={(event, newValue) => {
                          values.Rating = newValue;
                          setFieldValue(newValue);
                        }}
                        value={values.Rating}
                        size="medium"
                      />
                      <Grid item xs={10} />
                      <span style={{ color: "red" }}>
                        {<ErrorMessage name="Rating" />}
                      </span>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default AddBrandReview;