import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
  Avatar,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  images: {
    width: 70,
    height: 70,
    cursor: 'pointer'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));

const headCells = [
  { id: 'User_Details_Id', numeric: false, disablePadding: false, label: 'User ID' },
  { id: 'Full_Name', numeric: false, disablePadding: false, label: 'User Name' },
  { id: 'Email_Id', numeric: false, disablePadding: false, label: 'Email Address' },
  { id: 'Mobile', numeric: true, disablePadding: false, label: 'Mobile Number' },
  { id: 'Created_Date', numeric: false, disablePadding: false, label: 'Created Date' },
  { id: 'Profile_Pic', numeric: false, disablePadding: false, label: 'Profile Picture' },
];

const Results = ({
  className,
  allUsers,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  pagination,
  setPagination,
  allUsersNoFilter,
  createSortHandler,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const editUserHandler = userData => e => {
    navigate('/app/edit-user-profile', { state: userData });
  };
  const viewUserHandler = userData => e => {
    navigate('/app/view-user-profile', { state: userData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.id !== "Profile_Pic" ? (
                      <TableSortLabel style={{ fontSize: "13px", fontWeight: "bold" }}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>) : (<span style={{ fontSize: "13px", fontWeight: "bold" }} > {headCell.label}</span>)
                    }
                  </TableCell>
                ))}

                <TableCell
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  className={classes.headerColumn} > Actions&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allUsers) &&
                (allUsers).map(user => (
                  <TableRow
                    hover
                    key={user.User_Details_Id}
                    selected={
                      selectedUserIds.indexOf(user.User_Details_Id) !== -1
                    }
                  >
                    <TableCell>{user.User_Details_Id}</TableCell>
                    <TableCell>{user.Full_Name}</TableCell>
                    <TableCell style={{ color: '#1665D8' }}>
                      {user.Email_Id?.substring(0, 12)}...
                    </TableCell>
                    <TableCell>{user.Mobile}</TableCell>
                    <TableCell>{user.Created_Date}</TableCell>
                    <TableCell>
                      <Avatar
                        className={classes.images}
                        alt="user"
                        src={user.Profile_Pic_Path}
                        variant="circular"
                      />
                    </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.User_Profile_Management_Write?.Value === "User_Profile_Management_Write" && RolePermissions?.User_Profile_Management_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Button onClick={editUserHandler(user)}>
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Tooltip title="User Details">
                          <Button
                            onClick={viewUserHandler(user)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                      : null}
                    {RolePermissions?.User_Profile_Management_Read?.Value === "User_Profile_Management_Read" && RolePermissions?.User_Profile_Management_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="User Details">
                          <Button
                            onClick={viewUserHandler(user)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allUsers) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allUsers: PropTypes.array.isRequired
};

export default Results;