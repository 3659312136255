import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    TextField,
    InputAdornment,
    SvgIcon,
    makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    formFilterControl: {
        margin: theme.spacing(1),
        minWidth: 200
    }
}));

const Toolbar = ({ className, state, filters, setfilters, ...rest }) => {
    const classes = useStyles();

    const handleChange = event => {
        setfilters({
            Search: filters?.Search,
            Search_By_Filter: event.target.value,
            Email: state?.Email
        })
    };

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                alignItems="center"
                mb={2}
            >
                <Box display="flex" flexWrap="wrap" mb={2}>
                    <FormControl variant="outlined" className={classes.formFilterControl}>
                        <Select
                            value={filters.Search_By_Filter}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="Corp_Detail_Id">Corp detail id</MenuItem>
                            <MenuItem value="id">Employee id</MenuItem>
                            <MenuItem value="Organization_Name">Organization name</MenuItem>
                            <MenuItem value="Full_Name">Employee name</MenuItem>
                            <MenuItem value="email">Employee email</MenuItem>
                            <MenuItem value="Created_By">Created by</MenuItem>
                            <MenuItem value="Created_Date">Created date</MenuItem>
                            <MenuItem value="Status">Status</MenuItem>
                        </Select>
                    </FormControl>
                    <Box width={500}>
                        <TextField
                            onChange={(event) => {
                                setfilters({
                                    Search: event.target.value,
                                    Search_By_Filter: filters.Search_By_Filter,
                                    Email: state?.Email
                                })
                            }}
                            className={classes.formControl}
                            value={filters?.Search}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon fontSize="small" color="action">
                                            <SearchIcon />
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}
                            placeholder="Search Corporate Users"
                            variant="outlined"
                        />
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;