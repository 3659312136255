import config from './config';
import { toast } from 'react-toastify';
import { paginationTickets } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const TICKET_CREATE_REQUEST = "TICKET_CREATE_REQUEST";
export const TICKET_CREATE_SUCCESS = "TICKET_CREATE_SUCCESS";
export const TICKET_CREATE_FAIL = "TICKET_CREATE_FAIL";
export const TICKET_UPDATE_REQUEST = "TICKET_UPDATE_REQUEST";
export const TICKET_UPDATE_SUCCESS = "TICKET_UPDATE_SUCCESS";
export const TICKET_UPDATE_FAIL = "TICKET_UPDATE_FAIL";
export const TICKET_USERS_REQUEST = "TICKET_USERS_REQUEST";
export const TICKET_USERS_SUCCESS = "TICKET_USERS_SUCCESS";
export const TICKET_USERS_FAIL = "TICKET_USERS_FAIL";

export const createTicketAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: TICKET_CREATE_REQUEST
  });
  nodeCudApi.post(`/Tickets/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: TICKET_CREATE_SUCCESS
        });
        toast('Ticket added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/ticketing-management');
      } else {
        dispatch({
          type: TICKET_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: TICKET_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};
/*=============================================================
                edit Ticket Action
===============================================================*/
export const updateTicketAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: TICKET_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Tickets/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: TICKET_UPDATE_SUCCESS
        });
        toast('Ticket updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/ticketing-management');
      } else {
        dispatch({
          type: TICKET_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: TICKET_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

/*=============================================================
                 Delete Ticket Action
===============================================================*/
export const deleteTicketAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Tickets/Delete`, formData);
    if (data) {
      toast('Ticket deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationTickets(filters, pagination, callBackPagination))
    }
  } catch (err) {
  }
};

//Get users
export const getAllUserAction = (emailid) => async dispatch => {
  try {
    let formData = {
      Email_Id: emailid,
      Records_Filter: "FILTER"
    };
    dispatch({
      type: TICKET_USERS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin/User_Get_dropdown_search?PageNo=1&PageSize=${config.pageSize}&SortBy=Email_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: TICKET_USERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: TICKET_USERS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: TICKET_USERS_FAIL,
      payload: err
    });
  }
};

//Node API
export const getUserTicketAction = (formData, callBackTicketData) => async dispatch => {
  try {
    let { data, status } = await nodeGetApi.post(`/Tickets/Get_Admin`, formData);
    if (data) {
      callBackTicketData(data)
    }
  } catch (err) {
  }
};