import React,{ useEffect, useState } from 'react';
import { Link as RouterLink,useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import VehicleDetailsList from './VehicleDetailsView';
import { useSelector } from 'react-redux';
import isEmpty from 'src/store/validations/is-empty';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const VehicleDetailsView = () => {
  const classes = useStyles();
  const location = useLocation();
  const { state } = location;
  const [allvehicledetails, setVehicledetails] = useState([]);
  let vehicledetails = useSelector(state => state.deliveryvehicledetail.allvehicledetails);
  
  useEffect(() => {
    if (!isEmpty(vehicledetails)) {
      setVehicledetails(vehicledetails.deliveryvehicledetail);
    }
  }, [vehicledetails]);
  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/delivery-vehicle-details"
          variant="text"
          size="small"
          color="inherit"
          state={state?.state}
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
          View Vehicle Details
          </Typography>
        </Box>
        <VehicleDetailsList state={state?.state}  data={state?.data} allvehicledetails={allvehicledetails} />
      </Container>
    </Page>
  );
};

export default VehicleDetailsView;