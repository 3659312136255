import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
} from '@material-ui/core';
import {
  addgenericServiceAction, getAllcities, uploadPartnerServiceImage
} from './../../../store/actions/servicepartnercityAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { getAllBussinessSubtype } from 'src/store/actions/servicesAction';
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  }
}));

const AddGenericUserService = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  useEffect(() => {
    let data = {
      "Records_Filter": "ALL"
    }
    dispatch(getAllBussinessSubtype(data))
    dispatch(getAllcities());
  }, []);

  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const isSubmited = useSelector(state => state.genericservice.serviceCreate.isLoading);
  const errorMessage = useSelector(state => state.genericservice.serviceCreate.error);
  const citynames = useSelector(state => state.genericservice.citypartnercitynames.citynames);
  const BusinessSubtypeServices = useSelector(state => state.service.BussinesssubtypeServices.bussinesssubtypeServices);

  // img upload
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadPartnerServiceImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        City_Id: '',
        Service_Type: '',
        Advance_Amount: '',
        Per_Day_Rate: '',
        Per_Hour_Rate: '',
        Image_URL: imgUrl,
        Status: '',
        CGST_Percentage: '',
        IGST_Percentage: '',
        SGST_Percentage: '',
        Created_By: loginEmail,
      }}

      validationSchema={
        Yup.object().shape({
          City_Id: Yup.string().required('City Name is required.'),
          Service_Type: Yup.string().required('Service Type is required.'),
          Advance_Amount: Yup.number().min(0, "Advance Amount should not be less than 0.").required('Advance Amount is required.'),
          Per_Day_Rate: Yup.number().min(0, "Price Per Day should not be less than 0.").required('Price Per Day is required.'),
          Per_Hour_Rate: Yup.number().min(0, "Price Per Hour should not be less than 0.").required('Price Per Hour is required.'),
          Status: Yup.string().required('Status is required.'),
          CGST_Percentage: Yup.number().min(0, "CGST Percentage should not be less than 0.").max(100, 'CGST Percentage must be less than 100.').required('CGST Percentage is required.'),
          IGST_Percentage: Yup.number().min(0, "IGST Percentage should not be less than 0.").max(100, 'IGST Percentage must be less than 100.').required('IGST Percentage is required.'),
          SGST_Percentage: Yup.number().min(0, "SGST Percentage should not be less than 0.").max(100, 'SGST Percentage must be less than 100.').required('SGST Percentage is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        values.Image_URL = imgUrl;
        if (!values.Image_URL) {
          submitTest = false;
          errors.Image_URL = "Image is required.";
        }
        setErrors(errors);

        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(addgenericServiceAction(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    {/* City_Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.City_Id && errors.City_Id)}
                        fullWidth
                        helperText={touched.City_Id && errors.City_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        label="City Name"
                        name="City_Id"
                        value={values.City_Id}
                        variant="outlined"
                      >
                        <option key="" value="">--Please Select--</option>
                        {citynames?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                          <option key={option.City_Id} value={option.City_Id}>
                            {option.Name + " " + "-" + " " + option.State + " " + "-" + " " + option.Country}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Service_Type */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Service_Type && errors.Service_Type)}
                        fullWidth
                        helperText={touched.Service_Type && errors.Service_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        SelectProps={{ native: true }}
                        label="Service Type"
                        name="Service_Type"
                        value={values.Service_Type}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {BusinessSubtypeServices?.sort((a, b) => a.Business_Sub_Type?.localeCompare(b.Business_Sub_Type))?.map(option => (
                          <option key={option.Partner_Business_Type_Id} value={option.Business_Sub_Type}>
                            {option.Business_Sub_Type}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Advance_Amount */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Advance_Amount && errors.Advance_Amount)}
                        fullWidth
                        helperText={touched.Advance_Amount && errors.Advance_Amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Advance Amount"
                        name="Advance_Amount"
                        value={values.Advance_Amount}
                        variant="outlined"
                        type="number"
                      />
                    </Grid>

                    {/* Per_Day_Rate */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Per_Day_Rate && errors.Per_Day_Rate)}
                        fullWidth
                        helperText={touched.Per_Day_Rate && errors.Per_Day_Rate}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Price Per Day"
                        name="Per_Day_Rate"
                        value={values.Per_Day_Rate}
                        variant="outlined"
                        type="number"
                      />
                    </Grid>

                    {/* Per_Hour_Rate */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Per_Hour_Rate && errors.Per_Hour_Rate)}
                        fullWidth
                        helperText={touched.Per_Hour_Rate && errors.Per_Hour_Rate}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Price Per Hour"
                        name="Per_Hour_Rate"
                        value={values.Per_Hour_Rate}
                        variant="outlined"
                        type="number"
                      />
                    </Grid>

                    {/* CGST_Percentage */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.CGST_Percentage && errors.CGST_Percentage)}
                        fullWidth
                        helperText={touched.CGST_Percentage && errors.CGST_Percentage}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="CGST Percentage"
                        name="CGST_Percentage"
                        value={values.CGST_Percentage}
                        variant="outlined"
                        type="number"
                      />
                    </Grid>
                    {/* SGST_Percentage */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.SGST_Percentage && errors.SGST_Percentage)}
                        fullWidth
                        helperText={touched.SGST_Percentage && errors.SGST_Percentage}
                        onBlur={handleBlur}
                        onChange={e => {
                          let IGST_Percentage = values.CGST_Percentage + Number(e.target.value)
                          setFieldValue("SGST_Percentage", e.target.value);
                          setFieldValue("IGST_Percentage", IGST_Percentage);
                        }}
                        label="SGST Percentage"
                        name="SGST_Percentage"
                        value={values.SGST_Percentage}
                        variant="outlined"
                        type="number"
                      />
                    </Grid>
                    {/* IGST_Percentage */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.IGST_Percentage && errors.IGST_Percentage)}
                        fullWidth
                        helperText={touched.IGST_Percentage && errors.IGST_Percentage}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="IGST Percentage"
                        name="IGST_Percentage"
                        value={values.IGST_Percentage}
                        variant="outlined"
                        type="number"
                        disabled
                      />
                    </Grid>
                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={4} xs={12}>
                  <Card>
                    &nbsp;Service Image
                    <CardContent>
                      <Box display="flex" justifyContent="center" >
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="logo Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="logo Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                  <span style={{ color: "red" }}>
                    {!imgUrl && touched.Image_URL && errors.Image_URL}
                  </span>
                </Grid>

              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited || uploadPic}
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#27B6CC' }}
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddGenericUserService.propTypes = {
  className: PropTypes.string
};

export default AddGenericUserService;