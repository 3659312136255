import { toast } from 'react-toastify';
import { nodeCorpMongoApi } from './commonAxios';
import { paginationCorpOrderInfoGet, paginationCorpOrdersGet } from './node-actions/nodepagination';

export const CORP_ORDER_UPDATE_REQUEST = "CORP_ORDER_UPDATE_REQUEST";
export const CORP_ORDER_UPDATE_SUCCESS = "CORP_ORDER_UPDATE_SUCCESS";
export const CORP_ORDER_UPDATE_FAIL = "CORP_ORDER_UPDATE_FAIL";

export const CORP_ORDER_GET_REQUEST = "CORP_ORDER_GET_REQUEST";
export const CORP_ORDER_GET_SUCCESS = "CORP_ORDER_GET_SUCCESS";
export const CORP_ORDER_GET_FAIL = "CORP_ORDER_GET_FAIL";

export const CORP_DOOR_ORDER_GET_REQUEST = "CORP_DOOR_ORDER_GET_REQUEST";
export const CORP_DOOR_ORDER_GET_SUCCESS = "CORP_DOOR_ORDER_GET_SUCCESS";
export const CORP_DOOR_ORDER_GET_FAIL = "CORP_DOOR_ORDER_GET_FAIL";

export const updateCorpOrderStatusAction = (formData, filters, pagination, callBackPagination, pagetype) => async dispatch => {
    dispatch({
        type: CORP_ORDER_UPDATE_REQUEST
    });
    nodeCorpMongoApi.post(`/corp_SA_Product_Order/orderStatusUpdate`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.code === 201) {
                dispatch({
                    type: CORP_ORDER_UPDATE_SUCCESS
                });
                if (pagetype === "main") {
                    dispatch(paginationCorpOrdersGet(filters, pagination, callBackPagination))
                } else if (pagetype === "doorstep") {
                    dispatch(paginationCorpOrderInfoGet(filters, pagination, callBackPagination))
                }
                toast('Order updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            } else {
                dispatch({
                    type: CORP_ORDER_UPDATE_FAIL,
                    payload: successResponse.message
                });
                toast(successResponse?.message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            }
        })
        .catch(error => {
            dispatch({
                type: CORP_ORDER_UPDATE_FAIL,
                payload: "Currently server is not working.Please try again later."
            });
        })
};

export const getcorpsingleorderdetails = (formData) => async dispatch => {
    try {
        dispatch({
            type: CORP_ORDER_GET_REQUEST
        });
        let { data } = await nodeCorpMongoApi.post(`Corp_SA_Product_Order/detailsGet`, formData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: CORP_ORDER_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: CORP_ORDER_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: CORP_ORDER_GET_FAIL,
            payload: err
        });
    }
};

export const getcorpdoorsteporderdetails = (formData) => async dispatch => {
    try {
        dispatch({
            type: CORP_DOOR_ORDER_GET_REQUEST
        });
        let { data } = await nodeCorpMongoApi.post(`Corp_SA_Product_Order/doorStepEmployeeOrderedProductGet`, formData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: CORP_DOOR_ORDER_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: CORP_DOOR_ORDER_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: CORP_DOOR_ORDER_GET_FAIL,
            payload: err
        });
    }
};

export const corpInvoiceget = (formData = {}, updateStatesInvoice) => async (dispatch) => {
    try {
        let { data } = await nodeCorpMongoApi.post(`/Corp_SA_Product_Order/invoiceGet`,formData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                updateStatesInvoice(true, []);
            } else {
                try {
                    updateStatesInvoice(true, data?.results[0]);
                } catch (error) {
                    updateStatesInvoice(true, data.results);
                }
            }
        }
    } catch (err) {
        updateStatesInvoice(false, err);
    }
};