import config from './config';
import {  nodeGetApi } from './commonAxios';

export const PARTNERBANK_REQUEST = "PARTNERBANK_REQUEST";
export const PARTNERBANK_SUCCESS = "PARTNERBANK_SUCCESS";
export const PARTNERBANK_FAIL = "PARTNERBANK_FAIL";
export const PARTNERBANK_GET_REQUEST = "PARTNERBANK_GET_REQUEST";
export const PARTNERBANK_GET_SUCCESS = "PARTNERBANK_GET_SUCCESS";
export const PARTNERBANK_GET_FAIL = "PARTNERBANK_GET_FAIL";



/*=============================================================
                   Get All User Action
===============================================================*/
export const getAllbankdetailsAction = formData => async dispatch => {
  try {
    dispatch({
      type: PARTNERBANK_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partner_Bank_Details/Get`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNERBANK_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNERBANK_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PARTNERBANK_FAIL,
      payload: err
    });
  }
};

//Bank Details Get
export const getBankdetailsAction = (formData, callBackpartnerbank) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Partner_Bank_Details/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_BANK_DETAIL_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBackpartnerbank(data)
    }
  } catch (err) {
  }
};