import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createPartnerEmployeeAction } from 'src/store/actions/partneremployeeAction';
import PhoneInput from 'react-phone-input-2';
import config from 'src/store/actions/config';
import { CircularProgress } from '@mui/material';


const UsertypeOptions = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'PARTNER_ADMIN',
    label: 'PARTNER_ADMIN'
  },
  {
    value: 'PARTNER_EMPLOYEE',
    label: 'PARTNER_EMPLOYEE',
  }
];

const AddPartnerEmployeeDetails = ({ className, state, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState({ Email_Id: { key: '', message: '' }, global: { key: '', message: '' } });

  const isSubmited = useSelector(state => state.partneremployee.partneremployeeCreate.isLoading);
  const createdBy = useSelector(state => state.auth.user.Email_Id);

  //mobile
  const [mobile, setMobile] = useState();


  /*====================================
          Handler
  ====================================*/
  const values = {
    Partner_Details_Id: state?.Partner_Details_Id,
    Base_URL: config.BASEURL + "/set-password?token=",
    Email_Id: "",
    Is_Active: false,
    User_Type: "",
    Mobile: "",
    First_Name: "",
    Last_Name: "",
    Partner_Employee_ID: 1,
    Created_By: createdBy
  }

  const Form_Validation = Yup.object().shape({
    Email_Id: Yup.string().email('Please provide valid Email ID.').notOneOf([errorMessage.Email_Id.key, null], errorMessage.Email_Id.message).required('Email ID is required.'),
    User_Type: Yup.string().required('User Type is required.'),
    First_Name: Yup.string().required('First Name is required.'),
    Last_Name: Yup.string().required('Last Name is required.'),
    Mobile: Yup.string().required('Mobile Number is required.'),
    Partner_Employee_ID: Yup.string().required('Partner Employee ID is required.'),
  })

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};

    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      if (values.Is_Active) {
        formValues.Is_Active = 1;
      } else {
        formValues.Is_Active = 0;
      }
      dispatch(createPartnerEmployeeAction(formValues, navigate, state, setErrorMessage, setErrors));

    }
  }

  return (
    <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Partner_Employee_ID && errors.Partner_Employee_ID)}
                        label="Partner Employee ID"
                        name="Partner_Employee_ID"
                        onChange={handleChange}
                        value={values.Partner_Employee_ID}
                        variant="outlined"
                        helperText={<ErrorMessage name="Partner_Employee_ID" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Email_Id && errors.Email_Id)}
                        label="Email ID"
                        name="Email_Id"
                        onChange={handleChange}
                        value={values.Email_Id}
                        variant="outlined"
                        helperText={<ErrorMessage name="Email_Id" />}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.First_Name && errors.First_Name)}
                        label="First Name"
                        name="First_Name"
                        onChange={handleChange}
                        value={values.First_Name}
                        variant="outlined"
                        helperText={<ErrorMessage name="First_Name" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Last_Name && errors.Last_Name)}
                        label="Last Name"
                        name="Last_Name"
                        onChange={handleChange}
                        value={values.Last_Name}
                        variant="outlined"
                        helperText={<ErrorMessage name="Last_Name" />}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.User_Type && errors.User_Type)}
                        fullWidth
                        placeholder="User Type"
                        name="User_Type"
                        onChange={handleChange}
                        label="User Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.User_Type}
                        variant="outlined"
                        helperText={<ErrorMessage name="User_Type" />}
                      >
                        {UsertypeOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <PhoneInput
                        containerClass={touched.Mobile && errors.Mobile ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                        inputClass={"form-control MuiOutlinedInput-input"}
                        error={true}
                        country={"in"}
                        fullWidth
                        inputStyle={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px"
                        }}
                        inputProps={{
                          name: "Mobile",
                        }}
                        specialLabel="Mobile Number"
                        name="Mobile"
                        onBlur={handleBlur}
                        placeholder="+91 XXXXX XXXXX"
                        onChange={(value, country, e, formattedValue) => {
                          setFieldValue("Mobile", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                          setMobile(formattedValue)
                        }}
                        value={mobile}
                      />
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Mobile && errors.Mobile}</p>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Active}
                            onChange={handleChange}
                            name="Is_Active"
                            color="primary"
                          />
                        }
                        label="Is Active"
                      />
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default AddPartnerEmployeeDetails;