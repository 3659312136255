import {
    BIDDING_AVAILABLE_SERVICE_UPDATE_REQUEST,
    BIDDING_AVAILABLE_SERVICE_UPDATE_SUCCESS,
    BIDDING_AVAILABLE_SERVICE_UPDATE_FAIL,
    BIDDING_AVAILABLE_SERVICE_CREATE_REQUEST,
    BIDDING_AVAILABLE_SERVICE_CREATE_SUCCESS,
    BIDDING_AVAILABLE_SERVICE_CREATE_FAIL,
    PARTNER_ADDRESS_REQUEST,
    PARTNER_ADDRESS_SUCCESS,
    PARTNER_ADDRESS_FAIL,
    PHYSICAL_PARTNERS_REQUEST,
    PHYSICAL_PARTNERS_SUCCESS,
    PHYSICAL_PARTNERS_FAIL,
    BIDDING_SERVICE_REQUEST,
    BIDDING_SERVICE_SUCCESS,
    BIDDING_SERVICE_FAIL,
} from '../actions/biddingpartneravailableserviceAction';

const initialState = {

    biddingavailableserviceUpdate: { biddingavailableservices: {}, error: '', isLoading: false },
    biddingavailableserviceCreate: { biddingavailableservices: {}, error: '', isLoading: false },
    partneraddress: { all: [], error: '', isLoading: false },
    PhysicalPartners: { partners: [], error: '', isLoading: false },
    BiddingServices: { services: [], error: '', isLoading: false },
};


export default function (state = initialState, action) {
    switch (action.type) {
        case BIDDING_AVAILABLE_SERVICE_CREATE_REQUEST:
            return { ...state, biddingavailableserviceCreate: { biddingavailableservices: {}, error: '', isLoading: true } };
        case BIDDING_AVAILABLE_SERVICE_CREATE_SUCCESS:
            return { ...state, biddingavailableserviceCreate: { biddingavailableservices: state.biddingavailableserviceCreate.biddingavailableservices, error: '', isLoading: false } };
        case BIDDING_AVAILABLE_SERVICE_CREATE_FAIL:
            return { ...state, biddingavailableserviceCreate: { biddingavailableservices: {}, error: action.payload, isLoading: false } };
        case BIDDING_AVAILABLE_SERVICE_UPDATE_REQUEST:
            return { ...state, biddingavailableserviceUpdate: { biddingavailableservices: {}, error: '', isLoading: true } };
        case BIDDING_AVAILABLE_SERVICE_UPDATE_SUCCESS:
            return { ...state, biddingavailableserviceUpdate: { biddingavailableservices: state.biddingavailableserviceUpdate.biddingavailableservices, error: '', isLoading: false } };
        case BIDDING_AVAILABLE_SERVICE_UPDATE_FAIL:
            return { ...state, biddingavailableserviceUpdate: { biddingavailableservices: {}, error: action.payload, isLoading: false } };
        case PARTNER_ADDRESS_REQUEST:
            return { ...state, partneraddress: { all: state.partneraddress.all, error: '', isLoading: true } };
        case PARTNER_ADDRESS_SUCCESS:
            return { ...state, partneraddress: { all: action.payload, error: '', isLoading: false } };
        case PARTNER_ADDRESS_FAIL:
            return { ...state, partneraddress: { all: [], error: action.payload, isLoading: false } };
        case PHYSICAL_PARTNERS_REQUEST:
            return { ...state, PhysicalPartners: { partners: state.PhysicalPartners.partners, error: '', isLoading: true }, };
        case PHYSICAL_PARTNERS_SUCCESS:
            return { ...state, PhysicalPartners: { partners: action.payload, error: '', isLoading: false } };
        case PHYSICAL_PARTNERS_FAIL:
            return { ...state, PhysicalPartners: { partners: [], error: action.payload, isLoading: false } };
            case BIDDING_SERVICE_REQUEST:
            return { ...state, BiddingServices: { services: state.BiddingServices.services, error: '', isLoading: true }, };
        case BIDDING_SERVICE_SUCCESS:
            return { ...state, BiddingServices: { services: action.payload, error: '', isLoading: false } };
        case BIDDING_SERVICE_FAIL:
            return { ...state, BiddingServices: { services: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}