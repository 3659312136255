import config from './config';
import { toast } from 'react-toastify';
import { boomiAdminApi, nodeCudApi, nodeGetApi } from './commonAxios';

export const BIDDING_SERVICE_CREATE_REQUEST = "BIDDING_SERVICE_CREATE_REQUEST";
export const BIDDING_SERVICE_CREATE_SUCCESS = "BIDDING_SERVICE_CREATE_SUCCESS";
export const BIDDING_SERVICE_CREATE_FAIL = "BIDDING_SERVICE_CREATE_FAIL";
export const BIDDING_SERVICE_UPDATE_REQUEST = "BIDDING_SERVICE_UPDATE_REQUEST";
export const BIDDING_SERVICE_UPDATE_SUCCESS = "BIDDING_SERVICE_UPDATE_SUCCESS";
export const BIDDING_SERVICE_UPDATE_FAIL = "BIDDING_SERVICE_UPDATE_FAIL";

//Add Bidding Service Action
export const AddBiddingServiceAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: BIDDING_SERVICE_CREATE_REQUEST
  });
  setErrorMessage({ Service_Type: { key: '', message: '' }, global: { key: '', message: '' } });
  boomiAdminApi.post(`/Bidding_Service/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BIDDING_SERVICE_CREATE_SUCCESS
        });
        toast('Bidding Service added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/services-bidding');
      } else {
        const errors = {};
        if (successResponse.Service_Type) {
          errors.Service_Type = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Service_Type: { key: successResponse.Service_Type ? successResponse.Service_Type : '', message: successResponse.Service_Type ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Service_Type) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: BIDDING_SERVICE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Service_Type: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: BIDDING_SERVICE_CREATE_FAIL,
        payload: "Please Try Sometime Later.Currently Server is Not Working."
      });
    })
};

//   Update Bidding Service Action
export const UpdateBiddingServiceAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: BIDDING_SERVICE_UPDATE_REQUEST
  });
  setErrorMessage({ Service_Type: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Bidding_Service/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BIDDING_SERVICE_UPDATE_SUCCESS
        });
        toast('Bidding Service updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/services-bidding');
      } else {
        const errors = {};
        if (successResponse.Service_Type) {
          errors.Service_Type = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Service_Type: { key: successResponse.Service_Type ? successResponse.Service_Type : '', message: successResponse.Service_Type ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Service_Type) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: BIDDING_SERVICE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Service_Type: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: BIDDING_SERVICE_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

// singleImageUploadCreate Action
export const singleImageUploadCreate = (image, callBackfunction, key) => async dispatch => {
  boomiAdminApi.post(`/SA_Upload/Upload?functionality=BiddingService&fileextension=png&filetype=Images&filename=BiddingService`, image)
    .then(response => {
      let data = response.data;
      if (data.File_URL) {
        callBackfunction(true, data.File_URL, key);
      }
      else {
        callBackfunction(false, data.File_URL, key);
      }
    }).catch(error => {
      callBackfunction(false, "", key);
    })
}

export const singleImageUploadUpdate = (image, BiddingServiceId, modifiedBy, key, callBackfunction) => async dispatch => {
  boomiAdminApi.post(`/SA_Upload/Upload?functionality=Products&fileextension=png&filetype=Images&filename=Products`, image)
    .then(response => {
      let successResponse = response.data;
      let data = response.data;
      if (successResponse.Response_Status === "Success") {
        let imageCreate = {
          "Created_By": modifiedBy,
          "Bidding_Service_Id": BiddingServiceId
        }
        let Images = [];
        Images.push({
          "Service_Image": data.File_URL,
          "Is_Default": "0",
          "Image_Sort": key
        });
        imageCreate.Image = Images;
        boomiAdminApi.post(`/Bidding_Services_Images/Multi_Image_Create`, imageCreate)
          .then(response => {
            callBackfunction(true);
          }).catch(error => {
            callBackfunction(false);
          })
      }
    }).catch(error => {
      callBackfunction(false);
    })
}

export const imageDelete = (input, callBackfunction, key) => async dispatch => {
  let payload = {
    "Bidding_Service_Image_Id": input,
    "Record_Filter": "H"
  };
  boomiAdminApi.post(`/Bidding_Services_Images/Delete`, JSON.stringify(payload))
    .then(response => {
      let data = response.data;
      callBackfunction(input, data.Response_Status === "Success" ? true : false, key);
    })
}

//imageDefaultUpdate Action
export const imageDefaultUpdate = (serviceId, modifiedBy, Service_Image_Id, callBackfunction) => async dispatch => {
  boomiAdminApi.put(`/Bidding_Services_Images/Update`,
    {
      "Is_Default": 1,
      "Modified_By": modifiedBy,
      "Bidding_Service_Id": serviceId,
      "Bidding_Service_Image_Id": Service_Image_Id,
    })
    .then(response => {
      let data = response.data;
      if (data.Response_Status === "Success") {
        callBackfunction(true);
      }
      else {
        callBackfunction(false);
      }
    }).catch(error => {
      callBackfunction(false);
    })
}

//getServiceImages Action
export const getServiceImages = (serviceId, callBackfunction, key) => async dispatch => {
  let payload = {
    "Records_Filter": "FILTER",
    "Bidding_Service_Image_Id": "",
    "Bidding_Service_Id": serviceId,
    "Created_By": "",
  };
  nodeGetApi.post(`/Bidding_Services_Multi_Images/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=CREATED_BY&SortOrder=ASC`, payload)
    .then(response => {
      let data = response.data;
      if (data.Success_Response.Is_Data_Exist === "0" && data.Success_Response.Response_Status === "Success") {
        callBackfunction([])
      }
      else if (data.Success_Response.Is_Data_Exist === "1" && data.Success_Response.Response_Status === "Success") {
        callBackfunction(data.results[0].Bidding_Service_Image_Id, key);
      } else {
        callBackfunction([]);
      }
    })
}