import {
  USER_PAYMENT_UPDATE_REQUEST,
  USER_PAYMENT_UPDATE_SUCCESS,
  USER_PAYMENT_UPDATE_FAIL,
} from '../actions/userpaymentsettlementsAction'

const initialState = {
  userpaymentUpdate: { userpayment: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_PAYMENT_UPDATE_REQUEST:
      return { ...state, userpaymentUpdate: { userpayment: {}, error: '', isLoading: true } };
    case USER_PAYMENT_UPDATE_SUCCESS:
      return { ...state, userpaymentUpdate: { userpayment: state.userpaymentUpdate.userpayment, error: '', isLoading: false } };
    case USER_PAYMENT_UPDATE_FAIL:
      return { ...state, userpaymentUpdate: { userpayment: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}