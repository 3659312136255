import React, { useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Orders from './count/Orders';
import TopCoupons from './top/TopCoupons';
import TopDeals from './top/TopDeals';
import TopProducts from './top/TopProducts';
import Partners from './count/Partners';
import TotalCustomers from './count/TotalCustomers';
import TotalProfit from './count/TotalProfit';
import Cashback from './count/Cashback';
import TotalBrands from './count/TotalBrands';
import TotalCoupons from './count/TotalCoupons';
import TotalProducts from './count/TotalProducts';
import TotalDeals from './count/TotalDeals';
import OnlinePartners from './count/OnlinePartners';
import PhysicalPartners from './count/PhysicalPartners';
import { useDispatch, useSelector } from 'react-redux';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const [seconds, setSeconds] = React.useState(-1);

  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  const RolePermissions = useSelector(state => state.rolepermission.Roles);
  useEffect(() => {
    dispatch(getRolePermissions(user));
    setSeconds(5);
  }, []);

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          {/* orders */}
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <Orders setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

          {/* Profit */}
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <TotalProfit setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

          {/* user cash back */}
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <Cashback setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

          {/* partners */}
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <Partners setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

          {/* online partners */}
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <OnlinePartners setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

          {/* physical partners */}
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <PhysicalPartners setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

          {/* brands */}
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <TotalBrands setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

          {/* coupons */}
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <TotalCoupons setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

          {/* deals */}
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <TotalDeals setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

          {/* products */}
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <TotalProducts setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

          {/* users */}
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <TotalCustomers setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>
          
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
          </Grid>

          {/* TopCoupons */}
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <TopCoupons setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

          {/* TopDeals */}
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <TopDeals setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

          {/* TopProducts */}
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <TopProducts setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;