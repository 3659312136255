
import config from './config';
import { toast } from 'react-toastify';
import { paginationSubCategory } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi,boomiAdminApi } from './commonAxios';


export const SUB_CATEGORY_CREATE_REQUEST = "SUB_CATEGORY_CREATE_REQUEST";
export const SUB_CATEGORY_CREATE_SUCCESS = "SUB_CATEGORY_CREATE_SUCCESS";
export const SUB_CATEGORY_CREATE_FAIL = "SUB_CATEGORY_CREATE_FAIL";
export const SUB_CATEGORY_UPDATE_REQUEST = "SUB_CATEGORY_UPDATE_REQUEST";
export const SUB_CATEGORY_UPDATE_SUCCESS = "SUB_CATEGORY_UPDATE_SUCCESS";
export const SUB_CATEGORY_UPDATE_FAIL = "SUB_CATEGORY_UPDATE_FAIL";
export const CATEGORIES_REQUEST = "CATEGORIES_REQUEST";
export const CATEGORIES_SUCCESS = "CATEGORIES_SUCCESS";
export const CATEGORIES_FAIL = "CATEGORIES_FAIL";

/*=============================================================
                    Add Sub Category Action
===============================================================*/


export const createSubCategoryAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: SUB_CATEGORY_CREATE_REQUEST
  });
  setErrorMessage({ Sub_Category_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Categories/Sub_Category_Create`, formData)
    .then(response => {

      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SUB_CATEGORY_CREATE_SUCCESS
        });
        toast('SubCategory added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/subcategory');
      } else {
        const errors = {};
        if (successResponse.Sub_Category_Name) {
          errors.Sub_Category_Name = successResponse.UI_Display_Message;

        }
        setErrors(errors)
        setErrorMessage({ Sub_Category_Name: { key: successResponse.Sub_Category_Name ? successResponse.Sub_Category_Name : '', message: successResponse.Sub_Category_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Sub_Category_Name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: SUB_CATEGORY_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Sub_Category_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: SUB_CATEGORY_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
            Update Subcategory Action
===============================================================*/
export const updateSubCategoryAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: SUB_CATEGORY_UPDATE_REQUEST
  });
  setErrorMessage({ Sub_Category_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Categories/Sub_Category_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SUB_CATEGORY_UPDATE_SUCCESS
        });
        toast('SubCategory updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/subcategory');
      } else {
        const errors = {};
        if (successResponse.Sub_Category_Name) {
          errors.Sub_Category_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Sub_Category_Name: { key: successResponse.Sub_Category_Name ? successResponse.Sub_Category_Name : '', message: successResponse.Sub_Category_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Sub_Category_Name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: SUB_CATEGORY_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Sub_Category_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: SUB_CATEGORY_UPDATE_FAIL,
        payload: "Please Try Sometime Later.Currently Server is Not Working"
      });
    })
};

/*=============================================================
                  Delete Sub Category Action
===============================================================*/
export const deleteSubCategoryAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Categories/Sub_Category_Delete`,
      formData
    );
    if (data) {
      toast('SubCategory deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationSubCategory(filters, pagination, callBackPagination));
    }
  } catch (err) { }
};


/*=============================================================
               Get All Categories Action
===============================================================*/
export const getAllCategories = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Status: "Active",
      search_by_filter: "",
      search: "",
    };
    dispatch({
      type: CATEGORIES_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Categories/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=CATEGORY_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CATEGORIES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CATEGORIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CATEGORIES_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                SubCategory Image Upload Api
===============================================================*/
export const uploadSubCategoryImage = (
  formData,
  callBackUploadImgae,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=SubCategoryWeb&fileextension=png&filetype=Images&filename=SubCategoryWeb`,
      formData
    );
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};

/*=============================================================
                uploadsubmobileimage Action
===============================================================*/
export const uploadsubmobileimage = (
  formData,
  callBackUploadImgae1,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=SubCategoryMobile&fileextension=png&filetype=Images&filename=SubCategoryMobile`,
      formData
    );
    if (data) {
      callBackUploadImgae1(data);
    }
  } catch (err) { }
};