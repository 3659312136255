import { toast } from 'react-toastify';
import { nodeCudApi} from './commonAxios';
export const TEMPLATE_CREATE_REQUEST = "TEMPLATECREATE_REQUEST";
export const TEMPLATE_CREATE_SUCCESS = "TEMPLATE_CREATE_SUCCESS";
export const TEMPLATE_CREATE_FAIL = "TEMPLATE_CREATE_FAIL";
export const TEMPLATE_UPDATE_REQUEST = "TEMPLATE_UPDATE_REQUEST";
export const TEMPLATE_UPDATE_SUCCESS = "TEMPLATE_UPDATE_SUCCESS";
export const TEMPLATE_UPDATE_FAIL = "TEMPLATE_UPDATE_FAIL";

/*=============================================================
                  createTemplate Action 
===============================================================*/
export const createTemplateAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: TEMPLATE_CREATE_REQUEST
  });
  nodeCudApi.post(`/Templates/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: TEMPLATE_CREATE_SUCCESS
        });
        toast('Email template added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/email-template');
      } else {
        dispatch({
          type: TEMPLATE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: TEMPLATE_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                updateTemplate Action
===============================================================*/
export const updateTemplateAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: TEMPLATE_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Templates/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: TEMPLATE_UPDATE_SUCCESS
        });
        toast('Email template updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/email-template');
      } else {
        dispatch({
          type: TEMPLATE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: TEMPLATE_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};