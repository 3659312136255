import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Avatar
} from '@material-ui/core';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { updateDeliveryAction, uploadprofilepicImage } from 'src/store/actions/deliveryAgentAction';
import PhoneInput from 'react-phone-input-2'
import { CircularProgress } from '@mui/material';

const GenderOptions = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Male',
    label: 'Male'
  },
  {
    value: 'Female',
    label: 'Female'
  }
];

const DeliveryOptions = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'DELIVERY',
    label: 'DELIVERY'
  },
  {
    value: 'DELIVERY_ESI',
    label: 'DELIVERY_ESI'
  }
];

const delipersontypeOptions = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'DELIVERY',
    label: 'DELIVERY'
  },
  {
    value: 'RIDER',
    label: 'RIDER'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: 120,
    height: 120,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditDeliverypersonDetails = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  let { state } = location;

  const [errorMessage, setErrorMessage] = useState({ Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
  const [mobile, setMobile] = useState(state.Mobile);

  const isSubmited = useSelector(state => state.delivery.deliverysUpdate.isLoading);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);

  // img upload
  const [image, setImage] = useState({ preview: state.Profile_pic_path, raw: state.Profile_pic_path });

  const [imgUrl, setImgUrl] = useState(state.Profile_pic_path);
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    let data = new FormData();
    data.append('file', new Blob([e.target.files[0]]));
    dispatch(
      uploadprofilepicImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  const values = {
    "Aadhar": state.Aadhar ? state.Aadhar : "",
    "DOB": state.Dob ? state.Dob : "",
    "Del_User_Detail_id": state.Del_user_detail_id ? state.Del_user_detail_id : "",
    "Del_user_email_id": state.Del_user_email_id ? state.Del_user_email_id : "",
    "Del_User_Type": state.Del_User_Type ? state.Del_User_Type : "",
    "First_name": state.First_name ? state.First_name : "",
    "Full_name": state.Full_name ? state.Full_name : "",
    "Gender": state.Gender ? state.Gender : "",
    "Is_Approved": state.Is_Approved > 0 ? true : false,
    "Is_active": state.Is_active > 0 ? true : false,
    "Is_email_verified": state.Is_email_verified > 0 ? true : false,
    "Last_name": state.Last_name ? state.Last_name : "",
    "License": state.License ? state.License : "",
    "Marketing_Agreed": state.Marketing_Agreed > 0 ? true : false,
    "Mobile": state.Mobile ? state.Mobile : "",
    "Profile_pic_path": state.Profile_pic_path,
    "Role": state.Role ? state.Role : "",
    "T_and_C_Agreed": state.T_and_C_Agreed > 0 ? true : false,
    "IS_Blocked_By_Admin": state.IS_Blocked_By_Admin > 0 ? true : false
  }

  const Form_Validation = Yup.object().shape({
    First_name: Yup.string().required('First Name is required.'),
    Last_name: Yup.string().required('Last Name is required.'),
    Del_User_Type: Yup.string().required('Delivery User Type is required.'),
    Aadhar: Yup.string().min(12, "Aadhaar Number should contain 12 characters.").max(12, "Aadhaar Number should contain 12 characters.").matches(/^[0-9]*$/, "Aadhaar Number is not valid.").required("Aadhaar Number is required."),
    License: Yup.string().min(16, "Driving License Number is not valid.").max(16, "Driving License Number is not valid.").required("Driving License Number is required."),
    Mobile: Yup.string().min(14, "Please provide a valid Mobile Number.").required('Mobile Number is required.'),
    Del_user_email_id: Yup.string().email('Please provide valid Delivery Agent Email ID.').required('Delivery Agent Email ID is required.'),
    Gender: Yup.string().required('Gender is required.'),
    Role: Yup.string().required('Role is required.'),
    DOB: Yup.date()
      .max(new Date(Date.now() - 567648000000), "Delivery Person must be at least 18 years old.")
      .required("Date of Birth is required."),
  })

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};
    values.Profile_pic_path = imgUrl;

    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      if (values.T_and_C_Agreed) {
        formValues.T_and_C_Agreed = 1;
      } else {
        formValues.T_and_C_Agreed = 0;
      }
      if (values.Is_Approved) {
        formValues.Is_Approved = 1;
      } else {
        formValues.Is_Approved = 0;
      }
      if (values.Is_active) {
        formValues.Is_active = 1;
      } else {
        formValues.Is_active = 0;
      }
      if (values.Is_email_verified) {
        formValues.Is_email_verified = 1;
      } else {
        formValues.Is_email_verified = 0;
      }
      if (values.Marketing_Agreed) {
        formValues.Marketing_Agreed = 1;
      } else {
        formValues.Marketing_Agreed = 0;
      }
      if (values.IS_Blocked_By_Admin) {
        formValues.IS_Blocked_By_Admin = 1;
      } else {
        formValues.IS_Blocked_By_Admin = 0;
      }
      formValues.Modified_By = modifiedBy;
      dispatch(updateDeliveryAction(formValues, navigate, setErrorMessage, setErrors));
    }
  }

  return (
    <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
      {({
        errors,
        handleBlur,
        handleChange,
        values,
        touched,
        setFieldValue,
      }) => (
        <Form>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.First_name && errors.First_name)}
                        label="First Name"
                        name="First_name"
                        onChange={handleChange}
                        value={values.First_name}
                        variant="outlined"
                        helperText={<ErrorMessage name="First_name" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Last_name && errors.Last_name)}
                        label="Last Name"
                        name="Last_name"
                        onChange={handleChange}
                        value={values.Last_name}
                        variant="outlined"
                        helperText={<ErrorMessage name="Last_name" />}
                      />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        label="Full Name"
                        name="Full_name"
                        onChange={handleChange}
                        value={values.Full_name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Aadhar && errors.Aadhar)}
                        label="Aadhaar Number"
                        name="Aadhar"
                        onChange={handleChange}
                        value={values.Aadhar}
                        variant="outlined"
                        inputProps={{ maxLength: 12 }}
                        helperText={<ErrorMessage name="Aadhar" />}
                      />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.License && errors.License)}
                        label="Driving License Number"
                        name="License"
                        onChange={handleChange}
                        value={values.License}
                        variant="outlined"
                        inputProps={{ maxLength: 16 }}
                        helperText={<ErrorMessage name="License" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <PhoneInput
                        containerClass={touched.Mobile && errors.Mobile ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                        inputClass={"form-control MuiOutlinedInput-input"}
                        error={true}
                        country={"in"}
                        fullWidth
                        inputStyle={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px"
                        }}
                        inputProps={{
                          name: "Mobile",
                        }}
                        specialLabel="Mobile Number"
                        name="Mobile"
                        onBlur={handleBlur}
                        placeholder="+91 XXXXX XXXXX"
                        onChange={(value, country, e, formattedValue) => {
                          setFieldValue("Mobile", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                          setMobile(formattedValue)
                        }}
                        value={mobile}
                      />
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Mobile && errors.Mobile}</p>
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Del_user_email_id && errors.Del_user_email_id)}
                        label="Delivery Agent Email ID"
                        name="Del_user_email_id"
                        onChange={handleChange}
                        value={values.Del_user_email_id}
                        variant="outlined"
                        helperText={<ErrorMessage name="Del_user_email_id" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.DOB && errors.DOB)}
                        fullWidth
                        helperText={touched.DOB && errors.DOB}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="date"
                        label="Date of Birth"
                        name="DOB"
                        value={values.DOB}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                      </TextField>
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Gender && errors.Gender)}
                        fullWidth
                        placeholder="Gender"
                        name="Gender"
                        onChange={handleChange}
                        label="Gender"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Gender}
                        variant="outlined"
                        helperText={<ErrorMessage name="Gender" />}
                      >
                        {GenderOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Role && errors.Role)}
                        fullWidth
                        placeholder="Role"
                        name="Role"
                        onChange={handleChange}
                        label="Role"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Role}
                        variant="outlined"
                        helperText={<ErrorMessage name="Role" />}
                      >
                        {DeliveryOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Del_User_Type && errors.Del_User_Type)}
                        fullWidth
                        placeholder="Del User Type"
                        name="Del_User_Type"
                        onChange={handleChange}
                        label="Delivery User Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Del_User_Type}
                        variant="outlined"
                        helperText={<ErrorMessage name="Del_User_Type" />}
                      >
                        {delipersontypeOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}></Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.T_and_C_Agreed}
                            onChange={handleChange}
                            name="T_and_C_Agreed"
                            color="primary"
                          />
                        }
                        label="T&C Agreed"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_active}
                            onChange={handleChange}
                            name="Is_active"
                            color="primary"
                          />
                        }
                        label="Is Active"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Approved}
                            onChange={handleChange}
                            name="Is_Approved"
                            color="primary"
                          />
                        }
                        label="Is Approved"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Marketing_Agreed}
                            onChange={handleChange}
                            name="Marketing_Agreed"
                            color="primary"
                          />
                        }
                        label="Marketing Agreed"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_email_verified}
                            onChange={handleChange}
                            name="Is_email_verified"
                            color="primary"
                          />
                        }
                        label="Is Email Verified"
                      />
                    </Grid>

                    {/* IS_Blocked_By_Admin */}
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.IS_Blocked_By_Admin}
                            onChange={handleChange}
                            name="IS_Blocked_By_Admin"
                            color="primary"
                          />
                        }
                        label="Is Blocked By Admin"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Profile pic Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Profile pic Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited || uploadPic}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save Details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default EditDeliverypersonDetails;