import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import Typography from '@material-ui/core/Typography';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { useLocation } from 'react-router-dom';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';
import { getAllCategories, getProductImages, imageDefaultUpdate, imageDelete, singleImageUploadUpdate, updateBiddingProductsAction } from 'src/store/actions/biddingproductsAction';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const discountType = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'A',
    label: 'Amount'
  },
  {
    value: 'P',
    label: 'Percentage'
  },
];

const useStyles = makeStyles(() => ({
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid black'
  }
}));

const EditBidProducts = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  let { state } = location;

  // componentDidMount   
  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getProductImages(state.Bidding_Product_Id, callBackGetImages));
  }, []);

  // Multiple pic upload
  const [blobArrayPics, setBlobArrayPics] = useState([{

    key: 1, preview: null, Bidding_Product_Image_Id: null, Is_Default: 0
  },
  {
    key: 2, preview: null, Bidding_Product_Image_Id: null, Is_Default: 0
  },
  {
    key: 3, preview: null, Bidding_Product_Image_Id: null, Is_Default: 0
  },
  {
    key: 4, preview: null, Bidding_Product_Image_Id: null, Is_Default: 0
  },
  {
    key: 5, preview: null, Bidding_Product_Image_Id: null, Is_Default: 0
  }]);

  const [imageError, setImageError] = useState();
  const callBackUploadImage = (status) => {
    setImageDefault(true);
    if (status) {
      dispatch(getProductImages(state.Bidding_Product_Id, callBackGetImages));
    }
    else {
      setImageError("Unable to upload image please try again.");
    }
  };

  const [imageDefault, setImageDefault] = useState(false);
  function handleDefaultChange(status, key, imageId) {
    setImageDefault(true);
    dispatch(imageDefaultUpdate(state.Bidding_Product_Id, createdBy, imageId, callBackUploadImage));
  };

  const handleImage = (e, key) => {
    setImageError("");
    dispatch(
      singleImageUploadUpdate(new Blob([e.target.files[0]]), state.Bidding_Product_Id, createdBy, key, callBackUploadImage)
    );
  };

  function handleRemoveImage(e, key, imageId) {
    if (imageId) {
      dispatch(imageDelete(imageId, callBackDeleteImage, key));
    }
    else {
      callBackDeleteImage(imageId, true, key);
    }
  };

  const callBackDeleteImage = (imageId, status, key) => {
    if (status) {
      dispatch(getProductImages(state.Bidding_Product_Id, callBackGetImages, key));
    }
  };

  const callBackGetImages = (images, key) => {
    setImageDefault(false);
    let count = 0;
    const blobArrayPicsTemp = JSON.parse(JSON.stringify(blobArrayPics.map((record) => key ? { ...record, preview: null, Bidding_Product_Image_Id: null } : record)));
    if (images.length > 5) {
      for (let i = 0; i <= images.length - 6; i++) {
        blobArrayPicsTemp.push({
          key: i + 6, preview: null, Bidding_Product_Image_Id: null, Is_Default: 0
        })
      }
    }
    images.map(image => {
      blobArrayPicsTemp[count].preview = image.Product_Image;
      blobArrayPicsTemp[count].Bidding_Product_Image_Id = image.Bidding_Product_Image_Id;
      blobArrayPicsTemp[count].Is_Default = image.Is_Default;
      count++
    })
    setBlobArrayPics(blobArrayPicsTemp);
  };

  const categories = useSelector(state => state.biddingproducts.BidProductCategories.categories);
  const isSubmited = useSelector(state => state.biddingproducts.biddingproductUpdate.isLoading);
  const errorMessage = useSelector(state => state.biddingproducts.biddingproductUpdate.error);
  const createdBy = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor  
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Description || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Description);
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // Richtext editor (ESI Reviews) 
  const contentStateReviews = ContentState.createFromBlockArray(htmlToDraft(state.Meepaisa_Review_For_Product || "").contentBlocks);
  const [editorStateReviews, setEditorStateReviews] = useState(EditorState.createWithContent(contentStateReviews));
  const [rhDiscriptionReviews, setRhDiscriptionReviews] = useState(state.Meepaisa_Review_For_Product);
  const [rhDiscriptionReviewsMin, setRhDiscriptionReviewsMin] = useState(20);
  const onEditorStateChangeReviews = (editorStateReviews) => {
    setEditorStateReviews(editorStateReviews);
    setRhDiscriptionReviews(draftToHtml(convertToRaw(editorStateReviews.getCurrentContent())));
  };

  return (
    <Formik
      initialValues={{
        Bidding_Products_Category_Id: state.Bidding_Products_Category_Id ? state.Bidding_Products_Category_Id : "",
        Bidding_Product_Id: state.Bidding_Product_Id ? state.Bidding_Product_Id : "",
        Measurement_Type: state.Measurement_Type ? state.Measurement_Type : "",
        Description: state.Description ? state.Description : "",
        Esi_Commission: state.Esi_Commission ? state.Esi_Commission : "",
        Esi_Commission_Type: state.Esi_Commission_Type ? state.Esi_Commission_Type : "",
        Meepaisa_Rating_For_Product: state.Meepaisa_Rating_For_Product ? state.Meepaisa_Rating_For_Product : "",
        Meepaisa_Review_For_Product: parseInt(state.Esi_Rating),
        Modified_By: createdBy,
        Product_Name: state.Product_Name ? state.Product_Name : "",
        Status: state.Status ? state.Status : ""
      }}

      validationSchema={
        Yup.object().shape({
          Bidding_Products_Category_Id: Yup.string().required('Product Category Name is required.'),
          Product_Name: Yup.string().min(3).required('Product Name is required.'),
          Measurement_Type: Yup.string().required('Measurement type is required.'),
          Meepaisa_Rating_For_Product: Yup.number().required('Please provide Meepaisa Rating for product.').nullable(),
          Status: Yup.string().required('Status is required.'),
          Esi_Commission_Type: Yup.string().test(
            'oneOfRequired',
            'ESI Commission Type is required.',
            function (item) {
              return (this.parent.Esi_Commission_Type)
            }
          ),
          Esi_Commission: Yup.number().when("Esi_Commission_Type", (Esi_Commission_Type, schema) => {
            if (Esi_Commission_Type === "A") {
              return schema.min(1, "ESI Commission Amount must be at least 1.").required('ESI Commission Amount is required.');
            } else if (Esi_Commission_Type === "P") {
              return schema.min(1, "ESI Commission Percentage must be at least 1.").max(100, "ESI Commission Percentage must be less then 100.").required('ESI Commission Percentage is required.');
            }
          }),
        })
      }

      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        let submitTest = true;
        const errors = {};
        values.Description = rhDiscription;
        values.Meepaisa_Review_For_Product = rhDiscriptionReviews;
        if (!values.Description || values.Description?.replace(/(<([^>]+)>)/ig, '')?.trim().length === 0) {
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (values.Description?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Description = "Please provide at least " + rhDiscriptionMin + " characters";
        }

        if (!values.Meepaisa_Review_For_Product || values.Meepaisa_Review_For_Product?.replace(/(<([^>]+)>)/ig, '')?.trim().length === 0) {
          submitTest = false;
          errors.Meepaisa_Review_For_Product = "Meepaisa Review for Product is required.";
        }
        else if (values.Meepaisa_Review_For_Product?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionReviewsMin) {
          submitTest = false;
          errors.Meepaisa_Review_For_Product = "Please provide at least " + rhDiscriptionReviewsMin + " characters";
        }
        if (blobArrayPics.filter(item => { return !(!item.preview) }).length <= 0) {
          submitTest = false;
          setImageError("Image is required.");
        }

        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(updateBiddingProductsAction(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    {/* Bidding Products Category Name */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Bidding_Products_Category_Id && errors.Bidding_Products_Category_Id)}
                        fullWidth
                        helperText={touched.Bidding_Products_Category_Id && errors.Bidding_Products_Category_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Product Category Name"
                        name="Bidding_Products_Category_Id"
                        value={values.Bidding_Products_Category_Id}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {categories?.map(option => (
                          <option value={option.Bidding_Products_Category_Id} key={option.Bidding_Products_Category_Id}>
                            {option.Bidding_Products_Category_Name}</option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Product Name */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Product_Name && errors.Product_Name)}
                        fullWidth
                        helperText={touched.Product_Name && errors.Product_Name}
                        onBlur={handleBlur}
                        label="Product Name"
                        name="Product_Name"
                        onChange={handleChange}
                        value={values.Product_Name}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Measurement Type */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Measurement_Type && errors.Measurement_Type)}
                        fullWidth
                        helperText={touched.Measurement_Type && errors.Measurement_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Measurement Type"
                        name="Measurement_Type"
                        value={values.Measurement_Type}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>

                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        placeholder="Status"
                        name="Status"
                        onChange={handleChange}
                        label="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        helperText={<ErrorMessage name="Status" />}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/*  ESI Commission Type */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Esi_Commission_Type && errors.Esi_Commission_Type)}
                        fullWidth
                        helperText={touched.Esi_Commission_Type && errors.Esi_Commission_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="ESI Commission Type"
                        name="Esi_Commission_Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Esi_Commission_Type}
                        variant="outlined"
                      >
                        {discountType.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* ESI Commission */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Esi_Commission && errors.Esi_Commission)}
                        fullWidth
                        helperText={touched.Esi_Commission && errors.Esi_Commission}
                        onBlur={handleBlur}
                        label="ESI Commission"
                        name="Esi_Commission"
                        type="number"
                        onChange={handleChange}
                        value={values.Esi_Commission}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Description */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                      </span>
                    </Grid>

                    {/* Meepaisa_Review_For_Product */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Meepaisa Review for Product
                        <EditorContainer editorState={editorStateReviews} onEditorStateChange={onEditorStateChangeReviews} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscriptionReviews || rhDiscriptionReviews?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionReviewsMin) && touched.Meepaisa_Review_For_Product && errors.Meepaisa_Review_For_Product}
                      </span>
                    </Grid>

                    {/* Meepaisa Rating For Product */}
                    <Grid item md={4} xs={4}>
                      <Box>
                        Meepaisa Rating for Product</Box></Grid>
                    <Grid item md={4} xs={4}>
                      <Rating
                        name="Meepaisa_Rating_For_Product"
                        onChange={(event, newValue) => {
                          values.Meepaisa_Rating_For_Product = newValue;
                          setFieldValue(newValue);
                        }}
                        value={values.Meepaisa_Rating_For_Product}
                        size="small"
                      />
                    </Grid>

                  </Grid>
                </Grid>

                {/* upload photo */}
                <Grid item md={4} xs={12}  >
                  {blobArrayPics.filter(item => {
                    if (item.key === 1) {
                      return true;
                    } else {
                      return blobArrayPics[item.key - 2].preview !== null;
                    }
                  }).map(option => (
                    <Card style={{ marginBottom: "5px", marginTop: "5px" }} key={"image-card" + option.key}>
                      <CardContent>
                        <Box display="flex" justifyContent="center">
                          {option.preview ? (
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src={option.preview}
                              variant="square"
                            />
                          ) : (
                            <>
                              <Avatar
                                className={classes.images}
                                alt="Brand Upload"
                                src="/static/images/image-placeholder.png"
                                variant="square"
                              />
                            </>
                          )}
                        </Box>

                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          {option.preview ?
                            <> <FormControlLabel
                              disabled={Number(option.Is_Default) === 1 ? true : false}
                              key={"imageUpload" + option.key}
                              control={
                                <Checkbox
                                  onBlur={handleBlur}
                                  checked={Number(option.Is_Default) === 1 ? true : false}
                                  onChange={(e, status) => handleDefaultChange(status, option.key, option.Bidding_Product_Image_Id)}
                                  name="Is_Default"
                                  color="primary"
                                />
                              }
                              label={<Typography style={{ fontSize: "0.875rem" }}>Is Default</Typography>}
                            /> <Button
                              style={{ fontSize: '10', color: ' #425A70', textTransform: 'none' }}
                              onClick={(e) => handleRemoveImage(e, option.key, option.Bidding_Product_Image_Id)}
                              disabled={option.Is_Default === 1 ? true : false}
                            >
                                Remove Picture
                              </Button> </>
                            : <input
                              style={{ display: 'block', width: '80%' }}
                              id={"upload-photo" + option.key}
                              name="image"
                              type="file"
                              accept="image/*"
                              onChange={(e) => handleImage(e, option.key)}
                            />}
                          <br />
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                  <span style={{ color: "red" }}>
                    {blobArrayPics.filter(item => { return !(!item.preview) }).length <= 0 && imageError}
                  </span>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditBidProducts.propTypes = {
  className: PropTypes.string
};

export default EditBidProducts;