import {
  GIFTIMAGE_CREATE_REQUEST,
  GIFTIMAGE_CREATE_SUCCESS,
  GIFTIMAGE_CREATE_FAIL,
  GIFTIMAGE_UPDATE_REQUEST,
  GIFTIMAGE_UPDATE_SUCCESS,
  GIFTIMAGE_UPDATE_FAIL,

  GIFT_VENDORID_REQUEST,
  GIFT_VENDORID_SUCCESS,
  GIFT_VENDORID_FAIL,

} from '../actions/giftimageAction'

const initialState = {
  giftimageCreate: { giftimage: {}, error: '', isLoading: false },
  giftimageUpdate: { giftimage: {}, error: '', isLoading: false },

  giftVendorid: { giftvendorid: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GIFTIMAGE_CREATE_REQUEST:
      return { ...state, giftimageCreate: { giftimage: {}, error: '', isLoading: true } };
    case GIFTIMAGE_CREATE_SUCCESS:
      return { ...state, giftimageCreate: { giftimage: action.payload, error: '', isLoading: false } };
    case GIFTIMAGE_CREATE_FAIL:
      return { ...state, giftimageCreate: { giftimage: {}, error: action.payload, isLoading: false } };
    case GIFTIMAGE_UPDATE_REQUEST:
      return { ...state, giftimageUpdate: { giftimage: {}, error: '', isLoading: true } };
    case GIFTIMAGE_UPDATE_SUCCESS:
      return { ...state, giftimageUpdate: { giftimage: action.payload, error: '', isLoading: false } };
    case GIFTIMAGE_UPDATE_FAIL:
      return { ...state, giftimageUpdate: { giftimage: {}, error: action.payload, isLoading: false } };

    case GIFT_VENDORID_REQUEST:
      return { ...state, giftVendorid: { giftvendorid: state.giftVendorid.giftvendorid, error: '', isLoading: true }, };
    case GIFT_VENDORID_SUCCESS:
      return { ...state, giftVendorid: { giftvendorid: action.payload, error: '', isLoading: false } };
    case GIFT_VENDORID_FAIL:
      return { ...state, giftVendorid: { giftvendorid: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}