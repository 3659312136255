
import config from './config';
import { boomiAdminApi } from './commonAxios';

export const RAZORPAY_REQUEST = "RAZORPAY_REQUEST";
export const RAZORPAY_SUCCESS = "RAZORPAY_SUCCESS";
export const RAZORPAY_FAIL = "RAZORPAY_FAIL";
export const RAZORPAY_GET_REQUEST = "RAZORPAY_GET_REQUEST";
export const RAZORPAY_GET_SUCCESS = "RAZORPAY_GET_SUCCESS";
export const RAZORPAY_GET_FAIL = "RAZORPAY_GET_FAIL";
export const RAZORPAY_FILTER = "RAZORPAY_FILTER";


//  Get All Razorpay Action
export const getAllrazorpaydetailsAction = (formData, pagination) => async dispatch => {
  try {
    dispatch({
      type: RAZORPAY_REQUEST
    });
    let { data } = await boomiAdminApi.post(
      `/Razorpay/Payment_Transactions_Get?PageNo=${pagination.PageNo}&PageSize=${pagination.PageSize}&SortBy=Id&SortOrder=ASC`,
      formData
    );

    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: RAZORPAY_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: RAZORPAY_SUCCESS,
          payload: data
        });
      }
    }
  } catch (err) {
    dispatch({
      type: RAZORPAY_FAIL,
      payload: err
    });
  }
};

export const razorpayFilter = (fType, value) => {
  return function (dispatch) {
    dispatch({
      type: RAZORPAY_FILTER,
      fType: fType,
      value: value,
    });
  }
}