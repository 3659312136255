
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';
import { EditStoretypeAction, uploadImagepath } from 'src/store/actions/storetypeActions';
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditStoreType = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  let { state } = location;

  const isSubmited = useSelector(state => state.storetype.storetypeUpdate.isLoading);
  const errorMessage = useSelector(state => state.storetype.storetypeUpdate.error);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Description).contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Description);

  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // img upload
  const [image, setImage] = useState({ preview: state.Image_Path, raw: state.Image_Path });
  const [imgUrl, setImgUrl] = useState(state.Image_Path);
  const [uploadPic, setUploadPic] = useState(false);

  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadImagepath(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        Store_Type_Name: state.Store_Type_Name ? state.Store_Type_Name : "",
        Description: state.Description ? state.Description : "",
        Image_Path: imgUrl,
        Status: state.Status ? state.Status : "",
        Email_Id: modifiedBy
      }}
      validationSchema={Yup.object().shape({
        Status: Yup.string().required('Status is required.'),
      })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        const errors = {};
        setSubmitting(false);
        setErrors(errors);
        values.Image_Path = imgUrl
        values.Description = rhDiscription;
        if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }

        if (!values.Image_Path) {
          submitTest = false;
          errors.Image_Path = "Image is required.";
        }

        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(EditStoretypeAction(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container>
                <Grid container spacing={3}>
                  <Grid item md={8} xs={12}>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={Boolean(touched.Store_Type_Name && errors.Store_Type_Name)}
                          fullWidth
                          helperText={touched.Store_Type_Name && errors.Store_Type_Name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Store Type Name"
                          name="Store_Type_Name"
                          value={values.Store_Type_Name}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      {/*  Description  */}
                      <Grid item md={12} xs={12}>
                        <Box className={classes.description}>
                          Description
                          <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                        </Box>
                        <span style={{ color: "red" }}>
                          {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                        </span>
                      </Grid>
                      {/* Status */}
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={Boolean(touched.Status && errors.Status)}
                          fullWidth
                          helperText={touched.Status && errors.Status}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Status"
                          name="Status"
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ native: true }}
                          value={values.Status}
                          variant="outlined"
                        >
                          {statusOption.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Card>
                      &nbsp;Image Path
                      <CardContent>
                        <Box display="flex" justifyContent="center" >
                          {image.preview ? (
                            <Avatar
                              className={classes.images}
                              alt="logo Upload"
                              src={image.preview}
                              variant="square"
                            />
                          ) : (
                            <>
                              <Avatar
                                className={classes.images}
                                alt="logo Upload"
                                src="/static/images/image-placeholder.png"
                                variant="square"
                              />
                            </>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          {image.preview ? <Button
                            style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                            onClick={handleRemoveImage}
                          >
                            Remove Picture
                          </Button> : <input
                            style={{ display: 'block', width: '80%' }}
                            id="upload-photo"
                            name="image"
                            type="file"
                            accept="image/*"
                            onChange={handleImage}
                          />}
                          <br />
                        </Box>

                      </CardContent>
                    </Card>
                    <span style={{ color: "red" }}>
                      {!imgUrl && touched.Image_Path && errors.Image_Path}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2}>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" pl={2} pt={1} pb={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited || uploadPic}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditStoreType.propTypes = {
  className: PropTypes.string
};

export default EditStoreType;