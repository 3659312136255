import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    makeStyles,
    Tooltip
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import isEmpty from 'src/store/validations/is-empty';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    }
}));

const headCells = [
    { id: 'Coupon_MP_History_Id', numeric: false, disablePadding: true, label: 'MP Coupon History ID' },
    { id: 'Code', numeric: false, disablePadding: false, label: 'Code' },
    { id: 'Order_Id', numeric: false, disablePadding: false, label: 'Order ID' },
    { id: 'User_Email_Id', numeric: false, disablePadding: false, label: 'User Email ID' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
    className,
    allGlobalcouponhist,
    pagination,
    setPagination,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    createSortHandler,
    allGlobalcouponhistNoFilter,
    state,
    RolePermissions,
    roleUserType,
    ...rest
}) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [selectedGlobalCouponHistIds, setSelectedGlobalCouponHistIds] = useState([]);

    const visibleGlobalCouponHistHandler = couponhistData => e => {
        navigate("/app/view-global-coupon-history", { state: { state: state, data: couponhistData } });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-4rem' }} >Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allGlobalcouponhist) &&
                                (allGlobalcouponhist).map(globalcouponhist => (
                                    <TableRow
                                        hover
                                        key={globalcouponhist.Coupon_MP_History_Id}
                                        selected={
                                            selectedGlobalCouponHistIds.indexOf(globalcouponhist.Coupon_MP_History_Id) !== -1
                                        }
                                    >
                                        <TableCell >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{globalcouponhist.Coupon_MP_History_Id}</TableCell>
                                        <TableCell >{globalcouponhist.Code}</TableCell>
                                        <TableCell >{globalcouponhist.Order_Id}</TableCell>
                                        <TableCell >{globalcouponhist.User_Email_Id?.substring(0, 20)}...</TableCell>
                                        <TableCell >{globalcouponhist.Status}</TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Global_Coupon_History_Management_Read?.Value === "Global_Coupon_History_Management_Read" && RolePermissions?.Global_Coupon_History_Management_Read?.Is_Read === 1) ?
                                            <TableCell>
                                                <Tooltip title="Coupon View">
                                                    <Button
                                                        onClick={visibleGlobalCouponHistHandler(globalcouponhist)}
                                                        color="primary" >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            : null}
                                    </TableRow>
                                ))}
                        </TableBody>

                    </Table>
                </Box>
                {isEmpty(allGlobalcouponhist) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <Pagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    allGlobalcouponhist: PropTypes.array.isRequired
};

export default Results;