import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/email-template',
    createdAt: '',
    description:
      'Email templates allow you to customize the formatting and text of emails sent by users who share your content. Templates can be text-only, in which case the users email client will determine which is displayed.',
    media: '/static/Dashboard/email_template.jpg',
    title: 'Email Template',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/setting',
    createdAt: '',
    description:
      'The manner, position, or direction in which something is set. The frame or bed in which a gem is set also style of mounting. The time, place, and circumstances in which something occurs or develops. The time and place of the action of a literary, dramatic, or cinematic work.',
    media: '/static/Dashboard/settings.png',
    title: 'Settings',
    total: ''
  },
];