
import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi} from './commonAxios';

export const PART_BID_COMMENTS_UPDATE_REQUEST = "PART_BID_COMMENTS_UPDATE_REQUEST";
export const PART_BID_COMMENTS_UPDATE_SUCCESS = "PART_BID_COMMENTS_UPDATE_SUCCESS";
export const PART_BID_COMMENTS_UPDATE_FAIL = "PART_BID_COMMENTS_UPDATE_FAIL";

//update Partner Bidding Comments Action
export const updatePartnerBiddingComments = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: PART_BID_COMMENTS_UPDATE_REQUEST
    });
    nodeCudApi.post(`/Bidding_Products_Comment/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: PART_BID_COMMENTS_UPDATE_SUCCESS
                });
                toast('Partner Bidding Comment updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/partner-bidding-comments', state = { state });
            } else {
                toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                dispatch({
                    type: PART_BID_COMMENTS_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: PART_BID_COMMENTS_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};



//Get ALL Bidding Products Quote Comments
export const getBiddingProductsQuoteCommentsAction = (formData, callBackBiddingProductsQuoteComment) => async dispatch => {
    try {
      let { data } = await nodeGetApi.post(
        `/Super_Admin/Bidding_Product_Comments`,
        formData
      );
      if (data) {
        callBackBiddingProductsQuoteComment(data)
      }
    } catch (err) {
    }
  };