import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Avatar
} from '@material-ui/core';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { updatePartnerEmployeeAction, uploadPartnerEmployeeImage } from 'src/store/actions/partneremployeeAction';
import { CircularProgress } from '@mui/material';

const GenderOptions = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Male',
    label: 'Male'
  },
  {
    value: 'Female',
    label: 'Female'
  }
];

const UsertypeOptions = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'PARTNER_ADMIN',
    label: 'PARTNER_ADMIN'
  },
  {
    value: 'PARTNER_EMPLOYEE',
    label: 'PARTNER_EMPLOYEE',
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditPartnerEmployeeDetails = ({ className, state, data, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const [mobile, setMobile] = useState(data.Mobile);

  const isSubmited = useSelector(state => state.partneremployee.partneremployeeUpdate.isLoading);
  const errorMessage = useSelector(state => state.partneremployee.partneremployeeUpdate.error);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);

  // img upload
  const [image, setImage] = useState({ preview: data.Profile_Pic_Path, raw: data.Profile_Pic_Path });

  const [imgUrl, setImgUrl] = useState(data.Profile_Pic_Path);
  const [uploadPic, setUploadPic] = useState(false);

  const callBackUploadImage = data1 => {
    if (data1) {
      setImgUrl(data1.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    let data1 = new FormData();
    data1.append('file', e.target.files[0]);
    dispatch(
      uploadPartnerEmployeeImage(data.Email_Id, new Blob([e.target.files[0]]), callBackUploadImage)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  /*====================================
          Handler
  ====================================*/

  const values = {
    Partner_Details_Id: data.Partner_Details_Id ? data.Partner_Details_Id : "",
    Partner_Employee_Id: data.Partner_Employee_Id ? data.Partner_Employee_Id : "",
    Partner_User_Details_Id: data.Partner_User_Detail_Id ? data.Partner_User_Detail_Id : "",
    First_Name: data.First_Name ? data.First_Name : "",
    Last_Name: data.Last_Name ? data.Last_Name : "",
    // Full_Name: data.Full_Name ? data.Full_Name : "" ,
    DOB: data.DOB ? data.DOB : "",
    Mobile_Number: data.Mobile ? data.Mobile : "",
    User_Type: data.User_Type ? data.User_Type : "",
    Gender: data.Gender ? data.Gender : "",
    Id_Proof_Type: data.ID_Proof_Type ? data.ID_Proof_Type : "",
    Id_Proof_Number: data.ID_Proof_Number ? data.ID_Proof_Number : "",
    Partner_Employee_Email_Id: data.Email_Id ? data.Email_Id : "",
    Is_Active: data.Is_Active > 0 ? true : false,
    Profile_Pic_Path: imgUrl,
    Modified_By: modifiedBy,
  }

  const Form_Validation = Yup.object().shape({
    First_Name: Yup.string().required('First Name is required.'),
    Last_Name: Yup.string().required('Last Name is required.'),
    DOB: Yup.date()
      // .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
      .required("Date of Birth is Required"),
    Mobile_Number: Yup.string().min(14, "Please provide a valid Mobile Number.").required('Mobile Number is required.'),
    User_Type: Yup.string().required('User Type is required.'),
    Gender: Yup.string().required('Gender is required.'),
    Partner_Employee_Id: Yup.string().required('Partner Employee ID is required.'),
    Id_Proof_Number: Yup.string().when('Id_Proof_Type', (Id_Proof_Type, schema) => {
      if (Id_Proof_Type === "Aadhaar Card") {
        return schema.min(12, "Aadhaar Card Number is not valid.").max(12, "Aadhaar Card Number is not valid.").matches(/^[0-9]*$/, "Aadhaar Card Number is not valid.").required("Aadhaar Card Number is required.");
      } else if (Id_Proof_Type === "PAN Card") {
        return schema.matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "PAN Card Number is not valid.").required("PAN Card Number is required.");
      } else if (Id_Proof_Type === "Driving License") {
        return schema.matches(/^([a-zA-Z]){2}([0-9]){14}$/, "Driving License Number is not valid.").required("Driving License Number is required.");
      }
    }),
  })

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};
    values.Profile_Pic_Path = imgUrl;
    // if (!values.Profile_Pic_Path) {
    //   submitTest = false;
    //   errors.Profile_Pic_Path = "Image is required.";
    // }
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      if (values.Is_Active) {
        formValues.Is_Active = 1;
      } else {
        formValues.Is_Active = 0;
      }
      formValues.Modified_By = modifiedBy;
      dispatch(updatePartnerEmployeeAction(formValues, navigate, state));
    }
  }

  return (
    <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Partner_Employee_Id && errors.Partner_Employee_Id)}
                        label="Partner Employee ID"
                        name="Partner_Employee_Id"
                        onChange={handleChange}
                        value={values.Partner_Employee_Id}
                        variant="outlined"
                        helperText={<ErrorMessage name="Partner_Employee_Id" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Partner_Employee_Email_Id && errors.Partner_Employee_Email_Id)}
                        label="Email ID"
                        name="Partner_Employee_Email_Id"
                        onChange={handleChange}
                        value={values.Partner_Employee_Email_Id}
                        variant="outlined"
                        disabled
                        helperText={<ErrorMessage name="Partner_Employee_Email_Id" />}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.First_Name && errors.First_Name)}
                        label="First Name"
                        name="First_Name"
                        onChange={handleChange}
                        value={values.First_Name}
                        variant="outlined"
                        helperText={<ErrorMessage name="First_Name" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Last_Name && errors.Last_Name)}
                        label="Last Name"
                        name="Last_Name"
                        onChange={handleChange}
                        value={values.Last_Name}
                        variant="outlined"
                        helperText={<ErrorMessage name="Last_Name" />}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.User_Type && errors.User_Type)}
                        fullWidth
                        placeholder="User Type"
                        name="User_Type"
                        onChange={handleChange}
                        label="User Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.User_Type}
                        variant="outlined"
                        helperText={<ErrorMessage name="User_Type" />}
                      >
                        {UsertypeOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Gender && errors.Gender)}
                        fullWidth
                        placeholder="Gender"
                        name="Gender"
                        onChange={handleChange}
                        label="Gender"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Gender}
                        variant="outlined"
                        helperText={<ErrorMessage name="Gender" />}
                      >
                        {GenderOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.DOB && errors.DOB)}
                        fullWidth
                        helperText={touched.DOB && errors.DOB}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="date"
                        label="Date of Birth"
                        name="DOB"
                        value={values.DOB}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <PhoneInput
                        containerClass={touched.Mobile_Number && errors.Mobile_Number ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                        inputClass={"form-control MuiOutlinedInput-input"}
                        error={true}
                        country={"in"}
                        fullWidth
                        inputStyle={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px"
                        }}
                        inputProps={{
                          name: "Mobile_Number",
                        }}
                        specialLabel="Mobile Number"
                        name="Mobile_Number"
                        onBlur={handleBlur}
                        placeholder="+91 XXXXX XXXXX"
                        onChange={(value, country, e, formattedValue) => {
                          setFieldValue("Mobile_Number", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                          setMobile(formattedValue)
                        }}
                        value={mobile}
                      />
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Mobile_Number && errors.Mobile_Number}</p>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Id_Proof_Type && errors.Id_Proof_Type)}
                        fullWidth
                        helperText={touched.Id_Proof_Type && errors.Id_Proof_Type}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          handleChange(e);
                          setFieldValue("Id_Proof_Number", "");
                        }}
                        label="ID Proof Type"
                        name="Id_Proof_Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Id_Proof_Type}
                        variant="outlined"
                      >
                        <option value="" >
                          -Please Select-
                        </option>
                        <option value="Aadhaar Card" >
                          Aadhaar Card
                        </option>
                        <option value="PAN Card" >
                          PAN Card
                        </option>
                        <option value="Driving License" >
                          Driving License
                        </option>

                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Id_Proof_Number && errors.Id_Proof_Number)}
                        fullWidth
                        disabled={values.Id_Proof_Type?.length <= 0}
                        helperText={touched.Id_Proof_Number && errors.Id_Proof_Number}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: values.Id_Proof_Type === "Aadhaar Card" ? 12 : values.Id_Proof_Type === "Driving License" ? 16 : values.Id_Proof_Type === "PAN Card" ? 10 : ''
                        }}
                        label={values.Id_Proof_Type ? values.Id_Proof_Type + " Number" : "ID Proof Number"}
                        name="Id_Proof_Number"
                        value={values.Id_Proof_Number}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Active}
                            onChange={handleChange}
                            name="Is_Active"
                            color="primary"
                          />
                        }
                        label="Is Active"
                      />
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item md={4} xs={12}>
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Profile pic Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Profile pic Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                  <span style={{ color: "red" }}>
                    {!imgUrl && touched.Profile_Pic_Path && errors.Profile_Pic_Path}
                  </span>
                </Grid>

              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited || uploadPic}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default EditPartnerEmployeeDetails;