import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import EditContentManagement from './EditContentManagement';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const EditContentManagementView = () => {
    const classes = useStyles();
    return (
        <Page className={classes.root} title="Content Management">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/contents-management"
                    variant="text"
                    size="small"
                    color="inherit"
                >
                    <KeyboardBackspaceIcon />
          Go back
        </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        Edit Content
          </Typography>
                </Box>
                <EditContentManagement/>
      </Container>
        </Page>
    );
};

export default EditContentManagementView;
