import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));

const headCells = [
  { id: 'Reference_Id', numeric: false, disablePadding: false, label: 'Reference ID' },
  { id: 'Name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'User_Email_Id', numeric: false, disablePadding: false, label: 'User Email ID' },
  { id: 'Amount', numeric: true, disablePadding: false, label: 'Amount' },
  { id: 'Created_Date', numeric: false, disablePadding: false, label: 'Created Date' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allReferandEarn,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  allReferandEarnNoFilter,
  createSortHandler,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedReferandEarnIds, setSelectedReferandEarnIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedReferandEarnIds;
    if (event.target.checked) {
      newSelectedReferandEarnIds = allReferandEarn.map(referandearn => referandearn.Reference_Id);
    } else {
      newSelectedReferandEarnIds = [];
    }
    setSelectedReferandEarnIds(newSelectedReferandEarnIds);
  };

  const handleSelectOne = (event, id) => {
    setSelectedReferandEarnIds(selectedReferandEarnIds.filter(record => {
      for (let i = 0; i < allReferandEarnNoFilter.length; i++) {
        if (record == allReferandEarnNoFilter[i].Reference_Id) {
          return true;
        }
      }
      return false;
    }))

    const selectedIndex = selectedReferandEarnIds.indexOf(id);
    let newSelectedReferandEarnIds = [];
    if (selectedIndex === -1) {
      newSelectedReferandEarnIds = newSelectedReferandEarnIds.concat(selectedReferandEarnIds, id);
    } else if (selectedIndex === 0) {
      newSelectedReferandEarnIds = newSelectedReferandEarnIds.concat(
        selectedReferandEarnIds.slice(1)
      );
    } else if (selectedIndex === selectedReferandEarnIds.length - 1) {
      newSelectedReferandEarnIds = newSelectedReferandEarnIds.concat(
        selectedReferandEarnIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedReferandEarnIds = newSelectedReferandEarnIds.concat(
        selectedReferandEarnIds.slice(0, selectedIndex),
        selectedReferandEarnIds.slice(selectedIndex + 1)
      );
    }
    setSelectedReferandEarnIds(newSelectedReferandEarnIds);
  };

  const deleteSelectedReferandEarnHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const visibleReferandEarnHandler = referandearnData => e => {
    navigate("/app/refer-earn-history", { state: referandearnData });
  };

  const EditReferandEarnHandler = referandearnData => e => {
    navigate("/app/edit-refer-earn", { state: referandearnData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedReferandEarnIds.filter(record => {
                      for (let i = 0; i < allReferandEarnNoFilter.length; i++) {
                        if (record == allReferandEarnNoFilter[i].Reference_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allReferandEarn.length}
                    color="primary"
                    indeterminate={
                      selectedReferandEarnIds.filter(record => {
                        for (let i = 0; i < allReferandEarnNoFilter.length; i++) {
                          if (record == allReferandEarnNoFilter[i].Reference_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedReferandEarnIds.filter(record => {
                        for (let i = 0; i < allReferandEarnNoFilter.length; i++) {
                          if (record == allReferandEarnNoFilter[i].Reference_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allReferandEarn.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedReferandEarnIds.filter(record => {
                    for (let i = 0; i < allReferandEarnNoFilter.length; i++) {
                      if (record == allReferandEarnNoFilter[i].Reference_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedReferandEarnIds.filter(record => {
                    for (let i = 0; i < allReferandEarnNoFilter.length; i++) {
                      if (record == allReferandEarnNoFilter[i].Reference_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedReferandEarnHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allReferandEarn) &&
                (allReferandEarn).map((referandearn,i) => (
                  <TableRow
                    hover
                    key={i}
                    selected={
                      selectedReferandEarnIds.indexOf(referandearn.Reference_Id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedReferandEarnIds.indexOf(referandearn.Reference_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, referandearn.Reference_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell>{referandearn.Reference_Id}</TableCell>
                    <TableCell>{referandearn.Name}</TableCell>
                    <TableCell style={{ color: '#1665D8' }} >
                      {referandearn.User_Email_Id?.substring(0, 15)}...
                    </TableCell>
                    <TableCell>{referandearn.Amount}</TableCell>
                    <TableCell>{referandearn.Created_Date}</TableCell>
                    <TableCell>{referandearn.Status}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Refer_Earn_Write?.Value === "Refer_Earn_Write" && RolePermissions?.Refer_Earn_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Tooltip title="Refer&Earn History">
                          <Button
                            onClick={visibleReferandEarnHandler(referandearn)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        <Button
                          onClick={EditReferandEarnHandler(referandearn)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Refer_Earn_Read?.Value === "Refer_Earn_Read" && RolePermissions?.Refer_Earn_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="Refer&Earn History">
                          <Button
                            onClick={visibleReferandEarnHandler(referandearn)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allReferandEarn) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allReferandEarn: PropTypes.array.isRequired
};

export default Results;