import {
  CORP_PROFILES_FAIL,
  CORP_PROFILES_REQUEST,
  CORP_PROFILES_SUCCESS,
  CORP_TICKET_CREATE_FAIL,
  CORP_TICKET_CREATE_REQUEST,
  CORP_TICKET_CREATE_SUCCESS,
  CORP_TICKET_GET_FAIL,
  CORP_TICKET_GET_REQUEST,
  CORP_TICKET_GET_SUCCESS,
  CORP_TICKET_UPDATE_FAIL,
  CORP_TICKET_UPDATE_REQUEST,
  CORP_TICKET_UPDATE_SUCCESS
} from "../actions/corporateticketAction";

const initialState = {
  corpticket: { corpticket: {}, error: '', isLoading: false },
  corpticketUpdate: { corpticket: {}, error: '', isLoading: false },
  corpticketCreate: { corpticket: {}, error: '', isLoading: false },
  corpusers: { users: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {

    case CORP_TICKET_GET_REQUEST:
      return { ...state, corpticket: { corpticket: {}, error: '', isLoading: true } };
    case CORP_TICKET_GET_SUCCESS:
      return { ...state, corpticket: { corpticket: action.payload, error: '', isLoading: false } };
    case CORP_TICKET_GET_FAIL:
      return { ...state, corpticket: { corpticket: {}, error: action.payload, isLoading: false } };
    case CORP_TICKET_UPDATE_REQUEST:
      return { ...state, corpticketUpdate: { corpticket: {}, error: '', isLoading: true } };
    case CORP_TICKET_UPDATE_SUCCESS:
      return { ...state, corpticketUpdate: { corpticket: state.corpticketUpdate.corpticket, error: '', isLoading: false } };
    case CORP_TICKET_UPDATE_FAIL:
      return { ...state, corpticketUpdate: { corpticket: {}, error: action.payload, isLoading: false } };
    case CORP_TICKET_CREATE_REQUEST:
      return { ...state, corpticketCreate: { corpticket: {}, error: '', isLoading: true } };
    case CORP_TICKET_CREATE_SUCCESS:
      return { ...state, corpticketCreate: { corpticket: state.corpticketCreate.corpticket, error: '', isLoading: false } };
    case CORP_TICKET_CREATE_FAIL:
      return { ...state, corpticketCreate: { corpticket: {}, error: action.payload, isLoading: false } };
    case CORP_PROFILES_REQUEST:
      return { ...state, corpusers: { users: state.corpusers.users, error: '', isLoading: true }, };
    case CORP_PROFILES_SUCCESS:
      return { ...state, corpusers: { users: action.payload, error: '', isLoading: false } };
    case CORP_PROFILES_FAIL:
      return { ...state, corpusers: { users: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}