import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Checkbox,
    FormControlLabel,
    Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";
import { deleteMPProductCombo, getAllMeepaisaComboProducts, getAllMeepaisaProducts, updateMPProductComboAction } from 'src/store/actions/meepaisaproductcomboAction';

const EditMPProductCombo = ({ className, state, data, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isSubmited = useSelector(state => state.meepaisaproductcombo.productcomboUpdate.isLoading);
    const errorMessage = useSelector(state => state.meepaisaproductcombo.productcomboUpdate.error);
    const allProducts = useSelector(state => state.meepaisaproductcombo.meepaisaProducts.all);
    const allComboProducts = useSelector(state => state.meepaisaproductcombo.meepaisaComboProducts.all);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    const [product, setProduct] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        const formData1 = {
            "search_by_filter": "",
            "search": "",
            "Base_Product_Id": data.Base_Product_Id,
            "Records_Filter": "FILTER"
        };
        dispatch(getAllMeepaisaComboProducts(formData1));
        dispatch(getAllMeepaisaProducts());
        if (!dataLoaded) {
            // Simulate an API call or async operation (replace with your actual logic)
            setTimeout(() => {
                const response = allComboProducts;
                setProduct(response); // Update the state with the response data
                setDataLoaded(true); // Set dataLoaded to true to prevent further calls
            }, 1000); // Simulate a delay for illustration
        }
    }, [dataLoaded, product]);

    const subproducts = product.map((option) => ({
        Combo_Product_Id: option.Combo_Product_Id,
        Sub_Product_Id: option.Product_Id
    }))

    const deleteProductAction = (value) => {
        if (value) {
            const deleteData = {
                Combo_Products_Id: value.Combo_Product_Id,
                Records_Filter: "H"
            };
            const deleteData1 = {
                "search_by_filter": "",
                "search": "",
                "Base_Product_Id": data.Base_Product_Id,
                "Records_Filter": "FILTER"
            };
            dispatch(deleteMPProductCombo(deleteData, deleteData1));
        }
    };

    const handleAutocompleteChange = (event, newValue) => {
        setProduct(newValue);
    };

    return (
        <Formik
            initialValues={{
                Base_Product_Id: data.Base_Product_Id ? data.Base_Product_Id : "",
                Is_Active: data.Is_Active > 0 ? true : false,
                Modified_By: loginEmail,
            }}
            onSubmit={(values) => {
                let submitTest = true;
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    if (values.Is_Active) {
                        formValues.Is_Active = 1;
                    } else {
                        formValues.Is_Active = 0;
                    }
                    const formData = {
                        Base_Product_Id: values.Base_Product_Id,
                        Is_Active: formValues.Is_Active,
                        Modified_By: loginEmail,
                        Sub_Products: subproducts
                    };

                    let formValues1 = JSON.stringify(formData);
                    dispatch(updateMPProductComboAction(formValues1, navigate, state));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={12} xs={12}>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                gutterBottom
                                                style={{ color: '#66788A' }}
                                            >
                                                Base Product Name: {data.Base_Product_Name ? data.Base_Product_Name : ""}
                                            </Typography>
                                        </Grid>

                                        {/* Is_Active */}
                                        <Grid item md={12} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.Is_Active}
                                                        onChange={handleChange}
                                                        name="Is_Active"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Active"
                                            />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <Autocomplete
                                                multiple
                                                id="checkboxes-tags-demo"
                                                options={allProducts}
                                                disableCloseOnSelect
                                                groupBy={(option) => option.firstLetter}
                                                variant="outlined"
                                                value={product}
                                                onChange={handleAutocompleteChange}
                                                getOptionLabel={(option) => option.Product_Name}
                                                getOptionSelected={(option, value) => option.Product_Name === value.Product_Name}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <div key={index} {...getTagProps({ index })}
                                                            style={{ border: "1px solid grey", color: '#000000DE', fontSize: '13px', padding: '0px 12px', borderRadius: "20px", backgroundColor: '#E0E0E0' }}
                                                        >
                                                            {option.Product_Name}
                                                            <HighlightOffIcon variant="outlined" cursor="pointer" style={{ size: "22px", paddingTop: "7px" }}
                                                                onClick={() => {
                                                                    deleteProductAction(option);
                                                                    const updatedValues = product.filter((value) => value !== option);
                                                                    setProduct(updatedValues);
                                                                }}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                                renderOption={(props, option) => (
                                                    <div {...props}>
                                                        <Checkbox
                                                            checked={option.selected}
                                                        />
                                                        {props.Product_Name}
                                                    </div>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={Boolean(touched.Sub_Products && errors.Sub_Products)}
                                                        helperText={touched.Sub_Products && errors.Sub_Products}
                                                        onBlur={handleBlur}
                                                        label="Sub Products"
                                                        name="Sub_Products"
                                                        variant="outlined"
                                                        onChange={() => {
                                                            dispatch(getAllMeepaisaProducts(params.inputProps.value));
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off', // disable autocomplete and autofill
                                                            startAdornment: (
                                                                <React.Fragment>
                                                                    <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                    {params.InputProps.startAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>

                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" pl={2} pt={1} pb={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditMPProductCombo.propTypes = {
    className: PropTypes.string
};

export default EditMPProductCombo;