
import config from './config';
import { toast } from 'react-toastify';
import {  nodeGetApi,boomiAdminApi } from './commonAxios';

export const WALLET_PARTNER_HISTORY_REQUEST = "WALLET_PARTNER_HISTORY_REQUEST";
export const WALLET_PARTNER_HISTORY_SUCCESS = "WALLET_PARTNER_HISTORY_SUCCESS";
export const WALLET_PARTNER_HISTORY_FAIL = "WALLET_PARTNER_HISTORY_FAIL";
export const WALLET_PARTNER_HISTORY_GET_REQUEST = "WALLET_PARTNER_HISTORY_GET_REQUEST";
export const WALLET_PARTNER_HISTORY_GET_FAIL = "WALLET_PARTNER_HISTORY_GET_FAIL";
export const WALLET_PARTNER_HISTORY_GET_SUCCESS = "WALLET_PARTNER_HISTORY_GET_SUCCESS";
export const WALLET_HISTORY_PARTNER_CREATE_REQUEST = "WALLET_HISTORY_PARTNER_CREATE_REQUEST";
export const WALLET_HISTORY_PARTNER_CREATE_SUCCESS = "WALLET_HISTORY_PARTNER_CREATE_SUCCESS";
export const WALLET_HISTORY_PARTNER_CREATE_FAIL = "WALLET_HISTORY_PARTNER_CREATE_FAIL";
export const WALLET_HISTORY_PARTNER_UPDATE_REQUEST = "WALLET_HISTORY_PARTNER_UPDATE_REQUEST";
export const WALLET_HISTORY_PARTNER_UPDATE_SUCCESS = "WALLET_HISTORY_PARTNER_UPDATE_SUCCESS";
export const WALLET_HISTORY_PARTNER_UPDATE_FAIL = "WALLET_HISTORY_PARTNER_UPDATE_FAIL";


/*=============================================================
             get All Wallethistory Action
===============================================================*/
export const getAllPartnerWallethistoryAction = formData => async dispatch => {
  try {
    dispatch({
      type: WALLET_PARTNER_HISTORY_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partner_Wallet/Wallet_History_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=WALLET_HISTORY_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: WALLET_PARTNER_HISTORY_SUCCESS,
          payload: [],
          response: [0]
        });
      } else {
        dispatch({
          type: WALLET_PARTNER_HISTORY_SUCCESS,
          payload: data.results,
          response: data
        });
      }
    }
  } catch (err) {
    dispatch({
      type: WALLET_PARTNER_HISTORY_FAIL,
      payload: err
    });
  }
};

// Add Partner Wallet History Action
export const createPartnerWalletHistoryAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: WALLET_HISTORY_PARTNER_CREATE_REQUEST
  });
  boomiAdminApi.post(`/Partner_Wallet/Partner_Wallet_History_Create`, formData,
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: WALLET_HISTORY_PARTNER_CREATE_SUCCESS
        });
        toast('Partner Wallet History added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partnerwallethistory-management', state = { state });
      } else {
        dispatch({
          type: WALLET_HISTORY_PARTNER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: WALLET_HISTORY_PARTNER_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};


// Update Partner Wallet History Action
export const updatepartnerWalletHistoryAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: WALLET_HISTORY_PARTNER_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Partner_Wallet/Partner_Wallet_History_Update`, formData

  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: WALLET_HISTORY_PARTNER_UPDATE_SUCCESS
        });
        toast('Partner Wallet History updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partnerwallethistory-management', state = { state });
      } else {
        dispatch({
          type: WALLET_HISTORY_PARTNER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: WALLET_HISTORY_PARTNER_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

// Get Partner WalletHistory Action
export const getPartnerWalletHistoryAction = (formData, callBackPartnerWalletHistoryData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Partner_Wallet/Wallet_History_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Wallet_History_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBackPartnerWalletHistoryData(data)
    }
  } catch (err) {
  }
};