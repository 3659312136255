
import config from './config';
import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi,boomiAdminApi } from './commonAxios';


export const SERVICES_PARTNER_GENERIC_CREATE_REQUEST = "SERVICES_PARTNER_GENERIC_CREATE_REQUEST";
export const SERVICES_PARTNER_GENERIC_CREATE_SUCCESS = "SERVICES_PARTNER_GENERIC_CREATE_SUCCESS";
export const SERVICES_PARTNER_GENERIC_CREATE_FAIL = "SERVICES_PARTNER_GENERIC_CREATE_FAIL";

export const SERVICES_PARTNER_GENERIC_UPDATE_REQUEST = "SERVICES_PARTNER_GENERIC_UPDATE_REQUEST";
export const SERVICES_PARTNER_GENERIC_UPDATE_SUCCESS = "SERVICES_PARTNER_GENERIC_UPDATE_SUCCESS";
export const SERVICES_PARTNER_GENERIC_UPDATE_FAIL = "SERVICES_PARTNER_GENERIC_UPDATE_FAIL";
export const SERVICES_PARTNER_GENERIC_FILTER = "SERVICES_PARTNER_GENERIC_FILTER";
export const PARTNERS_BUSINESSTYPE_REQUEST = "PARTNERS_BUSINESSTYPE_REQUEST";
export const PARTNERS_BUSINESSTYPE_SUCCESS = "PARTNERS_BUSINESSTYPE_SUCCESS";
export const PARTNERS_BUSINESSTYPE_FAIL = "PARTNERS_BUSINESSTYPE_FAIL";
export const CITIES_REQUEST = "CITIES_REQUEST";
export const CITIES_SUCCESS = "CITIES_SUCCESS";
export const CITIES_FAIL = "CITIES_FAIL";


/*=============================================================
                  Add Partner Service Action
===============================================================*/
export const addgenericServiceAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: SERVICES_PARTNER_GENERIC_CREATE_REQUEST
  });
  let { data } = await nodeCudApi.post(`/User_Service/Create`,
    formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICES_PARTNER_GENERIC_CREATE_SUCCESS
        });
        toast('Partner Services added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/genericservices-management');
      } else {
        dispatch({
          type: SERVICES_PARTNER_GENERIC_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: SERVICES_PARTNER_GENERIC_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};
/*=============================================================
                  Update Partner Service Action
===============================================================*/
export const updategenericServiceAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: SERVICES_PARTNER_GENERIC_UPDATE_REQUEST
  });
  nodeCudApi.post(`/User_Service/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICES_PARTNER_GENERIC_UPDATE_SUCCESS
        });
        toast('Partner Services updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/genericservices-management');
      } else {

        dispatch({
          type: SERVICES_PARTNER_GENERIC_UPDATE_SUCCESS,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: SERVICES_PARTNER_GENERIC_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};
/*=============================================================
               Image Upload Action
===============================================================*/
export const uploadPartnerServiceImage = (
  formData,
  callBackUploadImgae
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=PartnerServiceCity&fileextension=png&filetype=Images&filename=PartnerServiceCity`,
      formData
    );
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) {
  }
};

/*=============================================================
            Get All partner city names Action
 ===============================================================*/
export const getAllcities = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      City_Id: "",
    };
    dispatch({
      type: CITIES_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Super_Admin_City/Super_Admin_City_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=CITY_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CITIES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CITIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CITIES_FAIL,
      payload: JSON.stringify(err)
    });
  }
};

//Get Partner Generic Services Action
export const getPartnerGenericServicesAction = (formData, callBackPartnerGenericServicesData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/User_Service/Generic_GET?PageNo=1&PageSize=${config.pageSize}&SortBy=SERVICE_NAME&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBackPartnerGenericServicesData(data)
    }
  } catch (err) {
  }
};