import { v4 as uuid } from 'uuid';

export default [
    {
        id: uuid(),
        hide: 0,
        link: '/app/global-coupon-management',
        createdAt: '',
        description:
            'Global Coupon Management contains all the data related to Coupons which are used while placing Orders.',
        media: '/static/Dashboard/catergory.png',
        title: 'Global Coupon Management',
        total: ''
    },
];