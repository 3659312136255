import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { AddStoretypepartnerlinkingAction, getAllPartners } from 'src/store/actions/storetypepartnerlinkingActions';
import { CircularProgress } from '@mui/material';

const AddStoreTypePartnerLink = ({ className, state, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState({ Partner_Detail_Id: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.storetypepartnerlink.storetypepartnerlinkCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const partners = useSelector(state => state.storetypepartnerlink.Partners.partners || []);

  /// componentDidMount
  useEffect(() => {
    dispatch(getAllPartners());
  }, []);

  return (
    <Formik
      initialValues={{
        Store_Type_Name: state?.Store_Type_Name,
        Partner_Detail_Id: '',
        Created_By: loginEmail,
        Is_Active: false,
      }}
      validationSchema={Yup.object().shape({
        Store_Type_Name: Yup.string().required('Store Type Name is required.'),
        Partner_Detail_Id: Yup.string().notOneOf([errorMessage.Partner_Detail_Id.key, null], errorMessage.Partner_Detail_Id.message).required('Partner Name is required.'),
      })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        const errors = {};
        setSubmitting(false);
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          if (values.Is_Active) {
            formValues.Is_Active = 1;
          } else {
            formValues.Is_Active = 0;
          }
          dispatch(AddStoretypepartnerlinkingAction(formValues, navigate, state, setErrorMessage, setErrors));
        }

      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Store_Type_Name && errors.Store_Type_Name)}
                        fullWidth
                        helperText={touched.Store_Type_Name && errors.Store_Type_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Store Type Name"
                        name="Store_Type_Name"
                        value={values.Store_Type_Name}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {/* Partner_Detail_Id */}
                      <TextField
                        error={Boolean(touched.Partner_Detail_Id && errors.Partner_Detail_Id)}
                        fullWidth
                        helperText={touched.Partner_Detail_Id && errors.Partner_Detail_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Partner Name"
                        name="Partner_Detail_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Partner_Detail_Id}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {partners?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                          <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                            {option.Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Is_Active */}
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Active}
                            onChange={handleChange}
                            name="Is_Active"
                            color="primary"
                          />
                        }
                        label="Is Active"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2}>
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>

            <Box display="flex" pl={2} pt={1} pb={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddStoreTypePartnerLink.propTypes = {
  className: PropTypes.string
};

export default AddStoreTypePartnerLink;