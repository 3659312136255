import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllrazorpaydetailsAction,
  razorpayFilter,
} from './../../store/actions/razorpayAction';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const RazorpayTransactionsView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  //permissions
  const RolePermissions = useSelector(state => state.rolepermission.Roles);
  const roleUserType = useSelector(state => state.auth.user);
  const Login_Details = useSelector(state => state.auth.user);
 

  // const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = useState({ PageNo: 1, PageSize: 10, SortBy: "Id", SortOrder: "ASC" })
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
 

  useEffect(() => {
    dispatch(razorpayFilter('search', ''));
    dispatch(razorpayFilter('select', 'ALL'));
    dispatch(getRolePermissions(Login_Details));
    let data ={
      Records_Filter: 'ALL',
    }
    dispatch(getAllrazorpaydetailsAction(data ,pagination))
    setPagination(pagination)
  }, [pagination]);

  const handlePageFirst = (value) => {
    setPagination({ ...pagination, PageSize: pagination.PageSize, PageNo: value });
  };

  const handlePagePreview = (value) => {
    setPagination({ ...pagination, PageSize: pagination.PageSize, PageNo: value - 1 });
  };

  const handlePageNext = (value) => {
    setPagination({ ...pagination, PageSize: pagination.PageSize, PageNo: value + 1 });
  };

  let razorpayMenu = useSelector(state => state.razorpay.razorpaytransactionFilter.select);
  let razorpaySearch = useSelector(state => state.razorpay.razorpaytransactionFilter.search);

  //static getDerivedStateFromProps
  const allRazorpaytransactions = useSelector(state => state.razorpay.razorpays.all);

  const allData = allRazorpaytransactions?.Transactions || []
  const [oldSearch, setOldSearch] = useState(0);
  const checkRazorpayPaymnetsForTable = () => {
    if (razorpaySearch) {
      try {
        if (razorpayMenu === "ALL") {
          return allData.filter(x => {
            let colummns = ["Id", "Amount", "contact", "email", "method"];
            for (let i = 0; i < colummns.length; i++) {
              const mainString = typeof x[colummns[i]] === "string" ? x[colummns[i]]?.toLowerCase() : x[colummns[i]];
              if (typeof x[colummns[i]] === "string") {
                if (mainString.includes(razorpaySearch?.toLowerCase())) {
                  return true;
                }
              }
              else {
                if (mainString === razorpaySearch) {
                  return true;
                }
              }
            }
            return false;
          });
        } else {
          return allData.filter(x => {
            let mainString = typeof x[razorpayMenu] == "string" ? x[razorpayMenu]?.toLowerCase() : x[razorpayMenu];
            let subString = typeof x[razorpayMenu] == "string" ? razorpaySearch?.toLowerCase() : razorpaySearch;
            if (typeof x[razorpayMenu] === "string") {
              return mainString.includes(subString);
            }
            else {
              if (mainString === razorpaySearch) {
                return true;
              }
            }
          })
        }
      } finally {
        if (page > 0) {
          if (oldSearch !== razorpaySearch.length) {
            setOldSearch(razorpaySearch.length);
            setPage(0);
          }
        }
        else if (oldSearch !== razorpaySearch.length) {
          setOldSearch(razorpaySearch.length);
          setPage(0);
        }
      }
    }
    else {
      return allData
    }
  }

  // Sorting 
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setPagination({ PageNo: 1, PageSize: pagination?.PageSize, SortBy: property, SortOrder: order === 'asc' ? "ASC" : "DESC" })
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  function descendingComparator(a, b, orderBy) {
    try {
      if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
        return -1;
      }
      if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
        return 1;
      }
      return 0;
    } catch (error) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {

      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/razorpaypayments-maincentre"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Razorpay Payments Transactions
          </Typography>
        </Box>
        <Toolbar />
        <Box mt={3}>
          <Results
            allRazorpaytransactions={stableSort(
              checkRazorpayPaymnetsForTable(), getComparator(order, orderBy))
            }
            handlePageFirst={handlePageFirst}
            handlePagePreview={handlePagePreview}
            handlePageNext={handlePageNext}
            allRazorpaytransaction={allData}
            page={page}
            limit={limit}
            pagination={pagination}
            order={order}
            orderBy={orderBy}
            createSortHandler={createSortHandler}
            RolePermissions={RolePermissions}
            roleUserType={roleUserType}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default RazorpayTransactionsView;