import {
  MEEPAISA_ZONE_CREATE_REQUEST,
  MEEPAISA_ZONE_CREATE_SUCCESS,
  MEEPAISA_ZONE_CREATE_FAIL,
  MEEPAISA_ZONE_UPDATE_REQUEST,
  MEEPAISA_ZONE_UPDATE_SUCCESS,
  MEEPAISA_ZONE_UPDATE_FAIL,
} from '../actions/meepaisazoneAction'

const initialState = {
  zoneCreate: { zone: {}, error: '', isLoading: false },
  zoneUpdate: { zone: {}, error: '', isLoading: false },
};
export default function (state = initialState, action) {
  switch (action.type) {
    
    case MEEPAISA_ZONE_CREATE_REQUEST:
      return { ...state, zoneCreate: { zone: {}, error: '', isLoading: true } };
    case MEEPAISA_ZONE_CREATE_SUCCESS:
      return { ...state, zoneCreate: { zone: action.payload, error: '', isLoading: false } };
    case MEEPAISA_ZONE_CREATE_FAIL:
      return { ...state, zoneCreate: { zone: {}, error: action.payload, isLoading: false } };

    case MEEPAISA_ZONE_UPDATE_REQUEST:
      return { ...state, zoneUpdate: { zone: {}, error: '', isLoading: true } };
    case MEEPAISA_ZONE_UPDATE_SUCCESS:
      return { ...state, zoneUpdate: { zone: action.payload, error: '', isLoading: false } };
    case MEEPAISA_ZONE_UPDATE_FAIL:
      return { ...state, zoneUpdate: { zone: {}, error: action.payload, isLoading: false } };

    
    default:
      return state;
  }
}
