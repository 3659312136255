import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box
} from '@material-ui/core';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAllUserRideComforfttypeAction, updateUserRideFareAction } from 'src/store/actions/userridefaresAction';
import { getAllPartnercitynames } from 'src/store/actions/citypartnerAction';
import { CircularProgress } from '@mui/material';

const Status = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const EditUserRideFares = ({ className, ...rest }) => {
  const location = useLocation();
  let { state } = location;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // componentDidMount
  useEffect(() => {
    const formData =
    {
      "Records_Filter": "FILTER"
    }; const formData1 =
    {
      "Records_Filter": "ALL"
    };
    dispatch(getAllUserRideComforfttypeAction(formData1));
    dispatch(getAllPartnercitynames(formData));
  }, []);

  const allUserRides = useSelector(state => state.userrideFares.userrideFare.userrideFare);
  const Cityname = useSelector(state => state.citypartner.citypartnercitynames.citynames);
  const isSubmited = useSelector(state => state.userrideFares.userrideFareUpdate.isLoading);
  const errorMessage = useSelector(state => state.userrideFares.userrideFareUpdate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  const values = {
    User_Ride_Vehicle_Fare_ID: state?.User_Ride_Vehicle_Fare_Id ? state?.User_Ride_Vehicle_Fare_Id : "",
    City_Id: state?.City_Id ? state?.City_Id : "",
    Ride_Vehicle_Type_Id: state?.Ride_Vehicle_Type_Id ? state?.Ride_Vehicle_Type_Id : "",
    Minimum_Fare: state?.Minimum_Fare ? state?.Minimum_Fare : "",
    Regular_Fare_Per_Km: state?.Regular_Fare_Per_Km ? state?.Regular_Fare_Per_Km : "",
    Seating_Capacity: state?.Seating_Capacity ? state?.Seating_Capacity : "",
    Waiting_Charge_Per_Minute: state?.Waiting_Charger_Per_Minute ? state?.Waiting_Charger_Per_Minute : "",
    Status: state?.Status ? state?.Status : "",
    Modified_BY: loginEmail,
  }
  const Form_Validation = Yup.object().shape({
    City_Id: Yup.string().required('City Name is required.'),
    Ride_Vehicle_Type_Id: Yup.string().required('Vehicle Type is required.'),
    Minimum_Fare: Yup.number().min(0, "Minimum Fare should not be less than 0.").required('Minimum Fare is required.'),
    Regular_Fare_Per_Km: Yup.number().min(0, "Regular Fare should not be less than 0.").required('Regular Fare Per Km is required.'),
    Seating_Capacity: Yup.number().min(0, "Seating Capacity should not be less than 0.").required('Seating Capacity is required.'),
    Waiting_Charge_Per_Minute: Yup.number().min(0, "Waiting Charge should not be less than 0.").required('Waiting Charge Per Minute is required.'),
    Status: Yup.string().required('Status is required.')
  })

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      dispatch(updateUserRideFareAction(formValues, navigate));
    }
  }

  return (
    <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
      }) => (
        <Form>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.City_Id && errors.City_Id)}
                        fullWidth
                        placeholder="City Name"
                        name="City_Id"
                        onChange={handleChange}
                        label="City Name"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.City_Id}
                        variant="outlined"
                        helperText={<ErrorMessage name="City_Id" />}
                      >
                        <option key="" value="">--Please Select--</option>
                        {Cityname?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                          <option key={option.City_Id} value={option.City_Id}>
                            {option.Name + " " + "-" + " " + option.State + " " + "-" + " " + option.Country}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Ride_Vehicle_Type_Id && errors.Ride_Vehicle_Type_Id)}
                        fullWidth
                        placeholder="Vehicle Type"
                        name="Ride_Vehicle_Type_Id"
                        onChange={handleChange}
                        label="Vehicle Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Ride_Vehicle_Type_Id}
                        variant="outlined"
                        helperText={<ErrorMessage name="Ride_Vehicle_Type_Id" />}
                      >
                        <option key="" value="">--Please Select--</option>
                        {allUserRides?.sort((a, b) => a.Vehicle_Type?.localeCompare(b.Vehicle_Type))?.map(option => (
                          <option key={option.Ride_Vehicle_Type_Id} value={option.Ride_Vehicle_Type_Id}>
                            {option.Vehicle_Type + " - " + option.Vehicle_Comfort_Type}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Minimum_Fare && errors.Minimum_Fare)}
                        fullWidth
                        helperText={touched.Minimum_Fare && errors.Minimum_Fare}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Minimum Fare"
                        name="Minimum_Fare"
                        type="number"
                        value={values.Minimum_Fare}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Regular_Fare_Per_Km && errors.Regular_Fare_Per_Km)}
                        fullWidth
                        helperText={touched.Regular_Fare_Per_Km && errors.Regular_Fare_Per_Km}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Regular Fare Per Km"
                        name="Regular_Fare_Per_Km"
                        type="number"
                        value={values.Regular_Fare_Per_Km}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Seating_Capacity && errors.Seating_Capacity)}
                        fullWidth
                        helperText={touched.Seating_Capacity && errors.Seating_Capacity}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Seating Capacity"
                        name="Seating_Capacity"
                        type="number"
                        value={values.Seating_Capacity}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Waiting_Charge_Per_Minute && errors.Waiting_Charge_Per_Minute)}
                        fullWidth
                        helperText={touched.Waiting_Charge_Per_Minute && errors.Waiting_Charge_Per_Minute}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Waiting Charge Per Minute"
                        name="Waiting_Charge_Per_Minute"
                        type="number"
                        value={values.Waiting_Charge_Per_Minute}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        placeholder="Status"
                        name="Status"
                        onChange={handleChange}
                        label="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        helperText={<ErrorMessage name="Status" />}
                      >
                        {Status.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default EditUserRideFares;