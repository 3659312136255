import React from 'react';
import {
  Box,
  InputAdornment,
  SvgIcon,
  TextField,
  makeStyles,
  Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Search as SearchIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  formFilterControl: {
    margin: theme.spacing(1),
    minWidth: 200
  }
}));

const Toolbar = ({ className, state, RolePermissions, roleUserType, filters, setfilters, ...rest }) => {
  const classes = useStyles();
 
   const handleChange = event => {
    setfilters({
      search: filters?.search,
      search_by_filter: event.target.value,
      Records_Filter: 'FILTER',
      Partner_Details_Id: state.Partner_Details_Id
    })
  };


  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" flexWrap="wrap" mb={2}>
          <FormControl variant="outlined"
            className={classes.formFilterControl}>
            <Select
               value={filters.search_by_filter}
              onChange={handleChange}

              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="ALL">All</MenuItem>
              <MenuItem value="Partner_Address_Id">Partner Address ID</MenuItem>
              <MenuItem value="Address_Type">Address Type</MenuItem>
              <MenuItem value="State">State</MenuItem>
              <MenuItem value="Country">Country</MenuItem>
              <MenuItem value="Zip">Pin Code</MenuItem>
              <MenuItem value="Is_Shipping_Available">Is Shipping Available</MenuItem>
            </Select>
          </FormControl>

          <Box width={500}>
            <TextField  onChange={(event) => {
              setfilters({
                search: event.target.value,
                search_by_filter: filters.search_by_filter,
                Records_Filter: 'FILTER',
                Partner_Details_Id: state.Partner_Details_Id
              })
            }}
              className={classes.formControl}
            value={filters?.search}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search Partner Address"
              variant="outlined"
            />
          </Box>
        </Box>
        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
          (RolePermissions?.Partner_Address_Write?.Value === "Partner_Address_Write" && RolePermissions?.Partner_Address_Write?.Is_Write === 1) ?
          <Button
            component={RouterLink}
            to="/app/add-partneraddress"
            color="primary"
            variant="contained"
            style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
            state={state}
          >
            <span style={{ color: "white" }}>
              + ADD PARTNER ADDRESS
            </span>
          </Button>
          : null}
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;