import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    link: '/app/role-management',
    createdAt: '',
    description:
      'Role Management lets you treat groups of users as a unit by assigning users to roles such as manager, sales, member, and so on. After you have established roles, you can create access rules in your application.',
    media: '/static/Dashboard/catergory.png',
    title: 'Role Management',
    total:''
  },
  {
    id: uuid(),
    link: '/app/mycompanyprofile',
    createdAt: '',
    description:
      'A Company Profile is a professional introduction of the business which aims to inform the audience about its products and services. Or it is defined as the firms historical description, its structure, resources, the rate of performance as well as its reputation..',
    media: '/static/Dashboard/subcategory.png',
    title: 'Company Profile Management',
    total: ''
  },
  {
    id: uuid(),
    link: '/app/admin-profile-users',
    createdAt: '',
    description:
      'Employee Management is a comprehensive process that encompasses practically all aspects of human resources. It includes talent acquisition, employee onboarding and employee engagement, performance management, and compensation and benefit.',
    media: '/static/Dashboard/brandmanagement.jpg',
    title: 'Admin Employee Management',
    total: ''
  },
  {
    id: uuid(),
    link: '/app/permissions',
    createdAt: '',
    description:
      'Permissions Management is a key module uses to create access for each module from Super Admin.',
    media: '/static/Dashboard/brandmanagement.jpg',
    title: 'Permissions Management',
    total: ''
  },
];