import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
  Tooltip,
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import CreateIcon from '@material-ui/icons/Create';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import VisibilityIcon from '@material-ui/icons/Visibility';
import isEmpty from '../../store/validations/is-empty';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Gift_Voucher_Id', numeric: false, disablePadding: false, label: 'Voucher ID' },
  { id: 'Voucher_Name', numeric: false, disablePadding: false, label: 'Voucher Name' },
  { id: 'Order_By_Email_Id', numeric: false, disablePadding: false, label: 'Order By Email ID' },
  { id: 'Order_To_Email_Id', numeric: false, disablePadding: false, label: 'Order To Email ID' },
  { id: 'Total_Value', numeric: true, disablePadding: false, label: 'Voucher Value' },
];

const Results = ({
  className,
  allGifts,
  deleteGiftHandler,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allGiftsNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedGiftIds, setSelectedGiftIds] = useState([]);

  const editGiftHandler = giftData => e => {
    navigate('/app/edit-gift', { state: giftData });
  };

  const userTransactionHandler = giftData => e => {
    navigate("/app/user-gifts/user-gift-history-details", { state: giftData });
  };

  const visibleTransactionHandler = giftData => e => {
    navigate("/app/view-usergifthistory", { state: giftData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions&nbsp;&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allGifts) &&
                (allGifts).map(gift => (
                  <TableRow
                    hover
                    key={gift.Gift_Voucher_Id}
                    selected={
                      selectedGiftIds.indexOf(gift.Gift_Voucher_Id) !== -1
                    }
                  >
                    <TableCell >{gift.Gift_Voucher_Id}</TableCell>
                    <TableCell style={{ overflowX: "hidden" }}>{gift.Voucher_Name}</TableCell>
                    <TableCell >{gift.Order_By_Email_Id?.substring(0, 22)}...</TableCell>
                    <TableCell >{gift.Order_To_Email_Id?.substring(0, 22)}...</TableCell>
                    <TableCell >₹{gift.Total_Value}</TableCell>
                    <TableCell>
                      {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                        (RolePermissions?.User_Gift_History_Read?.Value === "User_Gift_History_Read" && RolePermissions?.User_Gift_History_Read?.Is_Read === 1) ?
                        <Tooltip title="User Gift History">
                          <Button
                            onClick={userTransactionHandler(gift)}
                            color="primary"
                          >
                            <CardGiftcardIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        : null}
                      {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                        (RolePermissions?.User_Gifts_Write?.Value === "User_Gifts_Write" && RolePermissions?.User_Gifts_Write?.Is_Write === 1) ?
                        <Button
                          onClick={editGiftHandler(gift)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        : null}
                      {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                        (RolePermissions?.User_Gifts_Write?.Value === "User_Gifts_Write" && RolePermissions?.User_Gifts_Write?.Is_Write === 1) ?
                        <Tooltip title="View Gift History">
                          <Button
                            onClick={visibleTransactionHandler(gift)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        : null}
                    </TableCell>
                    {RolePermissions?.User_Gifts_Read?.Value === "User_Gifts_Read" && RolePermissions?.User_Gifts_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="View Gift History">
                          <Button
                            onClick={visibleTransactionHandler(gift)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allGifts) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allGifts: PropTypes.array.isRequired
};

export default Results;