import { Button, Card, CardContent, Divider, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { updatePartnerBiddingComments } from 'src/store/actions/partnerbiddingcommentsAction';
import ConfirmDialog from 'src/components/dialogs/confirmDialog';
import { CheckCircleRounded, CancelRounded } from '@material-ui/icons';
import ConfirmDialogone from 'src/components/dialogs/confirmDialog';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router';

const PartnerBiddingCommentsUpdate = ({ className, state, data, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const modifiedBy = useSelector(state => state.auth.user.Email_Id);
    const isapproval = useSelector(state => state.partnerbiddingcomment.partnerbidcommentUpdate.isLoading);
    const isrejected = useSelector(state => state.partnerbiddingcomment.partnerbidcommentUpdate.isLoading);

    const [PBCommentReject, setPBCommentReject] = useState(false);
    const [PBCommentTitle, setPBCommentTitle] = useState('');
    const [PBCommentMessage, setPBCommentMessage] = useState('');
    const [PBCommentIdle, setPBCommentIdle] = useState();

    const [PBCommentAccept, setPBCommentApprove] = useState(false);
    const [PBCommentTitle1, setPBCommentTitle1] = useState('');
    const [PBCommentMessage1, setPBCommentMessage1] = useState('');
    const [PBCommentIdle1, setPBCommentIdle1] = useState();

    const commentRejectHandleClose = (value) => {
        setPBCommentReject(false);

        if (value) {
            const RejectData = {
                Bidding_Product_Comments_Id: PBCommentIdle,
                Is_Admin_Approval: 0,
                Modified_By: modifiedBy
            }
            dispatch(updatePartnerBiddingComments(RejectData, navigate, state));
        }
    };

    const commentAcceptHandleClose = (value) => {
        setPBCommentApprove(false);

        if (value) {
            const approveData = {
                Bidding_Product_Comments_Id: PBCommentIdle1,
                Is_Admin_Approval: 1,
                Modified_By: modifiedBy
            }
            dispatch(updatePartnerBiddingComments(approveData, navigate, state));
        }
    };

    //Approve handler
    const commentApproveHandler = approveData => async e => {
        setPBCommentApprove(true);
        setPBCommentTitle1("Alert!");
        setPBCommentMessage1("Are you sure to Approve?");
        setPBCommentIdle1(approveData);
    };

    //Reject handler
    const commentRejectHandler = RejectData => async e => {
        setPBCommentReject(true);
        setPBCommentTitle("Alert!");
        setPBCommentMessage("Are you sure to Reject?");
        setPBCommentIdle(RejectData);
    };

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            {/* Bidding_Product_Comment_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Bidding Product Comment ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Bidding_Product_Comment_Id}
                                </Typography>
                            </Grid>

                            {/* Is_Admin_Approval */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Admin Approval
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Is_Admin_Approval}
                                </Typography>
                            </Grid>

                            {/* Created_Date */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Created_Date}
                                </Typography>
                            </Grid>

                            {/* Created_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={data?.Created_By}> {data?.Created_By?.length > 25 ?
                                        <span>{data?.Created_By?.substring(0, 25)}...</span> : data?.Created_By}</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={2} />
                            <Grid item xs={2} />
                            <Grid item xs={7} />
                        </Grid>
                    </Grid>

                    {/*  column 2 */}
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            {/* Partner_Bidding_Product_Quote_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Partner Bidding Product Quote ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Partner_Bidding_Product_Quote_Id}
                                </Typography>
                            </Grid>

                            {/* Commented_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Commented By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Commented_By}
                                </Typography>
                            </Grid>

                            {/* Modified_Date */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Modified_Date}
                                </Typography>
                            </Grid>

                            {/* Modified_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem', paddingBottom: '1rem' }}
                                >
                                    <span title={data?.Modified_By}> {data?.Modified_By?.length > 25 ?
                                        <span>{data?.Modified_By?.substring(0, 25)}...</span> : data?.Modified_By}</span>
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Divider />
                {/* Comments */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A', paddingBottom: '1rem' }}
                    >
                        Comments
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                    >
                        <span dangerouslySetInnerHTML={{ __html: data?.Comments }}></span>
                    </Typography>
                </Grid>

            </CardContent>
            <div style={{ margin: '1rem' }}>
                {/* {data?.Is_Admin_Approval !== "1" ? */}
                    <div>
                        {isapproval ?
                            <CircularProgress />
                            :
                            <Button variant="outlined" style={{ backgroundColor: '#12824C', color: '#FFFFFF', margin: '2rem' }}
                                startIcon={<CheckCircleRounded />} onClick={commentApproveHandler(data?.Bidding_Product_Comment_Id)}>Approve</Button>
                        }
                        {isrejected ?
                            <CircularProgress />
                            :
                            <Button variant="outlined" style={{ backgroundColor: 'red', margin: '2rem', color: '#FFFFFF' }} startIcon={<CancelRounded />}
                                onClick={commentRejectHandler(data?.Bidding_Product_Comment_Id)}>&nbsp;&nbsp;Reject&nbsp;&nbsp;</Button>
                        }
                    </div>
                    {/* : null} */}
            </div>
            <ConfirmDialog inputMessage={PBCommentMessage} titleMsg={PBCommentTitle} open={PBCommentReject} onClose={commentRejectHandleClose} />
            <ConfirmDialogone inputMessage={PBCommentMessage1} titleMsg={PBCommentTitle1} open={PBCommentAccept} onClose={commentAcceptHandleClose} />
        </Card >
    );
};

export default PartnerBiddingCommentsUpdate;