import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/delivery/reports/total-orders',
    description: 'Total Orders report can calculate and display the count of ongoing orders, returned and cancelled orders, delivered orders etc.,',
    media: '/static/Dashboard/budget.jpg',
    title: 'Total Orders'
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/delivery/reports/delivered-orders',
    description: 'Delivered Orders report can calculate and display the delivered order count.',
    media: '/static/Dashboard/budget.jpg',
    title: 'Delivered Orders'
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/delivery/reports/outfordelivery-orders',
    description: 'Out for Delivery Orders report can calculate and display the ongoing order count.',
    media: '/static/Dashboard/budget.jpg',
    title: 'Out for Delivery Orders'
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/delivery/reports/returned-orders',
    description: 'Returned Orders report can calculate and display the returned order count.',
    media: '/static/Dashboard/budget.jpg',
    title: 'Returned Orders'
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/delivery/reports/cancelled-orders',
    description: 'Cancelled Orders report can calculate and display the cancelled order count.',
    media: '/static/Dashboard/budget.jpg',
    title: 'Cancelled Orders'
  },
];