import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { getAllMeepaisaTheme, getAllMeepaisaZones, updateMeepaisazonetheme } from 'src/store/actions/meepaisazonethemeAction';


const EditMeepaisaZoneTheme = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = location;

  useEffect(() => {
    dispatch(getAllMeepaisaZones());
    dispatch(getAllMeepaisaTheme());
  }, []);

  const isSubmited = useSelector(state => state.zone.zoneUpdate.isLoading);
  const zones = useSelector(state => state.zonetheme.Zones.zones);
  const themes = useSelector(state => state.zonetheme.Themes.themes);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);

  const values = {
    Meepaisa_Theme_Id: state.Meepaisa_Theme_Id ? state.Meepaisa_Theme_Id : "",
    Zone_Id: state.Zone_Id ? state.Zone_Id : "",
    Zone_Theme_Id: state.Zone_Theme_Id ? state.Zone_Theme_Id : "",
    Is_Active: state.Is_Active > 0 ? true : false,
    Modified_By: "",
  }

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      if (values.Is_Active) {
        formValues.Is_Active = 1;
      } else {
        formValues.Is_Active = 0;
      }
      formValues.Modified_By = modifiedBy;
      dispatch(updateMeepaisazonetheme(formValues, navigate, setErrors));
    }
  }

  return (
    <Formik initialValues={{ ...values }} onSubmit={onSubmit} >
      {({
        handleBlur,
        handleChange,
        values,
      }) => (
        <Form>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={6}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Zone Name"
                        name="Zone_Id"
                        disabled
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={zones.length > 0 ? values.Zone_Id : ""}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {zones?.sort((a, b) => a.Zone_Name?.localeCompare(b.Zone_Name))?.map(option => (
                          <option key={option.Zone_Id} value={option.Zone_Id}>
                            {option.Zone_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Meepaisa Theme Name"
                        name="Meepaisa_Theme_Id"
                        disabled
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={themes.length > 0 ? values.Meepaisa_Theme_Id : ""}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {themes?.sort((a, b) => a.Meepaisa_Theme_Name?.localeCompare(b.Meepaisa_Theme_Name))?.map(option => (
                          <option key={option.Meepaisa_Theme_Id} value={option.Meepaisa_Theme_Id}>
                            {option.Meepaisa_Theme_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Active}
                              onChange={handleChange}
                              name="Is_Active"
                              color="primary"
                            />
                          }
                          label="Is Active"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{global.message}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained">
                  {/* Save Details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default EditMeepaisaZoneTheme;