import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateBillAction,
} from '../../store/actions/missingcashbackAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Pending',
    label: 'Pending'
  },
  {
    value: 'In-Progress',
    label: 'In-Progress'
  },
  {
    value: 'Hold',
    label: 'Hold'
  },
  {
    value: 'Settled',
    label: 'Settled'
  },
  {
    value: 'Rejected',
    label: 'Rejected'
  }
];

const EditMissingcashback = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = location;

  const isSubmitted = useSelector(state => state.bill.billUpdate.isLoading);
  const ErrorMessage = useSelector(state => state.bill.billUpdate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        Missing_Cashback_Id: state.Missing_Cashback_Id ? state.Missing_Cashback_Id : "",
        User_Visit_Id: state.User_Visit_Id ? state.User_Visit_Id : "",
        User_Email_Id: state.User_Email_Id ? state.User_Email_Id : "",
        Modified_By: loginEmail,
        Total_Amount: state.Total_Amount ? state.Total_Amount : "",
        Invoice_Attachment: state.Invoice_Attachment ? state.Invoice_Attachment : "",
        Status: state.Status ? state.Status : "",
        Coupon_Code: state.Coupon_Code ? state.Coupon_Code : "",
        Order_Or_Booking_Id: state.Order_Or_Booking_Id ? state.Order_Or_Booking_Id : ""
      }}
      validationSchema={
        Yup.object().shape({
          Total_Amount: Yup.number().min(1, "Total Amount must be at least 1.").required('Total Amount is required.'),
          Invoice_Attachment: Yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
            'Please provide valid URL.'
          ).required('Invoice URL is required.'),
          Status: Yup.string().required('Status is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};

        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(updateBillAction(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="User Visit ID"
                    name="User_Visit_Id"
                    value={values.User_Visit_Id}
                    variant="outlined"
                    disabled
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="User Email ID"
                    name="User_Email_Id"
                    value={values.User_Email_Id}
                    variant="outlined"
                    disabled
                  />
                </Grid>

                {/*Coupon_Code */}
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Offer ID"
                    name="Coupon_Code"
                    value={values.Coupon_Code}
                    variant="outlined"
                    disabled
                  />
                </Grid>

                {/*Order_Or_Booking_Id */}
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Order/Booking ID "
                    name="Order_Or_Booking_Id"
                    value={values.Order_Or_Booking_Id}
                    variant="outlined"
                    disabled
                  />
                </Grid>

                {/*Total_Amount */}
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.Total_Amount && errors.Total_Amount)}
                    fullWidth
                    helperText={touched.Total_Amount && errors.Total_Amount}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    label="Total Amount Rs"
                    name="Total_Amount"
                    value={values.Total_Amount}
                    variant="outlined"
                  />
                </Grid>

                {/* Status */}
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.Status && errors.Status)}
                    fullWidth
                    helperText={touched.Status && errors.Status}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Status"
                    name="Status"
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: true }}
                    value={values.Status}
                    variant="outlined"
                  >
                    {statusOption.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                {/*Invoice_Attachment */}
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.Invoice_Attachment && errors.Invoice_Attachment)}
                    fullWidth
                    helperText={touched.Invoice_Attachment && errors.Invoice_Attachment}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Invoice URL"
                    name="Invoice_Attachment"
                    value={values.Invoice_Attachment}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{ErrorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmitted ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmitted}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditMissingcashback.propTypes = {
  className: PropTypes.string
};

export default EditMissingcashback;