import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { forgotCheck, forgot } from '../../store/actions/authActions'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { MD5 } from '../../utils/md5/md5Encripion';
import clsx from 'clsx';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            <div style={{ fontSize: "10px", }}>
                {'Copyright © '}
                <Link color="inherit" target="_blank" href="https://www.easystepin.com/">
                    EasyStepIn
                </Link>{' 2016 - '}
                {new Date().getFullYear()}
                {'. All Rights Reserved.'}
            </div>
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(-2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        borderColor: theme.palette.secondary.main,
    },
    form: {
        maxWidth: '100%', // Fix IE 11 issue.
        width: '400px',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(0, 0, 0),
        width: "50%",
        marginLeft: "auto",
        marginRight: "auto",
        display: 'block'
    },
    vform: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(0),
    },
    vsubmit: {
        margin: theme.spacing(2, 0, 1),
        width: "90%",
    },
    margin: {
        margin: theme.spacing(2, 0, 1),
    }
}));

function Forgot(props) {
    const { signinResponse } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmited, setIsSubmited] = useState(false);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    
    const [stateInit, setStateInit] = useState({
        email: '',
        is_email_otp: true
    });
    const [otp, setOtp] = useState();
    const [mobile, setMobile] = useState();
    const [currentAction, setCurrentAction] = useState("forgot");

    const [currentState, setCurrentState] = useState('forgot');
    const [seconds, setSeconds] = React.useState();

    const forgotResponse = (status, errorMessage, otp, mobile) => {
        setMobile(mobile);
        setIsSubmited(false);
        setErrorMessage(errorMessage);
        if (currentAction === "forgot") {
            setCurrentState('forgot');
        } else if (currentAction === "otp") {
            setCurrentState('otp');
        }
        if (status) {
            setOtp(otp);
            setCurrentState('otp');
            setSeconds(30);
        }
    }

    const forgotSuccessResponse = (status, errorMessage) => {
        setErrorMessage(errorMessage);
        setIsSubmited(false);
        if (status) {
            signinResponse(true);
        }
        setCurrentState("otp");
    }


    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // componentDidMount
    useEffect(() => {
        if (isAuthenticated) {
            signinResponse(true);
        }
    }, []);


    useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    return (
        <Container component="main" maxWidth="xs">
            < div className={classes.paper}>
                <Avatar className={classes.avatar} src={"../../../../newfavicon.png"} />
                <Typography component="h1" variant="h5">
                    Forgot Password
                </Typography>
                {currentState === "forgot" &&
                    <Formik
                        initialValues={stateInit}

                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email('Please provide valid Email Address.')
                                .max(255)
                                .required('Email Address is required.')
                        })}

                        onSubmit={(values) => {
                            setIsSubmited(true);
                            values.is_email_otp = true;
                            setCurrentAction("forgot");
                            setErrorMessage("");
                            dispatch(forgotCheck(values, forgotResponse));
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            touched,
                            values
                        }) => (
                            <form onSubmit={(e) => {
                                handleSubmit(e);
                                setStateInit(values);
                            }} className={classes.form}>
                                <ConnectedFocusError />
                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email Address"
                                    margin="normal"
                                    key="forgotEmail"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setStateInit({
                                            email: e.target.value,
                                            is_email_otp: true
                                        });
                                    }}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                    autoCapitalize='none'
                                />

                                <div>
                                    <small style={{ color: "red" }}>{errorMessage}</small>
                                </div>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    className={classes.submit}
                                    disabled={isSubmited}
                                >
                                    {/* Request OTP */}
                                    <span style={{ color: 'white' }}>Request OTP</span>
                                </Button>

                            </form>
                        )}
                    </Formik>
                }
                {currentState === "otp" &&
                    <Formik
                        initialValues={{
                            Email_OTP: '',
                            password: '',
                            cpassword: ''
                        }}

                        validationSchema={Yup.object().shape({
                            Email_OTP: Yup.string().required(stateInit.is_email_otp ? 'Email OTP is required.' : 'Mobile OTP is required.'),
                            password: Yup.string()
                                .max(255)
                                .matches(/[a-z]+/, "At least one lowercase letter is required.")
                                .matches(/[A-Z]+/, "At least one uppercase letter is required.")
                                .matches(/[^\da-zA-Z]+/, "At least one special character is required.")
                                .matches(/.*\d+/, "At least one number is required.")
                                .min(8, "At least minimum 8 characters are required.")
                                .required('New Password is required.'),
                            cpassword: Yup.string()
                                .max(255).oneOf([Yup.ref('password'), null], 'Passwords must match.')
                                .required('Confirm Password is required.')
                        })}

                        onSubmit={(values, { setSubmitting, setErrors }) => {
                            setSubmitting(false);
                            let submitOTP = true;
                            const errors = {};
                            if (!(MD5(values.Email_OTP) === otp)) {
                                submitOTP = false;
                                errors.Email_OTP = "Invalid OTP.";
                            }
                            setErrors(errors);
                            if (submitOTP) {
                                setErrorMessage('');
                                values.email = stateInit.email;
                                dispatch(forgot(values, forgotSuccessResponse));
                                setIsSubmited(true);
                            }

                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            touched,
                            values,
                            resetForm,
                            setFieldValue
                        }) => (
                            <form onSubmit={handleSubmit} className={classes.vform}>
                                <ConnectedFocusError />
                                <Grid container>
                                    <Grid container item xs={12} spacing={2}>
                                        <TextField
                                            error={Boolean(touched.Email_OTP && errors.Email_OTP)}
                                            fullWidth
                                            helperText={touched.Email_OTP && errors.Email_OTP}
                                            label={stateInit.is_email_otp ? "Email OTP" : "Mobile OTP"}
                                            margin="normal"
                                            name="Email_OTP"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.Email_OTP}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid container item xs={12} spacing={2}>
                                        <FormControl fullWidth variant="outlined" className={clsx(classes.margin)}
                                            error={Boolean(touched.password && errors.password)}
                                        >
                                            <InputLabel htmlFor="password">New Password</InputLabel>
                                            <OutlinedInput
                                                id="password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={values.password}
                                                onBlur={handleBlur}
                                                onChange={(event) => setFieldValue("password", event.target.value)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                labelWidth={110}
                                            />
                                            <FormHelperText >{touched.password && errors.password}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={12} spacing={2}>
                                        <TextField
                                            error={Boolean(touched.cpassword && errors.cpassword)}
                                            fullWidth
                                            helperText={touched.cpassword && errors.cpassword}
                                            label="Confirm Password"
                                            margin="normal"
                                            name="cpassword"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="password"
                                            value={values.cpassword}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <div style={{
                                            marginTop: "5px",
                                            marginBottom: "-10px"
                                        }}>
                                            <center>
                                                <small style={{ color: "red" }}>{errorMessage}</small>
                                            </center>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: '#27B6CC' }}
                                            className={classes.vsubmit}
                                            disabled={isSubmited}
                                        >
                                            <span style={{ color: 'white' }}>Set Password</span>
                                            {/* Set Password */}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            type="button"
                                            fullWidth
                                            style={{
                                                borderStyle: "solid",
                                                borderWidth: "thin",
                                                borderColor: "#3f51b5",
                                                backgroundColor: '#27B6CC'
                                            }}
                                            variant={seconds >= 0 ? "outlined" : "contained"}
                                            onClick={() => setCurrentState("signup")}
                                            className={classes.vsubmit}
                                            disabled={isSubmited}
                                            onClick={() => {
                                                if (!(seconds >= 0)) {
                                                    dispatch(forgotCheck(stateInit, forgotResponse));
                                                    setIsSubmited(true);
                                                    resetForm();
                                                    setErrorMessage("");
                                                }
                                            }}

                                        >
                                            <span style={{ color: 'white' }}>Resend OTP {seconds}</span>
                                        </Button>

                                    </Grid>
                                </Grid>
                                <Grid container justify="flex-end">
                                    <Grid xs style={{ marginLeft: "-10px" }} item >
                                        <Link href="#" onClick={() => {
                                            setCurrentState("forgot");
                                            setIsSubmited(false);
                                            setErrorMessage("");
                                        }} variant="body2">
                                            <u>Try another Email Address</u>
                                        </Link>
                                    </Grid>
                                    <Grid item style={{ marginRight: "10px", pointerEvents: isSubmited ? "none" : "auto" }}>
                                        <Link href="#" onClick={() => {
                                            if (!isSubmited) {
                                                setCurrentAction("otp");

                                                dispatch(forgotCheck({
                                                    email: stateInit.email,
                                                    is_email_otp: !stateInit.is_email_otp
                                                }, forgotResponse));
                                                let currentObject = JSON.parse(JSON.stringify(stateInit));
                                                currentObject.is_email_otp = !stateInit.is_email_otp;
                                                setStateInit(currentObject);
                                                setIsSubmited(true);
                                                resetForm();
                                                setErrorMessage("");
                                            }

                                        }} variant="body2">
                                            <u>{stateInit.is_email_otp ? "Generate mobile OTP ******" + (mobile ? mobile.substring(mobile.length - 4) : "") + "." : "Generate Email Address OTP."}</u>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                }
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container >
    );
}

Forgot.propTypes = {
    signinResponse: PropTypes.func.isRequired
};

export default Forgot;