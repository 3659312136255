import {
    SINGLE_B2B_ORDER_REQUEST,
    SINGLE_B2B_ORDER_SUCCESS,
    SINGLE_B2B_ORDER_FAIL,

    B2B_ORDER_UPDATE_REQUEST,
    B2B_ORDER_UPDATE_SUCCESS,
    B2B_ORDER_UPDATE_FAIL,

    B2B_ORDER_ESIDELIPERSONS_REQUEST,
    B2B_ORDER_ESIDELIPERSONS_SUCCESS,
    B2B_ORDER_ESIDELIPERSONS_FAIL,

    B2B_ORDER_PARTNERDELIPERSONS_REQUEST,
    B2B_ORDER_PARTNERDELIPERSONS_SUCCESS,
    B2B_ORDER_PARTNERDELIPERSONS_FAIL

} from '../actions/b2borderAction'


const initialState = {
    b2bOrder: { order: {}, error: '', isLoading: false },
    b2bsingleorder: { all: {}, error: '', isLoading: false },
    b2borderUpdate: { order: {}, error: '', isLoading: false },
    b2bOrderesidelipersons: { all: [], error: '', isLoading: false },
    b2bOrderpartnerdelipersons: { all: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SINGLE_B2B_ORDER_REQUEST:
            return { ...state, b2bsingleorder: { all: {}, error: '', isLoading: true } };
        case SINGLE_B2B_ORDER_SUCCESS:
            return { ...state, b2bsingleorder: { all: action.payload, error: '', isLoading: false } };
        case SINGLE_B2B_ORDER_FAIL:
            return { ...state, b2bsingleorder: { all: {}, error: action.payload, isLoading: false } };

        case B2B_ORDER_UPDATE_REQUEST:
            return { ...state, b2borderUpdate: { order: {}, error: '', isLoading: true } };
        case B2B_ORDER_UPDATE_SUCCESS:
            return { ...state, b2borderUpdate: { order: action.payload, error: '', isLoading: false } };
        case B2B_ORDER_UPDATE_FAIL:
            return { ...state, b2borderUpdate: { order: {}, error: action.payload, isLoading: false } };

        case B2B_ORDER_ESIDELIPERSONS_REQUEST:
            return { ...state, b2bOrderesidelipersons: { all: state.b2bOrderesidelipersons.all, error: '', isLoading: true } };
        case B2B_ORDER_ESIDELIPERSONS_SUCCESS:
            return { ...state, b2bOrderesidelipersons: { all: action.payload, error: '', isLoading: false } };
        case B2B_ORDER_ESIDELIPERSONS_FAIL:
            return { ...state, b2bOrderesidelipersons: { all: [], error: action.payload, isLoading: false } };

        case B2B_ORDER_PARTNERDELIPERSONS_REQUEST:
            return { ...state, b2bOrderpartnerdelipersons: { all: state.b2bOrderpartnerdelipersons.all, error: '', isLoading: true } };
        case B2B_ORDER_PARTNERDELIPERSONS_SUCCESS:
            return { ...state, b2bOrderpartnerdelipersons: { all: action.payload, error: '', isLoading: false } };
        case B2B_ORDER_PARTNERDELIPERSONS_FAIL:
            return { ...state, b2bOrderpartnerdelipersons: { all: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}