import React, { useEffect, useState } from 'react';
import {
    Container, Grid, Typography, TextField, FormControl, RadioGroup, Radio, FormControlLabel,
    Button, Box, Divider, CardMedia, Card
} from '@mui/material';
import { CardActions, CardContent, FormLabel } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getcorpCouponImages, updatedCorpCouponAction } from 'src/store/actions/corporatecouponAction';
import { useLocation, useNavigate } from 'react-router';

const EditCorporateCoupon = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    let { state } = location;
    const navigate = useNavigate();

    const statusOption = [
        {
            value: '',
            label: '--Please Select--'
        },
        {
            value: 'Active',
            label: 'Active'
        },
        {
            value: 'In-Active',
            label: 'In-Active'
        }
    ];

    const [isSubmitted, setIsSubmitted] = useState(false);
    const modifiedBy = useSelector(state => state.auth.user.Email_Id);
    const couponImage = useSelector((state) => state.corpcoupons.corpcouponImages?.couponImage?.results || []);
    const initialImagesCount = 8;

    const imagesToShow = couponImage && couponImage?.slice(0, initialImagesCount);
    const [isExpirableCoupon, setIsExpirableCoupon] = useState(state?.Valid_To_Date == "" ? false : true);
    const [selectedImage, setSelectedImage] = useState(state.Image_Path);
    const [discount, setDiscount] = useState(state.Discount_Percentage);
    const [message, setMessage] = useState(state.Description);

    useEffect(() => {
        dispatch(getcorpCouponImages());
    }, [dispatch]);

    const handleRadioChange = (event, setFieldValue) => {
        const isExpirable = event.target.value === 'true';
        setIsExpirableCoupon(isExpirable);
        setFieldValue('Is_Expirable_Coupon', isExpirable);
        if (!isExpirable) {
            setFieldValue('Valid_To_Date', '');
        }
    };

    Yup.addMethod(Yup.string, 'maxWords', function (max, message) {
        return this.test({
            name: 'maxWords',
            exclusive: true,
            message: message,
            test: (value) => {
                if (!value) return true;
                const wordCount = value.split(/\s+/).filter((word) => word.length > 0).length;
                return wordCount <= max;
            },
        });
    });


    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>1. Select your coupon style</Typography>
                    {selectedImage && (
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                minWidth: '360px',
                                height: '308px',
                                alignItems: 'flex-start',
                                alignContent: 'flex-start',
                                flexWrap: 'wrap',
                                gap: '24px',
                                flex: '1 0 0',
                            }}
                        >
                            {selectedImage && (
                                <Grid
                                    item
                                    sx={{
                                        display: 'flex',
                                        height: '171px',
                                        padding: '10px',
                                        maxWidth: '368px',
                                        minWidth: '328px',
                                        gap: '10px',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        height="194"
                                        image={selectedImage}
                                        alt="Selected Image"
                                    />
                                    <Grid
                                        container
                                        item
                                        sx={{ display: 'flex', gap: '12px', flexDirection: 'row', justifyContent: 'space-between' }}
                                    >
                                        <Grid item>
                                            <Typography variant='body1' sx={{ color: '#00008', fontSize: '20px', fontWeight: 400, letterSpacing: '0.25px' }}>Meepaisa</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0.15px' }}>{discount ? discount : 0}% off on selected products</Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ margin: 0 }} />
                                    <Typography variant="caption" color="text.secondary" sx={{ color: '#00008', fontSize: '12px', fontWeight: 400, lineHeight: '19.92px', letterSpacing: '0.4px' }}>
                                        {message ? message : "“Thank you for your hard work and dedication! Please enjoy this special coupon as a token of our appreciation."}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid container item sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-end', gap: '20px', flex: '1 0 0' }}>

                                <Grid container item spacing={2}>
                                    {imagesToShow.map((img, i) => (
                                        <Grid item xs={3} key={i}>
                                            <Card
                                                sx={{
                                                    display: 'flex',
                                                    height: '120px',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                                    image={img.Image_Url}
                                                    alt="Paella dish"
                                                />
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography variant="h6" gutterBottom>2. Enter coupon details</Typography>
                    <Formik
                        initialValues={{
                            Coupon_Id: state?.Coupon_Id,
                            Coupon_Title: state?.Coupon_Title,
                            Coupon_Prefix: state?.Coupon_Prefix,
                            Status: state?.Status,
                            Discount_Percentage: discount,
                            Description: message,
                            Times_Of_Use: state?.Coupon_Max_Count,
                            Is_Expirable_Coupon: isExpirableCoupon,
                            Valid_To_Date: state?.Valid_To_Date,
                            Modified_By: modifiedBy
                        }}
                        validationSchema={Yup.object().shape({
                            Discount_Percentage: Yup.number().min(1, "Discount Percentage must be at least 1.").max(100, "Discount Percentage must be less than 100.").required('Discount Percentage is required.'),
                            Is_Expirable_Coupon: Yup.boolean().required('Required'),
                            Valid_To_Date: Yup.date()
                                .when('Is_Expirable_Coupon', {
                                    is: true,
                                    then: Yup.date().required('Expiry Date is required').nullable(),
                                    otherwise: Yup.date().nullable(),
                                }),
                            Description: Yup.string()
                                .required('Description is required.')
                                .maxWords(15, 'Description must be 15 words or less.'),
                            Status: Yup.string().required('Status is required.'),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            let submitTest = true;
                            values.Is_Expirable_Coupon = isExpirableCoupon;
                            setSubmitting(false);
                            setIsSubmitted(true);
                            if (submitTest) {
                                if (values) {
                                    let formValues = JSON.parse(JSON.stringify(values));
                                    dispatch(updatedCorpCouponAction(formValues, navigate));
                                }
                            }
                        }}
                    >
                        {({
                            errors,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            values,
                            touched,
                        }) => (
                            <Form>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                disabled
                                                variant="outlined"
                                                size="medium"
                                                label='Coupon Title'
                                                name='Coupon_Title'
                                                value={values.Coupon_Title}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                disabled
                                                variant="outlined"
                                                size="medium"
                                                label='Coupon Prefix'
                                                value={values.Coupon_Prefix}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name='Coupon_Prefix'
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                variant="outlined"
                                                size="medium"
                                                label='Discount Percentage'
                                                name='Discount_Percentage'
                                                type='number'
                                                fullWidth
                                                value={values.Discount_Percentage}
                                                inputProps={{
                                                    min: 0,
                                                    max: 99,
                                                    step: 1,
                                                }}
                                                onChange={(e) => {
                                                    let inputValue = e.target.value.trim();
                                                    inputValue = inputValue.replace(/\D/g, '');
                                                    inputValue = inputValue.slice(0, 2);

                                                    if (inputValue === '' || (parseInt(inputValue, 10) >= 0 && parseInt(inputValue, 10) <= 99)) {
                                                        setDiscount(inputValue);
                                                        handleChange({
                                                            target: {
                                                                id: 'Discount_Percentage',
                                                                value: inputValue,
                                                            }
                                                        });
                                                    }
                                                }}
                                                error={Boolean(touched.Discount_Percentage && errors.Discount_Percentage)}
                                                helperText={touched.Discount_Percentage && errors.Discount_Percentage}
                                                InputLabelProps={{
                                                    sx: {
                                                        '&.MuiInputLabel-root': {
                                                            '&::after': {
                                                                content: "'*'",
                                                                color: 'red',
                                                                marginLeft: '2px',
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label='Your message'
                                                name='Description'
                                                value={values.Description}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    const { name, value } = e.target;
                                                    const pastedText = e.nativeEvent.inputType === 'insertFromPaste';
                                                    const trimmedValue = pastedText || !value.startsWith(' ') ? value : value.trim();
                                                    const normalizedValue = trimmedValue.replace(/\s+/g, ' ');

                                                    const words = normalizedValue.split(' ');

                                                    const maxWords = 15;

                                                    if (trimmedValue.length === 0) {
                                                        setFieldValue(name, '');
                                                    } else if (words.length <= maxWords) {
                                                        setFieldValue(name, normalizedValue.slice(0, 150));
                                                        setMessage(normalizedValue.slice(0, 150))
                                                    } else {
                                                        const truncatedValue = words.slice(0, maxWords).join(' ');
                                                        setFieldValue(name, truncatedValue);
                                                        setMessage(truncatedValue)
                                                    }
                                                }}
                                                error={Boolean(touched.Description && errors.Description)}
                                                helperText={touched.Description && errors.Description}
                                                multiline={true}
                                                InputLabelProps={{
                                                    sx: {
                                                        '&.MuiInputLabel-root': {
                                                            '&::after': {
                                                                content: "'*'",
                                                                color: 'red',
                                                                marginLeft: '2px',
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                disabled
                                                fullWidth
                                                label='Times Of Use'
                                                value={values.Times_Of_Use}
                                                onBlur={handleBlur}
                                                onChange={(event) => {
                                                    const { value, name } = event.target;
                                                    if (/^(?!0+$)\d*$/.test(value)) {
                                                        handleChange(event);
                                                    }
                                                }}
                                                name='Times_Of_Use'
                                                type='text'
                                            />
                                        </Grid>
                                        <Typography sx={{ p: 2 }} variant='body1' gutterBottom={false}>Redemption fee of 2% will be charged on the product MRP when someone redeems the coupon</Typography>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl>
                                                <FormLabel id="demo-radio-buttons-group-label">Coupon Expiry</FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    value={String(isExpirableCoupon)}
                                                    onChange={(event) => handleRadioChange(event, setFieldValue)}
                                                >
                                                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="false" control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        {isExpirableCoupon && (
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Expiry Date"
                                                    value={values.Valid_To_Date}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    name='Valid_To_Date'
                                                    type="date"
                                                    variant="outlined"
                                                    error={Boolean(touched.Valid_To_Date && errors.Valid_To_Date)}
                                                    helperText={touched.Valid_To_Date && errors.Valid_To_Date}
                                                    InputLabelProps={{ shrink: true }}
                                                    inputProps={{
                                                        min: new Date().toISOString().split('T')[0]
                                                    }}
                                                />
                                            </Grid>
                                        )}

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                    </Grid>
                                </CardContent>
                                <Divider sx={{ margin: 0 }} />
                                <CardActions>
                                    <Box sx={{ width: '728px', height: '108px', padding: '36px 0px 36px 0px', gap: '24px' }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                            <Button disabled={isSubmitted} type='submit' variant="outlined" color="primary" size="medium">
                                                Send Coupon
                                            </Button>
                                        </Box>
                                    </Box>
                                </CardActions>
                            </Form>
                        )}
                    </Formik>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h6" gutterBottom>Facebook Ads</Typography>
                    <div>
                        <img src="path/to/your/facebook_ad_image.png" alt="Facebook Ads" style={{ width: '100%' }} />
                    </div>
                    <Typography variant="h6" gutterBottom>ADS</Typography>
                    <div>
                        <img src="path/to/your/ads_image.png" alt="ADS" style={{ width: '100%' }} />
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default EditCorporateCoupon;