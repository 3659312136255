import {
    STORE_DATEANDCOUNT_REQUEST,
    STORE_DATEANDCOUNT_SUCCESS,
    STORE_DATEANDCOUNT_FAIL,

} from '../actions/storedynamicdateandtimeAction'

const initialState = {
    storedateandcounts: { all: [], error: '', isLoading: false },
};
export default function (state = initialState, action) {
    switch (action.type) {
        case STORE_DATEANDCOUNT_REQUEST:
            return { ...state, storedateandcounts: { all: state.storedateandcounts.all, error: '', isLoading: true } };
        case STORE_DATEANDCOUNT_SUCCESS:
            return { ...state, storedateandcounts: { all: action.payload, error: '', isLoading: false } };
        case STORE_DATEANDCOUNT_FAIL:
            return { ...state, storedateandcounts: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}