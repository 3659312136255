
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../../store/validations/is-empty';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-10px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Partner_Details_Id', numeric: false, headerTitleAlign: 'center', disablePadding: true, label: 'Partner Details ID' },
  { id: 'Name', numeric: false, disablePadding: false, label: 'Partner Name' },
  { id: 'Esi_Reviews', numeric: false, disablePadding: false, label: 'ESI Reviews' },
  { id: 'Esi_Ratting', numeric: false, disablePadding: false, label: 'ESI Rating' },
];

const Results = ({
  className,
  allPartners,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedPartnerIds, setSelectedPartnerIds] = useState([]);
  const navigate = useNavigate();

  const OpenUserReviewHandler = ProductData => e => {
    navigate('/app/partner/user-reviews', { state: ProductData });
  };

  return (
    <Card className={clsx(classes.root, className)}
      {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', alignItems: 'center' }} >  &nbsp;&nbsp;&nbsp;Reviews
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allPartners) &&
                (allPartners).map(partner => (
                  <TableRow
                    hover
                    key={partner.Partner_Details_Id}
                    selected={
                      selectedPartnerIds.indexOf(partner.Partner_Details_Id) !== -1
                    }
                  >
                    <TableCell width="15%" >{partner.Partner_Details_Id}</TableCell>
                    <TableCell width="15%">{partner.Name?.substring(0, 15)}</TableCell>
                    <TableCell width="40%"><div dangerouslySetInnerHTML={{ __html: partner.Esi_Reviews?.substring(0, 60) }} ></div></TableCell>
                    <TableCell width="15%" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{partner.Esi_Ratting} </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Partner_Reviews_Ratings_Write?.Value === "Partner_Reviews_Ratings_Write" && RolePermissions?.Partner_Reviews_Ratings_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Button
                          onClick={OpenUserReviewHandler(partner)}
                          style={{ color: '#1665D8', fontSize: 13, textAlign: "right", }}
                        >User Reviews
                          {partner.reviews}
                        </Button>
                      </TableCell>
                      : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allPartners) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allPartners: PropTypes.array.isRequired
};

export default Results;