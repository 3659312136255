import {

    BIDDING_PRODUCTS_SUCCESS_STORIES_CREATE_REQUEST,
    BIDDING_PRODUCTS_SUCCESS_STORIES_CREATE_SUCCESS,
    BIDDING_PRODUCTS_SUCCESS_STORIES_CREATE_FAIL,
    BIDDING_SUCCESS_STORIES_PRODUCTS_UPDATE_REQUEST,
    BIDDING_SUCCESS_STORIES_PRODUCTS_UPDATE_SUCCESS,
    BIDDING_SUCCESS_STORIES_PRODUCTS_UPDATE_FAIL,
    PHYSICAL_PARTNERS_REQUEST,
    PHYSICAL_PARTNERS_SUCCESS,
    PHYSICAL_PARTNERS_FAIL,

} from '../actions/biddingproductssuccessstoriesAction';

const initialState = {
    biddingproductsuccessstoriesCreate: { bidproductsuccessstories: {}, error: '', isLoading: false },
    biddingproductsuccessstoriesUpdate: { bidproductsuccessstories: {}, error: '', isLoading: false },
    PhysicalPartners: { partners: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case BIDDING_PRODUCTS_SUCCESS_STORIES_CREATE_REQUEST:
            return { ...state, biddingproductsuccessstoriesCreate: { bidproductsuccessstories: {}, error: '', isLoading: true } };
        case BIDDING_PRODUCTS_SUCCESS_STORIES_CREATE_SUCCESS:
            return { ...state, biddingproductsuccessstoriesCreate: { bidproductsuccessstories: state.biddingproductsuccessstoriesCreate.bidproductsuccessstories, error: '', isLoading: false } };
        case BIDDING_PRODUCTS_SUCCESS_STORIES_CREATE_FAIL:
            return { ...state, biddingproductsuccessstoriesCreate: { bidproductsuccessstories: {}, error: action.payload, isLoading: false } };

        case BIDDING_SUCCESS_STORIES_PRODUCTS_UPDATE_REQUEST:
            return { ...state, biddingproductsuccessstoriesUpdate: { bidproductsuccessstories: {}, error: '', isLoading: true } };
        case BIDDING_SUCCESS_STORIES_PRODUCTS_UPDATE_SUCCESS:
            return { ...state, biddingproductsuccessstoriesUpdate: { bidproductsuccessstories: state.biddingproductsuccessstoriesUpdate.bidproductsuccessstories, error: '', isLoading: false } };
        case BIDDING_SUCCESS_STORIES_PRODUCTS_UPDATE_FAIL:
            return { ...state, biddingproductsuccessstoriesUpdate: { bidproductsuccessstories: {}, error: action.payload, isLoading: false } };

        case PHYSICAL_PARTNERS_REQUEST:
            return { ...state, PhysicalPartners: { partners: state.PhysicalPartners.partners, error: '', isLoading: true }, };
        case PHYSICAL_PARTNERS_SUCCESS:
            return { ...state, PhysicalPartners: { partners: action.payload, error: '', isLoading: false } };
        case PHYSICAL_PARTNERS_FAIL:
            return { ...state, PhysicalPartners: { partners: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}