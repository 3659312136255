import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { useLocation } from 'react-router-dom';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';
import { getAllCorpProfilesAction, updatedCorpTicketAction } from 'src/store/actions/corporateticketAction';

const statusopt = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Open',
    label: 'Open'
  },
  {
    value: 'In-Review',
    label: 'In Review'
  },
  {
    value: 'Closed',
    label: 'Closed'
  },
];

const IssuerelatedOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Orders',
    label: 'Orders'
  },
  {
    value: 'Payments',
    label: 'Payments'
  },
  {
    value: 'Delivery',
    label: 'Delivery'
  },
  {
    value: 'Products',
    label: 'Products'
  },
  {
    value: 'Recognition',
    label: 'Recognition'
  },
  {
    value: 'Gift Card',
    label: 'Gift Card'
  },
  {
    value: 'Coupons',
    label: 'Coupons'
  },
  {
    value: 'Others',
    label: 'Others'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: 120,
    height: 120,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditCorporateTicket = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  let { state } = location;

  // componentDidMount
  useEffect(() => {
    dispatch(getAllCorpProfilesAction());
  }, []);

  const errorMessage = useSelector(state => state.corptickets.corpticketUpdate.error);
  const isSubmited = useSelector(state => state.corptickets.corpticketUpdate.isLoading);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);

  const [descError, setDescError] = useState()

  const decodeHTMLEntities = (text) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  };

  const removePTags = (htmlContent) => {
    if (!htmlContent) return '';

    const decodedContent = decodeHTMLEntities(htmlContent);
    return decodedContent.replace(/<\/?p[^>]*>/g, '');
  };

  // Richtext editor
  const contentState = ContentState.createFromBlockArray(htmlToDraft(removePTags(state.Description) || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(removePTags(state.Description));

  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(2);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <Formik
      initialValues={{
        Ticket_Id: state.id ? state.id : "",
        Ticket_Subject: state.Ticket_Subject ? state.Ticket_Subject : "",
        Description: removePTags(state?.Description) ? removePTags(state?.Description) : "",
        Issues_Related: state.Issues_Related ? state.Issues_Related : "",
        Status: state.Status ? state.Status : "",
        URL_Attachment: state.URL_Attachment ? state.URL_Attachment : "",
        Modified_By: modifiedBy,
        Comments: state?.Comments ? state?.Comments : "",
        Resolutions: state?.Resolutions ? state?.Resolutions : "",
        Company_Name: state?.Company_Name ? state?.Company_Name : ""
      }}
      validationSchema={
        Yup.object().shape({
          Ticket_Subject: Yup.string().matches(/^[a-zA-Z][\sa-zA-Z]*/, "Ticket Subject should not start with Spaces and Special Characters.").min(2, "Ticket Subject should have atleast 2 characters.").required("Ticket Subject is required."),
          Status: Yup.string().required('Ticket Status  is required.'),
          // URL_Attachment: Yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
          //   'Please provide valid URL.'
          // ).required('Attachment URL is required.'),
          Issues_Related: Yup.string().required('Issue Related To is required.'),
          Company_Name: Yup.string().required('Ticket Owner is required.'),
          Comments: Yup.string().matches(/^[a-zA-Z][\sa-zA-Z]*/, "Comment should not start with Spaces and Special Characters.").required('Comment is required.'),
          Resolutions: Yup.string().matches(/^[a-zA-Z][\sa-zA-Z]*/, "Resolution should not start with Spaces and Special Characters.").required('Resolution is required.')
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        let submitTest = true;
        const errors = {};

        const plainTextDescription = rhDiscription.replace(/(<([^>]+)>)/ig, '').trim();
        const onlySpacesRegex = /^(|\s|&nbsp;)*$/;

        if (!plainTextDescription) {
          setDescError(!plainTextDescription)
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (plainTextDescription.length < rhDiscriptionMin) {
          setDescError(plainTextDescription.length < rhDiscriptionMin)
          submitTest = false;
          errors.Description = `Please provide at least ${rhDiscriptionMin} characters.`;
        }
        else if (onlySpacesRegex.test(plainTextDescription)) {
          setDescError(onlySpacesRegex.test(plainTextDescription))
          submitTest = false;
          errors.Description = "Description cannot consist of only spaces.";
        }

        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          formValues.Description = plainTextDescription
          dispatch(updatedCorpTicketAction(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Company_Name && errors.Company_Name)}
                        fullWidth
                        helperText={touched.Company_Name && errors.Company_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Company Name"
                        name="Company_Name"
                        value={values.Company_Name}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Ticket_Subject && errors.Ticket_Subject)}
                        fullWidth
                        helperText={touched.Ticket_Subject && errors.Ticket_Subject}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ticket Subject"
                        name="Ticket_Subject"
                        value={values.Ticket_Subject}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Issues_Related && errors.Issues_Related)}
                        fullWidth
                        helperText={touched.Issues_Related && errors.Issues_Related}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Issues Related"
                        name="Issues_Related"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Issues_Related}
                        variant="outlined"
                      >
                        {IssuerelatedOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        // error={Boolean(touched.URL_Attachment && errors.URL_Attachment)}
                        fullWidth
                        // helperText={touched.URL_Attachment && errors.URL_Attachment}
                        onBlur={handleBlur}
                        type="url"
                        onChange={handleChange}
                        label="URL Attachment"
                        name="URL_Attachment"
                        value={values.URL_Attachment}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Comments && errors.Comments)}
                        fullWidth
                        helperText={touched.Comments && errors.Comments}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Comment"
                        name="Comments"
                        value={values.Comments}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Resolutions && errors.Resolutions)}
                        fullWidth
                        helperText={touched.Resolutions && errors.Resolutions}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Resolution"
                        name="Resolutions"
                        value={values.Resolutions}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Description  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>{
                        descError && touched.Description && errors.Description
                      }
                      </span>
                    </Grid>
                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusopt.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
}

EditCorporateTicket.propTypes = {
  className: PropTypes.string
};

export default EditCorporateTicket;