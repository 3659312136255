
import config from './config';
import { toast } from 'react-toastify';
import { nodeGetApi, boomiAdminApi } from './commonAxios';

export const DELIVERY_WALLET_REQUEST = "DELIVERY_WALLET_REQUEST";
export const DELIVERY_WALLET_SUCCESS = "DELIVERY_WALLET_SUCCESS";
export const DELIVERY_WALLET_FAIL = "DELIVERY_WALLET_FAIL";

export const DELIVERY_WALLET_CREATE_REQUEST = "DELIVERY_WALLET_CREATE_REQUEST";
export const DELIVERY_WALLET_CREATE_SUCCESS = "DELIVERY_WALLET_CREATE_SUCCESS";
export const DELIVERY_WALLET_CREATE_FAIL = "DELIVERY_WALLET_CREATE_FAIL";

export const DELIVERY_WALLET_UPDATE_REQUEST = "DELIVERY_WALLET_UPDATE_REQUEST";
export const DELIVERY_WALLET_UPDATE_SUCCESS = "DELIVERY_WALLET_UPDATE_SUCCESS";
export const DELIVERY_WALLET_UPDATE_FAIL = "DELIVERY_WALLET_UPDATE_FAIL";

export const DELI_WALLET_HISTORY_CREATE_REQUEST = "DELI_WALLET_HISTORY_CREATE_REQUEST";
export const DELI_WALLET_HISTORY_CREATE_SUCCESS = "DELI_WALLET_HISTORY_CREATE_SUCCESS";
export const DELI_WALLET_HISTORY_CREATE_FAIL = "DELI_WALLET_HISTORY_CREATE_FAIL";

export const DELI_WALLET_HISTORY_UPDATE_REQUEST = "DELI_WALLET_HISTORY_UPDATE_REQUEST";
export const DELI_WALLET_HISTORY_UPDATE_SUCCESS = "DELI_WALLET_HISTORY_UPDATE_SUCCESS";
export const DELI_WALLET_HISTORY_UPDATE_FAIL = "DELI_WALLET_HISTORY_UPDATE_FAIL";

export const DELIVERY_WALLET_EMAILS_REQUEST = "DELIVERY_WALLET_EMAILS_REQUEST";
export const DELIVERY_WALLET_EMAILS_SUCCESS = "DELIVERY_WALLET_EMAILS_SUCCESS";
export const DELIVERY_WALLET_EMAILS_FAIL = "DELIVERY_WALLET_EMAILS_FAIL";


//Create Delivery Wallet Action
export const createDeliveryWalletAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: DELIVERY_WALLET_CREATE_REQUEST
    });
    boomiAdminApi.post(`/Delivery_Wallet/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: DELIVERY_WALLET_CREATE_SUCCESS
                });
                toast('Delivery Wallet added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/delivery-wallet-management');
            } else {
                dispatch({
                    type: DELIVERY_WALLET_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: DELIVERY_WALLET_CREATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//Update Delivery Wallet Action
export const updateDeliveryWalletAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: DELIVERY_WALLET_UPDATE_REQUEST
    });
    boomiAdminApi.put(`/Delivery_Wallet/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: DELIVERY_WALLET_UPDATE_SUCCESS
                });
                toast('Delivery Wallet updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/delivery-wallet-management');
            } else {
                dispatch({
                    type: DELIVERY_WALLET_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: DELIVERY_WALLET_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};


//Create Delivery Wallet History Action
export const createDeliWalletHistoryAction = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: DELI_WALLET_HISTORY_CREATE_REQUEST
    });
    boomiAdminApi.post(`/Delivery_Wallet/Delivery_Wallet_History_Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: DELI_WALLET_HISTORY_CREATE_SUCCESS
                });
                toast('Delivery Wallet History added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/delivery-wallet-history', state = { state });
            } else {
                dispatch({
                    type: DELI_WALLET_HISTORY_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: DELI_WALLET_HISTORY_CREATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//Update Delivery Wallet History Action
export const updateDeliWalletHistoryAction = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: DELI_WALLET_HISTORY_UPDATE_REQUEST
    });
    boomiAdminApi.put(`/Delivery_Wallet/Delivery_Wallet_History_Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: DELI_WALLET_HISTORY_UPDATE_SUCCESS
                });
                toast('Delivery Wallet History updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/delivery-wallet-history', state = { state });
            } else {
                dispatch({
                    type: DELI_WALLET_HISTORY_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: DELI_WALLET_HISTORY_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//get All Delivery Wallet Action
export const getAllDeliveryWalletAction = formData => async dispatch => {
    try {
        dispatch({
            type: DELIVERY_WALLET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Delivery_Wallet/Wallet_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=WALLET_OWNER&SortOrder=ASC`, formData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: DELIVERY_WALLET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: DELIVERY_WALLET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: DELIVERY_WALLET_FAIL,
            payload: err
        });
    }
};

//Get All Delivery Emails
export const getAlldeliveryEmails = (emailid) => async dispatch => {
    try {
        let formData = {
            "Del_User_Email_Id": emailid,
             "Records_Filter": "FILTER"
          };
        dispatch({
            type: DELIVERY_WALLET_EMAILS_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Delivery_Profile/Get_All_Profile_dropdown_search?PageNo=1&PageSize=${config.pageSize}&SortBy=Del_User_Email_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.Success_Response.Is_Data_Exist === '0') {
                dispatch({
                    type: DELIVERY_WALLET_EMAILS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: DELIVERY_WALLET_EMAILS_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: DELIVERY_WALLET_EMAILS_FAIL,
            payload: err
        });
    }
};
// Delivery Wallet Action
export const getSingleDeliveryWalletAction = (formData, callBackDeliveryWalletData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/Delivery_Wallet/Wallet_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Wallet_Id&SortOrder=ASC`, formData
        );
        if (data) {
            callBackDeliveryWalletData(data)
        }
    } catch (err) {
    }
};

// Get delivery WalletHistory Action
export const getDeliveryWalletHistoryAction = (formData, callBackDeliveryWalletHistoryData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/Delivery_Wallet/Wallet_History_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Wallet_History_Id&SortOrder=ASC`, formData);
        if (data) {
            callBackDeliveryWalletHistoryData(data)
        }
    } catch (err) {
    }
};