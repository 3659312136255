import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    Avatar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import CircularProgress from '@mui/material/CircularProgress';
import { TextareaAutosize } from '@mui/material';
import { EditSubcategoriesAction, SubcategoryuploadImagepath } from 'src/store/actions/servicessubcategorieAction';
import { paginationServicescategory, paginationsubcategoryServices } from 'src/store/actions/node-actions/nodepagination';
import EditorContainer from '../../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';

const StatusOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'ACTIVE',
        label: 'ACTIVE'
    },
    {
        value: 'IN-ACTIVE',
        label: 'IN-ACTIVE'
    }
];

const useStyles = makeStyles(() => ({
    images: {
        width: "100%",
        height: 100,
        borderRadius: 5,
        marginBottom: 15
    },
    Description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    },
    Sub_Description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }

}));

const EditsubServiceCategory = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const location = useLocation();
    let { state } = location;

    const [errorMessage, setErrorMessage] = useState({ Ss_Name: { key: '', message: '' }, global: { key: '', message: '' } });
    const isSubmitted = useSelector(state => state.category.categoryUpdate.isLoading);
    const modifiedBy = useSelector(state => state.auth.user.Email_Id);
    const [subcategories, setSubcategorires] = useState([])
    const [services, setServices] = useState([state]);


    // img upload
    const [image, setImage] = useState({ preview: state.Image, raw: state.Image });
    const [imgUrl, setImgUrl] = useState(state.Image);
    const [uploadPic, setUploadPic] = useState(false);
    const callBackUploadImgae = data => {
        if (data) {
            setImgUrl(data.File_URL);
        }
        setUploadPic(false);
    };

    const handleImage = e => {
        setUploadPic(true);
        e.preventDefault();
        setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0]
        });
        const data = new FormData();
        data.append('file', e.target.files[0]);
        dispatch(
            SubcategoryuploadImagepath(new Blob([e.target.files[0]]), callBackUploadImgae)
        );
    };

    const handleRemoveImage = e => {
        setImgUrl('');
        setImage({
            preview: '',
            raw: ''
        });
    };
    useEffect(() => {
        dispatch(
            paginationServicescategory({}, {}, (status, data) => {
                if (status) {
                    console.log(data);
                    setServices(data);
                } else setServices([]);
            })
        );
    }, []);


    //richertext editor
    const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Description).contentBlocks);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
    const [rhDiscription, setRhDiscription] = useState(state.Description);

    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    return (
        <Formik
            initialValues={{
                Image: imgUrl,
                Ss_Id: state.Ss_Id ? state.Ss_Id : "",
                Ss_Name: state.Ss_Name ? state.Ss_Name : "",
                Sub_Description: state.Sub_Description ? state.Sub_Description : "",
                Service_Id: state.Service_Id ? state.Service_Id : "",
                Description: state.Description ? state.Description : "",
                Status: state.Status ? state.Status : "",

            }}
            validationSchema={
                Yup.object().shape({
                    Ss_Name: Yup.string().min(3, "Sub Service Name must be at least 3 characters.").notOneOf([errorMessage.Ss_Name.key, null], errorMessage.Ss_Name.message).required('Sub Service Name is required.'),
                    Status: Yup.string().required('Status is required.'),
                    Service_Id: Yup.string().required('Service name is required.'),
                    Sub_Description: Yup.string().min(20, 'Sub Description must be at least 20 characters.').required('Sub_Description is required.'),
                    // Description: Yup.string().min(20, 'Description must be at least 20 characters.').required('Description is required.'),


                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                values.Image = imgUrl;
                if (!values.Image) {
                    submitTest = false;
                    errors.Image = " Web Image is required.";
                }
                values.Description = rhDiscription;
                if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
                    submitTest = false;
                    errors.Description = "Description is required.";
                }
                else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
                    submitTest = false;
                    errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
                }

                setErrors(errors);
                if (submitTest) {

                    dispatch(EditSubcategoriesAction(values, navigate,state));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Ss_Name && errors.Ss_Name)}
                                                fullWidth
                                                helperText={touched.Ss_Name && errors.Ss_Name}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Sub Service Name"
                                                name="Ss_Name"
                                                value={values.Ss_Name}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Id && errors.Service_Id)}
                                                fullWidth
                                                helperText={touched.Service_Id && errors.Service_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Service Name"
                                                name="Service_Id"
                                                value={services.find(service => service.Service_Id === values.Service_Id)?.Service_Name || ''}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{ readOnly: true }}
                                                variant="outlined"
                                            />

                                        </Grid>
                                        <Grid item md={12} xs={12}>



                                            <Box className={classes.Description}>
                                                Description
                                                <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                                            </Box>
                                            <span style={{ color: "red" }}>
                                                {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                                            </span>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Box>
                                                <TextareaAutosize
                                                    fullWidth
                                                    className={classes.Sub_Description}
                                                    minRows={10}
                                                    placeholder="Sub_Description"
                                                    value={values.Sub_Description}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    name="Sub_Description"
                                                />
                                            </Box>
                                            {touched.Sub_Description && errors.Sub_Description && (
                                                <span style={{ color: 'red' }}>{errors.Sub_Description}</span>
                                            )}
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {StatusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* upload photo */}
                                <Grid item md={4} xs={12}>
                                    <Card>
                                        &nbsp;Web Image
                                        <CardContent>
                                            <Box display="flex" justifyContent="center">
                                                {image.preview ? (
                                                    <Avatar
                                                        className={classes.images}
                                                        alt="Brand Upload"
                                                        src={image.preview}
                                                        variant="square"
                                                    />
                                                ) : (
                                                    <>
                                                        <Avatar
                                                            className={classes.images}
                                                            alt="Brand Upload"
                                                            src="/static/images/image-placeholder.png"
                                                            variant="square"
                                                        />
                                                    </>
                                                )}
                                            </Box>

                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            >
                                                {image.preview ? <Button
                                                    style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                                                    onClick={handleRemoveImage}
                                                >
                                                    Remove Picture
                                                </Button> : <input
                                                    style={{ display: 'block', width: '80%' }}
                                                    id="upload-photo"
                                                    name="image"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImage}
                                                />}
                                                <br />
                                            </Box>
                                        </CardContent>
                                    </Card><br />
                                    <span style={{ color: "red" }}>
                                        {!imgUrl && touched.Image && errors.Image}
                                    </span>




                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage.global.message}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted || uploadPic}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"

                                >
                                    {/* Save details */}
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }

                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditsubServiceCategory.propTypes = {
    className: PropTypes.string
};

export default EditsubServiceCategory;
