import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  buttonRoot: {
    minWidth: '7px',
    padding: '6px'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));

const headCells = [
  { id: 'Template_Id', numeric: false, disablePadding: false, label: 'Template ID' },
  { id: 'Template_Key', numeric: false, disablePadding: false, label: 'Template Key' },
  { id: 'Template_Purpose', numeric: false, disablePadding: false, label: 'Template Purpose' },
  { id: 'Mail_Subject_Template', numeric: false, disablePadding: false, label: 'Mail Subject' },
  { id: 'Create_Date', numeric: false, disablePadding: false, label: 'Created Date' },
];

const Results = ({
  className,
  allTemplates,
  deleteTemplateHandler,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  allTemplatesNoFilter,
  createSortHandler,
  pagination,
  setPagination,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedTemplateIds, setSelectedTemplateIds] = useState([]);

  const editTemplateHandler = templateData => e => {
    navigate('/app/edit-email-template', { state: templateData });
  };
  const viewTemplateHandler = templateData => e => {
    navigate('/app/view-emailtemplate', { state: templateData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px' }}> Actions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allTemplates) &&
                (allTemplates).map(template => (
                  <TableRow
                    hover
                    key={template.Template_Id}
                    selected={
                      selectedTemplateIds.indexOf(template.Template_Id) !== -1
                    }
                  >
                    <TableCell width="14%">{template.Template_Id}</TableCell>
                    <TableCell width="14%">{template.Template_Key}</TableCell>
                    <TableCell width="18%">{template.Template_Purpose}</TableCell>
                    <TableCell width="16%">{template.Mail_Subject_Template}</TableCell>
                    <TableCell width="16%">{template.Create_Date}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Email_Template_Write?.Value === "Email_Template_Write" && RolePermissions?.Email_Template_Write?.Is_Write === 1) ? <TableCell>
                      <Button
                        onClick={editTemplateHandler(template)}
                      >
                        <CreateIcon style={{ color: '#9EA0A5' }} />
                      </Button>
                      <Tooltip title="Template Details">
                        <Button
                          onClick={viewTemplateHandler(template)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                      : null}
                    {RolePermissions?.Email_Template_Read?.Value === "Email_Template_Read" && RolePermissions?.Email_Template_Read?.Is_Read === 1 &&
                      <TableCell >
                        <Tooltip title="Template Details">
                          <Button
                            onClick={viewTemplateHandler(template)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allTemplates) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;