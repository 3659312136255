import {
    RAZORPAY_PAYMENTS_REQUEST,
    RAZORPAY_PAYMENTS_SUCCESS,
    RAZORPAY_PAYMENTS_FAIL,
    RAZORPAY_PAYMENTS_GET_REQUEST,
    RAZORPAY_PAYMENTS_GET_SUCCESS,
    RAZORPAY_PAYMENTS_GET_FAIL,
    RAZORPAY_PAYMENTS_CREATE_REQUEST,
    RAZORPAY_PAYMENTS_CREATE_SUCCESS,
    RAZORPAY_PAYMENTS_CREATE_FAIL,
    RAZORPAY_PAYMENTS_FILTER,

} from '../actions/razorpaypaymentsActions';

const initialState = {
    razorpaypayments: { all: [], error: '', isLoading: false },
    razorpaypayment: { razorpaypayment: {}, error: '', isLoading: false },
    razorpaypaymentsCreate: { razorpaypayment: {}, error: '', isLoading: false },
    razorpaypaymentsFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case RAZORPAY_PAYMENTS_REQUEST:
            return { ...state, razorpaypayments: { all: state.razorpaypayments.all, error: '', isLoading: true } };
        case RAZORPAY_PAYMENTS_SUCCESS:
            return { ...state, razorpaypayments: { all: action.payload, error: '', isLoading: false } };
        case RAZORPAY_PAYMENTS_FAIL:
            return { ...state, razorpaypayments: { all: [], error: action.payload, isLoading: false } };
        case RAZORPAY_PAYMENTS_GET_REQUEST:
            return { ...state, razorpaypayment: { razorpaypayment: {}, error: '', isLoading: true } };
        case RAZORPAY_PAYMENTS_GET_SUCCESS:
            return { ...state, razorpaypayment: { razorpaypayment: action.payload, error: '', isLoading: false } };
        case RAZORPAY_PAYMENTS_GET_FAIL:
            return { ...state, razorpaypayment: { razorpaypayment: {}, error: action.payload, isLoading: false } };
        case RAZORPAY_PAYMENTS_CREATE_REQUEST:
            return { ...state, razorpaypaymentsCreate: { razorpaypayment: {}, error: '', isLoading: true } };
        case RAZORPAY_PAYMENTS_CREATE_SUCCESS:
            return { ...state, razorpaypaymentsCreate: { razorpaypayment: state.razorpaypaymentsCreate.razorpaypayment, error: '', isLoading: false } };
        case RAZORPAY_PAYMENTS_CREATE_FAIL:
            return { ...state, razorpaypaymentsCreate: { razorpaypayment: {}, error: action.payload, isLoading: false } };
        case RAZORPAY_PAYMENTS_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, razorpaypaymentsFilter: { search: state.razorpaypaymentsFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, razorpaypaymentsFilter: { select: state.razorpaypaymentsFilter.select, search: action.value } };
            }
            return res;
        default:
            return state;
    }
}