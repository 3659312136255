import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'src/store/validations/is-empty';
import { settingsDateandCount } from 'src/store/actions/settingsdynamicActions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  avatar: {
    cursor: 'pointer',
    width: 58,
    height: 58
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ProductCard = ({ className, product, ...rest }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let dynamicvalue = useSelector((state) => state.settingdateandcountReducer.settingdateandcounts.all);

  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  useEffect(() => {
    if (isEmpty(dynamicvalue)) {
      dispatch(settingsDateandCount());
    } else {
      setSeconds(5);
      if (product.title === "Settings") {
        product.total = dynamicvalue?.Settings?.Settings_Count;
        product.createdAt = dynamicvalue?.Settings?.Settings_Date;
      } else if (product.title === "Email Template") {
        product.total = dynamicvalue?.Email_Template?.Email_Template_Count;
        product.createdAt = dynamicvalue?.Email_Template?.Email_Template_Date;
      }
    }
  }, [dynamicvalue]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box display="flex" justifyContent="center" mb={3}>
          <Avatar
            className={classes.avatar}
            component={RouterLink}
            alt="Product"
            src={product.media}
            variant="square"
            to={product.link}
          />
        </Box>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {product.title}
        </Typography>
        <Typography style={{ paddingTop: "5px" }} align="justify" color="textPrimary" variant="body1">
          {product.description}
        </Typography>
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      <Box p={2}>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid className={classes.statsItem} item>
            <AccessTimeIcon className={classes.statsIcon} color="action" />
            <Typography color="textSecondary" display="inline" variant="body2">
              {product.createdAt}
            </Typography>
          </Grid>
          <Grid className={classes.statsItem} item>
            <Typography color="textSecondary" display="inline" variant="body2">
              {product.total}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProductCard;