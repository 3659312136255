import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import {
  createCityPartnercouponAction,
  getAllPartnercouponids
} from './../../store/actions/citypartnercouponAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const AddCitypartnercoupon = ({ className, state, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // componentDidMount
  useEffect(() => {
    dispatch(getAllPartnercouponids());
  }, []);

  const [errorMessage, setErrorMessage] = useState();
  const Citycoupon = useSelector(state => state.citypartnercoupon.citypartnercouponids.couponids);
  const isSubmitted = useSelector(state => state.citypartnercoupon.citypartnercouponCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        City_Id: state?.City_Id,
        Partner_Service_City_Id: state.Partner_Service_City_Id,
        Partner_Details_Id: state.Partner_Details_Id,
        City_Name: state.City_Name,
        Partner_Name: state.Partner_Name,
        Coupon_Id: '',
        Status: '',
        Created_By: loginEmail,
      }}

      validationSchema={
        Yup.object().shape({
          Coupon_Id: Yup.string().required('Coupon Name is required.'),
          Status: Yup.string().required('Status is required.'),
        })}

      onSubmit={(values, { setErrors, setSubmitting }) => {
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        let formValues = JSON.parse(JSON.stringify(values));
        dispatch(createCityPartnercouponAction(formValues, state, navigate, setErrorMessage));
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (<form onSubmit={handleSubmit}>
        <ConnectedFocusError />
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                {/* Coupon_Id */}
                <TextField
                  error={Boolean(touched.Coupon_Id && errors.Coupon_Id)}
                  fullWidth
                  helperText={touched.Coupon_Id && errors.Coupon_Id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Coupon Name"
                  InputLabelProps={{ shrink: true }}
                  name="Coupon_Id"
                  select
                  SelectProps={{ native: true }}
                  value={values.Coupon_Id}
                  variant="outlined"
                >
                  <option key="" value="">--Please Select--</option>
                  {Citycoupon?.filter(item => item.Partner_Details_Id === state.Partner_Details_Id)
                    ?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                      <option key={option.Coupon_Id} value={option.Coupon_Id}>
                        {option.Name}
                      </option>
                    ))}
                </TextField>
              </Grid>

              {/* Status */}
              <Grid item md={6} xs={12}>
                <TextField
                  error={Boolean(touched.Status && errors.Status)}
                  fullWidth
                  helperText={touched.Status && errors.Status}
                  label="Status"
                  name="Status"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.Status}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                >
                  {statusOption.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {errorMessage && <Box display="flex" pl={2} >
          <span style={{ color: "red" }}>{errorMessage}</span>
        </Box>
        }
        <Box display="flex" p={2}>
          {isSubmitted ?
            <CircularProgress />
            :
            <Button
              disabled={isSubmitted}
              type="submit"
              style={{ backgroundColor: '#27B6CC' }}
              variant="contained"
            >
              {/* Save details */}
              <span style={{ color: 'white' }}>Save details</span>
            </Button>
          }
        </Box>
      </form>
      )}
    </Formik>
  );
};

AddCitypartnercoupon.propTypes = {
  className: PropTypes.string
};

export default AddCitypartnercoupon;