
import config from './config';
import { toast } from 'react-toastify';
import { paginationPartnerReview } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi} from './commonAxios';

export const USERSREVIEW_REQUEST = "USERSREVIEW_REQUEST";
export const USERSREVIEW_SUCCESS = "USERSREVIEW_SUCCESS";
export const USERSREVIEW_FAIL = "USERSREVIEW_FAIL";
export const USERREVIEW_GET_REQUEST = "USERREVIEW_GET_REQUEST";
export const USERREVIEW_GET_SUCCESS = "USERREVIEW_GET_SUCCESS";
export const USERREVIEW_GET_FAIL = "USERREVIEW_GET_FAIL";
export const USERREVIEW_CREATE_REQUEST = "USERREVIEW_CREATE_REQUEST";
export const USERREVIEW_CREATE_SUCCESS = "USERREVIEW_CREATE_SUCCESS";
export const USERREVIEW_CREATE_FAIL = "USERREVIEW_CREATE_FAIL";
export const USERREVIEW_UPDATE_REQUEST = "USERREVIEW_UPDATE_REQUEST";
export const USERREVIEW_UPDATE_SUCCESS = "USERREVIEW_UPDATE_SUCCESS";
export const USERREVIEW_UPDATE_FAIL = "USERREVIEW_UPDATE_FAIL";

/*=============================================================
                  Add product review  Action
===============================================================*/


export const addPartnerReviewAction = (formData, state, navigate) => async dispatch => {
  dispatch({
    type: USERREVIEW_CREATE_REQUEST
  });
  nodeCudApi.post(`/Reviews/Partner_Review_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USERREVIEW_GET_SUCCESS
        });
        toast('User Partner Review added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partner/user-reviews', state = { state });
      } else {
        dispatch({
          type: USERREVIEW_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USERREVIEW_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                  Update User Action
===============================================================*/
export const updateUserAction = (formData, state, navigate) => async dispatch => {
  dispatch({
    type: USERREVIEW_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Reviews/Partner_Review_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USERREVIEW_UPDATE_SUCCESS
        });
        toast('User Partner Review updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partner/user-reviews', state = { state });
      } else {
        dispatch({
          type: USERREVIEW_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USERREVIEW_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

export const getAllUserReviewAction = formData => async dispatch => {
  try {
    dispatch({
      type: USERSREVIEW_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Reviews/Partner_Review_Get_Admin?PageNo=0&PageSize=${config.pageSize}&SortBy=PARTNER_EMAIL_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: USERSREVIEW_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: USERSREVIEW_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: USERSREVIEW_FAIL,
      payload: err
    });
  }
};

/*=============================================================
               Delete User Action
===============================================================*/
export const deleteUserAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(
      `/Reviews/Partner_Review_Delete`,
      formData);
    if (data) {

      toast('User review deleted successfully.',
        { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationPartnerReview(filters, pagination, callBackPagination));

    }
  } catch (err) { }
};