import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useSelector } from 'react-redux';
import Page from 'src/components/Page';
import EditCitypartnerdeal from './EditCitypartnerdeal';
import isEmpty from './../../store/validations/is-empty';
import { useLocation } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const EditCitypartnerdealView = () => {
  const location = useLocation();
  const { state } = location;
  const { data } = state.data;
  const classes = useStyles();

  const [allcitypartnerdeal, setCitypartnerdeal] = useState([]);

  let citypartnerdeals = useSelector(state => state.citypartnerdeal.allcitypartnerdeal);
  useEffect(() => {
    if (!isEmpty(citypartnerdeals)) {
      setCitypartnerdeal(citypartnerdeals.citypartnerdeal);
    }
  }, [citypartnerdeals]);

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/sbcm/city-deal-management"
          variant="text"
          size="small"
          color="inherit"
          state={state.state}
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Edit City Deal
          </Typography>
        </Box>
        <EditCitypartnerdeal allcitypartnerdeal={allcitypartnerdeal} state={state.state} data={state.data} />
      </Container>
    </Page>
  );
};

export default EditCitypartnerdealView;