import React from 'react';
import { TablePagination } from '@material-ui/core';

function CorpPagination(props) {
    //props variable consists of all <List.Accordion> properties along with map data.
    const { pagination, handlePageChange, handleLimitChange } = props;

    return (
        (pagination?.totalPages && pagination?.totalPages >= 1) ?
            <TablePagination
                component="div"
                count={pagination?.totalResults}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={pagination?.page - 1}
                rowsPerPage={pagination?.limit}
                rowsPerPageOptions={[5, 10, 20]}
            />
            :
            <TablePagination
                component="div"
                count={0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={0}
                rowsPerPage={10}
                rowsPerPageOptions={[5, 10, 20]}
            />
    );
}

export default CorpPagination;