import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  InputAdornment,
  SvgIcon,
  TextField,
  makeStyles,
  Button
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { SubscriptionUserFilter } from '../../store/actions/subscriptionuserAction';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  formFilterControl: {
    margin: theme.spacing(1),
    minWidth: 200
  }
}));

const Toolbar = ({ className, state, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let subscriptionMenu = useSelector(state => state.subscription.subscriptionsFilter.select);
  let subscriptionSearch = useSelector(state => state.subscription.subscriptionsFilter.search);

  const handleChange = event => {
    dispatch(SubscriptionUserFilter('select', event.target.value));
  };
  const handleSearch = event => {
    dispatch(SubscriptionUserFilter('search', event.target.value));
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" flexWrap="wrap" mb={2}>
          <FormControl variant="outlined" className={classes.formFilterControl}>
            <Select
              value={subscriptionMenu}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Subscription_User_Auto_Id">Subscription User Auto ID</MenuItem>
              <MenuItem value="User_Email_Id">User Email ID</MenuItem>
              <MenuItem value="Schedule_Type">Schedule Type</MenuItem>
              <MenuItem value="Subscription_Id">Subscription ID</MenuItem>
              <MenuItem value="Subscription_Package_Id">Subscription ID</MenuItem>
              <MenuItem value="Subscription_Status">Status</MenuItem>
            </Select>
          </FormControl>
          <Box width={400}>
            <TextField onChange={handleSearch}
              className={classes.formControl}
              value={subscriptionSearch}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search Subscription Product Users"
              variant="outlined"
            />
          </Box>
        </Box>
        <Button
          component={RouterLink}
          to="/app/add-subscriptionproductuser"
          color="primary"
          variant="contained"
          style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }} state={state}
        >
          <span style={{ color: "white" }}>
            + ADD SUBSCRIPTION PRODUCT USERS
          </span>
        </Button>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;