import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  avatar: {
    cursor: 'pointer',
    width: 58,
    height: 58
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ProductCard = ({ className, product, rolePermission, ...rest }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const Login_Details = useSelector(state => state.auth.user);

  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  useEffect(() => {
    dispatch(getRolePermissions(Login_Details));
    setSeconds(5);
    if (product.title === "Dashboard") {
      product.hide = rolePermission.Reports_Dashboard_Hide?.Is_Hide;
    } else if (product.title === "Budget") {
      product.hide = rolePermission.Reports_Budget_Hide?.Is_Hide;
    } else if (product.title === "Users") {
      product.hide = rolePermission.Reports_Users_Hide?.Is_Hide;
    } else if (product.title === "Partners") {
      product.hide = rolePermission.Reports_Partners_Hide?.Is_Hide;
    } else if (product.title === "Brands") {
      product.hide = rolePermission.Reports_Brands_Hide?.Is_Hide;
    } else if (product.title === "Coupons") {
      product.hide = rolePermission.Reports_Coupons_Hide?.Is_Hide;
    } else if (product.title === "Deals") {
      product.hide = rolePermission.Reports_Deals_Hide?.Is_Hide;
    } else if (product.title === "Products") {
      product.hide = rolePermission.Reports_Products_Hide?.Is_Hide;
    }
  }, []);

  return (
    <>
      {product.hide !== 1 &&
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Box display="flex" justifyContent="center" mb={3}>
              <Avatar
                className={classes.avatar}
                component={RouterLink}
                alt="Product"
                src={product.media}
                variant="square"
                to={product.link}
              />
            </Box>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              {product.title}
            </Typography>
            <Typography style={{ paddingTop: "5px" }} align="justify" color="textPrimary" variant="body1">
              {product.description}
            </Typography>
          </CardContent>
          <Box flexGrow={1} />
          <Divider />
        </Card>
      }
    </>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProductCard;