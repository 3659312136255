import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { updateZoneThemeProductsAction, getAllMFHProducts, getAllZoneTheme } from 'src/store/actions/meepaisazonethemeproductsAction';
import { CircularProgress } from '@mui/material';

const EditZoneThemeProduct = ({ className, ...rest }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = location;
    const isSubmitted = useSelector(state => state.zonethemeproduct.zonethemeproductUpdate.isLoading);
    const errorMessage = useSelector(state => state.zonethemeproduct.zonethemeproductUpdate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    //get mfh products
    const mfhzonethemeproducts = useSelector(state => state.zonethemeproduct.mfhproducts.all);
    const zonetheme = useSelector(state => state.zonethemeproduct.zonetheme.all);

    //all store type names
    useEffect(() => {
        dispatch(getAllMFHProducts());
        dispatch(getAllZoneTheme());
    }, []);


    return (
        <Formik
            initialValues={{
                Modified_By: loginEmail,
                Is_Active: state.Is_Active > 0 ? true : false,
                Product_Id: state.Product_Id ? state.Product_Id : "",
                Zone_Theme_Id: state.Zone_Theme_Id ? state.Zone_Theme_Id : "",
                Zone_Theme_Product_Id: state.Zone_Theme_Product_Id ? state.Zone_Theme_Product_Id : "",
            }}
            validationSchema={
                Yup.object().shape({
                    Product_Id: Yup.string().required('Product Name is required'),
                    Zone_Theme_Id: Yup.string().required('Zone Theme Name is required'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    if (values.Is_Active) {
                        formValues.Is_Active = 1;
                    } else {
                        formValues.Is_Active = 0;
                    }
                    dispatch(updateZoneThemeProductsAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        {/* Product_Id */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Product_Id && errors.Product_Id)}
                                                fullWidth
                                                helperText={touched.Product_Id && errors.Product_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Product Name"
                                                name="Product_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Product_Id}
                                                variant="outlined"
                                            ><option key="" value="">--Please Select--</option>
                                                {mfhzonethemeproducts?.sort((a, b) => a.Product_Name?.localeCompare(b.Product_Name))?.map(option => (
                                                    <option key={option.Product_Id} value={option.Product_Id}>
                                                        {option.Product_Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {/* Zone_Theme_Id */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Zone_Theme_Id && errors.Zone_Theme_Id)}
                                                fullWidth
                                                helperText={touched.Zone_Theme_Id && errors.Zone_Theme_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Zone Theme Name"
                                                name="Zone_Theme_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Zone_Theme_Id}
                                                variant="outlined"
                                            ><option key="" value="">--Please Select--</option>
                                                {zonetheme?.sort((a, b) => a.Zone_Theme_Name?.localeCompare(b.Zone_Theme_Name))?.map(option => (
                                                    <option key={option.Zone_Theme_Id} value={option.Zone_Theme_Id}>
                                                        {option.Zone_Name + " - " + option.Zone_Theme_Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {/* Is_Active */}
                                        <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.Is_Active}
                                                        onChange={handleChange}
                                                        name="Is_Active"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Active"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>
                        <Box display="flex" p={2}>
                            {isSubmitted ? <CircularProgress /> :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>}
                        </Box>
                    </Card>
                </form>)}
        </Formik>);
};

EditZoneThemeProduct.propTypes = {
    className: PropTypes.string
};

export default EditZoneThemeProduct;