import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  InputLabel,
  Card,
  CardContent,
  Grid,
  TextField,
  Avatar,
  FormControl,
  Select,
  makeStyles,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { ConnectedFocusError } from 'focus-formik-error';
import { VarientuploadImagepath } from 'src/store/actions/servicesvarientAction';
import { AddServiceRequestAction } from 'src/store/actions/servicepartnerRequestAction';
import {
  paginationsubcategoryServices,
  paginationrequestServices,
  paginationServicescategory
} from 'src/store/actions/node-actions/nodepagination';
import { getAllServiceCategories } from 'src/store/actions/servicepartnerRequestAction';


const ServiceOption = [

  {
    value: 'Services',
    label: 'Services'
  },
  {
    value: 'Sub Services',
    label: 'Sub Services'
  },
  {
    value: 'Variants',
    label: 'Variants'
  }
];

const StatusOption = [

  {
    value: 'success',
    label: 'success'
  },
  {
    value: 'pending',
    label: 'pending'
  },
  {
    value: 'hold',
    label: 'hold'
  },
  {
    value: 'rejected',
    label: 'rejected'
  }
];


const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  Description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddServiceRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getAllServiceCategories());
  }, []);

  let { state } = location;
  const [type, setType] = useState('');
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ Request_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  // const serviceData = location.state?.serviceData || {};
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [services, setServices] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [Requests, setRequest] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [hasMore1, setHasMore1] = useState(true);
  const [ServiceId, setSeriveceId] = useState();
  const isSubmitted = useSelector(
    state => state.category.categoryUpdate.isLoading
  );
  const createdBy = useSelector(state => state.auth.user.Email_Id);

  const initialValues = {
    Image: '',
    Request_Type: '',
    Request_Name: '',
    Description: '',
    Status_Feedback: '',
    Created_By: '',
    Service_Id: '',
    Ss_Id: '',
    Status: '',
  };

  const callBackUploadImgae = data => {
    if (data) {

      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = (e) => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });

    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      VarientuploadImagepath(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = () => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {

        setLoading(true);
        await dispatch(
          paginationServicescategory(
            {},
            { PageNo: currentPage, PageSize: 21 },
            (status, data, pagination) => {
              if (status) {
                if (Array.isArray(data)) {
                  setServices((prev) => [...prev, ...data]);
                  setHasMore(currentPage < pagination.TotalPages);
                } else {

                }
              }
            }
          )
        );
      } catch (error) {

      } finally {

        setLoading(false);
      }
    };

    fetchServices();
  }, [currentPage, dispatch]);


  useEffect(() => {
    const fetchSubcategoryServices = async () => {
      if (!ServiceId) return;


      if (true) {
        try {
          setLoading1(true);
          await dispatch(
            paginationsubcategoryServices(
              { Service_Id: ServiceId || "" },
              { PageNo: currentPage1, PageSize: 14 },
              (status, data, pagination1) => {

                if (status) {
                  if (Array.isArray(data)) {
                    setRequest((prev) => [...prev, ...data]);
                    setHasMore1(currentPage1 < pagination1?.TotalPages);
                  } else {

                  }
                }
              }
            )
          );
        } catch (error) {

        } finally {

          setLoading1(false);
        }
      }
    };

    fetchSubcategoryServices();
  }, [currentPage1, ServiceId, dispatch]);

  useEffect(() => {
    if (ServiceId) {
      setCurrentPage1(1);
      setRequest([]);
      setHasMore1(true);
    }
  }, [ServiceId]);

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
      setCurrentPage((prevPage) => prevPage + 1);

    }
  };

  const handleScroll1 = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore1 && !loading1) {
      setCurrentPage1((prevPage) => prevPage + 1);
    }
  };

  return (
    <Formik
      initialValues={{
        Image: '',
        Request_Type: '',
        Request_Name: '',
        Description: '',
        Created_By: '',
        Service_Id: '',
        Ss_Id: '',
        Status: '',
      }}
      validationSchema={Yup.object().shape({
        Request_Type: Yup.string().required("Request type is required."),
        Request_Name: Yup.string()
          .min(3, 'Request name must be at least 3 characters.')
          .required('Request name is required.'),
        Description: Yup.string()
          .min(20, 'Description must be at least 20 characters.')
          .required('Description is required.'),
        Status: Yup.string().required('Status is required.'),
        Service_Id: Yup.string().when('Request_Type', {
          is: (value) => value === 'Sub Services' || value === 'Variants',
          then: Yup.string().required('Service name is required.'),
        }),
        Ss_Id: Yup.string().when('Request_Type', {
          is: (value) => value === 'Variants',
          then: Yup.string().required('Sub-service name is required.'),
        }),

      })}
      onSubmit={(values, { setSubmitting, setErrors }) => {

        setSubmitting(false);
        let submitTest = true;
        const errors = {};
        values.Image = imgUrl;

        if (!values.Image) {
          errors.Image = "Image is required.";
          submitTest = false;
        }
        setErrors(errors);
        if (submitTest) {
          values.Created_By = createdBy
          let formValues = JSON.parse(JSON.stringify(values));

          dispatch(AddServiceRequestAction(formValues, navigate, setErrorMessage, setErrors));
        }
      }}
    >
      {({ handleSubmit, handleBlur, handleChange, setFieldValue, errors, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box>
                <Grid container spacing={4}>
                  {/* Form Fields - Left Side */}
                  <Grid item xs={12} md={8}>
                    <Box>
                      <Box
                        sx={{
                          minWidth: 120,
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: 4
                        }}
                      >
                        {/* Type Select */}
                        <Box sx={{ minWidth: '50%' }}>
                          <FormControl fullWidth variant="outlined">

                            <Autocomplete
                              options={ServiceOption}
                              getOptionLabel={(option) => option.label}
                              value={ServiceOption.find(option => option.value === values.Request_Type) || null}
                              onChange={(e, newValue) => {
                                setFieldValue("Request_Type", newValue?.value || '');
                                setType(newValue?.value || '');
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Request Type"
                                  error={Boolean(touched.Request_Type && errors.Request_Type)}
                                  helperText={touched.Request_Type && errors.Request_Type}
                                  onBlur={handleBlur}
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          </FormControl>
                        </Box>

                        {/* Conditionally Rendered Fields */}
                        {type === 'Services' && (
                          <Box sx={{ flex: 1 }}>
                            <TextField
                              error={Boolean(touched.Request_Name && errors.Request_Name)}
                              fullWidth
                              helperText={touched.Request_Name && errors.Request_Name}
                              onBlur={handleBlur}

                              onChange={(e) => {
                                setFieldValue("Request_Name", e.target.value);
                              }}

                              label="Service Name"
                              name="Request_Name"
                              value={values.Request_Name}
                              variant="outlined"
                            />
                          </Box>
                        )}
                        {type === 'Sub Services' && (
                          <Box sx={{ flex: 1 }}>
                            <TextField
                              error={Boolean(touched.Request_Name && errors.Request_Name)}
                              fullWidth
                              helperText={touched.Request_Name && errors.Request_Name}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue("Request_Name", e.target.value);
                              }}
                              label="Sub Service Name"
                              name="Request_Name"
                              value={values.Request_Name}
                              variant="outlined"
                            />
                          </Box>
                        )}
                        {type === 'Variants' && (
                          <Box sx={{ flex: 1 }}>
                            <TextField
                              error={Boolean(touched.Request_Name && errors.Request_Name)}
                              fullWidth
                              helperText={touched.Request_Name && errors.Request_Name}
                              onBlur={handleBlur}

                              onChange={(e) => {
                                setFieldValue("Request_Name", e.target.value);
                              }}
                              label="Variant Name"
                              name="Request_Name"
                              value={values.Request_Name}
                              variant="outlined"
                            />
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          minWidth: 120,
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: 4,
                          marginTop: 12,
                          marginBottom: "12px"
                        }}>

                        {(type === 'Sub Services' || type === 'Variants') && (
                          <Box sx={{ flex: 1, minWidth: '50%', marginTop: '-16px' }}>
                            <FormControl fullWidth variant="outlined">
                              <Autocomplete
                                options={services}
                                getOptionLabel={(option) => option.Service_Name || ""}
                                onChange={(event, newValue) => {

                                  if (newValue) {
                                    setSeriveceId(newValue.Service_Id);
                                    setFieldValue("Service_Id", newValue.Service_Id);
                                    setFieldValue("Service_Name", newValue.Service_Name);
                                  }
                                }}
                                value={
                                  services.length > 0
                                    ? services.find((service) => service.Service_Id === values.Service_Id) || null
                                    : null
                                }
                                isOptionEqualToValue={(option, value) => option.Service_Id === value.Service_Id}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Service Name"
                                    variant="outlined"
                                    error={Boolean(touched.Service_Id && errors.Service_Id)}
                                    helperText={touched.Service_Id && errors.Service_Id}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="normal"
                                  />
                                )}
                                sx={{
                                  width: '100%',
                                  '& .MuiAutocomplete-paper': {
                                    maxHeight: 50,
                                    overflow: 'auto',
                                  },
                                }}
                                ListboxProps={{
                                  onScroll: handleScroll,
                                }}
                                loading={loading}
                              />

                            </FormControl>
                          </Box>
                        )}

                        {type === 'Variants' && (
                          <Box sx={{ flex: 1, minWidth: '50%', marginTop: '-16px' }}>
                            <FormControl fullWidth variant="outlined">
                              {ServiceId !== undefined && (
                                <Autocomplete
                                  options={Requests}
                                  getOptionLabel={(option) => option.Ss_Name || ""}
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      setFieldValue("Ss_Id", newValue.Ss_Id);
                                      setFieldValue("Service_Id", newValue.Service_Id)
                                    }
                                  }}
                                  value={
                                    Requests.length > 0
                                      ? Requests.find((request) => request.Ss_Id === values.Ss_Id) || null
                                      : null
                                  }
                                  isOptionEqualToValue={(option, value) => option.Ss_Id === value.Ss_Id}
                                  loading={loading1}
                                  ListboxProps={{
                                    onScroll: handleScroll1,
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Sub-Service-name"
                                      variant="outlined"
                                      error={Boolean(touched.Ss_Id && errors.Ss_Id)}
                                      helperText={touched.Ss_Id && errors.Ss_Id}
                                      onBlur={(e) => {
                                        handleBlur(e);
                                      }}
                                      fullWidth
                                      margin="normal"
                                    />
                                  )}
                                  sx={{
                                    width: '100%',
                                    '& .MuiAutocomplete-paper': {
                                      maxHeight: 50,
                                      overflow: 'auto',
                                    },
                                  }}
                                />
                              )}

                            </FormControl>
                          </Box>
                        )}

                      </Box>

                      {/* Description Field */}
                      <Grid item md={12} xs={12}>
                        <Box>
                          <TextareaAutosize
                            aria-label="description"
                            placeholder="Description"
                            minRows={10}
                            style={{ width: '100%' }}
                            value={values.Description}
                            onChange={(e) => setFieldValue("Description", e.target.value)}
                          />
                        </Box>
                        {errors.Description && touched.Description && (
                          <Box style={{ color: 'red' }}>{errors.Description}</Box>
                        )}
                      </Grid>

                      {/* Status Field */}
                      <Grid item md={12} xs={12}>
                        <FormControl fullWidth variant="outlined" style={{ marginTop: '10px' }}>
                          <Autocomplete
                            error={Boolean(touched.Status && errors.Status)}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={(event, newValue) => {
                              setFieldValue("Status", newValue ? newValue.value : '');
                            }}
                            value={StatusOption.find(option => option.value === values.Status) || null}
                            options={StatusOption}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Status"
                                name="Status"
                                variant="outlined"
                                helperText={touched.Status && errors.Status}
                                error={Boolean(touched.Status && errors.Status)}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Box>
                  </Grid>

                  {/* Form Fields - Right Side */}
                  <Grid item md={4} xs={12}>
                    <Card>
                      &nbsp;Web Image
                      <CardContent>
                        <Box display="flex" justifyContent="center">
                          {image.preview ? (
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src={image.preview}
                              variant="square"
                            />
                          ) : (
                            <>
                              <Avatar
                                className={classes.images}
                                alt="Brand Upload"
                                src="/static/images/image-placeholder.png"
                                variant="square"
                              />
                            </>
                          )}
                        </Box>

                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          {image.preview ? (<Button
                            style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                            onClick={handleRemoveImage}
                          >
                            Remove Picture
                          </Button>) : (<input
                            style={{ display: 'block', width: '80%' }}
                            id="upload-photo"
                            name="Image"
                            type="file"
                            accept="image/*"
                            onChange={handleImage}
                          />)}
                          <br />
                        </Box>
                      </CardContent>
                    </Card>
                    <Typography color="error" variant="body2">
                      {!imgUrl && touched.Image && errors.Image ? "Image is required" : ""}
                    </Typography>
                  </Grid>
                </Grid>

                {/* Submit Button */}
                <Box display="flex" marginTop={4}>
                  {isSubmitted ? (
                    <CircularProgress />
                  ) : (

                    <Button
                      disabled={isSubmitted || uploadPic}
                      type="submit"
                      variant="contained"
                      style={{ backgroundColor: '#27B6CC' }}>
                      <span style={{ color: 'white' }}>Submit</span>

                    </Button>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddServiceRequest.propTypes = {
  className: PropTypes.string
};

export default AddServiceRequest;
