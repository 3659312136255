import {
  PROMOTIONAL_CREATE_REQUEST,
  PROMOTIONAL_CREATE_SUCCESS,
  PROMOTIONAL_CREATE_FAIL,
  PROMOTIONAL_UPDATE_REQUEST,
  PROMOTIONAL_UPDATE_SUCCESS,
  PROMOTIONAL_UPDATE_FAIL,
} from '../actions/promotionalAction'


const initialState = {
  promotionalCreate: { promotional: {}, error: '', isLoading: false },
  promotionalUpdate: { promotional: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROMOTIONAL_CREATE_REQUEST:
      return { ...state, promotionalCreate: { promotional: {}, error: '', isLoading: true } };
    case PROMOTIONAL_CREATE_SUCCESS:
      return { ...state, promotionalCreate: { promotional: state.promotionalCreate.promotional, error: '', isLoading: false } };
    case PROMOTIONAL_CREATE_FAIL:
      return { ...state, promotionalCreate: { promotional: {}, error: action.payload, isLoading: false } };
    case PROMOTIONAL_UPDATE_REQUEST:
      return { ...state, promotionalUpdate: { promotional: {}, error: '', isLoading: true } };
    case PROMOTIONAL_UPDATE_SUCCESS:
      return { ...state, promotionalUpdate: { promotional: action.payload, error: '', isLoading: false } };
    case PROMOTIONAL_UPDATE_FAIL:
      return { ...state, promotionalUpdate: { promotional: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}