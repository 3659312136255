import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {createdeliveryTicketAction, getAlldeliveryprofilesAction} from '../../store/actions/deliveryticketActions';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";

const IssuerelatedOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Order',
    label: 'Order'
  },
  {
    value: 'Payment',
    label: 'Payment'
  },
  {
    value: 'Delivery',
    label: 'Delivery'
  },
  {
    value: 'Product',
    label: 'Product'
  },
  {
    value: 'Others',
    label: 'Others'
  }
];

const useStyles = makeStyles(() => ({
  root: {},
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddDeliveryTicket = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const errorMessage = useSelector(state => state.deliverytickets.deliveryticketCreate.error);
  const isSubmitted = useSelector(state => state.deliverytickets.deliveryticketCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const users = useSelector(state => state.deliverytickets.deliveryusers.users);
  // Richtext editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(2);
  const [rhDiscription, setRhDiscription] = useState('');
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  useEffect(() => {
    dispatch(getAlldeliveryprofilesAction());
  }, []);

  return (
    <Formik
      initialValues={{
        Attachment: '',
        Del_User_Id: '',
        Description: '',
        Issue_Related_To: '',
        Subject: '',
        Support_Owner: loginEmail,
      }}
      validationSchema={
        Yup.object().shape({
          Subject: Yup.string().min(2, "Ticket Subject should have atleast 2 characters.").required("Ticket Subject is required."),
          Attachment: Yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
            'Please provide valid URL.'
          ).required('Attachment URL is required.'),
          Issue_Related_To: Yup.string().required('Issue Related To is required.'),
          Del_User_Id: Yup.string().required('Ticket Owner is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        values.Description = rhDiscription;
        if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(createdeliveryTicketAction(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      {/* <TextField
                        error={Boolean(touched.Del_User_Id && errors.Del_User_Id)}
                        fullWidth
                        helperText={touched.Del_User_Id && errors.Del_User_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ticket Owner"
                        name="Del_User_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Del_User_Id}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {users?.sort((a, b) => a.Del_User_Email_Id?.localeCompare(b.Del_User_Email_Id))?.map(option => (
                          <option key={option.Del_User_Detail_Id} value={option.Del_User_Email_Id}>
                            {option.Del_User_Email_Id}
                          </option>
                        ))}
                      </TextField> */}
                       <Autocomplete
                                                freeSolo
                                                options={users}
                                                groupBy={(option) => option.firstLetter}
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option.Del_User_Email_Id  }
                                                renderInput={(params) => <TextField {...params}
                                                    error={Boolean(touched.Del_User_Id && errors.Del_User_Id)}
                                                    helperText={touched.Del_User_Id && errors.Del_User_Id}
                                                    onBlur={handleBlur}
                                                    label="Del User Email ID"
                                                    name="Del_User_Id"
                                                    variant="outlined"
                                                    value={values.Del_User_Id}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        dispatch(getAlldeliveryprofilesAction(params.inputProps.value))
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill,
                                                        startAdornment: (
                                                            <React.Fragment>
                                                                <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                {params.InputProps.startAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />}
                                                onChange={(e, value) => {
                                                    setFieldValue("Del_User_Id", value?.Del_User_Email_Id ? value.Del_User_Email_Id : "");
                                                }}
                                            />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Subject && errors.Subject)}
                        fullWidth
                        helperText={touched.Subject && errors.Subject}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ticket Subject"
                        name="Subject"
                        value={values.Subject}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Issue_Related_To  */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Issue_Related_To && errors.Issue_Related_To)}
                        fullWidth
                        helperText={touched.Issue_Related_To && errors.Issue_Related_To}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Issue Related To"
                        name="Issue_Related_To"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Issue_Related_To}
                        variant="outlined"
                      >
                        {IssuerelatedOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Attachment */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Attachment && errors.Attachment)}
                        fullWidth
                        helperText={touched.Attachment && errors.Attachment}
                        onBlur={handleBlur}
                        type="url"
                        onChange={handleChange}
                        label="Attachment URL"
                        name="Attachment"
                        value={values.Attachment}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Discription  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmitted ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmitted}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddDeliveryTicket.propTypes = {
  className: PropTypes.string
};

export default AddDeliveryTicket;