import {
    PROMOTIONDATEANDCOUNT_REQUEST,
    PROMOTIONDATEANDCOUNT_SUCCESS,
    PROMOTIONDATEANDCOUNT_FAIL,

} from '../actions/promotiondynamicActions'

const initialState = {
    promotiondateandcounts: { all: [], error: '', isLoading: false },
};
export default function (state = initialState, action) {
    switch (action.type) {
        case PROMOTIONDATEANDCOUNT_REQUEST:
            return { ...state, promotiondateandcounts: { all: state.promotiondateandcounts.all, error: '', isLoading: true } };
        case PROMOTIONDATEANDCOUNT_SUCCESS:
            return { ...state, promotiondateandcounts: { all: action.payload, error: '', isLoading: false } };
        case PROMOTIONDATEANDCOUNT_FAIL:
            return { ...state, promotiondateandcounts: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}

