import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    Avatar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { updateRideVehiTypesAction, uploadVehicleTypeImage } from 'src/store/actions/ridevehicletypesAction';
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';

const useStyles = makeStyles(() => ({
    images: {
        width: "100%",
        height: 100,
        borderRadius: 5,
        marginBottom: 15
    },
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const vehicletypeOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'BIKE',
        label: 'BIKE'
    },
    {
        value: 'AUTO',
        label: 'AUTO'
    },
    {
        value: 'CAR',
        label: 'CAR'
    },
    {
        value: 'TEMPO',
        label: 'TEMPO'
    },
    {
        value: 'BUS',
        label: 'BUS'
    },
    {
        value: 'TRUCK',
        label: 'TRUCK'
    }
];

const comforttypeOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'HATCH BACK',
        label: 'HATCH BACK'
    },
    {
        value: 'SEDAN',
        label: 'SEDAN'
    },
    {
        value: 'SUV',
        label: 'SUV'
    },
    {
        value: 'PREMIUM',
        label: 'PREMIUM'
    },
    {
        value: 'LUXURY',
        label: 'LUXURY'
    },
];

const bikeOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'BIKE',
        label: 'BIKE'
    },
];

const autoOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'AUTO',
        label: 'AUTO'
    },
];

const tempoOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'TEMPO',
        label: 'TEMPO'
    },
];

const busOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'BUS',
        label: 'BUS'
    },
];

const truckOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'TRUCK',
        label: 'TRUCK'
    },
];

const EditRideVehicleType = ({ className, ...rest }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    let { state } = location;

    const isSubmited = useSelector(state => state.ridevehicletypes.ridevehitypeUpdate.isLoading);
    const errorMessage = useSelector(state => state.ridevehicletypes.ridevehitypeUpdate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    // Richtext editor
    const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Description || "").contentBlocks);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
    const [rhDiscription, setRhDiscription] = useState(state.Description);

    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    // img upload
    const [image, setImage] = useState({ preview: state.Vehicle_Comfort_Image_URL, raw: state.Vehicle_Comfort_Image_URL });
    const [imgUrl, setImgUrl] = useState(state.Vehicle_Comfort_Image_URL);
    const [uploadPic, setUploadPic] = useState(false);

    const callBackUploadImage = data => {
        if (data) {
            setImgUrl(data.File_URL);
        }
        setUploadPic(false);
    };

    const handleImage = e => {
        setUploadPic(true);
        e.preventDefault();
        setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0]
        });
        const data = new FormData();
        data.append('file', e.target.files[0]);
        dispatch(
            uploadVehicleTypeImage(new Blob([e.target.files[0]]), callBackUploadImage)
        );
    };

    const handleRemoveImage = e => {
        setImgUrl('');
        setImage({
            preview: '',
            raw: ''
        });
    };

    return (
        <Formik
            initialValues={{
                Ride_Vehicle_Type_Id: state.Ride_Vehicle_Type_Id ? state.Ride_Vehicle_Type_Id : "",
                Vehicle_Type: state.Vehicle_Type ? state.Vehicle_Type : "",
                Vehicle_Comfort_Type: state.Vehicle_Comfort_Type ? state.Vehicle_Comfort_Type : "",
                SGST_Percentage: state.SGST_Percentage ? state.SGST_Percentage : "",
                IGST_Percentage: state.IGST_Percentage ? state.IGST_Percentage : "",
                CGST_Percentage: state.CGST_Percentage ? state.CGST_Percentage : "",
                Description: state.Description ? state.Description : "",
                Vehicle_Comfort_Image_URL: imgUrl,
                Modified_By: loginEmail
            }}
            validationSchema={
                Yup.object().shape({
                    Vehicle_Type: Yup.string().required('Vehicle Type is required.'),
                    Vehicle_Comfort_Type: Yup.string().required('Vehicle Comfort Type is required.'),
                    SGST_Percentage: Yup.number().min(0, "SGST Percentage should not be less than 0.").max(100, 'SGST Percentage must be less than 100.').required('SGST Percentage is required.'),
                    CGST_Percentage: Yup.number().min(0, "CGST Percentage should not be less than 0.").max(100, 'CGST Percentage must be less than 100.').required('CGST Percentage is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};

                values.Description = rhDiscription;
                values.Vehicle_Comfort_Image_URL = imgUrl;

                if (!values.Vehicle_Comfort_Image_URL) {
                    submitTest = false;
                    errors.Vehicle_Comfort_Image_URL = "Image is required.";
                }

                if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
                    submitTest = false;
                    errors.Description = "Description is required.";
                }
                else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
                    submitTest = false;
                    errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
                }

                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(updateRideVehiTypesAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Vehicle_Type && errors.Vehicle_Type)}
                                                fullWidth
                                                helperText={touched.Vehicle_Type && errors.Vehicle_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Vehicle Type"
                                                name="Vehicle_Type"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Vehicle_Type}
                                                variant="outlined"
                                            >
                                                {vehicletypeOption?.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Vehicle_Comfort_Type && errors.Vehicle_Comfort_Type)}
                                                fullWidth
                                                helperText={touched.Vehicle_Comfort_Type && errors.Vehicle_Comfort_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Vehicle Comfort Type"
                                                name="Vehicle_Comfort_Type"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Vehicle_Comfort_Type}
                                                variant="outlined"
                                            >
                                                {values.Vehicle_Type === "BIKE" ?
                                                    bikeOption?.map(option => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))
                                                    :
                                                    values.Vehicle_Type === "AUTO" ?
                                                        autoOption?.map(option => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))
                                                        :
                                                        values.Vehicle_Type === "TEMPO" ?
                                                            tempoOption?.map(option => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))
                                                            :
                                                            values.Vehicle_Type === "BUS" ?
                                                                busOption?.map(option => (
                                                                    <option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))
                                                                :
                                                                values.Vehicle_Type === "TRUCK" ?
                                                                    truckOption?.map(option => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))
                                                                    :
                                                                    comforttypeOption?.map(option => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))
                                                }
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.CGST_Percentage && errors.CGST_Percentage)}
                                                fullWidth
                                                helperText={touched.CGST_Percentage && errors.CGST_Percentage}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="CGST Percentage"
                                                name="CGST_Percentage"
                                                value={values.CGST_Percentage}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.SGST_Percentage && errors.SGST_Percentage)}
                                                fullWidth
                                                helperText={touched.SGST_Percentage && errors.SGST_Percentage}
                                                onBlur={handleBlur}
                                                onChange={e => {
                                                    let IGST_Percentage = values.CGST_Percentage + Number(e.target.value)
                                                    setFieldValue("SGST_Percentage", e.target.value);
                                                    setFieldValue("IGST_Percentage", IGST_Percentage);
                                                }}
                                                label="SGST Percentage"
                                                name="SGST_Percentage"
                                                value={values.SGST_Percentage}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="IGST Percentage"
                                                name="IGST_Percentage"
                                                value={values.IGST_Percentage}
                                                variant="outlined"
                                                type='number'
                                                disabled
                                            />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <Box className={classes.description}>
                                                Description
                                                <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                                            </Box>
                                            <span style={{ color: "red" }}>
                                                {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                                            </span>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                {/* upload photo */}
                                <Grid item md={4} xs={12}>
                                    <Card>
                                        &nbsp;Image
                                        <CardContent>
                                            <Box display="flex" justifyContent="center">
                                                {image.preview ? (
                                                    <Avatar
                                                        className={classes.images}
                                                        alt="Vehicle Comfort Image Upload"
                                                        src={image.preview}
                                                        variant="square"
                                                    />
                                                ) : (
                                                    <>
                                                        <Avatar
                                                            className={classes.images}
                                                            alt="Vehicle Comfort Image Upload"
                                                            src="/static/images/image-placeholder.png"
                                                            variant="square"
                                                        />
                                                    </>
                                                )}
                                            </Box>

                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            >
                                                {image.preview ? <Button
                                                    style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                                                    onClick={handleRemoveImage}
                                                >
                                                    Remove Picture
                                                </Button> : <input
                                                    style={{ display: 'block', width: '80%' }}
                                                    id="upload-photo"
                                                    name="image"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImage}
                                                />}
                                                <br />
                                            </Box>

                                        </CardContent>
                                    </Card><br />

                                    <span style={{ color: "red" }}>
                                        {!imgUrl && touched.Vehicle_Comfort_Image_URL && errors.Vehicle_Comfort_Image_URL}
                                    </span>

                                </Grid>
                            </Grid>
                        </CardContent>

                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited || uploadPic}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditRideVehicleType.propTypes = {
    className: PropTypes.string
};

export default EditRideVehicleType;