import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Box,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { createPartnerWalletAction, getAllPartnerWalletAction, getAllPartnerEmails } from 'src/store/actions/partnerwalletAction';
import { CircularProgress } from '@mui/material';

const statusOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const AddPartnerWallet = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // componentDidMount
    useEffect(() => {
        const formData = {
            Records_Filter: 'ALL'
        };
        dispatch(getAllPartnerWalletAction(formData));
        dispatch(getAllPartnerEmails(formData));
    }, []);

    const allWallets = useSelector(state => state.partnerwallet.partnerwallets.all);
    const isSubmited = useSelector(state => state.partnerwallet.partnerwalletCreate.isLoading);
    const emails = useSelector(state => state.partnerwallet.partnerEmails.emails);
    const [errorMessage, setErrorMessage] = useState({ global: { key: '', message: '' } });

    return (
        <Formik
            initialValues={{
                Partner_Detail_Id: '',
                Is_Eligible_For_Withdraw: false,
                Status: '',
            }}
            validationSchema={
                Yup.object().shape({
                    Partner_Detail_Id: Yup.string().notOneOf(allWallets.map(item => item['Wallet_Owner']), "Partner Name already exist.").required('Partner Name is required.'),
                    Status: Yup.string().required('Status is required.'),
                })
            }
            onSubmit={(values, { setErrors }) => {
                const errors = {};
                setErrors(errors);
                let formValues = JSON.parse(JSON.stringify(values));
                if (values.Is_Eligible_For_Withdraw) {
                    formValues.Is_Eligible_For_Withdraw = 1;
                } else {
                    formValues.Is_Eligible_For_Withdraw = 0;
                }
                dispatch(createPartnerWalletAction(formValues, navigate, setErrorMessage, setErrors));
            }
            }
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        {/* Partner_Detail_Id */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Detail_Id && errors.Partner_Detail_Id)}
                                                fullWidth
                                                helperText={touched.Partner_Detail_Id && errors.Partner_Detail_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Name"
                                                name="Partner_Detail_Id"
                                                value={values.Partner_Detail_Id}
                                                variant="outlined"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                            ><option key="" value="">--Please Select--</option>
                                                {emails?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                                                    <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                                                        {option.Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        {/* Status */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        {/* Is Eligible For Withdraw */}
                                        <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        disabled
                                                        checked={values.Is_Eligible_For_Withdraw}
                                                        onChange={handleChange}
                                                        name="Is_Eligible_For_Withdraw"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Eligible for Withdrawl"
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>

                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage.global.message}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddPartnerWallet.propTypes = {
    className: PropTypes.string
};

export default AddPartnerWallet;