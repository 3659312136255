import axios from 'axios';
import config from './config';
import { toast } from 'react-toastify';

export const SUB_PACK_LOC_REQUEST = "SUB_PACK_LOC_REQUEST";
export const SUB_PACK_LOC_SUCCESS = "SUB_PACK_LOC_SUCCESS";
export const SUB_PACK_LOC_FAIL = "SUB_PACK_LOC_FAIL";

export const SUB_PACK_LOC_CREATE_REQUEST = "SUB_PACK_LOC_CREATE_REQUEST";
export const SUB_PACK_LOC_CREATE_SUCCESS = "SUB_PACK_LOC_CREATE_SUCCESS";
export const SUB_PACK_LOC_CREATE_FAIL = "SUB_PACK_LOC_CREATE_FAIL";

export const SUB_PACK_LOC_UPDATE_REQUEST = "SUB_PACK_LOC_UPDATE_REQUEST";
export const SUB_PACK_LOC_UPDATE_SUCCESS = "SUB_PACK_LOC_UPDATE_SUCCESS";
export const SUB_PACK_LOC_UPDATE_FAIL = "SUB_PACK_LOC_UPDATE_FAIL";

export const SUB_PACK_LOC_FILTER = "SUB_PACK_LOC_FILTER";

const headers = config.headersCommon;

//Get All Subscription Package Locations
export const getAllSubPackLocationsAction = formData => async dispatch => {
    try {
        dispatch({
            type: SUB_PACK_LOC_REQUEST
        });
        let { data } = await axios.post(
            `${config.url}/Subscription_Package_Locations/Subscription_Package_Locations_Get?PageNo=0&PageSize=${config.pageSize}&SortBy=SUBCRIPTION_PACKAGE_LOCATIONS_ID&SortOrder=ASC`,
            formData,
            {
                headers: headers
            }
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: SUB_PACK_LOC_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: SUB_PACK_LOC_SUCCESS,
                    payload: data.Subscription_Packages_Locations_Details
                });
            }
        }
    } catch (err) {
        dispatch({
            type: SUB_PACK_LOC_FAIL,
            payload: err
        });
    }
};

//create Subscription Package Locations
export const createSubPackLocationAction = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: SUB_PACK_LOC_CREATE_REQUEST
    });
    axios.post(`${config.url}/Subscription_Package_Locations/Subscription_Package_Locations_Create`, formData, {
        headers: headers
    })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: SUB_PACK_LOC_CREATE_SUCCESS
                });
                toast('Subscription Package Location added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/subscription-packages', state = { state });
            } else {
                dispatch({
                    type: SUB_PACK_LOC_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SUB_PACK_LOC_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};

//update Subscription Package Locations
export const updateSubPackLocationAction = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: SUB_PACK_LOC_UPDATE_REQUEST
    });
    axios.put(`${config.url}/Subscription_Package_Locations/Subscription_Package_Locations_Update`, formData, {
        headers: headers
    })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: SUB_PACK_LOC_UPDATE_SUCCESS
                });
                toast('Subscription Package Location updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/subscription-packages', state = { state });
            } else {
                dispatch({
                    type: SUB_PACK_LOC_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SUB_PACK_LOC_UPDATE_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};

//Subscription Package Locations filter
export const subPackLocationFilter = (fType, value) => {
    return function (dispatch) {
        dispatch({
            type: SUB_PACK_LOC_FILTER,
            fType: fType,
            value: value,
        });
    }
}