
import config from './config';
import { toast } from 'react-toastify';
import { paginationProductMasters } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const PRODUCTMASTERS_REQUEST = "PRODUCTMASTERS_REQUEST";
export const PRODUCTMASTERS_SUCCESS = "PRODUCTMASTERS_SUCCESS";
export const PRODUCTMASTERS_FAIL = "PRODUCTMASTERS_FAIL";
export const PRODUCTMASTER_GET_REQUEST = "PRODUCTMASTER_GET_REQUEST";
export const PRODUCTMASTER_GET_SUCCESS = "PRODUCTMASTER_GET_SUCCESS";
export const PRODUCTMASTER_GET_FAIL = "PRODUCTMASTER_GET_FAIL";
export const PRODUCTMASTER_CREATE_REQUEST = "PRODUCTMASTER_CREATE_REQUEST";
export const PRODUCTMASTER_CREATE_SUCCESS = "PRODUCTMASTER_CREATE_SUCCESS";
export const PRODUCTMASTER_CREATE_FAIL = "PRODUCTMASTER_CREATE_FAIL";
export const PRODUCTMASTER_UPDATE_REQUEST = "PRODUCTMASTER_UPDATE_REQUEST";
export const PRODUCTMASTER_UPDATE_SUCCESS = "PRODUCTMASTER_UPDATE_SUCCESS";
export const PRODUCTMASTER_UPDATE_FAIL = "PRODUCTMASTER_UPDATE_FAIL";

export const PRODUCT_BRANDS_REQUEST = "PRODUCT_BRANDS_REQUEST";
export const PRODUCT_BRANDS_SUCCESS = "PRODUCT_BRANDS_SUCCESS";
export const PRODUCT_BRANDS_FAIL = "PRODUCT_BRANDS_FAIL";

export const PRODUCT_CATEGORIES_REQUEST = "PRODUCT_CATEGORIES_REQUEST";
export const PRODUCT_CATEGORIES_SUCCESS = "PRODUCT_CATEGORIES_SUCCESS";
export const PRODUCT_CATEGORIES_FAIL = "PRODUCT_CATEGORIES_FAIL";

export const PRODUCT_SUBCATEGORIES_REQUEST = "PRODUCT_SUBCATEGORIES_REQUEST";
export const PRODUCT_SUBCATEGORIES_SUCCESS = "PRODUCT_SUBCATEGORIES_SUCCESS";
export const PRODUCT_SUBCATEGORIES_FAIL = "PRODUCT_SUBCATEGORIES_FAIL";

/*=============================================================
                  Add ProductMaster Action
===============================================================*/

export const createProductMasterAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: PRODUCTMASTER_CREATE_REQUEST
  });
  nodeCudApi.post(`/Products/Product_Master_Create`,
    formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PRODUCTMASTER_CREATE_SUCCESS
        });
        toast('Product master added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/product-management/product-masters');
      } else {
        dispatch({
          type: PRODUCTMASTER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PRODUCTMASTER_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
               update Product Master Action
===============================================================*/
export const updateProductMasterAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: PRODUCTMASTER_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Products/Product_Master_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PRODUCTMASTER_UPDATE_SUCCESS
        });
        toast('Product master updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/product-management/product-masters');
      } else {
        dispatch({
          type: PRODUCTMASTER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PRODUCTMASTER_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
             Delete ProductMaster Action
===============================================================*/
export const deleteProductMasterAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Products/Product_Master_Delete`, formData
    );
    if (data) {
      toast('Product master deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationProductMasters(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

/*=============================================================
                Get All Brands Action
===============================================================*/
export const getAllBrands = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Brand_Status: "Active",
      search_by_filter: "",
      search: "",
    };
    dispatch({
      type: PRODUCT_BRANDS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Brand/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=BRAND_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_BRANDS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_BRANDS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_BRANDS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                 Get All Categories Action
===============================================================*/
export const getAllCategories = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Status: "Active",
      search_by_filter: "",
      search: "",
    };
    dispatch({
      type: PRODUCT_CATEGORIES_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Categories/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=CATEGORY_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_CATEGORIES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_CATEGORIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_CATEGORIES_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                Get All Sub Categories Action
===============================================================*/
// export const getAllSubCategories = () => async dispatch => {
//   try {
//     let formData =
//     {
//       Records_Filter: "FILTER",
//       Status: "Active",
//       search_by_filter: "",
//       search: "",
//     };
//     dispatch({
//       type: PRODUCT_SUBCATEGORIES_REQUEST
//     });
//     let { data } = await nodeGetApi.post(
//       `/Categories/Sub_Category_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=SUB_CATEGORY_ID&SortOrder=ASC`,
//       formData
//     );
  
//     if (data) {
//       if (data.Is_Data_Exist === '0') {
//         dispatch({
//           type: PRODUCT_SUBCATEGORIES_SUCCESS,
//           payload: []
//         });
//       } else {

//         dispatch({
//           type: PRODUCT_SUBCATEGORIES_SUCCESS,
//           payload: data.results
//         });
//       }
//     }
//   } catch (err) {
//     dispatch({
//       type: PRODUCT_SUBCATEGORIES_FAIL,
//       payload: err
//     });
//   }
// };
export const getAllSubCategories = (formData, callBackData) => async dispatch => {
  try {
      let { data } = await nodeGetApi.post(`/Categories/Sub_Category_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=SUB_CATEGORY_ID&SortOrder=ASC`, formData);
      if (data) {
          if (data.Success_Response?.Is_Data_Exist === "0") {
              callBackData([])
          } else {
              callBackData(data.results)
          }
      }
  } catch (err) {
      dispatch({
          payload: err
      });
  }
};
