import { Card, CardContent, Button, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrderAddressAction, getAllOrderItemAddressAction } from 'src/store/actions/ordersAction';
import { Box } from '@mui/material';

const OrderAddressView = ({ className, state, data, ...rest }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const formData = {
            "Item_Master_Id": "",
            "Order_Id": state?.orderaddressData[0]?.Order_Id,
            "Records_Filter": "FILTER"
        }
        const formData1 = {
            "Item_Master_Id": state?.orderaddressData?.Item_Master_Id,
            "Order_Id": "",
            "Records_Filter": "FILTER"
        }
        dispatch(getAllOrderAddressAction(formData));
        dispatch(getAllOrderItemAddressAction(formData1));
    }, []);

    let orderAddress = useSelector(state => state?.orders?.OrderAddress?.all);
    let orderItemAddress = useSelector(state => state?.orders?.OrderItemAddress?.all);

    const Print = () => {
        let printContents = document.getElementById('printablediv').innerHTML;
        let a = window.open('', '', 'height=500, width=500');
        a.document.write('<div>');
        a.document.write(printContents);
        a.document.close();
        a.print();
    }

    return (
        <Card >
            <CardContent>
                <Grid container spacing={2}>

                    {state.addr === "ORDER" ?
                        <>
                            {state.Print === "easystepin" ?
                                <div id='printablediv'>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingTop: 20 }}>
                                                <img style={{ paddingLeft: 15 }} alt="Logo" height="40" src="/static/meepaisalogo_bw.jpg" />
                                                <img alt="Logo" height="70" src="/static/webapp_Image.png" />
                                            </div>
                                            <div style={{ fontSize: '13px', fontWeight: 'bold', marginLeft: '1rem', marginTop: '-1.5rem', marginBottom: '0.5rem' }}>Shipped By:</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>Order ID : {orderAddress?.Shipping_Address?.Order_ID}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Meepaisa_Shipping_Address?.Name}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Meepaisa_Shipping_Address?.Door_No}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Meepaisa_Shipping_Address?.LandMark}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Meepaisa_Shipping_Address?.Street}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Meepaisa_Shipping_Address?.City}, {orderAddress?.Meepaisa_Shipping_Address?.State}, {orderAddress?.Meepaisa_Shipping_Address?.Country}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Meepaisa_Shipping_Address?.ZIP}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Meepaisa_Shipping_Address?.Mobile_Number}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px', fontWeight: 'bold', marginTop: '0.8rem' }}>
                                                <p>-- Thank you for your Order with Meepaisa --</p>
                                            </div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px', marginTop: '0.8rem', fontWeight: 'bold' }}>
                                                <p>NOTE: Dear User, Please be careful while Unboxing your Order. It will be useful incase of Return. Please capture photos/video if you found any damages while Unboxing.</p>
                                                <p>FYI: Please Check Return Policies.</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                :
                                state.Print === "billing" ?
                                    <div id='printablediv'>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingTop: 20 }}>
                                                    <img style={{ paddingLeft: 15 }} alt="Logo" height="40" src="/static/meepaisalogo_bw.jpg" />
                                                    <img alt="Logo" height="70" src="/static/webapp_Image.png" />
                                                </div>
                                                <div style={{ fontSize: '13px', fontWeight: 'bold', marginLeft: '1rem', marginTop: '-1.5rem', marginBottom: '0.5rem' }}>Shipped By:</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>Order ID : {orderAddress?.Shipping_Address?.Order_ID}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Partner_Shipping_Address?.Door_No}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Partner_Shipping_Address?.LandMark}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Partner_Shipping_Address?.Street}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Partner_Shipping_Address?.City}, {orderAddress?.Partner_Shipping_Address?.State}, {orderAddress?.Partner_Shipping_Address?.Country}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Partner_Shipping_Address?.ZIP}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Partner_Shipping_Address?.Location}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Partner_Shipping_Address?.Mobile_Number}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px', fontWeight: 'bold', marginTop: '0.8rem' }}>
                                                    <p>-- Thank you for your Order with Meepaisa --</p>
                                                </div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px', marginTop: '0.8rem', fontWeight: 'bold' }}>
                                                    <p>NOTE: Dear User, Please be careful while Unboxing your Order. It will be useful incase of Return. Please capture photos/video if you found any damages while Unboxing.</p>
                                                    <p>FYI: Please Check Return Policies.</p>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    :
                                    state.Print === "shipping" ?
                                        <div id='printablediv'>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingTop: 20 }}>
                                                        <img style={{ paddingLeft: 15 }} alt="Logo" height="40" src="/static/meepaisalogo_bw.jpg" />
                                                        <img alt="Logo" height="70" src="/static/webapp_Image.png" />
                                                    </div>
                                                    <div style={{ fontSize: '13px', fontWeight: 'bold', marginLeft: '1rem', marginTop: '-1.5rem', marginBottom: '0.5rem' }}>Ship To:</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>Order ID : {orderAddress?.Shipping_Address?.Order_ID}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.Name}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.Door_No}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.LandMark}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.Street}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.City}, {orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.State}, {orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.Country}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.ZIP}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.Mobile_Number}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px', fontWeight: 'bold', marginTop: '0.8rem' }}>
                                                        <p>-- Thank you for your Order with Meepaisa --</p>
                                                    </div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px', marginTop: '0.8rem', fontWeight: 'bold' }}>
                                                        <p>NOTE: Dear User, Please be careful while Unboxing your Order. It will be useful incase of Return. Please capture photos/video if you found any damages while Unboxing.</p>
                                                        <p>FYI: Please Check Return Policies.</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        :
                                        state.Print === "esishipping" ?
                                            <>
                                                <div id='printablediv'>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingTop: 20 }}>
                                                                <img style={{ paddingLeft: 15 }} alt="Logo" height="40" src="/static/meepaisalogo_bw.jpg" />
                                                                <img alt="Logo" height="70" src="/static/webapp_Image.png" />
                                                            </div>
                                                            <div style={{ fontSize: '13px', fontWeight: 'bold', marginLeft: '1rem', marginTop: '-1.5rem', marginBottom: '0.5rem' }}>Ship To:</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.Name}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.Door_No}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.LandMark}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.Street}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.City}, {orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.State}, {orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.Country}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.ZIP}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.Mobile_Number}</div>
                                                            <div style={{ fontSize: '13px', fontWeight: 'bold', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Shipped By:</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>Order ID : {orderAddress?.Shipping_Address?.Order_ID}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Meepaisa_Shipping_Address?.Name}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Meepaisa_Shipping_Address?.Door_No}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Meepaisa_Shipping_Address?.LandMark}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Meepaisa_Shipping_Address?.Street}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Meepaisa_Shipping_Address?.City}, {orderAddress?.Meepaisa_Shipping_Address?.State}, {orderAddress?.Meepaisa_Shipping_Address?.Country}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Meepaisa_Shipping_Address?.ZIP}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Meepaisa_Shipping_Address?.Mobile_Number}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px', fontWeight: 'bold', marginTop: '0.8rem' }}>
                                                                <p>-- Thank you for your Order with Meepaisa --</p>
                                                            </div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px', marginTop: '0.8rem', fontWeight: 'bold' }}>
                                                                <p>NOTE: Dear User, Please be careful while Unboxing your Order. It will be useful incase of Return. Please capture photos/video if you found any damages while Unboxing.</p>
                                                                <p>FYI: Please Check Return Policies.</p>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </>
                                            :
                                            state.Print === "billingshipping" ?
                                                <>
                                                    <div id='printablediv'>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingTop: 20 }}>
                                                                    <img style={{ paddingLeft: 15 }} alt="Logo" height="40" src="/static/meepaisalogo_bw.jpg" />
                                                                    <img alt="Logo" height="70" src="/static/webapp_Image.png" />
                                                                </div>
                                                                <div style={{ fontSize: '13px', fontWeight: 'bold', marginLeft: '1rem', marginTop: '-1.5rem', marginBottom: '0.5rem' }}>Ship To:</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.Name}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.Door_No}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.LandMark}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.Street}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.City}, {orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.State}, {orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.Country}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.ZIP}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Shipping_Address?.User_Shipping_Address[0]?.Mobile_Number}</div>
                                                                <div style={{ fontSize: '13px', fontWeight: 'bold', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Shipped By:</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>Order ID : {orderAddress?.Shipping_Address?.Order_ID}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Partner_Shipping_Address?.Door_No}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Partner_Shipping_Address?.LandMark}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Partner_Shipping_Address?.Street}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Partner_Shipping_Address?.City}, {orderAddress?.Partner_Shipping_Address?.State}, {orderAddress?.Partner_Shipping_Address?.Country}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Partner_Shipping_Address?.ZIP}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Partner_Shipping_Address?.Location}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderAddress?.Partner_Shipping_Address?.Mobile_Number}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px', fontWeight: 'bold', marginTop: '0.8rem' }}>
                                                                    <p>-- Thank you for your Order with Meepaisa --</p>
                                                                </div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px', marginTop: '0.8rem', fontWeight: 'bold' }}>
                                                                    <p>NOTE: Dear User, Please be careful while Unboxing your Order. It will be useful incase of Return. Please capture photos/video if you found any damages while Unboxing.</p>
                                                                    <p>FYI: Please Check Return Policies.</p>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </>
                                                : null}
                        </>
                        :
                        <>
                            {state.Print === "IMeasystepin" ?
                                <div id='printablediv'>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingTop: 20 }}>
                                                <img style={{ paddingLeft: 15 }} alt="Logo" height="40" src="/static/meepaisalogo_bw.jpg" />
                                                <img alt="Logo" height="70" src="/static/webapp_Image.png" />
                                            </div>
                                            <div style={{ fontSize: '13px', fontWeight: 'bold', marginLeft: '1rem', marginTop: '-1.5rem', marginBottom: '0.5rem' }}>Shipped By:</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>Order ID : {orderItemAddress?.Shipping_Address?.Order_ID}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>Item ID : {orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Item_Master_ID}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Meepaisa_Shipping_Address?.Name}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Meepaisa_Shipping_Address?.Door_No}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Meepaisa_Shipping_Address?.LandMark}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Meepaisa_Shipping_Address?.Street}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Meepaisa_Shipping_Address?.City}, {orderItemAddress?.Meepaisa_Shipping_Address?.State}, {orderItemAddress?.Meepaisa_Shipping_Address?.Country}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Meepaisa_Shipping_Address?.ZIP}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Meepaisa_Shipping_Address?.Mobile_Number}</div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px', fontWeight: 'bold', marginTop: '0.8rem' }}>
                                                <p>-- Thank you for your Order with Meepaisa --</p>
                                            </div>
                                            <div style={{ marginLeft: '1rem', fontSize: '13px', marginTop: '0.8rem', fontWeight: 'bold' }}>
                                                <p>NOTE: Dear User, Please be careful while Unboxing your Order. It will be useful incase of Return. Please capture photos/video if you found any damages while Unboxing.</p>
                                                <p>FYI: Please Check Return Policies.</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                :
                                state.Print === "IMbilling" ?
                                    <div id='printablediv'>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingTop: 20 }}>
                                                    <img style={{ paddingLeft: 15 }} alt="Logo" height="40" src="/static/meepaisalogo_bw.jpg" />
                                                    <img alt="Logo" height="70" src="/static/webapp_Image.png" />
                                                </div>
                                                <div style={{ fontSize: '13px', fontWeight: 'bold', marginLeft: '1rem', marginTop: '-1.5rem', marginBottom: '0.5rem' }}>Shipped By:</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>Order ID : {orderItemAddress?.Shipping_Address?.Order_ID}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>Item ID : {orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Item_Master_ID}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Partner_Shipping_Address?.Door_No}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Partner_Shipping_Address?.LandMark}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Partner_Shipping_Address?.Street}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Partner_Shipping_Address?.City}, {orderItemAddress?.Partner_Shipping_Address?.State}, {orderItemAddress?.Partner_Shipping_Address?.Country}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Partner_Shipping_Address?.ZIP}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Partner_Shipping_Address?.Location}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Partner_Shipping_Address?.Mobile_Number}</div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px', fontWeight: 'bold', marginTop: '0.8rem' }}>
                                                    <p>-- Thank you for your Order with Meepaisa --</p>
                                                </div>
                                                <div style={{ marginLeft: '1rem', fontSize: '13px', marginTop: '0.8rem', fontWeight: 'bold' }}>
                                                    <p>NOTE: Dear User, Please be careful while Unboxing your Order. It will be useful incase of Return. Please capture photos/video if you found any damages while Unboxing.</p>
                                                    <p>FYI: Please Check Return Policies.</p>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    :
                                    state.Print === "IMshipping" ?
                                        <div id='printablediv'>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingTop: 20 }}>
                                                        <img style={{ paddingLeft: 15 }} alt="Logo" height="40" src="/static/meepaisalogo_bw.jpg" />
                                                        <img alt="Logo" height="70" src="/static/webapp_Image.png" />
                                                    </div>
                                                    <div style={{ fontSize: '13px', fontWeight: 'bold', marginLeft: '1rem', marginTop: '-1.5rem', marginBottom: '0.5rem' }}>Ship To:</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>Order ID : {orderItemAddress?.Shipping_Address?.Order_ID}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>Item ID : {orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Item_Master_ID}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Name}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Door_No}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.LandMark}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Street}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.City}, {orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.State}, {orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Country}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.ZIP}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Mobile_Number}</div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px', fontWeight: 'bold', marginTop: '0.8rem' }}>
                                                        <p>-- Thank you for your Order with Meepaisa --</p>
                                                    </div>
                                                    <div style={{ marginLeft: '1rem', fontSize: '13px', marginTop: '0.8rem', fontWeight: 'bold' }}>
                                                        <p>NOTE: Dear User, Please be careful while Unboxing your Order. It will be useful incase of Return. Please capture photos/video if you found any damages while Unboxing.</p>
                                                        <p>FYI: Please Check Return Policies.</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        :
                                        state.Print === "IMesishipping" ?
                                            <>
                                                <div id='printablediv'>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingTop: 20 }}>
                                                                <img style={{ paddingLeft: 15 }} alt="Logo" height="40" src="/static/meepaisalogo_bw.jpg" />
                                                                <img alt="Logo" height="70" src="/static/webapp_Image.png" />
                                                            </div>
                                                            <div style={{ fontSize: '13px', fontWeight: 'bold', marginLeft: '1rem', marginTop: '-1.5rem', marginBottom: '0.5rem' }}>Ship To:</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Name}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Door_No}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.LandMark}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Street}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.City}, {orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.State}, {orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Country}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.ZIP}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Mobile_Number}</div>
                                                            <div style={{ fontSize: '13px', fontWeight: 'bold', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Shipped By:</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>Order ID : {orderItemAddress?.Shipping_Address?.Order_ID}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>Item ID : {orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Item_Master_ID}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Meepaisa_Shipping_Address?.Name}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Meepaisa_Shipping_Address?.Door_No}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Meepaisa_Shipping_Address?.LandMark}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Meepaisa_Shipping_Address?.Street}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Meepaisa_Shipping_Address?.City}, {orderItemAddress?.Meepaisa_Shipping_Address?.State}, {orderItemAddress?.Meepaisa_Shipping_Address?.Country}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Meepaisa_Shipping_Address?.ZIP}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Meepaisa_Shipping_Address?.Mobile_Number}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px', fontWeight: 'bold', marginTop: '0.8rem' }}>
                                                                <p>-- Thank you for your Order with Meepaisa --</p>
                                                            </div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '13px', marginTop: '0.8rem', fontWeight: 'bold' }}>
                                                                <p>NOTE: Dear User, Please be careful while Unboxing your Order. It will be useful incase of Return. Please capture photos/video if you found any damages while Unboxing.</p>
                                                                <p>FYI: Please Check Return Policies.</p>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </>
                                            :
                                            state.Print === "IMbillingshipping" ?
                                                <>
                                                    <div id='printablediv'>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingTop: 20 }}>
                                                                    <img style={{ paddingLeft: 15 }} alt="Logo" height="40" src="/static/meepaisalogo_bw.jpg" />
                                                                    <img alt="Logo" height="70" src="/static/webapp_Image.png" />
                                                                </div>
                                                                <div style={{ fontSize: '13px', fontWeight: 'bold', marginLeft: '1rem', marginTop: '-1.5rem', marginBottom: '0.5rem' }}>Ship To:</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Name}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Door_No}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.LandMark}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Street}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.City}, {orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.State}, {orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Country}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.ZIP}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Mobile_Number}</div>
                                                                <div style={{ fontSize: '13px', fontWeight: 'bold', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Shipped By:</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>Order ID : {orderItemAddress?.Shipping_Address?.Order_ID}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>Item ID : {orderItemAddress?.Shipping_Address?.User_Shipping_Address[0]?.Item_Master_ID}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Partner_Shipping_Address?.Door_No}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Partner_Shipping_Address?.LandMark}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Partner_Shipping_Address?.Street}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Partner_Shipping_Address?.City}, {orderItemAddress?.Partner_Shipping_Address?.State}, {orderItemAddress?.Partner_Shipping_Address?.Country}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Partner_Shipping_Address?.ZIP}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Partner_Shipping_Address?.Location}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px' }}>{orderItemAddress?.Partner_Shipping_Address?.Mobile_Number}</div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px', fontWeight: 'bold', marginTop: '0.8rem' }}>
                                                                    <p>-- Thank you for your Order with Meepaisa --</p>
                                                                </div>
                                                                <div style={{ marginLeft: '1rem', fontSize: '13px', marginTop: '0.8rem', fontWeight: 'bold' }}>
                                                                    <p>NOTE: Dear User, Please be careful while Unboxing your Order. It will be useful incase of Return. Please capture photos/video if you found any damages while Unboxing.</p>
                                                                    <p>FYI: Please Check Return Policies.</p>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </>
                                                : null}
                        </>
                    }
                </Grid>

                <Box display="flex" p={2}>
                    <Button
                        style={{ backgroundColor: '#27B6CC' }}
                        variant="contained"
                        onClick={() => { Print() }}
                    >
                        <span style={{ color: 'white' }}>PRINT</span>
                    </Button>
                </Box>

            </CardContent>
        </Card >
    );
};


export default OrderAddressView;