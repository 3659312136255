import { nodeGetApi } from './commonAxios';

export const COMPANYSETTINGSDATEANDCOUNT_REQUEST = "COMPANYSETTINGSDATEANDCOUNT_REQUEST";
export const COMPANYSETTINGSDATEANDCOUNT_SUCCESS = "COMPANYSETTINGSDATEANDCOUNT_SUCCESS";
export const COMPANYSETTINGSDATEANDCOUNT_FAIL = "COMPANYSETTINGSDATEANDCOUNT_FAIL";

/*=============================================================
          Get ESI Main Center Date and count Action
===============================================================*/
export const getcompanysettinsDateandCount = () => async dispatch => {
  try {
    dispatch({
      type: COMPANYSETTINGSDATEANDCOUNT_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin/Total_And_Count`, {});
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: COMPANYSETTINGSDATEANDCOUNT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: COMPANYSETTINGSDATEANDCOUNT_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: COMPANYSETTINGSDATEANDCOUNT_FAIL,
      payload: err
    });
  }
};