import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    makeStyles,
    TableSortLabel,
    Tooltip,
    Button,
} from '@material-ui/core';
import isEmpty from 'src/store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';


const useStyles = makeStyles(theme => ({
    root: {},
    avatar: {
        marginRight: theme.spacing(2)
    },
    buttonRoot: {
        minWidth: '7px',
        padding: '6px'
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    },
}));

const headCells = [

    { id: 'Id', numeric: false, disablePadding: false, label: 'ID' },
    { id: 'Amount', numeric: false, disablePadding: false, label: 'Amount' },
    { id: 'Purpose', numeric: false, disablePadding: false, label: 'Purpose' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'Contact_Id', numeric: false, disablePadding: false, label: 'Contact ID' },

];

const Results = ({
    className,
    allRazorpaytransactions,
    page,
    limit,
    setPage,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    createSortHandler,
    allRazorpaytransactionNoFilter,
    state,
    RolePermissions,
    roleUserType,
    ...rest
}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [selectedDeliWalletHistoryIds, setSelectedDeliWalletHistoryIds] = useState([]);

    function checkingPagination(array) {
        if (array?.slice(page * limit, page * limit + limit).length <= 0) {
            setPage(page - 1);
        }
        else {
            return array?.slice(page * limit, page * limit + limit);
        }
        return array;
    }
    const visibleRazorpayHandler = razorpaytransactionData => e => {
        navigate("/app/view-razorpayhistory", { state: { state: state, data: razorpaytransactionData } });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-3rem' }}>Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allRazorpaytransactions) &&
                                (limit > 0 ? checkingPagination(allRazorpaytransactions) : allRazorpaytransactions).map(razorpaytransaction => (
                                    <TableRow
                                        hover
                                        key={razorpaytransaction.Id}
                                        selected={
                                            selectedDeliWalletHistoryIds.indexOf(razorpaytransaction.Id) !== -1
                                        }
                                    >
                                        <TableCell>{razorpaytransaction.Id}</TableCell>
                                        <TableCell>{razorpaytransaction.Amount}</TableCell>
                                        <TableCell>{razorpaytransaction.Purpose}</TableCell>
                                        <TableCell>{razorpaytransaction.Status}</TableCell>
                                        <TableCell>{razorpaytransaction.Contact_Id}</TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Razorpay_Payments_History_Read?.Value === "Razorpay_Payments_History_Read" && RolePermissions?.Razorpay_Payments_History_Read?.Is_Read === 1) ?
                                            <TableCell>
                                                <Tooltip title="Razorpay Transaction View">
                                                    <Button
                                                        onClick={visibleRazorpayHandler(razorpaytransaction)}
                                                        color="primary"
                                                    >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        :null}
                                    </TableRow>
                                ))}
                        </TableBody>

                    </Table>
                </Box>

                {isEmpty(allRazorpaytransactions) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt=""/>
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <TablePagination
                component="div"
                count={allRazorpaytransactions.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 20]}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    allRazorpaytransactions: PropTypes.array.isRequired
};

export default Results;