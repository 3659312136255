import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import Page from 'src/components/Page';
// import EditOrderStatusHistory from './orderLineItemUpdate';
import EditCmpltdOrderStatusHistory from './orderLineItemUpdate';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const EditCmpltdOrderStatusHistoryView = () => {
    const classes = useStyles();
    const location = useLocation();
    let { state } = location;

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                {state?.ID === "1" ?
                    <Button
                        component={RouterLink}
                        to="/app/cmpltd-order-history"
                        variant="text"
                        size="small"
                        color="inherit"
                    >
                        <KeyboardBackspaceIcon />
                        Go back
                    </Button>
                    :
                    state?.ID === " " ?
                        <Button
                            component={RouterLink}
                            to="/app/shipping"
                            variant="text"
                            size="small"
                            color="inherit"
                        >
                            <KeyboardBackspaceIcon />
                            Go back
                        </Button>
                        :
                        <Button
                            component={RouterLink}
                            to="/app/cmpltd-order-history"
                            variant="text"
                            size="small"
                            color="inherit"
                        >
                            <KeyboardBackspaceIcon />
                            Go back
                        </Button>
                }
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        Edit Order History
                    </Typography>
                </Box>
                <EditCmpltdOrderStatusHistory />
            </Container>
        </Page>
    );
};


export default EditCmpltdOrderStatusHistoryView;