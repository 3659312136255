import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Box,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { createDeliveryWalletAction, getAlldeliveryEmails, getAllDeliveryWalletAction } from 'src/store/actions/deliverywalletAction';
import { CircularProgress } from '@mui/material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";

const statusOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const AddDeliveryWallet = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // componentDidMount
    useEffect(() => {
        const formData = {
            "search_by_filter": "",
            "search": "",
            Records_Filter: 'ALL'
        };
        dispatch(getAllDeliveryWalletAction(formData));
        dispatch(getAlldeliveryEmails());
    }, []);

    const alldeliveryWallet = useSelector(state => state.deliverywallet.deliverywallet.all);
    const isSubmited = useSelector(state => state.deliverywallet.deliverywalletCreate.isLoading);
    const delemails = useSelector(state => state.deliverywallet.deliveryEmails.deliveryemails);
    const errorMessage = useSelector(state => state.deliverywallet.deliverywalletCreate.error);

    return (
        <Formik
            initialValues={{
                Del_User_Mail_Id: '',
                Is_Eligible_For_Withdraw: false,
                Status: '',
            }}

            validationSchema={
                Yup.object().shape({
                    Del_User_Mail_Id: Yup.string().notOneOf(alldeliveryWallet?.map(item => item['Wallet_Owner']), "Delivery User Email ID is already exist.").required('Delivery User Email ID is required.'),
                    Status: Yup.string().required('Status is required.'),
                })
            }
            onSubmit={(values) => {
                let formValues = JSON.parse(JSON.stringify(values));
                if (values.Is_Eligible_For_Withdraw) {
                    formValues.Is_Eligible_For_Withdraw = 1;
                } else {
                    formValues.Is_Eligible_For_Withdraw = 0;
                }
                dispatch(createDeliveryWalletAction(formValues, navigate));
            }
            }
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        {/*Del_User_Mail_Id */}
                                        <Grid item md={6} xs={12}>
                                            {/* <TextField
                                                error={Boolean(touched.Del_User_Mail_Id && errors.Del_User_Mail_Id)}
                                                fullWidth
                                                helperText={touched.Del_User_Mail_Id && errors.Del_User_Mail_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Delivery User Email ID"
                                                name="Del_User_Mail_Id"
                                                value={values.Del_User_Mail_Id}
                                                variant="outlined"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {delemails?.sort((a, b) => a.Del_User_Email_Id?.localeCompare(b.Del_User_Email_Id))?.map(option => (
                                                    <option key={option.Del_User_Detail_Id} value={option.Del_User_Email_Id}>
                                                        {option.Del_User_Email_Id}
                                                    </option>
                                                ))}
                                            </TextField> */}
                                            <Autocomplete
                                                freeSolo
                                                options={delemails}
                                                groupBy={(option) => option.firstLetter}
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option.Del_User_Email_Id  }
                                                renderInput={(params) => <TextField {...params}
                                                    error={Boolean(touched.Del_User_Mail_Id && errors.Del_User_Mail_Id)}
                                                    helperText={touched.Del_User_Mail_Id && errors.Del_User_Mail_Id}
                                                    onBlur={handleBlur}
                                                    label="Del User Email ID"
                                                    name="Del_User_Mail_Id"
                                                    variant="outlined"
                                                    value={values.Del_User_Mail_Id}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        dispatch(getAlldeliveryEmails(params.inputProps.value))
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill,
                                                        startAdornment: (
                                                            <React.Fragment>
                                                                <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                {params.InputProps.startAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />}
                                                onChange={(e, value) => {
                                                    setFieldValue("Del_User_Mail_Id", value?.Del_User_Email_Id ? value.Del_User_Email_Id : "");
                                                }}
                                            />
                                        </Grid>

                                        {/* Status */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {/* Is Eligible For Withdraw */}
                                        <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        disabled
                                                        checked={values.Is_Eligible_For_Withdraw}
                                                        onChange={handleChange}
                                                        name="Is_Eligible_For_Withdraw"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Eligible For Withdrawl"
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>

                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};


AddDeliveryWallet.propTypes = {
    className: PropTypes.string
};

export default AddDeliveryWallet;