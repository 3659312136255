import {
    DELIVERY_WALLET_REQUEST,
    DELIVERY_WALLET_SUCCESS,
    DELIVERY_WALLET_FAIL,

    DELIVERY_WALLET_CREATE_REQUEST,
    DELIVERY_WALLET_CREATE_SUCCESS,
    DELIVERY_WALLET_CREATE_FAIL,

    DELIVERY_WALLET_UPDATE_REQUEST,
    DELIVERY_WALLET_UPDATE_SUCCESS,
    DELIVERY_WALLET_UPDATE_FAIL,

    DELI_WALLET_HISTORY_CREATE_REQUEST,
    DELI_WALLET_HISTORY_CREATE_SUCCESS,
    DELI_WALLET_HISTORY_CREATE_FAIL,

    DELI_WALLET_HISTORY_UPDATE_REQUEST,
    DELI_WALLET_HISTORY_UPDATE_SUCCESS,
    DELI_WALLET_HISTORY_UPDATE_FAIL,

    DELIVERY_WALLET_EMAILS_REQUEST,
    DELIVERY_WALLET_EMAILS_SUCCESS,
    DELIVERY_WALLET_EMAILS_FAIL,
} from '../actions/deliverywalletAction'

const initialState = {
    deliverywallet: { all: [], error: '', isLoading: false },
    delwallet: { deliverywallet: {}, error: '', isLoading: false },
    deliverywallethistory: { all: [], error: '', isLoading: false },
    deliverywalletCreate: { deliverywallet: {}, error: '', isLoading: false },
    deliverywalletUpdate: { deliverywallet: {}, error: '', isLoading: false },
    deliwallethistoryCreate: { deliwallethistory: {}, error: '', isLoading: false },
    deliwallethistoryUpdate: { deliwallethistory: {}, error: '', isLoading: false },
    deliveryEmails: { deliveryemails: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case DELIVERY_WALLET_REQUEST:
            return { ...state, deliverywallet: { all: state.deliverywallet.all, error: '', isLoading: true } };
        case DELIVERY_WALLET_SUCCESS:
            return { ...state, deliverywallet: { all: action.payload, error: '', isLoading: false } };
        case DELIVERY_WALLET_FAIL:
            return { ...state, deliverywallet: { all: [], error: action.payload, isLoading: false } };

        case DELIVERY_WALLET_CREATE_REQUEST:
            return { ...state, deliverywalletCreate: { deliverywallet: {}, error: '', isLoading: true } };
        case DELIVERY_WALLET_CREATE_SUCCESS:
            return { ...state, deliverywalletCreate: { deliverywallet: state.deliverywalletCreate.deliverywallet, error: '', isLoading: false } };
        case DELIVERY_WALLET_CREATE_FAIL:
            return { ...state, deliverywalletCreate: { deliverywallet: {}, error: action.payload, isLoading: false } };

        case DELIVERY_WALLET_UPDATE_REQUEST:
            return { ...state, deliverywalletUpdate: { deliverywallet: {}, error: '', isLoading: true } };
        case DELIVERY_WALLET_UPDATE_SUCCESS:
            return { ...state, deliverywalletUpdate: { deliverywallet: state.deliverywalletUpdate.deliverywallet, error: '', isLoading: false } };
        case DELIVERY_WALLET_UPDATE_FAIL:
            return { ...state, deliverywalletUpdate: { deliverywallet: {}, error: action.payload, isLoading: false } };

        case DELI_WALLET_HISTORY_CREATE_REQUEST:
            return { ...state, deliwallethistoryCreate: { deliwallethistory: {}, error: '', isLoading: true } };
        case DELI_WALLET_HISTORY_CREATE_SUCCESS:
            return { ...state, deliwallethistoryCreate: { deliwallethistory: state.deliwallethistoryCreate.deliwallethistory, error: '', isLoading: false } };
        case DELI_WALLET_HISTORY_CREATE_FAIL:
            return { ...state, deliwallethistoryCreate: { deliwallethistory: {}, error: action.payload, isLoading: false } };

        case DELI_WALLET_HISTORY_UPDATE_REQUEST:
            return { ...state, deliwallethistoryUpdate: { deliwallethistory: {}, error: '', isLoading: true } };
        case DELI_WALLET_HISTORY_UPDATE_SUCCESS:
            return { ...state, deliwallethistoryUpdate: { deliwallethistory: state.deliwallethistoryUpdate.deliwallethistory, error: '', isLoading: false } };
        case DELI_WALLET_HISTORY_UPDATE_FAIL:
            return { ...state, deliwallethistoryUpdate: { deliwallethistory: {}, error: action.payload, isLoading: false } };

        case DELIVERY_WALLET_EMAILS_REQUEST:
            return { ...state, deliveryEmails: { deliveryemails: state.deliveryEmails.deliveryemails, error: '', isLoading: true }, };
        case DELIVERY_WALLET_EMAILS_SUCCESS:
            return { ...state, deliveryEmails: { deliveryemails: action.payload, error: '', isLoading: false } };
        case DELIVERY_WALLET_EMAILS_FAIL:
            return { ...state, deliveryEmails: { deliveryemails: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}