import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import Typography from '@material-ui/core/Typography';
import { ConnectedFocusError } from "focus-formik-error";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { CircularProgress } from '@mui/material';
import { createBiddingProductsAction, getAllCategories, singleImageUploadCreate } from 'src/store/actions/biddingproductsAction';
import Rating from '@material-ui/lab/Rating';
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const discountType = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'A',
    label: 'Amount'
  },
  {
    value: 'P',
    label: 'Percentage'
  },
];

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  Description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddBidProducts = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  // componentDidMount
  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  const [errorMessage, setErrorMessage] = useState({ Product_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  const categories = useSelector(state => state.biddingproducts.BidProductCategories.categories)
  const isSubmited = useSelector(state => state.biddingproducts.biddingproductCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor
  const [rhDescriptionMin, setRhDescriptionMin] = useState(20);
  const [rhDescription, setRhDescription] = useState('');
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // Richtext editor
  const [editorState, setEditorState,] = useState(EditorState.createEmpty());
  const [editorStateReviews, setEditorStateReviews] = useState(EditorState.createEmpty());
  const [rhDiscriptionReviewsMin, setRhDiscriptionReviewsMin] = useState(20);
  const [rhDiscriptionReviews, setRhDiscriptionReviews] = useState('');
  const onEditorStateChangeReviews = (editorStateReviews) => {
    setEditorStateReviews(editorStateReviews);
    setRhDiscriptionReviews(draftToHtml(convertToRaw(editorStateReviews.getCurrentContent())));
  };

  // Multiple pic upload
  const [blobArrayPics, setBlobArrayPics] = useState([{
    key: 1, isdefault: 0, preview: null
  },
  {
    key: 2, isdefault: 0, preview: null
  },
  {
    key: 3, isdefault: 0, preview: null
  },
  {
    key: 4, isdefault: 0, preview: null
  },
  {
    key: 5, isdefault: 0, preview: null
  }]);

  const [imageError, setImageError] = useState();
  const callBackUploadImgae = (status, imageUrl, key) => {
    if (status) {
      const blobArrayPicsTemp = JSON.parse(JSON.stringify(blobArrayPics));
      blobArrayPicsTemp[key - 1].preview = imageUrl;
      if (key === 1) {
        blobArrayPicsTemp[key - 1].isdefault = 1;
      }
      setBlobArrayPics(blobArrayPicsTemp);
    }
    else {
      setImageError("Unable to upload image please try again.");
    }
  };

  const handleImage = (e, key) => {
    setImageError("");
    dispatch(
      singleImageUploadCreate(new Blob([e.target.files[0]]), callBackUploadImgae, key)
    );
  };

  function handleDefaultChange(status, key) {
    const blobArrayPicsTemp = JSON.parse(JSON.stringify(blobArrayPics));
    for (let i = 0; i < blobArrayPicsTemp.length; i++) {
      if (status && key === blobArrayPicsTemp[i].key) {
        blobArrayPicsTemp[i].isdefault = 1;
      } else {
        blobArrayPicsTemp[i].isdefault = 0;
      }
    }
    setBlobArrayPics(blobArrayPicsTemp);
  };

  function handleRemoveImage(e, key) {
    const blobArrayPicsTemp = JSON.parse(JSON.stringify(blobArrayPics));
    blobArrayPicsTemp[key - 1].blob = null;
    blobArrayPicsTemp[key - 1].preview = null;
    for (let i = key; i < 5; i++) {
      let a = blobArrayPicsTemp[i - 1];
      let b = blobArrayPicsTemp[i];
      b.key = i;
      a.key = i + 1;
      blobArrayPicsTemp[i - 1] = b;
      blobArrayPicsTemp[i] = a;
    }
    setBlobArrayPics(blobArrayPicsTemp);
  };

  //Images array data
  let ImagesData = [];
  blobArrayPics.filter(item => { return !(!item.preview) }).map(item => {
    ImagesData.push({
            "Product_Image": item.preview,
            "Is_Default": item.isdefault,
            "Image_Sort": item.key
    });
  })

  return (
    <Formik
      initialValues={{
        Bidding_Products_Category_Id: " ",
        Created_By: loginEmail,
        Measurement_Type: "",
        Description: "",
        Esi_Commission: "",
        Esi_Commission_Type: "",
        Meepaisa_Rating_For_Product: "",
        Meepaisa_Review_For_Product: "",
        Product_Name: "",
        Status: ""
      }}

      validationSchema={
        Yup.object().shape({
          Product_Name: Yup.string().min(2, "Product Name must be at least 2 characters.").notOneOf([errorMessage.Product_Name.key, null], errorMessage.Product_Name.message).required('Product Name is required.'),
          Bidding_Products_Category_Id: Yup.string().required('Product Category Name is required.'),
          Measurement_Type: Yup.string().required('Measurement type is required.'),
          Meepaisa_Rating_For_Product: Yup.number().required('Please provide Meepaisa Rating for product.').nullable(),
          Status: Yup.string().required('Status is required.'),
          Esi_Commission_Type: Yup.string().test('oneOfRequired',
            'ESI Commission Type is required.',
            function (item) {
              return (this.parent.Esi_Commission_Type)
            }
          ),
          Esi_Commission: Yup.number().when("Esi_Commission_Type", (Esi_Commission_Type, schema) => {
            if (Esi_Commission_Type === "A") {
              return schema.min(1, "ESI Commission Amount must be at least 1.").required('ESI Commission Amount is required.');
            } else if (Esi_Commission_Type === "P") {
              return schema.min(1, "ESI Commission Percentage must be at least 1.").max(100, "ESI Commission Percentage must be less then 100.").required('ESI Commission Percentage is required.');
            }
          }),
        })
      }

      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        setImageError("");
        const errors = {};

        values.Description = rhDescription;
        values.Meepaisa_Review_For_Product = rhDiscriptionReviews;

        if (blobArrayPics.filter(item => { return !(!item.preview) }).length <= 0) {
          submitTest = false;
          setImageError("Image is required.");
        }
        if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDescriptionMin) {
          submitTest = false;
          errors.Description = "Please provide at least " + rhDescriptionMin + " characters.";
        }
        if (!values.Meepaisa_Review_For_Product || values.Meepaisa_Review_For_Product.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Meepaisa_Review_For_Product = "Meepaisa Review for Product is required.";
        }
        else if (values.Meepaisa_Review_For_Product.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionReviewsMin) {
          submitTest = false;
          errors.Meepaisa_Review_For_Product = "Please provide at least " + rhDiscriptionReviewsMin + " characters";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          formValues.Images = ImagesData
          dispatch(createBiddingProductsAction(formValues, navigate, setErrorMessage, setErrors));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    {/* Bidding Products Category Name*/}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Bidding_Products_Category_Id && errors.Bidding_Products_Category_Id)}
                        fullWidth
                        helperText={touched.Bidding_Products_Category_Id && errors.Bidding_Products_Category_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Product Category Name"
                        name="Bidding_Products_Category_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Bidding_Products_Category_Id}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {categories?.sort((a, b) => a.Bidding_Products_Category_Name?.localeCompare(b.Bidding_Products_Category_Name))?.map(option => (
                          <option key={option.Bidding_Products_Category_Id} value={option.Bidding_Products_Category_Id}>
                            {option.Bidding_Products_Category_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Product Name*/}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Product_Name && errors.Product_Name)}
                        fullWidth
                        helperText={touched.Product_Name && errors.Product_Name}
                        onBlur={handleBlur}
                        label="Product Name"
                        name="Product_Name"
                        onChange={handleChange}
                        value={values.Product_Name}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Measurement Type*/}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Measurement_Type && errors.Measurement_Type)}
                        fullWidth
                        helperText={touched.Measurement_Type && errors.Measurement_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Measurement Type"
                        name="Measurement_Type"
                        value={values.Measurement_Type}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>

                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        placeholder="Status"
                        name="Status"
                        onChange={handleChange}
                        label="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        helperText={<ErrorMessage name="Status" />}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/*  ESI Commission Type */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Esi_Commission_Type && errors.Esi_Commission_Type)}
                        fullWidth
                        helperText={touched.Esi_Commission_Type && errors.Esi_Commission_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="ESI Commission Type"
                        name="Esi_Commission_Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Esi_Commission_Type}
                        variant="outlined"
                      >
                        {discountType.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/*  ESI Commission */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Esi_Commission && errors.Esi_Commission)}
                        fullWidth
                        helperText={touched.Esi_Commission && errors.Esi_Commission}
                        onBlur={handleBlur}
                        label="ESI Commission"
                        name="Esi_Commission"
                        type="number"
                        onChange={handleChange}
                        value={values.Esi_Commission}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Description*/}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.Description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDescription || rhDescription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDescriptionMin) && touched.Description && errors.Description}
                      </span>
                    </Grid>

                    {/* Meepaisa Review For Product */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.Description}>
                        Meepaisa Review for Product
                        <EditorContainer editorState={editorStateReviews} onEditorStateChange={onEditorStateChangeReviews} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscriptionReviews || rhDiscriptionReviews.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionReviewsMin) && touched.Meepaisa_Review_For_Product
                          && errors.Meepaisa_Review_For_Product}
                      </span>
                    </Grid>

                    {/* Meepaisa_Rating_For_Product */}
                    <Grid item md={4} xs={4}>
                      <Box>
                        Meepaisa Rating for Product</Box></Grid>
                    <Grid item md={4} xs={4}>
                      <Rating
                        name="Meepaisa_Rating_For_Product"
                        onChange={(event, newValue) => {
                          values.Meepaisa_Rating_For_Product = newValue;
                          setFieldValue(newValue);
                        }}
                        value={values.Meepaisa_Rating_For_Product}
                        size="small"
                      />
                    </Grid>

                  </Grid>
                </Grid>

                {/* upload photo */}
                <Grid item md={4} xs={12}>
                  {blobArrayPics.filter(item => {
                    if (item.key === 1) {
                      return true;
                    } else {
                      return blobArrayPics[item.key - 2].preview !== null;
                    }
                  }).map(option => (
                    <Card style={{ marginBottom: "5px", marginTop: "5px" }} key={"image-card" + option.key}>
                      <CardContent>
                        <Box display="flex" justifyContent="center">
                          {option.preview ? (
                            <Avatar
                              className={classes.images}
                              alt="Product Upload"
                              src={option.preview}
                              variant="square"
                            />
                          ) : (
                            <>
                              <Avatar
                                className={classes.images}
                                alt="Product Upload"
                                src="/static/images/image-placeholder.png"
                                variant="square"
                              />
                            </>
                          )}
                        </Box>

                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          {option.preview ? <> <FormControlLabel
                            key={"imageUpload" + option.key}
                            disabled={option.isdefault === 1 ? true : false}
                            control={
                              <Checkbox
                                onBlur={handleBlur}
                                checked={option.isdefault === 1 ? true : false}
                                onChange={(e, status) => handleDefaultChange(status, option.key)}
                                name="Is_Default"
                                color="primary"
                              />
                            }
                            label={<Typography style={{ fontSize: "0.875rem" }}>Is Default</Typography>}
                          /> <Button
                            style={{ fontSize: '10', color: ' #425A70', textTransform: 'none' }}
                            onClick={(e) => handleRemoveImage(e, option.key)}
                            disabled={option.isdefault === 1 ? true : false}
                          >
                              Remove Picture
                            </Button> </> : <input
                            style={{ display: 'block', width: '80%' }}
                            id={"upload-photo" + option.key}
                            name="image"
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImage(e, option.key)}
                          />}
                          <br />
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                  <span style={{ color: "red" }}>
                    {blobArrayPics.filter(item => { return !(!item.preview) }).length <= 0 && imageError}
                  </span>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddBidProducts.propTypes = {
  className: PropTypes.string
};

export default AddBidProducts;