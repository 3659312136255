import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';
import isEmpty from 'src/store/validations/is-empty';
import CityPartnerProductList from './CityProductView';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CityProductDetails = () => {
  const classes = useStyles();
  const location = useLocation();
  const { state } = location;
  const [products, setProducts] = useState([]);
  let allProducts = useSelector(state => state.sbcmProduct.products.all);

  useEffect(() => {
    if (!isEmpty(allProducts)) {
      setProducts(allProducts.sbcmProduct);
    }
  }, [allProducts]);

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/sbcm/product-management"
          variant="text"
          size="small"
          color="inherit"
          state={state?.state}
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>

        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            View Partner City Product
          </Typography>
        </Box>
        <CityPartnerProductList state={state?.state} data={state?.data} products={products} />
      </Container>
    </Page>
  );
};

export default CityProductDetails;