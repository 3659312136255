import axios from 'axios';

import { toast } from 'react-toastify';
import { paginationPromotional } from './node-actions/nodepagination';
import { nodeCudApi,boomiAdminApi } from './commonAxios';



export const PROMOTIONAL_CREATE_REQUEST = "PROMOTIONAL_CREATE_REQUEST";
export const PROMOTIONAL_CREATE_SUCCESS = "PROMOTIONAL_CREATE_SUCCESS";
export const PROMOTIONAL_CREATE_FAIL = "PROMOTIONAL_CREATE_FAIL";
export const PROMOTIONAL_UPDATE_REQUEST = "PROMOTIONAL_UPDATE_REQUEST";
export const PROMOTIONAL_UPDATE_SUCCESS = "PROMOTIONAL_UPDATE_SUCCESS";
export const PROMOTIONAL_UPDATE_FAIL = "PROMOTIONAL_UPDATE_FAIL";
export const RESET = "RESET";

axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';



export const invalid_data = () => {
  return {
    type: RESET
  }
}

export const addPromotionalAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: PROMOTIONAL_CREATE_REQUEST
  });
  setErrorMessage({ Campaign_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Promotional_Campaign/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PROMOTIONAL_CREATE_SUCCESS
        });
        toast('Promotion added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/promotional-management');
      } else {
        const errors = {};
        if (successResponse.Campaign_Name) {
          errors.Campaign_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Campaign_Name: { key: successResponse.Campaign_Name ? successResponse.Campaign_Name : '', message: successResponse.Campaign_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Campaign_Name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: PROMOTIONAL_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Campaign_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: PROMOTIONAL_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                Promotional Image Upload API
===============================================================*/
export const uploadPromotionalImage = (
  formData,
  callBackUploadImgae
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=Promotional&fileextension=png&filetype=Images&filename=Promotional`,
      formData
    );
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};

/*=============================================================
                  Delete PromotionalById Action
===============================================================*/

export const deletePromotionalById = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Promotional_Campaign/Delete`, formData);
    if (data) {
      toast('Promotion deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationPromotional(filters, pagination, callBackPagination));
    }
  } catch (err) {

  }
};
/*=============================================================
                    Update Promotional Action
===============================================================*/
export const updatePromotionalAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: PROMOTIONAL_UPDATE_REQUEST
  });
  setErrorMessage({ Campaign_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Promotional_Campaign/Update`, formData
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PROMOTIONAL_UPDATE_SUCCESS
        });
        toast('Promotion updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/promotional-management');
      } else {
        const errors = {};
        if (successResponse.Campaign_Name) {
          errors.Campaign_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Campaign_Name: { key: successResponse.Campaign_Name ? successResponse.Campaign_Name : '', message: successResponse.Campaign_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Campaign_Name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: PROMOTIONAL_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Campaign_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: PROMOTIONAL_CREATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};