import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles, FormControlLabel,
    Checkbox, Avatar
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateThemeAction, uploadThemeImage } from '../../../store/actions/meepaisathemeAction';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { CircularProgress } from '@mui/material';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles(() => ({
    images: {
        width: "100%",
        height: 100,
        borderRadius: 5,
        marginBottom: 15
    },
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    },
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const EditMeepaisaTheme = ({ className, ...rest }) => {
    const location = useLocation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = location;

    const isSubmitted = useSelector(state => state.theme.themeUpdate.isLoading);
    const errorMessage = useSelector(state => state.theme.themeUpdate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);


    // img upload
    const [image, setImage] = useState({ preview: state.Meepaisa_Theme_Image, raw: state.Meepaisa_Theme_Image });
    const [imgUrl, setImgUrl] = useState(state.Meepaisa_Theme_Image);
    const [uploadPic, setUploadPic] = useState(false);
    const callBackUploadImgae = data => {
        if (data) {
            setImgUrl(data.File_URL);
        }
        setUploadPic(false);
    };

    const handleImage = e => {
        setUploadPic(true);
        e.preventDefault();
        setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0]
        });
        let data = new FormData();
        data.append('file', new Blob([e.target.files[0]]));
        dispatch(
            uploadThemeImage(new Blob([e.target.files[0]]), callBackUploadImgae)
        );
    };

    const handleRemoveImage = e => {
        setImgUrl('');
        setImage({
            preview: '',
            raw: ''
        });
    };


    return (
        <Formik
            initialValues={{
                Is_Active: state.Is_Active > 0 ? true : false,
                Meepaisa_Theme_Description: state.Meepaisa_Theme_Description ? state.Meepaisa_Theme_Description : "",
                Meepaisa_Theme_Id: state.Meepaisa_Theme_Id ? state.Meepaisa_Theme_Id : "",
                Meepaisa_Theme_Image: state.Meepaisa_Theme_Image ? state.Meepaisa_Theme_Image : "",
                Meepaisa_Theme_Name: state.Meepaisa_Theme_Name ? state.Meepaisa_Theme_Name : "",
                Meepaisa_Theme_Video: state.Meepaisa_Theme_Video ? state.Meepaisa_Theme_Video : "",
                Modified_By: loginEmail
            }}
            validationSchema={
                Yup.object().shape({
                    Meepaisa_Theme_Description: Yup.string().required('Zone Theme Description is required'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                values.Meepaisa_Theme_Image = imgUrl;
                if (!values.Meepaisa_Theme_Image) {
                    submitTest = false;
                    errors.Meepaisa_Theme_Image = "Image is required.";
                }
                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    if (values.Is_Active) {
                        formValues.Is_Active = 1;
                    } else {
                        formValues.Is_Active = 0;
                    }
                    dispatch(updateThemeAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        {/* Meepaisa_Theme_Name */}
                                        <Grid item md={6} xs={6}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Zone Theme Name"
                                                name="Meepaisa_Theme_Name"
                                                disabled
                                                value={values.Meepaisa_Theme_Name}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        {/* Meepaisa_Theme_Video */}
                                        <Grid item md={6} xs={6}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Zone Theme Video"
                                                name="Meepaisa_Theme_Video"
                                                value={values.Meepaisa_Theme_Video}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        {/* Description */}
                                        <Grid item md={12} xs={12}>
                                            Description
                                            <TextareaAutosize
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Description"
                                                value={values.Meepaisa_Theme_Description}
                                                onChange={handleChange}
                                                name="Meepaisa_Theme_Description"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                            >
                                            </TextareaAutosize>
                                            <span style={{ color: "red" }}>
                                                {((touched.Meepaisa_Theme_Description && errors.Meepaisa_Theme_Description))}
                                            </span>
                                        </Grid>
                                        {/* Is_Active */}
                                        <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.Is_Active}
                                                        onChange={handleChange}
                                                        name="Is_Active"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Active"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Card>
                                        <CardContent>
                                            <Box display="flex" justifyContent="center">
                                                {image.preview ? (
                                                    <Avatar
                                                        className={classes.images}
                                                        alt="Brand Upload"
                                                        src={image.preview}
                                                        variant="square"
                                                    />
                                                ) : (
                                                    <>
                                                        <Avatar
                                                            className={classes.images}
                                                            alt="Brand Upload"
                                                            src="/static/images/image-placeholder.png"
                                                            variant="square"
                                                        />
                                                    </>
                                                )}
                                            </Box>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            >
                                                {image.preview ? <Button
                                                    style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                                                    onClick={handleRemoveImage}
                                                >
                                                    Remove Picture
                                                </Button> : <input
                                                    style={{ display: 'block', width: '80%' }}
                                                    id="upload-photo"
                                                    name="image"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImage}
                                                />}
                                                <br />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                    <span style={{ color: "red" }}>
                                        {!imgUrl && touched.Meepaisa_Theme_Image && errors.Meepaisa_Theme_Image}
                                    </span>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>
                        <Box display="flex" p={2}>
                            {isSubmitted ? <CircularProgress /> :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>}
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditMeepaisaTheme.propTypes = {
    className: PropTypes.string
};

export default EditMeepaisaTheme;