import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import MPProductComboView from './MeepaisaProductComboView';
import { useSelector } from 'react-redux';
import isEmpty from 'src/store/validations/is-empty';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const ViewMPProductComboView = () => {
    const classes = useStyles();
    const location = useLocation();
    const { state } = location;

    const [allProductCombos, setProductCombos] = useState([]);

    let productcombos = useSelector(state => state.meepaisaproductcombo.allproductcombos);
    useEffect(() => {
        if (!isEmpty(productcombos)) {
            setProductCombos(productcombos.meepaisaproductcombo);
        }
    }, [productcombos]);

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/meepaisa-product-combo"
                    variant="text"
                    size="small"
                    color="inherit"
                    state={state?.state}
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        View Meepaisa Combo Products
                    </Typography>
                </Box>
                <MPProductComboView state={state.state} data={state.data} allProductCombos={allProductCombos} />
            </Container>
        </Page>
    );
};

export default ViewMPProductComboView;