import React from 'react';
import { TablePagination } from '@material-ui/core';

function Pagination(props) {
    //props variable consists of all <List.Accordion> properties along with map data.
    const { pagination, handlePageChange, handleLimitChange } = props;
    

    return (
        (pagination?.TotalPages && pagination?.TotalPages >= 1) ?
            <TablePagination
                component="div"
                count={pagination?.TotalCount}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={pagination?.PageNo - 1}
                rowsPerPage={pagination?.PageSize}
                rowsPerPageOptions={[5, 10, 20]}
            />
            :
            <TablePagination
                component="div"
                count={0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={0}
                rowsPerPage={10}
                rowsPerPageOptions={[5, 10, 20]}
            />
    );
}

export default Pagination;