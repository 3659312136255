import {
  CITY_LOCATION_CREATE_FAIL,
  CITY_LOCATION_CREATE_REQUEST,
  CITY_LOCATION_CREATE_SUCCESS,
  CITY_LOCATION_FAIL,
  CITY_LOCATION_REQUEST,
  CITY_LOCATION_SUCCESS,
  CITY_LOCATION_UPDATE_FAIL,
  CITY_LOCATION_UPDATE_REQUEST,
  CITY_LOCATION_UPDATE_SUCCESS
} from "../actions/citylocationAction";

const initialState = {
  citylocations: { all: [], error: '', isLoading: false },
  citylocation: { citylocation: {}, error: '', isLoading: false },
  citylocationCreate: { citylocation: {}, error: '', isLoading: false },
  citylocationUpdate: { citylocation: {}, error: '', isLoading: false },
};
export default function (state = initialState, action) {
  switch (action.type) {
    case CITY_LOCATION_REQUEST:
      return { ...state, citylocations: { all: state.citylocations.all, error: '', isLoading: true } };
    case CITY_LOCATION_SUCCESS:
      return { ...state, citylocations: { all: action.payload, error: '', isLoading: false } };
    case CITY_LOCATION_FAIL:
      return { ...state, citylocations: { all: [], error: action.payload, isLoading: false } };

    case CITY_LOCATION_CREATE_REQUEST:
      return { ...state, citylocationCreate: { citylocation: {}, error: '', isLoading: true } };
    case CITY_LOCATION_CREATE_SUCCESS:
      return { ...state, citylocationCreate: { citylocation: action.payload, error: '', isLoading: false } };
    case CITY_LOCATION_CREATE_FAIL:
      return { ...state, citylocationCreate: { citylocation: {}, error: action.payload, isLoading: false } };

    case CITY_LOCATION_UPDATE_REQUEST:
      return { ...state, citylocationUpdate: { citylocation: {}, error: '', isLoading: true } };
    case CITY_LOCATION_UPDATE_SUCCESS:
      return { ...state, citylocationUpdate: { citylocation: action.payload, error: '', isLoading: false } };
    case CITY_LOCATION_UPDATE_FAIL:
      return { ...state, citylocationUpdate: { citylocation: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}