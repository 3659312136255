import {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAIL,
  USER_GET_REQUEST,
  USER_GET_SUCCESS,
  USER_GET_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  ROLES_REQUEST,
  ROLES_SUCCESS,
  ROLES_FAIL,
} from '../actions/userAction'

const initialState = {
  users: { all: [], error: '', isLoading: false },
  user: { user: {}, error: '', isLoading: false },
  userCreate: { user: {}, error: '', isLoading: false },
  userUpdate: { user: {}, error: '', isLoading: false },
  Roles: { roles: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USERS_REQUEST:
      return { ...state, users: { all: state.users.all, error: '', isLoading: true } };
    case USERS_SUCCESS:
      return { ...state, users: { all: action.payload, error: '', isLoading: false } };
    case USERS_FAIL:
      return { ...state, users: { all: [], error: action.payload, isLoading: false } };
    case USER_GET_REQUEST:
      return { ...state, user: { userCreate: {}, error: '', isLoading: true } };
    case USER_GET_SUCCESS:
      return { ...state, user: { user: action.payload, error: '', isLoading: false } };
    case USER_GET_FAIL:
      return { ...state, user: { user: {}, error: action.payload, isLoading: false } }
    case USER_CREATE_REQUEST:
      return { ...state, userCreate: { user: {}, error: '', isLoading: true } };
    case USER_CREATE_SUCCESS:
      return { ...state, userCreate: { user: state.userCreate.user, error: '', isLoading: false } };
    case USER_CREATE_FAIL:
      return { ...state, userCreate: { user: {}, error: action.payload, isLoading: false } };
    case USER_UPDATE_REQUEST:
      return { ...state, userUpdate: { user: {}, error: '', isLoading: true } };
    case USER_UPDATE_SUCCESS:
      return { ...state, userUpdate: { user: state.userUpdate.user, error: '', isLoading: false } };
    case USER_UPDATE_FAIL:
      return { ...state, userUpdate: { user: {}, error: action.payload, isLoading: false } };

    case ROLES_REQUEST:
      return { ...state, Roles: { roles: state.Roles.roles, error: '', isLoading: true }, };
    case ROLES_SUCCESS:
      return { ...state, Roles: { roles: action.payload, error: '', isLoading: false } };
    case ROLES_FAIL:
      return { ...state, Roles: { roles: [], error: action.payload, isLoading: false } };
    default:
      return state;
  }
}