import {
  PARTNER_TICKET_CREATE_REQUEST,
  PARTNER_TICKET_CREATE_SUCCESS,
  PARTNER_TICKET_CREATE_FAIL,
  PARTNER_TICKET_UPDATE_REQUEST,
  PARTNER_TICKET_UPDATE_SUCCESS,
  PARTNER_TICKET_UPDATE_FAIL,
  TICKET_PARTNERS_REQUEST,
  TICKET_PARTNERS_SUCCESS,
  TICKET_PARTNERS_FAIL,
} from '../actions/partnerticketActions';

const initialState = {
  partnerticketCreate: { partnerticket: {}, error: '', isLoading: false },
  partnerticketUpdate: { partnerticket: {}, error: '', isLoading: false },
  Partners: { partners: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PARTNER_TICKET_CREATE_REQUEST:
      return { ...state, partnerticketCreate: { partnerticket: {}, error: '', isLoading: true } };
    case PARTNER_TICKET_CREATE_SUCCESS:
      return { ...state, partnerticketCreate: { partnerticket: state.partnerticketCreate.partnerticket, error: '', isLoading: false } };
    case PARTNER_TICKET_CREATE_FAIL:
      return { ...state, partnerticketCreate: { partnerticket: {}, error: action.payload, isLoading: false } };
    case PARTNER_TICKET_UPDATE_REQUEST:
      return { ...state, partnerticketUpdate: { partnerticket: {}, error: '', isLoading: true } };
    case PARTNER_TICKET_UPDATE_SUCCESS:
      return { ...state, partnerticketUpdate: { partnerticket: state.partnerticketUpdate.partnerticket, error: '', isLoading: false } };
    case PARTNER_TICKET_UPDATE_FAIL:
      return { ...state, partnerticketUpdate: { partnerticket: {}, error: action.payload, isLoading: false } };
    case TICKET_PARTNERS_REQUEST:
      return { ...state, Partners: { partners: state.Partners.partners, error: '', isLoading: true }, };
    case TICKET_PARTNERS_SUCCESS:
      return { ...state, Partners: { partners: action.payload, error: '', isLoading: false } };
    case TICKET_PARTNERS_FAIL:
      return { ...state, Partners: { partners: [], error: action.payload, isLoading: false } };
    default:
      return state;
  }
}