
import config from './config';
import { toast } from 'react-toastify';
import { paginationPartnerEmployee } from './node-actions/nodepagination';
import {  nodeGetApi,boomiAdminApi } from './commonAxios';


export const PARTNER_EMPLOYEE_REQUEST = "PARTNER_EMPLOYEE_REQUEST";
export const PARTNER_EMPLOYEE_SUCCESS = "PARTNER_EMPLOYEE_SUCCESS";
export const PARTNER_EMPLOYEE_FAIL = "PARTNER_EMPLOYEE_FAIL";

export const PARTNER_EMPLOYEE_GET_REQUEST = "PARTNER_EMPLOYEE_GET_REQUEST";
export const PARTNER_EMPLOYEE_GET_SUCCESS = "PARTNER_EMPLOYEE_GET_SUCCESS";
export const PARTNER_EMPLOYEE_GET_FAIL = "PARTNER_EMPLOYEE_GET_FAIL";
export const PARTNER_EMPLOYEE_CREATE_REQUEST = "PARTNER_EMPLOYEE_CREATE_REQUEST";
export const PARTNER_EMPLOYEE_CREATE_SUCCESS = "PARTNER_EMPLOYEE_CREATE_SUCCESS";
export const PARTNER_EMPLOYEE_CREATE_FAIL = "PARTNER_EMPLOYEE_CREATE_FAIL";
export const PARTNER_EMPLOYEE_UPDATE_REQUEST = "PARTNER_EMPLOYEE_UPDATE_REQUEST";
export const PARTNER_EMPLOYEE_UPDATE_SUCCESS = "PARTNER_EMPLOYEE_UPDATE_SUCCESS";
export const PARTNER_EMPLOYEE_UPDATE_FAIL = "PARTNER_EMPLOYEE_UPDATE_FAIL";


/*=============================================================
            Partner employee create Action
===============================================================*/
export const createPartnerEmployeeAction = (formData, navigate, state, setErrorMessage, setErrors) => async dispatch => {
    dispatch({
        type: PARTNER_EMPLOYEE_CREATE_REQUEST
    });
    setErrorMessage({ Email_Id: { key: '', message: '' }, global: { key: '', message: '' } });
    boomiAdminApi.post(`/Super_Admin_Partner_Employee/Create`, formData
    )
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: PARTNER_EMPLOYEE_GET_SUCCESS
                });
                toast('Partner Employee created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/partner-employee-management', state = { state });
            } else {
                const errors = {};
                if (successResponse.Email_Id) {
                    errors.Email_Id = successResponse.UI_Display_Message;
                }
                setErrors(errors)
                setErrorMessage({ Email_Id: { key: successResponse.Email_Id ? successResponse.Email_Id : '', message: successResponse.Del_user_email_id ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Del_user_email_id) ? successResponse.UI_Display_Message : '' } });
                dispatch({
                    type: PARTNER_EMPLOYEE_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            setErrorMessage({ Email_Id: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
            dispatch({
                type: PARTNER_EMPLOYEE_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

/*=============================================================
            Partner employee update Action
===============================================================*/
export const updatePartnerEmployeeAction = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: PARTNER_EMPLOYEE_UPDATE_REQUEST
    });
    boomiAdminApi.put(`/Super_Admin_Partner_Employee/Update`, formData
    )
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: PARTNER_EMPLOYEE_UPDATE_SUCCESS
                });
                toast('Partner Employee updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/partner-employee-management', state = { state });
            } else {

                dispatch({
                    type: PARTNER_EMPLOYEE_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: PARTNER_EMPLOYEE_CREATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

/*=============================================================
            Partner employee all get Action
===============================================================*/
export const getAllPartnerEmployeeAction = formData => async dispatch => {
    try {
        dispatch({
            type: PARTNER_EMPLOYEE_REQUEST
        });
        let { data } = await nodeGetApi.post(
            `/Super_Admin_Partner_Employee/Get?PageNo=0&PageSize=${config.pageSize}&SortBy=PARTNER_EMPLOYEE_ID&SortOrder=ASC`,
            formData
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: PARTNER_EMPLOYEE_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: PARTNER_EMPLOYEE_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        toast("Something went wrong please load page again.", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        dispatch({
            type: PARTNER_EMPLOYEE_FAIL,
            payload: err
        });
    }
};

/*=============================================================
            Partner employee delete Action
===============================================================*/
export const deletePartnerEmployeeAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
    try {
        let { data } = await boomiAdminApi.post(`/Super_Admin_Partner_Employee/Delete`, formData
        );
        if (data) {
            toast('Partner Employee deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            dispatch(paginationPartnerEmployee(filters, pagination, callBackPagination));
        }
    } catch (err) {
    }
};

/*=============================================================
            Partner employee image Action
===============================================================*/
export const uploadPartnerEmployeeImage = (
    Email_Id,
    formData,
    callBackUploadImage
) => async dispatch => {
    try {
        let { data } = await boomiAdminApi.post(
            `/SA_Upload/Upload?functionality=PartnerEmployeeProfile&fileextension=png&filetype=Images&filename=PartnerEmployeeProfile`,
            formData
        );
        if (data) {
            callBackUploadImage(data);
        }
    } catch (err) { }
};

//Partner Employee
export const getPartnerEmployeeAction = (formData, callBackPartnerEmployeeData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(
            `/Super_Admin_Partner_Employee/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Partner_Employee_Id&SortOrder=ASC`,
            formData
        );
        if (data) {
            callBackPartnerEmployeeData(data)
        }
    } catch (err) {
    }
};