import config from './config';
import { toast } from 'react-toastify';
import { paginationDeals, paginationPendingDeals } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi,boomiAdminApi } from './commonAxios';
export const DEALS_REQUEST = "DEALS_REQUEST";
export const DEALS_SUCCESS = "DEALS_SUCCESS";
export const DEALS_FAIL = "DEALS_FAIL";
export const DEAL_GET_REQUEST = "DEAL_GET_REQUEST";
export const DEAL_GET_SUCCESS = "DEAL_GET_SUCCESS";
export const DEAL_GET_FAIL = "DEAL_GET_FAIL";
export const DEAL_CREATE_REQUEST = "DEAL_CREATE_REQUEST";
export const DEAL_CREATE_SUCCESS = "DEAL_CREATE_SUCCESS";
export const DEAL_CREATE_FAIL = "DEAL_CREATE_FAIL";
export const DEAL_UPDATE_REQUEST = "DEAL_UPDATE_REQUEST";
export const DEAL_UPDATE_SUCCESS = "DEAL_UPDATE_SUCCESS";
export const DEAL_UPDATE_FAIL = "DEAL_UPDATE_FAIL";
export const DEAL_PARTNERS_REQUEST = "DEAL_PARTNERS_REQUEST";
export const DEAL_PARTNERS_SUCCESS = "DEAL_PARTNERS_SUCCESS";
export const DEAL_PARTNERS_FAIL = "DEAL_PARTNERS_FAIL";
export const DEAL_BRANDS_REQUEST = "DEAL_BRANDS_REQUEST";
export const DEAL_BRANDS_SUCCESS = "DEAL_BRANDS_SUCCESS";
export const DEAL_BRANDS_FAIL = "DEAL_BRANDS_FAIL";
export const CATEGORIES_REQUEST = "CATEGORIES_REQUEST";
export const CATEGORIES_SUCCESS = "CATEGORIES_SUCCESS";
export const CATEGORIES_FAIL = "CATEGORIES_FAIL";
export const DEALS_APPROVE_REQUEST = "DEALS_APPROVE_REQUEST";
export const DEALS_APPROVE_SUCCESS = "DEALS_APPROVE_SUCCESS";
export const DEALS_APPROVE_FAIL = "DEALS_APPROVE_FAIL";

/*=============================================================
                  Add DEAL Action
  =============================================================*/
export const createDealAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: DEAL_CREATE_REQUEST
  });
  setErrorMessage({ Title: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Deals/Deal_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DEAL_CREATE_SUCCESS
        });
        toast('Deal added  successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/deal-management');
      } else {
        const errors = {};
        if (successResponse.Title) {
          errors.Title = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Title: { key: successResponse.Title ? successResponse.Title : '', message: successResponse.Title ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Title) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: DEAL_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Title: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: DEAL_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};

/*=============================================================
                edit deal Action
===============================================================*/
export const updateDealAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: DEAL_UPDATE_REQUEST
  });
  setErrorMessage({ Title: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Deals/Deal_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DEAL_UPDATE_SUCCESS
        });
        toast('Deal updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/deal-management');
      } else {
        const errors = {};
        if (successResponse.Title) {
          errors.Title = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Title: { key: successResponse.Title ? successResponse.Title : '', message: successResponse.Title ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Title) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: DEAL_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Title: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: DEAL_UPDATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};

/*=============================================================
                Deal Image Upload Api
===============================================================*/
export const uploadDealImage = (
  formData,
  callBackUploadImgae,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=Deals&fileextension=png&filetype=Images&filename=Deals`,formData);
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};

/*=============================================================
                  Get All deal Action
===============================================================*/
export const getAllDealAction = formData => async dispatch => {
  try {
    dispatch({
      type: DEALS_REQUEST
    });
    let { data } =await nodeGetApi.post(`/Deals/Deal_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=DEAL_ID&SortOrder=ASC`,
      formData,
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: DEALS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: DEALS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: DEALS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  DEALS Delete Action
===============================================================*/
export const deleteDealAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Deals/Deal_Delete`, formData, {
      // Axios looks for the `auth` option, and, if it is set, formats a
      // basic auth header for you automatically.
    });
    if (data) {
      toast('Deal deleted sucessfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationDeals(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

//Node API
export const getSingleDeal = (formData, callBackDealData) => async dispatch => {
  try {
    let { data, status } =await nodeGetApi.post(`/Deals/Deal_Get_Admin`,
      formData,
    );
    if (data) {
      callBackDealData(data)
    }
  } catch (err) {
  }
};

/*=============================================================
           Get All onlinePhysical partners Action
===============================================================*/
export const getOnlinePhysicalPartners = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: 'FILTER',
      Status: "Active",
      search: "",
      search_by_filter: "",
    };
    dispatch({
      type: DEAL_PARTNERS_REQUEST
    });
    let { data } =await nodeGetApi.post(`/Partners/OnlineAndPhysical_Details_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`,
      formData,
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: DEAL_PARTNERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: DEAL_PARTNERS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: DEAL_PARTNERS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  Get All Brands Action
===============================================================*/
export const getAllBrands = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Brand_Status: "Active",
      search_by_filter: "",
      search: "",
    };
    dispatch({
      type: DEAL_BRANDS_REQUEST
    });
    let { data } =await nodeGetApi.post(`/Brand/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=BRAND_ID&SortOrder=ASC`,
      formData,
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: DEAL_BRANDS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: DEAL_BRANDS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: DEAL_BRANDS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                      Get All Categories
 =============================================================*/
export const getAllCategories = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Status: "Active",
      search_by_filter: "",
      search: "",
    };
    dispatch({
      type: CATEGORIES_REQUEST
    });
    let { data } =await nodeGetApi.post(`/Categories/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=CATEGORY_ID&SortOrder=ASC`,
      formData,
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CATEGORIES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CATEGORIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again", 3000);
    dispatch({
      type: CATEGORIES_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  Get All approved deals Action
 =============================================================*/
export const getAllApprovalDealAction = formData => async dispatch => {
  try {
    dispatch({
      type: DEALS_APPROVE_REQUEST
    });
    let { data } =await nodeGetApi.post(`/Deals/Deal_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=DEAL_ID&SortOrder=ASC`,
      formData,
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: DEALS_APPROVE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: DEALS_APPROVE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: DEALS_APPROVE_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                  update approve deal action
 =============================================================*/
export const updateDealApprovalAction = (formData, approvedealData, filters, pagination, callBackPagination) => async dispatch => {
  dispatch({
    type: DEAL_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Deals/Deal_Update`, formData)
  .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DEAL_UPDATE_SUCCESS
        });
        toast('Deal has been Accepted.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        dispatch(paginationPendingDeals(filters, pagination, callBackPagination));
        dispatch(getAllDealAction(approvedealData));
      } else {
        const errors = {};
        dispatch({
          type: DEAL_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: DEAL_UPDATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};

//Get Deal
export const getDealsAction = (formData, callBackDealsData) => async dispatch => {
  try {
    let { data } =await nodeGetApi.post(`/Deals/Deal_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=DEAL_ID&SortOrder=ASC`,
      formData,
    );
    if (data) {
      callBackDealsData(data)
    }
  } catch (err) {
  }
};