import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    makeStyles,
    TextareaAutosize,
    TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { getAllBiddingUserServices, getAllPartnerAddress, getAllPartnerAvailServices, UpdatePartnerBiddingServiceQuoteAction } from 'src/store/actions/partnerbiddingservicequoteAction';

const statusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const bidstatusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'INITIATED',
        label: 'INITIATED'
    },
    {
        value: 'PROCESSING',
        label: 'PROCESSING'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
    {
        value: 'COMPLETED',
        label: 'COMPLETED'
    }
];

const useStyles = makeStyles(() => ({
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const EditPartnerBiddingServiceQuotes = ({ className, state, data, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const isSubmitted = useSelector(state => state.partnerbiddingservicequotes.biddingpartnerservicequoteCreate.isLoading);
    const errorMessage = useSelector(state => state.partnerbiddingservicequotes.biddingpartnerservicequoteCreate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const bidpartavailservices = useSelector(state => state.partnerbiddingservicequotes.biddingpartneravailservices.all);

    useEffect(() => {
        let formdata = {
            Records_Filter: 'FILTER',
            Partner_Bidding_Available_Service_Id: "",
            Status: "Active",
            Bidding_Service_Id: state?.Bidding_Service_Id,
            Partner_Detail_Id: "",
            search_by_filter: "",
            search: ""
        }

        let addressdata = {
            search_by_filter: "",
            search: "",
            Records_Filter: "FILTER",
            Partner_Details_Id: data?.Partner_Detail_Id
        }
        dispatch(getAllBiddingUserServices())
        dispatch(getAllPartnerAvailServices(formdata))
        dispatch(getAllPartnerAddress(addressdata, callBackUserLocationData))
    }, []);

    const [locationdata, setLocationData] = React.useState([]);

    let USERLOCATION = []
    if (locationdata) {
        for (let i = 0; i < locationdata?.length; i++) {
            USERLOCATION.push(
                {
                    label: locationdata[i].Partner_Address_Id,
                    value: locationdata[i].Partner_Address_Id,
                })
        }
    }

    const callBackUserLocationData = (data) => {
        setLocationData(data)
    }

    const setLocationType = (value) => {
        if (value !== "") {
            let data = {
                search_by_filter: "",
                search: "",
                Records_Filter: "FILTER",
                Partner_Details_Id: value
            }
            dispatch(getAllPartnerAddress(data, callBackUserLocationData))
        }
    }

    // Schedule Call
    let nDate = new Date();
    let month = String(nDate.getMonth() + 1).length > 1 ? String(nDate.getMonth() + 1) : "0" + String(nDate.getMonth() + 1);
    let date = String(nDate.getDate()).length > 1 ? String(nDate.getDate()) : "0" + String(nDate.getDate());
    const [dos, setDos] = useState(nDate.getFullYear() + "-" + month +
        "-" + date);

    return (
        <Formik
            initialValues={{
                Advance_Token_Amount: data.Advance_Token_Amount ? data.Advance_Token_Amount : "",
                Bidding_Status: data.Bidding_Status ? data.Bidding_Status : "",
                ESI_Commision_Amount: data.Esi_Commision_Amount ? data.Esi_Commision_Amount : "",
                Exttra_Service_Per_Day: data.Extra_Service_Per_Day ? data.Extra_Service_Per_Day : "",
                Exttra_Service_Per_Hour: data.Extra_Service_Per_Hour ? data.Extra_Service_Per_Day : "",
                Modified_By: loginEmail,
                Partner_Bidding_Service_Quote_Id: data.Partner_Bidding_Service_Quote_Id ? data.Partner_Bidding_Service_Quote_Id : "",
                Partner_Comments: data.Partner_Comments ? data.Partner_Comments : "",
                Partner_Detail_Id: data.Partner_Detail_Id ? data.Partner_Detail_Id : "",
                Partner_Location: data.Partner_Location ? data.Partner_Location : "",
                Partner_Total_Amount: data.Partner_Total_Amount ? data.Partner_Total_Amount : "",
                Payment_Mode: data.Payment_Mode ? data.Payment_Mode : "",
                Quotation_Amount: data.Quotation_Amount ? data.Quotation_Amount : "",
                Service_Price_Per_Day: data.Service_Price_Per_Day ? data.Service_Price_Per_Day : "",
                Service_Price_Per_Hour: data.Service_Price_Per_Hour ? data.Service_Price_Per_Hour : "",
                Service_Sub_Type: data.Service_Sub_Type ? data.Service_Sub_Type : "",
                Status: data.Status ? data.Status : "",
                User_Service_Bidding_Id: data.User_Service_Bidding_Id ? data.User_Service_Bidding_Id : "",

            }}
            validationSchema={
                Yup.object().shape({
                    Partner_Detail_Id: Yup.string().required('Partner Name is required.'),
                    Bidding_Status: Yup.string().required('Bidding Status is required.'),
                    Partner_Comments: Yup.string().required('Partner Comments is required.'),
                    Partner_Location: Yup.string().required('Partner Location is required.'),
                    Status: Yup.string().required('Status is required.'),
                    Service_Price_Per_Hour: Yup.number().positive('Service Price Per Hour must be a positive number.'),
                    Service_Price_Per_Day: Yup.number().positive('Service Price Per Day must be a positive number.'),
                    Advance_Token_Amount: Yup.number().positive('Advance Token Amount must be a positive number.')
                        .required('Advance Token Amount is required.'),
                    Quotation_Amount: Yup.number().positive("Quotation Amount must be a positive number.").moreThan(Yup.ref('Advance_Token_Amount'), 'Require Quotation Amount greater than Advance Token Amount.')
                    .required('Quotation Amount is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(UpdatePartnerBiddingServiceQuoteAction(formValues, navigate, state));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Detail_Id && errors.Partner_Detail_Id)}
                                                fullWidth
                                                helperText={touched.Partner_Detail_Id && errors.Partner_Detail_Id}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setLocationType(e.target.value)
                                                }}
                                                label="Partner Name"
                                                name="Partner_Detail_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Partner_Detail_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {bidpartavailservices?.sort((a, b) => a.Partner_Name?.localeCompare(b.Partner_Name))?.map((option, i) => (
                                                    <option key={i} value={option.Partner_Detail_Id}>
                                                        {option.Partner_Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Location && errors.Partner_Location)}
                                                fullWidth
                                                helperText={touched.Partner_Location && errors.Partner_Location}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Location"
                                                name="Partner_Location"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Partner_Location}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {locationdata?.map(option => (
                                                    <option key={option.Partner_Address_Id} value={option.Partner_Address_Id}>
                                                        {option.Location}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Price_Per_Hour && errors.Service_Price_Per_Hour)}
                                                fullWidth
                                                helperText={touched.Service_Price_Per_Hour && errors.Service_Price_Per_Hour}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Service Price Per Hour"
                                                name="Service_Price_Per_Hour"
                                                value={values.Service_Price_Per_Hour}
                                                variant="outlined"
                                                type="number"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Price_Per_Day && errors.Service_Price_Per_Day)}
                                                fullWidth
                                                helperText={touched.Service_Price_Per_Day && errors.Service_Price_Per_Day}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Service Price Per Day"
                                                name="Service_Price_Per_Day"
                                                value={values.Service_Price_Per_Day}
                                                variant="outlined"
                                                type="number"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Advance_Token_Amount && errors.Advance_Token_Amount)}
                                                fullWidth
                                                helperText={touched.Advance_Token_Amount && errors.Advance_Token_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Token Amount"
                                                name="Advance_Token_Amount"
                                                value={values.Advance_Token_Amount}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Quotation_Amount && errors.Quotation_Amount)}
                                                fullWidth
                                                helperText={touched.Quotation_Amount && errors.Quotation_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Quotation Amount"
                                                name="Quotation_Amount"
                                                value={values.Quotation_Amount}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Exttra_Service_Per_Day && errors.Exttra_Service_Per_Day)}
                                                fullWidth
                                                helperText={touched.Exttra_Service_Per_Day && errors.Exttra_Service_Per_Day}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Extra Service Per Day"
                                                name="Exttra_Service_Per_Day"
                                                value={values.Exttra_Service_Per_Day}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Exttra_Service_Per_Hour && errors.Exttra_Service_Per_Hour)}
                                                fullWidth
                                                helperText={touched.Exttra_Service_Per_Hour && errors.Exttra_Service_Per_Hour}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Extra Service Per Hour"
                                                name="Exttra_Service_Per_Hour"
                                                value={values.Exttra_Service_Per_Hour}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Total_Amount && errors.Partner_Total_Amount)}
                                                fullWidth
                                                helperText={touched.Partner_Total_Amount && errors.Partner_Total_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Total Amount"
                                                name="Partner_Total_Amount"
                                                value={values.Partner_Total_Amount}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.ESI_Commision_Amount && errors.ESI_Commision_Amount)}
                                                fullWidth
                                                helperText={touched.ESI_Commision_Amount && errors.ESI_Commision_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ESI Commision Amount"
                                                name="ESI_Commision_Amount"
                                                value={values.ESI_Commision_Amount}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Payment_Mode && errors.Payment_Mode)}
                                                fullWidth
                                                helperText={touched.Payment_Mode && errors.Payment_Mode}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Payment Mode"
                                                name="Payment_Mode"
                                                value={values.Payment_Mode}
                                                variant="outlined"
                                            />
                                        </Grid>



                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Status && errors.Bidding_Status)}
                                                fullWidth
                                                helperText={touched.Bidding_Status && errors.Bidding_Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Status"
                                                name="Bidding_Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Status}
                                                variant="outlined"
                                            >
                                                {bidstatusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            Partner Comments
                                            <TextareaAutosize
                                                value={values.Partner_Comments}
                                                onChange={handleChange}
                                                name="Partner_Comments"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Partner Comments"
                                            >
                                            </TextareaAutosize>
                                            <span style={{ color: "red" }}>
                                                {((touched.Partner_Comments && errors.Partner_Comments))}
                                            </span>
                                        </Grid>


                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditPartnerBiddingServiceQuotes.propTypes = {
    className: PropTypes.string
};

export default EditPartnerBiddingServiceQuotes;