import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Box,
  Button,
  TextField,
  makeStyles
} from '@material-ui/core';
import { loginCheck } from '../../store/actions/authActions'
import { useDispatch, useSelector } from 'react-redux';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Forgot from './forgot-password';
import Popup from 'src/components/dialogs/PopupNoTitle';
import ProgressBar from 'src/components/bars/progress-bar';
import { useCurrentPosition } from 'react-use-geolocation';

function Copyright() {
  return (

    <Typography component="div" variant="body2" color="textSecondary" align="center">
      <div style={{ fontSize: "10px", }}>
        {'Copyright © '}
        <Link color="inherit" href="https://www.easystepin.com/">
          EasyStepIn
        </Link>{' 2016 - '}
        {new Date().getFullYear()}
        {'. All Rights Reserved.'}
      </div>
    </Typography>

  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'hidden'
  },
  image: {
    backgroundImage: 'url(/static/images/loginBg.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(9, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    display: 'block'
  }
}));

export default function Login() {
  const classes = useStyles();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);

  const signupResponse = (status) => {
    setOpenPopup(false);
  }
  const [position, error] = useCurrentPosition();

  const [ip, setIP] = useState('');
  const [city, setCity] = useState('');
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);
  const setTimeErrorMessage = (message) => {
    setSeconds(3);
    setErrorMessage(message);
  };

  const [showPassword, setShowPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const isSubmited = useSelector(state => state.auth.isLoading);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  const browserPayload = (isBrowser, browser, engine, os, ua) => ({
    isBrowser,
    browserMajorVersion: (browser.major),
    browserFullVersion: (browser.version),
    browserName: (browser.name),
    engineName: (engine.name),
    engineVersion: (engine.version),
    osName: (os.name),
    osVersion: (os.version),
    userAgent: (ua),
  });

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar} src={"../../../../newfavicon.png"} />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Formik
            initialValues={
              {
                Email_Id: '',
                password: ''
              }
            }

            validationSchema={Yup.object().shape({
              Email_Id: Yup.string()
                .email('Please provide valid Email Address.')
                .max(255)
                .required('Email Address is required.'),
              password: Yup.string()
                .max(255)
                .required('Password is required.')
            })}

            onSubmit={(values) => {
              try {
                let formdata = {
                  "Email_Id": values.Email_Id,
                  "Device_Identity": browserPayload,
                  "Password": values.password,
                  "Latitude": position?.coords?.latitude || "",
                  "IP_Address": ip,
                  "Longitude": position?.coords?.longitude || "",
                  "Location": city
                }
                dispatch(loginCheck(values, setTimeErrorMessage, navigate, formdata));
              } catch (error) {
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <ConnectedFocusError />
                <TextField
                  error={Boolean(touched.Email_Id && errors.Email_Id)}
                  fullWidth
                  helperText={touched.Email_Id && errors.Email_Id}
                  label="Email Address"
                  margin="normal"
                  name="Email_Id"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Email_Id}
                  variant="outlined"
                  autoCapitalize='none'
                />
                <FormControl fullWidth variant="outlined" error={Boolean(touched.password && errors.password)}>
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue("password", event.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                  <FormHelperText >{touched.password && errors.password}</FormHelperText>
                </FormControl>
                <center style={{ color: "red", marginTop: "5px", marginBottom: "5px" }}>
                  {seconds > 0 && <><small >{errorMessage}</small><ProgressBar completed={30 * seconds} /></>}
                </center>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: '#27B6CC' }}
                  className={classes.submit}
                  disabled={isSubmited}
                >
                  {/* Sign In */}
                  <span style={{ color: 'white' }}>Sign In</span>
                </Button>
                <Grid container>
                  <Grid item xs> <center>
                    <Link href="#" variant="body2" onClick={() => {
                      setOpenPopup(true);
                      setErrorMessage("");
                      resetForm();
                    }} >
                      <u>Forgot password?</u>
                    </Link>
                  </center>
                    <Box mt={2}>
                      <Copyright />
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>

        <Popup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}>
          <Forgot signinResponse={signupResponse} />
        </Popup>
      </Grid>
    </Grid >
  );
}