
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Bidding_service_Id', numeric: false, disablePadding: true, label: 'Bidding Service ID' },
  { id: 'Service_Type', numeric: false, disablePadding: false, label: 'Service Type' },
  { id: 'Description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'Created_By', numeric: false, disablePadding: false, label: 'Created By' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allBiddingServices,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allBiddingServicesNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const editBiddingServicesHandler = biddingserviceData => e => {
    navigate('/app/edit-biddingservices', { state: biddingserviceData });
  };

  const visibleBiddingServicesHandler = biddingserviceData => e => {
    navigate("/app/view-biddingservices", { state: biddingserviceData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1150}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.id !== "Description" ? (
                      <TableSortLabel className={classes.headerColumn}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>) : (<span style={{ fontSize: "13px", fontWeight: "bold" }} > {headCell.label}</span>)
                    }
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allBiddingServices) &&
                (allBiddingServices).map(service => (
                  <TableRow
                    hover
                    key={service.Bidding_service_Id}
                  >
                    <TableCell >{service.Bidding_service_Id}</TableCell>
                    <TableCell >{service.Service_Type}</TableCell>
                    <TableCell width="16%" ><div style={{ maxHeight: "40px", overflowY: "hidden", lor: '#1665D8' }} dangerouslySetInnerHTML={{ __html: service.Description }}></div>
                    </TableCell>
                    <TableCell style={{ color: '#1665D8' }}>{service.Created_By} </TableCell>
                    <TableCell>{service.Status} </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Bidding_Services_Write?.Value === "Bidding_Services_Write" && RolePermissions?.Bidding_Services_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Tooltip title="Bidding Service Details">
                          <Button
                            onClick={visibleBiddingServicesHandler(service)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        <Button
                          onClick={editBiddingServicesHandler(service)}>
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Bidding_Services_Read?.Value === "Bidding_Services_Read" && RolePermissions?.Bidding_Services_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="Bidding Service Details">
                          <Button
                            onClick={visibleBiddingServicesHandler(service)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allBiddingServices) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allBiddingServices: PropTypes.array.isRequired
};

export default Results;