import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
  Avatar
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  updateBannerAction,
  uploadBannerImage,
  getofferbyid,
  getcouponbyid,
  getdealbyid,
  getbannerpartners,
  getbannercities,
  getbannerStoretypes,
  getproductbyIDs,
} from './../../store/actions/bannerAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { getAllPartnerAction } from './../../store/actions/productpartnerAction';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles(() => ({
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const statusOption = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const BannerTypes = [
  {
    value: 'Coupon',
    label: 'Coupon'
  },
  {
    value: 'Deal',
    label: 'Deal'
  },
  {
    value: 'Product',
    label: 'Product'
  },
  {
    value: 'Partner',
    label: 'Partner'
  },
  {
    value: 'City',
    label: 'City'
  },
  {
    value: 'Store Type',
    label: 'Store Type'
  },
];

const PartnerTypes = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Online',
    label: 'Online'
  },
  {
    value: 'Physical',
    label: 'Physical'
  },
];

const bannerforOption = [
 
  {
    value: 'REGULAR',
    label: 'REGULAR'
  },
  {
    value: 'MFH',
    label: 'MFH'
  }
];
const EditBanner = ({ className, ...rest }) => {
  const location = useLocation();
  let { state } = location;

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getofferbyid());
    dispatch(getcouponbyid());
    dispatch(getdealbyid());
    const formData = {
      Product_Id: state.Banner_Offer_Id,
      Records_Filter: "FILTER",
      search_by_filter: "",
      search: "",
    };
    dispatch(getAllPartnerAction(formData));
    dispatch(getbannerpartners());
    dispatch(getbannercities());
    dispatch(getbannerStoretypes());
    let prodidData = {
      Product_Id: state.Banner_Offer_Id,
      Records_Filter: "FILTER"
    };
    dispatch(getproductbyIDs(prodidData))
  }, []);

  const [errorMessage, setErrorMessage] = useState({ Banner_Title: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.banner.bannerUpdate.isLoading);
  const partners = useSelector(state => state.banner.bannerpartners.partners);
  const deals = useSelector(state => state.banner.bannerdeals.deals);
  const coupons = useSelector(state => state.banner.bannercoupons.coupons);
  const createdBy = useSelector(state => state.auth.user.Email_Id);
  const productPartners = useSelector(state => state.productPartner.partners.all);
  const bannerPartners = useSelector(state => state.banner.bannerallpartners.allpartners);
  const bannerCities = useSelector(state => state.banner.bannercities.cities);
  const bannerStoretypes = useSelector(state => state.banner.bannerstoretypes.storetypes);
  const productids = useSelector(state => state.banner.bannerproductids.productids);

  // Richtext editor
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Banner_Description || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Banner_Description);
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const [image, setImage] = useState({ preview: state.Banner_Image, raw: state.Banner_Image });
  const [imgUrl, setImgUrl] = useState(state.Banner_Image);
  const [uploadPic, setUploadPic] = useState(false);

  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadBannerImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  const values = {
    Banner_Id: state.Banner_Id ? state.Banner_Id : "",
    Banner_Offer_Type: state.Banner_Offer_Type ? state.Banner_Offer_Type : "",
    Banner_Offer_Id: state.Banner_Offer_Id ? state.Banner_Offer_Id : "",
    Banner_Partner_Type: state.Banner_Partner_Type ? state.Banner_Partner_Type : "",
    Partner_Details_Id: state.Partner_Details_Id ? state.Partner_Details_Id : "",
    Banner_Title: state.Banner_Title ? state.Banner_Title : "",
    Description: state.Banner_Description ? state.Banner_Description : "",
    Banner_Image: imgUrl,
    Banner_Status: state.Banner_Status ? state.Banner_Status : "",
    From_Date: state.Banner_From_Date ? state.Banner_From_Date : "",
    To_Date: state.Banner_To_Date ? state.Banner_To_Date : "",
    Modified_By: createdBy,
    Banner_Priority_Sequence: state.Banner_Priority_Sequence ? state.Banner_Priority_Sequence : "",
    Banner_For: state.Banner_For ? state.Banner_For : "",
  }

  const Form_Validation = Yup.object().shape({
    Banner_Title: Yup.string().matches(/^[a-zA-Z][\sa-zA-Z]*/, "Please remove special characters & white spaces at the beginning for Banner Title.").min(3, "Banner Title requires atleast 3 characters.").notOneOf([errorMessage.Banner_Title.key, null], errorMessage.Banner_Title.message).required("Banner Title is required."),
    Banner_Status: Yup.string().required('Status is required.'),
    From_Date: Yup.date().min(new Date(state.Banner_From_Date) < new Date(Date.now() - 1 * 24 * 60 * 60 * 1000) ? new Date(new Date(state.Banner_From_Date) - 1 * 24 * 60 * 60 * 1000) : new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), new Date(state.Banner_From_Date) < new Date(Date.now() - 1 * 24 * 60 * 60 * 1000) ? "Required From Date greater than or equal to " + ((new Date(state.Banner_From_Date).getMonth() > 8) ? (new Date(state.Banner_From_Date).getMonth() + 1) : ('0' + (new Date(state.Banner_From_Date).getMonth() + 1))) + '/' + ((new Date(state.Banner_From_Date).getDate() > 9) ? new Date(state.Banner_From_Date).getDate() : ('0' + new Date(state.Banner_From_Date).getDate())) + '/' + new Date(state.Banner_From_Date).getFullYear() + "." : "Required From Date greater than or equal to today.").required("From Date is required."),
    To_Date: Yup.date().min(Yup.ref('From_Date'), "To Date can't be before From Date.").required("To Date is required."),
    Banner_Offer_Id: Yup.string().when("Banner_Offer_Type", (Banner_Offer_Type, schema) => {
      return Banner_Offer_Type ? schema.required('Banner Offer By ' + Banner_Offer_Type + ' is required.') : schema.required('Banner Offer By is required.')
    }),
    Banner_Partner_Type: Yup.string().when('Banner_Offer_Type', { is: "Product", then: Yup.string().required('Banner Partner Type is required.'), }),
    Partner_Details_Id: Yup.string().when('Banner_Offer_Type', { is: "Product", then: Yup.string().required('Partner Name Type is required.'), }),
    Banner_Offer_Type: Yup.string().required('Banner Offer Type is required.'),
    Banner_Priority_Sequence: Yup.number().min(1, "Banner Priority Sequence must be at least 1.").required('Banner Priority Sequence is required.'),
  })
  const onSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(false);
    let submitTest = true;
    const errors = {};
    values.Banner_Image = imgUrl;
    values.Description = rhDiscription;
    if (!values.Banner_Image) {
      errors.Banner_Image = "Image is required.";
      submitTest = false;
    } if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
      submitTest = false;
      errors.Description = "Description is required.";
    }
    else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
      submitTest = false;
      errors.Description = "Please provide at least " + rhDiscriptionMin + " characters";
    }
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      dispatch(updateBannerAction(formValues, navigate, setErrorMessage, setErrors));
    }
  }
  return (
    <Formik initialValues={{ ...values }} validationSchema={Form_Validation} onSubmit={onSubmit}>
      {({
        errors,
        handleBlur,
        handleChange,
        values,
        touched,
        setFieldValue
      }) => (
        <Form>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Banner_Title && errors.Banner_Title)}
                        label="Banner Title"
                        name="Banner_Title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Banner_Title}
                        variant="outlined"
                        helperText={<ErrorMessage name="Banner_Title" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Banner_Offer_Type && errors.Banner_Offer_Type)}
                        fullWidth
                        helperText={touched.Banner_Offer_Type && errors.Banner_Offer_Type}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("Banner_Partner_Type", "");
                          setFieldValue("Partner_Details_Id", "");
                          setFieldValue("Banner_Offer_Id", "");
                        }}
                        label="Banner Offer Type"
                        name="Banner_Offer_Type"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        value={values.Banner_Offer_Type}
                        variant="outlined"
                      >
                        <option key="" value="">--Please Select--</option>
                        {BannerTypes.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {values.Banner_Offer_Type === "Product" ?
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Banner Offer By Product"
                          name="Banner_Offer_Id"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ native: true }}
                          value={values.Banner_Offer_Id}
                          variant="outlined"
                          disabled
                        >
                          {productids.map(option => (
                            <option key={option.Product_Id} value={option.Product_Id}>
                              {option.Product_Name}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      :
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Banner_Offer_Id && errors.Banner_Offer_Id)}
                          fullWidth
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            let partner;
                            if (values.Banner_Offer_Type === "Deal") {
                              partner = deals?.filter(item => { return item.Deal_Id === e.target.value })?.[0]?.Partner_Details_Id;
                            } else if (values.Banner_Offer_Type === "Coupon") {
                              partner = coupons.filter(item => { return item.Coupon_Id === e.target.value })?.[0]?.Partner_Details_Id;
                            } else if (values.Banner_Offer_Type === "Partner") {
                              partner = bannerPartners.filter(item => { return item.Partner_Details_Id === e.target.value })?.[0]?.Partner_Details_Id;
                            } else {
                              setFieldValue("Banner_Partner_Type", "");
                              setFieldValue("Partner_Details_Id", "");
                            }
                            if (partner) {
                              setFieldValue("Banner_Partner_Type", partners?.filter(item => { return item.Partner_Details_Id === partner })?.[0]?.Type);
                              setFieldValue("Partner_Details_Id", partner);
                            }
                          }}
                          label={"Banner Offer By " + values.Banner_Offer_Type}
                          name="Banner_Offer_Id"
                          value={values.Banner_Offer_Id}
                          select
                          SelectProps={{ native: true }}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          helperText={<ErrorMessage name="Banner_Offer_Id" />}
                        >
                          <option key="" value="">--Please Select--</option>
                          {values.Banner_Offer_Type === "Deal" && deals?.sort((a, b) => a.Title?.localeCompare(b.Title))?.map(option => (
                            <option value={option.Deal_Id} key={option.Deal_Id}>{option.Title}</option>
                          ))}
                          {values.Banner_Offer_Type === "Coupon" && coupons?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                            <option value={option.Coupon_Id} key={option.Coupon_Id}>{option.Name}</option>
                          ))}
                          {/* {values.Banner_Offer_Type === "Product" && products?.sort((a, b) => a.Product_Name?.localeCompare(b.Product_Name))?.map(option => (
                            <option value={option.Product_Id} key={option.Product_Id}>{option.Product_Name}</option>
                          ))} */}
                          {values.Banner_Offer_Type === "Partner" && bannerPartners?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                            <option value={option.Partner_Details_Id} key={option.Partner_Details_Id}>{option.Name}</option>
                          ))}
                          {values.Banner_Offer_Type === "City" && bannerCities?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                            <option value={option.City_Id} key={option.City_Id}>{option.Name + " - " + option.State + " - " + option.Country}</option>
                          ))}
                          {values.Banner_Offer_Type === "Store Type" && bannerStoretypes?.sort((a, b) => a.Store_Type_Name?.localeCompare(b.Store_Type_Name))?.map(option => (
                            <option value={option.Store_Type_Name} key={option.Store_Type_Name}>{option.Store_Type_Name}</option>
                          ))}
                        </TextField>
                      </Grid>
                    }

                    {values.Banner_Offer_Type !== "City" && values.Banner_Offer_Type !== "Store Type" ?
                      <>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Banner_Partner_Type && errors.Banner_Partner_Type)}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={(e) => { handleChange(e); }}
                            label="Banner Partner Type"
                            name="Banner_Partner_Type"
                            disabled={values.Banner_Offer_Type === "Deal" || values.Banner_Offer_Type === "Coupon" || values.Banner_Offer_Type === "Partner"}
                            value={values.Banner_Partner_Type}
                            select
                            SelectProps={{ native: true }}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={<ErrorMessage name="Banner_Partner_Type" />}
                          >
                            {PartnerTypes.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>

                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Partner_Details_Id && errors.Partner_Details_Id)}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Partner Name"
                            name="Partner_Details_Id"
                            value={values.Partner_Details_Id}
                            select
                            disabled={values.Banner_Offer_Type === "Deal" || values.Banner_Offer_Type === "Coupon" || values.Banner_Offer_Type === "Partner"}
                            SelectProps={{ native: true }}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={<ErrorMessage name="Partner_Details_Id" />}
                          >
                            <option key="" value="">--Please Select--</option>
                            {(values.Banner_Offer_Type === "Deal" || values.Banner_Offer_Type === "Coupon" || values.Banner_Offer_Type === "Partner") && partners.filter(option => option.Type === values.Banner_Partner_Type).map(option => (
                              <option value={option.Partner_Details_Id} key={option.Partner_Details_Id}>{option.Name}</option>
                            ))}
                            {values.Banner_Offer_Type === "Product" && partners.filter(option => option.Type === values.Banner_Partner_Type).filter(option => {
                              for (let i = 0; i < productPartners.length; i++) {
                                if (productPartners[i].Partner_Details_Id === option.Partner_Details_Id) {
                                  return true;
                                }
                              }
                              return false;
                            })?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                              <option value={option.Partner_Details_Id} key={option.Partner_Details_Id}>{option.Name}</option>
                            ))}
                          </TextField>
                        </Grid>
                      </>
                      : null}

                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                      </span>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.From_Date && errors.From_Date)}
                        type="date"
                        label="From Date"
                        name="From_Date"
                        InputLabelProps={{ shrink: true }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.From_Date}
                        variant="outlined"
                        helperText={<ErrorMessage name="From_Date" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field as={TextField}
                        fullWidth
                        error={Boolean(touched.To_Date && errors.To_Date)}
                        type="date"
                        label="To Date"
                        name="To_Date"
                        InputLabelProps={{ shrink: true }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.To_Date}
                        variant="outlined"
                        helperText={<ErrorMessage name="To_Date" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Banner_Priority_Sequence && errors.Banner_Priority_Sequence)}
                        fullWidth
                        helperText={touched.Banner_Priority_Sequence && errors.Banner_Priority_Sequence}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Banner Priority Sequence"
                        name="Banner_Priority_Sequence"
                        value={values.Banner_Priority_Sequence}
                        variant="outlined"
                        type="number"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.Banner_Status && errors.Banner_Status)}
                        label="Status"
                        name="Banner_Status"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Banner_Status}
                        variant="outlined"
                        helperText={<ErrorMessage name="Banner_Status" />}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Banner_For && errors.Banner_For)}
                        fullWidth
                        helperText={touched.Banner_For && errors.Banner_For}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Banner For"
                        name="Banner_For"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Banner_For}
                        variant="outlined"
                      >
                        {bannerforOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Brand Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                  <span style={{ color: "red" }}>
                    {!imgUrl && touched.Banner_Image && errors.Banner_Image}
                  </span>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>
            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button disabled={isSubmited || uploadPic}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained" >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

EditBanner.propTypes = {
  className: PropTypes.string
};

export default EditBanner;