import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { AddPartnerManagementAction } from 'src/store/actions/servicespartnermanagementAction';
import { paginationsubcategoryServices, paginationPartnermanagementServices } from 'src/store/actions/node-actions/nodepagination';
import {
  Autocomplete,
} from "@mui/material";

const StatusOption = [
  // { value: '', label: '-- Please Select --' },
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'IN-ACTIVE', label: 'IN-ACTIVE' }
];

const useStyles = makeStyles(() => ({
  images: { width: '100%', height: 100, borderRadius: 5, marginBottom: 15 },
  Partner_Details_Id: { width: '100%', height: '20rem', padding: '1rem', overflow: 'auto', border: '1px solid grey' }
}));

const AddServicespartnermanagement = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const [subcategories, setSubcategorires] = useState([]);
  const [partnerservice, setpartnerservice] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [hasMore1, setHasMore1] = useState(true);
  // const [pageNo, setPageNo] = useState(1); 
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);

  const isSubmitted = useSelector(state => state.subcategory.subcategoryCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  useEffect(() => {
    const fetchSubcategoryServices = async () => {
      if (true) {
        try {

          setLoading1(true);
          await dispatch(
            paginationsubcategoryServices(
              {},
              { PageNo: currentPage1, PageSize: 20 },
              (status, data, pagination1) => {

                if (status) {
                  if (Array.isArray(data)) {
                    setSubcategorires((prev) => [...prev, ...data]);
                    setHasMore1(currentPage1 < pagination1?.TotalPages);
                  } else {

                  }
                }
              }
            )
          );
        } catch (error) {

        } finally {
          setLoading1(false);
        }
      }
    };

    fetchSubcategoryServices();
  }, [currentPage1, dispatch]);

  const handleScroll1 = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore1 && !loading1) {
      setCurrentPage1((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const fetchPartnermangementServices = async () => {
      if (true) {
        try {
          setLoading(true);
          await dispatch(
            paginationPartnermanagementServices(
              {},
              { PageNo: currentPage, PageSize: 20 },
              (status, data, pagination) => {

                if (status) {
                  if (Array.isArray(data)) {
                    setpartnerservice((prev) => [...prev, ...data]);
                    setHasMore(currentPage1 < pagination?.TotalPages);
                  } else {

                  }
                }
              }
            )
          );
        } catch (error) {

        } finally {
          setLoading(false);
        }
      }
    };
    fetchPartnermangementServices()
  }, [currentPage, dispatch]);

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };


  return (
    <Formik
      initialValues={{
        Ss_Id: '',
        Created_By: loginEmail,
        Partner_Details_Id: '',
        Status: '',
      }}
      validationSchema={Yup.object().shape({
        Ss_Id: Yup.string().required('Sub Service Name is required.'),
        Status: Yup.string().required('Status is required.'),
        Partner_Details_Id: Yup.string().required('Partner Name is required.'),
      })}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        if (submitTest) {
          dispatch(AddPartnerManagementAction(values, navigate));
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                {/* Partner_Details_Id */}
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    options={partnerservice || []}
                    getOptionLabel={(option) => option.Name || ''}
                    isOptionEqualToValue={(option, value) => option.Partner_Details_Id === value?.Partner_Details_Id}
                    onChange={(event, newValue) => {
                      setFieldValue('Partner_Details_Id', newValue?.Partner_Details_Id || '');
                    }}
                    value={partnerservice.find((option) => option.Partner_Details_Id === values.Partner_Details_Id) || null}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Partner Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onBlur={handleBlur}
                        error={Boolean(errors.Partner_Details_Id && touched.Partner_Details_Id)}
                        helperText={touched.Partner_Details_Id && errors.Partner_Details_Id}
                      />
                    )}
                    ListboxProps={{
                      style: {
                        maxHeight: 250,
                        overflow: 'auto',
                      },
                      onScroll: handleScroll,
                    }}
                  />

                </Grid>

                {/* Subservice Name */}
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    options={subcategories || []}
                    getOptionLabel={(option) => option.Ss_Name || ""}
                    isOptionEqualToValue={(option, value) => option.Ss_Id === value?.Ss_Id}
                    onChange={(event, newValue) => {
                      setFieldValue("Ss_Id", newValue?.Ss_Id || "");
                    }}
                    onBlur={(e) => handleBlur(e)}
                    value={subcategories.find((option) => option.Ss_Id === values.Ss_Id) || null}
                    loading={loading1}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.Ss_Id && errors.Ss_Id)}
                        helperText={touched.Ss_Id && errors.Ss_Id}
                        fullWidth
                        label="Sub Service Name"
                        variant="outlined"
                        margin="normal"
                        sx={{ width: "100%" }}
                      />
                    )}
                    ListboxProps={{
                      style: { maxHeight: 250, overflow: "auto" },
                      onScroll: handleScroll1,
                    }}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.Ss_Id} value={option.Ss_Id}>
                        {option.Ss_Name}
                      </MenuItem>
                    )}
                  />

                </Grid>

                {/* Status */}
                <Grid item md={12} xs={12}>

                  <Autocomplete
                    options={StatusOption || []}
                    getOptionLabel={(option) => option.label || ''}
                    onBlur={handleBlur}
                    onChange={(event, newValue) => {
                      setFieldValue('Status', newValue?.value || '');
                    }}
                    value={StatusOption.find((option) => option.value === values.Status) || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Status"
                        variant="outlined"
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        error={Boolean(touched.Status && errors.Status)}
                        margin="normal"
                      />
                    )}
                  />

                </Grid>
              </Grid>
            </CardContent>

            {/* Loading and submit */}
            <Box display="flex" p={2}>
              {loading1 && <CircularProgress />}
              {isSubmitted ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={isSubmitted}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              )}
            </Box>
          </Card>

          <Box display="flex" p={2}>
            {loading1 && <CircularProgress />}
          </Box>
        </form>
      )}
    </Formik>
  );
};

AddServicespartnermanagement.propTypes = {
  className: PropTypes.string
};

export default AddServicespartnermanagement;