import {
    PERMISSION_REQUEST,
    PERMISSION_SUCCESS,
    PERMISSION_FAIL,

    PERMISSION_CREATE_REQUEST,
    PERMISSION_CREATE_SUCCESS,
    PERMISSION_CREATE_FAIL,

    PERMISSION_UPDATE_REQUEST,
    PERMISSION_UPDATE_SUCCESS,
    PERMISSION_UPDATE_FAIL,
} from '../actions/permissionsAction'

const initialState = {
    permissions: { all: [], error: '', isLoading: false },
    permissionCreate: { permission: {}, error: '', isLoading: false },
    permissionUpdate: { permission: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PERMISSION_REQUEST:
            return { ...state, permissions: { all: state.permissions.all, error: '', isLoading: true } };
        case PERMISSION_SUCCESS:
            return { ...state, permissions: { all: action.payload, error: '', isLoading: false } };
        case PERMISSION_FAIL:
            return { ...state, permissions: { all: [], error: action.payload, isLoading: false } };

        case PERMISSION_CREATE_REQUEST:
            return { ...state, permissionCreate: { permission: {}, error: '', isLoading: true } };
        case PERMISSION_CREATE_SUCCESS:
            return { ...state, permissionCreate: { permission: state.permissionCreate.permission, error: '', isLoading: false } };
        case PERMISSION_CREATE_FAIL:
            return { ...state, permissionCreate: { permission: {}, error: action.payload, isLoading: false } };

        case PERMISSION_UPDATE_REQUEST:
            return { ...state, permissionUpdate: { permission: {}, error: '', isLoading: true } };
        case PERMISSION_UPDATE_SUCCESS:
            return { ...state, permissionUpdate: { permission: state.permissionUpdate.permission, error: '', isLoading: false } };
        case PERMISSION_UPDATE_FAIL:
            return { ...state, permissionUpdate: { permission: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}