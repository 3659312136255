import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import SimpleDialog from './../../../components/dialogs/dialog'
import DeleteIcon from '@material-ui/icons/Delete';
import isEmpty from '../../../store/validations/is-empty';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Product_Color_Variant_Id', numeric: false, disablePadding: false, label: 'Product Color Variant ID' },
  { id: 'Product_Master_Id', numeric: false, disablePadding: true, label: 'Product Master ID' },
  { id: 'Color', numeric: false, disablePadding: false, label: 'Color' },
  { id: 'Created_Date', numeric: false, disablePadding: false, label: 'Created Date' },
];

const Results = ({
  className,
  allProductColors,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  deleteProductMasterHandler,
  allProductColorsNoFilter,
  state,
  RolePermissions,
  roleUserType,
  pagination,
  setPagination,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const handleClose = (value) => {
    setOpen(false);
  };
  /*===============================
             handler     
  =================================*/

  const handleSelectAll = event => {
    let newSelectedProductIds;
    if (event.target.checked) {
      newSelectedProductIds = allProductColors.map(productcolorvariant => productcolorvariant.Product_Color_Variant_Id);
    }
    else {
      newSelectedProductIds = [];
    }
    setSelectedProductIds(newSelectedProductIds);
  };
  const handleSelectOne = (event, Id) => {
    const selectedIndex = selectedProductIds.indexOf(Id);
    let newselectedProductIds = [];
    if (selectedIndex === -1) {
      newselectedProductIds = newselectedProductIds.concat(selectedProductIds, Id);
    } else if (selectedIndex === 0) {
      newselectedProductIds = newselectedProductIds.concat(
        selectedProductIds.slice(1)
      );
    } else if (selectedIndex === selectedProductIds.length - 1) {
      newselectedProductIds = newselectedProductIds.concat(
        selectedProductIds.slice(0, -1)
      );
    }
    else if (selectedIndex > 0) {
      newselectedProductIds = newselectedProductIds.concat(
        selectedProductIds.slice(0, selectedIndex),
        selectedProductIds.slice(selectedIndex + 1)
      );
    }
    setSelectedProductIds(newselectedProductIds);
  };

  const deleteSelectedProductMasterHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const editProductMasterHandler = productmasterData => e => {
    navigate('/app/edit-product-color-variant', { state: { state: state, data: productmasterData } });
  };
  /*=================================
              handler end
  =================================*/
  return (
    <Card className={clsx(classes.root, className)}
      {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedProductIds.filter(record => {
                      for (let i = 0; i < allProductColorsNoFilter.length; i++) {
                        if (record == allProductColorsNoFilter[i].Product_Color_Variant_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allProductColors.length}
                    color="primary"
                    indeterminate={
                      selectedProductIds.filter(record => {
                        for (let i = 0; i < allProductColorsNoFilter.length; i++) {
                          if (record == allProductColorsNoFilter[i].Product_Color_Variant_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedProductIds.filter(record => {
                        for (let i = 0; i < allProductColorsNoFilter.length; i++) {
                          if (record == allProductColorsNoFilter[i].Product_Color_Variant_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allProductColors.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Product_Colors_Write?.Value === "Product_Colors_Write" && RolePermissions?.Product_Colors_Write?.Is_Write === 1) ?
                  <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions &nbsp; &nbsp;
                    {selectedProductIds.filter(record => {
                      for (let i = 0; i < allProductColorsNoFilter.length; i++) {
                        if (record == allProductColorsNoFilter[i].Product_Color_Variant_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length > 0 &&
                      <Button disabled={selectedProductIds.filter(record => {
                        for (let i = 0; i < allProductColorsNoFilter.length; i++) {
                          if (record == allProductColorsNoFilter[i].Product_Color_Variant_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length <= 0}
                        onClick={deleteSelectedProductMasterHandler()}>
                        <DeleteSweepIcon
                          style={{ color: '#ff7a7a', fontSize: 25 }}
                        />
                      </Button>}
                  </TableCell>
                  : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allProductColors) && (allProductColors).map(productcolorvariant => (
                <TableRow
                  hover
                  key={productcolorvariant.Product_Color_Variant_Id}
                  selected={
                    selectedProductIds.indexOf(productcolorvariant.Product_Color_Variant_Id) !== -1
                  }
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedProductIds.indexOf(productcolorvariant.Product_Color_Variant_Id) !== -1
                      }
                      onChange={event =>
                        handleSelectOne(event, productcolorvariant.Product_Color_Variant_Id)
                      }
                      value="true"
                    />
                  </TableCell>
                  <TableCell >&nbsp; &nbsp;&nbsp; {productcolorvariant.Product_Color_Variant_Id}</TableCell>
                  <TableCell >&nbsp; {productcolorvariant.Product_Master_Id}</TableCell>
                  <TableCell >{productcolorvariant.Color}</TableCell>
                  <TableCell >{productcolorvariant.Created_Date} </TableCell>
                  {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                    (RolePermissions?.Product_Colors_Write?.Value === "Product_Colors_Write" && RolePermissions?.Product_Colors_Write?.Is_Write === 1) ?
                    <TableCell style={{ textAlign: "center", }}>
                      <Button
                        onClick={editProductMasterHandler(productcolorvariant)}
                      >
                        <CreateIcon style={{ color: '#9EA0A5' }} />
                      </Button>
                      <Button>
                        <DeleteIcon
                          onClick={deleteProductMasterHandler(productcolorvariant)}
                          style={{ color: '#9EA0A5', }}
                        />
                      </Button>
                    </TableCell>
                    : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allProductColors) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allProductColors: PropTypes.array.isRequired
};

export default Results;