import {
  STORETYPE_LINKING_REQUEST,
  STORETYPE_LINKING_SUCCESS,
  STORETYPE_LINKING_FAIL,
  STORETYPE_LINKING_GET_REQUEST,
  STORETYPE_LINKING_GET_SUCCESS,
  STORETYPE_LINKING_GET_FAIL,
  STORETYPE_LINKING_CREATE_REQUEST,
  STORETYPE_LINKING_CREATE_SUCCESS,
  STORETYPE_LINKING_CREATE_FAIL,
  STORETYPE_LINKING_UPDATE_REQUEST,
  STORETYPE_LINKING_UPDATE_SUCCESS,
  STORETYPE_LINKING_UPDATE_FAIL,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,

} from '../actions/storetypelinkingActions'

const initialState = {
  storetypeslink: { all: [], error: '', isLoading: false },
  storetypelink: { storetype: {}, error: '', isLoading: false },
  storetypelinkCreate: { storetype: {}, error: '', isLoading: false },
  storetypelinkUpdate: { storetype: {}, error: '', isLoading: false },
  Categories: { categories: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STORETYPE_LINKING_REQUEST:
      return { ...state, storetypeslink: { all: state.storetypeslink.all, error: '', isLoading: true } };
    case STORETYPE_LINKING_SUCCESS:
      return { ...state, storetypeslink: { all: action.payload, error: '', isLoading: false } };
    case STORETYPE_LINKING_FAIL:
      return { ...state, storetypeslink: { all: [], error: action.payload, isLoading: false } };
    case STORETYPE_LINKING_GET_REQUEST:
      return { ...state, storetypelink: { storetypelink: {}, error: '', isLoading: true } };
    case STORETYPE_LINKING_GET_SUCCESS:
      return { ...state, storetypelink: { storetypelink: action.payload, error: '', isLoading: false } };
    case STORETYPE_LINKING_GET_FAIL:
      return { ...state, storetypelink: { storetypelink: {}, error: action.payload, isLoading: false } };
    case STORETYPE_LINKING_CREATE_REQUEST:
      return { ...state, storetypelinkCreate: { storetypelink: {}, error: '', isLoading: true } };
    case STORETYPE_LINKING_CREATE_SUCCESS:
      return { ...state, storetypelinkCreate: { storetypelink: state.storetypelinkCreate.storetypelink, error: '', isLoading: false } };
    case STORETYPE_LINKING_CREATE_FAIL:
      return { ...state, storetypelinkCreate: { storetypelink: {}, error: action.payload, isLoading: false } };
    case STORETYPE_LINKING_UPDATE_REQUEST:
      return { ...state, storetypelinkUpdate: { storetypelink: {}, error: '', isLoading: true } };
    case STORETYPE_LINKING_UPDATE_SUCCESS:
      return { ...state, storetypelinkUpdate: { storetypelink: state.storetypelinkUpdate.storetypelink, error: '', isLoading: false } };
    case STORETYPE_LINKING_UPDATE_FAIL:
      return { ...state, storetypelinkUpdate: { storetypelink: {}, error: action.payload, isLoading: false } };

    case GET_CATEGORIES_REQUEST:
      return { ...state, Categories: { categories: state.Categories.categories, error: '', isLoading: true }, };
    case GET_CATEGORIES_SUCCESS:
      return { ...state, Categories: { categories: action.payload, error: '', isLoading: false } };
    case GET_CATEGORIES_FAIL:
      return { ...state, Categories: { categories: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}