import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  makeStyles,
  Link
} from '@material-ui/core';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  images: {
    width: 70,
    height: 70,
    cursor: 'pointer'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
    width: '200px'
  },
}));

const headCells = [
  { id: 'Subscription_User_Auto_Id', numeric: false, disablePadding: false, label: 'Subscription User ID' },
  { id: 'User_Email_Id', numeric: false, disablePadding: false, label: 'User Email ID' },
  { id: 'Schedule_Type', numeric: false, disablePadding: false, label: 'Subscription Type' },
  { id: 'Subscription_Id', numeric: true, disablePadding: false, label: 'Subscription ID' },
  { id: 'Subscription_Status', numeric: false, disablePadding: false, label: 'Subscription Status' },
];

const Results = ({
  className,
  allSubscriptionusers,
  page,
  limit,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  setPage,
  allSubscriptionusersNoFilter,
  createSortHandler,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedSubscriptionUserIds, setSelectedSubscriptionUserIds] = useState([]);

  const visibleUserSubscriptionHandler = subscriptionuserData => e => {
    navigate("/app/view-subscriptionuser", { state: subscriptionuserData });
  };
  const editUserSubscriptionHandler = subscriptionuserData => e => {
    navigate("/app/edit-subscriptionuser", { state: subscriptionuserData });
  };

  const userProductSubscriptionHandler = subscriptionuserData => e => {
    navigate("/app/subscription-userproduct-management", { state: subscriptionuserData });
  };

  // pagination
  function checkingPagination(array) {
    if (array.slice(page * limit, page * limit + limit).length <= 0) {
      setPage(page - 1);
    }
    else {
      return array.slice(page * limit, page * limit + limit);
    }
    return array;
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.id !== "Profile_Pic" ? (
                      <TableSortLabel style={{ fontSize: "13px", fontWeight: "bold" }}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>) : (<span style={{ fontSize: "13px", fontWeight: "bold" }} > {headCell.label}</span>)
                    }
                  </TableCell>
                ))}
                <TableCell>Subscription Products</TableCell>
                <TableCell
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '80px'
                  }}
                  className={classes.headerColumn}>Actions &nbsp;&nbsp;&nbsp;
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allSubscriptionusers) &&
                (limit > 0 ? checkingPagination(allSubscriptionusers) : allSubscriptionusers).map(subscriptionuser => (
                  <TableRow
                    hover
                    key={subscriptionuser.Subscription_User_Auto_Id}
                  >
                    <TableCell >{subscriptionuser.Subscription_User_Auto_Id}</TableCell>
                    <TableCell style={{ color: '#1665D8' }}>
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href={subscriptionuser.User_Email_Id}
                      >
                        {subscriptionuser.User_Email_Id?.substring(0, 20)}...

                      </Link>
                    </TableCell>
                    <TableCell>{subscriptionuser.Schedule_Type}</TableCell>
                    <TableCell>{subscriptionuser.Subscription_Id}</TableCell>
                    <TableCell>{subscriptionuser.Subscription_Status}</TableCell>
                    <TableCell>
                      <Tooltip title="Subscription Products">
                        <Button
                          onClick={userProductSubscriptionHandler(subscriptionuser)}
                          color="primary" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#9EA0A5', fontSize: 20, }} >
                          <ViewCarouselIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="View Service History">
                        <Button
                          onClick={visibleUserSubscriptionHandler(subscriptionuser)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </Tooltip>
                      <Button
                        onClick={editUserSubscriptionHandler(subscriptionuser)}
                      >
                        <CreateIcon style={{ color: '#9EA0A5' }} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allSubscriptionusers) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={allSubscriptionusers.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 20]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allSubscriptionusers: PropTypes.array.isRequired
};

export default Results;