import { USER_LOGOUT } from '../actions/authActions';
import {
    ROLEPERM_REQUEST,
    ROLEPERM_SUCCESS,
    ROLEPERM_FAIL,
} from '../actions/dynamicrolepermissionAction'

const initialState = {
    Roles: JSON.parse(localStorage.getItem("results")) || {},
    error: '',
    isLoading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ROLEPERM_REQUEST:
            return { ...state, isLoading: true }

        case ROLEPERM_SUCCESS:
            if (action) {
               localStorage.setItem("results", JSON.stringify(action.data));
                return { isLoading: false, Roles: action.data, error: '' }
            } else {
                return { ...state }
            }

        case ROLEPERM_FAIL:
            if (action.login) {
                return { isLoading: false, Roles: {}, error: action.data }
            } else {
                return { ...state }
            }

        case USER_LOGOUT:
            localStorage.removeItem("results");
            return { ...state, Roles: {} };

        default:
            return state;
    }
}