import { Card, CardContent, Grid, Divider } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const MPProductComboView = ({ className, state, data, ...rest }) => {

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            {/* Base_Product_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Base Product ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Base_Product_Id}
                                </Typography>
                            </Grid>

                            {/* Base_Product_Name */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Base Product Name
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Base_Product_Name}
                                </Typography>
                            </Grid>

                            {/* Is_Active */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Active
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Is_Active}
                                </Typography>
                            </Grid>

                            <Divider />
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: 'black', fontWeight: 'bold' }}
                                >
                                    Sub Products:
                                </Typography>
                            </Grid>
                            <Grid item xs={5}></Grid>

                            {data?.Sub_Products_Details?.map((subproduct) => (
                                <>
                                    {/* Combo_Product_Id */}
                                    <Grid item xs={5}>
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            gutterBottom
                                            style={{ color: '#66788A' }}
                                        >
                                            Combo Product ID
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            gutterBottom
                                            style={{ paddingLeft: '3rem' }}
                                        >
                                            {subproduct?.Combo_Product_Id}
                                        </Typography>
                                    </Grid>

                                    {/* Product_Id */}
                                    <Grid item xs={5}>
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            gutterBottom
                                            style={{ color: '#66788A' }}
                                        >
                                            Product ID
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            gutterBottom
                                            style={{ paddingLeft: '3rem' }}
                                        >
                                            {subproduct?.Product_Id}
                                        </Typography>
                                    </Grid>

                                    {/* Product_Name */}
                                    <Grid item xs={5}>
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            gutterBottom
                                            style={{ color: '#66788A' }}
                                        >
                                            Product Name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            gutterBottom
                                            style={{ paddingLeft: '3rem' }}
                                        >
                                            {subproduct?.Product_Name}
                                        </Typography>
                                    </Grid>

                                    {/* Created_Date */}
                                    <Grid item xs={5}>
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            gutterBottom
                                            style={{ color: '#66788A' }}
                                        >
                                            Created Date
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            gutterBottom
                                            style={{ paddingLeft: '3rem' }}
                                        >
                                            {subproduct?.Created_Date}
                                        </Typography>
                                    </Grid>

                                    {/* Created_By */}
                                    <Grid item xs={5}>
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            gutterBottom
                                            style={{ color: '#66788A' }}
                                        >
                                            Created By
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            gutterBottom
                                            style={{ paddingLeft: '3rem' }}
                                        >
                                            <span title={subproduct?.Created_By}> {subproduct?.Created_By?.length > 25 ?
                                                <span>{subproduct?.Created_By?.substring(0, 25)}...</span> : subproduct?.Created_By}</span>
                                        </Typography>
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    );
};

export default MPProductComboView;