import { toast } from 'react-toastify';
import { paginationNewsLetter } from './node-actions/nodepagination';
import { nodeCudApi} from './commonAxios';
export const NEWSLETTER_CREATE_REQUEST = "NEWSLETTER_CREATE_REQUEST";
export const NEWSLETTER_CREATE_SUCCESS = "NEWSLETTER_CREATE_SUCCESS";
export const NEWSLETTER_CREATE_FAIL = "NEWSLETTER_CREATE_FAIL";
export const NEWSLETTER_UPDATE_REQUEST = "NEWSLETTER_UPDATE_REQUEST";
export const NEWSLETTER_UPDATE_SUCCESS = "NEWSLETTER_UPDATE_SUCCESS";
export const NEWSLETTER_UPDATE_FAIL = "NEWSLETTER_UPDATE_FAIL";

/*=============================================================
                  Add Newsletter Action
===============================================================*/

export const CreateNewsLetterAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: NEWSLETTER_CREATE_REQUEST
  });
  nodeCudApi.post(`/News/News_Letter_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: NEWSLETTER_CREATE_SUCCESS
        });
        toast('Newsletter added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/newsletter');
      } else {
        dispatch({
          type: NEWSLETTER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: NEWSLETTER_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                edit Newsletter Action
===============================================================*/
export const UpdateNewsletterAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: NEWSLETTER_UPDATE_REQUEST
  });
  nodeCudApi.post(`/News/News_Letter_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: NEWSLETTER_UPDATE_SUCCESS
        });
        toast('Newsletter updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/newsletter');
      } else {
        dispatch({
          type: NEWSLETTER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: NEWSLETTER_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

/*=============================================================
                 Delete newsletter Action
===============================================================*/
export const deleteNewsletterAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/News/News_Letter_Delete`, formData);
    if (data) {
      toast('Newsletter deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationNewsLetter(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};