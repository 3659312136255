import { toast } from 'react-toastify';
import {  nodeServiceApi } from './commonAxios';

export const PARTNER_SERVICEBOOKING_UPDATE_REQUEST = "PARTNER_SERVICEBOOKING_UPDATE_REQUEST";
export const PARTNER_SERVICEBOOKING_UPDATE_SUCCESS = "PARTNER_SERVICEBOOKING_UPDATE_SUCCESS";
export const PARTNER_SERVICEBOOKING_UPDATE_FAIL = "PARTNER_SERVICEBOOKING_UPDATE_FAIL ";



/*=============================================================
                Update PARTNER_SERVICEBOOKING Action
===============================================================*/
export const EditPartnerServicebookingAction = (formData, navigate,) => async dispatch => {
  dispatch({
    type: PARTNER_SERVICEBOOKING_UPDATE_REQUEST
  });
  nodeServiceApi.put(`/admin/mps_service_bookings/Update/${formData.Sb_Id}`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNER_SERVICEBOOKING_UPDATE_SUCCESS
        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      } else {  
        dispatch({
          type: PARTNER_SERVICEBOOKING_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
          
        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_SERVICEBOOKING_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

