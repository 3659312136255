import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  Box,
  colors
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { usersCount } from '../../../../store/actions/reports/dashboardActions';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  }
  , differenceIconFail: {
    color: colors.red[900]
  },
  differenceValueFail: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  },
  differenceIconSucc: {
    color: colors.green[900]
  },
  differenceValueSucc: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const TotalCustomers = ({ className, RolePermissions, setSeconds, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector(state => state.dash.Users.data);
  let Total_Users_Hide = RolePermissions?.Total_Users_Hide?.Is_Hide

  // componentDidMount
  useEffect(() => {
    dispatch(usersCount());
    setSeconds(5);
    if (RolePermissions?.Total_Users_Hide?.Value === "Total_Users_Hide" && RolePermissions?.Total_Users_Hide?.Is_Hide === 1) {
      Total_Users_Hide = RolePermissions?.Total_Users_Hide?.Is_Hide
    }
  }, []);

  return (
    <>
      {Total_Users_Hide !== 1 &&
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  TOTAL USERS
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {data.Total}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <PeopleIcon />
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={2} display="flex" alignItems="center">
              {data.Month - data.Avg >= 0 && <>
                <ArrowUpwardIcon className={classes.differenceIconSucc} />
                <Typography className={classes.differenceValueSucc} variant="body2">
                  {data.Month}
                </Typography>
              </>
              }
              {data.Month - data.Avg < 0 &&
                <>
                  <ArrowDownwardIcon className={classes.differenceIconFail} />
                  <Typography className={classes.differenceValueFail} variant="body2">
                    {data.Month}
                  </Typography>
                </>
              }
              <Typography color="textSecondary" variant="caption">
                Since last 30 days
              </Typography>
            </Box>
          </CardContent>
        </Card>
      }
    </>
  );
};

TotalCustomers.propTypes = {
  className: PropTypes.string
};

export default TotalCustomers;