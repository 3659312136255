import React from 'react';
import PropTypes, { number } from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    CreateNewsLetterAction
} from './../../store/actions/newsletteraction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
    {
        value: '--Please Select--',
        label: '--Please Select--'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const AddNewsletter = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const errorMessage = useSelector(state => state.newsletters.newsletterCreate.error);
    const isSubmitted = useSelector(state => state.newsletters.newsletterCreate.isLoading);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    return (
        <Formik
            initialValues={{
                Created_By: loginEmail,
                News_Letter_Email: '',
                News_Letter_Ip: '',
                Status: '',
                Subscribe: false,
            }}
            validationSchema={
                Yup.object().shape({
                    News_Letter_Email: Yup.string().required(' News Letter Email is required.'),
                    News_Letter_Ip: Yup.string().required(' News Letter IP is required.'),
                    Status: Yup.string().required('Status  is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    if (values.Subscribe) {
                        formValues.Subscribe = 1;
                    } else {
                        formValues.Subscribe = 0;
                    }
                    dispatch(CreateNewsLetterAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.News_Letter_Email && errors.News_Letter_Email)}
                                                fullWidth
                                                helperText={touched.News_Letter_Email && errors.News_Letter_Email}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="News Letter Email"
                                                name="News_Letter_Email"
                                                value={values.News_Letter_Email}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        {/* Newsletter_Ip */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.News_Letter_Ip && errors.News_Letter_Ip)}
                                                fullWidth
                                                helperText={touched.News_Letter_Ip && errors.News_Letter_Ip}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type={number}
                                                label="News Letter IP"
                                                name="News_Letter_Ip"
                                                value={values.News_Letter_Ip}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        {/* Status */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label=" Status"
                                                name="Status"
                                                select
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.Subscribe}
                                                        onChange={handleChange}
                                                        name="Subscribe"
                                                        color="primary"
                                                    />
                                                }
                                                label="Subscribe"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>

                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>

    );

};

AddNewsletter.propTypes = {
    className: PropTypes.string
};

export default AddNewsletter;