import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    makeStyles,
    TextareaAutosize,
    TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress, Rating } from '@mui/material';
import { getAllB2BBiddingProducts, getAllB2BBidUserAddress, getAllB2BProdBiddingQuotes, getAllBiddingPartnerDetails, getAllbidprodmeasuretypes, updateB2BUserProductsBidding } from 'src/store/actions/b2buserproductsbiddingAction';

const bidstatusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'INITIATED',
        label: 'INITIATED'
    },
    {
        value: 'PROCESSING',
        label: 'PROCESSING'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
    {
        value: 'COMPLETED',
        label: 'COMPLETED'
    }
];

const useStyles = makeStyles(() => ({
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const EditB2BUserProductsBidding = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const location = useLocation();
    let { state } = location;

    useEffect(() => {
        const formData = {
            search: "",
            search_by_filter: ""
        };
        const formData1 = {
            search: "",
            search_by_filter: "",
            Records_Filter: "FILTER",
            B2B_User_Products_Bidding_Id: state?.B2B_User_Products_Bidding_Id
        };
        const formData2 = {
            search: "",
            search_by_filter: "",
            Records_Filter: 'FILTER',
            Bidding_Product_Id: state.Bidding_Product_Id,
        };
        const formData3 = {
            Records_Filter: "FILTER",
            Partner_Details_Id: state.Buyer_Partner_Detail_Id,
            search_by_filter: "",
            search: "",
        };
        dispatch(getAllBiddingPartnerDetails(formData));
        dispatch(getAllB2BBiddingProducts(formData));
        dispatch(getAllB2BProdBiddingQuotes(formData1));
        dispatch(getAllbidprodmeasuretypes(formData2, callBackMeasureTypesData))
        dispatch(getAllB2BBidUserAddress(formData3, callBackUserAddressData))
    }, []);

    const [measuretypes, setmeasuretypes] = React.useState([]);
    const callBackMeasureTypesData = (data) => {
        setmeasuretypes(data[0].Measurement_Type.split(",").map(Values => ({ Measurement_Type: Values })))
    }

    const setmeasureType = (value) => {
        if (value !== "") {
            let data = {
                search: "",
                search_by_filter: "",
                Records_Filter: 'FILTER',
                Bidding_Product_Id: value,
            }
            dispatch(getAllbidprodmeasuretypes(data, callBackMeasureTypesData))
        }
    }

    const [useraddressdata, setUserAddressData] = React.useState([]);
    let USERADDRESS = []
    if (useraddressdata) {
        for (let i = 0; i < useraddressdata?.length; i++) {
            USERADDRESS.push(
                {
                    label: useraddressdata[i].User_Address_Id,
                    value: useraddressdata[i].User_Address_Id,
                })
        }
    }

    const callBackUserAddressData = (data) => {
        setUserAddressData(data)
    }

    const setUserAddressType = (value) => {
        if (value !== "") {
            let data = {
                Records_Filter: "FILTER",
                Partner_Details_Id: value,
                search_by_filter: "",
                search: "",
            }
            dispatch(getAllB2BBidUserAddress(data, callBackUserAddressData))
        }
    }

    const isSubmited = useSelector(state => state.b2buserproductsbid.b2buserprodbidUpdate.isLoading);
    const errorMessage = useSelector(state => state.b2buserproductsbid.b2buserprodbidUpdate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const b2bbidpartners = useSelector(state => state.b2buserproductsbid.b2bbidpartnerdetails.all)
    const b2bbidproducts = useSelector(state => state.b2buserproductsbid.b2bbidproducts.all)
    const b2bbidquotes = useSelector(state => state.b2buserproductsbid.b2bbidquotes.all)

    return (
        <Formik
            initialValues={{
                B2B_User_Products_Bidding_Id: state.B2B_User_Products_Bidding_Id ? state.B2B_User_Products_Bidding_Id : "",
                Bidding_Product_Id: state.Bidding_Product_Id ? state.Bidding_Product_Id : "",
                Buyer_Partner_Detail_Id: state.Buyer_Partner_Detail_Id ? state.Buyer_Partner_Detail_Id : "",
                Partner_Address_Id: state.Partner_Address_Id ? state.Partner_Address_Id : "",
                User_Pincode: state.User_Pincode ? state.User_Pincode : "",
                Quantity: state.Quantity ? state.Quantity : "",
                Measurement_Type: state.Measurement_Type ? state.Measurement_Type : "",
                Bidding_Status: state.Bidding_Status ? state.Bidding_Status : "",
                Seller_Partner_Detail_Id: state.Seller_Partner_Detail_Id ? state.Seller_Partner_Detail_Id : "",
                B2B_Bidding_Product_Master_Id: state.B2B_Bidding_Product_Master_Id ? state.B2B_Bidding_Product_Master_Id : "",
                Finalize_Partner_Bidding_Quote_Id: state.Finalize_Partner_Bidding_Quote_Id ? state.Finalize_Partner_Bidding_Quote_Id : "",
                Advance_Online_Transaction_Id: state.Advance_Online_Transaction_Id ? state.Advance_Online_Transaction_Id : "",
                Online_Transaction_Id: state.Online_Transaction_Id ? state.Online_Transaction_Id : "",
                Partner_Payment_Transaction_Id: state.Partner_Payment_Transaction_Id ? state.Partner_Payment_Transaction_Id : "",
                Payment_Transaction_Id: state.Payment_Transaction_Id ? state.Payment_Transaction_Id : "",
                Bidding_Expiry_Date: state.Bidding_Expiry_Date ? state.Bidding_Expiry_Date : "",
                Payment_Mode: state.Payment_Mode ? state.Payment_Mode : "",
                Online_Amount: state.Online_Amount ? state.Online_Amount : "",
                Advance_Online_Amount: state.Advance_Online_Amount ? state.Advance_Online_Amount : "",
                Advance_Wallet_Amount: state.Advance_Wallet_Amount ? state.Advance_Wallet_Amount : "",
                Remaining_Amount_After_Advance_Amount: state.Remaining_Amount_After_Advance_Amount ? state.Remaining_Amount_After_Advance_Amount : "",
                Wallet_Amount: state.Wallet_Amount ? state.Wallet_Amount : "",
                Online_Transaction_Amount: state.Online_Transaction_Amount ? state.Online_Transaction_Amount : "",
                Partner_Advance_Amount: state.Partner_Advance_Amount ? state.Partner_Advance_Amount : "",
                Partner_Balance_Amount: state.Partner_Balance_Amount ? state.Partner_Balance_Amount : "",
                Partner_Total_Amount: state.Partner_Total_Amount ? state.Partner_Total_Amount : "",
                Total_Advance_Amount: state.Total_Advance_Amount ? state.Total_Advance_Amount : "",
                ESI_Commision_Amount_After_Tax: state.Esi_Commission_Amount_After_Tax ? state.Esi_Commission_Amount_After_Tax : "",
                ESI_Commision_Amount_Before_Tax: state.Esi_Commision_Amount_Before_Tax ? state.Esi_Commision_Amount_Before_Tax : "",
                ESI_Commision_Tax_Amount: state.Esi_Commission_Tax_Amount ? state.Esi_Commission_Tax_Amount : "",
                ESI_Commision_Tax_Percentage: state.Esi_Commission_Tax_Percentage ? state.Esi_Commission_Tax_Percentage : "",
                Is_Partner_Amount_Settled: state.Is_Partner_Amount_Settled > 0 ? true : false,
                Advance_Payment_Status_From_User: state.Advance_Payment_Status_From_User > 0 ? true : false,
                Final_Payment_Status_From_User: state.Final_Payment_Status_From_User > 0 ? true : false,
                Cancelled_Reason: state.Cancelled_Reason ? state.Cancelled_Reason : "",
                User_Rating_For_Partner: state.User_Rating_For_Partner ? state.User_Rating_For_Partner : "",
                User_Rating_For_Product: state.User_Rating_For_Product ? state.User_Rating_For_Product : "",
                User_Review_For_Partner: state.User_Review_For_Partner ? state.User_Review_For_Partner : "",
                User_Review_For_Product: state.User_Review_For_Product ? state.User_Review_For_Product : "",
                Description: state.Description ? state.Description : "",
                Partner_Advance_Wallet_History_Id: state.Partner_Advance_Wallet_History_Id ? state.Partner_Advance_Wallet_History_Id : "",
                Partner_Final_Wallet_History_Id: state.Partner_Final_Wallet_History_Id ? state.Partner_Final_Wallet_History_Id : "",
                Partner_Advance_Wallet_Amount: state.Partner_Advance_Wallet_Amount ? state.Partner_Advance_Wallet_Amount : "",
                Partner_Final_Wallet_Amount: state.Partner_Final_Wallet_Amount ? state.Partner_Final_Wallet_Amount : "",
                Advance_Payment_Mode: state.Advance_Payment_Mode ? state.Advance_Payment_Mode : "",
                Partner_Final_Online_Amount: state.Partner_Final_Online_Amount ? state.Partner_Final_Online_Amount : "",
                Partner_Advance_Online_Amount: state.Partner_Advance_Online_Amount ? state.Partner_Advance_Online_Amount : "",
                Advance_Bank_Fee: state.Advance_Bank_Fee ? state.Advance_Bank_Fee : "",
                Final_Bank_Fee: state.Final_Bank_Fee ? state.Final_Bank_Fee : "",
                Is_Partner_Advance_Amount_Settled: state.Is_Partner_Advance_Amount_Settled ? state.Is_Partner_Advance_Amount_Settled : "",
                Bidding_Completion_Date: state.Bidding_Completion_Date ? state.Bidding_Completion_Date : "",
                Modified_By: loginEmail,
            }}
            validationSchema={
                Yup.object().shape({
                    Buyer_Partner_Detail_Id: Yup.string().required('Buyer Partner is required.'),
                    Bidding_Product_Id: Yup.string().required('Bidding Product is required.'),
                    Measurement_Type: Yup.string().required('Measurement Type is required.'),
                    User_Pincode: Yup.string().required('Pincode is required.'),
                    // Bidding_Expiry_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require Bidding Expiry Date greater than or equal to today.").required("Bidding Expiry Date is required."),
                    Quantity: Yup.number().min(0, 'Quantity should not be less than 0.').required('Quantity is required.'),
                    Bidding_Status: Yup.string().required('Bidding Status is required.'),
                    Online_Amount: Yup.number().min(0, 'Online Amount should not be less than 0.'),
                    Advance_Online_Amount: Yup.number().min(0, 'Advance Online Amount should not be less than 0.'),
                    Advance_Wallet_Amount: Yup.number().min(0, 'Advance Wallet Amount should not be less than 0.'),
                    Remaining_Amount_After_Advance_Amount: Yup.number().min(0, 'Remaining Amount After Advance Amount should not be less than 0.'),
                    Wallet_Amount: Yup.number().min(0, 'Wallet Amount should not be less than 0.'),
                    Online_Transaction_Amount: Yup.number().min(0, 'Online Transaction Amount should not be less than 0.'),
                    Partner_Advance_Amount: Yup.number().min(0, 'Partner Advance Amount should not be less than 0.'),
                    Partner_Balance_Amount: Yup.number().min(0, 'Partner Balance Amount should not be less than 0.'),
                    Partner_Total_Amount: Yup.number().min(0, 'Partner Total Amount should not be less than 0.'),
                    Total_Advance_Amount: Yup.number().min(0, 'Total Advance Amount should not be less than 0.'),
                    ESI_Commision_Amount_After_Tax: Yup.number().min(0, 'ESI Commision Amount After Tax should not be less than 0.'),
                    ESI_Commision_Amount_Before_Tax: Yup.number().min(0, 'ESI Commision Amount Before Tax should not be less than 0.'),
                    ESI_Commision_Tax_Amount: Yup.number().min(0, 'ESI Commision Tax Amount should not be less than 0.'),
                    Partner_Advance_Wallet_Amount: Yup.number().min(0, 'Partner Advance Wallet Amount should not be less than 0.'),
                    Partner_Final_Wallet_Amount: Yup.number().min(0, 'Partner Final Wallet Amount should not be less than 0.'),
                    Partner_Final_Online_Amount: Yup.number().min(0, 'Partner Final Online Amount should not be less than 0.'),
                    Partner_Advance_Online_Amount: Yup.number().min(0, 'Partner Advance Online Amount should not be less than 0.'),
                    Advance_Bank_Fee: Yup.number().min(0, 'Advance Bank Fee should not be less than 0.'),
                    Final_Bank_Fee: Yup.number().min(0, 'Final Bank Fee should not be less than 0.'),
                    ESI_Commision_Tax_Percentage: Yup.number().min(0, 'ESI Commision Tax Percentage should not be less than 0.').max(100, 'ESI Commision Tax Percentage must be less than 100.'),
                })
            }
            onSubmit={(values) => {
                let submitTest = true;
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    if (values.Is_Partner_Amount_Settled) {
                        formValues.Is_Partner_Amount_Settled = 1;
                    } else {
                        formValues.Is_Partner_Amount_Settled = 0;
                    }
                    dispatch(updateB2BUserProductsBidding(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Buyer_Partner_Detail_Id && errors.Buyer_Partner_Detail_Id)}
                                                fullWidth
                                                helperText={touched.Buyer_Partner_Detail_Id && errors.Buyer_Partner_Detail_Id}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setUserAddressType(e.target.value)
                                                }}
                                                label="Buyer Partner"
                                                name="Buyer_Partner_Detail_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Buyer_Partner_Detail_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {b2bbidpartners?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                                                    <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                                                        {option.Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Address"
                                                name="Partner_Address_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Partner_Address_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {useraddressdata?.map(option => (
                                                    <option key={option.Partner_Address_Id} value={option.Partner_Address_Id}>
                                                        {option.Location}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.User_Pincode && errors.User_Pincode)}
                                                fullWidth
                                                helperText={touched.User_Pincode && errors.User_Pincode}
                                                label="PinCode"
                                                name="User_Pincode"
                                                type="number"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.User_Pincode}
                                                variant="outlined"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                                                }}
                                                min={0}
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Product_Id && errors.Bidding_Product_Id)}
                                                fullWidth
                                                helperText={touched.Bidding_Product_Id && errors.Bidding_Product_Id}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setmeasureType(e.target.value)
                                                }}
                                                label="Bidding Product"
                                                name="Bidding_Product_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Product_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {b2bbidproducts?.map(option => (
                                                    <option key={option.Bidding_Product_Id} value={option.Bidding_Product_Id}>
                                                        {option.Product_Name.substring(0, 35)}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Quantity && errors.Quantity)}
                                                fullWidth
                                                helperText={touched.Quantity && errors.Quantity}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Quantity"
                                                name="Quantity"
                                                value={values.Quantity}
                                                variant="outlined"
                                                type="number"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Measurement_Type && errors.Measurement_Type)}
                                                fullWidth
                                                helperText={touched.Measurement_Type && errors.Measurement_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Measurement Type"
                                                name="Measurement_Type"
                                                value={values.Measurement_Type}
                                                variant="outlined"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                            >
                                                <option key="" value="">-Please Select-</option>
                                                {measuretypes?.map((option, i) => (
                                                    <option key={i} value={option.Measurement_Type}>
                                                        {option.Measurement_Type}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Status && errors.Bidding_Status)}
                                                fullWidth
                                                helperText={touched.Bidding_Status && errors.Bidding_Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Status"
                                                name="Bidding_Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Status}
                                                variant="outlined"
                                            >
                                                {bidstatusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Seller Partner Detail ID"
                                                name="Seller_Partner_Detail_Id"
                                                value={values.Seller_Partner_Detail_Id}
                                                variant="outlined"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {b2bbidpartners?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                                                    <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                                                        {option.Name + " - " + option.Email_Id}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="B2B Bidding Product Master ID"
                                                name="B2B_Bidding_Product_Master_Id"
                                                value={values.B2B_Bidding_Product_Master_Id}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Finalize Partner Bidding Quote ID"
                                                name="Finalize_Partner_Bidding_Quote_Id"
                                                value={values.Finalize_Partner_Bidding_Quote_Id}
                                                variant="outlined"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {b2bbidquotes?.map(option => (
                                                    <option key={option.B2B_Partner_Bidding_Quote_Id} value={option.B2B_Partner_Bidding_Quote_Id}>
                                                        {option.B2B_Partner_Bidding_Quote_Id}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Online Transaction ID"
                                                name="Advance_Online_Transaction_Id"
                                                value={values.Advance_Online_Transaction_Id}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Online Transaction ID"
                                                name="Online_Transaction_Id"
                                                value={values.Online_Transaction_Id}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Payment Transaction ID"
                                                name="Partner_Payment_Transaction_Id"
                                                value={values.Partner_Payment_Transaction_Id}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Payment Transaction ID"
                                                name="Payment_Transaction_Id"
                                                value={values.Payment_Transaction_Id}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}
                                                fullWidth
                                                helperText={touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Expiry Date"
                                                name="Bidding_Expiry_Date"
                                                value={values.Bidding_Expiry_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="datetime-local"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Payment Mode"
                                                name="Payment_Mode"
                                                value={values.Payment_Mode}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Online_Amount && errors.Online_Amount)}
                                                fullWidth
                                                helperText={touched.Online_Amount && errors.Online_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Online Amount"
                                                name="Online_Amount"
                                                value={values.Online_Amount}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>


                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Advance_Online_Amount && errors.Advance_Online_Amount)}
                                                fullWidth
                                                helperText={touched.Advance_Online_Amount && errors.Advance_Online_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Online Amount"
                                                name="Wallet_Amount"
                                                value={values.Advance_Online_Amount}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Advance_Wallet_Amount && errors.Advance_Wallet_Amount)}
                                                fullWidth
                                                helperText={touched.Advance_Wallet_Amount && errors.Advance_Wallet_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Wallet Amount"
                                                name="Advance_Wallet_Amount"
                                                value={values.Advance_Wallet_Amount}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Remaining_Amount_After_Advance_Amount && errors.Remaining_Amount_After_Advance_Amount)}
                                                fullWidth
                                                helperText={touched.Remaining_Amount_After_Advance_Amount && errors.Remaining_Amount_After_Advance_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Remaining Amount After Advance Amount"
                                                name="Remaining_Amount_After_Advance_Amount"
                                                value={values.Remaining_Amount_After_Advance_Amount}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>


                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Wallet_Amount && errors.Wallet_Amount)}
                                                fullWidth
                                                helperText={touched.Wallet_Amount && errors.Wallet_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Wallet Amount"
                                                name="Wallet_Amount"
                                                value={values.Wallet_Amount}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Online_Transaction_Amount && errors.Online_Transaction_Amount)}
                                                fullWidth
                                                helperText={touched.Online_Transaction_Amount && errors.Online_Transaction_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Online Transaction Amount"
                                                name="Online_Transaction_Amount"
                                                value={values.Online_Transaction_Amount}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Advance_Amount && errors.Partner_Advance_Amount)}
                                                fullWidth
                                                helperText={touched.Partner_Advance_Amount && errors.Partner_Advance_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Advance Amount"
                                                name="Partner_Advance_Amount"
                                                value={values.Partner_Advance_Amount}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Balance_Amount && errors.Partner_Balance_Amount)}
                                                fullWidth
                                                helperText={touched.Partner_Balance_Amount && errors.Partner_Balance_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Balance Amount"
                                                name="Partner_Balance_Amount"
                                                value={values.Partner_Balance_Amount}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Total_Amount && errors.Partner_Total_Amount)}
                                                fullWidth
                                                helperText={touched.Partner_Total_Amount && errors.Partner_Total_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Total Amount"
                                                name="Partner_Total_Amount"
                                                value={values.Partner_Total_Amount}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Total_Advance_Amount && errors.Total_Advance_Amount)}
                                                fullWidth
                                                helperText={touched.Total_Advance_Amount && errors.Total_Advance_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Total Advance Amount"
                                                name="Total_Advance_Amount"
                                                value={values.Total_Advance_Amount}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.ESI_Commision_Amount_After_Tax && errors.ESI_Commision_Amount_After_Tax)}
                                                fullWidth
                                                helperText={touched.ESI_Commision_Amount_After_Tax && errors.ESI_Commision_Amount_After_Tax}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ESI Commision Amount After Tax"
                                                name="ESI_Commision_Amount_After_Tax"
                                                value={values.ESI_Commision_Amount_After_Tax}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.ESI_Commision_Amount_Before_Tax && errors.ESI_Commision_Amount_Before_Tax)}
                                                fullWidth
                                                helperText={touched.ESI_Commision_Amount_Before_Tax && errors.ESI_Commision_Amount_Before_Tax}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ESI Commision Amount Before Tax"
                                                name="ESI_Commision_Amount_Before_Tax"
                                                value={values.ESI_Commision_Amount_Before_Tax}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.ESI_Commision_Tax_Amount && errors.ESI_Commision_Tax_Amount)}
                                                fullWidth
                                                helperText={touched.ESI_Commision_Tax_Amount && errors.ESI_Commision_Tax_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ESI Commision Tax Amount"
                                                name="ESI_Commision_Tax_Amount"
                                                value={values.ESI_Commision_Tax_Amount}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.ESI_Commision_Tax_Percentage && errors.ESI_Commision_Tax_Percentage)}
                                                fullWidth
                                                helperText={touched.ESI_Commision_Tax_Percentage && errors.ESI_Commision_Tax_Percentage}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ESI Commision Tax Percentage"
                                                name="ESI_Commision_Tax_Percentage"
                                                value={values.ESI_Commision_Tax_Percentage}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Advance Wallet History ID"
                                                name="Partner_Advance_Wallet_History_Id"
                                                value={values.Partner_Advance_Wallet_History_Id}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Final Wallet History ID"
                                                name="Partner_Final_Wallet_History_Id"
                                                value={values.Partner_Final_Wallet_History_Id}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Payment Mode"
                                                name="Advance_Payment_Mode"
                                                value={values.Advance_Payment_Mode}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Advance_Wallet_Amount && errors.Partner_Advance_Wallet_Amount)}
                                                fullWidth
                                                helperText={touched.Partner_Advance_Wallet_Amount && errors.Partner_Advance_Wallet_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Advance Wallet Amount"
                                                name="Partner_Advance_Wallet_Amount"
                                                value={values.Partner_Advance_Wallet_Amount}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Advance_Online_Amount && errors.Partner_Advance_Online_Amount)}
                                                fullWidth
                                                helperText={touched.Partner_Advance_Online_Amount && errors.Partner_Advance_Online_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Advance Online Amount"
                                                name="Partner_Advance_Online_Amount"
                                                value={values.Partner_Advance_Online_Amount}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Final_Wallet_Amount && errors.Partner_Final_Wallet_Amount)}
                                                fullWidth
                                                helperText={touched.Partner_Final_Wallet_Amount && errors.Partner_Final_Wallet_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Final Wallet Amount"
                                                name="Partner_Final_Wallet_Amount"
                                                value={values.Partner_Final_Wallet_Amount}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Final_Online_Amount && errors.Partner_Final_Online_Amount)}
                                                fullWidth
                                                helperText={touched.Partner_Final_Online_Amount && errors.Partner_Final_Online_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Final Online Amount"
                                                name="Partner_Final_Online_Amount"
                                                value={values.Partner_Final_Online_Amount}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Advance_Bank_Fee && errors.Advance_Bank_Fee)}
                                                fullWidth
                                                helperText={touched.Advance_Bank_Fee && errors.Advance_Bank_Fee}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Bank Fee"
                                                name="Advance_Bank_Fee"
                                                value={values.Advance_Bank_Fee}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Final_Bank_Fee && errors.Final_Bank_Fee)}
                                                fullWidth
                                                helperText={touched.Final_Bank_Fee && errors.Final_Bank_Fee}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Final Bank Fee"
                                                name="Final_Bank_Fee"
                                                value={values.Final_Bank_Fee}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Completion Date"
                                                name="Bidding_Completion_Date"
                                                value={values.Bidding_Completion_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="datetime-local"
                                            >
                                            </TextField>
                                        </Grid>

                                        {/* Is_Partner_Amount_Settled */}
                                        <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.Is_Partner_Amount_Settled}
                                                        onChange={handleChange}
                                                        name="Is_Partner_Amount_Settled"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Partner Amount Settled"
                                            />
                                        </Grid>

                                        {/* Advance_Payment_Status_From_User */}
                                        <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.Advance_Payment_Status_From_User}
                                                        onChange={handleChange}
                                                        name="Advance_Payment_Status_From_User"
                                                        color="primary"
                                                    />
                                                }
                                                label="Advance Payment Status from User"
                                            />
                                        </Grid>

                                        {/* Final_Payment_Status_From_User */}
                                        <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.Final_Payment_Status_From_User}
                                                        onChange={handleChange}
                                                        name="Final_Payment_Status_From_User"
                                                        color="primary"
                                                    />
                                                }
                                                label="Final Payment Status from User"
                                            />
                                        </Grid>

                                        {/* Is_Partner_Advance_Amount_Settled */}
                                        <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.Is_Partner_Advance_Amount_Settled}
                                                        onChange={handleChange}
                                                        name="Is_Partner_Advance_Amount_Settled"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Partner Advance Amount Settled"
                                            />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            Description
                                            <TextareaAutosize
                                                value={values.Description}
                                                onChange={handleChange}
                                                name="Description"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={5}
                                                placeholder="Description"
                                            >
                                            </TextareaAutosize>
                                        </Grid>

                                        {values?.Bidding_Status === "CANCELLED" ?
                                            <Grid item md={12} xs={12}>
                                                Cancelled Reason
                                                <TextareaAutosize
                                                    value={values.Cancelled_Reason}
                                                    onChange={handleChange}
                                                    name="Cancelled_Reason"
                                                    onBlur={handleBlur}
                                                    variant="outlined"
                                                    className={classes.TextareaAutosize}
                                                    minRows={5}
                                                    placeholder="Cancelled Reason"
                                                >
                                                </TextareaAutosize>
                                            </Grid>
                                            : null}

                                        {values?.Bidding_Status === "COMPLETED" ?
                                            <>
                                                <Grid item md={12} xs={12}>
                                                    User Review for Partner
                                                    <TextareaAutosize
                                                        value={values.User_Review_For_Partner}
                                                        onChange={handleChange}
                                                        name="User_Review_For_Partner"
                                                        onBlur={handleBlur}
                                                        variant="outlined"
                                                        className={classes.TextareaAutosize}
                                                        minRows={5}
                                                        placeholder="User Review for Partner"
                                                    >
                                                    </TextareaAutosize>
                                                </Grid>

                                                <Grid item md={12} xs={12}>
                                                    User Review for Product
                                                    <TextareaAutosize
                                                        value={values.User_Review_For_Product}
                                                        onChange={handleChange}
                                                        name="User_Review_For_Product"
                                                        onBlur={handleBlur}
                                                        variant="outlined"
                                                        className={classes.TextareaAutosize}
                                                        minRows={5}
                                                        placeholder="User Review for Product"
                                                    >
                                                    </TextareaAutosize>
                                                </Grid>

                                                {/* User_Rating_For_Partner  */}
                                                <Grid item md={6} xs={6}>
                                                    <Box>User Rating for Partner</Box>
                                                </Grid>
                                                <Grid item md={2} xs={4}>
                                                    <Rating
                                                        name="User_Rating_For_Partner"
                                                        onChange={(event, newValue) => {
                                                            values.User_Rating_For_Partner = newValue;
                                                            setFieldValue(newValue);
                                                        }}
                                                        value={values.User_Rating_For_Partner}
                                                        size="medium"
                                                    />
                                                </Grid>

                                                {/* User_Rating_For_Product  */}
                                                <Grid item md={6} xs={6}>
                                                    <Box>User Rating for Product</Box>
                                                </Grid>
                                                <Grid item md={2} xs={4}>
                                                    <Rating
                                                        name="User_Rating_For_Product"
                                                        onChange={(event, newValue) => {
                                                            values.User_Rating_For_Product = newValue;
                                                            setFieldValue(newValue);
                                                        }}
                                                        value={values.User_Rating_For_Product}
                                                        size="medium"
                                                    />
                                                </Grid>
                                            </>
                                            : null}

                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditB2BUserProductsBidding.propTypes = {
    className: PropTypes.string
};

export default EditB2BUserProductsBidding;