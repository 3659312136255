import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createTemplateAction,
} from './../../store/actions/templateAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {},
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  },
  TextareaAutosize: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddEmailTemplate = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const errorMessage = useSelector(state => state.template.templateCreate.error);
  const isSubmited = useSelector(state => state.template.templateCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [rhDiscription, setRhDiscription] = useState('');
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // Richtext editor (Features)
  const [editorStateFeatures, setEditorStateFeatures] = useState(EditorState.createEmpty());
  const [rhDiscriptionFeaturesMin, setRhDiscriptionFeaturesMin] = useState(20);
  const [rhDiscriptionFeatures, setRhDiscriptionFeatures] = useState('');
  const onEditorStateChangeFeatures = (editorStateFeatures) => {
    setEditorStateFeatures(editorStateFeatures);
    setRhDiscriptionFeatures(draftToHtml(convertToRaw(editorStateFeatures.getCurrentContent())));
  };

  return (
    <Formik
      initialValues={{
        Template_Key: '',
        Template_Purpose: '',
        Sms_Template: '',
        Mail_Subject_Template: '',
        Mail_Body_Template: '',
        Whatsapp_Template: '',
        Created_By: loginEmail,
      }}

      validationSchema={
        Yup.object().shape({
          Template_Key: Yup.string().required('Key is required.'),
          Template_Purpose: Yup.string().max(15, 'Purpose must be 15 characters or less.').required('Purpose is required.'),
          Sms_Template: Yup.string().min(20, 'SMS Body must be at least 20 characters.').required('SMS Body is required.'),
          Mail_Subject_Template: Yup.string().required('Mail Subject is required.'),
        })
      }
      onSubmit={(values, { setErrors, setSubmitting }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        values.Mail_Body_Template = rhDiscription;
        values.Whatsapp_Template = rhDiscriptionFeatures;

        if (!values.Mail_Body_Template || values.Mail_Body_Template.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Mail_Body_Template = "Mail Body is required.";
        }
        else if (values.Mail_Body_Template.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Mail_Body_Template = "Please provide at least " + rhDiscriptionMin + " characters.";
        }

        if (!values.Whatsapp_Template || values.Whatsapp_Template.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Whatsapp_Template = "WhatsApp Body is required.";
        }
        else if (values.Whatsapp_Template.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionFeaturesMin) {
          submitTest = false;
          errors.Whatsapp_Template = "Please provide at least " + rhDiscriptionFeaturesMin + " characters.";
        }

        setErrors(errors);
        if (submitTest) {
          let formData = JSON.parse(JSON.stringify(values));
          dispatch(createTemplateAction(formData, navigate));
        }
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    {/* Template_Key */}
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Template_Key && errors.Template_Key)}
                        fullWidth
                        helperText={touched.Template_Key && errors.Template_Key}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Key"
                        name="Template_Key"
                        value={values.Template_Key}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      Purpose
                      <TextareaAutosize
                        value={values.Template_Purpose}
                        onChange={handleChange}
                        name="Template_Purpose"
                        onBlur={handleBlur}
                        variant="outlined"
                        className={classes.TextareaAutosize}
                        minRows={10}
                        placeholder="Purpose"
                      >
                      </TextareaAutosize>
                      <span style={{ color: "red" }}>
                        {((touched.Template_Purpose && errors.Template_Purpose))}
                      </span>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      SMS Body
                      <TextareaAutosize
                        className={classes.TextareaAutosize}
                        rowsMin={10}
                        placeholder="SMS Body"
                        value={values.Sms_Template}
                        onChange={handleChange}
                        name="Sms_Template"
                        onBlur={handleBlur}
                        variant="outlined"
                      >
                      </TextareaAutosize>
                      <span style={{ color: "red" }}>
                        {((touched.Sms_Template && errors.Sms_Template))}
                      </span>
                    </Grid>
                    {/* Mail_Subject_Template */}
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Mail_Subject_Template && errors.Mail_Subject_Template)}
                        fullWidth
                        helperText={touched.Mail_Subject_Template && errors.Mail_Subject_Template}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Mail Subject"
                        name="Mail_Subject_Template"
                        value={values.Mail_Subject_Template}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                    {/* Discription  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Mail Body
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Mail_Body_Template && errors.Mail_Body_Template}
                      </span>

                    </Grid>

                    {/* Features  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        WhatsApp Body
                        <EditorContainer editorState={editorStateFeatures} onEditorStateChange={onEditorStateChangeFeatures} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscriptionFeatures || rhDiscriptionFeatures.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionFeaturesMin) && touched.Whatsapp_Template && errors.Whatsapp_Template}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddEmailTemplate.propTypes = {
  className: PropTypes.string
};

export default AddEmailTemplate;