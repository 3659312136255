import {
  BRANDS_REQUEST,
  BRANDS_SUCCESS,
  BRANDS_FAIL
} from '../actions/brandreviewAction'

const initialState = {
  brands: { all: [], error: '', isLoading: false },
  brand: { brand: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BRANDS_REQUEST:
      return { ...state, brands: { all: state.brands.all, error: '', isLoading: true } };
    case BRANDS_SUCCESS:
      return { ...state, brands: { all: action.payload, error: '', isLoading: false } };
    case BRANDS_FAIL:
      return { ...state, brands: { all: [], error: action.payload, isLoading: false } };
    default:
      return state;
  }
}