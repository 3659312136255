import {
  BANNER_CREATE_REQUEST,
  BANNER_CREATE_SUCCESS,
  BANNER_CREATE_FAIL,

  BANNER_UPDATE_REQUEST,
  BANNER_UPDATE_SUCCESS,
  BANNER_UPDATE_FAIL,

  BANNER_PARTNER_REQUEST,
  BANNER_PARTNER_SUCCESS,
  BANNER_PARTNER_FAIL,

  BANNER_DEAL_REQUEST,
  BANNER_DEAL_SUCCESS,
  BANNER_DEAL_FAIL,

  BANNER_COUPON_REQUEST,
  BANNER_COUPON_SUCCESS,
  BANNER_COUPON_FAIL,

  BANNER_PRODUCT_REQUEST,
  BANNER_PRODUCT_SUCCESS,
  BANNER_PRODUCT_FAIL,

  BANNER_PRODUCT_ID_REQUEST,
  BANNER_PRODUCT_ID_SUCCESS,
  BANNER_PRODUCT_ID_FAIL,

  BANNER_ALLPARTNERS_REQUEST,
  BANNER_ALLPARTNERS_SUCCESS,
  BANNER_ALLPARTNERS_FAIL,

  BANNER_CITY_REQUEST,
  BANNER_CITY_SUCCESS,
  BANNER_CITY_FAIL,

  BANNER_STORE_TYPE_REQUEST,
  BANNER_STORE_TYPE_SUCCESS,
  BANNER_STORE_TYPE_FAIL,
} from '../actions/bannerAction'

const initialState = {
  bannerCreate: { banner: {}, error: '', isLoading: false },
  bannerUpdate: { banner: {}, error: '', isLoading: false },
  bannerDelete: { banner: {}, error: '', isLoading: false },
  bannerpartners: { partners: [], error: '', isLoading: false },
  bannerdeals: { deals: [], error: '', isLoading: false },
  bannercoupons: { coupons: [], error: '', isLoading: false },
  bannerproducts: { products: [], error: '', isLoading: false },
  bannerproductids: { productids: [], error: '', isLoading: false },

  bannerallpartners: { allpartners: [], error: '', isLoading: false },
  bannercities: { cities: [], error: '', isLoading: false },
  bannerstoretypes: { storetypes: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BANNER_CREATE_REQUEST:
      return { ...state, bannerCreate: { banner: {}, error: '', isLoading: true } };
    case BANNER_CREATE_SUCCESS:
      return { ...state, bannerCreate: { banner: state.bannerCreate.banner, error: '', isLoading: false } };
    case BANNER_CREATE_FAIL:
      return { ...state, bannerCreate: { banner: {}, error: action.payload, isLoading: false } };

    case BANNER_UPDATE_REQUEST:
      return { ...state, bannerUpdate: { banner: {}, error: '', isLoading: true } };
    case BANNER_UPDATE_SUCCESS:
      return { ...state, bannerUpdate: { banner: action.payload, error: '', isLoading: false } };
    case BANNER_UPDATE_FAIL:
      return { ...state, bannerUpdate: { banner: {}, error: action.payload, isLoading: false } };

    case BANNER_PARTNER_REQUEST:
      return { ...state, bannerpartners: { partners: state.bannerpartners.partners, error: '', isLoading: true }, };
    case BANNER_PARTNER_SUCCESS:
      return { ...state, bannerpartners: { partners: action.payload, error: '', isLoading: false } };
    case BANNER_PARTNER_FAIL:
      return { ...state, bannerpartners: { partners: [], error: action.payload, isLoading: false } };

    case BANNER_DEAL_REQUEST:
      return { ...state, bannerdeals: { deals: state.bannerdeals.deals, error: '', isLoading: true }, };
    case BANNER_DEAL_SUCCESS:
      return { ...state, bannerdeals: { deals: action.payload, error: '', isLoading: false } };
    case BANNER_DEAL_FAIL:
      return { ...state, bannerdeals: { deals: [], error: action.payload, isLoading: false } };

    case BANNER_COUPON_REQUEST:
      return { ...state, bannercoupons: { coupons: state.bannercoupons.coupons, error: '', isLoading: true }, };
    case BANNER_COUPON_SUCCESS:
      return { ...state, bannercoupons: { coupons: action.payload, error: '', isLoading: false } };
    case BANNER_COUPON_FAIL:
      return { ...state, bannercoupons: { coupons: [], error: action.payload, isLoading: false } };

    case BANNER_PRODUCT_REQUEST:
      return { ...state, bannerproducts: { products: state.bannerproducts.products, error: '', isLoading: true }, };
    case BANNER_PRODUCT_SUCCESS:
      return { ...state, bannerproducts: { products: action.payload, error: '', isLoading: false } };
    case BANNER_PRODUCT_FAIL:
      return { ...state, bannerproducts: { products: [], error: action.payload, isLoading: false } };

    case BANNER_PRODUCT_ID_REQUEST:
      return { ...state, bannerproductids: { productids: state.bannerproductids.productids, error: '', isLoading: true }, };
    case BANNER_PRODUCT_ID_SUCCESS:
      return { ...state, bannerproductids: { productids: action.payload, error: '', isLoading: false } };
    case BANNER_PRODUCT_ID_FAIL:
      return { ...state, bannerproductids: { productids: [], error: action.payload, isLoading: false } };

    case BANNER_ALLPARTNERS_REQUEST:
      return { ...state, bannerallpartners: { allpartners: state.bannerallpartners.allpartners, error: '', isLoading: true }, };
    case BANNER_ALLPARTNERS_SUCCESS:
      return { ...state, bannerallpartners: { allpartners: action.payload, error: '', isLoading: false } };
    case BANNER_ALLPARTNERS_FAIL:
      return { ...state, bannerallpartners: { allpartners: [], error: action.payload, isLoading: false } };

    case BANNER_CITY_REQUEST:
      return { ...state, bannercities: { cities: state.bannercities.cities, error: '', isLoading: true }, };
    case BANNER_CITY_SUCCESS:
      return { ...state, bannercities: { cities: action.payload, error: '', isLoading: false } };
    case BANNER_CITY_FAIL:
      return { ...state, bannercities: { cities: [], error: action.payload, isLoading: false } };

    case BANNER_STORE_TYPE_REQUEST:
      return { ...state, bannerstoretypes: { storetypes: state.bannerstoretypes.storetypes, error: '', isLoading: true }, };
    case BANNER_STORE_TYPE_SUCCESS:
      return { ...state, bannerstoretypes: { storetypes: action.payload, error: '', isLoading: false } };
    case BANNER_STORE_TYPE_FAIL:
      return { ...state, bannerstoretypes: { storetypes: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}