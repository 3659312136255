import {
  PARTNER_PAYMENT_UPDATE_REQUEST,
  PARTNER_PAYMENT_UPDATE_SUCCESS,
  PARTNER_PAYMENT_UPDATE_FAIL,
} from '../actions/partnerpaymentsettlementsAction'

const initialState = {
  partnerpaymentUpdate: { partnerpayment: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {

    case PARTNER_PAYMENT_UPDATE_REQUEST:
      return { ...state, partnerpaymentUpdate: { partnerpayment: {}, error: '', isLoading: true } };
    case PARTNER_PAYMENT_UPDATE_SUCCESS:
      return { ...state, partnerpaymentUpdate: { partnerpayment: state.partnerpaymentUpdate.partnerpayment, error: '', isLoading: false } };
    case PARTNER_PAYMENT_UPDATE_FAIL:
      return { ...state, partnerpaymentUpdate: { partnerpayment: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}