import { nodeGetApi } from './commonAxios';
import config from './config';

export const BRANDS_REQUEST = "BRANDS_REQUEST";
export const BRANDS_SUCCESS = "BRANDS_SUCCESS";
export const BRANDS_FAIL = "BRANDS_FAIL";
export const RESET = "RESET";

/*=============================================================
                  Get All brand Action
===============================================================*/
export const getAllBrandAction = formData => async dispatch => {
  try {
    dispatch({
      type: BRANDS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Brand/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Brand_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: BRANDS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BRANDS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: BRANDS_FAIL,
      payload: err
    });
  }
};