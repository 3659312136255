import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import * as yup from "yup";
import {
  editProfileAction,
} from "../../../store/actions/profileActions";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Formik } from 'formik';
import PhoneInput from 'react-phone-input-2';
import { ConnectedFocusError } from "focus-formik-error";

const ProfileDetails = ({ className, profiletemp, callgetuser, loading, setLoading, ...rest }) => {
  const dispatch = useDispatch();

  const [mobileField, setMobileField] = useState();
  const [phoneLength, setPhoneLength] = useState(14);

  const user = useSelector((state) => state.auth.user);

  const updateStates = (data) => {
    setLoading(false);
    toast(data.UI_Display_Message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
    callgetuser();
  };

  useEffect(() => {
    setMobileField(profiletemp ? profiletemp.Mobile : "");
  }, [profiletemp]);

  return (
    <>
      {profiletemp &&
        <Formik
          initialValues={{
            Dob: profiletemp ? profiletemp.Dob : "",
            Facebook_Login_Id: profiletemp ? profiletemp.Facebook_Login_Id : "",
            First_Name: profiletemp ? profiletemp.First_Name : "",
            Gender: profiletemp ? profiletemp.Gender : "",
            Google_Login_Id: profiletemp ? profiletemp.Google_Login_Id : "",
            Id_Proof_Number: profiletemp ? profiletemp.Id_Proof_Number : "",
            Id_Proof_Type: profiletemp ? profiletemp.Id_Proof_type : "",
            Last_Name: profiletemp ? profiletemp.Last_name : "",
            Mobile: profiletemp ? profiletemp.Mobile : "",
            Email_Id: user.Email_Id,
            User_Details_Id: profiletemp ? profiletemp.User_Details_id : ""
          }}
          validationSchema={yup.object({
            First_Name: yup
              .string()
              .required("First Name is required."),
            Last_Name: yup
              .string()
              .required("Last Name is required."),
            Mobile: yup.string()
              .min(phoneLength, "Mobile Number is not valid.")
              .required('Mobile Number is required.'),
            Dob: yup.date()
              // .max(moment().subtract(18, "years"), "Date Of Birth must be before " + (moment().subtract(18, "years").month() < 10 ? "0" : "") + moment().subtract(18, "years").month() + "/" + (moment().subtract(18, "years").date() < 10 ? "0" : "") + moment().subtract(18, "years").date() + "/" + moment().subtract(18, "years").year() + ".")
              .required("Date Of Birth is required."),
            Gender: yup.string("Enter gender").required("Gender is required."),
            Id_Proof_Number: yup.string().when('Id_Proof_Type', (Id_Proof_Type, schema) => {
              if (Id_Proof_Type === "Aadhaar Card") {
                return schema.min(12, "Aadhaar Card Number is not valid.").max(12, "Aadhaar Card Number is not valid.").matches(/^[0-9]*$/, "Aadhaar Card Number is not valid.").required("Aadhaar Card Number is required.");
              } else if (Id_Proof_Type === "PAN Card") {
                return schema.matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "PAN Card Number is not valid.").required("PAN Card Number is required.");
              } else if (Id_Proof_Type === "Driving License") {
                return schema.matches(/^([a-zA-Z]){2}([0-9]){14}$/, "Driving License Number is not valid.").required("Driving License Number is required.");
              }
            }),
          })}

          onSubmit={(values) => {
            setLoading(true);
            dispatch(editProfileAction(updateStates, values));
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <form
              onSubmit={handleSubmit}
              {...rest}
            >
              <ConnectedFocusError />
              <Card>
                <CardHeader
                  title="Profile"
                />
                <Divider />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid item xs={12} lg={6}>
                      <TextField
                        error={Boolean(touched.First_Name && errors.First_Name)}
                        fullWidth
                        helperText={touched.First_Name && errors.First_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.First_Name}
                        variant="outlined"
                        name="First_Name"
                        label="First Name"
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="Last_Name"
                        label="Last Name"
                        value={values.Last_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.Last_Name && Boolean(errors.Last_Name)}
                        helperText={touched.Last_Name && errors.Last_Name}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>

                      <TextField
                        error={Boolean(touched.Gender && errors.Gender)}
                        fullWidth
                        helperText={touched.Gender && errors.Gender}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Gender"
                        name="Gender"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Gender}
                        variant="outlined"
                      >
                        <option value="" >
                          -Please Select-
                        </option>
                        <option value="Male" >
                          Male
                        </option>
                        <option value="Female" >
                          Female
                        </option>

                      </TextField>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <PhoneInput
                        containerClass={touched.Mobile && errors.Mobile ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                        inputClass={"form-control MuiOutlinedInput-input"}
                        error={true}
                        country={"in"}
                        fullWidth
                        inputStyle={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px"
                        }}
                        inputProps={{
                          name: "Mobile",
                        }}
                        specialLabel="Mobile Number"
                        name="Mobile"
                        onBlur={handleBlur}

                        onChange={(value, country, e, formattedValue) => {
                          if (country.dialCode === "") {
                            setPhoneLength(14);
                          } else {
                            setPhoneLength((country.format.split(" "))[0].length + 2);
                          }
                          setMobileField(formattedValue);

                          setFieldValue("Mobile", "" + country.dialCode + " " + value.replace(country.dialCode, ''));
                        }}
                        value={mobileField}
                      />
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Mobile && errors.Mobile}</p>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <TextField
                        error={Boolean(touched.Id_Proof_Type && errors.Id_Proof_Type)}
                        fullWidth
                        helperText={touched.Id_Proof_Type && errors.Id_Proof_Type}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("Id_Proof_Number", "");
                        }}
                        label="ID Proof Type"
                        name="Id_Proof_Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Id_Proof_Type}
                        variant="outlined"
                      >
                        <option value="" >
                          -Please Select-
                        </option>
                        <option value="Aadhaar Card" >
                          Aadhaar Card
                        </option>
                        <option value="PAN Card" >
                          PAN Card
                        </option>
                        <option value="Driving License" >
                          Driving License
                        </option>

                      </TextField>

                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        error={Boolean(touched.Id_Proof_Number && errors.Id_Proof_Number)}
                        fullWidth
                        disabled={values.Id_Proof_Type?.length <= 0}
                        helperText={touched.Id_Proof_Number && errors.Id_Proof_Number}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: values.Id_Proof_Type === "Aadhaar Card" ? 12 : values.Id_Proof_Type === "Driving License" ? 16 : values.Id_Proof_Type === "PAN Card" ? 10 : ''
                        }}
                        label={values.Id_Proof_Type ? values.Id_Proof_Type + " Number" : "ID Proof Number"}
                        name="Id_Proof_Number"
                        value={values.Id_Proof_Number}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="Dob"
                        label="Date Of Birth"
                        value={values.Dob}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleChange}
                        type="date"
                        error={touched.Dob && Boolean(errors.Dob)}
                        helperText={touched.Dob && errors.Dob}
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <div
                        style={{ fontWeight: 800, fontSize: "1.1rem", textAlign: "left" }}
                      >
                        Social Details
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="Facebook_Login_Id"
                        label="Facebook Login ID"
                        value={values.Facebook_Login_Id}
                        onChange={handleChange}
                        error={
                          touched.Facebook_Login_Id &&
                          Boolean(errors.Facebook_Login_Id)
                        }
                        helperText={
                          touched.Facebook_Login_Id && errors.Facebook_Login_Id
                        }
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="Google_Login_Id"
                        label="Google Login ID"
                        value={values.Google_Login_Id}
                        onChange={handleChange}
                        error={
                          touched.Google_Login_Id &&
                          Boolean(errors.Google_Login_Id)
                        }
                        helperText={
                          touched.Google_Login_Id && errors.Google_Login_Id
                        }
                      />
                    </Grid>

                  </Grid>
                </CardContent>
                <Divider />
                <Box
                  display="flex"
                  justifyContent="center"
                  p={2}
                >
                  <Button
                    disabled={loading}
                    // color="primary"
                    style={{ backgroundColor: '#27B6CC' }}
                    variant="contained"
                    type="submit"
                  >
                    {/* Save details */}
                    <span style={{ color: 'white' }}>Save details</span>
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      }
    </>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
  profiletemp: PropTypes.object,
  callgetuser: PropTypes.func
};

export default ProfileDetails;