import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
  Avatar,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import React, { useState } from 'react';
import {
  createCityAction,
} from '../../../store/actions/sbcmCityAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { ConnectedFocusError } from "focus-formik-error";
import { uploadCityImage } from 'src/store/actions/citypartnerAction';
import ESICountry from 'src/components/country-state-city/country';
import ESIState from 'src/components/country-state-city/state';
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: 120,
    height: 120,
    borderRadius: 5,
    marginBottom: 15
  },
}));

const AddCity = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSubmited = useSelector(state => state.sbcmCity.cityCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const [errorMessage, setErrorMessage] = useState({ Name: { key: '', message: '' }, global: { key: '', message: '' } });

  // img upload
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);

  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadCityImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };
  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        Country: '',
        State: '',
        Name: '',
        Status: '',
        City_Different_Names: '',
        Available_Zipcodes: '',
        // District: "",
        // Division: "",
        Image_Path: imgUrl,
        Is_Meepaisa_Available: false,
        Created_By: loginEmail,
      }}

      validationSchema={
        Yup.object().shape({
          // Name: Yup.string().notOneOf(allCities.map(item => item['Name']), "City already exist.").required('City is required.'),
          Name: Yup.string().notOneOf([errorMessage.Name.key, null], errorMessage.Name.message).required('City is required.'),
          State: Yup.string().required('State is required.'),
          Country: Yup.string().required('Country is required.'),
          Available_Zipcodes: Yup.string().required('Pin Code is required.'),
          Status: Yup.string().required('Status is required.'),
        })}

      onSubmit={(values, { setErrors, setSubmitting }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        values.Image_Path = imgUrl;
        if (!values.Image_Path) {
          submitTest = false;
          errors.Image_Path = "Image is required.";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          if (values.Is_Meepaisa_Available) {
            formValues.Is_Meepaisa_Available = 1;
          } else {
            formValues.Is_Meepaisa_Available = 0;
          }
          dispatch(createCityAction(formValues, navigate, setErrorMessage, setErrors));
        }
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} >
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    {/* Country Field */}
                    <Grid item md={6} xs={12}>
                      <ESICountry
                        error={Boolean(touched.Country && errors.Country)}
                        fullWidth
                        helperText={touched.Country && errors.Country}
                        placeholder="Country"
                        label="Country"
                        name="Country"
                        onChange={handleChange}
                        select
                        SelectProps={{ native: true }}
                        value={values.Country}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    {/* State field */}
                    <Grid item md={6} xs={12}>
                      <ESIState
                        error={Boolean(touched.State && errors.State)}
                        fullWidth
                        helperText={touched.State && errors.State}
                        label="State"
                        name="State"
                        onChange={handleChange}
                        value={values.State}
                        countrycode={values.Country}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    {/* City Field */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Name && errors.Name)}
                        fullWidth
                        helperText={touched.Name && errors.Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="City Name"
                        name="Name"
                        value={values.Name}
                        variant="outlined"
                      />
                    </Grid>

                    {/* City_Different_Names */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.City_Different_Names && errors.City_Different_Names)}
                        fullWidth
                        helperText={touched.City_Different_Names && errors.City_Different_Names}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="City Different Name"
                        name="City_Different_Names"
                        value={values.City_Different_Names}
                        variant="outlined"
                      />
                    </Grid>

                    {/*Available_Zipcode  */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Available_Zipcodes && errors.Available_Zipcodes)}
                        fullWidth
                        helperText={touched.Available_Zipcodes && errors.Available_Zipcodes}
                        label="Pin Code"
                        name="Available_Zipcodes"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.Available_Zipcodes}
                        variant="outlined"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                        }}
                        min={0}
                      />
                    </Grid>

                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Is_Meepaisa_Available */}
                    <Grid item md={6} xs={12}>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Meepaisa_Available}
                              onChange={handleChange}
                              name="Is_Meepaisa_Available"
                              color="primary"
                            />
                          }
                          label="Is Meepaisa Available"
                        />
                      </Box>
                    </Grid>

                  </Grid>
                </Grid>

                {/* upload photo */}
                <Grid item md={4} xs={12} >
                  <Card >
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Brand Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                  <span style={{ color: "red" }}>
                    {!imgUrl && touched.Image_Path && errors.Image_Path}
                  </span>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>
          </Card>

          <Box display="flex" p={2}>
            {isSubmited ?
              <CircularProgress />
              :
              <Button
                disabled={isSubmited || uploadPic}
                type="submit"
                style={{ backgroundColor: '#27B6CC' }}
                variant="contained"
              >
                <span style={{ color: 'white' }}>Save details</span>
              </Button>
            }
          </Box>
        </form>
      )}
    </Formik>
  );
};

AddCity.propTypes = {
  className: PropTypes.string
};

export default AddCity;