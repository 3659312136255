import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { addPartnerCitylocationAction, getAllCitylocationsid } from 'src/store/actions/partnercitylocationAction';
import { getAllPartneraddressAction } from 'src/store/actions/partneraddressAction';
import { CircularProgress } from '@mui/material';

const AddCitypartnerlocation = ({ className, state, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // componentDidMount
  useEffect(() => {
    let formData = {
      Partner_Details_Id: state?.Partner_Details_Id,
      Records_Filter: "FILTER",
      search: '',
      search_by_filter: ''
    }
    let formValues = {
      "City_ID": state?.City_Id,
      "Records_Filter": "FILTER",
      search: '',
      search_by_filter: ''
    }
    dispatch(getAllPartneraddressAction(formData));
    dispatch(getAllCitylocationsid(formValues));
  }, []);

  const Partnerid = useSelector(state => state.partneraddress.partnersaddress.all);
  const locations = useSelector(state => state.partnercitylocation.citylocationids.locationids);
  const isSubmitted = useSelector(state => state.partnercitylocation.partnercitylocationCreate.isLoading);
  const errorMessage = useSelector(state => state.partnercitylocation.partnercitylocationCreate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        Partner_Detail_Id: state?.Partner_Details_Id,
        Location_Id: "",
        City_Id: state?.City_Id,
        Created_By: loginEmail,
        Is_Active: false,
        Is_Managed_By_Meepaisa: false,
        Is_Partner_Delivery_Available: false,
        Is_Pickup_Available: false,
        Partner_Address_Id: "",
      }}

      validationSchema={
        Yup.object().shape({
          Partner_Address_Id: Yup.string().required('Partner Address is required.'),
          Location_Id: Yup.string().required('Location Name is required.'),
        })
      }

      onSubmit={(values, { setErrors, setSubmitting }) => {
        setSubmitting(false);
        const errors = {};
        setErrors(errors);

        let formValues = JSON.parse(JSON.stringify(values));
        if (values.Is_Active) {
          formValues.Is_Active = 1;
        } else {
          formValues.Is_Active = 0;
        } if (values.Is_Managed_By_Meepaisa) {
          formValues.Is_Managed_By_Meepaisa = 1;
        } else {
          formValues.Is_Managed_By_Meepaisa = 0;
        } if (values.Is_Partner_Delivery_Available) {
          formValues.Is_Partner_Delivery_Available = 1;
        } else {
          formValues.Is_Partner_Delivery_Available = 0;
        }
        if (values.Is_Pickup_Available) {
          formValues.Is_Pickup_Available = 1;
        } else {
          formValues.Is_Pickup_Available = 0;
        }
        dispatch(addPartnerCitylocationAction(formValues, navigate, state));
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>

                <Grid item md={6} xs={12}>
                  {/* Partner_Address_Id */}
                  <TextField
                    error={Boolean(touched.Partner_Address_Id && errors.Partner_Address_Id)}
                    fullWidth
                    helperText={touched.Partner_Address_Id && errors.Partner_Address_Id}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Partner Address"
                    InputLabelProps={{ shrink: true }}
                    name="Partner_Address_Id"
                    select
                    SelectProps={{ native: true }}
                    value={values.Partner_Address_Id}
                    variant="outlined"
                  ><option key="" value="">--Please Select--</option>
                    {Partnerid?.sort((a, b) => a.City?.localeCompare(b.City))?.map(option => (
                      <option key={option.Partner_Address_Id} value={option.Partner_Address_Id}>
                        {option.City + " " + "-" + " " + option.Location + " " + "-" + " " + option.Address_Type}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={6} xs={12}>
                  {/* Location_Id */}
                  <TextField
                    error={Boolean(touched.Location_Id && errors.Location_Id)}
                    fullWidth
                    helperText={touched.Location_Id && errors.Location_Id}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Location Name"
                    InputLabelProps={{ shrink: true }}
                    name="Location_Id"
                    select
                    SelectProps={{ native: true }}
                    value={values.Location_Id}
                    variant="outlined"
                  >
                    <option key="" value="">--Please Select--</option>
                    {locations?.sort((a, b) => a.Location_Name?.localeCompare(b.Location_Name))?.map(option => (
                      <option key={option.Location_Id} value={option.Location_Id}>
                        {option.Location_Name}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onBlur={handleBlur}
                        checked={values.Is_Active}
                        onChange={handleChange}
                        name="Is_Active"
                        color="primary"
                      />
                    }
                    label="Is Active"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onBlur={handleBlur}
                        checked={values.Is_Managed_By_Meepaisa}
                        onChange={handleChange}
                        name="Is_Managed_By_Meepaisa"
                        color="primary"
                      />
                    }
                    label="Is Managed by Meepaisa"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onBlur={handleBlur}
                        checked={values.Is_Partner_Delivery_Available}
                        onChange={handleChange}
                        name="Is_Partner_Delivery_Available"
                        color="primary"
                      />
                    }
                    label="Is Partner Delivery Available"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onBlur={handleBlur}
                        checked={values.Is_Pickup_Available}
                        onChange={handleChange}
                        name="Is_Pickup_Available"
                        color="primary"
                      />
                    }
                    label="Is Pickup Available"
                  />
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2}>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmitted ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmitted}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddCitypartnerlocation.propTypes = {
  className: PropTypes.string
};

export default AddCitypartnerlocation;