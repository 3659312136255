import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    makeStyles,
    TextareaAutosize,
    TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { createB2BUserProductsBidding, getAllB2BBiddingProducts, getAllBiddingPartnerDetails, getAllbidprodmeasuretypes } from 'src/store/actions/b2buserproductsbiddingAction';

const bidstatusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'INITIATED',
        label: 'INITIATED'
    },
    {
        value: 'PROCESSING',
        label: 'PROCESSING'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
    {
        value: 'COMPLETED',
        label: 'COMPLETED'
    }
];

const useStyles = makeStyles(() => ({
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const AddB2BUserProductsBidding = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        const formData = {
            search: "",
            search_by_filter: ""
        };
        dispatch(getAllBiddingPartnerDetails(formData));
        dispatch(getAllB2BBiddingProducts(formData));
    }, []);

    const [measuretypes, setmeasuretypes] = React.useState([]);
    const callBackMeasureTypesData = (data) => {
        setmeasuretypes(data[0].Measurement_Type.split(",").map(Values => ({ Measurement_Type: Values })))
    }

    const setmeasureType = (value) => {
        if (value !== "") {
            let data = {
                search: "",
                search_by_filter: "",
                Records_Filter: 'FILTER',
                Bidding_Product_Id: value,
            }
            dispatch(getAllbidprodmeasuretypes(data, callBackMeasureTypesData))
        }
    }

    const isSubmited = useSelector(state => state.b2buserproductsbid.b2buserprodbidCreate.isLoading);
    const errorMessage = useSelector(state => state.b2buserproductsbid.b2buserprodbidCreate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const b2bbidpartners = useSelector(state => state.b2buserproductsbid.b2bbidpartnerdetails.all)
    const b2bbidproducts = useSelector(state => state.b2buserproductsbid.b2bbidproducts.all)

    return (
        <Formik
            initialValues={{
                Buyer_Partner_Detail_Id: '',
                Bidding_Product_Id: '',
                Measurement_Type: '',
                User_Pincode: '',
                Bidding_Expiry_Date: '',
                Quantity: 0,
                Description: '',
                Bidding_Status: '',
                Created_By: loginEmail,
            }}
            validationSchema={
                Yup.object().shape({
                    Buyer_Partner_Detail_Id: Yup.string().required('Buyer Partner is required.'),
                    Bidding_Product_Id: Yup.string().required('Bidding Product is required.'),
                    Measurement_Type: Yup.string().required('Measurement Type is required.'),
                    User_Pincode: Yup.string().required('Pincode is required.'),
                    Bidding_Expiry_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require Bidding Expiry Date greater than or equal to today.").required("Bidding Expiry Date is required."),
                    Quantity: Yup.number().min(0, 'Quantity should not be less than 0.').required('Quantity is required.'),
                    Bidding_Status: Yup.string().required('Bidding Status is required.'),
                })
            }
            onSubmit={(values) => {
                let submitTest = true;
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(createB2BUserProductsBidding(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Buyer_Partner_Detail_Id && errors.Buyer_Partner_Detail_Id)}
                                                fullWidth
                                                helperText={touched.Buyer_Partner_Detail_Id && errors.Buyer_Partner_Detail_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Buyer Partner"
                                                name="Buyer_Partner_Detail_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Buyer_Partner_Detail_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {b2bbidpartners?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                                                    <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                                                        {option.Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.User_Pincode && errors.User_Pincode)}
                                                fullWidth
                                                helperText={touched.User_Pincode && errors.User_Pincode}
                                                label="PinCode"
                                                name="User_Pincode"
                                                type="number"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.User_Pincode}
                                                variant="outlined"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                                                }}
                                                min={0}
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Product_Id && errors.Bidding_Product_Id)}
                                                fullWidth
                                                helperText={touched.Bidding_Product_Id && errors.Bidding_Product_Id}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setmeasureType(e.target.value)
                                                }}
                                                label="Bidding Product"
                                                name="Bidding_Product_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Product_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {b2bbidproducts?.map(option => (
                                                    <option key={option.Bidding_Product_Id} value={option.Bidding_Product_Id}>
                                                        {option.Product_Name.substring(0, 35)}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Measurement_Type && errors.Measurement_Type)}
                                                fullWidth
                                                helperText={touched.Measurement_Type && errors.Measurement_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Measurement Type"
                                                name="Measurement_Type"
                                                value={values.Measurement_Type}
                                                variant="outlined"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                            >
                                                <option key="" value="">-Please Select-</option>
                                                {measuretypes?.map((option, i) => (
                                                    <option key={i} value={option.Measurement_Type}>
                                                        {option.Measurement_Type}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Quantity && errors.Quantity)}
                                                fullWidth
                                                helperText={touched.Quantity && errors.Quantity}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Quantity"
                                                name="Quantity"
                                                value={values.Quantity}
                                                variant="outlined"
                                                type="number"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}
                                                fullWidth
                                                helperText={touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Expiry Date"
                                                type="datetime-local"
                                                name="Bidding_Expiry_Date"
                                                defaultValue={values.Bidding_Expiry_Date}
                                                variant="outlined"
                                                className={classes.textField}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Status && errors.Bidding_Status)}
                                                fullWidth
                                                helperText={touched.Bidding_Status && errors.Bidding_Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Status"
                                                name="Bidding_Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Status}
                                                variant="outlined"
                                            >
                                                {bidstatusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            Description
                                            <TextareaAutosize
                                                value={values.Description}
                                                onChange={handleChange}
                                                name="Description"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Description"
                                            >
                                            </TextareaAutosize>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddB2BUserProductsBidding.propTypes = {
    className: PropTypes.string
};

export default AddB2BUserProductsBidding;