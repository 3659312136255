import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const BILL_UPDATE_REQUEST = "BILL_UPDATE_REQUEST";
export const BILL_UPDATE_SUCCESS = "BILL_UPDATE_SUCCESS";
export const BILL_UPDATE_FAIL = "BILL_UPDATE_FAIL";

/*=============================================================
                Update BIll Action
===============================================================*/
export const updateBillAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: BILL_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Tickets/Missing_Cashback_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BILL_UPDATE_SUCCESS
        });
        toast('Missing cashback updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/missing-cashback');
      } else {
        dispatch({
          type: BILL_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: BILL_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

//Missing Cashback
export const getMissingcashbackAction = (formData, callBackMissingCashbackData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Tickets/Missing_Cashback_GET_Admin`, formData);
    if (data) {
      callBackMissingCashbackData(data)
    }
  } catch (err) {
  }
};