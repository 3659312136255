import {
  CITYS_REQUEST,
  CITYS_SUCCESS,
  CITYS_FAIL,
  CITY_GET_REQUEST,
  CITY_GET_SUCCESS,
  CITY_GET_FAIL,
  CITY_CREATE_REQUEST,
  CITY_CREATE_SUCCESS,
  CITY_CREATE_FAIL,
  CITY_UPDATE_REQUEST,
  CITY_UPDATE_SUCCESS,
  CITY_UPDATE_FAIL,
} from '../actions/sbcmCityAction'

const initialState = {
  citys: { all: [], error: '', isLoading: false },
  city: { city: {}, error: '', isLoading: false },
  cityCreate: { city: {}, error: '', isLoading: false },
  cityUpdate: { city: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CITYS_REQUEST:
      return { ...state, citys: { all: state.citys.all, error: '', isLoading: true } };
    case CITYS_SUCCESS:
      return { ...state, citys: { all: action.payload, error: '', isLoading: false } };
    case CITYS_FAIL:
      return { ...state, citys: { all: [], error: action.payload, isLoading: false } };
    case CITY_GET_REQUEST:
      return { ...state, city: { city: {}, error: '', isLoading: true } };
    case CITY_GET_SUCCESS:
      return { ...state, city: { city: action.payload, error: '', isLoading: false } };
    case CITY_GET_FAIL:
      return { ...state, city: { city: {}, error: action.payload, isLoading: false } };
    case CITY_CREATE_REQUEST:
      return { ...state, cityCreate: { city: {}, error: '', isLoading: true } };
    case CITY_CREATE_SUCCESS:
      return { ...state, cityCreate: { city: state.cityCreate.city, error: '', isLoading: false } };
    case CITY_CREATE_FAIL:
      return { ...state, cityCreate: { city: {}, error: action.payload, isLoading: false } };
    case CITY_UPDATE_REQUEST:
      return { ...state, cityUpdate: { city: {}, error: '', isLoading: true } };
    case CITY_UPDATE_SUCCESS:
      return { ...state, cityUpdate: { city: state.cityCreate.city, error: '', isLoading: false } };
    case CITY_UPDATE_FAIL:
      return { ...state, cityUpdate: { city: {}, error: action.payload, isLoading: false } };
    default:
      return state;
  }
}