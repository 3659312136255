import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  topProducts
} from '../../../../store/actions/reports/dashboardActions';
import isEmpty from 'src/store/validations/is-empty';

const useStyles = makeStyles({
  root: {
    height: '100%'
  },
  image: {
    height: 48,
    width: 48
  }
});

const TopProducts = ({ className, RolePermissions, setSeconds, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const products = useSelector(state => state.dash.TProducts.data);
  let Top_Products_Hide = RolePermissions?.Top_Products_Hide?.Is_Hide

  // componentDidMount
  useEffect(() => {
    dispatch(topProducts());
    setSeconds(5);
    if (RolePermissions?.Top_Products_Hide?.Value === "Top_Products_Hide" && RolePermissions?.Top_Products_Hide?.Is_Hide === 1) {
      Top_Products_Hide = RolePermissions?.Top_Products_Hide?.Is_Hide
    }
  }, []);

  return (
    <>
      {Top_Products_Hide !== 1 &&
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardHeader
            title="Top Products"
          />
          <Divider />
          {isEmpty(products) &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', paddingBottom: '15px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >
              No top products found!
            </div>
          }
          <List>
            {products.map((product, i) => (
              <ListItem divider={i < products.length - 1} key={product.Product_Id}>
                <ListItemAvatar>
                  <img
                    alt="Product"
                    className={classes.image}
                    src={product.Product_Image}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={product.Product_Name}
                  secondary={`Price : ${product.Price}`}
                />
                <> {product.Grab_Count} </>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Card>
      }
    </>
  );
};

TopProducts.propTypes = {
  className: PropTypes.string
};

export default TopProducts;