import React from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Button,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
    TableSortLabel,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import isEmpty from '../../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CorpPagination from 'src/components/pagination/corppagination';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    }
}));

const headCells = [
    { id: 'Company_Name', numeric: false, disablePadding: true, label: 'Company Name' },
    { id: 'Created_By', numeric: false, disablePadding: false, label: 'Send By' },
    { id: 'Product_Name', numeric: true, disablePadding: false, label: 'Product Name' },
    { id: 'Category_Name', numeric: true, disablePadding: false, label: 'Product Category' },
    { id: 'Quantity', numeric: true, disablePadding: false, label: 'Quantity' },
    { id: 'Budget', numeric: false, disablePadding: false, label: 'Budget/Unit' },
];

const Results = ({
    className,
    allProductRequest,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    createSortHandler,
    allProductRequestNoFilter,
    pagination,
    setPagination,
    RolePermissions,
    roleUserType,
    ...rest
}) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const viewProductRequestHandler = productrequest => e => {
        navigate('/app/edit-corporate-product-request', { state: productrequest });
    };

    return (
        <Card className={clsx(classes.root, className)}
            {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell className={classes.headerColumn}
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                    Actions &nbsp; &nbsp;&nbsp; &nbsp;
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allProductRequest) && (allProductRequest).map(productrequest => (
                                <TableRow
                                    hover
                                    key={productrequest._id}
                                >
                                    <TableCell>{productrequest.Company_Name}</TableCell>
                                    <TableCell>{productrequest.Created_By}</TableCell>
                                    <TableCell>{productrequest.Product_Name}</TableCell>
                                    <TableCell>{productrequest.Category_Name}</TableCell>
                                    <TableCell>{productrequest.Quantity}</TableCell>
                                    <TableCell>{productrequest.Budget}</TableCell>
                                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                        (RolePermissions?.Product_Management_Write?.Value === "Product_Management_Write" && RolePermissions?.Product_Management_Write?.Is_Write === 1) ?
                                        <TableCell style={{ textAlign: "center", }}>
                                            <Tooltip title="Product Request Details">
                                                <Button
                                                    onClick={viewProductRequestHandler(productrequest)}
                                                    color="primary" >
                                                    <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                            </Tooltip>
                                        </TableCell>
                                        : null}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allProductRequest) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <CorpPagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    allProductRequest: PropTypes.array.isRequired
};

export default Results;