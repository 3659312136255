import { toast } from 'react-toastify';
import { paginationOffers } from './node-actions/nodepagination';
import { nodeCudApi} from './commonAxios';
export const OFFER_UPDATE_REQUEST = "OFFER_UPDATE_REQUEST";
export const OFFER_UPDATE_SUCCESS = "OFFER_UPDATE_SUCCESS";
export const OFFER_UPDATE_FAIL = "OFFER_UPDATE_FAIL";


export const EditOfferVisitAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: OFFER_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Offers_Visitor/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: OFFER_UPDATE_SUCCESS
        });
        toast('User Visit updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/offers-visitor');
      } else {
        dispatch({
          type: OFFER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: OFFER_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working."
      });
    })
};

/*=============================================================
                   delete User Visit Action
===============================================================*/
export const deleteOfferVisitAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Offers_Visitor/Delete`, formData);
    if (data) {
      toast('User Visit deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationOffers(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};