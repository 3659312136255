import {
    CONTENTMANAGEMENTS_STATIC_REQUEST,
    CONTENTMANAGEMENTS_STATIC_SUCCESS,
    CONTENTMANAGEMENTS_STATIC_FAIL,
    CONTENTMANAGEMENT_STATIC_GET_REQUEST,
    CONTENTMANAGEMENT_STATIC_GET_SUCCESS,
    CONTENTMANAGEMENT_STATIC_GET_FAIL,
    CONTENTMANAGEMENT_STATIC_CREATE_REQUEST,
    CONTENTMANAGEMENT_STATIC_CREATE_SUCCESS,
    CONTENTMANAGEMENT_STATIC_CREATE_FAIL,
    CONTENTMANAGEMENT_STATIC_UPDATE_REQUEST,
    CONTENTMANAGEMENT_STATIC_UPDATE_SUCCESS,
    CONTENTMANAGEMENT_STATIC_UPDATE_FAIL,
} from '../actions/deliverycmspageActions'

const initialState = {
    ContentManagement: { all: [], error: '', isLoading: false },
    contentmanagement: { contentmanagement: {}, error: '', isLoading: false },
    contentmanagementCreate: { contentmanagement: {}, error: '', isLoading: false },
    contentmanagementUpdate: { contentmanagement: {}, error: '', isLoading: false },
};


export default function (state = initialState, action) {
    switch (action.type) {
        case CONTENTMANAGEMENTS_STATIC_REQUEST:
            return { ...state, ContentManagement: { all: state.ContentManagement.all, error: '', isLoading: true } };
        case CONTENTMANAGEMENTS_STATIC_SUCCESS:
            return { ...state, ContentManagement: { all: action.payload, error: '', isLoading: false } };
        case CONTENTMANAGEMENTS_STATIC_FAIL:
            return { ...state, ContentManagement: { all: [], error: action.payload, isLoading: false } };
        case CONTENTMANAGEMENT_STATIC_GET_REQUEST:
            return {
                ...state, contentmanagement: { contentmanagement: {}, error: '', isLoading: true }
            };
        case CONTENTMANAGEMENT_STATIC_GET_SUCCESS:
            return {
                ...state, contentmanagement: { contentmanagement: action.payload, error: '', isLoading: false }
            };
        case CONTENTMANAGEMENT_STATIC_GET_FAIL:
            return { ...state, contentmanagement: { contentmanagement: {}, error: action.payload, isLoading: false } };
        case CONTENTMANAGEMENT_STATIC_CREATE_REQUEST:
            return { ...state, contentmanagementCreate: { contentmanagement: {}, error: '', isLoading: true } };
        case CONTENTMANAGEMENT_STATIC_CREATE_SUCCESS:
            return {
                ...state, contentmanagementCreate: {
                    contentmanagement: state.contentmanagementCreate.contentmanagement
                    , error: '', isLoading: false
                }
            };
        case CONTENTMANAGEMENT_STATIC_CREATE_FAIL:
            return { ...state, contentmanagementCreate: { contentmanagement: {}, error: action.payload, isLoading: false } };
        case CONTENTMANAGEMENT_STATIC_UPDATE_REQUEST:
            return { ...state, contentmanagementUpdate: { contentmanagement: {}, error: '', isLoading: true } };
        case CONTENTMANAGEMENT_STATIC_UPDATE_SUCCESS:
            return { ...state, contentmanagementUpdate: { contentmanagement: action.payload, error: '', isLoading: false } };
        case CONTENTMANAGEMENT_STATIC_UPDATE_FAIL:
            return { ...state, contentmanagementUpdate: { contentmanagement: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}