import { nodeGetApi } from './commonAxios';
import config from './config';

export const GIFT_HISTORY_REQUEST = "GIFT_HISTORY_REQUEST";
export const GIFT_HISTORY_SUCCESS = "GIFT_HISTORY_SUCCESS";
export const GIFT_HISTORY_FAIL = "GIFT_HISTORY_FAIL";
export const GIFT_HISTORY_GET_REQUEST = "GIFT_HISTORY_GET_REQUEST";
export const GIFT_HISTORY_GET_FAIL = "GIFT_HISTORY_GET_FAIL";
export const GIFT_HISTORY_GET_SUCCESS = "GIFT_HISTORY_GET_SUCCESS";

/*=============================================================
             Get All User gift history Action
 ===============================================================*/
export const getAllUsergifthistoryAction = formData => async dispatch => {
  try {
    dispatch({
      type: GIFT_HISTORY_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Gift_History/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=GIFT_HISTORY_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: GIFT_HISTORY_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: GIFT_HISTORY_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: GIFT_HISTORY_FAIL,
      payload: err
    });
  }
};

// Get User Gift Action
export const getSingleUserGift = (formData, callBackUserGiftData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Gift_History/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=NAME&SortOrder=ASC`, formData);
    if (data) {
      callBackUserGiftData(data)
    }
  } catch (err) {
  }
};