import {
    ALL_DELIVERY_COMMENTS_REQUEST,
    ALL_DELIVERY_COMMENTS_SUCCESS,
    ALL_DELIVERY_COMMENTS_FAIL,

    DELIVERY_COMMENTS_CREATE_REQUEST,
    DELIVERY_COMMENTS_CREATE_SUCCESS,
    DELIVERY_COMMENTS_CREATE_FAIL,

    DELIVERY_COMMENTS_UPDATE_REQUEST,
    DELIVERY_COMMENTS_UPDATE_SUCCESS,
    DELIVERY_COMMENTS_UPDATE_FAIL,
} from '../actions/deliverycommentsActions'

const initialState = {
    deliverycomments: { all: [], error: '', isLoading: false },
    deliverycomment: { deliverycomment: {}, error: '', isLoading: false },
    deliverycommentCreate: { deliverycomment: {}, error: '', isLoading: false },
    deliverycommentUpdate: { deliverycomment: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case ALL_DELIVERY_COMMENTS_REQUEST:
            return { ...state, deliverycomment: { deliverycomment: {}, error: '', isLoading: true } };
        case ALL_DELIVERY_COMMENTS_SUCCESS:
            return { ...state, deliverycomment: { deliverycomment: action.payload, error: '', isLoading: false } };
        case ALL_DELIVERY_COMMENTS_FAIL:
            return { ...state, deliverycomment: { deliverycomment: {}, error: action.payload, isLoading: false } };
        case DELIVERY_COMMENTS_CREATE_REQUEST:
            return { ...state, deliverycommentCreate: { deliverycomment: {}, error: '', isLoading: true } };
        case DELIVERY_COMMENTS_CREATE_SUCCESS:
            return { ...state, deliverycommentCreate: { deliverycomment: state.deliverycommentCreate.deliverycomment, error: '', isLoading: false } };
        case DELIVERY_COMMENTS_CREATE_FAIL:
            return { ...state, deliverycommentCreate: { deliverycomment: {}, error: action.payload, isLoading: false } };
        case DELIVERY_COMMENTS_UPDATE_REQUEST:
            return { ...state, deliverycommentUpdate: { deliverycomment: {}, error: '', isLoading: true } };
        case DELIVERY_COMMENTS_UPDATE_SUCCESS:
            return { ...state, deliverycommentUpdate: { deliverycomment: state.deliverycommentUpdate.deliverycomment, error: '', isLoading: false } };
        case DELIVERY_COMMENTS_UPDATE_FAIL:
            return { ...state, deliverycommentUpdate: { deliverycomment: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}