import {
  CITYPARTNERDEAL_REQUEST,
  CITYPARTNERDEAL_SUCCESS,
  CITYPARTNERDEAL_FAIL,
  CITYPARTNERDEAL_GET_REQUEST,
  CITYPARTNERDEAL_GET_SUCCESS,
  CITYPARTNERDEAL_GET_FAIL,
  CITYPARTNERDEAL_CREATE_REQUEST,
  CITYPARTNERDEAL_CREATE_SUCCESS,
  CITYPARTNERDEAL_CREATE_FAIL,
  CITYPARTNERDEAL_UPDATE_REQUEST,
  CITYPARTNERDEAL_UPDATE_SUCCESS,
  CITYPARTNERDEAL_UPDATE_FAIL,

  CITYPARTNERDEAL_DEALID_REQUEST,
  CITYPARTNERDEAL_DEALID_SUCCESS,
  CITYPARTNERDEAL_DEALID_FAIL,
  CITYPARTNERDEAL_SEARCH_REQUEST,
  CITYPARTNERDEAL_SEARCH_SUCCESS,
  CITYPARTNERDEAL_SEARCH_FAIL,

} from '../actions/citypartnerdealAction'

const initialState = {
  citypartnerdeals: { all: [], error: '', isLoading: false },
  citypartnerdeal: { citypartnerdeal: {}, error: '', isLoading: false },
  citypartnerdealCreate: { citypartnerdeal: {}, error: '', isLoading: false },
  citypartnerdealUpdate: { citypartnerdeal: {}, error: '', isLoading: false },
  citypartnerdealsearch: { dealsearch: [], error: '', isLoading: false },
  citypartnerdealids: { dealids: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CITYPARTNERDEAL_REQUEST:
      return { ...state, citypartnerdeals: { all: state.citypartnerdeals.all, error: '', isLoading: true } };
    case CITYPARTNERDEAL_SUCCESS:
      return { ...state, citypartnerdeals: { all: action.payload, error: '', isLoading: false } };
    case CITYPARTNERDEAL_FAIL:
      return { ...state, citypartnerdeals: { all: [], error: action.payload, isLoading: false } };
    case CITYPARTNERDEAL_GET_REQUEST:
      return { ...state, citypartnerdeal: { citypartnerdeal: {}, error: '', isLoading: true } };
    case CITYPARTNERDEAL_GET_SUCCESS:
      return { ...state, citypartnerdeal: { citypartnerdeal: action.payload, error: '', isLoading: false } };
    case CITYPARTNERDEAL_GET_FAIL:
      return { ...state, citypartnerdeal: { citypartnerdeal: {}, error: action.payload, isLoading: false } };
    case CITYPARTNERDEAL_CREATE_REQUEST:
      return { ...state, citypartnerdealCreate: { citypartnerdeal: {}, error: '', isLoading: true } };
    case CITYPARTNERDEAL_CREATE_SUCCESS:
      return { ...state, citypartnerdealCreate: { citypartnerdeal: action.payload, error: '', isLoading: false } };
    case CITYPARTNERDEAL_CREATE_FAIL:
      return { ...state, citypartnerdealCreate: { citypartnerdeal: {}, error: action.payload, isLoading: false } };
    case CITYPARTNERDEAL_UPDATE_REQUEST:
      return { ...state, citypartnerdealUpdate: { citypartnerdeal: {}, error: '', isLoading: true } };
    case CITYPARTNERDEAL_UPDATE_SUCCESS:
      return { ...state, citypartnerdealUpdate: { citypartnerdeal: action.payload, error: '', isLoading: false } };
    case CITYPARTNERDEAL_UPDATE_FAIL:
      return { ...state, citypartnerdealUpdate: { citypartnerdeal: {}, error: action.payload, isLoading: false } };
    case CITYPARTNERDEAL_SEARCH_REQUEST:
      return { ...state, citypartnerdealsearch: { dealsearch: state.citypartnerdealsearch.dealsearch, error: '', isLoading: true }, };
    case CITYPARTNERDEAL_SEARCH_SUCCESS:
      return { ...state, citypartnerdealsearch: { dealsearch: action.payload, error: '', isLoading: false } };
    case CITYPARTNERDEAL_SEARCH_FAIL:
      return { ...state, citypartnerdealsearch: { dealsearch: [], error: action.payload, isLoading: false } };

    case CITYPARTNERDEAL_DEALID_REQUEST:
      return { ...state, citypartnerdealids: { dealids: state.citypartnerdealids.dealids, error: '', isLoading: true }, };
    case CITYPARTNERDEAL_DEALID_SUCCESS:
      return { ...state, citypartnerdealids: { dealids: action.payload, error: '', isLoading: false } };
    case CITYPARTNERDEAL_DEALID_FAIL:
      return { ...state, citypartnerdealids: { dealids: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}
