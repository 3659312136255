
import config from './config';
import { toast } from 'react-toastify';
import { paginationLocationCityGet } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const PARTNER_CITY_LOCATION_REQUEST = 'PARTNER_CITY_LOCATION_REQUEST';
export const PARTNER_CITY_LOCATION_SUCCESS = "PARTNER_CITY_LOCATION_SUCCESS";
export const PARTNER_CITY_LOCATION_FAIL = "PARTNER_CITY_LOCATION_FAIL";
export const CITY_LOCATION_REQUEST = 'CITY_LOCATION_REQUEST';
export const CITY_LOCATION_SUCCESS = "CITY_LOCATION_SUCCESS";
export const CITY_LOCATION_FAIL = "CITY_LOCATION_FAIL";
export const PARTNER_CITY_LOCATION_GET_REQUEST = "PARTNER_CITY_LOCATION_GET_REQUEST";
export const PARTNER_CITY_LOCATION_GET_SUCCESS = "PARTNER_CITY_LOCATION_GET_SUCCESS";
export const PARTNER_CITY_LOCATION_GET_FAIL = "PARTNER_CITY_LOCATION_GET_FAIL";
export const PARTNER_CITY_LOCATION_CREATE_REQUEST = "PARTNER_CITY_LOCATION_CREATE_REQUEST";
export const PARTNER_CITY_LOCATION_CREATE_SUCCESS = "PARTNER_CITY_LOCATION_CREATE_SUCCESS";
export const PARTNER_CITY_LOCATION_CREATE_FAIL = "PARTNER_CITY_LOCATION_CREATE_FAIL";
export const PARTNER_CITY_LOCATION_UPDATE_REQUEST = "PARTNER_CITY_LOCATION_UPDATE_REQUEST";
export const PARTNER_CITY_LOCATION_UPDATE_SUCCESS = "PARTNER_CITY_LOCATION_UPDATE_SUCCESS";
export const PARTNER_CITY_LOCATION_UPDATE_FAIL = "PARTNER_CITY_LOCATION_UPDATE_FAIL";

/*=============================================================
                  Add PartnerCity Action
===============================================================*/


export const addPartnerCitylocationAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: PARTNER_CITY_LOCATION_CREATE_REQUEST
  });
  nodeCudApi.post(`/Shop_by_Location_in_City/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNER_CITY_LOCATION_CREATE_SUCCESS
        });
        toast('Partner City Location added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partner-city-location', state = { state });
      } else {
        dispatch({
          type: PARTNER_CITY_LOCATION_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_CITY_LOCATION_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};

/*=============================================================
                   Get All PartnerCity Action
===============================================================*/
export const getAllPartnerCitylocations = formData => async dispatch => {
  try {
    dispatch({
      type: PARTNER_CITY_LOCATION_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Shop_by_Location_in_City/Get?PageNo=0&PageSize=${config.pageSize}&SortBy=LOCATION_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNER_CITY_LOCATION_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNER_CITY_LOCATION_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PARTNER_CITY_LOCATION_FAIL,
      payload: err
    });
  }
};

//node API
export const getAllCitylocationsid = formData => async dispatch => {
  try {
    dispatch({
      type: CITY_LOCATION_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Location_For_City/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=LOCATION_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CITY_LOCATION_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CITY_LOCATION_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CITY_LOCATION_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  Delete PartnerCity Action
===============================================================*/
export const deletePartnerCitylocation = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(
      `/Shop_by_Location_in_City/Delete`,
      formData
    );
    if (data) {
      toast('Partner City Location deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationLocationCityGet(filters, pagination, callBackPagination));
    }
  } catch (err) { }
};

/*=============================================================
                Update PartnerCity Action
===============================================================*/
export const updatePartnerCitylocation = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: PARTNER_CITY_LOCATION_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Shop_by_Location_in_City/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNER_CITY_LOCATION_UPDATE_SUCCESS
        });
        toast('Partner City Location updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partner-city-location', state = { state });
      } else {
        dispatch({
          type: PARTNER_CITY_LOCATION_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_CITY_LOCATION_UPDATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};

//Get Partner Service city location Action
export const getPartnerServiceCityAction = (formData, callBackPartnerServiceCityData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Shop_by_Location_in_City/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=LOCATION_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBackPartnerServiceCityData(data)
    }
  } catch (err) {
  }
};