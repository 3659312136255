import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../store/validations/is-empty';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import SimpleDialog from '../../components/dialogs/dialog';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Partner_Service_City_Coupon_Id', numeric: false, disablePadding: false, label: 'Partner Service City Coupon ID' },
  { id: 'Coupon_Name', numeric: false, disablePadding: false, label: 'Coupon Name' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allCitypartnercoupons,
  deleteCitypartnercouponHandler,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allCitypartnercouponsNoFilter,
  state,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [selectedCitypartnercouponIds, setSelectedCitypartnercouponIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedCitypartnercouponIds;
    if (event.target.checked) {
      newSelectedCitypartnercouponIds = allCitypartnercoupons.map(citypartnercoupon => citypartnercoupon.Partner_Service_City_Coupon_Id);
    } else {
      newSelectedCitypartnercouponIds = [];
    }
    setSelectedCitypartnercouponIds(newSelectedCitypartnercouponIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCitypartnercouponIds.indexOf(id);
    let newSelectedCitypartnercouponIds = [];
    if (selectedIndex === -1) {
      newSelectedCitypartnercouponIds = newSelectedCitypartnercouponIds.concat(selectedCitypartnercouponIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCitypartnercouponIds = newSelectedCitypartnercouponIds.concat(
        selectedCitypartnercouponIds.slice(1)
      );
    } else if (selectedIndex === selectedCitypartnercouponIds.length - 1) {
      newSelectedCitypartnercouponIds = newSelectedCitypartnercouponIds.concat(
        selectedCitypartnercouponIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCitypartnercouponIds = newSelectedCitypartnercouponIds.concat(
        selectedCitypartnercouponIds.slice(0, selectedIndex),
        selectedCitypartnercouponIds.slice(selectedIndex + 1)
      );
    }
    setSelectedCitypartnercouponIds(newSelectedCitypartnercouponIds);
  };

  const deleteSelectedCitypartnercouponHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const editCitypartnercouponHandler = citypartnercouponData => e => {
    navigate('/app/sbcm/edit-coupon', { state: { state: state, data: citypartnercouponData } });
  };

  const viewCitypartnercouponHandler = citypartnercouponData => e => {
    navigate('/app/view-partnercitycoupon', { state: { state: state, data: citypartnercouponData } });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCitypartnercouponIds.filter(record => {
                      for (let i = 0; i < allCitypartnercouponsNoFilter.length; i++) {
                        if (record === allCitypartnercouponsNoFilter[i].Partner_Service_City_Coupon_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allCitypartnercoupons.length}
                    color="primary"
                    indeterminate={
                      selectedCitypartnercouponIds.filter(record => {
                        for (let i = 0; i < allCitypartnercouponsNoFilter.length; i++) {
                          if (record === allCitypartnercouponsNoFilter[i].Partner_Service_City_Coupon_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedCitypartnercouponIds.filter(record => {
                        for (let i = 0; i < allCitypartnercouponsNoFilter.length; i++) {
                          if (record === allCitypartnercouponsNoFilter[i].Partner_Service_City_Coupon_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allCitypartnercoupons.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedCitypartnercouponIds.filter(record => {
                    for (let i = 0; i < allCitypartnercouponsNoFilter.length; i++) {
                      if (record === allCitypartnercouponsNoFilter[i].Partner_Service_City_Coupon_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedCitypartnercouponIds.filter(record => {
                    for (let i = 0; i < allCitypartnercouponsNoFilter.length; i++) {
                      if (record === allCitypartnercouponsNoFilter[i].Partner_Service_City_Coupon_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedCitypartnercouponHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allCitypartnercoupons) &&
                (allCitypartnercoupons).map(citypartnercoupon => (
                  <TableRow
                    hover
                    key={citypartnercoupon.Partner_Service_City_Coupon_Id}
                    selected={
                      selectedCitypartnercouponIds.indexOf(citypartnercoupon.Partner_Service_City_Coupon_Id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedCitypartnercouponIds.indexOf(citypartnercoupon.Partner_Service_City_Coupon_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, citypartnercoupon.Partner_Service_City_Coupon_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell >{citypartnercoupon.Partner_Service_City_Coupon_Id}</TableCell>
                    <TableCell style={{ overflowX: "hidden" }}>
                      <span title={citypartnercoupon.Coupon_Name}>{citypartnercoupon.Coupon_Name?.length > 50 ? citypartnercoupon.Coupon_Name?.substring(0, 50) : citypartnercoupon.Coupon_Name}</span>
                    </TableCell>
                    <TableCell >{citypartnercoupon.Status}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.City_Coupon_Management_Write?.Value === "City_Coupon_Management_Write" && RolePermissions?.City_Coupon_Management_Write?.Is_Write === 1) ?
                      <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                          onClick={editCitypartnercouponHandler(citypartnercoupon)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                          <DeleteIcon
                            onClick={deleteCitypartnercouponHandler(citypartnercoupon)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                        <Button
                          onClick={viewCitypartnercouponHandler(citypartnercoupon)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.City_Coupon_Management_Read?.Value === "City_Coupon_Management_Read" && RolePermissions?.City_Coupon_Management_Read?.Is_Read === 1 &&
                      <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                          onClick={viewCitypartnercouponHandler(citypartnercoupon)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allCitypartnercoupons) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allCitypartnercoupons: PropTypes.array.isRequired
};

export default Results;