import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  EditPopupAction
} from './../../store/actions/popupAction';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: 120,
    height: 120,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditPopup = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  let { state } = location;

  const errorMessage = useSelector(state => state.popup.popupUpdate.error);
  const isSubmited = useSelector(state => state.popup.popupUpdate.isLoading);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Content || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Content);
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
  // Richtext editor (pages)
  const contentStatePage = ContentState.createFromBlockArray(htmlToDraft(state.Pages || "").contentBlocks);
  const [editorStatePage, setEditorStatePage] = useState(EditorState.createWithContent(contentStatePage));
  const [rhDiscriptionPage, setRhDiscriptionPage] = useState(state.Pages);
  const [rhDiscriptionPageMin, setRhDiscriptionPagesMin] = useState(20);
  const onEditorStateChangePage = (editorStatePage) => {
    setEditorStatePage(editorStatePage);
    setRhDiscriptionPage(draftToHtml(convertToRaw(editorStatePage.getCurrentContent())));
  };

  return (
    <Formik
      initialValues={{
        Popup_Id: state.Popup_Id ? state.Popup_Id : "",
        Title: state.Title ? state.Title : "",
        Content: state.Content ? state.Content : "",
        Pages: state.Pages ? state.Pages : "",
        Modified_By: modifiedBy,
        Status: state.Status ? state.Status : "",
      }}
      validationSchema={Yup.object().shape({
        Title: Yup.string().required('Title is required.'),
        Status: Yup.string().required('Status is required.'),
      })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        values.Content = rhDiscription;
        values.Pages = rhDiscriptionPage;

        if (!values.Content || values.Content.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Content = "Content is required.";
        }
        else if (values.Content.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Content = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
        if (!values.Pages || values.Pages.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Pages = "Page Description is required.";
        }
        else if (values.Pages.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionPageMin) {
          submitTest = false;
          errors.Pages = "Please provide at least " + rhDiscriptionPageMin + " characters.";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(EditPopupAction(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    {/* Title*/}
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Title && errors.Title)}
                        fullWidth
                        helperText={touched.Title && errors.Title}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Title"
                        name="Title"
                        value={values.Title}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Content Description  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Content
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Content && errors.Content}
                      </span>
                    </Grid>
                    {/* page  Description  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Page Description
                        <EditorContainer editorState={editorStatePage} onEditorStateChange={onEditorStateChangePage} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscriptionPage || rhDiscriptionPage.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionPageMin) && touched.Pages && errors.Pages}
                      </span>
                    </Grid>

                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditPopup.propTypes = {
  className: PropTypes.string
};

export default EditPopup;