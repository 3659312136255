import { toast } from 'react-toastify';
import { nodeCorpMongoApi } from './commonAxios';
import { paginationAllCorpNEGet } from './node-actions/nodepagination';

export const CORPORATE_NE_CREATE_REQUEST = "CORPORATE_NE_CREATE_REQUEST";
export const CORPORATE_NE_CREATE_SUCCESS = "CORPORATE_NE_CREATE_SUCCESS";
export const CORPORATE_NE_CREATE_FAIL = "CORPORATE_NE_CREATE_FAIL";

export const CORPORATE_NE_UPDATE_REQUEST = "CORPORATE_NE_UPDATE_REQUEST";
export const CORPORATE_NE_UPDATE_SUCCESS = "CORPORATE_NE_UPDATE_SUCCESS";
export const CORPORATE_NE_UPDATE_FAIL = "CORPORATE_NE_UPDATE_FAIL";
export const RESET = "RESET";

export const invalid_data = () => {
    return {
        type: RESET
    }
}

export const addcorporateNEAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CORPORATE_NE_CREATE_REQUEST
    });
    nodeCorpMongoApi.post(`/newsAndEvents/create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.code === 201 && successResponse?.message === "news and events created successfully") {
                dispatch({
                    type: CORPORATE_NE_CREATE_SUCCESS
                });
                toast('Corporate news and events added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corporate-newsandevents-management');
            } else {
                dispatch({
                    type: CORPORATE_NE_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CORPORATE_NE_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

export const deletecorporateNEById = (formData, filters, pagination, callBackPagination) => async dispatch => {
    try {
        let { data } = await nodeCorpMongoApi.post(`/newsAndEvents/delete`, formData);
        if (data) {
            toast('Corporate news and events deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            dispatch(paginationAllCorpNEGet(filters, pagination, callBackPagination));
        }
    } catch (err) {
    }
};

export const updatecorporateNEAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CORPORATE_NE_UPDATE_REQUEST
    });
    nodeCorpMongoApi.post(`/newsAndEvents/update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.code === 200 && successResponse?.message === "News and Events Updated Successfully.") {
                dispatch({
                    type: CORPORATE_NE_UPDATE_SUCCESS
                });
                toast('Corporate news and events updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corporate-newsandevents-management');
            } else {
                dispatch({
                    type: CORPORATE_NE_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CORPORATE_NE_UPDATE_FAIL,
                payload: "Please try again later.Currently server is not working"
            });
        })
};