import config from './config';
import { toast } from 'react-toastify';
import { paginationPartnerServiceCity } from './node-actions/nodepagination';
import { boomiAdminApi, nodeCudApi, nodeGetApi } from './commonAxios';

export const CITYPARTNER_CREATE_REQUEST = "CITYPARTNER_CREATE_REQUEST";
export const CITYPARTNER_CREATE_SUCCESS = "CITYPARTNER_CREATE_SUCCESS";
export const CITYPARTNER_CREATE_FAIL = "CITYPARTNER_CREATE_FAIL";
export const CITYPARTNER_UPDATE_REQUEST = "CITYPARTNER_UPDATE_REQUEST";
export const CITYPARTNER_UPDATE_SUCCESS = "CITYPARTNER_UPDATE_SUCCESS";
export const CITYPARTNER_UPDATE_FAIL = "CITYPARTNER_UPDATE_FAIL";
export const CITYPARTNER_PARTNERID_REQUEST = "CITYPARTNER_PARTNERID_REQUEST";
export const CITYPARTNER_PARTNERID_SUCCESS = "CITYPARTNER_PARTNERID_SUCCESS";
export const CITYPARTNER_PARTNERID_FAIL = "CITYPARTNER_PARTNERID_FAIL";
export const CITYPARTNER_CITYNAMES_REQUEST = "CITYPARTNER_CITYNAMES_REQUEST";
export const CITYPARTNER_CITYNAMES_SUCCESS = "CITYPARTNER_CITYNAMES_SUCCESS";
export const CITYPARTNER_CITYNAMES_FAIL = "CITYPARTNER_CITYNAMES_FAIL";

/*=============================================================
                  Add city partner Action
===============================================================*/
export const createCityPartnerAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: CITYPARTNER_CREATE_REQUEST
  });
  boomiAdminApi.post(`/Partner_Serevice_City_&_Location/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: CITYPARTNER_CREATE_SUCCESS
        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/sbcm/city-partner-management');
      } else {
        dispatch({
          type: CITYPARTNER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: CITYPARTNER_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Update city partner  Action
 ===============================================================*/
export const updateCityPartnerAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: CITYPARTNER_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Shop_By_City/Partner_Service_City_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: CITYPARTNER_UPDATE_SUCCESS
        });
        toast('City partner updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/sbcm/city-partner-management');
      } else {
        dispatch({
          type: CITYPARTNER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: CITYPARTNER_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
              Delete City partner Action
 ===============================================================*/
export const deleteCityPartnerAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Shop_By_City/Partner_Service_City_Delete`, formData);
    if (data) {
      toast('City partner deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationPartnerServiceCity(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

/*=============================================================
                City Image Upload Action
===============================================================*/
export const uploadCityImage = (
  formData,
  callBackUploadImgae,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=Cities&fileextension=png&filetype=Images&filename=City`, formData);
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};

/*=============================================================
             CITYPARTNER_PARTNERID_REQUEST Action
===============================================================*/
export const getAllPartnerids = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: 'FILTER',
      Status: "Active"
    };
    dispatch({
      type: CITYPARTNER_PARTNERID_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Partners/Physical_Partner_Profile_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.successResponse?.Is_Data_Exist === '0') {
        dispatch({
          type: CITYPARTNER_PARTNERID_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CITYPARTNER_PARTNERID_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CITYPARTNER_PARTNERID_FAIL,
      payload: JSON.stringify(err)
    });
  }
};

//node API
export const getAllPartnercitynames = (formData) => async dispatch => {
  try {
    dispatch({
      type: CITYPARTNER_CITYNAMES_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin_City/Super_Admin_City_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=CITY_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CITYPARTNER_CITYNAMES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CITYPARTNER_CITYNAMES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CITYPARTNER_CITYNAMES_FAIL,
      payload: JSON.stringify(err)
    });
  }
};

//Node API
export const getServiceCityPartnerAction = (formData, callBackServiceCityPartnerData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Shop_By_City/Partner_Service_City_Get`, formData);
    if (data) {
      callBackServiceCityPartnerData(data)
    }
  } catch (err) {
  }
};