import { toast } from 'react-toastify';
import { nodeCudApi } from './commonAxios';
import { paginationBusiServicesSubtypesGet } from './node-actions/nodepagination';

export const BUSSINESS_SUBTYPE_CREATE_REQUEST = "BUSSINESS_SUBTYPE_CREATE_REQUEST";
export const BUSSINESS_SUBTYPE_CREATE_SUCCESS = "BUSSINESS_SUBTYPE_CREATE_SUCCESS";
export const BUSSINESS_SUBTYPE_CREATE_FAIL = "BUSSINESS_SUBTYPE_CREATE_FAIL";
export const BUSSINESS_SUBTYPE_UPDATE_REQUEST = "BUSSINESS_SUBTYPE_UPDATE_REQUEST";
export const BUSSINESS_SUBTYPE_UPDATE_SUCCESS = "BUSSINESS_SUBTYPE_UPDATE_SUCCESS";
export const BUSSINESS_SUBTYPE_UPDATE_FAIL = "BUSSINESS_SUBTYPE_UPDATE_FAIL";

/*=============================================================
                  Add Bussiness Subtype Action
===============================================================*/
export const createBussinessSubTypeAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
    dispatch({
        type: BUSSINESS_SUBTYPE_CREATE_REQUEST
    });
    setErrorMessage({ Business_Sub_Type: { key: '', message: '' }, global: { key: '', message: '' } });
    nodeCudApi.post(`/Super_Admin_Partner_Business_Type/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: BUSSINESS_SUBTYPE_CREATE_SUCCESS
                });
                toast('Bussiness SubType added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/bussiness-subtype');
            } else {
                const errors = {};
                if (successResponse.Business_Sub_Type) {
                    errors.Business_Sub_Type = successResponse.UI_Display_Message;
                }
                setErrors(errors)
                setErrorMessage({ Business_Sub_Type: { key: successResponse.Business_Sub_Type ? successResponse.Business_Sub_Type : '', message: successResponse.Business_Sub_Type ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Business_Sub_Type) ? successResponse.UI_Display_Message : '' } });
                dispatch({
                    type: BUSSINESS_SUBTYPE_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            setErrorMessage({ Business_Sub_Type: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
            dispatch({
                type: BUSSINESS_SUBTYPE_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

/*=============================================================
               Delete  BUSSINESS SUBTYPE Action
===============================================================*/
export const deleteBussinessSubTypeAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
    try {
        let { data } = await nodeCudApi.post(`/Super_Admin_Partner_Business_Type/Delete`, formData);
        if (data) {
            toast('Bussiness SubType deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            dispatch(paginationBusiServicesSubtypesGet(filters, pagination, callBackPagination));
        }
    } catch (err) {
    }
};

/*=============================================================
                   BUSSINESS SUBTYPE Action
===============================================================*/
export const updateBussinessSubTypeAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: BUSSINESS_SUBTYPE_UPDATE_REQUEST
    });
    nodeCudApi.post(`/Super_Admin_Partner_Business_Type/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: BUSSINESS_SUBTYPE_UPDATE_SUCCESS
                });
                toast('Bussiness SubType updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/bussiness-subtype');
            } else {
                dispatch({
                    type: BUSSINESS_SUBTYPE_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: BUSSINESS_SUBTYPE_UPDATE_FAIL,
                payload: "Please try again later.Currently server is not working"
            });
        })
};