
import config from './config';
import { toast } from 'react-toastify';
import { paginationAllPartnersGet } from './node-actions/nodepagination';
import { nodeGetApi, boomiAdminApi } from './commonAxios';

export const PARTNERS_REQUEST = "PARTNERS_REQUEST";
export const PARTNERS_SUCCESS = "PARTNERS_SUCCESS";
export const PARTNERS_FAIL = "PARTNERS_FAIL";

export const PARTNER_CREATE_REQUEST = "PARTNER_CREATE_REQUEST";
export const PARTNER_CREATE_SUCCESS = "PARTNER_CREATE_SUCCESS";
export const PARTNER_CREATE_FAIL = "PARTNER_CREATE_FAIL";
export const PARTNER_UPDATE_REQUEST = "PARTNER_UPDATE_REQUEST";
export const PARTNER_UPDATE_SUCCESS = "PARTNER_UPDATE_SUCCESS";
export const PARTNER_UPDATE_FAIL = "PARTNER_UPDATE_FAIL";
export const ONLINEPARTNER_CREATE_REQUEST = "ONLINEPARTNER_CREATE_REQUEST";
export const ONLINEPARTNER_CREATE_SUCCESS = "ONLINEPARTNER_CREATE_SUCCESS";
export const ONLINEPARTNER_CREATE_FAIL = "ONLINEPARTNER_CREATE_FAIL";
export const ONLINEPARTNER_UPDATE_REQUEST = "ONLINEPARTNER_UPDATE_REQUEST";
export const ONLINEPARTNER_UPDATE_SUCCESS = "ONLINEPARTNER_UPDATE_SUCCESS";
export const ONLINEPARTNER_UPDATE_FAIL = "ONLINEPARTNER_UPDATE_FAIL";
export const PHYSICALPARTNER_CREATE_REQUEST = "PHYSICALPARTNER_CREATE_REQUEST";
export const PHYSICALPARTNER_CREATE_SUCCESS = "PHYSICALPARTNER_CREATE_SUCCESS";
export const PHYSICALPARTNER_CREATE_FAIL = "PHYSICALPARTNER_CREATE_FAIL";
export const PHYSICALPARTNER_UPDATE_REQUEST = "PHYSICALPARTNER_UPDATE_REQUEST";
export const PHYSICALPARTNER_UPDATE_SUCCESS = "PHYSICALPARTNER_UPDATE_SUCCESS";
export const PHYSICALPARTNER_UPDATE_FAIL = "PHYSICALPARTNER_UPDATE_FAIL";

export const PARTNERS_APPROVE_REQUEST = "PARTNERS_APPROVE_REQUEST";
export const PARTNERS_APPROVE_SUCCESS = "PARTNERS_APPROVE_SUCCESS";
export const PARTNERS_APPROVE_FAIL = "PARTNERS_APPROVE_FAIL";

/*=============================================================
                Add Online Partner Action  
===============================================================*/

export const addOnlinePartnerAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: ONLINEPARTNER_CREATE_REQUEST
  });
  setErrorMessage({ global: { key: '', message: '' } });
  boomiAdminApi.post(`/Partners/Online_Profile_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: ONLINEPARTNER_CREATE_SUCCESS
        });
        toast('Online partner added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partner-management');
      } else {
        const errors = {};
        setErrors(errors)
        setErrorMessage({ global: { key: '', message: successResponse.UI_Display_Message ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: ONLINEPARTNER_CREATE_FAIL,

        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }
    })
    .catch(error => {
      dispatch({
        type: ONLINEPARTNER_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};
/*=============================================================
           Delete Partner ById Action
===============================================================*/
export const deletePhysicalPartnerById = (formData, Type, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/Partners/Physical_Partner_Profile_Delete`, formData
      // Axios looks for the `auth` option, and, if it is set, formats a
      // basic auth header for you automatically.
    );
    if (data) {
      toast(Type + " " + 'partner deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationAllPartnersGet(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

/*=============================================================
            Update Online Partner By Id Action
===============================================================*/
export const updateOnlinePartner = (formData, navigate) => async dispatch => {
  dispatch({
    type: ONLINEPARTNER_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Partners/Online_Profile_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: ONLINEPARTNER_UPDATE_SUCCESS
        });
        toast('Online partner updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partner-management');
      } else {
        dispatch({
          type: ONLINEPARTNER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: ONLINEPARTNER_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*========================================================================================================
                                            add  Physical Partner Action
==========================================================================================================*/
export const addPhysicalPartnerAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: PHYSICALPARTNER_CREATE_REQUEST
  });
  setErrorMessage({ global: { key: '', message: '' } });
  boomiAdminApi.post(`/Partners/Physical_Partner_Profile_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PHYSICALPARTNER_CREATE_SUCCESS
        });
        toast('Physical partner added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partner-management');
      } else {
        const errors = {};
        setErrors(errors)
        setErrorMessage({ global: { key: '', message: successResponse.UI_Display_Message ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: PHYSICALPARTNER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PHYSICALPARTNER_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};
/*=============================================================
            Update Physical Partner By Id Action
===============================================================*/
export const updatePhysicalPartner = (formData, navigate) => async dispatch => {
  dispatch({
    type: PHYSICALPARTNER_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Partners/Physical_Partner_Profile_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PHYSICALPARTNER_UPDATE_SUCCESS
        });
        toast('Physical partner updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partner-management');
      } else {
        dispatch({
          type: PHYSICALPARTNER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })

    .catch(error => {
      dispatch({
        type: PHYSICALPARTNER_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
        Get All Online and Physical Partner Action
===============================================================*/
export const getAllOnlineAndPhysicalPartner = (formData) => async dispatch => {
  try {
    dispatch({
      type: PARTNERS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partners/OnlineAndPhysical_Details_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNERS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PARTNERS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
              partner logo Image Upload Action
===============================================================*/
export const uploadPartnerlogoImage = (
  formData,
  callBackUploadImgae,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=PartnerLogo&fileextension=png&filetype=Images&filename=PartnerLogo`,
      formData
    );
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};


/*=============================================================
                partner banner Image Upload Action
===============================================================*/
export const uploadPartnerbannerImage = (
  formData,
  callBackUploadImgae1,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=PartnerBanner&fileextension=png&filetype=Images&filename=PartnerBanner`,
      formData
    );
    if (data) {
      callBackUploadImgae1(data);
    }
  } catch (err) { }
};

/*=============================================================
               Get All Approval Partner Action
===============================================================*/
export const getAllApprovalPartnerAction = formData => async dispatch => {
  try {
    dispatch({
      type: PARTNERS_APPROVE_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partners/OnlineAndPhysical_Details_Get_Admin?PageNo=0&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNERS_APPROVE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNERS_APPROVE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PARTNERS_APPROVE_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Update approved partner Action
===============================================================*/
export const updatePartnerApprovalAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  dispatch({
    type: PARTNER_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Partners/Partner_Approve_Flow`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNER_UPDATE_SUCCESS
        });
        toast('Partner has been Accepted.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        dispatch(paginationAllPartnersGet(filters, pagination, callBackPagination));
      } else {
        const errors = {};
        dispatch({
          type: PARTNER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_UPDATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};

//  Get Single Partner Action
export const getSinglePartnerAction = (formData, callBackPartnerData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Partners/OnlineAndPhysical_Details_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Partner_Details_Id&SortOrder=ASC`,
      formData

    );
    if (data) {
      callBackPartnerData(data)
    }
  } catch (err) {
  }
};

export const getpartnerbusinesstype = (formData, callBacktypeData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Partner_Registraton/Partner_Business_Type_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=BUSINESS_TYPE&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBacktypeData(data.results)
    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};

export const getbussinessubtypeAction = (formData, callBacksubtypeData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Partner_Registraton/Partner_Business_Type_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=BUSINESS_TYPE&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBacksubtypeData(data.results[0].Business_Sub_Types)
    }
  } catch (err) { }
};

//upload pan image
export const uploadPartnerPanImage = (
  formData,
  callBackUploadPanImage,
  partnerId
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=PartnerPan&fileextension=png&filetype=Images&filename=PartnerPan`,
      formData
    );
    if (data) {
      callBackUploadPanImage(data);
    }
  } catch (err) { }
};


//upload gst image
export const uploadPartnerGstImage = (
  formData,
  callBackUploadGstImage,
  partnerId
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=PartnerGst&fileextension=png&filetype=Images&filename=PartnerGst`,
      formData
    );
    if (data) {
      callBackUploadGstImage(data);
    }
  } catch (err) { }
};

//  Get Single Partner Action
export const getPendingPartnerAction = (formData, callBackPendingPartnerData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Partners/OnlineAndPhysical_Details_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Partner_Details_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBackPendingPartnerData(data)
    }
  } catch (err) {
  }
};