import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useDispatch, useSelector } from 'react-redux';
import {
  cashbackCount
} from '../../../../store/actions/reports/dashboardActions';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  },
  differenceValueFail: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  },
  differenceIconSucc: {
    color: colors.green[900]
  },
  differenceValueSucc: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const Cashback = ({ className, RolePermissions, setSeconds, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector(state => state.dash.Cashback.data);
  let Total_Cashback_Hide = RolePermissions?.Total_Cashback_Hide?.Is_Hide

  // componentDidMount
  useEffect(() => {
    dispatch(cashbackCount());
    setSeconds(5);
    if (RolePermissions?.Total_Cashback_Hide?.Value === "Total_Cashback_Hide" && RolePermissions?.Total_Cashback_Hide?.Is_Hide === 1) {
      Total_Cashback_Hide = RolePermissions?.Total_Cashback_Hide?.Is_Hide
    }
  }, []);

  return (
    <>
      {Total_Cashback_Hide !== 1 &&
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                >
                  TOTAL CASHBACK
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="h3"
                >
                  ₹{data.Total ? Math.round(data.Total * Math.pow(10, 2)) / Math.pow(10, 2) : 0}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <AttachMoneyIcon />
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={2} display="flex" alignItems="center">
              {data.Month - data.Avg >= 0 && <>
                <ArrowUpwardIcon className={classes.differenceIconSucc} />
                <Typography className={classes.differenceValueSucc} variant="body2">
                  {data.Month ? Math.round(data.Month * Math.pow(10, 2)) / Math.pow(10, 2) : 0}
                </Typography>
              </>
              }
              {data.Month - data.Avg < 0 &&
                <>
                  <ArrowDownwardIcon className={classes.differenceIconFail} />
                  <Typography className={classes.differenceValueFail} variant="body2">
                    {data.Month ? Math.round(data.Month * Math.pow(10, 2)) / Math.pow(10, 2) : 0}
                  </Typography>
                </>
              }
              <Typography color="textSecondary" variant="caption">
                Since last 30 days
              </Typography>
            </Box>
          </CardContent>
        </Card>
      }
    </>
  );
};

Cashback.propTypes = {
  className: PropTypes.string
};

export default Cashback;