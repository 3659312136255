import React, { useState } from 'react';
import {
    Box,
    Typography,
    Container,
    Grid,
    makeStyles,
    Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import ProductCard from './ProductCard';
import data from './data';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    productCard: {
        height: '100%'
    }
}));

const ProductList = () => {
    const classes = useStyles();
    const [products] = useState(data);
    const RolePermissions = useSelector(state => state.rolepermission.Roles);
    const rolePermission = eval(RolePermissions)

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/bidding-management"
                    variant="text"
                    size="small"
                    color="inherit"
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        B2B Bidding Products Management
                    </Typography>
                </Box>
                <Box mt={3}>
                    <Grid container spacing={3}>
                        {products.map(product => (
                            <Grid item key={product.id} lg={4} md={6} xs={12}>
                                <ProductCard
                                    className={classes.productCard}
                                    product={product}
                                    rolePermission={rolePermission}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box mt={3} display="flex" justifyContent="center">
                </Box>
            </Container>
        </Page>
    );
};

export default ProductList;