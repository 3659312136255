import { toast } from 'react-toastify';
import {  paginationPartnermanagementServices } from './node-actions/nodepagination';
import {  nodeServiceApi,boomiAdminApi } from './commonAxios';
export const SERVICE_PARTNERMANAGEMENT_CREATE_REQUEST = "SERVICE_PARTNERMANAGEMENT_CREATE_REQUEST";
export const SERVICE_PARTNERMANAGEMENT_CREATE_SUCCESS = "SERVICE_PARTNERMANAGEMENT_CREATE_SUCCESS";
export const SERVICE_PARTNERMANAGEMENT_CREATE_FAIL = "SERVICE_PARTNERMANAGEMENT_CREATE_FAIL";
export const SERVICE_PARTNERMANAGEMENT_UPDATE_REQUEST = "SERVICE_PARTNERMANAGEMENT_UPDATE_REQUEST";
export const SERVICE_PARTNERMANAGEMENT_UPDATE_SUCCESS = "SERVICE_PARTNERMANAGEMENT_UPDATE_SUCCESS";
export const SERVICE_PARTNERMANAGEMENT_UPDATE_FAIL = "SERVICE_PARTNERMANAGEMENT_UPDATE_FAIL ";

/*=============================================================
                  Add PARTNERMANAGEMENT Action
==============================================================*/
export const AddPartnerManagementAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: SERVICE_PARTNERMANAGEMENT_CREATE_REQUEST,
  });
  nodeServiceApi.post(`/admin/partner_services/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICE_PARTNERMANAGEMENT_CREATE_SUCCESS,
        });
        toast('Service Category Created Successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/servicespartner-management');
      } else {
        dispatch({
          type: SERVICE_PARTNERMANAGEMENT_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }
    })
    .catch(error => {
      dispatch({
        type:SERVICE_PARTNERMANAGEMENT_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Update PARTNERMANAGEMENT Action
===============================================================*/
export const EditServicesPartnerManagementAction = (formData, navigate) => async dispatch => {
 
  dispatch({
    type: SERVICE_PARTNERMANAGEMENT_UPDATE_REQUEST
  });
  nodeServiceApi.put(`/admin/partner_services/Update/${formData.Pss_Id}`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICE_PARTNERMANAGEMENT_UPDATE_SUCCESS
        });
        toast('Service Category Updated Successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/servicespartner-management');
      } else {
        dispatch({
          type: SERVICE_PARTNERMANAGEMENT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }
    })
    .catch(error => {
      dispatch({
        type: SERVICE_PARTNERMANAGEMENT_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};


/*=============================================================
              Delete  PARTNERMANAGEMENT Action
 ===============================================================*/
export const deletePartnerManagementAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeServiceApi.delete(`/admin/partner_services/Delete/${formData.id}`,);
    if (data) {
      toast(' PartnerManagement deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    }
    dispatch(paginationPartnermanagementServices(filters, pagination, callBackPagination));

  } 
catch (err) {
  }
};

/*=============================================================
          PARTNERMANAGEMENT logo Image Upload Action
===============================================================*/
export const PartnerManagementuploadImagepath = (
  formData,
  callBackUploadImgae,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=PartnerManagementType&fileextension=png&filetype=Images&filename=PartnerManagementType`,
      formData
    );
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) {
  }
};