import {
  TESTIMONIAL_UPDATE_REQUEST,
  TESTIMONIAL_UPDATE_SUCCESS,
  TESTIMONIAL_UPDATE_FAIL,
} from '../actions/testimonialAction';

const initialState = {
  testimonialUpdate: { testimonial: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TESTIMONIAL_UPDATE_REQUEST:
      return { ...state, testimonialUpdate: { testimonial: {}, error: '', isLoading: true } };
    case TESTIMONIAL_UPDATE_SUCCESS:
      return { ...state, testimonialUpdate: { testimonial: state.testimonialUpdate.testimonial, error: '', isLoading: false } };
    case TESTIMONIAL_UPDATE_FAIL:
      return { ...state, testimonialUpdate: { testimonial: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}