import { Card, CardContent, Grid, Button } from '@material-ui/core';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import CertificatePreview from '../CertificatePreview';
import Base64 from "src/hooks/Base64";

const ViewCorporateAward = ({ className, ...rest }) => {
    const location = useLocation();
    let { state } = location;
    const [htmlString, setHtmlString] = useState(Base64.atob(state.Embeded_Template_Code))
    const [open, setOpen] = useState(false);

    function formatHtmlString(htmlString) {
        const escapedString = htmlString
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;");
        const formattedString = escapedString.replace(/\n/g, '<br/>');
        return formattedString;
    }

    const formattedContent = formatHtmlString(htmlString);

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Card>
            <CardContent>
                {/* Grid creates visual consistency between layouts and adapts to screen size */}
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Template ID
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state._id}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Template Name
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state.Template_Name}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Status
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state.Status}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created Date
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state.Created_Date}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created By
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state.Created_By}> {state.Created_By?.length > 50 ?
                                        <span>{state.Created_By?.substring(0, 50)}...</span> : state.Created_By}</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified Date
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state.Modified_Date}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified By
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state.Modified_By}> {state.Modified_By?.length > 50 ?
                                        <span>{state.Modified_By?.substring(0, 50)}...</span> : state.Modified_By}</span>
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={5} style={{ marginTop: 20 }}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A' }}
                    >
                        Template Attributes:
                    </Typography>
                </Grid>
                <Grid item style={{ marginTop: 20 }}>
                    {state?.Template_Loop.map((template, index) => (
                        <Typography
                            variant="h5"
                            component="h2"
                            gutterBottom
                        >
                            Attribute Name: {template.template_attribute_name} -
                            Attribute Name as: {template.template_attribute_name_as}
                        </Typography>
                    ))}
                </Grid>
                <Grid item xs={5} style={{ marginTop: 20 }}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A' }}
                    >
                        Template
                    </Typography>
                </Grid>
                <Grid item style={{ marginTop: 20 }}>
                    <Button onClick={handleClickOpen} variant="outlined" style={{ border: '1px solid #27b6cc' }}>
                        <span style={{ color: '#27B6CC' }}>Preview</span>
                    </Button>
                    <CertificatePreview htmlContent={formattedContent} open={open} onClose={handleClose} />
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ViewCorporateAward;