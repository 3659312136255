import {
    SERVICES_REQUEST,
    SERVICES_SUCCESS,
    SERVICES_FAIL,
    SERVICES_GET_REQUEST,
    SERVICES_GET_SUCCESS,
    SERVICES_GET_FAIL,
    SERVICES_UPDATE_REQUEST,
    SERVICES_UPDATE_SUCCESS,
    SERVICES_UPDATE_FAIL,
    SERVICES_FILTER,
    SERVICES_CREATE_REQUEST,
    SERVICES_CREATE_SUCCESS,
    SERVICES_CREATE_FAIL,
    BUSSINESS_SERVICES_REQUEST,
    BUSSINESS_SERVICES_SUCCESS,
    BUSSINESS_SERVICES_FAIL,
    BUSSINESS_SUBTYPE_REQUEST,
    BUSSINESS_SUBTYPE_SUCCESS,
    BUSSINESS_SUBTYPE_FAIL,
    PARTNER_SERVICES_REQUEST,
    PARTNER_SERVICES_SUCCESS,
    PARTNER_SERVICES_FAIL,
    PARTNERS_SERVICE_REQUEST,
    PARTNERS_SERVICE_SUCCESS,
    PARTNERS_SERVICE_FAIL,
    USER_SERVICE_USERPAY_REQUEST,
    USER_SERVICE_USERPAY_SUCCESS,
    USER_SERVICE_USERPAY_FAIL,
    USER_SERVICE_PARTNERPAY_REQUEST,
    USER_SERVICE_PARTNERPAY_SUCCESS,
    USER_SERVICE_PARTNERPAY_FAIL,
    ADDRESS_REQUEST,
    ADDRESS_SUCCESS,
    ADDRESS_FAIL,
    EMPLOYEE_SERVICES_REQUEST,
    EMPLOYEE_SERVICES_SUCCESS,
    EMPLOYEE_SERVICES_FAIL,
    USER_SERVICE_EMAILS_REQUEST,
    USER_SERVICE_EMAILS_SUCCESS,
    USER_SERVICE_EMAILS_FAIL,

} from '../actions/servicesAction';

const initialState = {
    services: { all: [], error: '', isLoading: false },
    service: { service: {}, error: '', isLoading: false },
    serviceUpdate: { service: {}, error: '', isLoading: false },
    serviceCreate: { service: {}, error: '', isLoading: false },
    servicesFilter: { select: 'All', search: '' },
    BussinessServices: { bussinessServices: [], error: '', isLoading: false },
    BussinesssubtypeServices: { bussinesssubtypeServices: [], error: '', isLoading: false },
    PartnerServices: { partnerservices: [], error: '', isLoading: false },
    PhysicalPartners: { partners: [], error: '', isLoading: false },
    userserviceUserPay: { serviceuserpay: {}, error: '', isLoading: false },
    userservicePartnerPay: { servicepartnerpay: {}, error: '', isLoading: false },
    GenericServices: { genericServices: [], error: '', isLoading: false },
    AddressServices: { addressServices: [], error: '', isLoading: false },
    EmployeeServices: { employeeServices: [], error: '', isLoading: false },
    EmailUserServices: { all: [], error: '', isLoading: false },
};


export default function (state = initialState, action) {
    switch (action.type) {
        case SERVICES_REQUEST:
            return { ...state, services: { all: state.services.all, error: '', isLoading: true } };
        case SERVICES_SUCCESS:
            return { ...state, services: { all: action.payload, error: '', isLoading: false } };
        case SERVICES_FAIL:
            return { ...state, services: { all: [], error: action.payload, isLoading: false } };
        case SERVICES_GET_REQUEST:
            return { ...state, service: { service: {}, error: '', isLoading: true } };
        case SERVICES_GET_SUCCESS:
            return { ...state, service: { service: action.payload, error: '', isLoading: false } };
        case SERVICES_GET_FAIL:
            return { ...state, service: { service: {}, error: action.payload, isLoading: false } };
        case SERVICES_CREATE_REQUEST:
            return { ...state, serviceCreate: { service: {}, error: '', isLoading: true } };
        case SERVICES_CREATE_SUCCESS:
            return { ...state, serviceCreate: { service: state.serviceCreate.service, error: '', isLoading: false } };
        case SERVICES_CREATE_FAIL:
            return { ...state, serviceCreate: { service: {}, error: action.payload, isLoading: false } };
        case SERVICES_UPDATE_REQUEST:
            return { ...state, serviceUpdate: { service: {}, error: '', isLoading: true } };
        case SERVICES_UPDATE_SUCCESS:
            return { ...state, serviceUpdate: { service: state.serviceUpdate.service, error: '', isLoading: false } };
        case SERVICES_UPDATE_FAIL:
            return { ...state, serviceUpdate: { service: {}, error: action.payload, isLoading: false } };
        case SERVICES_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, servicesFilter: { search: state.servicesFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, servicesFilter: { select: state.servicesFilter.select, search: action.value } };
            }
            return res;
        case BUSSINESS_SERVICES_REQUEST:
            return { ...state, BussinessServices: { bussinessServices: state.BussinessServices.bussinessServices, error: '', isLoading: true }, };
        case BUSSINESS_SERVICES_SUCCESS:
            return { ...state, BussinessServices: { bussinessServices: action.payload, error: '', isLoading: false } };
        case BUSSINESS_SERVICES_FAIL:
            return { ...state, BussinessServices: { bussinessServices: [], error: action.payload, isLoading: false } };
        case BUSSINESS_SUBTYPE_REQUEST:
            return { ...state, BussinesssubtypeServices: { bussinesssubtypeServices: state.BussinesssubtypeServices.bussinesssubtypeServices, error: '', isLoading: true }, };
        case BUSSINESS_SUBTYPE_SUCCESS:
            return { ...state, BussinesssubtypeServices: { bussinesssubtypeServices: action.payload, error: '', isLoading: false } };
        case BUSSINESS_SUBTYPE_FAIL:
            return { ...state, BussinesssubtypeServices: { bussinesssubtypeServices: [], error: action.payload, isLoading: false } };
        case PARTNER_SERVICES_REQUEST:
            return { ...state, PartnerServices: { partnerservices: state.PartnerServices.partnerservices, error: '', isLoading: true }, };
        case PARTNER_SERVICES_SUCCESS:
            return { ...state, PartnerServices: { partnerservices: action.payload, error: '', isLoading: false } };
        case PARTNER_SERVICES_FAIL:
            return { ...state, PartnerServices: { partnerservices: [], error: action.payload, isLoading: false } };
        case PARTNERS_SERVICE_REQUEST:
            return { ...state, PhysicalPartners: { partners: state.PhysicalPartners.partners, error: '', isLoading: true }, };
        case PARTNERS_SERVICE_SUCCESS:
            return { ...state, PhysicalPartners: { partners: action.payload, error: '', isLoading: false } };
        case PARTNERS_SERVICE_FAIL:
            return { ...state, PhysicalPartners: { partners: [], error: action.payload, isLoading: false } };
        case USER_SERVICE_USERPAY_REQUEST:
            return { ...state, userserviceUserPay: { serviceuserpay: {}, error: '', isLoading: true } };
        case USER_SERVICE_USERPAY_SUCCESS:
            return { ...state, userserviceUserPay: { serviceuserpay: state.userserviceUserPay.serviceuserpay, error: '', isLoading: false } };
        case USER_SERVICE_USERPAY_FAIL:
            return { ...state, userserviceUserPay: { serviceuserpay: {}, error: action.payload, isLoading: false } };
        case USER_SERVICE_PARTNERPAY_REQUEST:
            return { ...state, userservicePartnerPay: { servicepartnerpay: {}, error: '', isLoading: true } };
        case USER_SERVICE_PARTNERPAY_SUCCESS:
            return { ...state, userservicePartnerPay: { servicepartnerpay: state.userservicePartnerPay.servicepartnerpay, error: '', isLoading: false } };
        case USER_SERVICE_PARTNERPAY_FAIL:
            return { ...state, userservicePartnerPay: { servicepartnerpay: {}, error: action.payload, isLoading: false } };
        case ADDRESS_REQUEST:
            return { ...state, AddressServices: { addressServices: state.AddressServices.addressServices, error: '', isLoading: true }, };
        case ADDRESS_SUCCESS:
            return { ...state, AddressServices: { addressServices: action.payload, error: '', isLoading: false } };
        case ADDRESS_FAIL:
            return { ...state, AddressServices: { addressServices: [], error: action.payload, isLoading: false } };
        case EMPLOYEE_SERVICES_REQUEST:
            return { ...state, EmployeeServices: { employeeServices: state.EmployeeServices.employeeServices, error: '', isLoading: true }, };
        case EMPLOYEE_SERVICES_SUCCESS:
            return { ...state, EmployeeServices: { employeeServices: action.payload, error: '', isLoading: false } };
        case EMPLOYEE_SERVICES_FAIL:
            return { ...state, EmployeeServices: { employeeServices: [], error: action.payload, isLoading: false } };
            case USER_SERVICE_EMAILS_REQUEST:
                return { ...state, EmailUserServices: { all: state.EmailUserServices.all, error: '', isLoading: true }, };
            case USER_SERVICE_EMAILS_SUCCESS:
                return { ...state, EmailUserServices: { all: action.payload, error: '', isLoading: false } };
            case USER_SERVICE_EMAILS_FAIL:
                return { ...state, EmailUserServices: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}