import { nodeGetApi } from './commonAxios';

export const SERVICES_DATEANDCOUNT_REQUEST = "SERVICES_DATEANDCOUNT_REQUEST";
export const SERVICES_DATEANDCOUNT_SUCCESS = "SERVICES_DATEANDCOUNT_SUCCESS";
export const SERVICES_DATEANDCOUNT_FAIL = "SERVICES_DATEANDCOUNT_FAIL";

/*=============================================================
          Get Rides Date and count Action
===============================================================*/
export const getServicesDateandCount = () => async dispatch => {
  try {
    dispatch({
      type: SERVICES_DATEANDCOUNT_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Total_Count_And_Date/User_Service`);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SERVICES_DATEANDCOUNT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SERVICES_DATEANDCOUNT_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SERVICES_DATEANDCOUNT_FAIL,
      payload: err
    });
  }
};