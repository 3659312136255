import {
    RAZORPAY_TRANSACTIONS_REQUEST,
    RAZORPAY_TRANSACTIONS_SUCCESS,
    RAZORPAY_TRANSACTIONS_FAIL,
    RAZORPAY_TRANSACTIONS_GET_REQUEST,
    RAZORPAY_TRANSACTIONS_GET_SUCCESS,
    RAZORPAY_TRANSACTIONS_GET_FAIL,
    RAZORPAY_TRANSACTIONS_CREATE_REQUEST,
    RAZORPAY_TRANSACTIONS_CREATE_SUCCESS,
    RAZORPAY_TRANSACTIONS_CREATE_FAIL,
    RAZORPAY_TRANSACTIONS_FILTER,
   
} from '../actions/razorpaytransactionAction';

const initialState = {
    razorpaytransactions: { all: [], error: '', isLoading: false },
    razorpaytransaction: { razorpaytransaction: {}, error: '', isLoading: false },
    razorpaytransactionsCreate: { razorpaytransaction: {}, error: '', isLoading: false },
    razorpaytransactionsFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case RAZORPAY_TRANSACTIONS_REQUEST:
            return { ...state, razorpaytransactions: { all: state.razorpaytransactions.all, error: '', isLoading: true } };
        case RAZORPAY_TRANSACTIONS_SUCCESS:
            return { ...state, razorpaytransactions: { all: action.payload, error: '', isLoading: false } };
        case RAZORPAY_TRANSACTIONS_FAIL:
            return { ...state, razorpaytransactions: { all: [], error: action.payload, isLoading: false } };
        case RAZORPAY_TRANSACTIONS_GET_REQUEST:
            return { ...state, razorpaytransaction: { razorpaytransaction: {}, error: '', isLoading: true } };
        case RAZORPAY_TRANSACTIONS_GET_SUCCESS:
            return { ...state, razorpaytransaction: { razorpaytransaction: action.payload, error: '', isLoading: false } };
        case RAZORPAY_TRANSACTIONS_GET_FAIL:
            return { ...state, razorpaytransaction: { razorpaytransaction: {}, error: action.payload, isLoading: false } };
        case RAZORPAY_TRANSACTIONS_CREATE_REQUEST:
            return { ...state, razorpaytransactionsCreate: { razorpaytransaction: {}, error: '', isLoading: true } };
        case RAZORPAY_TRANSACTIONS_CREATE_SUCCESS:
            return { ...state, razorpaytransactionsCreate: { razorpaytransaction: state.razorpaytransactionsCreate.razorpaytransaction , error: '', isLoading: false } };
        case RAZORPAY_TRANSACTIONS_CREATE_FAIL:
            return { ...state, razorpaytransactionsCreate: { razorpaytransaction: {}, error: action.payload, isLoading: false } };
        case RAZORPAY_TRANSACTIONS_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, razorpaytransactionsFilter: { search: state.razorpaytransactionsFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, razorpaytransactionsFilter: { select: state.razorpaytransactionsFilter.select, search: action.value } };
            }
            return res;
        default:
            return state;
    }
}

