import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import { uploadProfileAction } from "../../../store/actions/profileActions";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  avatar: {
    height: 100,
    width: 100
  }
}));

const Profile = ({ className, profiletemp, callgetuser, loading, setLoading, ...rest }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const inputFile = useRef(null);
  const profileimg = profiletemp?.Profile_Pic_Path ? profiletemp?.Profile_Pic_Path : '../../../../static/defaultlogo.png';

  const [currentUrl, setCurrentUrl] = React.useState(profiletemp?.Profile_Pic_Path);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadHandler = async (event) => {
    setLoading(true);
    let file = event.target.files[0];
    const data = await toBase64(event.target.files[0]);

    let fileData = {
      fileName: file.name.split(".")[0],
      ext: file.name.split(".")[1],
      userDetailsId: profiletemp.User_Details_id,
    };
    dispatch(uploadProfileAction(updateStates, data, fileData));
  };
  const updateStates = (data, loading) => {
    setLoading(false);
    toast(data.UI_Display_Message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
    setCurrentUrl(data.File_URL);
    callgetuser();
  };


  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar className={classes.avatar} src={profileimg} />
          <Typography color="textPrimary" gutterBottom variant="h3">
            {profiletemp?.First_Name} {profiletemp?.Last_name}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {profiletemp?.Email_Id}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {profiletemp?.Mobile}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <input
          ref={inputFile}
          style={{
            display: "none",
          }}
          type="file"
          name="file"
          accept=".png, .jpg, .img"
          onChange={uploadHandler}
        />

        <Button onClick={() => inputFile.current.click()} color="primary" disabled={loading} fullWidth variant="text">
          Upload picture
        </Button>
      </CardActions>
    </Card >
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  profiletemp: PropTypes.object,
  callgetuser: PropTypes.func
};

export default Profile;