import { v4 as uuid } from 'uuid';

export default [
    {
        id: uuid(),
        hide: 0,
        link: '/app/servicescategories-management',
        createdAt: '',
        description:
            'A Service Management helps organizers plan, execute and report on events, driving success for their business.',
        media: '/static/Dashboard/catergory.png',
        title: 'Services Management',
        total: ''
    },
 

    {
        id: uuid(),
        hide: 0,
        link: '/app/servicespartner-management',
        createdAt: '',
        description:
            'A Service Management helps organizers plan, execute and report on events, driving success for their business.',
        media: '/static/Dashboard/catergory.png',
        title: 'Partner Services Management',
        total: ''
    },
    

 
 {
        id: uuid(),
        hide: 0,
        link: '/app/servicebanners-management',
        createdAt: '',
        description:
            'A Service Management helps organizers plan, execute and report on events, driving success for their business.',
        media: '/static/Dashboard/catergory.png',
        title: 'Services Banner Management',
        total: ''
    },



    {
        id: uuid(),
        hide: 0,
        link: '/app/ServiceBooking-management',
        createdAt: '',
        description:
            'A Service Management helps organizers plan, execute and report on events, driving success for their business.',
        media: '/static/Dashboard/catergory.png',
        title: 'Partner Booking Request ',
        total: ''
    },

    {
        id: uuid(),
        hide: 0,
        link: '/app/ServiceRequest-management',
        createdAt: '',
        description:
            'A Service Management helps organizers plan, execute and report on events, driving success for their business.',
        media: '/static/Dashboard/catergory.png',
        title: 'Services Request Management',
        total: ''
    },
 
];