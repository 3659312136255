import {
    SETTINGDATEANDCOUNT_REQUEST,
    SETTINGDATEANDCOUNT_SUCCESS,
    SETTINGDATEANDCOUNT_FAIL,

} from '../actions/settingsdynamicActions'

const initialState = {
    settingdateandcounts: { all: [], error: '', isLoading: false },
};
export default function (state = initialState, action) {
    switch (action.type) {
        case SETTINGDATEANDCOUNT_REQUEST:
            return { ...state, settingdateandcounts: { all: state.settingdateandcounts.all, error: '', isLoading: true } };
        case SETTINGDATEANDCOUNT_SUCCESS:
            return { ...state, settingdateandcounts: { all: action.payload, error: '', isLoading: false } };
        case SETTINGDATEANDCOUNT_FAIL:
            return { ...state, settingdateandcounts: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}

