import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    TextField,
    InputAdornment,
    SvgIcon,
    makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// useStyles we use a hook to consume the styles
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    formFilterControl: {
        margin: theme.spacing(1),
        minWidth: 200
    }
}));

const Toolbar = ({ className, filters, setfilters, ...rest }) => {
    // classes contains the styles
    const classes = useStyles();
    // handleChange, handleSearch performs the action for filter and search
    const handleChange = event => {
        setfilters({
            search: filters?.search,
            search_by_filter: event.target.value,
            Records_Filter: "FILTER",
            Is_Approved: "0"
        })
    };

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                alignItems="center"
                mb={2}
            >
                <Box display="flex" flexWrap="wrap" mb={2}>
                    <FormControl variant="outlined" className={classes.formFilterControl}>
                        <Select
                            value={filters.search_by_filter}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="Coupon_Id">Coupon ID</MenuItem>
                            <MenuItem value="Name">Coupon Name</MenuItem>
                            <MenuItem value="Partner_Name">Partner Name</MenuItem>
                            <MenuItem value="Status">Status</MenuItem>
                        </Select>
                    </FormControl>
                    <Box width={500}>
                        <TextField
                            onChange={(event) => {
                                setfilters({
                                    search: event.target.value,
                                    search_by_filter: filters.search_by_filter,
                                    Records_Filter: "FILTER",
                                    Is_Approved: "0"
                                })
                            }}
                            className={classes.formControl}
                            value={filters?.search}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon fontSize="small" color="action">
                                            <SearchIcon />
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}
                            placeholder="Search Coupons"
                            variant="outlined"
                        />
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;