import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'MP_Coupon_Id', numeric: false, disablePadding: true, label: 'MP Coupon ID' },
  { id: 'Code', numeric: false, disablePadding: false, label: 'Code' },
  { id: 'Coupon_Count', numeric: false, disablePadding: false, label: 'Coupon Count' },
  { id: 'Is_Expired', numeric: false, disablePadding: false, label: 'Is Expired' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allGlobalcoupon,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  pagination,
  setPagination,
  createSortHandler,
  allGlobalcouponNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedGlobalCouponIds, setSelectedGlobalCouponIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedGlobalCouponIds;
    if (event.target.checked) {
      newSelectedGlobalCouponIds = allGlobalcoupon.map(globalcoupon => globalcoupon.MP_Coupon_Id);
    } else {
      newSelectedGlobalCouponIds = [];
    }
    setSelectedGlobalCouponIds(newSelectedGlobalCouponIds);
  };

  const handleSelectOne = (event, id) => {
    setSelectedGlobalCouponIds(selectedGlobalCouponIds.filter(record => {
      for (let i = 0; i < allGlobalcouponNoFilter.length; i++) {
        if (record === allGlobalcouponNoFilter[i].MP_Coupon_Id) {
          return true;
        }
      }
      return false;
    }))
    const selectedIndex = selectedGlobalCouponIds.indexOf(id);
    let newSelectedGlobalCouponIds = [];
    if (selectedIndex === -1) {
      newSelectedGlobalCouponIds = newSelectedGlobalCouponIds.concat(selectedGlobalCouponIds, id);
    } else if (selectedIndex === 0) {
      newSelectedGlobalCouponIds = newSelectedGlobalCouponIds.concat(
        selectedGlobalCouponIds.slice(1)
      );
    } else if (selectedIndex === selectedGlobalCouponIds.length - 1) {
      newSelectedGlobalCouponIds = newSelectedGlobalCouponIds.concat(
        selectedGlobalCouponIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedGlobalCouponIds = newSelectedGlobalCouponIds.concat(
        selectedGlobalCouponIds.slice(0, selectedIndex),
        selectedGlobalCouponIds.slice(selectedIndex + 1)
      );
    }
    setSelectedGlobalCouponIds(newSelectedGlobalCouponIds);
  };

  const deleteSelectedGlobalCouponHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const editGlobalCouponHandler = couponData => e => {
    navigate('/app/update-global-coupon', { state: couponData });
  };

  const visibleGlobalCouponHandler = couponData => e => {
    navigate("/app/view-global-coupon", { state: couponData });
  };

  const globalCouponHistoryHandler = couponData => e => {
    navigate("/app/global-coupon-history", { state: couponData });
  };


  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedGlobalCouponIds.filter(record => {
                      for (let i = 0; i < allGlobalcouponNoFilter.length; i++) {
                        if (record === allGlobalcouponNoFilter[i].MP_Coupon_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allGlobalcoupon.length}
                    color="primary"
                    indeterminate={
                      selectedGlobalCouponIds.filter(record => {
                        for (let i = 0; i < allGlobalcouponNoFilter.length; i++) {
                          if (record === allGlobalcouponNoFilter[i].MP_Coupon_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedGlobalCouponIds.filter(record => {
                        for (let i = 0; i < allGlobalcouponNoFilter.length; i++) {
                          if (record === allGlobalcouponNoFilter[i].MP_Coupon_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allGlobalcoupon.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Global_Coupon_Management_Write?.Value === "Global_Coupon_Management_Write" && RolePermissions?.Global_Coupon_Management_Write?.Is_Write === 1) ?
                  <TableCell >Coupon History</TableCell>
                  : null}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-4rem' }} >Actions
                  {selectedGlobalCouponIds.filter(record => {
                    for (let i = 0; i < allGlobalcouponNoFilter.length; i++) {
                      if (record === allGlobalcouponNoFilter[i].MP_Coupon_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedGlobalCouponIds.filter(record => {
                    for (let i = 0; i < allGlobalcouponNoFilter.length; i++) {
                      if (record === allGlobalcouponNoFilter[i].MP_Coupon_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedGlobalCouponHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allGlobalcoupon) &&
                (allGlobalcoupon).map(globalcoupon => (
                  <TableRow
                    hover
                    key={globalcoupon.MP_Coupon_Id}
                    selected={
                      selectedGlobalCouponIds.indexOf(globalcoupon.MP_Coupon_Id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedGlobalCouponIds.indexOf(globalcoupon.MP_Coupon_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, globalcoupon.MP_Coupon_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell >{globalcoupon.MP_Coupon_Id}</TableCell>
                    <TableCell >{globalcoupon.Code}</TableCell>
                    <TableCell >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{globalcoupon.Coupon_Count}</TableCell>
                    <TableCell >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{globalcoupon.Is_Expired}</TableCell>
                    <TableCell >{globalcoupon.Status}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Global_Coupon_Management_Write?.Value === "Global_Coupon_Management_Write" && RolePermissions?.Global_Coupon_Management_Write?.Is_Write === 1) ? <TableCell>
                      <Tooltip title="Coupon History">
                        <Button
                          onClick={globalCouponHistoryHandler(globalcoupon)}
                          color="primary" >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<CardGiftcardIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                      : null}
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Global_Coupon_Management_Write?.Value === "Global_Coupon_Management_Write" && RolePermissions?.Global_Coupon_Management_Write?.Is_Write === 1) ? <TableCell>
                      <Tooltip title="Coupon Details">
                        <Button
                          onClick={visibleGlobalCouponHandler(globalcoupon)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </Tooltip>
                      <Button
                        onClick={editGlobalCouponHandler(globalcoupon)}>
                        <CreateIcon style={{ color: '#9EA0A5' }} />
                      </Button>
                    </TableCell>
                      : null}
                    {RolePermissions?.Global_Coupon_Management_Read?.Value === "Global_Coupon_Management_Read" && RolePermissions?.Global_Coupon_Management_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="Coupon Details">
                          <Button
                            onClick={visibleGlobalCouponHandler(globalcoupon)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allGlobalcoupon) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allGlobalcoupon: PropTypes.array.isRequired
};

export default Results;