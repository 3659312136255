import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles, TextareaAutosize
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { AddBiddingUserServiceAction, getAllBiddingServices, getAllServicesUserEmails, getAllbiddingservices } from 'src/store/actions/biddinguserserviceAction';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";

const bidstatusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'INITIATED',
        label: 'INITIATED'
    },
    {
        value: 'PROCESSING',
        label: 'PROCESSING'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
    {
        value: 'COMPLETED',
        label: 'COMPLETED'
    },
];

const useStyles = makeStyles(() => ({
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const AddBiddingUserServices = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    const isSubmitted = useSelector(state => state.biddinguserservices.biddinguserserviceCreate.isLoading);
    const errorMessage = useSelector(state => state.biddinguserservices.biddinguserserviceCreate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const emails = useSelector(state => state.biddinguserservices.ServicesUserEmails.serviceemails);
    const services = useSelector(state => state.biddinguserservices.BiddingServices.services);

    useEffect(() => {
        dispatch(getAllServicesUserEmails())
        dispatch(getAllBiddingServices());
    }, []);

    const [servicesdata, setServicessData] = React.useState([]);
    const callBacksubtypeservicesData = (data) => {
        setServicessData(data[0].Service_Sub_Types.split(",").map(Values => ({ Service_Sub_Types: Values })))
    }

    const setServiceType = (value) => {
        if (value !== "") {
            let data = {
                search: "",
                search_by_filter: "",
                Records_Filter: 'FILTER',
                Service_Type: "",
                Bidding_Service_Id: value,
            }
            dispatch(getAllbiddingservices(data, callBacksubtypeservicesData))
        }
    }

    return (
        <Formik
            initialValues={{
                Bidding_Expiry_Date: "",
                Bidding_Service_Id: "",
                Description: "",
                Email_Id: "",
                User_Pincode: "",
                Created_By: loginEmail,
                Bidding_Status: "",
                Services_Request_Total_Time_In_Days: "",
                Services_Request_Total_Time_In_Hours: "",
                Services_Sub_Type: "",
                Bidding_Services_Start_Date: "",
            }}
            validationSchema={
                Yup.object().shape({
                    Bidding_Service_Id: Yup.string().required('Bidding Service Name is required.'),
                    Email_Id: Yup.string().required('User Email ID is required.'),
                    Bidding_Status: Yup.string().required('Bidding Status is required.'),
                    User_Pincode: Yup.string().matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, 'Please provide valid User Pincode.').required('User Pincode is required.'),
                    Bidding_Expiry_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require Bidding Expiry Date greater than or equal to today.").required("Bidding Expiry Date is required."),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};

                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(AddBiddingUserServiceAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={6} xs={12}>
                                            <Autocomplete
                                                freeSolo
                                                options={emails}
                                                groupBy={(option) => option.firstLetter}
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option.Email_Id}
                                                renderInput={(params) => <TextField {...params}
                                                    error={Boolean(touched.Email_Id && errors.Email_Id)}
                                                    helperText={touched.Email_Id && errors.Email_Id}
                                                    onBlur={handleBlur}
                                                    label="User Email ID"
                                                    name="Email_Id"
                                                    variant="outlined"
                                                    value={values.Email_Id}
                                                    onChange={() => {
                                                        dispatch(getAllServicesUserEmails(params.inputProps.value))
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill,
                                                        startAdornment: (
                                                            <React.Fragment>
                                                                <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                {params.InputProps.startAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />}
                                                onChange={(e, value) => {
                                                    setFieldValue("Email_Id", value?.Email_Id ? value.Email_Id : "");
                                                }}
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.User_Pincode && errors.User_Pincode)}
                                                fullWidth
                                                helperText={touched.User_Pincode && errors.User_Pincode}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="User Pincode"
                                                name="User_Pincode"
                                                value={values.User_Pincode}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                                                }}
                                                min={0}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Service_Id && errors.Bidding_Service_Id)}
                                                fullWidth
                                                helperText={touched.Bidding_Service_Id && errors.Bidding_Service_Id}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setServiceType(e.target.value)
                                                }}
                                                label="Bidding Service Name"
                                                name="Bidding_Service_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Service_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">-Please Select-</option>
                                                {services?.sort((a, b) => a.Service_Type?.localeCompare(b.Service_Type))?.map(option => (
                                                    <option key={option.Bidding_service_Id} value={option.Bidding_service_Id}>
                                                        {option.Service_Type}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Services_Sub_Type && errors.Services_Sub_Type)}
                                                fullWidth
                                                helperText={touched.Services_Sub_Type && errors.Services_Sub_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Service Sub Type"
                                                name="Services_Sub_Type"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Services_Sub_Type}
                                                variant="outlined"
                                            >
                                                <option key="" value="">-Please Select-</option>
                                                {servicesdata?.map((option, i) => (
                                                    <option key={i} value={option.Service_Sub_Types}>
                                                        {option.Service_Sub_Types}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                helperText={touched.Services_Request_Total_Time_In_Hours && errors.Services_Request_Total_Time_In_Hours}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Service Request Total Time In Hours"
                                                name="Services_Request_Total_Time_In_Hours"
                                                value={values.Services_Request_Total_Time_In_Hours}
                                                variant="outlined"
                                                type="number"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                helperText={touched.Services_Request_Total_Time_In_Days && errors.Services_Request_Total_Time_In_Days}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Service Request Total Time In Days"
                                                name="Services_Request_Total_Time_In_Days"
                                                value={values.Services_Request_Total_Time_In_Days}
                                                variant="outlined"
                                                type="number"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="date"
                                                label="Bidding Service Start Date"
                                                name="Bidding_Services_Start_Date"
                                                value={values.Bidding_Services_Start_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}
                                                fullWidth
                                                helperText={touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="date"
                                                label="Bidding Expiry Date"
                                                name="Bidding_Expiry_Date"
                                                value={values.Bidding_Expiry_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Status && errors.Bidding_Status)}
                                                fullWidth
                                                helperText={touched.Bidding_Status && errors.Bidding_Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Status"
                                                name="Bidding_Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Status}
                                                variant="outlined"
                                            >
                                                {bidstatusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            Description
                                            <TextareaAutosize
                                                value={values.Description}
                                                onChange={handleChange}
                                                name="Description"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Description"
                                            >
                                            </TextareaAutosize>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddBiddingUserServices.propTypes = {
    className: PropTypes.string
};

export default AddBiddingUserServices;