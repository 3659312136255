import React from 'react';
import { TextField } from '@material-ui/core';
import { boomiAdminApi } from 'src/store/actions/commonAxios';

const ESICity = (props) => {
    const { countrycode, statecode, ...rest } = props
    const [city, setCity] = React.useState([]);
    const [countryField, setCountryField] = React.useState();
    const [stateField, setStateField] = React.useState([]);

    React.useLayoutEffect(() => {
        setStateField(statecode);
        setCountryField(countrycode);
        if ((countrycode && statecode) && (countrycode !== countryField || statecode !== stateField)) {
            let citysdata = {
                "Filter_Type": "CITY",
                "Country": countrycode,
                "State": statecode
            }
            getCitys(citysdata)
        }
    }, [countrycode, statecode])

    const getCitys = (citysdata) => {
        boomiAdminApi.post(`/Country_State_City/Get`, citysdata)
            .then(response => {
                setCity(response?.data?.City[0]?.City_List);
            })
            .catch(error => {
            })
    }

    return (
        <TextField {...props}>
            <option key="" value="">--Please Select--</option>
            {city?.map(option => (
                <option key={option.CITY_ID} value={option.CITY_ID}>
                    {option.NAME}
                </option>
            ))}
        </TextField>
    );
};

export default ESICity;