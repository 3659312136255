import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    makeStyles
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import isEmpty from '../../store/validations/is-empty';
import CorpPagination from 'src/components/pagination/corppagination';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    }
}));

const headCells = [
    { id: 'id', numeric: false, disablePadding: true, label: 'Corp CMS ID' },
    { id: 'Page_Name', numeric: false, disablePadding: false, label: 'Page Name' },
    { id: 'Created_Date', numeric: false, disablePadding: false, label: 'Created Date' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
    className,
    allContentManagement,
    deleteContentManagementHandler,
    pagination,
    setPagination,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    createSortHandler,
    allContentManagementNoFilter,
    RolePermissions,
    roleUserType,
    ...rest
}) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [selectedcontentmanagementIds, setSelectedcontentmanagementIds] = useState([]);

    const handleSelectAll = event => {
        let newSelectedcontentmanagementIds;
        if (event.target.checked) {
            newSelectedcontentmanagementIds = allContentManagement.map(contentmanagement => contentmanagement.id);
        } else {
            newSelectedcontentmanagementIds = [];
        }
        setSelectedcontentmanagementIds(newSelectedcontentmanagementIds);
    };

    const handleSelectOne = (event, id) => {
        setSelectedcontentmanagementIds(selectedcontentmanagementIds.filter(record => {
            for (let i = 0; i < allContentManagementNoFilter.length; i++) {
                if (record === allContentManagementNoFilter[i].id) {
                    return true;
                }
            }
            return false;
        }))
        const selectedIndex = selectedcontentmanagementIds.indexOf(id);
        let newSelectedcontentmanagementIds = [];
        if (selectedIndex === -1) {
            newSelectedcontentmanagementIds = newSelectedcontentmanagementIds.concat(selectedcontentmanagementIds, id);
        } else if (selectedIndex === 0) {
            newSelectedcontentmanagementIds = newSelectedcontentmanagementIds.concat(
                selectedcontentmanagementIds.slice(1)
            );
        } else if (selectedIndex === selectedcontentmanagementIds.length - 1) {
            newSelectedcontentmanagementIds = newSelectedcontentmanagementIds.concat(
                selectedcontentmanagementIds.slice(0, -1)
            );
        } else if (selectedIndex > 0) {
            newSelectedcontentmanagementIds = newSelectedcontentmanagementIds.concat(
                selectedcontentmanagementIds.slice(0, selectedIndex),
                selectedcontentmanagementIds.slice(selectedIndex + 1)
            );
        }
        setSelectedcontentmanagementIds(newSelectedcontentmanagementIds);
    };

    const editContentManagementHandler = contentmanagementData => e => {
        navigate('/app/edit-CMS-corporate', { state: contentmanagementData });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedcontentmanagementIds.filter(record => {
                                            for (let i = 0; i < allContentManagementNoFilter.length; i++) {
                                                if (record === allContentManagementNoFilter[i].id) {
                                                    return true;
                                                }
                                            }
                                            return false;
                                        }).length === allContentManagement.length}
                                        color="primary"
                                        indeterminate={
                                            selectedcontentmanagementIds.filter(record => {
                                                for (let i = 0; i < allContentManagementNoFilter.length; i++) {
                                                    if (record === allContentManagementNoFilter[i].id) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            }).length > 0 &&
                                            selectedcontentmanagementIds.filter(record => {
                                                for (let i = 0; i < allContentManagementNoFilter.length; i++) {
                                                    if (record === allContentManagementNoFilter[i].id) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            }).length < allContentManagement.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-35%' }} >Actions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {selectedcontentmanagementIds.filter(record => {
                                        for (let i = 0; i < allContentManagementNoFilter.length; i++) {
                                            if (record === allContentManagementNoFilter[i].id) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }).length > 0 && <Button disabled={selectedcontentmanagementIds.filter(record => {
                                        for (let i = 0; i < allContentManagementNoFilter.length; i++) {
                                            if (record === allContentManagementNoFilter[i].id) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }).length <= 0} onClick={deleteContentManagementHandler(selectedcontentmanagementIds.map(record => record))}>
                                            <DeleteSweepIcon
                                                style={{ color: '#ff7a7a', fontSize: 25 }}
                                            />
                                        </Button>}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allContentManagement) &&
                                (allContentManagement).map(contentmanagement => (
                                    <TableRow
                                        hover
                                        key={contentmanagement.id}
                                        selected={
                                            selectedcontentmanagementIds.indexOf(contentmanagement.id) !== -1
                                        }
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={
                                                    selectedcontentmanagementIds.indexOf(contentmanagement.id) !== -1
                                                }
                                                onChange={event =>
                                                    handleSelectOne(event, contentmanagement.id)
                                                }
                                                value="true"
                                            />
                                        </TableCell>
                                        <TableCell >{contentmanagement.id}</TableCell>
                                        <TableCell style={{ overflowX: "hidden" }}>{contentmanagement.Page_Name}</TableCell>
                                        <TableCell >{contentmanagement.Created_Date}</TableCell>
                                        <TableCell style={{ color: '#1665D8' }}>{contentmanagement.Status} </TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Corporate_Content_Management_Write?.Value === "Corporate_Content_Management_Write" && RolePermissions?.Corporate_Content_Management_Write?.Is_Write === 1) ?
                                            <TableCell >
                                                <Button onClick={editContentManagementHandler(contentmanagement)} >
                                                    <CreateIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                                <Button>
                                                    <DeleteIcon onClick={deleteContentManagementHandler(contentmanagement)}
                                                        style={{ color: '#9EA0A5' }}
                                                    />
                                                </Button>
                                            </TableCell>
                                            : null}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allContentManagement) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <CorpPagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    allContentManagement: PropTypes.array.isRequired
};

export default Results;