import {
    RAZORPAY_REQUEST,
    RAZORPAY_SUCCESS,
    RAZORPAY_FAIL,
    RAZORPAY_GET_REQUEST,
    RAZORPAY_GET_SUCCESS,
    RAZORPAY_GET_FAIL,
    RAZORPAY_FILTER
} from '../actions/razorpayAction';

const initialState = {
   razorpays: { all: [], error: '', isLoading: false },
   razorpay: { razorpay: {}, error: '', isLoading: false },
   razorpaytransactionFilter: { select: 'All', search: '' },


};

export default function (state = initialState, action) {
    switch (action.type) {
        case RAZORPAY_REQUEST:
            return { ...state, razorpays: { all: state.razorpays.all, error: '', isLoading: true } };
        case RAZORPAY_SUCCESS:
            return { ...state, razorpays: { all: action.payload, error: '', isLoading: false } };
        case RAZORPAY_FAIL:
            return { ...state, razorpays: { all: [], error: action.payload, isLoading: false } };
        case RAZORPAY_GET_REQUEST:
            return { ...state, razorpay: { razorpay: {}, error: '', isLoading: true } };
        case RAZORPAY_GET_SUCCESS:
            return { ...state, razorpay: { razorpay: action.payload, error: '', isLoading: false } };
        case RAZORPAY_GET_FAIL:
            return { ...state, razorpay: { razorpay: {}, error: action.payload, isLoading: false } };
            case RAZORPAY_FILTER:
        let res = { ...state };
        if (action.fType === "select") {
           res = { ...state, razorpaytransactionFilter: { search: state.razorpaytransactionFilter.search, select: action.value } }
        }
        else if (action.fType === "search") {
          res = { ...state, razorpaytransactionFilter: { select: state.razorpaytransactionFilter.select, search: action.value } };
        }
        return res;
        default:
            return state;
    }
}