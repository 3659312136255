import {
  PRODUCTMASTERS_REQUEST,
  PRODUCTMASTERS_SUCCESS,
  PRODUCTMASTERS_FAIL,
  PRODUCTMASTER_GET_REQUEST,
  PRODUCTMASTER_GET_SUCCESS,
  PRODUCTMASTER_GET_FAIL,
  PRODUCTMASTER_CREATE_REQUEST,
  PRODUCTMASTER_CREATE_SUCCESS,
  PRODUCTMASTER_CREATE_FAIL,
  PRODUCTMASTER_UPDATE_REQUEST,
  PRODUCTMASTER_UPDATE_SUCCESS,
  PRODUCTMASTER_UPDATE_FAIL,
  PRODUCT_BRANDS_REQUEST,
  PRODUCT_BRANDS_SUCCESS,
  PRODUCT_BRANDS_FAIL,
  PRODUCT_CATEGORIES_REQUEST,
  PRODUCT_CATEGORIES_SUCCESS,
  PRODUCT_CATEGORIES_FAIL,
  PRODUCT_SUBCATEGORIES_REQUEST,
  PRODUCT_SUBCATEGORIES_SUCCESS,
  PRODUCT_SUBCATEGORIES_FAIL
} from './../actions/productmasterAction'

const initialState = {
  productmasters: { all: [], error: '', isLoading: false },
  productmaster: { productmaster: {}, error: '', isLoading: false },
  productmasterCreate: { productmaster: {}, error: '', isLoading: false },
  productmasterUpdate: { productmaster: {}, error: '', isLoading: false },
  productBrands: { brands: [], error: '', isLoading: false },
  productCategories: { categories: [], error: '', isLoading: false },
  productSubCategories: { subcategories: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PRODUCTMASTERS_REQUEST:
      return { ...state, productmasters: { all: state.productmasters.all, error: '', isLoading: true } };
    case PRODUCTMASTERS_SUCCESS:
      return { ...state, productmasters: { all: action.payload, error: '', isLoading: false } };
    case PRODUCTMASTERS_FAIL:
      return { ...state, productmasters: { all: [], error: action.payload, isLoading: false } };
    case PRODUCTMASTER_GET_REQUEST:
      return { ...state, productmaster: { productmaster: {}, error: '', isLoading: true } };
    case PRODUCTMASTER_GET_SUCCESS:
      return { ...state, productmaster: { productmaster: action.payload, error: '', isLoading: false } };
    case PRODUCTMASTER_GET_FAIL:
      return { ...state, productmaster: { productmaster: {}, error: action.payload, isLoading: false } };
    case PRODUCTMASTER_CREATE_REQUEST:
      return { ...state, productmasterCreate: { productmaster: {}, error: '', isLoading: true } };
    case PRODUCTMASTER_CREATE_SUCCESS:
      return { ...state, productmasterCreate: { productmaster: state.productmasterCreate.productmaster, error: '', isLoading: false } };
    case PRODUCTMASTER_CREATE_FAIL:
      return { ...state, productmasterCreate: { productmaster: {}, error: action.payload, isLoading: false } };
    case PRODUCTMASTER_UPDATE_REQUEST:
      return { ...state, productmasterUpdate: { productmaster: {}, error: '', isLoading: true } };
    case PRODUCTMASTER_UPDATE_SUCCESS:
      return { ...state, productmasterUpdate: { productmaster: state.productmasterUpdate.productmaster, error: '', isLoading: false } };
    case PRODUCTMASTER_UPDATE_FAIL:
      return { ...state, productmasterUpdate: { productmaster: {}, error: action.payload, isLoading: false } };

    case PRODUCT_BRANDS_REQUEST:
      return { ...state, productBrands: { brands: state.productBrands.brands, error: '', isLoading: true }, };
    case PRODUCT_BRANDS_SUCCESS:
      return { ...state, productBrands: { brands: action.payload, error: '', isLoading: false } };
    case PRODUCT_BRANDS_FAIL:
      return { ...state, productBrands: { brands: [], error: action.payload, isLoading: false } };
    case PRODUCT_CATEGORIES_REQUEST:
      return { ...state, productCategories: { categories: state.productCategories.categories, error: '', isLoading: true }, };
    case PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, productCategories: { categories: action.payload, error: '', isLoading: false } };
    case PRODUCT_CATEGORIES_FAIL:
      return { ...state, productCategories: { categories: [], error: action.payload, isLoading: false } };
    case PRODUCT_SUBCATEGORIES_REQUEST:
      return { ...state, productSubCategories: { subcategories: state.productSubCategories.subcategories, error: '', isLoading: true }, };
    case PRODUCT_SUBCATEGORIES_SUCCESS:
      return { ...state, productSubCategories: { subcategories: action.payload, error: '', isLoading: false } };
    case PRODUCT_SUBCATEGORIES_FAIL:
      return { ...state, productSubCategories: { subcategories: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}