import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Role_Name', numeric: false, disablePadding: true, label: 'Role Name' },
  { id: 'Created_By', numeric: false, disablePadding: false, label: 'Created By' },
  { id: 'Modified_By', numeric: false, disablePadding: false, label: 'Modified By' },
  { id: 'Role_Description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allRole,
  deleteRoleHandler,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allRoleNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedRoleIds, setSelectedRoleIds] = useState([]);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedRoleIds;
    if (event.target.checked) {
      newSelectedRoleIds = allRole.map(role => role.Role_Name);
    } else {
      newSelectedRoleIds = [];
    }
    setSelectedRoleIds(newSelectedRoleIds);
  };

  const handleSelectOne = (event, id) => {
    setSelectedRoleIds(selectedRoleIds.filter(record => {
      for (let i = 0; i < allRoleNoFilter.length; i++) {
        if (record === allRoleNoFilter[i].Role_Name) {
          return true;
        }
      }
      return false;
    }))
    const selectedIndex = selectedRoleIds.indexOf(id);
    let newSelectedCategoryIds = [];
    if (selectedIndex === -1) {
      newSelectedCategoryIds = newSelectedCategoryIds.concat(selectedRoleIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCategoryIds = newSelectedCategoryIds.concat(
        selectedRoleIds.slice(1)
      );
    } else if (selectedIndex === selectedRoleIds.length - 1) {
      newSelectedCategoryIds = newSelectedCategoryIds.concat(
        selectedRoleIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCategoryIds = newSelectedCategoryIds.concat(
        selectedRoleIds.slice(0, selectedIndex),
        selectedRoleIds.slice(selectedIndex + 1)
      );
    }
    setSelectedRoleIds(newSelectedCategoryIds);
  };

  const deleteSelectedCategoryHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const editRoleHandler = roleData => e => {
    navigate('/app/edit-role', { state: roleData });
  };

  const viewRoleHandler = roleData => e => {
    navigate('/app/view-role', { state: roleData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedRoleIds.filter(record => {
                      for (let i = 0; i < allRoleNoFilter.length; i++) {
                        if (record === allRoleNoFilter[i].Role_Name) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allRole.length}
                    color="primary"
                    indeterminate={
                      selectedRoleIds.filter(record => {
                        for (let i = 0; i < allRoleNoFilter.length; i++) {
                          if (record === allRoleNoFilter[i].Role_Name) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedRoleIds.filter(record => {
                        for (let i = 0; i < allRoleNoFilter.length; i++) {
                          if (record === allRoleNoFilter[i].Role_Name) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allRole.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions&nbsp;&nbsp;
                  {selectedRoleIds.filter(record => {
                    for (let i = 0; i < allRoleNoFilter.length; i++) {
                      if (record === allRoleNoFilter[i].Role_Name) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedRoleIds.filter(record => {
                    for (let i = 0; i < allRoleNoFilter.length; i++) {
                      if (record === allRoleNoFilter[i].Role_Name) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedCategoryHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allRole) &&
                (allRole).map(role => (
                  <TableRow
                    hover
                    key={role.Role_Name}
                    selected={
                      selectedRoleIds.indexOf(role.Role_Name) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedRoleIds.indexOf(role.Role_Name) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, role.Role_Name)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell>{role.Role_Name}</TableCell>
                    <TableCell>{role.Created_By?.substring(0, 12)}</TableCell>
                    <TableCell style={{ width: "16%", color: '#1665D8' }}>
                      {role.Modified_By?.substring(0, 12)}
                    </TableCell>
                    <TableCell width="16%" ><div style={{ maxHeight: "40px", overflowY: "hidden", lor: '#1665D8' }} dangerouslySetInnerHTML={{ __html: role.Role_Description }}></div>
                    </TableCell>
                    <TableCell width="11%">
                      {role.Status}
                    </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Role_Management_Write?.Value === "Role_Management_Write" && RolePermissions?.Role_Management_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Button
                          onClick={editRoleHandler(role)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                          <DeleteIcon
                            onClick={deleteRoleHandler(role)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                        <Button>
                          <VisibilityIcon
                            onClick={viewRoleHandler(role)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Role_Management_Read?.Value === "Role_Management_Read" && RolePermissions?.Role_Management_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Button>
                          <VisibilityIcon
                            onClick={viewRoleHandler(role)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allRole) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allRole: PropTypes.array.isRequired
};

export default Results;