import {
    SUBSCRIPTION_USER_PRODUCT_REQUEST,
    SUBSCRIPTION_USER_PRODUCT_SUCCESS,
    SUBSCRIPTION_USER_PRODUCT_FAIL,
    SUBSCRIPTION_USER_PRODUCT_GET_REQUEST,
    SUBSCRIPTION_USER_PRODUCT_GET_SUCCESS,
    SUBSCRIPTION_USER_PRODUCT_GET_FAIL,
    SUBSCRIPTION_USER_PRODUCT_UPDATE_REQUEST,
    SUBSCRIPTION_USER_PRODUCT_UPDATE_SUCCESS,
    SUBSCRIPTION_USER_PRODUCT_UPDATE_FAIL,
    SUBSCRIPTION_USER_PRODUCT_FILTER,
    SUBSCRIPTION_USER_PRODUCT_CREATE_REQUEST,
    SUBSCRIPTION_USER_PRODUCT_CREATE_SUCCESS,
    SUBSCRIPTION_USER_PRODUCT_CREATE_FAIL,
    SUBSCRIPTION_PARTNERS_REQUEST,
    SUBSCRIPTION_PARTNERS_SUCCESS,
    SUBSCRIPTION_PARTNERS_FAIL,
    SUBSCRIPTION_PRODUCTS_REQUEST,
    SUBSCRIPTION_PRODUCTS_SUCCESS,
    SUBSCRIPTION_PRODUCTS_FAIL,
    SUBSCRIPTION_PARTNER_PRODUCTS_REQUEST,
    SUBSCRIPTION_PARTNER_PRODUCTS_SUCCESS,
    SUBSCRIPTION_PARTNER_PRODUCTS_FAIL,
} from '../actions/subscriptionuserproductAction';

const initialState = {
    subscriptionproducts: { all: [], error: '', isLoading: false },
    subscriptionproduct: { subscriptionproducts: {}, error: '', isLoading: false },
    subscriptionproductUpdate: { subscriptionproducts: {}, error: '', isLoading: false },
    subscriptionproductCreate: { subscriptionproducts: {}, error: '', isLoading: false },
    subscriptionproductFilter: { select: 'All', search: '' },
    PhysicalPartners: { partners: [], error: '', isLoading: false },
    Products: { products: [], error: '', isLoading: false },
    PartnerProducts: { partnerproducts: [], error: '', isLoading: false },
};


export default function (state = initialState, action) {
    switch (action.type) {
        case SUBSCRIPTION_USER_PRODUCT_REQUEST:
            return { ...state, subscriptionproducts: { all: state.subscriptionproducts.all, error: '', isLoading: true } };
        case SUBSCRIPTION_USER_PRODUCT_SUCCESS:
            return { ...state, subscriptionproducts: { all: action.payload, error: '', isLoading: false } };
        case SUBSCRIPTION_USER_PRODUCT_FAIL:
            return { ...state, subscriptionproducts: { all: [], error: action.payload, isLoading: false } };
        case SUBSCRIPTION_USER_PRODUCT_GET_REQUEST:
            return { ...state, subscription: { subscription: {}, error: '', isLoading: true } };
        case SUBSCRIPTION_USER_PRODUCT_GET_SUCCESS:
            return { ...state, subscriptionproduct: { subscriptionproduct: {}, error: '', isLoading: true } };
        case SUBSCRIPTION_USER_PRODUCT_GET_FAIL:
            return { ...state, subscriptionproduct: { subscriptionproduct: action.payload, error: '', isLoading: false } };
        case SUBSCRIPTION_USER_PRODUCT_CREATE_REQUEST:
            return { ...state, subscriptionproductCreate: { subscriptionproduct: {}, error: '', isLoading: true } };
        case SUBSCRIPTION_USER_PRODUCT_CREATE_SUCCESS:
            return { ...state, subscriptionproductCreate: { subscriptionproduct: state.subscriptionproductCreate.subscriptionproduct, error: '', isLoading: false } };
        case SUBSCRIPTION_USER_PRODUCT_CREATE_FAIL:
            return { ...state, subscriptionproductCreate: { subscriptionproduct: {}, error: action.payload, isLoading: false } };
        case SUBSCRIPTION_USER_PRODUCT_UPDATE_REQUEST:
            return { ...state, subscriptionproductUpdate: { subscriptionproduct: {}, error: '', isLoading: true } };
        case SUBSCRIPTION_USER_PRODUCT_UPDATE_SUCCESS:
            return { ...state, subscriptionproductUpdate: { subscriptionproduct: state.subscriptionproductUpdate.subscriptionproduct, error: '', isLoading: false } };
        case SUBSCRIPTION_USER_PRODUCT_UPDATE_FAIL:
            return { ...state, subscriptionproductUpdate: { subscriptionproduct: {}, error: action.payload, isLoading: false } };
        case SUBSCRIPTION_PARTNERS_REQUEST:
            return { ...state, PhysicalPartners: { partners: state.PhysicalPartners.partners, error: '', isLoading: true }, };
        case SUBSCRIPTION_PARTNERS_SUCCESS:
            return { ...state, PhysicalPartners: { partners: action.payload, error: '', isLoading: false } };
        case SUBSCRIPTION_PARTNERS_FAIL:
            return { ...state, PhysicalPartners: { partners: [], error: action.payload, isLoading: false } };
        case SUBSCRIPTION_PRODUCTS_REQUEST:
            return { ...state, Products: { products: state.Products.products, error: '', isLoading: true }, };
        case SUBSCRIPTION_PRODUCTS_SUCCESS:
            return { ...state, Products: { products: action.payload, error: '', isLoading: false } };
        case SUBSCRIPTION_PRODUCTS_FAIL:
            return { ...state, Products: { products: [], error: action.payload, isLoading: false } };
            case SUBSCRIPTION_PARTNER_PRODUCTS_REQUEST:
                return { ...state, PartnerProducts: { partnerproducts: state.PartnerProducts.partnerproducts, error: '', isLoading: true }, };
            case SUBSCRIPTION_PARTNER_PRODUCTS_SUCCESS:
                return { ...state, PartnerProducts: { partnerproducts: action.payload, error: '', isLoading: false } };
            case SUBSCRIPTION_PARTNER_PRODUCTS_FAIL:
                return { ...state, PartnerProducts: { partnerproducts: [], error: action.payload, isLoading: false } };
            case SUBSCRIPTION_USER_PRODUCT_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, subscriptionproductFilter: { search: state.subscriptionproductFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, subscriptionproductFilter: { select: state.subscriptionproductFilter.select, search: action.value } };
            }
            return res;
        default:
            return state;
    }
}