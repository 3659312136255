import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import Results from './Results';
import Toolbar from './Toolbar';
import ConfirmDialog from '../../components/dialogs/confirmDialog'
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';
import { deletecorporateAwardById } from 'src/store/actions/corporateawardAction';
import { paginationAllCorpAWARDGet } from 'src/store/actions/node-actions/nodepagination';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CorporateAwardListView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [corpaward, setCorpaward] = useState([]);

  //permissions
  const RolePermissions = useSelector(state => state.rolepermission.Roles);
  const roleUserType = useSelector(state => state.auth.user);
  const Login_Details = useSelector(state => state.auth.user);

  const [filters, setFilters] = React.useState({
    "Award_Id": "",
    "Search_By_Filter": "All",
    "Search": ""
  });

  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = useState({ page: 1, limit: 10, sortBy: "createdAt:desc" })

  const handleLimitChange = event => {
    setPagination({ ...pagination, limit: +event.target.value, page: 1, sortBy: "createdAt:desc" });
  };

  const handlePageChange = (event, newPage) => {
    setPagination({ ...pagination, limit: pagination.limit, page: newPage + 1, sortBy: "createdAt:desc" });
  };

  const callBackPagination = async (status, data, pagination) => {
    if (status) {
      setCorpaward(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({ page: 1, limit: 10, sortBy: "createdAt:desc" });
    }
  }

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      corpawardCall();
    }
  }, [pagination])

  const [testFilters, setTestFilters] = useState({});
  useEffect(() => {
    if (JSON.stringify(testFilters) != JSON.stringify(filters)) {
      setTestFilters(filters);
      searchFunction()
    }
  }, [filters]);

  const searchFunction = () => {
    const searchpagination = {
      page: 1, limit: 10, sortBy: "createdAt:desc"
    }
    dispatch(paginationAllCorpAWARDGet(filters, searchpagination, callBackPagination))
  }

  const corpawardCall = () => {
    dispatch(paginationAllCorpAWARDGet(filters, pagination, callBackPagination))
  };

  useEffect(() => {
    dispatch(getRolePermissions(Login_Details));
    dispatch(paginationAllCorpAWARDGet(filters, pagination, callBackPagination))
  }, []);

  // confirm dailogbox
  const [bopen, setBopen] = useState(false);
  const [btitle, setBtitle] = useState('');
  const [bmessage, setBmessage] = useState('');
  const [corpawardIdle, setcorporateAwardIdle] = useState([]);

  const bhandleClose = (value) => {
    setBopen(false);
    if (value) {
      const deleteData = {
        Template_Id: corpawardIdle?._id ? [corpawardIdle?._id] : corpawardIdle
      };
      dispatch(deletecorporateAwardById(deleteData, filters, { page: 1, limit: 10, sortBy: "createdAt:desc" }, callBackPagination));
    }
  };

  //Delete handler
  const deletecorporateAwardHandler = corpawardData => async e => {
    setBopen(true);
    setBtitle("Alert!");
    setBmessage("Are you sure to delete?");
    setcorporateAwardIdle(corpawardData);
  };

  // Sorting 
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPagination({ page: 1, limit: pagination?.limit, sortBy: order === 'asc' ? "createdAt:asc" : "createdAt:desc" })
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/corporate-management"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h3" gutterBottom>
            Corporate Award Management
          </Typography>
        </Box>
        <Toolbar filters={filters} setfilters={setFilters} RolePermissions={RolePermissions} roleUserType={roleUserType} />
        <Box mt={3}>
          <Results
            allcorporateAwards={corpaward}
            deletecorporateAwardHandler={deletecorporateAwardHandler}
            allcorporateAwardsNoFilter={corpaward}
            handleLimitChange={handleLimitChange}
            handlePageChange={handlePageChange}
            order={order}
            orderBy={orderBy}
            pagination={pagination}
            setPagination={setPagination}
            createSortHandler={createSortHandler}
            RolePermissions={RolePermissions}
            roleUserType={roleUserType}
          />
        </Box>
      </Container>
      <ConfirmDialog inputMessage={bmessage} titleMsg={btitle} open={bopen} onClose={bhandleClose} />
    </Page>
  );
};

export default connect()(CorporateAwardListView);