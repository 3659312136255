import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AddUserAction, getAllRoles } from '../../../store/actions/employeeprofileAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/material.css'
import { CircularProgress } from '@mui/material';

const AddUser = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobile, setMobile] = useState();

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  const [errorMessage, setErrorMessage] = useState({ Email_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.user.userCreate.isLoading);
  const roles = useSelector(state => state.user.Roles.roles);

  return (
    <Formik
      initialValues={{
        Email_Id: '',
        Mobile: '',
        User_Type: ''
      }}

      validationSchema={Yup.object().shape({
        Email_Id: Yup.string().email("Please provide valid Email ID. ").notOneOf([errorMessage.Email_Id.key, null], errorMessage.Email_Id.message).required('Email ID is required.'),
        Mobile: Yup.string().min(14, "Please provide a valid Mobile Number.").required('Mobile Number is required.'),
        User_Type: Yup.string().required('User Type is required.'),
      })}

      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        if (values) {
          let formData = JSON.parse(JSON.stringify(values));
          dispatch(AddUserAction(formData, navigate, setErrorMessage, setErrors));
        }
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Email_Id && errors.Email_Id)}
                        fullWidth
                        helperText={touched.Email_Id && errors.Email_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Email ID"
                        name="Email_Id"
                        value={values.Email_Id}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Mobile */}
                    <Grid item md={6} xs={12}>
                      <PhoneInput
                        containerClass={touched.Mobile && errors.Mobile ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                        inputClass={"form-control MuiOutlinedInput-input"}
                        error={true}
                        country={"in"}
                        fullWidth
                        inputStyle={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px"
                        }}
                        inputProps={{
                          name: "Mobile",
                        }}
                        specialLabel="Mobile Number"
                        placeholder="+91 XXXXX XXXXX"
                        name="Mobile"
                        onBlur={handleBlur}
                        onChange={(value, country, e, formattedValue) => {
                          setFieldValue("Mobile", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                          setMobile(formattedValue)
                        }}
                        value={values.Mobile}
                      />
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Mobile && errors.Mobile}</p>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.User_Type && errors.User_Type)}
                        fullWidth
                        helperText={touched.User_Type && errors.User_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="User Type"
                        name="User_Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.User_Type}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {roles?.sort((a, b) => a.Role_Name?.localeCompare(b.Role_Name))?.map(option => (
                          <option key={option.Role_Name} value={option.Role_Name}>
                            {option.Role_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2}>
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>

            <Box display="flex" pl={2} pt={1} pb={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddUser.propTypes = {
  className: PropTypes.string
};

export default AddUser;