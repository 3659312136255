import {

    BIDDING_USER_PRODUCTS_CREATE_REQUEST,
    BIDDING_USER_PRODUCTS_CREATE_SUCCESS,
    BIDDING_USER_PRODUCTS_CREATE_FAIL,
    BIDDING_USER_PRODUCTS_UPDATE_REQUEST,
    BIDDING_USER_PRODUCTS_UPDATE_SUCCESS,
    BIDDING_USER_PRODUCTS_UPDATE_FAIL,
    BIDDING_PRODUCTS_REQUEST,
    BIDDING_PRODUCTS_SUCCESS,
    BIDDING_PRODUCTS_FAIL,
    BID_PRODUCTS_USER_EMAIL_REQUEST,
    BID_PRODUCTS_USER_EMAIL_SUCCESS,
    BID_PRODUCTS_USER_EMAIL_FAIL,
    PARTNER_BIDDING_QUOTES_REQUEST,
    PARTNER_BIDDING_QUOTES_SUCCESS,
    PARTNER_BIDDING_QUOTES_FAIL,
    PARTNER_PAYMNETS_REQUEST,
    PARTNER_PAYMNETS_SUCCESS,
    PARTNER_PAYMNETS_FAIL,

} from '../actions/biddinguserproductsAction';

const initialState = {
    biddinguserproductCreate: { biddinguserproduct: {}, error: '', isLoading: false },
    biddinguserproductUpdate: { biddinguserproduct: {}, error: '', isLoading: false },
    Biddingproducts: { products: [], error: '', isLoading: false },
    BidUserEmails: { biduseremails: [], error: '', isLoading: false },
    PartnerBiddingQuotes: { quotes: [], error: '', isLoading: false },
    PartnerPayments: { payments: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case BIDDING_USER_PRODUCTS_CREATE_REQUEST:
            return { ...state, biddinguserproductCreate: { biddinguserproduct: {}, error: '', isLoading: true } };
        case BIDDING_USER_PRODUCTS_CREATE_SUCCESS:
            return { ...state, biddinguserproductCreate: { biddinguserproduct: state.biddinguserproductCreate.biddinguserproduct, error: '', isLoading: false } };
        case BIDDING_USER_PRODUCTS_CREATE_FAIL:
            return { ...state, biddinguserproductCreate: { biddinguserproduct: {}, error: action.payload, isLoading: false } };
       case BIDDING_USER_PRODUCTS_UPDATE_REQUEST:
            return { ...state, biddinguserproductUpdate: { biddinguserproduct: {}, error: '', isLoading: true } };
        case BIDDING_USER_PRODUCTS_UPDATE_SUCCESS:
            return { ...state, biddinguserproductUpdate: { biddinguserproduct: state.biddinguserproductUpdate.biddinguserproduct, error: '', isLoading: false } };
        case BIDDING_USER_PRODUCTS_UPDATE_FAIL:
            return { ...state, biddinguserproductUpdate: { biddinguserproduct: {}, error: action.payload, isLoading: false } };

            case BIDDING_PRODUCTS_REQUEST:
                return { ...state, Biddingproducts: { products: state.Biddingproducts.all, error: '', isLoading: true } };
            case BIDDING_PRODUCTS_SUCCESS:
                return { ...state, Biddingproducts: { products: action.payload, error: '', isLoading: false } };
            case BIDDING_PRODUCTS_FAIL:
                return { ...state, Biddingproducts: { products: [], error: action.payload, isLoading: false } };

                case BID_PRODUCTS_USER_EMAIL_REQUEST:
                    return { ...state, BidUserEmails: { biduseremails: state.BidUserEmails.biduseremails, error: '', isLoading: true }, };
                case BID_PRODUCTS_USER_EMAIL_SUCCESS:
                    return { ...state, BidUserEmails: { biduseremails: action.payload, error: '', isLoading: false } };
                case BID_PRODUCTS_USER_EMAIL_FAIL:
                    return { ...state, BidUserEmails: { biduseremails: [], error: action.payload, isLoading: false } };
        
                case PARTNER_BIDDING_QUOTES_REQUEST:
                    return { ...state, PartnerBiddingQuotes: { quotes: state.PartnerBiddingQuotes.quotes, error: '', isLoading: true }, };
                case PARTNER_BIDDING_QUOTES_SUCCESS:
                    return { ...state, PartnerBiddingQuotes: { quotes: action.payload, error: '', isLoading: false } };
                case PARTNER_BIDDING_QUOTES_FAIL:
                    return { ...state, PartnerBiddingQuotes: { quotes: [], error: action.payload, isLoading: false } };
        
                    case PARTNER_PAYMNETS_REQUEST:
                    return { ...state, PartnerPayments: { payments: state.PartnerPayments.payments, error: '', isLoading: true }, };
                case PARTNER_PAYMNETS_SUCCESS:
                    return { ...state, PartnerPayments: { payments: action.payload, error: '', isLoading: false } };
                case PARTNER_PAYMNETS_FAIL:
                    return { ...state, PartnerPayments: { payments: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}
