import {
    BIDDING_SERVICE_QUOTE_CREATE_REQUEST,
    BIDDING_SERVICE_QUOTE_CREATE_SUCCESS,
    BIDDING_SERVICE_QUOTE_CREATE_FAIL,
    BIDDING_SERVICE_QUOTE_UPDATE_REQUEST,
    BIDDING_SERVICE_QUOTE_UPDATE_SUCCESS,
    BIDDING_SERVICE_QUOTE_UPDATE_FAIL,
    BIDDING_USER_SERVICE_REQUEST,
    BIDDING_USER_SERVICE_SUCCESS,
    BIDDING_USER_SERVICE_FAIL,
    PHYSICAL_PARTNERS_REQUEST,
    PHYSICAL_PARTNERS_SUCCESS,
    PHYSICAL_PARTNERS_FAIL,
    BIDDING_PARTNER_AVAIL_SERVICES_REQUEST,
    BIDDING_PARTNER_AVAIL_SERVICES_SUCCESS,
    BIDDING_PARTNER_AVAIL_SERVICES_FAIL
} from '../actions/partnerbiddingservicequoteAction';

const initialState = {
    biddingpartnerservicequoteUpdate: { partnerbiddingservicequotes: {}, error: '', isLoading: false },
    biddingpartnerservicequoteCreate: { partnerbiddingservicequotes: {}, error: '', isLoading: false },
    biddinguserservices: { all: [], error: '', isLoading: false },
    biddingservicepartners: { all: [], error: '', isLoading: false },
    biddingpartneravailservices: { all: [], error: '', isLoading: false },
};


export default function (state = initialState, action) {
    switch (action.type) {
        case BIDDING_SERVICE_QUOTE_CREATE_REQUEST:
            return { ...state, biddingpartnerservicequoteCreate: { partnerbiddingservicequotes: {}, error: '', isLoading: true } };
        case BIDDING_SERVICE_QUOTE_CREATE_SUCCESS:
            return { ...state, biddingpartnerservicequoteCreate: { partnerbiddingservicequotes: state.biddingpartnerservicequoteCreate.partnerbiddingservicequotes, error: '', isLoading: false } };
        case BIDDING_SERVICE_QUOTE_CREATE_FAIL:
            return { ...state, biddingpartnerservicequoteCreate: { partnerbiddingservicequotes: {}, error: action.payload, isLoading: false } };
        case BIDDING_SERVICE_QUOTE_UPDATE_REQUEST:
            return { ...state, biddingpartnerservicequoteUpdate: { partnerbiddingservicequotes: {}, error: '', isLoading: true } };
        case BIDDING_SERVICE_QUOTE_UPDATE_SUCCESS:
            return { ...state, biddingpartnerservicequoteUpdate: { partnerbiddingservicequotes: state.biddingpartnerservicequoteUpdate.partnerbiddingservicequotes, error: '', isLoading: false } };
        case BIDDING_SERVICE_QUOTE_UPDATE_FAIL:
            return { ...state, biddingpartnerservicequoteUpdate: { partnerbiddingservicequotes: {}, error: action.payload, isLoading: false } };

        case BIDDING_USER_SERVICE_REQUEST:
            return { ...state, biddinguserservices: { all: state.biddinguserservices.all, error: '', isLoading: true } };
        case BIDDING_USER_SERVICE_SUCCESS:
            return { ...state, biddinguserservices: { all: action.payload, error: '', isLoading: false } };
        case BIDDING_USER_SERVICE_FAIL:
            return { ...state, biddinguserservices: { all: [], error: action.payload, isLoading: false } };

        case PHYSICAL_PARTNERS_REQUEST:
            return { ...state, biddingservicepartners: { all: state.biddingservicepartners.all, error: '', isLoading: true } };
        case PHYSICAL_PARTNERS_SUCCESS:
            return { ...state, biddingservicepartners: { all: action.payload, error: '', isLoading: false } };
        case PHYSICAL_PARTNERS_FAIL:
            return { ...state, biddingservicepartners: { all: [], error: action.payload, isLoading: false } };

        case BIDDING_PARTNER_AVAIL_SERVICES_REQUEST:
            return { ...state, biddingpartneravailservices: { all: state.biddingpartneravailservices.all, error: '', isLoading: true } };
        case BIDDING_PARTNER_AVAIL_SERVICES_SUCCESS:
            return { ...state, biddingpartneravailservices: { all: action.payload, error: '', isLoading: false } };
        case BIDDING_PARTNER_AVAIL_SERVICES_FAIL:
            return { ...state, biddingpartneravailservices: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}