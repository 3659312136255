import config from './config';
import { toast } from 'react-toastify';
import { paginationAllUserBidServicesGet } from './node-actions/nodepagination';
import { boomiAdminApi, nodeCudApi, nodeGetApi } from './commonAxios';

export const BIDDING_USER_SERVICE_CREATE_REQUEST = "BIDDING_USER_SERVICE_CREATE_REQUEST";
export const BIDDING_USER_SERVICE_CREATE_SUCCESS = "BIDDING_USER_SERVICE_CREATE_SUCCESS";
export const BIDDING_USER_SERVICE_CREATE_FAIL = "BIDDING_USER_SERVICE_CREATE_FAIL";
export const BIDDING_USER_SERVICE_UPDATE_REQUEST = "BIDDING_USER_SERVICE_UPDATE_REQUEST";
export const BIDDING_USER_SERVICE_UPDATE_SUCCESS = "BIDDING_USER_SERVICE_UPDATE_SUCCESS";
export const BIDDING_USER_SERVICE_UPDATE_FAIL = "BIDDING_USER_SERVICE_UPDATE_FAIL";

export const BIDDING_SERVICES_REQUEST = "BIDDING_SERVICES_REQUEST";
export const BIDDING_SERVICES_SUCCESS = "BIDDING_SERVICES_SUCCESS";
export const BIDDING_SERVICES_FAIL = "BIDDING_SERVICES_FAIL";

export const BID_SERVICES_USER_EMAIL_REQUEST = "BID_SERVICES_USER_EMAIL_REQUEST";
export const BID_SERVICES_USER_EMAIL_SUCCESS = "BID_SERVICES_USER_EMAIL_SUCCESS";
export const BID_SERVICES_USER_EMAIL_FAIL = "BID_SERVICES_USER_EMAIL_FAIL";

export const PARTNER_SERVICES_BIDDING_QUOTES_REQUEST = "PARTNER_SERVICES_BIDDING_QUOTES_REQUEST";
export const PARTNER_SERVICES_BIDDING_QUOTES_SUCCESS = "PARTNER_SERVICES_BIDDING_QUOTES_SUCCESS";
export const PARTNER_SERVICES_BIDDING_QUOTES_FAIL = "PARTNER_SERVICES_BIDDING_QUOTES_FAIL";

export const PARTNER_PAYMENTS_REQUEST = "PARTNER_PAYMENTS_REQUEST";
export const PARTNER_PAYMENTS_SUCCESS = "PARTNER_PAYMENTS_SUCCESS";
export const PARTNER_PAYMENTS_FAIL = "PARTNER_PAYMENTS_FAIL";

// Add User Bidding Service Action
export const AddBiddingUserServiceAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: BIDDING_USER_SERVICE_CREATE_REQUEST
  });
  nodeCudApi.post(`/Bidding_User_Service/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BIDDING_USER_SERVICE_CREATE_SUCCESS
        });
        toast('Bidding User Service added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/bidding-user-services');
      } else {
        dispatch({
          type: BIDDING_USER_SERVICE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: BIDDING_USER_SERVICE_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

// Update Bidding User Service Action
export const UpdateBiddingUserServiceAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: BIDDING_USER_SERVICE_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Bidding_User_Service/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BIDDING_USER_SERVICE_UPDATE_SUCCESS
        });
        toast('Bidding User Service updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/bidding-user-services');
      } else {

        dispatch({
          type: BIDDING_USER_SERVICE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: BIDDING_USER_SERVICE_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

//Get All User Address Get
export const getAllUserAddress = (formData, callBacksubtypeData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/User/Address_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=USER_EMAIL_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === "0") {
        callBacksubtypeData([])
      } else {
        callBacksubtypeData(data.results)
      }
    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};

//get All bidding services
export const getAllBiddingServices = () => async dispatch => {
  try {
    let formData = {
      "search_by_filter": "",
      "search": "",
      "Bidding_Service_Id": "",
      "Service_Type": "",
      "Records_Filter": ""
    };
    dispatch({
      type: BIDDING_SERVICES_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin/Bidding_Services?PageNo=1&PageSize=${config.pageSize}&SortBy=BIDDING_SERVICE_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: BIDDING_SERVICES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BIDDING_SERVICES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: BIDDING_SERVICES_FAIL,
      payload: err
    });
  }
};


//Get All User Email Get
export const getAllServicesUserEmails = (emailid) => async dispatch => {
  try {
    let formData = {
      Email_Id: emailid,
      Records_Filter: "FILTER"
    };
    dispatch({
      type: BID_SERVICES_USER_EMAIL_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin/User_Get_dropdown_search?PageNo=1&PageSize=${config.pageSize}&SortBy=Email_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: BID_SERVICES_USER_EMAIL_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BID_SERVICES_USER_EMAIL_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: BID_SERVICES_USER_EMAIL_FAIL,
      payload: err
    });
  }
};


//Get All Partner Services Bidding quotes
export const getAllPartnerServicesBiddingQuotes = (formData) => async dispatch => {
  try {
    dispatch({
      type: PARTNER_SERVICES_BIDDING_QUOTES_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin/Partner_Bidding_Service_Quotes?PageNo=1&PageSize=${config.pageSize}&SortBy=Partner_Bidding_Service_Quote_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNER_SERVICES_BIDDING_QUOTES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNER_SERVICES_BIDDING_QUOTES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PARTNER_SERVICES_BIDDING_QUOTES_FAIL,
      payload: err
    });
  }
};


//Get All Bidding Products
export const getAllbiddingservices = (formData, callBacksubtypeservicesData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Super_Admin/Bidding_Services?PageNo=1&PageSize=${config.pageSize}&SortBy=BIDDING_SERVICE_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === "0") {
        callBacksubtypeservicesData([])
      } else {
        callBacksubtypeservicesData(data.results)
      }
    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};

// Alert Notification for Service Action

export const AlertServiceAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/Notifications/Services`, formData)
    if (data) {
      dispatch(paginationAllUserBidServicesGet(filters, pagination, callBackPagination));
      toast('Trigger the notification successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    }
  } catch (err) {
  }
};


//PartnerPayments Services Action
export const UserServicesPartnerPayAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: PARTNER_PAYMENTS_REQUEST
  });
  boomiAdminApi.post(`/Bidding_Service/Service_Payment`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNER_PAYMENTS_SUCCESS
        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/bidding-user-services');
      } else {
        dispatch({
          type: PARTNER_PAYMENTS_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_PAYMENTS_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

//Get Bidding User Service
export const getBiddingUserServiceAction = (formData, callBackBiddingServiceData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Super_Admin/User_Services_Bidding`, formData);
    if (data) {
      callBackBiddingServiceData(data)
    }
  } catch (err) {
  }
};