import {
  MEEPAISA_ZONE_THEME_CREATE_REQUEST,
  MEEPAISA_ZONE_THEME_CREATE_SUCCESS,
  MEEPAISA_ZONE_THEME_CREATE_FAIL,
  MEEPAISA_ZONE_THEME_UPDATE_REQUEST,
  MEEPAISA_ZONE_THEME_UPDATE_SUCCESS,
  MEEPAISA_ZONE_THEME_UPDATE_FAIL,
  MEEPAISA_ZONE_REQUEST,
  MEEPAISA_ZONE_SUCCESS,
  MEEPAISA_ZONE_FAIL,
  MEEPAISA_THEME_REQUEST,
  MEEPAISA_THEME_SUCCESS,
  MEEPAISA_THEME_FAIL
} from '../actions/meepaisazonethemeAction'

const initialState = {
  zonethemeCreate: { zonetheme: {}, error: '', isLoading: false },
  zonethemeUpdate: { zonetheme: {}, error: '', isLoading: false },
  Zones: { zones: [], error: '', isLoading: false },
  Themes: { themes: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {

    case MEEPAISA_ZONE_THEME_CREATE_REQUEST:
      return { ...state, zonethemeCreate: { zonetheme: {}, error: '', isLoading: true } };
    case MEEPAISA_ZONE_THEME_CREATE_SUCCESS:
      return { ...state, zonethemeCreate: { zonetheme: action.payload, error: '', isLoading: false } };
    case MEEPAISA_ZONE_THEME_CREATE_FAIL:
      return { ...state, zonethemeCreate: { zonetheme: {}, error: action.payload, isLoading: false } };

    case MEEPAISA_ZONE_THEME_UPDATE_REQUEST:
      return { ...state, zonethemeUpdate: { zonetheme: {}, error: '', isLoading: true } };
    case MEEPAISA_ZONE_THEME_UPDATE_SUCCESS:
      return { ...state, zonethemeUpdate: { zonetheme: action.payload, error: '', isLoading: false } };
    case MEEPAISA_ZONE_THEME_UPDATE_FAIL:
      return { ...state, zonethemeUpdate: { zonetheme: {}, error: action.payload, isLoading: false } };

    case MEEPAISA_ZONE_REQUEST:
      return { ...state, Zones: { zones: state.Zones.zones, error: '', isLoading: true }, };
    case MEEPAISA_ZONE_SUCCESS:
      return { ...state, Zones: { zones: action.payload, error: '', isLoading: false } };
    case MEEPAISA_ZONE_FAIL:
      return { ...state, Zones: { zones: [], error: action.payload, isLoading: false } };

    case MEEPAISA_THEME_REQUEST:
      return { ...state, Themes: { themes: state.Themes.themes, error: '', isLoading: true }, };
    case MEEPAISA_THEME_SUCCESS:
      return { ...state, Themes: { themes: action.payload, error: '', isLoading: false } };
    case MEEPAISA_THEME_FAIL:
      return { ...state, Themes: { themes: [], error: action.payload, isLoading: false } };
    default:
      return state;
  }
}
