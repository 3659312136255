import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Results from './Results';
import Toolbar from './Toolbar';
import {
  deleteRProductAction,
} from '../../../../store/actions/relatedProductAction';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from '.././../../../components/dialogs/confirmDialog'
import { useLocation } from 'react-router-dom';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';
import { paginationRelatedProducts } from 'src/store/actions/node-actions/nodepagination';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ProductMasterListView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const { state } = location;

  //permissions
  const RolePermissions = useSelector(state => state.rolepermission.Roles);
  const roleUserType = useSelector(state => state.auth.user);
  const Login_Details = useSelector(state => state.auth.user);

  const [relatedproduct, setRelatedProduct] = useState([]);
  const [filters, setFilters] = React.useState({
    search: '',
    search_by_filter: 'All',
    Records_Filter: 'FILTER',
    Product_Id: state.Product_Id,
  });

  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = useState({ PageNo: 1, PageSize: 10, SortBy: "Related_Prod_Id", SortOrder: "ASC" })

  const handleLimitChange = event => {
    setPagination({ ...pagination, PageSize: +event.target.value, PageNo: 1 });
  };
  const handlePageChange = (event, newPage) => {
    setPagination({ ...pagination, PageSize: pagination.PageSize, PageNo: newPage + 1 });
  };

  const callBackPagination = async (status, data, pagination) => {
    if (status) {
      setRelatedProduct(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({ PageNo: 1, PageSize: 10, SortBy: "Related_Prod_Id", SortOrder: "ASC" });
    }
  }

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      relatedproductsCall();
    }
  }, [pagination])

  const [testFilters, setTestFilters] = useState({});
  useEffect(() => {
    if (JSON.stringify(testFilters) != JSON.stringify(filters)) {
      setTestFilters(filters);
      searchFunction();
    }
  }, [filters]);

  const searchFunction = () => {
    const searchpagination = {
      PageNo: 1, PageSize: 10, SortBy: "Related_Prod_Id", SortOrder: "ASC"
    }
    dispatch(paginationRelatedProducts(filters, searchpagination, callBackPagination))
  }

  const relatedproductsCall = () => {
    dispatch(paginationRelatedProducts(filters, pagination, callBackPagination))
    dispatch(getRolePermissions(Login_Details));
  };

  let relatedproducts = []
  if (relatedproduct) {
    for (let i = 0; i < relatedproduct?.length; i++) {
      let data = relatedproduct[i].Related_Products
      for (let j = 0; j < data?.length; j++) {
        relatedproducts.push({
          Product_ID: relatedproduct[i].Product_ID,
          Related_Prod_Id: data[j].Related_Prod_Id,
          Related_Product_Of_Product_Id: data[j].Related_Product_Of_Product_Id,
          Created_Date: data[j].Created_Date,
          Created_By: data[j].Created_By,
        }
        )
      }
    }
  }

  const [product1, setProduct1] = useState(false);
  const [ptitle, setPtitle] = useState('');
  const [pmessage, setPmessage] = useState('');
  const [productIdle, setProductIdle] = useState('');
  const phandleClose = (value) => {
    setProduct1(false);
    if (value) {
      const deleteData = {
        Related_Product_Id: productIdle.Related_Prod_Id
      };
      dispatch(deleteRProductAction(deleteData, filters, pagination, callBackPagination));
    }
  };

  //Delete handler
  const deleteProductMasterHandler = productmasterData => async e => {
    setProduct1(true);
    setPtitle("Alert!");
    setPmessage("Are you sure to delete?");
    setProductIdle(productmasterData);
  };


  // Sorting 
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Related_Prod_Id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPagination({ PageNo: 1, PageSize: pagination?.PageSize, SortBy: property, SortOrder: order === 'asc' ? "ASC" : "DESC" })
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  useEffect(() => {
    dispatch(getRolePermissions(Login_Details));
    dispatch(paginationRelatedProducts(filters, pagination, callBackPagination))
  }, []);

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/product-management/products"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            {state.Product_Name}&nbsp;Related Products
          </Typography>
        </Box>
        <Toolbar state={state} RolePermissions={RolePermissions} roleUserType={roleUserType} filters={filters} setfilters={setFilters} />
        <Box mt={3}>
          <Results
            relatedproducts={relatedproduct}
            allProductMastersNoFilter={relatedproduct}
            deleteProductMasterHandler={deleteProductMasterHandler}
            pagination={pagination}
            setPagination={setPagination}
            handleLimitChange={handleLimitChange}
            handlePageChange={handlePageChange}
            order={order}
            orderBy={orderBy}
            createSortHandler={createSortHandler}
            state={state}
            RolePermissions={RolePermissions}
            roleUserType={roleUserType}
          />
        </Box>
      </Container>
      <ConfirmDialog inputMessage={pmessage} titleMsg={ptitle} open={product1} onClose={phandleClose} />
    </Page>
  );
};

export default ProductMasterListView;