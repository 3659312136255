import { PARTNER_CITY_LOCATION_CREATE_FAIL, PARTNER_CITY_LOCATION_CREATE_REQUEST, PARTNER_CITY_LOCATION_CREATE_SUCCESS, PARTNER_CITY_LOCATION_DELETE_FAIL, PARTNER_CITY_LOCATION_DELETE_REQUEST, PARTNER_CITY_LOCATION_DELETE_SUCCESS, PARTNER_CITY_LOCATION_FAIL, PARTNER_CITY_LOCATION_FILTER, PARTNER_CITY_LOCATION_GET_FAIL, PARTNER_CITY_LOCATION_GET_REQUEST, PARTNER_CITY_LOCATION_GET_SUCCESS, PARTNER_CITY_LOCATION_REQUEST, PARTNER_CITY_LOCATION_SUCCESS, PARTNER_CITY_LOCATION_UPDATE_FAIL, CITY_LOCATION_REQUEST, CITY_LOCATION_SUCCESS, CITY_LOCATION_FAIL, PARTNER_CITY_LOCATION_UPDATE_REQUEST, PARTNER_CITY_LOCATION_UPDATE_SUCCESS } from "../actions/partnercitylocationAction";


const initialState = {
  partnercitylocations: { all: [], error: '', isLoading: false },
  citylocationids: { locationids: [], error: '', isLoading: false },
  partnercitylocation: { partnercitylocation: {}, error: '', isLoading: false },
  partnercitylocationCreate: { partnercitylocation: {}, error: '', isLoading: false },
  partnercitylocationUpdate: { partnercitylocation: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PARTNER_CITY_LOCATION_REQUEST:
      return { ...state, partnercitylocations: { all: state.partnercitylocations.all, error: '', isLoading: true } };
    case PARTNER_CITY_LOCATION_SUCCESS:
      return { ...state, partnercitylocations: { all: action.payload, error: '', isLoading: false } };
    case PARTNER_CITY_LOCATION_FAIL:
      return { ...state, partnercitylocations: { all: [], error: action.payload, isLoading: false } };
    case CITY_LOCATION_REQUEST:
      return { ...state, citylocationids: { locationids: state.citylocationids.locationids, error: '', isLoading: true } };
    case CITY_LOCATION_SUCCESS:
      return { ...state, citylocationids: { locationids: action.payload, error: '', isLoading: false } };
    case CITY_LOCATION_FAIL:
      return { ...state, citylocationids: { locationids: [], error: action.payload, isLoading: false } };

    case PARTNER_CITY_LOCATION_GET_REQUEST:
      return { ...state, partnercitylocation: { partnercitylocation: {}, error: '', isLoading: true } };
    case PARTNER_CITY_LOCATION_GET_SUCCESS:
      return { ...state, partnercitylocation: { partnercitylocation: action.payload, error: '', isLoading: false } };
    case PARTNER_CITY_LOCATION_GET_FAIL:
      return { ...state, partnercitylocation: { partnercitylocation: {}, error: action.payload, isLoading: false } };

    case PARTNER_CITY_LOCATION_CREATE_REQUEST:
      return { ...state, partnercitylocationCreate: { partnercitylocation: {}, error: '', isLoading: true } };
    case PARTNER_CITY_LOCATION_CREATE_SUCCESS:
      return { ...state, partnercitylocationCreate: { partnercitylocation: action.payload, error: '', isLoading: false } };
    case PARTNER_CITY_LOCATION_CREATE_FAIL:
      return { ...state, partnercitylocationCreate: { partnercitylocation: {}, error: action.payload, isLoading: false } };

    case PARTNER_CITY_LOCATION_UPDATE_REQUEST:
      return { ...state, partnercitylocationUpdate: { partnercitylocation: {}, error: '', isLoading: true } };
    case PARTNER_CITY_LOCATION_UPDATE_SUCCESS:
      return { ...state, partnercitylocationUpdate: { partnercitylocation: action.payload, error: '', isLoading: false } };
    case PARTNER_CITY_LOCATION_UPDATE_FAIL:
      return { ...state, partnercitylocationUpdate: { partnercitylocation: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}