import config from './config';
import { toast } from 'react-toastify';
import { nodeGetApi, boomiAdminApi } from './commonAxios';

export const DELIVERY_UPDATES_REQUEST = "DELIVERY_UPDATES_REQUEST";
export const DELIVERY_UPDATES_SUCCESS = "DELIVERY_UPDATES_SUCCESS";
export const DELIVERY_UPDATES_FAIL = "DELIVERY_UPDATES_FAIL";
export const DELIVERY_REQUEST = "DELIVERY_REQUEST";
export const DELIVERY_SUCCESS = "DELIVERY_SUCCESS";
export const DELIVERY_FAIL = "DELIVERY_FAIL";
export const DELIVERY_AGENT_APPROVAL_REQUEST = "DELIVERY_AGENT_APPROVAL_REQUEST";
export const DELIVERY_AGENT_APPROVAL_SUCCESS = "DELIVERY_AGENT_APPROVAL_SUCCESS";
export const DELIVERY_AGENT_APPROVAL_FAIL = "DELIVERY_AGENT_APPROVAL_FAIL";
export const DELIVERY_AGENT_REJECT_REQUEST = "DELIVERY_AGENT_REJECT_REQUEST";
export const DELIVERY_AGENT_REJECT_SUCCESS = "DELIVERY_AGENT_REJECT_SUCCESS";
export const DELIVERY_AGENT_REJECT_FAIL = "DELIVERY_AGENT_REJECT_FAIL";
export const DELIVERY_CREATE_REQUEST = "DELIVERY_CREATE_REQUEST";
export const DELIVERY_CREATE_SUCCESS = "DELIVERY_CREATE_SUCCESS";
export const DELIVERY_CREATE_FAIL = "DELIVERY_CREATE_FAIL";
export const DELIVERY_AGENT_CURRENT_ADDRESS_REQUEST = "DELIVERY_AGENT_CURRENT_ADDRESS_REQUEST";
export const DELIVERY_AGENT_CURRENT_ADDRESS_SUCCESS = "DELIVERY_AGENT_CURRENT_ADDRESS_SUCCESS";
export const DELIVERY_AGENT_CURRENT_ADDRESS_FAIL = "DELIVERY_AGENT_CURRENT_ADDRESS_FAIL";
export const DELIVERY_AGENT_WORK_ADDRESS_REQUEST = "DELIVERY_AGENT_WORK_ADDRESS_REQUEST";
export const DELIVERY_AGENT_WORK_ADDRESS_SUCCESS = "DELIVERY_AGENT_WORK_ADDRESS_SUCCESS";
export const DELIVERY_AGENT_WORK_ADDRESS_FAIL = "DELIVERY_AGENT_WORK_ADDRESS_FAIL";
export const DELIVERY_AGENT_PARMANENT_ADDRESS_REQUEST = "DELIVERY_AGENT_PARMANENT_ADDRESS_REQUEST";
export const DELIVERY_AGENT_PARMANENT_ADDRESS_SUCCESS = "DELIVERY_AGENT_PARMANENT_ADDRESS_SUCCESS";
export const DELIVERY_AGENT_PARMANENT_ADDRESS_FAIL = "DELIVERY_AGENT_PARMANENT_ADDRESS_FAIL";
export const DELIVERY_AGENT_VEHICAL_DETAILS_REQUEST = "DELIVERY_AGENT_VEHICAL_DETAILS_REQUEST";
export const DELIVERY_AGENT_VEHICAL_DETAILS_SUCCESS = "DELIVERY_AGENT_VEHICAL_DETAILS_SUCCESS";
export const DELIVERY_AGENT_VEHICAL_DETAILS_FAIL = "DELIVERY_AGENT_VEHICAL_DETAILS_FAIL";
export const RESET = "RESET";

export const DELIVERY_PROFILE_VEHICAL_DETAILS_REQUEST = "DELIVERY_PROFILE_VEHICAL_DETAILS_REQUEST";
export const DELIVERY_PROFILE_VEHICAL_DETAILS_SUCCESS = "DELIVERY_PROFILE_VEHICAL_DETAILS_SUCCESS";
export const DELIVERY_PROFILE_VEHICAL_DETAILS_FAIL = "DELIVERY_PROFILE_VEHICAL_DETAILS_FAIL";

export const DELIVERYAGENT_CREATE_REQUEST = "DELIVERYAGENT_CREATE_REQUEST";
export const DELIVERYAGENT_CREATE_SUCCESS = "DELIVERYAGENT_CREATE_SUCCESS";
export const DELIVERYAGENT_CREATE_FAIL = "DELIVERYAGENT_CREATE_FAIL";

/*=============================================================
             Delivery Agent Current Address Action
===============================================================*/
export const DeliveryAgentCurrentAddressAction = formData => async dispatch => {
  try {
    dispatch({
      type: DELIVERY_AGENT_CURRENT_ADDRESS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Delivery_User_Address/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=DEL_USER_ADDRESS_ID&SortOrder=ASC`, formData,);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: DELIVERY_AGENT_CURRENT_ADDRESS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: DELIVERY_AGENT_CURRENT_ADDRESS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: DELIVERY_AGENT_CURRENT_ADDRESS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
             Delivery Agent Work Address Action
===============================================================*/
export const DeliveryAgentWorkAddressAction = formData => async dispatch => {
  try {
    dispatch({
      type: DELIVERY_AGENT_WORK_ADDRESS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Delivery_User_Address/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=DEL_USER_ADDRESS_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: DELIVERY_AGENT_WORK_ADDRESS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: DELIVERY_AGENT_WORK_ADDRESS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: DELIVERY_AGENT_WORK_ADDRESS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
             Delivery Agent Permanent Address Action
===============================================================*/
export const DeliveryAgentPermanentAddressAction = formData => async dispatch => {
  try {
    dispatch({
      type: DELIVERY_AGENT_PARMANENT_ADDRESS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Delivery_User_Address/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=DEL_USER_ADDRESS_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: DELIVERY_AGENT_PARMANENT_ADDRESS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: DELIVERY_AGENT_PARMANENT_ADDRESS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: DELIVERY_AGENT_PARMANENT_ADDRESS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
             Delivery Agent Vehical Details Action
===============================================================*/
export const DeliveryAgentVehicalDetailsAction = formData => async dispatch => {
  try {
    dispatch({
      type: DELIVERY_AGENT_VEHICAL_DETAILS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin_Vehicle_Details/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Del_Vehicle_Detail_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: DELIVERY_AGENT_VEHICAL_DETAILS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: DELIVERY_AGENT_VEHICAL_DETAILS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: DELIVERY_AGENT_VEHICAL_DETAILS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
             Delivery Profile Details Action
===============================================================*/
export const DeliveryProfileAction = formData => async dispatch => {
  try {
    dispatch({
      type: DELIVERY_PROFILE_VEHICAL_DETAILS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Delivery_Profile/Profile_GET?PageNo=1&PageSize=${config.pageSize}&SortBy=DEL_USER_EMAIL_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: DELIVERY_PROFILE_VEHICAL_DETAILS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: DELIVERY_PROFILE_VEHICAL_DETAILS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: DELIVERY_AGENT_VEHICAL_DETAILS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Profile Image Upload Action
===============================================================*/
export const uploadprofilepicImage = (
  formData,
  callBackUploadImgae
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=DeliveryProfile&fileextension=png&filetype=Images&filename=DeliveryProfile`, formData);
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};

/*=============================================================
                    Update Delivery Action
===============================================================*/
export const updateDeliveryAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: DELIVERY_UPDATES_REQUEST
  });
  setErrorMessage({ Full_name: { key: '', message: '' }, global: { key: '', message: '' } });
  boomiAdminApi.put(`/Delivery/profile_upldate`, formData,)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DELIVERY_UPDATES_SUCCESS
        });
        toast('Delivery agent details updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/approved-delivery-agent-view');
      } else {
        const errors = {};
        if (successResponse.Full_name) {
          errors.Full_name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Full_name: { key: successResponse.Full_name ? successResponse.Full_name : '', message: successResponse.Full_name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Full_name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: DELIVERY_UPDATES_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Full_name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: DELIVERY_UPDATES_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

/*=============================================================
                    Update Delivery Action
===============================================================*/
export const updatesDeliveryAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: DELIVERY_UPDATES_REQUEST
  });
  setErrorMessage({ Full_name: { key: '', message: '' }, global: { key: '', message: '' } });
  boomiAdminApi.put(`/Delivery/profile_upldate`, formData, {
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DELIVERY_UPDATES_SUCCESS
        });
        toast('Delivery agent details updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/pending-delivery-agents-view');
      } else {
        const errors = {};
        if (successResponse.Full_name) {
          errors.Full_name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Full_name: { key: successResponse.Full_name ? successResponse.Full_name : '', message: successResponse.Full_name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Full_name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: DELIVERY_UPDATES_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Full_name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: DELIVERY_UPDATES_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

/*=============================================================
                  Get All delivery Action
===============================================================*/
export const getAllDeliveryAction = formData => async dispatch => {
  try {
    dispatch({
      type: DELIVERY_REQUEST
    });
    let { data } = await boomiAdminApi.post(`/Delivery_Registration/Get_Approval?PageNo=1&PageSize=${config.pageSize}&SortBy=DEL_USER_EMAIL_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: DELIVERY_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: DELIVERY_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: DELIVERY_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                 Delivery Agent Approval Action
===============================================================*/
export const DeliveryAgentApprovalAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: DELIVERY_AGENT_APPROVAL_REQUEST
  });
  boomiAdminApi.put(`/Delivery_Registration/Approval?PageNo=0&PageSize=${config.pageSize}&SortBy=EMAIL_ID&SortOrder=ASC`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DELIVERY_AGENT_APPROVAL_SUCCESS
        });
        toast('Successfully approved.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        const formData = {
          "Records_Filter": "FILTER",
          "Is_Approved": "1",
        };
        dispatch(getAllDeliveryAction(formData));
        navigate('/app/approved-delivery-agent-view', (state));
      } else {
        dispatch({
          type: DELIVERY_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: DELIVERY_AGENT_APPROVAL_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

/*=============================================================
                Delivery agent reject Action
===============================================================*/
export const DeliveryAgentRejectAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: DELIVERY_AGENT_REJECT_REQUEST
  });
  boomiAdminApi.put(`/Delivery_Registration/Approval`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DELIVERY_AGENT_REJECT_SUCCESS
        });
        toast('Successfully rejected.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        const formData = {
          "Records_Filter": "ALL",
          "Is_Approved": "1",
        };
        dispatch(getAllDeliveryAction(formData));
        navigate('/app/pending-delivery-agents-view', (state));
      } else {
        dispatch({
          type: DELIVERY_AGENT_REJECT_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: DELIVERY_AGENT_REJECT_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

/*=============================================================
                Add delivery agent  Action
===============================================================*/
export const createDeliveryAgentAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: DELIVERYAGENT_CREATE_REQUEST
  });
  setErrorMessage({ Del_user_email_id: { key: '', message: '' }, global: { key: '', message: '' } });
  boomiAdminApi.post(`/Super_Admin/DeliveryAgent_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DELIVERYAGENT_CREATE_SUCCESS
        });
        toast('Delivery person added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/approved-delivery-agent-view');
      } else {
        const errors = {};
        if (successResponse.Del_user_email_id) {
          errors.Del_user_email_id = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Del_user_email_id: { key: successResponse.Del_user_email_id ? successResponse.Del_user_email_id : '', message: successResponse.Del_user_email_id ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Del_user_email_id) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: DELIVERYAGENT_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Del_user_email_id: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: DELIVERYAGENT_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};

//Get DeliveryAgent Action
export const getDeliveryAgentAction = (formData, callBackDeliveryAgentData) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/Delivery_Registration/Get_Approval?PageNo=1&PageSize=${config.pageSize}&SortBy=DEL_USER_EMAIL_ID&SortOrder=ASC`, formData);
    if (data) {
      callBackDeliveryAgentData(data)
    }
  } catch (err) {
  }
};

//Get DeliveryAgent Registration approval Action
export const getDeliveryAgentRegApprovalAction = (formData, callBackDeliveryAgentRegApprovalData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Delivery_Registration/Get_Approval?PageNo=1&PageSize=${config.pageSize}&SortBy=DEL_USER_DETAIL_ID&SortOrder=ASC`, formData);
    if (data) {
      callBackDeliveryAgentRegApprovalData(data)
    }
  } catch (err) {
  }
};