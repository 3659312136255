import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/gift-vendors',
    createdAt: 'Updated 2 hr ago',
    description:
      'Gift vendors refers to the creation and updation of vendors.',
    media: '/static/Dashboard/gift_vendors.jpg',
    title: 'Gift Vendors',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/gift-images',
    createdAt: 'Updated 2 hr ago',
    description:
      'Gift Images refers to the creation and updation of Images.',
    media: '/static/Dashboard/gift_images.jpg',
    title: 'Gift Images',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/user-gifts',
    createdAt: 'Updated 2 hr ago',
    description:
      'User gifts refers to display and update the gifts information.',
    media: '/static/Dashboard/usergift.jpg',
    title: 'User Gifts',
    total: ''
  }
];