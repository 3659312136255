import {
    SERVICE_PARTNERMANAGEMENT_CREATE_REQUEST,
    SERVICE_PARTNERMANAGEMENT_CREATE_SUCCESS,
    SERVICE_PARTNERMANAGEMENT_CREATE_FAIL,
    SERVICE_PARTNERMANAGEMENT_UPDATE_REQUEST,
    SERVICE_PARTNERMANAGEMENT_UPDATE_SUCCESS,
    SERVICE_PARTNERMANAGEMENT_UPDATE_FAIL,
  
  } from '../actions/servicespartnermanagementAction'
  
  const initialState = {
    ServicepartnermanagementCreate: { Servicepartnermanagement: {}, error: '', isLoading: false },
    ServicepartnermanagementUpdate: { Servicepartnermanagement: {}, error: '', isLoading: false },
   
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case SERVICE_PARTNERMANAGEMENT_CREATE_REQUEST:
        return { ...state, ServicepartnermanagementCreate: { Servicepartnermanagement: {}, error: '', isLoading: true } };
      case SERVICE_PARTNERMANAGEMENT_CREATE_SUCCESS:
        return { ...state, ServicepartnermanagementCreate: { Servicepartnermanagement: action.payload, error: '', isLoading: false } };
      case SERVICE_PARTNERMANAGEMENT_CREATE_FAIL:
        return { ...state, ServicepartnermanagementCreate: { Servicepartnermanagement: {}, error: action.payload, isLoading: false } };
  
      case SERVICE_PARTNERMANAGEMENT_UPDATE_REQUEST:
        return { ...state, ServicepartnermanagementUpdate: { Servicepartnermanagement: {}, error: '', isLoading: true } };
      case SERVICE_PARTNERMANAGEMENT_UPDATE_SUCCESS:
        return { ...state, ServicepartnermanagementUpdate: { Servicepartnermanagement: action.payload, error: '', isLoading: false } };
      case SERVICE_PARTNERMANAGEMENT_UPDATE_FAIL:
        return { ...state, ServicepartnermanagementUpdate: { Servicepartnermanagement: {}, error: action.payload, isLoading: false } };
      default:
        return state;
    }
  }