import {
    BIDDING_USER_SERVICE_UPDATE_REQUEST,
    BIDDING_USER_SERVICE_UPDATE_SUCCESS,
    BIDDING_USER_SERVICE_UPDATE_FAIL,
    BIDDING_USER_SERVICE_CREATE_REQUEST,
    BIDDING_USER_SERVICE_CREATE_SUCCESS,
    BIDDING_USER_SERVICE_CREATE_FAIL,
    BIDDING_SERVICES_REQUEST,
    BIDDING_SERVICES_SUCCESS,
    BIDDING_SERVICES_FAIL,
    BID_SERVICES_USER_EMAIL_REQUEST,
    BID_SERVICES_USER_EMAIL_SUCCESS,
    BID_SERVICES_USER_EMAIL_FAIL,
    PARTNER_SERVICES_BIDDING_QUOTES_REQUEST,
    PARTNER_SERVICES_BIDDING_QUOTES_SUCCESS,
    PARTNER_SERVICES_BIDDING_QUOTES_FAIL,
    PARTNER_PAYMENTS_REQUEST,
    PARTNER_PAYMENTS_SUCCESS,
    PARTNER_PAYMENTS_FAIL,

} from '../actions/biddinguserserviceAction';

const initialState = {
    biddinguserserviceUpdate: { biddinguserservices: {}, error: '', isLoading: false },
    biddinguserserviceCreate: { biddinguserservices: {}, error: '', isLoading: false },
    BiddingServices: { services: [], error: '', isLoading: false },
    ServicesUserEmails: { serviceemails: [], error: '', isLoading: false },
    PartnerServicesBiddingQuotes: { servicequotes: [], error: '', isLoading: false },
    ServicesPartnerPayments: { servicespayments: [], error: '', isLoading: false },
};


export default function (state = initialState, action) {
    switch (action.type) {
        case BIDDING_USER_SERVICE_CREATE_REQUEST:
            return { ...state, biddinguserserviceCreate: { biddinguserservices: {}, error: '', isLoading: true } };
        case BIDDING_USER_SERVICE_CREATE_SUCCESS:
            return { ...state, biddinguserserviceCreate: { biddinguserservices: state.biddinguserserviceCreate.biddinguserservice, error: '', isLoading: false } };
        case BIDDING_USER_SERVICE_CREATE_FAIL:
            return { ...state, biddinguserserviceCreate: { biddinguserservices: {}, error: action.payload, isLoading: false } };
        case BIDDING_USER_SERVICE_UPDATE_REQUEST:
            return { ...state, biddinguserserviceUpdate: { biddinguserservices: {}, error: '', isLoading: true } };
        case BIDDING_USER_SERVICE_UPDATE_SUCCESS:
            return { ...state, biddinguserserviceUpdate: { biddinguserservices: state.biddinguserserviceUpdate.biddinguserservice, error: '', isLoading: false } };
        case BIDDING_USER_SERVICE_UPDATE_FAIL:
            return { ...state, biddinguserserviceUpdate: { biddinguserservices: {}, error: action.payload, isLoading: false } };

        case BIDDING_SERVICES_REQUEST:
            return { ...state, BiddingServices: { services: state.BiddingServices.services, error: '', isLoading: true }, };
        case BIDDING_SERVICES_SUCCESS:
            return { ...state, BiddingServices: { services: action.payload, error: '', isLoading: false } };
        case BIDDING_SERVICES_FAIL:
            return { ...state, BiddingServices: { services: [], error: action.payload, isLoading: false } };

        case BID_SERVICES_USER_EMAIL_REQUEST:
            return { ...state, ServicesUserEmails: { serviceemails: state.ServicesUserEmails.serviceemails, error: '', isLoading: true }, };
        case BID_SERVICES_USER_EMAIL_SUCCESS:
            return { ...state, ServicesUserEmails: { serviceemails: action.payload, error: '', isLoading: false } };
        case BID_SERVICES_USER_EMAIL_FAIL:
            return { ...state, ServicesUserEmails: { serviceemails: [], error: action.payload, isLoading: false } };

        case PARTNER_SERVICES_BIDDING_QUOTES_REQUEST:
            return { ...state, PartnerServicesBiddingQuotes: { servicequotes: state.PartnerServicesBiddingQuotes.servicequotes, error: '', isLoading: true }, };
        case PARTNER_SERVICES_BIDDING_QUOTES_SUCCESS:
            return { ...state, PartnerServicesBiddingQuotes: { servicequotes: action.payload, error: '', isLoading: false } };
        case PARTNER_SERVICES_BIDDING_QUOTES_FAIL:
            return { ...state, PartnerServicesBiddingQuotes: { servicequotes: [], error: action.payload, isLoading: false } };

        case PARTNER_PAYMENTS_REQUEST:
            return { ...state, ServicesPartnerPayments: { servicespayments: state.ServicesPartnerPayments.servicespayments, error: '', isLoading: true }, };
        case PARTNER_PAYMENTS_SUCCESS:
            return { ...state, ServicesPartnerPayments: { servicespayments: action.payload, error: '', isLoading: false } };
        case PARTNER_PAYMENTS_FAIL:
            return { ...state, ServicesPartnerPayments: { servicespayments: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}