import { Card, CardContent, Grid } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

const Orderb2bnotificationList = ({ className, ...rest }) => {
  const location = useLocation();
  let { state } = location;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Grid container spacing={3}>

              {/* Order_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Shopper Order ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Shopper_Order_Id}
                </Typography>
              </Grid>

              {/* Order_Status */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Order Status
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Order_Status}
                </Typography>
              </Grid>
              {/* Buyer_Partner_Detail_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Buyer Partner Details ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Buyer_Partner_Detail_Id}
                </Typography>
              </Grid>

              {/* User_Email_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  User Email ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '2rem' }}
                >
                  <span title={state?.Shopper_Email_Id}> {state?.Shopper_Email_Id?.length > 25 ?
                    <span>{state?.Shopper_Email_Id?.substring(0, 25)}...</span> : state?.Shopper_Email_Id}</span>
                </Typography>
              </Grid>
              {/* Billing_Address_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Billing Address Id
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Billing_Address[0].Billing_Address_Id}
                </Typography>
              </Grid>
              {/* City */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  City
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Billing_Address[0].City}
                </Typography>
              </Grid>
              {/* Country */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Country
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Billing_Address[0].Country}
                </Typography>
              </Grid>
              {/* Door_No */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Door No
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Billing_Address[0].Door_No}
                </Typography>
              </Grid>
              {/* Is_Approved */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Is Approved
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Is_Approved}
                </Typography>
              </Grid>
              {/* User_Email_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  User Email ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0]?.User_Email_Id}
                </Typography>
              </Grid>
              {/* Product_Name */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Product Name
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0]?.Product_Name}
                </Typography>
              </Grid>
              {/* Line_Item_Status */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Line Item Status
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0]?.Line_Item_Status}
                </Typography>
              </Grid>
              {/* Selling_Price */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Selling Price
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0]?.Selling_Price}
                </Typography>
              </Grid>
              {/* Order_Date */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Order Date
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0]?.Order_Date}
                </Typography>
              </Grid>
              {/* Partner_Details_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Partner Details ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0]?.Partner_Details_Id}
                </Typography>
              </Grid>
              {/* Shipping_Address_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Shipping Address ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0].Shipping_Address[0]?.Shipping_Address_Id}
                </Typography>
              </Grid>
              {/* City */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Shipping City
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0].Shipping_Address[0]?.City}
                </Typography>
              </Grid>
              {/* State */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Shipping State
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0].Shipping_Address[0]?.State}
                </Typography>
              </Grid>
              {/* Door_No */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Shipping Door No
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0].Shipping_Address[0]?.Door_No}
                </Typography>
              </Grid>
              {/* Mobile */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Shipping Mobile
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0].Shipping_Address[0]?.Mobile}
                </Typography>
              </Grid>
              {/* Shipping_ID */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Shipping ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0].Shipping_Summary[0]?.Shipping_ID}
                </Typography>
              </Grid>
              {/* Shipping_Channel */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Shipping Channel
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0].Shipping_Summary[0]?.Shipping_Channel}
                </Typography>
              </Grid>
              {/* Del_Order_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Del Orde ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0].Shipping_Summary[0]?.Del_Order_Id}
                </Typography>
              </Grid>
              {/* Payment_Method */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Payment Method
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Payment_Summary[0]?.Payment_Method}
                </Typography>
              </Grid>
              {/* Payment_Reference_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Payment Reference ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Payment_Summary[0]?.Payment_Reference_Id}
                </Typography>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={2} />
              <Grid item xs={7} />
            </Grid>
          </Grid>

          {/*  column 2 */}
          <Grid item md={6}>
            <Grid container spacing={3}>

              {/* Product_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Product ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Product_Id}
                </Typography>
              </Grid>

              {/* Shopper_Email_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Shopper Email ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Shopper_Email_Id}
                </Typography>
              </Grid>
              {/* Seller_Partner_Detail_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Seller Partner Detail ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Seller_Partner_Detail_Id}
                </Typography>
              </Grid>

              {/* Partner_Mail */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Partner Mail
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  <span title={state?.Partner_Mail}> {state?.Partner_Mail?.length > 25 ?
                    <span>{state?.Partner_Mail?.substring(0, 25)}...</span> : state?.Partner_Mail}</span>
                </Typography>
              </Grid>
              {/* Address_Type */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Address Type
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Billing_Address[0].Address_Type}
                </Typography>
              </Grid>
              {/* State */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  State
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Billing_Address[0].State}
                </Typography>
              </Grid>
              {/* Street */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Street
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Billing_Address[0].Street}
                </Typography>
              </Grid>
              {/* Zip */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Zip
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Billing_Address[0].Zip}
                </Typography>
              </Grid>
              {/* Shipping_Address_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Item Master ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0]?.Item_Master_Id}
                </Typography>
              </Grid>
              {/* Product_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Product ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0]?.Product_Id}
                </Typography>
              </Grid>
              {/* MRP */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  MRP
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0]?.MRP}
                </Typography>
              </Grid>
              {/* Quantity */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Quantity
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0]?.Quantity}
                </Typography>
              </Grid>
              {/* Payment_Status */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Payment Status
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0]?.Payment_Status}
                </Typography>
              </Grid>
              {/* Brand_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Brand ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0]?.Brand_Id}
                </Typography>
              </Grid>
              {/* Address_Type */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Shipping Address Type
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0].Shipping_Address[0]?.Address_Type}
                </Typography>
              </Grid>
              {/* Country */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Shipping Country
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0].Shipping_Address[0]?.Country}
                </Typography>
              </Grid>
              {/* Street */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Shipping Street
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0].Shipping_Address[0]?.Street}
                </Typography>
              </Grid>
              {/* Zip */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Shipping Zip
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0].Shipping_Address[0]?.Zip}
                </Typography>
              </Grid>
              {/* Land_Mark */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Shipping LandMark
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0].Shipping_Address[0]?.Land_Mark}
                </Typography>
              </Grid>
              {/* Email_Id */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Shipping Email ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0].Shipping_Address[0]?.User_Email_Id}
                </Typography>
              </Grid>
              {/* Expected_Delivery_Time */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Expected Delivery Time
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0].Shipping_Summary[0]?.Expected_Delivery_Time}
                </Typography>
              </Grid>
              {/* Shipping_Status */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Shipping Status
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Item_Data.Item_Master_Data[0].Shipping_Summary[0]?.Shipping_Status}
                </Typography>
              </Grid>
              {/* Payment_Status */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Payment Status
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Payment_Summary[0]?.Payment_Status}
                </Typography>
              </Grid>
              {/* Payment_Date */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Payment Date
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Payment_Summary[0]?.Payment_Date}
                </Typography>
              </Grid>
              {/* User_Billable_Amount */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  User Billable Amount
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Payment_Summary[0]?.User_Billable_Amount}
                </Typography>
              </Grid>
              {/* Total_Order_Amount */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Total Order Amount
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Financial_Summary[0]?.Total_Order_Amount}
                </Typography>
              </Grid>
              {/* User_Total_Tax_Amount */}
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  User Total Tax Amount
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state?.Financial_Summary[0]?.User_Total_Tax_Amount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Orderb2bnotificationList;