import {
  PARTNER_DELIVERYAGENT_REQUEST,
  PARTNER_DELIVERYAGENT_SUCCESS,
  PARTNER_DELIVERYAGENT_FAIL,

  PARTNER_DELIVERYAGENT_GET_REQUEST,
  PARTNER_DELIVERYAGENT_GET_SUCCESS,
  PARTNER_DELIVERYAGENT_GET_FAIL,
  PARTNER_DELIVERYAGENT_CREATE_REQUEST,
  PARTNER_DELIVERYAGENT_CREATE_SUCCESS,
  PARTNER_DELIVERYAGENT_CREATE_FAIL,
  PARTNER_DELIVERYAGENT_UPDATE_REQUEST,
  PARTNER_DELIVERYAGENT_UPDATE_SUCCESS,
  PARTNER_DELIVERYAGENT_UPDATE_FAIL,

} from '../actions/partnerdeliveryagentsAction'

const initialState = {
  partnersdeliveryagents: { all: [], error: '', isLoading: false },
  partnerdeliveryagent: { partnerdeliveryagent: {}, error: '', isLoading: false },
  partnerdeliveryagentCreate: { partnerdeliveryagent: {}, error: '', isLoading: false },
  partnerdeliveryagentUpdate: { partnerdeliveryagent: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PARTNER_DELIVERYAGENT_REQUEST:
      return { ...state, partnersdeliveryagents: { all: state.partnersdeliveryagents.all, error: '', isLoading: true } };
    case PARTNER_DELIVERYAGENT_SUCCESS:
      return { ...state, partnersdeliveryagents: { all: action.payload, error: '', isLoading: false } };
    case PARTNER_DELIVERYAGENT_FAIL:
      return { ...state, partnersdeliveryagents: { all: [], error: action.payload, isLoading: false } };

    case PARTNER_DELIVERYAGENT_GET_REQUEST:
      return { ...state, partnerdeliveryagent: { partnerdeliveryagent: {}, error: '', isLoading: true } };
    case PARTNER_DELIVERYAGENT_GET_SUCCESS:
      return { ...state, partnerdeliveryagent: { partnerdeliveryagent: action.payload, error: '', isLoading: false } };
    case PARTNER_DELIVERYAGENT_GET_FAIL:
      return { ...state, partnerdeliveryagent: { partnerdeliveryagent: {}, error: action.payload, isLoading: false } };

    case PARTNER_DELIVERYAGENT_CREATE_REQUEST:
      return { ...state, partnerdeliveryagentCreate: { partnerdeliveryagent: {}, error: '', isLoading: true } };
    case PARTNER_DELIVERYAGENT_CREATE_SUCCESS:
      return { ...state, partnerdeliveryagentCreate: { partnerdeliveryagent: state.partnerdeliveryagentCreate.partnerdeliveryagent, error: '', isLoading: false } };
    case PARTNER_DELIVERYAGENT_CREATE_FAIL:
      return { ...state, partnerdeliveryagentCreate: { partnerdeliveryagent: {}, error: action.payload, isLoading: false } };
    case PARTNER_DELIVERYAGENT_UPDATE_REQUEST:
      return { ...state, partnerdeliveryagentUpdate: { partnerdeliveryagent: {}, error: '', isLoading: true } };
    case PARTNER_DELIVERYAGENT_UPDATE_SUCCESS:
      return { ...state, partnerdeliveryagentUpdate: { partnerdeliveryagent: state.partnerdeliveryagentUpdate.partnerdeliveryagent, error: '', isLoading: false } };
    case PARTNER_DELIVERYAGENT_UPDATE_FAIL:
      return { ...state, partnerdeliveryagentUpdate: { partnerdeliveryagent: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}