
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getAllProductAction
} from '../../../../../store/actions/productAction';
import {
  EditrelatedproductAction,
} from '../../../../../store/actions/relatedProductAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const EditRelatedProduct = ({ className, data, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = location;

  // componentDidMount
  useEffect(() => {
    const formData = {
      search_by_filter: "",
      search: "",
      Records_Filter: 'FILTER',
      Product_Id: data.Related_Product_Of_Product_Id
    };
    dispatch(getAllProductAction(formData));
  }, []);

  const allProducts = useSelector(state => state.product.products.all);
  const isSubmited = useSelector(state => state.rproduct.relatedproductUpdate.isLoading);
  const errorMessage = useSelector(state => state.rproduct.relatedproductUpdate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        Modified_By: loginEmail,
        Product_Id: data.Product_ID ? data.Product_ID : "",
        Related_Product_Id: data.Related_Prod_Id ? data.Related_Prod_Id : "",
        Related_Product_Of_Product_Id: data.Related_Product_Of_Product_Id ? data.Related_Product_Of_Product_Id : "",
      }}
      validationSchema={
        Yup.object().shape({
          Related_Product_Of_Product_Id: Yup.string().required('Product Name is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        // setImageError("");
        const errors = {};
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(EditrelatedproductAction(formValues, data.Product_ID, navigate, state));
        }
      }}>
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Related_Product_Of_Product_Id && errors.Related_Product_Of_Product_Id)}
                        fullWidth
                        helperText={touched.Related_Product_Of_Product_Id && errors.Related_Product_Of_Product_Id}
                        onBlur={handleBlur}
                        label="Product Name"
                        name="Related_Product_Of_Product_Id"
                        onChange={handleChange}
                        value={values.Related_Product_Of_Product_Id}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                        minRows={3}
                      >
                        <option key="" value="">--Please Select--</option>
                        {allProducts?.sort((a, b) => a.Product_Name?.localeCompare(b.Product_Name))?.map(option => (
                          <option key={option.Product_Id} value={option.Product_Id}>
                            {option.Product_Name?.length > 70 ? option.Product_Name?.substring(0, 70) : option.Product_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save Details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditRelatedProduct.propTypes = {
  className: PropTypes.string
};

export default EditRelatedProduct;