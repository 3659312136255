import { Card, CardContent, Divider, Grid, makeStyles, Accordion, Link, AccordionSummary, AccordionDetails } from '@material-ui/core';
import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { DeliveryAgentCurrentAddressAction, DeliveryAgentPermanentAddressAction, DeliveryAgentVehicalDetailsAction, DeliveryAgentWorkAddressAction } from 'src/store/actions/deliveryAgentAction';
import isEmpty from 'src/store/validations/is-empty';

const useStyles = makeStyles(() => ({
    images: {
        width: 200,
        height: 200
    },
    content: {
        justifyContent: "center"
    },
}));

const PartnerDeliveryAgentList = ({ className, data, ...rest }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        const formData = {
            "Address_Type": "Permanent Address",
            "Del_User_Email_Id": data?.Del_User_Email_Id,
            "Records_Filter": "FILTER"
        }
        dispatch(DeliveryAgentPermanentAddressAction(formData));
        const workformData = {
            "Address_Type": "Working Address",
            "Del_User_Email_Id": data?.Del_User_Email_Id,
            "Records_Filter": "FILTER"
        }
        dispatch(DeliveryAgentWorkAddressAction(workformData));
        const currentformData = {
            "Address_Type": "Current Address",
            "Del_User_Email_Id": data?.Del_User_Email_Id,
            "Records_Filter": "FILTER"
        }
        dispatch(DeliveryAgentCurrentAddressAction(currentformData));
        const vehicalformData = {
            "search_by_filter": "",
            "search": "",
            "Del_User_Email_Id": data?.Del_User_Email_Id,
            "Records_Filter": "FILTER"
        }
        dispatch(DeliveryAgentVehicalDetailsAction(vehicalformData));
    }, []);

    let currentAddress = useSelector(state => state.delivery.deliveryagentcurrentaddress.all);
    let permanentAddress = useSelector(state => state.delivery.deliveryagentparmanentaddress.all);
    let workAddress = useSelector(state => state.delivery.deliveryagentworkaddress.all);
    let vehicalsDetails = useSelector(state => state.delivery.deliveryagentvehicals.all);

    return (
        <Card >
            <CardContent>
                <div style={{ marginTop: '1rem' }}>
                    <Accordion>
                        <div className="vehicalsDetailCard">
                            <AccordionSummary
                                className={classes.content}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"  >
                                {<div style={{ marginTop: "-1%", fontSize: "16px" }}>Vehicle Details</div>}
                            </AccordionSummary>
                        </div>
                        <Divider />
                        <div >
                            {!isEmpty(vehicalsDetails) ? (vehicalsDetails.Is_Data_Exist === "0" ? ("No Details Found") : (vehicalsDetails.map((vehicalsDetails, index) =>
                                <div key={index}>
                                    <AccordionDetails>
                                        <div>
                                            <Grid container spacing={3}>
                                                <Grid item md={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Delivery Vehicle Detail ID
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {vehicalsDetails?.Del_Vehicle_Detail_Id}
                                                            </Typography>
                                                        </Grid>
                                                        {/* Name */}
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Fuel Type
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {vehicalsDetails?.Fuel_Type}
                                                            </Typography>
                                                        </Grid>


                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Model
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {vehicalsDetails?.Model}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                RC Number
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {vehicalsDetails?.RC_Number}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Type
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {vehicalsDetails?.Type}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Vehicle Color
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {vehicalsDetails?.Vehicle_Color}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Vehicle Number
                                                            </Typography>
                                                        </Grid>


                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {vehicalsDetails?.Vehicle_Number}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Vehicle Image Path
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                <a target="_blank" href={vehicalsDetails?.Vehcle_Image_Path}>
                                                                    <img
                                                                        className={classes.images}
                                                                        alt="Vehicle Image Path"
                                                                        src={vehicalsDetails?.Vehcle_Image_Path}
                                                                        variant="square"
                                                                    />
                                                                </a>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                RC Front Path Pic
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                <a target="_blank" href={vehicalsDetails?.RC_Pic_Front_Path}>
                                                                    <img
                                                                        className={classes.images}
                                                                        alt="RC Pic Front Path"
                                                                        src={vehicalsDetails?.RC_Pic_Front_Path}
                                                                        variant="square"
                                                                    />
                                                                </a>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                RC Back Path Pic
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                <a target="_blank" href={vehicalsDetails?.RC_Pic_Back_Path}>
                                                                    <img
                                                                        className={classes.images}
                                                                        alt="RC Pic Back Path"
                                                                        src={vehicalsDetails?.RC_Pic_Back_Path}
                                                                        variant="square"
                                                                    />
                                                                </a>
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Insurance File Path
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {/* Link component allows you to easily customize anchor elements with your theme colors and typography styles. */}
                                                                {/* span element is used for inline organization and styling. */}
                                                                <Link href={vehicalsDetails.Insurance_File_Path} underline="none" target="_blank">
                                                                    <span title={vehicalsDetails.Insurance_File_Path}> {vehicalsDetails.Insurance_File_Path?.length > 29 ?
                                                                        <span>{vehicalsDetails.Insurance_File_Path?.substring(0, 25)}...</span> : vehicalsDetails.Insurance_File_Path}</span>
                                                                </Link>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Driving License Front Path
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                <a target="_blank" href={vehicalsDetails.Driving_License_Front_Path}>
                                                                    <img
                                                                        className={classes.images}
                                                                        alt="Driving License Front Path"
                                                                        src={vehicalsDetails.Driving_License_Front_Path}
                                                                        variant="square"
                                                                    />
                                                                </a>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Driving License Back Path
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                <a target="_blank" href={vehicalsDetails.Driving_License_Back_Path}>
                                                                    <img
                                                                        className={classes.images}
                                                                        alt="Driving License Back Path"
                                                                        src={vehicalsDetails.Driving_License_Back_Path}
                                                                        variant="square"
                                                                    />
                                                                </a>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </AccordionDetails>
                                </div>))) : <div style={{ textAlign: "center", marginBottom: "20px" }}>No Details Found</div>}
                        </div>
                    </Accordion>
                </div>
                <div style={{ marginTop: '1rem' }}>
                    <Accordion>
                        <div className="vehicalsDetailCard">
                            <AccordionSummary
                                className={classes.content}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"  >
                                {<div style={{ marginTop: "-1%", fontSize: "16px" }}>Current Address</div>}
                            </AccordionSummary>
                        </div>
                        <Divider />
                        <div >
                            {!isEmpty(currentAddress) ? (currentAddress.Is_Data_Exist === "0" ? ("No Details Found") : (currentAddress.map((currentAddress, index) =>
                                <div key={index}>
                                    <AccordionDetails>
                                        <div>
                                            <Grid container spacing={3}>
                                                <Grid item md={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Door No
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.Door_No}
                                                            </Typography>
                                                        </Grid>
                                                        {/* Name */}
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Street Name
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.Street_One}
                                                            </Typography>
                                                        </Grid>


                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Land Mark
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.Land_Mark}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                City
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.City}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                State
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.State}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Country
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.Country}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Zip Code
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.Zip_Code}
                                                            </Typography>
                                                        </Grid>



                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Mobile Number
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.Mobile}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Alternate Number
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.Alternate_Mobile}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </AccordionDetails>
                                </div>))) : <div style={{ textAlign: "center", marginBottom: "20px" }}>No Details Found</div>}
                        </div>
                    </Accordion>
                </div>
                <div style={{ marginTop: '1rem' }}>
                    <Accordion>
                        <div className="vehicalsDetailCard">
                            <AccordionSummary
                                className={classes.content}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"  >
                                {<div style={{ marginTop: "-1%", fontSize: "16px" }}>Permanent Address</div>}
                            </AccordionSummary>
                        </div>
                        <Divider />
                        <div >
                            {!isEmpty(permanentAddress) ? (permanentAddress.Is_Data_Exist === "0" ? ("No Details Found") : (permanentAddress.map((permanentAddress, index) =>
                                <div key={index}>
                                    <AccordionDetails>
                                        <div>
                                            <Grid container spacing={3}>
                                                <Grid item md={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Door No.
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.Door_No}
                                                            </Typography>
                                                        </Grid>
                                                        {/* Name */}
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Street Name
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.Street_One}
                                                            </Typography>
                                                        </Grid>


                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Land Mark
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.Land_Mark}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                City
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.City}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                State
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.State}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Country
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.Country}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Zip Code
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.Zip_Code}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Mobile Number
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.Mobile}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Alternate Number
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.Alternate_Mobile}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </AccordionDetails>
                                </div>))) : <div style={{ textAlign: "center", marginBottom: "20px" }}>No Details Found</div>}
                        </div>
                    </Accordion>
                </div>
                <div style={{ marginTop: '1rem' }}>
                    <Accordion>
                        <div >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"  >
                                {<div style={{ marginTop: "-1%", fontSize: "16px" }}>Work Address</div>}
                            </AccordionSummary>
                        </div>
                        <Divider />
                        <div >
                            {!isEmpty(workAddress) ? (workAddress.Is_Data_Exist === "0" ? ("No Details Found") : (workAddress.map((workAddress, index) =>
                                <div key={index}>
                                    <AccordionDetails>
                                        <div>
                                            <Grid container spacing={3}>
                                                <Grid item md={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Door No.
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.Door_No}
                                                            </Typography>
                                                        </Grid>
                                                        {/* Name */}
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Street Name
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.Street_One}
                                                            </Typography>
                                                        </Grid>


                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Land Mark
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.Land_Mark}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                City
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.City}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                State
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.State}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Country
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.Country}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Zip Code
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.Zip_Code}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Mobile Number
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.Mobile}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Alternate Number
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.Alternate_Mobile}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </AccordionDetails>
                                </div>))) : <div style={{ textAlign: "center", marginBottom: "20px" }}>No Details Found</div>}
                        </div>
                    </Accordion>
                </div>
            </CardContent>
        </Card >
    );
};

export default PartnerDeliveryAgentList;