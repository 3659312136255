import {
  WALLET_HISTORY_REQUEST,
  WALLET_HISTORY_SUCCESS,
  WALLET_HISTORY_FAIL,
  WALLET_HISTORY_GET_REQUEST,
  WALLET_HISTORY_GET_SUCCESS,
  WALLET_HISTORY_GET_FAIL,
  WALLET_HISTORY_CREATE_REQUEST,
  WALLET_HISTORY_CREATE_SUCCESS,
  WALLET_HISTORY_CREATE_FAIL
} from '../actions/wallethistoryAction'

const initialState = {
  wallethistories: { all: [], error: '', isLoading: false },
  wallethistory: { wallet: {}, error: '', isLoading: false },
  wallethistoryCreate: { wallet: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case WALLET_HISTORY_REQUEST:
      return { ...state, wallethistories: { all: state.wallethistories.all, error: '', isLoading: true } };
    case WALLET_HISTORY_SUCCESS:
      return { ...state, wallethistories: { all: action.payload, error: '', isLoading: false } };
    case WALLET_HISTORY_FAIL:
      return { ...state, wallethistories: { all: [], error: action.payload, isLoading: false } };
    case WALLET_HISTORY_CREATE_REQUEST:
      return { ...state, wallethistoryCreate: { wallet: {}, error: '', isLoading: true } };
    case WALLET_HISTORY_CREATE_SUCCESS:
      return { ...state, wallethistoryCreate: { wallet: state.wallethistoryCreate.wallet, error: '', isLoading: false } };
    case WALLET_HISTORY_CREATE_FAIL:
      return { ...state, wallethistoryCreate: { wallet: {}, error: action.payload, isLoading: false } };
    case WALLET_HISTORY_GET_REQUEST:
      return { ...state, wallethistory: { wallet: {}, error: '', isLoading: true } };
    case WALLET_HISTORY_GET_SUCCESS:
      return { ...state, wallethistory: { wallet: action.payload, error: '', isLoading: false } };
    case WALLET_HISTORY_GET_FAIL:
      return { ...state, wallethistory: { wallet: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}