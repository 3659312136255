import { toast } from 'react-toastify';
import { boomipaginationgetMeepaisaZoneAction } from './boomi-actions/boomipagination';
import {boomiAdminApi } from './commonAxios';
export const MEEPAISA_ZONE_CREATE_REQUEST = "MEEPAISA_ZONE_CREATE_REQUEST";
export const MEEPAISA_ZONE_CREATE_SUCCESS = "MEEPAISA_ZONE_CREATE_SUCCESS";
export const MEEPAISA_ZONE_CREATE_FAIL = "MEEPAISA_ZONE_CREATE_FAIL";
export const MEEPAISA_ZONE_UPDATE_REQUEST = "MEEPAISA_ZONE_UPDATE_REQUEST";
export const MEEPAISA_ZONE_UPDATE_SUCCESS = "MEEPAISA_ZONE_UPDATE_SUCCESS";
export const MEEPAISA_ZONE_UPDATE_FAIL = "MEEPAISA_ZONE_UPDATE_FAIL";

/*=============================================================
                  Add MeepaisaZone Action
===============================================================*/

export const addMeepaisazoneAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: MEEPAISA_ZONE_CREATE_REQUEST
  });
  setErrorMessage({ Zone_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  boomiAdminApi.post(`/Meepaisa_Zone/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: MEEPAISA_ZONE_CREATE_SUCCESS
        });
        toast('Meepaisa Zone added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/meepaisa-zone');
      } else {
        const errors = {};
        if (successResponse.Zone_Name) {
          errors.Zone_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Zone_Name: { key: successResponse.Zone_Name ? successResponse.Zone_Name : '', message: successResponse.Zone_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Zone_Name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: MEEPAISA_ZONE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Zone_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: MEEPAISA_ZONE_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};

/*=============================================================
                  Delete Category Action
===============================================================*/
export const deleteZone = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/Meepaisa_Zone/Delete`, formData);
    if (data) {
      toast('Meepaisa Zone deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(boomipaginationgetMeepaisaZoneAction(filters, pagination, callBackPagination));
    }
  } catch (err) { }
};
/*=============================================================
                Update Meepaisa Zone Action
===============================================================*/

export const updateMeepaisazone = (formData, navigate) => async dispatch => {
  dispatch({
    type: MEEPAISA_ZONE_UPDATE_REQUEST
  });
  boomiAdminApi.post(`/Meepaisa_Zone/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: MEEPAISA_ZONE_UPDATE_SUCCESS
        });
        toast('Meepaisa Zone updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/meepaisa-zone');
      } else {
        dispatch({
          type: MEEPAISA_ZONE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: MEEPAISA_ZONE_UPDATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};



/*=============================================================
              uploadzoneimage Action
===============================================================*/

export const uploadZoneImage = (
  formData,
  callBackUploadImgae1,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=Zones&fileextension=png&filetype=Images&filename=MeepaisaZone`,formData);
    if (data) {
      callBackUploadImgae1(data);
    }
  } catch (err) { }
};


