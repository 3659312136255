import {
  USERREVIEW_CREATE_REQUEST,
  USERREVIEW_CREATE_SUCCESS,
  USERREVIEW_CREATE_FAIL,
  USERREVIEW_UPDATE_REQUEST,
  USERREVIEW_UPDATE_SUCCESS,
  USERREVIEW_UPDATE_FAIL,
} from '../actions/branduserreviewAction'

const initialState = {
  userCreate: { user: {}, error: '', isLoading: false },
  userUpdate: { user: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USERREVIEW_CREATE_REQUEST:
      return { ...state, userCreate: { user: {}, error: '', isLoading: true } };
    case USERREVIEW_CREATE_SUCCESS:
      return { ...state, userCreate: { user: state.userCreate.user, error: '', isLoading: false } };
    case USERREVIEW_CREATE_FAIL:
      return { ...state, userCreate: { user: {}, error: action.payload, isLoading: false } };
    case USERREVIEW_UPDATE_REQUEST:
      return { ...state, userUpdate: { user: {}, error: '', isLoading: true } };
    case USERREVIEW_UPDATE_SUCCESS:
      return { ...state, userUpdate: { user: state.userUpdate.user, error: '', isLoading: false } };
    case USERREVIEW_UPDATE_FAIL:
      return { ...state, userUpdate: { user: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}