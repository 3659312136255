import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    CardHeader,
    Divider,
    ListItemAvatar,
    ListItemText,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    getAllESIDeliveryPersonsAction,
    getAllPartnerDeliveryPersonsAction,
    getsingleOrderAction,
    updateOrderStatusAction,
    getAllPartneraddressesAction,
    paymentAuthorizationAction,
    OrderPartnerPaymentAction,
    updateOrderShipAction,
    OrderCancelShipAction,
    createOrderShipAction,
    cancelOrderShipAction,
    getNewOrderAction
} from 'src/store/actions/ordersAction';    
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import CloseIcon from '@material-ui/icons/Close';
import isEmpty from 'src/store/validations/is-empty';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    images: {
        width: 120,
        height: 120,
        borderRadius: 5,
        marginBottom: 15
    },
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    },
    image: {
        width: 50,
        height: 50
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    cancelButton: {
        color: "#27B6CC",
        backgroundColor: "white",
        border: "1px solid grey",
    },
    acceptButton: {
        color: "white",
        backgroundColor: "#27B6CC",
    },
}));

const allretailorderlevelstatus = [
    {
        value: 'NEW',
        label: 'NEW'
    },
    {
        value: 'ORDERED',
        label: 'ORDERED'
    },
    {
        value: 'ACCEPTED',
        label: 'ACCEPTED'
    },
    {
        value: 'SHIPPED',
        label: 'SHIPPED'
    },
];

const allorderstatus = [
    {
        value: 'NEW',
        label: 'NEW'
    },
    {
        value: 'ORDERED',
        label: 'ORDERED'
    },
    {
        value: 'ACCEPTED',
        label: 'ACCEPTED'
    },
    {
        value: 'REJECTED',
        label: 'REJECTED'
    },
    {
        value: 'PACKED',
        label: 'PACKED'
    },
    {
        value: 'SHIPPED',
        label: 'SHIPPED'
    },
    {
        value: 'OUT-FOR-DELIVERY',
        label: 'OUT-FOR-DELIVERY'
    },
    {
        value: 'IN-TRANSIT',
        label: 'IN-TRANSIT'
    },
    {
        value: 'DELIVERED',
        label: 'DELIVERED'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
    {
        value: 'PARTIAL-CANCELLATION',
        label: 'PARTIAL-CANCELLATION'
    },
    {
        value: 'RETURN',
        label: 'RETURN'
    }, {
        value: 'PARTIAL-RETURNED',
        label: 'PARTIAL-RETURNED'
    },

];

const alllineitemstatus = [
    {
        value: 'NEW',
        label: 'NEW'
    },
    {
        value: 'ORDERED',
        label: 'ORDERED'
    },
    {
        value: 'ACCEPTED',
        label: 'ACCEPTED'
    },
    {
        value: 'REJECTED',
        label: 'REJECTED'
    },
    {
        value: 'PACKED',
        label: 'PACKED'
    },
    {
        value: 'SHIPPED',
        label: 'SHIPPED'
    },
    {
        value: 'OUT-FOR-DELIVERY',
        label: 'OUT-FOR-DELIVERY'
    },
    {
        value: 'IN-TRANSIT',
        label: 'IN-TRANSIT'
    },
    {
        value: 'DELIVERED',
        label: 'DELIVERED'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
    {
        value: 'RETURN',
        label: 'RETURN'
    },
];

const ShippingchannelpartnerOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Partner',
        label: 'Partner'
    },
    {
        value: 'ESI',
        label: 'ESI'
    },
    {
        value: 'Thirdparty',
        label: 'Thirdparty'
    },
];

const ShippingTypeOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'LIVE',
        label: 'LIVE'
    },
    {
        value: 'NORMAL',
        label: 'NORMAL'
    },
    {
        value: 'STANDARD',
        label: 'STANDARD'
    }
];

const deliverymessage = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Delay due to Rain',
        label: 'Delay due to Rain'
    },
    {
        value: 'Delay due to Traffic',
        label: 'Delay due to Traffic'
    },
    {
        value: 'Delay due to Accident',
        label: 'Delay due to Accident'
    },
    {
        value: 'Delay due to Heavy Orders',
        label: 'Delay due to Heavy Orders'
    },
    {
        value: 'Delay due to Vehicle Repair',
        label: 'Delay due to Vehicle Repair'
    }
];

const cancelreasontype = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Out of Stock',
        label: 'Out of Stock'
    },
    {
        value: 'High Demand',
        label: 'High Demand'
    },
    ,
    {
        value: 'By Mistakenly Places Order',
        label: 'By Mistakenly Places Order'
    },
    {
        value: 'Bought from other Partner',
        label: 'Bought from other Partner'
    },
    ,
    {
        value: 'Delivery Time is too long',
        label: 'Delivery Time is too long'
    },
    {
        value: 'Product Damaged',
        label: 'Product Damaged'
    },
    {
        value: 'Product Quality',
        label: 'Product Quality'
    },
    {
        value: 'Product Size',
        label: 'Product Size'
    },

    {
        value: 'Product Fitting is not good',
        label: 'Product Fitting is not good'
    },
    {
        value: 'Other Reason',
        label: 'Other Reason'
    },

];

const EditOrderStatusHistory = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const location = useLocation();
    let { state } = location;

    //order level
    const viewEasystepinaddress = orderaddressData => e => {
        navigate("/app/order-address-view", { state: { orderaddressData, Print: "easystepin", addr: "ORDER" } });
    };

    const viewBillingaddress = orderaddressData => e => {
        navigate("/app/order-address-view", { state: { orderaddressData, Print: "billing", addr: "ORDER" } });
    };

    const viewShippingaddress = orderaddressData => e => {
        navigate("/app/order-address-view", { state: { orderaddressData, Print: "shipping", addr: "ORDER" } });
    };

    const viewESIShippingaddress = orderaddressData => e => {
        navigate("/app/order-address-view", { state: { orderaddressData, Print: "esishipping", addr: "ORDER" } });
    };

    const viewBillShipaddress = orderaddressData => e => {
        navigate("/app/order-address-view", { state: { orderaddressData, Print: "billingshipping", addr: "ORDER" } });
    };

    //order Item level
    const viewIMEasystepinaddress = orderaddressData => e => {
        navigate("/app/order-address-view", { state: { orderaddressData, Print: "IMeasystepin", addr: "ITEM" } });
    };

    const viewIMBillingaddress = orderaddressData => e => {
        navigate("/app/order-address-view", { state: { orderaddressData, Print: "IMbilling", addr: "ITEM" } });
    };

    const viewIMShippingaddress = orderaddressData => e => {
        navigate("/app/order-address-view", { state: { orderaddressData, Print: "IMshipping", addr: "ITEM" } });
    };

    const viewIMESIShippingaddress = orderaddressData => e => {
        navigate("/app/order-address-view", { state: { orderaddressData, Print: "IMesishipping", addr: "ITEM" } });
    };

    const viewIMBillShipaddress = orderaddressData => e => {
        navigate("/app/order-address-view", { state: { orderaddressData, Print: "IMbillingshipping", addr: "ITEM" } });
    };

    //navigate to invoice print
    const viewOrderInvoice = orderInvoiceData => e => {
        navigate("/app/order-invoice-view", { state: orderInvoiceData });
    };

    //print popup
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //authorize payment popup
    const [authopen, setAuthOpen] = React.useState(false);

    const handleClickAuthOpen = () => {
        setAuthOpen(true);
    };

    const handleAuthClose = () => {
        setAuthOpen(false);
    };

    //pay to partner popup
    const [payopen, setPayOpen] = React.useState(false);

    const handleClickPayOpen = () => {
        setPayOpen(true);
    };

    const handlePayClose = () => {
        setPayOpen(false);
    };

    {/* ship rocket shipment */ }
    //ship to popup
    const [shipopen, setShipOpen] = React.useState(false);

    const handleClickShipOpen = () => {
        setShipOpen(true);
    };

    const handleShipClose = () => {
        setShipOpen(false);
    };

    //cancel shipment popup
    const [cancelshipopen, setCancelShipOpen] = React.useState(false);

    const handleClickCancelShipOpen = () => {
        setCancelShipOpen(true);
    };

    const handleShipCancelClose = () => {
        setCancelShipOpen(false);
    };

    {/* Nimbuspost shipment */ }
    //ship order popup
    const [shiporderopen, setShipOrderOpen] = React.useState(false);

    const handleClickShipOrderOpen = () => {
        setShipOrderOpen(true);
    };

    const handleShipOrderClose = () => {
        setShipOrderOpen(false);
    };

    //cancel order popup
    const [cancelorderopen, setCancelOrderOpen] = React.useState(false);

    const handleClickCancelOrderOpen = () => {
        setCancelOrderOpen(true);
    };

    const handleShipCancelOrderClose = () => {
        setCancelOrderOpen(false);
    };

    //dropdown popup
    const [testopen, setTestOpen] = React.useState(false);

    const handleClickTestOpen = () => {
        setTestOpen(true);
    };

    const handleTestClose = () => {
        setTestOpen(false);
    };

    //for Order Id and Partner Id get
    const [orderId, setOrderId] = useState(
        state?.ordersData?.Order_Id ? state?.ordersData?.Order_Id :
            state?.orderaddressData?.Order_Id ? state?.orderaddressData?.Order_Id :
                state?.notification?.Order_Id ? state?.notification?.Order_Id :
                    state?.Order_Id ? state?.Order_Id :
                        state?.orderaddressData[0]?.Order_Id)
    const [partnerId, setPartnerId] = useState(
        state?.ordersData?.Order_Partner_Detail_ID ? state?.ordersData?.Order_Partner_Detail_ID :
            state?.orderaddressData?.Order_Partner_Detail_ID ? state?.orderaddressData?.Order_Partner_Detail_ID :
                state?.ordersData?.Partner_Detail_Id ? state?.ordersData?.Partner_Detail_Id :
                    state?.notification?.Partner_Detail_Id ? state?.notification?.Partner_Detail_Id :
                        state?.Order_Partner_Detail_ID ? state?.Order_Partner_Detail_ID :
                            state?.orderaddressData[0]?.Order_Partner_Detail_ID)

    //dispatch actions
    useEffect(() => {
        if (!isEmpty(orderId)) {
            const formData = {
                "search": '',
                "search_by_filter": 'All',
                "Records_Filter": "",
                "Order_Id": orderId,
                   
            };
            dispatch(getNewOrderAction(formData));
        }
        //get esi delivery persons
        const formData1 = {
            "Role": ""
        };
        dispatch(getAllESIDeliveryPersonsAction(formData1));
        //get partner delivery persons
        if (!isEmpty(partnerId)) {
            const formData2 = {
                "Records_Filter": "FILTER",
                "Role": "PARTNER_DELIVERY",
                "Partner_ID": partnerId
            };
            dispatch(getAllPartnerDeliveryPersonsAction(formData2));
        }
        if (!isEmpty(partnerId)) {
            const addressData = {
                "search": "",
                "search_by_filter": "",
                "Records_Filter": "FILTER",
                "Partner_Details_Id": partnerId
            };
            dispatch(getAllPartneraddressesAction(addressData));
        }
    }, []);

    const Orders = useSelector(state => state?.orders?.Neworder?.all);
    console.log(Orders,"modified ordersss")
    const esidelipersons = useSelector(state => state?.orders?.Orderesidelipersons?.all || [])
    const partnerdelipersons = useSelector(state => state?.orders?.Orderpartnerdelipersons?.all || [])
    const partneraddress = useSelector(state => state?.orders?.Orderpartneraddress?.all)
    const isSubmitted = useSelector(state => state?.orders?.orderPartnerPaymentCreate?.isLoading);
    const isOrderPayment = useSelector(state => state?.orders?.orderPaymentAuthCreate?.isLoading);
    const isShipSubmitted = useSelector(state => state?.orders?.orderShipCreate?.isLoading);
    const isShipCancelled = useSelector(state => state?.orders?.orderShipCancel?.isLoading);
    const isOrderShipSubmitted = useSelector(state => state?.orders?.orderShipnimbusCreate?.isLoading);
    const isOrderShipCancelled = useSelector(state => state?.orders?.orderShipnimbusCancel?.isLoading);
    const isOrderUpdate = useSelector(state => state?.orders?.orderUpdate?.isLoading);

    const [errorMessage, setErrorMessage] = useState({ global: { key: '', message: '' } });

    //Order data Iteration  
    let orderdata = []
    // if (Orders) {
    //     for (let i = 0; i < Orders?.User_Billing_Address_Full?.length; i++) {
    //         let billing = Orders.User_Billing_Address_Full
    //         for (let j = 0; j < billing?.length; j++) {
    //             let financial = Orders.User_Shipping_Address_Full
    //             for (let k = 0; k < financial?.length; k++) {
    //                 let payment = Orders.Payment_Summary
    //                 for (let l = 0; l < payment?.length; l++) {
    //                     orderdata.push({

    //                         Order_Id: Orders?.Order_Id,
    //                         Order_Status: Orders?.Order_Status,
    //                         Is_Approved: Orders?.Is_Approved,
    //                         User_Email_Id: Orders?.User_Email_Id,
    //                         Order_Update_Type: Orders?.Order_Update_Type,
    //                         Order_Edit: Orders?.Order_Edit,
    //                         Cancel_Return_User_Comment: Orders?.Cancel_Return_User_Comment,
    //                         Cancel_Return_User_Reason_Type: Orders?.Cancel_Return_User_Reason_Type,
    //                         Order_Type: Orders?.Order_Type,
    //                         Order_Partner_Detail_ID: Orders?.Order_Partner_Detail_ID,
    //                         Partner_Name: Orders?.Partner_Name,
    //                         Partner_Mail: Orders?.Partner_Mail,
    //                         Partner_Mobile: Orders?.Partner_Mobile,
    //                         Partner_UPI: Orders?.Partner_UPI,

    //                         Address_Type: billing[i]?.Address_Type,
    //                         Billing_Address_Id: billing[i]?.Billing_Address_Id,
    //                         City: billing[i]?.City,
    //                         Country: billing[i]?.Country,
    //                         Created_By: billing[i]?.Created_By,
    //                         Door_No: billing[i]?.Door_No,
    //                         Email_Id: billing[i]?.Email_Id,
    //                         Google_Map_Location: billing[i]?.Google_Map_Location,
    //                         Land_Mark: billing[i]?.Land_Mark,
    //                         Location: billing[i]?.Location,
    //                         Mobile: billing[i]?.Mobile,
    //                         State: billing[i]?.State,
    //                         Street: billing[i]?.Street,
    //                         Zip: billing[i]?.Zip,

    //                         Cashback_To_User: financial[j]?.Cashback_To_User,
    //                         Corp_Coupon_Amount: financial[j]?.Corp_Coupon_Amount,
    //                         Coupon_Amount: financial[j]?.Coupon_Amount,
    //                         Delivery_Person_Tip: financial[j]?.Delivery_Person_Tip,
    //                         Gift_Voucher_Amount: financial[j]?.Gift_Voucher_Amount,
    //                         Meepaisa_Gift_Wrap_Amount: financial[j]?.Meepaisa_Gift_Wrap_Amount,
    //                         Meepaisa_Packaging_Charges: financial[j]?.Meepaisa_Packaging_Charges,
    //                         Meepaisa_Shipping_Fee: financial[j]?.Meepaisa_Shipping_Fee,
    //                         Meepaisa_Total_Tax_Amount: financial[j]?.Meepaisa_Total_Tax_Amount,
    //                         Meepiasa_Billable_Amount_After_Taxes: financial[j]?.Meepiasa_Billable_Amount_After_Taxes,
    //                         Meepiasa_Billable_Amount_Before_Taxes: financial[j]?.Meepiasa_Billable_Amount_Before_Taxes,
    //                         Partner_Final_Settlement_Amount: financial[j]?.Partner_Final_Settlement_Amount,
    //                         Razorpay_Amount: financial[j]?.Razorpay_Amount,
    //                         Total_Order_Amount: financial[j]?.Total_Order_Amount,
    //                         User_Billable_Amount_After_Taxes: financial[j]?.User_Billable_Amount_After_Taxes,
    //                         User_Billable_Amount_Before_Taxes: financial[j]?.User_Billable_Amount_Before_Taxes,
    //                         User_Shipping_Fee: financial[j]?.User_Shipping_Fee,
    //                         User_Total_Tax_Amount: financial[j]?.User_Total_Tax_Amount,
    //                         Wallet_Amount: financial[j]?.Wallet_Amount,

    //                         Payment_Date: payment[k]?.Payment_Date,
    //                         Payment_Method: payment[k]?.Payment_Method,
    //                         Payment_Reference_Id: payment[k]?.Payment_Reference_Id,
    //                         Payment_Status: payment[k]?.Payment_Status,
    //                         User_Billable_Amount: payment[k]?.User_Billable_Amount,

    //                         Shipping_ID: Orders?.Order_Shipping_Summary?.Shipping_ID,
    //                         Shipping_Fee: Orders?.Order_Shipping_Summary?.Shipping_Fee,
    //                         Shipping_Tax: Orders?.Order_Shipping_Summary?.Shipping_Tax,
    //                         Shipping_Total: Orders?.Order_Shipping_Summary?.Shipping_Total,
    //                         Shipping_Channel: Orders?.Order_Shipping_Summary?.Shipping_Channel,
    //                         Shipping_Type: Orders?.Order_Shipping_Summary?.Shipping_Type,
    //                         Live_Delivered_By_Email: Orders?.Order_Shipping_Summary?.Live_Delivered_By_Email,
    //                         Live_Delivered_By_Name: Orders?.Order_Shipping_Summary?.Live_Delivered_By_Name,
    //                         Live_Delivery_Person_Phone: Orders?.Order_Shipping_Summary?.Live_Delivery_Person_Phone,
    //                         Thirdparty_Channel_Partner_Name: Orders?.Order_Shipping_Summary?.Thirdparty_Channel_Partner_Name,
    //                         Thirdparty_Delivery_Person_Name: Orders?.Order_Shipping_Summary?.Thirdparty_Delivery_Person_Name,
    //                         Thirdparty_Delivery_Phone: Orders?.Order_Shipping_Summary?.Thirdparty_Delivery_Phone,
    //                         Thirdparty_Shipping_Method: Orders?.Order_Shipping_Summary?.Thirdparty_Shipping_Method,
    //                         Thirdparty_Shipping_Tracking_Number: Orders?.Order_Shipping_Summary?.Thirdparty_Shipping_Tracking_Number,
    //                         Thirdparty_Tracking_Verification_URL: Orders?.Order_Shipping_Summary?.Thirdparty_Tracking_Verification_URL,
    //                         Delivery_Message: Orders?.Order_Shipping_Summary?.Delivery_Message,
    //                         Expected_Delivery_Date_Time: Orders?.Order_Shipping_Summary?.Expected_Delivery_Date_Time,
    //                         Expected_Delivery_Time_In_Minutes: Orders?.Order_Shipping_Summary?.Expected_Delivery_Time_In_Minutes,
    //                         Ordered_Date_Time: Orders?.Order_Shipping_Summary?.Ordered_Date_Time,
    //                         Del_Order_Id: Orders?.Order_Shipping_Summary?.Del_Order_Id,
    //                         Partner_Address_ID: Orders?.Order_Shipping_Summary?.Partner_Address_Id,
    //                         Delivery_Person_Fee: Orders?.Order_Shipping_Summary?.Delivery_Person_Fee,
    //                         Packaging_Charges: Orders?.Order_Shipping_Summary?.Packaging_Charges,
    //                     })
    //                 }
    //             }
    //         }
    //     }
    // }

    //Order line item data Iteration
    if (Orders) {
        let billing = Orders?.User_Billing_Address_Full;
        let shipping = Orders?.User_Shipping_Address_Full;
        let shippingSummary = Orders?.Order_Shipping_Summary;  
        let orderstatus=Orders?.Order_Status_Dates_Json;

        orderdata.push({
            // Order Details
            Order_Id: Orders?.Order_Id,
            Order_Status: Orders?.Order_Status,
            Is_Approved: Orders?.Is_Approved,
            User_Email_Id: Orders?.User_Email_Id,
            Order_Update_Type: Orders?.Order_Update_Type,
            Order_Edit: Orders?.Order_Edit,
            Cancel_Return_User_Comment: Orders?.Cancel_Return_User_Comment,
            Cancel_Return_User_Reason_Type: Orders?.Cancel_Return_User_Reason_Type,
            Order_Type: Orders?.Order_Type,
            Order_Partner_Detail_ID: Orders?.Order_Partner_Detail_ID,
            Partner_Name: Orders?.Partner_Name,
            Partner_Mail: Orders?.Partner_Mail,
            Partner_Mobile: Orders?.Partner_Mobile,
            Partner_UPI: Orders?.Partner_UPI,
            Total_Order_Value:Orders?.Total_Order_Value,
            Payment_Type:Orders?.Payment_Type,
            Payment_Transaction_Status:Orders?.Payment_Transaction_Status,
            Wallet_Amount:Orders?.Wallet_Amount,
            Shipping_Amount:Orders?.Shipping_Amount,
    
            // Billing Address
            Address_Type: billing?.Address_Type,
            Billing_Address_Id: billing?.USER_ADDRESS_ID,
            City: billing?.CITY,
            Country: billing?.COUNTRY,
            // Created_By: billing?.Created_By,
            Door_No: billing?.DOOR_NO,
            Email_Id: billing?.USER_EMAIL_ID,
            Google_Map_Location: billing?.GOOGLE_MAP_LOCATION,
            Land_Mark: billing?.LAND_MARK,
            Location: billing?.LOCATION,
            Mobile: billing?.MOBILE,
            State: billing?.STATE,
            Street: billing?.STREET,
            Zip: billing?.ZIP,
            Date:orderstatus?.Date,
    
            // Shipping Address
            Cashback_To_User: shipping?.Cashback_To_User,
            Corp_Coupon_Amount: shipping?.Corp_Coupon_Amount,
            Coupon_Amount: shipping?.Coupon_Amount,
            Delivery_Person_Tip: shipping?.Delivery_Person_Tip,
            Gift_Voucher_Amount: shipping?.Gift_Voucher_Amount,
            Meepaisa_Gift_Wrap_Amount: shipping?.Meepaisa_Gift_Wrap_Amount,
            Meepaisa_Packaging_Charges: shipping?.Meepaisa_Packaging_Charges,
            Meepaisa_Shipping_Fee: shipping?.Meepaisa_Shipping_Fee,
            Meepaisa_Total_Tax_Amount: shipping?.Meepaisa_Total_Tax_Amount,
            Meepiasa_Billable_Amount_After_Taxes: shipping?.Meepiasa_Billable_Amount_After_Taxes,
            Meepiasa_Billable_Amount_Before_Taxes: shipping?.Meepiasa_Billable_Amount_Before_Taxes,
            Partner_Final_Settlement_Amount: shipping?.Partner_Final_Settlement_Amount,
            Razorpay_Amount: shipping?.Razorpay_Amount,
            // Total_Order_Amount: shipping?.Total_Order_Amount,
            User_Billable_Amount_After_Taxes: shipping?.User_Billable_Amount_After_Taxes,
            User_Billable_Amount_Before_Taxes: shipping?.User_Billable_Amount_Before_Taxes,
            User_Shipping_Fee: shipping?.User_Shipping_Fee,
            User_Total_Tax_Amount: shipping?.User_Total_Tax_Amount,
    
            // Shipping Summary
            Shipping_ID: shippingSummary?.Shipping_ID,
            Shipping_Tax: shippingSummary?.Shipping_Tax,
            Shipping_Total: shippingSummary?.Shipping_Total,
            Shipping_Channel: shippingSummary?.Shipping_Channel,
            Shipping_Type: shippingSummary?.Shipping_Type,
            Live_Delivered_By_Email: shippingSummary?.Live_Delivered_By_Email,
            Live_Delivered_By_Name: shippingSummary?.Live_Delivered_By_Name,
            Live_Delivery_Person_Phone: shippingSummary?.Live_Delivery_Person_Phone,
            Thirdparty_Channel_Partner_Name: shippingSummary?.Thirdparty_Channel_Partner_Name,
            Thirdparty_Delivery_Person_Name: shippingSummary?.Thirdparty_Delivery_Person_Name,
            Thirdparty_Delivery_Phone: shippingSummary?.Thirdparty_Delivery_Phone,
            Thirdparty_Shipping_Method: shippingSummary?.Thirdparty_Shipping_Method,
            Thirdparty_Shipping_Tracking_Number: shippingSummary?.Thirdparty_Shipping_Tracking_Number,
            Thirdparty_Tracking_Verification_URL: shippingSummary?.Thirdparty_Tracking_Verification_URL,
            Delivery_Message: shippingSummary?.Delivery_Message,
            Expected_Delivery_Date_Time: shippingSummary?.Expected_Delivery_Date_Time,
            Expected_Delivery_Time_In_Minutes: shippingSummary?.Expected_Delivery_Time_In_Minutes,
            Ordered_Date_Time: shippingSummary?.Ordered_Date_Time,
            Del_Order_Id: shippingSummary?.Del_Order_Id,
            Partner_Address_ID: shippingSummary?.Partner_Address_Id,
            Delivery_Person_Fee: shippingSummary?.Delivery_Person_Fee,
            Packaging_Charges: shippingSummary?.Packaging_Charges
        });
    }
    

    console.log(orderdata[0],"orderdata[0]orderdata[0]orderdata[0]")
    let allOrderDetails = []
    if (Orders) {
        console.log(Orders,"Ordersjjjjjjjjjjjj")
        for (let i = 0; i < Orders?.length; i++) {
            let lineitemdata = Orders?.Order_Status_Dates_Json
            allOrderDetails.push({
                Order_Id: Orders?.Order_Id,
                Order_Status: Orders?.Order_Status,
                Is_Approved: Orders?.Is_Approved,
                User_Email_Id: Orders?.User_Email_Id,
                Order_Update_Type: Orders?.Order_Update_Type,
                Cancel_Return_User_Comment: Orders?.Cancel_Return_User_Comment,
                Cancel_Return_User_Reason_Type: Orders?.Cancel_Return_User_Reason_Type,
                Order_Partner_Detail_ID: Orders?.Order_Partner_Detail_ID,
                Order_Type: Orders?.Order_Type,
                Product_Name:Orders?.Product_Name,

                Brand_Id: lineitemdata[i]?.Brand_Id,
                Brand_Image_Path: lineitemdata[i]?.Brand_Image_Path,
                Brand_Name: lineitemdata[i]?.Brand_Name,
                Is_Item_Return_Flag: lineitemdata[i]?.Is_Item_Return_Flag,
                Item_Master_Id: lineitemdata[i]?.Item_Master_Id,
                Line_Item_Status: lineitemdata[i]?.Line_Item_Status,
                MRP: lineitemdata[i]?.MRP,
                Max_Days_To_Return: lineitemdata[i]?.Max_Days_To_Return,
                Order_Date: lineitemdata[i]?.Order_Date,
                Partner_Details_Id: lineitemdata[i]?.Partner_Details_Id,
                Payment_Status: lineitemdata[i]?.Payment_Status,
                Product_Id: lineitemdata[i]?.Product_Id,
                Product_Image: lineitemdata[i]?.Product_Image,
                Product_Name: Orders?.Product_Name,
                Quantity: lineitemdata[i]?.Quantity,
                Selling_Price: lineitemdata[i]?.Selling_Price,
                Product_Size: lineitemdata[i]?.Product_Size,

                Item_Master_Financial_Update_Flag: lineitemdata[i]?.Item_Financial_Summary[0]?.Item_Master_Financial_Update_Flag,
                Meepaisa_Billable_Amount: lineitemdata[i]?.Item_Financial_Summary[0]?.Meepaisa_Billable_Amount,
                Meepaisa_Gift_Wrap_Amount: lineitemdata[i]?.Item_Financial_Summary[0]?.Meepaisa_Gift_Wrap_Amount,
                Meepaisa_Packaging_Charges: lineitemdata[i]?.Item_Financial_Summary[0]?.Meepaisa_Packaging_Charges,
                Meepaisa_Shipping_Fee: lineitemdata[i]?.Item_Financial_Summary[0]?.Meepaisa_Shipping_Fee,
                Meepaisa_Total_Tax_Amount: lineitemdata[i]?.Item_Financial_Summary[0]?.Meepaisa_Total_Tax_Amount,
                Net_Price: lineitemdata[i]?.Item_Financial_Summary[0]?.Net_Price,
                Partner_Total_Price: lineitemdata[i]?.Item_Financial_Summary[0]?.Partner_Total_Price,
                User_Billable_Amount: lineitemdata[i]?.Item_Financial_Summary[0]?.User_Billable_Amount,
                User_Total_Tax_Amount: lineitemdata[i]?.Item_Financial_Summary[0]?.User_Total_Tax_Amount,

                Address_Type: lineitemdata[i]?.Shipping_Address[0]?.Address_Type,
                City: lineitemdata[i]?.Shipping_Address[0]?.City,
                Country: lineitemdata[i]?.Shipping_Address[0]?.Country,
                Created_By: lineitemdata[i]?.Shipping_Address[0]?.Created_By,
                Door_No: lineitemdata[i]?.Shipping_Address[0]?.Door_No,
                Email_Id: lineitemdata[i]?.Shipping_Address[0]?.Email_Id,
                Google_Map_Location: lineitemdata[i]?.Shipping_Address[0]?.Google_Map_Location,
                Land_Mark: lineitemdata[i]?.Shipping_Address[0]?.Land_Mark,
                Location: lineitemdata[i]?.Shipping_Address[0]?.Location,
                Mobile: lineitemdata[i]?.Shipping_Address[0]?.Mobile,
                Shipping_Address_Id: lineitemdata[i]?.Shipping_Address[0]?.Shipping_Address_Id,
                State: lineitemdata[i]?.Shipping_Address[0]?.State,
                Street: lineitemdata[i]?.Shipping_Address[0]?.Street,
                Zip: lineitemdata[i]?.Shipping_Address[0]?.Zip,

                Shipping_ID: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_ID,
                Shipping_Fee: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Fee,
                Shipping_Tax: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Tax,
                Shipping_Total: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Total,
                Shipping_Channel: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Channel,
                Shipping_Type: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Type,
                Live_Delivered_By_Email: lineitemdata[i]?.Shipping_Summary[0]?.Live_Delivered_By_Email,
                Live_Delivered_By_Name: lineitemdata[i]?.Shipping_Summary[0]?.Live_Delivered_By_Name,
                Live_Delivery_Person_Phone: lineitemdata[i]?.Shipping_Summary[0]?.Live_Delivery_Person_Phone,
                Thirdparty_Channel_Partner_Name: lineitemdata[i]?.Shipping_Summary[0]?.Thirdparty_Channel_Partner_Name,
                Thirdparty_Delivery_Person_Name: lineitemdata[i]?.Shipping_Summary[0]?.Thirdparty_Delivery_Person_Name,
                Thirdparty_Delivery_Phone: lineitemdata[i]?.Shipping_Summary[0]?.Thirdparty_Delivery_Phone,
                Thirdparty_Shipping_Method: lineitemdata[i]?.Shipping_Summary[0]?.Thirdparty_Shipping_Method,
                Thirdparty_Shipping_Tracking_Number: lineitemdata[i]?.Shipping_Summary[0]?.Thirdparty_Shipping_Tracking_Number,
                Thirdparty_Tracking_Verification_URL: lineitemdata[i]?.Shipping_Summary[0]?.Thirdparty_Tracking_Verification_URL,
                Delivery_Message: lineitemdata[i]?.Shipping_Summary[0]?.Delivery_Message,
                Expected_Delivery_Date_Time: lineitemdata[i]?.Shipping_Summary[0]?.Expected_Delivery_Date,
                Expected_Delivery_Time_In_Minutes: lineitemdata[i]?.Shipping_Summary[0]?.Expected_Delivery_Time,
                Ordered_Date_Time: lineitemdata[i]?.Shipping_Summary[0]?.Ordered_Date_Time,
                Del_Order_Id: lineitemdata[i]?.Shipping_Summary[0]?.Del_Order_Id,
                Partner_Address_ID: lineitemdata[i]?.Shipping_Summary[0]?.Partner_Address_Id,
                Shipping_Channel_Partner: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Channel_Partner,
                Shipping_Reference_Id: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Reference_Id,
                Shipping_Status: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Status,
                Delivery_Person_Fee: lineitemdata[i]?.Shipping_Summary[0]?.Delivery_Person_Fee,
                Packaging_Charges: lineitemdata[i]?.Shipping_Summary[0]?.Packaging_Charges,
            })
        }
    }

//     let allOrderDetails = [];
// if (Orders) {
//     console.log(Orders,"Ordersjjjjjjjjjjjj")
//     for (let i = 0; i < Orders?.length; i++) {
//         let order = Orders[i];
//         console.log(order,"orderorderorderorderorderorderorder")
//         allOrderDetails.push({
//             Order_Id: order?.Order_Id,
//             Invoice_Number: order?.Invoice_Number,
//             User_Details_Id: order?.User_Details_Id,
//             Product_Id: order?.Product_Id,
//             Partner_Product_Id: order?.Partner_Product_Id,
//             Return_Days: order?.Return_Days,
//             Partner_Detail_Id: order?.Partner_Detail_Id,
//             Transaction_Id: order?.Transaction_Id,
//             Coupon_Utilisation_Id: order?.Coupon_Utilisation_Id,
//             Order_Status: order?.Order_Status,
//             Order_Status_Dates_Json: {
//                 Date: order?.Order_Status_Dates_Json?.Date,
//                 BY: order?.Order_Status_Dates_Json?.BY,
//                 ID: order?.Order_Status_Dates_Json?.ID,
//             },
//             Mrp: order?.Mrp,
//             Base_Selling_Price: order?.Base_Selling_Price,
//             Quantity_Selling_Price: order?.Quantity_Selling_Price,
//             Order_Partner_Detail_ID: order?.Order_Partner_Detail_ID,
//             Quantity: order?.Quantity,
//             Sgst: order?.Sgst,
//             Cgst: order?.Cgst,
//             Vat: order?.Vat,
//             Total_Tax: order?.Total_Tax,
//             Sgst_Percentage: order?.Sgst_Percentage,
//             Cgst_Percentage: order?.Cgst_Percentage,
//             Vat_Percentage: order?.Vat_Percentage,

//             // User Billing Address Full
//             User_Billing_Address_Full: {
//                 USER_ADDRESS_ID: order?.User_Billing_Address_Full?.USER_ADDRESS_ID,
//                 USER_EMAIL_ID: order?.User_Billing_Address_Full?.USER_EMAIL_ID,
//                 DOOR_NO: order?.User_Billing_Address_Full?.DOOR_NO,
//                 STREET: order?.User_Billing_Address_Full?.STREET,
//                 CITY: order?.User_Billing_Address_Full?.CITY,
//                 STATE: order?.User_Billing_Address_Full?.STATE,
//                 COUNTRY: order?.User_Billing_Address_Full?.COUNTRY,
//                 ZIP: order?.User_Billing_Address_Full?.ZIP,
//                 LAND_MARK: order?.User_Billing_Address_Full?.LAND_MARK,
//                 SHIPPING_EMAIL_ID: order?.User_Billing_Address_Full?.SHIPPING_EMAIL_ID,
//                 MOBILE: order?.User_Billing_Address_Full?.MOBILE,
//                 ADDRESS_TYPE: order?.User_Billing_Address_Full?.ADDRESS_TYPE,
//                 NAME: order?.User_Billing_Address_Full?.NAME,
//                 GOOGLE_MAP_LOCATION: order?.User_Billing_Address_Full?.GOOGLE_MAP_LOCATION,
//                 LOCATION: order?.User_Billing_Address_Full?.LOCATION,
//                 ALTERNATE_MOBILE: order?.User_Billing_Address_Full?.ALTERNATE_MOBILE,
//                 LATITUDE: order?.User_Billing_Address_Full?.LATITUDE,
//                 LONGITUDE: order?.User_Billing_Address_Full?.LONGITUDE,
//             },

//             // User Shipping Address Full
//             User_Shipping_Address_Full: {
//                 USER_ADDRESS_ID: order?.User_Shipping_Address_Full?.USER_ADDRESS_ID,
//                 USER_EMAIL_ID: order?.User_Shipping_Address_Full?.USER_EMAIL_ID,
//                 DOOR_NO: order?.User_Shipping_Address_Full?.DOOR_NO,
//                 STREET: order?.User_Shipping_Address_Full?.STREET,
//                 CITY: order?.User_Shipping_Address_Full?.CITY,
//                 STATE: order?.User_Shipping_Address_Full?.STATE,
//                 COUNTRY: order?.User_Shipping_Address_Full?.COUNTRY,
//                 ZIP: order?.User_Shipping_Address_Full?.ZIP,
//                 LAND_MARK: order?.User_Shipping_Address_Full?.LAND_MARK,
//                 SHIPPING_EMAIL_ID: order?.User_Shipping_Address_Full?.SHIPPING_EMAIL_ID,
//                 MOBILE: order?.User_Shipping_Address_Full?.MOBILE,
//                 ADDRESS_TYPE: order?.User_Shipping_Address_Full?.ADDRESS_TYPE,
//                 NAME: order?.User_Shipping_Address_Full?.NAME,
//                 GOOGLE_MAP_LOCATION: order?.User_Shipping_Address_Full?.GOOGLE_MAP_LOCATION,
//                 LOCATION: order?.User_Shipping_Address_Full?.LOCATION,
//                 ALTERNATE_MOBILE: order?.User_Shipping_Address_Full?.ALTERNATE_MOBILE,
//                 LATITUDE: order?.User_Shipping_Address_Full?.LATITUDE,
//                 LONGITUDE: order?.User_Shipping_Address_Full?.LONGITUDE,
//             },

//             Partner_Address_Id: order?.Partner_Address_Id,
//             Meepaisa_Commission_Amount: order?.Meepaisa_Commission_Amount,
//             Gift_Wrap_Amount: order?.Gift_Wrap_Amount,
//             Shipping_Amount: order?.Shipping_Amount,
//             Partner_Coupon_Amount: order?.Partner_Coupon_Amount,
//             Modified_Date: order?.Modified_Date,
//             Modified_By: order?.Modified_By,
//             Created_Date: order?.Created_Date,
//             Created_By: order?.Created_By,
//             Cashback: order?.Cashback,
//             Cashback_Settlement_To_User: order?.Cashback_Settlement_To_User,
//             Partner_Settlement_Amount: order?.Partner_Settlement_Amount,
//             Partner_Settlement_Status: order?.Partner_Settlement_Status,
//             Partner_Settlement_Type: order?.Partner_Settlement_Type,
//             Partner_Settlement_Id: order?.Partner_Settlement_Id,
//             Return_Status_Dates_Json: order?.Return_Status_Dates_Json,
//             Wallet_History_Id: order?.Wallet_History_Id,
//             Wallet_Amount: order?.Wallet_Amount,
//             Payment_Type: order?.Payment_Type,
//             Payment_Amount: order?.Payment_Amount,
//             Payment_Transaction_Id: order?.Payment_Transaction_Id,
//             Payment_Transaction_Status: order?.Payment_Transaction_Status,
//             Total_Order_Value: order?.Total_Order_Value,
//             Gift_Amount: order?.Gift_Amount,
//             Meepaisa_Coupon_Code: order?.Meepaisa_Coupon_Code,
//             Corp_Coupon_Amount: order?.Corp_Coupon_Amount,
//             Email_Id: order?.Email_Id,
//             Price: order?.Price,
//             Product_Name: order?.Product_Name,
//             Coupon_Code: order?.Coupon_Code,
//             Coupon_Discount: order?.Coupon_Discount,
//             Partner_Name: order?.Partner_Name,
//             Product_Size_Id: order?.Product_Size_Id,
//             Delivery_Tracking_Json: order?.Delivery_Tracking_Json,
//         });
//     }
// }

console.log(allOrderDetails,"allOrderDetailsallOrderDetailsallOrderDetails  ")

    return (
        <>
            <Card>
                <CardHeader title="Order Details" />
                <Box display="flex" style={{ marginTop: "-50px" }} justifyContent="flex-end" p={1}>
                    <Button
                        style={{ backgroundColor: '#27B6CC', margin: "1%" }}
                        variant="contained"
                        onClick={handleClickAuthOpen}
                    >
                        <span style={{ color: 'white' }}>AUTHORIZE NEW PAYMENT</span>
                    </Button>
                    <Button
                        style={{ backgroundColor: '#27B6CC', margin: "1%" }}
                        variant="contained"
                        onClick={handleClickPayOpen}
                    >
                        <span style={{ color: 'white' }}>PAY TO PARTNER</span>
                    </Button>
                    {orderdata[0]?.Order_Type === "FOOD" || orderdata[0]?.Order_Type === "GROCERY" || orderdata[0]?.Order_Type === "FARMER" ?
                        <Button
                            style={{ backgroundColor: '#27B6CC', margin: "1%" }}
                            variant="contained"
                            onClick={handleClickOpen}
                        >
                            <span style={{ color: 'white' }}>PRINT</span>
                        </Button> : null
                    }
                    {orderdata[0]?.Order_Type === "MFH" ?
                        <Button
                            style={{ backgroundColor: '#27B6CC', margin: "1%" }}
                            variant="contained"
                            onClick={viewOrderInvoice(orderdata[0])}
                        >
                            <span style={{ color: 'white' }}>INVOICE PRINT</span>
                        </Button> : null
                    }
                </Box>

                <Dialog open={authopen} fullWidth={true} maxWidth={'sm'}
                    classes={{ paper: classes.dialogPaper }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle>
                        <Box display="flex" alignItems="center" style={{ fontSize: 20 + 'px', marginBottom: "5px", marginTop: "-5px" }} >
                            <HelpOutlineOutlinedIcon style={{ fontSize: 30 + 'px', paddingRight: "5px", color: "#3f51b5" }} />
                            <span>Alert</span>
                            <IconButton style={{ marginTop: "-5px" }} onClick={handleAuthClose} className={classes.closeButton}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <Divider style={{ marginTop: "-15px" }} />
                    <DialogContent>
                        {(() => {
                            return (
                                <Formik
                                    initialValues={{
                                        "Amount": orderdata[0]?.Razorpay_Amount,
                                        "Currency": "INR",
                                        "Payment_Id": orderdata[0]?.Payment_Reference_Id
                                    }}

                                    onSubmit={values => {
                                        dispatch(paymentAuthorizationAction(values, state.ID, navigate));
                                    }}
                                >
                                    {({ handleSubmit, values }) => (
                                        <>
                                            <DialogContent>
                                                <DialogContentText style={{ color: 'black', fontSize: 20 }} >
                                                    Are you sure to Authorize ?
                                                </DialogContentText>
                                                <DialogContentText style={{ color: 'black' }} >
                                                    Total Order Amount: {orderdata[0]?.Total_Order_Value}
                                                </DialogContentText>
                                                <DialogContentText style={{ color: 'black' }} >
                                                    Payment Reference ID: {orderdata[0]?.Payment_Reference_Id}
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Button onClick={handleAuthClose} variant="contained" className={classes.cancelButton}>
                                                    CANCEL
                                                </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                                {isOrderPayment ?
                                                    <CircularProgress />
                                                    :
                                                    <Button onClick={handleSubmit} variant="contained" className={classes.acceptButton} disabled={isOrderPayment}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;AUTHORIZE&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </Button>
                                                }
                                            </DialogActions>
                                        </>
                                    )}
                                </Formik>
                            )
                        })()}
                    </DialogContent>
                </Dialog>

                <Dialog open={payopen} fullWidth={true} maxWidth={'sm'}
                    classes={{ paper: classes.dialogPaper }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle>
                        <Box display="flex" alignItems="center" style={{ fontSize: 20 + 'px', marginBottom: "5px", marginTop: "-5px" }} >
                            <span>Pay to Partner</span>
                            <IconButton style={{ marginTop: "-5px" }} onClick={handlePayClose} className={classes.closeButton}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <Divider style={{ marginTop: "-15px" }} />
                    <DialogContent>
                        {(() => {
                            return (
                                <Formik
                                    initialValues={{
                                        Order_Id: orderdata[0]?.Order_Id ? orderdata[0]?.Order_Id : "",
                                        Name: orderdata[0]?.Partner_Name ? orderdata[0]?.Partner_Name : "",
                                        Mobile: orderdata[0]?.Partner_Mobile ? orderdata[0]?.Partner_Mobile : "",
                                        Email: orderdata[0]?.Partner_Mail ? orderdata[0]?.Partner_Mail : "",
                                        Amount: orderdata[0]?.Partner_Final_Settlement_Amount ? orderdata[0]?.Partner_Final_Settlement_Amount : "",
                                        Fund_Account_Type: "",
                                        Reason: "",
                                        Transaction_From: "",
                                        UPI_ID: orderdata[0]?.Partner_UPI ? orderdata[0]?.Partner_UPI : "",
                                    }}

                                    onSubmit={values => {
                                        let formValues = JSON.parse(JSON.stringify(values));
                                        dispatch(OrderPartnerPaymentAction(formValues, state.ID, navigate));
                                    }}
                                >
                                    {({
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        values }) => (
                                        <form onSubmit={handleSubmit}>
                                            <ConnectedFocusError />
                                            <Card>
                                                <CardContent>
                                                    <Grid container spacing={3}>
                                                        <Grid item md={12} xs={12}>
                                                            <div style={{ marginLeft: '1rem', fontSize: '17px' }}>Partner Name: {values?.Name}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '17px' }}>Partner Email: {values?.Email}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '17px' }}>Partner Mobile: {values?.Mobile}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '17px' }}>Amount: {values?.Amount}</div>
                                                            <div style={{ marginLeft: '1rem', fontSize: '17px' }}>UPI ID: {values?.UPI_ID}</div>
                                                        </Grid>

                                                        {/* Reason */}
                                                        <Grid item md={12} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                label="Reason"
                                                                name="Reason"
                                                                value={values.Reason}
                                                                variant="outlined"
                                                                multiline
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>

                                                <Box display="flex" p={2}>
                                                    {isSubmitted ?
                                                        <CircularProgress />
                                                        :
                                                        <Button
                                                            disabled={isSubmitted}
                                                            type="submit"
                                                            variant="contained"
                                                            style={{ backgroundColor: '#27B6CC' }}
                                                        >
                                                            <span style={{ color: 'white' }}>Save details</span>
                                                        </Button>
                                                    }
                                                </Box>
                                            </Card>
                                        </form>
                                    )}
                                </Formik>
                            )
                        })()}
                    </DialogContent>
                </Dialog>

                {orderdata[0]?.Order_Type !== "RETAIL" && orderdata[0]?.Order_Type !== "MFH" ?
                    <div>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                        >
                            <DialogActions >
                                <Grid container justifyContent='center' spacing={3}>
                                    <Grid item md={6}>
                                        <Button onClick={viewEasystepinaddress(orderdata)}>EasyStepIn Address</Button>
                                        <Button onClick={viewBillingaddress(orderdata)}>Billing Address</Button>
                                        <Button onClick={viewShippingaddress(orderdata)}>Shipping Address</Button>
                                        <Button onClick={viewESIShippingaddress(orderdata)}>ESI & Shipping Address</Button>
                                        <Button onClick={viewBillShipaddress(orderdata)}>Billing & Shipping Address</Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </div>
                    : null
                }
                <Divider />
                <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                    <Grid item xs={6}>
                        <div style={{ marginLeft: '1rem' }}>Order ID: {orderdata[0]?.Order_Id}</div>
                        <div style={{ marginLeft: '1rem' }}>Order Status: {orderdata[0]?.Order_Status}</div>
                        <div style={{ marginLeft: '1rem' }}>User Email ID: {orderdata[0]?.User_Email_Id}</div>
                        <div style={{ marginLeft: '1rem' }}>Order Partner Detail ID: {orderdata[0]?.Order_Partner_Detail_ID}</div>
                        <div style={{ marginLeft: '1rem' }}>Order Partner Name: {orderdata[0]?.Partner_Name}</div>
                        <div style={{ marginLeft: '1rem' }}>Order Type: {orderdata[0]?.Order_Type}</div>
                        <div style={{ fontWeight: 'bold', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Billing Details:</div>
                        <div style={{ marginLeft: '1rem' }}>{orderdata[0]?.Billing_Address_Id}</div>
                        <div style={{ marginLeft: '1rem' }}>{orderdata[0]?.Email_Id}</div>
                        <div style={{ marginLeft: '1rem' }}>{orderdata[0]?.Address_Type}</div>
                        <div style={{ marginLeft: '1rem' }}>{orderdata[0]?.Land_Mark}</div>
                        <div style={{ marginLeft: '1rem' }}>{orderdata[0]?.City}</div>
                        <div style={{ marginLeft: '1rem' }}>{orderdata[0]?.State}</div>
                        <div style={{ marginLeft: '1rem' }}>{orderdata[0]?.Country}</div>
                        <div style={{ marginLeft: '1rem' }}>{orderdata[0]?.Zip}</div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ fontWeight: 'bold', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Payment Details:</div>
                        <div style={{ marginLeft: '1rem' }}>Total Order Amount: {orderdata[0]?.Total_Order_Value}</div>
                        <div style={{ marginLeft: '1rem' }}>User Total Tax Amount: {orderdata[0]?.User_Total_Tax_Amount}</div>
                        <div style={{ marginLeft: '1rem' }}>Meepaisa Total Tax Amount: {orderdata[0]?.Meepaisa_Total_Tax_Amount}</div>
                        <div style={{ marginLeft: '1rem' }}>Payment Date: {orderdata[0]?.Date}</div>
                        <div style={{ marginLeft: '1rem' }}>Payment Method: {orderdata[0]?.Payment_Type}</div>
                        <div style={{ marginLeft: '1rem' }}>Payment Reference ID: {orderdata[0]?.Payment_Reference_Id}</div>
                        <div style={{ marginLeft: '1rem' }}>Payment Status: {orderdata[0]?.Payment_Transaction_Status === "SUCCESS" ? <text style={{ color: 'green' }}>SUCCESS</text> : <text style={{ color: 'red' }}>FAIL</text>}</div>
                        <div style={{ marginLeft: '1rem' }}>User Billable Amount: {orderdata[0]?.User_Billable_Amount}</div>
                        <div style={{ marginLeft: '1rem' }}>Wallet Amount: {orderdata[0]?.Wallet_Amount}</div>
                        {orderdata[0]?.Order_Type !== "RETAIL" && orderdata[0]?.Order_Type !== "MFH" ?
                            <>
                                <div style={{ marginLeft: '1rem' }}>Shipping Fee: {orderdata[0]?.Shipping_Amount}</div>
                                <div style={{ marginLeft: '1rem' }}>Delivery Person Fee: {orderdata[0]?.Delivery_Person_Fee}</div>
                                <div style={{ marginLeft: '1rem' }}>Packaging Charges: {orderdata[0]?.Packaging_Charges}</div>
                            </>
                            : null
                        }
                    </Grid>
                </Grid>
                <CardContent>
                    {orderdata[0]?.Order_Id &&
                        <Formik
                            initialValues={{
                                Order_Update: {
                                    Billing_Address: {
                                        Billing_Address_Id: orderdata[0]?.Billing_Address_Id ? orderdata[0]?.Billing_Address_Id : "",
                                        Billing_Address_Update_Flag: orderdata[0]?.Billing_Address_Update_Flag ? orderdata[0]?.Billing_Address_Update_Flag : "",
                                    },
                                    Cancel_Return_User_Comment: Orders?.Cancel_Return_User_Comment ? Orders.Cancel_Return_User_Comment : "",
                                    Cancel_Return_User_Reason_Type: Orders?.Cancel_Return_User_Reason_Type ? Orders.Cancel_Return_User_Reason_Type : "",
                                    Corporate: {
                                        Corporate_Discont_Coupon: orderdata[0]?.Corporate_Discont_Coupon ? orderdata[0]?.Corporate_Discont_Coupon : "",
                                        Corporate_Discount_Amount: orderdata[0]?.Corporate_Discount_Amount ? orderdata[0]?.Corporate_Discount_Amount : "",
                                        Corporate_Update_Flag: orderdata[0]?.Corporate_Update_Flag ? orderdata[0]?.Corporate_Update_Flag : "",
                                    },
                                    Gift_Voucher: {
                                        Gift_ID: orderdata[0]?.Gift_ID ? orderdata[0]?.Gift_ID : "",
                                        Gift_ID_Amount: orderdata[0]?.Gift_ID_Amount ? orderdata[0]?.Gift_ID_Amount : "",
                                        Gift_Voucher_Update_Flag: orderdata[0]?.Gift_Voucher_Update_Flag ? state?.Gift_Voucher?.Gift_Voucher_Update_Flag : "",
                                    },
                                    Is_Approved: Orders?.Is_Approved ? Orders.Is_Approved : "",
                                    // Item_Data: {
                                    //     Item_Master_Data: [
                                    //         {
                                    //             Brand_Id: allOrderDetails?.Brand_Id ? allOrderDetails?.Brand_Id : "",
                                    //             Is_Approved: allOrderDetails?.Is_Approved ? allOrderDetails?.Is_Approved : "",
                                    //             Is_Item_Return_Flag: allOrderDetails?.Is_Item_Return_Flag ? allOrderDetails?.Is_Item_Return_Flag : "",
                                    //             Item_Financial_Summary: {
                                    //                 Item_Master_Financial_Update_Flag: allOrderDetails?.Item_Master_Financial_Update_Flag ? allOrderDetails?.Item_Master_Financial_Update_Flag : "",
                                    //                 Meepaisa_Billable_Amount: allOrderDetails?.Meepaisa_Billable_Amount ? allOrderDetails?.Meepaisa_Billable_Amount : "",
                                    //                 Meepaisa_Gift_Wrap_Amount: allOrderDetails?.Meepaisa_Gift_Wrap_Amount ? allOrderDetails?.Meepaisa_Gift_Wrap_Amount : "",
                                    //                 Meepaisa_Packaging_Charges: allOrderDetails?.Meepaisa_Packaging_Charges ? allOrderDetails?.Meepaisa_Packaging_Charges : "",
                                    //                 Meepaisa_Shipping_Fee: allOrderDetails?.Meepaisa_Shipping_Fee ? allOrderDetails?.Meepaisa_Shipping_Fee : "",
                                    //                 Meepaisa_Total_Tax_Amount: allOrderDetails?.Meepaisa_Total_Tax_Amount ? allOrderDetails?.Meepaisa_Total_Tax_Amount : "",
                                    //                 Net_Price: allOrderDetails?.Net_Price ? allOrderDetails?.Net_Price : "",
                                    //                 Partner_Total_Price: allOrderDetails?.Partner_Total_Price ? allOrderDetails?.Partner_Total_Price : "",
                                    //                 User_Billable_Amount: allOrderDetails?.User_Billable_Amount ? allOrderDetails?.User_Billable_Amount : "",
                                    //                 User_Total_Tax_Amount: allOrderDetails?.User_Total_Tax_Amount ? allOrderDetails?.User_Total_Tax_Amount : "",
                                    //             },
                                    //             Item_Master_Id: allOrderDetails?.Item_Master_Id ? allOrderDetails?.Item_Master_Id : "",
                                    //             Item_Master_Update_Flag: allOrderDetails?.Item_Master_Update_Flag ? allOrderDetails?.Item_Master_Update_Flag : "",
                                    //             Line_Item_Status: allOrderDetails?.Line_Item_Status ? allOrderDetails?.Line_Item_Status : "",
                                    //             MRP: allOrderDetails?.MRP ? allOrderDetails?.MRP : "",
                                    //             Max_Days_To_Return: allOrderDetails?.Max_Days_To_Return ? allOrderDetails?.Max_Days_To_Return : "",
                                    //             Order_Date: allOrderDetails?.Order_Date ? allOrderDetails?.Order_Date : "",
                                    //             Partner_Details_Id: allOrderDetails?.Partner_Details_Id ? allOrderDetails?.Partner_Details_Id : "",
                                    //             Payment_Status: allOrderDetails?.Payment_Status ? allOrderDetails?.Payment_Status : "",
                                    //             Product_Id: allOrderDetails?.Product_Id ? allOrderDetails?.Product_Id : "",
                                    //             Quantity: allOrderDetails?.Quantity ? allOrderDetails?.Quantity : "",
                                    //             Selling_Price: allOrderDetails?.Selling_Price ? allOrderDetails?.Selling_Price : "",
                                    //             Shipping_Address: {
                                    //                 Item_Master_Shipping_Address_Update_Flag: allOrderDetails?.Item_Master_Shipping_Address_Update_Flag ? allOrderDetails?.Item_Master_Shipping_Address_Update_Flag : "",
                                    //                 Shipping_Address_Id: allOrderDetails?.Shipping_Address_Id ? allOrderDetails?.Shipping_Address_Id : "",
                                    //             },
                                    //             Shipping_Summary: {
                                    //                 Expected_Delivery_Date_Time: allOrderDetails?.Expected_Delivery_Date_Time ? allOrderDetails?.Expected_Delivery_Date_Time : "",
                                    //                 Expected_Delivery_Time_In_Minutes: allOrderDetails?.Expected_Delivery_Time_In_Minutes ? allOrderDetails?.Expected_Delivery_Time_In_Minutes : "",
                                    //                 Item_Master_Shipping_Update_Flag: "YES",
                                    //                 Live_Delivered_By_Email: allOrderDetails?.Live_Delivered_By_Email ? allOrderDetails?.Live_Delivered_By_Email : "",
                                    //                 Live_Delivered_By_Name: allOrderDetails?.Live_Delivered_By_Name ? allOrderDetails?.Live_Delivered_By_Name : "",
                                    //                 Live_Delivery_Person_Phone: allOrderDetails?.Live_Delivery_Person_Phone ? allOrderDetails?.Live_Delivery_Person_Phone : "",
                                    //                 Order_Delivery_Transactions: {
                                    //                     Del_Order_Id: allOrderDetails?.Del_Order_Id ? allOrderDetails?.Del_Order_Id : "",
                                    //                     Order_Delivery_Transaction_Update_Flag: "YES",
                                    //                 },
                                    //                 Ordered_Date_Time: allOrderDetails?.Ordered_Date_Time ? allOrderDetails?.Ordered_Date_Time : "",
                                    //                 Partner_Address_ID: allOrderDetails?.Partner_Address_ID ? allOrderDetails?.Partner_Address_ID : "",
                                    //                 Shipping_Channel: allOrderDetails?.Shipping_Channel ? allOrderDetails?.Shipping_Channel : "",
                                    //                 Shipping_Fee: allOrderDetails?.Shipping_Fee ? allOrderDetails?.Shipping_Fee : "",
                                    //                 Shipping_ID: allOrderDetails?.Shipping_ID ? allOrderDetails?.Shipping_ID : "",
                                    //                 Shipping_Tax: allOrderDetails?.Shipping_Tax ? allOrderDetails?.Shipping_Tax : "",
                                    //                 Shipping_Total: allOrderDetails?.Shipping_Total ? allOrderDetails?.Shipping_Total : "",
                                    //                 Shipping_Type: allOrderDetails?.Shipping_Type ? allOrderDetails?.Shipping_Type : "",
                                    //                 Thirdparty_Channel_Partner_Name: allOrderDetails?.Thirdparty_Channel_Partner_Name ? allOrderDetails?.Thirdparty_Channel_Partner_Name : "",
                                    //                 Thirdparty_Delivery_Person_Name: allOrderDetails?.Thirdparty_Delivery_Person_Name ? allOrderDetails?.Thirdparty_Delivery_Person_Name : "",
                                    //                 Thirdparty_Delivery_Phone: allOrderDetails?.Thirdparty_Delivery_Phone ? allOrderDetails?.Thirdparty_Delivery_Phone : "",
                                    //                 Thirdparty_Shipping_Method: allOrderDetails?.Thirdparty_Shipping_Method ? allOrderDetails?.Thirdparty_Shipping_Method : "",
                                    //                 Thirdparty_Shipping_Tracking_Number: allOrderDetails?.Thirdparty_Shipping_Tracking_Number ? allOrderDetails?.Thirdparty_Shipping_Tracking_Number : "",
                                    //                 Thirdparty_Tracking_Verification_URL: allOrderDetails?.Thirdparty_Tracking_Verification_URL ? allOrderDetails?.Thirdparty_Tracking_Verification_URL : "",
                                    //                 Delivery_Message: allOrderDetails?.Delivery_Message ? allOrderDetails?.Delivery_Message : ""
                                    //             }
                                    //         }
                                    //     ]
                                    // },
                                    Meepaisa_Coupon: {
                                        Meepaisa_Coupon_Amount: orderdata[0]?.Meepaisa_Coupon_Amount ? orderdata[0]?.Meepaisa_Coupon_Amount : "",
                                        Meepaisa_Coupon_ID: orderdata[0]?.Meepaisa_Coupon_ID ? orderdata[0]?.Meepaisa_Coupon_ID : "",
                                        Meepaisa_Coupon_Update_Flag: orderdata[0]?.Meepaisa_Coupon_Update_Flag ? orderdata[0]?.Meepaisa_Coupon_Update_Flag : ""
                                    },
                                    Order_Financial_Summary: {
                                        Cashback_To_User: orderdata[0]?.Cashback_To_User ? orderdata[0]?.Cashback_To_User : "",
                                        Delivery_Person_Tip: orderdata[0]?.Delivery_Person_Tip ? orderdata[0]?.Delivery_Person_Tip : "",
                                        Financial_Update_Flag: orderdata[0]?.Financial_Update_Flag ? orderdata[0]?.Financial_Update_Flag : "",
                                        Meepaisa_Gift_Wrap_Amount: orderdata[0]?.Meepaisa_Gift_Wrap_Amount ? orderdata[0]?.Meepaisa_Gift_Wrap_Amount : "",
                                        Meepaisa_Packaging_Charges: orderdata[0]?.Meepaisa_Packaging_Charges ? orderdata[0]?.Meepaisa_Packaging_Charges : "",
                                        Meepaisa_Shipping_Fee: orderdata[0]?.Meepaisa_Shipping_Fee ? orderdata[0]?.Meepaisa_Shipping_Fee : "",
                                        Meepaisa_Total_Tax_Amount: orderdata[0]?.Meepaisa_Total_Tax_Amount ? orderdata[0]?.Meepaisa_Total_Tax_Amount : "",
                                        Meepiasa_Billable_Amount_After_Taxes: orderdata[0]?.Meepiasa_Billable_Amount_After_Taxes ? orderdata[0]?.Meepiasa_Billable_Amount_After_Taxes : "",
                                        Meepiasa_Billable_Amount_Before_Taxes: orderdata[0]?.Meepiasa_Billable_Amount_Before_Taxes ? orderdata[0]?.Meepiasa_Billable_Amount_Before_Taxes : "",
                                        Total_Order_Amount: orderdata[0]?.Total_Order_Amount ? orderdata[0]?.Total_Order_Amount : "",
                                        User_Billable_Amount_After_Taxes: orderdata[0]?.User_Billable_Amount_After_Taxes ? orderdata[0]?.User_Billable_Amount_After_Taxes : "",
                                        User_Billable_Amount_Before_Taxes: orderdata[0]?.User_Billable_Amount_Before_Taxes ? orderdata[0]?.User_Billable_Amount_Before_Taxes : "",
                                        User_Shipping_Fee: orderdata[0]?.User_Shipping_Fee ? orderdata[0]?.User_Shipping_Fee : "",
                                        User_Total_Tax_Amount: orderdata[0]?.User_Total_Tax_Amount ? orderdata[0]?.User_Total_Tax_Amount : "",
                                    },
                                    Order_Id: Orders?.Order_Id ? Orders?.Order_Id : "",
                                    Order_Shipping_Summary: {
                                        Expected_Delivery_Date_Time: orderdata[0]?.Expected_Delivery_Date_Time ? orderdata[0]?.Expected_Delivery_Date_Time : "",
                                        Expected_Delivery_Time_In_Minutes: orderdata[0]?.Expected_Delivery_Time_In_Minutes ? orderdata[0]?.Expected_Delivery_Time_In_Minutes : "",
                                        Live_Delivered_By_Email: orderdata[0]?.Live_Delivered_By_Email ? orderdata[0]?.Live_Delivered_By_Email : "",
                                        Live_Delivered_By_Name: orderdata[0]?.Live_Delivered_By_Name ? orderdata[0]?.Live_Delivered_By_Name : "",
                                        Live_Delivery_Person_Phone: orderdata[0]?.Live_Delivery_Person_Phone ? orderdata[0]?.Live_Delivery_Person_Phone : "",
                                        Order_Delivery_Transactions: {
                                            Del_Order_Id: orderdata[0]?.Del_Order_Id ? orderdata[0]?.Del_Order_Id : "",
                                            Order_Delivery_Transaction_Update_Flag: "YES"
                                        },
                                        Order_Shipping_Update_Flag: "YES",
                                        Ordered_Date_Time: orderdata[0]?.Ordered_Date_Time ? orderdata[0]?.Ordered_Date_Time : "",
                                        Partner_Address_ID: orderdata[0]?.Partner_Address_ID ? orderdata[0]?.Partner_Address_ID : "",
                                        Shipping_Channel: orderdata[0]?.Shipping_Channel ? orderdata[0]?.Shipping_Channel : "",
                                        Shipping_Fee: orderdata[0]?.Shipping_Fee ? orderdata[0]?.Shipping_Fee : "",
                                        Shipping_ID: orderdata[0]?.Shipping_ID ? orderdata[0]?.Shipping_ID : "",
                                        Shipping_Tax: orderdata[0]?.Shipping_Tax ? orderdata[0]?.Shipping_Tax : "",
                                        Shipping_Total: orderdata[0]?.Shipping_Total ? orderdata[0]?.Shipping_Total : "",
                                        Shipping_Type: orderdata[0]?.Shipping_Type ? orderdata[0]?.Shipping_Type : "",
                                        Thirdparty_Channel_Partner_Name: orderdata[0]?.Thirdparty_Channel_Partner_Name ? orderdata[0]?.Thirdparty_Channel_Partner_Name : "",
                                        Thirdparty_Delivery_Person_Name: orderdata[0]?.Thirdparty_Delivery_Person_Name ? orderdata[0]?.Thirdparty_Delivery_Person_Name : "",
                                        Thirdparty_Delivery_Phone: orderdata[0]?.Thirdparty_Delivery_Phone ? orderdata[0]?.Thirdparty_Delivery_Phone : "",
                                        Thirdparty_Shipping_Method: orderdata[0]?.Thirdparty_Shipping_Method ? orderdata[0]?.Thirdparty_Shipping_Method : "",
                                        Thirdparty_Shipping_Tracking_Number: orderdata[0]?.Thirdparty_Shipping_Tracking_Number ? orderdata[0]?.Thirdparty_Shipping_Tracking_Number : "",
                                        Thirdparty_Tracking_Verification_URL: orderdata[0]?.Thirdparty_Tracking_Verification_URL ? orderdata[0]?.Thirdparty_Tracking_Verification_URL : "",
                                        Delivery_Message: orderdata[0]?.Delivery_Message ? orderdata[0]?.Delivery_Message : ""
                                    },
                                    Order_Status: Orders?.Order_Status ? Orders?.Order_Status : "",
                                    Order_Type: Orders?.Order_Type ? Orders?.Order_Type : "",
                                    Order_Transactions: {
                                        Order_Transaction_Update_Flag: orderdata[0]?.Order_Transaction_Update_Flag ? orderdata[0]?.Order_Transaction_Update_Flag : "",
                                        Payment_Date: orderdata[0]?.Payment_Date ? orderdata[0]?.Payment_Date : "",
                                        Payment_Method: orderdata[0]?.Payment_Method ? orderdata[0]?.Payment_Method : "",
                                        Payment_Reference_Id: orderdata[0]?.Payment_Reference_Id ? orderdata[0]?.Payment_Reference_Id : "",
                                        Payment_Status: orderdata[0]?.Payment_Status ? orderdata[0]?.Payment_Status : "",
                                        Transaction_Id: orderdata[0]?.Transaction_Id ? orderdata[0]?.Transaction_Id : "",
                                        User_Billable_Amount: orderdata[0]?.User_Billable_Amount ? orderdata[0]?.User_Billable_Amount : ""
                                    },
                                    Order_Update_Flag: "YES",
                                    Order_Update_Type: "O",
                                    User_Email_Id: Orders?.User_Email_Id ? Orders?.User_Email_Id : "",
                                    Order_Edit: Orders?.Order_Edit ? Orders?.Order_Edit : "",
                                    Wallet: {
                                        Cashback_Amount_To_User_Wallet: orderdata[0]?.Cashback_Amount_To_User_Wallet ? orderdata[0]?.Cashback_Amount_To_User_Wallet : "",
                                        Wallet_Amount: orderdata[0]?.Wallet_Amount ? orderdata[0]?.Wallet_Amount : "",
                                        Wallet_Id: orderdata[0]?.Wallet_Id ? orderdata[0]?.Wallet_Id : "",
                                        Wallet_Update_Flag: orderdata[0]?.Wallet_Update_Flag ? orderdata[0]?.Wallet_Update_Flag : "",
                                    }
                                }
                            }}

                            onSubmit={(values, { setErrors, setSubmitting }) => {
                                let submitTest = true;
                                setSubmitting(false);
                                const errors = {};

                                setErrors(errors);
                                if (submitTest) {
                                    let formValues = JSON.parse(JSON.stringify(values));
                                    dispatch(updateOrderStatusAction(formValues, state.ID, navigate, setErrorMessage, setErrors));
                                }
                            }
                            }
                        >
                            {({
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                values,
                                setFieldValue,
                            }) => (
                                <>
                                    <ConnectedFocusError />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item md={8} xs={12}>
                                                <Grid container spacing={3}>
                                                    {/* Order_Status input field with value */}

                                                    {values?.Order_Update?.Order_Status === "CANCELLED" ?
                                                        <>
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Cancel Return User Reason Type"
                                                                    name={`Order_Update.Cancel_Return_User_Reason_Type`}
                                                                    select
                                                                    InputLabelProps={{ shrink: true }}
                                                                    SelectProps={{ native: true }}
                                                                    value={values?.Order_Update?.Cancel_Return_User_Reason_Type}
                                                                    variant="outlined"
                                                                >
                                                                    {cancelreasontype?.map(option => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Cancel Return User Comment"
                                                                    name={`Order_Update.Cancel_Return_User_Comment`}
                                                                    value={values?.Order_Update?.Cancel_Return_User_Comment}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            </Grid>
                                                        </>
                                                        : null
                                                    }

                                                    {values?.Order_Update?.Order_Type === "RETAIL" || values?.Order_Update?.Order_Type === "MFH" ?
                                                        <Grid item md={6} xs={12}>
                                                            {/* Order_Status input field with value */}
                                                            <TextField
                                                                fullWidth
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                label="Order Status"
                                                                name={`Order_Update.Order_Status`}
                                                                select
                                                                InputLabelProps={{ shrink: true }}
                                                                SelectProps={{ native: true }}
                                                                value={values?.Order_Update?.Order_Status}
                                                                variant="outlined"
                                                            >
                                                                {values?.Order_Update?.Order_Status === "NEW" || values?.Order_Update?.Order_Status === "ORDERED" || values?.Order_Update?.Order_Status === "ACCEPTED" || values?.Order_Update?.Order_Status === "SHIPPED" ?
                                                                    <>
                                                                        {allretailorderlevelstatus?.map(option => (
                                                                            <option key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </option>
                                                                        ))}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {allorderstatus?.map(option => (
                                                                            <option key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </option>
                                                                        ))}
                                                                    </>
                                                                }
                                                            </TextField>
                                                        </Grid>
                                                        :
                                                        <>
                                                            {/* Order_Status input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Order Status"
                                                                    name={`Order_Update.Order_Status`}
                                                                    select
                                                                    InputLabelProps={{ shrink: true }}
                                                                    SelectProps={{ native: true }}
                                                                    value={values?.Order_Update?.Order_Status}
                                                                    variant="outlined"
                                                                >
                                                                    {allorderstatus?.map(option => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            {/* Shipping_Channel input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Shipping Channel"
                                                                    name={`Order_Update.Order_Shipping_Summary.Shipping_Channel`}
                                                                    select
                                                                    InputLabelProps={{ shrink: true }}
                                                                    SelectProps={{ native: true }}
                                                                    value={values?.Order_Update?.Order_Shipping_Summary?.Shipping_Channel}
                                                                    variant="outlined"
                                                                >

                                                                    {ShippingchannelpartnerOption?.map(option => (
                                                                        <option key={option.value} value={option.value} >
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>

                                                            {/* Shipping_Type input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Shipping Type"
                                                                    name="Order_Update.Order_Shipping_Summary.Shipping_Type"
                                                                    select
                                                                    InputLabelProps={{ shrink: true }}
                                                                    SelectProps={{ native: true }}
                                                                    value={values?.Order_Update?.Order_Shipping_Summary?.Shipping_Type}
                                                                    variant="outlined"
                                                                >
                                                                    {ShippingTypeOption?.map(option => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>

                                                            {/* Partner address input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Partner Address"
                                                                    name="Order_Update.Order_Shipping_Summary.Partner_Address_ID"
                                                                    select
                                                                    InputLabelProps={{ shrink: true }}
                                                                    SelectProps={{ native: true }}
                                                                    value={values?.Order_Update?.Order_Shipping_Summary?.Partner_Address_ID}
                                                                    variant="outlined"
                                                                >
                                                                    <option key="" value="">--Please Select--</option>
                                                                    {partneraddress?.sort((a, b) => a.Address_Type?.localeCompare(b.Address_Type))?.map(option => (
                                                                        <option key={option.Partner_Address_Id} value={option.Partner_Address_Id}>
                                                                            {option.Address_Type + ", " + option.Country + ", " + option.State + ", " + option.City + ", " + option.Land_Mark + ", " + option.Street + ", " + option.Zip}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>

                                                            {/* Delivery_Message input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Delivery Delay Message"
                                                                    name="Order_Update.Order_Shipping_Summary.Delivery_Message"
                                                                    select
                                                                    InputLabelProps={{ shrink: true }}
                                                                    SelectProps={{ native: true }}
                                                                    value={values?.Order_Update?.Order_Shipping_Summary?.Delivery_Message}
                                                                    variant="outlined"
                                                                >
                                                                    {deliverymessage.map(option => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            {values?.Order_Update?.Order_Shipping_Summary?.Shipping_Channel === 'Thirdparty' ? (
                                                                <>
                                                                    {/* Thirdparty_Channel_Partner_Name input field with value */}
                                                                    <Grid item md={6} xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            label="Thirdparty Channel Partner Name"
                                                                            name="Order_Update.Order_Shipping_Summary.Thirdparty_Channel_Partner_Name"
                                                                            value={values?.Order_Update?.Order_Shipping_Summary?.Thirdparty_Channel_Partner_Name}
                                                                            variant="outlined"
                                                                            InputLabelProps={{ shrink: true }}
                                                                        >
                                                                        </TextField>
                                                                    </Grid>
                                                                    {/* Thirdparty_Shipping_Method input field with value */}
                                                                    <Grid item md={6} xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            label="Thirdparty Shipping Method"
                                                                            name="Order_Update.Order_Shipping_Summary.Thirdparty_Shipping_Method"
                                                                            value={values?.Order_Update?.Order_Shipping_Summary?.Thirdparty_Shipping_Method}
                                                                            variant="outlined"
                                                                            InputLabelProps={{ shrink: true }}
                                                                        >
                                                                        </TextField>
                                                                    </Grid>
                                                                    {/* Thirdparty_Shipping_Tracking_Number input field with value */}
                                                                    <Grid item md={6} xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            label="Thirdparty Shipping Tracking Number"
                                                                            name="Order_Update.Order_Shipping_Summary.Thirdparty_Shipping_Tracking_Number"
                                                                            value={values?.Order_Update?.Order_Shipping_Summary?.Thirdparty_Shipping_Tracking_Number}
                                                                            variant="outlined"
                                                                            InputLabelProps={{ shrink: true }}
                                                                        >
                                                                        </TextField>
                                                                    </Grid>
                                                                    {/* Thirdparty_Tracking_Verification_URL input field with value */}
                                                                    <Grid item md={6} xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            label="Thirdparty Tracking Verification URL"
                                                                            name="Order_Update.Order_Shipping_Summary.Thirdparty_Tracking_Verification_URL"
                                                                            value={values?.Order_Update?.Order_Shipping_Summary?.Thirdparty_Tracking_Verification_URL}
                                                                            variant="outlined"
                                                                            InputLabelProps={{ shrink: true }}
                                                                        >
                                                                        </TextField>
                                                                    </Grid>
                                                                    {/* Thirdparty_Delivery_Person_Name input field with value */}
                                                                    <Grid item md={6} xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            label="Thirdparty Delivery Person Name"
                                                                            name="Order_Update.Order_Shipping_Summary.Thirdparty_Delivery_Person_Name"
                                                                            value={values?.Order_Update?.Order_Shipping_Summary?.Thirdparty_Delivery_Person_Name}
                                                                            variant="outlined"
                                                                            InputLabelProps={{ shrink: true }}
                                                                        >
                                                                        </TextField>
                                                                    </Grid>
                                                                    {/* Thirdparty_Delivery_Phone input field with value */}
                                                                    <Grid item md={6} xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            label="Thirdparty Delivery Phone"
                                                                            name="Order_Update.Order_Shipping_Summary.Thirdparty_Delivery_Phone"
                                                                            value={values?.Order_Update?.Order_Shipping_Summary?.Thirdparty_Delivery_Phone}
                                                                            variant="outlined"
                                                                            InputLabelProps={{ shrink: true }}
                                                                        >
                                                                        </TextField>
                                                                    </Grid>
                                                                    {/* Live_Delivered_By_Email input field with value */}
                                                                    <Grid item md={6} xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            label="Delivered By Email"
                                                                            name="Order_Update.Order_Shipping_Summary.Live_Delivered_By_Email"
                                                                            value={values?.Order_Update?.Order_Shipping_Summary?.Live_Delivered_By_Email}
                                                                            variant="outlined"
                                                                            InputLabelProps={{ shrink: true }}
                                                                        >
                                                                        </TextField>
                                                                    </Grid>
                                                                    {/* Live_Delivered_By_Name input field with value */}
                                                                    <Grid item md={6} xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            label="Delivered By Name"
                                                                            name="Order_Update.Order_Shipping_Summary.Live_Delivered_By_Name"
                                                                            value={values?.Order_Update?.Order_Shipping_Summary?.Live_Delivered_By_Name}
                                                                            variant="outlined"
                                                                            InputLabelProps={{ shrink: true }}
                                                                        >
                                                                        </TextField>
                                                                    </Grid>
                                                                    {/* Live_Delivery_Person_Phone input field with value */}
                                                                    <Grid item md={6} xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            label="Delivered Person Phone"
                                                                            name="Order_Update.Order_Shipping_Summary.Live_Delivery_Person_Phone"
                                                                            value={values?.Order_Update?.Order_Shipping_Summary?.Live_Delivery_Person_Phone}
                                                                            variant="outlined"
                                                                            InputLabelProps={{ shrink: true }}
                                                                        >
                                                                        </TextField>
                                                                    </Grid>

                                                                </>
                                                            ) : values?.Order_Update?.Order_Shipping_Summary?.Shipping_Channel === 'ESI' ? (
                                                                <>
                                                                    {/* Live_Delivered_By_Email input field with value */}
                                                                    <Grid item md={6} xs={12}>
                                                                        <Autocomplete
                                                                            options={esidelipersons}
                                                                            groupBy={(option) => option?.firstLetter}
                                                                            id="combo-box-demo"
                                                                            getOptionLabel={(option) => option?.Del_User_Email_Id}
                                                                            onChange={(e, value) => {
                                                                                setFieldValue("Order_Update.Order_Shipping_Summary.Live_Delivered_By_Email", value.Del_User_Email_Id ? value.Del_User_Email_Id : "");
                                                                                setFieldValue("Order_Update.Order_Shipping_Summary.Live_Delivered_By_Name", value.First_Name ? value.First_Name : "");
                                                                                setFieldValue("Order_Update.Order_Shipping_Summary.Live_Delivery_Person_Phone", value.Mobile ? value.Mobile : "");
                                                                            }}
                                                                            value={esidelipersons?.length > 0 ? esidelipersons?.filter((item) => item?.Del_User_Email_Id === values?.Order_Update?.Order_Shipping_Summary?.Live_Delivered_By_Email)[0] : ""}
                                                                            renderOption={(option) => (
                                                                                <React.Fragment>
                                                                                    <ListItemAvatar>
                                                                                        <img
                                                                                            alt={option.type}
                                                                                            className={classes.image}
                                                                                            style={{
                                                                                                height: 48,
                                                                                                width: 48
                                                                                            }}
                                                                                            src={option?.Profile_Pic_Path}
                                                                                        />
                                                                                    </ListItemAvatar>
                                                                                    <ListItemText
                                                                                        secondary={option?.Del_User_Email_Id}
                                                                                        primary={option?.First_Name + " " + option?.Last_Name}
                                                                                    />
                                                                                </React.Fragment>
                                                                            )}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    placeholder="Search"
                                                                                    variant="outlined"
                                                                                    InputProps={{
                                                                                        ...params.InputProps,
                                                                                        autoComplete: 'off', // disable autocomplete and autofill,
                                                                                        startAdornment: (
                                                                                            <React.Fragment>
                                                                                                <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                                                {params.InputProps.startAdornment}
                                                                                            </React.Fragment>
                                                                                        ),
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />

                                                                    </Grid>
                                                                    {/* Live_Delivered_By_Name input field with value */}
                                                                    <Grid item md={6} xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            label="Delivered By Name"
                                                                            name="Order_Update.Order_Shipping_Summary.Live_Delivered_By_Name"
                                                                            value={values?.Order_Update?.Order_Shipping_Summary?.Live_Delivered_By_Name}
                                                                            variant="outlined"
                                                                            disabled
                                                                            InputLabelProps={{ shrink: true }}
                                                                        >
                                                                        </TextField>
                                                                    </Grid>
                                                                    {/* Live_Delivery_Person_Phone input field with value */}
                                                                    <Grid item md={6} xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            label="Delivered Person Phone"
                                                                            name="Order_Update.Order_Shipping_Summary.Live_Delivery_Person_Phone"
                                                                            value={values?.Order_Update?.Order_Shipping_Summary?.Live_Delivery_Person_Phone}
                                                                            variant="outlined"
                                                                            disabled
                                                                            InputLabelProps={{ shrink: true }}
                                                                        >
                                                                        </TextField>
                                                                    </Grid>

                                                                </>
                                                            ) : (
                                                                <>
                                                                    {/* Live_Delivered_By_Email input field with value */}
                                                                    <Grid item md={6} xs={12}>
                                                                        <Autocomplete
                                                                            options={partnerdelipersons}
                                                                            groupBy={(option) => option?.firstLetter}
                                                                            id="combo-box-demo"
                                                                            getOptionLabel={(option) => option?.Del_User_Email_Id}
                                                                            onChange={(e, value) => {
                                                                                setFieldValue("Order_Update.Order_Shipping_Summary.Live_Delivered_By_Email", value.Del_User_Email_Id ? value.Del_User_Email_Id : "");
                                                                                setFieldValue("Order_Update.Order_Shipping_Summary.Live_Delivered_By_Name", value.First_Name ? value.First_Name : "");
                                                                                setFieldValue("Order_Update.Order_Shipping_Summary.Live_Delivery_Person_Phone", value.Mobile ? value.Mobile : "");
                                                                            }}
                                                                            value={partnerdelipersons?.length > 0 ? partnerdelipersons?.filter((item) => item?.Del_User_Email_Id === values?.Order_Update?.Order_Shipping_Summary?.Live_Delivered_By_Email)[0] : ""}
                                                                            renderOption={(option) => (
                                                                                <React.Fragment>
                                                                                    <ListItemAvatar>
                                                                                        <img
                                                                                            alt={option.type}
                                                                                            className={classes.image}
                                                                                            style={{
                                                                                                height: 48,
                                                                                                width: 48
                                                                                            }}
                                                                                            src={option?.Profile_Pic_Path}
                                                                                        />
                                                                                    </ListItemAvatar>
                                                                                    <ListItemText
                                                                                        secondary={option?.Del_User_Email_Id}
                                                                                        primary={option?.First_Name + " " + option?.Last_Name}
                                                                                    />
                                                                                </React.Fragment>
                                                                            )}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    placeholder="Search"
                                                                                    variant="outlined"
                                                                                    InputProps={{
                                                                                        ...params.InputProps,
                                                                                        autoComplete: 'off', // disable autocomplete and autofill,
                                                                                        startAdornment: (
                                                                                            <React.Fragment>
                                                                                                <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                                                {params.InputProps.startAdornment}
                                                                                            </React.Fragment>
                                                                                        ),
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />

                                                                    </Grid>
                                                                    {/* Live_Delivered_By_Name input field with value */}
                                                                    <Grid item md={6} xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            label="Delivered By Name"
                                                                            name="Order_Update.Order_Shipping_Summary.Live_Delivered_By_Name"
                                                                            value={values?.Order_Update?.Order_Shipping_Summary?.Live_Delivered_By_Name}
                                                                            variant="outlined"
                                                                            disabled
                                                                            InputLabelProps={{ shrink: true }}
                                                                        >
                                                                        </TextField>
                                                                    </Grid>
                                                                    {/* Live_Delivery_Person_Phone input field with value */}
                                                                    <Grid item md={6} xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            label="Delivered Person Phone"
                                                                            name="Order_Update.Order_Shipping_Summary.Live_Delivery_Person_Phone"
                                                                            value={values?.Order_Update?.Order_Shipping_Summary?.Live_Delivery_Person_Phone}
                                                                            variant="outlined"
                                                                            disabled
                                                                            InputLabelProps={{ shrink: true }}
                                                                        >
                                                                        </TextField>
                                                                    </Grid>
                                                                </>
                                                            )}
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <Box display="flex" pl={2} >
                                        <span style={{ color: "red" }}>{errorMessage.global.message}</span>
                                    </Box>

                                    <Box display="flex" p={2}>
                                        {isOrderUpdate ?
                                            <CircularProgress />
                                            :
                                            <Button
                                                style={{ backgroundColor: '#27B6CC' }}
                                                variant="contained"
                                                onClick={handleSubmit}
                                                disabled={isOrderUpdate}
                                            >
                                                <span style={{ color: 'white' }}>Save details</span>
                                            </Button>
                                        }
                                    </Box>
                                </>
                            )}
                        </Formik>
                    }
                </CardContent>
                <Divider />
            </Card>

            {allOrderDetails?.map((data, index) => (
                <Card style={{ marginTop: '2rem' }} key={index}>
                    <CardHeader title="Shipping Details" />
                    {data?.Order_Type === "RETAIL" || data?.Order_Type === "MFH" ?
                        <div>
                            <Box display="flex" style={{ marginTop: "-50px" }} justifyContent="flex-end" p={1}>
                                <Button
                                    style={{ backgroundColor: '#27B6CC', margin: "1%" }}
                                    variant="contained"
                                    onClick={handleClickOpen}
                                >
                                    <span style={{ color: 'white' }}>PRINT</span>
                                </Button>
                                {data?.Shipping_Reference_Id === "" || null ?
                                    <Button
                                        style={{ backgroundColor: '#27B6CC', margin: "1%" }}
                                        variant="contained"
                                        onClick={handleClickTestOpen}
                                    >
                                        <span style={{ color: 'white' }}>SHIP TO</span>
                                    </Button>
                                    :
                                    data?.Thirdparty_Channel_Partner_Name === "NIMBUSPOST" ?
                                        <Button
                                            style={{ backgroundColor: '#27B6CC', margin: "1%" }}
                                            variant="contained"
                                            onClick={handleClickCancelOrderOpen}
                                        >
                                            <span style={{ color: 'white' }}>CANCEL SHIPMENT</span>
                                        </Button>
                                        :
                                        <Button
                                            style={{ backgroundColor: '#27B6CC', margin: "1%" }}
                                            variant="contained"
                                            onClick={handleClickCancelShipOpen}
                                        >
                                            <span style={{ color: 'white' }}>CANCEL SHIPMENT</span>
                                        </Button>
                                }

                            </Box>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                            >
                                <DialogActions >
                                    <Grid container justifyContent='center' spacing={3}>
                                        <Grid item md={6}>
                                            <Button onClick={viewIMEasystepinaddress(data)}>EasyStepIn Address</Button>
                                            <Button onClick={viewIMBillingaddress(data)}>Billing Address</Button>
                                            <Button onClick={viewIMShippingaddress(data)}>Shipping Address</Button>
                                            <Button onClick={viewIMESIShippingaddress(data)}>ESI & Shipping Address</Button>
                                            <Button onClick={viewIMBillShipaddress(data)}>Billing & Shipping Address</Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Dialog>

                            {/* select dropdown */}
                            <Dialog
                                open={testopen}
                                onClose={handleTestClose}
                            >
                                <DialogActions >
                                    <Grid container justifyContent='center' spacing={3}>
                                        <Grid item md={6}>
                                            <Button onClick={() => { handleClickShipOrderOpen(data) }}>Nimbuspost</Button>
                                            <Button onClick={() => { handleClickShipOpen(data) }}>ShipRocket</Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Dialog>

                            <Dialog open={shipopen} fullWidth={true} maxWidth={'sm'}
                                classes={{ paper: classes.dialogPaper }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                <DialogTitle>
                                    <Box display="flex" alignItems="center" style={{ fontSize: 20 + 'px', marginBottom: "5px", marginTop: "-5px" }} >
                                        <HelpOutlineOutlinedIcon style={{ fontSize: 30 + 'px', paddingRight: "5px", color: "#3f51b5" }} />
                                        <span>Alert</span>
                                        <IconButton style={{ marginTop: "-5px" }} onClick={handleShipClose} className={classes.closeButton}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </DialogTitle>
                                <Divider style={{ marginTop: "-15px" }} />
                                <DialogContent>
                                    {(() => {
                                        return (
                                            <Formik
                                                initialValues={{
                                                    Item_Master_Id: data?.Item_Master_Id,
                                                    Order_Id: data?.Order_Id
                                                }}

                                                onSubmit={values => {
                                                    dispatch(updateOrderShipAction(values, state.ID, navigate));
                                                }}
                                            >
                                                {({ handleSubmit, values }) => (
                                                    <>
                                                        <DialogContent>
                                                            <DialogContentText style={{ color: 'black', fontSize: 20 }} >
                                                                Are you sure to create Shiprocket Shipment ?
                                                            </DialogContentText>
                                                            <DialogContentText style={{ color: 'black' }} >
                                                                Order ID: {data?.Order_Id}
                                                            </DialogContentText>
                                                            <DialogContentText style={{ color: 'black' }} >
                                                                Item Master ID: {data?.Item_Master_Id}
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Button onClick={handleShipClose} variant="contained" className={classes.cancelButton}>
                                                                CANCEL
                                                            </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            {isShipSubmitted ?
                                                                <CircularProgress />
                                                                :
                                                                <Button onClick={handleSubmit} variant="contained" className={classes.acceptButton} disabled={isShipSubmitted}>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;CREATE SHIPMENT&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </Button>
                                                            }
                                                        </DialogActions>
                                                    </>
                                                )}
                                            </Formik>
                                        )
                                    })()}
                                </DialogContent>
                            </Dialog>

                            <Dialog open={cancelshipopen} fullWidth={true} maxWidth={'sm'}
                                classes={{ paper: classes.dialogPaper }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                <DialogTitle>
                                    <Box display="flex" alignItems="center" style={{ fontSize: 20 + 'px', marginBottom: "5px", marginTop: "-5px" }} >
                                        <HelpOutlineOutlinedIcon style={{ fontSize: 30 + 'px', paddingRight: "5px", color: "#3f51b5" }} />
                                        <span>Alert</span>
                                        <IconButton style={{ marginTop: "-5px" }} onClick={handleShipCancelClose} className={classes.closeButton}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </DialogTitle>
                                <Divider style={{ marginTop: "-15px" }} />
                                <DialogContent>
                                    {(() => {
                                        return (
                                            <Formik
                                                initialValues={{
                                                    Order_Id: data?.Shipping_Reference_Id
                                                }}

                                                onSubmit={values => {
                                                    dispatch(OrderCancelShipAction(values, state.ID, navigate));
                                                }}
                                            >
                                                {({ handleSubmit, values }) => (
                                                    <>
                                                        <DialogContent>
                                                            <DialogContentText style={{ color: 'black', fontSize: 20 }} >
                                                                Are you sure to cancel Shiprocket Shipment ?
                                                            </DialogContentText>
                                                            <DialogContentText style={{ color: 'black' }} >
                                                                Shipment Order ID: {data?.Shipping_Reference_Id}
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Button onClick={handleShipCancelClose} variant="contained" className={classes.cancelButton}>
                                                                CANCEL
                                                            </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            {isShipCancelled ?
                                                                <CircularProgress />
                                                                :
                                                                <Button onClick={handleSubmit} variant="contained" className={classes.acceptButton} disabled={isShipCancelled}>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;CANCEL SHIPMENT&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </Button>
                                                            }
                                                        </DialogActions>
                                                    </>
                                                )}
                                            </Formik>
                                        )
                                    })()}
                                </DialogContent>
                            </Dialog>

                            <Dialog open={shiporderopen} fullWidth={true} maxWidth={'sm'}
                                classes={{ paper: classes.dialogPaper }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                <DialogTitle>
                                    <Box display="flex" alignItems="center" style={{ fontSize: 20 + 'px', marginBottom: "5px", marginTop: "-5px" }} >
                                        <HelpOutlineOutlinedIcon style={{ fontSize: 30 + 'px', paddingRight: "5px", color: "#3f51b5" }} />
                                        <span>Alert</span>
                                        <IconButton style={{ marginTop: "-5px" }} onClick={handleShipOrderClose} className={classes.closeButton}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </DialogTitle>
                                <Divider style={{ marginTop: "-15px" }} />
                                <DialogContent>
                                    {(() => {
                                        return (
                                            <Formik
                                                initialValues={{
                                                    Item_Master_Id: data?.Item_Master_Id,
                                                    Order_Id: data?.Order_Id
                                                }}

                                                onSubmit={values => {
                                                    dispatch(createOrderShipAction(values, state.ID, navigate));
                                                }}
                                            >
                                                {({ handleSubmit, values }) => (
                                                    <>
                                                        <DialogContent>
                                                            <DialogContentText style={{ color: 'black', fontSize: 20 }} >
                                                                Are you sure to create Nimbuspost Shipment ?
                                                            </DialogContentText>
                                                            <DialogContentText style={{ color: 'black' }} >
                                                                Order ID: {data?.Order_Id}
                                                            </DialogContentText>
                                                            <DialogContentText style={{ color: 'black' }} >
                                                                Item Master ID: {data?.Item_Master_Id}
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Button onClick={handleShipOrderClose} variant="contained" className={classes.cancelButton}>
                                                                CANCEL
                                                            </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            {isOrderShipSubmitted ?
                                                                <CircularProgress />
                                                                :
                                                                <Button onClick={handleSubmit} variant="contained" className={classes.acceptButton} disabled={isOrderShipSubmitted}>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;CREATE SHIPMENT&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </Button>
                                                            }
                                                        </DialogActions>
                                                    </>
                                                )}
                                            </Formik>
                                        )
                                    })()}
                                </DialogContent>
                            </Dialog>

                            <Dialog open={cancelorderopen} fullWidth={true} maxWidth={'sm'}
                                classes={{ paper: classes.dialogPaper }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                <DialogTitle>
                                    <Box display="flex" alignItems="center" style={{ fontSize: 20 + 'px', marginBottom: "5px", marginTop: "-5px" }} >
                                        <HelpOutlineOutlinedIcon style={{ fontSize: 30 + 'px', paddingRight: "5px", color: "#3f51b5" }} />
                                        <span>Alert</span>
                                        <IconButton style={{ marginTop: "-5px" }} onClick={handleShipCancelOrderClose} className={classes.closeButton}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </DialogTitle>
                                <Divider style={{ marginTop: "-15px" }} />
                                <DialogContent>
                                    {(() => {
                                        return (
                                            <Formik
                                                initialValues={{
                                                    awb: data?.Shipping_Reference_Id
                                                }}

                                                onSubmit={values => {
                                                    dispatch(cancelOrderShipAction(values, state.ID, navigate));
                                                }}
                                            >
                                                {({ handleSubmit, values }) => (
                                                    <>
                                                        <DialogContent>
                                                            <DialogContentText style={{ color: 'black', fontSize: 20 }} >
                                                                Are you sure to cancel Nimbuspost Shipment ?
                                                            </DialogContentText>
                                                            <DialogContentText style={{ color: 'black' }} >
                                                                Shipment awb ID: {data?.Shipping_Reference_Id}
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Button onClick={handleShipCancelOrderClose} variant="contained" className={classes.cancelButton}>
                                                                CANCEL
                                                            </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            {isOrderShipCancelled ?
                                                                <CircularProgress />
                                                                :
                                                                <Button onClick={handleSubmit} variant="contained" className={classes.acceptButton} disabled={isOrderShipCancelled}>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;CANCEL SHIPMENT&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </Button>
                                                            }
                                                        </DialogActions>
                                                    </>
                                                )}
                                            </Formik>
                                        )
                                    })()}
                                </DialogContent>
                            </Dialog>

                        </div>
                        : null
                    }
                    <Divider />
                    <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                        <Grid item xs={6}>
                            <div style={{ fontWeight: 'bold', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Line Item Details:</div>
                            <div style={{ marginLeft: '1rem' }}>Order ID: {data?.Order_Id}</div>
                            <div style={{ marginLeft: '1rem' }}>Item Master ID: {data?.Item_Master_Id}</div>
                            <div style={{ marginLeft: '1rem' }}>Item Master Status: {data?.Line_Item_Status}</div>
                            <div style={{ fontWeight: 'bold', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Shipping Address:</div>
                            <div style={{ marginLeft: '1rem' }}>{data?.Shipping_Address_Id}</div>
                            <div style={{ marginLeft: '1rem' }}>{data?.Email_Id}</div>
                            <div style={{ marginLeft: '1rem' }}>{data?.Address_Type}</div>
                            <div style={{ marginLeft: '1rem' }}>{data?.Land_Mark}</div>
                            <div style={{ marginLeft: '1rem' }}>{data?.City}</div>
                            <div style={{ marginLeft: '1rem' }}>{data?.State}</div>
                            <div style={{ marginLeft: '1rem' }}>{data?.Country}</div>
                            <div style={{ marginLeft: '1rem' }}>{data?.Zip}</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ fontWeight: 'bold', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Product Details:</div>
                            <div style={{ marginLeft: '1rem' }}>
                                <img
                                    className={classes.image}
                                    alt="Profile"
                                    src={data?.Product_Image}
                                    variant="square"
                                />
                            </div>
                            <div style={{ marginLeft: '1rem' }}>Product Name: {allOrderDetails.Product_Name}</div>
                            <div style={{ marginLeft: '1rem' }}>Quantity: {data.Quantity}</div>
                            <div style={{ marginLeft: '1rem' }}>Product Size: {data.Product_Size}</div>
                            <div style={{ marginLeft: '1rem' }}>Selling Price: {data.Selling_Price}</div>
                            <div style={{ marginLeft: '1rem' }}>MRP: {data.MRP}</div>
                            <div style={{ marginLeft: '1rem' }}>Max Days To Return: {data.Max_Days_To_Return}</div>
                            <div style={{ fontWeight: 'bold', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Payment Details:</div>
                            <div style={{ marginLeft: '1rem' }}>Partner Total Price: {data.Partner_Total_Price}</div>
                            <div style={{ marginLeft: '1rem' }}>Partner ID: {data.Partner_Details_Id}</div>
                            <div style={{ marginLeft: '1rem' }}>Net Price: {data.Net_Price}</div>
                            <div style={{ marginLeft: '1rem' }}>User Billable Amount: {data.User_Billable_Amount}</div>
                            <div style={{ marginLeft: '1rem' }}>Meepaisa Billable Amount: {data.Meepaisa_Billable_Amount}</div>
                            <div style={{ marginLeft: '1rem' }}>User Total Tax Amount: {data.User_Total_Tax_Amount}</div>
                            <div style={{ marginLeft: '1rem' }}>Meepaisa Total Tax Amount: {data.Meepaisa_Total_Tax_Amount}</div>
                            {data.Order_Type === "RETAIL" || data.Order_Type === "MFH" ?
                                <>
                                    <div style={{ marginLeft: '1rem' }}>Shipping Fee: {data.Shipping_Fee}</div>
                                    <div style={{ marginLeft: '1rem' }}>Delivery Person Fee: {data.Delivery_Person_Fee}</div>
                                    <div style={{ marginLeft: '1rem' }}>Packaging Charges: {data.Packaging_Charges}</div>
                                </>
                                : null
                            }
                        </Grid>
                    </Grid>

                    <CardContent>
                        <Formik
                            initialValues={{
                                Order_Update: {
                                    Billing_Address: {
                                        Billing_Address_Id: orderdata[0]?.Billing_Address_Id ? orderdata[0]?.Billing_Address_Id : "",
                                        Billing_Address_Update_Flag: orderdata[0]?.Billing_Address_Update_Flag ? orderdata[0]?.Billing_Address_Update_Flag : "",
                                    },
                                    Cancel_Return_User_Comment: orderdata[0]?.Cancel_Return_User_Comment ? orderdata[0].Cancel_Return_User_Comment : "",
                                    Cancel_Return_User_Reason_Type: orderdata[0]?.Cancel_Return_User_Reason_Type ? orderdata[0].Cancel_Return_User_Reason_Type : "",
                                    Corporate: {
                                        Corporate_Discont_Coupon: orderdata[0]?.Corporate_Discont_Coupon ? orderdata[0]?.Corporate_Discont_Coupon : "",
                                        Corporate_Discount_Amount: orderdata[0]?.Corporate_Discount_Amount ? orderdata[0]?.Corporate_Discount_Amount : "",
                                        Corporate_Update_Flag: orderdata[0]?.Corporate_Update_Flag ? orderdata[0]?.Corporate_Update_Flag : "",
                                    },
                                    Gift_Voucher: {
                                        Gift_ID: orderdata[0]?.Gift_ID ? orderdata[0]?.Gift_ID : "",
                                        Gift_ID_Amount: orderdata[0]?.Gift_ID_Amount ? orderdata[0]?.Gift_ID_Amount : "",
                                        Gift_Voucher_Update_Flag: orderdata[0]?.Gift_Voucher_Update_Flag ? orderdata[0]?.Gift_Voucher_Update_Flag : "",
                                    },
                                    Is_Approved: orderdata[0]?.Is_Approved ? orderdata[0].Is_Approved : "",
                                    Item_Data: {
                                        Item_Master_Data: [
                                            {
                                                Brand_Id: data?.Brand_Id ? data?.Brand_Id : "",
                                                Is_Approved: data?.Is_Approved ? data?.Is_Approved : "",
                                                Is_Item_Return_Flag: data?.Is_Item_Return_Flag ? data?.Is_Item_Return_Flag : "",
                                                Item_Financial_Summary: {
                                                    Item_Master_Financial_Update_Flag: data?.Item_Master_Financial_Update_Flag ? data?.Item_Master_Financial_Update_Flag : "",
                                                    Meepaisa_Billable_Amount: data?.Meepaisa_Billable_Amount ? data?.Meepaisa_Billable_Amount : "",
                                                    Meepaisa_Gift_Wrap_Amount: data?.Meepaisa_Gift_Wrap_Amount ? data?.Meepaisa_Gift_Wrap_Amount : "",
                                                    Meepaisa_Packaging_Charges: data?.Meepaisa_Packaging_Charges ? data?.Meepaisa_Packaging_Charges : "",
                                                    Meepaisa_Shipping_Fee: data?.Meepaisa_Shipping_Fee ? data?.Meepaisa_Shipping_Fee : "",
                                                    Meepaisa_Total_Tax_Amount: data?.Meepaisa_Total_Tax_Amount ? data?.Meepaisa_Total_Tax_Amount : "",
                                                    Net_Price: data?.Net_Price ? data?.Net_Price : "",
                                                    Partner_Total_Price: data?.Partner_Total_Price ? data?.Partner_Total_Price : "",
                                                    User_Billable_Amount: data?.User_Billable_Amount ? data?.User_Billable_Amount : "",
                                                    User_Total_Tax_Amount: data?.User_Total_Tax_Amount ? data?.User_Total_Tax_Amount : "",
                                                },
                                                Item_Master_Id: data?.Item_Master_Id ? data?.Item_Master_Id : "",
                                                Item_Master_Update_Flag: "YES",
                                                Line_Item_Status: data?.Line_Item_Status ? data?.Line_Item_Status : "",
                                                MRP: data?.MRP ? data?.MRP : "",
                                                Max_Days_To_Return: data?.Max_Days_To_Return ? data?.Max_Days_To_Return : "",
                                                Order_Date: data?.Order_Date ? data?.Order_Date : "",
                                                Partner_Details_Id: data?.Partner_Details_Id ? data?.Partner_Details_Id : "",
                                                Payment_Status: data?.Payment_Status ? data?.Payment_Status : "",
                                                Product_Id: data?.Product_Id ? data?.Product_Id : "",
                                                Quantity: data?.Quantity ? data?.Quantity : "",
                                                Selling_Price: data?.Selling_Price ? data?.Selling_Price : "",
                                                Shipping_Address: {
                                                    // Item_Master_Shipping_Address_Update_Flag: "YES",
                                                    Item_Master_Shipping_Address_Update_Flag: data?.Item_Master_Shipping_Address_Update_Flag ? data?.Item_Master_Shipping_Address_Update_Flag : "",
                                                    Shipping_Address_Id: data?.Shipping_Address_Id ? data?.Shipping_Address_Id : "",
                                                },
                                                Shipping_Summary: {
                                                    Expected_Delivery_Date_Time: data?.Expected_Delivery_Date_Time ? data?.Expected_Delivery_Date_Time : "",
                                                    Expected_Delivery_Time_In_Minutes: data?.Expected_Delivery_Time_In_Minutes ? data?.Expected_Delivery_Time_In_Minutes : "",
                                                    Item_Master_Shipping_Update_Flag: "YES",
                                                    Live_Delivered_By_Email: data?.Live_Delivered_By_Email ? data?.Live_Delivered_By_Email : "",
                                                    Live_Delivered_By_Name: data?.Live_Delivered_By_Name ? data?.Live_Delivered_By_Name : "",
                                                    Live_Delivery_Person_Phone: data?.Live_Delivery_Person_Phone ? data?.Live_Delivery_Person_Phone : "",
                                                    Order_Delivery_Transactions: {
                                                        Del_Order_Id: data?.Del_Order_Id ? data?.Del_Order_Id : "",
                                                        Order_Delivery_Transaction_Update_Flag: "YES"
                                                    },
                                                    Ordered_Date_Time: data?.Ordered_Date_Time ? data?.Ordered_Date_Time : "",
                                                    Shipping_Channel: data?.Shipping_Channel ? data?.Shipping_Channel : "",
                                                    Partner_Address_ID: data?.Partner_Address_ID ? data?.Partner_Address_ID : "",
                                                    Shipping_Fee: data?.Shipping_Fee ? data?.Shipping_Fee : "",
                                                    Shipping_ID: data?.Shipping_ID ? data?.Shipping_ID : "",
                                                    Shipping_Tax: data?.Shipping_Tax ? data?.Shipping_Tax : "",
                                                    Shipping_Total: data?.Shipping_Total ? data?.Shipping_Total : "",
                                                    Shipping_Type: data?.Shipping_Type ? data?.Shipping_Type : "",
                                                    Thirdparty_Channel_Partner_Name: data?.Thirdparty_Channel_Partner_Name ? data?.Thirdparty_Channel_Partner_Name : "",
                                                    Thirdparty_Delivery_Person_Name: data?.Thirdparty_Delivery_Person_Name ? data?.Thirdparty_Delivery_Person_Name : "",
                                                    Thirdparty_Delivery_Phone: data?.Thirdparty_Delivery_Phone ? data?.Thirdparty_Delivery_Phone : "",
                                                    Thirdparty_Shipping_Method: data?.Thirdparty_Shipping_Method ? data?.Thirdparty_Shipping_Method : "",
                                                    Thirdparty_Shipping_Tracking_Number: data?.Thirdparty_Shipping_Tracking_Number ? data?.Thirdparty_Shipping_Tracking_Number : "",
                                                    Thirdparty_Tracking_Verification_URL: data?.Thirdparty_Tracking_Verification_URL ? data?.Thirdparty_Tracking_Verification_URL : "",
                                                    Delivery_Message: data?.Delivery_Message ? data?.Delivery_Message : "",
                                                }
                                            }
                                        ]
                                    },
                                    Meepaisa_Coupon: {
                                        Meepaisa_Coupon_Amount: orderdata[0]?.Meepaisa_Coupon_Amount ? orderdata[0]?.Meepaisa_Coupon_Amount : "",
                                        Meepaisa_Coupon_ID: orderdata[0]?.Meepaisa_Coupon_ID ? orderdata[0]?.Meepaisa_Coupon_ID : "",
                                        Meepaisa_Coupon_Update_Flag: orderdata[0]?.Meepaisa_Coupon_Update_Flag ? orderdata[0]?.Meepaisa_Coupon_Update_Flag : ""
                                    },
                                    Order_Financial_Summary: {
                                        Cashback_To_User: orderdata[0]?.Cashback_To_User ? orderdata[0]?.Cashback_To_User : "",
                                        Delivery_Person_Tip: orderdata[0]?.Delivery_Person_Tip ? orderdata[0]?.Delivery_Person_Tip : "",
                                        Financial_Update_Flag: orderdata[0]?.Financial_Update_Flag ? orderdata[0]?.Financial_Update_Flag : "",
                                        Meepaisa_Gift_Wrap_Amount: orderdata[0]?.Meepaisa_Gift_Wrap_Amount ? orderdata[0]?.Meepaisa_Gift_Wrap_Amount : "",
                                        Meepaisa_Packaging_Charges: orderdata[0]?.Meepaisa_Packaging_Charges ? orderdata[0]?.Meepaisa_Packaging_Charges : "",
                                        Meepaisa_Shipping_Fee: orderdata[0]?.Meepaisa_Shipping_Fee ? orderdata[0]?.Meepaisa_Shipping_Fee : "",
                                        Meepaisa_Total_Tax_Amount: orderdata[0]?.Meepaisa_Total_Tax_Amount ? orderdata[0]?.Meepaisa_Total_Tax_Amount : "",
                                        Meepiasa_Billable_Amount_After_Taxes: orderdata[0]?.Meepiasa_Billable_Amount_After_Taxes ? orderdata[0]?.Meepiasa_Billable_Amount_After_Taxes : "",
                                        Meepiasa_Billable_Amount_Before_Taxes: orderdata[0]?.Meepiasa_Billable_Amount_Before_Taxes ? orderdata[0]?.Meepiasa_Billable_Amount_Before_Taxes : "",
                                        Total_Order_Amount: orderdata[0]?.Total_Order_Amount ? orderdata[0]?.Total_Order_Amount : "",
                                        User_Billable_Amount_After_Taxes: orderdata[0]?.User_Billable_Amount_After_Taxes ? orderdata[0]?.User_Billable_Amount_After_Taxes : "",
                                        User_Billable_Amount_Before_Taxes: orderdata[0]?.User_Billable_Amount_Before_Taxes ? orderdata[0]?.User_Billable_Amount_Before_Taxes : "",
                                        User_Shipping_Fee: orderdata[0]?.User_Shipping_Fee ? orderdata[0]?.User_Shipping_Fee : "",
                                        User_Total_Tax_Amount: orderdata[0]?.User_Total_Tax_Amount ? orderdata[0]?.User_Total_Tax_Amount : "",
                                    },
                                    Order_Id: orderdata[0]?.Order_Id ? orderdata[0]?.Order_Id : "",
                                    Order_Type: orderdata[0]?.Order_Type ? orderdata[0]?.Order_Type : "",
                                    Order_Status: orderdata[0]?.Order_Status ? orderdata[0]?.Order_Status : "",
                                    Order_Transactions: {
                                        Order_Transaction_Update_Flag: orderdata[0]?.Order_Transaction_Update_Flag ? orderdata[0]?.Order_Transaction_Update_Flag : "",
                                        Payment_Date: orderdata[0]?.Payment_Date ? orderdata[0]?.Payment_Date : "",
                                        Payment_Method: orderdata[0]?.Payment_Method ? orderdata[0]?.Payment_Method : "",
                                        Payment_Reference_Id: orderdata[0]?.Payment_Reference_Id ? orderdata[0]?.Payment_Reference_Id : "",
                                        Payment_Status: orderdata[0]?.Payment_Status ? orderdata[0]?.Payment_Status : "",
                                        Transaction_Id: orderdata[0]?.Transaction_Id ? orderdata[0]?.Transaction_Id : "",
                                        User_Billable_Amount: orderdata[0]?.User_Billable_Amount ? orderdata[0]?.User_Billable_Amount : ""
                                    },
                                    Order_Update_Flag: "YES",
                                    Order_Update_Type: orderdata[0]?.Order_Update_Type ? orderdata[0]?.Order_Update_Type : "",
                                    User_Email_Id: orderdata[0]?.User_Email_Id ? orderdata[0]?.User_Email_Id : "",
                                    Wallet: {
                                        Cashback_Amount_To_User_Wallet: orderdata[0]?.Cashback_Amount_To_User_Wallet ? orderdata[0]?.Cashback_Amount_To_User_Wallet : "",
                                        Wallet_Amount: orderdata[0]?.Wallet_Amount ? orderdata[0]?.Wallet_Amount : "",
                                        Wallet_Id: orderdata[0]?.Wallet_Id ? orderdata[0]?.Wallet_Id : "",
                                        Wallet_Update_Flag: orderdata[0]?.Wallet_Update_Flag ? orderdata[0]?.Wallet_Update_Flag : "",
                                    }
                                }
                            }}
                            onSubmit={(values, { setErrors, setSubmitting }) => {
                                let submitTest = true;
                                setSubmitting(false);
                                const errors = {};

                                setErrors(errors);
                                if (submitTest) {
                                    let formValues = JSON.parse(JSON.stringify(values));
                                    dispatch(updateOrderStatusAction(formValues, state.ID, navigate, setErrorMessage, setErrors));
                                }
                            }
                            }
                        >
                            {({
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                values,
                                setFieldValue,
                            }) => (
                                <>
                                    <ConnectedFocusError />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item md={8} xs={12}>
                                                <Grid container spacing={3}>
                                                    {/* Shipping_Channel input field with value */}
                                                    <Grid item md={6} xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            label="Shipping Channel"
                                                            name={`Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Shipping_Channel`}
                                                            select
                                                            InputLabelProps={{ shrink: true }}
                                                            SelectProps={{ native: true }}
                                                            value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Shipping_Channel}
                                                            variant="outlined"
                                                        >

                                                            {ShippingchannelpartnerOption?.map(option => (
                                                                <option key={option.value} value={option.value} >
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>

                                                    {/* Shipping_Type input field with value */}
                                                    <Grid item md={6} xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            label="Shipping Type"
                                                            name="Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Shipping_Type"
                                                            select
                                                            InputLabelProps={{ shrink: true }}
                                                            SelectProps={{ native: true }}
                                                            value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Shipping_Type}
                                                            variant="outlined"
                                                        >
                                                            {ShippingTypeOption?.map(option => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>

                                                    {/* Line_Item_Status input field with value */}
                                                    <Grid item md={6} xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            label="Shipping Status"
                                                            name="Order_Update.Item_Data.Item_Master_Data[0].Line_Item_Status"
                                                            select
                                                            InputLabelProps={{ shrink: true }}
                                                            SelectProps={{ native: true }}
                                                            value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Line_Item_Status}
                                                            variant="outlined"
                                                        >
                                                            {alllineitemstatus?.map(option => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>

                                                    {/* Partner address input field with value */}
                                                    <Grid item md={6} xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            label="Partner Address"
                                                            name="Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Partner_Address_ID"
                                                            select
                                                            InputLabelProps={{ shrink: true }}
                                                            SelectProps={{ native: true }}
                                                            value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Partner_Address_ID}
                                                            variant="outlined"
                                                        >
                                                            <option key="" value="">--Please Select--</option>
                                                            {partneraddress?.sort((a, b) => a.Address_Type?.localeCompare(b.Address_Type))?.map(option => (
                                                                <option key={option.Partner_Address_Id} value={option.Partner_Address_Id}>
                                                                    {option.Address_Type + ", " + option.Country + ", " + option.State + ", " + option.City + ", " + option.Land_Mark + ", " + option.Street + ", " + option.Zip}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>

                                                    {/* Delivery_Message input field with value */}
                                                    <Grid item md={6} xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            label="Delivery Delay Message"
                                                            name="Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Delivery_Message"
                                                            select
                                                            InputLabelProps={{ shrink: true }}
                                                            SelectProps={{ native: true }}
                                                            value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Delivery_Message}
                                                            variant="outlined"
                                                        >
                                                            {deliverymessage?.map(option => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>

                                                    {values?.Order_Update?.Item_Data.Item_Master_Data[0]?.Line_Item_Status === "CANCELLED" ?
                                                        <>
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Cancel Return User Reason Type"
                                                                    name={`Order_Update.Cancel_Return_User_Reason_Type`}
                                                                    select
                                                                    InputLabelProps={{ shrink: true }}
                                                                    SelectProps={{ native: true }}
                                                                    value={values?.Order_Update?.Cancel_Return_User_Reason_Type}
                                                                    variant="outlined"
                                                                >
                                                                    {cancelreasontype?.map(option => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Cancel Return User Comment"
                                                                    name={`Order_Update.Cancel_Return_User_Comment`}
                                                                    value={values?.Order_Update?.Cancel_Return_User_Comment}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            </Grid>
                                                        </>
                                                        : null
                                                    }

                                                    {values?.Order_Update?.Item_Data.Item_Master_Data[0]?.Shipping_Summary?.Shipping_Channel === 'Thirdparty' ? (
                                                        <>
                                                            {/* Thirdparty_Channel_Partner_Name input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Thirdparty Channel Partner Name"
                                                                    name="Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Thirdparty_Channel_Partner_Name"
                                                                    value={values?.Order_Update?.Item_Data.Item_Master_Data[0]?.Shipping_Summary?.Thirdparty_Channel_Partner_Name}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Thirdparty_Shipping_Method input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Thirdparty Shipping Method"
                                                                    name="Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Thirdparty_Shipping_Method"
                                                                    value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Thirdparty_Shipping_Method}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Thirdparty_Shipping_Tracking_Number input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Thirdparty Shipping Tracking Number"
                                                                    name="Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Thirdparty_Shipping_Tracking_Number"
                                                                    value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Thirdparty_Shipping_Tracking_Number}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Thirdparty_Tracking_Verification_URL input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Thirdparty Tracking Verification URL"
                                                                    name="Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Thirdparty_Tracking_Verification_URL"
                                                                    value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Thirdparty_Tracking_Verification_URL}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Thirdparty_Delivery_Person_Name input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Thirdparty Delivery Person Name"
                                                                    name="Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Thirdparty_Delivery_Person_Name"
                                                                    value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Thirdparty_Delivery_Person_Name}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Thirdparty_Delivery_Phone input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Thirdparty Delivery Phone"
                                                                    name="Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Thirdparty_Delivery_Phone"
                                                                    value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Thirdparty_Delivery_Phone}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Live_Delivered_By_Email input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Delivered By Email"
                                                                    name="Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivered_By_Email"
                                                                    value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Live_Delivered_By_Email}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Live_Delivered_By_Name input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Delivered By Name"
                                                                    name="Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivered_By_Name"
                                                                    value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Live_Delivered_By_Name}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Live_Delivery_Person_Phone input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Delivered Person Phone"
                                                                    name="Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivery_Person_Phone"
                                                                    value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Live_Delivery_Person_Phone}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>

                                                        </>
                                                    ) : values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Shipping_Channel === 'ESI' ? (
                                                        <>
                                                            {/* Live_Delivered_By_Email input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <Autocomplete
                                                                    options={esidelipersons}
                                                                    groupBy={(option) => option?.firstLetter}
                                                                    id="combo-box-demo"
                                                                    getOptionLabel={(option) => option?.Del_User_Email_Id}
                                                                    onChange={(e, value) => {
                                                                        setFieldValue("Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivered_By_Email", value?.Del_User_Email_Id ? value?.Del_User_Email_Id : "");
                                                                        setFieldValue("Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivered_By_Name", value?.First_Name ? value?.First_Name : "");
                                                                        setFieldValue("Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivery_Person_Phone", value?.Mobile ? value?.Mobile : "");
                                                                    }}
                                                                    value={esidelipersons?.length > 0 ? esidelipersons?.filter((item) => item?.Del_User_Email_Id === values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Live_Delivered_By_Email)[0] : ""}
                                                                    renderOption={(option) => (
                                                                        <React.Fragment>
                                                                            <ListItemAvatar>
                                                                                <img
                                                                                    alt={option.type}
                                                                                    className={classes.image}
                                                                                    style={{
                                                                                        height: 48,
                                                                                        width: 48
                                                                                    }}
                                                                                    src={option?.Profile_Pic_Path}
                                                                                />
                                                                            </ListItemAvatar>
                                                                            <ListItemText
                                                                                secondary={option?.Del_User_Email_Id}
                                                                                primary={option?.First_Name + " " + option?.Last_Name}
                                                                            />
                                                                        </React.Fragment>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            placeholder="Search"
                                                                            variant="outlined"
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                autoComplete: 'off', // disable autocomplete and autofill,
                                                                                startAdornment: (
                                                                                    <React.Fragment>
                                                                                        <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                                        {params.InputProps.startAdornment}
                                                                                    </React.Fragment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    )}
                                                                />

                                                            </Grid>
                                                            {/* Live_Delivered_By_Name input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Delivered By Name"
                                                                    name="Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivered_By_Name"
                                                                    value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Live_Delivered_By_Name}
                                                                    variant="outlined"
                                                                    disabled
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Live_Delivery_Person_Phone input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Delivered Person Phone"
                                                                    name="Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivery_Person_Phone"
                                                                    value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Live_Delivery_Person_Phone}
                                                                    variant="outlined"
                                                                    disabled
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>

                                                        </>
                                                    ) : (
                                                        <>
                                                            {/* Live_Delivered_By_Email input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <Autocomplete
                                                                    options={partnerdelipersons}
                                                                    groupBy={(option) => option?.firstLetter}
                                                                    id="combo-box-demo"
                                                                    getOptionLabel={(option) => option?.Del_User_Email_Id}
                                                                    onChange={(e, value) => {
                                                                        setFieldValue("Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivered_By_Email", value.Del_User_Email_Id ? value.Del_User_Email_Id : "");
                                                                        setFieldValue("Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivered_By_Name", value.First_Name ? value.First_Name : "");
                                                                        setFieldValue("Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivery_Person_Phone", value.Mobile ? value.Mobile : "");
                                                                    }}
                                                                    value={partnerdelipersons?.length > 0 ? partnerdelipersons?.filter((item) => item?.Del_User_Email_Id === values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Live_Delivered_By_Email)[0] : ""}
                                                                    renderOption={(option) => (
                                                                        <React.Fragment>
                                                                            <ListItemAvatar>
                                                                                <img
                                                                                    alt={option.type}
                                                                                    className={classes.image}
                                                                                    style={{
                                                                                        height: 48,
                                                                                        width: 48
                                                                                    }}
                                                                                    src={option?.Profile_Pic_Path}
                                                                                />
                                                                            </ListItemAvatar>
                                                                            <ListItemText
                                                                                secondary={option?.Del_User_Email_Id}
                                                                                primary={option?.First_Name + " " + option?.Last_Name}
                                                                            />
                                                                        </React.Fragment>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            placeholder="Search"
                                                                            variant="outlined"
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                autoComplete: 'off', // disable autocomplete and autofill,
                                                                                startAdornment: (
                                                                                    <React.Fragment>
                                                                                        <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                                        {params.InputProps.startAdornment}
                                                                                    </React.Fragment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    )}
                                                                />

                                                            </Grid>
                                                            {/* Live_Delivered_By_Name input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Delivered By Name"
                                                                    name="Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivered_By_Name"
                                                                    value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Live_Delivered_By_Name}
                                                                    variant="outlined"
                                                                    disabled
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Live_Delivery_Person_Phone input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Delivered Person Phone"
                                                                    name="Order_Update.Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivery_Person_Phone"
                                                                    value={values?.Order_Update?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary?.Live_Delivery_Person_Phone}
                                                                    variant="outlined"
                                                                    disabled
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <Box display="flex" pl={2} >
                                        <span style={{ color: "red" }}>{errorMessage.global.message}</span>
                                    </Box>

                                    {data.Order_Type === "RETAIL" || data.Order_Type === "MFH" ?
                                        <Box display="flex" p={2}> {isOrderUpdate ?
                                            <CircularProgress />
                                            :
                                            <Button
                                                style={{ backgroundColor: '#27B6CC' }}
                                                variant="contained"
                                                onClick={handleSubmit}
                                                disabled={isOrderUpdate}
                                            >
                                                <span style={{ color: 'white' }}>Save details</span>
                                            </Button>
                                        }
                                        </Box>
                                        : null
                                    }
                                </>
                            )}
                        </Formik>
                    </CardContent>
                    <Divider />
                </Card>
            ))
            }
        </>
    )
}

export default EditOrderStatusHistory;