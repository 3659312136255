
import { toast } from 'react-toastify';
import config from './config';
import {  nodeGetApi} from './commonAxios';


export const PARTNERS_REQUEST = "PARTNERS_REQUEST";
export const PARTNERS_SUCCESS = "PARTNERS_SUCCESS";
export const PARTNERS_FAIL = "PARTNERS_FAIL";

/*=============================================================
        Get All Online and Physical Partner Action
===============================================================*/


export const getAllOnlineAndPhysicalPartner = (formData) => async dispatch => {
  try {
    dispatch({
      type: PARTNERS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partners/OnlineAndPhysical_Details_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNERS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again.", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    dispatch({
      type: PARTNERS_FAIL,
      payload: err
    });
  }
};