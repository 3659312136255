import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  AddSettingAction
} from './../../store/actions/settingAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  root: {},
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddSetting = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState({ Setting_Field_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.setting.settingCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [rhDiscription, setRhDiscription] = useState('');
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <Formik
      initialValues={{
        Field_Name: '',
        Field_Value: '',
        Purpose: '',
        Created_By: loginEmail,
        Status: ''
      }}
      validationSchema={Yup.object().shape({
        Field_Name: Yup.string().notOneOf([errorMessage.Setting_Field_Name.key, null], errorMessage.Setting_Field_Name.message).required('Field Name is required.'),
        Field_Value: Yup.string().required('Field value is required.'),
        Status: Yup.string().required('Status is required.'),
      })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        const errors = {};
        setSubmitting(false);
        setErrors(errors);

        values.Purpose = rhDiscription;

        if (!values.Purpose || values.Purpose.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Purpose = "Purpose is required.";
        }
        else if (values.Purpose.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Purpose = "Please provide at least " + rhDiscriptionMin + " characters.";
        }

        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(AddSettingAction(formValues, navigate, setErrorMessage, setErrors));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    {/*  Field_Name */}
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Field_Name && errors.Field_Name)}
                        fullWidth
                        helperText={touched.Field_Name && errors.Field_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Field Name"
                        name="Field_Name"
                        value={values.Field_Name}
                        variant="outlined"
                      />

                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Field_Value && errors.Field_Value)}
                        fullWidth
                        helperText={touched.Field_Value && errors.Field_Value}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Field Value"
                        name="Field_Value"
                        value={values.Field_Value}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Purpose Description  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Purpose
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Purpose && errors.Purpose}
                      </span>
                    </Grid>
                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddSetting.propTypes = {
  className: PropTypes.string
};

export default AddSetting;