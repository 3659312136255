import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import SimpleDialog from 'src/components/dialogs/dialog';
import isEmpty from 'src/store/validations/is-empty';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Location_Id', numeric: false, disablePadding: false, label: 'Location ID' },
  { id: 'Shop_By_Location_In_City_Id', numeric: false, disablePadding: false, label: 'Shop By Location City ID' },
  { id: 'City_Name', numeric: false, disablePadding: false, label: 'City Name' },
  { id: 'Partner_Name', numeric: false, disablePadding: false, label: 'Partner Name' },
  { id: 'Is_Active', numeric: false, disablePadding: false, label: 'Is Active' },
];

const Results = ({
  className,
  allCitypartners,
  deleteCitypartnerHandler,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allCitypartnersNoFilter,
  state,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedCitypartnerIds, setSelectedCitypartnerIds] = useState([]);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedCitypartnerIds;
    if (event.target.checked) {
      newSelectedCitypartnerIds = allCitypartners.map(citypartner => citypartner.Shop_By_Location_In_City_Id);
    } else {
      newSelectedCitypartnerIds = [];
    }
    setSelectedCitypartnerIds(newSelectedCitypartnerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCitypartnerIds.indexOf(id);
    let newSelectedCitypartnerIds = [];
    if (selectedIndex === -1) {
      newSelectedCitypartnerIds = newSelectedCitypartnerIds.concat(selectedCitypartnerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCitypartnerIds = newSelectedCitypartnerIds.concat(
        selectedCitypartnerIds.slice(1)
      );
    } else if (selectedIndex === selectedCitypartnerIds.length - 1) {
      newSelectedCitypartnerIds = newSelectedCitypartnerIds.concat(
        selectedCitypartnerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCitypartnerIds = newSelectedCitypartnerIds.concat(
        selectedCitypartnerIds.slice(0, selectedIndex),
        selectedCitypartnerIds.slice(selectedIndex + 1)
      );
    }
    setSelectedCitypartnerIds(newSelectedCitypartnerIds);
  };

  const deleteSelectedCitypartnerHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const editCitypartnerHandler = citypartnerData => e => {
    navigate('/app/edit-city-partner-location', { state: { state: state, data: citypartnerData } });
  };
  const viewCitypartnerHandler = citypartnerData => e => {
    navigate('/app/view-city-partner-location', { state: { state: state, data: citypartnerData } });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCitypartnerIds.filter(record => {
                      for (let i = 0; i < allCitypartnersNoFilter.length; i++) {
                        if (record === allCitypartnersNoFilter[i].Shop_By_Location_In_City_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allCitypartners.length}
                    color="primary"
                    indeterminate={
                      selectedCitypartnerIds.filter(record => {
                        for (let i = 0; i < allCitypartnersNoFilter.length; i++) {
                          if (record === allCitypartnersNoFilter[i].Shop_By_Location_In_City_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedCitypartnerIds.filter(record => {
                        for (let i = 0; i < allCitypartnersNoFilter.length; i++) {
                          if (record === allCitypartnersNoFilter[i].Shop_By_Location_In_City_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allCitypartners.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>Actions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedCitypartnerIds.filter(record => {
                    for (let i = 0; i < allCitypartnersNoFilter.length; i++) {
                      if (record === allCitypartnersNoFilter[i].Shop_By_Location_In_City_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedCitypartnerIds.filter(record => {
                    for (let i = 0; i < allCitypartnersNoFilter.length; i++) {
                      if (record === allCitypartnersNoFilter[i].Shop_By_Location_In_City_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedCitypartnerHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allCitypartners) &&
                (allCitypartners).map(citypartner => (
                  <TableRow
                    hover
                    key={citypartner.Shop_By_Location_In_City_Id}
                    selected={
                      selectedCitypartnerIds.indexOf(citypartner.Shop_By_Location_In_City_Id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedCitypartnerIds.indexOf(citypartner.Shop_By_Location_In_City_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, citypartner.Shop_By_Location_In_City_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell>{citypartner.Location_Id}</TableCell>
                    <TableCell>{citypartner.Shop_By_Location_In_City_Id}</TableCell>
                    <TableCell >{citypartner.City_Name}</TableCell>
                    <TableCell >{citypartner.Partner_Name}</TableCell>
                    <TableCell >{citypartner.Is_Active}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Partner_Locations_Write?.Value === "Partner_Locations_Write" && RolePermissions?.Partner_Locations_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Button
                          onClick={editCitypartnerHandler(citypartner)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Tooltip title="city partner location Details">
                          <Button
                            onClick={viewCitypartnerHandler(citypartner)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        <Button>
                          <DeleteIcon
                            onClick={deleteCitypartnerHandler(citypartner)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Partner_Locations_Write?.Value === "Partner_Locations_Write" && RolePermissions?.Partner_Locations_Write?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="city partner location Details">
                          <Button
                            onClick={viewCitypartnerHandler(citypartner)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allCitypartners) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allCitypartners: PropTypes.array.isRequired
};

export default Results;