
import config from './config';
import { toast } from 'react-toastify';
import {  nodeGetApi,boomiAdminApi } from './commonAxios';

export const PART_BID_QUOTES_CREATE_REQUEST = "PART_BID_QUOTES_CREATE_REQUEST";
export const PART_BID_QUOTES_CREATE_SUCCESS = "PART_BID_QUOTES_CREATE_SUCCESS";
export const PART_BID_QUOTES_CREATE_FAIL = "PART_BID_QUOTES_CREATE_FAIL";

export const PART_BID_QUOTES_UPDATE_REQUEST = "PART_BID_QUOTES_UPDATE_REQUEST";
export const PART_BID_QUOTES_UPDATE_SUCCESS = "PART_BID_QUOTES_UPDATE_SUCCESS";
export const PART_BID_QUOTES_UPDATE_FAIL = "PART_BID_QUOTES_UPDATE_FAIL";

export const BID_QUOTE_PARTNERS_GET_REQUEST = "BID_QUOTE_PARTNERS_GET_REQUEST";
export const BID_QUOTE_PARTNERS_GET_SUCCESS = "BID_QUOTE_PARTNERS_GET_SUCCESS";
export const BID_QUOTE_PARTNERS_GET_FAIL = "BID_QUOTE_PARTNERS_GET_FAIL";


//create Partner Bidding Quotes Action
export const createPartnerBiddingQuotes = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: PART_BID_QUOTES_CREATE_REQUEST
    });
    boomiAdminApi.post(`/Partner_Bidding_Quotations/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: PART_BID_QUOTES_CREATE_SUCCESS
                });
                toast('Partner Bidding Quote created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/partner-bidding-quotes', state = { state });
            } else {
                dispatch({
                    type: PART_BID_QUOTES_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: PART_BID_QUOTES_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

//update Partner Bidding Quotes Action
export const updatePartnerBiddingQuotes = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: PART_BID_QUOTES_UPDATE_REQUEST
    });
    boomiAdminApi.put(`/Partner_Bidding_Quotations/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: PART_BID_QUOTES_UPDATE_SUCCESS
                });
                toast('Partner Bidding Quote updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/partner-bidding-quotes', state = { state });
            } else {

                dispatch({
                    type: PART_BID_QUOTES_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: PART_BID_QUOTES_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//get all Bidding Partners
export const getAllBiddingQuotePartners = formData => async dispatch => {
    try {
        dispatch({
            type: BID_QUOTE_PARTNERS_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(
            `/Super_Admin/Partner_Bidding_Available_Products?PageNo=1&PageSize=${config.pageSize}&SortBy=Partner_Bidding_Available_Products_Id&SortOrder=ASC`,
            formData
        );
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: BID_QUOTE_PARTNERS_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BID_QUOTE_PARTNERS_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BID_QUOTE_PARTNERS_GET_FAIL,
            payload: err
        });
    }
};

//get all Bidding Quote Partner location
export const getAllBidQuotePartLocation = (formData, callBackData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(
            `/Partners/Partner_Address_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Partner_Address_Id&SortOrder=ASC`,
            formData
        );
        if (data) {
            if (data.Success_Response.Is_Data_Exist === "0") {
                callBackData([])
            } else {
                callBackData(data.results)
            }
        }
    } catch (err) {
        dispatch({
            payload: err
        });
    }
};

//get all Bidding Quotes Partner Delivery persons
export const getAllBidQuotePartnerDelivery = (formData, callBackData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(
            `/Super_Admin/Delivery_Person_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=DEL_USER_EMAIL_ID&SortOrder=ASC`,
            formData
        );
        if (data) {
            if (data.Is_Data_Exist === "0") {
                callBackData([])
            } else {
                callBackData(data.results)
            }
        }
    } catch (err) {
        dispatch({
            payload: err
        });
    }
};

//Get ALL Bidding Products Quote
export const getBiddingProductsQuoteAction = (formData, callBackBiddingProductsQuote) => async dispatch => {
    try {
      let { data } = await nodeGetApi.post(
        `/Super_Admin/Partner_Bidding_Quotes`,
        formData
      );
      if (data) {
        callBackBiddingProductsQuote(data)
      }
    } catch (err) {
    }
  };