import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
    TableSortLabel,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../store/validations/is-empty';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import SimpleDialog from '../../components/dialogs/dialog';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    }
}));

const headCells = [
    { id: 'Partner_Employee_Id', numeric: false, disablePadding: false, label: 'Partner Employee ID' },
    { id: 'Email_Id', numeric: false, disablePadding: false, label: 'Email ID' },
    { id: 'User_Type', numeric: false, disablePadding: false, label: 'User Type' },
    { id: 'Is_Active', numeric: false, disablePadding: false, label: 'Is Active' },
];

const Results = ({
    className,
    allPartneremployees,
    deletePartnerEmployeeHandler,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    createSortHandler,
    allPartnerEmployeesNoFilter,
    state,
    RolePermissions,
    roleUserType,
    pagination,
    setPagination,
    ...rest
}) => {

    const classes = useStyles();
    const [selectedPartneremployeeIds, setSelectedPartneremployeeIds] = useState([]);
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const handleClose = (value) => {
        setOpen(false);
    };
    const navigate = useNavigate();

    const handleSelectAll = event => {
        let newSelectedPartneremployeeIds;
        if (event.target.checked) {
            newSelectedPartneremployeeIds = allPartneremployees.map(partnerdeliagent => partnerdeliagent.Email_Id);
        } else {
            newSelectedPartneremployeeIds = [];
        }
        setSelectedPartneremployeeIds(newSelectedPartneremployeeIds);
    };

    const handleSelectOne = (event, id) => {

        const selectedIndex = selectedPartneremployeeIds.indexOf(id);
        let newSelectedPartneremployeeIds = [];
        if (selectedIndex === -1) {
            newSelectedPartneremployeeIds = newSelectedPartneremployeeIds.concat(selectedPartneremployeeIds, id);
        } else if (selectedIndex === 0) {
            newSelectedPartneremployeeIds = newSelectedPartneremployeeIds.concat(
                selectedPartneremployeeIds.slice(1)
            );
        } else if (selectedIndex === selectedPartneremployeeIds.length - 1) {
            newSelectedPartneremployeeIds = newSelectedPartneremployeeIds.concat(
                selectedPartneremployeeIds.slice(0, -1)
            );
        } else if (selectedIndex > 0) {
            newSelectedPartneremployeeIds = newSelectedPartneremployeeIds.concat(
                selectedPartneremployeeIds.slice(0, selectedIndex),
                selectedPartneremployeeIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPartneremployeeIds(newSelectedPartneremployeeIds);
    };

    const deleteSelectedPartneremployeeHandler = () => async e => {
        setOpen(true);
        setTitle("Alert!");
        setMessage("This feature is under development.");
    };

    const visiblePartneremployee = partneremployeeData => e => {
        navigate("/app/view-partner-employee", { state: { state: state, data: partneremployeeData } });
    };

    const editPartneremployeeHandler = partneremployeeData => e => {
        navigate('/app/edit-partner-employee', { state: { state: state, data: partneremployeeData } });
    };


    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedPartneremployeeIds.filter(record => {
                                            for (let i = 0; i < allPartnerEmployeesNoFilter.length; i++) {
                                                if (record == allPartnerEmployeesNoFilter[i].Email_Id) {
                                                    return true;
                                                }
                                            }
                                            return false;
                                        }).length === allPartneremployees.length}
                                        color="primary"
                                        indeterminate={
                                            selectedPartneremployeeIds.filter(record => {
                                                for (let i = 0; i < allPartnerEmployeesNoFilter.length; i++) {
                                                    if (record == allPartnerEmployeesNoFilter[i].Email_Id) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            }).length > 0 &&
                                            selectedPartneremployeeIds.filter(record => {
                                                for (let i = 0; i < allPartnerEmployeesNoFilter.length; i++) {
                                                    if (record == allPartnerEmployeesNoFilter[i].Email_Id) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            }).length < allPartneremployees.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: "-50px" }} >&nbsp;&nbsp;&nbsp;Actions
                                    {selectedPartneremployeeIds.filter(record => {
                                        for (let i = 0; i < allPartnerEmployeesNoFilter.length; i++) {
                                            if (record == allPartnerEmployeesNoFilter[i].Email_Id) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }).length > 0 && <Button disabled={selectedPartneremployeeIds.filter(record => {
                                        for (let i = 0; i < allPartnerEmployeesNoFilter.length; i++) {
                                            if (record == allPartnerEmployeesNoFilter[i].Email_Id) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }).length <= 0} onClick={deleteSelectedPartneremployeeHandler()}>
                                            <DeleteSweepIcon
                                                style={{ color: '#ff7a7a', fontSize: 25 }}
                                            />
                                        </Button>}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allPartneremployees) &&
                                (allPartneremployees).map(partneremployee => (
                                    <TableRow
                                        hover
                                        key={partneremployee.Email_Id}
                                        selected={
                                            selectedPartneremployeeIds.indexOf(partneremployee.Email_Id) !== -1
                                        }
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={
                                                    selectedPartneremployeeIds.indexOf(partneremployee.Email_Id) !== -1
                                                }
                                                onChange={event =>
                                                    handleSelectOne(event, partneremployee.Email_Id)
                                                }
                                                value="true"
                                            />
                                        </TableCell>
                                        <TableCell>{partneremployee.Partner_Employee_Id}</TableCell>
                                        <TableCell>{partneremployee.Email_Id}</TableCell>
                                        <TableCell>{partneremployee.User_Type}</TableCell>
                                        <TableCell>{partneremployee.Is_Active}</TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Partner_Employees_Write?.Value === "Partner_Employees_Write" && RolePermissions?.Partner_Employees_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Button
                                                    onClick={editPartneremployeeHandler(partneremployee)}
                                                >
                                                    <CreateIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                                <Button>
                                                    <DeleteIcon
                                                        onClick={deletePartnerEmployeeHandler(partneremployee)}
                                                        style={{ color: '#9EA0A5' }}
                                                    />
                                                </Button>
                                                <Button>
                                                    <VisibilityIcon
                                                        onClick={visiblePartneremployee(partneremployee)}
                                                        style={{ color: '#9EA0A5' }}
                                                    />
                                                </Button>
                                            </TableCell>
                                            : null}
                                        {RolePermissions?.Partner_Employees_Read?.Value === "Partner_Employees_Read" && RolePermissions?.Partner_Employees_Read?.Is_Read === 1 &&
                                            <TableCell >
                                                <Button>
                                                    <VisibilityIcon
                                                        onClick={visiblePartneremployee(partneremployee)}
                                                        style={{ color: '#9EA0A5' }}
                                                    />
                                                </Button>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allPartneremployees) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <Pagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
            <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    allPartneremployees: PropTypes.array.isRequired
};

export default Results;