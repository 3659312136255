import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  Box,
  colors
} from '@material-ui/core';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useDispatch, useSelector } from 'react-redux';
import { deliDeliveredorderCount } from 'src/store/actions/delivery-reports/deliverydashboardAction';

//useStyles contains the styles
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  differenceIconFail: {
    color: colors.red[900]
  },
  differenceValueFail: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  },
  differenceIconSucc: {
    color: colors.green[900]
  },
  differenceValueSucc: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const DeliveredOrders = ({ className, RolePermissions, setSeconds, ...rest }) => {
  // classes contains the styles
  const classes = useStyles();
  // useDispatch hook is used to dispatch an action
  const dispatch = useDispatch();
  //data contains the data from API using state call
  const data = useSelector(state => state.deliverydashboard.DeliDeliveredOrders.data);
  let Delivery_Delivered_Orders_Hide = RolePermissions?.Delivery_Delivered_Orders_Hide?.Is_Hide

  // componentDidMount
  useEffect(() => {
    let formData = {
      "Records_Filter": "FILTER",
      "Status": "DELIVERED"
    }
    dispatch(deliDeliveredorderCount(formData));
    setSeconds(5);
    if (RolePermissions?.Delivery_Delivered_Orders_Hide?.Value === "Delivery_Delivered_Orders_Hide" && RolePermissions?.Delivery_Delivered_Orders_Hide?.Is_Hide === 1) {
      Delivery_Delivered_Orders_Hide = RolePermissions?.Delivery_Delivered_Orders_Hide?.Is_Hide
    }
  }, []);

  return (
    <>
      {Delivery_Delivered_Orders_Hide !== 1 &&
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  DELIVERED ORDERS
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {data.Total}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <ThumbUpIcon />
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={2} display="flex" alignItems="center">
              {data.Month - data.Avg >= 0 && <>
                <ArrowUpwardIcon className={classes.differenceIconSucc} />
                <Typography className={classes.differenceValueSucc} variant="body2">
                  {data.Month}
                </Typography>
              </>
              }
              {data.Month - data.Avg < 0 &&
                <>
                  <ArrowDownwardIcon className={classes.differenceIconFail} />
                  <Typography className={classes.differenceValueFail} variant="body2">
                    {data.Month}
                  </Typography>
                </>
              }
              <Typography color="textSecondary" variant="caption">
                Since last 30 days
              </Typography>
            </Box>
          </CardContent>
        </Card>

      }
    </>
  );
};

DeliveredOrders.propTypes = {
  className: PropTypes.string
};

export default DeliveredOrders;