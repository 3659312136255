import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import {
  deletestoretypepartnerlinkingAction,
} from '../../store/actions/storetypepartnerlinkingActions';
import ConfirmDialog from '../../components/dialogs/confirmDialog';
import { paginationStoreTypePartner } from 'src/store/actions/node-actions/nodepagination';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const StorePartnerLinkingView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const { state } = location;
  //permissions
  const RolePermissions = useSelector(state => state.rolepermission.Roles);
  const roleUserType = useSelector(state => state.auth.user);
  const Login_Details = useSelector(state => state.auth.user);

  const [storetypepartner, setStoretypepartner] = useState([]);
  const [filters, setFilters] = React.useState({
    search: '',
    search_by_filter: 'All',
    Store_Type_Name:state.Store_Type_Name,
    Records_Filter:"FILTER"
  });
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = useState({ PageNo: 1, PageSize: 10, SortBy: "Store_Type_Partner_Id", SortOrder: "ASC" })

  const handleLimitChange = event => {
    setPagination({ ...pagination, PageSize: +event.target.value, PageNo: 1 });
  };

  const handlePageChange = (event, newPage) => {
    setPagination({ ...pagination, PageSize: pagination.PageSize, PageNo: newPage + 1 });
  };
  const callBackPagination = async (status, data, pagination) => {
    if (status) {
      setStoretypepartner(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({ PageNo: 1, PageSize: 10, SortBy: "Store_Type_Partner_Id", SortOrder: "ASC" });
    }
  }

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      storetypepartnerCall();
    }
  }, [pagination])

  const [testFilters, setTestFilters] = useState({});
  useEffect(() => {
    if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
      setTestFilters(filters);
      searchFunction();
    }
  }, [filters]);

  const searchFunction = ()=>{
    const searchpagination={
      PageNo: 1, PageSize: 10, SortBy: "Store_Type_Partner_Id", SortOrder: "ASC" 
    }
    dispatch(paginationStoreTypePartner(filters, searchpagination, callBackPagination))
  }

  const storetypepartnerCall = () => {
    dispatch(getRolePermissions(Login_Details));
    dispatch(paginationStoreTypePartner(filters, pagination, callBackPagination))
  };

  useEffect(() => {
    dispatch(paginationStoreTypePartner(filters, pagination, callBackPagination))
  }, []);
  
  // confirm dailogbox
  const [storetypepartnerlink, setStoretypepartnerlink] = useState(false);
  const [ttitle, setTtitle] = useState('');
  const [tmessage, setTmessage] = useState('');
  const [storetypepartnerlinkIdle, setStoretypepartnerlinkIdle] = useState('');

  const chandleClose = (value) => {
    setStoretypepartnerlink(false);
    if (value) {

      const deleteData = {
        Store_Type_Partner_Id: storetypepartnerlinkIdle?.Store_Type_Partner_Id,
        Updated_By: Login_Details.Email_Id
      };
      dispatch(deletestoretypepartnerlinkingAction(deleteData, filters, pagination, callBackPagination));
    }
  };


  //Delete handler
  const deleteStoretypepartnerlinkHandler = storetypepartnerlinkData => async e => {
    setStoretypepartnerlink(true);
    setTtitle("Alert!");
    setTmessage("Are you sure to delete?");
    setStoretypepartnerlinkIdle(storetypepartnerlinkData);
  };

 
  // Sorting 
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Store_Type_Partner_Id');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPagination({ PageNo: 1, PageSize: pagination?.PageSize, SortBy: property, SortOrder: order === 'asc' ? "ASC" : "DESC" })
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };


  return (
    <Page className={classes.root} title="Store Type">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/store-type"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Store Type Partners
          </Typography>
        </Box>

        <Toolbar state={state} filters={filters} setfilters={setFilters} RolePermissions={RolePermissions} roleUserType={roleUserType} />
        <Box mt={3}>
          <Results
            allStoretypespartnerlink={storetypepartner}
            deleteStoretypepartnerlinkHandler={deleteStoretypepartnerlinkHandler}
            allStoretypespartnerlinkNoFilter={storetypepartner}
            pagination={pagination}
            setPagination={setPagination}
            handleLimitChange={handleLimitChange}
            handlePageChange={handlePageChange}
            order={order}
            orderBy={orderBy}
            createSortHandler={createSortHandler}
            state={state}
            RolePermissions={RolePermissions}
            roleUserType={roleUserType}
          />
        </Box>
      </Container>
      <ConfirmDialog inputMessage={tmessage} titleMsg={ttitle} open={storetypepartnerlink} onClose={chandleClose} />

    </Page>
  );
};

export default StorePartnerLinkingView;