import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";

export default class EditorContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="editor">
                <Editor
                    editorState={this.props.editorState}
                    onEditorStateChange={this.props.onEditorStateChange}
                    // wrapperClassName="wrapper-class"
                    // editorClassName="editor-class"
                    // toolbarClassName="toolbar-class"
                    toolbar={{
                        // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'remove', 'history'],
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                    }}
                />
            </div>
        );
    }
}