import React from 'react'
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        paddingRight: '0px',
        marginTop: "-10px",
        marginBottom: "-20px"
    }
}))

export default function Popup(props) {

    const { children, openPopup, setOpenPopup } = props;
    const classes = useStyles();

    return (
        <Dialog open={openPopup} maxWidth="md" classes={{ paper: classes.dialogWrapper }}>
            <div style={{ display: 'flex' }} className={classes.dialogTitle}>
                <IconButton style={{ marginLeft: "auto" }} onClick={() => { setOpenPopup(false) }} >
                    <CloseIcon />
                </IconButton>
            </div>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    )
}
