import { toast } from 'react-toastify';
import { paginationServicescategory } from './node-actions/nodepagination';
import { boomiAdminApi, nodeServiceApi, urlGenarator } from './commonAxios';

export const SERVICE_CATEGORY_CREATE_REQUEST = "SERVICE_CATEGORY_CREATE_REQUEST";
export const SERVICE_CATEGORY_CREATE_SUCCESS = "SERVICE_CATEGORY_CREATE_SUCCESS";
export const SERVICE_CATEGORY_CREATE_FAIL = "SERVICE_CATEGORY_CREATE_FAIL";
export const SERVICE_CATEGORY_UPDATE_REQUEST = "SERVICE_CATEGORY_UPDATE_REQUEST";
export const SERVICE_CATEGORY_UPDATE_SUCCESS = "SERVICE_CATEGORY_UPDATE_SUCCESS";
export const SERVICE_CATEGORY_UPDATE_FAIL = "SERVICE_CATEGORY_UPDATE_FAIL ";

/*=============================================================
                  Add ServiceCategory Action
==============================================================*/
export const AddCategorytypeAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: SERVICE_CATEGORY_CREATE_REQUEST
  });
  nodeServiceApi.post (`/admin/services/Create/`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICE_CATEGORY_CREATE_SUCCESS
        });
        toast('Service Category Created Successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/servicescategories-management');
      } else {
        dispatch({
          type: SERVICE_CATEGORY_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
        toast(successResponse.UI_Display_Message+"please change Service Name and Try Again ", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }
    })
    .catch(error => {
      dispatch({
        type:SERVICE_CATEGORY_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Update ServiceCategory Action
===============================================================*/
export const EditServiceCategorytypeAction = (formData, navigate,) => async dispatch => {
  dispatch({
    type: SERVICE_CATEGORY_UPDATE_REQUEST
  });
  nodeServiceApi.put(`/admin/services/Update/${formData.Service_Id}`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICE_CATEGORY_UPDATE_SUCCESS
        });
        toast('Service Category Updated Successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/servicescategories-management',);
      } else {  
        dispatch({
          type: SERVICE_CATEGORY_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
          
        });
        toast(successResponse.UI_Display_Message+"please change Service Name and Try Again ", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }
    })
    .catch(error => {
      dispatch({
        type: SERVICE_CATEGORY_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};


/*=============================================================
              Delete  ServiceCategory Action
 ===============================================================*/
export const deletecategoryAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeServiceApi.delete(`/admin/services/Delete/${formData.id} `,);
    if (data) {
      toast(' Category deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    }
    dispatch(paginationServicescategory(filters, pagination, callBackPagination));

  } 
catch (err) {
  }
};

/*=============================================================
           ServiceCategory logo Image Upload Action
===============================================================*/
export const categoryuploadImagepath = (
  formData,
  callBackUploadImgae,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=ServiceCategoryType&fileextension=png&filetype=Images&filename=ServiceCategoryType`,
      formData
    );
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) {
  }
};