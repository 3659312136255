import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
  Avatar
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createUserAction,
  uploadUserImage,
  getAllEmails,
  getAllUserAction,
} from '../../store/actions/userprofileAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/material.css'
import { CircularProgress } from '@mui/material';

const genderOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Male',
    label: 'Male'
  },
  {
    value: 'Female',
    label: 'Female'
  },
  {
    value: 'Transgender',
    label: 'Transgender'
  }
];

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  }
}));

const AddUser = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  // componentDidMount
  useEffect(() => {
    const formData = {
      search: '',
      search_by_filter: '',
      Records_Filter: 'ALL'
    };
    dispatch(getAllUserAction(formData));
    dispatch(getAllEmails(formData));
  }, []);

  const allUsers = useSelector(state => state.userprofile.users.all);
  const [errorMessage, setErrorMessage] = useState({ Email_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.userprofile.userCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const emails = useSelector(state => state.userprofile.userEmails.emails);

  //mobile
  const [mobile, setMobile] = useState();

  // img upload
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadUserImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };
  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        First_Name: '',
        Last_Name: '',
        Full_Name: '',
        Email_Id: '',
        Mobile: '',
        Dob: '',
        Gender: '',
        Id_Proof_Type: '',
        Id_Proof_Number: '',
        Google_Login_Id: '',
        Facebook_Login_Id: '',
        Profile_Pic_Path: imgUrl,
        Created_By: loginEmail,
        Status: ''
      }}
      validationSchema={
        Yup.object().shape({
          First_Name: Yup.string().required('First Name is required.'),
          Last_Name: Yup.string().required('Last Name is required.'),
          Full_Name: Yup.string().required('Full Name is required.'),
          Email_Id: Yup.string().notOneOf([errorMessage.Email_Id.key, null], errorMessage.Email_Id.message).required('Email Address is required.'),
          Mobile: Yup.string().min(14, "Please provide a valid Mobile Number.").notOneOf([errorMessage.Mobile.key, null], errorMessage.Mobile.message).required('Mobile Number is required.'),
          Dob: Yup.date()
            .max(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "DOB should not be greater than today.")
            .required('Date Of Birth is required.'),
          Gender: Yup.string().required('Gender is required.'),
          Id_Proof_Number: Yup.string().when('Id_Proof_Type', (Id_Proof_Type, schema) => {
            if (Id_Proof_Type === "Aadhaar Card") {
              return schema.min(12, "Aadhaar Card Number is not valid.").max(12, "Aadhaar Card Number is not valid.").matches(/^[0-9]*$/, "Aadhaar Card Number is not valid.").required("Aadhaar Card Number is required.");
            } else if (Id_Proof_Type === "PAN Card") {
              return schema.matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "PAN Card Number is not valid.").required("PAN Card Number is required.");
            } else if (Id_Proof_Type === "Driving License") {
              return schema.matches(/^([a-zA-Z]){2}([0-9]){14}$/, "Driving License Number is not valid.").required("Driving License Number is required.");
            }
          }),
          Status: Yup.string().required('Status is required.'),
        })
      }
      onSubmit={(values, { setErrors }) => {
        values.Profile_Pic_Path = imgUrl;
        if (values) {
          let formData = JSON.parse(JSON.stringify(values));
          dispatch(createUserAction(formData, navigate, setErrorMessage, setErrors));
        }
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    {/* First Name */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.First_Name && errors.First_Name)}
                        fullWidth
                        helperText={touched.First_Name && errors.First_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="First Name"
                        name="First_Name"
                        value={values.First_Name}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Last Name */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Last_Name && errors.Last_Name)}
                        fullWidth
                        helperText={touched.Last_Name && errors.Last_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Last Name"
                        name="Last_Name"
                        value={values.Last_Name}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Full Name */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Full_Name && errors.Full_Name)}
                        fullWidth
                        helperText={touched.Full_Name && errors.Full_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Full Name"
                        name="Full_Name"
                        value={values.Full_Name}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Email Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Email_Id && errors.Email_Id)}
                        fullWidth
                        helperText={touched.Email_Id && errors.Email_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Email Address"
                        name="Email_Id"
                        value={values.Email_Id}
                        variant="outlined"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {emails.filter(option => {
                          let test = true;
                          for (let i = 0; i < allUsers.length; i++) {

                            try {
                              if (option.Email_Id === allUsers[i].Email_Id) {
                                test = false;
                                break;
                              }
                            } catch (error) {

                            }
                          }

                          return test
                        }).map(option => (

                          <option key={option.Email_Id} value={option.Email_Id}>{option.Email_Id}</option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Mobile */}
                    <Grid item md={6} xs={12}>
                      <PhoneInput
                        containerClass={touched.Mobile && errors.Mobile ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                        inputClass={"form-control MuiOutlinedInput-input"}
                        error={true}
                        country={"in"}
                        fullWidth
                        inputStyle={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px"
                        }}
                        inputProps={{
                          name: "Mobile",
                        }}
                        specialLabel="Mobile Number"
                        name="Mobile"
                        onBlur={handleBlur}
                        placeholder="+91 XXXXX XXXXX"
                        onChange={(value, country, e, formattedValue) => {
                          setFieldValue("Mobile", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                          setMobile(formattedValue)
                        }}
                        value={mobile}
                      />
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Mobile && errors.Mobile}</p>
                    </Grid>

                    {/* DoB */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Dob && errors.Dob)}
                        fullWidth
                        helperText={touched.Dob && errors.Dob}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Date Of Birth"
                        name="Dob"
                        type="date"
                        value={values.Dob}
                        variant="outlined"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {/* Gender */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Gender && errors.Gender)}
                        fullWidth
                        helperText={touched.Gender && errors.Gender}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Gender"
                        name="Gender"
                        select
                        SelectProps={{ native: true }}
                        value={values.Gender}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {genderOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <TextField
                        error={Boolean(touched.Id_Proof_Type && errors.Id_Proof_Type)}
                        fullWidth
                        helperText={touched.Id_Proof_Type && errors.Id_Proof_Type}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          handleChange(e);
                          setFieldValue("Id_Proof_Number", "");
                        }}
                        label="ID Proof Type"
                        name="Id_Proof_Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Id_Proof_Type}
                        variant="outlined"
                      >
                        <option value="" >
                          -Please Select-
                        </option>
                        <option value="Aadhaar Card" >
                          Aadhaar Card
                        </option>
                        <option value="Driving License" >
                          Driving License
                        </option>
                        <option value="PAN Card" >
                          PAN Card
                        </option>
                      </TextField>

                    </Grid>
                    {/* Id Proof Number */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Id_Proof_Number && errors.Id_Proof_Number)}
                        fullWidth
                        disabled={values.Id_Proof_Type?.length <= 0}
                        helperText={touched.Id_Proof_Number && errors.Id_Proof_Number}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label={values.Id_Proof_Type ? values.Id_Proof_Type + " Number" : "ID Proof Number"}
                        name="Id_Proof_Number"
                        value={values.Id_Proof_Number}
                        variant="outlined"
                        inputProps={{ maxLength: values.Id_Proof_Type === "Aadhaar Card" ? 12 : values.Id_Proof_Type === "Driving License" ? 16 : values.Id_Proof_Type === "PAN Card" ? 10 : '' }}
                      />
                    </Grid>
                    {/* Google  Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Google_Login_Id && errors.Google_Login_Id)}
                        fullWidth
                        helperText={touched.Google_Login_Id && errors.Google_Login_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Google ID"
                        name="Google_Login_Id"
                        value={values.Google_Login_Id}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Facebook Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Facebook_Login_Id && errors.Facebook_Login_Id)}
                        fullWidth
                        helperText={touched.Facebook_Login_Id && errors.Facebook_Login_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Facebook ID"
                        name="Facebook_Login_Id"
                        value={values.Facebook_Login_Id}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
                {/* upload photo */}
                <Grid item md={4} xs={12}>
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="User Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="User Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" p={2}>
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>
            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited || uploadPic}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddUser.propTypes = {
  className: PropTypes.string
};

export default AddUser;