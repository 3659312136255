import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Box,
} from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { userRideUserPayAction } from 'src/store/actions/userridesAction';

const TransferTypeOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'CR',
        label: 'Credit'
    },
    {
        value: 'DB',
        label: 'Debit'
    }
];

const RideUserPayment = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let { state } = location;

    const isSubmited = useSelector(state => state?.userrides?.userrideUserPay?.isLoading);
    const errorMessage = useSelector(state => state?.userrides?.userrideUserPay?.error);

    return (
        <Formik
            initialValues={{
                Credit_Reason: "",
                Credit_amount: "",
                Debit_Amount: "",
                Debit_Reason: "",
                Transfer_Type: "",
                Payment_Gateway_Reference_Number: state.Payment_Gateway_Transaction_Number ? state.Payment_Gateway_Transaction_Number : "",
                User_Ride_Id: state.User_Ride_Id ? state.User_Ride_Id : "",
                User_email_Id: state.User_Email_Id ? state.User_Email_Id : ""
            }}

            validationSchema={
                Yup.object().shape({
                    Transfer_Type: Yup.string().required('Transfer Type is required.'),
                    Credit_amount: Yup.number().when('Transfer_Type', { is: "CR", then: Yup.number().min(1, "Credit Amount must be at least 1.").required('Credited Amount is required.'), }),
                    Credit_Reason: Yup.string().when('Transfer_Type', { is: "CR", then: Yup.string().required('Credited Reason is required.'), }),
                    Debit_Amount: Yup.number().when('Transfer_Type', { is: "DB", then: Yup.number().min(1, "Debit Amount must be at least 1.").required('Debited Amount is required.'), }),
                    Debit_Reason: Yup.string().when('Transfer_Type', { is: "DB", then: Yup.string().required('Debited Reason is required.'), }),
                })}

            onSubmit={(values, { setSubmitting, setErrors }) => {
                setSubmitting(false);
                const errors = {};
                setErrors(errors);
                if (values) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(userRideUserPayAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        {/* User_Ride_Id */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="User Ride ID"
                                                name="User_Ride_Id"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.User_Ride_Id}
                                                variant="outlined"
                                                disabled
                                            />
                                        </Grid>

                                        {/* User_email_Id */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="User Email ID"
                                                name="User_email_Id"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.User_email_Id}
                                                variant="outlined"
                                                disabled
                                            />
                                        </Grid>

                                        {values.Payment_Gateway_Reference_Number !== "" ? (
                                            <>
                                                {/* Payment_Gateway_Reference_Number */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Payment Gateway Reference Number"
                                                        name="Payment_Gateway_Reference_Number"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.Payment_Gateway_Reference_Number}
                                                        variant="outlined"
                                                        disabled
                                                    />
                                                </Grid>
                                            </>
                                        ) : (
                                            null
                                        )}

                                        {/* Transfer_Type */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Transfer_Type && errors.Transfer_Type)}
                                                fullWidth
                                                helperText={touched.Transfer_Type && errors.Transfer_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                select
                                                SelectProps={{ native: true }}
                                                label="Transfer Type"
                                                name="Transfer_Type"
                                                value={values.Transfer_Type}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                {TransferTypeOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {values.Transfer_Type === 'DB' ? (
                                            <>
                                                {/* Debit_Amount */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.Debit_Amount && errors.Debit_Amount)}
                                                        fullWidth
                                                        helperText={touched.Debit_Amount && errors.Debit_Amount}
                                                        label="Debited Amount"
                                                        name="Debit_Amount"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.Debit_Amount}
                                                        variant="outlined"
                                                        type="number"
                                                    />
                                                </Grid>

                                                {/* Debit_Reason */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.Debit_Reason && errors.Debit_Reason)}
                                                        fullWidth
                                                        helperText={touched.Debit_Reason && errors.Debit_Reason}
                                                        label="Debited Reason"
                                                        name="Debit_Reason"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.Debit_Reason}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                                {/* Credit_amount */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.Credit_amount && errors.Credit_amount)}
                                                        fullWidth
                                                        helperText={touched.Credit_amount && errors.Credit_amount}
                                                        label="Credited Amount"
                                                        name="Credit_amount"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.Credit_amount}
                                                        variant="outlined"
                                                        type="number"
                                                    />
                                                </Grid>

                                                {/* Credit_Reason */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.Credit_Reason && errors.Credit_Reason)}
                                                        fullWidth
                                                        helperText={touched.Credit_Reason && errors.Credit_Reason}
                                                        label="Credited Reason"
                                                        name="Credit_Reason"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.Credit_Reason}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </>
                                        )}

                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Box display="flex" pl={2} >
                        <span style={{ color: "red" }}>{errorMessage}</span>
                    </Box>

                    <Box display="flex" p={2}>
                        {isSubmited ?
                            <CircularProgress />
                            :
                            <Button
                                disabled={isSubmited}
                                type="submit"
                                style={{ backgroundColor: '#27B6CC' }}
                                variant="contained"
                            >
                                <span style={{ color: 'white' }}>Save details</span>
                            </Button>
                        }
                    </Box>

                </form>
            )}
        </Formik>
    );
};

RideUserPayment.propTypes = {
    className: PropTypes.string
};

export default RideUserPayment;