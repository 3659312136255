import axios from 'axios'
import { toast } from 'react-toastify'
import { nodeCudApi, nodeGetApi } from './commonAxios'

export const POS_SESSION_UPDATE_SUCCESS = "POS_SESSION_UPDATE_SUCCESS";
export const POS_SESSION_UPDATE_FAILURE = "POS_SESSION_UPDATE_FAILURE";
export const POS_SESSION_CREATE_SUCCESS = "POS_SESSION_CREATE_SUCCESS";
export const POS_SESSION_CREATE_FAILURE = "POS_SESSION_CREATE_FAILURE";
export const POS_CUSTOMER_CREATE_SUCCESS = "POS_CUSTOMER_CREATE_SUCCESS";
export const POS_CUSTOMER_CREATE_FAILURE = "POS_CUSTOMER_CREATE_FAILURE";
export const POS_ORDER_CREATE_SUCCESS = "POS_ORDER_CREATE_SUCCESS";
export const POS_ORDER_CREATE_FAILURE = "POS_ORDER_CREATE_FAILURE";

export const POS_Category_Get = async (formData = {}) => {
    return new Promise(async (resolve, reject) => {
        await nodeGetApi.post('/POS/Category_Get', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            } else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, data: [], error: err.message })
        })
    })
}

export const POS_Product_Get = async (formData = {}) => {
    return new Promise(async (resolve, reject) => {
        await nodeGetApi.post('/POS/Product_Get', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            } else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, data: [], error: err.message })
        })
    })
}

export const POS_Cust_Get = async (formData = {}) => {
    return new Promise(async (resolve, reject) => {
        await nodeGetApi.post('/POS/Customer_Get', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            } else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, data: [], error: err.message })
        })
    })
}


export const POS_Invoice_Get = async (formData = {}) => {
    return new Promise(async (resolve, reject) => {
        await nodeGetApi.post('/POS/Order_Invoice_Get', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data })
            } else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, data: [], error: err.message })
        })
    })
}


export const POS_Session_Get = async (formData = {}) => {
    return new Promise(async (resolve, reject) => {
        await nodeGetApi.post('/POS/Session_Get', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            } else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, data: [], error: err.message })
        })
    })
}


export const User_RecentOrder_Get = async (formData = {}) => {
    return new Promise(async (resolve, reject) => {
        await nodeGetApi.post('/POS/Recent_Order_Get?PageNo=1&PageSize=1&SortBy=CREATED_DATE&SortOrder=DESC', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            } else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, data: [], error: err.message })
        })
    })
}

export const POS_Session_Create = (formData) => async (dispatch) => {
    try {
        const response = await nodeCudApi.post('/POS/Session_Create', formData);
        if (response && response.data && response.data.Response_Status === 'Success') {
            // Dispatch a success action
            dispatch({ type: 'POS_SESSION_CREATE_SUCCESS', payload: response.data });
            // Show a success toast
            toast('Session created successfully...', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
            return { status: true, data: response.data };
        } else {
            // Dispatch a failure action
            dispatch({ type: 'POS_SESSION_CREATE_FAILURE' });
            return { status: false, data: [] };
        }
    } catch (error) {
        // Dispatch a failure action
        dispatch({ type: 'POS_SESSION_CREATE_FAILURE', error: error.message });
        return { status: false, data: [], error: error.message };
    }
};

export const POS_Session_Update = (formData) => async (dispatch) => {
    try {
        const response = await nodeCudApi.post('/POS/Session_Update', formData);
        if (response && response.data && response.data.Response_Status === 'Success') {
            // Dispatch a success action
            dispatch({ type: 'POS_SESSION_UPDATE_SUCCESS', payload: response.data });
            // Show a success toast
            toast('Session closed successfully...', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
            return { status: true, data: response.data };
        } else {
            // Dispatch a failure action
            dispatch({ type: 'POS_SESSION_UPDATE_FAILURE' });
            return { status: false, data: [] };
        }
    } catch (error) {
        // Dispatch a failure action
        dispatch({ type: 'POS_SESSION_UPDATE_FAILURE', error: error.message });
        return { status: false, data: [], error: error.message };
    }
};


export const POS_Customer_create = (formData) => async (dispatch) => {
    try {
        const response = await nodeCudApi.post('/POS/Customer_Create', formData);
        if (response && response.data && response.data.Response_Status === 'Success') {
            // Dispatch a success action
            dispatch({ type: 'POS_CUSTOMER_CREATE_SUCCESS', payload: response.data });
            // Show a success toast
            toast(response.data.UI_Display_Message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
            return { status: true, data: response.data };
        } else {
            // Dispatch a failure action
            dispatch({ type: 'POS_CUSTOMER_CREATE_FAILURE' });
            return { status: false, data: response.data };
        }
    } catch (error) {
        // Dispatch a failure action
        dispatch({ type: 'POS_CUSTOMER_CREATE_FAILURE', error: error.message });
        return { status: false, data: [], error: error.message };
    }
};



export const POS_Order_create = (formData) => async (dispatch) => {
    try {
        const response = await nodeCudApi.post('/POS/Order_Create', formData);
        if (response && response.data && response.data.Response_Status === 'Success') {
            // Dispatch a success action
            dispatch({ type: 'POS_ORDER_CREATE_SUCCESS', payload: response.data });
            // Show a success toast
            toast(response.data.UI_Display_Message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
            return { status: true, data: response.data };
        } else {
            // Dispatch a failure action
            dispatch({ type: 'POS_ORDER_CREATE_FAILURE' });
            return { status: false, data: response.data };
        }
    } catch (error) {
        // Dispatch a failure action
        dispatch({ type: 'POS_ORDER_CREATE_FAILURE', error: error.message });
        return { status: false, data: [], error: error.message };
    }
};





// export const POS_Session_Create = async (formData = {}) => {
//     try {
//         const response = await axios.post('https://5873-14-195-25-38.ngrok-free.app/POS/Session_Create', formData);

//         if (response && response.data) {
//             const successResponse = response.data;

//             if (successResponse.Response_Status === 'Success') {
//                 // Show a toast notification
//                 toast('Session created successfully.', {
//                     position: toast.POSITION.TOP_CENTER,
//                     autoClose: 3000
//                 });
//             }

//             return { status: true, data: response.data };
//         } else {
//             return { status: false, data: [] };
//         }
//     } catch (error) {
//         return { status: false, data: [], error: error.message };
//     }
// };