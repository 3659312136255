import { nodeGetApi } from './commonAxios';
import config from './config';

export const USER_STATISTICS_REQUEST = "USER_STATISTICS_REQUEST";
export const USER_STATISTICS_SUCCESS = "USER_STATISTICS_SUCCESS";
export const USER_STATISTICS_FAIL = "USER_STATISTICS_FAIL";

//  Get All User Statistics Action
export const getAllUserStatisticsAction = formData => async dispatch => {
  try {
    dispatch({
      type: USER_STATISTICS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/User_Statistics/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=User_Statistics_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: USER_STATISTICS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: USER_STATISTICS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: USER_STATISTICS_FAIL,
      payload: err
    });
  }
};