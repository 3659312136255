import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import isEmpty from '../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  buttonRoot: {
    minWidth: '7px',
    padding: '6px'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));

const headCells = [
  { id: 'Contact_Id', numeric: false, disablePadding: false, label: 'Payment ID' },
  { id: 'Amount', numeric: false, disablePadding: false, label: 'Amount' },
  { id: 'contact', numeric: false, disablePadding: false, label: 'Mobile' },
  { id: 'Email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'method', numeric: false, disablePadding: false, label: 'Payment Method' },
];

const Results = ({
  className,
  allRazorpaytransactions,
  allRazorpaytransaction,
  page,
  limit,
  pagination,
  order,
  orderBy,
  createSortHandler,
  RolePermissions,
  roleUserType,
  handlePageFirst,
  handlePagePreview,
  handlePageNext,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedRazorpayIds, setSelectedRazorpayIds] = useState([]);

  const visibleRazorpayHandler = razorpayData => e => {
    navigate("/app/view-razorpaytransactiondetails", { state: razorpayData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Actions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allRazorpaytransaction) &&
                (allRazorpaytransaction).map(razorpay => (
                  <TableRow
                    hover
                    key={razorpay.Id}
                    selected={
                      selectedRazorpayIds.indexOf(razorpay.Id) !== -1
                    }
                  >
                    <TableCell>{razorpay.Id}</TableCell>
                    <TableCell>{razorpay.Amount}</TableCell>
                    <TableCell>{razorpay.contact}</TableCell>
                    <TableCell style={{ color: '#1665D8' }} >
                      {razorpay.email?.substring(0, 25)}...
                    </TableCell>
                    <TableCell>{razorpay.method}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Razorpay_Transactions_Read?.Value === "Razorpay_Transactions_Read" && RolePermissions?.Razorpay_Transactions_Read?.Is_Read === 1) ?
                      <TableCell>
                        <Button
                          onClick={visibleRazorpayHandler(razorpay)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allRazorpaytransaction) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>     
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
      <Button
        disabled={pagination.PageNo == 1}
        onClick={() => {
          handlePageFirst(1)
        }}
        color="primary"
      >
        <KeyboardBackspaceIcon style={{ color: '#9EA0A5' }} />
        Go the First Page
      </Button> 
      <Button
        disabled={pagination.PageNo == 1}
        onClick={() => {
          handlePagePreview(pagination.PageNo)
        }}
        color="primary"
      >
        <ChevronLeftIcon style={{ color: '#9EA0A5' }} />
        Previous
      </Button>
      <Button
        onClick={() => {
          handlePageNext(pagination.PageNo)
        }}
        color="primary"
      >Next
        <NavigateNextIcon style={{ color: '#9EA0A5' }} />
      </Button>
      </div>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allRazorpaytransaction: PropTypes.array.isRequired
};

export default Results;