import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography,
    makeStyles,
    Box,
    colors
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useDispatch, useSelector } from 'react-redux';
import { PhysicalPartnersCount } from 'src/store/actions/reports/dashboardActions';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    avatar: {
        backgroundColor: colors.green[600],
        height: 56,
        width: 56
    }
    , differenceIconFail: {
        color: colors.red[900]
    },
    differenceValueFail: {
        color: colors.red[900],
        marginRight: theme.spacing(1)
    },
    differenceIconSucc: {
        color: colors.green[900]
    },
    differenceValueSucc: {
        color: colors.green[900],
        marginRight: theme.spacing(1)
    }
}));

const PhysicalPartners = ({ className, RolePermissions, setSeconds, ...rest }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const data = useSelector(state => state.dash.PhysicalPartners.data);
    let Total_Physical_Partners_Hide = RolePermissions?.Total_Physical_Partners_Hide?.Is_Hide

    // componentDidMount
    useEffect(() => {
        let payload = {
            "Records_Filter": "FILTER",
            "Type": "Physical"
        }
        dispatch(PhysicalPartnersCount(payload));
        setSeconds(5);
        if (RolePermissions?.Total_Physical_Partners_Hide?.Value === "Total_Physical_Partners_Hide" && RolePermissions?.Total_Physical_Partners_Hide?.Is_Hide === 1) {
            Total_Physical_Partners_Hide = RolePermissions?.Total_Physical_Partners_Hide?.Is_Hide
        }
    }, []);

    return (
        <>
            {Total_Physical_Partners_Hide !== 1 &&
                <Card className={clsx(classes.root, className)} {...rest}>
                    <CardContent>
                        <Grid container justifyContent="space-between" spacing={3}>
                            <Grid item>
                                <Typography color="textSecondary" gutterBottom variant="h6">
                                    PHYSICAL PARTNERS
                                </Typography>
                                <Typography color="textPrimary" variant="h3">
                                    {data.Total}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Avatar className={classes.avatar}>
                                    <PeopleIcon />
                                </Avatar>
                            </Grid>
                        </Grid>
                        <Box mt={2} display="flex" alignItems="center">
                            {data.Month - data.Avg > 0 && <>
                                <ArrowUpwardIcon className={classes.differenceIconSucc} />
                                <Typography className={classes.differenceValueSucc} variant="body2">
                                    {data.Month}
                                </Typography>
                            </>
                            }
                            {data.Month - data.Avg < 0 &&
                                <>
                                    <ArrowDownwardIcon className={classes.differenceIconFail} />
                                    <Typography className={classes.differenceValueFail} variant="body2">
                                        {data.Month}
                                    </Typography>
                                </>
                            }
                            <Typography color="textSecondary" variant="caption">
                                Since last 30 days
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>}
        </>
    );
};

PhysicalPartners.propTypes = {
    className: PropTypes.string
};

export default PhysicalPartners;