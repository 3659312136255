import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { ConnectedFocusError } from "focus-formik-error";
import { createPartnerWalletHistoryAction, getAllPartnerWallethistoryAction } from 'src/store/actions/partnerwallethistoryAction';
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'INITIATED',
    label: 'INITIATED'
  },
  {
    value: 'HOLD',
    label: 'HOLD'
  },
  {
    value: 'SETTLED',
    label: 'SETTLED'
  }
];

const TransactionTypeOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'CR',
    label: 'Credit'
  },
  {
    value: 'DB',
    label: 'Debit'
  }
];

const AddPartnerWalletHistory = ({ className, state, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSubmited = useSelector(state => state.partnerwallethistory.partnerwalletHistoryCreate.isLoading);
  const errorMessage = useSelector(state => state.partnerwallethistory.partnerwalletHistoryCreate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  // componentDidMount
  useEffect(() => {
    const formData = {
      Records_Filter: 'FILTER',
      Wallet_Id: state?.Wallet_Id,
      search_by_filter: "",
      search: "",
    };
    dispatch(getAllPartnerWallethistoryAction(formData));
  }, []);

  return (
    <Formik
      initialValues={{
        Wallet_ID: state.Wallet_Id ? state.Wallet_Id : "",
        Wallet_Owner_ID: state.Wallet_Owner ? state.Wallet_Owner : "",
        UPI_ID: "",
        Balance: state.Remaining_Balance ? state.Remaining_Balance : "",
        Paid_Amount: "",
        Credited_Amount: "",
        Credit_Reason: "",
        Debited_Amount: "",
        Debit_Reason: "",
        Transaction_Date: "",
        Transaction_Type: "",
        Payment_Request_Date_Time: "",
        Payment_Gateway_Payment_Method_User_Internal_ID: "",
        Payment_Method: "",
        Payment_Settlement_Date_Time: "",
        Status: "",
        Email_ID: loginEmail
      }}

      validationSchema={
        Yup.object().shape({
          Transaction_Type: Yup.string().required('Transaction Type is required.'),
          Credited_Amount: Yup.number().when('Transaction_Type', { is: "CR", then: Yup.number().min(0, "Credited Amount should not be less than 0.").required('Credited Amount is required.'), }),
          Credit_Reason: Yup.string().when('Transaction_Type', { is: "CR", then: Yup.string().required('Credited Reason is required.'), }),
          Debited_Amount: Yup.number().when('Transaction_Type', { is: "DB", then: Yup.number().min(0, "Debited Amount should not be less than 0.").required('Debited Amount is required.'), }),
          Debit_Reason: Yup.string().when('Transaction_Type', { is: "DB", then: Yup.string().required('Debited Reason is required.'), }),
          Transaction_Date: Yup.string().required('Transaction Date is required.'),
          Payment_Request_Date_Time: Yup.string().required('Payment Requested Date Time is required.'),
          Payment_Settlement_Date_Time: Yup.string().required('Payment Settlement Date Time is required.'),
          Status: Yup.string().required('Status is required.'),
        })}

      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        if (values) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(createPartnerWalletHistoryAction(formValues, navigate, state));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (<form onSubmit={handleSubmit}>
        <ConnectedFocusError />
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={8} xs={12}>
                <Grid container spacing={3}>

                  {/* Balance */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Balance"
                      name="Balance"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Balance}
                      variant="outlined"
                      type="number"
                      disabled
                    />
                  </Grid>

                  {/* Transaction_Type */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Transaction_Type && errors.Transaction_Type)}
                      fullWidth
                      helperText={touched.Transaction_Type && errors.Transaction_Type}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      label="Transaction Type"
                      name="Transaction_Type"
                      value={values.Transaction_Type}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    >
                      {TransactionTypeOption.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>

                  {values.Transaction_Type === 'DB' ? (
                    <>
                      {/* Debited_Amount */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Debited_Amount && errors.Debited_Amount)}
                          fullWidth
                          helperText={touched.Debited_Amount && errors.Debited_Amount}
                          label="Debited Amount"
                          name="Debited_Amount"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.Debited_Amount}
                          variant="outlined"
                          type="number"
                        />
                      </Grid>

                      {/* Debit_Reason */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Debit_Reason && errors.Debit_Reason)}
                          fullWidth
                          helperText={touched.Debit_Reason && errors.Debit_Reason}
                          label="Debited Reason"
                          name="Debit_Reason"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.Debit_Reason}
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      {/* Credited_Amount */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Credited_Amount && errors.Credited_Amount)}
                          fullWidth
                          helperText={touched.Credited_Amount && errors.Credited_Amount}
                          label="Credited Amount"
                          name="Credited_Amount"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.Credited_Amount}
                          variant="outlined"
                          type="number"
                        />
                      </Grid>

                      {/* Credit_Reason */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Credit_Reason && errors.Credit_Reason)}
                          fullWidth
                          helperText={touched.Credit_Reason && errors.Credit_Reason}
                          label="Credited Reason"
                          name="Credit_Reason"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.Credit_Reason}
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  )}

                  {/* Paid_Amount */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Paid Amount"
                      name="Paid_Amount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Paid_Amount}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>

                  {/* Payment_Method */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Payment Method"
                      name="Payment_Method"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Payment_Method}
                      variant="outlined"
                    />
                  </Grid>

                  {/* Transaction_Date */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Transaction_Date && errors.Transaction_Date)}
                      fullWidth
                      helperText={touched.Transaction_Date && errors.Transaction_Date}
                      label="Transaction Date"
                      name="Transaction_Date"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Transaction_Date}
                      variant="outlined"
                      type="datetime-local"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  {/* Payment_Requested_Date_Time */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Payment_Request_Date_Time && errors.Payment_Request_Date_Time)}
                      fullWidth
                      helperText={touched.Payment_Request_Date_Time && errors.Payment_Request_Date_Time}
                      label="Payment Requested Date Time"
                      name="Payment_Request_Date_Time"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Payment_Request_Date_Time}
                      variant="outlined"
                      type="datetime-local"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  {/* Payment_Settlement_Date_Time */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Payment_Settlement_Date_Time && errors.Payment_Settlement_Date_Time)}
                      fullWidth
                      helperText={touched.Payment_Settlement_Date_Time && errors.Payment_Settlement_Date_Time}
                      label="Payment Settlement Date Time"
                      name="Payment_Settlement_Date_Time"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Payment_Settlement_Date_Time}
                      variant="outlined"
                      type="datetime-local"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  {/* Status */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Status && errors.Status)}
                      fullWidth
                      helperText={touched.Status && errors.Status}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      label="Status"
                      name="Status"
                      value={values.Status}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    >
                      {statusOption.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>

                  {/* Upi_Id */}
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="UPI ID"
                      name="UPI_ID"
                      value={values.UPI_ID}
                      variant="outlined"
                    />
                  </Grid>

                  {/* Payment_Gateway_Payment_Method_User_Internal_Id */}
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Payment Gateway Payment Method User Internal ID"
                      name="Payment_Gateway_Payment_Method_User_Internal_ID"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Payment_Gateway_Payment_Method_User_Internal_ID}
                      variant="outlined"
                    />
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Box display="flex" p={2}>
          <span style={{ color: "red" }}>{errorMessage}</span>
        </Box>

        <Box display="flex" p={2}>
          {isSubmited ?
            <CircularProgress />
            :
            <Button
              disabled={isSubmited}
              type="submit"
              style={{ backgroundColor: '#27B6CC' }}
              variant="contained"
            >
              <span style={{ color: 'white' }}>Save details</span>
            </Button>
          }
        </Box>

      </form>
      )}
    </Formik>
  );
};

AddPartnerWalletHistory.propTypes = {
  className: PropTypes.string
};

export default AddPartnerWalletHistory;