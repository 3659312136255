import config from './config';
import { toast } from 'react-toastify';
import { boomiAdminApi, nodeGetApi } from './commonAxios';

export const B2B_USER_BID_QUOTES_CREATE_REQUEST = "B2B_USER_BID_QUOTES_CREATE_REQUEST";
export const B2B_USER_BID_QUOTES_CREATE_SUCCESS = "B2B_USER_BID_QUOTES_CREATE_SUCCESS";
export const B2B_USER_BID_QUOTES_CREATE_FAIL = "B2B_USER_BID_QUOTES_CREATE_FAIL";

export const B2B_USER_BID_QUOTES_UPDATE_REQUEST = "B2B_USER_BID_QUOTES_UPDATE_REQUEST";
export const B2B_USER_BID_QUOTES_UPDATE_SUCCESS = "B2B_USER_BID_QUOTES_UPDATE_SUCCESS";
export const B2B_USER_BID_QUOTES_UPDATE_FAIL = "B2B_USER_BID_QUOTES_UPDATE_FAIL";

export const B2B_BID_QUOTE_PARTNERS_GET_REQUEST = "B2B_BID_QUOTE_PARTNERS_GET_REQUEST";
export const B2B_BID_QUOTE_PARTNERS_GET_SUCCESS = "B2B_BID_QUOTE_PARTNERS_GET_SUCCESS";
export const B2B_BID_QUOTE_PARTNERS_GET_FAIL = "B2B_BID_QUOTE_PARTNERS_GET_FAIL";

//create B2B User Bidding Quotes Action
export const createB2BUserProdBiddingQuotes = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: B2B_USER_BID_QUOTES_CREATE_REQUEST
    });
    boomiAdminApi.post(`/B2B_Partner_Bidding_Quotes/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: B2B_USER_BID_QUOTES_CREATE_SUCCESS
                });
                toast('B2B User Product Bidding Quote created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/b2b-user-product-bidding-quotes', state = { state });
            } else {
                dispatch({
                    type: B2B_USER_BID_QUOTES_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: B2B_USER_BID_QUOTES_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

//update B2B User Bidding Quotes Action
export const updateB2BUserProdBiddingQuotes = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: B2B_USER_BID_QUOTES_UPDATE_REQUEST
    });
    boomiAdminApi.put(`/B2B_Partner_Bidding_Quotes/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: B2B_USER_BID_QUOTES_UPDATE_SUCCESS
                });
                toast('B2B User Product Bidding Quote updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/b2b-user-product-bidding-quotes', state = { state });
            } else {

                dispatch({
                    type: B2B_USER_BID_QUOTES_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: B2B_USER_BID_QUOTES_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//get all B2B Bidding Partners
export const getAllB2BBiddingQuotePartners = formData => async dispatch => {
    try {
        dispatch({
            type: B2B_BID_QUOTE_PARTNERS_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Super_Admin/Partner_Bidding_Available_Products?PageNo=1&PageSize=${config.pageSize}&SortBy=Partner_Bidding_Available_Products_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: B2B_BID_QUOTE_PARTNERS_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: B2B_BID_QUOTE_PARTNERS_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: B2B_BID_QUOTE_PARTNERS_GET_FAIL,
            payload: err
        });
    }
};

//get all B2B Bidding Quote Partner location
export const getAllB2BBidQuotePartLocation = (formData, callBackData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/Partners/Partner_Address_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_ADDRESS_ID&SortOrder=ASC`, formData);
        if (data) {
            if (data.Is_Data_Exist === "0") {
                callBackData([])
            } else {
                callBackData(data.results)
            }
        }
    } catch (err) {
        dispatch({
            payload: err
        });
    }
};

//get all B2B Bidding Quotes Partner Delivery persons
export const getAllB2BBidQuotePartnerDelivery = (formData, callBackData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/Super_Admin/Delivery_Person_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Del_User_Email_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.Is_Data_Exist === "0") {
                callBackData([])
            } else {
                callBackData(data.results)
            }
        }
    } catch (err) {
        dispatch({
            payload: err
        });
    }
};

//Get B2B User Products Bidding Quote Action
export const getB2BBiddingProductQuoteAction = (formData, callBackB2BBiddingProductQuote) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/B2B/Bidding_Quotes`, formData);
        if (data) {
            callBackB2BBiddingProductQuote(data)
        }
    } catch (err) {
    }
};