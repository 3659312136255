import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Box,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createUserRideAction, getRideAllUserAction, getRideVehicleComfortTypes } from 'src/store/actions/userridesAction';
import { CircularProgress } from '@mui/material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";

const AddUserRides = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const formData = {
            search_by_filter: "",
            search: "",
            "Records_Filter": "FILTER",
            "Status": "Active"
        };
        const formData1 = {
            Records_Filter: 'ALL',
            search_by_filter: "",
            search: "",
        };
        dispatch(getRideAllUserAction(formData));
        dispatch(getRideVehicleComfortTypes(formData1));
    }, []);

    const isSubmited = useSelector(state => state.userrides.userrideCreate.isLoading);
    const errorMessage = useSelector(state => state.userrides.userrideCreate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const rideuseremails = useSelector(state => state.userrides.userrideemails.all)
    const ridevehiclecomfort = useSelector(state => state.userrides.userridevehiclecomfort.all)

    const values = {
        postcode: "",
        Comfort_Type: "",
        Distance_In_Kms: "",
        Drop_Latitude: "",
        Drop_Location: "",
        Drop_Longitude: "",
        Is_Out_Station_Ride: false,
        Is_Schedule_Ride: false,
        Pickup_Latitude: "",
        Pickup_Location: "",
        Pickup_Longitude: "",
        Schedule_Date_Time: "",
        User_Email_Id: "",
        Created_By: ""
    }

    const Form_Validation = Yup.object().shape({
        User_Email_Id: Yup.string().required('User Email ID is required.'),
        Comfort_Type: Yup.string().required('Comfort Type is required.'),
        Schedule_Date_Time: Yup.string().required('Schedule Date Time is required.'),
        Distance_In_Kms: Yup.number().min(0, "Distance should not be less than 0.").required('Distance In Kms is required.'),
        Pickup_Location: Yup.string().required('Pickup Location is required.'),
        Drop_Location: Yup.string().required('Drop Location is required.'),
        Pickup_Latitude: Yup.string().matches(/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/, 'Please provide valid Pickup Latitude.').required('Pickup Latitude is required.'),
        Pickup_Longitude: Yup.string().matches(/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/, 'Please provide valid Pickup Longitude.').required('Pickup Longitude is required.'),
        Drop_Latitude: Yup.string().matches(/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/, 'Please provide valid Drop Latitude.').required('Drop Latitude is required.'),
        Drop_Longitude: Yup.string().matches(/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/, 'Please provide valid Drop Longitude.').required('Drop Longitude is required.'),
        postcode: Yup.string().matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, 'Please provide valid Post Code.').required('Post Code is required.'),
    })

    const onSubmit = (values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            if (values.Is_Out_Station_Ride) {
                formValues.Is_Out_Station_Ride = 1;
            } else {
                formValues.Is_Out_Station_Ride = 0;
            }
            if (values.Is_Schedule_Ride) {
                formValues.Is_Schedule_Ride = 1;
            } else {
                formValues.Is_Schedule_Ride = 0;
            }

            const formData = {
                Address: {
                    postcode: values.postcode
                },
                User_Rides: {
                    Comfort_Type: values.Comfort_Type,
                    Distance_In_Kms: values.Distance_In_Kms,
                    Drop_Latitude: values.Drop_Latitude,
                    Drop_Location: values.Drop_Location,
                    Drop_Longitude: values.Drop_Longitude,
                    Is_Out_Station_Ride: formValues.Is_Out_Station_Ride,
                    Is_Schedule_Ride: formValues.Is_Schedule_Ride,
                    Pickup_Latitude: values.Pickup_Latitude,
                    Pickup_Location: values.Pickup_Location,
                    Pickup_Longitude: values.Pickup_Longitude,
                    Schedule_Date_Time: values.Schedule_Date_Time,
                    User_Email_Id: values.User_Email_Id,
                    Created_By: loginEmail
                }
            }
            let formvalues = JSON.parse(JSON.stringify(formData));
            dispatch(createUserRideAction(formvalues, navigate));
        }
    }

    return (
        <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
            {({
                errors,
                handleBlur,
                handleChange,
                touched,
                values,
                setFieldValue
            }) => (
                <Form>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        {/* User_Email_Id */}
                                        <Grid item md={6} xs={12}>
                                            <Autocomplete
                                                freeSolo
                                                options={rideuseremails}
                                                groupBy={(option) => option.firstLetter}
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option.Email_Id}
                                                renderInput={(params) => <TextField {...params}
                                                    error={Boolean(touched.User_Email_Id && errors.User_Email_Id)}
                                                    helperText={touched.User_Email_Id && errors.User_Email_Id}
                                                    onBlur={handleBlur}
                                                    label="User Email ID"
                                                    name="User_Email_Id"
                                                    variant="outlined"
                                                    value={values.User_Email_Id}
                                                    onChange={() => {
                                                        dispatch(getRideAllUserAction(params.inputProps.value))
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill,
                                                        startAdornment: (
                                                            <React.Fragment>
                                                                <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                {params.InputProps.startAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />}
                                                onChange={(e, value) => {
                                                    setFieldValue("User_Email_Id", value?.Email_Id ? value.Email_Id : "");
                                                }}
                                            />

                                        </Grid>

                                        {/* Comfort_Type */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Comfort_Type && errors.Comfort_Type)}
                                                fullWidth
                                                helperText={touched.Comfort_Type && errors.Comfort_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Comfort Type"
                                                name="Comfort_Type"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Comfort_Type}
                                                variant="outlined"
                                            ><option key="" value="">--Please Select--</option>
                                                {ridevehiclecomfort?.sort((a, b) => a.Vehicle_Comfort_Type?.localeCompare(b.Vehicle_Comfort_Type))?.map(option => (
                                                    <option key={option.Ride_Vehicle_Type_Id} value={option.Vehicle_Comfort_Type}>
                                                        {option.Vehicle_Comfort_Type + " - " + option.Vehicle_Type}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {/* Schedule_Date_Time */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Schedule_Date_Time && errors.Schedule_Date_Time)}
                                                fullWidth
                                                helperText={touched.Schedule_Date_Time && errors.Schedule_Date_Time}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Schedule Date Time"
                                                name="Schedule_Date_Time"
                                                value={values.Schedule_Date_Time}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="datetime-local"
                                            >
                                            </TextField>
                                        </Grid>

                                        {/* Distance_In_Kms */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Distance_In_Kms && errors.Distance_In_Kms)}
                                                fullWidth
                                                helperText={touched.Distance_In_Kms && errors.Distance_In_Kms}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Distance In Kms"
                                                name="Distance_In_Kms"
                                                type="number"
                                                value={values.Distance_In_Kms}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        {/* Pickup_Location */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Pickup_Location && errors.Pickup_Location)}
                                                fullWidth
                                                helperText={touched.Pickup_Location && errors.Pickup_Location}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Pickup Location"
                                                name="Pickup_Location"
                                                value={values.Pickup_Location}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        {/* Pickup_Latitude */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Pickup_Latitude && errors.Pickup_Latitude)}
                                                fullWidth
                                                helperText={touched.Pickup_Latitude && errors.Pickup_Latitude}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Pickup Latitude"
                                                name="Pickup_Latitude"
                                                value={values.Pickup_Latitude}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        {/* Pickup_Longitude */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Pickup_Longitude && errors.Pickup_Longitude)}
                                                fullWidth
                                                helperText={touched.Pickup_Longitude && errors.Pickup_Longitude}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Pickup Longitude"
                                                name="Pickup_Longitude"
                                                value={values.Pickup_Longitude}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        {/* Drop_Location */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Drop_Location && errors.Drop_Location)}
                                                fullWidth
                                                helperText={touched.Drop_Location && errors.Drop_Location}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Drop Location"
                                                name="Drop_Location"
                                                value={values.Drop_Location}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        {/* Drop_Latitude */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Drop_Latitude && errors.Drop_Latitude)}
                                                fullWidth
                                                helperText={touched.Drop_Latitude && errors.Drop_Latitude}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Drop Latitude"
                                                name="Drop_Latitude"
                                                value={values.Drop_Latitude}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        {/* Drop_Longitude */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Drop_Longitude && errors.Drop_Longitude)}
                                                fullWidth
                                                helperText={touched.Drop_Longitude && errors.Drop_Longitude}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Drop Longitude"
                                                name="Drop_Longitude"
                                                value={values.Drop_Longitude}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        {/* postcode */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.postcode && errors.postcode)}
                                                fullWidth
                                                helperText={touched.postcode && errors.postcode}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Post Code"
                                                name="postcode"
                                                value={values.postcode}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                                                }}
                                                min={0}
                                            >
                                            </TextField>
                                        </Grid>

                                        {/* Is_Out_Station_Ride */}
                                        <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        // disabled
                                                        onBlur={handleBlur}
                                                        checked={values.Is_Out_Station_Ride}
                                                        onChange={handleChange}
                                                        name="Is_Out_Station_Ride"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Out Station Ride"
                                            />
                                        </Grid>

                                        {/* Is_Schedule_Ride */}
                                        <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        // disabled
                                                        onBlur={handleBlur}
                                                        checked={values.Is_Schedule_Ride}
                                                        onChange={handleChange}
                                                        name="Is_Schedule_Ride"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Schedule Ride"
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>

                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    {/* Save details */}
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </Form>
            )}
        </Formik>
    );
};


export default AddUserRides;