import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
} from '@material-ui/core';
import isEmpty from 'src/store/validations/is-empty';
import CorpPagination from 'src/components/pagination/corppagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Corporate ID' },
  { id: 'Company_Name', numeric: false, disablePadding: false, label: 'Organization name' },
  { id: 'Website_URL', numeric: false, disablePadding: false, label: 'Organization website' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allcorpUser,
  CorpUserApprovelHandler,
  CorpUserRejectHandlers,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allcorpNoFilter,
  RolePermissions,
  roleUserType,
  pagination,
  setPagination,
  ...rest
}) => {
  // const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}>
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}>
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell > <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >&emsp;Actions&nbsp;</div></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allcorpUser) &&
                (allcorpUser)?.map(corp => (
                  <TableRow
                    hover
                    key={corp.id}>
                    <TableCell>{corp.id}</TableCell>
                    < TableCell>{corp.Company_Name}</ TableCell >
                    <TableCell >{corp.Website_URL}</TableCell>
                    <TableCell style={{ color: '#1665D8' }}>{corp.Status}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Pending_Delivery_Agents_Write?.Value === "Pending_Delivery_Agents_Write" && RolePermissions?.Pending_Delivery_Agents_Write?.Is_Write === 1) ?
                      <TableCell><div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
                        <Button
                          onClick={CorpUserApprovelHandler(corp)}
                          size='small'
                          variant='contained'
                          color="inherit"
                          style={{ background: 'green', color: 'white' }}
                        >
                          Approve
                        </Button>
                        <Button
                          size='small'
                          variant='contained'
                          color="inherit"
                          style={{ background: 'red', color: 'white' }}
                          onClick={CorpUserRejectHandlers(corp)}>
                          Decline
                        </Button></div>
                      </TableCell>
                      : null}

                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allcorpUser) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <CorpPagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allcorpUser: PropTypes.array.isRequired
};

export default Results;