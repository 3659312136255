import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import EditPartnerDeliveryAgentDetails from './editPartnerDeliveryAgents';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEmpty from 'src/store/validations/is-empty';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const EditPartnerDeliveryagentDetailsView = () => {
  const classes = useStyles();
  const location = useLocation();
  const { state } = location;

  const [allpartnerdeliagent, setPartnerdeliagent] = useState([]);

  let partnersdeliagent = useSelector(state => state.partnerdeliveryagent.allpartnerdeliagent);
  useEffect(() => {
    if (!isEmpty(partnersdeliagent)) {
      setPartnerdeliagent(partnersdeliagent.partnerdeliagent);
    }
  }, [partnersdeliagent]);

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/partner-deliveryagent-management"
          variant="text"
          size="small"
          color="inherit"
          state={state.state}
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Edit Partner Delivery Agent
          </Typography>
        </Box>
        <EditPartnerDeliveryAgentDetails state={state.state} data={state.data} allpartnerdeliagent={allpartnerdeliagent} />
      </Container>
    </Page>
  );
};

export default EditPartnerDeliveryagentDetailsView;