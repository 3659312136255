import {
  GIFT_HISTORY_REQUEST,
  GIFT_HISTORY_SUCCESS,
  GIFT_HISTORY_FAIL,
  GIFT_HISTORY_GET_REQUEST,
  GIFT_HISTORY_GET_SUCCESS,
  GIFT_HISTORY_GET_FAIL

} from '../actions/usergifthistoryAction'

const initialState = {
  usergifthistories: { all: [], error: '', isLoading: false },
  usergifthistory: { usergifthistory: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GIFT_HISTORY_REQUEST:
      return { ...state, usergifthistories: { all: state.usergifthistories.all, error: '', isLoading: true } };
    case GIFT_HISTORY_SUCCESS:
      return { ...state, usergifthistories: { all: action.payload, error: '', isLoading: false } };
    case GIFT_HISTORY_FAIL:
      return { ...state, usergifthistories: { all: [], error: action.payload, isLoading: false } };
    case GIFT_HISTORY_GET_REQUEST:
      return { ...state, usergifthistory: { usergifthistory: {}, error: '', isLoading: true } };
    case GIFT_HISTORY_GET_SUCCESS:
      return { ...state, usergifthistory: { usergifthistory: action.payload, error: '', isLoading: false } };
    case GIFT_HISTORY_GET_FAIL:
      return { ...state, usergifthistory: { usergifthistory: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}