import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import Pagination from 'src/components/pagination/pagination';


const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  images: {
    width: 100,
    height: 30
  },
  buttonRoot: {
    minWidth: '7px',
    padding: '6px'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));
const headCells = [
  { id: 'Store_Type_Linking_Id', numeric: false, disablePadding: false, label: 'Store Type Linking ID' },
  { id: 'Store_Type_Name', numeric: false, disablePadding: false, label: 'Store Type Name' },
  { id: 'Category_Name', numeric: false, disablePadding: false, label: 'Category Name' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allStoretypeslink,
  deleteStoretypelinkHandler,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allStoretypeslinkNoFilter,
  state,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedStoretypelinkIds, setSelectedStoretypelinkIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const handleClose = (value) => {
    setOpen(false);
  };

  /*===============================
             handler     
  =================================*/

  const handleSelectAll = event => {
    let newSelectedStoretypelinkIds;
    if (event.target.checked) {
      newSelectedStoretypelinkIds = allStoretypeslink.map(storetypelink => storetypelink.Store_Type_Linking_Id);
    } else {
      newSelectedStoretypelinkIds = [];
    }
    setSelectedStoretypelinkIds(newSelectedStoretypelinkIds);
  };
  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedStoretypelinkIds.indexOf(id);
    let newselectedStoretypelinkIds = [];
    if (selectedIndex === -1) {
      newselectedStoretypelinkIds = newselectedStoretypelinkIds.concat(selectedStoretypelinkIds, id);
    } else if (selectedIndex === 0) {
      newselectedStoretypelinkIds = newselectedStoretypelinkIds.concat(
        selectedStoretypelinkIds.slice(1));
    } else if (selectedIndex === newselectedStoretypelinkIds.length - 1) {
      newselectedStoretypelinkIds = newselectedStoretypelinkIds.concat(
        selectedStoretypelinkIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newselectedStoretypelinkIds = newselectedStoretypelinkIds.concat(
        selectedStoretypelinkIds.slice(0, selectedIndex),
        selectedStoretypelinkIds.slice(selectedIndex + 1)
      );
    }
    setSelectedStoretypelinkIds(newselectedStoretypelinkIds);
  };

  const deleteSelectedStoretypelinkHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };
  const editStoretypelinkHandler = storetypelinkData => e => {
    navigate('/app/store-type-linking-edit-storetype-linking', { state: { state: state, data: storetypelinkData } });
  };
  const visibleStoretypeHandler = storetypelinkData => e => {
    navigate('/app/storetype-linking-details', { state: { state: state, data: storetypelinkData } });
  };

  /*=================================
              handler end
  =================================*/

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedStoretypelinkIds.filter(record => {
                      for (let i = 0; i < allStoretypeslinkNoFilter.length; i++) {
                        if (record == allStoretypeslinkNoFilter[i].Store_Type_Linking_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allStoretypeslink.length}
                    color="primary"
                    indeterminate={
                      selectedStoretypelinkIds.filter(record => {
                        for (let i = 0; i < allStoretypeslinkNoFilter.length; i++) {
                          if (record == allStoretypeslinkNoFilter[i].Store_Type_Linking_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedStoretypelinkIds.filter(record => {
                        for (let i = 0; i < allStoretypeslinkNoFilter.length; i++) {
                          if (record == allStoretypeslinkNoFilter[i].Store_Type_Linking_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allStoretypeslink.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.id !== "Product_Image" ? (
                      <TableSortLabel className={classes.headerColumn}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>) : (<span style={{ fontSize: "13px", fontWeight: "bold" }} > {headCell.label}</span>)
                    }
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions
                  {selectedStoretypelinkIds.filter(record => {
                    for (let i = 0; i < allStoretypeslinkNoFilter.length; i++) {
                      if (record == allStoretypeslinkNoFilter[i].Store_Type_Linking_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedStoretypelinkIds.filter(record => {
                    for (let i = 0; i < allStoretypeslinkNoFilter.length; i++) {
                      if (record == allStoretypeslinkNoFilter[i].Store_Type_Linking_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedStoretypelinkHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allStoretypeslink) &&
                (allStoretypeslink).map(storetypelink => (
                  <TableRow
                    hover
                    key={storetypelink.Store_Type_Linking_Id}
                    selected={
                      selectedStoretypelinkIds.indexOf(storetypelink.Store_Type_Linking_Id) !== -1} >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedStoretypelinkIds.indexOf(storetypelink.Store_Type_Linking_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, storetypelink.Store_Type_Linking_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell  >{storetypelink.Store_Type_Linking_Id}</TableCell>
                    <TableCell >{storetypelink.Store_Type_Name}</TableCell>
                    <TableCell >{storetypelink.Category_Name}</TableCell>
                    <TableCell >{storetypelink.Status}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Store_Type_Categories_Write?.Value === "Store_Type_Categories_Write" && RolePermissions?.Store_Type_Categories_Write?.Is_Write === 1) ?
                      <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                          onClick={editStoretypelinkHandler(storetypelink)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                          <DeleteIcon
                            onClick={deleteStoretypelinkHandler(storetypelink)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                        <Button
                          onClick={visibleStoretypeHandler(storetypelink)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Store_Type_Categories_Read?.Value === "Store_Type_Categories_Read" && RolePermissions?.Store_Type_Categories_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Button
                          onClick={visibleStoretypeHandler(storetypelink)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allStoretypeslink) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;