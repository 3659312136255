import { Card, CardContent, Divider, Grid, makeStyles, Accordion, Link, AccordionSummary, AccordionDetails } from '@material-ui/core';
import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { DeliveryAgentCurrentAddressAction, DeliveryAgentPermanentAddressAction, DeliveryAgentVehicalDetailsAction, DeliveryAgentWorkAddressAction, DeliveryProfileAction } from 'src/store/actions/deliveryAgentAction';
import isEmpty from '../../../store/validations/is-empty'

const useStyles = makeStyles(() => ({
    images: {
        width: 200,
        height: 200
    },
    content: {
        justifyContent: "center"
    },
}));


const DeliveryAgentsListView = ({ className, ...rest }) => {
    //useLocation returns the location object that contains information about the current URL
    const location = useLocation();
    let { state } = location;
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        const formData = {
            "Address_Type": "Permanent Address",
            "Del_User_Email_Id": state?.Del_user_email_id,
            "Records_Filter": "FILTER"
        }
        dispatch(DeliveryAgentPermanentAddressAction(formData));
        const workformData = {
            "Address_Type": "Working Address",
            "Del_User_Email_Id": state?.Del_user_email_id,
            "Records_Filter": "FILTER"
        }
        dispatch(DeliveryAgentWorkAddressAction(workformData));
        const profileData = {
            "search_by_filter": "",
            "search": "",
            "Del_user_email_id": state?.Del_user_email_id,
            "Records_Filter": "FILTER"
        }
        dispatch(DeliveryProfileAction(profileData));
        const currentformData = {
            "Address_Type": "Current Address",
            "Del_User_Email_Id": state?.Del_user_email_id,
            "Records_Filter": "FILTER"
        }
        dispatch(DeliveryAgentCurrentAddressAction(currentformData));

        const vehicalformData = {
            "search_by_filter": "",
            "search": "",
            "Del_User_Email_Id": state?.Del_user_email_id,
            "Records_Filter": "FILTER"
        }
        dispatch(DeliveryAgentVehicalDetailsAction(vehicalformData));
    }, []);

    let currentAddress = useSelector(state => state.delivery.deliveryagentcurrentaddress.all);
    let permanentAddress = useSelector(state => state.delivery.deliveryagentparmanentaddress.all);
    let workAddress = useSelector(state => state.delivery.deliveryagentworkaddress.all);
    let vehicalsDetails = useSelector(state => state.delivery.deliveryagentvehicals.all);
    let profileDetails = useSelector(state => state.delivery.deliveryagentprofile.all);

    return (
        <Card >
            <CardContent>
                <div style={{ marginTop: '1rem' }}>
                    <Accordion>
                        <div className="vehicalsDetailCard">
                            <AccordionSummary
                                className={classes.content}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"  >
                                {<div style={{ marginTop: "-1%", fontSize: "16px" }}>Profile Details</div>}
                            </AccordionSummary>
                        </div>
                        <Divider />
                        <div >
                            {!isEmpty(profileDetails) ? (profileDetails?.Is_Data_Exist === "0" ? ("No Details Found") : (profileDetails?.map((profileDetails, index) =>
                                <div key={index}>
                                    <AccordionDetails>
                                        <div>
                                            <Grid container spacing={3}>
                                                <Grid item md={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                User Detail ID
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.User_Detail_Id}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Delivery User Email ID
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Del_user_email_id}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Delivery User Type
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Del_User_Type}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Full Name
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Full_name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                First Name
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.First_name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Last Name
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Last_name}
                                                            </Typography>
                                                        </Grid>
                                                        {/* Name */}
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Date Of Birth
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Dob}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Gender
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Gender}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Mobile
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Mobile}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Aadhar
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Aadhar}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Licence
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Licence}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Pancard Number
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Pancard_Number}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Role
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Role}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Is Approved
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.IS_Approved}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Is Active
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Is_active}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Is Blocked By Admin
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.IS_Blocked_By_Admin}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Is Email Verified
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Is_email_verified}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Delivery Person Duty
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Delivery_Person_Duty}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                T and C Agreed
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.T_and_c_aggreed}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Created By
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Created_by}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Created date
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Created_date}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Updated By
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Updated_by}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Updated date
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {profileDetails?.Updated_date}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Profile Pic Path
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                <a target="_blank" href={profileDetails?.Profile_pic_path}>
                                                                    <img
                                                                        className={classes.images}
                                                                        alt="Vehicle Image Path"
                                                                        src={profileDetails?.Profile_pic_path}
                                                                        variant="square"
                                                                    />
                                                                </a>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Pan Card Image
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                <a target="_blank" href={profileDetails?.Pan_Card_Image}>
                                                                    <img
                                                                        className={classes.images}
                                                                        alt="Pan_Card_Image"
                                                                        src={profileDetails?.Pan_Card_Image}
                                                                        variant="square"
                                                                    />
                                                                </a>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Driving Licence Front Image
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                <a target="_blank" href={profileDetails.Driving_Licence_Front_Image}>
                                                                    <img
                                                                        className={classes.images}
                                                                        alt="RC Pic Front Path"
                                                                        src={profileDetails.Driving_Licence_Front_Image}
                                                                        variant="square"
                                                                    />
                                                                </a>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Driving Licence Back Image
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                <a target="_blank" href={profileDetails.Driving_Licence_Back_Image}>
                                                                    <img
                                                                        className={classes.images}
                                                                        alt="RC Pic Back Path"
                                                                        src={profileDetails.Driving_Licence_Back_Image}
                                                                        variant="square"
                                                                    />
                                                                </a>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </AccordionDetails>
                                </div>))) : <div style={{ textAlign: "center", marginBottom: "20px" }}>No Details Found</div>}
                        </div>
                    </Accordion>
                </div>
                <div style={{ marginTop: '1rem' }}>
                    <Accordion>
                        <div className="vehicalsDetailCard">
                            <AccordionSummary
                                className={classes.content}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"  >
                                {<div style={{ marginTop: "-1%", fontSize: "16px" }}>Vehicle Details</div>}
                            </AccordionSummary>
                        </div>
                        <Divider />
                        <div >
                            {!isEmpty(vehicalsDetails) ? (vehicalsDetails.Is_Data_Exist === "0" ? ("No Details Found") : (vehicalsDetails.map((vehicalsDetails, index) =>
                                <div key={index}>
                                    <AccordionDetails>
                                        <div>
                                            <Grid container spacing={3}>
                                                <Grid item md={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Delivery Vehicle Detail ID
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {vehicalsDetails.Del_Vehicle_Detail_Id}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Fuel Type
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {vehicalsDetails.Fuel_Type}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Vehicle Model
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {vehicalsDetails.Model}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                RC Number
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {vehicalsDetails.RC_Number}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Vehicle  Type
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {vehicalsDetails.Type}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Vehicle Color
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {vehicalsDetails.Vehicle_Color}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Vehicle Number
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {vehicalsDetails.Vehicle_Number}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Insurance Number
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {vehicalsDetails.Insurance_Number}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Vehicle Image Path
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                <a target="_blank" href={vehicalsDetails.Vehcle_Image_Path}>
                                                                    <img
                                                                        className={classes.images}
                                                                        alt="Vehicle Image Path"
                                                                        src={vehicalsDetails.Vehcle_Image_Path}
                                                                        variant="square"
                                                                    />
                                                                </a>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                RC Front Path Pic
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                <a target="_blank" href={vehicalsDetails.RC_Pic_Front_Path}>
                                                                    <img
                                                                        className={classes.images}
                                                                        alt="RC Pic Front Path"
                                                                        src={vehicalsDetails.RC_Pic_Front_Path}
                                                                        variant="square"
                                                                    />
                                                                </a>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                RC Back Path Pic
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                <a target="_blank" href={vehicalsDetails.RC_Pic_Back_Path}>
                                                                    <img
                                                                        className={classes.images}
                                                                        alt="RC Pic Back Path"
                                                                        src={vehicalsDetails.RC_Pic_Back_Path}
                                                                        variant="square"
                                                                    />
                                                                </a>
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Insurance File Path
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {/* Link component allows you to easily customize anchor elements with your theme colors and typography styles. */}
                                                                <Link href={vehicalsDetails.Insurance_File_Path} underline="none" target="_blank">
                                                                    <span title={vehicalsDetails.Insurance_File_Path}> {vehicalsDetails.Insurance_File_Path?.length > 29 ?
                                                                        <span>{vehicalsDetails.Insurance_File_Path?.substring(0, 25)}...</span> : vehicalsDetails.Insurance_File_Path}</span>
                                                                </Link>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </AccordionDetails>
                                </div>))) : <div style={{ textAlign: "center", marginBottom: "20px" }}>No Details Found</div>}
                        </div>
                    </Accordion>
                </div>
                <div style={{ marginTop: '1rem' }}>
                    <Accordion>
                        <div className="vehicalsDetailCard">
                            <AccordionSummary
                                className={classes.content}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"  >
                                {<div style={{ marginTop: "-1%", fontSize: "16px" }}>Current Address</div>}
                            </AccordionSummary>
                        </div>
                        <Divider />
                        <div >
                            {!isEmpty(currentAddress) ? (currentAddress.Is_Data_Exist === "0" ? ("No Details Found") : (currentAddress.map((currentAddress, index) =>
                                <div key={index}>
                                    <AccordionDetails>
                                        <div>
                                            <Grid container spacing={3}>
                                                <Grid item md={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Door No
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.Door_No}
                                                            </Typography>
                                                        </Grid>
                                                        {/* Name */}
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Street Name
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.Street_One}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Land Mark
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.Land_Mark}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                City
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.City}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                State
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.State}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Country
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.Country}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Zip Code
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.Zip_Code}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Mobile Number
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.Mobile}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Alternate Number
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {currentAddress.Alternate_Mobile}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </AccordionDetails>
                                </div>))) : <div style={{ textAlign: "center", marginBottom: "20px" }}>No Details Found</div>}
                        </div>
                    </Accordion>
                </div>
                <div style={{ marginTop: '1rem' }}>
                    <Accordion>
                        <div className="vehicalsDetailCard">
                            <AccordionSummary
                                className={classes.content}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"  >
                                {<div style={{ marginTop: "-1%", fontSize: "16px" }}>Permanent Address</div>}
                            </AccordionSummary>
                        </div>
                        <Divider />
                        <div >
                            {!isEmpty(permanentAddress) ? (permanentAddress.Is_Data_Exist === "0" ? ("No Details Found") : (permanentAddress.map((permanentAddress, index) =>
                                <div key={index}>
                                    <AccordionDetails>
                                        <div>
                                            <Grid container spacing={3}>
                                                <Grid item md={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Door No.
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.Door_No}
                                                            </Typography>
                                                        </Grid>
                                                        {/* Name */}
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Street Name
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.Street_One}
                                                            </Typography>
                                                        </Grid>


                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Land Mark
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.Land_Mark}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                City
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.City}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                State
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.State}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Country
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.Country}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Zip Code
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.Zip_Code}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Mobile Number
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.Mobile}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Alternate Number
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {permanentAddress.Alternate_Mobile}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </AccordionDetails>
                                </div>))) : <div style={{ textAlign: "center", marginBottom: "20px" }}>No Details Found</div>}
                        </div>
                    </Accordion>
                </div>
                <div style={{ marginTop: '1rem' }}>
                    <Accordion>
                        <div >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"  >
                                {<div style={{ marginTop: "-1%", fontSize: "16px" }}>Work Address</div>}
                            </AccordionSummary>
                        </div>
                        <Divider />
                        <div >
                            {!isEmpty(workAddress) ? (workAddress.Is_Data_Exist === "0" ? ("No Details Found") : (workAddress.map((workAddress, index) =>
                                <div key={index}>
                                    <AccordionDetails>
                                        <div>
                                            <Grid container spacing={3}>
                                                <Grid item md={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Door No.
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.Door_No}
                                                            </Typography>
                                                        </Grid>
                                                        {/* Name */}
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Street Name
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.Street_One}
                                                            </Typography>
                                                        </Grid>


                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Land Mark
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.Land_Mark}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                City
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.City}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                State
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.State}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Country
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.Country}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Zip Code
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.Zip_Code}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Mobile Number
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.Mobile}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ color: '#66788A' }}
                                                            >
                                                                Alternate Number
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="h5"
                                                                component="h2"
                                                                gutterBottom
                                                                style={{ paddingLeft: '3rem' }}
                                                            >
                                                                {workAddress.Alternate_Mobile}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </AccordionDetails>
                                </div>))) : <div style={{ textAlign: "center", marginBottom: "20px" }}>No Details Found</div>}
                        </div>
                    </Accordion>
                </div>
            </CardContent>
        </Card >
    );
};

export default DeliveryAgentsListView;