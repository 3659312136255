import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
  MenuItem,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { ConnectedFocusError } from 'focus-formik-error';
import CircularProgress from '@mui/material/CircularProgress';
import { paginationPartnermanagementServices, paginationsubcategoryServices } from 'src/store/actions/node-actions/nodepagination';
import Autocomplete from '@mui/material/Autocomplete';
import { EditServicesPartnerManagementAction } from 'src/store/actions/servicespartnermanagementAction';

const StatusOption = [
  {
    value: '',
    label: '-- Please Select --',
  },
  {
    value: 'ACTIVE',
    label: 'ACTIVE',
  },
  {
    value: 'IN-ACTIVE',
    label: 'IN-ACTIVE',
  },
];

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15,
  },
  Partner_Details_Id: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey',
  },
}));

const EditServicePartnerManagement = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  let { state } = location;
  const [subcategories, setSubcategorires] = useState([]);
  const [errorMessage, setErrorMessage] = useState({
    Ss_Id: { key: '', message: '' },
    global: { key: '', message: '' },
  });
  const isSubmitted = useSelector((state) => state.category.categoryUpdate.isLoading);
  const modifiedBy = useSelector((state) => state.auth.user.Email_Id);
  const [partnerservice, setpartnerservice] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [hasMore1, setHasMore1] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);

  useEffect(() => {
    const fetchSubcategoryServices = async () => {
      try {
        setLoading1(true);
        await dispatch(
          paginationsubcategoryServices(
            {},
            { PageNo: currentPage1, PageSize: 20 },
            (status, data, pagination1) => {
              if (status) {
                if (Array.isArray(data)) {
                  setSubcategorires((prev) => [...prev, ...data]);
                  setHasMore1(currentPage1 < pagination1?.TotalPages);
                }
              }
            }
          )
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading1(false);
      }
    };

    fetchSubcategoryServices();
  }, [currentPage1, dispatch]);

  const handleScroll1 = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore1 && !loading1) {
      setCurrentPage1((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const fetchPartnermangementServices = async () => {
      try {
        setLoading(true);
        await dispatch(
          paginationPartnermanagementServices(
            {},
            { PageNo: currentPage, PageSize: 20 },
            (status, data, pagination) => {
              if (status) {
                if (Array.isArray(data)) {
                  setpartnerservice((prev) => [...prev, ...data]);
                  setHasMore(currentPage < pagination?.TotalPages);
                }
              }
            }
          )
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPartnermangementServices();
  }, [currentPage, dispatch]);

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <Formik
      initialValues={{
        Pss_Id: state.Pss_Id ? state.Pss_Id : '',
        Ss_Id: state.Ss_Id ? state.Ss_Id : '',
        Partner_Details_Id: state.Partner_Details_Id ? state.Partner_Details_Id : '',
        Status: state.Status ? state.Status : '',
      }}
      validationSchema={Yup.object().shape({
        Ss_Id: Yup.string()
          .min(3, 'Sub service name must be at least 3 characters.')
          .notOneOf([errorMessage.Ss_Id.key, null], errorMessage.Ss_Id.message)
          .required('Sub service name is required.'),
        Status: Yup.string().required('Status is required.'),
        Partner_Details_Id: Yup.string()
          .min(10, 'Partner Details Id must be at least 10 characters.')
          .required('Partner Details Id is required.'),
      })}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        const errors = {};
        setErrors(errors);

        dispatch(EditServicesPartnerManagementAction(values, navigate));
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        options={partnerservice || []}
                        getOptionLabel={(option) => option.Name || ''}
                        isOptionEqualToValue={(option, value) => option.Partner_Details_Id === value?.Partner_Details_Id}
                        onChange={(event, newValue) => {
                          setFieldValue('Partner_Details_Id', newValue?.Partner_Details_Id || '');
                        }}
                        value={partnerservice.find((option) => option.Partner_Details_Id === values.Partner_Details_Id) || null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Partner Name"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            onBlur={handleBlur}
                            error={Boolean(errors.Partner_Details_Id && touched.Partner_Details_Id)}
                            helperText={touched.Partner_Details_Id && errors.Partner_Details_Id}
                          />
                        )}
                        ListboxProps={{
                          style: {
                            maxHeight: 250,
                            overflow: 'auto',
                          },
                          onScroll: handleScroll,
                        }}
                      />
                    </Grid>

                    {/* Subservice Name */}
                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        options={subcategories || []}
                        getOptionLabel={(option) => option.Ss_Name || ''}
                        isOptionEqualToValue={(option, value) => option.Ss_Id === value?.Ss_Id}
                        onChange={(event, newValue) => {
                          setFieldValue('Ss_Id', newValue?.Ss_Id || '');
                        }}
                        onBlur={handleBlur}
                        value={subcategories.find((option) => option.Ss_Id === values.Ss_Id) || null}
                        loading={loading1}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(touched.Ss_Id && errors.Ss_Id)}
                            helperText={touched.Ss_Id && errors.Ss_Id}
                            fullWidth
                            label="Sub Service Name"
                            variant="outlined"
                            margin="normal"
                            sx={{ width: '100%' }}
                          />
                        )}
                        ListboxProps={{
                          style: { maxHeight: 250, overflow: 'auto' },
                          onScroll: handleScroll1,
                        }}
                        renderOption={(props, option) => (
                          <MenuItem {...props} key={option.Ss_Id} value={option.Ss_Id}>
                            {option.Ss_Name}
                          </MenuItem>
                        )}
                      />
                    </Grid>

                    {/* Status */}
                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        options={StatusOption || []}
                        getOptionLabel={(option) => option.label || ''}
                        onBlur={handleBlur}
                        onChange={(event, newValue) => {
                          setFieldValue('Status', newValue?.value || '');
                        }}
                        value={StatusOption.find((option) => option.value === values.Status) || null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status"
                            variant="outlined"
                            fullWidth
                            helperText={touched.Status && errors.Status}
                            error={Boolean(touched.Status && errors.Status)}
                            margin="normal"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3} justifyContent="flex-end">
                <Grid item>
                  <Button variant="contained" color="primary" type="submit" disabled={isSubmitted}>
                    {isSubmitted ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditServicePartnerManagement.propTypes = {
  className: PropTypes.string,
};

export default EditServicePartnerManagement;
