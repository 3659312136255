import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
  Avatar
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';
import DeleteIcon from '@material-ui/icons/Delete';
import { deletevarientActions } from 'src/store/actions/servicesvarientAction';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  images: {
    width: 70,
    height: 70,
    cursor: 'pointer'
  },
  headerColumn: {
    fontSize: '12px',
    marginRight: '-16px',
    fontWeight: 'bold'
  }
}));

const headCells = [
  { id: 'Sv_Id', numeric: false, disablePadding: false, label: 'Id' },
  { id: 'SV_Name', numeric: false, disablePadding: false, label: 'Name' },

  {
    id: 'SS_Name',
    numeric: true,
    disablePadding: false,
    label: 'Sub Service Name'
  },
  {
    id: 'Service_Name',
    numeric: true,
    disablePadding: false,
    label: 'Service Name'
  },
  {
    id: 'Image',
    numeric: true,
    disablePadding: false,
    label: 'Image'
  },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' }
];

const Results = ({
  className,
  allnewService,
  deleteServiceHandler,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  setPage,
  allnewServicesNoFilter,
  createSortHandler,
  RolePermissions,
  Delete,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedServicesIds, setSelectedServicesIds] = useState([]);
  const dispatch = useDispatch();

  const addSubServiceHandeler = serviceData => e => {
    navigate('/app/servicescity-management', { state: serviceData });
  };

  const visibleServicesHandler = serviceData => e => {
    navigate('/app/view-varientservice', { state: serviceData });
  };
  const editServicesHandler = serviceData => e => {
    navigate('/app/edit-servicevarient', { state: serviceData });
  };


  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map(headCell => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      style={{ fontSize: '13px', fontWeight: 'bold' }}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell
                  className={classes.headerColumn}
                  style={{
                    display: 'table-cell',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '-30px',
                    textAlign: 'center'
                  }}
                >
                  {' '}
                  Actions &nbsp;&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allnewService) &&
                allnewService.map(service => (
                  <TableRow
                    hover
                    key={service.Sv_Id}
                    selected={selectedServicesIds.indexOf(service.Sv_Id) !== -1}
                  >
                    <TableCell>{service.Sv_Id}</TableCell>
                    <TableCell style={{ color: '#1665D8' }}>
                      {service.Sv_Name?.substring(0, 18)}...
                    </TableCell>
                    <TableCell>{service.Ss_Name}</TableCell>
                    <TableCell>{service.Service_Name}</TableCell>
                    <TableCell>
                      <Avatar
                        className={classes.images}
                        alt="Brand"
                        src={service.Image}
                        variant="circular"
                      />
                    </TableCell>
                    <TableCell>{service.Status}</TableCell>

                    {roleUserType.User_Type === 'ADMIN' ||
                    roleUserType.User_Type === 'SUPER_ADMIN' ||
                    (RolePermissions?.User_Services_Management_Write?.Value ===
                      'User_Services_Management_Write' &&
                      RolePermissions?.User_Services_Management_Write
                        ?.Is_Write === 1) ? (
                      <TableCell>
                        <Box display="flex" alignItems="center">
                        <Tooltip title="Add City Services">
                          <Button
                            onClick={addSubServiceHandeler(service)}
                            color="primary"
                          >
                            <AddIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        
                        <Tooltip title="View variant History">
                          <Button
                            onClick={visibleServicesHandler(service)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        
                        <Button
                          // disabled={(service.Gst === "SUCCESS" && service.Service_Id === "COD") ? true : false}
                          onClick={editServicesHandler(service)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                         

                          <DeleteIcon
                            onClick={() => Delete(service.Sv_Id)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                        </Box>
                      </TableCell>
                    ) : null}
                    {RolePermissions?.User_Services_Management_Read?.Value ===
                      'User_Services_Management_Read' &&
                      RolePermissions?.User_Services_Management_Read
                        ?.Is_Read === 1 && (
                        <TableCell>
                          <Tooltip title="View Service History">
                            <Button
                              onClick={visibleServicesHandler(service)}
                              color="primary"
                            >
                              <VisibilityIcon style={{ color: '#9EA0A5' }} />
                            </Button>
                          </Tooltip>
                        </TableCell>
                      )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allnewService) && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '20px',
                fontSize: 20
              }}
            >
              No records found!
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '2px'
              }}
            >
              <img height="400" src="/static/nodata.gif" />
            </div>
          </>
        )}
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allnewService: PropTypes.array.isRequired
};

export default Results;
