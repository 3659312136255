import { nodeGetApi } from './commonAxios';

export const CONTENTDATEANDCOUNT_REQUEST = 'CONTENTDATEANDCOUNT_REQUEST';
export const CONTENTDATEANDCOUNT_SUCCESS = "CONTENTDATEANDCOUNT_SUCCESS";
export const CONTENTDATEANDCOUNT_FAIL = "CONTENTDATEANDCOUNT_FAIL";

export const contentDateandCount = () => async dispatch => {
  try {
    dispatch({
      type: CONTENTDATEANDCOUNT_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Total_Count_And_Date/Contenet_Management`);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CONTENTDATEANDCOUNT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CONTENTDATEANDCOUNT_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CONTENTDATEANDCOUNT_FAIL,
      payload: err
    });
  }
};