import { toast } from 'react-toastify';
import { boomiAdminApi, nodeCudApi, nodeGetApi } from './commonAxios';
import config from './config';
import { paginationCategories } from './node-actions/nodepagination';

export const CATEGORY_CREATE_REQUEST = "CATEGORY_CREATE_REQUEST";
export const CATEGORY_CREATE_SUCCESS = "CATEGORY_CREATE_SUCCESS";
export const CATEGORY_CREATE_FAIL = "CATEGORY_CREATE_FAIL";
export const CATEGORY_UPDATE_REQUEST = "CATEGORY_UPDATE_REQUEST";
export const CATEGORY_UPDATE_SUCCESS = "CATEGORY_UPDATE_SUCCESS";
export const CATEGORY_UPDATE_FAIL = "CATEGORY_UPDATE_FAIL";

export const STORETYPE_GET_REQUEST = "STORETYPE_GET_REQUEST";
export const STORETYPE_GET_SUCCESS = "STORETYPE_GET_SUCCESS";
export const STORETYPE_GET_FAIL = "STORETYPE_GET_FAIL";

/*=============================================================
                  Add Category Action
===============================================================*/
export const addCategoryAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: CATEGORY_CREATE_REQUEST
  });
  setErrorMessage({ Category_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Categories/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: CATEGORY_CREATE_SUCCESS
        });
        toast('Category added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/category-management');
      } else {
        const errors = {};
        if (successResponse.Category_Name) {
          errors.Category_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Category_Name: { key: successResponse.Category_Name ? successResponse.Category_Name : '', message: successResponse.Category_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Category_Name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: CATEGORY_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Category_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: CATEGORY_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};

/*=============================================================
                  Delete Category Action
===============================================================*/
export const deleteCategory = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Categories/Delete`, formData);
    if (data) {
      toast('Category deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationCategories(filters, pagination, callBackPagination));
    }
  } catch (err) { }
};

/*=============================================================
                Update Category Action
===============================================================*/
export const updateCategory = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: CATEGORY_UPDATE_REQUEST
  });
  setErrorMessage({ Category_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Categories/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: CATEGORY_UPDATE_SUCCESS
        });
        toast('Category updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/category-management');
      } else {
        const errors = {};
        if (successResponse.Category_Name) {
          errors.Category_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Category_Name: { key: successResponse.Category_Name ? successResponse.Category_Name : '', message: successResponse.Category_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Category_Name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: CATEGORY_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Category_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: CATEGORY_UPDATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};

/*=============================================================
              UploadCategoryImage Action
===============================================================*/
export const uploadCategoryImage = (
  formData,
  callBackUploadImgae,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=Categories&fileextension=png&filetype=Images&filename=CategoryWeb`, formData);
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};
/*=============================================================
              uploadmobileimage Action
===============================================================*/

export const uploadmobileimage = (
  formData,
  callBackUploadImgae1,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=Categories&fileextension=png&filetype=Images&filename=CategoryMobile`, formData);
    if (data) {
      callBackUploadImgae1(data);
    }
  } catch (err) { }
};


//node API
export const getAllStoretypes = () => async dispatch => {
  try {
    let formData = {
      search_by_filter: "",
      search: "",
      Records_Filter: "FILTER",
      Status: "Active"
    };
    dispatch({
      type: STORETYPE_GET_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Store_Type/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=STORE_TYPE_NAME&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: STORETYPE_GET_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: STORETYPE_GET_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again", 3000);
    dispatch({
      type: STORETYPE_GET_FAIL,
      payload: err
    });
  }
};