import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useSelector } from 'react-redux';
import Page from 'src/components/Page';
import { useLocation } from 'react-router-dom';
import AddCityProduct from './AddCityProduct';
import isEmpty from 'src/store/validations/is-empty';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AddCityProductView = () => {
  const location = useLocation();
  const { state } = location;
  const classes = useStyles();
  const [allcitypartnerproduct, setCitypartnerproduct] = useState([]);

  let citypartnerproducts = useSelector(state => state.sbcmProduct.allcitypartnerproduct);

  useEffect(() => {
    if (!isEmpty(citypartnerproducts)) {
      setCitypartnerproduct(citypartnerproducts.sbcmProduct);
    }
  }, [citypartnerproducts]);

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/sbcm/product-management"
          variant="text"
          size="small"
          color="inherit"
          state={state}
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Add City Product
          </Typography>
        </Box>
        <AddCityProduct allcitypartnerproduct={allcitypartnerproduct} state={state} />
      </Container>
    </Page>);
};

export default AddCityProductView;