import {
    WALLET_PARTNER_HISTORY_REQUEST,
    WALLET_PARTNER_HISTORY_SUCCESS,
    WALLET_PARTNER_HISTORY_FAIL,
    WALLET_PARTNER_HISTORY_GET_REQUEST,
    WALLET_PARTNER_HISTORY_GET_FAIL,
    WALLET_PARTNER_HISTORY_GET_SUCCESS,
    WALLET_HISTORY_PARTNER_CREATE_REQUEST,
    WALLET_HISTORY_PARTNER_CREATE_SUCCESS,
    WALLET_HISTORY_PARTNER_CREATE_FAIL,
    WALLET_HISTORY_PARTNER_UPDATE_REQUEST,
    WALLET_HISTORY_PARTNER_UPDATE_SUCCESS,
    WALLET_HISTORY_PARTNER_UPDATE_FAIL,
} from '../actions/partnerwallethistoryAction';

const initialState = {
    partnerwallethistories: { all: [], error: '', isLoading: false },
    partnerwallethistory: { partnerwallethistory: {}, error: '', isLoading: false },
    partnerwalletHistoryCreate: { partnerwallethistory: {}, error: '', isLoading: false },
    partnerwalletHistoryUpdate: { partnerwallethistory: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case WALLET_PARTNER_HISTORY_REQUEST:
            return { ...state, partnerwallethistories: { all: state.partnerwallethistories.all, response: state.partnerwallethistories.all, error: '', isLoading: true } };
        case WALLET_PARTNER_HISTORY_SUCCESS:
            return { ...state, partnerwallethistories: { all: action.payload, response: action.response, error: '', isLoading: false } };
        case WALLET_PARTNER_HISTORY_FAIL:
            return { ...state, partnerwallethistories: { all: [], error: action.payload, isLoading: false } };
        case WALLET_PARTNER_HISTORY_GET_REQUEST:
            return { ...state, partnerwallethistory: { partnerwallethistory: {}, error: '', isLoading: true } };
        case WALLET_PARTNER_HISTORY_GET_SUCCESS:
            return { ...state, partnerwallethistory: { partnerwallethistory: action.payload, error: '', isLoading: false } };
        case WALLET_PARTNER_HISTORY_GET_FAIL:
            return { ...state, partnerwallethistory: { partnerwallethistory: {}, error: action.payload, isLoading: false } };
        case WALLET_HISTORY_PARTNER_CREATE_REQUEST:
            return { ...state, partnerwalletHistoryCreate: { partnerwallethistory: {}, error: '', isLoading: true } };
        case WALLET_HISTORY_PARTNER_CREATE_SUCCESS:
            return { ...state, partnerwalletHistoryCreate: { partnerwallethistory: state.partnerwalletHistoryCreate.partnerwallet, error: '', isLoading: false } };
        case WALLET_HISTORY_PARTNER_CREATE_FAIL:
            return { ...state, partnerwalletHistoryCreate: { partnerwallethistory: {}, error: action.payload, isLoading: false } };
        case WALLET_HISTORY_PARTNER_UPDATE_REQUEST:
            return { ...state, partnerwalletHistoryUpdate: { partnerwallethistory: {}, error: '', isLoading: true } };
        case WALLET_HISTORY_PARTNER_UPDATE_SUCCESS:
            return { ...state, partnerwalletHistoryUpdate: { partnerwallethistory: state.partnerwalletHistoryUpdate.partnerwallethistory, error: '', isLoading: false } };
        case WALLET_HISTORY_PARTNER_UPDATE_FAIL:
            return { ...state, partnerwalletHistoryUpdate: { partnerwallethistory: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}