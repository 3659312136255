import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import SimpleDialog from '.././../../../components/dialogs/dialog'
import DeleteIcon from '@material-ui/icons/Delete';
import isEmpty from '../../../../store/validations/is-empty';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginLeft: "26px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Related_Prod_Id', numeric: false, disablePadding: false, label: 'Related Product ID' },
  { id: 'Related_Product_Of_Product_Id', numeric: false, disablePadding: true, label: 'Product ID' },
  { id: 'Created_Date', numeric: false, disablePadding: false, label: 'Created Date' },
];

const Results = ({
  className,
  relatedproducts,
  order,
  orderBy,
  handleLimitChange,
  handlePageChange,
  pagination,
  setPagination,
  createSortHandler,
  deleteProductMasterHandler,
  allProductMastersNoFilter,
  state,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedProductIds;
    if (event.target.checked) {
      newSelectedProductIds = relatedproducts.map(relatedproducts => relatedproducts.Related_Prod_Id);
    }
    else {
      newSelectedProductIds = [];
    }
    setSelectedProductIds(newSelectedProductIds);
  };

  const handleSelectOne = (event, Id) => {
    const selectedIndex = selectedProductIds.indexOf(Id);
    let newselectedProductIds = [];
    if (selectedIndex === -1) {
      newselectedProductIds = newselectedProductIds.concat(selectedProductIds, Id);
    } else if (selectedIndex === 0) {
      newselectedProductIds = newselectedProductIds.concat(
        selectedProductIds.slice(1)
      );
    } else if (selectedIndex === selectedProductIds.length - 1) {
      newselectedProductIds = newselectedProductIds.concat(
        selectedProductIds.slice(0, -1)
      );
    }
    else if (selectedIndex > 0) {
      newselectedProductIds = newselectedProductIds.concat(
        selectedProductIds.slice(0, selectedIndex),
        selectedProductIds.slice(selectedIndex + 1)
      );
    }
    setSelectedProductIds(newselectedProductIds);
  };

  const deleteSelectedProductMasterHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };
  const editRelatedProductHandler = productmasterData => e => {
    navigate('/app/edit-relatedproducts', { state: { state: state, data: productmasterData } });
  };

  return (
    <Card className={clsx(classes.root, className)}
      {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ paddingLeft: "15px" }} padding="checkbox">
                  <Checkbox
                    checked={selectedProductIds.filter(record => {
                      for (let i = 0; i < allProductMastersNoFilter.length; i++) {
                        if (record == allProductMastersNoFilter[i].Related_Prod_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === relatedproducts.length}
                    color="primary"
                    indeterminate={
                      selectedProductIds.filter(record => {
                        for (let i = 0; i < allProductMastersNoFilter.length; i++) {
                          if (record == allProductMastersNoFilter[i].Related_Prod_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedProductIds.filter(record => {
                        for (let i = 0; i < allProductMastersNoFilter.length; i++) {
                          if (record == allProductMastersNoFilter[i].Related_Prod_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < relatedproducts.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions &nbsp; &nbsp;
                  {selectedProductIds.filter(record => {
                    for (let i = 0; i < allProductMastersNoFilter.length; i++) {
                      if (record == allProductMastersNoFilter[i].Related_Prod_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 &&
                    <Button disabled={selectedProductIds.filter(record => {
                      for (let i = 0; i < allProductMastersNoFilter.length; i++) {
                        if (record == allProductMastersNoFilter[i].Related_Prod_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length <= 0}
                      onClick={deleteSelectedProductMasterHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(relatedproducts) && (relatedproducts).map(relatedproducts => (
                <TableRow
                  hover
                  key={relatedproducts.Related_Prod_Id}
                  selected={
                    selectedProductIds.indexOf(relatedproducts.Related_Prod_Id) !== -1
                  }
                >
                  <TableCell style={{ paddingLeft: "15px" }} padding="checkbox">
                    <Checkbox
                      checked={
                        selectedProductIds.indexOf(relatedproducts.Related_Prod_Id) !== -1
                      }
                      onChange={event =>
                        handleSelectOne(event, relatedproducts.Related_Prod_Id)
                      }
                      value="true"
                    />
                  </TableCell>
                  <TableCell style={{ paddingLeft: "40px" }}>{relatedproducts.Related_Prod_Id}</TableCell>
                  <TableCell style={{ paddingLeft: "40px" }}>{relatedproducts.Related_Product_Of_Product_Id}</TableCell>
                  <TableCell style={{ paddingLeft: "40px" }}>{relatedproducts.Created_Date} </TableCell>
                  {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                    (RolePermissions?.Related_Products_Write?.Value === "Related_Products_Write" && RolePermissions?.Related_Products_Write?.Is_Write === 1) ?
                    <TableCell style={{ textAlign: "center", }}>
                      <Button
                        onClick={editRelatedProductHandler(relatedproducts)}
                      >
                        <CreateIcon style={{ color: '#9EA0A5' }} />
                      </Button>
                      <Button>&nbsp;&nbsp;&nbsp;
                        <DeleteIcon
                          onClick={deleteProductMasterHandler(relatedproducts)}
                          style={{ color: '#9EA0A5', }}
                        />
                      </Button>
                    </TableCell>
                    : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(relatedproducts) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  relatedproducts: PropTypes.array.isRequired
};

export default Results;