import { v4 as uuid } from 'uuid';

export default [
    {
        id: uuid(),
        hide: 0,
        link: '/app/b2b-user-products-bidding',
        createdAt: '',
        description: 'B2B Bidding is an offer to set a price tag by an individual or business for a product that something be done. Bidding is used to determine the cost or value of something.',
        media: '/static/Dashboard/bidding.png',
        title: 'B2B User Products Biddings',
        total: ''
    }
];