import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateTransactionAction,
} from './../../store/actions/transactionhistoryAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const TranscationStatus = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Processing',
    label: 'Processing'
  },
  {
    value: 'Hold',
    label: 'Hold'
  },
  {
    value: 'Pending',
    label: 'Pending'
  },
  {
    value: 'Success',
    label: 'Success'
  },
  {
    value: 'Failed',
    label: 'Failed'
  }
];

const EditTransaction = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = location;

  const errorMessage = useSelector(state => state.transaction.transactionUpdate.error);
  const isSubmited = useSelector(state => state.transaction.transactionUpdate.isLoading);
  const createdBy = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        Modified_By: createdBy,
        Transaction_Id: state.Transaction_Id ? state.Transaction_Id : "",
        Transaction_Status: state.Transaction_Status ? state.Transaction_Status : "",
        Order_Id: state.Order_Id ? state.Order_Id : "",
        Order_Amount: state.Order_Amount ? state.Order_Amount : "",
        Payment_Method: state.Payment_Method ? state.Payment_Method : "",
        Payment_Mode: state.Payment_Mode ? state.Payment_Mode : "",
        Payment_Retry: state.Payment_Retry ? state.Payment_Retry : "",
        Transaction_Date: state.Transaction_Date ? state.Transaction_Date : "",
        Wallet_Amount: state.Wallet_Amount ? state.Wallet_Amount : "",
        Gift_Amount: state.Gift_Amount ? state.Gift_Amount : "",
        Razorpay_Amount: state.Razorpay_Amount ? state.Razorpay_Amount : "",
        Order_Total_Before_Discount: state.Order_Total_Before_Discount ? state.Order_Total_Before_Discount : "",
        Order_Discount: state.Order_Discount ? state.Order_Discount : "",
        Meepaisa_Coupon_Code: state.Meepaisa_Coupon_Code ? state.Meepaisa_Coupon_Code : "",
        Meepaisa_Coupon_Amount: state.Meepaisa_Coupon_Amount ? state.Meepaisa_Coupon_Amount : "",
        Meepaisa_Coupon_Percentage: state.Meepaisa_Coupon_Percentage ? state.Meepaisa_Coupon_Percentage : "",
        Corp_Coupon_Code: state.Corp_Coupon_Code ? state.Corp_Coupon_Code : "",
        Corp_Coupon_Amount: state.Corp_Coupon_Amount ? state.Corp_Coupon_Amount : "",
        Corp_Coupon_Percentage: state.Corp_Coupon_Percentage ? state.Corp_Coupon_Percentage : "",
        Payment_Gateway_Reference_Number: state.Payment_Gateway_Reference_Number ? state.Payment_Gateway_Reference_Number : "",
        Payment_Gateway_Response_Message: state.Payment_Gateway_Response_Message ? state.Payment_Gateway_Response_Message : "",
      }}
      validationSchema={
        Yup.object().shape({
          Transaction_Status: Yup.string().required('Transaction Status  is required.'),
        })
      }
      onSubmit={(values) => {
        if (values) {
          let formData = JSON.parse(JSON.stringify(values));
          dispatch(updateTransactionAction(formData, navigate));
        }
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Transaction ID"
                        name="Transaction_Id"
                        value={values.Transaction_Id}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Order ID"
                        name="Order_Id"
                        value={values.Order_Id}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Order Amount"
                        name="Order_Amount"
                        value={values.Order_Amount}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Order Discount"
                        name="Order_Discount"
                        value={values.Order_Discount}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Order Total Before Discount"
                        name="Order_Total_Before_Discount"
                        value={values.Order_Total_Before_Discount}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Payment Method"
                        name="Payment_Method"
                        value={values.Payment_Method}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Payment Mode"
                        name="Payment_Mode"
                        value={values.Payment_Mode}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Payment Retry"
                        name="Payment_Retry"
                        value={values.Payment_Retry}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Wallet Amount"
                        name="Wallet_Amount"
                        value={values.Wallet_Amount}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Razorpay Amount"
                        name="Razorpay_Amount"
                        value={values.Razorpay_Amount}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Gift Amount"
                        name="Gift_Amount"
                        value={values.Gift_Amount}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Meepaisa Coupon Amount"
                        name="Meepaisa_Coupon_Amount"
                        value={values.Meepaisa_Coupon_Amount}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Meepaisa Coupon Percentage"
                        name="Meepaisa_Coupon_Percentage"
                        value={values.Meepaisa_Coupon_Percentage}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Meepaisa Coupon Code"
                        name="Meepaisa_Coupon_Code"
                        value={values.Meepaisa_Coupon_Code}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Corp Coupon Code"
                        name="Corp_Coupon_Code"
                        value={values.Corp_Coupon_Code}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Corp Coupon Percentage"
                        name="Corp_Coupon_Percentage"
                        value={values.Corp_Coupon_Percentage}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Corp Coupon Amount"
                        name="Corp_Coupon_Amount"
                        value={values.Corp_Coupon_Amount}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Payment Gateway Response Message"
                        name="Payment_Gateway_Response_Message"
                        value={values.Payment_Gateway_Response_Message}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Payment Gateway Reference Number"
                        name="Payment_Gateway_Reference_Number"
                        value={values.Payment_Gateway_Reference_Number}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Transaction Date"
                        name="Transaction_Date"
                        value={values.Transaction_Date}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Transaction_Status && errors.Transaction_Status)}
                        fullWidth
                        helperText={touched.Transaction_Status && errors.Transaction_Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label=" Transaction Status"
                        name="Transaction_Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Transaction_Status}
                        variant="outlined"
                      >
                        {TranscationStatus.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>

  );
};

EditTransaction.propTypes = {
  className: PropTypes.string
};

export default EditTransaction;