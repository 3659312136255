import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const BIDDING_PARTNER_SERVICE_QUOTE_COMMENT_UPDATE_REQUEST = "BIDDING_PARTNER_SERVICE_QUOTE_COMMENT_UPDATE_REQUEST";
export const BIDDING_PARTNER_SERVICE_QUOTE_COMMENT_UPDATE_SUCCESS = "BIDDING_PARTNER_SERVICE_QUOTE_COMMENT_UPDATE_SUCCESS";
export const BIDDING_PARTNER_SERVICE_QUOTE_COMMENT_UPDATE_FAIL = "BIDDING_PARTNER_SERVICE_QUOTE_COMMENT_UPDATE_FAIL";

// BiddingServiceQuoteComment reject Action
export const BiddingServiceQuoteCommentAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: BIDDING_PARTNER_SERVICE_QUOTE_COMMENT_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Bidding_Services_Comments/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BIDDING_PARTNER_SERVICE_QUOTE_COMMENT_UPDATE_SUCCESS
        });
        toast('Bidding Service updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partner-bidding-service-quote-comments', state = { state });
      } else {
        dispatch({
          type: BIDDING_PARTNER_SERVICE_QUOTE_COMMENT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: BIDDING_PARTNER_SERVICE_QUOTE_COMMENT_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

//Get ALL Bidding Services Quote Comments
export const getBiddingServicesQuoteCommentsAction = (formData, callBackBiddingServicesQuoteComment) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Super_Admin/Bidding_Service_Comments`,
      formData
    );
    if (data) {
      callBackBiddingServicesQuoteComment(data)
    }
  } catch (err) {
  }
};