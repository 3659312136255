import config from './config';
import { toast } from 'react-toastify';

import { paginationBanner } from './node-actions/nodepagination';
import { boomiAdminApi, nodeCudApi, nodeGetApi } from './commonAxios';

export const BANNER_CREATE_REQUEST = "BANNER_CREATE_REQUEST";
export const BANNER_CREATE_SUCCESS = "BANNER_CREATE_SUCCESS";
export const BANNER_CREATE_FAIL = "BANNER_CREATE_FAIL";

export const BANNER_UPDATE_REQUEST = "BANNER_UPDATE_REQUEST";
export const BANNER_UPDATE_SUCCESS = "BANNER_UPDATE_SUCCESS";
export const BANNER_UPDATE_FAIL = "BANNER_UPDATE_FAIL";

export const BANNER_PARTNER_REQUEST = "BANNER_PARTNER_REQUEST";
export const BANNER_PARTNER_SUCCESS = "BANNER_PARTNER_SUCCESS";
export const BANNER_PARTNER_FAIL = "BANNER_PARTNER_FAIL";

export const BANNER_DEAL_REQUEST = "BANNER_DEAL_REQUEST";
export const BANNER_DEAL_SUCCESS = "BANNER_DEAL_SUCCESS";
export const BANNER_DEAL_FAIL = "BANNER_DEAL_FAIL";

export const BANNER_COUPON_REQUEST = "BANNER_COUPON_REQUEST";
export const BANNER_COUPON_SUCCESS = "BANNER_COUPON_SUCCESS";
export const BANNER_COUPON_FAIL = "BANNER_COUPON_FAIL";

export const BANNER_PRODUCT_REQUEST = "BANNER_PRODUCT_REQUEST";
export const BANNER_PRODUCT_SUCCESS = "BANNER_PRODUCT_SUCCESS";
export const BANNER_PRODUCT_FAIL = "BANNER_PRODUCT_FAIL";

export const BANNER_PRODUCT_ID_REQUEST = "BANNER_PRODUCT_ID_REQUEST";
export const BANNER_PRODUCT_ID_SUCCESS = "BANNER_PRODUCT_ID_SUCCESS";
export const BANNER_PRODUCT_ID_FAIL = "BANNER_PRODUCT_ID_FAIL";

export const BANNER_ALLPARTNERS_REQUEST = "BANNER_ALLPARTNERS_REQUEST";
export const BANNER_ALLPARTNERS_SUCCESS = "BANNER_ALLPARTNERS_SUCCESS";
export const BANNER_ALLPARTNERS_FAIL = "BANNER_ALLPARTNERS_FAIL";

export const BANNER_CITY_REQUEST = "BANNER_CITY_REQUEST";
export const BANNER_CITY_SUCCESS = "BANNER_CITY_SUCCESS";
export const BANNER_CITY_FAIL = "BANNER_CITY_FAIL";

export const BANNER_STORE_TYPE_REQUEST = "BANNER_STORE_TYPE_REQUEST";
export const BANNER_STORE_TYPE_SUCCESS = "BANNER_STORE_TYPE_SUCCESS";
export const BANNER_STORE_TYPE_FAIL = "BANNER_STORE_TYPE_FAIL";

/*=============================================================
                  Add Banner Action
===============================================================*/
export const addBannerAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: BANNER_CREATE_REQUEST
  });
  setErrorMessage({ Banner_Title: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Banner/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BANNER_CREATE_SUCCESS
        });
        toast('Banner added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/banner-management');
      } else {
        const errors = {};
        if (successResponse.Banner_Title) {
          errors.Banner_Title = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Banner_Title: { key: successResponse.Banner_Title ? successResponse.Banner_Title : '', message: successResponse.Banner_Title ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Banner_Title) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: BANNER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Banner_Title: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: BANNER_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working."
      });
    })
};

/*=============================================================
                Banner Image Upload Action
===============================================================*/
export const uploadBannerImage = (
  formData,
  callBackUploadImgae
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=Banner&fileextension=png&filetype=Images&filename=Banner`, formData);
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};

/*=============================================================
                  Delete Banner BY ID Action
===============================================================*/
export const deleteBannerById = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Banner/Delete`, formData);
    if (data) {
      toast('Banner deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationBanner(filters, pagination, callBackPagination));
    }
  } catch (err) { }
};

/*=============================================================
                    Update Banner Action
===============================================================*/
export const updateBannerAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: BANNER_UPDATE_REQUEST
  });
  setErrorMessage({ Banner_Title: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Banner/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BANNER_UPDATE_SUCCESS
        });
        toast('Banner updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/banner-management');
      } else {
        const errors = {};
        if (successResponse.Banner_Title) {
          errors.Banner_Title = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Banner_Title: { key: successResponse.Banner_Title ? successResponse.Banner_Title : '', message: successResponse.Banner_Title ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Banner_Title) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: BANNER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Banner_Title: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: BANNER_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working."
      });
    })
};
/*=============================================================
                   getofferbyid Action
===============================================================*/
export const getofferbyid = () => async dispatch => {
  try {
    let formData = {
      search_by_filter: "All",
      search: "",
      Records_Filter: 'FILTER',
      Status: "Active"
    };
    dispatch({
      type: BANNER_PARTNER_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Partners/OnlineAndPhysical_Details_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BANNER_PARTNER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BANNER_PARTNER_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: BANNER_PARTNER_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                   getdealbyid Action
===============================================================*/
export const getdealbyid = () => async dispatch => {
  try {
    let formData = {
      search: '',
      search_by_filter: 'All',
      Records_Filter: "FILTER",
      Is_Approved: "1"
    };
    dispatch({
      type: BANNER_DEAL_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Deals/Deal_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=DEAL_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BANNER_DEAL_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BANNER_DEAL_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: BANNER_DEAL_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                   getcouponbyid Action
===============================================================*/
export const getcouponbyid = () => async dispatch => {
  try {
    let formData = {
      search: '',
      search_by_filter: 'All',
      Records_Filter: "FILTER",
      Is_Approved: "1"
    };
    dispatch({
      type: BANNER_COUPON_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Coupons/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=COUPON_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: BANNER_COUPON_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BANNER_COUPON_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: BANNER_COUPON_FAIL,
      payload: err
    });
  }
};

// get partners Action
export const getbannerpartners = () => async dispatch => {
  try {
    let formData = {
      search_by_filter: "All",
      search: "",
    };
    dispatch({
      type: BANNER_ALLPARTNERS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Partners/OnlineAndPhysical_Details_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BANNER_ALLPARTNERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BANNER_ALLPARTNERS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: BANNER_ALLPARTNERS_FAIL,
      payload: err
    });
  }
};

//GET CITIES
export const getbannercities = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER"
    };
    dispatch({
      type: BANNER_CITY_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin_City/Super_Admin_City_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=CITY_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BANNER_CITY_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BANNER_CITY_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: BANNER_CITY_FAIL,
      payload: JSON.stringify(err)
    });
  }
};

//store types get
export const getbannerStoretypes = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "ALL"
    };
    dispatch({
      type: BANNER_STORE_TYPE_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Store_Type/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=STATUS&SortOrder=ASC `, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BANNER_STORE_TYPE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BANNER_STORE_TYPE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again.", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    dispatch({
      type: BANNER_STORE_TYPE_FAIL,
      payload: err
    });
  }
};

//products search get
export const getproductbysearch = (productname) => async dispatch => {
  try {
    let formData = {
      Product_Name: productname,
      Records_Filter: "FILTER"
    };
    dispatch({
      type: BANNER_PRODUCT_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Products/Get_Admin_Drop_Down`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BANNER_PRODUCT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BANNER_PRODUCT_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: BANNER_PRODUCT_FAIL,
      payload: err
    });
  }
};

//product get based on ID
export const getproductbyIDs = (formData) => async dispatch => {
  try {
    dispatch({
      type: BANNER_PRODUCT_ID_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Products/Get_Admin_Drop_Down`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BANNER_PRODUCT_ID_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BANNER_PRODUCT_ID_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: BANNER_PRODUCT_ID_FAIL,
      payload: err
    });
  }
};