import { nodeGetApi } from './commonAxios';

export const USERRIDESDATEANDCOUNT_REQUEST = 'USERRIDESDATEANDCOUNT_REQUEST';
export const USERRIDESDATEANDCOUNT_SUCCESS = "USERRIDESDATEANDCOUNT_SUCCESS";
export const USERRIDESDATEANDCOUNT_FAIL = "USERRIDESDATEANDCOUNT_FAIL";

/*=============================================================
          Get Rides Date and count Action
===============================================================*/
export const getuserridesDateandCount = () => async dispatch => {
  try {
    dispatch({
      type: USERRIDESDATEANDCOUNT_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Total_Count_And_Date/User_Ride`);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: USERRIDESDATEANDCOUNT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: USERRIDESDATEANDCOUNT_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: USERRIDESDATEANDCOUNT_FAIL,
      payload: err
    });
  }
};