import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    Avatar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { paginationsubcategoryServices,paginationvarientServices } from 'src/store/actions/node-actions/nodepagination';

import { ConnectedFocusError } from "focus-formik-error";
import CircularProgress from '@mui/material/CircularProgress';
import ESICities from 'src/components/common/cities';
import ESICountries from 'src/components/common/countries';
import ESIStates from 'src/components/common/states';

import {EditServiceCityCategorytypeAction,categoryuploadImagepath} from 'src/store/actions/servicescitycategories'


const StatusOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'ACTIVE',
        label: 'ACTIVE'
    },
    {
        value: 'IN-ACTIVE',
        label: 'IN-ACTIVE'
    }
];

const useStyles = makeStyles(() => ({
    images: {
        width: "100%",
        height: 100,
        borderRadius: 5,
        marginBottom: 15
    },
    Description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const EditServiceCategoryCity = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const location = useLocation();
    let { state } = location;

    // console.log(state)


    console.log( state.Svcc_Id )

    const [errorMessage, setErrorMessage] = useState({ Service_Name: { key: '', message: '' }, global: { key: '', message: '' } });
    const isSubmitted = useSelector(state => state.category.categoryUpdate.isLoading);
    const modifiedBy = useSelector(state => state.auth.user.Email_Id);
   
    
    const [citycategory,setCitycategory]=useState([])
  



    useEffect(() => {
        dispatch(paginationvarientServices({}, {}, (status, data) => {
          if (status) {
            console.log(data)
            setCitycategory(data)
          }
          else
            setCitycategory([])
        }));
      }, []);





    return (
        <Formik
            initialValues={{
                Svcc_Id: state.Svcc_Id ? state.Svcc_Id : "",
                Status: state.Status ? state.Status : "",
                Sv_Id: state.Sv_Id ? state.Sv_Id:"",
                Country_Id: state.Country_Id ? state.Country_Id :"",
                State_Id: state.State_Id ? state.State_Id:"",
                City_Id: state.City_Id ? state.City_Id : "",
                Service_Charge_For_Timeslot: state.Service_Charge_For_Timeslot ? state. Service_Charge_For_Timeslot:"",
                Service_Charge_For_Weekend_Timeslot: state.Service_Charge_For_Weekend_Timeslot ? state.Service_Charge_For_Weekend_Timeslot:"",
                Service_Charge_For_Special_Days_Timeslot: state.Service_Charge_For_Special_Days_Timeslot ? state.Service_Charge_For_Special_Days_Timeslot:"",
            }}
            validationSchema={
                Yup.object().shape({
                   

                    Sv_Id: Yup.string().required('Sv_Name is required.'),   
                    Status: Yup.string().required('Status is required.'),
                    Country_Id: Yup.string().required('Country is required.'),
                    State_Id: Yup.string().required('State is required.'),
                    City_Id: Yup.string().required('City is required.'),
                    Service_Charge_For_Timeslot:Yup.number().required('Service Charge For Timeslot is required.'),
                    Service_Charge_For_Weekend_Timeslot:Yup.number().required('Service Charge For Weekend Timeslot is required.'),
                    Service_Charge_For_Special_Days_Timeslot:Yup.number().required('Service Charge For Special Day Timeslot is required.')
                })
            }

            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
             

                setErrors(errors);
                if (submitTest) {

                    dispatch(EditServiceCityCategorytypeAction(values, navigate,state));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                              error={Boolean(touched.Sv_Id && errors.Sv_Id)}
                                              fullWidth
                                              helperText={touched.Sv_Id && errors.Sv_Id}
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              label="Sv_Name"
                                              name="Sv_Id"                 
                                              value={citycategory.find(city => city.Sv_Id === state.Sv_Id)?.Sv_Name || ''} 
                                              variant="outlined"
                                              >
                                            </TextField>
                                        </Grid>
                                      
                   
                                        <Grid item md={6} xs={12}>
                                            <ESICountries
                                                error={Boolean(touched.Country_Id && errors.Country_Id)}
                                                fullWidth
                                                helperText={touched.Country_Id && errors.Country_Id}
                                                onBlur={handleBlur}
                                                onChange={(value)=>{setFieldValue("Country_Id",value.currentTarget.value)}}
                                                select
                                                label="Country "
                                                name="Country_Id"
                                                value={values.Country_Id}
                                                variant="outlined" 
                                                SelectProps={{ native: true }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <ESIStates

                                                Country_Id={values.Country_Id}
                                                error={Boolean(touched.State_Id && errors.State_Id)}
                                                fullWidth
                                                helperText={touched.State_Id && errors.State_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                select
                                                label="State "
                                                name="State_Id"
                                                value={values.State_Id}
                                                variant="outlined"
                                                SelectProps={{ native: true }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <ESICities
                                                State_Id={values.State_Id}
                                                error={Boolean(touched.City_Id && errors.City_Id)}
                                                fullWidth
                                                helperText={touched.City_Id && errors.City_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="City "
                                                select
                                                name="City_Id"
                                                value={values.City_Id}
                                                variant="outlined"
                                                SelectProps={{ native: true }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Charge_For_Timeslot && errors.Service_Charge_For_Timeslot)}
                                                fullWidth
                                                helperText={touched.Service_Charge_For_Timeslot && errors.Service_Charge_For_Timeslot}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ServiceChargeForTimeslot "
                                                name="Service_Charge_For_Timeslot"
                                                value={values.Service_Charge_For_Timeslot}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Charge_For_Weekend_Timeslot && errors.Service_Charge_For_Weekend_Timeslot)}
                                                fullWidth
                                                helperText={touched.Service_Charge_For_Weekend_Timeslot && errors.Service_Charge_For_Weekend_Timeslot}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ServiceChargeForWeekendTimeslot "
                                                name="Service_Charge_For_Weekend_Timeslot"
                                                value={values.Service_Charge_For_Weekend_Timeslot}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Charge_For_Special_Days_Timeslot && errors.Service_Charge_For_Special_Days_Timeslot)}
                                                fullWidth
                                                helperText={touched.Service_Charge_For_Special_Days_Timeslot && errors.Service_Charge_For_Special_Days_Timeslot}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ServiceChargeForSpecialDaysTimeslot "
                                                name="Service_Charge_For_Special_Days_Timeslot"
                                                value={values.Service_Charge_For_Special_Days_Timeslot}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    
                                        
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {StatusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>

                              
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage.global.message}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                  
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                  
                                >
                                    {/* Save details */}
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                           
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditServiceCategoryCity.propTypes = {
    className: PropTypes.string
};

export default EditServiceCategoryCity;



