import { v4 as uuid } from 'uuid';

export default [
    {
        id: uuid(),
        hide: 0,
        link: '/app/user-rides',
        createdAt: '',
        description:
            'A Ride Management automates vehicle booking, billing, scheduling and dispatching, facilitates dynamic pricing, and more.',
        media: '/static/Dashboard/catergory.png',
        title: 'User Rides',
        total: ''
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/user-ride-fares',
        createdAt: '',
        description:
            'Sample rider prices are estimates only and do not reflect variations due to discounts, geography, traffic delays, or other factors. Flat rates and minimum fees may apply. Actual prices for rides and scheduled rides may vary.',
        media: '/static/Dashboard/catergory.png',
        title: 'User Ride Fares',
        total: ''
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/ride-vehicle-types',
        createdAt: '',
        description:
            'Rides Vehicle Types means a category of power-driven vehicles which do not differ in essential engine/vehicle and OBD system characteristics.',
        media: '/static/Dashboard/catergory.png',
        title: 'Ride Vehicle Types',
        total: ''
    },
];