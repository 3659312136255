import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { getAllPartners } from 'src/store/actions/couponAction';
import { EditOnlinePartnerAction, EditPhysicalPartner, getAllPartnersAddress } from 'src/store/actions/productpartnerAction';
import { CircularProgress } from '@mui/material';

const partnerTypeOption = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Online',
    label: 'Online Partner'
  },
  {
    value: 'Physical',
    label: 'Physical Partner'
  }
];

const Esi_Cashback_Type = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'A',
    label: 'Amount'
  },
  {
    value: 'P',
    label: 'Percentage'
  },
  {
    value: 'R',
    label: 'Reward Points'
  },
];

const Discount_Type = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'A',
    label: 'Amount'
  },
  {
    value: 'P',
    label: 'Percentage'
  }
];

const statusOption = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const CashbacklabelOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'UPTO',
    label: 'UPTO'
  },
  {
    value: 'FLAT',
    label: 'FLAT'
  }
];

const DiscountlabelOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'UPTO',
    label: 'UPTO'
  },
  {
    value: 'FLAT',
    label: 'FLAT'
  }
];

const Partner_Original_Discount_Type = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'A',
    label: 'Amount'
  },
  {
    value: 'P',
    label: 'Percentage'
  }
];

const EditPendingProductPartner = ({ className, state, data, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // componentDidMount
  useEffect(() => {
    dispatch(getAllPartners());
    dispatch(getAllPartnersAddress());
  }, []);

  const [errorMessage, setErrorMessage] = useState();
  const isSubmited = useSelector(state => state.productPartner.partnerUpdate.isLoading);
  const partnerAddress = useSelector(state => state.productPartner.partnerAddress.address);
  const partners = useSelector(state => state.coupon.couponPartners.partners);

  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        Partner_Product_Id: data.Partner_Product_Id ? data.Partner_Product_Id : "",
        Product_Id: data.Product_Id ? data.Product_Id : "",
        Type: data.Partner_Type ? data.Partner_Type : "",
        Price: data.Price ? data.Price : 0,
        Warranty_Period: data.Warranty_Period ? data.Warranty_Period : "",
        Discount_Type: data.Discount_Type ? data.Discount_Type : '',
        Discount: data.Discount ? data.Discount : 0,
        Esi_Cashback_Type: data.Esi_Cashback_Type ? data.Esi_Cashback_Type : '',
        Esi_Cashback: data.Esi_Cashback ? data.Esi_Cashback : 0,
        Partner_Address_Id: data.Partner_Address_Id ? data.Partner_Address_Id : '',
        Partner_Details_Id: data.Partner_Details_Id ? data.Partner_Details_Id : '',
        // Discount_From_Date: data.Discount_From_Date ? data.Discount_From_Date : '',
        // Discount_To_Date: data.Discount_To_Date ? data.Discount_To_Date : '',
        Add_To_Cart: data.Add_To_Cart > 0 ? true : false,
        Verified: data.Verified > 0 ? true : false,
        Exclusive: data.Exclusive > 0 ? true : false,
        Is_Popular: data.Is_Popular > 0 ? true : false,
        What_Hot: data.What_Hot > 0 ? true : false,
        Todays_Hot: data.Todays_Hot > 0 ? true : false,
        Extra_CashBack: data.Extra_CashBack > 0 ? true : false,
        Created_By: data.Created_By ? data.Created_By : loginEmail,
        Status: data.Status ? data.Status : '',
        Is_Upcoming: data.Is_Upcoming > 0 ? true : false,
        Max_Days_To_Return: data.Max_Days_To_Return ? data.Max_Days_To_Return : 0,
        Shipping_Amount: data.Shipping_Amount ? data.Shipping_Amount : 0,
        Web_Redirection_Url: data.Web_Redirection_Url ? data.Web_Redirection_Url : '',
        Mobile_Redirection_Url: data.Mobile_Redirection_Url ? data.Mobile_Redirection_Url : '',
        Is_Default: data.Is_Default > 0 ? true : false,
        Is_Esi_Shipping_Available: data.Is_Esi_Shipping_Available > 0 ? true : false,
        Is_Partner_Shipping_Available: data.Is_Partner_Shipping_Available > 0 ? true : false,
        Is_Return_Available: data.Is_Return_Available > 0 ? true : false,
        Is_Gift_Wrap_Available: data.Is_Gift_Wrap_Available > 0 ? true : false,
        Gift_Wrap_Amount: data.Gift_Wrap_Amount ? data.Gift_Wrap_Amount : 0,
        Cashback_Label: data.Cashback_Label ? data.Cashback_Label : 0,
        Discount_Label: data.Discount_Label ? data.Discount_Label : 0,
        Today_Recommendations: data.Today_Recommendations > 0 ? true : false,
        Trending_Today: data.Trending_Today > 0 ? true : false,
        Is_Shop_By_City_Only: data.Is_Shop_By_City_Only > 0 ? true : false,
        Is_Product_Available: data.Is_Product_Available > 0 ? true : false,
        Is_Product_Available_On_Same_Location: data.Is_Product_Available_On_Same_Location > 0 ? true : false,
        Esi_Commission: data.Esi_Commission ? data.Esi_Commission : '',
        Esi_Commission_Type: data.Esi_Commission_Type ? data.Esi_Commission_Type : '',
        Esi_Total_Commission: data.Esi_Total_Commission ? data.Esi_Total_Commission : '',
        Partner_Original_Discount: data.Partner_Original_Discount ? data.Partner_Original_Discount : '',
        Partner_Original_Discount_Amount: data.Partner_Original_Discount_Amount ? data.Partner_Original_Discount_Amount : '',
        Partner_Original_Discount_Type: data.Partner_Original_Discount_Type ? data.Partner_Original_Discount_Type : '',
        Partner_Original_Selling_Price: data.Partner_Original_Selling_Price ? data.Partner_Original_Selling_Price : '',
        Partner_Selling_Price: data.Partner_Selling_Price ? data.Partner_Selling_Price : '',
        Meepaisa_Cashback_Proposed_Percentage: data.Meepaisa_Cashback_Proposed_Percentage ? data.Meepaisa_Cashback_Proposed_Percentage : '',
        Partner_Original_Discount_Label: data.Partner_Original_Discount_Label ? data.Partner_Original_Discount_Label : '',
        Discount_Amount_Percentage: data.Discount_Amount_Percentage ? data.Discount_Amount_Percentage : '',
        Discount_Amount: data.Discount_Amount ? data.Discount_Amount : "",
        Cashback_Amount_Percentage: data.Cashback_Amount_Percentage ? data.Cashback_Amount_Percentage : "",
        Is_Approved: data.Is_Approved > 0 ? true : false
      }}

      validationSchema={
        Yup.object().shape({
          Type: Yup.string().required('Partner Type is required.'),
          Partner_Details_Id: Yup.string().required('Partner Name is required.'),
          // Discount_From_Date: Yup.date().min(new Date(data.Discount_From_Date) < new Date(Date.now() - 1 * 24 * 60 * 60 * 1000) ? new Date(new Date(data.Discount_From_Date) - 1 * 24 * 60 * 60 * 1000) : new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), new Date(data.Discount_From_Date) < new Date(Date.now() - 1 * 24 * 60 * 60 * 1000) ? "Require Discount From Date greater than or equal to " + ((new Date(data.Discount_From_Date).getMonth() > 8) ? (new Date(data.Discount_From_Date).getMonth() + 1) : ('0' + (new Date(data.Discount_From_Date).getMonth() + 1))) + '/' + ((new Date(data.Discount_From_Date).getDate() > 9) ? new Date(data.Discount_From_Date).getDate() : ('0' + new Date(data.Discount_From_Date).getDate())) + '/' + new Date(data.Discount_From_Date).getFullYear() + "." : "Require Discount From Date greater than or equal to today.").required("Discount From Date is required."),
          // Discount_To_Date: Yup.date().when("Discount_From_Date", (Discount_From_Date, schema) => Discount_From_Date && schema.min(Discount_From_Date, "Discount To Date can't be before Discount From Date.")).required("Discount To Date is required."),
          Max_Days_To_Return: Yup.number().min(0, "Max Days To Return should not be less than 0.").max(30, "Max Days To Return must be less than or equal to 30."),
          Warranty_Period: Yup.string().required('Warranty Period is required.'),
          Shipping_Amount: Yup.number().min(0, "Shipping Amount should not be less than 0.").required('Shipping Amount is required.'),
          Gift_Wrap_Amount: Yup.number().min(0, "Gift Wrap Amount should not be less than 0.").required('Gift Wrap Amount is required.'),
          Status: Yup.string().required('Status is required.'),
          Web_Redirection_Url: Yup.string().when("Type", (Type, schema) => Type === "Online" ? schema.url('Please provide valid URL.').required("Web Redirection URL is required.") : schema),
          Mobile_Redirection_Url: Yup.string().when("Type", (Type, schema) => Type === "Online" ? schema.url('Please provide valid URL.').required("Mobile Redirection URL is required.") : schema),
          Discount_Type: Yup.string().when('Type', {
            is: "Online", then: Yup.string().test(
              'oneOfRequired',
              'Discount Type / ESI Cashback Type is required.',
              function (item) {
                return (this.parent.Discount_Type || this.parent.Esi_Cashback_Type)
              })
          }).when('Type', { is: "Physical", then: Yup.string().required('User Discount Type is required.') }
          ),
          Discount_Label: Yup.string().when("Discount", (Discount, schema) => {
            if (Discount !== "") {
              return schema.required('Discount Label is required.');
            }
          }).when('Type', { is: "Physical", then: Yup.string().required('User Discount Label is required') }),
          Discount: Yup.number().when("Discount_Type", (Discount_Type, schema) => {
            if (Discount_Type === "A") {
              return schema.min(1, "Discount Amount must be at least 1.").required('Discount Amount is required.');
            } else if (Discount_Type === "P") {
              return schema.min(1, "Discount Percentage must be at least 1.").max(100, "Discount Percentage must be less then 100.").required('Discount Percentage is required.');
            }
          }),
          Esi_Cashback_Type: Yup.string().when('Type', {
            is: "Online", then: Yup.string().test(
              'oneOfRequired',
              'Discount Type / ESI Cashback Type is required.',
              function (item) {
                return (this.parent.Discount_Type || this.parent.Esi_Cashback_Type)
              }
            )
          }
          ).when('Type', { is: "Physical", then: Yup.string().required('ESI Cashback Type is required.') }
          ),
          Cashback_Label: Yup.string().when("Esi_Cashback", (Esi_Cashback, schema) => {
            if (Esi_Cashback !== "") {
              return schema.required('ESI Cashback Label is required.');
            }
          }).when('Type', { is: "Physical", then: Yup.string().required('ESI Cashback Label is required') }),
          Esi_Cashback: Yup.number().when("Esi_Cashback_Type", (Esi_Cashback_Type, schema) => {
            if (Esi_Cashback_Type === "A") {
              return schema.min(1, "ESI Cashback Amount must be at least 1.").required('ESI Cashback Amount is required.');
            } else if (Esi_Cashback_Type === "P") {
              return schema.min(1, "ESI Cashback Percentage must be at least 1.").max(100, "ESI Cashback Percentage must be less then 100.").required('ESI Cashback Percentage is required.');
            } else if (Esi_Cashback_Type === "R") {
              return schema.min(1, "ESI Cashback Reward Points must be at least 1.").max(100, "ESI Cashback Reward Points must be less than 100.").required('ESI Cashback Reward Points are required.');
            }
          }),
          Partner_Original_Discount_Type: Yup.string().when('Type', { is: "Physical", then: Yup.string().required('Partner Discount Type is required.') }),
          Partner_Original_Discount_Label: Yup.string().when('Type', { is: "Physical", then: Yup.string().required('Partner Discount Label is required.') }),
          Partner_Original_Discount_Amount: Yup.number().when('Type', {
            is: "Physical", then: Yup.number().when(["Partner_Original_Discount_Type", "Price"], (Partner_Original_Discount_Type, Price, schema) => {
              if (Partner_Original_Discount_Type === "A") {
                return schema.min(1, "Discount Amount must be at least 1.").max(Price, "Discount Amount must be less than Price.").required('Discount Amount is required.');
              } else if (Partner_Original_Discount_Type === "P") {
                return schema.min(1, "Discount Percentage must be at least 1.").max(100, "Discount Percentage must be less than 100.").required('Partner Discount is required.');
              }
            })
          }),
          Meepaisa_Cashback_Proposed_Percentage: Yup.number().when('Type', { is: "Physical", then: Yup.number().min(0, "Cashback Proposed Percentage should not be less than 0.").max(100, "Cashback Proposed Percentage must be less than 100.").required('Meepaisa Cashback Proposed Percentage is required.') }),
          Partner_Address_Id: Yup.string().when("Type", (Type, schema) => Type === "Physical" ? schema.required("Partner Address is required.") : schema),
        })
      }

      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        let formValues = JSON.parse(JSON.stringify(values));
        if (values.Is_Approved) {
          formValues.Is_Approved = 1;
        } else {
          formValues.Is_Approved = 0;
        }
        if (values.Add_To_Cart) {
          formValues.Add_To_Cart = 1;
        } else {
          formValues.Add_To_Cart = 0;
        }
        if (values.Verified) {
          formValues.Verified = 1;
        } else {
          formValues.Verified = 0;
        }
        if (values.Exclusive) {
          formValues.Exclusive = 1;
        } else {
          formValues.Exclusive = 0;
        }
        if (values.Is_Popular) {
          formValues.Is_Popular = 1;
        } else {
          formValues.Is_Popular = 0;
        }
        if (values.What_Hot) {
          formValues.What_Hot = 1;
        } else {
          formValues.What_Hot = 0;
        }
        if (values.Todays_Hot) {
          formValues.Todays_Hot = 1;
        } else {
          formValues.Todays_Hot = 0;
        }
        if (values.Extra_CashBack) {
          formValues.Extra_CashBack = 1;
        } else {
          formValues.Extra_CashBack = 0;
        }
        if (values.Is_Upcoming) {
          formValues.Is_Upcoming = 1;
        } else {
          formValues.Is_Upcoming = 0;
        }
        if (values.Is_Default) {
          formValues.Is_Default = 1;
        } else {
          formValues.Is_Default = 0;
        }
        if (values.Is_Esi_Shipping_Available) {
          formValues.Is_Esi_Shipping_Available = 1;
        } else {
          formValues.Is_Esi_Shipping_Available = 0;
        }
        if (values.Is_Partner_Shipping_Available) {
          formValues.Is_Partner_Shipping_Available = 1;
        } else {
          formValues.Is_Partner_Shipping_Available = 0;
        }
        if (values.Is_Gift_Wrap_Available) {
          formValues.Is_Gift_Wrap_Available = 1;
        } else {
          formValues.Is_Gift_Wrap_Available = 0;
        }
        if (values.Is_Return_Available) {
          formValues.Is_Return_Available = 1;
        } else {
          formValues.Is_Return_Available = 0;
        }
        if (values.Today_Recommendations) {
          formValues.Today_Recommendations = 1;
        } else {
          formValues.Today_Recommendations = 0;
        }
        if (values.Trending_Today) {
          formValues.Trending_Today = 1;
        } else {
          formValues.Trending_Today = 0;
        }
        if (values.Is_Shop_By_City_Only) {
          formValues.Is_Shop_By_City_Only = 1;
        } else {
          formValues.Is_Shop_By_City_Only = 0;
        }
        if (values.Is_Product_Available) {
          formValues.Is_Product_Available = 1;
        } else {
          formValues.Is_Product_Available = 0;
        }
        if (values.Is_Product_Available_On_Same_Location) {
          formValues.Is_Product_Available_On_Same_Location = 1;
        } else {
          formValues.Is_Product_Available_On_Same_Location = 0;
        }
        if (values.Type === 'Online') {
          dispatch(EditOnlinePartnerAction(formValues, navigate, state, setErrorMessage));
        } else if (values.Type === 'Physical') {
          dispatch(EditPhysicalPartner(formValues, navigate, state, setErrorMessage))
        }
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    {/* Partner Type */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Type && errors.Type)}
                        fullWidth
                        helperText={touched.Type && errors.Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Partner Type"
                        name="Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Type}
                        variant="outlined"
                        disabled
                      >{partnerTypeOption.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                      </TextField>
                    </Grid>

                    {/* Partner_Details_Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Partner_Details_Id && errors.Partner_Details_Id)}
                        fullWidth
                        helperText={touched.Partner_Details_Id && errors.Partner_Details_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Partner Name"
                        name="Partner_Details_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Partner_Details_Id}
                        variant="outlined"
                        disabled
                      > <option key="" value="">-Please Select-</option>
                        {partners?.filter(option => values.Type === option.Type
                        )?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                          <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                            {option.Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/*Price*/}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Price (MRP)"
                        name="Price"
                        disabled
                        value={values.Price}
                        variant="outlined"
                        type="number"
                      >
                      </TextField>
                    </Grid>

                    {values.Type === 'Online' && (
                      <>
                        {/*Discount_Type*/}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Discount_Type && errors.Discount_Type)}
                            fullWidth
                            helperText={touched.Discount_Type && errors.Discount_Type}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Discount Type"
                            name="Discount_Type"
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: true }}
                            value={values.Discount_Type}
                            variant="outlined"  >
                            {Discount_Type.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>

                        {/*Discount*/}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Discount && errors.Discount)}
                            fullWidth
                            disabled={values.Discount_Type !== "" ? false : true}
                            helperText={touched.Discount && errors.Discount}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label={"Discount " + (values.Discount_Type === "A" ? "Amount ₹" : values.Discount_Type === "P" ? "Percentage %" : "")}
                            name="Discount"
                            value={values.Discount}
                            type="number"
                            variant="outlined" >
                          </TextField>
                        </Grid>

                        {/* Discount Label input field */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Discount_Label && errors.Discount_Label)}
                            fullWidth
                            helperText={touched.Discount_Label && errors.Discount_Label}
                            disabled={values.Discount !== "" ? false : true}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Discount Label"
                            name="Discount_Label"
                            select
                            SelectProps={{ native: true }}
                            InputLabelProps={{ shrink: true }}
                            value={values.Discount_Label}
                            variant="outlined"
                          >
                            {DiscountlabelOption.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>

                        {/*ESI_Cashback_Type*/}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Esi_Cashback_Type && errors.Esi_Cashback_Type)}
                            fullWidth
                            helperText={touched.Esi_Cashback_Type && errors.Esi_Cashback_Type}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="ESI Cashback Type"
                            name="Esi_Cashback_Type"
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: true }}
                            value={values.Esi_Cashback_Type}
                            variant="outlined" >
                            {Esi_Cashback_Type.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>

                        {/*Esi_Cashback*/}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Esi_Cashback && errors.Esi_Cashback)}
                            fullWidth
                            disabled={values.Esi_Cashback_Type !== "" ? false : true}
                            helperText={touched.Esi_Cashback && errors.Esi_Cashback}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label={"ESI Cashback " + (values.Esi_Cashback_Type === "A" ? "Amount ₹" : values.Esi_Cashback_Type === "P" ? "Percentage %" : values.Esi_Cashback_Type === "R" ? "Reward Points" : "")}
                            name="Esi_Cashback"
                            value={values.Esi_Cashback}
                            type="number"
                            variant="outlined"  >
                          </TextField>
                        </Grid>

                        {/* ESI Cashback Label input field */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Cashback_Label && errors.Cashback_Label)}
                            fullWidth
                            helperText={touched.Cashback_Label && errors.Cashback_Label}
                            disabled={values.Esi_Cashback !== "" ? false : true}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="ESI Cashback Label"
                            name="Cashback_Label"
                            select
                            SelectProps={{ native: true }}
                            InputLabelProps={{ shrink: true }}
                            value={values.Cashback_Label}
                            variant="outlined"
                          >
                            {CashbacklabelOption.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>

                        {/* Web_Redirection_Url*/}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Web_Redirection_Url && errors.Web_Redirection_Url)}
                            fullWidth
                            helperText={touched.Web_Redirection_Url && errors.Web_Redirection_Url}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Web Redirection URL"
                            name="Web_Redirection_Url"
                            value={values.Web_Redirection_Url}
                            variant="outlined"
                          />
                        </Grid>

                        {/* Mobile_Redirection_Url*/}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Mobile_Redirection_Url && errors.Mobile_Redirection_Url)}
                            fullWidth
                            helperText={touched.Mobile_Redirection_Url && errors.Mobile_Redirection_Url}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Mobile Redirection URL"
                            name="Mobile_Redirection_Url"
                            value={values.Mobile_Redirection_Url}
                            variant="outlined"
                          />
                        </Grid>
                      </>
                    )}

                    {values.Type === 'Physical' && (
                      <>
                        {/* Partner_Original_Discount_Type */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Partner_Original_Discount_Type && errors.Partner_Original_Discount_Type)}
                            fullWidth
                            helperText={touched.Partner_Original_Discount_Type && errors.Partner_Original_Discount_Type}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Partner Discount Type"
                            name="Partner_Original_Discount_Type"
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: true }}
                            value={values.Partner_Original_Discount_Type}
                            variant="outlined" >
                            {Partner_Original_Discount_Type.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}

                          </TextField>
                        </Grid>

                        {/*Partner_Original_Discount_Label*/}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Partner_Original_Discount_Label && errors.Partner_Original_Discount_Label)}
                            fullWidth
                            helperText={touched.Partner_Original_Discount_Label && errors.Partner_Original_Discount_Label}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Partner Discount Label"
                            name="Partner_Original_Discount_Label"
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: true }}
                            value={values.Partner_Original_Discount_Label}
                            variant="outlined"
                          >
                            {DiscountlabelOption.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>

                        {/*Partner_Original_Discount*/}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Partner_Original_Discount_Amount && errors.Partner_Original_Discount_Amount)}
                            fullWidth
                            helperText={touched.Partner_Original_Discount_Amount && errors.Partner_Original_Discount_Amount}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              let PPrice = values.Partner_Original_Discount_Type === "P" ? (e.target.value / 100 * values.Price).toFixed(2) : e.target.value
                              setFieldValue("Partner_Original_Discount_Amount", e.target.value);
                              setFieldValue("Partner_Original_Discount", PPrice);
                              setFieldValue("Partner_Original_Selling_Price", values.Price - PPrice);
                            }}
                            label={"Partner Discount " + (values.Partner_Original_Discount_Type === "A" ? "Amount ₹" : values.Partner_Original_Discount_Type === "P" ? "Percentage %" : "")}
                            name="Partner_Original_Discount_Amount"
                            value={values.Partner_Original_Discount_Amount}
                            type="number"
                            variant="outlined" >
                          </TextField>
                        </Grid>

                        {/* Partner_Original_Discount input field */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Partner_Original_Discount && errors.Partner_Original_Discount)}
                            fullWidth
                            helperText={touched.Partner_Original_Discount && errors.Partner_Original_Discount}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Partner Original Discount"
                            name="Partner_Original_Discount"
                            type="number"
                            value={values.Partner_Original_Discount}
                            variant="outlined"
                            disabled
                          >
                          </TextField>
                        </Grid>

                        {/* Partner_Original_Selling_Price */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Partner_Original_Selling_Price && errors.Partner_Original_Selling_Price)}
                            fullWidth
                            helperText={touched.Partner_Original_Selling_Price && errors.Partner_Original_Selling_Price}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="number"
                            label="Partner Original Selling Price"
                            name="Partner_Original_Selling_Price"
                            value={values.Partner_Original_Selling_Price}
                            variant="outlined"
                            disabled
                          />
                        </Grid>

                        {/* User Discount_Type*/}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Discount_Type && errors.Discount_Type)}
                            fullWidth
                            helperText={touched.Discount_Type && errors.Discount_Type}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="User Discount Type"
                            name="Discount_Type"
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: true }}
                            value={values.Discount_Type}
                            variant="outlined"  >
                            {Discount_Type.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}

                          </TextField>
                        </Grid>

                        {/* User Discount Label input field */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Discount_Label && errors.Discount_Label)}
                            fullWidth
                            helperText={touched.Discount_Label && errors.Discount_Label}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="User Discount Label"
                            name="Discount_Label"
                            select
                            SelectProps={{ native: true }}
                            InputLabelProps={{ shrink: true }}
                            value={values.Discount_Label}
                            variant="outlined"
                          >
                            {DiscountlabelOption.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>

                        {/*User Discount*/}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Discount && errors.Discount)}
                            fullWidth
                            helperText={touched.Discount && errors.Discount}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              let UPrice = values.Discount_Type === "P" ? (e.target.value / 100 * values.Price).toFixed(2) : e.target.value
                              setFieldValue("Discount", e.target.value);
                              setFieldValue("Discount_Amount_Percentage", UPrice);
                              setFieldValue("Discount_Amount", values.Price - UPrice);
                              setFieldValue("Esi_Total_Commission", ((values.Price - UPrice) - (values.Partner_Original_Selling_Price)).toFixed(2));
                            }}
                            label={"User Discount " + (values.Discount_Type === "A" ? "Amount ₹" : values.Discount_Type === "P" ? "Percentage %" : "")}
                            name="Discount"
                            value={values.Discount}
                            type="number"
                            variant="outlined" >
                          </TextField>
                        </Grid>

                        {/* Discount_Amount_Percentage input field */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Discount_Amount_Percentage && errors.Discount_Amount_Percentage)}
                            fullWidth
                            helperText={touched.Discount_Amount_Percentage && errors.Discount_Amount_Percentage}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="User Original Discount"
                            name="Discount_Amount_Percentage"
                            type="number"
                            value={values.Discount_Amount_Percentage}
                            variant="outlined"
                            disabled
                            InputLabelProps={{ shrink: true }}
                          >
                          </TextField>
                        </Grid>

                        {/* Discount_Amount input field */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Discount_Amount && errors.Discount_Amount)}
                            fullWidth
                            helperText={touched.Discount_Amount && errors.Discount_Amount}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="User Original Selling Price"
                            name="Discount_Amount"
                            type="number"
                            value={values.Discount_Amount}
                            variant="outlined"
                            disabled
                            InputLabelProps={{ shrink: true }}
                          >
                          </TextField>
                        </Grid>

                        {/* Esi_Total_Commission */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Esi_Total_Commission && errors.Esi_Total_Commission)}
                            fullWidth
                            helperText={touched.Esi_Total_Commission && errors.Esi_Total_Commission}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="ESI Total Commission"
                            type="number"
                            name="Esi_Total_Commission"
                            value={values.Esi_Total_Commission}
                            variant="outlined"
                            disabled
                          />
                        </Grid>

                        {/* Meepaisa_Cashback_Proposed_Percentage input field */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Meepaisa_Cashback_Proposed_Percentage && errors.Meepaisa_Cashback_Proposed_Percentage)}
                            fullWidth
                            helperText={touched.Meepaisa_Cashback_Proposed_Percentage && errors.Meepaisa_Cashback_Proposed_Percentage}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              let CashbackPrice = (e.target.value / 100 * values.Esi_Total_Commission).toFixed(2)
                              setFieldValue("Meepaisa_Cashback_Proposed_Percentage", e.target.value);
                              setFieldValue("Cashback_Amount_Percentage", CashbackPrice);
                              setFieldValue("Esi_Commission", (values.Esi_Total_Commission - CashbackPrice).toFixed(2));
                            }}
                            label="Cashback Proposed Percentage"
                            name="Meepaisa_Cashback_Proposed_Percentage"
                            value={values.Meepaisa_Cashback_Proposed_Percentage}
                            variant="outlined"
                            type='number'
                          >
                          </TextField>
                        </Grid>

                        {/*Cashback_Amount_Percentage*/}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Cashback_Amount_Percentage && errors.Cashback_Amount_Percentage)}
                            fullWidth
                            helperText={touched.Cashback_Amount_Percentage && errors.Cashback_Amount_Percentage}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="ESI Cashback Amount"
                            name="Cashback_Amount_Percentage"
                            value={values.Cashback_Amount_Percentage}
                            variant="outlined"
                            type='number'
                            disabled
                            InputLabelProps={{ shrink: true }}
                          >
                          </TextField>
                        </Grid>
                        {/* Esi_Commission */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Esi_Commission && errors.Esi_Commission)}
                            fullWidth
                            helperText={touched.Esi_Commission && errors.Esi_Commission}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="ESI Commission"
                            // label={"ESI Commission " + (values.Esi_Commission_Type === "A" ? "Amount ₹" : values.Esi_Commission_Type === "P" ? "Percentage %" : "")}
                            name="Esi_Commission"
                            type="number"
                            variant="outlined"
                            value={values.Esi_Commission}
                            disabled
                          >
                          </TextField>
                        </Grid>

                        {/*ESI_Cashback_Type*/}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Esi_Cashback_Type && errors.Esi_Cashback_Type)}
                            fullWidth
                            helperText={touched.Esi_Cashback_Type && errors.Esi_Cashback_Type}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="ESI Cashback Type"
                            name="Esi_Cashback_Type"
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: true }}
                            value={values.Esi_Cashback_Type}
                            variant="outlined" >
                            {Esi_Cashback_Type.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>

                        {/* ESI Cashback Label input field */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Cashback_Label && errors.Cashback_Label)}
                            fullWidth
                            helperText={touched.Cashback_Label && errors.Cashback_Label}
                            // disabled={values.Esi_Cashback !== "" ? false : true}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="ESI Cashback Label"
                            name="Cashback_Label"
                            select
                            SelectProps={{ native: true }}
                            InputLabelProps={{ shrink: true }}
                            value={values.Cashback_Label}
                            variant="outlined"
                          >
                            {CashbacklabelOption.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>

                        {/*Esi_Cashback*/}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Esi_Cashback && errors.Esi_Cashback)}
                            fullWidth
                            helperText={touched.Esi_Cashback && errors.Esi_Cashback}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label={"ESI User Cashback " + (values.Esi_Cashback_Type === "A" ? "Amount ₹" : values.Esi_Cashback_Type === "P" ? "Percentage %" : values.Esi_Cashback_Type === "R" ? "Rewards" : "")}
                            name="Esi_Cashback"
                            variant="outlined"
                            type='number'
                            value={values.Esi_Cashback}
                          >
                          </TextField>
                        </Grid>

                        {/* Partner_Address_Id */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Partner_Address_Id && errors.Partner_Address_Id)}
                            fullWidth
                            helperText={touched.Partner_Address_Id && errors.Partner_Address_Id}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Partner Address"
                            name="Partner_Address_Id"
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: true }}
                            value={values.Partner_Address_Id}
                            variant="outlined"
                          >  <option key="" value="">-Please Select-</option>
                            {partnerAddress?.filter(option => option.Partner_Details_Id === values.Partner_Details_Id).map(option => (
                              <option key={option.Partner_Address_Id} value={option.Partner_Address_Id}> {"Street : " + option.Street + ", Land Mark :" + option.Land_Mark + "."}</option>
                            ))}
                          </TextField>
                        </Grid>
                      </>
                    )}

                    {/*  valid from date */}
                    {/* < Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Discount_From_Date && errors.Discount_From_Date)}
                        fullWidth
                        helperText={touched.Discount_From_Date && errors.Discount_From_Date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Discount From Date"
                        type="datetime-local"
                        name="Discount_From_Date"
                        defaultValue={values.Discount_From_Date}
                        variant="outlined"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid> */}

                    {/* valid through date/ % */}
                    {/* <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Discount_To_Date && errors.Discount_To_Date)}
                        fullWidth
                        helperText={touched.Discount_To_Date && errors.Discount_To_Date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Discount To Date"
                        type="datetime-local"
                        name="Discount_To_Date"
                        defaultValue={values.Discount_To_Date}
                        variant="outlined"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid> */}

                    {/* Max_Days_To_Return  */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Max_Days_To_Return && errors.Max_Days_To_Return)}
                        fullWidth
                        helperText={touched.Max_Days_To_Return && errors.Max_Days_To_Return}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        label="Max Days To Return"
                        name="Max_Days_To_Return"
                        value={values.Max_Days_To_Return}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Shipping_Amount  */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Shipping_Amount && errors.Shipping_Amount)}
                        fullWidth
                        helperText={touched.Shipping_Amount && errors.Shipping_Amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        label="Shipping Amount"
                        name="Shipping_Amount"
                        value={values.Shipping_Amount}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Warranty_Period */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Warranty_Period && errors.Warranty_Period)}
                        fullWidth
                        multiline
                        helperText={touched.Warranty_Period && errors.Warranty_Period}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Warranty Period"
                        name="Warranty_Period"
                        value={values.Warranty_Period}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>

                    {/*Gift_Wrap_Amount*/}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Gift_Wrap_Amount && errors.Gift_Wrap_Amount)}
                        fullWidth
                        helperText={touched.Gift_Wrap_Amount && errors.Gift_Wrap_Amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Gift Wrap Amount"
                        name="Gift_Wrap_Amount"
                        value={values.Gift_Wrap_Amount}
                        variant="outlined"
                        type="number"
                      >
                      </TextField>
                    </Grid>

                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {values.Type === 'Physical' && (
                      <>
                        {/* Is_Approved */}
                        <Grid item md={6} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onBlur={handleBlur}
                                checked={values.Is_Approved}
                                onChange={handleChange}
                                name="Is_Approved"
                                color="primary"
                              />
                            }
                            label="Is Approved" />
                        </Grid>
                        {/* Add-to-cart */}
                        <Grid item md={6} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onBlur={handleBlur}
                                checked={values.Add_To_Cart}
                                onChange={handleChange}
                                name="Add_To_Cart"
                                color="primary"
                              />
                            }
                            label="Add To Cart" />
                        </Grid>
                        {/* Is_Shop_By_City_Only */}
                        <Grid item md={6} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onBlur={handleBlur}
                                checked={values.Is_Shop_By_City_Only}
                                onChange={handleChange}
                                name="Is_Shop_By_City_Only"
                                color="primary"
                              />
                            }
                            label="Is Shop By City Only" />
                        </Grid>
                        {/* Is_Product_Available */}
                        <Grid item md={6} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onBlur={handleBlur}
                                checked={values.Is_Product_Available}
                                onChange={handleChange}
                                name="Is_Product_Available"
                                color="primary"
                              />
                            }
                            label="Is Product Available" />
                        </Grid>

                        {/* Is_Product_Available_On_Same_Location */}
                        <Grid item md={6} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onBlur={handleBlur}
                                checked={values.Is_Product_Available_On_Same_Location}
                                onChange={handleChange}
                                name="Is_Product_Available_On_Same_Location"
                                color="primary"
                              />
                            }
                            label="Is Product Available On Same Location" />
                        </Grid>
                      </>
                    )}

                    {/* Is_Partner_Shipping_Available */}
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Partner_Shipping_Available}
                            onChange={handleChange}
                            name="Is_Partner_Shipping_Available"
                            color="primary"
                          />
                        }
                        label="Is Partner Shipping Available" />
                    </Grid>

                    {/* Is_Esi_Shipping_Available */}
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Esi_Shipping_Available}
                            onChange={handleChange}
                            name="Is_Esi_Shipping_Available"
                            color="primary"
                          />
                        }
                        label="Is ESI Shipping Available" />
                    </Grid>

                    {/* Is_Return_Available */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Return_Available}
                            onChange={handleChange}
                            name="Is_Return_Available"
                            color="primary"
                          />
                        }
                        label="Is Return Available" />
                    </Grid>

                    {/* Is_Default */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Default}
                            onChange={handleChange}
                            name="Is_Default"
                            color="primary"
                          />
                        }
                        label="Is Default" />
                    </Grid>

                    {/*Extra_CashBack */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Extra_CashBack}
                            onChange={handleChange}
                            name="Extra_CashBack"
                            color="primary"
                          />
                        }
                        label="Extra Cashback" />
                    </Grid>

                    {/* Verified */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Verified}
                            onChange={handleChange}
                            name="Verified"
                            color="primary"
                          />
                        }
                        label="Verified"
                      />
                    </Grid>

                    {/* Exclusive */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Exclusive}
                            onChange={handleChange}
                            name="Exclusive"
                            color="primary"
                          />
                        }
                        label="Exclusive"
                      />
                    </Grid>

                    {/* Whats_Hot */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.What_Hot}
                            onChange={handleChange}
                            name="What_Hot"
                            color="primary"
                          />
                        }
                        label="What Hot"
                      />
                    </Grid>

                    {/* Todays_Hot */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Todays_Hot}
                            onChange={handleChange}
                            name="Todays_Hot"
                            color="primary"
                          />
                        }
                        label="Todays Hot"
                      />
                    </Grid>

                    {/* Is_Popular */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Popular}
                            onChange={handleChange}
                            name="Is_Popular"
                            color="primary"
                          />
                        }
                        label="Is Popular"
                      />
                    </Grid>

                    {/* Is_Gift_Wrap_Available */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Gift_Wrap_Available}
                            onChange={handleChange}
                            name="Is_Gift_Wrap_Available"
                            color="primary"
                          />
                        }
                        label="Is Gift Wrap Available" />
                    </Grid>

                    {/* Is_Upcoming */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Upcoming}
                            onChange={handleChange}
                            name="Is_Upcoming"
                            color="primary"
                          />
                        }
                        label="Is Upcoming"
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Trending_Today}
                            onChange={handleChange}
                            name="Trending_Today"
                            color="primary"
                          />
                        }
                        label="Trending Today" />
                    </Grid>

                    {/* Is_Upcoming */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Today_Recommendations}
                            onChange={handleChange}
                            name="Today_Recommendations"
                            color="primary"
                          />
                        }
                        label="Recommendations"
                      />
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <span style={{ color: "red" }}>{errorMessage}</span>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditPendingProductPartner.propTypes = {
  className: PropTypes.string
};

export default EditPendingProductPartner;