import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
  Box, Container, makeStyles, Button, Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { NewgetInventory } from 'src/store/actions/node-actions/nodepagination';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';
import { deleteInventory } from 'src/store/actions/inventoryAction';
import ConfirmDialog from 'src/components/dialogs/confirmDialog';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const NewInventoryListView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { Partner_Product_Id, Product_Name, Partner_Name } = location.state || {};
  //permissions
  const RolePermissions = useSelector(state => state.rolepermission.Roles);
  const roleUserType = useSelector(state => state.auth.user);
  const Login_Details = useSelector(state => state.auth.user);

  const [InventoryIdle, setInventoryIdle] = useState([]);
  const [copen, setCopen] = useState(false);
  const [cmessage, setCmessage] = useState('');
  const [ctitle, setCtitle] = useState('');
  const [inventories, setInventories] = useState([]);
  const [filters, setFilters] = React.useState({
    "search": '',
    "Partner_Product_Id": Partner_Product_Id,
    "search_by_filter": 'All'
  });

  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = useState({ PageNo: 1, PageSize: 10, SortBy: "Inventory_Id", SortOrder: "DESC" })

  const handleLimitChange = event => {
    setPagination({ ...pagination, PageSize: +event.target.value, PageNo: 1 });
  };
  const handlePageChange = (event, newPage) => {
    setPagination({ ...pagination, PageSize: pagination.PageSize, PageNo: newPage + 1 });
  };

  const callBackPagination = async (status, data, pagination) => {
    if (status) {
      setInventories(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({ PageNo: 1, PageSize: 10, SortBy: "Inventory_Id", SortOrder: "ASC" });
    }
  }

  const bhandleClose = (value) => {
    setCopen(false);
    if (value) {
      const deleteData = {
        Inventory_Id: InventoryIdle,
        Modified_By: Login_Details?.Email_Id
      };
      dispatch(deleteInventory(deleteData, filters, pagination, callBackPagination));
    }
  };

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      inventoryCall();
    }
  }, [pagination])

  const [testFilters, setTestFilters] = useState({});
  useEffect(() => {
    if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
      setTestFilters(filters);
      searchFunction();
    }
  }, [filters]);

  const deleteInventoryHandler = categoryData => async e => {
    setCopen(true);
    setCtitle("Alert!");
    setCmessage("Are you sure to delete?");
    setInventoryIdle(categoryData);
  };

  const searchFunction = () => {
    const searchpagination = {
      PageNo: 1, PageSize: 10, SortBy: "Inventory_Id", SortOrder: "ASC"
    }
    dispatch(NewgetInventory(filters, searchpagination, callBackPagination))
  }

  const inventoryCall = () => {
    dispatch(NewgetInventory(filters, pagination, callBackPagination))
  };

  useEffect(() => {
    dispatch(getRolePermissions(Login_Details));
    dispatch(NewgetInventory(filters, pagination, callBackPagination))
  }, []);

  // Sorting 
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Inventory_Id');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPagination({ PageNo: 1, PageSize: pagination?.PageSize, SortBy: property, SortOrder: order === 'asc' ? "ASC" : "DESC" })

  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          // component={RouterLink}
          // to="/app/product/partner-management"
          variant="text"
          size="small"
          color="inherit"
          onClick={() => navigate(-1)}
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            {Partner_Name}, {Product_Name} Inventory
          </Typography>
        </Box>
        <Toolbar filters={filters} setfilters={setFilters} RolePermissions={RolePermissions} roleUserType={roleUserType} Product_Name={Product_Name} />
        <Box mt={3}>
          <Results
            allInventories={inventories}
            deleteInventoryHandler={deleteInventoryHandler}
            allInventoriesNoFilter={inventories}
            handleLimitChange={handleLimitChange}
            handlePageChange={handlePageChange}
            order={order}
            orderBy={orderBy}
            pagination={pagination}
            setPagination={setPagination}
            createSortHandler={createSortHandler}
            RolePermissions={RolePermissions}
            roleUserType={roleUserType}
          />
        </Box>
      </Container>
      <ConfirmDialog inputMessage={cmessage} titleMsg={ctitle} open={copen} onClose={bhandleClose} />
    </Page>
  );
};

export default NewInventoryListView;