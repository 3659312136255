import { Card, CardContent, Grid, makeStyles, Divider } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
    root: {},
    images: {
        width: 300,
        height: 200
    },
}));

const VehicleDetailsList = ({ className, state, data, ...rest }) => {
    const classes = useStyles();

    return (
        <Card className={clsx(classes.root)}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            {/* Del_Vehicle_Detail_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Del Vehicle Detail ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Del_Vehicle_Detail_Id ? state?.Del_Vehicle_Detail_Id : data?.Del_Vehicle_Detail_Id}
                                </Typography>
                            </Grid>

                            {/* Vehicle Availability City ID */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Vehicle Availability City ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Vehicle_Availability_City_Id ? state?.Vehicle_Availability_City_Id : data?.Vehicle_Availability_City_Id}
                                </Typography>
                            </Grid>

                            {/* City */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    City
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.City ? state?.City : data?.City}
                                </Typography>
                            </Grid>

                            {/* User_Email_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Delivery User Email ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state?.Del_User_Email_Id ? state?.Del_User_Email_Id : data?.Del_User_Email_Id}> {state?.Del_User_Email_Id ? state?.Del_User_Email_Id : data?.Del_User_Email_Id?.length > 25 ?
                                        <span>{state?.Del_User_Email_Id ? state?.Del_User_Email_Id : data?.Del_User_Email_Id?.substring(0, 25)}...</span> : state?.Del_User_Email_Id ? state?.Del_User_Email_Id : data?.Del_User_Email_Id}</span>
                                </Typography>
                            </Grid>

                            {/* Vehicle_Number */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Vehicle Number
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Vehicle_Number ? state?.Vehicle_Number : data?.Vehicle_Number}
                                </Typography>
                            </Grid>

                            {/* Vehicle_Color */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Vehicle Color
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Vehicle_Color ? state?.Vehicle_Color : data?.Vehicle_Color}
                                </Typography>
                            </Grid>

                            {/* Vehicle_Type */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Vehicle Type
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Type ? state?.Type : data?.Type}
                                </Typography>
                            </Grid>

                            {/* Vehicle_Model */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Vehicle Model
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Model ? state?.Model : data?.Model}
                                </Typography>
                            </Grid>

                            {/* RC_Number */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    RC Number
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.RC_Number ? state?.RC_Number : data?.RC_Number}
                                </Typography>
                            </Grid>

                            {/* Vehicle_Comfort_Type */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Vehicle Comfort Type
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Vehicle_Comfort_Type ? state?.Vehicle_Comfort_Type : data?.Vehicle_Comfort_Type}
                                </Typography>
                            </Grid>

                            {/* Vehicle_Seating_Capacity */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Vehicle Seating Capacity
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Vehicle_Seating_Capacity ? state?.Vehicle_Seating_Capacity : data?.Vehicle_Seating_Capacity}
                                </Typography>
                            </Grid>

                            <Grid item xs={2} />
                            <Grid item xs={2} />
                            <Grid item xs={7} />
                        </Grid>
                    </Grid>

                    {/*  column 2 */}
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            {/* State */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    State
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.State ? state?.State : data?.State}
                                </Typography>
                            </Grid>

                            {/* Country */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Country
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Country ? state?.Country : data?.Country}
                                </Typography>
                            </Grid>

                            {/* User_Ride_Vehicle_Fare_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    User Ride Vehicle Fare ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.User_Ride_Vehicle_Fare_Id ? state?.User_Ride_Vehicle_Fare_Id : data?.User_Ride_Vehicle_Fare_Id}
                                </Typography>
                            </Grid>

                            {/*Regular_Fare_Per_Kilometer */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Regular Fare Per Kilometer
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Regular_Fare_Per_Kilometer ? state?.Regular_Fare_Per_Kilometer : data?.Regular_Fare_Per_Kilometer}
                                </Typography>
                            </Grid>

                            {/* Is_Active_Vehicle */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Active Vehicle
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Is_Active_Vehicle ? state?.Is_Active_Vehicle : data?.Is_Active_Vehicle}
                                </Typography>
                            </Grid>

                            {/* Insurance_Number */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Insurance Number
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Insurance_Number ? state?.Insurance_Number : data?.Insurance_Number}
                                </Typography>
                            </Grid>

                            {/*Created_Date */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Created_Date ? state?.Created_Date : data?.Created_Date}
                                </Typography>
                            </Grid>

                            {/*Updated_Date */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Updated Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Updated_Date ? state?.Updated_Date : data?.Updated_Date}
                                </Typography>
                            </Grid>

                            {/* Created_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state?.Created_By ? state?.Created_By : data?.Created_By}> {state?.Created_By ? state?.Created_By : data?.Created_By?.length > 25 ?
                                        <span>{state?.Created_By ? state?.Created_By : data?.Created_By?.substring(0, 25)}...</span> : state?.Created_By ? state?.Created_By : data?.Created_By}</span>
                                </Typography>
                            </Grid>

                            {/* Updated_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Updated By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state?.Updated_By ? state?.Updated_By : data?.Updated_By}> {state?.Updated_By ? state?.Updated_By : data?.Updated_By?.length > 25 ?
                                        <span>{state?.Updated_By ? state?.Updated_By : data?.Updated_By?.substring(0, 25)}...</span> : state?.Updated_By ? state?.Updated_By : data?.Updated_By}</span>
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Divider />
                {/* Insurance_File_Path */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A' }}
                    >
                        Insurance File Path
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ paddingBottom: '1rem' }}
                    >
                        {state?.Insurance_File_Path ? state?.Insurance_File_Path : data?.Insurance_File_Path}
                    </Typography>
                </Grid>
                {/* Vehcle_Image_Path */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A' }}
                    >
                        Vehicle Image Path
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ paddingBottom: '1rem' }}
                    >
                        <a target="_blank" href={state?.Vehcle_Image_Path ? state?.Vehcle_Image_Path : data?.Vehcle_Image_Path}>
                            <img
                                className={classes.images}
                                alt="Vehicle Image Path"
                                src={state?.Vehcle_Image_Path ? state?.Vehcle_Image_Path : data?.Vehcle_Image_Path}
                                variant="square"
                            />
                        </a>
                    </Typography>
                </Grid>
                {/* RC_Pic_Front_Path */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A' }}
                    >
                        RC Pic Front Path
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ paddingBottom: '1rem' }}
                    >
                        <a target="_blank" href={state?.RC_Pic_Front_Path ? state?.RC_Pic_Front_Path : data?.RC_Pic_Front_Path}>
                            <img
                                className={classes.images}
                                alt="RC Pic Front Path"
                                src={state?.RC_Pic_Front_Path ? state?.RC_Pic_Front_Path : data?.RC_Pic_Front_Path}
                                variant="square"
                            />
                        </a>
                    </Typography>
                </Grid>

                {/* RC_Pic_Back_Path */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A' }}
                    >
                        RC Pic Back Path
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ paddingBottom: '1rem' }}
                    >
                        <a target="_blank" href={state?.RC_Pic_Back_Path ? state?.RC_Pic_Back_Path : data?.RC_Pic_Back_Path}>
                            <img
                                className={classes.images}
                                alt="RC Pic Back Path"
                                src={state?.RC_Pic_Back_Path ? state?.RC_Pic_Back_Path : data?.RC_Pic_Back_Path}
                                variant="square"
                            />
                        </a>
                    </Typography>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default VehicleDetailsList;