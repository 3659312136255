import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    makeStyles
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    }
}));

const headCells = [
    { id: 'Cms_Static_Id', numeric: false, disablePadding: true, label: 'CMS Static ID' },
    { id: 'Name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'Created_Date', numeric: false, disablePadding: false, label: 'Created Date' },
    { id: 'Module_Type', numeric: false, disablePadding: false, label: 'Module Type' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
    className,
    allPrivacyPolicyTermsCondition,
    deletePrivacyPolicyTermsConditionsHandler,
    pagination,
    setPagination,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    createSortHandler,
    allPrivacyPolicyTermsConditionNoFilter,
    RolePermissions,
    roleUserType,
    ...rest
}) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [selectedprivacypolicytermsconditionIds, setSelectedprivacypolicytermsconditionIds] = useState([]);

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const handleClose = (value) => {
        setOpen(false);
    };

    const handleSelectAll = event => {
        let newSelectedprivacypolicytermsconditionIds;
        if (event.target.checked) {
            newSelectedprivacypolicytermsconditionIds = allPrivacyPolicyTermsCondition.map(privacypolicytermscondition => privacypolicytermscondition.Cms_Static_Id);
        } else {
            newSelectedprivacypolicytermsconditionIds = [];
        }
        setSelectedprivacypolicytermsconditionIds(newSelectedprivacypolicytermsconditionIds);
    };

    const handleSelectOne = (event, id) => {
        setSelectedprivacypolicytermsconditionIds(selectedprivacypolicytermsconditionIds.filter(record => {
            for (let i = 0; i < allPrivacyPolicyTermsConditionNoFilter.length; i++) {
                if (record === allPrivacyPolicyTermsConditionNoFilter[i].Cms_Static_Id) {
                    return true;
                }
            }
            return false;
        }))
        const selectedIndex = selectedprivacypolicytermsconditionIds.indexOf(id);
        let newSelectedprivacypolicytermsconditionIds = [];
        if (selectedIndex === -1) {
            newSelectedprivacypolicytermsconditionIds = newSelectedprivacypolicytermsconditionIds.concat(
                selectedprivacypolicytermsconditionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedprivacypolicytermsconditionIds = newSelectedprivacypolicytermsconditionIds.concat(
                selectedprivacypolicytermsconditionIds.slice(1)

            );
        } else if (selectedIndex === selectedprivacypolicytermsconditionIds.length - 1) {
            newSelectedprivacypolicytermsconditionIds = newSelectedprivacypolicytermsconditionIds.concat(
                selectedprivacypolicytermsconditionIds.slice(0, -1)
            );
        } else if (selectedIndex > 0) {
            newSelectedprivacypolicytermsconditionIds = newSelectedprivacypolicytermsconditionIds.concat(
                selectedprivacypolicytermsconditionIds.slice(0, selectedIndex),
                selectedprivacypolicytermsconditionIds.slice(selectedIndex + 1),
            );
        }
        setSelectedprivacypolicytermsconditionIds(newSelectedprivacypolicytermsconditionIds);
    };

    const deleteSelectedPrivacyPolicyTermsConditionsHandler = () => async e => {
        setOpen(true);
        setTitle("Alert!");
        setMessage("This feature is under development.");
    };

    const editPrivacypolicyTermsconditionsHandler = privacypolicytermsconditionsData => e => {
        navigate('/app/edit-Content-Management', { state: privacypolicytermsconditionsData });
    };
    const viewcontentHandler = privacypolicytermsconditionsData => e => {
        navigate('/app/view-contentmanagement', { state: privacypolicytermsconditionsData });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedprivacypolicytermsconditionIds.filter(record => {
                                            for (let i = 0; i < allPrivacyPolicyTermsConditionNoFilter.length; i++) {
                                                if (record === allPrivacyPolicyTermsConditionNoFilter[i].Cms_Static_Id) {
                                                    return true;
                                                }
                                            }
                                            return false;
                                        }).length === allPrivacyPolicyTermsCondition.length}
                                        color="primary"
                                        indeterminate={
                                            selectedprivacypolicytermsconditionIds.filter(record => {
                                                for (let i = 0; i < allPrivacyPolicyTermsConditionNoFilter.length; i++) {
                                                    if (record === allPrivacyPolicyTermsConditionNoFilter[i].Cms_Static_Id) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            }).length > 0 &&
                                            selectedprivacypolicytermsconditionIds.filter(record => {
                                                for (let i = 0; i < allPrivacyPolicyTermsConditionNoFilter.length; i++) {
                                                    if (record === allPrivacyPolicyTermsConditionNoFilter[i].Cms_Static_Id) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            }).length < allPrivacyPolicyTermsCondition.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} > &nbsp;&nbsp;&nbsp;&nbsp;Actions&nbsp;&nbsp;&nbsp;

                                    {selectedprivacypolicytermsconditionIds.filter(record => {
                                        for (let i = 0; i < allPrivacyPolicyTermsConditionNoFilter.length; i++) {
                                            if (record === allPrivacyPolicyTermsConditionNoFilter[i].Cms_Static_Id) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }).length > 0 && <Button disabled={selectedprivacypolicytermsconditionIds.filter(record => {
                                        for (let i = 0; i < allPrivacyPolicyTermsConditionNoFilter.length; i++) {
                                            if (record === allPrivacyPolicyTermsConditionNoFilter[i].Cms_Static_Id) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }).length <= 0} onClick={deleteSelectedPrivacyPolicyTermsConditionsHandler()}>
                                            <DeleteSweepIcon
                                                style={{ color: '#ff7a7a', fontSize: 25 }}
                                            />
                                        </Button>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allPrivacyPolicyTermsCondition) &&
                                (allPrivacyPolicyTermsCondition).map(privacypolicytermscondition => (
                                    <TableRow
                                        hover
                                        key={privacypolicytermscondition.Cms_Static_Id}
                                        selected={
                                            selectedprivacypolicytermsconditionIds.indexOf(privacypolicytermscondition.Cms_Static_Id) !== -1
                                        }
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={
                                                    selectedprivacypolicytermsconditionIds.indexOf(privacypolicytermscondition.Cms_Static_Id) !== -1
                                                }
                                                onChange={event =>
                                                    handleSelectOne(event, privacypolicytermscondition.Cms_Static_Id)
                                                }
                                                value="true"
                                            />
                                        </TableCell>
                                        <TableCell width="18%">{privacypolicytermscondition.Cms_Static_Id}</TableCell>
                                        <TableCell width="18%">{privacypolicytermscondition.Name}</TableCell>
                                        <TableCell >{privacypolicytermscondition.Created_Date}</TableCell>
                                        <TableCell>{privacypolicytermscondition.Module_Type}</TableCell>
                                        <TableCell style={{ color: '#1665D8' }}>
                                            {privacypolicytermscondition.Status}
                                        </TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Content_Management_Write?.Value === "Content_Management_Write" && RolePermissions?.Content_Management_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Button
                                                    onClick={editPrivacypolicyTermsconditionsHandler(privacypolicytermscondition)}
                                                >
                                                    <CreateIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                                <Button>
                                                    <DeleteIcon
                                                        onClick={deletePrivacyPolicyTermsConditionsHandler(privacypolicytermscondition)}
                                                        style={{ color: '#9EA0A5' }}
                                                    />
                                                </Button>
                                                <Tooltip title="content Details">
                                                    <Button
                                                        onClick={viewcontentHandler(privacypolicytermscondition)}
                                                        color="primary" >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            : null}
                                        {RolePermissions?.Content_Management_Read?.Value === "Content_Management_Read" && RolePermissions?.Content_Management_Read?.Is_Read === 1 &&
                                            <TableCell>
                                                <Tooltip title="content Details">
                                                    <Button
                                                        onClick={viewcontentHandler(privacypolicytermscondition)}
                                                        color="primary" >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allPrivacyPolicyTermsCondition) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt=""/>
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <Pagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
            <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    allPrivacyPolicyTermsCondition: PropTypes.array.isRequired
};

export default Results;