import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  UpdateNewsletterAction
} from './../../store/actions/newsletteraction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const EditNewsletter = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = location;

  const errorMessage = useSelector(state => state.newsletters.newsletterUpdate.error);
  const isSubmitted = useSelector(state => state.newsletters.newsletterUpdate.isLoading);
  const createdBy = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        News_Letter_Id: state.News_Letter_Id ? state.News_Letter_Id : "",
        Modified_By: createdBy,
        News_Letter_Email: state.News_Letter_Email ? state.News_Letter_Email : "",
        News_Letter_Ip: state.News_Letter_Ip ? state.News_Letter_Ip : "",
        Status: state.Status ? state.Status : "",
        Subscribe: state.Subscribe > 0 ? true : false
      }}
      validationSchema={
        Yup.object().shape({
          News_Letter_Email: Yup.string().required(' News letter Email is required.'),
          News_Letter_Ip: Yup.string().required(' News Letter Ip is required.'),
          Status: Yup.string().required('Status  is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          if (values.Subscribe) {
            formValues.Subscribe = 1;
          } else {
            formValues.Subscribe = 0;
          }
          dispatch(UpdateNewsletterAction(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="News Letter Email"
                        name="News_Letter_Email"
                        value={values.News_Letter_Email}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    {/* News_Letter_Ip */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.News_Letter_Ip && errors.News_Letter_Ip)}
                        fullWidth
                        helperText={touched.News_Letter_Ip && errors.News_Letter_Ip}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="News Letter IP"
                        name="News_Letter_Ip"
                        value={values.News_Letter_Ip}
                        variant="outlined"
                      />

                    </Grid>
                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label=" Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Subscribe}
                              onChange={handleChange}
                              name="Subscribe"
                              color="primary"
                            />
                          }
                          label="Subscribe"
                        />
                      </Box>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmitted ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmitted}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditNewsletter.propTypes = {
  className: PropTypes.string
};

export default EditNewsletter;