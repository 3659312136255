import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteContentManagementStaticAction,
} from '../../store/actions/privacypolicyTermsconditionsAction';
import ConfirmDialog from '../../components/dialogs/confirmDialog';
import { paginationContentManagement } from 'src/store/actions/node-actions/nodepagination';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const PrivacyPolicyListView = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    //permissions
    const RolePermissions = useSelector(state => state.rolepermission.Roles);
    const roleUserType = useSelector(state => state.auth.user);
    const Login_Details = useSelector(state => state.auth.user);

    const [content, setContent] = useState([]);
    const [filters, setFilters] = React.useState({
        search: '',
        search_by_filter: 'All',
    });
    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = useState({ PageNo: 1, PageSize: 10, SortBy: "Cms_Static_Id", SortOrder: "ASC" })

    const handleLimitChange = event => {
        setPagination({ ...pagination, PageSize: +event.target.value, PageNo: 1 });
    };

    const handlePageChange = (event, newPage) => {
        setPagination({ ...pagination, PageSize: pagination.PageSize, PageNo: newPage + 1 });
    };
    const callBackPagination = async (status, data, pagination) => {
        if (status) {
            setContent(data);
        }
        setPageOld(true);
        if (pagination) {
            setPagination(pagination);
        } else {
            setPagination({ PageNo: 1, PageSize: 10, SortBy: "Cms_Static_Id", SortOrder: "ASC" });
        }
    }

    useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            contentCall();
        }
    }, [pagination])

    const [testFilters, setTestFilters] = useState({});
    useEffect(() => {
        if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
            setTestFilters(filters);
            searchFunction();
        }
    }, [filters]);

    const searchFunction = () => {
        const searchpagination = {
            PageNo: 1, PageSize: 10, SortBy: "Cms_Static_Id", SortOrder: "ASC"
        }
        dispatch(paginationContentManagement(filters, searchpagination, callBackPagination))
    }

    const contentCall = () => {
        dispatch(paginationContentManagement(filters, pagination, callBackPagination))
    };

    useEffect(() => {
        dispatch(paginationContentManagement(filters, pagination, callBackPagination))
        dispatch(getRolePermissions(Login_Details));
    }, []);

    // confirm dailogbox
    const [copen, setCopen] = useState(false);
    const [ctitle, setCtitle] = useState('');
    const [cmessage, setCmessage] = useState('');
    const [privacypolicytermsconditionsIdle, setPrivacypolicyTermsconditionsIdle] = useState('');

    const chandleClose = (value) => {
        setCopen(false);

        if (value) {
            const deleteData = {
                Cms_Static_Id: privacypolicytermsconditionsIdle.Cms_Static_Id,
                Modified_By: Login_Details.Email_Id
            };
            dispatch(deleteContentManagementStaticAction(deleteData,filters, pagination, callBackPagination));
        }
    };

    //Delete handler
    const deletePrivacyPolicyTermsConditionsHandler = privacypolicytermsconditionsData => async e => {
        setCopen(true);
        setCtitle("Alert!");
        setCmessage("Are you sure to delete?");
        setPrivacypolicyTermsconditionsIdle(privacypolicytermsconditionsData);
    };

    // Sorting 
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Cms_Static_Id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPagination({ PageNo: 1, PageSize: pagination?.PageSize, SortBy: property, SortOrder: order === 'asc' ? "ASC" : "DESC" })
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/content-management"
                    variant="text"
                    size="small"
                    color="inherit"
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        Content Management
                    </Typography>
                </Box>
                <Toolbar filters={filters} setfilters={setFilters} RolePermissions={RolePermissions} roleUserType={roleUserType} />
                <Box mt={3}>
                    <Results
                        allPrivacyPolicyTermsCondition={content}
                        allPrivacyPolicyTermsConditionNoFilter={content}
                        deletePrivacyPolicyTermsConditionsHandler={deletePrivacyPolicyTermsConditionsHandler}
                        pagination={pagination}
                        setPagination={setPagination}
                        handleLimitChange={handleLimitChange}
                        handlePageChange={handlePageChange}
                        order={order}
                        orderBy={orderBy}
                        createSortHandler={createSortHandler}
                        RolePermissions={RolePermissions}
                        roleUserType={roleUserType}
                    />
                </Box>
            </Container>
            <ConfirmDialog inputMessage={cmessage} titleMsg={ctitle} open={copen} onClose={chandleClose} />
        </Page>
    );
};

export default PrivacyPolicyListView;