import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Deal_Id', numeric: false, disablePadding: false, label: 'Deal ID' },
  { id: 'Title', numeric: false, disablePadding: false, label: 'Deal Title' },
  { id: 'Name', numeric: false, disablePadding: false, label: 'Partner Name' },
  { id: 'Discount', numeric: true, disablePadding: false, label: 'Discount' },
  { id: 'Start_Date', numeric: false, disablePadding: false, label: 'Start Date' },
  { id: 'End_Date', numeric: false, disablePadding: false, label: 'End Date' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allDeal,
  deleteDealHandler,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allDealNoFilter,
  pagination,
  setPagination,
  RolePermissions,
  roleUserType,
  ...rest
}) => {

  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedDealIds, setSelectedDealIds] = useState([]);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedDealIds;
    if (event.target.checked) {
      newSelectedDealIds = allDeal.map(deal => deal.Deal_Id);
    } else {
      newSelectedDealIds = [];
    }
    setSelectedDealIds(newSelectedDealIds);
  };

  const handleSelectOne = (event, id) => {
    setSelectedDealIds(selectedDealIds.filter(record => {
      for (let i = 0; i < allDealNoFilter.length; i++) {
        if (record == allDealNoFilter[i].Deal_Id) {
          return true;
        }
      }
      return false;
    }))
    const selectedIndex = selectedDealIds.indexOf(id);
    let newSelectedDealIds = [];
    if (selectedIndex === -1) {
      newSelectedDealIds = newSelectedDealIds.concat(selectedDealIds, id);
    } else if (selectedIndex === 0) {
      newSelectedDealIds = newSelectedDealIds.concat(
        selectedDealIds.slice(1)
      );
    } else if (selectedIndex === selectedDealIds.length - 1) {
      newSelectedDealIds = newSelectedDealIds.concat(
        selectedDealIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedDealIds = newSelectedDealIds.concat(
        selectedDealIds.slice(0, selectedIndex),
        selectedDealIds.slice(selectedIndex + 1)
      );
    }
    setSelectedDealIds(newSelectedDealIds);
  };

  const deleteSelectedDealHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const editDealHandler = dealData => e => {
    navigate('/app/edit-deal', { state: dealData });
  };

  const visibleDealHandler = dealData => e => {
    navigate("/app/deals-details", { state: dealData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedDealIds.filter(record => {
                      for (let i = 0; i < allDealNoFilter.length; i++) {
                        if (record == allDealNoFilter[i].Deal_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allDeal.length}
                    color="primary"
                    indeterminate={
                      selectedDealIds.filter(record => {
                        for (let i = 0; i < allDealNoFilter.length; i++) {
                          if (record == allDealNoFilter[i].Deal_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedDealIds.filter(record => {
                        for (let i = 0; i < allDealNoFilter.length; i++) {
                          if (record == allDealNoFilter[i].Deal_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allDeal.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> Actions &nbsp;&nbsp;&nbsp;
                  {selectedDealIds.filter(record => {
                    for (let i = 0; i < allDealNoFilter.length; i++) {
                      if (record == allDealNoFilter[i].Deal_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedDealIds.filter(record => {
                    for (let i = 0; i < allDealNoFilter.length; i++) {
                      if (record == allDealNoFilter[i].Deal_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedDealHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allDeal) &&
                (allDeal).map(deal => (
                  <TableRow
                    hover
                    key={deal.Deal_Id}
                    selected={
                      selectedDealIds.indexOf(deal.Deal_Id) !== -1
                    }  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedDealIds.indexOf(deal.Deal_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, deal.Deal_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell width="10%">{deal.Deal_Id}</TableCell>
                    <TableCell width="12%" >{deal.Title}</TableCell>
                    <TableCell >{deal.Partner_Name}</TableCell>
                    <TableCell >{deal.Discount_Type === "A" ? "₹" + deal.Discount : deal.Discount + "%"}</TableCell>
                    <TableCell width="10%">{deal.Start_Date}</TableCell>
                    <TableCell width="10%">{deal.End_Date}</TableCell>
                    <TableCell width="10%" style={{ color: '#1665D8' }}> {deal.Status} </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Deal_Management_Write?.Value === "Deal_Management_Write" && RolePermissions?.Deal_Management_Write?.Is_Write === 1) ?
                      <TableCell style={{ justifyContent: 'center' }}>
                        <Tooltip title="Deals Details">
                          <Button
                            onClick={visibleDealHandler(deal)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        <Button
                          onClick={editDealHandler(deal)} >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                          <DeleteIcon
                            onClick={deleteDealHandler(deal)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Deal_Management_Read?.Value === "Deal_Management_Read" && RolePermissions?.Deal_Management_Read?.Is_Read === 1 &&
                      <TableCell style={{ justifyContent: 'center' }}>
                        <Tooltip title="Deals Details">
                          <Button
                            onClick={visibleDealHandler(deal)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allDeal) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allDeal: PropTypes.array.isRequired
};

export default Results;