import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
    TableSortLabel,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PaymentsIcon from '@mui/icons-material/Payments';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    buttonRoot: {
        minWidth: '7px',
        padding: '6px'
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    },
}));

const headCells = [
    { id: 'B2B_User_Products_Bidding_Id', numeric: false, disablePadding: false, label: 'B2B User Products Bidding ID' },
    { id: 'Seller_Partner_Name', numeric: false, disablePadding: false, label: 'Seller Partner Name' },
    { id: 'Buyer_Partner_Name', numeric: true, disablePadding: false, label: 'Buyer Partner Name' },
    { id: 'Bidding_Product_Name', numeric: true, disablePadding: false, label: 'Bidding Product Name' },
    { id: 'Bidding_Status', numeric: true, disablePadding: false, label: 'Bidding Status' },
];

const Results = ({
    className,
    allB2BUserProductsBidding,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    pagination,
    setPagination,
    createSortHandler,
    allB2BUserProductsBiddingNoFilter,
    RolePermissions,
    roleUserType,
    notifyB2BProductPartnersHandler,
    ...rest
}) => {
    const navigate = useNavigate();
    const classes = useStyles();

    const editB2BUserProductsBiddingHandler = b2buserprodbidData => e => {
        navigate('/app/edit-b2b-user-products-bidding', { state: b2buserprodbidData });
    };

    const visibleB2BUserProductsBiddingHandler = b2buserprodbidData => e => {
        navigate("/app/view-b2b-user-products-bidding", { state: b2buserprodbidData });
    };

    const OpenB2BUserProdBidQuotesHandler = b2buserprodbidData => e => {
        navigate("/app/b2b-user-product-bidding-quotes", { state: b2buserprodbidData });
    };

    const OpenB2BProdBidPartnerPaymentsHandler = b2buserprodbidData => e => {
        navigate("/app/b2b-prod-bid-partner-payments", { state: b2buserprodbidData });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1400}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                    (RolePermissions?.B2B_User_Products_Bidding_Write?.Value === "B2B_User_Products_Bidding_Write" && RolePermissions?.B2B_User_Products_Bidding_Write?.Is_Write === 1) ?
                                    <TableCell >Bidding Quotes</TableCell>
                                    : null}

                                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                    (RolePermissions?.B2B_User_Products_Bidding_Write?.Value === "B2B_User_Products_Bidding_Write" && RolePermissions?.B2B_User_Products_Bidding_Write?.Is_Write === 1) ?
                                    <TableCell >Payments</TableCell>
                                    : null}
                                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                    (RolePermissions?.B2B_User_Products_Bidding_Write?.Value === "B2B_User_Products_Bidding_Write" && RolePermissions?.B2B_User_Products_Bidding_Write?.Is_Write === 1) ?
                                    <TableCell >Notifications</TableCell>
                                    : null}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-2rem' }} >Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allB2BUserProductsBidding) &&
                                (allB2BUserProductsBidding).map((b2buserprodbid, i) => (
                                    <TableRow
                                        hover
                                        key={i}
                                    >
                                        <TableCell>{b2buserprodbid.B2B_User_Products_Bidding_Id}</TableCell>
                                        <TableCell>{b2buserprodbid.Seller_Partner_Name?.substring(0, 20)}...</TableCell>
                                        <TableCell>{b2buserprodbid.Buyer_Partner_Name?.substring(0, 20)}...</TableCell>
                                        <TableCell>{b2buserprodbid.Bidding_Product_Name?.substring(0, 20)}...</TableCell>
                                        <TableCell>{b2buserprodbid.Bidding_Status}</TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.B2B_User_Products_Bidding_Write?.Value === "B2B_User_Products_Bidding_Write" && RolePermissions?.B2B_User_Products_Bidding_Write?.Is_Write === 1) ?
                                            <TableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Tooltip title="Bidding Quotes">
                                                    <Button>
                                                        <RequestQuoteIcon
                                                            onClick={OpenB2BUserProdBidQuotesHandler(b2buserprodbid)}
                                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#9EA0A5', fontSize: 20, }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            : null}
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.B2B_User_Products_Bidding_Write?.Value === "B2B_User_Products_Bidding_Write" && RolePermissions?.B2B_User_Products_Bidding_Write?.Is_Write === 1) ?
                                            <TableCell>&nbsp;
                                                <Tooltip title="Partner Payments">
                                                    <Button disabled={b2buserprodbid?.Advance_Payment_Status_From_User !== 1 ? true : false}>
                                                        <PaymentsIcon
                                                            onClick={OpenB2BProdBidPartnerPaymentsHandler(b2buserprodbid)}
                                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#9EA0A5', fontSize: 20, }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            : null}
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.B2B_User_Products_Bidding_Write?.Value === "B2B_User_Products_Bidding_Write" && RolePermissions?.B2B_User_Products_Bidding_Write?.Is_Write === 1) ?
                                            <TableCell>&nbsp;&nbsp;&nbsp;
                                                <Tooltip title="Partner Notifications">
                                                    <Button>
                                                        <NotificationsActiveIcon
                                                            onClick={notifyB2BProductPartnersHandler(b2buserprodbid)}
                                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#9EA0A5', fontSize: 20, }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            : null}
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.B2B_User_Products_Bidding_Write?.Value === "B2B_User_Products_Bidding_Write" && RolePermissions?.B2B_User_Products_Bidding_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="B2B User Products Bidding History">
                                                    <Button
                                                        onClick={visibleB2BUserProductsBiddingHandler(b2buserprodbid)}
                                                        color="primary"
                                                    >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                                <Button
                                                    onClick={editB2BUserProductsBiddingHandler(b2buserprodbid)}
                                                >
                                                    <CreateIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                            </TableCell>
                                            : null}
                                        {RolePermissions?.B2B_User_Products_Bidding_Read?.Value === "B2B_User_Products_Bidding_Read" && RolePermissions?.B2B_User_Products_Bidding_Read?.Is_Read === 1 &&
                                            <TableCell>
                                                <Tooltip title="B2B User Products Bidding History">
                                                    <Button
                                                        onClick={visibleB2BUserProductsBiddingHandler(b2buserprodbid)}
                                                        color="primary"
                                                    >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allB2BUserProductsBidding) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <Pagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    allB2BUserProductsBidding: PropTypes.array.isRequired
};

export default Results;