import {
  OFFER_UPDATE_REQUEST,
  OFFER_UPDATE_SUCCESS,
  OFFER_UPDATE_FAIL,
} from '../actions/offersvisitorAction'

const initialState = {
  offerUpdate: { offer: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OFFER_UPDATE_REQUEST:
      return { ...state, offerUpdate: { offer: {}, error: '', isLoading: true } };
    case OFFER_UPDATE_SUCCESS:
      return { ...state, offerUpdate: { offer: state.offerUpdate.offer, error: '', isLoading: false } };
    case OFFER_UPDATE_FAIL:
      return { ...state, offerUpdate: { offer: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}