import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Box,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { ConnectedFocusError } from "focus-formik-error";
import { updateCitylocation } from 'src/store/actions/citylocationAction';
import { CircularProgress } from '@mui/material';

const EditCityLocation = ({ className, state, data, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState();
    const isSubmited = useSelector(state => state.citylocation.citylocationUpdate.isLoading);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    return (
        <Formik
            initialValues={{
                City_ID: data?.City_ID ? data?.City_ID : "",
                Updated_By: loginEmail,
                Location_Id: data?.Location_Id ? data?.Location_Id : "",
                Is_Active: data?.Is_Active > 0 ? true : false,
                Location_Name: data?.Location_Name ? data?.Location_Name : "",
                Zipcode: data?.Zipcode ? data?.Zipcode : ""
            }}

            validationSchema={
                Yup.object().shape({
                    Location_Name: Yup.string().required('Location Name is required.'),
                    Zipcode: Yup.string().required('Pin Code is required.')
                })}

            onSubmit={(values, { setErrors, setSubmitting }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};

                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    if (values.Is_Active) {
                        formValues.Is_Active = 1;
                    } else {
                        formValues.Is_Active = 0;
                    }
                    dispatch(updateCitylocation(formValues, state, navigate));
                }
            }
            }
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit} >
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={5} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Location_Name && errors.Location_Name)}
                                                fullWidth
                                                helperText={touched.Location_Name && errors.Location_Name}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Location Name"
                                                name="Location_Name"
                                                value={values.Location_Name}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={5} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Zipcode && errors.Zipcode)}
                                                fullWidth
                                                helperText={touched.Zipcode && errors.Zipcode}
                                                label="Pin Code"
                                                name="Zipcode"
                                                type="number"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.Zipcode}
                                                variant="outlined"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                                                }}
                                                min={0}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onBlur={handleBlur}
                                                            checked={values.Is_Active}
                                                            onChange={handleChange}
                                                            name="Is_Active"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Is Active"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Box display="flex" pl={2} >
                        <span style={{ color: "red" }}>{errorMessage}</span>
                    </Box>

                    <Box display="flex" p={2}>
                        {isSubmited ?
                            <CircularProgress />
                            :
                            <Button
                                disabled={isSubmited}
                                type="submit"
                                style={{ backgroundColor: '#27B6CC' }}
                                variant="contained"
                            >
                                {/* Save details */}
                                <span style={{ color: 'white' }}>Save details</span>
                            </Button>
                        }
                    </Box>
                </form>
            )}
        </Formik>
    );
};

EditCityLocation.propTypes = {
    className: PropTypes.string
};

export default EditCityLocation;