import config from './config';
import { toast } from 'react-toastify';
import { paginationDeliveryTicket } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const DELIVERY_TICKET_GET_REQUEST = "DELIVERY_TICKET_GET_REQUEST";
export const DELIVERY_TICKET_GET_SUCCESS = "DELIVERY_TICKET_GET_SUCCESS";
export const DELIVERY_TICKET_GET_FAIL = "DELIVERY_TICKET_GET_FAIL";
export const DELIVERY_TICKET_UPDATE_REQUEST = "DELIVERY_TICKET_UPDATE_REQUEST";
export const DELIVERY_TICKET_UPDATE_SUCCESS = "DELIVERY_TICKET_UPDATE_SUCCESS";
export const DELIVERY_TICKET_UPDATE_FAIL = "DELIVERY_TICKET_UPDATE_FAIL";
export const DELIVERY_TICKET_CREATE_REQUEST = "DELIVERY_TICKET_CREATE_REQUEST";
export const DELIVERY_TICKET_CREATE_SUCCESS = "DELIVERY_TICKET_CREATE_SUCCESS";
export const DELIVERY_TICKET_CREATE_FAIL = "DELIVERY_TICKET_CREATE_FAIL";
export const DELIVERY_PROFILES_REQUEST = "DELIVERY_PROFILES_REQUEST";
export const DELIVERY_PROFILES_SUCCESS = "DELIVERY_PROFILES_SUCCESS";
export const DELIVERY_PROFILES_FAIL = "DELIVERY_PROFILES_FAIL";

/*=============================================================
                edit Delivery Ticket Action
===============================================================*/
export const updatedeliveryTicketAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: DELIVERY_TICKET_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Delivery_Ticket/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DELIVERY_TICKET_UPDATE_SUCCESS
        });
        toast('Delivery Ticket updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/delivery-tickets');
      } else {
        dispatch({
          type: DELIVERY_TICKET_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: DELIVERY_TICKET_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

/*=============================================================
                Delete delivery ticket Action
===============================================================*/
export const deletedeliveryTicketAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Delivery_Ticket/Delete`, formData);
    if (data) {
      toast('Delivery Ticket deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationDeliveryTicket(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

/*=============================================================
              Create Delivery Ticket Action
===============================================================*/
export const createdeliveryTicketAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: DELIVERY_TICKET_CREATE_REQUEST
  });
  nodeCudApi.post(`/Delivery_Ticket/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DELIVERY_TICKET_CREATE_SUCCESS
        });
        toast('Delivery Ticket created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/delivery-tickets');
      } else {
        dispatch({
          type: DELIVERY_TICKET_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: DELIVERY_TICKET_CREATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

//Get users
export const getAlldeliveryprofilesAction = (emailid) => async dispatch => {
  try {
    let formData = {
      "Del_User_Email_Id": emailid,
       "Records_Filter": "FILTER"
    };
    dispatch({
      type: DELIVERY_PROFILES_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Delivery_Profile/Get_All_Profile_dropdown_search?PageNo=1&PageSize=${config.pageSize}&SortBy=Del_User_Email_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: DELIVERY_PROFILES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: DELIVERY_PROFILES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again", 3000);
    dispatch({
      type: DELIVERY_PROFILES_FAIL,
      payload: err
    });
  }
};

//Get Ticket Action
export const getDeliveryTicketAction = (formData, callBackDeliveryTicketData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Delivery_Ticket/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Del_Ticket_Id&SortOrder=ASC`, formData);
    if (data) {
      callBackDeliveryTicketData(data)
    }
  } catch (err) {
  }
};