import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { createGlobalCouponAction, getAllGlobalCouponsAction } from 'src/store/actions/globalcouponAction';
import { CircularProgress } from '@mui/material';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const statusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const cashbackType = [
    {
        value: '',
        label: '-Please Select-'
    },
    {
        value: 'A',
        label: 'Amount'
    },
    {
        value: 'P',
        label: 'Percentage'
    }
];

const CashbacklabelOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'UPTO',
        label: 'UPTO'
    },
    {
        value: 'FLAT',
        label: 'FLAT'
    }
];

const useStyles = makeStyles(() => ({
    root: {},
    images: {
        width: "100%",
        height: 100,
        borderRadius: 5,
        marginBottom: 15
    },
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    },
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const AddGlobalCoupon = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    //all Global Coupons
    useEffect(() => {
        let formData = {
            search: '',
            search_by_filter: '',
            Records_Filter: 'ALL',
        }
        dispatch(getAllGlobalCouponsAction(formData));
    }, []);

    const isSubmitted = useSelector(state => state.globalcoupon.globalcouponCreate.isLoading);
    const errorMessage = useSelector(state => state.globalcoupon.globalcouponCreate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    //get globalcoupons
    const globalcoupons = useSelector(state => state.globalcoupon.globalcoupons.all||[]);

    return (
        <Formik
            initialValues={{
                Name: "",
                Code: "",
                Coupon_Count: 0,
                Min_Order_Value: "",
                CashBack: "",
                CashBack_Type: "",
                Cashback_Label: "",
                Description: "",
                Start_Date: "",
                End_Date: "",
                Status: "",
                Is_Expired: false,
                Created_By: loginEmail,
            }}
            validationSchema={
                Yup.object().shape({
                    Name: Yup.string().min(3, "Coupon Name must be at least 3 characters.").required('Coupon Name is required.'),
                    Code: Yup.string().notOneOf(globalcoupons.map(item => item['Code']), "Coupon Code is already exist.").required('Coupon Code is required.'),
                    Coupon_Count: Yup.number().min(0, "Coupon Count should not be less than 0.").required('Coupon Count is required.'),
                    Min_Order_Value: Yup.number().min(0, "Min Order Value should not be less than 0.").required('Min Order Value is required.'),
                    CashBack_Type: Yup.string().required('Cashback Type is required.'),
                    CashBack: Yup.number().when("CashBack_Type", (CashBack_Type, schema) => {
                        if (CashBack_Type === "A") {
                            return schema.min(1, "Cashback Amount must be at least 1.").required('Cashback Amount is required.');
                        } else if (CashBack_Type === "P") {
                            return schema.min(1, "Cashback Percentage must be at least 1.").max(100, "Cashback Percentage must be less than 100.").required('Cashback Percentage is required.');
                        }
                    }),
                    Cashback_Label: Yup.string().required('Cashback Label is required.'),
                    Start_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require Start Date greater than or equal to today.").required("Start Date is required."),
                    End_Date: Yup.date().when("Start_Date", (Start_Date, schema) => Start_Date && schema.min(Start_Date, "End date can't be before Start date.")).required("End Date is required."),
                    Status: Yup.string().required('Status is required.'),
                    Description: Yup.string().min(20, 'Description must be at least 20 characters.').required('Description is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    if (values.Is_Expired) {
                        formValues.Is_Expired = 1;
                    } else {
                        formValues.Is_Expired = 0;
                    }
                    dispatch(createGlobalCouponAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        {/* Name */}
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Name && errors.Name)}
                                                fullWidth
                                                helperText={touched.Name && errors.Name}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Coupon Name"
                                                name="Name"
                                                value={values.Name}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        {/* Code */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Code && errors.Code)}
                                                fullWidth
                                                helperText={touched.Code && errors.Code}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Coupon Code"
                                                name="Code"
                                                value={values.Code}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        {/* Coupon_Count */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Coupon_Count && errors.Coupon_Count)}
                                                fullWidth
                                                helperText={touched.Coupon_Count && errors.Coupon_Count}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Coupon Count"
                                                name="Coupon_Count"
                                                value={values.Coupon_Count}
                                                variant="outlined"
                                                type="number"
                                            />
                                        </Grid>
                                        {/* Min_Order_Value */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Min_Order_Value && errors.Min_Order_Value)}
                                                fullWidth
                                                helperText={touched.Min_Order_Value && errors.Min_Order_Value}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Min Order Value"
                                                name="Min_Order_Value"
                                                value={values.Min_Order_Value}
                                                variant="outlined"
                                                type="number"
                                            />
                                        </Grid>
                                        {/* CashBack_Type */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.CashBack_Type && errors.CashBack_Type)}
                                                fullWidth
                                                helperText={touched.CashBack_Type && errors.CashBack_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Cashback Type"
                                                name="CashBack_Type"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.CashBack_Type}
                                                variant="outlined"  >
                                                {cashbackType.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>))}
                                            </TextField>
                                        </Grid>
                                        {/*CashBack*/}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.CashBack && errors.CashBack)}
                                                fullWidth
                                                helperText={touched.CashBack && errors.CashBack}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label={"Cashback " + (values.CashBack_Type === "A" ? "Amount ₹" : values.CashBack_Type === "P" ? "Percentage %" : "")}
                                                name="CashBack"
                                                value={values.CashBack}
                                                type="number"
                                                variant="outlined" >
                                            </TextField>
                                        </Grid>
                                        {/* Cashback Label input field */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Cashback_Label && errors.Cashback_Label)}
                                                fullWidth
                                                helperText={touched.Cashback_Label && errors.Cashback_Label}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Cashback Label"
                                                name="Cashback_Label"
                                                select
                                                SelectProps={{ native: true }}
                                                InputLabelProps={{ shrink: true }}
                                                value={values.Cashback_Label}
                                                variant="outlined"
                                            >
                                                {CashbacklabelOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>))}
                                            </TextField>
                                        </Grid>
                                        {/*  Start_Date */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Start_Date && errors.Start_Date)}
                                                fullWidth
                                                helperText={touched.Start_Date && errors.Start_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Start Date"
                                                type="date"
                                                name="Start_Date"
                                                defaultValue={values.Start_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        {/* End_Date */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.End_Date && errors.End_Date)}
                                                fullWidth
                                                helperText={touched.End_Date && errors.End_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="End Date"
                                                type="date"
                                                name="End_Date"
                                                defaultValue={values.End_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        {/* status */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>))}
                                            </TextField>
                                        </Grid>
                                        {/* Is_Expired */}
                                        <Grid item md={6} xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onBlur={handleBlur}
                                                            checked={values.Is_Expired}
                                                            onChange={handleChange}
                                                            name="Is_Expired"
                                                            color="primary"
                                                        />}
                                                    label="Is Expired"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            Description
                                            <TextareaAutosize
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Description"
                                                value={values.Description}
                                                onChange={handleChange}
                                                name="Description"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                            >
                                            </TextareaAutosize>
                                            <span style={{ color: "red" }}>
                                                {((touched.Description && errors.Description))}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>
                        <Box display="flex" p={2}>
                            {isSubmitted ? <CircularProgress /> :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>}
                        </Box>
                    </Card>
                </form>)}
        </Formik>);
};
AddGlobalCoupon.propTypes = {
    className: PropTypes.string
};
export default AddGlobalCoupon;