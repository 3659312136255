import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    CardHeader,
    Divider,
    ListItemAvatar,
    ListItemText
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";
import { getAllB2BESIDeliveryPersonsAction, getAllB2BPartnerDeliveryPersonsAction, getsingleB2BOrderAction, updateB2BOrderStatusAction } from 'src/store/actions/b2borderAction';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles(() => ({
    images: {
        width: 120,
        height: 120,
        borderRadius: 5,
        marginBottom: 15
    },
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    },
    image: {
        width: 50,
        height: 50
    }
}));

const allorderstatus = [
    {
        value: 'NEW',
        label: 'NEW'
    },
    {
        value: 'ORDERED',
        label: 'ORDERED'
    },
    {
        value: 'ACCEPTED',
        label: 'ACCEPTED'
    },
    {
        value: 'REJECTED',
        label: 'REJECTED'
    },
    {
        value: 'PACKED',
        label: 'PACKED'
    },
    {
        value: 'SHIPPED',
        label: 'SHIPPED'
    },
    {
        value: 'OUT-FOR-DELIVERY',
        label: 'OUT-FOR-DELIVERY'
    },
    {
        value: 'IN-TRANSIT',
        label: 'IN-TRANSIT'
    },
    {
        value: 'DELIVERED',
        label: 'DELIVERED'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
    {
        value: 'PARTIAL-CANCELLATION',
        label: 'PARTIAL-CANCELLATION'
    },
    {
        value: 'RETURNED',
        label: 'RETURNED'
    }, {
        value: 'PARTIAL-RETURNED',
        label: 'PARTIAL-RETURNED'
    },

];

const alllineitemstatus = [
    {
        value: 'NEW',
        label: 'NEW'
    },
    {
        value: 'ORDERED',
        label: 'ORDERED'
    },
    {
        value: 'ACCEPTED',
        label: 'ACCEPTED'
    },
    {
        value: 'REJECTED',
        label: 'REJECTED'
    },
    {
        value: 'PACKED',
        label: 'PACKED'
    },
    {
        value: 'SHIPPED',
        label: 'SHIPPED'
    },
    {
        value: 'OUT-FOR-DELIVERY',
        label: 'OUT-FOR-DELIVERY'
    },
    {
        value: 'IN-TRANSIT',
        label: 'IN-TRANSIT'
    },
    {
        value: 'DELIVERED',
        label: 'DELIVERED'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
    {
        value: 'RETURNED',
        label: 'RETURNED'
    },
];

const ShippingchannelpartnerOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Partner',
        label: 'Partner'
    },
    {
        value: 'ESI',
        label: 'ESI'
    },
    {
        value: 'Thirdparty',
        label: 'Thirdparty'
    },
];

const ShippingTypeOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'LIVE',
        label: 'LIVE'
    },
    {
        value: 'NORMAL',
        label: 'NORMAL'
    },
    {
        value: 'STANDARD',
        label: 'STANDARD'
    }
];

const deliverymessage = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Delay due to Rain',
        label: 'Delay due to Rain'
    },
    {
        value: 'Delay due to Traffic',
        label: 'Delay due to Traffic'
    },
    {
        value: 'Delay due to Accident',
        label: 'Delay due to Accident'
    },
    {
        value: 'Delay due to Heavy Orders',
        label: 'Delay due to Heavy Orders'
    },
    {
        value: 'Delay due to Vehicle Repair',
        label: 'Delay due to Vehicle Repair'
    }
];

const cancelreasontype = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Out of Stock',
        label: 'Out of Stock'
    },
    {
        value: 'High Demand',
        label: 'High Demand'
    },
    ,
    {
        value: 'By Mistakenly Places Order',
        label: 'By Mistakenly Places Order'
    },
    {
        value: 'Bought from other Partner',
        label: 'Bought from other Partner'
    },
    ,
    {
        value: 'Delivery Time is too long',
        label: 'Delivery Time is too long'
    },
    {
        value: 'Product Damaged',
        label: 'Product Damaged'
    },
    {
        value: 'Product Quality',
        label: 'Product Quality'
    },
    {
        value: 'Product Size',
        label: 'Product Size'
    },
    {
        value: 'Product Fitting is not good',
        label: 'Product Fitting is not good'
    },
    {
        value: 'Other Reason',
        label: 'Other Reason'
    },

];

const EditB2BOrderHistory = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const location = useLocation();
    let { state } = location;

    const Orders = useSelector(state => state?.b2borders?.b2bsingleorder?.all)
    const esidelipersons = useSelector(state => state?.b2borders?.b2bOrderesidelipersons?.all)
    const partnerdelipersons = useSelector(state => state?.b2borders?.b2bOrderpartnerdelipersons?.all)
    const isb2bOrderUpdate = useSelector(state => state?.b2borders?.b2borderUpdate?.isLoading);

    const [errorMessage, setErrorMessage] = useState({ global: { key: '', message: '' } });

    useEffect(() => {
        const formData = {
            "Records_Filter": "FILTER",
            "Shopper_Order_Id": state?.Shopper_Order_Id
        };
        //get esi delivery persons
        const formData1 = {
            "Role": ""
        };
        //get partner delivery persons
        const formData2 = {
            "Records_Filter": "FILTER",
            "Role": "PARTNER_DELIVERY",
            "Partner_ID": state?.Seller_Partner_Detail_Id
        };
        dispatch(getsingleB2BOrderAction(formData));
        dispatch(getAllB2BESIDeliveryPersonsAction(formData1));
        dispatch(getAllB2BPartnerDeliveryPersonsAction(formData2));
    }, []);

    let orderdata = []
    if (Orders) {
        for (let i = 0; i < Orders?.Billing_Address?.length; i++) {
            let billing = Orders.Billing_Address
            for (let j = 0; j < billing?.length; j++) {
                let financial = Orders.Financial_Summary
                for (let k = 0; k < financial?.length; k++) {
                    let payment = Orders.Payment_Summary
                    for (let l = 0; l < financial?.length; l++) {
                        orderdata.push({

                            Shopper_Order_Id: Orders?.Shopper_Order_Id,
                            Order_Status: Orders?.Order_Status,
                            Is_Approved: Orders?.Is_Approved,
                            Shopper_Email_Id: Orders?.Shopper_Email_Id,
                            Seller_Partner_Detail_Id: Orders?.Seller_Partner_Detail_Id,
                            Buyer_Partner_Detail_Id: Orders?.Buyer_Partner_Detail_Id,

                            Address_Type: billing[i]?.Address_Type,
                            Billing_Address_Id: billing[i]?.Billing_Address_Id,
                            City: billing[i]?.City,
                            Country: billing[i]?.Country,
                            Created_By: billing[i]?.Created_By,
                            Door_No: billing[i]?.Door_No,
                            Email_Id: billing[i]?.Email_Id,
                            Google_Map_Location: billing[i]?.Google_Map_Location,
                            Land_Mark: billing[i]?.Land_Mark,
                            Location: billing[i]?.Location,
                            Mobile: billing[i]?.Mobile,
                            State: billing[i]?.State,
                            Street: billing[i]?.Street,
                            Zip: billing[i]?.Zip,

                            Cashback_To_User: financial[j]?.Cashback_To_User,
                            Delivery_Person_Tip: financial[j]?.Delivery_Person_Tip,
                            Total_Order_Amount: financial[j]?.Total_Order_Amount,
                            User_Billable_Amount_After_Taxes: financial[j]?.User_Billable_Amount_After_Taxes,
                            User_Billable_Amount_Before_Taxes: financial[j]?.User_Billable_Amount_Before_Taxes,
                            User_Shipping_Fee: financial[j]?.User_Shipping_Fee,
                            User_Total_Tax_Amount: financial[j]?.User_Total_Tax_Amount,

                            Payment_Date: payment[k]?.Payment_Date,
                            Payment_Method: payment[k]?.Payment_Method,
                            Payment_Reference_Id: payment[k]?.Payment_Reference_Id,
                            Payment_Status: payment[k]?.Payment_Status,
                            User_Billable_Amount: payment[k]?.User_Billable_Amount,
                        })
                    }
                }
            }
        }
    }

    let allOrderDetails = []
    if (Orders) {
        for (let i = 0; i < Orders?.Item_Data?.Item_Master_Data?.length; i++) {
            let lineitemdata = Orders?.Item_Data?.Item_Master_Data
            allOrderDetails.push({
                Shopper_Order_Id: Orders?.Shopper_Order_Id,
                Order_Status: Orders?.Order_Status,
                Is_Approved: Orders?.Is_Approved,
                Shopper_Email_Id: Orders?.Shopper_Email_Id,
                Seller_Partner_Detail_Id: Orders?.Seller_Partner_Detail_Id,
                Buyer_Partner_Detail_Id: Orders?.Buyer_Partner_Detail_Id,

                Item_Master_Id: lineitemdata[i]?.Item_Master_Id,
                Line_Item_Status: lineitemdata[i]?.Line_Item_Status,
                Is_Item_Return_Flag: lineitemdata[i]?.Is_Item_Return_Flag,
                Product_Id: lineitemdata[i]?.Product_Id,
                Product_Image: lineitemdata[i]?.Product_Image,
                Product_Name: lineitemdata[i]?.Product_Name,
                Brand_Id: lineitemdata[i]?.Brand_Id,
                Brand_Image_Path: lineitemdata[i]?.Brand_Image_Path,
                Brand_Name: lineitemdata[i]?.Brand_Name,
                MRP: lineitemdata[i]?.MRP,
                Max_Days_To_Return: lineitemdata[i]?.Max_Days_To_Return,
                Quantity: lineitemdata[i]?.Quantity,
                Selling_Price: lineitemdata[i]?.Selling_Price,
                Order_Date: lineitemdata[i]?.Order_Date,
                Partner_Details_Id: lineitemdata[i]?.Partner_Details_Id,
                Payment_Status: lineitemdata[i]?.Payment_Status,
                Is_Approved: lineitemdata[i]?.Is_Approved,

                Net_Price: lineitemdata[i]?.Item_Financial_Summary[0]?.Net_Price,
                Partner_Total_Price: lineitemdata[i]?.Item_Financial_Summary[0]?.Partner_Total_Price,
                User_Billable_Amount: lineitemdata[i]?.Item_Financial_Summary[0]?.User_Billable_Amount,
                User_Total_Tax_Amount: lineitemdata[i]?.Item_Financial_Summary[0]?.User_Total_Tax_Amount,

                Address_Type: lineitemdata[i]?.Shipping_Address[0]?.Address_Type,
                City: lineitemdata[i]?.Shipping_Address[0]?.City,
                Country: lineitemdata[i]?.Shipping_Address[0]?.Country,
                Created_By: lineitemdata[i]?.Shipping_Address[0]?.Created_By,
                Door_No: lineitemdata[i]?.Shipping_Address[0]?.Door_No,
                Email_Id: lineitemdata[i]?.Shipping_Address[0]?.Email_Id,
                Google_Map_Location: lineitemdata[i]?.Shipping_Address[0]?.Google_Map_Location,
                Land_Mark: lineitemdata[i]?.Shipping_Address[0]?.Land_Mark,
                Location: lineitemdata[i]?.Shipping_Address[0]?.Location,
                Mobile: lineitemdata[i]?.Shipping_Address[0]?.Mobile,
                Shipping_Address_Id: lineitemdata[i]?.Shipping_Address[0]?.Shipping_Address_Id,
                State: lineitemdata[i]?.Shipping_Address[0]?.State,
                Street: lineitemdata[i]?.Shipping_Address[0]?.Street,
                Zip: lineitemdata[i]?.Shipping_Address[0]?.Zip,

                Shipping_ID: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_ID,
                Shipping_Fee: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Fee,
                Shipping_Tax: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Tax,
                Shipping_Total: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Total,
                Shipping_Channel: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Channel,
                Shipping_Type: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Type,
                Live_Delivered_By_Email: lineitemdata[i]?.Shipping_Summary[0]?.Live_Delivered_By_Email,
                Live_Delivered_By_Name: lineitemdata[i]?.Shipping_Summary[0]?.Live_Delivered_By_Name,
                Live_Delivery_Person_Phone: lineitemdata[i]?.Shipping_Summary[0]?.Live_Delivery_Person_Phone,
                Thirdparty_Channel_Partner_Name: lineitemdata[i]?.Shipping_Summary[0]?.Thirdparty_Channel_Partner_Name,
                Thirdparty_Delivery_Person_Name: lineitemdata[i]?.Shipping_Summary[0]?.Thirdparty_Delivery_Person_Name,
                Thirdparty_Delivery_Phone: lineitemdata[i]?.Shipping_Summary[0]?.Thirdparty_Delivery_Phone,
                Thirdparty_Shipping_Method: lineitemdata[i]?.Shipping_Summary[0]?.Thirdparty_Shipping_Method,
                Thirdparty_Shipping_Tracking_Number: lineitemdata[i]?.Shipping_Summary[0]?.Thirdparty_Shipping_Tracking_Number,
                Thirdparty_Tracking_Verification_URL: lineitemdata[i]?.Shipping_Summary[0]?.Thirdparty_Tracking_Verification_URL,
                Delivery_Message: lineitemdata[i]?.Shipping_Summary[0]?.Delivery_Message,
                Expected_Delivery_Date: lineitemdata[i]?.Shipping_Summary[0]?.Expected_Delivery_Date,
                Expected_Delivery_Time: lineitemdata[i]?.Shipping_Summary[0]?.Expected_Delivery_Time,
                Del_Order_Id: lineitemdata[i]?.Shipping_Summary[0]?.Del_Order_Id,
                Cancel_Return_User_Comment: lineitemdata[i]?.Cancel_Return_User_Comment,
                Cancel_Return_User_Reason_Type: lineitemdata[i]?.Cancel_Return_User_Reason_Type,
            })
        }
    }

    return (
        <>
            <Card>
                <CardHeader title="Order Details" />
                <Divider />
                <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                    <Grid item xs={6}>
                        <div style={{ marginLeft: '1rem' }}>Order ID: {orderdata[0]?.Shopper_Order_Id}</div>
                        <div style={{ marginLeft: '1rem' }}>Order Status: {orderdata[0]?.Order_Status}</div>
                        <div style={{ marginLeft: '1rem' }}>Shopper Email ID: {orderdata[0]?.Shopper_Email_Id}</div>
                        <div style={{ marginLeft: '1rem' }}>Buyer Partner ID: {orderdata[0]?.Buyer_Partner_Detail_Id}</div>
                        <div style={{ fontWeight: 'bold', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Billing Details:</div>
                        <div style={{ marginLeft: '1rem' }}>{orderdata[0]?.Billing_Address_Id}</div>
                        <div style={{ marginLeft: '1rem' }}>{orderdata[0]?.Email_Id}</div>
                        <div style={{ marginLeft: '1rem' }}>{orderdata[0]?.Address_Type}</div>
                        <div style={{ marginLeft: '1rem' }}>{orderdata[0]?.Land_Mark}</div>
                        <div style={{ marginLeft: '1rem' }}>{orderdata[0]?.City}</div>
                        <div style={{ marginLeft: '1rem' }}>{orderdata[0]?.State}</div>
                        <div style={{ marginLeft: '1rem' }}>{orderdata[0]?.Country}</div>
                        <div style={{ marginLeft: '1rem' }}>{orderdata[0]?.Zip}</div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ fontWeight: 'bold', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Payment Details:</div>
                        <div style={{ marginLeft: '1rem' }}>Total Order Amount: {orderdata[0]?.Total_Order_Amount}</div>
                        <div style={{ marginLeft: '1rem' }}>User Total Tax Amount: {orderdata[0]?.User_Total_Tax_Amount}</div>
                        <div style={{ marginLeft: '1rem' }}>Payment Date: {orderdata[0]?.Payment_Date}</div>
                        <div style={{ marginLeft: '1rem' }}>Payment Method: {orderdata[0]?.Payment_Method}</div>
                        <div style={{ marginLeft: '1rem' }}>Payment Reference ID: {orderdata[0]?.Payment_Reference_Id}</div>
                        <div style={{ marginLeft: '1rem' }}>Payment Status: {orderdata[0]?.Payment_Status}</div>
                        <div style={{ marginLeft: '1rem' }}>User Billable Amount: {orderdata[0]?.User_Billable_Amount}</div>
                    </Grid>
                </Grid>
                <CardContent>
                    {orderdata[0]?.Shopper_Order_Id &&
                        <Formik
                            initialValues={{

                                Order_ID: Orders?.Shopper_Order_Id ? Orders?.Shopper_Order_Id : "",
                                Order_Status: Orders?.Order_Status ? Orders?.Order_Status : "",
                                B2B_Email: Orders?.Shopper_Email_Id ? Orders?.Shopper_Email_Id : "",
                                Buyer_Partner_Detail_ID: Orders?.Buyer_Partner_Detail_Id ? Orders?.Buyer_Partner_Detail_Id : "",
                                Seller_Partner_Detail_ID: Orders?.Seller_Partner_Detail_Id ? Orders?.Seller_Partner_Detail_Id : "",
                                Item: [
                                    {
                                        Cancel_Comment: allOrderDetails?.Cancel_Return_User_Comment ? allOrderDetails?.Cancel_Return_User_Comment : "",
                                        Cancel_Reason: allOrderDetails?.Cancel_Return_User_Reason_Type ? allOrderDetails?.Cancel_Return_User_Reason_Type : "",
                                        Item_ID: allOrderDetails?.Item_Master_Id ? allOrderDetails?.Item_Master_Id : "",
                                        Item_Status: allOrderDetails?.Line_Item_Status ? allOrderDetails?.Line_Item_Status : "",
                                        Live_Del_Order_ID: allOrderDetails?.Del_Order_Id ? allOrderDetails?.Del_Order_Id : "",
                                        Live_Delivered_By_Email: allOrderDetails?.Live_Delivered_By_Email ? allOrderDetails?.Live_Delivered_By_Email : "",
                                        Live_Delivery_Person_Mobile_Number: allOrderDetails?.Live_Delivery_Person_Phone ? allOrderDetails?.Live_Delivery_Person_Phone : "",
                                        Live_Delivery_Person_Name: allOrderDetails?.Live_Delivered_By_Name ? allOrderDetails?.Live_Delivered_By_Name : "",
                                        Shipping_Channel: allOrderDetails?.Shipping_Channel ? allOrderDetails?.Shipping_Channel : "",
                                        Shipping_Type: allOrderDetails?.Shipping_Type ? allOrderDetails?.Shipping_Type : "",
                                        Shopper_Shipping_Id: allOrderDetails?.Shipping_ID ? allOrderDetails?.Shipping_ID : "",
                                        ThirdParty_Channel_Live_Tracking_URL: allOrderDetails?.Thirdparty_Tracking_Verification_URL ? allOrderDetails?.Thirdparty_Tracking_Verification_URL : "",
                                        ThirdParty_Delivery_Person_Mobile_Number: allOrderDetails?.Thirdparty_Delivery_Phone ? allOrderDetails?.Thirdparty_Delivery_Phone : "",
                                        ThirdParty_Delivery_Person_Name: allOrderDetails?.Thirdparty_Delivery_Person_Name ? allOrderDetails?.Thirdparty_Delivery_Person_Name : "",
                                        ThirdParty_Channel_Partner_Name: allOrderDetails?.Thirdparty_Channel_Partner_Name ? allOrderDetails?.Thirdparty_Channel_Partner_Name : "",
                                        ThirdParty_Shipping_Method: allOrderDetails?.Thirdparty_Shipping_Method ? allOrderDetails?.Thirdparty_Shipping_Method : "",
                                        ThirdParty_Shipping_Tracking_Number: allOrderDetails?.Thirdparty_Shipping_Tracking_Number ? allOrderDetails?.Thirdparty_Shipping_Tracking_Number : "",
                                        Delivery_Message: allOrderDetails?.Delivery_Message ? allOrderDetails?.Delivery_Message : "",
                                    }
                                ],
                            }}

                            onSubmit={(values, { setErrors, setSubmitting }) => {
                                let submitTest = true;
                                setSubmitting(false);
                                const errors = {};

                                setErrors(errors);
                                if (submitTest) {
                                    let formValues = JSON.parse(JSON.stringify(values));
                                    dispatch(updateB2BOrderStatusAction(formValues, navigate));
                                }
                            }
                            }
                        >
                            {({
                                handleBlur,
                                handleChange,
                                values,
                            }) => (
                                <>
                                    <ConnectedFocusError />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item md={8} xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item md={6} xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            label="Order Status"
                                                            name="Order_Status"
                                                            select
                                                            InputLabelProps={{ shrink: true }}
                                                            SelectProps={{ native: true }}
                                                            value={values.Order_Status}
                                                            variant="outlined"
                                                            disabled
                                                        >
                                                            {allorderstatus?.map(option => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </>
                            )}
                        </Formik>
                    }
                </CardContent>
                <Divider />
            </Card>

            {allOrderDetails.map((data, index) => (
                <Card style={{ marginTop: '2rem' }} key={index}>
                    <CardHeader title="Shipping Details" />
                    <Divider />
                    <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                        <Grid item xs={6}>
                            <div style={{ fontWeight: 'bold', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Line Item Details:</div>
                            <div style={{ marginLeft: '1rem' }}>Shopper Order ID: {data.Shopper_Order_Id}</div>
                            <div style={{ marginLeft: '1rem' }}>Item Master ID: {data.Item_Master_Id}</div>
                            <div style={{ marginLeft: '1rem' }}>Item Master Status: {data.Line_Item_Status}</div>
                            <div style={{ marginLeft: '1rem' }}>Partner ID: {data.Seller_Partner_Detail_Id}</div>
                            <div style={{ fontWeight: 'bold', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Shipping Address:</div>
                            <div style={{ marginLeft: '1rem' }}>{data?.Shipping_Address_Id}</div>
                            <div style={{ marginLeft: '1rem' }}>{data?.Email_Id}</div>
                            <div style={{ marginLeft: '1rem' }}>{data?.Address_Type}</div>
                            <div style={{ marginLeft: '1rem' }}>{data?.Land_Mark}</div>
                            <div style={{ marginLeft: '1rem' }}>{data?.City}</div>
                            <div style={{ marginLeft: '1rem' }}>{data?.State}</div>
                            <div style={{ marginLeft: '1rem' }}>{data?.Country}</div>
                            <div style={{ marginLeft: '1rem' }}>{data?.Zip}</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ fontWeight: 'bold', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Product Details:</div>
                            <div style={{ marginLeft: '1rem' }}>
                                <img
                                    className={classes.image}
                                    alt="Profile"
                                    src={data?.Product_Image}
                                    variant="square"
                                />
                            </div>
                            <div style={{ marginLeft: '1rem' }}>Product Name: {data.Product_Name}</div>
                            <div style={{ marginLeft: '1rem' }}>Quantity: {data.Quantity}</div>
                            <div style={{ marginLeft: '1rem' }}>Selling Price: {data.Selling_Price}</div>
                            <div style={{ marginLeft: '1rem' }}>MRP: {data.MRP}</div>
                            <div style={{ marginLeft: '1rem' }}>Max Days To Return: {data.Max_Days_To_Return}</div>
                            <div style={{ fontWeight: 'bold', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Payment Details:</div>
                            <div style={{ marginLeft: '1rem' }}>Partner Total Price: {data.Partner_Total_Price}</div>
                            <div style={{ marginLeft: '1rem' }}>Net Price: {data.Net_Price}</div>
                            <div style={{ marginLeft: '1rem' }}>User Billable Amount: {data.User_Billable_Amount}</div>
                            <div style={{ marginLeft: '1rem' }}>User Total Tax Amount: {data.User_Total_Tax_Amount}</div>
                        </Grid>
                    </Grid>

                    <CardContent>
                        <Formik
                            initialValues={{

                                Order_ID: Orders?.Shopper_Order_Id ? Orders?.Shopper_Order_Id : "",
                                Order_Status: Orders?.Order_Status ? Orders?.Order_Status : "",
                                B2B_Email: Orders?.Shopper_Email_Id ? Orders?.Shopper_Email_Id : "",
                                Buyer_Partner_Detail_ID: Orders?.Buyer_Partner_Detail_Id ? Orders?.Buyer_Partner_Detail_Id : "",
                                Seller_Partner_Detail_ID: Orders?.Seller_Partner_Detail_Id ? Orders?.Seller_Partner_Detail_Id : "",
                                Item: [
                                    {
                                        Cancel_Comment: data?.Cancel_Return_User_Comment ? data?.Cancel_Return_User_Comment : "",
                                        Cancel_Reason: data?.Cancel_Return_User_Reason_Type ? data?.Cancel_Return_User_Reason_Type : "",
                                        Item_ID: data?.Item_Master_Id ? data?.Item_Master_Id : "",
                                        Item_Status: data?.Line_Item_Status ? data?.Line_Item_Status : "",
                                        Live_Del_Order_ID: data?.Del_Order_Id ? data?.Del_Order_Id : "",
                                        Live_Delivered_By_Email: data?.Live_Delivered_By_Email ? data?.Live_Delivered_By_Email : "",
                                        Live_Delivery_Person_Mobile_Number: data?.Live_Delivery_Person_Phone ? data?.Live_Delivery_Person_Phone : "",
                                        Live_Delivery_Person_Name: data?.Live_Delivered_By_Name ? data?.Live_Delivered_By_Name : "",
                                        Shipping_Channel: data?.Shipping_Channel ? data?.Shipping_Channel : "",
                                        Shipping_Type: data?.Shipping_Type ? data?.Shipping_Type : "",
                                        Shopper_Shipping_Id: data?.Shipping_ID ? data?.Shipping_ID : "",
                                        ThirdParty_Channel_Live_Tracking_URL: data?.Thirdparty_Tracking_Verification_URL ? data?.Thirdparty_Tracking_Verification_URL : "",
                                        ThirdParty_Delivery_Person_Mobile_Number: data?.Thirdparty_Delivery_Phone ? data?.Thirdparty_Delivery_Phone : "",
                                        ThirdParty_Delivery_Person_Name: data?.Thirdparty_Delivery_Person_Name ? data?.Thirdparty_Delivery_Person_Name : "",
                                        ThirdParty_Channel_Partner_Name: data?.Thirdparty_Channel_Partner_Name ? data?.Thirdparty_Channel_Partner_Name : "",
                                        ThirdParty_Shipping_Method: data?.Thirdparty_Shipping_Method ? data?.Thirdparty_Shipping_Method : "",
                                        ThirdParty_Shipping_Tracking_Number: data?.Thirdparty_Shipping_Tracking_Number ? data?.Thirdparty_Shipping_Tracking_Number : "",
                                        Delivery_Message: data?.Delivery_Message ? data?.Delivery_Message : "",
                                    }
                                ],
                            }}

                            onSubmit={(values, { setErrors, setSubmitting }) => {
                                let submitTest = true;
                                setSubmitting(false);
                                const errors = {};

                                setErrors(errors);
                                if (submitTest) {
                                    let formValues = JSON.parse(JSON.stringify(values));
                                    dispatch(updateB2BOrderStatusAction(formValues, navigate, setErrorMessage, setErrors));
                                }
                            }
                            }
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                touched,
                                values,
                                setFieldValue,
                            }) => (
                                <>
                                    <ConnectedFocusError />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item md={8} xs={12}>
                                                <Grid container spacing={3}>
                                                    {/* Shipping_Channel input field with value */}
                                                    <Grid item md={6} xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            label="Shipping Channel"
                                                            name={`Item[0].Shipping_Channel`}
                                                            select
                                                            InputLabelProps={{ shrink: true }}
                                                            SelectProps={{ native: true }}
                                                            value={values.Item[0]?.Shipping_Channel}
                                                            variant="outlined"
                                                        >

                                                            {ShippingchannelpartnerOption?.map(option => (
                                                                <option key={option.value} value={option.value} >
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>

                                                    {/* Shipping_Type input field with value */}
                                                    <Grid item md={6} xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            label="Shipping Type"
                                                            name={`Item[0].Shipping_Type`}
                                                            select
                                                            InputLabelProps={{ shrink: true }}
                                                            SelectProps={{ native: true }}
                                                            value={values.Item[0]?.Shipping_Type}
                                                            variant="outlined"
                                                        >
                                                            {ShippingTypeOption?.map(option => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>

                                                    {/* Line_Item_Status input field with value */}
                                                    <Grid item md={6} xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            label="Shipping Status"
                                                            name={`Item[0].Item_Status`}
                                                            select
                                                            InputLabelProps={{ shrink: true }}
                                                            SelectProps={{ native: true }}
                                                            value={values.Item[0]?.Item_Status}
                                                            variant="outlined"
                                                        >
                                                            {alllineitemstatus?.map(option => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>

                                                    {/* Delivery_Message input field with value */}
                                                    <Grid item md={6} xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            label="Delivery Delay Message"
                                                            name={`Item[0].Delivery_Message`}
                                                            select
                                                            InputLabelProps={{ shrink: true }}
                                                            SelectProps={{ native: true }}
                                                            value={values.Item[0].Delivery_Message}
                                                            variant="outlined"
                                                        >
                                                            {deliverymessage?.map(option => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>

                                                    {values?.Item[0]?.Line_Item_Status === "CANCELLED" ?
                                                        <>
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Cancel Return User Reason Type"
                                                                    name={`Item[0].Cancel_Reason`}
                                                                    select
                                                                    InputLabelProps={{ shrink: true }}
                                                                    SelectProps={{ native: true }}
                                                                    value={values.Item[0].Cancel_Reason}
                                                                    variant="outlined"
                                                                >
                                                                    {cancelreasontype?.map(option => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Cancel Return User Comment"
                                                                    name={`Item[0].Cancel_Comment`}
                                                                    value={values.Item[0].Cancel_Comment}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            </Grid>
                                                        </>
                                                        : null
                                                    }
                                                    {values?.Item[0]?.Shipping_Channel === 'Thirdparty' ? (
                                                        <>
                                                            {/* ThirdParty_Channel_Partner_Name input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Thirdparty Channel Partner Name"
                                                                    name={`Item[0].ThirdParty_Channel_Partner_Name`}
                                                                    value={values.Item[0].ThirdParty_Channel_Partner_Name}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Thirdparty_Shipping_Method input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    helperText={touched.ThirdParty_Shipping_Method && errors.ThirdParty_Shipping_Method}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Thirdparty Shipping Method"
                                                                    name={`Item[0].ThirdParty_Shipping_Method`}
                                                                    value={values.Item[0].ThirdParty_Shipping_Method}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Thirdparty_Shipping_Tracking_Number input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Thirdparty Shipping Tracking Number"
                                                                    name={`Item[0].ThirdParty_Shipping_Tracking_Number`}
                                                                    value={values.Item[0].ThirdParty_Shipping_Tracking_Number}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Thirdparty_Tracking_Verification_URL input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Thirdparty Tracking Verification URL"
                                                                    name={`Item[0].ThirdParty_Channel_Live_Tracking_URL`}
                                                                    value={values.Item[0].ThirdParty_Channel_Live_Tracking_URL}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Thirdparty_Delivery_Person_Name input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Thirdparty Delivery Person Name"
                                                                    name={`Item[0].ThirdParty_Delivery_Person_Name`}
                                                                    value={values.Item[0].ThirdParty_Delivery_Person_Name}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* ThirdParty_Delivery_Person_Mobile_Number input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Thirdparty Delivery Phone"
                                                                    name={`Item[0].ThirdParty_Delivery_Person_Mobile_Number`}
                                                                    value={values.Item[0].ThirdParty_Delivery_Person_Mobile_Number}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Live_Delivered_By_Email input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Delivered By Email"
                                                                    name={`Item[0].Live_Delivered_By_Email`}
                                                                    value={values.Item[0].Live_Delivered_By_Email}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Live_Delivered_By_Name input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Delivered By Name"
                                                                    name={`Item[0].Live_Delivery_Person_Name`}
                                                                    value={values.Item[0].Live_Delivery_Person_Name}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Live_Delivery_Person_Phone input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Delivered Person Phone"
                                                                    name={`Item[0].Live_Delivery_Person_Mobile_Number`}
                                                                    value={values.Item[0].Live_Delivery_Person_Mobile_Number}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>

                                                        </>
                                                    ) : values?.Item[0]?.Shipping_Channel === 'ESI' ? (
                                                        <>
                                                            {/* Live_Delivered_By_Email input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <Autocomplete
                                                                    options={esidelipersons}
                                                                    groupBy={(option) => option?.firstLetter}
                                                                    id="combo-box-demo"
                                                                    getOptionLabel={(option) => option?.Del_User_Email_Id}
                                                                    onChange={(e, value) => {
                                                                        setFieldValue("Item[0].Live_Delivered_By_Email", value.Del_User_Email_Id ? value.Del_User_Email_Id : "");
                                                                        setFieldValue("Item[0].Live_Delivery_Person_Name", value.First_Name ? value.First_Name : "");
                                                                        setFieldValue("Item[0].Live_Delivery_Person_Mobile_Number", value.Mobile ? value.Mobile : "");
                                                                    }}
                                                                    value={esidelipersons && esidelipersons?.length > 0 ? esidelipersons?.filter((item) => item?.Del_User_Email_Id === values?.Item[0]?.Live_Delivered_By_Email)[0] : ""}
                                                                    renderOption={(option) => (
                                                                        <React.Fragment>
                                                                            <ListItemAvatar>
                                                                                <img
                                                                                    alt={option.type}
                                                                                    className={classes.image}
                                                                                    style={{
                                                                                        height: 48,
                                                                                        width: 48
                                                                                    }}
                                                                                    src={option?.Profile_Pic_Path}
                                                                                />
                                                                            </ListItemAvatar>
                                                                            <ListItemText
                                                                                secondary={option?.Del_User_Email_Id}
                                                                                primary={option?.First_Name + " " + option?.Last_Name}
                                                                            />
                                                                        </React.Fragment>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            placeholder="Search"
                                                                            variant="outlined"
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                autoComplete: 'off', // disable autocomplete and autofill,
                                                                                startAdornment: (
                                                                                    <React.Fragment>
                                                                                        <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                                        {params.InputProps.startAdornment}
                                                                                    </React.Fragment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    )}
                                                                />

                                                            </Grid>
                                                            {/* Live_Delivery_Person_Name input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Delivered By Name"
                                                                    name={`Item[0].Live_Delivery_Person_Name`}
                                                                    value={values.Item[0].Live_Delivery_Person_Name}
                                                                    variant="outlined"
                                                                    disabled
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Live_Delivery_Person_Mobile_Number input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Delivered Person Phone"
                                                                    name={`Item[0].Live_Delivery_Person_Mobile_Number`}
                                                                    value={values.Item[0].Live_Delivery_Person_Mobile_Number}
                                                                    variant="outlined"
                                                                    disabled
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>

                                                        </>
                                                    ) : (
                                                        <>
                                                            {/* Live_Delivered_By_Email input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <Autocomplete
                                                                    options={partnerdelipersons}
                                                                    groupBy={(option) => option?.firstLetter}
                                                                    id="combo-box-demo"
                                                                    getOptionLabel={(option) => option?.Del_User_Email_Id ? option?.Del_User_Email_Id : ""}
                                                                    onChange={(e, value) => {
                                                                        setFieldValue("Item[0].Live_Delivered_By_Email", value.Del_User_Email_Id ? value.Del_User_Email_Id : "");
                                                                        setFieldValue("Item[0].Live_Delivery_Person_Name", value.First_Name ? value.First_Name : "");
                                                                        setFieldValue("Item[0].Live_Delivery_Person_Mobile_Number", value.Mobile ? value.Mobile : "");
                                                                    }}
                                                                    value={partnerdelipersons && partnerdelipersons?.length > 0 ? partnerdelipersons && partnerdelipersons?.filter((item) => item?.Del_User_Email_Id === values?.Item[0]?.Live_Delivered_By_Email)[0] : ""}
                                                                    renderOption={(option) => (
                                                                        <React.Fragment>
                                                                            <ListItemAvatar>
                                                                                <img
                                                                                    alt={option.type}
                                                                                    className={classes.image}
                                                                                    style={{
                                                                                        height: 48,
                                                                                        width: 48
                                                                                    }}
                                                                                    src={option?.Profile_Pic_Path}
                                                                                />
                                                                            </ListItemAvatar>
                                                                            <ListItemText
                                                                                secondary={option?.Del_User_Email_Id}
                                                                                primary={option?.First_Name + " " + option?.Last_Name}
                                                                            />
                                                                        </React.Fragment>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            placeholder="Search"
                                                                            variant="outlined"
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                autoComplete: 'off', // disable autocomplete and autofill,
                                                                                startAdornment: (
                                                                                    <React.Fragment>
                                                                                        <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                                        {params.InputProps.startAdornment}
                                                                                    </React.Fragment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    )}
                                                                />

                                                            </Grid>
                                                            {/* Live_Delivery_Person_Name input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Delivered By Name"
                                                                    name={`Item[0].Live_Delivery_Person_Name`}
                                                                    value={values.Item[0].Live_Delivery_Person_Name}
                                                                    variant="outlined"
                                                                    disabled
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                            {/* Live_Delivery_Person_Mobile_Number input field with value */}
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    label="Delivered Person Phone"
                                                                    name={`Item[0].Live_Delivery_Person_Mobile_Number`}
                                                                    value={values.Item[0].Live_Delivery_Person_Mobile_Number}
                                                                    variant="outlined"
                                                                    disabled
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <Box display="flex" pl={2} >
                                        <span style={{ color: "red" }}>{errorMessage.global.message}</span>
                                    </Box>

                                    <Box display="flex" p={2}>
                                        {isb2bOrderUpdate ?
                                            <CircularProgress />
                                            :
                                            <Button
                                                type="submit"
                                                style={{ backgroundColor: '#27B6CC' }}
                                                variant="contained"
                                                onClick={handleSubmit}
                                            >
                                                <span style={{ color: 'white' }}>Save details</span>
                                            </Button>
                                        }
                                    </Box>

                                </>
                            )}
                        </Formik>
                    </CardContent>
                    <Divider />
                </Card>
            ))
            }
        </>
    )
}


export default EditB2BOrderHistory;