import {
    CORPORATE_AWARD_CREATE_REQUEST,
    CORPORATE_AWARD_CREATE_SUCCESS,
    CORPORATE_AWARD_CREATE_FAIL,
    CORPORATE_AWARD_UPDATE_REQUEST,
    CORPORATE_AWARD_UPDATE_SUCCESS,
    CORPORATE_AWARD_UPDATE_FAIL
} from '../actions/corporateawardAction'

const initialState = {
    corporateawardCreate: { corporateaward: {}, error: '', isLoading: false },
    corporateawardUpdate: { corporateaward: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CORPORATE_AWARD_CREATE_REQUEST:
            return { ...state, corporateawardCreate: { corporateaward: {}, error: '', isLoading: true } };
        case CORPORATE_AWARD_CREATE_SUCCESS:
            return { ...state, corporateawardCreate: { corporateaward: state.corporateawardCreate.corporateaward, error: '', isLoading: false } };
        case CORPORATE_AWARD_CREATE_FAIL:
            return { ...state, corporateawardCreate: { corporateaward: {}, error: action.payload, isLoading: false } };
        case CORPORATE_AWARD_UPDATE_REQUEST:
            return { ...state, corporateawardUpdate: { corporateaward: {}, error: '', isLoading: true } };
        case CORPORATE_AWARD_UPDATE_SUCCESS:
            return { ...state, corporateawardUpdate: { corporateaward: action.payload, error: '', isLoading: false } };
        case CORPORATE_AWARD_UPDATE_FAIL:
            return { ...state, corporateawardUpdate: { corporateaward: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}