import {
    ROLES_REQUEST,
    ROLES_SUCCESS,
    ROLES_FAIL,
    ROLE_GET_REQUEST,
    ROLE_GET_SUCCESS,
    ROLE_GET_FAIL,
    ROLE_CREATE_REQUEST,
    ROLE_CREATE_SUCCESS,
    ROLE_CREATE_FAIL,
    ROLE_UPDATE_REQUEST,
    ROLE_UPDATE_SUCCESS,
    ROLE_UPDATE_FAIL,
} from '../actions/roleActions';

const initialState = {
    roles: { all: [], error: '', isLoading: false },
    role: { role: {}, error: '', isLoading: false },
    roleCreate: { role: {}, error: '', isLoading: false },
    roleUpdate: { role: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ROLES_REQUEST:
            return { ...state, roles: { all: state.roles.all, error: '', isLoading: true } };
        case ROLES_SUCCESS:
            return { ...state, roles: { all: action.payload, error: '', isLoading: false } };
        case ROLES_FAIL:
            return { ...state, roles: { all: [], error: action.payload, isLoading: false } };
        case ROLE_GET_REQUEST:
            return { ...state, role: { role: {}, error: '', isLoading: true } };
        case ROLE_GET_SUCCESS:
            return { ...state, role: { role: action.payload, error: '', isLoading: false } };
        case ROLE_GET_FAIL:
            return { ...state, role: { role: {}, error: action.payload, isLoading: false } };
        case ROLE_CREATE_REQUEST:
            return { ...state, roleCreate: { role: {}, error: '', isLoading: true } };
        case ROLE_CREATE_SUCCESS:
            return { ...state, roleCreate: { role: action.payload, error: '', isLoading: false } };
        case ROLE_CREATE_FAIL:
            return { ...state, roleCreate: { role: {}, error: action.payload, isLoading: false } };
        case ROLE_UPDATE_REQUEST:
            return { ...state, roleUpdate: { role: {}, error: '', isLoading: true } };
        case ROLE_UPDATE_SUCCESS:
            return { ...state, roleUpdate: { role: action.payload, error: '', isLoading: false } };
        case ROLE_UPDATE_FAIL:
            return { ...state, roleUpdate: { role: {}, error: action.payload, isLoading: false } };
        default:
            return state;
    }
}
