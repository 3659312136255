import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';
import { CircularProgress } from '@mui/material';
import { updateCorpPopupAction } from 'src/store/actions/corporatepopupActions';

const statusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const useStyles = makeStyles(() => ({
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const EditCorpPopup = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const location = useLocation();
    let { state } = location;


    const isSubmitted = useSelector(state => state.corppopup.corppopupUpdate.isLoading);
    const errorMessage = useSelector(state => state.corppopup.corppopupUpdate.error);
    const modifiedBy = useSelector(state => state.auth.user.Email_Id);

    const decodeHTMLEntities = (text) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    };

    const removePTags = (htmlContent) => {
        if (!htmlContent) return '';

        const decodedContent = decodeHTMLEntities(htmlContent);
        return decodedContent
            .replace(/<\/?p[^>]*>/g, '\n')
            .replace(/&nbsp;/g, ' ')
            .replace(/\s+/g, ' ')
            .trim();
    };

    // Richtext editor
    const contentState = ContentState.createFromBlockArray(htmlToDraft(removePTags(state.Description) || "").contentBlocks);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
    const [rhDiscription, setRhDiscription] = useState(removePTags(state.Description));

    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    return (
        <Formik
            initialValues={{
                Corp_Policy_Id: state.id ? state.id : "",
                Page_Name: state.Page_Name ? state.Page_Name : "",
                Description: state.Description ? state.Description : "",
                Status: state.Status ? state.Status : "",
                Modified_By: modifiedBy,
            }}
            validationSchema={
                Yup.object().shape({
                    Page_Name: Yup.string().required("Page Name is required."),
                    Status: Yup.string().required('Status is required'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                setSubmitting(false);
                let submitTest = true;
                const errors = {};
                values.Description = rhDiscription;

                if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
                    submitTest = false;
                    errors.Description = "Description is required.";
                }
                else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
                    submitTest = false;
                    errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
                }

                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(updateCorpPopupAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        {/*  Page_Name */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Page_Name && errors.Page_Name)}
                                                fullWidth
                                                helperText={touched.Page_Name && errors.Page_Name}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Page Name"
                                                name="Page_Name"
                                                value={values.Page_Name}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        {/* Status */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        {/* Description  */}
                                        <Grid item md={12} xs={12}>
                                            <Box className={classes.description}>
                                                Description
                                                <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                                            </Box>
                                            <span style={{ color: "red" }}>
                                                {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent >
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    {/* Save details */}
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card >
                </form >
            )
            }
        </Formik >
    );
};

EditCorpPopup.propTypes = {
    className: PropTypes.string
};

export default EditCorpPopup;