import { toast } from 'react-toastify';
import { nodeCudApi} from './commonAxios';
export const TRANSACTION_UPDATE_REQUEST = "TRANSACTION_UPDATE_REQUEST";
export const TRANSACTION_UPDATE_SUCCESS = "TRANSACTION_UPDATE_SUCCESS";
export const TRANSACTION_UPDATE_FAIL = "TRANSACTION_UPDATE_FAIL";


export const updateTransactionAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: TRANSACTION_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Transactions/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: TRANSACTION_UPDATE_SUCCESS
        });
        toast('Transaction updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/transaction-history');
      } else {
        dispatch({
          type: TRANSACTION_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: TRANSACTION_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};