import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useDispatch, useSelector } from 'react-redux';
import Results from './Results';
import Toolbar from './Toolbar';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';
import { paginationAllBidVehicleBrandsGet } from 'src/store/actions/node-actions/nodepagination';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const BiddingVehicleBrandsListView = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [bidvehiclebrands, setBidVehicleBrands] = useState([]);

    //permissions
    const RolePermissions = useSelector(state => state.rolepermission.Roles);
    const roleUserType = useSelector(state => state.auth.user);
    const Login_Details = useSelector(state => state.auth.user);

    const [filters, setFilters] = React.useState({
        "search": '',
        "search_by_filter": 'All',
    });

    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = useState({ PageNo: 1, PageSize: 10, SortBy: "Bidding_Ride_Vehicle_Brand_Id", SortOrder: "ASC" })

    const handleLimitChange = event => {
        setPagination({ ...pagination, PageSize: +event.target.value, PageNo: 1 });
    };

    const handlePageChange = (event, newPage) => {
        setPagination({ ...pagination, PageSize: pagination.PageSize, PageNo: newPage + 1 });
    };

    const callBackPagination = async (status, data, pagination) => {
        if (status) {
            setBidVehicleBrands(data);
        }
        setPageOld(true);
        if (pagination) {
            setPagination(pagination);
        } else {
            setPagination({ PageNo: 1, PageSize: 10, SortBy: "Bidding_Ride_Vehicle_Brand_Id", SortOrder: "ASC" });
        }
    }

    useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            bidvehiclebrandCall();
        }
    }, [pagination])

    const [testFilters, setTestFilters] = useState({});
    useEffect(() => {
        if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
            setTestFilters(filters);
            searchFunction()
        }
    }, [filters]);

    const searchFunction = () => {
        const searchpagination = {
            PageNo: 1, PageSize: 10, SortBy: "Bidding_Ride_Vehicle_Brand_Id", SortOrder: "ASC"
        }
        dispatch(paginationAllBidVehicleBrandsGet(filters, searchpagination, callBackPagination))
    }

    const bidvehiclebrandCall = () => {
        dispatch(paginationAllBidVehicleBrandsGet(filters, pagination, callBackPagination))
    };

    useEffect(() => {
        dispatch(getRolePermissions(Login_Details));
        dispatch(paginationAllBidVehicleBrandsGet(filters, pagination, callBackPagination))
    }, []);

    // Sorting 
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Bidding_Ride_Vehicle_Brand_Id');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPagination({ PageNo: 1, PageSize: pagination?.PageSize, SortBy: property, SortOrder: order === 'asc' ? "ASC" : "DESC" })
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/bidding-rides"
                    variant="text"
                    size="small"
                    color="inherit"
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <Box py={2}>
                    <Typography variant="h3" gutterBottom>
                        Bidding Vehicle Brands
                    </Typography>
                </Box>
                <Toolbar filters={filters} setfilters={setFilters} RolePermissions={RolePermissions} roleUserType={roleUserType} />
                <Box mt={3}>
                    <Results
                        allBidVehicleBrands={bidvehiclebrands}
                        allBidVehicleBrandsNoFilter={bidvehiclebrands}
                        handleLimitChange={handleLimitChange}
                        handlePageChange={handlePageChange}
                        order={order}
                        orderBy={orderBy}
                        pagination={pagination}
                        setPagination={setPagination}
                        createSortHandler={createSortHandler}
                        RolePermissions={RolePermissions}
                        roleUserType={roleUserType}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default BiddingVehicleBrandsListView;