import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    makeStyles,
    TextField
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import isEmpty from 'src/store/validations/is-empty';
import CorpPagination from 'src/components/pagination/corppagination';
import { useDispatch, useSelector } from 'react-redux';
import { updateCorpOrderStatusAction } from 'src/store/actions/corporderAction';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    },
    textField: {
        width: '170px',
        height: '40px',
        borderRadius: '50px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '50px',
            height: '40px',
            '& fieldset': {
                borderColor: 'inherit',
            },
            '&:hover fieldset': {
                borderColor: 'inherit',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'inherit',
            },
        },
        '& .MuiSelect-icon': {
            color: 'inherit',
            left: '75%',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            fontSize: '16px',
            width: '2em',
            height: '2em',
        },
    },
}));

const statusOption = [
    {
        value: 'Order confirmed',
        label: 'Order confirmed'
    },
    {
        value: 'Shipped',
        label: 'Shipped'
    },
    {
        value: 'On process',
        label: 'On process'
    },
    {
        value: 'Out for delivery',
        label: 'Out for delivery'
    },
    {
        value: 'Delivered',
        label: 'Delivered'
    },
    {
        value: 'Returned to origin',
        label: 'Returned to origin'
    }
];

const headCells = [
    { id: '_id', numeric: false, disablePadding: true, label: 'Order id' },
    { id: 'Full_Name', numeric: false, disablePadding: false, label: 'Receiver' },
    { id: 'Expected_Delivery_Date', numeric: false, disablePadding: false, label: 'Estimated delivery date' },
    { id: 'Order_Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
    className,
    allCorpOrderDoorStep,
    pagination,
    setPagination,
    pagesdata,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    createSortHandler,
    allCorpOrderDoorStepNoFilter,
    RolePermissions,
    roleUserType,
    state,
    filters,
    callBackPagination,
    ...rest
}) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const Login_Details = useSelector(state => state.auth.user);
    const pagetype = "doorstep"

    const visibleOrderHandler = orderData => e => {
        navigate("/app/corp-order-info", { state: { state: state, data: orderData, page: "doorstep" } });
    };

    const handleStatusChange = (e, corporder) => {
        const newStatus = e.target.value;
        let formData = {
            "Order_Id": corporder?.Order_Id,
            "Employee_Order_Id": corporder?._id,
            "Delivery_Type": state?.Delivery_Type,
            "Order_Status": newStatus,
            "Modified_By": Login_Details?.Email_Id
        }
        dispatch(updateCorpOrderStatusAction(formData, filters, pagination, callBackPagination, pagetype));
    };

    const getStatusStyles = (status) => {
        switch (status) {
            case 'Order confirmed':
                return {
                    borderColor: '#000000',
                    color: '#000000',
                };
            case 'Shipped':
                return {
                    borderColor: '#9C27B0',
                    color: '#9C27B0',
                };
            case 'On process':
                return {
                    borderColor: '#EF6C00',
                    color: '#EF6C00',
                };
            case 'Out for delivery':
                return {
                    borderColor: '#EF6C00',
                    color: '#EF6C00',
                };
            case 'Delivered':
                return {
                    borderColor: '#2E7D32',
                    color: '#2E7D32',
                };
            case 'Returned to origin':
                return {
                    borderColor: '#D32F2F',
                    color: '#D32F2F',
                };
            default:
                return {
                    borderColor: '#000000',
                    color: '#000000',
                };
        }
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        {headCell.id !== "Created_By" ? (
                                            <TableSortLabel className={classes.headerColumn}
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id)}
                                            >
                                                {headCell.label}
                                            </TableSortLabel>) : (<span style={{ fontSize: "13px", fontWeight: "bold" }} > {headCell.label}</span>)
                                        }
                                    </TableCell>
                                ))}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: "-5rem" }} >Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allCorpOrderDoorStep) &&
                                allCorpOrderDoorStep.map((corporderdoorstep) => {
                                    const statusStyles = getStatusStyles(corporderdoorstep?.Order_Status);
                                    return (
                                        <TableRow
                                            hover
                                            key={corporderdoorstep?._id}
                                        >
                                            <TableCell>{corporderdoorstep?._id}</TableCell>
                                            <TableCell>{corporderdoorstep?.Full_Name?.length > 25 ? (corporderdoorstep?.Full_Name?.substring(0, 25) + "...") : corporderdoorstep?.Full_Name}</TableCell>
                                            <TableCell>{corporderdoorstep?.Expected_Delivery_Date}</TableCell>
                                            <TableCell width="15%">
                                                <TextField
                                                    fullWidth
                                                    value={corporderdoorstep?.Order_Status}
                                                    onChange={(e) => handleStatusChange(e, corporderdoorstep)}
                                                    select
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                    className={classes.textField}
                                                    InputProps={{
                                                        style: {
                                                            borderColor: statusStyles.borderColor,
                                                            color: statusStyles.color,
                                                        },
                                                    }}
                                                    SelectProps={{
                                                        native: true,
                                                        style: {
                                                            color: statusStyles.color,
                                                        },
                                                        classes: {
                                                            icon: classes.textField,
                                                        },
                                                    }}
                                                >
                                                    {statusOption.map(option => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </TableCell>
                                            {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                                (RolePermissions?.Corporate_Order_Write?.Value === "Corporate_Order_Write" && RolePermissions?.Corporate_Order_Write?.Is_Write === 1) ?
                                                <TableCell>
                                                    <Tooltip title="Corp Order Details">
                                                        <Button
                                                            onClick={visibleOrderHandler(corporderdoorstep)}
                                                            color="primary" >
                                                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                        </Button>
                                                    </Tooltip>
                                                </TableCell>
                                                : null}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allCorpOrderDoorStep) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <CorpPagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    allCorpOrderDoorStep: PropTypes.array.isRequired
};

export default Results;