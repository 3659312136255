import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';
import { paginationAllB2BUserProductsBidGet } from 'src/store/actions/node-actions/nodepagination';
import { AlertB2BProductPartnersAction } from 'src/store/actions/b2buserproductsbiddingAction';
import ConfirmDialog from 'src/components/dialogs/confirmDialog';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const B2BUserProductsBiddingListView = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [b2buserproductsbid, setb2buserproductsbid] = useState([]);

    //permissions
    const RolePermissions = useSelector(state => state.rolepermission.Roles);
    const roleUserType = useSelector(state => state.auth.user);
    const Login_Details = useSelector(state => state.auth.user);

    const [filters, setFilters] = React.useState({
        "search": '',
        "search_by_filter": 'All'
    });

    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = useState({ PageNo: 1, PageSize: 10, SortBy: "B2B_User_Products_Bidding_Id", SortOrder: "ASC" })

    const handleLimitChange = event => {
        setPagination({ ...pagination, PageSize: +event.target.value, PageNo: 1 });
    };

    const handlePageChange = (event, newPage) => {
        setPagination({ ...pagination, PageSize: pagination.PageSize, PageNo: newPage + 1 });
    };

    const callBackPagination = async (status, data, pagination) => {
        if (status) {
            setb2buserproductsbid(data);
        }
        setPageOld(true);
        if (pagination) {
            setPagination(pagination);
        } else {
            setPagination({ PageNo: 1, PageSize: 10, SortBy: "B2B_User_Products_Bidding_Id", SortOrder: "ASC" });
        }
    }

    useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            b2buserproductsbidCall();
        }
    }, [pagination])

    const [testFilters, setTestFilters] = useState({});
    useEffect(() => {
        if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
            setTestFilters(filters);
            searchFunction()
        }
    }, [filters]);

    const searchFunction = () => {
        const searchpagination = {
            PageNo: 1, PageSize: 10, SortBy: "B2B_User_Products_Bidding_Id", SortOrder: "ASC"
        }
        dispatch(paginationAllB2BUserProductsBidGet(filters, searchpagination, callBackPagination))
    }

    const b2buserproductsbidCall = () => {
        dispatch(paginationAllB2BUserProductsBidGet(filters, pagination, callBackPagination))
    };

    useEffect(() => {
        dispatch(getRolePermissions(Login_Details));
        dispatch(paginationAllB2BUserProductsBidGet(filters, pagination, callBackPagination))
    }, []);

    // Sorting 
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('B2B_User_Products_Bidding_Id');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPagination({ PageNo: 1, PageSize: pagination?.PageSize, SortBy: property, SortOrder: order === 'asc' ? "ASC" : "DESC" })
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    //notifications
    const [notifyprodpartners, setNotifyProdPartners] = useState(false);
    const [title, settitle] = useState('');
    const [message, setmessage] = useState('');
    const [notifyprodpartnersIdle, setNotifyProdPartnersIdle] = useState('');

    const notifyB2BProductPartnersHandler = copyData => async e => {
        setNotifyProdPartners(true);
        settitle("Alert!");
        setmessage("Are you sure to Trigger Notification for Partners?");
        setNotifyProdPartnersIdle(copyData);
    };

    const handleclose = (value) => {
        setNotifyProdPartners(false);
        if (value) {
            const notifyprodpartnerssData = {
                Partner_Bidding_Products_Id: notifyprodpartnersIdle.Bidding_Product_Id
            };
            dispatch(AlertB2BProductPartnersAction(notifyprodpartnerssData, filters, pagination, callBackPagination));
        }
    };

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/b2b-bidding-products"
                    variant="text"
                    size="small"
                    color="inherit"
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        B2B User Products Bidding
                    </Typography>
                </Box>
                <Toolbar filters={filters} setfilters={setFilters} RolePermissions={RolePermissions} roleUserType={roleUserType} />
                <Box mt={3}>
                    <Results
                        allB2BUserProductsBidding={b2buserproductsbid}
                        allB2BUserProductsBiddingNoFilter={b2buserproductsbid}
                        handleLimitChange={handleLimitChange}
                        handlePageChange={handlePageChange}
                        order={order}
                        orderBy={orderBy}
                        pagination={pagination}
                        setPagination={setPagination}
                        createSortHandler={createSortHandler}
                        RolePermissions={RolePermissions}
                        roleUserType={roleUserType}
                        notifyB2BProductPartnersHandler={notifyB2BProductPartnersHandler}
                    />
                </Box>
            </Container>
            <ConfirmDialog inputMessage={message} titleMsg={title} open={notifyprodpartners} onClose={handleclose} />
        </Page>
    );
};

export default B2BUserProductsBiddingListView;