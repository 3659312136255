import { toast } from 'react-toastify';
import { nodeCudApi} from './commonAxios';
export const TESTIMONIAL_UPDATE_REQUEST = "TESTIMONIAL_UPDATE_REQUEST";
export const TESTIMONIAL_UPDATE_SUCCESS = "TESTIMONIAL_UPDATE_SUCCESS";
export const TESTIMONIAL_UPDATE_FAIL = "TESTIMONIAL_UPDATE_FAIL";

/*=============================================================
                  Update Testimonial Action
===============================================================*/
export const updateTestimonialAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: TESTIMONIAL_UPDATE_REQUEST
  });
  nodeCudApi.post(`/AppRatingsAndReviews/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: TESTIMONIAL_UPDATE_SUCCESS
        });
        toast('Testimonial updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/testimonial-management');
      } else {

        dispatch({
          type: TESTIMONIAL_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: TESTIMONIAL_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};