import {

    BID_SUCCESS_STORY_CREATE_REQUEST,
    BID_SUCCESS_STORY_CREATE_SUCCESS,
    BID_SUCCESS_STORY_CREATE_FAIL,

    BID_SUCCESS_STORY_UPDATE_REQUEST,
    BID_SUCCESS_STORY_UPDATE_SUCCESS,
    BID_SUCCESS_STORY_UPDATE_FAIL,

    DEL_BID_SS_VEHICLES_GET_REQUEST,
    DEL_BID_SS_VEHICLES_GET_SUCCESS,
    DEL_BID_SS_VEHICLES_GET_FAIL,

    DEL_BID_SS_DELUSERS_GET_REQUEST,
    DEL_BID_SS_DELUSERS_GET_SUCCESS,
    DEL_BID_SS_DELUSERS_GET_FAIL

} from '../actions/biddingridesuccessstoriesAction'

const initialState = {
    bidsuccessstoryCreate: { bidsuccessstory: {}, error: '', isLoading: false },
    bidsuccessstoryUpdate: { bidsuccessstory: {}, error: '', isLoading: false },
    bidssvehicles: { all: [], error: '', isLoading: false },
    bidssdeliusers: { all: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case BID_SUCCESS_STORY_CREATE_REQUEST:
            return { ...state, bidsuccessstoryCreate: { bidsuccessstory: {}, error: '', isLoading: true } };
        case BID_SUCCESS_STORY_CREATE_SUCCESS:
            return { ...state, bidsuccessstoryCreate: { bidsuccessstory: state.bidsuccessstoryCreate.bidsuccessstory, error: '', isLoading: false } };
        case BID_SUCCESS_STORY_CREATE_FAIL:
            return { ...state, bidsuccessstoryCreate: { bidsuccessstory: {}, error: action.payload, isLoading: false } };

        case BID_SUCCESS_STORY_UPDATE_REQUEST:
            return { ...state, bidsuccessstoryUpdate: { bidsuccessstory: {}, error: '', isLoading: true } };
        case BID_SUCCESS_STORY_UPDATE_SUCCESS:
            return { ...state, bidsuccessstoryUpdate: { bidsuccessstory: state.bidsuccessstoryUpdate.bidsuccessstory, error: '', isLoading: false } };
        case BID_SUCCESS_STORY_UPDATE_FAIL:
            return { ...state, bidsuccessstoryUpdate: { bidsuccessstory: {}, error: action.payload, isLoading: false } };

        case DEL_BID_SS_VEHICLES_GET_REQUEST:
            return { ...state, bidssvehicles: { all: state.bidssvehicles.all, error: '', isLoading: true } };
        case DEL_BID_SS_VEHICLES_GET_SUCCESS:
            return { ...state, bidssvehicles: { all: action.payload, error: '', isLoading: false } };
        case DEL_BID_SS_VEHICLES_GET_FAIL:
            return { ...state, bidssvehicles: { all: [], error: action.payload, isLoading: false } };

        case DEL_BID_SS_DELUSERS_GET_REQUEST:
            return { ...state, bidssdeliusers: { all: state.bidssdeliusers.all, error: '', isLoading: true } };
        case DEL_BID_SS_DELUSERS_GET_SUCCESS:
            return { ...state, bidssdeliusers: { all: action.payload, error: '', isLoading: false } };
        case DEL_BID_SS_DELUSERS_GET_FAIL:
            return { ...state, bidssdeliusers: { all: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}