import {
  ADS_CREATE_REQUEST,
  ADS_CREATE_SUCCESS,
  ADS_CREATE_FAIL,
  ADS_UPDATE_REQUEST,
  ADS_UPDATE_SUCCESS,
  ADS_UPDATE_FAIL,

} from '../actions/adsAction'

const initialState = {
  pageads: { all: [], error: '', isLoading: false },
  pagead: { pagead: {}, error: '', isLoading: false },
  pageadCreate: { pagead: {}, error: '', isLoading: false },
  pageadUpdate: { pagead: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADS_CREATE_REQUEST:
      return { ...state, pageadCreate: { pagead: {}, error: '', isLoading: true } };
    case ADS_CREATE_SUCCESS:
      return { ...state, pageadCreate: { pagead: state.pageadCreate.deal, error: '', isLoading: false } };
    case ADS_CREATE_FAIL:
      return { ...state, pageadCreate: { pagead: {}, error: action.payload, isLoading: false } };
    case ADS_UPDATE_REQUEST:
      return { ...state, pageadUpdate: { pagead: {}, error: '', isLoading: true } };
    case ADS_UPDATE_SUCCESS:
      return { ...state, pageadUpdate: { pagead: state.pageadUpdate.deal, error: '', isLoading: false } };
    case ADS_UPDATE_FAIL:
      return { ...state, pageadUpdate: { pagead: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}