import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateReferandEarnAction,
} from './../../store/actions/referandearnAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const payments = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Pending',
    label: 'Pending'
  },
  {

    value: 'Settled',
    label: 'Settled'
  },

];

const signup = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Pending',
    label: 'Pending'
  },
  {

    value: 'Registered',
    label: 'Registered'
  },

];

const paymenttransfer = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Not Initiated',
    label: 'Not Initiated'
  },
  {

    value: 'Wallet',
    label: 'Wallet'
  },

];

const EditReferandEarn = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = location;

  const isSubmited = useSelector(state => state.referandearn.referandearnsUpdate.isLoading);
  const errorMessage = useSelector(state => state.referandearn.referandearnsUpdate.error);

  return (
    <Formik
      initialValues={{
        Name: state.Name,
        Email_Id: state.Email_Id,
        Amount: state.Amount,
        Sign_up_Status: state.Sign_up_Status,
        Status: state.Status,
        User_Email_Id: state.User_Email_Id,
        Reference_Id: state.Reference_Id,
        Payment_Status: state.Payment_Status,
        Payment_Transfer_To: state.Payment_Transfer_To,
        Wallet_History_Id: state?.Wallet_History_Id
      }}

      validationSchema={
        Yup.object().shape({
          Name: Yup.string().required('Name is required.'),
          Email_Id: Yup.string().required('Email ID is required.'),
          Amount: Yup.number().min(0, "Amount should not be less than 0.").required('Amount is required.'),
          User_Email_Id: Yup.string().required('User Email ID is required.'),
          Status: Yup.string().required('Status is required.'),
          Sign_up_Status: Yup.string().required('Signup Status is required.'),
        })
      }
      onSubmit={(values) => {
        let formData = JSON.parse(JSON.stringify(values));
        dispatch(updateReferandEarnAction(formData, navigate));
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    {/* Name */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Name && errors.Name)}
                        fullWidth
                        helperText={touched.Name && errors.Name}
                        onBlur={handleBlur}
                        label="Name"
                        name="Name"
                        onChange={handleChange}
                        value={values.Name}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Email Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Email_Id && errors.Email_Id)}
                        fullWidth
                        helperText={touched.Email_Id && errors.Email_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Email ID"
                        name="Email_Id"
                        value={values.Email_Id}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                    {/* User_Email_Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.User_Email_Id && errors.User_Email_Id)}
                        fullWidth
                        helperText={touched.User_Email_Id && errors.User_Email_Id}
                        onBlur={handleBlur}
                        label="User Email ID"
                        name="User_Email_Id"
                        onChange={handleChange}
                        value={values.User_Email_Id}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Amount */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Amount && errors.Amount)}
                        fullWidth
                        helperText={touched.Amount && errors.Amount}
                        onBlur={handleBlur}
                        label="Amount"
                        name="Amount"
                        onChange={handleChange}
                        value={values.Amount}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Wallet_History_Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Wallet_History_Id && errors.Wallet_History_Id)}
                        fullWidth
                        helperText={touched.Wallet_History_Id && errors.Wallet_History_Id}
                        onBlur={handleBlur}
                        label="Wallet History ID"
                        name="Wallet_History_Id"
                        onChange={handleChange}
                        value={values.Wallet_History_Id}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Message */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Sign_up_Status && errors.Sign_up_Status)}
                        fullWidth
                        helperText={touched.Sign_up_Status && errors.Sign_up_Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Signup Status"
                        name="Sign_up_Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Sign_up_Status}
                        variant="outlined"
                      >
                        {signup.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Payment_Status && errors.Payment_Status)}
                        fullWidth
                        helperText={touched.Payment_Status && errors.Payment_Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Payment Status"
                        name="Payment_Status"
                        select
                        SelectProps={{ native: true }}
                        value={values.Payment_Status}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {payments.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Message */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Payment_Transfer_To && errors.Payment_Transfer_To)}
                        fullWidth
                        helperText={touched.Payment_Transfer_To && errors.Payment_Transfer_To}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Payment Transfer To"
                        name="Payment_Transfer_To"
                        select
                        SelectProps={{ native: true }}
                        value={values.Payment_Transfer_To}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {paymenttransfer.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC', color: 'white' }}
                  variant="contained"
                >
                  Save details
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditReferandEarn.propTypes = {
  className: PropTypes.string
};

export default EditReferandEarn;