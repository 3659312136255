import {
    SUB_PACK_REQUEST,
    SUB_PACK_SUCCESS,
    SUB_PACK_FAIL,

    SUB_PACK_CREATE_REQUEST,
    SUB_PACK_CREATE_SUCCESS,
    SUB_PACK_CREATE_FAIL,

    SUB_PACK_UPDATE_REQUEST,
    SUB_PACK_UPDATE_SUCCESS,
    SUB_PACK_UPDATE_FAIL,

    SUB_PACK_FILTER,
} from '../actions/subscriptionpackagesAction'

const initialState = {
    subspackages: { all: [], error: '', isLoading: false },
    subspackageCreate: { subspackage: {}, error: '', isLoading: false },
    subspackageUpdate: { subspackage: {}, error: '', isLoading: false },
    subspackageFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SUB_PACK_REQUEST:
            return { ...state, subspackages: { all: state.subspackages.all, error: '', isLoading: true } };
        case SUB_PACK_SUCCESS:
            return { ...state, subspackages: { all: action.payload, error: '', isLoading: false } };
        case SUB_PACK_FAIL:
            return { ...state, subspackages: { all: [], error: action.payload, isLoading: false } };

        case SUB_PACK_CREATE_REQUEST:
            return { ...state, subspackageCreate: { subspackage: {}, error: '', isLoading: true } };
        case SUB_PACK_CREATE_SUCCESS:
            return { ...state, subspackageCreate: { subspackage: state.subspackageCreate.subspackage, error: '', isLoading: false } };
        case SUB_PACK_CREATE_FAIL:
            return { ...state, subspackageCreate: { subspackage: {}, error: action.payload, isLoading: false } };

        case SUB_PACK_UPDATE_REQUEST:
            return { ...state, subspackageUpdate: { subspackage: {}, error: '', isLoading: true } };
        case SUB_PACK_UPDATE_SUCCESS:
            return { ...state, subspackageUpdate: { subspackage: action.payload, error: '', isLoading: false } };
        case SUB_PACK_UPDATE_FAIL:
            return { ...state, subspackageUpdate: { subspackage: {}, error: action.payload, isLoading: false } };

        case SUB_PACK_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, subspackageFilter: { search: state.subspackageFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, subspackageFilter: { select: state.subspackageFilter.select, search: action.value } };
            }
            return res;

        default:
            return state;
    }
}