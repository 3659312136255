import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableSortLabel,
    TableRow,
    makeStyles,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'src/components/pagination/pagination';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PaymentIcon from '@mui/icons-material/Payment';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    images: {
        width: 100,
        height: 50
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
        width: '200px'
    }
}));

const headCells = [
    { id: 'User_Products_Bidding_Id', numeric: false, disablePadding: true, label: 'User Products Bidding ID' },
    { id: 'User_Email_Id', numeric: false, disablePadding: false, label: 'User Email ID' },
    { id: 'Quantity', numeric: false, disablePadding: false, label: 'Quantity' },
    { id: 'User_Pincode', numeric: false, disablePadding: false, label: 'User Pincode' },
    { id: 'Bidding_Status', numeric: false, disablePadding: false, label: 'Bidding Status' },
];

const Results = ({
    className,
    allBiddingUserProducts,
    order,
    orderBy,
    createSortHandler,
    handleLimitChange,
    handlePageChange,
    allBiddingUserProductsNoFilter,
    pagination,
    setPagination,
    RolePermissions,
    roleUserType,
    notifyProductsHandler,
    ...rest
}) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const visibleBiddingUserProductsHandler = biddinguserproductsData => e => {
        navigate("/app/view-user-bidding-products", { state: biddinguserproductsData });
    };
    const editBiddingUserProductsHandler = biddinguserproductsData => e => {
        navigate('/app/edit-user-bidding-products', { state: biddinguserproductsData });
    };

    const UserProductsBiddingQuotesHandler = biddinguserproductsData => e => {
        navigate("/app/partner-bidding-quotes", { state: biddinguserproductsData });
    };

    const UserProductsPartnerPaymnetsHandler = biddinguserproductsData => e => {
        navigate("/app/bidding-products-partner-payments", { state: biddinguserproductsData });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                    (RolePermissions?.Bidding_User_Products_Write?.Value === "Bidding_User_Products_Write" && RolePermissions?.Bidding_User_Products_Write?.Is_Write === 1) ?
                                    <TableCell>Quotes</TableCell>
                                    : null}
                                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                    (RolePermissions?.Bidding_User_Products_Write?.Value === "Bidding_User_Products_Write" && RolePermissions?.Bidding_User_Products_Write?.Is_Write === 1) ?
                                    <TableCell >Notifications</TableCell>
                                    : null}
                                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                    (RolePermissions?.Bidding_User_Products_Write?.Value === "Bidding_User_Products_Write" && RolePermissions?.Bidding_User_Products_Write?.Is_Write === 1) ?
                                    <TableCell >Payments</TableCell>
                                    : null}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allBiddingUserProducts) &&
                                (allBiddingUserProducts).map(biddinguserproducts => (
                                    <TableRow
                                        hover
                                        key={biddinguserproducts.User_Products_Bidding_Id}
                                    >
                                        <TableCell>{biddinguserproducts.User_Products_Bidding_Id}</TableCell>
                                        <TableCell>{biddinguserproducts.User_Email_Id?.substring(0, 20)}...</TableCell>
                                        <TableCell>{biddinguserproducts.Quantity}</TableCell>
                                        <TableCell>{biddinguserproducts.User_Pincode}</TableCell>
                                        <TableCell>{biddinguserproducts.Bidding_Status}</TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Bidding_User_Products_Write?.Value === "Bidding_User_Products_Write" && RolePermissions?.Bidding_User_Products_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="User Product Bidding Quotes">
                                                    <Button
                                                        onClick={UserProductsBiddingQuotesHandler(biddinguserproducts)}
                                                        color="primary"
                                                    >
                                                        <RequestQuoteIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>

                                            : null}
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Bidding_User_Products_Write?.Value === "Bidding_User_Products_Write" && RolePermissions?.Bidding_User_Products_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="Notifications">
                                                    <Button>
                                                        <NotificationsActiveIcon
                                                            onClick={notifyProductsHandler(biddinguserproducts)}
                                                            style={{ display: 'flex', justifyContent: 'center', color: '#9EA0A5', fontSize: 20, }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            : null}
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Bidding_User_Products_Write?.Value === "Bidding_User_Products_Write" && RolePermissions?.Bidding_User_Products_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="Payments">
                                                    <span>
                                                        <Button disabled={biddinguserproducts.Advance_Payment_Status_From_User !== 1 ? true : false}
                                                            onClick={UserProductsPartnerPaymnetsHandler(biddinguserproducts)}
                                                            color="primary"
                                                        >
                                                            <PaymentIcon style={{ color: '#9EA0A5' }} />
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                            : null}
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Bidding_User_Products_Write?.Value === "Bidding_User_Products_Write" && RolePermissions?.Bidding_User_Products_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="Bidding Product Details">
                                                    <Button
                                                        onClick={visibleBiddingUserProductsHandler(biddinguserproducts)}
                                                        color="primary" >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                                <Button
                                                    onClick={editBiddingUserProductsHandler(biddinguserproducts)}
                                                >
                                                    <CreateIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                            </TableCell>
                                            : null}
                                        {RolePermissions?.Bidding_User_Products_Read?.Value === "Bidding_User_Products_Read" && RolePermissions?.Bidding_User_Products_Read?.Is_Read === 1 &&
                                            <TableCell>
                                                <Tooltip title="Brand Details">
                                                    <Button
                                                        onClick={visibleBiddingUserProductsHandler(biddinguserproducts)}
                                                        color="primary" >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allBiddingUserProducts) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <Pagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
};

export default Results;