import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { topDeliverypersons } from 'src/store/actions/delivery-reports/deliverydashboardAction';

//useStyles contains the styles
const useStyles = makeStyles({
  root: {
    height: '100%'
  },
  image: {
    height: 48,
    width: 48
  }
});

const TopdelPersons = ({ className, RolePermissions, setSeconds, ...rest }) => {
  // classes contains the styles
  const classes = useStyles();
  // useDispatch hook is used to dispatch an action
  const dispatch = useDispatch();
  //topdeliverypersons contains the data from API using state call
  const topdeliverypersons = useSelector(state => state.deliverydashboard.TopDeliveryPersons.data);
  let Top_Delivery_Persons_Hide = RolePermissions?.Top_Delivery_Persons_Hide?.Is_Hide

  // componentDidMount
  useEffect(() => {
    let formData = {
      "Records_Filter": "ALL",
      "Date": ''
    };
    dispatch(topDeliverypersons(formData));
    setSeconds(5);
    if (RolePermissions?.Top_Delivery_Persons_Hide?.Value === "Top_Delivery_Persons_Hide" && RolePermissions?.Top_Delivery_Persons_Hide?.Is_Hide === 1) {
      Top_Delivery_Persons_Hide = RolePermissions?.Top_Delivery_Persons_Hide?.Is_Hide
    }
  }, []);

  return (
    <>
      {Top_Delivery_Persons_Hide !== 1 &&
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardHeader
            title="Top 10 Delivery Persons"
          />
          <Divider />
          <List>
            {topdeliverypersons.map((top, i) => (
              <ListItem divider={i < topdeliverypersons.length - 1} key={top.Del_user_email_id}>
                <ListItemAvatar>
                  <img
                    alt="User"
                    className={classes.image}
                    src={top.Profile_pic_path}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={top.Full_name}
                  secondary={top.Del_user_email_id}
                />
                <> {top.Count} </>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Card>
      }
    </>
  );
};

TopdelPersons.propTypes = {
  className: PropTypes.string
};

export default TopdelPersons;