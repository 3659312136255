import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { updateUserRideAction } from 'src/store/actions/userridesAction';
import Rating from '@material-ui/lab/Rating';
import { getAllESIDeliveryPersonsAction } from 'src/store/actions/ordersAction';
import { CircularProgress } from '@mui/material';

const Status = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const RideStatus = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'NEW',
    label: 'NEW'
  },
  {
    value: 'ACCEPTED',
    label: 'ACCEPTED'
  },
  {
    value: 'REJECTED',
    label: 'REJECTED'
  },
  {
    value: 'ON-THE-WAY',
    label: 'ON-THE-WAY'
  },
  {
    value: 'PICKED',
    label: 'PICKED'
  },
  {
    value: 'RIDE-IN-PROGRESS',
    label: 'RIDE-IN-PROGRESS'
  },
  {
    value: 'COMPLETED',
    label: 'COMPLETED'
  }
];

const PaymentStatus = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'SUCCESS',
    label: 'SUCCESS'
  },
  {
    value: 'PENDING',
    label: 'PENDING'
  },
  {
    value: 'FAILED',
    label: 'FAILED'
  }
];

const BookingStatus = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'NEW',
    label: 'NEW'
  },
  {
    value: 'ACCEPTED',
    label: 'ACCEPTED'
  },
  {
    value: 'REJECTED',
    label: 'REJECTED'
  },
  {
    value: 'CANCELLED',
    label: 'CANCELLED'
  },
  {
    value: 'ON-THE-WAY',
    label: 'ON-THE-WAY'
  },
  {
    value: 'COMPLETED',
    label: 'COMPLETED'
  }
];

const EditUserRides = ({ className, ...rest }) => {
  const location = useLocation();
  let { state } = location;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const formData = {
      "Role": "DELIVERY_ESI"
    };
    dispatch(getAllESIDeliveryPersonsAction(formData));
  }, []);

  const isSubmited = useSelector(state => state.userrides.userrideUpdate.isLoading);
  const errorMessage = useSelector(state => state.userrides.userrideCreate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const esidelipersons = useSelector(state => state.orders.Orderesidelipersons.all)

  const values = {
    User_Ride_Id: state?.User_Ride_Id ? state?.User_Ride_Id : "",
    User_Email_Id: state?.User_Email_Id ? state?.User_Email_Id : "",
    User_Cashback: state?.User_Cashback ? state?.User_Cashback : "",
    User_Cashback_Status: state?.User_Cashback_Status ? state?.User_Cashback_Status : "",
    Booking_Status: state?.Booking_Status ? state?.Booking_Status : "",
    Driver_Vehicle_Id: state?.Driver_Vehicle_Id ? state?.Driver_Vehicle_Id : "",
    Driver_email_Id: state?.Driver_email_Id ? state?.Driver_email_Id : "",
    Wallet_Amount: state?.Wallet_Amount ? state?.Wallet_Amount : "",
    User_Paid_Total_Fare: state?.User_Paid_Total_Fare ? state?.User_Paid_Total_Fare : "",
    Total_Fare_Before_Discount: state?.Total_Fare_Before_Discount ? state?.Total_Fare_Before_Discount : "",
    Total_Inter_State_Fare: state?.Total_Inter_State_Fare ? state?.Total_Inter_State_Fare : "",
    Total_Toll_Fare: state?.Total_Toll_Fare ? state?.Total_Toll_Fare : "",
    Final_Setteled_Fare_To_Driver: state?.Final_Setteled_Fare_To_Driver ? state?.Final_Setteled_Fare_To_Driver : "",
    Pickup_Latitude: state?.Pickup_Latitude ? state?.Pickup_Latitude : "",
    Pickup_Location: state?.Pickup_Location ? state?.Pickup_Location : "",
    Pickup_Longitude: state?.Pickup_Longitude ? state?.Pickup_Longitude : "",
    Drop_Latitude: state?.Drop_Latitude ? state?.Drop_Latitude : "",
    Drop_Location: state?.Drop_Location ? state?.Drop_Location : "",
    Drop_Longitude: state?.Drop_Longitude ? state?.Drop_Longitude : "",
    Meepaisa_Comission: state?.Meepaisa_Comission ? state?.Meepaisa_Comission : "",
    Payment_Gateway_Amount: state?.Payment_Gateway_Amount ? state?.Payment_Gateway_Amount : "",
    Payment_Gateway_Transaction_Number: state?.Payment_Gateway_Transaction_Number ? state?.Payment_Gateway_Transaction_Number : "",
    Payment_Method: state?.Payment_Method ? state?.Payment_Method : "",
    Payment_Status: state?.Payment_Status ? state?.Payment_Status : "",
    Driver_Tip: state?.Driver_Tip ? state?.Driver_Tip : "",
    Comfort_Type: state?.Comfort_Type ? state?.Comfort_Type : "",
    Distance_In_Kms: state?.Distance_In_Kms ? state?.Distance_In_Kms : "",
    Rider_Actual_Distance_In_Kms: state?.Rider_Actual_Distance_In_Kms ? state?.Rider_Actual_Distance_In_Kms : "",
    Ride_Discount: state?.Ride_Discount ? state?.Ride_Discount : "",
    Ride_Review: state?.Ride_Review ? state?.Ride_Review : "",
    Ride_Status: state?.Ride_Status ? state?.Ride_Status : "",
    Ride_Rating: parseInt(state.Ride_Rating),
    Ride_Otp: state?.Ride_Otp ? state?.Ride_Otp : "",
    Ride_Requested_Date: state?.Ride_Requested_Date ? state?.Ride_Requested_Date : "",
    Ride_Pickup_Date: state?.Ride_Pickup_Date ? state?.Ride_Pickup_Date : "",
    Ride_Drop_Date: state?.Ride_Drop_Date ? state?.Ride_Drop_Date : "",
    Schedule_Date_Time: state?.Schedule_Date_Time ? state?.Schedule_Date_Time : "",
    Status: state?.Status ? state?.Status : "",
    Extra_Charges: state?.Extra_Charges ? state?.Extra_Charges : "",
    IGST: state?.IGST ? state?.IGST : "",
    Meepaisa_Commission_Percentage: state?.Meepaisa_Commission_Percentage ? state?.Meepaisa_Commission_Percentage : "",
    Is_Driver_Came_On_Time: state.Is_Driver_Came_On_Time > 0 ? true : false,
    Is_Driver_Polite: state.Is_Driver_Polite > 0 ? true : false,
    Is_Out_Station_Ride: state.Is_Out_Station_Ride > 0 ? true : false,
    Is_Rash_Driving: state.Is_Rash_Driving > 0 ? true : false,
    Is_Schedule_Ride: state.Is_Schedule_Ride > 0 ? true : false,
    Modified_By: loginEmail
  }

  const Form_Validation = Yup.object().shape({
    Status: Yup.string().required('Status is required.'),
    Ride_Status: Yup.string().required('Ride Status is required.'),
    Booking_Status: Yup.string().required('Booking Status is required.'),
    Payment_Status: Yup.string().required('Payment Status is required.'),
    Driver_email_Id: Yup.string().required('Driver Email ID is required.'),
    Total_Toll_Fare: Yup.number().min(0, "Total Toll Fare should not be less than 0.").required('Total Toll Fare is required.'),
    Total_Inter_State_Fare: Yup.number().min(0, "Total Inter State Fare should not be less than 0.").required('Total Inter State Fare is required.'),
    Extra_Charges: Yup.number().min(0, "Extra Charges should not be less than 0.").required('Extra Charges is required.'),
    IGST: Yup.number().min(0, "IGST% should not be less than 0.").max(100, 'IGST% must be less than 100.').required('IGST% is required.'),
    Meepaisa_Commission_Percentage: Yup.number().min(0, "Meepaisa Commission Percentage should not be less than 0.").max(100, 'Meepaisa Commission Percentage must be less than 100.').required('Meepaisa Commission Percentage is required.'),
    User_Cashback: Yup.number().when('Payment_Status', {
      is: "SUCCESS",
      then: Yup.number().min(1, "User Cashback should not be less than 1.").required('User Cashback is required.'),
    }),
  })

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      if (values.Is_Driver_Came_On_Time) {
        formValues.Is_Driver_Came_On_Time = 1;
      } else {
        formValues.Is_Driver_Came_On_Time = 0;
      }
      if (values.Is_Driver_Polite) {
        formValues.Is_Driver_Polite = 1;
      } else {
        formValues.Is_Driver_Polite = 0;
      }
      if (values.Is_Out_Station_Ride) {
        formValues.Is_Out_Station_Ride = 1;
      } else {
        formValues.Is_Out_Station_Ride = 0;
      }
      if (values.Is_Rash_Driving) {
        formValues.Is_Rash_Driving = 1;
      } else {
        formValues.Is_Rash_Driving = 0;
      }
      if (values.Is_Schedule_Ride) {
        formValues.Is_Schedule_Ride = 1;
      } else {
        formValues.Is_Schedule_Ride = 0;
      }
      dispatch(updateUserRideAction(formValues, navigate));
    }
  }

  return (
    <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    {/* User_Email_Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="User Email ID"
                        name="User_Email_Id"
                        value={values.User_Email_Id}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Driver_Vehicle_Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Driver Vehicle ID"
                        name="Driver_Vehicle_Id"
                        value={values.Driver_Vehicle_Id}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Pickup_Location */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Pickup Location"
                        name="Pickup_Location"
                        value={values.Pickup_Location}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Drop_Location */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Drop Location"
                        name="Drop_Location"
                        value={values.Drop_Location}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Pickup_Latitude */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Pickup Latitude"
                        name="Pickup_Latitude"
                        value={values.Pickup_Latitude}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Pickup_Longitude */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Pickup Longitude"
                        name="Pickup_Longitude"
                        value={values.Pickup_Longitude}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Drop_Latitude */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Drop Latitude"
                        name="Drop_Latitude"
                        value={values.Drop_Latitude}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Drop_Longitude */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Drop Longitude"
                        name="Drop_Longitude"
                        value={values.Drop_Longitude}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Ride_Requested_Date */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ride Requested Date"
                        name="Ride_Requested_Date"
                        type="date"
                        value={values.Ride_Requested_Date}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Schedule_Date_Time */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Schedule Date Time"
                        name="Schedule_Date_Time"
                        type="datetime-local"
                        value={values.Schedule_Date_Time}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Ride_Pickup_Date */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ride Pickup Date"
                        name="Ride_Pickup_Date"
                        type="date"
                        value={values.Ride_Pickup_Date}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Ride_Drop_Date */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ride Drop Date"
                        name="Ride_Drop_Date"
                        type="date"
                        value={values.Ride_Drop_Date}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Comfort_Type */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Comfort Type"
                        name="Comfort_Type"
                        value={values.Comfort_Type}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Ride_Otp */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ride OTP"
                        name="Ride_Otp"
                        value={values.Ride_Otp}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Distance_In_Kms */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Distance In Kms"
                        name="Distance_In_Kms"
                        type="number"
                        value={values.Distance_In_Kms}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Rider_Actual_Distance_In_Kms */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Rider Actual Distance In Kms"
                        name="Rider_Actual_Distance_In_Kms"
                        type="number"
                        value={values.Rider_Actual_Distance_In_Kms}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Ride_Discount */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ride Discount"
                        name="Ride_Discount"
                        type="number"
                        value={values.Ride_Discount}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Driver_Tip */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Driver Tip"
                        name="Driver_Tip"
                        type="number"
                        value={values.Driver_Tip}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Total_Toll_Fare */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Total_Toll_Fare && errors.Total_Toll_Fare)}
                        helperText={<ErrorMessage name="Total_Toll_Fare" />}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={(e) => {
                          let tollfree = Number(e.target.value) + Number(values.Estimate_Total_Fare)
                          setFieldValue("Total_Toll_Fare", e.target.value);
                          setFieldValue("User_Paid_Total_Fare", tollfree);
                        }}
                        label="Total Toll Fare"
                        name="Total_Toll_Fare"
                        type="number"
                        value={values.Total_Toll_Fare}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                      </TextField>
                    </Grid>

                    {/* Total_Inter_State_Fare */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Total_Inter_State_Fare && errors.Total_Inter_State_Fare)}
                        helperText={<ErrorMessage name="Total_Inter_State_Fare" />}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={(e) => {
                          let interstatefree = Number(e.target.value) + Number(values.Estimate_Total_Fare) + Number(values.Total_Toll_Fare)
                          setFieldValue("Total_Inter_State_Fare", e.target.value);
                          setFieldValue("User_Paid_Total_Fare", interstatefree);
                        }}
                        label="Total Inter State Fare"
                        name="Total_Inter_State_Fare"
                        type="number"
                        value={values.Total_Inter_State_Fare}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                      </TextField>
                    </Grid>

                    {/* Extra_Charges */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Extra_Charges && errors.Extra_Charges)}
                        helperText={<ErrorMessage name="Extra_Charges" />}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={(e) => {
                          let extracharges = Number(e.target.value) + Number(values.Estimate_Total_Fare) + Number(values.Total_Toll_Fare) + Number(values.Total_Inter_State_Fare)
                          setFieldValue("Extra_Charges", e.target.value);
                          setFieldValue("User_Paid_Total_Fare", extracharges);
                        }}
                        label="Extra Charges"
                        name="Extra_Charges"
                        type="number"
                        value={values.Extra_Charges}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                      </TextField>
                    </Grid>

                    {/* User_Paid_Total_Fare */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="User Paid Total Fare"
                        name="User_Paid_Total_Fare"
                        type="number"
                        value={values.User_Paid_Total_Fare}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* IGST */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.IGST && errors.IGST)}
                        helperText={<ErrorMessage name="IGST" />}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={(e) => {
                          let igst = Number(e.target.value / 100 * values.User_Paid_Total_Fare).toFixed(2)
                          let paymentgatewayamount = Number(values.User_Paid_Total_Fare) + Number(igst).toFixed(2)
                          setFieldValue("IGST", e.target.value);
                          setFieldValue("Payment_Gateway_Amount", paymentgatewayamount);
                        }}
                        label="IGST%"
                        name="IGST"
                        type="number"
                        value={values.IGST}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                      </TextField>
                    </Grid>

                    {/* Payment_Gateway_Amount */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Payment Gateway Amount"
                        name="Payment_Gateway_Amount"
                        type="number"
                        value={values.Payment_Gateway_Amount}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Meepaisa_Commission_Percentage */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Meepaisa_Commission_Percentage && errors.Meepaisa_Commission_Percentage)}
                        helperText={<ErrorMessage name="Meepaisa_Commission_Percentage" />}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={(e) => {
                          let commission = Number(e.target.value / 100 * values.Payment_Gateway_Amount).toFixed(2)
                          let driverpayment = Number(values.Payment_Gateway_Amount - commission).toFixed(2)
                          setFieldValue("Meepaisa_Commission_Percentage", e.target.value);
                          setFieldValue("Meepaisa_Comission", commission);
                          setFieldValue("Final_Setteled_Fare_To_Driver", driverpayment);
                        }}
                        label="Meepaisa Commission Percentage"
                        name="Meepaisa_Commission_Percentage"
                        type="number"
                        value={values.Meepaisa_Commission_Percentage}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                      </TextField>
                    </Grid>

                    {/* Meepaisa_Comission */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Meepaisa Commission"
                        name="Meepaisa_Comission"
                        type="number"
                        value={values.Meepaisa_Comission}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Final_Setteled_Fare_To_Driver */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Final Setteled Fare To Driver"
                        name="Final_Setteled_Fare_To_Driver"
                        type="number"
                        value={values.Final_Setteled_Fare_To_Driver}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Wallet_Amount */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Wallet Amount"
                        name="Wallet_Amount"
                        type="number"
                        value={values.Wallet_Amount}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Total_Fare_Before_Discount */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Total Fare Before Discount"
                        name="Total_Fare_Before_Discount"
                        type="number"
                        value={values.Total_Fare_Before_Discount}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Payment_Gateway_Transaction_Number */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Payment Gateway Transaction Number"
                        name="Payment_Gateway_Transaction_Number"
                        value={values.Payment_Gateway_Transaction_Number}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Payment_Method */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Payment Method"
                        name="Payment_Method"
                        value={values.Payment_Method}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Driver_email_Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Driver_email_Id && errors.Driver_email_Id)}
                        fullWidth
                        placeholder="Driver Email ID"
                        name="Driver_email_Id"
                        onChange={handleChange}
                        label="Driver Email ID"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Driver_email_Id}
                        variant="outlined"
                        helperText={<ErrorMessage name="Driver_email_Id" />}
                      >
                        <option key="" value="">--Please Select--</option>
                        {esidelipersons?.map(option => (
                          <option key={option.Del_User_Email_Id} value={option.Del_User_Email_Id}>
                            {option.Del_User_Email_Id}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Booking_Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Booking_Status && errors.Booking_Status)}
                        fullWidth
                        placeholder="Booking Status"
                        name="Booking_Status"
                        onChange={handleChange}
                        label="Booking Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Booking_Status}
                        variant="outlined"
                        helperText={<ErrorMessage name="Booking_Status" />}
                      >
                        {BookingStatus.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Payment_Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Payment_Status && errors.Payment_Status)}
                        fullWidth
                        placeholder="Payment Status"
                        name="Payment_Status"
                        onChange={handleChange}
                        label="Payment Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Payment_Status}
                        variant="outlined"
                        helperText={<ErrorMessage name="Payment_Status" />}
                      >
                        {PaymentStatus.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Ride_Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Ride_Status && errors.Ride_Status)}
                        fullWidth
                        placeholder="Ride Status"
                        name="Ride_Status"
                        onChange={handleChange}
                        label="Ride Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Ride_Status}
                        variant="outlined"
                        helperText={<ErrorMessage name="Ride_Status" />}
                      >
                        {RideStatus.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        placeholder="Status"
                        name="Status"
                        onChange={handleChange}
                        label="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        helperText={<ErrorMessage name="Status" />}
                      >
                        {Status.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {values?.Payment_Status === "SUCCESS" ?
                      <>
                        {/* User_Cashback_Status */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            name="User_Cashback_Status"
                            onChange={handleChange}
                            label="User Cashback Status"
                            InputLabelProps={{ shrink: true }}
                            value={values.User_Cashback_Status}
                            variant="outlined"
                            disabled
                          >
                          </TextField>
                        </Grid>

                        {/* User_Cashback */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.User_Cashback && errors.User_Cashback)}
                            helperText={<ErrorMessage name="User_Cashback" />}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="User Cashback"
                            name="User_Cashback"
                            type="number"
                            value={values.User_Cashback}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            disabled={values.User_Cashback_Status === "SUCCESS"}
                          >
                          </TextField>
                        </Grid>
                      </>
                      : null}

                    {/* Ride_Rating */}
                    <br></br>
                    <Grid item md={2} xs={2}>
                      <Box>Ride Rating</Box>
                    </Grid>
                    <Grid item md={2} xs={2}>
                      <Rating
                        name="Ride_Rating"
                        onChange={(event, newValue) => {
                          values.Ride_Rating = newValue;
                          setFieldValue(newValue);
                        }}
                        value={values.Ride_Rating}
                        size="medium"
                        disabled
                      />
                    </Grid>

                    {/* Ride_Review */}
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ride Review"
                        name="Ride_Review"
                        value={values.Ride_Review}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        minRows={3}
                        disabled
                      >
                      </TextField>
                    </Grid>

                    {/* Is_Driver_Came_On_Time */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled
                            onBlur={handleBlur}
                            checked={values.Is_Driver_Came_On_Time}
                            onChange={handleChange}
                            name="Is_Driver_Came_On_Time"
                            color="primary"
                          />
                        }
                        label="Is Driver Came On Time"
                      />
                    </Grid>

                    {/* Is_Driver_Polite */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled
                            onBlur={handleBlur}
                            checked={values.Is_Driver_Polite}
                            onChange={handleChange}
                            name="Is_Driver_Polite"
                            color="primary"
                          />
                        }
                        label="Is Driver Polite"
                      />
                    </Grid>

                    {/* Is_Rash_Driving */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Rash_Driving}
                            onChange={handleChange}
                            name="Is_Rash_Driving"
                            color="primary"
                            disabled
                          />
                        }
                        label="Is Rash Driving"
                      />
                    </Grid>

                    {/* Is_Out_Station_Ride */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Out_Station_Ride}
                            onChange={handleChange}
                            name="Is_Out_Station_Ride"
                            color="primary"
                          />
                        }
                        label="Is Out Station Ride"
                      />
                    </Grid>

                    {/* Is_Schedule_Ride */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Schedule_Ride}
                            onChange={handleChange}
                            name="Is_Schedule_Ride"
                            color="primary"
                          />
                        }
                        label="Is Schedule Ride"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default EditUserRides;