import { Link as RouterLink } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAdsAction } from './../../store/actions/adsAction';
import { paginationAds } from 'src/store/actions/node-actions/nodepagination';
import ConfirmDialog from 'src/components/dialogs/confirmDialog';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AdsListView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  //permissions
  const RolePermissions = useSelector(state => state.rolepermission.Roles);
  const roleUserType = useSelector(state => state.auth.user);
  const Login_Details = useSelector(state => state.auth.user);

  const [ads, setAds] = useState([]);
  const [filters, setFilters] = React.useState({
    search: '',
    search_by_filter: 'All'
  });
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = useState({ PageNo: 1, PageSize: 10, SortBy: "Ad_Id", SortOrder: "ASC" })

  const handleLimitChange = event => {
    setPagination({ ...pagination, PageSize: +event.target.value, PageNo: 1 });
  };

  const handlePageChange = (event, newPage) => {
    setPagination({ ...pagination, PageSize: pagination.PageSize, PageNo: newPage + 1 });
  };
  const callBackPagination = async (status, data, pagination) => {
    if (status) {
      setAds(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({ PageNo: 1, pageSize: 10, SortBy: "Ad_Id", SortOrder: "ASC" });
    }
  }

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      adsCall();
    }
  }, [pagination])

  const [testFilters, setTestFilters] = useState({});
  useEffect(() => {
    if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
      setTestFilters(filters);
      searchFunction()
    }
  }, [filters]);

  const searchFunction = () => {
    const searchpagination = {
      PageNo: 1, pageSize: 10, SortBy: "Ad_Id", SortOrder: "ASC"
    }
    dispatch(paginationAds(filters, searchpagination, callBackPagination))
}

  const adsCall = () => {
    dispatch(paginationAds(filters, pagination, callBackPagination))
  };

  useEffect(() => {
    dispatch(paginationAds(filters, pagination, callBackPagination))
    dispatch(getRolePermissions(Login_Details));
  }, []);

  // confirm dailogbox
  const [copen, setCopen] = useState(false);
  const [ctitle, setCtitle] = useState('');
  const [cmessage, setCmessage] = useState('');
  const [adsIdle, setAdsIdle] = useState('');

  const chandleClose = (value) => {
    setCopen(false);
    if (value) {
      const deleteData = {
        Ads_Id: adsIdle.Ads_Id,
        Modified_By: Login_Details?.Email_Id
      };
      dispatch(deleteAdsAction(deleteData, filters, pagination, callBackPagination));
    }
  };

  //Delete handler
  const deleteAdsHandler = adsData => async e => {
    setCopen(true);
    setCtitle("Alert!");
    setCmessage("Are you sure to delete?");
    setAdsIdle(adsData);
  };

  // Sorting 
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Ads_Id');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPagination({ PageNo: 1, PageSize: pagination?.PageSize, SortBy: property, SortOrder: order === 'asc' ? "ASC" : "DESC" })
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/promotion-management"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Ads Management
          </Typography>
        </Box>
        <Toolbar filters={filters} setfilters={setFilters} RolePermissions={RolePermissions} roleUserType={roleUserType} />
        <Box mt={3}>
          <Results
            allAds={ads}
            allAdsNoFilter={ads}
            deleteAdsHandler={deleteAdsHandler}
            handleLimitChange={handleLimitChange}
            handlePageChange={handlePageChange}
            order={order}
            orderBy={orderBy}
            pagination={pagination}
            setPagination={setPagination}
            createSortHandler={createSortHandler}
            RolePermissions={RolePermissions}
            roleUserType={roleUserType}
          />
        </Box>
      </Container>
      <ConfirmDialog inputMessage={cmessage} titleMsg={ctitle} open={copen} onClose={chandleClose} />
    </Page>
  );
};

export default AdsListView;