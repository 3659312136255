import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import config from 'src/store/actions/config';
import { nodeGetApi } from 'src/store/actions/commonAxios';

const BidRideBrandVehicle = (props) => {
    const { vehicleid, ...rest } = props
    const [vehicleField, setvehicleField] = React.useState();
    const [brandvehicles, setBrandVehicles] = useState();

    React.useEffect(() => {
        if (vehicleid && (vehicleid !== vehicleField)) {
            setvehicleField(vehicleid);
            let brandsData = {
                Records_Filter: "FILTER",
                Bidding_Vehicle_Id: vehicleid,
                search_by_filter: "",
                search: "",
            }
            getBrands(brandsData)
        }
    }, [vehicleid])

    const getBrands = (brandsData) => {
        nodeGetApi.post(`/Bidding_Ride_Brand_Vehicle/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Bidding_Ride_Brand_Vehicle_Id&SortOrder=ASC`, brandsData)
            .then(response => {
                setBrandVehicles(response?.data?.results);
            })
            .catch(error => {
            })
    }

    return (
        <TextField {...props}>
            <option key="" value="">--Please Select--</option>
            {brandvehicles?.map(option => (
                <option key={option.Bidding_Ride_Brand_Vehicle_Id} value={option.Bidding_Ride_Brand_Vehicle_Id}>
                    {option.Brand_Name}
                </option>
            ))}
        </TextField>
    );
};

export default BidRideBrandVehicle;