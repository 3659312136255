import {
    DELIVERYDATEANDCOUNT_REQUEST,
    DELIVERYDATEANDCOUNT_SUCCESS,
    DELIVERYDATEANDCOUNT_FAIL,

} from '../actions/deliveryActions'

const initialState = {
    deliverydateandcounts: { all: [], error: '', isLoading: false },
};
export default function (state = initialState, action) {
    switch (action.type) {
        case DELIVERYDATEANDCOUNT_REQUEST:
            return { ...state, deliverydateandcounts: { all: state.deliverydateandcounts.all, error: '', isLoading: true } };
        case DELIVERYDATEANDCOUNT_SUCCESS:
            return { ...state, deliverydateandcounts: { all: action.payload, error: '', isLoading: false } };
        case DELIVERYDATEANDCOUNT_FAIL:
            return { ...state, deliverydateandcounts: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}

