import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Box,
} from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { UserServicesUserPayAction } from 'src/store/actions/servicesAction';


const TransferTypeOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'CR',
        label: 'Credit'
    },
    {
        value: 'DB',
        label: 'Debit'
    }
];

const ServiceUserPayment = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let { state } = location;

    const isSubmited = useSelector(state => state?.services?.userserviceUserPay?.isLoading);
    const errorMessage = useSelector(state => state?.services?.userserviceUserPay?.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    return (
        <Formik
            initialValues={{
                Credit_Reason: "",
                Debit_Reason: "",
                Razor_Pay_Id: "",
                Transfer_Type: "",
                UPI_Id: "",
                User_Service_Request_Id: state.User_Service_Request_Id ? state.User_Service_Request_Id : "",
                User_Mail_Id: state.User_Email_Id ? state.User_Email_Id : "",
                Updated_By: loginEmail
            }}

            validationSchema={
                Yup.object().shape({
                    Credit_Reason: Yup.string().when('Transfer_Type', { is: "CR", then: Yup.string().required('Credited Reason is required.'), }),
                    Debit_Reason: Yup.string().when('Transfer_Type', { is: "DB", then: Yup.string().required('Debited Reason is required.'), }),
                })}

            onSubmit={(values, { setSubmitting, setErrors }) => {
                setSubmitting(false);
                const errors = {};
                setErrors(errors);
                if (values) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(UserServicesUserPayAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        {/* User_Service_Request_Id */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="User Service Request ID"
                                                name="User_Service_Request_Id"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.User_Service_Request_Id}
                                                variant="outlined"
                                                disabled
                                            />
                                        </Grid>

                                        {/* User_email_Id */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="User Email ID"
                                                name="User_Mail_Id"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.User_Mail_Id}
                                                variant="outlined"
                                                disabled
                                            />
                                        </Grid>

                                        {/* Transfer_Type */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Transfer_Type && errors.Transfer_Type)}
                                                fullWidth
                                                helperText={touched.Transfer_Type && errors.Transfer_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                select
                                                SelectProps={{ native: true }}
                                                label="Transfer Type"
                                                name="Transfer_Type"
                                                value={values.Transfer_Type}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                {TransferTypeOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {values.Transfer_Type === 'DB' ? (
                                            <>
                                                {/* Debit_Reason */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.Debit_Reason && errors.Debit_Reason)}
                                                        fullWidth
                                                        helperText={touched.Debit_Reason && errors.Debit_Reason}
                                                        label="Debited Reason"
                                                        name="Debit_Reason"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.Debit_Reason}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                                {/* Credit_Reason */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.Credit_Reason && errors.Credit_Reason)}
                                                        fullWidth
                                                        helperText={touched.Credit_Reason && errors.Credit_Reason}
                                                        label="Credited Reason"
                                                        name="Credit_Reason"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.Credit_Reason}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </>
                                        )}

                                        {/* UPI_Id */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="UPI ID"
                                                name="UPI_Id"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.UPI_Id}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        {/* Razor_Pay_Id */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Razor Pay ID"
                                                name="Razor_Pay_Id"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.Razor_Pay_Id}
                                                variant="outlined"
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Box display="flex" pl={2} >
                        <span style={{ color: "red" }}>{errorMessage}</span>
                    </Box>

                    <Box display="flex" p={2}>
                        {isSubmited ?
                            <CircularProgress />
                            :
                            <Button
                                disabled={isSubmited}
                                type="submit"
                                style={{ backgroundColor: '#27B6CC' }}
                                variant="contained"
                            >
                                <span style={{ color: 'white' }}>Save details</span>
                            </Button>
                        }
                    </Box>

                </form>
            )}
        </Formik>
    );
};

ServiceUserPayment.propTypes = {
    className: PropTypes.string
};

export default ServiceUserPayment;