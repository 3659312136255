import { v4 as uuid } from 'uuid';

export default [
    {
        id: uuid(),
        hide: 0,
        link: '/app/services-management',
        createdAt: '',
        description:
            'A Service Management helps organizers plan, execute and report on events, driving success for their business.',
        media: '/static/Dashboard/catergory.png',
        title: 'User Services Management',
        total: ''
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/genericservices-management',
        createdAt: '',
        description:
            'A Service Management helps organizers plan, execute and report on events, driving success for their business.',
        media: '/static/Dashboard/catergory.png',
        title: 'Services Generic Management',
        total: ''
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/bussiness-subtype',
        createdAt: '',
        description:
            'A Service Management helps organizers plan, execute and report on events, driving success for their business.',
        media: '/static/Dashboard/catergory.png',
        title: 'Business Services Subtypes',
        total: ''
    },
];