import { Button, Card, CardContent, Divider, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from 'src/components/dialogs/confirmDialog';
import { CheckCircleRounded, CancelRounded } from '@material-ui/icons';
import ConfirmDialogone from 'src/components/dialogs/confirmDialog';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router';
import { updateB2BUserProdBiddingComments } from 'src/store/actions/b2buserprodbidcommentsAction';

const B2BUserProdBidCommentsUpdate = ({ className, state, data, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const modifiedBy = useSelector(state => state.auth.user.Email_Id);
    const isapproval = useSelector(state => state.b2buserprodbidcomment.b2buserprodbidcommentUpdate.isLoading);
    const isrejected = useSelector(state => state.b2buserprodbidcomment.b2buserprodbidcommentUpdate.isLoading);

    const [B2BCommentReject, setB2BCommentReject] = useState(false);
    const [B2BCommentTitle, setB2BCommentTitle] = useState('');
    const [B2BCommentMessage, setB2BCommentMessage] = useState('');
    const [B2BCommentIdle, setB2BCommentIdle] = useState();

    const [B2BCommentAccept, setB2BCommentApprove] = useState(false);
    const [B2BCommentTitle1, setB2BCommentTitle1] = useState('');
    const [B2BCommentMessage1, setB2BCommentMessage1] = useState('');
    const [B2BCommentIdle1, setB2BCommentIdle1] = useState();

    const commentRejectHandleClose = (value) => {
        setB2BCommentReject(false);

        if (value) {
            const RejectData = {
                B2B_Bidding_Product_Comment_Id: B2BCommentIdle,
                Is_Admin_Approval: 0,
                Modified_By: modifiedBy
            }
            dispatch(updateB2BUserProdBiddingComments(RejectData, navigate, state));
        }
    };

    const commentAcceptHandleClose = (value) => {
        setB2BCommentApprove(false);

        if (value) {
            const approveData = {
                B2B_Bidding_Product_Comment_Id: B2BCommentIdle1,
                Is_Admin_Approval: 1,
                Modified_By: modifiedBy
            }
            dispatch(updateB2BUserProdBiddingComments(approveData, navigate, state));
        }
    };

    //Approve handler
    const commentApproveHandler = approveData => async e => {
        setB2BCommentApprove(true);
        setB2BCommentTitle1("Alert!");
        setB2BCommentMessage1("Are you sure to Approve?");
        setB2BCommentIdle1(approveData);
    };

    //Reject handler
    const commentRejectHandler = RejectData => async e => {
        setB2BCommentReject(true);
        setB2BCommentTitle("Alert!");
        setB2BCommentMessage("Are you sure to Reject?");
        setB2BCommentIdle(RejectData);
    };

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            {/* B2B_Bidding_Product_Comment_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    B2B Bidding Product Comment ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.B2B_Bidding_Product_Comment_Id}
                                </Typography>
                            </Grid>

                            {/* Is_Admin_Approval */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Admin Approval
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Is_Admin_Approval}
                                </Typography>
                            </Grid>

                            {/* Created_Date */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Created_Date}
                                </Typography>
                            </Grid>

                            {/* Created_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={data?.Created_By}> {data?.Created_By?.length > 25 ?
                                        <span>{data?.Created_By?.substring(0, 25)}...</span> : data?.Created_By}</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={2} />
                            <Grid item xs={2} />
                            <Grid item xs={7} />
                        </Grid>
                    </Grid>

                    {/*  column 2 */}
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            {/* Partner_Bidding_Product_Quote_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Partner Bidding Product Quote ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Partner_Bidding_Product_Quote_Id}
                                </Typography>
                            </Grid>

                            {/* Commented_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Commented By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Commented_By}
                                </Typography>
                            </Grid>

                            {/* Modified_Date */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Modified_Date}
                                </Typography>
                            </Grid>

                            {/* Modified_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem', paddingBottom: '1rem' }}
                                >
                                    <span title={data?.Modified_By}> {data?.Modified_By?.length > 25 ?
                                        <span>{data?.Modified_By?.substring(0, 25)}...</span> : data?.Modified_By}</span>
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Divider />
                {/* Comments */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A', paddingBottom: '1rem' }}
                    >
                        Comments
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                    >
                        <span dangerouslySetInnerHTML={{ __html: data?.Comments }}></span>
                    </Typography>
                </Grid>

            </CardContent>
            <div style={{ margin: '1rem' }}>
                {/* {data?.Is_Admin_Approval !== "1" ? */}
                <div>
                    {isapproval ?
                        <CircularProgress />
                        :
                        <Button variant="outlined" style={{ backgroundColor: '#12824C', color: '#FFFFFF', margin: '2rem' }}
                            startIcon={<CheckCircleRounded />} onClick={commentApproveHandler(data?.B2B_Bidding_Product_Comment_Id)}>Approve</Button>
                    }
                    {isrejected ?
                        <CircularProgress />
                        :
                        <Button variant="outlined" style={{ backgroundColor: 'red', margin: '2rem', color: '#FFFFFF' }} startIcon={<CancelRounded />}
                            onClick={commentRejectHandler(data?.B2B_Bidding_Product_Comment_Id)}>&nbsp;&nbsp;Reject&nbsp;&nbsp;</Button>
                    }
                </div>
                {/* : null} */}
            </div>
            <ConfirmDialog inputMessage={B2BCommentMessage} titleMsg={B2BCommentTitle} open={B2BCommentReject} onClose={commentRejectHandleClose} />
            <ConfirmDialogone inputMessage={B2BCommentMessage1} titleMsg={B2BCommentTitle1} open={B2BCommentAccept} onClose={commentAcceptHandleClose} />
        </Card >
    );
};

export default B2BUserProdBidCommentsUpdate;