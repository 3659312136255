import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    Avatar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { ConnectedFocusError } from "focus-formik-error";
import CircularProgress from '@mui/material/CircularProgress';
import {
    EditServiceCategorytypeAction,
    categoryuploadImagepath
} from '../../../store/actions/servicecategoriesAction';
import { TextareaAutosize } from '@mui/material';

const StatusOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'ACTIVE',
        label: 'ACTIVE'
    },
    {
        value: 'IN-ACTIVE',
        label: 'IN-ACTIVE'
    }
];

const useStyles = makeStyles(() => ({
    images: {
        width: "100%",
        height: 100,
        borderRadius: 5,
        marginBottom: 15
    },
    Description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const EditServiceCategory = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const location = useLocation();
    let { state } = location;

    const [errorMessage, setErrorMessage] = useState({ Service_Name: { key: '', message: '' }, global: { key: '', message: '' } });
    const isSubmitted = useSelector(state => state.category.categoryUpdate.isLoading);
    const modifiedBy = useSelector(state => state.auth.user.Email_Id);
    // img upload
    const [image, setImage] = useState({ preview: state.Image, raw: state.Image });
    const [imgUrl, setImgUrl] = useState(state.Image);
    const [uploadPic, setUploadPic] = useState(false);
    const callBackUploadImgae = data => {
        if (data) {
            setImgUrl(data.File_URL);
        }
        setUploadPic(false);
    };

    const handleImage = e => {
        setUploadPic(true);
        e.preventDefault();
        setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0]
        });
        const data = new FormData();
        data.append('file', e.target.files[0]);
        dispatch(
            categoryuploadImagepath(new Blob([e.target.files[0]]), callBackUploadImgae)
        );
    };

    const handleRemoveImage = e => {
        setImgUrl('');
        setImage({
            preview: '',
            raw: ''
        });
    };






    return (
        <Formik
            initialValues={{
                Image: imgUrl,
                Service_Id: state.Service_Id ? state.Service_Id : "",
                Service_Name: state.Service_Name ? state.Service_Name : "",
                GST: state.Gst ? state.Gst : "",
                Comission: state.Comission ? state.Comission : "",
                Description: state.Description ? state.Description : "",
                Status: state.Status ? state.Status : "",
                // Modified_By: modifiedBy,

            }}
            validationSchema={
                Yup.object().shape({
                    Service_Name: Yup.string().min(3, "Category Name must be at least 3 characters.").notOneOf([errorMessage.Service_Name.key, null], errorMessage.Service_Name.message).required('Category Name is required.'),
                    Status: Yup.string().required('Status is required.'),
                    Comission: Yup.number()
                    .required('Comission is required.')
                    .min(5, 'Comission must be at least 5 %.')
                    .max(100, 'Comission must be at most 100 %.'),
                  GST: Yup.number()
                    .required('Gst is required.')
                    .min(5, 'GST must be at least 5 %.')
                    .max(100, 'GST must be at most 100 %.'),
                    Description: Yup.string().min(20, 'Description must be at least 20 characters.').required('Description is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                values.Image = imgUrl;
                if (!values.Image) {
                    submitTest = false;
                    errors.Image = " Web Image is required.";
                }

                setErrors(errors);
                if (submitTest) {

                    dispatch(EditServiceCategorytypeAction(values, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={12} xs={12}>
                                            {/* <TextField
                                                error={Boolean(touched.Service_Name && errors.Service_Name)}
                                                fullWidth
                                                helperText={touched.Service_Name && errors.Service_Name}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Service Name"
                                                name="Service_Name"
                                                value={values.Service_Name}
                                                variant="outlined"
                                            /> */}
                                              <TextField
                                                    error={Boolean(touched.Service_Name && errors.Service_Name)}
                                                    fullWidth
                                                    helperText={touched.Service_Name && errors.Service_Name}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Service_Name"
                                                    name="Service_Name"
                                                    value={values.Service_Name}
                                                    variant="outlined"
                                                />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                        <TextField
                                                error={Boolean(touched.GST && errors.GST)}
                                                fullWidth
                                                helperText={touched.GST && errors.GST}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handleChange(e);
                                                    }
                                                }}
                                                label="GST %"
                                                name="GST"
                                                value={values.GST}
                                                variant="outlined"
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Comission && errors.Comission)}
                                                fullWidth
                                                helperText={touched.Comission && errors.Comission}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handleChange(e);
                                                    }
                                                }}
                                                label="Comission %"
                                                name="Comission"
                                                value={values.Comission}
                                                variant="outlined"
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            />

                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Box>
                                                <TextareaAutosize
                                                    fullWidth
                                                    className={classes.Description}
                                                    minRows={10}
                                                    placeholder="Description"
                                                    value={values.Description}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    name="Description"
                                                />
                                            </Box>
                                            {touched.Description && errors.Description && (
                                                <span style={{ color: 'red' }}>{errors.Description}</span>
                                            )}
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {StatusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* upload photo */}
                                <Grid item md={4} xs={12}>
                                    <Card>
                                        &nbsp;Web Image
                                        <CardContent>
                                            <Box display="flex" justifyContent="center">
                                                {image.preview ? (
                                                    <Avatar
                                                        className={classes.images}
                                                        alt="Brand Upload"
                                                        src={image.preview}
                                                        variant="square"
                                                    />
                                                ) : (
                                                    <>
                                                        <Avatar
                                                            className={classes.images}
                                                            alt="Brand Upload"
                                                            src="/static/images/image-placeholder.png"
                                                            variant="square"
                                                        />
                                                    </>
                                                )}
                                            </Box>

                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            >
                                                {image.preview ? <Button
                                                    style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                                                    onClick={handleRemoveImage}
                                                >
                                                    Remove Picture
                                                </Button> : <input
                                                    style={{ display: 'block', width: '80%' }}
                                                    id="upload-photo"
                                                    name="image"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImage}
                                                />}
                                                <br />
                                            </Box>
                                        </CardContent>
                                    </Card><br />
                                    <span style={{ color: "red" }}>
                                        {!imgUrl && touched.Image && errors.Image}
                                    </span>




                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage.global.message}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted || uploadPic}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                // onClick={(e) => {
                                //     e.preventDefault()
                                //     dispatch(EditServiceCategorytypeAction({ id:state.Service_Id }))
                                // }
                                // }
                                >
                                    {/* Save details */}
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }

                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditServiceCategory.propTypes = {
    className: PropTypes.string
};

export default EditServiceCategory;