import { Card, CardContent, Grid } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const PartnerEmployeeList = ({ className, state, data, ...rest }) => {

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            {/* Partner_Employee_Id */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Partner Employee ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Partner_Employee_Id ? state?.Partner_Employee_Id : data?.Partner_Employee_Id}

                                </Typography>
                            </Grid>
                            {/* Partner_Details_Id */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Partner Details ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Partner_Details_Id ? state?.Partner_Details_Id : data?.Partner_Details_Id}
                                </Typography>
                            </Grid>
                            {/* Email_Id */}
                            <Grid item xs={2} />
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Email ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Email_Id ? state?.Email_Id : data?.Email_Id}
                                </Typography>
                            </Grid>
                            {/* Mobile */}
                            <Grid item xs={2} />
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Mobile
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Mobile ? state?.Mobile : data?.Mobile}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} />

                            {/* User_Type */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    User Type
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.User_Type ? state?.User_Type : data?.User_Type}
                                </Typography>
                            </Grid>

                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    First Name
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.First_Name ? state?.First_Name : data?.First_Name}
                                </Typography>
                            </Grid>

                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Last Name
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Last_Name ? state?.Last_Name : data?.Last_Name}
                                </Typography>
                            </Grid>

                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Gender
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Gender ? state?.Gender : data?.Gender}
                                </Typography>
                            </Grid>

                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Date of Birth
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.DOB ? state?.DOB : data?.DOB}
                                </Typography>
                            </Grid>

                            {/* Is_Active */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Active
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Is_Active ? state?.Is_Active : data?.Is_Active === 1 ? ("Yes") : ("No")}
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Profile Pic Path
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '0.5rem' }}
                                >
                                    <a target="_blank" href={state?.Profile_Pic_Path ? state?.Profile_Pic_Path : data?.Profile_Pic_Path}>
                                        <span title={state?.Profile_Pic_Path ? state?.Profile_Pic_Path : data?.Profile_Pic_Path}> {state?.Profile_Pic_Path ? state?.Profile_Pic_Path : data?.Profile_Pic_Path?.length > 29 ?
                                            <span>{state?.Profile_Pic_Path ? state?.Profile_Pic_Path : data?.Profile_Pic_Path?.substring(0, 25)}...</span> : state?.Profile_Pic_Path ? state?.Profile_Pic_Path : data?.Profile_Pic_Path}</span>

                                    </a>
                                </Typography>
                            </Grid>

                            {/* Created_Date */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Created_Date ? state?.Created_Date : data?.Created_Date}
                                </Typography>
                            </Grid>

                            {/* Modified_Date */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Modified_Date ? state?.Modified_Date : data?.Modified_Date}
                                </Typography>
                            </Grid>

                            {/* Created_By */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state?.Created_By ? state?.Created_By : data?.Created_By}> {state?.Created_By ? state?.Created_By : data?.Created_By?.length > 25 ?
                                        <span>{state?.Created_By ? state?.Created_By : data?.Created_By?.substring(0, 25)}...</span> : state?.Created_By ? state?.Created_By : data?.Created_By}</span>
                                </Typography>
                            </Grid>

                            {/* Modified_By */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state?.Modified_By ? state?.Modified_By : data?.Modified_By}> {state?.Modified_By ? state?.Modified_By : data?.Modified_By?.length > 25 ?
                                        <span>{state?.Modified_By ? state?.Modified_By : data?.Modified_By?.substring(0, 25)}...</span> : state?.Modified_By ? state?.Modified_By : data?.Modified_By}</span>
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default PartnerEmployeeList;