import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
  Link
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../../store/validations/is-empty';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PaymentIcon from '@mui/icons-material/Payment';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  images: {
    width: 70,
    height: 70,
    cursor: 'pointer'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },

}));

const headCells = [
  { id: 'User_Service_Request_Id', numeric: false, disablePadding: false, label: 'User Service Request ID' },
  { id: 'User_Email_Id', numeric: false, disablePadding: false, label: 'User Email ID' },
  { id: 'User_Service_Request_Status', numeric: false, disablePadding: false, label: 'User Service Status' },
  // { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allService,
  deleteServiceHandler,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  setPage,
  allServicesNoFilter,
  createSortHandler,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedServicesIds, setSelectedServicesIds] = useState([]);

  const visibleServicesHandler = serviceData => e => {
    navigate("/app/view-services", { state: serviceData });
  };
  const editServicesHandler = serviceData => e => {
    navigate('/app/edit-service', { state: serviceData });
  };
  const userpaymentsHandler = serviceData => e => {
    navigate('/app/services-user-payments', { state: serviceData });
  };
  const partnerpaymentsHandler = serviceData => e => {
    navigate('/app/services-completed-partner-payments', { state: serviceData });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel style={{ fontSize: "13px", fontWeight: "bold" }}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>

                  </TableCell>
                ))}
                <TableCell>Payment History</TableCell>
                <TableCell
                  className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-30px' }} > Actions &nbsp;&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allService) &&
                (allService).map(service => (
                  <TableRow
                    hover
                    key={service.User_Service_Request_Id}
                    selected={
                      selectedServicesIds.indexOf(service.User_Service_Request_Id) !== -1
                    }
                  >
                    <TableCell>{service.User_Service_Request_Id}</TableCell>
                    <TableCell style={{ color: '#1665D8' }}>
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href={service.User_Email_Id}
                      >
                        {service.User_Email_Id?.substring(0, 25)}...
                      </Link>
                    </TableCell>
                    <TableCell>{service.User_Service_Request_Status}</TableCell>
                    {/* <TableCell>{service.Status}</TableCell> */}
                    <TableCell >
                      <Tooltip title="User Payment">
                        <Button disabled={(service.User_Service_Request_Status === "CANCELLED") || (service.User_Service_Request_Status === "NEW") ? false : true}
                          onClick={userpaymentsHandler(service)}
                          color="primary"
                        >
                          <PaymentIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Partner Payment">
                        <Button disabled={(service.User_Service_Request_Status === "COMPLETED") ? false : true}
                          onClick={partnerpaymentsHandler(service)}
                          color="primary"
                        >
                          <PaymentIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="View Service History">
                        <Button
                          onClick={visibleServicesHandler(service)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </Tooltip>
                      <Button
                        onClick={editServicesHandler(service)}
                      >
                        <CreateIcon style={{ color: '#9EA0A5' }} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allService) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allService: PropTypes.array.isRequired
};

export default Results;