import {
  TRANSACTION_UPDATE_REQUEST,
  TRANSACTION_UPDATE_SUCCESS,
  TRANSACTION_UPDATE_FAIL,
} from '../actions/transactionhistoryAction'

const initialState = {
  transactionUpdate: { transaction: {}, error: '', isLoading: false },
  transactionDelete: { transaction: {}, error: '', isLoading: false },
  transactionFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TRANSACTION_UPDATE_REQUEST:
      return { ...state, transactionUpdate: { transaction: {}, error: '', isLoading: true } };
    case TRANSACTION_UPDATE_SUCCESS:
      return { ...state, transactionUpdate: { transaction: state.transactionUpdate.transaction, error: '', isLoading: false } };
    case TRANSACTION_UPDATE_FAIL:
      return { ...state, transactionUpdate: { transaction: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}