import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import EditProductColorVariant from './EditProductColorVariant';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const EditProductColorVariantView = () => {
  const location = useLocation();
  const { state } = location;
  const { data } = state.data;
  const classes = useStyles();

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/product-management/product-color-variants"
          variant="text"
          size="small"
          color="inherit"
          state={state.state}
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Edit Product Color Variant
          </Typography>
        </Box>
        <EditProductColorVariant state={state.state} data={state.data} />
      </Container>
    </Page>
  );
};

export default EditProductColorVariantView;