import config from './config';
import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const DELI_BID_VEHI_COMMISSION_CREATE_REQUEST = "DELI_BID_VEHI_COMMISSION_CREATE_REQUEST";
export const DELI_BID_VEHI_COMMISSION_CREATE_SUCCESS = "DELI_BID_VEHI_COMMISSION_CREATE_SUCCESS";
export const DELI_BID_VEHI_COMMISSION_CREATE_FAIL = "DELI_BID_VEHI_COMMISSION_CREATE_FAIL";

export const DELI_BID_VEHI_COMMISSION_UPDATE_REQUEST = "DELI_BID_VEHI_COMMISSION_UPDATE_REQUEST";
export const DELI_BID_VEHI_COMMISSION_UPDATE_SUCCESS = "DELI_BID_VEHI_COMMISSION_UPDATE_SUCCESS";
export const DELI_BID_VEHI_COMMISSION_UPDATE_FAIL = "DELI_BID_VEHI_COMMISSION_UPDATE_FAIL";

export const DELI_USERS_GET_REQUEST = "DELI_USERS_GET_REQUEST";
export const DELI_USERS_GET_SUCCESS = "DELI_USERS_GET_SUCCESS";
export const DELI_USERS_GET_FAIL = "DELI_USERS_GET_FAIL";

export const DELI_BID_VEHICLES_REQUEST = "DELI_BID_VEHICLES_REQUEST";
export const DELI_BID_VEHICLES_SUCCESS = "DELI_BID_VEHICLES_SUCCESS";
export const DELI_BID_VEHICLES_FAIL = "DELI_BID_VEHICLES_FAIL";

//create Delivery Bidding Vehicle Commission Action
export const createDeliBidVehicleCommission = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: DELI_BID_VEHI_COMMISSION_CREATE_REQUEST
    });
    nodeCudApi.post(`/Bidding_Vehicle_Commission/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: DELI_BID_VEHI_COMMISSION_CREATE_SUCCESS
                });
                toast('Delivery Bidding Vehicle Commission created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/delivery-bidding-vehicle-commission');
            } else {
                dispatch({
                    type: DELI_BID_VEHI_COMMISSION_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: DELI_BID_VEHI_COMMISSION_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

//update Delivery Bidding Vehicle Commission Action
export const updateDeliBidVehicleCommission = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: DELI_BID_VEHI_COMMISSION_UPDATE_REQUEST
    });
    nodeCudApi.post(`/Bidding_Vehicle_Commission/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: DELI_BID_VEHI_COMMISSION_UPDATE_SUCCESS
                });
                toast('Delivery Bidding Vehicle Commission updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/delivery-bidding-vehicle-commission');
            } else {

                dispatch({
                    type: DELI_BID_VEHI_COMMISSION_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: DELI_BID_VEHI_COMMISSION_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//get all Bidding Commission Delivery Users
export const getAllBidCommissionDeliveryUsers = formData => async dispatch => {
    try {
        dispatch({
            type: DELI_USERS_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Delivery_Profile/Get_All_Profile?PageNo=1&PageSize=${config.pageSize}&SortBy=DEL_USER_EMAIL_ID&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: DELI_USERS_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: DELI_USERS_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: DELI_USERS_GET_FAIL,
            payload: err
        });
    }
};

//get all Bidding Delivery Vehicles
export const getAllBidCommissionDelVehicles = formData => async dispatch => {
    try {
        dispatch({
            type: DELI_BID_VEHICLES_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Bidding_Vehicle_Details/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Bidding_Vehicle_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: DELI_BID_VEHICLES_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: DELI_BID_VEHICLES_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: DELI_BID_VEHICLES_FAIL,
            payload: err
        });
    }
};