import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
  Button
} from '@material-ui/core';
import isEmpty from 'src/store/validations/is-empty';
import CorpPagination from 'src/components/pagination/corppagination';
import { Users as UsersIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Corporate ID' },
  { id: 'Company_Name', numeric: false, disablePadding: false, label: 'Organization name' },
  { id: 'Website_URL', numeric: false, disablePadding: false, label: 'Organization website' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allcorpUser,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  pagination,
  ...rest
}) => {

  const classes = useStyles();
  const navigate = useNavigate();

  const OpenCorporateUsersHandler = CorpUserData => e => {
    navigate('/app/corporate-user-management', { state: CorpUserData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}>
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}>
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell>Users</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allcorpUser) &&
                (allcorpUser)?.map(corp => (
                  <TableRow
                    hover
                    key={corp.id}>
                    <TableCell>{corp.id}</TableCell>
                    <TableCell>{corp.Company_Name}</TableCell>
                    <TableCell>{corp.Website_URL}</TableCell>
                    <TableCell style={{ color: '#1665D8' }}>{corp.Status}</TableCell>
                    <TableCell>
                      <Tooltip title="Corporate Users">
                        <span>
                          <Button>
                            <UsersIcon
                              onClick={OpenCorporateUsersHandler(corp)}
                              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#9EA0A5', fontSize: 25, marginLeft: -20}} />
                          </Button>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allcorpUser) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <CorpPagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allcorpUser: PropTypes.array.isRequired
};

export default Results;