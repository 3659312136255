import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Partner_Business_Type_Id', numeric: false, disablePadding: true, label: 'Service Subtype ID' },
  { id: 'Business_Type', numeric: false, disablePadding: false, label: 'Service Type' },
  { id: 'Description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'Business_Sub_Type', numeric: false, disablePadding: false, label: 'Service Subtype' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allBussinessType,
  deleteBussinessSubtypeHandler,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allBussinessTypeNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedBussinessTypeIds, setSelectedBussinessTypeIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedBussinessTypeIds;
    if (event.target.checked) {
      newSelectedBussinessTypeIds = allBussinessType.map(bussinessSubtype => bussinessSubtype.Partner_Business_Type_Id);
    } else {
      newSelectedBussinessTypeIds = [];
    }
    setSelectedBussinessTypeIds(newSelectedBussinessTypeIds);
  };

  const handleSelectOne = (event, id) => {
    setSelectedBussinessTypeIds(selectedBussinessTypeIds.filter(record => {
      for (let i = 0; i < allBussinessTypeNoFilter.length; i++) {
        if (record == allBussinessTypeNoFilter[i].Partner_Business_Type_Id) {
          return true;
        }
      }
      return false;
    }))

    const selectedIndex = selectedBussinessTypeIds.indexOf(id);
    let newSelectedBussinessTypeIds = [];
    if (selectedIndex === -1) {
      newSelectedBussinessTypeIds = newSelectedBussinessTypeIds.concat(selectedBussinessTypeIds, id);
    } else if (selectedIndex === 0) {
      newSelectedBussinessTypeIds = newSelectedBussinessTypeIds.concat(
        selectedBussinessTypeIds.slice(1)
      );
    } else if (selectedIndex === selectedBussinessTypeIds.length - 1) {
      newSelectedBussinessTypeIds = newSelectedBussinessTypeIds.concat(
        selectedBussinessTypeIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedBussinessTypeIds = newSelectedBussinessTypeIds.concat(
        selectedBussinessTypeIds.slice(0, selectedIndex),
        selectedBussinessTypeIds.slice(selectedIndex + 1)
      );
    }
    selectedBussinessTypeIds(newSelectedBussinessTypeIds);
  };

  const deleteSelectedBussinessSubTypeHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const editCategoryHandler = categoryData => e => {
    navigate('/app/edit-bussinessSubtype', { state: categoryData });
  };

  const visibleCategoryHandler = categoryData => e => {
    navigate("/app/view-bussinessSubtype", { state: categoryData });
  };
  
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedBussinessTypeIds.filter(record => {
                      for (let i = 0; i < allBussinessTypeNoFilter.length; i++) {
                        if (record == allBussinessTypeNoFilter[i].Partner_Business_Type_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allBussinessType.length}
                    color="primary"
                    indeterminate={
                      selectedBussinessTypeIds.filter(record => {
                        for (let i = 0; i < allBussinessTypeNoFilter.length; i++) {
                          if (record == allBussinessTypeNoFilter[i].Partner_Business_Type_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedBussinessTypeIds.filter(record => {
                        for (let i = 0; i < allBussinessTypeNoFilter.length; i++) {
                          if (record == allBussinessTypeNoFilter[i].Partner_Business_Type_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allBussinessType.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedBussinessTypeIds.filter(record => {
                    for (let i = 0; i < allBussinessTypeNoFilter.length; i++) {
                      if (record == allBussinessTypeNoFilter[i].Partner_Business_Type_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedBussinessTypeIds.filter(record => {
                    for (let i = 0; i < allBussinessTypeNoFilter.length; i++) {
                      if (record == allBussinessTypeNoFilter[i].Partner_Business_Type_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedBussinessSubTypeHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allBussinessType) &&
                (allBussinessType).map(bussinessSubtype => (
                  <TableRow
                    hover
                    key={bussinessSubtype.Partner_Business_Type_Id}
                    selected={
                      selectedBussinessTypeIds.indexOf(bussinessSubtype.Partner_Business_Type_Id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedBussinessTypeIds.indexOf(bussinessSubtype.Partner_Business_Type_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, bussinessSubtype.Partner_Business_Type_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell >{bussinessSubtype.Partner_Business_Type_Id}</TableCell>
                    <TableCell >{bussinessSubtype.Business_Type}</TableCell>
                    <TableCell width="16%" ><div style={{ maxHeight: "40px", overflowY: "hidden", lor: '#1665D8' }} dangerouslySetInnerHTML={{ __html: bussinessSubtype.Description }}></div>
                    </TableCell>
                    <TableCell style={{ justifyContent: 'center' }}>{bussinessSubtype.Business_Sub_Type}  </TableCell>
                    <TableCell style={{ color: '#1665D8' }}>{bussinessSubtype.Status} </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Bussiness_Services_Subtypes_Write?.Value === "Bussiness_Services_Subtypes_Write" && RolePermissions?.Bussiness_Services_Subtypes_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Tooltip title="Bussiness Subtype Details">
                          <Button
                            onClick={visibleCategoryHandler(bussinessSubtype)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        <Button
                          onClick={editCategoryHandler(bussinessSubtype)}>
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                          <DeleteIcon onClick={deleteBussinessSubtypeHandler(bussinessSubtype)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Bussiness_Services_Subtypes_Read?.Value === "Bussiness_Services_Subtypes_Read" && RolePermissions?.Bussiness_Services_Subtypes_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="Bussiness Subtype Details">
                          <Button
                            onClick={visibleCategoryHandler(bussinessSubtype)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allBussinessType) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allBussinessType: PropTypes.array.isRequired
};

export default Results;