import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllSubscriptionUserAction,
    SubscriptionUserFilter
} from './../../store/actions/subscriptionuserAction';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SubscriptionUsersListView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = event => {
    setLimit(+event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // componentDidMount
  useEffect(() => {
    const formData = {
      Records_Filter: "ALL",
    };
    dispatch(getAllSubscriptionUserAction(formData));
    dispatch(SubscriptionUserFilter('search', ''));
    dispatch(SubscriptionUserFilter('select', 'All'));
  }, []);

  let subscriptionMenu = useSelector(state => state.subscription.subscriptionsFilter.select);
  let subscriptionSearch = useSelector(state => state.subscription.subscriptionsFilter.search);

  //static getDerivedStateFromProps

  const allSubscriptionusers = useSelector(state => state.subscription.subscriptions.all);
  const [oldSearch, setOldSearch] = useState(0);

  const checkSubscriptionUserForTable = () => {
   if (subscriptionSearch) {
      try {
        if (subscriptionMenu === "All") {
          return allSubscriptionusers.filter(x => {
            let colummns = ["Subscription_User_Auto_Id", "User_Email_Id", "Schedule_Type", "Subscription_Id", "Subscription_Package_Id", "Subscription_Status"];
            for (let i = 0; i < colummns.length; i++) {
              const mainString = typeof x[colummns[i]] === "string" ? x[colummns[i]]?.toLowerCase() : x[colummns[i]];
              if (typeof x[colummns[i]] === "string") {
                if (mainString.includes(subscriptionSearch?.toLowerCase())) {
                  return true;
                }
              }
              else {
                if (mainString === subscriptionSearch) {
                  return true;
                }
              }
            }
            return false;
          });

        } else {
          return allSubscriptionusers.filter(x => {
            let mainString = typeof x[subscriptionMenu] == "string" ? x[subscriptionMenu]?.toLowerCase() : x[subscriptionMenu];
            let subString = typeof x[subscriptionMenu] == "string" ? subscriptionSearch?.toLowerCase() : subscriptionSearch;
            if (typeof x[subscriptionMenu] === "string") {
              return mainString.includes(subString);
            }
            else {
              if (mainString === subscriptionSearch) {
                return true;
              }
            }
          })
        }
      } finally {
        if (page > 0) {
          if (oldSearch !== subscriptionSearch.length) {
            setOldSearch(subscriptionSearch.length);
            setPage(0);
          }
        }
        else if (oldSearch !== subscriptionSearch.length) {
          setOldSearch(subscriptionSearch.length);
          setPage(0);
        }
      }
    }
    else {
      return allSubscriptionusers
    }
  }

  // Sorting 
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Subscription_User_Auto_Id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  function descendingComparator(a, b, orderBy) {
    try {
      if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
        return -1;
      }
      if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
        return 1;
      }
      return 0;
    } catch (error) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }

  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {

      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/subscription-management"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Subscription User Details
          </Typography>
        </Box>

        <Toolbar />
        <Box mt={3}>
          <Results
            allSubscriptionusers={stableSort(checkSubscriptionUserForTable(), getComparator(order, orderBy))}
            allSubscriptionusersNoFilter={allSubscriptionusers}
            page={page}
            limit={limit}
            handleLimitChange={handleLimitChange}
            handlePageChange={handlePageChange}
            setPage={setPage}
            order={order}
            orderBy={orderBy}
            createSortHandler={createSortHandler}
          />
        </Box>
      </Container>
    </Page>
  );
};


export default SubscriptionUsersListView;