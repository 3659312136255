import {
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  PRODUCTS_FAIL,
  PRODUCTS_FILTER,
} from '../actions/reviewsandratingAction'

const initialState = {
  products: { all: [], error: '', isLoading: false },
  product: { product: {}, error: '', isLoading: false },
  productFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PRODUCTS_REQUEST:
      return { ...state, products: { all: state.products.all, error: '', isLoading: true } };
    case PRODUCTS_SUCCESS:
      return { ...state, products: { all: action.payload, error: '', isLoading: false } };
    case PRODUCTS_FAIL:
      return { ...state, products: { all: [], error: action.payload, isLoading: false } };
    case PRODUCTS_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, productFilter: { search: state.productFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, productFilter: { select: state.productFilter.select, search: action.value } };
      }
      return res;
    default:
      return state;    
  }
}