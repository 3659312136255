import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    makeStyles,
} from '@material-ui/core';
import isEmpty from 'src/store/validations/is-empty';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

// useStyles we use a hook to consume the styles
const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    }
}));

//headCells are the values in header
const headCells = [
    { id: 'Deal_Id', numeric: false, disablePadding: false, label: 'Deal ID' },
    { id: 'Title', numeric: false, disablePadding: false, label: 'Deal Title' },
    { id: 'Name', numeric: false, disablePadding: false, label: 'Partner Name' },
    { id: 'Discount', numeric: true, disablePadding: false, label: 'Discount' },
    { id: 'Start_Date', numeric: false, disablePadding: false, label: 'Start Date' },
    { id: 'End_Date', numeric: false, disablePadding: false, label: 'End Date' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
    className,
    allDealsApproval,
    updateDealApprovalHandler,
    pagination,
    setPagination,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    createSortHandler,
    allDealsApprovalNoFilter,
    RolePermissions,
    roleUserType,
    ...rest
}) => {
    // useNavigation is a hook which gives access to navigation object
    const navigate = useNavigate();
    // classes contains the styles
    const classes = useStyles();
    // useState is a Hook that allows you to have state variables in functional components
    const [selectedDealApprovalIds, setSelectedDealApprovalIds] = useState([]);

    //visibleDealHandler contains the naviagtion for view page
    const visibleDealHandler = viewData => e => {
        navigate("/app/view-dealapproval", { state: viewData });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1200}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allDealsApproval) &&
                                (allDealsApproval).map(dealapproval => (
                                    <TableRow
                                        hover
                                        key={dealapproval.Deal_Id}
                                        selected={
                                            selectedDealApprovalIds.indexOf(dealapproval.Deal_Id) !== -1
                                        }
                                    >
                                        <TableCell >{dealapproval.Deal_Id}</TableCell>
                                        <TableCell >{dealapproval.Title}</TableCell>
                                        <TableCell >{dealapproval.Partner_Name}</TableCell>
                                        <TableCell >&nbsp;&nbsp;{dealapproval.Discount_Type === "A" ? "₹" + dealapproval.Discount : dealapproval.Discount + "%"}</TableCell>
                                        <TableCell >{dealapproval.Start_Date}</TableCell>
                                        <TableCell >{dealapproval.End_Date}</TableCell>
                                        <TableCell style={{ color: '#1665D8' }}>{dealapproval.Status}</TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Pending_Deals_Write?.Value === "Pending_Deals_Write" && RolePermissions?.Pending_Deals_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="View Deal">
                                                    <Button
                                                        onClick={visibleDealHandler(dealapproval)}
                                                        color="primary"
                                                    >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                                <Button
                                                    onClick={updateDealApprovalHandler(dealapproval)}
                                                    style={{ backgroundColor: '#12824C', color: '#FFFFFF' }}
                                                >
                                                    ACCEPT
                                                </Button>
                                            </TableCell>
                                            : null}
                                        {RolePermissions?.Pending_Deals_Read?.Value === "Pending_Deals_Read" && RolePermissions?.Pending_Deals_Read?.Is_Read === 1 &&
                                            <TableCell style={{ justifyContent: 'center' }}>
                                                <Tooltip title="View Deal">
                                                    <Button
                                                        onClick={visibleDealHandler(dealapproval)}
                                                        color="primary"
                                                    >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allDealsApproval) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <Pagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    allDealsApproval: PropTypes.array.isRequired
};

export default Results;