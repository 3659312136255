import config from './config';
import { toast } from 'react-toastify';
import { paginationDeliveryCommentGet } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const ALL_DELIVERY_COMMENTS_REQUEST = "ALL_DELIVERY_COMMENTS_REQUEST";
export const ALL_DELIVERY_COMMENTS_SUCCESS = "ALL_DELIVERY_COMMENTS_SUCCESS";
export const ALL_DELIVERY_COMMENTS_FAIL = "ALL_DELIVERY_COMMENTS_FAIL";

export const DELIVERY_COMMENTS_CREATE_REQUEST = "DELIVERY_COMMENTS_CREATE_REQUEST";
export const DELIVERY_COMMENTS_CREATE_SUCCESS = "DELIVERY_COMMENTS_CREATE_SUCCESS";
export const DELIVERY_COMMENTS_CREATE_FAIL = "DELIVERY_COMMENTS_CREATE_FAIL";

export const DELIVERY_COMMENTS_UPDATE_REQUEST = "DELIVERY_COMMENTS_UPDATE_REQUEST";
export const DELIVERY_COMMENTS_UPDATE_SUCCESS = "DELIVERY_COMMENTS_UPDATE_SUCCESS";
export const DELIVERY_COMMENTS_UPDATE_FAIL = "DELIVERY_COMMENTS_UPDATE_FAIL";

/*=============================================================
              Add Delivery Comment Ticket Action
===============================================================*/
export const createdeliveryCommentTicketAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: DELIVERY_COMMENTS_CREATE_REQUEST
  });
  nodeCudApi.post(`/Delivery_Ticket_Comment/Ticket_Comment_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DELIVERY_COMMENTS_CREATE_SUCCESS
        });
        toast('Comment added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/delivery-ticket-comments', state = { state });

      } else {
        dispatch({
          type: DELIVERY_COMMENTS_CREATE_SUCCESS,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: DELIVERY_COMMENTS_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};
/*=============================================================
              edit Delivery Comment Ticket Action
===============================================================*/
export const updatedeliveryCommentTicketAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: DELIVERY_COMMENTS_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Delivery_Ticket_Comment/Ticket_Comment_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DELIVERY_COMMENTS_UPDATE_SUCCESS
        });
        toast('Comment updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/delivery-ticket-comments', state = { state });

      } else {
        dispatch({
          type: DELIVERY_COMMENTS_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: DELIVERY_COMMENTS_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

/*=============================================================
                Delete Delivery comment Action
===============================================================*/
export const deletedeliveryCommentAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Delivery_Ticket_Comment/Ticket_Comment_Delete`, formData);
    if (data) {
      toast('Comment deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationDeliveryCommentGet(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

// Get DeliveryComment Action
export const getDeliveryCommentAction = (formData, callBackDeliveryCommentData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Delivery_Ticket_Comment/Ticket_Comment_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Del_Ticket_Comment_Id&SortOrder=ASC`, formData);
    if (data) {
      callBackDeliveryCommentData(data)
    }
  } catch (err) {
  }
};