import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    makeStyles,
    Typography,
    Breadcrumbs,
    Link,
    Grid,
    Button
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import CorpOrderInfo from './orderInfo';
import isEmpty from 'src/store/validations/is-empty';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { corpInvoiceget } from 'src/store/actions/corporderAction';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    actionButton: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    }
}));

const CorpOrderInfoView = () => {
    const location = useLocation();
    const { state } = location;
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [allorderinfo, setOrderInfo] = useState([]);
    const [invoiceDetails, setInvoiceDetails] = useState();

    const updateStatesInvoice = (status, data) => {
        if (status) {
            setInvoiceDetails(data);
        }
    };
    let corporderinfo = useSelector(state => state.corporder.allorderinfo);
    useEffect(() => {
        if (!isEmpty(corporderinfo)) {
            setOrderInfo(corporderinfo.corporder);
        }
    }, [corporderinfo]);
    useEffect(() => {
        let formDataInvoice = {
            "Order_Id": state?.Order_Id
        }
        dispatch(corpInvoiceget(formDataInvoice, updateStatesInvoice));
    }, []);
    const viewOrderInvoice = orderInvoiceData => e => {
        navigate("/app/corp-order-invoice-view", { state: orderInvoiceData });
    };
    const orderData = [
        {
            order_Id: 'ua64gd67ewundsu8e',
            user_Name: 'chakri',
            mobile: 8686898584,
        },
        {
            order_Id: 'ua6ffssd67ewundsu8e',
            user_Name: 'heme',
            mobile: 8686898585,
        },
        {
            order_Id: 'yq87gd67ewqwsz3h',
            user_Name: 'ravi',
            mobile: 8686898586,
        },
        {
            order_Id: 'ws12hg67ehfcsa3r',
            user_Name: 'kiran',
            mobile: 8686898587,
        },
        {
            order_Id: 'ph34gd67etresf9b',
            user_Name: 'deepak',
            mobile: 8686898588,
        },
        {
            order_Id: 'jq56gd67eiudsx9d',
            user_Name: 'sai',
            mobile: 8686898589,
        },
        {
            order_Id: 'us87gd67eqwexu8a',
            user_Name: 'vamsi',
            mobile: 8686898590,
        },
        {
            order_Id: 'ml21gd67eytrexa5z',
            user_Name: 'prasad',
            mobile: 8686898591,
        },
        {
            order_Id: 'vc98gd67ewjklo1p',
            user_Name: 'suresh',
            mobile: 8686898592,
        },
        {
            order_Id: 'kl09gd67ewihgf6d',
            user_Name: 'pavan',
            mobile: 8686898593,
        },
        {
            order_Id: 'dr56gd67elkomz3p',
            user_Name: 'ram',
            mobile: 8686898594,
        },
        {
            order_Id: 'zx34gd67ewonsv1x',
            user_Name: 'varun',
            mobile: 8686898595,
        },
        {
            order_Id: 'tx78gd67ewiqap7r',
            user_Name: 'raj',
            mobile: 8686898596,
        },
        {
            order_Id: 'pl12gd67ewvmbw4l',
            user_Name: 'shiva',
            mobile: 8686898597,
        },
        {
            order_Id: 'gb87gd67enbxal6p',
            user_Name: 'mahesh',
            mobile: 8686898598,
        },
    ];
    const exportToCSV = () => {
        const headers = ['Order ID', 'User Name', 'Mobile Number'];
        const rows = orderData.map(order => [order.order_Id, order.user_Name, order.mobile]);

        let csvContent = 'data:text/csv;charset=utf-8,'
            + [headers.join(','), ...rows.map(row => row.join(','))].join('\n');

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'order_info.csv');
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link); // Clean up after download
    };
    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                {state?.page === "doorstep" ?
                    <Button
                        component={RouterLink}
                        to="/app/corp-order-doorstep-delivery-management"
                        variant="text"
                        size="small"
                        color="inherit"
                        state={state}
                    >
                        <KeyboardBackspaceIcon />
                        Go back
                    </Button>
                    :
                    <Button
                        component={RouterLink}
                        to="/app/corporate-order-management"
                        variant="text"
                        size="small"
                        color="inherit"
                    >
                        <KeyboardBackspaceIcon />
                        Go back
                    </Button>
                }
                <Box py={2}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h2" gutterBottom>
                                Order info for User
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.actionButton}

                                onClick={viewOrderInvoice(invoiceDetails)}
                            >
                                Download Invoice
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.actionButton}
                                onClick={exportToCSV}
                                style={{ marginLeft: 8 }} // Adds some space between the buttons
                            >
                                Download CSV
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <CorpOrderInfo state={state?.state} data={state?.data} allorderinfo={allorderinfo} />
            </Container>
        </Page>
    );
};

export default CorpOrderInfoView;