import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  EditUserAction, getAllRoles
} from '../../store/actions/userAction';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/material.css'
import { CircularProgress } from '@mui/material';

const EditUser = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = location;

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  const isSubmited = useSelector(state => state.user.userUpdate.isLoading);
  const errorMessage = useSelector(state => state.user.userUpdate.error);
  const roles = useSelector(state => state.user.Roles.roles);
  const ModifiedBy = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        User_Type: state.User_Type ? state.User_Type : "",
        Email_Id: state.Email_Id ? state.Email_Id : "",
        Mobile: state.Mobile ? state.Mobile : "",
        Password: '',
        Is_Active: state.Is_Active > 0 ? true : false,
        IS_Blocked_By_Admin: state.IS_Blocked_By_Admin > 0 ? true : false,
        Modified_By: ModifiedBy
      }}
      validationSchema={Yup.object().shape({
        Mobile: Yup.string().min(14, "Please provide a valid Mobile Number.").required('Mobile Number is required.'),
        User_Type: Yup.string().required('User Type is required.'),
      })}

      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        let formValues = JSON.parse(JSON.stringify(values));
        if (values.Is_Active) {
          formValues.Is_Active = 1;
        } else {
          formValues.Is_Active = 0;
        }
        if (values.IS_Blocked_By_Admin) {
          formValues.IS_Blocked_By_Admin = 1;
        } else {
          formValues.IS_Blocked_By_Admin = 0;
        }
        dispatch(EditUserAction(formValues, navigate));
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      {/* Email_Id*/}
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Email ID"
                        name="Email_Id"
                        value={values.Email_Id}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                    {/* Mobile */}
                    <Grid item md={6} xs={12}>
                      <PhoneInput
                        containerClass={touched.Mobile && errors.Mobile ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                        inputClass={"form-control MuiOutlinedInput-input"}
                        error={true}
                        country={"in"}
                        fullWidth
                        inputStyle={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px"
                        }}
                        inputProps={{
                          name: "Mobile",
                        }}
                        specialLabel="Mobile Number"
                        placeholder="+91 XXXXX XXXXX"
                        name="Mobile"
                        onBlur={handleBlur}
                        onChange={(value, country, e, formattedValue) => {
                          setFieldValue("Mobile", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                        }}
                        disabled
                        value={values.Mobile}
                      />
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Mobile && errors.Mobile}</p>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.User_Type && errors.User_Type)}
                        fullWidth
                        helperText={touched.User_Type && errors.User_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="User Type"
                        name="User_Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.User_Type}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {roles?.sort((a, b) => a.Role_Name?.localeCompare(b.Role_Name))?.map(option => (
                          <option key={option.Role_Name} value={option.Role_Name}>
                            {option.Role_Name}
                          </option>))}
                      </TextField>
                    </Grid>
                    {/* Is_Active */}
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Active}
                            onChange={handleChange}
                            name="Is_Active"
                            color="primary"
                          />}
                        label="Is Active"
                      />
                    </Grid>
                    {/* IS_Blocked_By_Admin */}
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.IS_Blocked_By_Admin}
                            onChange={handleChange}
                            name="IS_Blocked_By_Admin"
                            color="primary"
                          />}
                        label="Is Blocked By Admin"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" pl={2} pt={1} pb={2}>
              {isSubmited ?
                <CircularProgress /> :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>}
            </Box>
          </Card>
        </form>)}
    </Formik>);
};

EditUser.propTypes = {
  className: PropTypes.string
};

export default EditUser;