import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableSortLabel,
    TableRow,
    makeStyles,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import isEmpty from '../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'src/components/pagination/pagination';
import PaymentIcon from '@mui/icons-material/Payment';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    images: {
        width: 100,
        height: 50
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
        width: '180px'
    }
}));

const headCells = [
    { id: 'User_Service_Bidding_Id', numeric: false, disablePadding: true, label: 'User Services Bidding ID' },
    { id: 'User_Email_Id', numeric: false, disablePadding: false, label: 'User Email ID' },
    { id: 'Service_Type', numeric: false, disablePadding: false, label: 'Service Type' },
    { id: 'User_Pincode', numeric: false, disablePadding: false, label: 'User Pincode' },
    { id: 'Bidding_Status', numeric: false, disablePadding: false, label: 'Bidding Status' },
];

const Results = ({
    className,
    allBiddingUserServices,
    order,
    orderBy,
    createSortHandler,
    handleLimitChange,
    handlePageChange,
    allBiddingUserServicesNoFilter,
    pagination,
    setPagination,
    notifyServiceHandler,
    RolePermissions,
    roleUserType,
    ...rest
}) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const visibleBiddingUserServicesHandler = biddinguserserviceData => e => {
        navigate("/app/view-bidding-user-services", { state: biddinguserserviceData });
    };
    const editBiddingUserServicesHandler = biddinguserserviceData => e => {
        navigate('/app/edit-bidding-user-services', { state: biddinguserserviceData });
    };

    const BidPartnerQuoteHandler = partnerQuoteData => e => {
        navigate('/app/partner-bidding-service-quotes', { state: partnerQuoteData });
    };

    const ServicesPartnerPaymentsHandler = biddinguserservicepaymentData => e => {
        navigate("/app/bidding-services-partner-payments", { state: biddinguserservicepaymentData });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                    (RolePermissions?.Bidding_User_Services_Write?.Value === "Bidding_User_Services_Write" && RolePermissions?.Bidding_User_Services_Write?.Is_Write === 1) ?
                                    <TableCell >Quotes</TableCell>
                                    : null}
                                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                    (RolePermissions?.Bidding_User_Services_Write?.Value === "Bidding_User_Services_Write" && RolePermissions?.Bidding_User_Services_Write?.Is_Write === 1) ?
                                    <TableCell >Notifications</TableCell>
                                    : null}

                                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                    (RolePermissions?.Bidding_User_Services_Write?.Value === "Bidding_User_Services_Write" && RolePermissions?.Bidding_User_Services_Write?.Is_Write === 1) ?
                                    <TableCell> Payments</TableCell>
                                    : null}

                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allBiddingUserServices) &&
                                (allBiddingUserServices).map(biddinguserservice => (
                                    <TableRow
                                        hover
                                        key={biddinguserservice.User_Service_Bidding_Id}
                                    >
                                        <TableCell>{biddinguserservice.User_Service_Bidding_Id}</TableCell>
                                        <TableCell>{biddinguserservice.User_Email_Id?.substring(0, 20)}...</TableCell>
                                        <TableCell>{biddinguserservice.Service_Type}</TableCell>
                                        <TableCell>{biddinguserservice.User_Pincode}</TableCell>
                                        <TableCell>{biddinguserservice.Bidding_Status}</TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Bidding_User_Services_Write?.Value === "Bidding_User_Services_Write" && RolePermissions?.Bidding_User_Services_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="Quotes">
                                                    <Button>
                                                        <RequestQuoteIcon
                                                            onClick={BidPartnerQuoteHandler(biddinguserservice)}
                                                            style={{ display: 'flex', justifyContent: 'center', color: '#9EA0A5', fontSize: 20, }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            : null}
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Bidding_User_Services_Write?.Value === "Bidding_User_Services_Write" && RolePermissions?.Bidding_User_Services_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="Notifications">
                                                    <span>
                                                        <Button>
                                                            <AddAlertIcon
                                                                onClick={notifyServiceHandler(biddinguserservice)}
                                                                style={{ display: 'flex', justifyContent: 'center', color: '#9EA0A5', fontSize: 20, }} />
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                            : null}
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Bidding_User_Services_Write?.Value === "Bidding_User_Services_Write" && RolePermissions?.Bidding_User_Services_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="Partner Payment">
                                                    <span>
                                                        <Button disabled={biddinguserservice.Advance_Payment_Status_From_User !== 1 ? true : false}
                                                            onClick={ServicesPartnerPaymentsHandler(biddinguserservice)}
                                                            color="primary"
                                                        >
                                                            <PaymentIcon style={{ color: '#9EA0A5' }} />
                                                        </Button>
                                                    </span>
                                                </Tooltip>

                                            </TableCell>
                                            : null}
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Bidding_User_Services_Write?.Value === "Bidding_User_Services_Write" && RolePermissions?.Bidding_User_Services_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="Bidding User Service Details">
                                                    <span>
                                                        <Button
                                                            onClick={visibleBiddingUserServicesHandler(biddinguserservice)}
                                                            color="primary" >
                                                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                                <Button
                                                    onClick={editBiddingUserServicesHandler(biddinguserservice)}
                                                >
                                                    <CreateIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                            </TableCell>

                                            : null}
                                        {RolePermissions?.Bidding_User_Services_Read?.Value === "Bidding_User_Services_Read" && RolePermissions?.Bidding_User_Services_Read?.Is_Read === 1 &&
                                            <TableCell>
                                                <Tooltip title="Bidding User Service Details">
                                                    <Button
                                                        onClick={visibleBiddingUserServicesHandler(biddinguserservice)}
                                                        color="primary" >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allBiddingUserServices) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <Pagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
};

export default Results;