
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { EditStoretypepartnerlinkingAction, getAllPartners } from 'src/store/actions/storetypepartnerlinkingActions';
import { CircularProgress } from '@mui/material';

const EditStoreTypePartnerLink = ({ className, state, data, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSubmited = useSelector(state => state.storetypepartnerlink.storetypepartnerlinkUpdate.isLoading);
  const errorMessage = useSelector(state => state.storetypepartnerlink.storetypepartnerlinkUpdate.error);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);
  const partners = useSelector(state => state.storetypepartnerlink.Partners.partners || []);

  /// componentDidMount
  useEffect(() => {
    dispatch(getAllPartners());
  }, []);

  return (
    <Formik
      initialValues={{
        Store_Type_Partner_Id: data.Store_Type_Partner_Id ? data.Store_Type_Partner_Id : "",
        Store_Type_Name: data.Store_Type_Name ? data.Store_Type_Name : "",
        Partner_Detail_Id: data.Partner_Detail_Id ? data.Partner_Detail_Id : "",
        Is_Active: data.Is_Active > 0 ? true : false,
        Updated_By: modifiedBy
      }}

      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        const errors = {};
        setSubmitting(false);
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          if (values.Is_Active) {
            formValues.Is_Active = 1;
          } else {
            formValues.Is_Active = 0;
          }
          dispatch(EditStoretypepartnerlinkingAction(formValues, navigate, state));
        }
      }}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container>
                <Grid container spacing={3}>
                  <Grid item md={8} xs={12}>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Store Type Name"
                          name="Store_Type_Name"
                          value={values.Store_Type_Name}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        {/* category name */}
                        <TextField
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Partner Name"
                          name="Partner_Detail_Id"
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ native: true }}
                          value={values.Partner_Detail_Id}
                          variant="outlined"
                          disabled
                        ><option key="" value="">--Please Select--</option>
                          {partners?.map(option => (
                            <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                              {option.Name}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      {/* Is Eligible For Withdraw */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Active}
                              onChange={handleChange}
                              name="Is_Active"
                              color="primary"
                            />
                          }
                          label="Is Active"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2}>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" pl={2} pt={1} pb={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditStoreTypePartnerLink.propTypes = {
  className: PropTypes.string
};

export default EditStoreTypePartnerLink;