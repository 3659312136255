import { Button, Card, CardContent, Divider, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from 'src/components/dialogs/confirmDialog';
import { CheckCircleRounded, CancelRounded } from '@material-ui/icons';
import ConfirmDialogone from 'src/components/dialogs/confirmDialog';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router';
import { updateDeliveryBiddingComments } from 'src/store/actions/deliverybiddingcommentsAction';

const DeliveryBiddingCommentsUpdate = ({ className, state, data, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const modifiedBy = useSelector(state => state.auth.user.Email_Id);
    const isapproval = useSelector(state => state.deliverybiddingcomment.deliverybidcommentUpdate.isLoading);
    const isrejected = useSelector(state => state.deliverybiddingcomment.deliverybidcommentUpdate.isLoading);

    const [DBCommentReject, setDBCommentReject] = useState(false);
    const [DBCommentTitle, setDBCommentTitle] = useState('');
    const [DBCommentMessage, setDBCommentMessage] = useState('');
    const [DBCommentIdle, setDBCommentIdle] = useState();

    const [DBCommentAccept, setDBCommentApprove] = useState(false);
    const [DBCommentTitle1, setDBCommentTitle1] = useState('');
    const [DBCommentMessage1, setDBCommentMessage1] = useState('');
    const [DBCommentIdle1, setDBCommentIdle1] = useState();

    const commentRejectHandleClose = (value) => {
        setDBCommentReject(false);

        if (value) {
            const RejectData = {
                Bidding_Ride_Comment_Id: DBCommentIdle,
                Is_Admin_Approval: 0,
                Modified_By: modifiedBy
            }
            dispatch(updateDeliveryBiddingComments(RejectData, navigate, state));
        }
    };

    const commentAcceptHandleClose = (value) => {
        setDBCommentApprove(false);

        if (value) {
            const approveData = {
                Bidding_Ride_Comment_Id: DBCommentIdle1,
                Is_Admin_Approval: 1,
                Modified_By: modifiedBy
            }
            dispatch(updateDeliveryBiddingComments(approveData, navigate, state));
        }
    };

    //Approve handler
    const commentApproveHandler = approveData => async e => {
        setDBCommentApprove(true);
        setDBCommentTitle1("Alert!");
        setDBCommentMessage1("Are you sure to Approve?");
        setDBCommentIdle1(approveData);
    };

    //Reject handler
    const commentRejectHandler = RejectData => async e => {
        setDBCommentReject(true);
        setDBCommentTitle("Alert!");
        setDBCommentMessage("Are you sure to Reject?");
        setDBCommentIdle(RejectData);
    };

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            {/* Bidding_Ride_Comment_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Bidding Ride Comment ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Bidding_Ride_Comment_Id}
                                </Typography>
                            </Grid>

                            {/* Is_Admin_Approval */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Admin Approval
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Is_Admin_Approval}
                                </Typography>
                            </Grid>

                            {/* Created_Date */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Created_Date}
                                </Typography>
                            </Grid>

                            {/* Created_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={data?.Created_By}> {data?.Created_By?.length > 25 ?
                                        <span>{data?.Created_By?.substring(0, 25)}...</span> : data?.Created_By}</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={2} />
                            <Grid item xs={2} />
                            <Grid item xs={7} />
                        </Grid>
                    </Grid>

                    {/*  column 2 */}
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            {/* Delivery_Bidding_Ride_Quote_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Delivery Bidding Ride Quote ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Delivery_Bidding_Ride_Quote_Id}
                                </Typography>
                            </Grid>

                            {/* Commented_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Commented By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Commented_By}
                                </Typography>
                            </Grid>

                            {/* Modified_Date */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Modified_Date}
                                </Typography>
                            </Grid>

                            {/* Modified_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem', paddingBottom: '1rem' }}
                                >
                                    <span title={data?.Modified_By}> {data?.Modified_By?.length > 25 ?
                                        <span>{data?.Modified_By?.substring(0, 25)}...</span> : data?.Modified_By}</span>
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Divider />
                {/* Comments */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A', paddingBottom: '1rem' }}
                    >
                        Comments
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                    >
                        <span dangerouslySetInnerHTML={{ __html: data?.Comments }}></span>
                    </Typography>
                </Grid>

            </CardContent>
            <div style={{ margin: '1rem' }}>
                {/* {data?.Is_Admin_Approval !== "1" ? */}
                <div>
                    {isapproval ?
                        <CircularProgress />
                        :
                        <Button variant="outlined" style={{ backgroundColor: '#12824C', color: '#FFFFFF', margin: '2rem' }}
                            startIcon={<CheckCircleRounded />} onClick={commentApproveHandler(data?.Bidding_Ride_Comment_Id)}>Approve</Button>
                    }
                    {isrejected ?
                        <CircularProgress />
                        :
                        <Button variant="outlined" style={{ backgroundColor: 'red', margin: '2rem', color: '#FFFFFF' }} startIcon={<CancelRounded />}
                            onClick={commentRejectHandler(data?.Bidding_Ride_Comment_Id)}>&nbsp;&nbsp;Reject&nbsp;&nbsp;</Button>
                    }
                </div>
                {/* : null} */}
            </div>
            <ConfirmDialog inputMessage={DBCommentMessage} titleMsg={DBCommentTitle} open={DBCommentReject} onClose={commentRejectHandleClose} />
            <ConfirmDialogone inputMessage={DBCommentMessage1} titleMsg={DBCommentTitle1} open={DBCommentAccept} onClose={commentAcceptHandleClose} />
        </Card >
    );
};

export default DeliveryBiddingCommentsUpdate;