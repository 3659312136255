import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
    TableSortLabel,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WalletIcon from '@mui/icons-material/Wallet';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    buttonRoot: {
        minWidth: '7px',
        padding: '6px'
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    },
}));

const headCells = [
    { id: 'Wallet_Id', numeric: false, disablePadding: false, label: 'Wallet ID' },
    { id: 'Wallet_Owner', numeric: false, disablePadding: false, label: 'Wallet Owner' },
    { id: 'Total_Amount', numeric: false, disablePadding: false, label: 'Total Amount' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
    className,
    alldeliveryWallet,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    alldeliveryWalletNoFilter,
    createSortHandler,
    pagination,
    setPagination,
    RolePermissions,
    roleUserType,
    ...rest
}) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [selectedDeliveryWalletIds, setSelectedDeliveryWalletIds] = useState([]);

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const handleClose = (value) => {
        setOpen(false);
    };

    const handleSelectAll = event => {
        let newSelectedDeliWalletIds;
        if (event.target.checked) {
            newSelectedDeliWalletIds = alldeliveryWallet.map(delwallet => delwallet.Wallet_Id);
        } else {
            newSelectedDeliWalletIds = [];
        }
        setSelectedDeliveryWalletIds(newSelectedDeliWalletIds);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedDeliveryWalletIds.indexOf(id);
        let newSelectedDeliWalletIds = [];
        if (selectedIndex === -1) {
            newSelectedDeliWalletIds = newSelectedDeliWalletIds.concat(selectedDeliveryWalletIds, id);
        } else if (selectedIndex === 0) {
            newSelectedDeliWalletIds = newSelectedDeliWalletIds.concat(
                selectedDeliveryWalletIds.slice(1)
            );
        } else if (selectedIndex === selectedDeliveryWalletIds.length - 1) {
            newSelectedDeliWalletIds = newSelectedDeliWalletIds.concat(
                selectedDeliveryWalletIds.slice(0, -1)
            );
        } else if (selectedIndex > 0) {
            newSelectedDeliWalletIds = newSelectedDeliWalletIds.concat(
                selectedDeliveryWalletIds.slice(0, selectedIndex),
                selectedDeliveryWalletIds.slice(selectedIndex + 1)
            );
        }
        setSelectedDeliveryWalletIds(newSelectedDeliWalletIds);
    };

    const deleteSelectedDeliWalletHandler = () => async e => {
        setOpen(true);
        setTitle("Alert!");
        setMessage("This feature is under development.");
    };

    const editDeliveryWalletHandler = deliveryWalletData => e => {
        navigate('/app/edit-delivery-wallet', { state: deliveryWalletData });
    };

    const visibleDeliveryWalletHandler = delwallet => e => {
        navigate("/app/delivery-wallet-history", { state: delwallet });
    };

    const viewDeliveryWalletHandler = delwallet => e => {
        navigate("/app/view-delivery-wallet", { state: delwallet });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedDeliveryWalletIds.filter(record => {
                                            for (let i = 0; i < alldeliveryWalletNoFilter.length; i++) {
                                                if (record == alldeliveryWalletNoFilter[i].Wallet_Id) {
                                                    return true;
                                                }
                                            }
                                            return false;
                                        }).length === alldeliveryWallet.length}
                                        color="primary"
                                        indeterminate={
                                            selectedDeliveryWalletIds.filter(record => {
                                                for (let i = 0; i < alldeliveryWalletNoFilter.length; i++) {
                                                    if (record == alldeliveryWalletNoFilter[i].Wallet_Id) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            }).length > 0 &&
                                            selectedDeliveryWalletIds.filter(record => {
                                                for (let i = 0; i < alldeliveryWalletNoFilter.length; i++) {
                                                    if (record == alldeliveryWalletNoFilter[i].Wallet_Id) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            }).length < alldeliveryWallet.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                    (RolePermissions?.Delivery_Wallet_Write?.Value === "Delivery_Wallet_Write" && RolePermissions?.Delivery_Wallet_Write?.Is_Write === 1) ?
                                    <TableCell>Wallet History</TableCell>
                                    : null}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-5rem' }} >Actions
                                    {selectedDeliveryWalletIds.filter(record => {
                                        for (let i = 0; i < alldeliveryWalletNoFilter.length; i++) {
                                            if (record == alldeliveryWalletNoFilter[i].Wallet_Id) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }).length > 0 && <Button disabled={selectedDeliveryWalletIds.filter(record => {
                                        for (let i = 0; i < alldeliveryWalletNoFilter.length; i++) {
                                            if (record == alldeliveryWalletNoFilter[i].Wallet_Id) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }).length <= 0} onClick={deleteSelectedDeliWalletHandler()}>

                                            <DeleteSweepIcon
                                                style={{ color: '#ff7a7a', fontSize: 25 }}
                                            />
                                        </Button>}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(alldeliveryWallet) &&
                                (alldeliveryWallet).map(delwallet => (
                                    <TableRow
                                        hover
                                        key={delwallet.Wallet_Id}
                                        selected={
                                            selectedDeliveryWalletIds.indexOf(delwallet.Wallet_Id) !== -1
                                        }
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={
                                                    selectedDeliveryWalletIds.indexOf(delwallet.Wallet_Id) !== -1
                                                }
                                                onChange={event =>
                                                    handleSelectOne(event, delwallet.Wallet_Id)
                                                }
                                                value="true"
                                            />
                                        </TableCell>
                                        <TableCell>{delwallet.Wallet_Id}</TableCell>
                                        <TableCell style={{ color: '#1665D8' }} >
                                            {delwallet.Wallet_Owner?.substring(0, 30)}...
                                        </TableCell>
                                        <TableCell>{delwallet.Total_Amount}</TableCell>
                                        <TableCell>{delwallet.Status}</TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Delivery_Wallet_Write?.Value === "Delivery_Wallet_Write" && RolePermissions?.Delivery_Wallet_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="Wallet History">
                                                    <Button
                                                        onClick={visibleDeliveryWalletHandler(delwallet)}
                                                        color="primary"
                                                    >
                                                        <WalletIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            : null}
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Delivery_Wallet_Write?.Value === "Delivery_Wallet_Write" && RolePermissions?.Delivery_Wallet_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Button
                                                    onClick={viewDeliveryWalletHandler(delwallet)}
                                                    color="primary"
                                                >
                                                    <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                                <Button
                                                    onClick={editDeliveryWalletHandler(delwallet)}
                                                >
                                                    <CreateIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                            </TableCell>
                                            : null}
                                        {RolePermissions?.Delivery_Wallet_Read?.Value === "Delivery_Wallet_Read" && RolePermissions?.Delivery_Wallet_Read?.Is_Read === 1 &&
                                            <TableCell>
                                                <Button
                                                    onClick={viewDeliveryWalletHandler(delwallet)}
                                                    color="primary"
                                                >
                                                    <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(alldeliveryWallet) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt=""/>
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <Pagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
            <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    alldeliveryWallet: PropTypes.array.isRequired
};

export default Results;