import React from 'react';
import {
    Box,
    InputAdornment,
    SvgIcon,
    TextField,
    makeStyles,
    Button
} from '@material-ui/core';
import clsx from 'clsx';
import { Search as SearchIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    formFilterControl: {
        margin: theme.spacing(1),
        minWidth: 200
    }
}));

const Toolbar = ({ className, filters, setfilters, RolePermissions, roleUserType, ...rest }) => {
    const classes = useStyles();

    const handleChange = event => {
        setfilters({
            search: filters?.search,
            search_by_filter: event.target.value
        })
    };

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                alignItems="center"
                mb={2}
            >
                <Box display="flex" flexWrap="wrap" mb={2}>
                    <FormControl variant="outlined" className={classes.formFilterControl}>
                        <Select
                            value={filters.search_by_filter}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="Partner_Bidding_Use_Case_Id">Partner Bidding Use Case ID</MenuItem>
                            <MenuItem value="Partner_Name">Partner Name</MenuItem>
                            <MenuItem value="Meepaisa_Rating_For_Partner">Meepaisa Rating For Partner</MenuItem>
                            <MenuItem value="Meepaisa_Review_For_Partner">Meepaisa Review For Partner</MenuItem>
                        </Select>
                    </FormControl>
                    <Box width={400}>
                        <TextField
                            onChange={(event) => {
                                setfilters({
                                    search: event.target.value,
                                    search_by_filter: filters.search_by_filter
                                })
                            }}
                            className={classes.formControl}
                            value={filters?.search}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon fontSize="small" color="action">
                                            <SearchIcon />
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}
                            placeholder="Search Bidding Products Success Stories"
                            variant="outlined"
                        />
                    </Box>
                </Box>
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
          (RolePermissions?.Products_Bidding_Success_Story_Write?.Value === "Products_Bidding_Success_Story_Write" && RolePermissions?.Products_Bidding_Success_Story_Write?.Is_Write === 1) ?
                <Button
                    component={RouterLink}
                    to="/app/add-products-bidding-success-story"
                    color="primary"
                    variant="contained"
                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}>
                    <span style={{ color: "white" }}>
                        + Add Bid Product Success Story
                    </span>
                </Button>
              : null} 
            </Box>
        </div>
    );
};

export default Toolbar;