import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createWallethistoryAction,
} from './../../store/actions/wallethistoryAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const AddWalletHistory = ({ className, state, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const errorMessage = useSelector(state => state.wallethistory.wallethistoryCreate.error);
  const isSubmited = useSelector(state => state.wallethistory.wallethistoryCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        Amount: '',
        Reason: '',
        Wallet_Id: state?.Wallet_Id,
        Created_By: loginEmail
      }}

      validationSchema={
        Yup.object().shape({
          Amount: Yup.number().min(1, "Amount must be at least 1.").required('Amount is required.'),
          Reason: Yup.string().required('Reason is required.'),
        })
      }
      onSubmit={(values) => {
        let formValues = JSON.parse(JSON.stringify(values));
        dispatch(createWallethistoryAction(formValues, navigate, state));
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    {/* User Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Amount && errors.Amount)}
                        fullWidth
                        helperText={touched.Amount && errors.Amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Amount"
                        name="Amount"
                        value={values.Amount}
                        variant="outlined"
                        type="number"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Reason && errors.Reason)}
                        fullWidth
                        multiline
                        helperText={touched.Reason && errors.Reason}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Reason"
                        name="Reason"
                        value={values.Reason}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" p={2}>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>
            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddWalletHistory.propTypes = {
  className: PropTypes.string
};

export default AddWalletHistory;