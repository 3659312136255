import React from 'react';

const Logo = props => {
  return (
  <div>
    <img style={{padding:5}} alt="Logo" height="60" src="/static/newlogo.png"{...props} />
    <img style={{paddingBottom:10}} alt="Logo" height="50" src="/static/newlogotext.png"{...props} />
  </div>
  );
};

export default Logo;