
import { toast } from 'react-toastify';
import config from './config';
import { boomiAdminApi } from './commonAxios';

export const RAZORPAY_TRANSACTIONS_REQUEST = "RAZORPAY_TRANSACTIONS_REQUEST";
export const RAZORPAY_TRANSACTIONS_SUCCESS = "RAZORPAY_TRANSACTIONS_SUCCESS";
export const RAZORPAY_TRANSACTIONS_FAIL = "RAZORPAY_TRANSACTIONS_FAIL";
export const RAZORPAY_TRANSACTIONS_GET_REQUEST = "RAZORPAY_TRANSACTIONS_GET_REQUEST";
export const RAZORPAY_TRANSACTIONS_GET_SUCCESS = "RAZORPAY_TRANSACTIONS_GET_SUCCESS";
export const RAZORPAY_TRANSACTIONS_GET_FAIL = "RAZORPAY_TRANSACTIONS_GET_FAIL";
export const RAZORPAY_TRANSACTIONS_CREATE_REQUEST = "RAZORPAY_TRANSACTIONS_CREATE_REQUEST";
export const RAZORPAY_TRANSACTIONS_CREATE_SUCCESS = "RAZORPAY_TRANSACTIONS_CREATE_SUCCESS";
export const RAZORPAY_TRANSACTIONS_CREATE_FAIL = "RAZORPAY_TRANSACTIONS_CREATE_FAIL";
export const RAZORPAY_TRANSACTIONS_FILTER = "RAZORPAY_TRANSACTIONS_FILTER";


/*=============================================================
                  Add Razorpay Transaction Action
===============================================================*/
export const createRazorpayTransactionAction = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: RAZORPAY_TRANSACTIONS_CREATE_REQUEST
    });
    boomiAdminApi.post(`/Razorpay/Razorpay_Transaction_Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: RAZORPAY_TRANSACTIONS_CREATE_SUCCESS
                });
                toast('Razorpay Transactions added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });

                navigate('/app/razorpay-history', state = { state });
            } else {
                dispatch({
                    type: RAZORPAY_TRANSACTIONS_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: RAZORPAY_TRANSACTIONS_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

/*=============================================================
                  Get All Razorpay Transaction Action
===============================================================*/
export const getAllRazorpayTransactionAction = formData => async dispatch => {
    try {
        dispatch({
            type: RAZORPAY_TRANSACTIONS_REQUEST
        });
        let { data } = await boomiAdminApi.post(
            `/Razorpay/Transactions_Get?&SortBy=Id&SortOrder=ASC`,
            formData
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: RAZORPAY_TRANSACTIONS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: RAZORPAY_TRANSACTIONS_SUCCESS,
                    payload: data.Transactions
                });
            }
        }
    } catch (err) {
        dispatch({
            type: RAZORPAY_TRANSACTIONS_FAIL,
            payload: err
        });
    }
};

/*=============================================================
                Razorpay Transaction Filter  Action
===============================================================*/
export const RazorpayTransactionFilter = (fType, value) => {
    return function (dispatch) {
        dispatch({
            type: RAZORPAY_TRANSACTIONS_FILTER,
            fType: fType,
            value: value,
        });
    }
}