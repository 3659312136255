import {
  PRODUCTCOLORVARIANT_CREATE_REQUEST,
  PRODUCTCOLORVARIANT_CREATE_SUCCESS,
  PRODUCTCOLORVARIANT_CREATE_FAIL,
  PRODUCTCOLORVARIANT_UPDATE_REQUEST,
  PRODUCTCOLORVARIANT_UPDATE_SUCCESS,
  PRODUCTCOLORVARIANT_UPDATE_FAIL,
} from './../actions/productcolorvariantAction'

const initialState = {
  productcolorvariantCreate: { productcolorvariant: {}, error: '', isLoading: false },
  productcolorvariantUpdate: { productcolorvariant: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PRODUCTCOLORVARIANT_CREATE_REQUEST:
      return { ...state, productcolorvariantCreate: { productcolorvariant: {}, error: '', isLoading: true } };
    case PRODUCTCOLORVARIANT_CREATE_SUCCESS:
      return { ...state, productcolorvariantCreate: { productcolorvariant: state.productcolorvariantCreate.productcolorvariant, error: '', isLoading: false } };
    case PRODUCTCOLORVARIANT_CREATE_FAIL:
      return { ...state, productcolorvariantCreate: { productcolorvariant: {}, error: action.payload, isLoading: false } };
    case PRODUCTCOLORVARIANT_UPDATE_REQUEST:
      return { ...state, productcolorvariantUpdate: { productcolorvariant: {}, error: '', isLoading: true } };
    case PRODUCTCOLORVARIANT_UPDATE_SUCCESS:
      return { ...state, productcolorvariantUpdate: { productcolorvariant: state.productcolorvariantUpdate.productcolorvariant, error: '', isLoading: false } };
    case PRODUCTCOLORVARIANT_UPDATE_FAIL:
      return { ...state, productcolorvariantUpdate: { productcolorvariant: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}