import config from './config';
import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const BIDDING_SERVICE_SUCCESS_STORIES_CREATE_REQUEST = "BIDDING_SERVICE_SUCCESS_STORIES_CREATE_REQUEST";
export const BIDDING_SERVICE_SUCCESS_STORIES_CREATE_SUCCESS = "BIDDING_SERVICE_SUCCESS_STORIES_CREATE_SUCCESS";
export const BIDDING_SERVICE_SUCCESS_STORIES_CREATE_FAIL = "BIDDING_SERVICE_SUCCESS_STORIES_CREATE_FAIL";
export const BIDDING_SERVICE_SUCCESS_STORIES_UPDATE_REQUEST = "BIDDING_SERVICE_SUCCESS_STORIES_UPDATE_REQUEST";
export const BIDDING_SERVICE_SUCCESS_STORIES_UPDATE_SUCCESS = "BIDDING_SERVICE_SUCCESS_STORIES_UPDATE_SUCCESS";
export const BIDDING_SERVICE_SUCCESS_STORIES_UPDATE_FAIL = "BIDDING_SERVICE_SUCCESS_STORIES_UPDATE_FAIL";

export const PHYSICAL_PARTNERS_REQUEST = "PHYSICAL_PARTNERS_REQUEST";
export const PHYSICAL_PARTNERS_SUCCESS = "PHYSICAL_PARTNERS_SUCCESS";
export const PHYSICAL_PARTNERS_FAIL = "PHYSICAL_PARTNERS_FAIL";

export const BIDDING_SERVICES_REQUEST = "BIDDING_SERVICES_REQUEST";
export const BIDDING_SERVICES_SUCCESS = "BIDDING_SERVICES_SUCCESS";
export const BIDDING_SERVICES_FAIL = "BIDDING_SERVICES_FAIL";

//  Add Bidding Service Success Stories Action
export const AddBiddingServiceSucessStoriesAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: BIDDING_SERVICE_SUCCESS_STORIES_CREATE_REQUEST
  });
  nodeCudApi.post(`/Success_Stories/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BIDDING_SERVICE_SUCCESS_STORIES_CREATE_SUCCESS
        });
        toast('Bid Success Story added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/bidding-services-success-stories');
      } else {
        dispatch({
          type: BIDDING_SERVICE_SUCCESS_STORIES_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: BIDDING_SERVICE_SUCCESS_STORIES_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};


// Update Bidding Service Success Stories Action
export const UpdateBiddingServiceSuccessStoriesAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: BIDDING_SERVICE_SUCCESS_STORIES_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Success_Stories/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BIDDING_SERVICE_SUCCESS_STORIES_UPDATE_SUCCESS
        });
        toast('Bid Success Story updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/bidding-services-success-stories');
      } else {

        dispatch({
          type: BIDDING_SERVICE_SUCCESS_STORIES_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: BIDDING_SERVICE_SUCCESS_STORIES_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

// get Physical partners
export const getAllPartnerDetails = () => async dispatch => {
  try {
    let formData = {
      "ExtraCashBack": "",
      "Name": "",
      "Partner_Details_Id": "",
      "Records_Filter": "FILTER",
      "Status": "",
      "Top_Stores": "",
      "Buisness_Type": "SERVICES"
    };
    dispatch({
      type: PHYSICAL_PARTNERS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Partners/Physical_Partner_Profile_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.successResponse?.Is_Data_Exist === '0') {
        dispatch({
          type: PHYSICAL_PARTNERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PHYSICAL_PARTNERS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PHYSICAL_PARTNERS_FAIL,
      payload: err
    });
  }
};

//get All bidding services
export const getAllBiddingServices = () => async dispatch => {
  try {
    let formData = {
      "search_by_filter": "",
      "search": "",
      "Bidding_Service_Id": "",
      "Service_Type": "",
      "Records_Filter": ""
    };
    dispatch({
      type: BIDDING_SERVICES_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin/Bidding_Services?PageNo=1&PageSize=${config.pageSize}&SortBy=BIDDING_SERVICE_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BIDDING_SERVICES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BIDDING_SERVICES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: BIDDING_SERVICES_FAIL,
      payload: err
    });
  }
};