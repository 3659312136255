import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import DeliveryBiddingQuotesView from './viewDeliveryBiddingQuotes';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEmpty from 'src/store/validations/is-empty';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const ViewDeliveryBiddingQuotes = () => {
    const classes = useStyles();
    const location = useLocation();
    const { state } = location;

    const [alldeliverybidquotes, setPartnerdeliagents] = useState([]);

    let deliverybidquotes = useSelector(state => state.deliverybiddingquotes.alldeliverybidquotes);
    useEffect(() => {
        if (!isEmpty(deliverybidquotes)) {
            setPartnerdeliagents(deliverybidquotes.deliverybiddingquotes);
        }
    }, [deliverybidquotes]);

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/delivery-bidding-quotes"
                    variant="text"
                    size="small"
                    color="inherit"
                    state={state?.state}
                >
                    <KeyboardBackspaceIcon />
                    Go Back
                </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        View Delivery Bidding Quote
                    </Typography>
                </Box>
                <DeliveryBiddingQuotesView state={state?.state} data={state?.data} alldeliverybidquotes={alldeliverybidquotes} />
            </Container>
        </Page>
    );
};

export default ViewDeliveryBiddingQuotes;