import { Card, CardContent, Grid, Divider } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

const ProductsList = ({ className, ...rest }) => {
    const location = useLocation();
    let { state } = location;

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            {/* Product_Id */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Product ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Product_Id}
                                </Typography>
                            </Grid>

                            {/* Brand_Id */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Brand ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Brand_Id}
                                </Typography>
                            </Grid>

                            {/* Category_Id */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Category ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Category_Id}
                                </Typography>
                            </Grid>

                            {/* ESI Rating */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    ESI Rating
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Esi_Rating}
                                </Typography>
                            </Grid>

                            {/* Price */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Price
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Price}
                                </Typography>
                            </Grid>

                            {/* Sub_Category_Id */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Sub Category ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Sub_Category_Id}
                                </Typography>
                            </Grid>

                            {/* Status */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Status
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Status}
                                </Typography>
                            </Grid>

                            {/* Is_Upcoming */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Upcoming
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Is_Upcoming}
                                </Typography>
                            </Grid>

                            {/* Whats_Hot */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    What's Hot
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Whats_Hot}
                                </Typography>
                            </Grid>

                            {/* Is_Top_Product */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Top Product
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Is_Top_Product}
                                </Typography>
                            </Grid>

                            {/* Today_Recommendations */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Today Recommendations
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Today_Recommendations}
                                </Typography>
                            </Grid>

                            {/* Is_MFH_Combo */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is MFH Combo
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Is_Mfh_Combo}
                                </Typography>
                            </Grid>

                            {/* Created_By_Email_Id */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created By Email
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state?.Created_By_Email}> {state?.Created_By_Email?.length > 25 ?
                                        <span>{state?.Created_By_Email?.substring(0, 25)}...</span> : state?.Created_By_Email}</span>
                                </Typography>
                            </Grid>

                            {/* Created_Date */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Created_Date}
                                </Typography>
                            </Grid>

                            <Grid item xs={2} />
                            <Grid item xs={2} />
                            <Grid item xs={7} />
                        </Grid>
                    </Grid>

                    {/*  column 2 */}
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            {/* Product Name */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Product Name
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Product_Name}
                                </Typography>
                            </Grid>

                            {/* Product_Color */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Product Color
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Product_Color}
                                </Typography>
                            </Grid>

                            {/* Product_Size_Id */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Product Size ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Product_Size_Id}
                                </Typography>
                            </Grid>

                            {/* Product_Master_Id */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Product Master ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Product_Master_Id}
                                </Typography>
                            </Grid>

                            {/* Product_Color_Variant_Id */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Product Color Variant ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Product_Color_Variant_Id}
                                </Typography>
                            </Grid>

                            {/* Country_Of_Origin */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Country Of Origin
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Country_Of_Origin}
                                </Typography>
                            </Grid>

                            {/* Product_For */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Product For
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Product_For}
                                </Typography>
                            </Grid>

                            {/* Is_Popular */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Popular
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Is_Popular}
                                </Typography>
                            </Grid>

                            {/* Trending_Today */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Trending Today
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Trending_Today}
                                </Typography>
                            </Grid>

                            {/* Is_Approved */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Approved
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Is_Approved}
                                </Typography>
                            </Grid>

                            {/* Modified_Date */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Modified_Date}
                                </Typography>
                            </Grid>

                            {/* Modified_By */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state?.Modified_By}> {state?.Modified_By?.length > 25 ?
                                        <span>{state?.Modified_By?.substring(0, 25)}...</span> : state?.Modified_By}</span>
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Divider />
                {/* Warranty_Period */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A', paddingBottom: '1rem' }}
                    >
                        Warranty Period
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                    >
                        <span dangerouslySetInnerHTML={{ __html: state?.Warranty_Period }}></span>
                    </Typography>
                </Grid>
                <Divider />
                {/* Product_Short_Description */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A', paddingBottom: '1rem' }}
                    >
                        Product Short Description
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                    >
                        <span dangerouslySetInnerHTML={{ __html: state?.Product_Short_Description }}></span>
                    </Typography>
                </Grid>
                <Divider />
                {/* Product Description */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A', paddingBottom: '1rem' }}
                    >
                        Product Description
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ paddingLeft: '1rem' }}
                    >
                        <span dangerouslySetInnerHTML={{ __html: state?.Product_Description }}></span>
                    </Typography>
                </Grid>
                <Divider />
                {/* Features */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A', paddingBottom: '1rem' }}
                    >
                        Features
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ paddingLeft: '1rem' }}
                    >
                        <span dangerouslySetInnerHTML={{ __html: state?.Features }}></span>
                    </Typography>
                </Grid>
                <Divider />
                {/* Specifications */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A', paddingBottom: '1rem' }}
                    >
                        Specifications
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ paddingLeft: '1rem' }}
                    >
                        <span dangerouslySetInnerHTML={{ __html: state?.Specifications }}></span>
                    </Typography>
                </Grid>
                <Divider />
                {/* Esi_Reviews */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A', paddingBottom: '1rem' }}
                    >
                        ESI Reviews
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ paddingLeft: '1rem' }}
                    >
                        <span dangerouslySetInnerHTML={{ __html: state?.Esi_Reviews }}></span>
                    </Typography>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ProductsList;