import * as dash from '../../actions/reports/dashboardActions';
import * as statis from '../../actions/reports/statisticsActions';

const initialState = {
    Order: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    Profit: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    Cashback: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    Users: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    Partners: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    OnlinePartners: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    PhysicalPartners: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    Brands: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    Coupons: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    Deals: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    Products: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },

    OrderS: { data: [], error: "" },
    ProfitS: { data: [], error: "" },
    CashbackS: { data: [], error: "" },
    UsersS: { data: [], error: "" },
    PartnersS: { data: [], error: "" },
    BrandsS: { data: [], error: "" },
    CouponsS: { data: [], error: "" },
    DealsS: { data: [], error: "" },
    ProductsS: { data: [], error: "" },
    TCoupons: { data: [], error: "" },
    TDeals: { data: [], error: "" },
    TProducts: { data: [], error: "" },
}

const dashboardReducers = (state = initialState, action) => {
    switch (action.type) {
        case dash.DASH_BOARD_TOTAL_ORDER:
            return { ...state, Order: { data: action.succress ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.succress ? "" : action.data } }
        case dash.DASH_BOARD_TOTAL_PROFIT:
            return { ...state, Profit: { data: action.succress ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.succress ? "" : action.data } }
        case dash.DASH_BOARD_TOTAL_CASHBACK:
            return { ...state, Cashback: { data: action.succress ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.succress ? "" : action.data } }
        case dash.DASH_BOARD_TOTAL_USERS:
            return { ...state, Users: { data: action.succress ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.succress ? "" : action.data } };
        case dash.DASH_BOARD_TOTAL_PARTNERS:
            return { ...state, Partners: { data: action.succress ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.succress ? "" : action.data } }
        case dash.DASH_BOARD_ONLINE_PARTNERS:
            return { ...state, OnlinePartners: { data: action.succress ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.succress ? "" : action.data } }
        case dash.DASH_BOARD_PHYSICAL_PARTNERS:
            return { ...state, PhysicalPartners: { data: action.succress ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.succress ? "" : action.data } }
        case dash.DASH_BOARD_TOTAL_BRANDS:
            return { ...state, Brands: { data: action.succress ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.succress ? "" : action.data } }
        case dash.DASH_BOARD_TOTAL_COUPONS:
            return { ...state, Coupons: { data: action.succress ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.succress ? "" : action.data } }
        case dash.DASH_BOARD_TOTAL_DEALS:
            return { ...state, Deals: { data: action.succress ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.succress ? "" : action.data } };
        case dash.DASH_BOARD_TOTAL_PRODUCTS:
            return { ...state, Products: { data: action.succress ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.succress ? "" : action.data } };

        case statis.STATISTICS_ORDER:
            return { ...state, OrderS: { data: action.succress ? action.data : [], error: action.succress ? "" : action.data } }
        case statis.STATISTICS_PROFIT:
            return { ...state, ProfitS: { data: action.succress ? action.data : [], error: action.succress ? "" : action.data } }
        case statis.STATISTICS_CASHBACK:
            return { ...state, CashbackS: { data: action.succress ? action.data : [], error: action.succress ? "" : action.data } }
        case statis.STATISTICS_USERS:
            return { ...state, UsersS: { data: action.succress ? action.data : [], error: action.succress ? "" : action.data } };
        case statis.STATISTICS_PARTNERS:
            return { ...state, PartnersS: { data: action.succress ? action.data : [], error: action.succress ? "" : action.data } }
        case statis.STATISTICS_BRANDS:
            return { ...state, BrandsS: { data: action.succress ? action.data : [], error: action.succress ? "" : action.data } }
        case statis.STATISTICS_COUPONS:
            return { ...state, CouponsS: { data: action.succress ? action.data : [], error: action.succress ? "" : action.data } }
        case statis.STATISTICS_DEALS:
            return { ...state, DealsS: { data: action.succress ? action.data : [], error: action.succress ? "" : action.data } };
        case statis.STATISTICS_PRODUCTS:
            return { ...state, ProductsS: { data: action.succress ? action.data : [], error: action.succress ? "" : action.data } };

        case dash.DASH_BOARD_TOP_COUPONS:
            return { ...state, TCoupons: { data: action.succress ? action.data : [], error: action.succress ? "" : action.data } }
        case dash.DASH_BOARD_TOP_DEALS:
            return { ...state, TDeals: { data: action.succress ? action.data : [], error: action.succress ? "" : action.data } };
        case dash.DASH_BOARD_TOP_PRODUCTS:
            return { ...state, TProducts: { data: action.succress ? action.data : [], error: action.succress ? "" : action.data } };


        default:
            return state;
    }
}
export default dashboardReducers;