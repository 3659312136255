import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
    TableSortLabel,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';
import isEmpty from 'src/store/validations/is-empty';
import PaymentIcon from '@mui/icons-material/Payment';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    buttonRoot: {
        minWidth: '7px',
        padding: '6px'
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    },
}));

const headCells = [
    { id: 'User_Ride_Id', numeric: false, disablePadding: false, label: 'User Ride ID' },
    { id: 'Driver_email_Id', numeric: false, disablePadding: false, label: 'Driver Email ID' },
    { id: 'User_Email_Id', numeric: false, disablePadding: false, label: 'User Email ID' },
    { id: 'Ride_Status', numeric: true, disablePadding: false, label: 'Ride Status' },
    { id: 'Payment_Status', numeric: true, disablePadding: false, label: 'Payment Status' },
];

const Results = ({
    className,
    allcompUserRides,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    pagination,
    setPagination,
    createSortHandler,
    allcompUserRidesNoFilter,
    ...rest
}) => {
    const navigate = useNavigate();
    const classes = useStyles();

    const editUserRideHandler = userrideData => e => {
        navigate('/app/edit-user-rides', { state: userrideData });
    };

    const visibleUserRideHandler = userrideData => e => {
        navigate("/app/user-rides-view", { state: userrideData });
    };

    const rideUserPayHandler = userrideData => e => {
        navigate("/app/userpayment-user-rides", { state: userrideData });
    };

    const rideDeliPayHandler = userrideData => e => {
        navigate("/app/deliverypayment-user-rides", { state: userrideData });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell>Payments</TableCell>
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-30px' }} >Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allcompUserRides) &&
                                (allcompUserRides).map((compuserride, i) => (
                                    <TableRow
                                        hover
                                        key={i}
                                    >
                                        <TableCell width="15%">{compuserride.User_Ride_Id}</TableCell>
                                        <TableCell>{compuserride.Driver_email_Id?.substring(0, 20)}...</TableCell>
                                        <TableCell>{compuserride.User_Email_Id?.substring(0, 20)}...</TableCell>
                                        <TableCell width="15%">{compuserride.Ride_Status}</TableCell>
                                        <TableCell width="15%">{compuserride.Payment_Status}</TableCell>
                                        <TableCell >
                                            <Tooltip title="User Payment">
                                                <Button
                                                    onClick={rideUserPayHandler(compuserride)}
                                                    color="primary"
                                                >
                                                    <PaymentIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Delivery Payment">
                                                <Button
                                                    onClick={rideDeliPayHandler(compuserride)}
                                                    color="primary"
                                                >
                                                    <PaymentIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="View UserRide History">
                                                <Button
                                                    onClick={visibleUserRideHandler(compuserride)}
                                                    color="primary"
                                                >
                                                    <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                            </Tooltip>
                                            <Button
                                                onClick={editUserRideHandler(compuserride)}
                                            >
                                                <CreateIcon style={{ color: '#9EA0A5' }} />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allcompUserRides) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <Pagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    allcompUserRides: PropTypes.array.isRequired
};

export default Results;