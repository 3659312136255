import { Card, CardContent, Divider, Grid } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const PartneraddressList = ({ className, state, data, ...rest }) => {

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            {/* Partner_Address_Id */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Partner Address ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Partner_Address_Id ? data?.Partner_Address_Id : state?.Partner_Address_Id}

                                </Typography>
                            </Grid>

                            {/* Address_Type */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Address Type
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Address_Type ? data?.Address_Type : state?.Address_Type}
                                </Typography>
                            </Grid>

                            {/* Mobile */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Mobile
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Mobile ? data?.Mobile : state?.Mobile}

                                </Typography>
                            </Grid>

                            {/* Landline */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Landline
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Landline ? data?.Landline : state?.Landline}
                                </Typography>
                            </Grid>

                            {/* Location */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Location
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Location ? data?.Location : state?.Location}
                                </Typography>
                            </Grid>

                            {/* Latitude */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Latitude
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Latitude ? data?.Latitude : state?.Latitude}
                                </Typography>
                            </Grid>

                            {/* Door_No */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Door No
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Door_No ? data?.Door_No : state?.Door_No}
                                </Typography>
                            </Grid>

                            {/* Is_Default */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Address Default
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Is_Default ? data?.Is_Default : state?.Is_Default === "1" ? ("Yes") : ("No")}
                                </Typography>
                            </Grid>

                            {/* Is_Active */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Address Active
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Is_Active ? data?.Is_Active : state?.Is_Active === "1" ? ("Yes") : ("No")}
                                </Typography>
                            </Grid>

                            {/* Created_By */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={data?.Created_By ? data?.Created_By : state?.Created_By}> {data?.Created_By ? data?.Created_By : state?.Created_By?.length > 25 ?
                                        <span>{data?.Created_By ? data?.Created_By : state?.Created_By?.substring(0, 25)}...</span> : data?.Created_By ? data?.Created_By : state?.Created_By}</span>
                                </Typography>
                            </Grid>

                            {/* Modified_By */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={data?.Modified_By ? data?.Modified_By : state?.Modified_By}> {data?.Modified_By ? data?.Modified_By : state?.Modified_By?.length > 25 ?
                                        <span>{data?.Modified_By ? data?.Modified_By : state?.Modified_By?.substring(0, 25)}...</span> : data?.Modified_By ? data?.Modified_By : state?.Modified_By}</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={2} />
                            <Grid item xs={2} />
                            <Grid item xs={7} />
                        </Grid>
                    </Grid>

                    {/*  column 2 */}
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            {/* Land_Mark */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Land Mark
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Land_Mark ? data?.Land_Mark : state?.Land_Mark}
                                </Typography>
                            </Grid>

                            {/*Street */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Street
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Street ? data?.Street : state?.Street}
                                </Typography>
                            </Grid>

                            {/* City */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    City
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.City ? data?.City : state?.City}
                                </Typography>
                            </Grid>

                            {/* State  */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    State
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.State ? data?.State : state?.State}
                                </Typography>
                            </Grid>

                            {/* Country */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Country
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Country ? data?.Country : state?.Country}
                                </Typography>
                            </Grid>

                            {/* Zip */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Zip
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Zip ? data?.Zip : state?.Zip}
                                </Typography>
                            </Grid>

                            {/* Longitude */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Longitude
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Longitude ? data?.Longitude : state?.Longitude}
                                </Typography>
                            </Grid>

                            {/* Is_Pickup_Available */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Pickup Available
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Is_Pickup_Available ? data?.Is_Pickup_Available : state?.Is_Pickup_Available === "1" ? ("Yes") : ("No")}
                                </Typography>
                            </Grid>

                            {/* Is_Shipping_Available */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Shipping Available
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Is_Shipping_Available ? data?.Is_Shipping_Available : state?.Is_Shipping_Available === "1" ? ("Yes") : ("No")}
                                </Typography>
                            </Grid>

                            {/* Created_Date */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Created_Date ? data?.Created_Date : state?.Created_Date}
                                </Typography>
                            </Grid>

                            {/* Modified_Date */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Modified_Date ? data?.Modified_Date : state?.Modified_Date}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Divider />


                {/* Google_Map_Location */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A' }}
                    >
                        Google Map Location URL
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ paddingLeft: '1rem' }}
                    >
                        <a target="_blank" href={data?.Google_Map_Location ? data?.Google_Map_Location : state?.Google_Map_Location}>
                            <span title={data?.Google_Map_Location ? data?.Google_Map_Location : state?.Google_Map_Location}> {data?.Google_Map_Location ? data?.Google_Map_Location : state?.Google_Map_Location?.length > 20 ?
                                <span>{data?.Google_Map_Location ? data?.Google_Map_Location : state?.Google_Map_Location?.substring(0, 20)}...</span> : data?.Google_Map_Location ? data?.Google_Map_Location : state?.Google_Map_Location}</span>
                        </a>
                    </Typography>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default PartneraddressList;