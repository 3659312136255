import { nodeGetApi } from '../commonAxios';

export const DEL_STATISTICS_TOTAL_ORDERS = "DEL_STATISTICS_TOTAL_ORDERS";

export const deliveryorderStatistics = (formData) => {
    return function (dispatch) {
        nodeGetApi.post(`/Reports/Order_Statistics_Get`, formData)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Response_Status === "Success") {
                    dispatch({
                        type: DEL_STATISTICS_TOTAL_ORDERS,
                        data: data.results,
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DEL_STATISTICS_TOTAL_ORDERS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DEL_STATISTICS_TOTAL_ORDERS,
                    data: String(error),
                    success: false
                });
            })
    }
}