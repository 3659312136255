import { Button, Card, CardContent, Grid, makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { getcorpdoorsteporderdetails, getcorpsingleorderdetails, updateCorpOrderStatusAction } from 'src/store/actions/corporderAction';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    images: {
        width: 100,
        height: 100
    },
    root: {
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    card: {
        marginBottom: theme.spacing(3),
    },
    media: {
        height: 124,
        width: 110,
        objectFit: 'contain',
        borderRadius: 4
    },
    media1: {
        height: 52,
        width: 52,
        objectFit: 'contain',
        borderRadius: 4,
        marginRight: 5
    },
    productInfo: {
        paddingLeft: theme.spacing(2),
    },
    addressCard: {
        backgroundColor: theme.palette.background.default,
    },
    addressTitle: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    selectControl: {
        marginTop: theme.spacing(2),
    },
    actionButton: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(2),
        textDecoration: 'underline',
    },
    productInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const statusOption = [
    {
        value: 'Order confirmed',
        label: 'Order confirmed'
    },
    {
        value: 'Shipped',
        label: 'Shipped'
    },
    {
        value: 'On process',
        label: 'On process'
    },
    {
        value: 'Out for delivery',
        label: 'Out for delivery'
    },
    {
        value: 'Delivered',
        label: 'Delivered'
    },
    {
        value: 'Canceled',
        label: 'Canceled'
    }
];

const doorstepStatusOption = [
    {
        value: 'Order confirmed',
        label: 'Order confirmed'
    },
    {
        value: 'Shipped',
        label: 'Shipped'
    },
    {
        value: 'On process',
        label: 'On process'
    },
    {
        value: 'Out for delivery',
        label: 'Out for delivery'
    },
    {
        value: 'Delivered',
        label: 'Delivered'
    },
    {
        value: 'Returned to origin',
        label: 'Returned to origin'
    }
];

const CorpOrderInfo = () => {
    const classes = useStyles();
    const location = useLocation();
    let { state } = location;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const pagetype = "main"
    const [corpOrder, setCorpOrder] = React.useState([]);
    const [filters, setFilters] = React.useState({ Search: '', Search_By_Filter: 'All' });
    const [pagination, setPagination] = React.useState({ page: 1, limit: 10, sortBy: 'createdAt:desc' })
    const Login_Details = useSelector(state => state.auth.user);
    const orderdetails = useSelector(state => state.corporder.corpsingleorder.all);
    const doorsteporderdetails = useSelector(state => state.corporder.corpdoorsteporder.all);
    const [expandedProduct, setExpandedProduct] = useState(null);
    const finalResult = orderdetails && orderdetails?.length > 0 ? orderdetails : doorsteporderdetails
    const [finalData, setFinalData] = useState()
    const doorstepDelivery = orderdetails[0]?.Delivery_Type ? orderdetails[0]?.Delivery_Type.toLowerCase() === 'doorstep delivery' : state?.state?.Delivery_Type?.toLowerCase() === 'doorstep delivery';

    useEffect(() => {
        if (finalResult[0] && finalResult[0]?.Ordered_Products.length > 0) {
            setFinalData(finalResult[0])
        }
    }, [finalResult])

    const handleToggle = (productId) => {
        setExpandedProduct(expandedProduct === productId ? null : productId);
    };

    useEffect(() => {
        if (state?._id) {
            let formData = {
                Search: '',
                Search_By_Filter: 'All',
                Order_Id: state?._id
            }
            dispatch(getcorpsingleorderdetails(formData));
        } else if (state?.state?.Order_Id) {
            let formData = {
                Order_Id: state?.state?.Order_Id,
                Employee_Order_Id: state?.data?._id
            }
            dispatch(getcorpdoorsteporderdetails(formData));
        }
    }, []);

    const callBackPagination = async (status, data, pagination) => {
        if (status) {
            setCorpOrder(data);
        }
        if (pagination) {
            pagination.sortBy = 'createdAt:desc'
            setPagination(pagination);
        } else {
            setPagination({ page: 1, limit: 10, sortBy: 'createdAt:desc', });
        }
    }

    const handleStatusChange = (e) => {
        const newStatus = e.target.value;
        const isDoorstepDelivery = orderdetails[0]?.Delivery_Type ? orderdetails[0]?.Delivery_Type.toLowerCase() === 'doorstep delivery' : state?.state?.Delivery_Type?.toLowerCase() === 'doorstep delivery';
        if (isDoorstepDelivery) {
            let formData = {
                "Order_Id": orderdetails[0]?._id ? orderdetails[0]?._id : state?.data?.Order_Id,
                "Delivery_Type": orderdetails[0]?.Delivery_Type ? orderdetails[0]?.Delivery_Type : state?.state?.Delivery_Type,
                "Employee_Order_Id": state?.data?._id ? state?.data?._id : "",
                "Order_Status": newStatus,
                "Modified_By": Login_Details?.Email_Id
            }
            dispatch(updateCorpOrderStatusAction(formData, filters, pagination, callBackPagination, pagetype))
        } else {
            let formData = {
                "Order_Id": orderdetails[0]?._id ? orderdetails[0]?._id : state?.data?.Order_Id,
                "Delivery_Type": orderdetails[0]?.Delivery_Type ? orderdetails[0]?.Delivery_Type : state?.state?.Delivery_Type,
                "Order_Status": newStatus,
                "Modified_By": Login_Details?.Email_Id
            }
            dispatch(updateCorpOrderStatusAction(formData, filters, pagination, callBackPagination, pagetype))
        }
        navigate('/app/corporate-order-management')
    };

    const getLogoOrder = (logos) => {
        const order = ['Is_Front', 'Is_Back', 'Is_Right', 'Is_Left', 'Is_Top', 'Is_Bottom'];
        return logos
            .filter(logo => order.some(key => logo[key]))
            .sort((a, b) => {
                return order.findIndex(key => a[key]) - order.findIndex(key => b[key]);
            });
    };

    return (
        <div className={classes.root}>
            {finalData?.Ordered_Products && finalData?.Ordered_Products?.map((product) => {
                const orderedLogos = getLogoOrder(product?.logos || []);
                return (
                    <Card className={classes.card} key={product.Ordered_Product_Id}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <img
                                        src={product?.Image_Path}
                                        alt="Product"
                                        className={classes.media}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <div className={classes.productInfo}>
                                        <Typography variant="h6">{product?.Brand_Name}</Typography>
                                        <Typography variant="body1">{product?.Product_Name}</Typography>
                                        <Typography variant="body2">{product?.Currency}{product?.Finalized_Discounted_Price}/unit</Typography>
                                        <Typography variant="body2">Total amount - {product?.Currency}{product?.Total_Amount}</Typography>

                                        {expandedProduct === product.Ordered_Product_Id && (
                                            <>
                                                <Typography variant="body2">Product id: {product?.Product_Id}</Typography>
                                                <Typography variant="body2">Quantity: {product?.Quantity}</Typography>
                                                <Typography variant="body2">Colour: {product?.Product_Color_Name}</Typography>
                                                <Typography variant="body2">Size: {product?.Size_Type}</Typography>
                                                <Typography variant="body2">
                                                    Customization Logo & Image:
                                                    {orderedLogos.some(logo => logo.Reference_Image) && (
                                                        <span>
                                                            {orderedLogos.some(logo => logo.Is_Front && logo.Reference_Image) && " Front"}
                                                            {orderedLogos.some(logo => logo.Is_Back && logo.Reference_Image) && ", Back"}
                                                            {orderedLogos.some(logo => logo.Is_Right && logo.Reference_Image) && ", Right"}
                                                            {orderedLogos.some(logo => logo.Is_Left && logo.Reference_Image) && ", Left"}
                                                            {orderedLogos.some(logo => logo.Is_Top && logo.Reference_Image) && ", Top"}
                                                            {orderedLogos.some(logo => logo.Is_Bottom && logo.Reference_Image) && ", Bottom"}
                                                        </span>
                                                    )}
                                                </Typography>

                                                {orderedLogos.filter(logo => logo.Reference_Image).length > 0 && (
                                                    <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                                        {orderedLogos
                                                            .filter(logo => logo.Reference_Image)
                                                            .map((logo, logoIndex) => (
                                                                <div key={logoIndex}>
                                                                    <img
                                                                        src={logo?.Logo_URL}
                                                                        alt={`Logo URL ${logoIndex + 1}`}
                                                                        className={classes.media1}
                                                                    />
                                                                    <img
                                                                        src={logo?.Reference_Image}
                                                                        alt={`Reference Image ${logoIndex + 1}`}
                                                                        className={classes.media1}
                                                                    />
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        <Grid item xs={2}>
                                            <Button
                                                color="primary"
                                                onClick={() => handleToggle(product.Ordered_Product_Id)}
                                                className={classes.button}
                                            >
                                                {expandedProduct === product.Ordered_Product_Id ? 'View less' : 'View more'}
                                            </Button>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                );
            })}

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Card className={classes.addressCard}>
                        <CardContent>
                            <Typography className={classes.addressTitle}>Billing address</Typography>
                            <Typography>{orderdetails[0]?.billingAddress[0]?.Full_Name ? orderdetails[0]?.billingAddress[0]?.Full_Name : doorsteporderdetails[0]?.billingAddress[0]?.Full_Name}</Typography>
                            <Typography>{orderdetails[0]?.billingAddress[0]?.Address ? orderdetails[0]?.billingAddress[0]?.Address : doorsteporderdetails[0]?.billingAddress[0]?.Address}</Typography>
                            <Typography>{orderdetails[0]?.billingAddress[0]?.City ? orderdetails[0]?.billingAddress[0]?.City : doorsteporderdetails[0]?.billingAddress[0]?.City}</Typography>
                            <Typography>{orderdetails[0]?.billingAddress[0]?.State ? orderdetails[0]?.billingAddress[0]?.State : doorsteporderdetails[0]?.billingAddress[0]?.State}</Typography>
                            <Typography>{orderdetails[0]?.billingAddress[0]?.Country ? orderdetails[0]?.billingAddress[0]?.Country : doorsteporderdetails[0]?.billingAddress[0]?.Country}</Typography>
                            <Typography>{orderdetails[0]?.billingAddress[0]?.Zip_Code ? orderdetails[0]?.billingAddress[0]?.Zip_Code : doorsteporderdetails[0]?.billingAddress[0]?.Zip_Code}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card className={classes.addressCard}>
                        <CardContent>
                            <Typography className={classes.addressTitle}>Ship address</Typography>
                            <Typography>{orderdetails[0]?.shippingaddress[0]?.Full_Name ? orderdetails[0]?.shippingaddress[0]?.Full_Name : doorsteporderdetails[0]?.shippingaddress[0]?.Full_Name}</Typography>
                            <Typography>{orderdetails[0]?.shippingaddress[0]?.Address ? orderdetails[0]?.shippingaddress[0]?.Address : doorsteporderdetails[0]?.shippingaddress[0]?.Address}</Typography>
                            <Typography>{orderdetails[0]?.shippingaddress[0]?.City ? orderdetails[0]?.shippingaddress[0]?.City : doorsteporderdetails[0]?.shippingaddress[0]?.City}</Typography>
                            <Typography>{orderdetails[0]?.shippingaddress[0]?.State ? orderdetails[0]?.shippingaddress[0]?.State : doorsteporderdetails[0]?.shippingaddress[0]?.State}</Typography>
                            <Typography>{orderdetails[0]?.shippingaddress[0]?.Country ? orderdetails[0]?.shippingaddress[0]?.Country : doorsteporderdetails[0]?.shippingaddress[0]?.Country}</Typography>
                            <Typography>{orderdetails[0]?.shippingaddress[0]?.Zip_Code ? orderdetails[0]?.shippingaddress[0]?.Zip_Code : doorsteporderdetails[0]?.shippingaddress[0]?.Zip_Code}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Card className={classes.addressCard}>
                        <CardContent>
                            <Grid container justifyContent="flex-end">
                                <Grid item md={4} xs={12}>
                                    {doorstepDelivery ?
                                        <TextField
                                            fullWidth
                                            value={orderdetails[0]?.Order_Status || doorsteporderdetails[0]?.Order_Status}
                                            onChange={(e) => handleStatusChange(e)}
                                            label="Delivery Status"
                                            select
                                            SelectProps={{ native: true }}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        >
                                            {doorstepStatusOption.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                        :
                                        <TextField
                                            fullWidth
                                            value={orderdetails[0]?.Order_Status || doorsteporderdetails[0]?.Order_Status}
                                            onChange={(e) => handleStatusChange(e)}
                                            label="Delivery Status"
                                            select
                                            SelectProps={{ native: true }}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        >
                                            {statusOption.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                    }
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default CorpOrderInfo;