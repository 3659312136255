
import config from './config';
import { toast } from 'react-toastify';
import { paginationDeliveryAgent } from './node-actions/nodepagination';
import { nodeGetApi, boomiAdminApi } from './commonAxios';

export const PARTNER_DELIVERYAGENT_REQUEST = "PARTNER_DELIVERYAGENT_REQUEST";
export const PARTNER_DELIVERYAGENT_SUCCESS = "PARTNER_DELIVERYAGENT_SUCCESS";
export const PARTNER_DELIVERYAGENT_FAIL = "PARTNER_DELIVERYAGENT_FAIL";

export const PARTNER_DELIVERYAGENT_GET_REQUEST = "PARTNER_DELIVERYAGENT_GET_REQUEST";
export const PARTNER_DELIVERYAGENT_GET_SUCCESS = "PARTNER_DELIVERYAGENT_GET_SUCCESS";
export const PARTNER_DELIVERYAGENT_GET_FAIL = "PARTNER_DELIVERYAGENT_GET_FAIL";
export const PARTNER_DELIVERYAGENT_CREATE_REQUEST = "PARTNER_DELIVERYAGENT_CREATE_REQUEST";
export const PARTNER_DELIVERYAGENT_CREATE_SUCCESS = "PARTNER_DELIVERYAGENT_CREATE_SUCCESS";
export const PARTNER_DELIVERYAGENT_CREATE_FAIL = "PARTNER_DELIVERYAGENT_CREATE_FAIL";
export const PARTNER_DELIVERYAGENT_UPDATE_REQUEST = "PARTNER_DELIVERYAGENT_UPDATE_REQUEST";
export const PARTNER_DELIVERYAGENT_UPDATE_SUCCESS = "PARTNER_DELIVERYAGENT_UPDATE_SUCCESS";
export const PARTNER_DELIVERYAGENT_UPDATE_FAIL = "PARTNER_DELIVERYAGENT_UPDATE_FAIL";

/*=============================================================
             Partner delivery agent create Action
===============================================================*/
export const createPartnerDeliveryAgentAction = (formData, navigate, state, setErrorMessage, setErrors) => async dispatch => {
    dispatch({
        type: PARTNER_DELIVERYAGENT_CREATE_REQUEST
    });
    setErrorMessage({ Del_user_email_id: { key: '', message: '' }, global: { key: '', message: '' } });
    boomiAdminApi.post(`/Super_Admin_Partner_Delivery_Person/Create`, formData
    )
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: PARTNER_DELIVERYAGENT_GET_SUCCESS
                });
                toast('Partner delivery agent added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/partner-deliveryagent-management', state = { state });
            } else {
                const errors = {};
                if (successResponse.Del_user_email_id) {
                    errors.Del_user_email_id = successResponse.UI_Display_Message;
                }
                setErrors(errors)
                setErrorMessage({ Del_user_email_id: { key: successResponse.Del_User_Email_Id ? successResponse.Del_User_Email_Id : '', message: successResponse.Del_User_Email_Id ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Del_User_Email_Id) ? successResponse.UI_Display_Message : '' } });
                dispatch({
                    type: PARTNER_DELIVERYAGENT_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            setErrorMessage({ Del_user_email_id: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
            dispatch({
                type: PARTNER_DELIVERYAGENT_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

/*=============================================================
            Partner delivery agent update Action
===============================================================*/
export const updatePartnerDeliveryAgentAction = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: PARTNER_DELIVERYAGENT_UPDATE_REQUEST
    });
    boomiAdminApi.put(`/Super_Admin_Partner_Delivery_Person/Update`, formData
    )
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: PARTNER_DELIVERYAGENT_UPDATE_SUCCESS
                });
                toast('Partner delivery agent updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/partner-deliveryagent-management', state = { state });
            } else {

                dispatch({
                    type: PARTNER_DELIVERYAGENT_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: PARTNER_DELIVERYAGENT_CREATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

/*=============================================================
            Partner delivery agent all get Action
===============================================================*/
export const getAllPartnerDeliveryAgentAction = formData => async dispatch => {
    try {
        dispatch({
            type: PARTNER_DELIVERYAGENT_REQUEST
        });
        let { data } = await nodeGetApi.post(
            `/Super_Admin_Partner_Delivery_Person/Get?PageNo=0&PageSize=${config.pageSize}&SortBy=DEL_USER_DETAIL_ID&SortOrder=ASC`,
            formData
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: PARTNER_DELIVERYAGENT_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: PARTNER_DELIVERYAGENT_SUCCESS,
                    payload: data.Super_Admin_Partner_Delivery_Person
                });
            }
        }
    } catch (err) {
        toast("Something went wrong please load page again.", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        dispatch({
            type: PARTNER_DELIVERYAGENT_FAIL,
            payload: err
        });
    }
};

/*=============================================================
            Partner delivery agent delete Action
===============================================================*/
export const deletePartnerDeliveryAgentAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
    try {
        let { data } = await boomiAdminApi.post(`/Super_Admin_Partner_Delivery_Person/Delete`, formData
        );
        if (data) {
            toast('Partner delivery agent deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            dispatch(paginationDeliveryAgent(filters, pagination, callBackPagination));
        }
    } catch (err) {
    }
};

/*=============================================================
            Partner delivery agent image Action
===============================================================*/
export const uploadPartnerDeliAgentpicImage = (
    Del_User_Email_Id,
    formData,
    callBackUploadImage
) => async dispatch => {
    try {
        let { data } = await boomiAdminApi.post(
            `/SA_Upload/Upload?functionality=PartnerDeliveryProfile&fileextension=png&filetype=Images&filename=PartnerDeliveryProfile`,
            formData
        );
        if (data) {
            callBackUploadImage(data);
        }
    } catch (err) {
    }
};

//Get Delivery Agent Action
export const getPartnerDeliveryAgentAction = (formData, callBackPartnerDeliveryAgentData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(
            `/Super_Admin_Partner_Delivery_Person/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Del_User_Detail_Id&SortOrder=ASC`,
            formData
        );
        if (data) {
            callBackPartnerDeliveryAgentData(data)
        }
    } catch (err) {
    }
};