import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useDispatch, useSelector } from 'react-redux';
import { getPartnersDateandCount } from 'src/store/actions/partnerdynamicdateandcountAction';
import isEmpty from 'src/store/validations/is-empty';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  avatar: {
    cursor: 'pointer',
    width: 58,
    height: 58
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ProductCard = ({ className, product, rolePermission, ...rest }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let dynamicvalue = useSelector((state) => state.partnerdynamicdateandcountReducer.partnersdateandcounts.all);
  const Login_Details = useSelector(state => state.auth.user);

  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  useEffect(() => {
    if (isEmpty(dynamicvalue)) {
      dispatch(getRolePermissions(Login_Details));
      dispatch(getPartnersDateandCount());
    } else {
      setSeconds(5);
      if (product.title === "Partner Management") {
        product.hide = rolePermission.Partner_Management_Main_Hide?.Is_Hide;
        product.total = dynamicvalue?.Partner_Details?.Partner_Count;
        product.createdAt = dynamicvalue?.Partner_Details?.Partner_Date;
      } else if (product.title === "Partner Ticketing Management") {
        product.hide = rolePermission.Partner_Ticketing_Management_Hide?.Is_Hide;
        product.total = dynamicvalue?.Partner_ticket?.Partner_ticket_Count;
        product.createdAt = dynamicvalue?.Partner_ticket?.Partner_ticket_Date;
      } else if (product.title === "Partner Wallet Management") {
        product.hide = rolePermission.Partner_Wallet_Management_Hide?.Is_Hide;
        product.total = dynamicvalue?.partner_wallet?.partner_wallet_Count;
        product.createdAt = dynamicvalue?.partner_wallet?.partner_wallet_Date;
      } else if (product.title === "Partner Services Management") {
        product.hide = rolePermission.Partner_Services_Management_Hide?.Is_Hide;
        product.total = dynamicvalue?.Partner_Services?.Partner_Service_Count;
        product.createdAt = dynamicvalue?.Partner_Services?.Partner_Service_Date;
      }
    }
  }, [dynamicvalue]);

  return (
    <>
      {product.hide !== 1 &&
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Box display="flex" justifyContent="center" mb={3}>
              <Avatar
                className={classes.avatar}
                component={RouterLink}
                alt="Product"
                src={product.media}
                variant="square"
                to={product.link}
              />
            </Box>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              {product.title}
            </Typography>
            <Typography style={{ paddingTop: "5px" }} align="justify" color="textPrimary" variant="body1">
              {product.description}
            </Typography>
          </CardContent>
          <Box flexGrow={1} />
          <Divider />
          <Box p={2}>
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid className={classes.statsItem} item>
                <AccessTimeIcon className={classes.statsIcon} color="action" />
                <Typography color="textSecondary" display="inline" variant="body2">
                  {product.createdAt}
                </Typography>
              </Grid>
              <Grid className={classes.statsItem} item>
                <Typography color="textSecondary" display="inline" variant="body2">
                  {product.total}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Card>
      }
    </>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProductCard;