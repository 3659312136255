import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
//ESI MAIN CENTER
import ESIMainPage from 'src/views/esi-main-centre-main-page/index';
//user main centre
import UserMainPage from 'src/views/users-main-centre/index';
//user rides
import UserRidesMainPage from 'src/views/rides-main-page/index';
//services
import ServicesMainPage from 'src/views/services-main-page/index';
//CATEGORY MANAGEMENT
import CategoryManagement from 'src/views/category-management/index';
import AddCategoryView from 'src/views/add-category/index';
import EditCategoryView from './views/edit-category';
//SUBCATEGORY MANAGEMNET
import SubCategoryListView from './views/subcategory-management';
import EditSubCategoryView from './views/edit-subcategory';
import AddSubCategoryView from 'src/views/add-subCategory/index';
//BRAND MANAGEMENT
import BrandListView from 'src/views/brand-management/index';
import AddBrandView from './views/add-brand/index';
import EditBrandView from './views/edit-brand';
//PARTNER MANAGEMENT
import PartnerManagement from 'src/views/partner-management';
import AddPartnerView from './views/add-partner/index';
import EditPartnerView from './views/edit-partner/index';
//PHYSICAL
import PartneraddressView from './views/partneraddress-view';
import PartneraddressListView from './views/partneraddress-management';
import AddPartneraddressView from './views/add-partneraddress';
import EditPartneraddressView from './views/edit-partneraddress';
//APPROVAL
import PartnersApproval from './views/partner-management/approval';
import PartnerApprovalView from './views/partner-management/approval/partner-approvalview';
//PRODUCT MANAGEMENT
//PRODUCT MASTERS
import ProductMasters from 'src/views/product-management/product-masters/index';
import ProductColorVariants from 'src/views/product-management/product-color-variants/index';
import AddProductMasterView from './views/product-management/product-masters/add-product-master/index';
import EditProductMasterView from './views/product-management/product-masters/edit-product-master/index';
import AddProductColorVariantView from './views/product-management/product-color-variants/add-product-color-variant/index';
import EditProductColorVariantView from './views/product-management/product-color-variants/edit-product-color-variant/index';
import ProductSizes from 'src/views/product-management/product-sizes/index';
import AddProductSizeView from './views/product-management/product-sizes/add-product-size/index';
import EditProductSizeView from './views/product-management/product-sizes/edit-product-size/index';
//PRODUCTS
import Products from 'src/views/product-management/products';
// import ProductListView from 'src/views/product/ProductListView';
import ProductManagement from 'src/views/product-management';
import AddProductView from './views/add-product/index';
import EditProductView from './views/edit-product';
import RelatedProductView from 'src/views/product-management/products/relatedProducts';
import AddRelatedProductView from './views/product-management/products/relatedProducts/add-relatedproducts/index';
import EditRelatedProductView from './views/product-management/products/relatedProducts/edit-relatedproducts/index';
import ProductPartnerView from 'src/views/product-management/products/productpartner';
import AddProductPartnerView from './views/product-management/products/add-productpartner/index';
import EditProductPartnerView from './views/product-management/products/edit-productpartner/index';
//APPROVAL
import ProductsApproval from './views/product-management/products/product-approval';
import ProductApprovalView from './views/product-management/products/product-approval/view-productapproval';
//INVENTORY MANAGEMENT
import Inventory from 'src/views/inventory/index';
import AddInventoryView from './views/add-inventory/index';
import InventoryHistoryView from './views/inventory-history';

import EditInventoryView from './views/edit-inventory';
//COUPON MANAGEMENT
import CouponManagement from 'src/views/coupon-management/index';
import AddCouponView from 'src/views/add-coupon/index';
import EditCouponView from './views/edit-coupon';
//APPROVAL 
import CouponsApproval from './views/coupon-management/approval';
import CouponApprovalView from './views/coupon-management/approval/coupon-approvalview';
//DEAL MANAGEMENT
import DealManagement from 'src/views/deal-management';
import AddDealView from './views/add-deal/index';
import EditDealView from './views/edit-deal';
//APPROVAL
import DealApprovalView from './views/deal-management/approval/deal-approvalview';
import DealsApproval from './views/deal-management/approval';
//SHOPBYCITY MANAGEMENT
import ShopByCityManagement from 'src/views/shop-by-city-management';
import CitysListView from './views/shop-by-city-management/city-management';
import AddCityView from './views/shop-by-city-management/add-city';
import EditCityView from './views/shop-by-city-management/edit-city';
import CitypartnerListView from 'src/views/citys-partner-management';
import AddCitypartnerView from './views/add-citypartner';
import EditCitypartnerView from './views/edit-citypartner';
import CitypartnercouponListView from 'src/views/citys-coupon-management';
import AddCitypartnercouponView from './views/add-citypartnercoupon';
import EditCitypartnercouponView from './views/edit-citypartnercoupon';
import CitypartnerdealListView from 'src/views/citys-deal-management';
import AddCitypartnerdealView from './views/add-citypartnerdeal';
import EditCitypartnerdealView from './views/edit-citypartnerdeal';
import CityProductListView from './views/shop-by-city-management/city-product-management';
import AddCityProductView from './views/shop-by-city-management/add-city-product';
import EditCityProductView from './views/shop-by-city-management/edit-city-product';
//BANNER MANAGEMENT
import BannerManagement from 'src/views/banner-management';
import AddBrannerView from './views/add-banner/index';
import EditBannerView from './views/edit-banner';
//GIFT MANAGEMENT
import GiftvendorListView from './views/gift-vendors';
import AddGiftvendorView from './views/add-giftvendor';
import EditGiftvendorView from './views/edit-giftvendor';
import GiftimageListView from './views/gift-images';
import AddGiftimageView from './views/add-giftimage';
import EditGiftimageView from './views/edit-giftimage';
import GiftManagement from 'src/views/gift-management';
import GiftListView from './views/user-gifts';
import UserGiftHistoryView from 'src/views/user-gifts/user-gift-history-details';
import EditGiftView from './views/edit-gift';
//ADMIN USER MANAGEMENT
import UserListView from 'src/views/admin-user-management';
import AddUserView from 'src/views/add-user/index';
import EditUserView from './views/edit-user';
//USER PROFILE MANAGEMENT
import UsersManagement from 'src/views/user-profile-management';
import AddUserProfileView from './views/add-user-profile/index';
import EditUserProfileView from './views/edit-user-profile';
//ORDER MANAGEMNET
import OrderManagement from 'src/views/order-management/index';
//ORDER HISTORY
import OrderListView from './views/order-history/index';
//MISSING CASHBACK
import MissingCashbackListView from './views/missing-cashback/index';
import EditMissingCashbackView from './views/edit-missingcashback';
import MissingcashbackView from './views/missing-cashback/view-missingcashback';
//REVIEWS AND RATINGS
import ReviewsAndRatingsListView from './views/reviews-and-ratings';
import ProductReviewsAndRatingsListView from './views/reviews-and-ratings/index';
import AddProductReviewView from './views/add-product-review/index';
import AddPartnerReviewView from './views/add-partner-review/index';
import AddBrandReviewView from './views/add-brand-review/index';
import EditBrandReview from 'src/views/edit-brand-review';
import EditProductReview from 'src/views/edit-product-review';
import EditPartnerReview from 'src/views/edit-partner-review';
import PartnerUserReviewsListView from './views/user-reviews/partneruserreviews';
import ProductUserReviewsListView from './views/user-reviews';
import BrandUserReviewsListView from './views/user-reviews/branduserreviews';
import BrandReviewListView from 'src/views/reviews-and-ratings/brandreviewtable/index';
import PartnerReviewListView from 'src/views/reviews-and-ratings/partnerreviewtable';
import UserReviewsListView from './views/user-reviews';
//TICKETING MANAGEMENT
import TicketingManagementListView from './views/ticketingManagement';
import AddTicketView from './views/add-ticket';
import EditTicketView from './views/edit-ticket';
//TRANSACTION HISTORY
import TransactionHistoryListView from './views/transaction-history/index';
import TransactionHistoryDetailsView from './views/transaction-history-details';
import EditTransactionView from './views/edit-transaction';
//PAYMENTS AND SETTLEMENTS
import PaymentAndSettlements from 'src/views/payment-and-settlements';
//USER
import UserPaymentSettlementsEditView from './views/user-payment-settlements-edit';
import UserPaymentSettlementsListView from './views/user-payment-settlements';
import UserPaymentSettlementsDetailsView from './views/user-payment-settlements-details';
//PARTNER
import PartnerPaymentSettlementsDetailsView from './views/partner-payment-settlements-details';
import PartnerPaymentSettlementsEditView from './views/partner-payment-settlements-edit';
import PartnerPaymentSettlementsListView from './views/partner-payment-settlements';
//WALLET MANAGEMENT
import WalletManagement from 'src/views/wallet-management';
import AddWalletView from './views/add-wallet/index';
import AddWalletHistoryView from './views/add-wallet-history/index';
import WalletHistoryView from './views/wallet-history';
import EditWalletView from './views/edit-wallet';
//ORDER SHIPPING
import ShippingListView from './views/shipping';
// import ShippingView from './views/shipping/view-shipping';
import ReturnshippingListView from './views/shipping/returned-shippings';
import ReturnedshippingsView from './views/shipping/returned-shippings/view-returnedshippings';
import CancelledshippingListView from './views/shipping/cancelled-shippings';
import CancelledshippingsView from './views/shipping/cancelled-shippings/view-cancelledshippings';
//OFFER VISITORS
import OffersVisitListView from './views/offers-visitor/index';
import EditOffersVisitorView from './views/edit-offersvisitor';
//PROMOTION MANAGEMENT
import PromotionManagement from './views/promotion-management/index';
//REFER AND EARN
import ReferandEarnListView from './views/refer-earn';
import ReferandEarnHistoryView from './views/refer-earn/refer-earn-history';
//NEWSLETTER
import NewsletterListView from './views/Newsletter';
import AddNewsletterView from './views/add-newsletter/index';
import EditNewsletterView from './views/edit-newsletter/index';
//ADS MANAGEMENT
import AdsListView from './views/ads-management';
import AddAdsView from './views/add-ads';
import EditAdsView from './views/edit-ads';
//PROMOTIONAL MANAGEMENT
import PromotionalManagement from 'src/views/promotional-management/index';
import AddPromotionalView from './views/add-promotional/index';
import EditPromotionalView from './views/edit-promotional/index';
//POPUP MANAGEMENT
import AddPopupView from 'src/views/add-popup/index';
import PopupListView from 'src/views/popup-management';
import EditPopupView from './views/edit-popup';
//CONTENT MANAGEMENT
import ContentManagement from 'src/views/content-management/index';
//NEWS AND EVENTS
import NewseventsListView from './views/News-and-Events';
import AddNewseventsView from './views/add-newsevents/index';
import EditNewseventsView from './views/edit-newsevents';
//FAQ MANAGEMENT
import FaqManagement from 'src/views/faq-management/index';
import AddFAQView from './views/add-faq/index';
import EditFAQView from './views/edit-faq/index';
//CONTENT MANAGEMENT
import EditContentManagementView from './views/edit-Content-Management/index';
import PrivacyPolicyListView from './views/contents/index';
import AddContentManagementView from './views/add-Content-Management/index';
//TESTIMONIAL MANAGEMENT
import TestimonialsListView from 'src/views/testimonial-management/index';
import TestimonialHistoryDetailsView from './views/testimonial-history-details';
import EditTestimonialView from './views/edit-testimonial';
//DELIVERY MANAGEMENT
import DeliveryManagement from './views/delivery-management';
//DASHBOARD
import DashboardView from 'src/views/reports/DashboardView';
import DeliveryDashboard from './views/delivery-reports/delivery-dashboard';
//REPORTS
import Reports from 'src/views/reports/index';
import ReportOrders from 'src/views/reports/statistics/orders';
import ReportUsers from './views/reports/statistics/users';
import ReportPartners from './views/reports/statistics/partners';
import ReportBrands from './views/reports/statistics/brands';
import ReportCoupons from './views/reports/statistics/coupons';
import ReportDeals from './views/reports/statistics/deals';
import ReportProducts from './views/reports/statistics/products';
import DeliveryReports from './views/delivery-reports';
import TotalOrderReports from './views/delivery-reports/delivery-statistics/totalorders';
import DeliveredOrdersReport from './views/delivery-reports/delivery-statistics/deliveredorders';
import OutfordeliveryOrdersReport from './views/delivery-reports/delivery-statistics/outfordeliveryorders';
import ReturnedOrdersReport from './views/delivery-reports/delivery-statistics/returnedorders';
import CancelledOrdersReport from './views/delivery-reports/delivery-statistics/cancelledorders';
//DELIVERY FAQS
import AddDeliveryView from './views/add-deliveryfaq';
import EditdeliveryFaqView from './views/edit-deliveryfaq';
import DeliveryFaqListView from './views/deliveryfaq-management/index';
//DELIVERY CONTENT MANAGEMENT
import ContentListView from './views/delivery-cms-page';
import AddContentView from './views/delivery-cms-page/add-Cms-page';
import EditContentView from './views/delivery-cms-page/edit-Cms-Page';
//APPROVED DELIVERY AGENTS
import DeliveryAgentManagement from './views/delivery-Agent-management/delivery-Agent-Person-Approval';
import EditDeliverypersonDetails from './views/delivery-Agent-management/edit-delivery-person-details/index'
import DeliveryAgentsListView from './views/delivery-Agent-management/delivery-Agent-Details-View/index';
//PENDING DELIVERY AGENTS
import PendingDeliveryAgentListView from './views/delivery-Agent-management/pending-delivery-Agent-Person-Approvel';
import PendingDeliveryAgentsListView from './views/delivery-Agent-management/pending-delivery-Agent-Details-View/index';
import EditPendingDeliverypersonDetailsView from './views/delivery-Agent-management/edit-delivery-persons-details';
//DELIVERY PAYMENT SETTLEMENT
import DeliveryPaymentSettlementsListView from './views/delivery-payment-settlements';
import DeliveryPaymentSettlementsDetailsView from './views/delivery-payment-settlements/delivery-payment-settlements-details';
import DeliveryPaymentSettlementsEditView from './views/delivery-payment-settlements/delivery-payment-settlements-edit';
//DELIVERY TICKETS
import DeliveryTicketsListView from './views/Deliveryticketing';
import EditdeliveryTicketView from './views/edit-deliveryticket';
import CommentsListView from './views/ticketingManagement/comments';
import AddCommentView from './views/add-comment';
import EditCommentView from './views/edit-comment';
import DeliveryCommentsListView from './views/Deliveryticketing/comments';
import AdddeliveryCommentView from './views/add-deliverycomment';
import EditdeliveryCommentView from './views/edit-deliverycomment';

//CORPORATE CONTENT MANAGEMENT
import ContentListcorporateView from './views/corporate-cms-page';
import AddContentcorporateView from './views/corporate-cms-page/add-CMS-corporate';
import EditContentcorporateView from './views/corporate-cms-page/edit-CMS-corporate';
//SETTINGS
import SettingsManagement from 'src/views/settings-management/index';
//EMAIL TEMPLATE
import EmailTemplateListView from 'src/views/email-template/index';
import AddEmailTemplateView from './views/add-email-template/index';
import EditEmailTemplateView from './views/edit-email-template/index';
//SETTINGS
// import SettingsView from 'src/views/settings/SettingsView';
import SettingListView from 'src/views/setting/index';
import AddSettingView from './views/add-setting/index';
import EditSettingView from './views/edit-setting/index';
//NOTIFICATIONS
import OrdernotificationView from './views/view-ordernotification';
import EditReferandEarnView from './views/edit-refer-earn';
import AddDeliverypersonDetailsView from './views/delivery-Agent-management/add-delivery-persons-details';
import PartnerDeliveryAgentListView from './views/partner-deliveryagent-management';
import AddPartnerDeliverypersonDetailsView from './views/partner-deliveryagent-management/add-deliveryagents';
import EditPartnerDeliveryagentDetailsView from './views/partner-deliveryagent-management/update-deliveryagents';
import PartnerDeliveryAgentView from './views/partner-deliveryagent-management/view-deliveryagents';
import PartnerEmployeeListView from './views/partner-employee-management';
import EditPartnerEmployeeDetailsView from './views/partner-employee-management/update-partneremployee';
import AddPartnerEmployeeDetailsView from './views/partner-employee-management/add-partneremployee';
import PartnerEmployeeView from './views/partner-employee-management/view-partneremployee';
import StoreList from './views/store-management';
import StoreListView from './views/store-type';
import AddStoreTypeView from './views/store-type/add-storetype';
import EditStoreTypeView from './views/store-type/edit-storetype';
import StoretypeDetailsView from './views/storetype-details';
import StoreLinkingView from './views/store-type-linking';
import AddStoreTypeLinkView from './views/store-type-linking/add-storetype-linking';
import EditStoreTypeLinkView from './views/store-type-linking/edit-storetype-linking';
import StoretypeLinkDetailsView from './views/storetype-linking-details';
import UserFinancialTransactionsListView from './views/user-financial-transactions';
import UserFinancialTransactionsView from './views/user-financial-transactions/view-userfinancial-transactions';
import EditUserFinancialTransactionView from './views/user-financial-transactions/edit-userfinancial-transactions';
//Company Profile
import CompanyProfile from 'src/views/company-profile-management/index';
import RoleManagement from 'src/views/role-management/index';
import AddRoleView from './views/role-management/add-role';
import EditRoleView from './views/role-management/edit-role';
//Employee 
import EmployeeListView from 'src/views/admin-profile-users/index';
import AddProfileUserView from './views/admin-profile-users/add-profile-users';
import EditProfileUserView from './views/admin-profile-users/edit-profile-users';
import CompanySettings from './views/mycompanyprofile';
import CityLocation from './views/shop-by-city-management/city-location';
import AddCityLocationView from './views/shop-by-city-management/city-location/add-city-location';
import EditCityLocationView from './views/shop-by-city-management/city-location/edit-city-location';
import PartnerCityLocationListView from './views/citys-partner-management/city-partner-location';
import AddCitypartnerlocationView from './views/citys-partner-management/city-partner-location/add-city-partner-location';
import EditCitypartnerlocationView from './views/citys-partner-management/city-partner-location/edit-city-partner-location';
import PartnerbankdetailsView from './views/partnerbank-details';
import SubcategorydetailsView from './views/subcategory-details';
import CoupondetailsView from './views/couponview-details';
import CategoryDetailsView from './views/category-management-details';
import BrandDetailsView from './views/brand-management-details';
import DealsDetailsView from './views/deal-management-details';
import StorePartnerLinkingView from './views/store-type-partner-linking';
import PartnerTicketsListView from './views/partner-ticketing-management';
import AddPartnerTicketView from './views/add-partnerticket';
import EditPartnerTicketView from './views/edit-partnerticket';
import AddStoreTypePartnerLinkView from './views/store-type-partner-linking/add-storetype-partner-linking';
import EditStoreTypepartnerLinkView from './views/store-type-partner-linking/edit-storetype-partner-linking';
import StoretypeLinkPartnerDetailsView from './views/storetype-linking-partnerdetails';
import EditOrderStatusHistoryView from './views/order-history/order-lineitem-update';
import B2BOrderListView from './views/b2b-order-management';
import EditB2BOrderHistoryView from './views/b2b-order-management/update-b2b-order';
import PartnerApprovalListView from './views/product-management/products/productpartner/pending-productpartner';
import EditPendingProductPartnerView from './views/product-management/products/productpartner/pending-productpartner/approve-pendingpartner';
import UserRidesListView from './views/user-rides';
import EditUserRidesView from './views/user-rides/edit-user-rides';
import UserRideDetailsView from './views/user-rides/user-rides-view';
import ServicesListView from './views/services-management';
import EditUserServicesView from './views/services-management/edit-service';
import AddUserServicesView from './views/services-management/add-service';
import ServicesView from './views/services-management/view-services';
import PartnerServicesListView from './views/partnerservice-management';
import AddPartnerServicesView from './views/partnerservice-management/add-partnerservice';
import EditPartnerServicesView from './views/partnerservice-management/edit-partnerservice';
import PartnerServicesView from './views/partnerservice-management/view-partnerservices';
import UserRideFaresListView from './views/user-ride-fares';
import EditUserRideFaresView from './views/user-ride-fares/edit-user-ride-fares';
import UserRideFareDetailsView from './views/user-ride-fares/user-ride-fares-view';
import AddUserRideFaresView from './views/user-ride-fares/add-user-ride-fares';
import ServicesGenericListView from './views/servicegeneric-management';
import AddGenericUserServiceView from './views/servicegeneric-management/add-genericservice';
import EditGenericUserServiceView from './views/servicegeneric-management/edit-genericservice';
import GenericServicesView from './views/servicegeneric-management/view-genericservices';
import DeliveryWalletListView from './views/delivery-wallet-management';
import AddDeliveryWalletView from './views/delivery-wallet-management/add-delivery-wallet';
import EditDeliveryWalletView from './views/delivery-wallet-management/edit-delivery-wallet';
import DeliveryWalletHistoryListView from './views/delivery-wallet-management/delivery-wallet-history';
import PartnerWalletListView from './views/partnerwallet-management';
import AddPartnerWalletView from './views/partnerwallet-management/add-partnerwallet';
import EditPartnerWalletView from './views/partnerwallet-management/edit-partnerwallet';
import PartnerWalletHistoryView from './views/partnerwallethistory-management';
import TicketDetailsView from './views/ticketingManagement/ticketing-management-view';
import CommentDetailsView from './views/user-comments-details';
import PartnerTicketDetailsView from './views/partner-ticketing-management-view';
import PartnerCommentDetailsView from './views/partner-comments-details';
import AddPartnerCommentView from './views/add-partnercomment';
import PartnerCommentsListView from './views/partner-ticketing-management/comments';
import EditPartnerCommentView from './views/edit-partnercomment';
import DeliveryTicketDetailsView from './views/Deliveryticketing/delivery-ticketing-management-view';
import DeliveryCommentDetailsView from './views/delivery-comments-details';
import AddDeliveryTicketView from './views/add-deliveryticket';
import AddDeliveryWalletHistoryView from './views/delivery-wallet-management/delivery-wallet-history/add-deliverywallet-history';
import EditDeliveryWalletHistoryView from './views/delivery-wallet-management/delivery-wallet-history/edit-deliverywallet-history';
import DeliveryWalletHistoryView from './views/delivery-wallet-management/delivery-wallet-history/view-deliverywallet-history';
import AddUserRidesView from './views/user-rides/add-user-rides';
import AddPartnerWalletHistoryView from './views/partnerwallethistory-management/add-partnerwallethistory';
import EditPartnerWalletHistoryView from './views/partnerwallethistory-management/edit-partnerwallethistory';
import PartnerWalletHistoryList from './views/partnerwallethistory-management/view-partnerwallethistory';
//Partner MAIN CENTER
import PartnerMainCenter from 'src/views/partner-main-page/index';
import ViewOrderAddress from './views/order-history/address-print';
import DeliveryVehicleListView from './views/delivery-vehicle-details';
import AddDeliveryVehicleView from './views/delivery-vehicle-details/add-deliveryvehicledetails';
import EditDeliveryVehicleView from './views/delivery-vehicle-details/edit-deliveryvehicledetails';
import VehicleDetailsView from './views/delivery-vehicle-details/view-vehicledetails';
import ProductsView from './views/view-products';
import BussinessSubtypeView from './views/bussiness-subtype';
import AddBussinessView from './views/bussiness-subtype/add-bussinessSubtype';
import EditBussinessView from './views/bussiness-subtype/edit-bussinessSubtype';
import BussinessSubtypeList from './views/bussiness-subtype/view-bussinessSubtype';
//razorpay
import RazorpayMainPage from 'src/views/razorpay-payments-main-page/index';
import RazorpayView from './views/razorpaypayments-management';
import AddRazorpayView from './views/razorpaypayments-management/add-razorpaypayments';
import AddRazorpayhistoryView from './views/razorpaypayments-management/razorpay-history/add-razorpayhistory';
import RazorpayhistoryView from './views/razorpaypayments-management/razorpay-history/view-razorpayhistory';
import ContentManagementView from './views/view-contentmanagement';
import EmailTemplateView from './views/view-emailtemplate';
import SettingsManagementView from './views/view-settingsmanagement';
import UserProfilesManagementView from './views/view-userprofilemanagement';
import UsersManagementView from './views/view-usermanagement';
import RazorpayTransactionView from './views/razorpaypayments-management/razorpay-history';
import GlobalCouponMainPage from 'src/views/global-coupon-mainpage/index';
import GlobalCouponListView from './views/global-coupon-management';
import AddGlobalCouponView from './views/global-coupon-management/add-global-coupon';
import UpdateGlobalCouponView from './views/global-coupon-management/update-global-coupon';
import GlobalCouponView from './views/global-coupon-management/view-global-coupon';
import GlobalCouponHistoryListView from './views/global-coupon-management/global-coupon-history';
import GlobalCouponHistoryView from './views/global-coupon-management/view-global-coupon-history';
import UserGiftView from './views/view-usergifthistory';
import PartnerVehicleListView from './views/partner-vehicle-details';
import AddPartnerVehicleView from './views/partner-vehicle-details/add-partnervehicledetails';
import EditPartnerVehicleView from './views/partner-vehicle-details/edit-partnervehicledetails';
import VehiclePartnerDetails from './views/partner-vehicle-details/view-partnervehicledetails';
import PartnerHistoryView from './views/partner-management/partner-management-history';
import DeliveryWalletView from './views/delivery-wallet-management/view-deliverywallet';
import PartnerWalletView from './views/partnerwallet-management/view-partnerwallet';
import WalletView from './views/wallet-management/view-wallet';
import RoleView from './views/role-management/view-role';
import AdminEmployeeView from './views/admin-profile-users/view-adminprofile-users';
import CityView from './views/shop-by-city-management/city-management/view-citydetails';
import CityDealDetails from './views/citys-deal-management/view-partnercitydeal';
import CityCouponDetails from './views/citys-coupon-management/view-partnercitycoupon';
import CityProductDetails from './views/shop-by-city-management/city-product-management/view-partnercityproduct';
import CityLocationList from './views/shop-by-city-management/city-location/view-city-location';
import PartnerProdcutDetails from './views/product-management/products/productpartner/view-partner-product';
import GiftVendorList from './views/gift-vendors/view-giftvendor';
import WalletHistoryDetails from './views/wallet-history/view-wallet-history';
import PartnerServiceCityView from './views/view-partnerservicecity';
import SubcriptionManagement from 'src/views/subscription-management/index';
import SubscriptionUsersListView from './views/subscription-user-management';
import AddSubscriptionUserView from './views/subscription-user-management/add-subscriptionuser';
import EditUserSubscriptionView from './views/subscription-user-management/edit-subscriptionuser';
import UserSubscriptionDetailsView from './views/subscription-user-management/view-subscriptionuser';
import SubscriptionProductsUsersListView from './views/subscription-userproduct-management';
import Orderb2bnotificationView from './views/view-b2bordernotification';
import SubsPackagesListView from './views/subscription-packages';
import AddSubscriptionPackageView from './views/subscription-packages/add-subscription-packages';
import EditSubscriptionPackageView from './views/subscription-packages/edit-subscription-packages';
import SubscriptionPackageView from './views/subscription-packages/view-subscription-packages';
import SubsPackLocationsListView from './views/subscription-package-locations';
import NewServicesListView from './views/services-management/services-management-new';
import InProcessServicesListView from './views/services-management/services-management-in-process';
import CancelledServicesListView from './views/services-management/services-management-cancelled';
import CompletedServicesListView from './views/services-management/services-management-completed';
import PartnerCityLocationDetails from './views/citys-partner-management/city-partner-location/view-city-partner-location';
import UserRidesNewListView from './views/user-rides/new-user-rides';
import UserRidesCompletedListView from './views/user-rides/completed-user-rides';
import RideUserPaymentView from './views/user-rides/rides-user-payments';
import RideDeliveryPaymentView from './views/user-rides/rides-delivery-payments';
import ServicesUserPaymentView from './views/services-management/services-user-payments';
import VehicleTypesListView from './views/rides-vehicle-types';
import AddRideVehicleTypeView from './views/rides-vehicle-types/add-rides-vehicle-types';
import EditRideVehicleTypeView from './views/rides-vehicle-types/edit-rides-vehicle-types';
import RideVehicleTypeDetails from './views/rides-vehicle-types/view-rides-vehicle-types';
import UserRidesScheduledListView from './views/user-rides/scheduled-user-rides';
import ServicesPartnerPaymentView from './views/services-management/services-partner-payments';
import ServicesCompletedPartnerPaymentView from './views/services-management/services-management-completed/services-completed-partner-payments';
import ServicesCancelledUserPaymentView from './views/services-management/services-management-cancelled/services-cancelled-user-payments';
import RazorpaydetailsView from './views/razorpaypayments-management/view-razorpaydetails';
import ProductListView from './views/reviews-and-ratings';
import PermissionsListView from './views/permissions-management';
import AddPermissionView from './views/permissions-management/add-permissions';
import EditPermissionView from './views/permissions-management/edit-permissions';
import PermissionDetailsView from './views/permissions-management/view-permissions';
import RazorpayTransactionsView from './views/razorpaytransaction-management';
import RazorpayTransactionDetailsView from './views/razorpaytransaction-management/view-razorpaytransactiondetails';
import BiddingMainPage from 'src/views/bidding-management/index';
import BiddingRidesMainPage from 'src/views/bidding-ride-management/index';
import BiddingVehiclesListView from './views/bidding-ride-vehicles';
import ViewBiddingVehicle from './views/bidding-ride-vehicles/bidding-vehicles-view';
import AddBiddingVehicleView from './views/bidding-ride-vehicles/bidding-vehicles-add';
import EditBiddingVehicleView from './views/bidding-ride-vehicles/bidding-vehicles-edit';
import BidSuccessStoryListView from './views/bidding-ride-success-stories';
import ViewDeliveryBidSuccessStory from './views/bidding-ride-success-stories/view-delivery-bidding-success-stories';
import AddDeliveryBidSuccessStoryView from './views/bidding-ride-success-stories/add-delivery-bidding-success-stories';
import EditDeliveryBidSuccessStoryView from './views/bidding-ride-success-stories/edit-delivery-bidding-success-story';
import BiddingProductsMainPage from 'src/views/bidding-product-management/index';
import PartnerBidAvailProductsListView from './views/bidding-partner-available-products';
import ViewPartnerBidAvailProducts from './views/bidding-partner-available-products/view-bidding-partner-available-products';
import AddPartnerBidAvailProductView from './views/bidding-partner-available-products/add-bidding-partner-available-product';
import EditPartnerBidAvailProductView from './views/bidding-partner-available-products/edit-bidding-partner-available-product';
import PartnerBiddingQuotesListView from './views/partner-bidding-quotes';
import ViewPartnerBiddingQuotesView from './views/partner-bidding-quotes/view-partner-bidding-quotes';
import AddPartnerBiddingQuoteView from './views/partner-bidding-quotes/add-partner-bidding-quotes';
import EditPartnerBiddingQuoteView from './views/partner-bidding-quotes/edit-partner-bidding-quotes';
import PartnerBiddingCommentsListView from './views/partner-bidding-comments';
import UpdatePartnerBiddingComments from './views/partner-bidding-comments/update-partner-bidding-comments';
import BiddingVehicleBrandsListView from './views/bidding-ride-vehicle-brands';
import ViewBiddingVehicleBrand from './views/bidding-ride-vehicle-brands/view-bidding-vehicle-brands';
import AddBidVehicleBrandView from './views/bidding-ride-vehicle-brands/add-bidding-vehicle-brands';
import EditBidVehicleBrandView from './views/bidding-ride-vehicle-brands/edit-bidding-vehicle-brand';
import UserRideBiddingsListView from './views/user-rides-bidding';
import UserRideBiddingDetailsView from './views/user-rides-bidding/view-user-ride-bidding';
import AddUserRideBiddingView from './views/user-rides-bidding/add-user-ride-bidding';
import EditUserRideBiddingView from './views/user-rides-bidding/edit-user-ride-bidding';
import DeliveryBiddingQuotesListView from './views/delivery-bidding-quotes';
import ViewDeliveryBiddingQuotes from './views/delivery-bidding-quotes/view-delivery-bidding-quotes';
import AddDeliveryBiddingQuoteView from './views/delivery-bidding-quotes/add-delivery-bidding-quotes';
import EditDeliveryBiddingQuoteView from './views/delivery-bidding-quotes/edit-delivery-bidding-quotes';
import DeliveryBiddingCommentsListView from './views/delivery-bidding-comments';
import UpdateDeliveryBiddingComments from './views/delivery-bidding-comments/update-delivery-bidding-comments';
import BiddingBrandVehiclesListView from './views/bidding-ride-brand-vehicles';
import ViewBiddingBrandVehicle from './views/bidding-ride-brand-vehicles/view-bidding-ride-brand-vehicle';
import AddBidRideBrandVehicleView from './views/bidding-ride-brand-vehicles/add-bidding-ride-brand-vehicle';
import EditBidRideBrandVehicleView from './views/bidding-ride-brand-vehicles/edit-bidding-ride-brand-vehicle';
import B2BBiddingProductsMainPage from './views/b2b-bidding-management';
import B2BUserProductsBiddingListView from './views/b2b-user-products-bidding';
import AddB2BUserProductsBiddingView from './views/b2b-user-products-bidding/add-b2b-user-products-bidding';
import B2BUserProductsBiddingDetailsView from './views/b2b-user-products-bidding/view-b2b-user-products-bidding';
import EditB2BUserProductsBiddingView from './views/b2b-user-products-bidding/edit-b2b-user-products-bidding';
import BiddingUserProductsListView from './views/bidding-user-products';
import AddBiddingUserProductsView from './views/bidding-user-products/bidding-user-products-add';
import EditBiddingUserProductsView from './views/bidding-user-products/bidding-user-products-edit';
import ViewBiddingUserProductsList from './views/bidding-user-products/bidding-user-products-view';
import BiddingProductsSuccessStoriesListView from './views/bidding-products-success-stories';
import AddBidProductSuccessStoryView from './views/bidding-products-success-stories/add-bidding-products-success-stories';
import ViewBiddingUserProductsSuccessStoryList from './views/bidding-products-success-stories/view-bidding-products-success-stories';
import EditBidProductSuccessStoryView from './views/bidding-products-success-stories/edit-bidding-products-success-stories';
import AddBiddingProductsCategoryView from './views/bidding-products-category/add-bidding-products-category';
import EditBiddingProductsCategoryView from './views/bidding-products-category/edit-bidding-products-category';
import BiddingProductsCategoryListView from './views/bidding-products-category';
import ViewProductsCategory from './views/bidding-products-category/view-bidding-products-category';
import BiddingProductsListView from './views/bidding-products';
import AddBiddingProductsView from './views/bidding-products/add-bidding-product';
import EditBiddingProductsView from './views/bidding-products/edit-bidding-product';
import ViewBiddingProducts from './views/bidding-products/view-bidding-products';

import BiddingServicesMainPage from 'src/views/bidding-service-management/index';
import BiddingServicesListView from './views/bidding-services';
import AddBiddingServicesView from './views/bidding-services/add-biddingservices';
import BiddingServicesSuccessStoryListView from './views/bidding-service-success-stories';
import AddBidServiceSuccessStoryView from './views/bidding-service-success-stories/add-bidservicesuccessstories';
import PartnerBidAvailServicesListView from './views/bidding-partner-available-services';
import AddBiddingPartnerAvailServicesView from './views/bidding-partner-available-services/add-bidding-partner-avail-service';
import BiddingUserServicesListView from './views/bidding-user-services';
import AddBiddingUserServicesView from './views/bidding-user-services/add-bidding-user-services';
import EditBiddingServicesView from './views/bidding-services/edit-biddingservices';
import ViewBiddingServicesList from './views/bidding-services/view-biddingservices';
import EditBiddingPartnerAvailServicesView from './views/bidding-partner-available-services/edit-bidding-partner-avail-service';
import ViewBiddingUserServicesList from './views/bidding-user-services/view-bidding-user-services';
import EditBiddingUserServicesView from './views/bidding-user-services/edit-bidding-user-services';
import ViewBiddingPartnerAvailServicesList from './views/bidding-partner-available-services/view-bidding-partner-avail-service';
import PartnerBiddingServiceQuotesListView from './views/partner-bidding-service-quotes';
import AddPartnerBiddingServiceQuotesView from './views/partner-bidding-service-quotes/add-partnerbiddingservicequotes';
import EditPartnerBiddingServiceQuotesView from './views/partner-bidding-service-quotes/edit-partnerbiddingservicequotes';
import ViewPartnerBiddingServiceQuotesList from './views/partner-bidding-service-quotes/view-partnerbiddingservicequotes';
import PartnerBiddingServiceCommentsListView from './views/partner-bidding-service-quotes/partner-bidding-service-comments';
import ViewPartnerBiddingServiceQuoteCommentsList from './views/partner-bidding-service-quotes/partner-bidding-service-comments/view-partnerbiddingservicequotecomments';
import EditBidServiceSuccessStoryView from './views/bidding-service-success-stories/edit-bidservicesuccessstories';
import ViewBiddingUserServicesSuccessStoryList from './views/bidding-service-success-stories/view-bidservicesuccessstories';
import B2BUserProdBidQuotesListView from './views/b2b-user-products-bidding-quotes';
import ViewB2BUserProdBidQuotes from './views/b2b-user-products-bidding-quotes/view-b2b-user-products-bidding-quote';
import AddB2BUserProdBiddingQuoteView from './views/b2b-user-products-bidding-quotes/add-b2b-user-products-bidding-quote';
import EditB2BUserProdBiddingQuoteView from './views/b2b-user-products-bidding-quotes/edit-b2b-user-products-bidding-quote';
import B2BUserProdBidCommentsListView from './views/b2b-user-product-bidding-comments';
import UpdateB2BUserProdBidComments from './views/b2b-user-product-bidding-comments/update-b2b-user-prod-bidding-comments';
import BiddingProductsPartnerPaymentsView from './views/bidding-user-products/bidding-products-partner-payments';
import UserRideDeliveryPaymentView from './views/user-rides-bidding/user-ride-delivery-payment';
import B2BBidProdPartnerPaymentView from './views/b2b-user-products-bidding/b2b-bid-product-partner-payments';
import BiddingServicesPartnerPaymentsView from './views/bidding-user-services/bidding-services-partner-payments';
import DeliBidVehicleCommissionListView from './views/delivery-bidding-vehicle-commission';
import AddDeliBidVehicleCommissionView from './views/delivery-bidding-vehicle-commission/add-delivery-bidding-vehicle-commission';
import EditDeliBidVehicleCommissionView from './views/delivery-bidding-vehicle-commission/edit-delivery-bidding-vehicle-commission';
import ViewDeliBidVehicleCommission from './views/delivery-bidding-vehicle-commission/view-delivery-bidding-vehicle-commission';
import MeepaisaFashionHubMainPage from 'src/views/meepaisa-fashion-hub/index';
import MPProductComboListView from './views/meepaisa-product-combo';
import ViewMPProductComboView from './views/meepaisa-product-combo/view-meepaisa-product-combo';
import AddMPProductComboView from './views/meepaisa-product-combo/add-meepaisa-product-combo';
import EditMPProductComboView from './views/meepaisa-product-combo/edit-meepaisa-product-combo';
//MEEPAISA ZONE
import MeepaisaZone from 'src/views/meepaisa-zone/index';
import AddMeepaisaZoneView from './views/meepaisa-zone/add-meepaisa-zone';
import EditMeepaisaZoneView from './views/meepaisa-zone/edit-meepaisa-zone';
import MeepaisaZoneView from './views/meepaisa-zone/meepaisa-zone-details';
//MEEPAISA ZONE THEME
import MeepaisaZoneTheme from 'src/views/meepaisa-zone-theme/index';
import AddMeepaisaZoneThemeView from './views/meepaisa-zone-theme/add-meepaisa-zone-theme/Index';
import EditMeepaisaZoneThemeView from './views/meepaisa-zone-theme/edit-meepaisa-zone-theme';
import MeepaisaZoneThemeView from './views/meepaisa-zone-theme/meepaisa-zone-theme-details';

import MeepaisaThemeListView from './views/meepaisa-theme-management/meepaisa-theme';
import AddMeepaisaThemeView from './views/meepaisa-theme-management/add-meepaisa-theme';
import EditMeepaisaThemeView from './views/meepaisa-theme-management/edit-meepaisa-theme';
import ViewMeepaisaTheme from './views/meepaisa-theme-management/view-meepaisa-theme';
import MeepaisaZoneThemeProductsListView from './views/meepaisa-zone-theme-products-management/meepaisa-zone-theme-products';
import AddZoneThemeProductView from './views/meepaisa-zone-theme-products-management/add-zone-theme-products';
import EditZoneThemeProductView from './views/meepaisa-zone-theme-products-management/edit-zone-theme-products';
import ViewZoneThemeProduct from './views/meepaisa-zone-theme-products-management/view-zone-theme-products';
import ViewOrderInvoice from './views/order-history/invoice-print';
import ContactUsView from './views/contact-us/view-contactus';
import ContactUsListView from './views/contact-us';
import CorporateTicketsListView from './views/corporate-ticket-management';




//CORPORATE MANAGEMENT
import CorporateManagement from './views/corporate-management';
//CORPORATE FAQS
import CorporateFaqListView from './views/corporatefaq-management/index';
import AddCorporateFaqView from './views/corporatefaq-management/add-corporatefaq';
import EditCorporateFaqView from './views/corporatefaq-management/edit-corporatefaq';


import CorporateNewsandEventsListView from './views/corporate-newsandevents-management/index';
import EditCorporateNWView from './views/corporate-newsandevents-management/edit-corporate-newsandevents';
import AddCorporateNWView from './views/corporate-newsandevents-management/add-corporate-newsandevents';
import PendingCorporateUserListView from './views/corporate-pending-user-management/pending-corporate-user-Approvel';
import CorporateCouponsList from './views/corporate-coupon-management';
import ApprovedCorporateUserListView from './views/corporate-approved-user-management';
import RejectedCorporateUserListView from './views/corporate-rejected-user-management';
import EditCorporateTicketView from './views/corporate-ticket-management/edit-corporateticket';
import AddDCorporateTicketView from './views/corporate-ticket-management/add-corporateticket';
import AddCorporateCouponView from './views/corporate-coupon-management/add-corporate-coupon';
import EditCorporateCouponView from './views/corporate-coupon-management/edit-corporate-coupon';
import CorporateCategoryListView from './views/corporate-category-management/category-management';
import AddCorporateCategoryList from './views/corporate-category-management/add-category-for-corp';
import CorporateCategoryDetailsView from './views/corporate-category-management-details';
import EditCorporateCategoryList from './views/corporate-category-management/edit-category-for-corp';
import CorporateProductListView from './views/corporate-product-management/product-management';
import EditCorporateProduct from './views/corporate-product-management/edit-product-for-corp';
import AddCorporateProduct from './views/corporate-product-management/add-product-for-corp';
import CorporateGiftingListView from './views/corporate-gifting-management';
import CorporateGiftingHistoryListView from './views/corporate-gifting-management/corporate-gifting-history';
import CorpProductsView from './views/view-corp-products';
import CorporateProductRequestListView from './views/corporate-product-management/product-request-management';
import EditProductRequestList from './views/corporate-product-management/product-request-management/edit-product-request';

// New Service Management
import NewProductList from './views/newservice-management';
import Servicessubcategory from './views/services-subcategories-management';
import ServicesnewsubView from './views/services-subcategories-management/view-subservice';
import NewBussinessvarient from './views/services-varientmanagement';
import AddServiceCategoryView from './views/service-categoriesmangement/add-servicecategory';
import NewServicerequest from './views/servicerequest-management';
import AddServiceRequests from './views/servicerequest-management/add-servicerequest';
import EditserviceRequests from './views/servicerequest-management/edit-servicerequest';
import ServiceBooking from './views/servicebookings/ServiceBookings';
import ServicesBookingDetails from './views/servicebookings/ServiceBookingsdetails';
import AddServiceSubCategoryView from './views/services-subcategories-management/add-servicesubcategory';
import AddServiceVarients from './views/services-varientmanagement/add-Servicevarientcategory';
import EditserviceVarientView from './views/services-varientmanagement/edit-servicevarient';
import ServicesnewvarientView from './views/services-varientmanagement/view-varientservice';
import EditserviceCategoryView from './views/service-categoriesmangement/edit-servicecategory';
import ServicescategoriesView from './views/service-categoriesmangement/view-Servicecategories';
import Servicecategories from './views/service-categoriesmangement';
import Servicepartenermangement from './views/service-partnermangement';
import ServicespartnermanagementView from './views/service-partnermangement/view-partnermanagement';
import AddServicepartnermanagement from './views/service-partnermangement/add-partnermangement';
import EditServicesPartnerManagement from './views/service-partnermangement/edit-servicepartnermanagement';
import EditsubserviceCategoryView from './views/services-subcategories-management/edit-subservicescatagory';
import ServicescategoryCity from './views/services-categories-cities';
import AddServiceCategoryCity from './views/services-categories-cities/add-cityservicecategory';
import EditserviceCategoryCity from './views/services-categories-cities/edit-cityservicecategory';
import ServicescategoriesCityView from './views/services-categories-cities/view-cityservicecategory';
import CorporateOrderListView from './views/corporate-order-management';
import CorporOrderDoorStepListView from './views/corporate-order-management/order-doorstep-management';
import CorpOrderInfoView from './views/corporate-order-management/order-info';
import AddProductPos from './views/product-management/product-pos/add-product-pos';
import POSOrderListView from './views/pos-order-management';
import Servicebanners from './views/service-banners';
import EditBannerViews from './views/service-banners/edit-servicebanners';
import AddBannerViews from './views/service-banners/add-servicebanners';
import NewInventoryListView from './views/new-inventory';
import CreateInventoryView from './views/modified-add-inventory';
import EditNewInventoryView from './views/edit-newinventory';
import NewReviewProductListView from './views/neworder-reviews-and-ratings';
import EditNewOrderStatusHistoryView from './views/neworder-history/neworder-lineitem-update';
import EditNewOrderStatusHistory from './views/neworder-history/neworder-lineitem-update/index'

//NEW ORDER MANAGEMNET
import NewOrderProductList from '../src/views/neworder-management/index';
import NewOrderListView from './views/neworder-history/index'
import NewTransactionHistoryListView from './views/neworder-transaction-history/index';
import NewPaymentsandSettlements from './views/neworder-payment-and-settlements/index';
import NewPartnerPaymentsandSettlements from './views/neworder-partner-payment-settlements/index';
import NewShippingListView from './views/neworder-shippings/index'
import NewUserPaymentSettlementsListView from './views/neworder-user-payments-settlements/index'
import CancelOrderListView from './views/neworder-history/indexnew'
import ReturnOrderListView from './views/neworder-history/indexshipped'
import NewTransactionHistoryDetailsView from './views/newtransaction-history-details/index'
import AddCorporateAwardView from './views/corporate-award-management/add-corporate-award';
import EditCorporateAwardView from './views/corporate-award-management/edit-corporate-award';
import CorporateAwardListView from './views/corporate-award-management';
import ViewCorporateAwardDetails from './views/corporate-award-management/view-corporate-award';
import CorporateUserManagementList from './views/corporate-user-management';

import OngoingOrderListView from './views/ongoing-order-history/index';
import CmpltdOrderListView from './views/completed-order-history/index';
import CancldOrderListView from './views/cancelled-order-history/index';
import ReturndOrderListView from './views/returned-order-history/index';

import EditInPrgsOrderStatusHistoryView from './views/ongoing-order-history/order-lineitem-update';
import EditCmpltdOrderStatusHistoryView from './views/completed-order-history/order-lineitem-update';
import EditCnclldOrderStatusHistoryView from './views/cancelled-order-history/order-lineitem-update';
import EditReturnOrderStatusHistoryView from './views/returned-order-history/order-lineitem-update';
import CorpViewOrderInvoice from './views/corporate-order-management/invoice-print';
import OrderList from './views/retail-orders';
import TransactionListView from './views/transactions';
import TransactionDetailsView from './views/transaction-details';
import PartnerCouponUtilizeList from './views/partner-CouponUtilize';
import PartnerCupnDetailsView from './views/partner-CouponDetails';
import CorpPopupListView from './views/corporate-popup-management';
import AddCorpPopupView from './views/corporate-popup-management/add-corporate-popup';
import EditCorpPopupView from './views/corporate-popup-management/edit-corporate-popup';
import OrderNewListView from './views/new-order-history';
import EditOrderStatusHistoryNewView from './views/new-order-history/order-lineitem-update';
import NewInventoryHistoryView from './views/new-inventory-history';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'products', element: <ProductListView /> },
      // { path: 'settings', element: <SettingsView /> },

      // reports
      { path: 'reports', element: <Reports /> },
      { path: 'reports/dashboard', element: <DashboardView /> },
      { path: 'reports/orders', element: <ReportOrders /> },
      { path: 'reports/users', element: <ReportUsers /> },
      { path: 'reports/partners', element: <ReportPartners /> },
      { path: 'reports/brands', element: <ReportBrands /> },
      { path: 'reports/coupons', element: <ReportCoupons /> },
      { path: 'reports/deals', element: <ReportDeals /> },
      { path: 'reports/products', element: <ReportProducts /> },

      //user rides
      { path: 'user-rides-maincentre', element: <UserRidesMainPage /> },
      { path: 'user-rides', element: <UserRidesListView /> },
      { path: 'edit-user-rides', element: <EditUserRidesView /> },
      { path: 'user-rides-view', element: <UserRideDetailsView /> },
      { path: 'add-user-ride', element: <AddUserRidesView /> },
      { path: 'new-user-rides', element: <UserRidesNewListView /> },
      { path: 'completed-user-rides', element: <UserRidesCompletedListView /> },
      { path: 'scheduled-user-rides', element: <UserRidesScheduledListView /> },
      { path: 'userpayment-user-rides', element: <RideUserPaymentView /> },
      { path: 'deliverypayment-user-rides', element: <RideDeliveryPaymentView /> },

      //user ride fares
      { path: 'user-ride-fares', element: <UserRideFaresListView /> },
      { path: 'edit-user-ride-fares', element: <EditUserRideFaresView /> },
      { path: 'add-user-ride-fares', element: <AddUserRideFaresView /> },
      { path: 'user-ride-fares-view', element: <UserRideFareDetailsView /> },

      //user ride fares
      { path: 'ride-vehicle-types', element: <VehicleTypesListView /> },
      { path: 'add-vehicle-type', element: <AddRideVehicleTypeView /> },
      { path: 'edit-vehicle-type', element: <EditRideVehicleTypeView /> },
      { path: 'view-vehicle-type', element: <RideVehicleTypeDetails /> },

      //permissions
      { path: 'permissions', element: <PermissionsListView /> },
      { path: 'add-permission', element: <AddPermissionView /> },
      { path: 'edit-permission', element: <EditPermissionView /> },
      { path: 'view-permission', element: <PermissionDetailsView /> },

      //services
      { path: 'services-maincentre', element: <ServicesMainPage /> },
      { path: 'services-management', element: <ServicesListView /> },
      { path: 'edit-service', element: <EditUserServicesView /> },
      { path: 'add-service', element: <AddUserServicesView /> },
      { path: 'view-services', element: <ServicesView /> },
      { path: 'services-management-new', element: <NewServicesListView /> },
      { path: 'services-management-in-process', element: <InProcessServicesListView /> },
      { path: 'services-management-completed', element: <CompletedServicesListView /> },
      { path: 'services-management-cancelled', element: <CancelledServicesListView /> },
      { path: 'services-user-payments', element: <ServicesUserPaymentView /> },
      { path: 'services-user-payments', element: <ServicesUserPaymentView /> },
      { path: 'services-partner-payments', element: <ServicesPartnerPaymentView /> },
      { path: 'services-completed-partner-payments', element: <ServicesCompletedPartnerPaymentView /> },
      { path: 'services-cancelled-user-payments', element: <ServicesCancelledUserPaymentView /> },
      //Partner Service
      { path: 'partnerservices-management', element: <PartnerServicesListView /> },
      { path: 'edit-partnerservice', element: <EditPartnerServicesView /> },
      { path: 'add-partnerservice', element: <AddPartnerServicesView /> },
      { path: 'view-partnerservices', element: <PartnerServicesView /> },
      //Generic Service
      { path: 'genericservices-management', element: <ServicesGenericListView /> },
      { path: 'edit-genericservice', element: <EditGenericUserServiceView /> },
      { path: 'add-genericservice', element: <AddGenericUserServiceView /> },
      { path: 'view-genericservices', element: <GenericServicesView /> },
      // global coupon main centre
      { path: 'globalcoupon-main-centre', element: <GlobalCouponMainPage /> },
      { path: 'global-coupon-management', element: <GlobalCouponListView /> },
      { path: 'add-global-coupon', element: <AddGlobalCouponView /> },
      { path: 'update-global-coupon', element: <UpdateGlobalCouponView /> },
      { path: 'view-global-coupon', element: <GlobalCouponView /> },
      { path: 'global-coupon-history', element: <GlobalCouponHistoryListView /> },
      { path: 'view-global-coupon-history', element: <GlobalCouponHistoryView /> },
      // esi main centre
      { path: 'store-type-management', element: <StoreList /> },
      { path: 'esi-main-centre', element: <ESIMainPage /> },
      { path: 'category-management', element: <CategoryManagement /> },
      { path: 'store-type', element: <StoreListView /> },
      { path: 'store-type-linking', element: <StoreLinkingView /> },
      { path: 'store-type-partner-linking', element: <StorePartnerLinkingView /> },
      { path: 'store-type-add-storetype', element: <AddStoreTypeView /> },
      { path: 'store-type-linking-add-storetype-linking', element: <AddStoreTypeLinkView /> },
      { path: 'storetype-details', element: <StoretypeDetailsView /> },
      { path: 'storetype-linking-details', element: <StoretypeLinkDetailsView /> },
      { path: 'store-type-edit-storetype', element: <EditStoreTypeView /> },
      { path: 'store-type-linking-edit-storetype-linking', element: <EditStoreTypeLinkView /> },
      { path: 'add-category', element: <AddCategoryView /> },
      { path: 'add-subCategory', element: <AddSubCategoryView /> },
      { path: 'brand-management', element: <BrandListView /> },
      { path: 'category-details', element: <CategoryDetailsView /> },
      { path: 'brand-details', element: <BrandDetailsView /> },
      { path: 'deals-details', element: <DealsDetailsView /> },
      { path: 'add-brand', element: <AddBrandView /> },
      { path: 'partner-management', element: <PartnerManagement /> },
      { path: 'product-management/product-masters', element: <ProductMasters /> },

      //for prod pos
      { path: 'product-management/product-pos', element: <AddProductPos /> },

      { path: 'product-management/product-color-variants', element: <ProductColorVariants /> },
      { path: 'product-management/product-sizes', element: <ProductSizes /> },
      { path: 'coupon-management', element: <CouponManagement /> },
      { path: 'add-coupon', element: <AddCouponView /> },
      { path: 'deal-management', element: <DealManagement /> },
      { path: 'admin-user-management', element: <UserListView /> },
      { path: 'shipping', element: < ShippingListView /> },
      // { path: 'view-shipping', element: <ShippingView /> },
      { path: 'add-newsevents', element: <AddNewseventsView /> },
      { path: 'edit-newsevents', element: <EditNewseventsView /> },
      { path: 'News-and-Events', element: < NewseventsListView /> },
      { path: 'returned-shippings', element: <ReturnshippingListView /> },
      { path: 'view-returnedshippings', element: <ReturnedshippingsView /> },
      { path: 'cancelled-shippings', element: <CancelledshippingListView /> },
      { path: 'view-cancelledshippings', element: <CancelledshippingsView /> },
      { path: 'user-profile-management', element: <UsersManagement /> },
      { path: 'wallet-management', element: <WalletManagement /> },
      { path: 'banner-management', element: <BannerManagement /> },
      { path: 'product-management/products', element: <Products /> },
      { path: 'delivery-management', element: <DeliveryManagement /> },
      { path: 'add-product-master', element: <AddProductMasterView /> },
      { path: 'edit-product-master', element: <EditProductMasterView /> },
      { path: 'add-product-size', element: <AddProductSizeView /> },
      { path: 'edit-product-size', element: <EditProductSizeView /> },
      { path: 'add-product-color-variant', element: <AddProductColorVariantView /> },
      { path: 'edit-product-color-variant', element: <EditProductColorVariantView /> },
      { path: 'product-management', element: <ProductManagement /> },
      { path: 'view-products', element: <ProductsView /> },
      { path: 'add-user', element: <AddUserView /> },
      { path: 'edit-user', element: <EditUserView /> },
      { path: 'newsletter', element: < NewsletterListView /> },
      { path: 'add-newsletter', element: <AddNewsletterView /> },
      { path: 'edit-newsletter', element: <EditNewsletterView /> },
      { path: 'edit-missingcashback', element: <EditMissingCashbackView /> },
      { path: 'view-missingcashback', element: <MissingcashbackView /> },
      { path: 'add-partner', element: <AddPartnerView /> },
      { path: 'add-deal', element: <AddDealView /> },
      { path: 'add-product', element: <AddProductView /> },
      { path: 'add-banner', element: <AddBrannerView /> },
      { path: 'add-user-profile', element: <AddUserProfileView /> },
      { path: 'view-user-profile', element: <UserProfilesManagementView /> },
      { path: 'view-user-managment', element: <UsersManagementView /> },
      { path: 'add-wallet', element: <AddWalletView /> },
      { path: 'add-wallet-history', element: <AddWalletHistoryView /> },
      { path: 'edit-coupon', element: <EditCouponView /> },
      { path: 'edit-Content-Management', element: <EditContentManagementView /> },
      { path: 'add-Content-Management', element: <AddContentManagementView /> },
      { path: 'view-contentmanagement', element: <ContentManagementView /> },
      { path: 'contents-management', element: <PrivacyPolicyListView /> },
      { path: 'edit-transaction', element: <EditTransactionView /> },
      { path: 'edit-brand', element: <EditBrandView /> },
      { path: 'edit-user-profile', element: <EditUserProfileView /> },
      { path: 'edit-wallet', element: <EditWalletView /> },
      { path: 'edit-partner', element: <EditPartnerView /> },
      { path: 'edit-category', element: <EditCategoryView /> },
      { path: 'edit-deal', element: <EditDealView /> },
      { path: 'edit-banner', element: <EditBannerView /> },
      { path: 'edit-product', element: <EditProductView /> },
      { path: 'edit-ticket', element: <EditTicketView /> },
      { path: 'edit-deliveryticket', element: <EditdeliveryTicketView /> },
      { path: 'edit-subcategory', element: <EditSubCategoryView /> },
      { path: 'subcategory', element: <SubCategoryListView /> },
      { path: 'product/partner-management', element: <ProductPartnerView /> },
      { path: 'add-productpartner', element: <AddProductPartnerView /> },
      { path: 'edit-productpartner', element: <EditProductPartnerView /> },
      { path: 'pending-product-partners', element: <PartnerApprovalListView /> },
      { path: 'view-partner-product', element: <PartnerProdcutDetails /> },
      { path: 'edit-pending-productpartner', element: <EditPendingProductPartnerView /> },
      { path: 'inventory', element: <Inventory /> },
      { path: 'add-inventory', element: <AddInventoryView /> },
      { path: 'edit-coupon', element: <EditCouponView /> },
      { path: 'edit-inventory', element: <EditInventoryView /> },
      { path: 'inventory-history', element: <InventoryHistoryView /> },
      { path: 'new-inventory-history', element: <NewInventoryHistoryView /> },

      { path: 'add-email-template', element: <AddEmailTemplateView /> },
      { path: 'edit-email-template', element: <EditEmailTemplateView /> },
      { path: 'email-template', element: <EmailTemplateListView /> },
      { path: 'view-emailtemplate', element: <EmailTemplateView /> },
      { path: 'gift-management', element: <GiftManagement /> },
      { path: 'add-giftvendor', element: <AddGiftvendorView /> },
      { path: 'edit-giftvendor', element: <EditGiftvendorView /> },
      { path: 'view-giftvendor', element: <GiftVendorList /> },
      { path: 'add-giftimage', element: <AddGiftimageView /> },
      { path: 'edit-giftimage', element: <EditGiftimageView /> },
      { path: 'view-partner-deliveryagent', element: <PartnerDeliveryAgentView /> },
      { path: 'partner-deliveryagent-management', element: <PartnerDeliveryAgentListView /> },
      { path: 'add-partner-deliveryagent', element: <AddPartnerDeliverypersonDetailsView /> },
      { path: 'edit-partner-deliveryagent', element: <EditPartnerDeliveryagentDetailsView /> },
      { path: 'partner-employee-management', element: <PartnerEmployeeListView /> },
      { path: 'add-partner-employee', element: <AddPartnerEmployeeDetailsView /> },
      { path: 'edit-partner-employee', element: <EditPartnerEmployeeDetailsView /> },
      { path: 'view-partner-employee', element: <PartnerEmployeeView /> },
      // esi main centre end
      { path: 'user-financial-transactions', element: <UserFinancialTransactionsListView /> },
      { path: 'view-user-financial-transaction', element: <UserFinancialTransactionsView /> },
      { path: 'edit-user-financial-transaction', element: <EditUserFinancialTransactionView /> },
      //user main centre
      { path: 'user-main-centre', element: <UserMainPage /> },
      // order management
      { path: 'add-product-review', element: <AddProductReviewView /> },
      { path: 'add-partner-review', element: <AddPartnerReviewView /> },
      { path: 'add-brand-review', element: <AddBrandReviewView /> },
      { path: 'edit-product-review', element: <EditProductReview /> },
      { path: 'edit-brand-review', element: <EditBrandReview /> },
      { path: 'edit-partner-review', element: <EditPartnerReview /> },
      { path: 'product/user-reviews', element: <ProductUserReviewsListView /> },
      { path: 'partner/user-reviews', element: <PartnerUserReviewsListView /> },
      { path: 'brand/user-reviews', element: <BrandUserReviewsListView /> },
      { path: 'product/reviews-and-ratings', element: <ProductReviewsAndRatingsListView /> },
      { path: 'brand/reviews-and-ratings', element: <BrandReviewListView /> },
      { path: 'partner/reviews-and-ratings', element: <PartnerReviewListView /> },
      { path: 'add-ads', element: <AddAdsView /> },
      { path: 'edit-ads', element: <EditAdsView /> },
      { path: 'product/related-products-management', element: <RelatedProductView /> },
      { path: 'user-gifts/user-gift-history-details', element: <UserGiftHistoryView /> },
      { path: 'add-relatedproducts', element: <AddRelatedProductView /> },
      { path: 'edit-relatedproducts', element: <EditRelatedProductView /> },
      { path: 'delivery-content-management', element: <ContentListView /> },
      { path: 'add-Cms-Page', element: <AddContentView /> },
      { path: 'edit-Cms-Page', element: <EditContentView /> },
      { path: 'ads-management', element: <AdsListView /> },
      { path: 'gift-vendors', element: <GiftvendorListView /> },
      { path: 'gift-images', element: <GiftimageListView /> },
      { path: 'edit-gift', element: <EditGiftView /> },
      { path: 'user-gifts', element: <GiftListView /> },
      { path: 'view-usergifthistory', element: <UserGiftView /> },
      { path: 'partneraddress-view', element: <PartneraddressView /> },
      { path: 'partneraddress-management', element: <PartneraddressListView /> },
      { path: 'add-partneraddress', element: <AddPartneraddressView /> },
      { path: 'edit-partneraddress', element: <EditPartneraddressView /> },
      { path: 'order-management', element: <OrderManagement /> },
      { path: 'order-history', element: <OrderListView /> },
      { path: 'order-invoice-view', element: <ViewOrderInvoice /> },
      { path: 'missing-cashback', element: <MissingCashbackListView /> },
      { path: 'offers-visitor', element: <OffersVisitListView /> },
      { path: 'edit-offersvisitor', element: <EditOffersVisitorView /> },
      { path: 'refer-earn', element: <ReferandEarnListView /> },
      { path: 'payment-and-settlements', element: <PaymentAndSettlements /> },
      { path: 'ticketing-management', element: <TicketingManagementListView /> },
      { path: 'delivery-tickets', element: <DeliveryTicketsListView /> },
      { path: 'ticketing-management-comments', element: <CommentsListView /> },
      { path: 'delivery-ticket-comments', element: <DeliveryCommentsListView /> },
      { path: 'add-comment', element: <AddCommentView /> },
      { path: 'add-deliverycomment', element: <AdddeliveryCommentView /> },
      { path: 'edit-comment', element: < EditCommentView /> },
      { path: 'edit-deliverycomment', element: < EditdeliveryCommentView /> },
      { path: 'transaction-history', element: <TransactionHistoryListView /> },
      { path: 'reviews-and-ratings', element: <ReviewsAndRatingsListView /> },
      { path: 'partner-payment-settlements', element: <PartnerPaymentSettlementsListView /> },
      { path: 'user-payment-settlements', element: <UserPaymentSettlementsListView /> },
      { path: 'user-reviews', element: <UserReviewsListView /> },
      { path: 'transaction-history-details', element: <TransactionHistoryDetailsView /> },
      { path: 'wallet-history', element: <WalletHistoryView /> },
      { path: 'view-wallet-history', element: <WalletHistoryDetails /> },
      { path: 'refer-earn-history', element: <ReferandEarnHistoryView /> },
      { path: 'user-payment-settlements-details', element: <UserPaymentSettlementsDetailsView /> },
      { path: 'partner-payment-settlements-details', element: <PartnerPaymentSettlementsDetailsView /> },
      { path: 'partner-payment-settlements-edit', element: <PartnerPaymentSettlementsEditView /> },
      { path: 'user-payment-settlements-edit', element: <UserPaymentSettlementsEditView /> },
      { path: 'add-ticket', element: <AddTicketView /> },
      { path: 'add-faq', element: <AddFAQView /> },
      { path: 'edit-faq', element: <EditFAQView /> },
      { path: 'faq-management', element: <FaqManagement /> },
      { path: 'edit-order-status-history', element: <EditOrderStatusHistoryView /> },
      { path: 'order-address-view', element: <ViewOrderAddress /> },
      //B2B order management
      { path: 'b2b-order-history', element: <B2BOrderListView /> },
      { path: 'b2b-order-update', element: <EditB2BOrderHistoryView /> },
      { path: 'view-b2bordernotification', element: <Orderb2bnotificationView /> },
      // delivery faq
      { path: 'add-deliveryfaq', element: <AddDeliveryView /> },
      { path: 'edit-deliveryfaq', element: <EditdeliveryFaqView /> },
      { path: 'deliveryfaq-management', element: <DeliveryFaqListView /> },
      // order management end
      { path: 'approved-delivery-agent-view', element: <DeliveryAgentManagement /> },
      { path: 'promotion-management', element: <PromotionManagement /> },
      { path: 'view-approved-delivery-agent-details', element: <DeliveryAgentsListView /> },
      { path: 'pending-delivery-agents-view', element: <PendingDeliveryAgentListView /> },
      { path: 'edit-pending-delivery-agent-details', element: <EditPendingDeliverypersonDetailsView /> },
      { path: 'edit-approved-delivery-agent-details', element: <EditDeliverypersonDetails /> },
      { path: 'view-pending-delivery-agent-details', element: <PendingDeliveryAgentsListView /> },
      { path: 'add-delivery-agent-details', element: <AddDeliverypersonDetailsView /> },
      //content management
      { path: 'popup-management', element: <PopupListView /> },
      { path: 'add-popup', element: <AddPopupView /> },
      { path: 'edit-popup', element: <EditPopupView /> },
      { path: 'content-management', element: <ContentManagement /> },
      { path: 'add-promotional', element: <AddPromotionalView /> },
      { path: 'edit-promotional', element: <EditPromotionalView /> },
      { path: 'promotional-management', element: <PromotionalManagement /> },
      //content management end
      //settings
      { path: 'add-setting', element: <AddSettingView /> },
      { path: 'edit-setting', element: <EditSettingView /> },
      { path: 'setting', element: <SettingListView /> },
      { path: 'settings-management', element: <SettingsManagement /> },
      { path: 'view-settingsmanagement', element: <SettingsManagementView /> },

      // shop by city management
      { path: 'shop-by-city-management', element: < ShopByCityManagement /> },
      { path: 'sbcm/city-management', element: < CitysListView /> },
      { path: 'sbcm/add-city', element: < AddCityView /> },
      { path: 'sbcm/edit-city', element: < EditCityView /> },
      { path: 'view-citydetails', element: < CityView /> },
      { path: 'view-partnercitydeal', element: <CityDealDetails /> },
      { path: 'view-partnercitycoupon', element: <CityCouponDetails /> },
      { path: 'view-partnercityproduct', element: <CityProductDetails /> },
      { path: 'sbcm/city-partner-management', element: <CitypartnerListView /> },
      { path: 'sbcm/add-partner', element: <AddCitypartnerView /> },
      { path: 'sbcm/edit-partner', element: <EditCitypartnerView /> },
      { path: 'view-partnerservicecity', element: <PartnerServiceCityView /> },
      { path: 'sbcm/city-coupon-management', element: <CitypartnercouponListView /> },
      { path: 'sbcm/add-coupon', element: <AddCitypartnercouponView /> },
      { path: 'sbcm/edit-coupon', element: <EditCitypartnercouponView /> },
      { path: 'sbcm/city-deal-management', element: <CitypartnerdealListView /> },
      { path: 'sbcm/add-deal', element: <AddCitypartnerdealView /> },
      { path: 'sbcm/edit-deal', element: <EditCitypartnerdealView /> },
      { path: 'sbcm/product-management', element: <CityProductListView /> },
      { path: 'view-city-partner-location', element: <PartnerCityLocationDetails /> },
      { path: 'sbcm/add-product', element: <AddCityProductView /> },
      { path: 'sbcm/edit-product', element: <EditCityProductView /> },
      { path: 'city-location', element: < CityLocation /> },
      { path: 'add-city-location', element: < AddCityLocationView /> },
      { path: 'edit-city-location', element: < EditCityLocationView /> },
      { path: 'view-city-location', element: < CityLocationList /> },
      { path: 'partner-city-location', element: < PartnerCityLocationListView /> },
      { path: 'add-city-partner-location', element: < AddCitypartnerlocationView /> },
      { path: 'edit-city-partner-location', element: < EditCitypartnerlocationView /> },
      { path: 'view-dealapproval', element: <DealApprovalView /> },
      { path: 'pending-deals', element: <DealsApproval /> },
      { path: 'pending-coupons', element: <CouponsApproval /> },
      { path: 'pending-partners', element: <PartnersApproval /> },
      { path: 'view-couponapproval', element: <CouponApprovalView /> },
      { path: 'view-partnerapproval', element: <PartnerApprovalView /> },
      { path: 'pending-products', element: <ProductsApproval /> },
      { path: 'view-productapproval', element: <ProductApprovalView /> },
      { path: 'testimonial-history-details', element: <TestimonialHistoryDetailsView /> },
      { path: 'edit-testimonial', element: <EditTestimonialView /> },
      { path: 'testimonial-management', element: <TestimonialsListView /> },
      { path: 'edit-refer-earn', element: <EditReferandEarnView /> },
      //settings end
      { path: 'view-ordernotification', element: <OrdernotificationView /> },
      //delivery management
      { path: 'delivery/dashboard', element: <DeliveryDashboard /> },
      { path: 'delivery/reports', element: <DeliveryReports /> },
      { path: 'delivery/reports/total-orders', element: <TotalOrderReports /> },
      { path: 'delivery/reports/delivered-orders', element: <DeliveredOrdersReport /> },
      { path: 'delivery/reports/outfordelivery-orders', element: <OutfordeliveryOrdersReport /> },
      { path: 'delivery/reports/returned-orders', element: <ReturnedOrdersReport /> },
      { path: 'delivery/reports/cancelled-orders', element: <CancelledOrdersReport /> },
      { path: 'corporate-management', element: <CorporateManagement /> },
      { path: 'delivery-wallet-management', element: <DeliveryWalletListView /> },
      { path: 'add-delivery-wallet', element: <AddDeliveryWalletView /> },
      { path: 'edit-delivery-wallet', element: <EditDeliveryWalletView /> },
      { path: 'delivery-wallet-history', element: <DeliveryWalletHistoryListView /> },
      { path: 'add-delivery-wallethistory', element: <AddDeliveryWalletHistoryView /> },
      { path: 'edit-delivery-wallethistory', element: <EditDeliveryWalletHistoryView /> },
      { path: 'view-delivery-wallethistory', element: <DeliveryWalletHistoryView /> },
      { path: 'view-delivery-wallet', element: <DeliveryWalletView /> },
      { path: 'view-partner-wallet', element: <PartnerWalletView /> },
      { path: 'view-wallet', element: <WalletView /> },
      //Delivery payment and settlements
      { path: 'delivery-payment-settlements', element: <DeliveryPaymentSettlementsListView /> },
      { path: 'delivery-payment-settlements-details', element: <DeliveryPaymentSettlementsDetailsView /> },
      { path: 'delivery-payment-settlements-edit', element: <DeliveryPaymentSettlementsEditView /> },
      // corporate faq
      { path: 'add-corporatefaq', element: <AddCorporateFaqView /> },
      { path: 'edit-corporatefaq', element: <EditCorporateFaqView /> },
      { path: 'corporatefaq-management', element: <CorporateFaqListView /> },
      //corporate CMS Page  
      { path: 'corporate-content-management', element: <ContentListcorporateView /> },
      { path: 'add-CMS-corporate', element: <AddContentcorporateView /> },
      { path: 'edit-CMS-corporate', element: <EditContentcorporateView /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'mycompanyprofile', element: <CompanySettings /> },
      //Company Profile
      { path: 'company-profile-management', element: <CompanyProfile /> },
      { path: 'role-management', element: <RoleManagement /> },
      { path: 'add-role', element: <AddRoleView /> },
      { path: 'edit-role', element: <EditRoleView /> },
      { path: 'view-role', element: <RoleView /> },

      //Employee users
      { path: 'admin-profile-users', element: <EmployeeListView /> },
      { path: 'admin-profile-users/add-profile-users', element: <AddProfileUserView /> },
      { path: 'admin-profile-users/edit-profile-users', element: <EditProfileUserView /> },
      { path: 'admin-profile-users/view-adminprofile-users', element: <AdminEmployeeView /> },

      //Partner bank details
      { path: 'partnerbank-details', element: <PartnerbankdetailsView /> },
      //Subcategory details
      { path: 'subcategory-details', element: <SubcategorydetailsView /> },
      //coupon details
      { path: 'couponview-details', element: <CoupondetailsView /> },
      //Partner Tickets
      { path: 'partner-ticketing-management', element: <PartnerTicketsListView /> },
      { path: 'add-partnerticket', element: <AddPartnerTicketView /> },
      { path: 'edit-partnerticket', element: <EditPartnerTicketView /> },
      { path: 'partner-ticketing-management-view', element: <PartnerTicketDetailsView /> },
      { path: 'user-comments-details', element: <CommentDetailsView /> },
      { path: 'ticketing-management-view', element: <TicketDetailsView /> },
      { path: 'partner-comments-details', element: <PartnerCommentDetailsView /> },
      { path: 'edit-partnercomment', element: <EditPartnerCommentView /> },
      { path: 'partner-ticketing-management-comments', element: <PartnerCommentsListView /> },
      { path: 'add-partnercomment', element: <AddPartnerCommentView /> },
      { path: 'delivery-ticketing-management-view', element: <DeliveryTicketDetailsView /> },
      { path: 'delivery-comments-details', element: <DeliveryCommentDetailsView /> },
      { path: 'add-deliveryticket', element: <AddDeliveryTicketView /> },
      // store type partner linking
      { path: 'partner-ticketing-management', element: <PartnerTicketsListView /> },
      { path: 'add-storetype-partner-linking', element: <AddStoreTypePartnerLinkView /> },
      { path: 'edit-storetype-partner-linking', element: <EditStoreTypepartnerLinkView /> },
      { path: 'storetype-linking-partnerdetails', element: <StoretypeLinkPartnerDetailsView /> },
      //Partner Wallet
      { path: 'partnerwallet-management', element: <PartnerWalletListView /> },
      { path: 'edit-partnerwallet', element: <EditPartnerWalletView /> },
      { path: 'add-partnerwallet', element: <AddPartnerWalletView /> },
      { path: 'partnerwallethistory-management', element: <PartnerWalletHistoryView /> },
      { path: 'add-partnerwallethistory', element: <AddPartnerWalletHistoryView /> },
      { path: 'edit-partnerwallethistory', element: <EditPartnerWalletHistoryView /> },
      { path: 'view-partnerwallethistory', element: <PartnerWalletHistoryList /> },
      //Partner Main Page
      { path: 'partner-main-page', element: < PartnerMainCenter /> },
      { path: 'partner-management-history', element: < PartnerHistoryView /> },

      //vehicle details
      { path: 'delivery-vehicle-details', element: <DeliveryVehicleListView /> },
      { path: 'add-deliveryvehicledetails', element: <AddDeliveryVehicleView /> },
      { path: 'edit-deliveryvehicledetails', element: <EditDeliveryVehicleView /> },
      { path: 'view-vehicledetails', element: <VehicleDetailsView /> },
      //Bussiness SubTypes
      { path: 'bussiness-subtype', element: <BussinessSubtypeView /> },
      { path: 'add-bussinessSubtype', element: <AddBussinessView /> },
      { path: 'edit-bussinessSubtype', element: <EditBussinessView /> },
      { path: 'view-bussinessSubtype', element: <BussinessSubtypeList /> },
      //Razorpay
      { path: 'razorpaypayments-maincentre', element: <RazorpayMainPage /> },
      { path: 'razorpaypayments-management', element: <RazorpayView /> },
      { path: 'add-razorpaypayments', element: <AddRazorpayView /> },
      { path: 'add-razorpayhistory', element: <AddRazorpayhistoryView /> },
      { path: 'razorpay-history', element: <RazorpayTransactionView /> },
      { path: 'view-razorpayhistory', element: <RazorpayhistoryView /> },
      { path: 'view-razorpaydetails', element: <RazorpaydetailsView /> },
      { path: 'razorpaytransaction-management', element: <RazorpayTransactionsView /> },
      { path: 'view-razorpaytransactiondetails', element: <RazorpayTransactionDetailsView /> },
      //Partner vehicle details
      { path: 'partner-vehicle-details', element: <PartnerVehicleListView /> },
      { path: 'add-partnervehicledetails', element: <AddPartnerVehicleView /> },
      { path: 'edit-partnervehicledetails', element: <EditPartnerVehicleView /> },
      { path: 'view-partnervehicledetails', element: <VehiclePartnerDetails /> },
      //Subcription User
      { path: 'subscription-management', element: <SubcriptionManagement /> },
      { path: 'subscription-user-management', element: <SubscriptionUsersListView /> },
      { path: 'add-subscriptionuser', element: <AddSubscriptionUserView /> },
      { path: 'edit-subscriptionuser', element: <EditUserSubscriptionView /> },
      { path: 'view-subscriptionuser', element: <UserSubscriptionDetailsView /> },
      //Subcription product User
      { path: 'subscription-userproduct-management', element: <SubscriptionProductsUsersListView /> },//Subcription
      { path: 'subscription-management', element: <SubcriptionManagement /> },
      { path: 'razorpaypayments-management', element: <RazorpayView /> },
      //Subcription Packages
      { path: 'subscription-packages', element: <SubsPackagesListView /> },
      { path: 'add-subscription-package', element: <AddSubscriptionPackageView /> },
      { path: 'edit-subscription-package', element: <EditSubscriptionPackageView /> },
      { path: 'view-subscription-package', element: <SubscriptionPackageView /> },
      //Subcription Package Locations
      { path: 'subscription-package-locations', element: <SubsPackLocationsListView /> },
      //Bidding Main page
      { path: 'bidding-management', element: <BiddingMainPage /> },
      //bidding rides
      { path: 'bidding-rides', element: <BiddingRidesMainPage /> },
      //user ride biddings
      { path: 'user-ride-biddings', element: <UserRideBiddingsListView /> },
      { path: 'view-user-ride-bidding', element: <UserRideBiddingDetailsView /> },
      { path: 'add-user-ride-bidding', element: <AddUserRideBiddingView /> },
      { path: 'edit-user-ride-bidding', element: <EditUserRideBiddingView /> },
      { path: 'user-ride-bid-delivery-payments', element: <UserRideDeliveryPaymentView /> },
      //bidding vehicles
      { path: 'bidding-vehicles', element: <BiddingVehiclesListView /> },
      { path: 'bidding-vehicles-view', element: <ViewBiddingVehicle /> },
      { path: 'add-bidding-vehicle', element: <AddBiddingVehicleView /> },
      { path: 'edit-bidding-vehicle', element: <EditBiddingVehicleView /> },
      //bidding vehicle Brands
      { path: 'bidding-ride-vehicle-brands', element: <BiddingVehicleBrandsListView /> },
      { path: 'bidding-vehicle-brand-view', element: <ViewBiddingVehicleBrand /> },
      { path: 'add-bidding-vehicle-brand', element: <AddBidVehicleBrandView /> },
      { path: 'edit-bidding-vehicle-brand', element: <EditBidVehicleBrandView /> },
      //bidding Brand vehicles
      { path: 'bidding-ride-brand-vehicles', element: <BiddingBrandVehiclesListView /> },
      { path: 'view-bidding-ride-brand-vehicle', element: <ViewBiddingBrandVehicle /> },
      { path: 'add-bidding-ride-brand-vehicle', element: <AddBidRideBrandVehicleView /> },
      { path: 'edit-bidding-ride-brand-vehicle', element: <EditBidRideBrandVehicleView /> },
      //delivery bidding Quotes
      { path: 'delivery-bidding-quotes', element: <DeliveryBiddingQuotesListView /> },
      { path: 'view-delivery-bidding-quote', element: <ViewDeliveryBiddingQuotes /> },
      { path: 'add-delivery-bidding-quote', element: <AddDeliveryBiddingQuoteView /> },
      { path: 'edit-delivery-bidding-quote', element: <EditDeliveryBiddingQuoteView /> },
      //delivery bidding Comments
      { path: 'delivery-bidding-comments', element: <DeliveryBiddingCommentsListView /> },
      { path: 'update-delivery-bidding-comments', element: <UpdateDeliveryBiddingComments /> },
      //bidding ride success stories
      { path: 'delivery-bidding-success-stories', element: <BidSuccessStoryListView /> },
      { path: 'view-delivery-bidding-success-story', element: <ViewDeliveryBidSuccessStory /> },
      { path: 'add-delivery-bidding-success-story', element: <AddDeliveryBidSuccessStoryView /> },
      { path: 'edit-delivery-bidding-success-story', element: <EditDeliveryBidSuccessStoryView /> },
      //delivery bidding vehicle commission
      { path: 'delivery-bidding-vehicle-commission', element: <DeliBidVehicleCommissionListView /> },
      { path: 'add-delivery-bidding-vehicle-commission', element: <AddDeliBidVehicleCommissionView /> },
      { path: 'edit-delivery-bidding-vehicle-commission', element: <EditDeliBidVehicleCommissionView /> },
      { path: 'view-delivery-bidding-vehicle-commission', element: <ViewDeliBidVehicleCommission /> },
      //bidding products
      { path: 'bidding-products', element: <BiddingProductsMainPage /> },
      //partner bidding available products
      { path: 'partner-bidding-available-products', element: <PartnerBidAvailProductsListView /> },
      { path: 'partner-bidding-available-products-view', element: <ViewPartnerBidAvailProducts /> },
      { path: 'add-partner-bidding-available-product', element: <AddPartnerBidAvailProductView /> },
      { path: 'edit-partner-bidding-available-product', element: <EditPartnerBidAvailProductView /> },
      //partner bidding Quotes
      { path: 'partner-bidding-quotes', element: <PartnerBiddingQuotesListView /> },
      { path: 'partner-bidding-Quote-view', element: <ViewPartnerBiddingQuotesView /> },
      { path: 'add-partner-bidding-Quote', element: <AddPartnerBiddingQuoteView /> },
      { path: 'edit-partner-bidding-Quote', element: <EditPartnerBiddingQuoteView /> },
      //partner bidding Comments
      { path: 'partner-bidding-comments', element: <PartnerBiddingCommentsListView /> },
      { path: 'update-partner-bidding-comments', element: <UpdatePartnerBiddingComments /> },
      //b2b bidding products
      { path: 'b2b-bidding-products', element: <B2BBiddingProductsMainPage /> },
      //b2b user product biddings
      { path: 'b2b-user-products-bidding', element: <B2BUserProductsBiddingListView /> },
      { path: 'view-b2b-user-products-bidding', element: <B2BUserProductsBiddingDetailsView /> },
      { path: 'add-b2b-user-products-bidding', element: <AddB2BUserProductsBiddingView /> },
      { path: 'edit-b2b-user-products-bidding', element: <EditB2BUserProductsBiddingView /> },
      { path: 'b2b-prod-bid-partner-payments', element: <B2BBidProdPartnerPaymentView /> },
      //B2B User Products bidding Quotes
      { path: 'b2b-user-product-bidding-quotes', element: <B2BUserProdBidQuotesListView /> },
      { path: 'view-b2b-user-product-bidding-quote', element: <ViewB2BUserProdBidQuotes /> },
      { path: 'add-b2b-user-product-bidding-quote', element: <AddB2BUserProdBiddingQuoteView /> },
      { path: 'edit-b2b-user-product-bidding-quote', element: <EditB2BUserProdBiddingQuoteView /> },
      //B2B User Products bidding Comments
      { path: 'b2b-user-product-bidding-comments', element: <B2BUserProdBidCommentsListView /> },
      { path: 'update-b2b-user-product-bidding-comments', element: <UpdateB2BUserProdBidComments /> },
      //user bidding products
      { path: 'user-bidding-products', element: <BiddingUserProductsListView /> },
      { path: 'add-user-bidding-products', element: <AddBiddingUserProductsView /> },
      { path: 'edit-user-bidding-products', element: <EditBiddingUserProductsView /> },
      { path: 'view-user-bidding-products', element: <ViewBiddingUserProductsList /> },
      { path: 'bidding-products-partner-payments', element: <BiddingProductsPartnerPaymentsView /> },
      //bidding products Success Stories
      { path: 'bidding-products-success-stories', element: <BiddingProductsSuccessStoriesListView /> },
      { path: 'add-products-bidding-success-story', element: <AddBidProductSuccessStoryView /> },
      { path: 'view-products-bidding-success-story', element: <ViewBiddingUserProductsSuccessStoryList /> },
      { path: 'edit-products-bidding-success-story', element: <EditBidProductSuccessStoryView /> },
      //Bidding Products Category
      { path: 'add-bidding-products-category', element: <AddBiddingProductsCategoryView /> },
      { path: 'edit-bidding-products-category', element: <EditBiddingProductsCategoryView /> },
      { path: 'bidding-category', element: <BiddingProductsCategoryListView /> },
      { path: 'view-bidding-products-category', element: < ViewProductsCategory /> },
      //Bidding Products
      { path: 'bidding-product', element: <BiddingProductsListView /> },
      { path: 'add-bidding-products', element: <AddBiddingProductsView /> },
      { path: 'edit-bidding-products', element: <EditBiddingProductsView /> },
      { path: 'view-bidding-products', element: < ViewBiddingProducts /> },

      //bidding rides
      { path: 'bidding-services', element: <BiddingServicesMainPage /> },


      //Bidding Services
      { path: 'services-bidding', element: <BiddingServicesListView /> },
      { path: 'add-biddingservices', element: <AddBiddingServicesView /> },
      { path: 'edit-biddingservices', element: <EditBiddingServicesView /> },
      { path: 'view-biddingservices', element: <ViewBiddingServicesList /> },

      //Bidding Services Success Stories
      { path: 'bidding-services-success-stories', element: <BiddingServicesSuccessStoryListView /> },
      { path: 'add-biddingservicessuccessstories', element: <AddBidServiceSuccessStoryView /> },
      { path: 'edit-biddingservicessuccessstories', element: <EditBidServiceSuccessStoryView /> },
      { path: 'view-biddingservicessuccessstories', element: <ViewBiddingUserServicesSuccessStoryList /> },
      // {path: 'bidding-products-partner-payments', element: <BiddingProductsPartnerPaymentsView/> },


      //Bidding partner avail Services
      { path: 'bidding-partner-avail-services', element: <PartnerBidAvailServicesListView /> },
      { path: 'add-partner-bidding-available-services', element: <AddBiddingPartnerAvailServicesView /> },
      { path: 'edit-partner-bidding-available-services', element: <EditBiddingPartnerAvailServicesView /> },
      { path: 'view-partner-bidding-available-services', element: <ViewBiddingPartnerAvailServicesList /> },

      //Bidding User Services
      { path: 'bidding-user-services', element: <BiddingUserServicesListView /> },
      { path: 'add-bidding-user-services', element: <AddBiddingUserServicesView /> },
      { path: 'edit-bidding-user-services', element: <EditBiddingUserServicesView /> },
      { path: 'view-bidding-user-services', element: <ViewBiddingUserServicesList /> },
      { path: 'bidding-services-partner-payments', element: <BiddingServicesPartnerPaymentsView /> },
      //Partner Bidding Service Quotes
      { path: 'partner-bidding-service-quotes', element: <PartnerBiddingServiceQuotesListView /> },
      { path: 'add-partner-bidding-service-quotes', element: <AddPartnerBiddingServiceQuotesView /> },
      { path: 'edit-partner-bidding-service-quotes', element: <EditPartnerBiddingServiceQuotesView /> },
      { path: 'view-partner-bidding-service-quotes', element: <ViewPartnerBiddingServiceQuotesList /> },
      { path: 'partner-bidding-service-quote-comments', element: <PartnerBiddingServiceCommentsListView /> },
      { path: 'view-partner-bidding-service-quote-comments', element: <ViewPartnerBiddingServiceQuoteCommentsList /> },
      //meepaisa fashion hub
      { path: 'meepaisa-fashion-hub', element: <MeepaisaFashionHubMainPage /> },
      //meepaisa product combo
      { path: 'meepaisa-product-combo', element: <MPProductComboListView /> },
      { path: 'view-meepaisa-product-combo', element: <ViewMPProductComboView /> },
      { path: 'add-meepaisa-product-combo', element: <AddMPProductComboView /> },
      { path: 'edit-meepaisa-product-combo', element: <EditMPProductComboView /> },
      //meepaisa zone
      { path: 'meepaisa-zone', element: <MeepaisaZone /> },
      { path: '/app/add-meepaisa', element: <AddMeepaisaZoneView /> },
      { path: '/app/edit-meepaisa', element: <EditMeepaisaZoneView /> },
      { path: '/app/zone-details', element: <MeepaisaZoneView /> },
      //meepaisa zone theme
      { path: 'meepaisa-zone-theme', element: <MeepaisaZoneTheme /> },
      { path: '/app/add-meepaisatheme', element: <AddMeepaisaZoneThemeView /> },
      { path: '/app/edit-meepaisatheme', element: <EditMeepaisaZoneThemeView /> },
      { path: '/app/zonetheme-details', element: <MeepaisaZoneThemeView /> },

      //meepaisa fashion hub Theme
      { path: 'meepaisa-theme', element: <MeepaisaThemeListView /> },
      { path: 'add-meepaisa-theme', element: <AddMeepaisaThemeView /> },
      { path: 'edit-meepaisa-theme', element: <EditMeepaisaThemeView /> },
      { path: 'view-meepaisa-theme', element: <ViewMeepaisaTheme /> },
      //meepaisa fashion Zone Theme Products
      { path: 'meepaisa-zone-theme-products', element: <MeepaisaZoneThemeProductsListView /> },
      { path: 'add-zone-theme-products', element: <AddZoneThemeProductView /> },
      { path: 'edit-zone-theme-products', element: <EditZoneThemeProductView /> },
      { path: 'view-zone-theme-products', element: <ViewZoneThemeProduct /> },
      //contact us
      { path: 'contact-us', element: <ContactUsListView /> },
      { path: 'view-contact-us', element: <ContactUsView /> },


      //  corporate management

      { path: 'corporate-gifting-management', element: <CorporateGiftingListView /> },
      { path: 'corporate-gifting-history', element: <CorporateGiftingHistoryListView /> },

      { path: 'corporate-newsandevents-management', element: <CorporateNewsandEventsListView /> },
      { path: 'add-corporate-newsandevents', element: <AddCorporateNWView /> },
      { path: 'edit-corporate-newsandevents', element: <EditCorporateNWView /> },

      { path: 'corporate-ticket-management', element: <CorporateTicketsListView /> },
      { path: 'add-corporate-ticket', element: <AddDCorporateTicketView /> },
      { path: 'edit-corporate-ticket', element: <EditCorporateTicketView /> },


      { path: 'pending-corporate-user-management', element: <PendingCorporateUserListView /> },
      { path: 'corporate-coupon-management', element: <CorporateCouponsList /> },
      { path: 'add-corporate-coupon', element: <AddCorporateCouponView /> },
      { path: 'edit-corporate-coupon', element: <EditCorporateCouponView /> },

      { path: 'approved-corporate-user-management', element: <ApprovedCorporateUserListView /> },
      { path: 'rejected-corporate-user-management', element: <RejectedCorporateUserListView /> },
      { path: 'corporate-user-management', element: <CorporateUserManagementList /> },
      //corporate category management
      { path: 'corporate-category-management', element: <CorporateCategoryListView /> },
      { path: 'add-category-for-corporate', element: <AddCorporateCategoryList /> },
      { path: 'edit-category-for-corporate', element: <EditCorporateCategoryList /> },
      { path: 'category-details-for-corporate', element: <CorporateCategoryDetailsView /> },
      //corporate product management
      { path: 'corporate-product-management', element: <CorporateProductListView /> },
      { path: 'add-product-for-corporate', element: <AddCorporateProduct /> },
      { path: 'edit-product-for-corporate', element: <EditCorporateProduct /> },
      { path: 'product-details-for-corporate', element: <CorpProductsView /> },
      { path: 'corporate-product-request-management', element: <CorporateProductRequestListView /> },
      { path: 'edit-corporate-product-request', element: <EditProductRequestList /> },
      //corp popup
      { path: 'corporate-popup-management', element: <CorpPopupListView /> },
      { path: 'add-corporate-popup', element: <AddCorpPopupView /> },
      { path: 'edit-corporate-popup', element: <EditCorpPopupView /> },
	  

      //New Service  management
      { path: 'newservices-maincentre', element: <NewProductList /> },

      //Service categories mangement
      { path: 'servicescategories-management', element: <Servicecategories /> },
      { path: 'add-serviceCategory', element: <AddServiceCategoryView /> },
      { path: 'edit-servicecategory', element: <EditserviceCategoryView /> },
      { path: 'view-servicecategories', element: <ServicescategoriesView /> },

      //Sevice subcategories mangement
      { path: 'servicessubcategory-management', element: <Servicessubcategory /> },
      { path: 'view-newsubservices', element: <ServicesnewsubView /> },
      { path: 'add-servicesubCategory', element: <AddServiceSubCategoryView /> },
      { path: 'edit-subservicecategories-management', element: <EditsubserviceCategoryView /> },

      //Service varient mangement
      { path: 'servicesvarient-management', element: <NewBussinessvarient /> },
      { path: 'add-servicevarient', element: <AddServiceVarients /> },
      { path: 'edit-servicevarient', element: <EditserviceVarientView /> },
      { path: 'view-varientservice', element: <ServicesnewvarientView /> },

      //Service Partner mangement
      { path: 'servicespartner-management', element: <Servicepartenermangement /> },
      { path: 'servicespartner-managementview', element: <ServicespartnermanagementView /> },
      { path: 'servicesadd-partnermanagement', element: <AddServicepartnermanagement /> },
      { path: 'servicesedit-partnermanagement', element: <EditServicesPartnerManagement /> },

      //City Service management

      { path: 'servicescity-management', element: < ServicescategoryCity /> },
      { path: 'add-serviceCategoryCity', element: <AddServiceCategoryCity /> },
      { path: 'edit-servicecategoryCity', element: <EditserviceCategoryCity /> },
      { path: 'view-servicecategoriesCity', element: <ServicescategoriesCityView /> },

      //servicerequests
      { path: 'ServiceRequest-management', element: <NewServicerequest /> },
      { path: 'add-serviceRequest', element: <AddServiceRequests /> },
      { path: 'edit-serviceRequest', element: <EditserviceRequests /> },

      //servicebookings
      { path: 'ServiceBooking-management', element: <ServiceBooking /> },
      { path: 'ServiceBookingdetails-management/:Sb_Id', element: <ServicesBookingDetails /> },
      //corporate order management
      { path: 'corporate-order-management', element: <CorporateOrderListView /> },
      { path: 'corp-order-doorstep-delivery-management', element: <CorporOrderDoorStepListView /> },
      { path: 'corp-order-info', element: <CorpOrderInfoView /> },

      //services banner management
      { path: 'servicebanners-management', element: <Servicebanners /> },
      { path: 'add-servicebanners', element: <AddBannerViews /> },
      { path: 'edit-servicebanners', element: <EditBannerViews /> },
      { path: 'pos-order-management', element: <POSOrderListView /> },

      //new inventory management
      { path: 'newinventory', element: <NewInventoryListView /> },
      { path: 'create-inventory', element: < CreateInventoryView /> },
      { path: 'edit-newinventory', element: <EditNewInventoryView /> },
      // new order management 
      { path: 'neworder-history', element: <NewOrderListView /> },
      { path: 'neworder-management', element: <NewOrderProductList /> },
      { path: 'neworder-ratingsandreviews', element: <NewReviewProductListView /> },
      { path: 'neworder-transactionhistory', element: <NewTransactionHistoryListView /> },
      { path: 'neworder-paymentsandsettlements', element: <NewPaymentsandSettlements /> },
      { path: 'neworder-partnerpaymentsettlements', element: <NewPartnerPaymentsandSettlements /> },
      { path: 'neworder-userpaymentsettlements', element: <NewUserPaymentSettlementsListView /> },
      { path: 'edit-neworder-status-history', element: <EditNewOrderStatusHistoryView /> },
      { path: 'neworder-shipping', element: <NewShippingListView /> },
      { path: 'edit-neworder-status-history', element: <EditNewOrderStatusHistory /> },
      { path: 'cancel-orders-list', element: <CancelOrderListView /> },
      { path: 'return-orders-list', element: <ReturnOrderListView /> },
      { path: 'newtransaction-history-details', element: <NewTransactionHistoryDetailsView /> },

      //Awardmanagement Table
      { path: 'add-corporate-award', element: <AddCorporateAwardView /> },
      { path: 'edit-corporate-award', element: <EditCorporateAwardView /> },
      { path: 'corporate-award-management', element: <CorporateAwardListView /> },
      { path: 'view-corporate-award', element: <ViewCorporateAwardDetails /> },
      { path: 'corp-order-invoice-view', element: <CorpViewOrderInvoice /> },

      { path: 'new-order-history', element: <OrderNewListView /> },
      { path: 'inprogress-order-history', element: <OngoingOrderListView /> },
      { path: 'cmpltd-order-history', element: <CmpltdOrderListView /> },
      { path: 'cancelled-order-history', element: <CancldOrderListView /> },
      { path: 'returned-order-history', element: <ReturndOrderListView /> },

      { path: 'edit-new-order-status-history', element: <EditOrderStatusHistoryNewView /> },
      { path: 'edit-inprgs-order-status-history', element: <EditInPrgsOrderStatusHistoryView /> },
      { path: 'edit-cmpltd-order-status-history', element: <EditCmpltdOrderStatusHistoryView /> },
      { path: 'edit-cncld-order-status-history', element: <EditCnclldOrderStatusHistoryView /> },
      { path: 'edit-rtrnd-order-status-history', element: <EditReturnOrderStatusHistoryView /> },

      { path: 'retail-order-management', element: <OrderList /> },
      { path: 'transactions', element: <TransactionListView /> },
      { path: 'transaction-details', element: <TransactionDetailsView /> },
      { path: 'partner-cupn-utlze', element: <PartnerCouponUtilizeList /> },
      { path: 'partner-cupn-utlze-details', element: <PartnerCupnDetailsView /> },

    ]
  },

  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/reports/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;