import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  buttonRoot: {
    minWidth: '7px',
    padding: '6px'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));

const headCells = [
  { id: 'User_Ride_Id', numeric: false, disablePadding: false, label: 'User Ride ID' },
  { id: 'Driver_email_Id', numeric: false, disablePadding: false, label: 'Driver Email ID' },
  { id: 'User_Email_Id', numeric: false, disablePadding: false, label: 'User Email ID' },
  { id: 'Ride_Status', numeric: true, disablePadding: false, label: 'Ride Status' },
  { id: 'Payment_Status', numeric: true, disablePadding: false, label: 'Payment Status' },
];

const Results = ({
  className,
  allUserRides,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  pagination,
  setPagination,
  createSortHandler,
  allUserRidesNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const editUserRideHandler = userrideData => e => {
    navigate('/app/edit-user-rides', { state: userrideData });
  };

  const visibleUserRideHandler = userrideData => e => {
    navigate("/app/user-rides-view", { state: userrideData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-30px' }} >Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allUserRides) &&
                (allUserRides).map((userride, i) => (
                  <TableRow
                    hover
                    key={i}
                  >
                    <TableCell >{userride.User_Ride_Id}</TableCell>
                    <TableCell>{userride.Driver_email_Id?.substring(0, 20)}...</TableCell>
                    <TableCell>{userride.User_Email_Id?.substring(0, 20)}...</TableCell>
                    <TableCell>{userride.Ride_Status}</TableCell>
                    <TableCell>{userride.Payment_Status}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.User_Rides_Write?.Value === "User_Rides_Write" && RolePermissions?.User_Rides_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Tooltip title="View UserRide History">
                          <Button
                            onClick={visibleUserRideHandler(userride)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        <Button
                          onClick={editUserRideHandler(userride)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.User_Rides_Read?.Value === "User_Rides_Read" && RolePermissions?.User_Rides_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="View UserRide History">
                          <Button
                            onClick={visibleUserRideHandler(userride)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allUserRides) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allUserRides: PropTypes.array.isRequired
};

export default Results;