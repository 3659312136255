import axios from 'axios';
import config from './config';
import { toast } from 'react-toastify';
import { paginationBrand } from './node-actions/nodepagination';
import { boomiAdminApi, nodeCudApi } from './commonAxios';

export const BRAND_CREATE_REQUEST = "BRAND_CREATE_REQUEST";
export const BRAND_CREATE_SUCCESS = "BRAND_CREATE_SUCCESS";
export const BRAND_CREATE_FAIL = "BRAND_CREATE_FAIL";
export const BRAND_UPDATE_REQUEST = "BRAND_UPDATE_REQUEST";
export const BRAND_UPDATE_SUCCESS = "BRAND_UPDATE_SUCCESS";
export const BRAND_UPDATE_FAIL = "BRAND_UPDATE_FAIL";
export const RESET = "RESET";

axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

const nodeheaders = config.nodeheadersCommon;
/*=============================================================
                  Add brand Action
===============================================================*/
export const addBrandAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: BRAND_CREATE_REQUEST
  });
  setErrorMessage({ Brand_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Brand/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BRAND_CREATE_SUCCESS
        });
        toast('Brand added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/brand-management');
      } else {
        const errors = {};
        if (successResponse.Brand_Name) {
          errors.Brand_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Brand_Name: { key: successResponse.Brand_Name ? successResponse.Brand_Name : '', message: successResponse.Brand_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Brand_Name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: BRAND_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Brand_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: BRAND_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};
/*=============================================================
                Brand Image Upload Action
===============================================================*/
export const uploadBrandImage = (
  formData,
  callBackUploadImgae
) => async dispatch => {
  try {
    let { data } =
      await boomiAdminApi.post(`/SA_Upload/Upload?functionality=Brands&fileextension=png&filetype=Images&filename=Brand`, formData)
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};

/*=============================================================
                  Delete brandById Action
===============================================================*/
export const deleteBrandById = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Brand/Delete`, formData,
    );
    if (data) {
      toast('Brand deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationBrand(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

//Node API
export const getSingleBrand = (formData, callBackBrandData) => async dispatch => {
  try {
    let { data } = await axios.post(
      `${config.nodeurl}/Brand/Get_Admin`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      callBackBrandData(data);
    }
  } catch (err) { }
};


/*=============================================================
                    Update Brand Action
===============================================================*/
export const updateBrandAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: BRAND_UPDATE_REQUEST
  });
  setErrorMessage({ Brand_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Brand/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BRAND_UPDATE_SUCCESS
        });
        toast('Brand updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/brand-management');
      } else {
        const errors = {};
        if (successResponse.Brand_Name) {
          errors.Brand_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Brand_Name: { key: successResponse.Brand_Name ? successResponse.Brand_Name : '', message: successResponse.Brand_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Brand_Name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: BRAND_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Brand_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: BRAND_CREATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

/*=============================================================
                     Invalid_data Action
===============================================================*/
export const invalid_data = () => {
  return {
    type: RESET
  }
}