import {
    DELIVERY_VEHICLE_REQUEST,
    DELIVERY_VEHICLE_SUCCESS,
    DELIVERY_VEHICLE_FAIL,
    DELIVERY_VEHICLE_GET_REQUEST,
    DELIVERY_VEHICLE_GET_SUCCESS,
    DELIVERY_VEHICLE_GET_FAIL,
    DELIVERY_VEHICLE_CREATE_REQUEST,
    DELIVERY_VEHICLE_CREATE_SUCCESS,
    DELIVERY_VEHICLE_CREATE_FAIL,
    DELIVERY_VEHICLE_UPDATE_REQUEST,
    DELIVERY_VEHICLE_UPDATE_SUCCESS,
    DELIVERY_VEHICLE_UPDATE_FAIL,
} from '../actions/deliveryvehicledetailsAction';


const initialState = {
    deliveryvehicledetails: { all: [], error: '', isLoading: false },
    deliveryvehicledetail: { deliveryvehicledetail: {}, error: '', isLoading: false },
    deliveryvehicledetailCreate: { deliveryvehicledetail: {}, error: '', isLoading: false },
    deliveryvehicledetailUpdate: { deliveryvehicledetail: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case DELIVERY_VEHICLE_REQUEST:
            return { ...state, deliveryvehicledetails: { all: state.deliveryvehicledetails.all, error: '', isLoading: true } };
        case DELIVERY_VEHICLE_SUCCESS:
            return { ...state, deliveryvehicledetails: { all: action.payload, error: '', isLoading: false } };
        case DELIVERY_VEHICLE_FAIL:
            return { ...state, deliveryvehicledetails: { all: [], error: action.payload, isLoading: false } };
        case DELIVERY_VEHICLE_GET_REQUEST:
            return { ...state, deliveryvehicledetail: { deliveryvehicledetail: {}, error: '', isLoading: true } };
        case DELIVERY_VEHICLE_GET_SUCCESS:
            return { ...state, deliveryvehicledetail: { deliveryvehicledetail: action.payload, error: '', isLoading: false } };
        case DELIVERY_VEHICLE_GET_FAIL:
            return { ...state, deliveryvehicledetail: { deliveryvehicledetail: {}, error: action.payload, isLoading: false } };
        case DELIVERY_VEHICLE_CREATE_REQUEST:
            return { ...state, deliveryvehicledetailCreate: { deliveryvehicledetail: {}, error: '', isLoading: true } };
        case DELIVERY_VEHICLE_CREATE_SUCCESS:
            return { ...state, deliveryvehicledetailCreate: { deliveryvehicledetail: state.deliveryvehicledetailCreate.deliveryvehicledetail, error: '', isLoading: false } };
        case DELIVERY_VEHICLE_CREATE_FAIL:
            return { ...state, deliveryvehicledetailCreate: { deliveryvehicledetail: {}, error: action.payload, isLoading: false } };
        case DELIVERY_VEHICLE_UPDATE_REQUEST:
            return { ...state, deliveryvehicledetailUpdate: { deliveryvehicledetail: {}, error: '', isLoading: true } };
        case DELIVERY_VEHICLE_UPDATE_SUCCESS:
            return { ...state, deliveryvehicledetailUpdate: { deliveryvehicledetail: state.deliveryvehicledetailUpdate.deliveryvehicledetail, error: '', isLoading: false } };
        case DELIVERY_VEHICLE_UPDATE_FAIL:
            return { ...state, deliveryvehicledetailUpdate: { deliveryvehicledetail: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}