import {
    PARTNER_DATEANDCOUNT_REQUEST,
    PARTNER_DATEANDCOUNT_SUCCESS,
    PARTNER_DATEANDCOUNT_FAIL,
} from '../actions/partnerdynamicdateandcountAction';

const initialState = {
    partnersdateandcounts: { all: [], error: '', isLoading: false },
};
export default function (state = initialState, action) {
    switch (action.type) {
        case PARTNER_DATEANDCOUNT_REQUEST:
            return { ...state, partnersdateandcounts: { all: state.partnersdateandcounts.all, error: '', isLoading: true } };
        case PARTNER_DATEANDCOUNT_SUCCESS:
            return { ...state, partnersdateandcounts: { all: action.payload, error: '', isLoading: false } };
        case PARTNER_DATEANDCOUNT_FAIL:
            return { ...state, partnersdateandcounts: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}

