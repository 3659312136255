import {

    DELI_BID_VEHI_COMMISSION_CREATE_REQUEST,
    DELI_BID_VEHI_COMMISSION_CREATE_SUCCESS,
    DELI_BID_VEHI_COMMISSION_CREATE_FAIL,

    DELI_BID_VEHI_COMMISSION_UPDATE_REQUEST,
    DELI_BID_VEHI_COMMISSION_UPDATE_SUCCESS,
    DELI_BID_VEHI_COMMISSION_UPDATE_FAIL,

    DELI_USERS_GET_REQUEST,
    DELI_USERS_GET_SUCCESS,
    DELI_USERS_GET_FAIL,

    DELI_BID_VEHICLES_REQUEST,
    DELI_BID_VEHICLES_SUCCESS,
    DELI_BID_VEHICLES_FAIL,

} from '../actions/deliverybiddingvehiclecommissionAction'

const initialState = {
    delibidVehiCommissionCreate: { delibidVehiCommission: {}, error: '', isLoading: false },
    delibidVehiCommissionUpdate: { delibidVehiCommission: {}, error: '', isLoading: false },
    biddeliveryusers: { all: [], error: '', isLoading: false },
    delibidvehicles: { all: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case DELI_BID_VEHI_COMMISSION_CREATE_REQUEST:
            return { ...state, delibidVehiCommissionCreate: { delibidVehiCommission: {}, error: '', isLoading: true } };
        case DELI_BID_VEHI_COMMISSION_CREATE_SUCCESS:
            return { ...state, delibidVehiCommissionCreate: { delibidVehiCommission: state.delibidVehiCommissionCreate.partbidavailprod, error: '', isLoading: false } };
        case DELI_BID_VEHI_COMMISSION_CREATE_FAIL:
            return { ...state, delibidVehiCommissionCreate: { delibidVehiCommission: {}, error: action.payload, isLoading: false } };

        case DELI_BID_VEHI_COMMISSION_UPDATE_REQUEST:
            return { ...state, delibidVehiCommissionUpdate: { delibidVehiCommission: {}, error: '', isLoading: true } };
        case DELI_BID_VEHI_COMMISSION_UPDATE_SUCCESS:
            return { ...state, delibidVehiCommissionUpdate: { delibidVehiCommission: state.delibidVehiCommissionUpdate.partbidavailprod, error: '', isLoading: false } };
        case DELI_BID_VEHI_COMMISSION_UPDATE_FAIL:
            return { ...state, delibidVehiCommissionUpdate: { delibidVehiCommission: {}, error: action.payload, isLoading: false } };

        case DELI_USERS_GET_REQUEST:
            return { ...state, biddeliveryusers: { all: state.biddeliveryusers.all, error: '', isLoading: true } };
        case DELI_USERS_GET_SUCCESS:
            return { ...state, biddeliveryusers: { all: action.payload, error: '', isLoading: false } };
        case DELI_USERS_GET_FAIL:
            return { ...state, biddeliveryusers: { all: [], error: action.payload, isLoading: false } };

        case DELI_BID_VEHICLES_REQUEST:
            return { ...state, delibidvehicles: { all: state.delibidvehicles.all, error: '', isLoading: true } };
        case DELI_BID_VEHICLES_SUCCESS:
            return { ...state, delibidvehicles: { all: action.payload, error: '', isLoading: false } };
        case DELI_BID_VEHICLES_FAIL:
            return { ...state, delibidvehicles: { all: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}