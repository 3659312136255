import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import isEmpty from '../../../store/validations/is-empty';
import SimpleDialog from '../../../components/dialogs/dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Ticket_Id', numeric: false, disablePadding: false, label: 'Ticket ID' },
  { id: 'Ticket_Comment_Id', numeric: false, disablePadding: false, label: 'Ticket Comment ID' },
  { id: 'Comments', numeric: false, disablePadding: false, label: 'Comments' },
  { id: 'Created_By', numeric: false, disablePadding: false, label: 'Created By' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allcomment,
  deleteCommentHandler,
  page,
  setPage,
  limit,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  state,
  createSortHandler,
  allCommentNoFilter,
  pagination,
  setPagination,
  RolePermissions,
  roleUserType,
  ...rest
}) => {

  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedCommentIds, setSelectedCommentIds] = useState([]);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedCommentIds;
    if (event.target.checked) {
      newSelectedCommentIds = allcomment.map(comment => comment.Ticket_Comment_Id);
    } else {
      newSelectedCommentIds = [];
    }
    setSelectedCommentIds(newSelectedCommentIds);
  };

  const handleSelectOne = (event, id) => {
    setSelectedCommentIds(selectedCommentIds.filter(record => {
      for (let i = 0; i < allCommentNoFilter.length; i++) {
        if (record == allCommentNoFilter[i].Ticket_Id) {
          return true;
        }
      }
      return false;
    }))
    const selectedIndex = selectedCommentIds.indexOf(id);
    let newSelectedCommentIds = [];
    if (selectedIndex === -1) {
      newSelectedCommentIds = newSelectedCommentIds.concat(selectedCommentIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCommentIds = newSelectedCommentIds.concat(
        selectedCommentIds.slice(1)
      );
    } else if (selectedIndex === selectedCommentIds.length - 1) {
      newSelectedCommentIds = newSelectedCommentIds.concat(
        selectedCommentIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCommentIds = newSelectedCommentIds.concat(
        selectedCommentIds.slice(0, selectedIndex),
        selectedCommentIds.slice(selectedIndex + 1)
      );
    }
    setSelectedCommentIds(newSelectedCommentIds);
  };

  const deleteSelectedCommentHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const editCommentHandler = commentData => e => {
    navigate('/app/edit-comment', { state: { state: state, data: commentData } });
  };
  const ViewCommentHandler = ticketData => e => {
    navigate('/app/user-comments-details', { state: ticketData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCommentIds.filter(record => {
                      for (let i = 0; i < allCommentNoFilter?.length; i++) {
                        if (record == allCommentNoFilter[i].Ticket_Comment_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allcomment?.length}
                    color="primary"
                    indeterminate={
                      selectedCommentIds.filter(record => {
                        for (let i = 0; i < allCommentNoFilter?.length; i++) {
                          if (record == allCommentNoFilter[i].Ticket_Comment_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedCommentIds.filter(record => {
                        for (let i = 0; i < allCommentNoFilter.length; i++) {
                          if (record == allCommentNoFilter[i].Ticket_Comment_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allcomment.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>&nbsp;&nbsp;&nbsp;&nbsp;Actions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedCommentIds.filter(record => {
                    for (let i = 0; i < allCommentNoFilter.length; i++) {
                      if (record == allCommentNoFilter[i].Ticket_Comment_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedCommentIds.filter(record => {
                    for (let i = 0; i < allCommentNoFilter.length; i++) {
                      if (record == allCommentNoFilter[i].Ticket_Comment_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedCommentHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allcomment) &&
                (allcomment).map(comment => (
                  <TableRow
                    hover
                    key={comment.Ticket_Comment_Id}
                    selected={
                      selectedCommentIds.indexOf(comment.Ticket_Id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedCommentIds.indexOf(comment.Ticket_Comment_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, comment.Ticket_Comment_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell width="10%">{comment.Ticket_Id}</TableCell>
                    <TableCell width="15%">{comment.Ticket_Comment_Id}</TableCell>
                    <TableCell>{comment.Comments?.substring(0, 20)}...</TableCell>
                    <TableCell style={{ color: '#1665D8' }}>
                      {comment.Created_By?.substring(0, 20)}...
                    </TableCell>
                    <TableCell>{comment.Status}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.User_Comments_Write?.Value === "User_Comments_Write" && RolePermissions?.User_Comments_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Button
                          onClick={editCommentHandler(comment)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Tooltip title="Comment Details">
                          <Button
                            onClick={ViewCommentHandler(comment)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        <Button>
                          <DeleteIcon
                            onClick={deleteCommentHandler(comment)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.User_Comments_Read?.Value === "User_Comments_Read" && RolePermissions?.User_Comments_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="Comment Details">
                          <Button
                            onClick={ViewCommentHandler(comment)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allcomment) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allcomment: PropTypes.array.isRequired
};

export default Results;