import {
    MP_PRODUCT_COMBO_CREATE_REQUEST,
    MP_PRODUCT_COMBO_CREATE_SUCCESS,
    MP_PRODUCT_COMBO_CREATE_FAIL,

    MP_PRODUCT_COMBO_UPDATE_REQUEST,
    MP_PRODUCT_COMBO_UPDATE_SUCCESS,
    MP_PRODUCT_COMBO_UPDATE_FAIL,

    MEEPAISA_PRODUCTS_REQUEST,
    MEEPAISA_PRODUCTS_SUCCESS,
    MEEPAISA_PRODUCTS_FAIL,

    MEEPAISA_COMBO_PRODUCTS_REQUEST,
    MEEPAISA_COMBO_PRODUCTS_SUCCESS,
    MEEPAISA_COMBO_PRODUCTS_FAIL,
} from '../actions/meepaisaproductcomboAction'

const initialState = {
    productcomboCreate: { productcombo: {}, error: '', isLoading: false },
    productcomboUpdate: { productcombo: {}, error: '', isLoading: false },
    meepaisaProducts: { all: [], error: '', isLoading: false },
    meepaisaComboProducts: { all: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case MP_PRODUCT_COMBO_CREATE_REQUEST:
            return { ...state, productcomboCreate: { productcombo: {}, error: '', isLoading: true } };
        case MP_PRODUCT_COMBO_CREATE_SUCCESS:
            return { ...state, productcomboCreate: { productcombo: state.productcomboCreate.productcombo, error: '', isLoading: false } };
        case MP_PRODUCT_COMBO_CREATE_FAIL:
            return { ...state, productcomboCreate: { productcombo: {}, error: action.payload, isLoading: false } };

        case MP_PRODUCT_COMBO_UPDATE_REQUEST:
            return { ...state, productcomboUpdate: { productcombo: {}, error: '', isLoading: true } };
        case MP_PRODUCT_COMBO_UPDATE_SUCCESS:
            return { ...state, productcomboUpdate: { productcombo: state.productcomboUpdate.productcombo, error: '', isLoading: false } };
        case MP_PRODUCT_COMBO_UPDATE_FAIL:
            return { ...state, productcomboUpdate: { productcombo: {}, error: action.payload, isLoading: false } };

        case MEEPAISA_PRODUCTS_REQUEST:
            return { ...state, meepaisaProducts: { all: state.meepaisaProducts.all, error: '', isLoading: true }, };
        case MEEPAISA_PRODUCTS_SUCCESS:
            return { ...state, meepaisaProducts: { all: action.payload, error: '', isLoading: false } };
        case MEEPAISA_PRODUCTS_FAIL:
            return { ...state, meepaisaProducts: { all: [], error: action.payload, isLoading: false } };

        case MEEPAISA_COMBO_PRODUCTS_REQUEST:
            return { ...state, meepaisaComboProducts: { all: state.meepaisaComboProducts.all, error: '', isLoading: true }, };
        case MEEPAISA_COMBO_PRODUCTS_SUCCESS:
            return { ...state, meepaisaComboProducts: { all: action.payload, error: '', isLoading: false } };
        case MEEPAISA_COMBO_PRODUCTS_FAIL:
            return { ...state, meepaisaComboProducts: { all: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}