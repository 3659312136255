import { Card, CardContent, Grid } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

const AdminEmployeeList = ({ className, ...rest }) => {
    const location = useLocation();
    let { state } = location;

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            {/* Email_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Email ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state?.Email_Id}> {state?.Email_Id?.length > 25 ?
                                        <span>{state?.Email_Id?.substring(0, 25)}...</span> : state?.Email_Id}</span>
                                </Typography>
                            </Grid>
                            {/* User_Type */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    User Type
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.User_Type}
                                </Typography>
                            </Grid>
                            {/* Mobile */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Mobile
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Mobile}
                                </Typography>
                            </Grid>
                            {/* Created_Date */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Created_Date}
                                </Typography>
                            </Grid>
                            {/* Created_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state?.Created_By}> {state?.Created_By?.length > 25 ?
                                        <span>{state?.Created_By?.substring(0, 25)}...</span> : state?.Created_By}</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={2} />
                            <Grid item xs={2} />
                            <Grid item xs={7} />

                        </Grid>
                    </Grid>

                    {/*  column 2 */}
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            {/* User_Details_id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    User Detail ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.User_Details_id}
                                </Typography>
                            </Grid>
                            {/* Is_Active */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Active
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Is_Active}
                                </Typography>
                            </Grid>
                            {/* Modified_Date */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Modified_Date}
                                </Typography>
                            </Grid>
                            {/* Modified_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state?.Modified_By}> {state?.Modified_By?.length > 25 ?
                                        <span>{state?.Modified_By?.substring(0, 25)}...</span> : state?.Modified_By}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default AdminEmployeeList;