import {
  PARTNERS_REQUEST,
  PARTNERS_SUCCESS,
  PARTNERS_FAIL,
} from '../actions/partnerreviewtableAction'

const initialState = {
  partners: { all: [], error: '', isLoading: false },
  partner: { partner: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PARTNERS_REQUEST:
      return { ...state, partners: { all: state.partners.all, error: '', isLoading: true } };
    case PARTNERS_SUCCESS:
      return { ...state, partners: { all: action.payload, error: '', isLoading: false } };
    case PARTNERS_FAIL:
      return { ...state, partners: { all: [], error: action.payload, isLoading: false } };
    default:
      return state;
  }
}