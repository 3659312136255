import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography,
  Avatar
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import { useLocation } from 'react-router-dom';
import PartnerDeliveryAgentList from './viewPartnerDeliveryAgent';
import isEmpty from 'src/store/validations/is-empty';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const PartnerDeliveryAgentView = ({ className, data, ...rest }) => {
  const location = useLocation();
  const classes = useStyles();
  const { state } = location;
  const [allpartnerdeliagent, setPartnerdeliagent] = useState([]);

  let partnersdeliveryagent = useSelector(state => state.partnerdeliveryagent.allpartnerdeliagent);

  useEffect(() => {
    if (!isEmpty(partnersdeliveryagent)) {
      setPartnerdeliagent(partnersdeliveryagent.partnerdeliveryagent);
    }
  }, [partnersdeliveryagent]);

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/partner-deliveryagent-management"
          variant="text"
          size="small"
          color="inherit"
          state={state?.state}
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            View Partner Delivery Agent Details
          </Typography>
        </Box>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)", marginTop: '1rem', marginBottom: '1rem' }}>
          <div style={{ marginLeft: '1.5rem' }}>
            <Avatar
              alt="profile"
              src={state?.data?.Profile_Pic_Path}
              sx={{ width: 24, height: 24 }}
            />
          </div>
          <div style={{ color: "blue", fontWeight: 'bold', marginTop: '0.7rem', marginLeft: '-4rem' }}>{state?.data?.First_Name} {state?.data?.Last_Name}</div>
          <div></div><div></div> <div></div><div></div>
          <div>
            <div style={{ fontWeight: 'bold', marginTop: "1%", marginLeft: '-4rem' }}>{state?.data?.Del_User_Email_Id}</div>
            <div style={{ fontWeight: 'bold', marginTop: "2%", marginLeft: '-4rem' }}>{state?.data?.Mobile}</div>
            <div style={{ fontWeight: 'bold', marginTop: "2%", marginLeft: '-4rem' }}>{state?.data?.Del_User_Type}</div>
          </div>
        </div>
        <PartnerDeliveryAgentList state={state?.state} data={state?.data} allpartnerdeliagent={allpartnerdeliagent} />
      </Container>
    </Page>
  );
};

export default PartnerDeliveryAgentView;