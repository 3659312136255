import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableSortLabel,
    TableRow,
    makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../store/validations/is-empty';
import CommentIcon from '@material-ui/icons/Comment';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    images: {
        width: 100,
        height: 50
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    }
}));

const headCells = [
    { id: 'B2B_Bidding_Product_Comment_Id', numeric: false, disablePadding: true, label: 'B2B Bidding Product Comment ID' },
    { id: 'Partner_Bidding_Product_Quote_Id', numeric: false, disablePadding: false, label: 'Partner Bidding Product Quote ID' },
    { id: 'Is_Admin_Approval', numeric: false, disablePadding: false, label: 'Is Admin Approval' },
    { id: 'Commented_By', numeric: false, disablePadding: false, label: 'Commented By' },
];

const Results = ({
    className,
    allB2BUserProdBidComments,
    order,
    orderBy,
    createSortHandler,
    handleLimitChange,
    handlePageChange,
    allB2BUserProdBidCommentsNoFilter,
    pagination,
    setPagination,
    RolePermissions,
    roleUserType,
    state,
    ...rest
}) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const viewB2BUserProdBidCommentsHandler = b2buserprodbidComments => e => {
        navigate("/app/update-b2b-user-product-bidding-comments", { state: { state: state, data: b2buserprodbidComments } });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-3rem' }} >Comments
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allB2BUserProdBidComments) &&
                                (allB2BUserProdBidComments).map(b2buserprodbidcomment => (
                                    <TableRow
                                        hover
                                        key={b2buserprodbidcomment.B2B_Bidding_Product_Comment_Id}
                                    >
                                        <TableCell>{b2buserprodbidcomment.B2B_Bidding_Product_Comment_Id}</TableCell>
                                        <TableCell>{b2buserprodbidcomment.Partner_Bidding_Product_Quote_Id}</TableCell>
                                        <TableCell>{b2buserprodbidcomment.Is_Admin_Approval}</TableCell>
                                        <TableCell>{b2buserprodbidcomment.Commented_By}</TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Partner_Bidding_Comments_Write?.Value === "Partner_Bidding_Comments_Write" && RolePermissions?.Partner_Bidding_Comments_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="Partner Bidding Comments">
                                                    <Button
                                                        onClick={viewB2BUserProdBidCommentsHandler(b2buserprodbidcomment)}
                                                        color="primary" >
                                                        <CommentIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            : null}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allB2BUserProdBidComments) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <Pagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
};

export default Results;