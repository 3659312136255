
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { EditStoretypelinkingAction, getAllCategories } from 'src/store/actions/storetypelinkingActions';
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const EditStoreTypeLink = ({ className, state, data, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSubmited = useSelector(state => state.storetypelink.storetypelinkUpdate.isLoading);
  const errorMessage = useSelector(state => state.storetypelink.storetypelinkUpdate.error);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);
  const categories = useSelector(state => state.storetypelink.Categories.categories || []);

  /// componentDidMount
  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  return (
    <Formik
      initialValues={{
        Store_Type_Linking_ID: data.Store_Type_Linking_Id ? data.Store_Type_Linking_Id : "",
        Store_Type_Name: data.Store_Type_Name ? data.Store_Type_Name : "",
        Category_ID: data.Category_Id ? data.Category_Id : "",
        Status: data.Status ? data.Status : "",
        Modified_By: modifiedBy
      }}
      validationSchema={Yup.object().shape({
        Status: Yup.string().required('Status is required.'),
      })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        const errors = {};
        setSubmitting(false);
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(EditStoretypelinkingAction(formValues, navigate, state));
        }

      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container>
                <Grid container spacing={3}>
                  <Grid item md={8} xs={12}>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Store Type Name"
                          name="Store_Type_Name"
                          value={values.Store_Type_Name}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        {/* category name */}
                        <TextField
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Category Name"
                          name="Category_ID"
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ native: true }}
                          value={values.Category_ID}
                          variant="outlined"
                          disabled
                        ><option key="" value="">--Please Select--</option>
                          {categories?.map(option => (
                            <option key={option.Category_Id} value={option.Category_Id}>
                              {option.Category_Name}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      {/* Status */}
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={Boolean(touched.Status && errors.Status)}
                          fullWidth
                          helperText={touched.Status && errors.Status}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Status"
                          name="Status"
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ native: true }}
                          value={values.Status}
                          variant="outlined"
                        >
                          {statusOption.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2}>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" pl={2} pt={1} pb={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditStoreTypeLink.propTypes = {
  className: PropTypes.string
};

export default EditStoreTypeLink;