import { toast } from 'react-toastify';
import {boomiAdminApi } from './commonAxios';
import { boomipaginationTheme } from './boomi-actions/boomipagination';
export const THEME_CREATE_REQUEST = "THEME_CREATE_REQUEST";
export const THEME_CREATE_SUCCESS = "THEME_CREATE_SUCCESS";
export const THEME_CREATE_FAIL = "THEME_CREATE_FAIL";
export const THEME_UPDATE_REQUEST = "THEME_UPDATE_REQUEST";
export const THEME_UPDATE_SUCCESS = "THEME_UPDATE_SUCCESS";
export const THEME_UPDATE_FAIL = "THEME_UPDATE_FAIL";

/*=============================================================
                  Add Theme Action
===============================================================*/
export const createThemeAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: THEME_CREATE_REQUEST
  });
  boomiAdminApi.post(`/Meepaisa_Theme/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: THEME_CREATE_SUCCESS
        });
        toast('Meepaisa Theme added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/meepaisa-theme');
      } else {
        dispatch({
          type: THEME_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }
    })
    .catch(error => {
      dispatch({
        type: THEME_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};


/*=============================================================
                  Edit Theme Action
===============================================================*/
export const updateThemeAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: THEME_UPDATE_REQUEST
  });
  boomiAdminApi.post(`/Meepaisa_Theme/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: THEME_UPDATE_SUCCESS
        });
        toast('Meepaisa Theme updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/meepaisa-theme');
      } else {
        dispatch({
          type: THEME_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }
    })
    .catch(error => {
      dispatch({
        type: THEME_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
               Zone Theme Image Upload Action
===============================================================*/
export const uploadThemeImage = (
  formData,
  callBackUploadImgae
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=Themes&fileextension=png&filetype=Images&filename=Theme`,formData);
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};

/*=============================================================
                Delete Zone Theme Action
===============================================================*/
export const deleteThemeAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/Meepaisa_Theme/Delete`, formData);
    if (data) {
      toast('Meepaisa Theme deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(boomipaginationTheme(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

