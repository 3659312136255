import {
  BRAND_CREATE_REQUEST,
  BRAND_CREATE_SUCCESS,
  BRAND_CREATE_FAIL,
  BRAND_UPDATE_REQUEST,
  BRAND_UPDATE_SUCCESS,
  BRAND_UPDATE_FAIL,
  RESET
} from '../actions/brandAction'


const initialState = {
  brandCreate: { brand: {}, error: '', isLoading: false },
  brandUpdate: { brand: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BRAND_CREATE_REQUEST:
      return { ...state, brandCreate: { brand: {}, error: '', isLoading: true } };
    case BRAND_CREATE_SUCCESS:
      return { ...state, brandCreate: { brand: state.brandCreate.brand, error: '', isLoading: false } };
    case BRAND_CREATE_FAIL:
      return { ...state, brandCreate: { brand: {}, error: action.payload, isLoading: false } };
    case BRAND_UPDATE_REQUEST:
      return { ...state, brandUpdate: { brand: {}, error: '', isLoading: true } };
    case BRAND_UPDATE_SUCCESS:
      return { ...state, brandUpdate: { brand: action.payload, error: '', isLoading: false } };
    case BRAND_UPDATE_FAIL:
      return { ...state, brandUpdate: { brand: {}, error: action.payload, isLoading: false } };

    case RESET:
      return { ...state, brandCreate: { brand: {}, error: '', isLoading: false } };
    default:
      return state;
  }
}