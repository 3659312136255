import {
  USER_REQUEST, USER_SUCCESS,
  USER_FAIL, USER_LOGOUT, USER_SESSION_REFRESH_DATE
} from '../actions/authActions';
import CryptoJS from 'crypto-js';
import config from '../actions/config';


const getExpireDate = () => {
  try {
    const encript = localStorage.getItem('Token')
    const bytesData = CryptoJS.AES.decrypt(encript, config.cryptoKay);
    const token = bytesData.toString(CryptoJS.enc.Utf8);
    return JSON.parse(token).expireDate || new Date().setSeconds(new Date().getSeconds() + (60 * 5))
  } catch (error) {
    return new Date().setSeconds(new Date().getSeconds() + (60 * 5))
  }

}

const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || {},
  error: '',
  isAuthenticated: !!localStorage.getItem("user"),
  sessionExpiredTime: 60 * 5,
  sessionExpiredDate: getExpireDate(),
  isLoading: false
}

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_REQUEST:
      return { ...state, isLoading: true, isAuthenticated: false }
    case USER_SUCCESS:
      if (action.login) {
        localStorage.setItem("user", JSON.stringify(action.data));
        return { ...state, isLoading: false, user: action.data, error: '', isAuthenticated: true }
      } else {
        if (state.isAuthenticated) {
          localStorage.setItem("user", JSON.stringify(action.data));
          return { ...state, isLoading: false, user: action.data, error: '', isAuthenticated: true }
        } else {
          return { ...state }
        }
      }
    case USER_FAIL:
      if (action.login) {
        return { ...state, isLoading: false, user: {}, error: action.data, isAuthenticated: false }
      } else {
        return { ...state }
      }
    case USER_LOGOUT:
      localStorage.removeItem("user");
      return { ...state, isAuthenticated: false, user: {} };
    case USER_SESSION_REFRESH_DATE:
      return { ...state, sessionExpiredDate: action.expireDate, sessionExpiredTime: action?.expireTime || state.sessionExpiredTime };
    default:
      return state;
  }
}

export default authenticationReducer;