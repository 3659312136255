import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    link: '/app/subscription-user-management',
    createdAt: '',
    description:
      'User Subscriptions means the user subscriptions purchased by the Customer and set out in the Subscription Order Form which entitle Authorised Users to access and use the Services in accordance with this Agreement.',
    media: '/static/Dashboard/admin_user_management.jpg',
    title: 'Subscription Users',
    total: ''
  }, 
  {
    id: uuid(),
    link: '/app/subscription-packages',
    createdAt: '',
    description:
      'Subscription Package means the Customer-selected Epic access and talk time, as amended from time to time, which form part of this Agreement and also  a monthly or annual payment that gives the right to certain Services according to the Subscription.',
    media: '/static/Dashboard/inventorymanagement.jpg',
    title: 'Subscription Packages',
    total: ''
  }, 
];
