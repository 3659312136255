import config from './config';
import { toast } from 'react-toastify';
import { paginationServices, paginationsubcategoryServices } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi, boomiAdminApi, nodeServiceApi, urlGenarator } from './commonAxios';
import Axios from 'axios';

// export const STORETYPE_REQUEST = "STORETYPE_REQUEST ";
// export const STORETYPE_SUCCESS = "STORETYPE_SUCCESS";
// export const STORETYPE_FAIL = "STORETYPE_FAIL";
export const SUB_CATEGORY_UPDATE_REQUEST = "SUB_CATEGORY_UPDATE_REQUEST ";
export const SUB_CATEGORY_UPDATE_SUCCESS = "SUB_CATEGORY_UPDATE_SUCCESS";
export const SUB_CATEGORY_UPDATE_FAIL = "SUB_CATEGORY_UPDATE_FAIL";
export const SERVICE_SUB_CATEGORY_TYPE_CREATE_REQUEST = "SERVICE_SUB_CATEGORY_TYPE_CREATE_REQUEST";
export const SERVICE_SUB_CATEGORY_TYPE_CREATE_SUCCESS = "SERVICE_SUB_CATEGORY_TYPE_CREATE_SUCCESS";
export const SERVICE_SUB_CATEGORY_TYPE_CREATE_FAIL = "SERVICE_SUB_CATEGORY_TYPE_CREATE_FAIL";
export const SERVICE_CATEGORY_TYPE_CREATE_REQUEST = "SERVICE_CATEGORYTYPE_CREATE_REQUEST";
export const SERVICE_CATEGORY_TYPE_CREATE_SUCCESS = "SERVICE_CATEGORY_TYPE_CREATE_SUCCESS";
export const SERVICE_CATEGORY_TYPE_CREATE_FAIL = "SERVICE_CATEGORY_TYPE_CREATE_FAIL";
export const SUB_SERVICES_GET_REQUEST = "SUB_SERVICES_GET_REQUEST"
export const SUB_SERVICES_GET_SUCCESSFULL = "SUB_SERVICES_GET_SUCCESSFULL"
export const SUB_SERVICES_GET_FAILED = "SUB_SERVICES_GET_FAILED"
export const SUB_CATEGORIES_REQUEST = "SUB_CATEGORIES_REQUEST";
export const SUB_CATEGORIES_SUCCESS = "SUB_CATEGORIES_SUCCESS";
export const SUB_CATEGORIES_FAIL = "SUB_CATEGORIES_FAIL";
/*=============================================================
                  Add Store type Action
==============================================================*/
export const AddSubCategorytypeAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: SERVICE_SUB_CATEGORY_TYPE_CREATE_REQUEST
  });
  try {
    const response = await nodeServiceApi.post(`/admin/sub_service/Create`, formData);
    
    const successResponse = response.data;

    if (successResponse.Response_Status === "Success") {
      dispatch({
        type: SERVICE_SUB_CATEGORY_TYPE_CREATE_SUCCESS
      });
      toast('Sub Service Type created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      navigate('/app/servicessubcategory-management', state = { state });
    } else {
      dispatch({
        type: SERVICE_SUB_CATEGORY_TYPE_CREATE_FAIL,
        payload: successResponse.UI_Display_Message
      });
      toast(successResponse.UI_Display_Message + " Please change Sub Service Name and Try Again", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    }
  } catch (error) {
    dispatch({
      type: SERVICE_SUB_CATEGORY_TYPE_CREATE_FAIL,
      payload: "Currently server is not working. Please try again later."
    });
  }
};



/*=============================================================
                Update Store type Action
===============================================================*/
export const EditSubcategoriesAction = (formData, navigate,state) => async dispatch => {
  dispatch({
    type: SERVICE_SUB_CATEGORY_TYPE_CREATE_REQUEST
  });
  nodeServiceApi.put(`/admin/sub_service/Update/${formData.Ss_Id}`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICE_SUB_CATEGORY_TYPE_CREATE_SUCCESS
        });
        toast('Sub Service updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/servicessubcategory-management',state = { state });
      } else {
        dispatch({
          type: SERVICE_SUB_CATEGORY_TYPE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
        toast(successResponse.UI_Display_Message + "Please change Sub Service Name and Try Again", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }
    })
    .catch(error => {
      dispatch({
        type: SERVICE_SUB_CATEGORY_TYPE_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Get All Store type Action
 ===============================================================*/
export const getAllServiceCategories = () => async dispatch => {
  try {
    let formData = {
      Status: "Active",
      SearchFor: "",
      Search: "",
    };
    dispatch({
      type: SERVICE_CATEGORY_TYPE_CREATE_REQUEST
    });
    let { data } = await nodeServiceApi.post(
      `/admin/services/get/?PageNo=1&PageSize=${config.pageSize}&SortBy=Ss_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SERVICE_CATEGORY_TYPE_CREATE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SERVICE_CATEGORY_TYPE_CREATE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SERVICE_CATEGORY_TYPE_CREATE_FAIL,
      payload: err
    });
  }
};

/*=============================================================
              Delete  Delivery Payment Action
 ===============================================================*/
export const deletesubcategoryActions = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeServiceApi.delete(`/admin/sub_service/Delete/${formData.id}`,);
    if (data) {
      toast(' Sub Service deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    }
    dispatch(paginationsubcategoryServices(filters, pagination, callBackPagination));

  }
  catch (err) {
  }
};

/*=============================================================
            partner logo Image Upload Action
===============================================================*/
export const SubcategoryuploadImagepath = (
  formData,
  callBackUploadImgae,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=ServiceSubCategoryType&fileextension=png&filetype=Images&filename=ServiceSubCategoryType`,
      formData
    );
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) {
  }
};


export const getAllSubServices = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Status: "Active",
      search_by_filter: "",
      search: "",
    };
    dispatch({
      type: SUB_SERVICES_GET_REQUEST
    });
    let { data } = await nodeServiceApi.post(`/admin/sub_service/Get`, {});
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SUB_SERVICES_GET_SUCCESSFULL,
          payload: []
        });
      } else {
        dispatch({
          type: SUB_SERVICES_GET_SUCCESSFULL,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SUB_SERVICES_GET_FAILED,
      payload: err
    });
  }
};
