import React, { useState, useEffect } from 'react';
import {
        Container, Typography, Grid, Card, CardContent, CardMedia, Button, Box, Dialog, DialogTitle,
        DialogContent, DialogActions, TextField, FormControl, InputLabel, Select, MenuItem, Pagination,
        Tooltip,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useDispatch, useSelector } from 'react-redux';
import { paginationServicesBookings } from 'src/store/actions/node-actions/nodepagination';
import { EditPartnerServicebookingAction } from 'src/store/actions/partnerservicesbookingAction';
import { paginationPartnermanagementServices } from 'src/store/actions/node-actions/nodepagination';


const ServiceBooking = () => {
        const theme = useTheme();
        const dispatch = useDispatch();
        const [services, setServices] = useState([]);
        const [serviceStatuses, setServiceStatuses] = useState({});
        const [pagination, setPagination] = useState({ PageNo: 1 });
        const [sortOrder, setSortOrder] = useState('Desc');
        const navigate = useNavigate();
        const [searchTerm, setSearchTerm] = useState('');
        const [subService, setSubService] = useState('');
        const [zip, setZip] = useState('');
        const [date, setDate] = useState('');
        const [partnerList, setPartnerList] = useState([]);
        const [openDialog, setOpenDialog] = useState(false);
        const [selectedSsId, setSelectedSsId] = useState('');
        const [selectedPartnerId, setSelectedPartnerId] = useState('');


        useEffect(() => {
                fetchData();
        }, [searchTerm, subService, zip, date, dispatch, pagination.PageNo, sortOrder]);


        const fetchData = async () => {
                let searchFor = 'All';
                let searchValue = searchTerm;

                if (subService) {
                        searchFor = 'Name';
                        searchValue = subService;
                } else if (zip) {
                        searchFor = 'Zip';
                        searchValue = zip;
                } else if (date) {
                        searchFor = 'Schedule_Datetime';
                        searchValue = date;
                }

                const formData = {
                        Search: searchValue,
                        SearchFor: searchFor,
                        SortOrder: sortOrder
                };

                const handleResponse = (success, results, paginationData) => {
                        if (success) {
                                setServices(results);
                                const statuses = {};
                                results.forEach(service => {
                                        statuses[service.Sb_Id] = service.Service_Status;
                                });
                                setServiceStatuses(statuses);

                                if (paginationData) {
                                        setPagination(paginationData);
                                }
                        } else {
                                console.error("Error fetching services");
                        }
                };

                try {
                        await dispatch(paginationServicesBookings(formData, { PageNo: pagination.PageNo }, handleResponse));
                } catch (error) {
                        console.error("Error fetching services:", error);
                }
        };

        const handleSearchChange = (event) => {
                setSearchTerm(event.target.value);
        };

        const handleSubServiceChange = (event) => {
                setSubService(event.target.value);
        };

        const handleZipChange = (event) => {
                setZip(event.target.value);
        };

        const handleDateChange = (event) => {
                setDate(event.target.value);
        };

        const handleSearchSubmit = () => {
                setPagination({ PageNo: 1 });
        };

        const handleStatusChange = async (Sb_Id, newStatus, Ss_Id) => {
                setServiceStatuses((prevStatuses) => ({
                        ...prevStatuses,
                        [Sb_Id]: newStatus,
                }));

                const statusesRequiringPartner = ['Accepted', 'PartnerAccepted', 'OnGoing', 'Done'];

                if (statusesRequiringPartner.includes(newStatus)) {
                        setSelectedSsId(Sb_Id);
                        await fetchPartners(Ss_Id);
                        setOpenDialog(true);
                } else {
                        triggerEditAPI(Sb_Id, newStatus);
                }
        };

        const triggerEditAPI = (Sb_Id, Service_Status, Partner_Details_Id = null) => {
                const formData = {
                        Sb_Id,
                        Service_Status,
                        Partner_Details_Id,
                };
                dispatch(EditPartnerServicebookingAction(formData, navigate));
        };


        const fetchPartners = async (Ss_Id) => {
                const formData = {
                        Ss_Id: Ss_Id
                };
                const handleResponse = (success, results) => {
                        if (success) {
                                const filteredPartners = results.filter(partner => partner.Ss_Id === Ss_Id);
                                setPartnerList(filteredPartners);
                        }

                        else {
                                console.error("Error fetching partners");
                        }
                };
                await dispatch(paginationPartnermanagementServices(formData, {}, handleResponse))
        };

        const handlePageChange = (event, newPage) => {
                setPagination(prev => ({ ...prev, PageNo: newPage }));
        };

        const handleSortOrderChange = (event) => {
                setSortOrder(event.target.value);
        };

        return (
                <Box>
                        <Box sx={{ width: "100%", height: "100vh" }}>
                                <Box>
                                        <Button
                                                component={RouterLink}
                                                to="/app/newservices-maincentre"
                                                variant="text"
                                                size="small"
                                                color="inherit"
                                                sx={{ marginTop: "2%" }}
                                        >
                                                <KeyboardBackspaceIcon />
                                                Go back
                                        </Button>
                                </Box>
                                <Container>
                                        <Box sx={{ display: "flex", marginTop: "2%", marginBottom: "2%" }}>
                                                <Typography variant="h4" gutterBottom>
                                                        Booking Requests
                                                </Typography>
                                        </Box>
                                        <Box
                                                sx={{
                                                        width: "100%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "15px",
                                                        marginBottom: "5%"
                                                }}
                                        >
                                                <Box sx={{ width: "30%" }}>
                                                        <TextField
                                                                id="Search"
                                                                label="Search"
                                                                fullWidth
                                                                value={searchTerm}
                                                                onChange={handleSearchChange}
                                                        />
                                                </Box>

                                                <Box sx={{ width: "30%" }}>
                                                        <TextField
                                                                id="Sub Services"
                                                                label="Sub Services"
                                                                fullWidth
                                                                value={subService}
                                                                onChange={handleSubServiceChange}
                                                        />
                                                </Box>

                                                <Box sx={{ width: "17%" }}>
                                                        <TextField
                                                                id="Zip"
                                                                label="Zip"
                                                                fullWidth
                                                                value={zip}
                                                                onChange={handleZipChange}
                                                        />
                                                </Box>
                                                <Box sx={{ width: "15%" }}>
                                                        <TextField
                                                                fullWidth
                                                                type="date"
                                                                name="Date and Time"
                                                                InputLabelProps={{ shrink: true }}
                                                                variant="outlined"
                                                                value={date}
                                                                onChange={handleDateChange}
                                                        />
                                                </Box>
                                        </Box>

                                        <Grid container spacing={4}>
                                                {services.map((service, index) => {
                                                        const scheduleDatetime = new Date(service.Schedule_Datetime);
                                                        const formattedTime = scheduleDatetime.toLocaleTimeString([], {
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: false
                                                        });
                                                        const day = scheduleDatetime.getDate().toString().padStart(2, '0');
                                                        const month = (scheduleDatetime.getMonth() + 1).toString().padStart(2, '0');
                                                        const year = scheduleDatetime.getFullYear();
                                                        const formattedDate = `${day}-${month}-${year}`;

                                                        const totals = Array.isArray(service.Servicevariants)
                                                                ? service.Servicevariants.map(variant => {
                                                                        const serviceCharge = parseFloat(variant.Service_Charge_For_Timeslot) || 0;
                                                                        const quantity = parseInt(variant.Quantity, 10) || 0;
                                                                        return serviceCharge * quantity;
                                                                })
                                                                : [];

                                                        const total = totals.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                                                        return (
                                                                <Grid item xs={12} sm={6} md={4} key={service.Sb_Id}>
                                                                        <Card>
                                                                                <CardMedia
                                                                                        component="img"
                                                                                        height="140"
                                                                                        image={service.Image}
                                                                                        alt="sub service image"
                                                                                        style={{ objectFit: 'contain' }}
                                                                                />
                                                                                <CardContent>
                                                                                        <Box display="flex" alignItems="center" mb={2}>
                                                                                                <CalendarTodayIcon sx={{ color: 'text.secondary', mr: 1 }} />
                                                                                                <Typography variant="body2" color="textSecondary">
                                                                                                        {formattedDate} {formattedTime}
                                                                                                </Typography>
                                                                                        </Box>
                                                                                        <Typography variant="h6" component="div">
                                                                                                {service.Name}
                                                                                        </Typography>
                                                                                        <Typography variant="body1" color="textSecondary">
                                                                                                <LocationOnIcon /> {service.City} ,{service.Door_No} ,{service.Street}, {service.Land_Mark} ,{service.Zip}
                                                                                        </Typography>
                                                                                        <Typography variant="body2" component="div">
                                                                                                Number of hours: {service.Gst}
                                                                                        </Typography>
                                                                                        <Typography variant="body2" component="div">
                                                                                                Price: ₹{service.Gst}/hour
                                                                                        </Typography>
                                                                                        <Typography variant="body2" component="div">
                                                                                                Total: ₹{total}/-
                                                                                        </Typography>
                                                                                        <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="10px">
                                                                                                <Box sx={{ width: '50%' }}>
                                                                                                        <FormControl fullWidth variant="outlined">
                                                                                                                <Select
                                                                                                                        size="small"
                                                                                                                        labelId="service-status-label"
                                                                                                                        value={serviceStatuses[service.Sb_Id] || ''}
                                                                                                                        onChange={(event) => handleStatusChange(service.Sb_Id, event.target.value, service.Ss_Id)}
                                                                                                                >
                                                                                                                        {[
                                                                                                                                { label: 'ServicePlaced', value: 'ServicePlaced' },
                                                                                                                                { label: 'Accepted', value: 'Accepted' },
                                                                                                                                { label: 'PartnerAccepted', value: 'PartnerAccepted' },
                                                                                                                                { label: 'OnGoing', value: 'OnGoing' },
                                                                                                                                { label: 'Done', value: 'Done' },
                                                                                                                                { label: 'Cancelled', value: 'IN-ACTIVE' },
                                                                                                                        ].map((option) => (
                                                                                                                                <MenuItem key={option.value} value={option.value}>
                                                                                                                                        {option.label}
                                                                                                                                </MenuItem>
                                                                                                                        ))}
                                                                                                                </Select>

                                                                                                        </FormControl>

                                                                                                </Box>
                                                                                                <Button variant="outlined" color="primary" onClick={() => navigate(`/app/ServiceBookingdetails-management/${service.Sb_Id}`)}>
                                                                                                        VIEW DETAILS
                                                                                                </Button>
                                                                                        </Box>

                                                                                </CardContent>
                                                                        </Card>
                                                                </Grid>
                                                        );
                                                })}
                                        </Grid>
                                        <Box sx={{ marginTop: "2%", display: "flex", justifyContent: "center" }}>
                                                <Pagination
                                                        count={pagination.TotalPages}
                                                        page={pagination.PageNo}
                                                        onChange={handlePageChange}
                                                        color="primary"
                                                />
                                        </Box>
                                </Container>
                                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                                        <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "2%", width: "96%" }}>
                                                <CloseIcon onClick={() => setOpenDialog(false)} />
                                        </Box>
                                        <DialogTitle> Select Partners </DialogTitle>
                                        <DialogContent>
                                                {partnerList.map((partner) => (
                                                        <Box
                                                                key={partner.Pss_Id}
                                                                onClick={() => {
                                                                        if (partner.Status !== 'IN-ACTIVE') {
                                                                                setSelectedPartnerId(partner.Partner_Details_Id);
                                                                                triggerEditAPI(selectedSsId, serviceStatuses[selectedSsId], partner.Partner_Details_Id);
                                                                                setOpenDialog(false);
                                                                        }
                                                                }}
                                                                sx={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        flexDirection: "column",
                                                                        marginBottom: "10px",
                                                                        padding: "10px",
                                                                        borderRadius: "5px",
                                                                        cursor: partner.Status === 'IN-ACTIVE' ? "not-allowed" : "pointer",
                                                                        backgroundColor: selectedPartnerId === partner.Partner_Details_Id ? "rgba(0, 123, 255, 0.2)" : "transparent",
                                                                        "&:hover": {
                                                                                backgroundColor: partner.Status !== 'IN-ACTIVE' && "rgba(0, 123, 255, 0.1)",
                                                                        },
                                                                        filter: partner.Status === 'IN-ACTIVE' ? "blur(1px)" : "none",
                                                                }}
                                                        >
                                                                {partner.Status === 'IN-ACTIVE' ? (
                                                                        <Tooltip title={`Make ${partner.Ss_Name} Active To Access`} arrow>
                                                                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                                                        <Typography variant="body1">Partner Name: {partner.Name}</Typography>
                                                                                        <Typography variant="body2">Partner Status: {partner.Status}</Typography>
                                                                                </Box>
                                                                        </Tooltip>
                                                                ) : (
                                                                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                                                <Typography variant="body1">Partner Name: {partner.Name}</Typography>
                                                                                <Typography variant="body2">Partner Status: {partner.Status}</Typography>
                                                                        </Box>
                                                                )}
                                                        </Box>
                                                ))}
                                        </DialogContent>
                                </Dialog>
                        </Box>
                </Box>
        );
};

export default ServiceBooking