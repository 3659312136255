import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardContent, Grid, TextField, Button, Box
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  getAllProducts, getAllMasters, getAllSizes, getAllDetails, getAllPartnerProducts, createNewInventoryAction
} from './../../store/actions/inventoryAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { CircularProgress } from '@mui/material';
import { getAllPartnersAddress } from 'src/store/actions/productpartnerAction';

const statusOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const lineOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'ONLINE',
    label: 'ONLINE'
  },
  {
    value: 'OFFLINE',
    label: 'OFFLINE'
  },
  {
    value: 'BOTH',
    label: 'BOTH'
  },

];

const AddNewInventory = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { Partner_Product_Id, Product_Master_Id, Partner_Details_Id, Product_Name } = location.state || {};
  const loginEmail = useSelector(state => state.auth.user);

  /// componentDidMount
  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllDetails());
    dispatch(getAllMasters());
    const formData = {
      Records_Filter: "FILTER",
      Product_Master_Id: Product_Master_Id
    }
    dispatch(getAllSizes(formData));
    const formDataAddrs = {
      Records_Filter: "FILTER",
      Partner_Details_Id: Partner_Details_Id,
      search: ''
    }
    dispatch(getAllPartnersAddress(formDataAddrs));
    dispatch(getAllPartnerProducts());
  }, [Product_Master_Id, Partner_Details_Id]);

  // const [masterid, setMasterid] = useState('');
  // const [partnerid, setPartnerid] = useState('');
  // const [Product, setProduct] = useState('');

  const isSubmited = useSelector(
    state => state.inventory.newinventoryCreate.isLoading
  );
  const errorMessage = useSelector(
    state => state.inventory.newinventoryCreate.error
  );
  // const inventoryPhysicalPartners = useSelector(
  //   state => state.inventory.inventoryPhysicalPartners.partners
  // );
  const address = useSelector(
    state => state.productPartner.partnerAddress.address
  );
  const sizes = useSelector(state => state.inventory.inventorySizes.sizes);

  // const setType = value => {
  //   let formData = {
  //     Records_Filter: 'FILTER',
  //     Product_Master_Id: value,
  //     Partner_Details_Id: partnerid
  //   };
  //   dispatch(getAllSizes(formData));
  // };

  return (
    <Formik
      initialValues={{
        Partner_Address_Id: '', Availability_Stock: '', Partner_Product_Id: '', Product_Size_Id: '',
        Offline_Min_Stock: '', Locker_Number: '', Availability_On: '', Availability_Status: '',
        Product_Master_Id: '', Created_By: loginEmail.Email_Id
      }}
      validationSchema={Yup.object().shape({
        Availability_Stock: Yup.string().required('Availability stock is required.'),
        Offline_Min_Stock: Yup.string().required('Offline Min Stock is required.'),
        // Partner_Product_Id: Yup.string().required(
        //   'Partner with Product Name is required.'
        // ),
        Product_Size_Id: Yup.string().required('Product Size is required.'),
        Partner_Address_Id: Yup.string().required('Partner Address is required.'),
        Availability_On: Yup.string().required('Availability is required.'),
        Availability_Status: Yup.string().required('Availability Status is required.')
      })}
      onSubmit={values => {
        let formData = JSON.parse(JSON.stringify({ ...values, Partner_Product_Id: Partner_Product_Id }));
        dispatch(createNewInventoryAction(formData, navigate));
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    {/* Partner Product Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        disabled={true}
                        value={Product_Name}
                        label="Product Name"
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>

                    {/* Product_Size */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.Product_Size_Id && errors.Product_Size_Id
                        )}
                        fullWidth
                        helperText={
                          touched.Product_Size_Id && errors.Product_Size_Id
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Product Size"
                        name="Product_Size_Id"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        value={values.Product_Size_Id}
                        variant="outlined"
                      >
                        <option key="" value="">
                          --Please Select--
                        </option>
                        {sizes.map(option => (
                          <option
                            value={option.Product_Size_Id}
                            key={option.Product_Size_Id}
                          >
                            {option.Size + ' - ' + option.Size_Measurement}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Partner Address Dropdown */}
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        options={address}
                        getOptionLabel={option => option.Location || ''}
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={Boolean(
                              touched.Partner_Address_Id &&
                              errors.Partner_Address_Id
                            )}
                            helperText={
                              touched.Partner_Address_Id &&
                              errors.Partner_Address_Id
                            }
                            onBlur={handleBlur}
                            label="Partner Address"
                            name="Partner_Address_Id"
                            variant="outlined"
                          />
                        )}
                        onChange={(event, value) => {
                          setFieldValue(
                            'Partner_Address_Id',
                            value?.Partner_Address_Id || ''
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.Availability_Stock &&
                          errors.Availability_Stock
                        )}
                        fullWidth
                        helperText={
                          touched.Availability_Stock &&
                          errors.Availability_Stock
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Availability Stock"
                        name="Availability_Stock"
                        type="number"
                        // select
                        // SelectProps={{ native: true }}
                        // InputLabelProps={{ shrink: true }}
                        value={values.Availability_Stock}
                        variant="outlined"
                      ></TextField>
                    </Grid>


                    {/* Product_Size */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.Offline_Min_Stock && errors.Offline_Min_Stock
                        )}
                        fullWidth
                        helperText={
                          touched.Offline_Min_Stock && errors.Offline_Min_Stock
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Offline Min Stock"
                        name="Offline_Min_Stock"
                        type="number"
                        // select
                        // SelectProps={{ native: true }}
                        // InputLabelProps={{ shrink: true }}
                        value={values.Offline_Min_Stock}
                        variant="outlined"
                      >
                        {/* <option key="" value="">--Please Select--</option>
                        {sizes.map(option => (
                          <option value={option.Product_Size_Id} key={option.Product_Size_Id}>{option.Size + " - " + option.Size_Measurement}</option>
                        ))} */}
                      </TextField>
                    </Grid>
                    {/* Total Stock */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.Locker_Number && errors.Locker_Number
                        )}
                        fullWidth
                        helperText={
                          touched.Locker_Number && errors.Locker_Number
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // type="string"
                        label="Locker Number"
                        name="Locker_Number"
                        value={values.Locker_Number}
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    {/* Available Date */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.Availability_On && errors.Availability_On
                        )}
                        fullWidth
                        helperText={
                          touched.Availability_On && errors.Availability_On
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Availability On"
                        name="Availability_On"
                        select
                        SelectProps={{ native: true }}
                        value={values.Availability_On}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {lineOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Availability Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.Availability_Status &&
                          errors.Availability_Status
                        )}
                        fullWidth
                        helperText={
                          touched.Availability_Status &&
                          errors.Availability_Status
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Availability Status"
                        name="Availability_Status"
                        select
                        SelectProps={{ native: true }}
                        value={values.Availability_Status}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" pl={2}>
              <span style={{ color: 'red' }}>{errorMessage}</span>
            </Box>
            <Box display="flex" p={2}>
              {isSubmited ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={isSubmited}
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#27B6CC' }}
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              )}
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddNewInventory.propTypes = {
  className: PropTypes.string
};

export default AddNewInventory;
