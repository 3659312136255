import {

    PART_BID_QUOTES_CREATE_REQUEST,
    PART_BID_QUOTES_CREATE_SUCCESS,
    PART_BID_QUOTES_CREATE_FAIL,

    PART_BID_QUOTES_UPDATE_REQUEST,
    PART_BID_QUOTES_UPDATE_SUCCESS,
    PART_BID_QUOTES_UPDATE_FAIL,

    BID_QUOTE_PARTNERS_GET_REQUEST,
    BID_QUOTE_PARTNERS_GET_SUCCESS,
    BID_QUOTE_PARTNERS_GET_FAIL,

} from '../actions/partnerbiddingquotesAction'

const initialState = {
    partnerbidquoteCreate: { partnerbidquote: {}, error: '', isLoading: false },
    partnerbidquoteUpdate: { partnerbidquote: {}, error: '', isLoading: false },
    bidquotepartners: { all: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case PART_BID_QUOTES_CREATE_REQUEST:
            return { ...state, partnerbidquoteCreate: { partnerbidquote: {}, error: '', isLoading: true } };
        case PART_BID_QUOTES_CREATE_SUCCESS:
            return { ...state, partnerbidquoteCreate: { partnerbidquote: state.partnerbidquoteCreate.partbidavailprod, error: '', isLoading: false } };
        case PART_BID_QUOTES_CREATE_FAIL:
            return { ...state, partnerbidquoteCreate: { partnerbidquote: {}, error: action.payload, isLoading: false } };

        case PART_BID_QUOTES_UPDATE_REQUEST:
            return { ...state, partnerbidquoteUpdate: { partnerbidquote: {}, error: '', isLoading: true } };
        case PART_BID_QUOTES_UPDATE_SUCCESS:
            return { ...state, partnerbidquoteUpdate: { partnerbidquote: state.partnerbidquoteUpdate.partbidavailprod, error: '', isLoading: false } };
        case PART_BID_QUOTES_UPDATE_FAIL:
            return { ...state, partnerbidquoteUpdate: { partnerbidquote: {}, error: action.payload, isLoading: false } };

        case BID_QUOTE_PARTNERS_GET_REQUEST:
            return { ...state, bidquotepartners: { all: state.bidquotepartners.all, error: '', isLoading: true } };
        case BID_QUOTE_PARTNERS_GET_SUCCESS:
            return { ...state, bidquotepartners: { all: action.payload, error: '', isLoading: false } };
        case BID_QUOTE_PARTNERS_GET_FAIL:
            return { ...state, bidquotepartners: { all: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}