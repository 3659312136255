import { toast } from 'react-toastify';
import { paginationcitycategoryServices } from './node-actions/nodepagination';
import { boomiAdminApi, nodeServiceApi, urlGenarator } from './commonAxios';
import Axios from 'axios';

export const SERVICE_CATEGORY_CITY_CREATE_REQUEST = "SERVICE_CATEGORY_CITY_CREATE_REQUEST";
export const SERVICE_CATEGORY_CITY_CREATE_SUCCESS = "SERVICE_CATEGORY_CITY_CREATE_SUCCESS";
export const SERVICE_CATEGORY_CITY_CREATE_FAIL = "SERVICE_CATEGORY_CITY_CREATE_FAIL";
export const SERVICE_CATEGORY_CITY_UPDATE_REQUEST = "SERVICE_CATEGORY_CITY_UPDATE_REQUEST";
export const SERVICE_CATEGORY_CITY_UPDATE_SUCCESS = "SERVICE_CATEGORY_CITY_UPDATE_SUCCESS";
export const SERVICE_CATEGORY_CITY_UPDATE_FAIL = "SERVICE_CATEGORY_CITY_UPDATE_FAIL ";

/*=============================================================
                  Add City Service Action
==============================================================*/
export const AddCityCategorytypeAction = (formData, navigate,state) => async dispatch => {
  dispatch({
    type: SERVICE_CATEGORY_CITY_CREATE_REQUEST
  });
  console.log(formData,"// 20")
  nodeServiceApi.post(`/admin/service_variant_city_charge/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICE_CATEGORY_CITY_CREATE_SUCCESS
        });
        toast(' City Service Created Successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/servicescity-management', state = { state });
      } else {
        dispatch({
          type: SERVICE_CATEGORY_CITY_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
        toast(successResponse.UI_Display_Message+'Please change country, state and city.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type:SERVICE_CATEGORY_CITY_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Update City Service Action
===============================================================*/
export const EditServiceCityCategorytypeAction = (formData, navigate,state) => async dispatch => {
  console.log(formData, "ffffffffffggggg")
  dispatch({
    type: SERVICE_CATEGORY_CITY_UPDATE_REQUEST
  });
  nodeServiceApi.put(`/admin/service_variant_city_charge/Update/${formData.Svcc_Id}`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICE_CATEGORY_CITY_UPDATE_SUCCESS,
        });
        toast(' City Service Updated Successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/servicescity-management',state = { state });
      } else {
        dispatch({
          type: SERVICE_CATEGORY_CITY_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: SERVICE_CATEGORY_CITY_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};


/*=============================================================
              Delete City Service Action
 ===============================================================*/
export const deletecitycategoryAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeServiceApi.delete(`/admin/service_variant_city_charge/Delete/${formData.id}`,);
    if (data) {
      toast(' City Service deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    }
    dispatch(paginationcitycategoryServices(filters, pagination, callBackPagination));
  } 
catch (err) {
  }
};

