import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { ConnectedFocusError } from "focus-formik-error";
import { updateUserFinanceTransactionAction } from 'src/store/actions/userfinancialtransactionsAction';
import { CircularProgress } from '@mui/material';

const TranscationStatus = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Initiated',
    label: 'Initiated'
  },
  {
    value: 'Queued',
    label: 'Queued'
  },
  {
    value: 'Settled',
    label: 'Settled'
  },
  {
    value: 'Rejected',
    label: 'Rejected'
  },
];

const StatusOptions = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  },
];

const EditUserFinancialTransaction = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = location;

  const errorMessage = useSelector(state => state.userfinancialtransaction.userfinancialtransactionUpdate.error);
  const isSubmited = useSelector(state => state.userfinancialtransaction.userfinancialtransactionUpdate.isLoading);
  const updatedBy = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        User_Financial_Transaction_Id: state.User_Financial_Transaction_Id ? state.User_Financial_Transaction_Id : "",
        User_Email_Id: state.User_Email_Id ? state.User_Email_Id : "",
        Transaction_Type: state.Transaction_Type ? state.Transaction_Type : "",
        Transaction_Date: state.Transaction_Date ? state.Transaction_Date : "",
        Transaction_Reference_Meepaisa_Id: state.Transaction_Reference_Meepaisa_Id ? state.Transaction_Reference_Meepaisa_Id : "",
        Transaction_Payment_Reference_Id: state.Trasaction_Payment_Reference_Id ? state.Trasaction_Payment_Reference_Id : "",
        Transaction_From: state.Transaction_From ? state.Transaction_From : "",
        Transaction_Status: state.Transaction_Status ? state.Transaction_Status : "",
        Transaction_Channel: state.Trasaction_Channel ? state.Trasaction_Channel : "",
        Reason: state.Reason ? state.Reason : "",
        Payment_Method: state.Payment_Method ? state.Payment_Method : "",
        Amount: state.Amount ? state.Amount : "",
        Status: state.Status ? state.Status : "",
        Modified_By: updatedBy,
      }}
      validationSchema={
        Yup.object().shape({
          Transaction_Status: Yup.string().required('Transaction Status is required.'),
          Status: Yup.string().required('Status is required.'),
        })
      }
      onSubmit={(values) => {
        if (values) {
          let formData = JSON.parse(JSON.stringify(values));
          dispatch(updateUserFinanceTransactionAction(formData, navigate));
        }
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="User Email ID"
                        name="User_Email_Id"
                        value={values.User_Email_Id}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Transaction Type"
                        name="Transaction_Type"
                        value={values.Transaction_Type}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Transaction Date"
                        type="date"
                        name="Transaction_Date"
                        defaultValue={values.Transaction_Date}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Transaction Reference Meepaisa ID"
                        name="Transaction_Reference_Meepaisa_Id"
                        value={values.Transaction_Reference_Meepaisa_Id}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Transaction Payment Reference ID"
                        name="Transaction_Payment_Reference_Id"
                        value={values.Transaction_Payment_Reference_Id}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Transaction From"
                        name="Transaction_From"
                        value={values.Transaction_From}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Transaction Channel"
                        name="Transaction_Channel"
                        value={values.Transaction_Channel}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Payment Method"
                        name="Payment_Method"
                        value={values.Payment_Method}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Reason"
                        name="Reason"
                        value={values.Reason}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Amount"
                        name="Amount"
                        type="number"
                        value={values.Amount}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Transaction_Status && errors.Transaction_Status)}
                        fullWidth
                        helperText={touched.Transaction_Status && errors.Transaction_Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Transaction Status"
                        name="Transaction_Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Transaction_Status}
                        variant="outlined"
                      >
                        {TranscationStatus.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {StatusOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditUserFinancialTransaction.propTypes = {
  className: PropTypes.string
};

export default EditUserFinancialTransaction;