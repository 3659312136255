import {
  STORETYPE_PARTNER_LINKING_REQUEST,
  STORETYPE_PARTNER_LINKING_SUCCESS,
  STORETYPE_PARTNER_LINKING_FAIL,
  STORETYPE_PARTNER_LINKING_GET_REQUEST,
  STORETYPE_PARTNER_LINKING_GET_SUCCESS,
  STORETYPE_PARTNER_LINKING_GET_FAIL,
  STORETYPE_PARTNER_LINKING_CREATE_REQUEST,
  STORETYPE_PARTNER_LINKING_CREATE_SUCCESS,
  STORETYPE_PARTNER_LINKING_CREATE_FAIL,
  STORETYPE_PARTNER_LINKING_UPDATE_REQUEST,
  STORETYPE_PARTNER_LINKING_UPDATE_SUCCESS,
  STORETYPE_PARTNER_LINKING_UPDATE_FAIL,
  GET_PARTNERS_REQUEST,
  GET_PARTNERS_SUCCESS,
  GET_PARTNERS_FAIL,

} from '../actions/storetypepartnerlinkingActions'

const initialState = {
  storetypespartnerlink: { all: [], error: '', isLoading: false },
  storetypepartnerlink: { storetypepartner: {}, error: '', isLoading: false },
  storetypepartnerlinkCreate: { storetypepartner: {}, error: '', isLoading: false },
  storetypepartnerlinkUpdate: { storetypepartner: {}, error: '', isLoading: false },
  Partners: { partners: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STORETYPE_PARTNER_LINKING_REQUEST:
      return { ...state, storetypespartnerlink: { all: state.storetypespartnerlink.all, error: '', isLoading: true } };
    case STORETYPE_PARTNER_LINKING_SUCCESS:
      return { ...state, storetypespartnerlink: { all: action.payload, error: '', isLoading: false } };
    case STORETYPE_PARTNER_LINKING_FAIL:
      return { ...state, storetypespartnerlink: { all: [], error: action.payload, isLoading: false } };
    case STORETYPE_PARTNER_LINKING_GET_REQUEST:
      return { ...state, storetypepartnerlink: { storetypepartnerlink: {}, error: '', isLoading: true } };
    case STORETYPE_PARTNER_LINKING_GET_SUCCESS:
      return { ...state, storetypepartnerlink: { storetypepartnerlink: action.payload, error: '', isLoading: false } };
    case STORETYPE_PARTNER_LINKING_GET_FAIL:
      return { ...state, storetypepartnerlink: { storetypepartnerlink: {}, error: action.payload, isLoading: false } };
    case STORETYPE_PARTNER_LINKING_CREATE_REQUEST:
      return { ...state, storetypelinkCreate: { storetypepartnerlink: {}, error: '', isLoading: true } };
    case STORETYPE_PARTNER_LINKING_CREATE_SUCCESS:
      return { ...state, storetypelinkCreate: { storetypepartnerlink: state.storetypelinkCreate.storetypepartnerlink, error: '', isLoading: false } };
    case STORETYPE_PARTNER_LINKING_CREATE_FAIL:
      return { ...state, storetypelinkCreate: { storetypepartnerlink: {}, error: action.payload, isLoading: false } };
    case STORETYPE_PARTNER_LINKING_UPDATE_REQUEST:
      return { ...state, storetypelinkUpdate: { storetypepartnerlink: {}, error: '', isLoading: true } };
    case STORETYPE_PARTNER_LINKING_UPDATE_SUCCESS:
      return { ...state, storetypelinkUpdate: { storetypepartnerlink: state.storetypelinkUpdate.storetypepartnerlink, error: '', isLoading: false } };
    case STORETYPE_PARTNER_LINKING_UPDATE_FAIL:
      return { ...state, storetypelinkUpdate: { storetypepartnerlink: {}, error: action.payload, isLoading: false } };

    case GET_PARTNERS_REQUEST:
      return { ...state, Partners: { partners: state.Partners.partners, error: '', isLoading: true }, };
    case GET_PARTNERS_SUCCESS:
      return { ...state, Partners: { partners: action.payload, error: '', isLoading: false } };
    case GET_PARTNERS_FAIL:
      return { ...state, Partners: { partners: [], error: action.payload, isLoading: false } };
    default:
      return state;
  }
}