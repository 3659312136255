import {
  DEALS_REQUEST,
  DEALS_SUCCESS,
  DEALS_FAIL,
  DEAL_GET_REQUEST,
  DEAL_GET_SUCCESS,
  DEAL_GET_FAIL,
  DEAL_CREATE_REQUEST,
  DEAL_CREATE_SUCCESS,
  DEAL_CREATE_FAIL,
  DEAL_UPDATE_REQUEST,
  DEAL_UPDATE_SUCCESS,
  DEAL_UPDATE_FAIL,
  DEAL_PARTNERS_REQUEST,
  DEAL_PARTNERS_SUCCESS,
  DEAL_PARTNERS_FAIL,
  DEAL_BRANDS_REQUEST,
  DEAL_BRANDS_SUCCESS,
  DEAL_BRANDS_FAIL,
  CATEGORIES_REQUEST,
  CATEGORIES_SUCCESS,
  CATEGORIES_FAIL,

  DEALS_APPROVE_REQUEST,
  DEALS_APPROVE_SUCCESS,
  DEALS_APPROVE_FAIL
} from '../actions/dealAction'

const initialState = {
  deals: { all: [], error: '', isLoading: false },
  deal: { deal: {}, error: '', isLoading: false },
  dealCreate: { deal: {}, error: '', isLoading: false },
  dealUpdate: { deal: {}, error: '', isLoading: false },
  dealPartners: { partners: [], error: '', isLoading: false },
  dealBrands: { brands: [], error: '', isLoading: false },
  Categories: { categories: [], error: '', isLoading: false },
  dealsapproval: { all: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DEALS_REQUEST:
      return { ...state, deals: { all: state.deals.all, error: '', isLoading: true } };
    case DEALS_SUCCESS:
      return { ...state, deals: { all: action.payload, error: '', isLoading: false } };
    case DEALS_FAIL:
      return { ...state, deals: { all: [], error: action.payload, isLoading: false } };
    case DEAL_GET_REQUEST:
      return { ...state, deal: { deal: {}, error: '', isLoading: true } };
    case DEAL_GET_SUCCESS:
      return { ...state, deal: { deal: action.payload, error: '', isLoading: false } };
    case DEAL_GET_FAIL:
      return { ...state, deal: { deal: {}, error: action.payload, isLoading: false } };
    case DEAL_CREATE_REQUEST:
      return { ...state, dealCreate: { deal: {}, error: '', isLoading: true } };
    case DEAL_CREATE_SUCCESS:
      return { ...state, dealCreate: { deal: state.dealCreate.deal, error: '', isLoading: false } };
    case DEAL_CREATE_FAIL:
      return { ...state, dealCreate: { deal: {}, error: action.payload, isLoading: false } };
    case DEAL_UPDATE_REQUEST:
      return { ...state, dealUpdate: { deal: {}, error: '', isLoading: true } };
    case DEAL_UPDATE_SUCCESS:
      return { ...state, dealUpdate: { deal: state.dealCreate.deal, error: '', isLoading: false } };
    case DEAL_UPDATE_FAIL:
      return { ...state, dealUpdate: { deal: {}, error: action.payload, isLoading: false } };
    case DEAL_PARTNERS_REQUEST:
      return { ...state, dealPartners: { partners: state.dealPartners.partners, error: '', isLoading: true }, };
    case DEAL_PARTNERS_SUCCESS:
      return { ...state, dealPartners: { partners: action.payload, error: '', isLoading: false } };
    case DEAL_PARTNERS_FAIL:
      return { ...state, dealPartners: { partners: [], error: action.payload, isLoading: false } };
    case DEAL_BRANDS_REQUEST:
      return { ...state, dealBrands: { brands: state.dealBrands.brands, error: '', isLoading: true }, };
    case DEAL_BRANDS_SUCCESS:
      return { ...state, dealBrands: { brands: action.payload, error: '', isLoading: false } };
    case DEAL_BRANDS_FAIL:
      return { ...state, dealBrands: { brands: [], error: action.payload, isLoading: false } };

    case CATEGORIES_REQUEST:
      return { ...state, Categories: { categories: state.Categories.categories, error: '', isLoading: true }, };
    case CATEGORIES_SUCCESS:
      return { ...state, Categories: { categories: action.payload, error: '', isLoading: false } };
    case CATEGORIES_FAIL:
      return { ...state, Categories: { categories: [], error: action.payload, isLoading: false } };

    case DEALS_APPROVE_REQUEST:
      return { ...state, dealsapproval: { all: state.dealsapproval.all, error: '', isLoading: true } };
    case DEALS_APPROVE_SUCCESS:
      return { ...state, dealsapproval: { all: action.payload, error: '', isLoading: false } };
    case DEALS_APPROVE_FAIL:
      return { ...state, dealsapproval: { all: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}