import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  buttonRoot: {
    minWidth: '7px',
    padding: '6px'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));

const headCells = [
  { id: 'Partner_Settlement_Id', numeric: false, disablePadding: false, label: 'Partner Settlement ID' },
  { id: 'Total_Order_Value', numeric: false, disablePadding: false, label: 'Total Order Amount' },
  { id: 'Meepaisa_Commission_Amount', numeric: false, disablePadding: false, label: 'ESI Commission Amount' },
  { id: 'Final_Settlement_Amount', numeric: true, disablePadding: false, label: 'Final Settlement Amount' },
  { id: 'Payment_Transaction_Status', numeric: false, disablePadding: false, label: 'Payment Status' },
];

const Results = ({
  className,
  allPartnerpayments,
  setPagination,
  pagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allPartnerpaymentsNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedPartnerpaymentIds, setSelectedPartnerpaymentIds] = useState([]);

  const editPartnerpaymentHandler = partnerpaymentData => e => {
    navigate('/app/partner-payment-settlements-edit', { state: partnerpaymentData });
  };
  const visiblePartnerpaymentHandler = partnerpaymentData => e => {
    navigate("/app/partner-payment-settlements-details", { state: partnerpaymentData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allPartnerpayments) &&
                (allPartnerpayments).map(partnerpayment => (
                  <TableRow
                    hover
                    key={partnerpayment.Partner_Payment_Settlement_Id}
                    selected={
                      selectedPartnerpaymentIds.indexOf(partnerpayment.Partner_Payment_Settlement_Id) !== -1
                    }
                  >
                    <TableCell>{partnerpayment.Partner_Settlement_Id}</TableCell>
                    <TableCell>{partnerpayment.Total_Order_Value}</TableCell>
                    <TableCell>{partnerpayment.Meepaisa_Commission_Amount}</TableCell>
                    <TableCell>{partnerpayment.Final_Settlement_Amount}</TableCell>
                    <TableCell>{partnerpayment.Payment_Transaction_Status}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Partner_Payments_Settlements_Write?.Value === "Partner_Payments_Settlements_Write" && RolePermissions?.Partner_Payments_Settlements_Write?.Is_Write === 1) ?
                      <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                          to="/app/transaction-history-details"
                          onClick={visiblePartnerpaymentHandler(partnerpayment)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button
                          onClick={editPartnerpaymentHandler(partnerpayment)}  >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Partner_Payments_Settlements_Read?.Value === "Partner_Payments_Settlements_Read" && RolePermissions?.Partner_Payments_Settlements_Read?.Is_Read === 1 &&
                      <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                          to="/app/transaction-history-details"
                          onClick={visiblePartnerpaymentHandler(partnerpayment)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allPartnerpayments) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allPartnerpayments: PropTypes.array.isRequired
};

export default Results;