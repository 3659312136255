
import config from './config';
import { toast } from 'react-toastify';
import { paginationProductcolorsGet } from './node-actions/nodepagination';
import { nodeCudApi } from './commonAxios';

export const PRODUCTCOLORVARIANT_CREATE_REQUEST = "PRODUCTCOLORVARIANT_CREATE_REQUEST";
export const PRODUCTCOLORVARIANT_CREATE_SUCCESS = "PRODUCTCOLORVARIANT_CREATE_SUCCESS";
export const PRODUCTCOLORVARIANT_CREATE_FAIL = "PRODUCTCOLORVARIANT_CREATE_FAIL";
export const PRODUCTCOLORVARIANT_UPDATE_REQUEST = "PRODUCTCOLORVARIANT_UPDATE_REQUEST";
export const PRODUCTCOLORVARIANT_UPDATE_SUCCESS = "PRODUCTCOLORVARIANT_UPDATE_SUCCESS";
export const PRODUCTCOLORVARIANT_UPDATE_FAIL = "PRODUCTCOLORVARIANT_UPDATE_FAIL";

/*=============================================================
              Add Product color Variant Action
===============================================================*/


export const createProductColorVariantAction = (formData, state, navigate) => async dispatch => {
  dispatch({
    type: PRODUCTCOLORVARIANT_CREATE_REQUEST
  });
  nodeCudApi.post(`/Products/ColorVariantCreate`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PRODUCTCOLORVARIANT_CREATE_SUCCESS
        });
        toast('Product color variant added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/product-management/product-color-variants', state = { state });
      } else {
        dispatch({
          type: PRODUCTCOLORVARIANT_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PRODUCTCOLORVARIANT_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
               update Product Color Variant Action
  ===============================================================*/
export const updateProductColorVariantAction = (formData, state, navigate) => async dispatch => {
  dispatch({
    type: PRODUCTCOLORVARIANT_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Products/ColorVariantUpdate`, formData
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PRODUCTCOLORVARIANT_UPDATE_SUCCESS
        });
        toast('Product color variant updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/product-management/product-color-variants', state = { state });
      } else {
        dispatch({
          type: PRODUCTCOLORVARIANT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PRODUCTCOLORVARIANT_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
             Delete Product Color Variant Action
===============================================================*/
export const deleteProductColorVariantAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Products/ColorVariantDelete`, formData
    );
    if (data) {
      toast('Product color variant deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationProductcolorsGet(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};