import { Card, CardContent, Grid } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import { CardMedia } from '@mui/material';

const ServicesViewsubService = ({ className, ...rest }) => {
    const location = useLocation();
    let { state } = location;

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            {/* User_Service_Request_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    sub Service ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Ss_Id}
                                </Typography>
                            </Grid>

                            {/* User_Email_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Status
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Status}
                                </Typography>
                            </Grid>

                            {/*Service_Person_Name */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Service Id
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Service_Id}
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Service Name
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Service_Name}
                                </Typography>
                            </Grid>


                            {/*Service_person_Mobile_Number */}

                            {/* Created_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state?.Created_By}> {state?.Created_By?.length > 25 ?
                                        <span>{state?.Created_By?.substring(0, 25)}...</span> : state?.Created_By}</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified Datetime
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Modified_Datetime}
                                </Typography>
                            </Grid>



                            <Grid item xs={2} />
                            <Grid item xs={2} />
                            <Grid item xs={7} />
                        </Grid>
                    </Grid>

                    {/*  column 2 */}
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            {/*Partner_Detail_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Sub Service Name
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Ss_Name}
                                </Typography>
                            </Grid>

                            {/*Service_Type */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created Datetime
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state?.Created_Datetime}> {state?.Created_Datetime?.length > 25 ?
                                        <span>{state?.Created_Datetime?.substring(0, 25)}...</span> : state?.Created_Datetime}</span>
                                </Typography>
                            </Grid>

                            {/*Service_Person_Email_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Image
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>

                                <CardMedia component="img" image={state?.Image} sx={{ objectFit: 'contain', width: "80%", height: "50%", paddingLeft: '3rem' }} />
                            </Grid>

                            {/* Payment_Method */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Modified_By}
                                </Typography>
                            </Grid>



                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography
                            variant="h5"
                            component="h2"
                            gutterBottom
                            style={{ color: '#66788A' }}
                        >
                            Sub Service Description
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Typography
                            variant="h5"
                            component="h2"
                            gutterBottom
                            style={{ paddingLeft: '3rem' }}
                        >
                            {state?.Description}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography
                            variant="h5"
                            component="h2"
                            gutterBottom
                            style={{ color: '#66788A' }}
                        >
                            Sub Service Sub_Description
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Typography
                            variant="h5"
                            component="h2"
                            gutterBottom
                        >
                            {state?.Sub_Description}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};


export default ServicesViewsubService;