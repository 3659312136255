import config from './config';
import { toast } from 'react-toastify';
import { paginationStoreTypeManagement } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi, boomiAdminApi } from './commonAxios';

export const STORETYPE_REQUEST = "STORETYPE_REQUEST ";
export const STORETYPE_SUCCESS = "STORETYPE_SUCCESS";
export const STORETYPE_FAIL = "STORETYPE_FAIL";
export const STORETYPE_GET_REQUEST = "STORETYPE_GET_REQUEST ";
export const STORETYPE_GET_SUCCESS = "STORETYPE_GET_SUCCESS";
export const STORETYPE_GET_FAIL = "STORETYPE_GET_FAIL";
export const STORETYPE_CREATE_REQUEST = "STORETYPE_CREATE_REQUEST";
export const STORETYPE_CREATE_SUCCESS = "STORETYPE_CREATE_SUCCESS";
export const STORETYPE_CREATE_FAIL = "STORETYPE_CREATE_FAIL";
export const STORETYPE_UPDATE_REQUEST = "STORETYPE_UPDATE_REQUEST";
export const STORETYPE_UPDATE_SUCCESS = "STORETYPE_UPDATE_SUCCESS";
export const STORETYPE_UPDATE_FAIL = "STORETYPE_UPDATE_FAIL ";

/*=============================================================
                  Add Store type Action
==============================================================*/
export const AddStoretypeAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: STORETYPE_CREATE_REQUEST
  });
  nodeCudApi.post(`/Store_Type/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: STORETYPE_CREATE_SUCCESS
        });
        toast('Store Type created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/store-type');
      } else {
        dispatch({
          type: STORETYPE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: STORETYPE_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Update Store type Action
===============================================================*/
export const EditStoretypeAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: STORETYPE_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Store_Type/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: STORETYPE_UPDATE_SUCCESS
        });
        toast('Store Type updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/store-type');
      } else {
        dispatch({
          type: STORETYPE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: STORETYPE_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Get All Store type Action
 ===============================================================*/
export const getAllStoretypeAction = formData => async dispatch => {
  try {
    dispatch({
      type: STORETYPE_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Store_Type/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=STORE_TYPE_NAME&SortOrder=ASC `,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: STORETYPE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: STORETYPE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again.", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    dispatch({
      type: STORETYPE_FAIL,
      payload: err
    });
  }
};

/*=============================================================
              Delete  Delivery Payment Action
 ===============================================================*/
export const deletestoretypeAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Store_Type/Delete`, formData
    );
    if (data) {
      toast(' Store Type deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationStoreTypeManagement(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

/*=============================================================
            partner logo Image Upload Action
===============================================================*/
export const uploadImagepath = (
  formData,
  callBackUploadImgae,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=StoreType&fileextension=png&filetype=Images&filename=StoreType`,
      formData
    );
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) {
  }
};