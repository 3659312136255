import { nodeGetApi } from './../commonAxios';
export const DASH_BOARD_TOTAL_ORDER = "DASH_BOARD_TOTAL_ORDER";
export const DASH_BOARD_TOTAL_PROFIT = "DASH_BOARD_TOTAL_PROFIT";
export const DASH_BOARD_TOTAL_CASHBACK = "DASH_BOARD_TOTAL_CASHBACK";
export const DASH_BOARD_TOTAL_USERS = "DASH_BOARD_TOTAL_USERS";
export const DASH_BOARD_TOTAL_PARTNERS = "DASH_BOARD_TOTAL_PARTNERS";
export const DASH_BOARD_ONLINE_PARTNERS = "DASH_BOARD_ONLINE_PARTNERS";
export const DASH_BOARD_PHYSICAL_PARTNERS = "DASH_BOARD_PHYSICAL_PARTNERS";
export const DASH_BOARD_TOTAL_BRANDS = "DASH_BOARD_TOTAL_BRANDS";
export const DASH_BOARD_TOTAL_COUPONS = "DASH_BOARD_TOTAL_COUPONS";
export const DASH_BOARD_TOTAL_DEALS = "DASH_BOARD_TOTAL_DEALS";
export const DASH_BOARD_TOTAL_PRODUCTS = "DASH_BOARD_TOTAL_PRODUCTS";
export const DASH_BOARD_TOP_PRODUCTS = "DASH_BOARD_TOP_PRODUCTS";
export const DASH_BOARD_TOP_COUPONS = "DASH_BOARD_TOP_COUPONS";
export const DASH_BOARD_TOP_DEALS = "DASH_BOARD_TOP_DEALS";


/*=============================================================
                  orderCount Action
===============================================================*/

export const orderCount = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "ALL",
            "Date": ''
        };
        nodeGetApi.post(`/Reports/Orders/Count`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DASH_BOARD_TOTAL_ORDER,
                        data: data.results[0],
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: DASH_BOARD_TOTAL_ORDER,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASH_BOARD_TOTAL_ORDER,
                    data: String(error),
                    succress: false
                });
            })
    }

}

/*=============================================================
                  profitCount Action
  =============================================================*/

export const profitCount = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "ALL",
            "Date": ''
        };
        nodeGetApi.post(`/Reports/ESI_Profit/Count`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DASH_BOARD_TOTAL_PROFIT,
                        data: data.results[0],
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: DASH_BOARD_TOTAL_PROFIT,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASH_BOARD_TOTAL_PROFIT,
                    data: String(error),
                    succress: false
                });
            })
    }

}

/*=============================================================
                  cashbackCount Action
  =============================================================*/

export const cashbackCount = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "ALL",
            "Date": ''
        };
        nodeGetApi.post(`/Reports/Users_Cashback/Count`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DASH_BOARD_TOTAL_CASHBACK,
                        data: data.results[0],
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: DASH_BOARD_TOTAL_CASHBACK,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASH_BOARD_TOTAL_CASHBACK,
                    data: String(error),
                    succress: false
                });
            })
    }

}

/*=============================================================
                  usersCount Action
  =============================================================*/

export const usersCount = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "ALL",
            "Date": ''
        };
        nodeGetApi.post(`/Reports/Users/Count`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DASH_BOARD_TOTAL_USERS,
                        data: data.results[0],
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: DASH_BOARD_TOTAL_USERS,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASH_BOARD_TOTAL_USERS,
                    data: String(error),
                    succress: false
                });
            })
    }

}

/*=============================================================
                  partnersCount Action
  =============================================================*/

export const partnersCount = (payload) => {
    return function (dispatch) {
        nodeGetApi.post(`/Reports/Partners/Count`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DASH_BOARD_TOTAL_PARTNERS,
                        data: data.results[0],
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: DASH_BOARD_TOTAL_PARTNERS,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASH_BOARD_TOTAL_PARTNERS,
                    data: String(error),
                    succress: false
                });
            })
    }

}
/*=============================================================
                  brandsCount Action
  =============================================================*/

export const brandsCount = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "ALL",
            "Date": ''
        };
        nodeGetApi.post(`/Reports/Brands/Count`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DASH_BOARD_TOTAL_BRANDS,
                        data: data.results[0],
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: DASH_BOARD_TOTAL_BRANDS,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASH_BOARD_TOTAL_BRANDS,
                    data: String(error),
                    succress: false
                });
            })
    }
}
/*=============================================================
                  couponsCount Action
  =============================================================*/

export const couponsCount = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "ALL",
            "Date": ''
        };
        nodeGetApi.post(`/Reports/Coupons/Count`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DASH_BOARD_TOTAL_COUPONS,
                        data: data.results[0],
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: DASH_BOARD_TOTAL_COUPONS,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASH_BOARD_TOTAL_COUPONS,
                    data: String(error),
                    succress: false
                });
            })
    }

}

/*=============================================================
                  dealsCount Action
  =============================================================*/
export const dealsCount = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "ALL",
            "Date": ''
        };
        nodeGetApi.post(`/Reports/Deals/Count`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DASH_BOARD_TOTAL_DEALS,
                        data: data.results[0],
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: DASH_BOARD_TOTAL_DEALS,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASH_BOARD_TOTAL_DEALS,
                    data: String(error),
                    succress: false
                });
            })
    }

}
/*=============================================================
                  productsCount Action
  =============================================================*/

export const productsCount = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "ALL",
            "Date": ''
        };
        nodeGetApi.post(`/Reports/Products/Count`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DASH_BOARD_TOTAL_PRODUCTS,
                        data: data.results[0],
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: DASH_BOARD_TOTAL_PRODUCTS,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASH_BOARD_TOTAL_PRODUCTS,
                    data: String(error),
                    succress: false
                });
            })
    }

}
/*=============================================================
                  topProducts Action
  =============================================================*/

export const topProducts = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "ALL",
            "Date": ''
        };
        nodeGetApi.post(`/Reports/Top/Products`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DASH_BOARD_TOP_PRODUCTS,
                        data: data.results,
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: DASH_BOARD_TOP_PRODUCTS,
                        data: data.Success.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASH_BOARD_TOP_PRODUCTS,
                    data: String(error),
                    succress: false
                });
            })
    }

}
/*=============================================================
                  topCoupons Action
  =============================================================*/

export const topCoupons = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "ALL",
            "Date": ''
        };
        nodeGetApi.post(`/Reports/Top/Coupons`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DASH_BOARD_TOP_COUPONS,
                        data: data.results,
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: DASH_BOARD_TOP_COUPONS,
                        data: data.Success.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASH_BOARD_TOP_COUPONS,
                    data: String(error),
                    succress: false
                });
            })
    }

}

/*=============================================================
                  topDeals Action
  =============================================================*/

export const topDeals = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "ALL",
            "Date": ''
        };
        nodeGetApi.post(`/Reports/Top/Deals`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DASH_BOARD_TOP_DEALS,
                        data: data.results,
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: DASH_BOARD_TOP_DEALS,
                        data: data.Success.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASH_BOARD_TOP_DEALS,
                    data: String(error),
                    succress: false
                });
            })
    }

}


//online partners get
export const OnlinePartnersCount = (payload) => {
    return function (dispatch) {
        nodeGetApi.post(`/Reports/Partners/Count`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DASH_BOARD_ONLINE_PARTNERS,
                        data: data.results[0],
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: DASH_BOARD_ONLINE_PARTNERS,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASH_BOARD_ONLINE_PARTNERS,
                    data: String(error),
                    succress: false
                });
            })
    }
}


//physical partners get
export const PhysicalPartnersCount = (payload) => {
    return function (dispatch) {
        nodeGetApi.post(`/Reports/Partners/Count`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: DASH_BOARD_PHYSICAL_PARTNERS,
                        data: data.results[0],
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: DASH_BOARD_PHYSICAL_PARTNERS,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASH_BOARD_PHYSICAL_PARTNERS,
                    data: String(error),
                    succress: false
                });
            })
    }
}