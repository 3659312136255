import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import DealApprovalList from './dealApprovalView';

// useStyles we use a hook to consume the styles
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));


const DealApprovalView = () => {

  // classes contains the styles
  const classes = useStyles();

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/pending-deals"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>

        <Box py={2}>
        </Box>
        <DealApprovalList />
      </Container>
    </Page>
  );
};

export default DealApprovalView;