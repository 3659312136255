import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    makeStyles
} from '@material-ui/core';
import isEmpty from 'src/store/validations/is-empty';
import CorpPagination from 'src/components/pagination/corppagination';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    }
}));

const headCells = [
    { id: 'Corp_Detail_Id', numeric: false, disablePadding: false, label: 'Corp detail id' },
    { id: 'id', numeric: false, disablePadding: false, label: 'Employee id' },
    { id: 'Organization_Name', numeric: false, disablePadding: false, label: 'Organization name' },
    { id: 'Full_Name', numeric: false, disablePadding: false, label: 'Employee name' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Employee email' },
    { id: 'Created_By', numeric: false, disablePadding: false, label: 'Created by' },
    { id: 'Created_Date', numeric: false, disablePadding: false, label: 'Created date' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
    className,
    allcorpUser,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    createSortHandler,
    pagination,
    state,
    ...rest
}) => {

    const classes = useStyles();

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}>
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allcorpUser) &&
                                (allcorpUser)?.map(corp => (
                                    <TableRow
                                        hover
                                        key={corp.id}>
                                        <TableCell>{corp.Corp_Detail_Id}</TableCell>
                                        <TableCell>{corp.id}</TableCell>
                                        <TableCell>{corp.Organization_Name}</ TableCell>
                                        <TableCell>{corp.Full_Name}</ TableCell>
                                        <TableCell>{corp.email}</TableCell>
                                        <TableCell>{corp.Created_By}</TableCell>
                                        <TableCell>{corp.Created_Date}</TableCell>
                                        <TableCell style={{ color: '#1665D8' }}>{corp.Status}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allcorpUser) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <CorpPagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    allcorpUser: PropTypes.array.isRequired
};

export default Results;