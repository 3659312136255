
import config from './config';
import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const PERMISSION_REQUEST = "PERMISSION_REQUEST ";
export const PERMISSION_SUCCESS = "PERMISSION_SUCCESS";
export const PERMISSION_FAIL = "PERMISSION_FAIL";

export const PERMISSION_CREATE_REQUEST = "PERMISSION_CREATE_REQUEST";
export const PERMISSION_CREATE_SUCCESS = "PERMISSION_CREATE_SUCCESS";
export const PERMISSION_CREATE_FAIL = "PERMISSION_CREATE_FAIL";

export const PERMISSION_UPDATE_REQUEST = "PERMISSION_UPDATE_REQUEST";
export const PERMISSION_UPDATE_SUCCESS = "PERMISSION_UPDATE_SUCCESS";
export const PERMISSION_UPDATE_FAIL = "PERMISSION_UPDATE_FAIL ";

//get permissions
export const getAllPermissionsAction = formData => async dispatch => {
    try {
        dispatch({
            type: PERMISSION_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Super_Admin/Permissions_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=LABEL&SortOrder=ASC`,
            formData
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: PERMISSION_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: PERMISSION_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: PERMISSION_FAIL,
            payload: err
        });
    }
};

//create permissions
export const AddPermissionAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: PERMISSION_CREATE_REQUEST
    });
    nodeCudApi.post(`/Super_Admin/Permissions_Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: PERMISSION_CREATE_SUCCESS
                });
                toast('Permission created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/permissions');
            } else {
                dispatch({
                    type: PERMISSION_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: PERMISSION_CREATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//update permissions
export const EditPermissionAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: PERMISSION_UPDATE_REQUEST
    });
    nodeCudApi.post(`/Super_Admin/Permissions_Update`, formData
    )
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: PERMISSION_UPDATE_SUCCESS
                });
                toast('Permission updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/permissions');
            } else {
                dispatch({
                    type: PERMISSION_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: PERMISSION_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};