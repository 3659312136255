import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { createDeliBidVehicleCommission, getAllBidCommissionDeliveryUsers, getAllBidCommissionDelVehicles } from 'src/store/actions/deliverybiddingvehiclecommissionAction';

const esicommissionType = [
    {
        value: '',
        label: '-Please Select-'
    },
    {
        value: 'A',
        label: 'Amount'
    },
    {
        value: 'P',
        label: 'Percentage'
    },
];

const statusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const AddDeliBidVehicleCommission = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        let formData = {
            search_by_filter: "",
            search: ""
        };
        dispatch(getAllBidCommissionDeliveryUsers(formData))
        dispatch(getAllBidCommissionDelVehicles(formData))
    }, []);

    const isSubmitted = useSelector(state => state.delibidvehicommission.delibidVehiCommissionCreate.isLoading);
    const errorMessage = useSelector(state => state.delibidvehicommission.delibidVehiCommissionCreate.error);
    const biddeliusers = useSelector(state => state.delibidvehicommission.biddeliveryusers.all);

    const biddelivehicles = useSelector(state => state.delibidvehicommission.delibidvehicles.all);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    return (
        <Formik
            initialValues={{
                Bidding_Vehicle_Id: '',
                Del_User_Email_Id: '',
                Commission_Type: '',
                Meepaisa_Commission: '',
                Status: '',
                Created_By: loginEmail,
            }}
            validationSchema={
                Yup.object().shape({
                    Bidding_Vehicle_Id: Yup.string().required('Bidding Vehicle is required.'),
                    Del_User_Email_Id: Yup.string().required('Delivery Email ID is required.'),
                    Commission_Type: Yup.string().required('Meepaisa Commission Type is required.'),
                    Meepaisa_Commission: Yup.number().when("Commission_Type", (Commission_Type, schema) => {
                        if (Commission_Type === "A") {
                            return schema.min(1, "Meepaisa Commission Amount must be at least 1.").required('Meepaisa Commission Amount is required.');
                        } else if (Commission_Type === "P") {
                            return schema.min(1, "Meepaisa Commission Percentage must be at least 1.").max(100, "Meepaisa Commission Percentage must be less then 100.").required('Meepaisa Commission Percentage is required.');
                        }
                    }),
                    Status: Yup.string().required('Status is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(createDeliBidVehicleCommission(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Del_User_Email_Id && errors.Del_User_Email_Id)}
                                                fullWidth
                                                helperText={touched.Del_User_Email_Id && errors.Del_User_Email_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Delivery Email ID"
                                                name="Del_User_Email_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Del_User_Email_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {biddeliusers?.sort((a, b) => a.Del_User_Email_Id?.localeCompare(b.Del_User_Email_Id))?.map(option => (
                                                    <option key={option.Del_User_Email_Id} value={option.Del_User_Email_Id}>
                                                        {option.Del_User_Email_Id}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Vehicle_Id && errors.Bidding_Vehicle_Id)}
                                                fullWidth
                                                helperText={touched.Bidding_Vehicle_Id && errors.Bidding_Vehicle_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Vehicle"
                                                name="Bidding_Vehicle_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Vehicle_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {biddelivehicles?.map(option => (
                                                    <option key={option.Bidding_Vehicle_Id} value={option.Bidding_Vehicle_Id}>
                                                        {option.Vehicle_Type + " - " + option.Vehicle_Comfort_Type}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Commission_Type && errors.Commission_Type)}
                                                fullWidth
                                                helperText={touched.Commission_Type && errors.Commission_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Meepaisa Commission Type"
                                                name="Commission_Type"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Commission_Type}
                                                variant="outlined"
                                            >
                                                {esicommissionType?.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Meepaisa_Commission && errors.Meepaisa_Commission)}
                                                fullWidth
                                                helperText={touched.Meepaisa_Commission && errors.Meepaisa_Commission}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label={"Meepaisa Commission " + (values.Commission_Type === "A" ? "Amount ₹" : values.Commission_Type === "P" ? "Percentage %" : "")}
                                                name="Meepaisa_Commission"
                                                value={values.Meepaisa_Commission}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddDeliBidVehicleCommission.propTypes = {
    className: PropTypes.string
};

export default AddDeliBidVehicleCommission;