import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  updateCityPartnerAction,
  getAllPartnerids,
  getAllPartnercitynames
} from './../../store/actions/citypartnerAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const EditCitypartner = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let { state } = location;

  // componentDidMount
  useEffect(() => {
    const formData =
    {
      "Records_Filter": "FILTER"
    };
    dispatch(getAllPartnerids());
    dispatch(getAllPartnercitynames(formData));
  }, []);


  const Partnerid = useSelector(state => state.citypartner.citypartnerid.partnerid);
  const Cityname = useSelector(state => state.citypartner.citypartnercitynames.citynames);
  const isSubmitted = useSelector(state => state.citypartner.citypartnerUpdate.isLoading);
  const errorMessage = useSelector(state => state.citypartner.citypartnerUpdate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        Partner_Service_City_Id: state.Partner_Service_City_Id ? state.Partner_Service_City_Id : "",
        Partner_Details_Id: state.Partner_Details_Id ? state.Partner_Details_Id : "",
        City_Name: state.City_Name ? state.City_Name : "",
        City_Id: state.City_Id ? state.City_Id : "",
        Status: state.Status ? state.Status : "",
        Modified_By: loginEmail
      }}

      validationSchema={
        Yup.object().shape({
          Status: Yup.string().required('Status is required.'),
        })
      }

      onSubmit={(values, { setErrors, setSubmitting }) => {
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        let formValues = JSON.parse(JSON.stringify(values));
        dispatch(updateCityPartnerAction(formValues, navigate));
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}
        >
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  {/* Partner_Details_Id */}
                  <TextField
                    error={Boolean(touched.Partner_Details_Id && errors.Partner_Details_Id)}
                    fullWidth
                    helperText={touched.Partner_Details_Id && errors.Partner_Details_Id}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Partner Name"
                    InputLabelProps={{ shrink: true }}
                    name="Partner_Details_Id"
                    select
                    SelectProps={{ native: true }}
                    value={values.Partner_Details_Id}
                    variant="outlined"
                    disabled
                  ><option key="" value="">--Please Select--</option>
                    {Partnerid
                      .map(option => (
                        <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                          {option.Name}
                        </option>
                      ))}
                  </TextField>
                </Grid>

                <Grid item md={6} xs={12}>
                  {/* City_Name */}
                  <TextField
                    error={Boolean(touched.City_Name && errors.City_Name)}
                    fullWidth
                    helperText={touched.City_Name && errors.City_Name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="City Name"
                    InputLabelProps={{ shrink: true }}
                    name="City_Name"
                    select
                    SelectProps={{ native: true }}
                    value={values.City_Name}
                    variant="outlined"
                    disabled
                  ><option key="" value="">--Please Select--</option>
                    {Cityname.map(option => (
                      <option key={option.City_Id} value={option.Name}>
                        {option.Name + " " + "-" + " " + option.State + " " + "-" + " " + option.Country}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                {/* Status */}
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.Status && errors.Status)}
                    fullWidth
                    helperText={touched.Status && errors.Status}
                    placeholder="Status"
                    label="Status"
                    name="Status"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    value={values.Status}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {statusOption.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmitted ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmitted}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditCitypartner.propTypes = {
  className: PropTypes.string
};

export default EditCitypartner;