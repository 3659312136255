import {

    B2B_USER_BID_COMMENTS_UPDATE_REQUEST,
    B2B_USER_BID_COMMENTS_UPDATE_SUCCESS,
    B2B_USER_BID_COMMENTS_UPDATE_FAIL,

} from '../actions/b2buserprodbidcommentsAction'

const initialState = {
    b2buserprodbidcommentUpdate: { b2buserprodbidcomment: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case B2B_USER_BID_COMMENTS_UPDATE_REQUEST:
            return { ...state, b2buserprodbidcommentUpdate: { b2buserprodbidcomment: {}, error: '', isLoading: true } };
        case B2B_USER_BID_COMMENTS_UPDATE_SUCCESS:
            return { ...state, b2buserprodbidcommentUpdate: { b2buserprodbidcomment: state.b2buserprodbidcommentUpdate.b2buserprodbidcomment, error: '', isLoading: false } };
        case B2B_USER_BID_COMMENTS_UPDATE_FAIL:
            return { ...state, b2buserprodbidcommentUpdate: { b2buserprodbidcomment: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}