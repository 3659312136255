import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { AddPermissionAction } from 'src/store/actions/permissionsAction';
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';
import modulesdata from '../modulesdata';

const statusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const useStyles = makeStyles(() => ({
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const AddPermission = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    const isSubmitted = useSelector(state => state.permission.permissionCreate.isLoading);
    const errorMessage = useSelector(state => state.permission.permissionCreate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    // Richtext editor
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
    const [rhDiscription, setRhDiscription] = useState('');
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    const [checked, setChecked] = React.useState(false);
    const [checked1, setChecked1] = React.useState(false);
    const [checked2, setChecked2] = React.useState(false);

    const handleChange1 = (event) => {
        if (event.target.checked) {
            setChecked(event.target.checked);
            setChecked1(false)
            setChecked2(false)
        }
    };

    const handleChange2 = (event) => {
        if (event.target.checked) {
            setChecked1(event.target.checked);
            setChecked(false)
            setChecked2(false)
        }
    };

    const handleChange3 = (event) => {
        if (event.target.checked) {
            setChecked2(event.target.checked);
            setChecked(false)
            setChecked1(false)
        }
    };

    return (
        <Formik
            initialValues={{
                Label: '',
                Value: '',
                Description: '',
                Status: '',
                Is_Read: checked,
                Is_Write: checked1,
                Is_Hide: checked2,
                Created_By: loginEmail,
            }}
            validationSchema={
                Yup.object().shape({
                    Label: Yup.string().required('Label is required.'),
                    Status: Yup.string().required('Status is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};

                values.Description = rhDiscription;

                if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
                    submitTest = false;
                    errors.Description = "Description is required.";
                }
                else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
                    submitTest = false;
                    errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
                }
                setErrors(errors);
                let formValues = JSON.parse(JSON.stringify(values));
                if (submitTest) {
                    if (checked) {
                        formValues.Is_Read = 1;
                    } else {
                        formValues.Is_Read = 0;
                    }
                    if (checked1) {
                        formValues.Is_Write = 1;
                    } else {
                        formValues.Is_Write = 0;
                    }
                    if (checked2) {
                        formValues.Is_Hide = 1;
                    } else {
                        formValues.Is_Hide = 0;
                    }
                    dispatch(AddPermissionAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Label && errors.Label)}
                                                fullWidth
                                                helperText={touched.Label && errors.Label}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    setFieldValue("Label", e.target.value);
                                                    setFieldValue("Value", e.target.value);
                                                }}
                                                label="Label"
                                                name="Label"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Label}
                                                variant="outlined"
                                            >
                                                {modulesdata.map(option => (
                                                    <option key={option.label} value={option.label}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Value"
                                                name="Value"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Value}
                                                variant="outlined"
                                                disabled
                                            >
                                                {modulesdata.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.value}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <Box className={classes.description}>
                                                Description
                                                <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                                            </Box>
                                            <span style={{ color: "red" }}>
                                                {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                                            </span>
                                        </Grid>

                                        <Grid item md={4} xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onBlur={handleBlur}
                                                            checked={checked}
                                                            onChange={handleChange1}
                                                            name="Is_Read"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Is Read"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item md={4} xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onBlur={handleBlur}
                                                            checked={checked1}
                                                            onChange={handleChange2}
                                                            name="Is_Write"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Is Write"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item md={4} xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onBlur={handleBlur}
                                                            checked={checked2}
                                                            onChange={handleChange3}
                                                            name="Is_Hide"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Is Hide"
                                                />
                                            </Box>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>

                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddPermission.propTypes = {
    className: PropTypes.string
};

export default AddPermission;