

import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { nodeServiceApi, urlGenarator } from 'src/store/actions/commonAxios';

const ESICountries = (props) => {
    const { valueUpdate, ...rest } = props;
    const [country, setCountry] = useState([]);

    useEffect(() => {
        let country = {}; 
        getCountry(country);
    }, []);

    const getCountry = (country) => {
        nodeServiceApi.post(urlGenarator(`/admin/csc_countries/Get`, { PageNo: 1, PageSize: 1000 }), country)
            .then(response => {
                const sortedCountries = response?.data?.results.sort((a, b) =>
                    a.Name.localeCompare(b.Name)
                );
                setCountry(sortedCountries);
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <TextField
            {...props}
            select 
        >
            <option key="" value="">--Please Select--</option>
            {country?.map(option => (
                <option key={option.Id} value={option.Id}>
                    {option.Name}
                </option>
            ))}
        </TextField>
    );
};

export default ESICountries;
