import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from 'src/components/dialogs/confirmDialog';
import { updateProductApprovalAction } from 'src/store/actions/productAction';
import { paginationPendingProducts } from 'src/store/actions/node-actions/nodepagination';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';

// useStyles we use a hook to consume the styles.
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const ProductsApproval = () => {
    // useDispatch hook is used to dispatch an action
    const dispatch = useDispatch();
    // classes contains the styles
    const classes = useStyles();

    //permissions
    const RolePermissions = useSelector(state => state.rolepermission.Roles);
    const roleUserType = useSelector(state => state.auth.user);
    const Login_Details = useSelector(state => state.auth.user);

    // useState is a Hook that allows you to have state variables in functional components
    const [productapprovals, setProductapprovals] = useState([]);
    const [filters, setFilters] = React.useState({
        search: '',
        search_by_filter: 'All',
        Records_Filter: "FILTER",
        Is_Approved: "0"
    });

    // createdBy contains the logged in user email id
    const createdBy = useSelector(state => state.auth.user.Email_Id);

    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = useState({ PageNo: 1, PageSize: 10, SortBy: "Product_Id", SortOrder: "ASC" })

    const handleLimitChange = event => {
        setPagination({ ...pagination, PageSize: +event.target.value, PageNo: 1 });
    };

    const handlePageChange = (event, newPage) => {
        setPagination({ ...pagination, PageSize: pagination.PageSize, PageNo: newPage + 1 });
    };

    const callBackPagination = async (status, data, pagination) => {
        if (status) {
            setProductapprovals(data);
        }
        setPageOld(true);
        if (pagination) {
            setPagination(pagination);
        } else {
            setPagination({ PageNo: 1, PageSize: 10, SortBy: "Product_Id", SortOrder: "ASC" });
        }
    }

    useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            productapprovalsCall();
        }
    }, [pagination])

    const [testFilters, setTestFilters] = useState({});
    useEffect(() => {
        if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
            setTestFilters(filters);
            searchFunction();
        }
    }, [filters]);
    const searchFunction = () => {
        const searchpagination = {
            PageNo: 1, PageSize: 10, SortBy: "Product_Id", SortOrder: "ASC"
        }
        dispatch(paginationPendingProducts(filters, searchpagination, callBackPagination))
    }
    const productapprovalsCall = () => {
        dispatch(paginationPendingProducts(filters, pagination, callBackPagination))
    };

    // confirm dialogbox
    const [productappropen, setProductappropen] = useState(false);
    const [productapprtitle, setProductapprtitle] = useState('');
    const [productapprmessage, setProductapprmessage] = useState('');
    const [productapprIdle, setProductapprIdle] = useState('');

    // productapprhandleClose contains update popup action
    const productapprhandleClose = (value) => {
        setProductappropen(false);

        if (value) {
            const approveData = {
                Product_Id: productapprIdle.Product_Id,
                Product_Name: productapprIdle.Product_Name,
                Category_Id: productapprIdle.Category_Id,
                Brand_Id: productapprIdle.Brand_Id,
                Product_Short_Description: productapprIdle.Product_Short_Description,
                Product_Description: productapprIdle.Product_Description,
                Features: productapprIdle.Features,
                Specifications: productapprIdle.Specifications,
                Product_Price: productapprIdle.Price,
                Product_Size_Id: productapprIdle.Product_Size_Id,
                Product_Color_Variant_Id: productapprIdle.Product_Color_Variant_Id,
                Product_Master_Id: productapprIdle.Product_Master_Id,
                Is_Popular: productapprIdle.Is_Popular,
                Whats_Hot: productapprIdle.Whats_Hot,
                Is_Top_Product: productapprIdle.Is_Top_Product,
                Esi_Review: productapprIdle.Esi_Reviews,
                Esi_Rating: productapprIdle.Esi_Rating,
                Country_Of_Origin: productapprIdle.Country_Of_Origin,
                Is_Upcoming: productapprIdle.Is_Upcoming,
                Is_Verified: productapprIdle.Is_Verified,
                Sub_Category_Id: productapprIdle.Sub_Category_Id,
                Modified_By_Email: createdBy,
                //Static data
                Status: "Active",
                Is_Approved: "1"
            };
            dispatch(updateProductApprovalAction(approveData, filters, pagination, callBackPagination));
        }
    };

    //Approve handler
    const updateProductApprovalHandler = approveData => async e => {
        setProductappropen(true);
        setProductapprtitle("Alert!");
        setProductapprmessage("Are you sure to Accept?");
        setProductapprIdle(approveData);
    };

    useEffect(() => {
        dispatch(getRolePermissions(Login_Details));
        dispatch(paginationPendingProducts(filters, pagination, callBackPagination))
    }, []);

    // Sorting 
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Product_Id');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPagination({ PageNo: 1, PageSize: pagination?.PageSize, SortBy: property, SortOrder: order === 'asc' ? "ASC" : "DESC" })
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/product-management/products"
                    variant="text"
                    size="small"
                    color="inherit"
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        Pending Products
                    </Typography>
                </Box>
                <Toolbar filters={filters} setfilters={setFilters} />
                <Box mt={3}>
                    <Results
                        allProductsApproval={productapprovals}
                        allProductApprovalNoFilter={productapprovals}
                        updateProductApprovalHandler={updateProductApprovalHandler}
                        handleLimitChange={handleLimitChange}
                        handlePageChange={handlePageChange}
                        order={order}
                        orderBy={orderBy}
                        pagination={pagination}
                        setPagination={setPagination}
                        createSortHandler={createSortHandler}
                        RolePermissions={RolePermissions}
                        roleUserType={roleUserType}
                    />
                </Box>
            </Container>
            <ConfirmDialog inputMessage={productapprmessage} titleMsg={productapprtitle} open={productappropen} onClose={productapprhandleClose} />
        </Page>
    );
};

export default ProductsApproval;