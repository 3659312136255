import config from './config';
import { toast } from 'react-toastify';
import { paginationShopbycityDeal } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const CITYPARTNERDEAL_REQUEST = "CITYPARTNERDEAL_REQUEST";
export const CITYPARTNERDEAL_SUCCESS = "CITYPARTNERDEAL_SUCCESS";
export const CITYPARTNERDEAL_FAIL = "CITYPARTNERDEAL_FAIL";
export const CITYPARTNERDEAL_GET_REQUEST = "CITYPARTNERDEAL_GET_REQUEST";
export const CITYPARTNERDEAL_GET_SUCCESS = "CITYPARTNERDEAL_GET_SUCCESS";
export const CITYPARTNERDEAL_GET_FAIL = "CITYPARTNERDEAL_GET_FAIL";
export const CITYPARTNERDEAL_CREATE_REQUEST = "CITYPARTNERDEAL_CREATE_REQUEST";
export const CITYPARTNERDEAL_CREATE_SUCCESS = "CITYPARTNERDEAL_CREATE_SUCCESS";
export const CITYPARTNERDEAL_CREATE_FAIL = "CITYPARTNERDEAL_CREATE_FAIL";
export const CITYPARTNERDEAL_UPDATE_REQUEST = "CITYPARTNERDEAL_UPDATE_REQUEST";
export const CITYPARTNERDEAL_UPDATE_SUCCESS = "CITYPARTNERDEAL_UPDATE_SUCCESS";
export const CITYPARTNERDEAL_UPDATE_FAIL = "CITYPARTNERDEAL_UPDATE_FAIL";
export const CITYPARTNERDEAL_DEALID_REQUEST = "CITYPARTNERDEAL_DEALID_REQUEST";
export const CITYPARTNERDEAL_DEALID_SUCCESS = "CITYPARTNERDEAL_DEALID_SUCCESS";
export const CITYPARTNERDEAL_DEALID_FAIL = "CITYPARTNERDEAL_DEALID_FAIL";
export const CITYPARTNERDEAL_SEARCH_REQUEST = "CITYPARTNERDEAL_SEARCH_REQUEST";
export const CITYPARTNERDEAL_SEARCH_SUCCESS = "CITYPARTNERDEAL_SEARCH_SUCCESS";
export const CITYPARTNERDEAL_SEARCH_FAIL = "CITYPARTNERDEAL_SEARCH_FAIL";

/*=============================================================
                  Add city partner deal Action
===============================================================*/
export const createCityPartnerdealAction = (formData, state, navigate, setErrorMessage) => async dispatch => {
  dispatch({
    type: CITYPARTNERDEAL_CREATE_REQUEST
  });
  nodeCudApi.post(`/Shop_By_City/Partner_Service_City_Deals_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: CITYPARTNERDEAL_CREATE_SUCCESS
        });
        toast('City deal added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/sbcm/city-deal-management', state = { state });
      } else {
        setErrorMessage(successResponse.UI_Display_Message);
        dispatch({
          type: CITYPARTNERDEAL_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage("Currently server is not working. Please try again later.");
      dispatch({
        type: CITYPARTNERDEAL_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Update city partner deal Action
  =============================================================*/
export const updateCityPartnerdealAction = (formData, state, navigate) => async dispatch => {
  dispatch({
    type: CITYPARTNERDEAL_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Shop_By_City/Partner_Service_City_Deals_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: CITYPARTNERDEAL_UPDATE_SUCCESS
        });
        toast('City deal updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/sbcm/city-deal-management', state = { state });
      } else {
        dispatch({
          type: CITYPARTNERDEAL_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: CITYPARTNERDEAL_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
             Get All city Partner deals Action
  =============================================================*/
export const getAllCityPartnerdealsAction = formData => async dispatch => {
  try {
    dispatch({
      type: CITYPARTNERDEAL_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Shop_By_City/Partner_Service_City_Deals_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_SERVICE_CITY_DEAL_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CITYPARTNERDEAL_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CITYPARTNERDEAL_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again.", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    dispatch({
      type: CITYPARTNERDEAL_FAIL,
      payload: err
    });
  }
};

/*=============================================================
              Delete city partner deal Action
  =============================================================*/
export const deleteCityPartnerdealAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Shop_By_City/Partner_Service_City_Deals_Delete`, formData);
    if (data) {
      toast('City deal deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationShopbycityDeal(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

//node API
export const getAllPartnerdealids = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Is_Approved: "1",
      search: '',
      search_by_filter: ''
    };
    dispatch({
      type: CITYPARTNERDEAL_DEALID_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Deals/Deal_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=DEAL_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CITYPARTNERDEAL_DEALID_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CITYPARTNERDEAL_DEALID_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CITYPARTNERDEAL_DEALID_FAIL,
      payload: JSON.stringify(err)
    });
  }
};

export const getAllPartnerdeals = (formData) => async dispatch => {
  try {
    dispatch({
      type: CITYPARTNERDEAL_SEARCH_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Deals/Get_Deal_Dropdown_Search?PageNo=1&PageSize=${config.pageSize}&SortBy=DEAL_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CITYPARTNERDEAL_SEARCH_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CITYPARTNERDEAL_SEARCH_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CITYPARTNERDEAL_SEARCH_FAIL,
      payload: JSON.stringify(err)
    });
  }
};

// Get PartnerServiceCityDeal
export const getPartnerServiceCityDealAction = (formData, callBackPartnerServiceCityDealData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Shop_By_City/Partner_Service_City_Deals_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_SERVICE_CITY_DEAL_ID&SortOrder=ASC`, formData);
    if (data) {
      callBackPartnerServiceCityDealData(data)
    }
  } catch (err) {
  }
};