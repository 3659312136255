import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  InputAdornment,
  SvgIcon,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  formFilterControl: {
    margin: theme.spacing(1),
    minWidth: 200
  }
}));

const Toolbar = ({ className, filters, setfilters, ...rest }) => {
  const classes = useStyles();

  const handleChange = event => {
    setfilters({
      search: filters?.search,
      search_by_filter: event.target.value,
      Records_Filter: 'FILTER',
      User_Service_Request_Status: "NEW",
    })
  };

  return (
    <div {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" flexWrap="wrap" mb={2}>
          <FormControl variant="outlined" className={classes.formFilterControl}>
            <Select
              value={filters.search_by_filter}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="User_Service_Request_Id">User Service Request ID</MenuItem>
              <MenuItem value="User_Email_Id">User Email ID</MenuItem>
              <MenuItem value="User_Service_Request_Status">User Service Status</MenuItem>
              {/* <MenuItem value="Payment_Status">Payment Status</MenuItem> */}
              {/* <MenuItem value="Status">Status</MenuItem> */}

            </Select>
          </FormControl>
          <Box width={500}>
            <TextField
              onChange={(event) => {
                setfilters({
                  search: event.target.value,
                  search_by_filter: filters.search_by_filter,
                  Records_Filter: 'FILTER',
                  User_Service_Request_Status: "NEW",
                })
              }}
              className={classes.formControl}
              value={filters.search}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search Services"
              variant="outlined"
            />
          </Box>
        </Box>
        <Box display="flex"
          flexWrap="wrap" mb={2}>
          <FormControl variant="outlined" className={classes.formFilterControl}>
            <Select
              value= "New_Services"
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="ALL" href="/app/services-management" component="a">ALL</MenuItem>
              <MenuItem value="New_Services" href="/app/services-management-new" component="a">NEW</MenuItem>
              <MenuItem value="In-Process_Services" href="/app/services-management-in-process" component="a">IN-PROCESS</MenuItem>
              <MenuItem value="Completed_Services" href="/app/services-management-completed" component="a">COMPLETED</MenuItem>
              <MenuItem value="Cancelled_Services" href="/app/services-management-cancelled" component="a">CANCELLED</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;