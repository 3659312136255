import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    Grid,
    makeStyles,
    TextField,
    Button,
    Box, ListItemAvatar, ListItemText,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Rating from '@material-ui/lab/Rating';
import { useLocation } from 'react-router-dom';
import { ConnectedFocusError } from "focus-formik-error";
import { updateServiceAction, getAllServices, getAllPartnerServices, getAllPartnerDetails, updatepaymrentServiceAction, getAllEmployeeServicesAction } from 'src/store/actions/servicesAction';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles(() => ({
    images: {
        width: 120,
        height: 120,
        borderRadius: 5,
        marginBottom: 15
    },
    image: {
        width: 50,
        height: 50
    }
}));

const TranscationStatus = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'NEW',
        label: 'NEW'
    },
    {
        value: 'ACCEPTED',
        label: 'ACCEPTED'
    },
    {
        value: 'INITIATED',
        label: 'INITIATED'
    },
    {
        value: 'IN-PROCESS',
        label: 'IN-PROCESS'
    },
    {
        value: 'COMPLETED',
        label: 'COMPLETED'
    },
    {
        value: 'REJECTED',
        label: 'REJECTED'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
];
const PartnerStatus = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'NEW',
        label: 'NEW'
    },
    {
        value: 'ACCEPTED',
        label: 'ACCEPTED'
    },
    {
        value: 'INITIATED',
        label: 'INITIATED'
    },
    {
        value: 'IN-PROCESS',
        label: 'IN-PROCESS'
    },
    {
        value: 'COMPLETED',
        label: 'COMPLETED'
    },
    {
        value: 'REJECTED',
        label: 'REJECTED'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
];

const StatusOptions = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    },
];

const PaymentOptions = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'SUCCESS',
        label: 'SUCCESS'
    },
    {
        value: 'PENDING',
        label: 'PENDING'
    },
    {
        value: 'FAILED',
        label: 'FAILED'
    },
];

const Paymentmethod = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'ONLINE',
        label: 'ONLINE',
    },
    {
        value: 'COD',
        label: 'COD'
    },
];
const AdvancePaymentStatus = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'SUCCESS',
        label: 'SUCCESS'
    },
    {
        value: 'PENDING',
        label: 'PENDING'
    },
    {
        value: 'FAILED',
        label: 'FAILED'
    },
]

const EditUserServices = ({ className, ...rest }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = location;
    const classes = useStyles();

    useEffect(() => {
        setEndDate(state?.Service_Start_Date)
        dispatch(getAllPartnerDetails());
        dispatch(getAllServices())
        dispatch(getAllPartnerServices(state?.Partner_Detail_Id, state?.Service_Type));
        dispatch(getAllEmployeeServicesAction(state?.Partner_Detail_Id))
    }, []);

    const partnerservices = useSelector(state => state.service.PartnerServices.partnerservices);
    const employeeServices = useSelector(state => state.service.EmployeeServices.employeeServices);
    const isSubmited = useSelector(state => state.service.serviceUpdate.isLoading);
    const errorMessage = useSelector(state => state.service.serviceUpdate.error);
    const updatedBy = useSelector(state => state.auth.user.Email_Id);
    const partners = useSelector(state => state.service.PhysicalPartners.partners);
    // Date
    let nDate = new Date();

    let UpdateValues = []
    const setType = (Partner_Detail_Id) => {
        if (Partner_Detail_Id !== "") {
            dispatch(getAllPartnerServices(Partner_Detail_Id, state.Service_Type));
        }
    }
    const setemployeeType = (Partner_Detail_Id) => {
        if (Partner_Detail_Id !== "") {
            dispatch(getAllEmployeeServicesAction(Partner_Detail_Id));
        }
    }

    //for End date validation
    const [datevalue, setDatevalue] = useState('');
    const setEndDate = (value) => {
        setDatevalue(value);
    };

    const colletct = () => {
        UpdateValues = {
            Modified_By: updatedBy,
            Partner_Service_Id: state.Partner_Service_Id ? state.Partner_Service_Id : "",
            User_Email_ID: state.User_Email_Id ? state.User_Email_Id : "",
            User_Service_Request_Id: state.User_Service_Request_Id ? state.User_Service_Request_Id : "",
            Payment_Method: "COD",
            Advance_Payment: state.Advance_Amount ? state.Advance_Amount : "",
            Advance_Payment_status: "SUCCESS",
            // Payment_Status:"SUCCESS",
            Service_Request_Status_From_Partner: "IN-PROCESS",
            User_Service_Request_Status: "IN-PROCESS"
        }
        dispatch(updatepaymrentServiceAction(UpdateValues, navigate, state));
    };

    return (
        <Formik
            initialValues={{
                Modified_By: updatedBy,
                Partner_Service_Id: state.Partner_Service_Id ? state.Partner_Service_Id : "",
                Partner_Employee_Id: state.Partner_Employee_Id ? state.Partner_Employee_Id : "",
                Payment_Status: state.Payment_Status ? state.Payment_Status : "",
                Price_Per_Day: state.Price_Per_Day ? state.Price_Per_Day : "",
                Price_Per_Hour: state.Price_Per_Hour ? state.Price_Per_Hour : "",
                Service_End_Date: state.Service_End_Date ? state.Service_End_Date : "",
                Service_Price: state.Service_Price ? state.Service_Price : "",
                Service_Request_Status_From_Partner: state.Service_Request_Status_From_Partner ? state.Service_Request_Status_From_Partner : "",
                Service_Request_Total_Time_In_Days: state.Service_Request_Total_Time_In_Days ? state.Service_Request_Total_Time_In_Days : "",
                Service_Request_Total_Time_In_Hours: state.Service_Request_Total_Time_In_Hours ? state.Service_Request_Total_Time_In_Hours : "",
                Service_Requested_Date: state.Service_Request_date ? state.Service_Request_date : "",
                Service_Required_Date: state.Service_Required_Date ? state.Service_Required_Date : "",
                Service_Start_Date: state.Service_Start_Date ? state.Service_Start_Date : "",
                Status: state.Status ? state.Status : "",
                Service_Type: state.Service_Type ? state.Service_Type : "",
                User_Cancel_Reason: state.User_Cancel_Reason ? state.User_Cancel_Reason : "",
                User_Email_ID: state.User_Email_Id ? state.User_Email_Id : "",
                User_Service_Request_Id: state.User_Service_Request_Id ? state.User_Service_Request_Id : "",
                User_Service_Request_Status: state.User_Service_Request_Status ? state.User_Service_Request_Status : "",
                Partner_Detail_Id: state.Partner_Detail_Id ? state.Partner_Detail_Id : "",
                Service_Person_Email_Id: state.Service_Person_Email_Id ? state.Service_Person_Email_Id : "",
                Service_Person_Name: state.Service_Person_Name ? state.Service_Person_Name : "",
                Service_person_Mobile_Number: state.Service_person_Mobile_Number ? state.Service_person_Mobile_Number : "",
                Payment_Method: state.Payment_Method ? state.Payment_Method : "",
                Advance_Payment: state.Advance_Amount ? state.Advance_Amount : "",
                Advance_Payment_status: state.Advanced_Payment_Status ? state.Advanced_Payment_Status : "",
                After_Discount_Service_Price: state.Service_Price ? state.Service_Price : "",
                Rating: state.Rating ? state.Rating : "",
                Review: state.Review ? state.Review : "",
            }}
            validationSchema={
                Yup.object().shape({
                    Status: Yup.string().required('Status is required.'),
                    Partner_Detail_Id: Yup.string().required('Partner Name is required.'),
                    // Partner_Service_Id: Yup.string().required('Partner Service Name is required.'),
                    // Service_Required_Date: Yup.string().test("date-of-service", "Service Date cannot be past date.", (value) => {
                    //     if (value) {
                    //         let date1 = new Date(value).getTime();
                    //         let date2 = new Date(dos).getTime();
                    //         if (date1 >= date2) {
                    //             return true;
                    //         }
                    //     }
                    //     return false;
                    // }),
                    // Service_Start_Date: Yup.string().test("date-of-service", "Service Start Date cannot be past date.", (value) => {
                    //     if (value) {
                    //         let date1 = new Date(value).getTime();
                    //         let date2 = new Date(dos).getTime();
                    //         if (date1 >= date2) {
                    //             return true;
                    //         }
                    //     }
                    //     return false;
                    // }).required('Service Start Date is required.'),
                    // Service_End_Date: Yup.string().test("Service_Start_Date", "Service End Date cannot be before or equal to Service Start Date.", (value) => {
                    //     if (value) {
                    //         let date1 = new Date(value).getTime();
                    //         let date2 = new Date(datevalue).getTime();
                    //         if (date1 > date2) {
                    //             return true;
                    //         }
                    //     }
                    //     return false;
                    // }).required('Service End Date is required.'),
                    Advance_Payment: Yup.number().min(0, "Advance Payment  should not be less than 0.").required('Advance Payment is required.'),
                    Service_Price: Yup.number().min(0, " Service Price  should not be less than 0.").required('Service Price is required.'),
                    Price_Per_Day: Yup.number().min(0, "Price Per Day  should not be less than 0.").required('Price Per Day is required.'),
                    Price_Per_Hour: Yup.number().min(0, "Price Per Hour should not be less than 0.").required('Price Per Hour is required.'),
                    Service_Request_Total_Time_In_Days: Yup.number().min(0.5, "Service Request Total Time in Days  should not be less than 0.5days.").required('Service Request Total Time In Days is required.'),
                    Service_Request_Total_Time_In_Hours: Yup.number().min(0, "Service Request Total Time in Hours must be at least 0.").required('Service Request Total Time In Hours is required.'),
                    User_Service_Request_Status: Yup.string().required('User Service Request Status is required.'),
                    // Payment_Status: Yup.string().required('Payment Status is required.'),
                })
            }

            onSubmit={(values) => {
                if (values) {
                    let formData = JSON.parse(JSON.stringify(values));
                    dispatch(updateServiceAction(formData, navigate));
                }
            }
            }
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="User Email ID"
                                                name="User_Email_ID"
                                                value={values.User_Email_ID}
                                                variant="outlined"
                                                disabled
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <Autocomplete
                                                freeSolo
                                                options={partners}
                                                groupBy={(option) => option?.firstLetter}
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option?.Name || option?.Partner_Details_Id}
                                                onChange={(e, value) => {
                                                    setType(value?.Partner_Details_Id ? value?.Partner_Details_Id : "");
                                                    setFieldValue("Partner_Detail_Id", value?.Partner_Details_Id ? value?.Partner_Details_Id : "");

                                                }}
                                                value={partners?.length > 0 ? partners?.filter((item) => item?.Partner_Details_Id === values?.Partner_Detail_Id)[0] : ""}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                        <ListItemText
                                                            primary={option?.Name}
                                                        />
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        error={Boolean(touched.Partner_Detail_Id && errors.Partner_Detail_Id)}
                                                        fullWidth
                                                        helperText={touched.Partner_Detail_Id && errors.Partner_Detail_Id}
                                                        {...params}
                                                        placeholder="Search"
                                                        variant="outlined"
                                                        label="Partner Name"
                                                        name="Partner_Detail_Id"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off', // disable autocomplete and autofill,
                                                            startAdornment: (
                                                                <React.Fragment>
                                                                    {/* <SearchIcon color="inherit" size={20}></SearchIcon> */}
                                                                    {params.InputProps.startAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />

                                        </Grid>


                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Service Type"
                                                name="Service_Type"
                                                disabled
                                                value={values.Service_Type}
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Autocomplete
                                                options={partnerservices}
                                                groupBy={(option) => option?.firstLetter}
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option?.Resource_Email_Id || ""}
                                                onChange={(e, value) => {
                                                    setemployeeType(value.Partner_Detail_Id)
                                                    setFieldValue("Service_Person_Email_Id", value?.Resource_Email_Id ? value.Resource_Email_Id : "");
                                                    setFieldValue("Service_Person_Name", value?.Resource_Name ? value?.Resource_Name : "");
                                                    setFieldValue("Service_person_Mobile_Number", value?.Resource_Mobile ? value?.Resource_Mobile : "");
                                                    setFieldValue("Partner_Service_Id", value?.Partner_Service_Id ? value?.Partner_Service_Id : "");
                                                }}
                                                value={partnerservices?.length > 0 ? partnerservices?.filter((item) => item?.Resource_Email_Id === values?.Service_Person_Email_Id)[0] : ""}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                        <ListItemAvatar>
                                                            <img
                                                                alt={option.type}
                                                                className={classes.image}
                                                                style={{
                                                                    height: 48,
                                                                    width: 48
                                                                }}
                                                                src={option?.Sample_Service_Image_Url}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            secondary={option?.Resource_Email_Id}
                                                            primary={option?.Resource_Name}
                                                        />
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Search"
                                                        variant="outlined"
                                                        label="Service Person Email ID"
                                                        name="Service_Person_Email_Id"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off', // disable autocomplete and autofill,
                                                            startAdornment: (
                                                                <React.Fragment>
                                                                    <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                    {params.InputProps.startAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        {/*Service_Person_Name*/}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Service Person Name"
                                                name="Service_Person_Name"
                                                value={values.Service_Person_Name}
                                                variant="outlined"
                                                disabled
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>
                                        {/* Service_person_Mobile_Number */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Service person Mobile Number"
                                                name="Service_person_Mobile_Number"
                                                value={values?.Service_person_Mobile_Number}
                                                variant="outlined"
                                                disabled
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Service_Id && errors.Partner_Service_Id)}
                                                fullWidth
                                                helperText={touched.Partner_Service_Id && errors.Partner_Service_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Service Name"
                                                name="Partner_Service_Id"
                                                disabled
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Partner_Service_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {partnerservices?.sort((a, b) => a.Service_Name?.localeCompare(b.Service_Name))?.map(option => (
                                                    <option key={option.Partner_Service_Id} value={option.Partner_Service_Id}>
                                                        {option.Service_Name}
                                                    </option>
                                                ))}

                                            </TextField>
                                        </Grid>
                                        {/*Partner_Employee_Id*/}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                //  onChange={handleChange}
                                                onChange={(e, value) => {
                                                    setemployeeType(value?.Partner_Details_Id ? value?.Partner_Details_Id : "");
                                                    setFieldValue("Partner_Employee_Id", e.target.value);

                                                }}
                                                label="Partner Employee ID"
                                                name="Partner_Employee_Id"
                                                value={values.Partner_Employee_Id}
                                                variant="outlined"
                                                select
                                                SelectProps={{ native: true }}
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {employeeServices?.sort((a, b) => a.Partner_User_Detail_Id?.localeCompare(b.Partner_User_Detail_Id))?.map(option => (
                                                    <option key={option.Partner_User_Detail_Id} value={option.Partner_User_Detail_Id}>
                                                        {option.Full_Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Required_Date && errors.Service_Required_Date)}
                                                fullWidth
                                                helperText={touched.Service_Required_Date && errors.Service_Required_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Service Required Date"
                                                name="Service_Required_Date"
                                                type="datetime-local"
                                                value={values.Service_Required_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Start_Date && errors.Service_Start_Date)}
                                                fullWidth
                                                helperText={touched.Service_Start_Date && errors.Service_Start_Date}
                                                onBlur={handleBlur}
                                                // disabled
                                                //  onChange={(e) => { handleChange(e); setEndDate(e.target.value) }}
                                                onChange={(e, value) => {
                                                    setEndDate(e.target.value)
                                                    setFieldValue("Service_Start_Date", e.target.value);
                                                }}
                                                label="Service Start Date"
                                                name="Service_Start_Date"
                                                type="datetime-local"
                                                value={values.Service_Start_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Price_Per_Day && errors.Price_Per_Day)}
                                                fullWidth
                                                helperText={touched.Price_Per_Day && errors.Price_Per_Day}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Price Per Day"
                                                name="Price_Per_Day"
                                                value={values.Price_Per_Day}
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Price_Per_Hour && errors.Price_Per_Hour)}
                                                fullWidth
                                                helperText={touched.Price_Per_Hour && errors.Price_Per_Hour}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Price Per Hour"
                                                name="Price_Per_Hour"
                                                value={values.Price_Per_Hour}
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days)}
                                                fullWidth
                                                helperText={touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days}
                                                onBlur={handleBlur}
                                                // onChange={handleChange}
                                                onChange={e => {
                                                    let SP1 = Number(values.Price_Per_Day) * Number(e.target.value) + Number(values.Price_Per_Hour) * Number(values.Service_Request_Total_Time_In_Hours)
                                                    setFieldValue("Service_Request_Total_Time_In_Days", e.target.value);
                                                    setFieldValue("Service_Price", SP1);
                                                    setFieldValue("After_Discount_Service_Price", SP1);
                                                }}
                                                label="Service Request Total Time In Days"
                                                name="Service_Request_Total_Time_In_Days"
                                                value={values.Service_Request_Total_Time_In_Days}
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Request_Total_Time_In_Hours && errors.Service_Request_Total_Time_In_Hours)}
                                                fullWidth
                                                helperText={touched.Service_Request_Total_Time_In_Hours && errors.Service_Request_Total_Time_In_Hours}
                                                onBlur={handleBlur}
                                                // onChange={handleChange}
                                                onChange={e => {
                                                    let SP2 = Number(values.Price_Per_Day) * Number(values.Service_Request_Total_Time_In_Days) + Number(values.Price_Per_Hour) * Number(e.target.value)
                                                    setFieldValue("Service_Request_Total_Time_In_Hours", e.target.value);
                                                    setFieldValue("Service_Price", SP2);
                                                    setFieldValue("After_Discount_Service_Price", SP2);
                                                }}
                                                label="Service Request Total Time In Hours"
                                                name="Service_Request_Total_Time_In_Hours"
                                                value={values.Service_Request_Total_Time_In_Hours}
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Price && errors.Service_Price)}
                                                fullWidth
                                                helperText={touched.Service_Price && errors.Service_Price}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Service Price"
                                                name="Service_Price"
                                                value={values.Service_Price}
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="After Discount Service Price"
                                                name="After_Discount_Service_Price"
                                                value={values.After_Discount_Service_Price}
                                                disabled
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    setFieldValue("Service_Request_Status_From_Partner", e.target.value);
                                                    setFieldValue("Service_Start_Date", e.target.value === 'IN-PROCESS' ? nDate : '')
                                                    setFieldValue("User_Service_Request_Status", e.target.value);
                                                }}
                                                label="Service Request Status From Partner"
                                                name="Service_Request_Status_From_Partner"
                                                value={values.Service_Request_Status_From_Partner}
                                                variant="outlined"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                            >
                                                {PartnerStatus.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.User_Service_Request_Status && errors.User_Service_Request_Status)}
                                                fullWidth
                                                helperText={touched.User_Service_Request_Status && errors.User_Service_Request_Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="User Service Request Status"
                                                name="User_Service_Request_Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.User_Service_Request_Status}
                                                variant="outlined"
                                            >
                                                {TranscationStatus.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        {/* {values.Service_Request_Status_From_Partner === 'IN-PROCESS' || values.Service_Request_Status_From_Partner === 'COMPLETED' && (
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    error={Boolean(touched.Service_Start_Date && errors.Service_Start_Date)}
                                                    fullWidth
                                                    helperText={touched.Service_Start_Date && errors.Service_Start_Date}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Service Start Date"
                                                    name="Service_Start_Date"
                                                    type="datetime-local"
                                                    value={values.Service_Start_Date}
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                >
                                                </TextField>
                                            </Grid>
                                        )} */}
                                        {values.Service_Request_Status_From_Partner === 'COMPLETED' && (
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    error={Boolean(touched.Service_End_Date && errors.Service_End_Date)}
                                                    fullWidth
                                                    helperText={touched.Service_End_Date && errors.Service_End_Date}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Service End Date"
                                                    name="Service_End_Date"
                                                    type="datetime-local"
                                                    value={values.Service_End_Date}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                >
                                                </TextField>
                                            </Grid>
                                        )}
                                        {values.Service_Request_Status_From_Partner === 'CANCELLED' || values.User_Service_Request_Status === 'CANCELLED' && (
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="User Cancel Reason"
                                                    name="User_Cancel_Reason"
                                                    value={values.User_Cancel_Reason}
                                                    variant="outlined"
                                                >
                                                </TextField>
                                            </Grid>
                                        )}
                                        {/* Payment_Method */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                // onChange={handleChange}
                                                onChange={(e) => {
                                                    setFieldValue('Payment_Method', e.target.value)
                                                    setFieldValue('Payment_Status', e.target.value === 'COD' ? "SUCCESS" : '')
                                                }}
                                                label="Payment Method"
                                                name="Payment_Method"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Payment_Method}
                                                variant="outlined"
                                            >
                                                {Paymentmethod.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Advance_Payment && errors.Advance_Payment)}
                                                fullWidth
                                                helperText={touched.Advance_Payment && errors.Advance_Payment}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Payment"
                                                name="Advance_Payment"
                                                value={values.Advance_Payment}
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </Grid>
                                        {/* Advance_Payment_status */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Payment Status"
                                                name="Advance_Payment_status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Advance_Payment_status}
                                                variant="outlined"
                                            >
                                                {AdvancePaymentStatus.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Payment_Status && errors.Payment_Status)}
                                                fullWidth
                                                helperText={touched.Payment_Status && errors.Payment_Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Payment Status"
                                                name="Payment_Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Payment_Status}
                                                variant="outlined"
                                            >
                                                {PaymentOptions.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {StatusOptions.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        {/* Rating */}
                                        <br></br>
                                        <Grid item md={2} xs={2}>
                                            <Box>Service Rating</Box>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Rating
                                                name="Rating"
                                                onChange={(event, newValue) => {
                                                    values.Rating = newValue;
                                                    setFieldValue(newValue);
                                                }}
                                                value={values.Rating}
                                                size="medium"
                                                disabled
                                            />
                                        </Grid>

                                        {/* Review */}
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Review"
                                                name="Review"
                                                value={values.Review}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                multiline
                                                minRows={3}
                                                disabled
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>
                        <Box display="flex" pl={2} >
                            {state.Advanced_Payment_Status !== 'SUCCESS' && (
                                <span style={{ color: 'red' }}>Please Collect Adavance Amount: {values.Advance_Payment}</span>)}
                        </Box>
                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <span>
                                    {state.Advanced_Payment_Status !== 'SUCCESS' && values.Service_Request_Status_From_Partner === 'IN-PROCESS' ? <Button
                                        disabled={isSubmited}
                                        onClick={() => {
                                            colletct();
                                        }}
                                        style={{ backgroundColor: '#27B6CC' }}
                                        variant="contained"
                                    >
                                        <span style={{ color: 'white' }}>collected</span>
                                    </Button> :
                                        <Button
                                            disabled={isSubmited}
                                            type="submit"
                                            style={{ backgroundColor: '#27B6CC' }}
                                            variant="contained"
                                        >
                                            <span style={{ color: 'white' }}>Save details</span>
                                        </Button>}
                                </span>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditUserServices.propTypes = {
    className: PropTypes.string
};

export default EditUserServices;