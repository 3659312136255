import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Box, Typography
} from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { UserProductsPartnerPayAction } from 'src/store/actions/biddinguserproductsAction';

const PaymentModeOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'WALLET',
        label: 'WALLET'
    },
    {
        value: 'ONLINE',
        label: 'ONLINE'
    },
    {
        value: 'BANK',
        label: 'BANK'
    },
];

const PaymentTypeOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'ADVANCE',
        label: 'ADVANCE'
    },
    {
        value: 'FINAL',
        label: 'FINAL'
    },

];

const BiddingProductsPartnerPayments = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let { state } = location;

    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const isSubmited = useSelector(state => state?.biddinguserproducts?.PartnerPayments?.isLoading);
    const errorMessage = useSelector(state => state?.biddinguserproducts?.PartnerPayments?.error);

    return (
        <Formik
            initialValues={{
                Account_Number: "",
                Amount: state.Is_Partner_Advance_Amount_Settled ? state.Partner_Balance_Amount : state.Partner_Advance_Amount,
                Bank_Fee: "",
                Bank_Transaction_ID: "",
                Bank_Tranascation_Status: "",
                Bank_Transaction_Date: "",
                Created_By: loginEmail,
                Email_Id: state.Email_Id ? state.Email_Id : "",
                IFSC_Code: "",
                Mobile: state.Mobile ? state.Mobile : "",
                Name: state.Name ? state.Name : "",
                Payment_Mode: "",
                Payment_Type: "",
                Purpose: "",
                UPI: "",
                User_Products_Bidding_Id: state.User_Products_Bidding_Id ? state.User_Products_Bidding_Id : "",
            }}

            validationSchema={
                Yup.object().shape({
                    Payment_Mode: Yup.string().required('Payment Mode is required.'),
                    Payment_Type: Yup.string().required('Payment Type is required.'),
                    Amount: Yup.string().matches(/^\d+(\.\d{1,2})?$/,'Please enter valid Amount').required('Amount is required.'),
                    UPI: Yup.string().when('Payment_Mode', {
                        is: "ONLINE", then: Yup.string().required('UPI ID is required.'),
                    }),
                    Account_Number: Yup.string().when('Payment_Mode', {
                        is: "BANK", then: Yup.string().required('Account Number is required.'),
                    }),

                    IFSC_Code: Yup.string().when('Payment_Mode', {
                        is: "BANK", then: Yup.string().required('IFSC Code is required.'),
                    }),
                    Bank_Transaction_ID: Yup.string().when('Payment_Mode', {
                        is: "BANK", then: Yup.string().required('Bank Transaction ID is required.'),
                    }),
                })}

            onSubmit={(values, { setSubmitting, setErrors }) => {
                setSubmitting(false);
                const errors = {};
                setErrors(errors);
                if (values) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(UserProductsPartnerPayAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={6} xs={12}>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                gutterBottom
                                                style={{ color: '#66788A' }}
                                            >
                                                User Products Bidding ID: {values.User_Products_Bidding_Id}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                gutterBottom
                                                style={{ color: '#66788A' }}
                                            >
                                                Email ID: {values.Email_Id}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                gutterBottom
                                                style={{ color: '#66788A' }}
                                            >
                                                Name: {values.Name}
                                            </Typography>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                gutterBottom
                                                style={{ color: '#66788A' }}
                                            >
                                                Mobile: {values.Mobile}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                gutterBottom
                                                style={{ color: '#66788A' }}
                                            >
                                                Advance Amount: {state.Partner_Advance_Amount}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                gutterBottom
                                                style={{ color: '#66788A' }}
                                            >
                                                Remaining Amount: {state.Partner_Balance_Amount}
                                            </Typography>
                                        </Grid>

                                        {/* Payment_Type */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Payment_Type && errors.Payment_Type)}
                                                fullWidth
                                                helperText={touched.Payment_Type && errors.Payment_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                select
                                                SelectProps={{ native: true }}
                                                label="Payment Type"
                                                name="Payment_Type"
                                                value={values.Payment_Type}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                {PaymentTypeOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {/* Payment_Mode */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Payment_Mode && errors.Payment_Mode)}
                                                fullWidth
                                                helperText={touched.Payment_Mode && errors.Payment_Mode}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                select
                                                SelectProps={{ native: true }}
                                                label="Payment Mode"
                                                name="Payment_Mode"
                                                value={values.Payment_Mode}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                {PaymentModeOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {/* Amount */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Amount && errors.Amount)}
                                                fullWidth
                                                helperText={touched.Amount && errors.Amount}
                                                label="Amount"
                                                name="Amount"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Purpose && errors.Purpose)}
                                                fullWidth
                                                helperText={touched.Purpose && errors.Purpose}
                                                label="Purpose"
                                                name="Purpose"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.Purpose}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        {values.Payment_Mode === "ONLINE" ?
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    error={Boolean(touched.UPI && errors.UPI)}
                                                    fullWidth
                                                    helperText={touched.UPI && errors.UPI}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="UPI ID"
                                                    name="UPI"
                                                    value={values.UPI}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            : null}

                                        {values.Payment_Mode === "BANK" ?
                                            <>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.Account_Number && errors.Account_Number)}
                                                        fullWidth
                                                        helperText={touched.Account_Number && errors.Account_Number}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        label="Account Number"
                                                        name="Account_Number"
                                                        value={values.Account_Number}
                                                        variant="outlined"
                                                    />
                                                </Grid>

                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.IFSC_Code && errors.IFSC_Code)}
                                                        fullWidth
                                                        helperText={touched.IFSC_Code && errors.IFSC_Code}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        label="IFSC Code"
                                                        name="IFSC_Code"
                                                        value={values.IFSC_Code}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.Bank_Transaction_ID && errors.Bank_Transaction_ID)}
                                                        fullWidth
                                                        helperText={touched.Bank_Transaction_ID && errors.Bank_Transaction_ID}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        label="Bank Transaction ID"
                                                        name="Bank_Transaction_ID"
                                                        value={values.Bank_Transaction_ID}
                                                        variant="outlined"
                                                    />
                                                </Grid>

                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.Bank_Transaction_Date && errors.Bank_Transaction_Date)}
                                                        fullWidth
                                                        helperText={touched.Bank_Transaction_Date && errors.Bank_Transaction_Date}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        label="Bank Transaction Date"
                                                        name="Bank_Transaction_Date"
                                                        value={values.Bank_Transaction_Date}
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        type="datetime-local"
                                                    >
                                                    </TextField>
                                                </Grid>

                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        label="Bank Tranascation Status"
                                                        name="Bank_Tranascation_Status"
                                                        value={values.Bank_Tranascation_Status}
                                                        variant="outlined"
                                                    />
                                                </Grid>

                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        type="number"
                                                        label="Bank Fee"
                                                        name="Bank_Fee"
                                                        value={values.Bank_Fee}
                                                        variant="outlined"
                                                    />
                                                </Grid>

                                            </>
                                            : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Box display="flex" pl={2} >
                        <span style={{ color: "red" }}>{errorMessage}</span>
                    </Box>

                    <Box display="flex" p={2}>
                        {isSubmited ?
                            <CircularProgress />
                            :
                            <Button
                                disabled={isSubmited}
                                type="submit"
                                style={{ backgroundColor: '#27B6CC' }}
                                variant="contained"
                            >
                                <span style={{ color: 'white' }}>Save details</span>
                            </Button>
                        }
                    </Box>

                </form>
            )}
        </Formik>
    );
};

BiddingProductsPartnerPayments.propTypes = {
    className: PropTypes.string
};

export default BiddingProductsPartnerPayments;