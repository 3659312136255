import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../store/validations/is-empty';
import Pagination from 'src/components/pagination/pagination';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'mro.Created_Date', numeric: false, disablePadding: true, label: 'Order ID' },
  { id: 'User_Email_Id', numeric: false, disablePadding: false, label: 'User Email ID' },
  { id: 'mro.User_Details_Id', numeric: false, disablePadding: false, label: 'User Detail ID' },
  { id: 'Invoice_Number', numeric: false, disablePadding: false, label: 'Invoice Number' },
  { id: 'Order_Status', numeric: false, disablePadding: false, label: 'Order Status' },
  { id: 'Name', numeric: false, disablePadding: false, label: 'Partner Name' },
];

const Results = ({
  className,
  allOrders,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allOrdersNoFilter,
  pagination,
  setPagination,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedOrdersIds, setSelectedOrdersIds] = useState([]);

  // const OrderLineItemStatusHandler = ordersData => e => {
  //   navigate('/app/edit-order-status-history', { state: { ordersData, ID: "1" } });
  // };

  const OrderLineItemStatusHandler = orderId => {
    navigate('/app/edit-inprgs-order-status-history', { state: { orderId } });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-120px' }} >Edit Order
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allOrders) &&
                (allOrders).map((orders, i) => (
                  <TableRow
                    hover
                    key={i}
                    selected={
                      selectedOrdersIds.indexOf(orders.Order_Id) !== -1
                    }>
                    <TableCell width="14%">{orders?.Order_Id}</TableCell>
                    <TableCell width="17%">{orders?.Email_Id?.substring(0, 20)}...</TableCell>
                    <TableCell width="12%">{orders?.User_Details_Id}</TableCell>
                    <TableCell width="12%">{orders?.Invoice_Number}</TableCell>
                    <TableCell >{orders?.Order_Status}</TableCell>
                    <TableCell width="12%">{orders?.Name}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Order_History_Write?.Value === "Order_History_Write" && RolePermissions?.Order_History_Write?.Is_Write === 1) ?
                      <TableCell width="20%">
                        <Button onClick={() => OrderLineItemStatusHandler(orders.Order_Id)}>
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allOrders) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allOrders: PropTypes.array.isRequired
};

export default Results;