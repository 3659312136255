import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { getAllBiddingPartners, getAllBiddingProducts, getAllBidPartnerAddress, updatePartnerBidAvailProducts } from 'src/store/actions/bidpartneravailproductsAction';

const statusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const discountType = [
    {
        value: '',
        label: '-Please Select-'
    },
    {
        value: 'A',
        label: 'Amount'
    },
    {
        value: 'P',
        label: 'Percentage'
    },
];

const EditPartnerBidAvailProduct = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let { state } = location;

    useEffect(() => {
        let formData = {
            "ExtraCashBack": "",
            "Name": "",
            "Partner_Details_Id": "",
            "Records_Filter": "FILTER",
            "Status": "",
            "Top_Stores": "",
            "Buisness_Type":"RETAIL"
        };
        let data = {
            search_by_filter: "",
            search: "",
            Records_Filter: "FILTER",
            Partner_Details_Id: state.Partner_Detail_Id
        }
        let formData1 = {
            search: "",
            search_by_filter: ""
        }
        dispatch(getAllBiddingPartners(formData));
        dispatch(getAllBiddingProducts(formData1));
        dispatch(getAllBidPartnerAddress(data, callBackUserAddrData));
    }, []);

    const [addressdata, setAddressData] = React.useState([]);
    let USERADDRESS = []
    if (addressdata) {
        for (let i = 0; i < addressdata?.length; i++) {
            USERADDRESS.push(
                {
                    label: addressdata[i].Partner_Address_Id,
                    value: addressdata[i].Partner_Address_Id,
                })
        }
    }

    const callBackUserAddrData = (data) => {
        setAddressData(data)
    }

    const setAddrType = (value) => {
        if (value !== "") {
            let data = {
                search_by_filter: "",
                search: "",
                Records_Filter: "FILTER",
                Partner_Details_Id: value
            }
            dispatch(getAllBidPartnerAddress(data, callBackUserAddrData))
        }
    }

    const isSubmitted = useSelector(state => state.partnerbidavailproducts.partbidavailprodUpdate.isLoading);
    const errorMessage = useSelector(state => state.partnerbidavailproducts.partbidavailprodUpdate.error);
    const bidproducts = useSelector(state => state.partnerbidavailproducts.partbidproducts.all);
    const bidpartners = useSelector(state => state.partnerbidavailproducts.partbidpartners.all);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    return (
        <Formik
            initialValues={{
                partner_bidding_available_products_id: state.Partner_Bidding_Available_Products_Id ? state.Partner_Bidding_Available_Products_Id : "",
                product_id: state.Product_Id ? state.Product_Id : "",
                partner_detail_id: state.Partner_Detail_Id ? state.Partner_Detail_Id : "",
                partner_address_id: state.Partner_Address_Id ? state.Partner_Address_Id : "",
                available_stock: state.Available_Stock ? state.Available_Stock : "",
                is_product_available: state.Is_Product_Available > 0 ? true : false,
                status: state.Status ? state.Status : "",
                modified_by: loginEmail,
                Esi_Commission: state.Esi_Commission ? state.Esi_Commission : "",
                Esi_Commission_Type: state.Esi_Commission_Type ? state.Esi_Commission_Type : "",
            }}
            validationSchema={
                Yup.object().shape({
                    product_id: Yup.string().required('Product Name is required.'),
                    partner_detail_id: Yup.string().required('Partner Name is required.'),
                    partner_address_id: Yup.string().required('Partner Address is required.'),
                    available_stock: Yup.number().min(0, "Available Stock should not be less than 0.").required('Available Stock is required.'),
                    status: Yup.string().required('Status is required.'),
                    Esi_Commission_Type: Yup.string().test('oneOfRequired',
                        'ESI Commission Type is required.',
                        function (item) {
                            return (this.parent.Esi_Commission_Type)
                        }
                    ),
                    Esi_Commission: Yup.number().when("Esi_Commission_Type", (Esi_Commission_Type, schema) => {
                        if (Esi_Commission_Type === "A") {
                            return schema.min(1, "ESI Commission must be at least 1.").required('ESI Commission is required.');
                        } else if (Esi_Commission_Type === "P") {
                            return schema.min(1, "ESI Commission Percentage must be at least 1.").max(100, "ESI Commission Percentage must be less then 100.").required('ESI Commission Percentage is required.');
                        }
                    }),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    if (values.is_product_available) {
                        formValues.is_product_available = 1;
                    } else {
                        formValues.is_product_available = 0;
                    }
                    dispatch(updatePartnerBidAvailProducts(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.partner_detail_id && errors.partner_detail_id)}
                                                fullWidth
                                                helperText={touched.partner_detail_id && errors.partner_detail_id}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setAddrType(e.target.value)
                                                }}
                                                label="Partner Name"
                                                name="partner_detail_id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.partner_detail_id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {bidpartners?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                                                    <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                                                        {option.Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.partner_address_id && errors.partner_address_id)}
                                                fullWidth
                                                helperText={touched.partner_address_id && errors.partner_address_id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Address"
                                                name="partner_address_id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.partner_address_id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {addressdata?.map(option => (
                                                    <option key={option.Partner_Address_Id} value={option.Partner_Address_Id}>
                                                        {/* {option.Address_Type + " - " + option.City + " - " + option.State + " - " + option.Country + " - " + option.Zip + " - " + option.Location} */}
                                                        {option.Location}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.product_id && errors.product_id)}
                                                fullWidth
                                                helperText={touched.product_id && errors.product_id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Product Name"
                                                name="product_id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.product_id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {bidproducts?.sort((a, b) => a.Product_Name?.localeCompare(b.Product_Name))?.map(option => (
                                                    <option key={option.Bidding_Product_Id} value={option.Bidding_Product_Id}>
                                                        {option.Product_Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.available_stock && errors.available_stock)}
                                                fullWidth
                                                helperText={touched.available_stock && errors.available_stock}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Available Stock"
                                                name="available_stock"
                                                value={values.available_stock}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.status && errors.status)}
                                                fullWidth
                                                helperText={touched.status && errors.status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.status}
                                                variant="outlined"
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {/*  ESI Commission Type */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Esi_Commission_Type && errors.Esi_Commission_Type)}
                                                fullWidth
                                                helperText={touched.Esi_Commission_Type && errors.Esi_Commission_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ESI Commission Type"
                                                name="Esi_Commission_Type"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Esi_Commission_Type}
                                                variant="outlined"
                                            >
                                                {discountType.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        {/*  ESI Commission */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Esi_Commission && errors.Esi_Commission)}
                                                fullWidth
                                                helperText={touched.Esi_Commission && errors.Esi_Commission}
                                                onBlur={handleBlur}
                                                label="ESI Commission"
                                                name="Esi_Commission"
                                                type="number"
                                                onChange={handleChange}
                                                value={values.Esi_Commission}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        {/* Is_Product_Available*/}
                                        <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.is_product_available}
                                                        onChange={handleChange}
                                                        name="is_product_available"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Product Available"
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditPartnerBidAvailProduct.propTypes = {
    className: PropTypes.string
};

export default EditPartnerBidAvailProduct;