import { toast } from 'react-toastify';
import { nodeCorpMongoApi } from './commonAxios';
import { paginationAllCorpAWARDGet } from './node-actions/nodepagination';

export const CORPORATE_AWARD_CREATE_REQUEST = "CORPORATE_AWARD_CREATE_REQUEST";
export const CORPORATE_AWARD_CREATE_SUCCESS = "CORPORATE_AWARD_CREATE_SUCCESS";
export const CORPORATE_AWARD_CREATE_FAIL = "CORPORATE_AWARD_CREATE_FAIL";
export const CORPORATE_AWARD_UPDATE_REQUEST = "CORPORATE_AWARD_UPDATE_REQUEST";
export const CORPORATE_AWARD_UPDATE_SUCCESS = "CORPORATE_AWARD_UPDATE_SUCCESS";
export const CORPORATE_AWARD_UPDATE_FAIL = "CORPORATE_AWARD_UPDATE_FAIL";

/*=============================================================
                  Add Corporate AWARD Action
===============================================================*/
export const addcorporateAwardAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CORPORATE_AWARD_CREATE_REQUEST
    });
    nodeCorpMongoApi.post(`Award_Template/create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.code === 201) {
                dispatch({
                    type: CORPORATE_AWARD_CREATE_SUCCESS
                });
                toast('Corporate Award added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corporate-award-management');
            } else {
                dispatch({
                    type: CORPORATE_AWARD_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CORPORATE_AWARD_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

/*=============================================================
                Delete Corporate AWARD ById Action
===============================================================*/
export const deletecorporateAwardById = (formData, filters, pagination, callBackPagination) => async dispatch => {
    try {
        let { data } = await nodeCorpMongoApi.post(`/Award_Template/delete`, formData);
        if (data) {
            toast('Corporate Award deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            dispatch(paginationAllCorpAWARDGet(filters, pagination, callBackPagination));
        }
    } catch (err) {
    }
};

/*=============================================================
                 Update Corporate Award Action
===============================================================*/
export const updatecorporateAwardAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CORPORATE_AWARD_UPDATE_REQUEST
    });
    nodeCorpMongoApi.post(`/Award_Template/update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.code === 200) {
                dispatch({
                    type: CORPORATE_AWARD_UPDATE_SUCCESS
                });
                toast('Corporate Award updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corporate-award-management');
            } else {
                dispatch({
                    type: CORPORATE_AWARD_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CORPORATE_AWARD_UPDATE_FAIL,
                payload: "Please try again later.Currently server is not working"
            });
        })
};