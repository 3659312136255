import {
  GIFT_REQUEST,
  GIFT_SUCCESS,
  GIFT_FAIL,
  GIFT_GET_REQUEST,
  GIFT_GET_SUCCESS,
  GIFT_GET_FAIL,
  GIFT_CREATE_REQUEST,
  GIFT_CREATE_SUCCESS,
  GIFT_CREATE_FAIL,
  GIFT_UPDATE_REQUEST,
  GIFT_UPDATE_SUCCESS,
  GIFT_UPDATE_FAIL,

  GIFT_VENDORID_REQUEST,
  GIFT_VENDORID_SUCCESS,
  GIFT_VENDORID_FAIL,

  GIFT_IMAGEID_REQUEST,
  GIFT_IMAGEID_SUCCESS,
  GIFT_IMAGEID_FAIL
} from '../actions/giftAction'

const initialState = {
  gifts: { all: [], error: '', isLoading: false },
  gift: { gift: {}, error: '', isLoading: false },
  giftCreate: { gift: {}, error: '', isLoading: false },
  giftUpdate: { gift: {}, error: '', isLoading: false },

  giftVendorid: { vendorid: [], error: '', isLoading: false },
  giftImageid: { imageid: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GIFT_REQUEST:
      return { ...state, gifts: { all: state.gifts.all, error: '', isLoading: true } };
    case GIFT_SUCCESS:
      return { ...state, gifts: { all: action.payload, error: '', isLoading: false } };
    case GIFT_FAIL:
      return { ...state, gifts: { all: [], error: action.payload, isLoading: false } };
    case GIFT_GET_REQUEST:
      return { ...state, gift: { gift: {}, error: '', isLoading: true } };
    case GIFT_GET_SUCCESS:
      return { ...state, gift: { gift: action.payload, error: '', isLoading: false } };
    case GIFT_GET_FAIL:
      return { ...state, gift: { gift: {}, error: action.payload, isLoading: false } };
    case GIFT_CREATE_REQUEST:
      return { ...state, giftCreate: { gift: {}, error: '', isLoading: true } };
    case GIFT_CREATE_SUCCESS:
      return { ...state, giftCreate: { gift: action.payload, error: '', isLoading: false } };
    case GIFT_CREATE_FAIL:
      return { ...state, giftCreate: { gift: {}, error: action.payload, isLoading: false } };
    case GIFT_UPDATE_REQUEST:
      return { ...state, giftUpdate: { gift: {}, error: '', isLoading: true } };
    case GIFT_UPDATE_SUCCESS:
      return { ...state, giftUpdate: { gift: action.payload, error: '', isLoading: false } };
    case GIFT_UPDATE_FAIL:
      return { ...state, giftUpdate: { gift: {}, error: action.payload, isLoading: false } };

    case GIFT_VENDORID_REQUEST:
      return { ...state, giftVendorid: { vendorid: state.giftVendorid.vendorid, error: '', isLoading: true }, };
    case GIFT_VENDORID_SUCCESS:
      return { ...state, giftVendorid: { vendorid: action.payload, error: '', isLoading: false } };
    case GIFT_VENDORID_FAIL:
      return { ...state, giftVendorid: { vendorid: [], error: action.payload, isLoading: false } };

    case GIFT_IMAGEID_REQUEST:
      return { ...state, giftImageid: { imageid: state.giftImageid.imageid, error: '', isLoading: true }, };
    case GIFT_IMAGEID_SUCCESS:
      return { ...state, giftImageid: { imageid: action.payload, error: '', isLoading: false } };
    case GIFT_IMAGEID_FAIL:
      return { ...state, giftImageid: { imageid: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}