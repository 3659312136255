import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { boomiAdminApi } from 'src/store/actions/commonAxios';

const ESIState = (props) => {
    const { countrycode, ...rest } = props
    const [countryField, setCountryField] = React.useState();
    const [state, setState] = useState();

    React.useEffect(() => {
        if (countrycode && (countrycode !== countryField)) {
            setCountryField(countrycode);
            let statesData = {
                "Filter_Type": "STATE",
                "Country": countrycode
            }
            getStates(statesData)
        }
    }, [countrycode])

    const getStates = (statesData) => {
        boomiAdminApi.post(`/Country_State_City/Get`, statesData)
            .then(response => {
                setState(response?.data?.State);
            })
            .catch(error => {
            })
    }

    return (
        <TextField {...props}>
            <option key="" value="">--Please Select--</option>
            {state?.map(option => (
                <option key={option.STATE_CODE} value={option.STATE_CODE}>
                    {option.NAME}
                </option>
            ))}
        </TextField>
    );
};

export default ESIState;