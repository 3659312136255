import { v4 as uuid } from 'uuid';

export default [
    {
        id: uuid(),
        hide: 0,
        link: '/app/user-bidding-products',
        createdAt: '',
        description: 'Partner Bidding Available Products are used to store the Quantity of Availability of Products from Partners.',
        media: '/static/Dashboard/bidding.png',
        title: 'User Bidding Products',
        total: ''
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/bidding-category',
        createdAt: '',
        description: 'Partner Bidding Available Products are used to store the Quantity of Availability of Products from Partners.',
        media: '/static/Dashboard/bidding.png',
        title: 'Bidding Products Category',
        total: ''
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/bidding-product',
        createdAt: '',
        description: 'Partner Bidding Available Products are used to store the Quantity of Availability of Products from Partners.',
        media: '/static/Dashboard/bidding.png',
        title: 'Bidding Products',
        total: ''
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/partner-bidding-available-products',
        createdAt: '',
        description: 'Partner Bidding Available Products are used to store the Quantity of Availability of Products from Partners.',
        media: '/static/Dashboard/bidding.png',
        title: 'Partner Bidding Available Products',
        total: ''
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/bidding-products-success-stories',
        createdAt: '',
        description: 'Partner Bidding Available Products are used to store the Quantity of Availability of Products from Partners.',
        media: '/static/Dashboard/bidding.png',
        title: 'Bidding Products Success Stories',
        total: ''
    }
];