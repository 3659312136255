import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import EditCityLocation from './EditCityLocation'
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEmpty from 'src/store/validations/is-empty';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const EditCityLocationView = () => {
  const classes = useStyles();
  const location = useLocation();
  let { state } = location;
  const { data } = state.data;

  const [allcitylocation, setCitylocation] = useState([]);

  let citylocations = useSelector(state => state.citylocation.allcitylocation);
  useEffect(() => {
    if (!isEmpty(citylocations)) {
      setCitylocation(citylocations.citylocation);
    }
  }, [citylocations]);

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/city-location"
          variant="text"
          size="small"
          color="inherit"
          state={state.state}
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Edit City Location
          </Typography>
        </Box>
        <EditCityLocation allcitylocation={allcitylocation} state={state.state} data={state.data} />
      </Container>
    </Page>
  );
};

export default EditCityLocationView;