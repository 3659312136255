import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  images: {
    width: 70,
    height: 70,
    cursor: 'pointer'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));

const headCells = [
  { id: 'Partner_Service_Generic_Id', numeric: false, disablePadding: false, label: 'Partner Service Generic ID' },
  { id: 'Created_By', numeric: true, disablePadding: false, label: 'Created By' },
  { id: 'Service_Type', numeric: false, disablePadding: false, label: 'Service Type' },
  { id: 'City_Name', numeric: false, disablePadding: false, label: 'City Name' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allService,
  deleteServiceHandler,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  setPage,
  allServicesNoFilter,
  createSortHandler,
  RolePermissions,
  roleUserType,
  pagination,
  setPagination,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedTestimonialIds, setSelectedTestimonialIds] = useState([]);

  const visibleGenericServiceHandler = serviceData => e => {
    navigate("/app/view-genericservices", { state: serviceData });
  };
  const editGenericServiceHandler = serviceData => e => {
    navigate('/app/edit-genericservice', { state: serviceData });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.id !== "Profile_Pic" ? (
                      <TableSortLabel style={{ fontSize: "13px", fontWeight: "bold" }}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>) : (<span style={{ fontSize: "13px", fontWeight: "bold" }} > {headCell.label}</span>)
                    }
                  </TableCell>
                ))}
                <TableCell
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  className={classes.headerColumn}> Actions &nbsp;&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allService) &&
                (allService).map(service => (
                  <TableRow
                    hover
                    key={service.Partner_Service_Generic_Id}
                    selected={
                      selectedTestimonialIds.indexOf(service.Partner_Service_Generic_Id) !== -1
                    }
                  >
                    <TableCell>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {service.Partner_Service_Generic_Id}</TableCell>
                    <TableCell style={{ color: '#1665D8' }}>
                      {service.Created_By?.substring(0, 12)}...
                    </TableCell>
                    <TableCell>{service.Service_Type}</TableCell>
                    <TableCell>{service.City_Name}</TableCell>
                    <TableCell>{service.Status}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Services_Generic_Management_Write?.Value === "Services_Generic_Management_Write" && RolePermissions?.Services_Generic_Management_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Tooltip title="View Service History">
                          <Button
                            onClick={visibleGenericServiceHandler(service)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        <Button
                          onClick={editGenericServiceHandler(service)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Services_Generic_Management_Read?.Value === "Services_Generic_Management_Read" && RolePermissions?.Services_Generic_Management_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="View Service History">
                          <Button
                            onClick={visibleGenericServiceHandler(service)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allService) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;