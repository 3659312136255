import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import SimpleDialog from './../../../components/dialogs/dialog'
import DeleteIcon from '@material-ui/icons/Delete';
import { CgSize } from "react-icons/cg";
import { IoIosColorPalette } from "react-icons/io";
import isEmpty from '../../../store/validations/is-empty';
import { Tooltip } from '@material-ui/core';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Product_Master_Id', numeric: false, disablePadding: true, label: 'Product Master ID' },
  { id: 'Sku', numeric: false, disablePadding: false, label: 'SKU ID' },
  { id: 'Product_Name', numeric: false, disablePadding: false, label: 'Product Master Name' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' }
];

const Results = ({
  className,
  allProductMasters,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  pagination,
  setPagination,
  createSortHandler,
  deleteProductMasterHandler,
  allProductMastersNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const handleClose = (value) => {
    setOpen(false);
  };
  /*===============================
             handler     
  =================================*/

  const handleSelectAll = event => {
    let newSelectedProductIds;
    if (event.target.checked) {
      newSelectedProductIds = allProductMasters.map(productmaster => productmaster.Product_Master_Id);
    }
    else {
      newSelectedProductIds = [];
    }
    setSelectedProductIds(newSelectedProductIds);
  };
  const handleSelectOne = (event, Id) => {
    const selectedIndex = selectedProductIds.indexOf(Id);
    let newselectedProductIds = [];
    if (selectedIndex === -1) {
      newselectedProductIds = newselectedProductIds.concat(selectedProductIds, Id);
    } else if (selectedIndex === 0) {
      newselectedProductIds = newselectedProductIds.concat(
        selectedProductIds.slice(1)
      );
    } else if (selectedIndex === selectedProductIds.length - 1) {
      newselectedProductIds = newselectedProductIds.concat(
        selectedProductIds.slice(0, -1)
      );
    }
    else if (selectedIndex > 0) {
      newselectedProductIds = newselectedProductIds.concat(
        selectedProductIds.slice(0, selectedIndex),
        selectedProductIds.slice(selectedIndex + 1)
      );
    }
    setSelectedProductIds(newselectedProductIds);
  };

  const deleteSelectedProductMasterHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const editProductMasterHandler = productmasterData => e => {
    navigate('/app/edit-product-master', { state: productmasterData });
  };

  const OpenPartnerHandler = productmasterData => e => {
    navigate('/app/product-management/product-sizes', { state: productmasterData });
  };

  const OpenPartnerHandler1 = productmasterData => e => {
    navigate('/app/product-management/product-color-variants', { state: productmasterData });
  };

  /*=================================
              handler end
  =================================*/
  return (
    <Card className={clsx(classes.root, className)}
      {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedProductIds.filter(record => {
                      for (let i = 0; i < allProductMastersNoFilter.length; i++) {
                        if (record == allProductMastersNoFilter[i].Product_Master_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allProductMasters.length}
                    color="primary"
                    indeterminate={
                      selectedProductIds.filter(record => {
                        for (let i = 0; i < allProductMastersNoFilter.length; i++) {
                          if (record == allProductMastersNoFilter[i].Product_Master_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedProductIds.filter(record => {
                        for (let i = 0; i < allProductMastersNoFilter.length; i++) {
                          if (record == allProductMastersNoFilter[i].Product_Master_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allProductMasters.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Product_Master_Write?.Value === "Product_Master_Write" && RolePermissions?.Product_Master_Write?.Is_Write === 1) ?
                  <TableCell className={classes.headerColumn} >Product Sizes & Colors </TableCell>
                  : null}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions &nbsp;&nbsp;&nbsp;
                  {selectedProductIds.filter(record => {
                    for (let i = 0; i < allProductMastersNoFilter.length; i++) {
                      if (record == allProductMastersNoFilter[i].Product_Master_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 &&
                    <Button disabled={selectedProductIds.filter(record => {
                      for (let i = 0; i < allProductMastersNoFilter.length; i++) {
                        if (record == allProductMastersNoFilter[i].Product_Master_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length <= 0}
                      onClick={deleteSelectedProductMasterHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allProductMasters) && (allProductMasters).map(productmaster => (
                <TableRow
                  hover
                  key={productmaster.Product_Master_Id}
                  selected={
                    selectedProductIds.indexOf(productmaster.Product_Master_Id) !== -1
                  }>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedProductIds.indexOf(productmaster.Product_Master_Id) !== -1
                      }
                      onChange={event =>
                        handleSelectOne(event, productmaster.Product_Master_Id)
                      }
                      value="true"
                    />
                  </TableCell>
                  <TableCell >{productmaster.Product_Master_Id}</TableCell>
                  <TableCell >{productmaster.Sku}</TableCell>
                  <TableCell style={{ color: '#1665D8' }} >
                    <span title={productmaster.Product_Name}>
                      {productmaster.Product_Name?.substring(0, 15)}...
                    </span>
                  </TableCell>
                  <TableCell style={{ color: '#1665D8', }}>
                    {productmaster.Status}
                  </TableCell>
                  {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                    (RolePermissions?.Product_Master_Write?.Value === "Product_Master_Write" && RolePermissions?.Product_Master_Write?.Is_Write === 1) ?
                    <TableCell >
                      <Tooltip title="Size">
                        <Button>
                          < CgSize
                            onClick={OpenPartnerHandler(productmaster)}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#9EA0A5', fontSize: 20, }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Color">
                        <Button>
                          < IoIosColorPalette
                            onClick={OpenPartnerHandler1(productmaster)}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#9EA0A5', fontSize: 20, }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                    : null}
                  {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                    (RolePermissions?.Product_Master_Write?.Value === "Product_Master_Write" && RolePermissions?.Product_Master_Write?.Is_Write === 1) ?
                    <TableCell style={{ textAlign: "center", }}>
                      <Button
                        onClick={editProductMasterHandler(productmaster)}
                      >
                        <CreateIcon style={{ color: '#9EA0A5' }} />
                      </Button>
                      <Button>
                        <DeleteIcon
                          onClick={deleteProductMasterHandler(productmaster)}
                          style={{ color: '#9EA0A5', }}
                        />
                      </Button>
                    </TableCell>
                    : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allProductMasters) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allProductMasters: PropTypes.array.isRequired
};

export default Results;