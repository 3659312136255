import {

  DELIVERY_TICKET_GET_REQUEST,
  DELIVERY_TICKET_GET_SUCCESS,
  DELIVERY_TICKET_GET_FAIL,
  DELIVERY_TICKET_UPDATE_REQUEST,
  DELIVERY_TICKET_UPDATE_SUCCESS,
  DELIVERY_TICKET_UPDATE_FAIL,
  DELIVERY_TICKET_CREATE_REQUEST,
  DELIVERY_TICKET_CREATE_SUCCESS,
  DELIVERY_TICKET_CREATE_FAIL,
  DELIVERY_PROFILES_REQUEST,
  DELIVERY_PROFILES_SUCCESS,
  DELIVERY_PROFILES_FAIL,
} from '../actions/deliveryticketActions'

const initialState = {
  deliverytickets: { all: [], error: '', isLoading: false },
  deliveryticket: { deliveryticket: {}, error: '', isLoading: false },
  deliveryticketUpdate: { deliveryticket: {}, error: '', isLoading: false },
  deliveryticketCreate: { deliveryticket: {}, error: '', isLoading: false },
  deliveryusers: { users: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {

    case DELIVERY_TICKET_GET_REQUEST:
      return { ...state, deliveryticket: { deliveryticket: {}, error: '', isLoading: true } };
    case DELIVERY_TICKET_GET_SUCCESS:
      return { ...state, deliveryticket: { deliveryticket: action.payload, error: '', isLoading: false } };
    case DELIVERY_TICKET_GET_FAIL:
      return { ...state, deliveryticket: { deliveryticket: {}, error: action.payload, isLoading: false } };
    case DELIVERY_TICKET_UPDATE_REQUEST:
      return { ...state, deliveryticketUpdate: { deliveryticket: {}, error: '', isLoading: true } };
    case DELIVERY_TICKET_UPDATE_SUCCESS:
      return { ...state, deliveryticketUpdate: { deliveryticket: state.deliveryticketUpdate.deliveryticket, error: '', isLoading: false } };
    case DELIVERY_TICKET_UPDATE_FAIL:
      return { ...state, deliveryticketUpdate: { deliveryticket: {}, error: action.payload, isLoading: false } };
    case DELIVERY_TICKET_CREATE_REQUEST:
      return { ...state, deliveryticketCreate: { deliveryticket: {}, error: '', isLoading: true } };
    case DELIVERY_TICKET_CREATE_SUCCESS:
      return { ...state, deliveryticketCreate: { deliveryticket: state.deliveryticketCreate.deliveryticket, error: '', isLoading: false } };
    case DELIVERY_TICKET_CREATE_FAIL:
      return { ...state, deliveryticketCreate: { deliveryticket: {}, error: action.payload, isLoading: false } };
    case DELIVERY_PROFILES_REQUEST:
      return { ...state, deliveryusers: { users: state.deliveryusers.users, error: '', isLoading: true }, };
    case DELIVERY_PROFILES_SUCCESS:
      return { ...state, deliveryusers: { users: action.payload, error: '', isLoading: false } };
    case DELIVERY_PROFILES_FAIL:
      return { ...state, deliveryusers: { users: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}