import { toast } from 'react-toastify';
import { boomiAdminApi, nodeGetApi } from './commonAxios';

export const B2B_USER_BID_COMMENTS_UPDATE_REQUEST = "B2B_USER_BID_COMMENTS_UPDATE_REQUEST";
export const B2B_USER_BID_COMMENTS_UPDATE_SUCCESS = "B2B_USER_BID_COMMENTS_UPDATE_SUCCESS";
export const B2B_USER_BID_COMMENTS_UPDATE_FAIL = "B2B_USER_BID_COMMENTS_UPDATE_FAIL";

//update B2B Partner Bidding Comments Action
export const updateB2BUserProdBiddingComments = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: B2B_USER_BID_COMMENTS_UPDATE_REQUEST
    });
    boomiAdminApi.put(`/B2B_Bidding_Product_Comments/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: B2B_USER_BID_COMMENTS_UPDATE_SUCCESS
                });
                toast('B2B User Product Bidding Comment updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/b2b-user-product-bidding-comments', state = { state });
            } else {
                toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                dispatch({
                    type: B2B_USER_BID_COMMENTS_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: B2B_USER_BID_COMMENTS_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//Get B2B User Products Bidding Comment Action
export const getB2BBiddingProductCommentAction = (formData, callBackB2BBiddingProductQuote) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/B2B/Bidding_Comments`, formData);
        if (data) {
            callBackB2BBiddingProductQuote(data)
        }
    } catch (err) {
    }
};