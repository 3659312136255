import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  buttonRoot: {
    minWidth: '7px',
    padding: '6px'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));

const headCells = [
  { id: 'Created_Date', numeric: false, disablePadding: false, label: 'Coupon ID' },
  { id: 'Transaction_Id', numeric: false, disablePadding: false, label: 'Transaction ID' },
  // { id: 'Order_Id', numeric: false, disablePadding: false, label: 'Order ID' },
  { id: 'User_Details_Id', numeric: false, disablePadding: false, label: 'User Details Id' },
  { id: 'Partner_Name', numeric: true, disablePadding: false, label: 'Partner Name' },
  { id: 'Coupon_Code', numeric: true, disablePadding: false, label: 'Coupon Code' },
  { id: 'Payment_Transaction_Status', numeric: false, disablePadding: false, label: 'Transaction Status' },
];

const Results = ({
  className,
  allTransactions,
  deleteTransactionHandler,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allTransactionsNoFilter,
  pagination,
  setPagination,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedTransactionIds, setSelectedTransactionIds] = useState([]);

  // const editTransactionHandler = transactionData => e => {
  //   navigate('/app/edit-transaction', { state: transactionData });
  // };
  const visibleTransactionHandler = transactionData => e => {
    navigate("/app/partner-cupn-utlze-details", { state: transactionData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell, index) => (
                  <TableCell
                    key={index}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel style={{ fontSize: "13px", fontWeight: "bold" }}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allTransactions) &&
                (allTransactions).map(transaction => (
                  <TableRow
                    hover
                    key={transaction.Coupon_Utilisation_Id}
                    selected={
                      selectedTransactionIds.indexOf(transaction.Coupon_Utilisation_Id) !== -1
                    }
                  >
                    <TableCell>{transaction.Coupon_Utilisation_Id}</TableCell>
                    <TableCell>{transaction.Transaction_Id}</TableCell>
                    {/* <TableCell>{transaction.Order_Id}</TableCell> */}
                    <TableCell>{transaction.User_Details_Id}</TableCell>
                    <TableCell>{transaction.Partner_Name}</TableCell>
                    <TableCell>{transaction.Coupon_Code}</TableCell>
                    <TableCell>{transaction.Payment_Transaction_Status}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Transaction_History_Write?.Value === "Transaction_History_Write" && RolePermissions?.Transaction_History_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Tooltip title="View Coupon Details">
                          <Button
                            onClick={visibleTransactionHandler(transaction)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        {/* <Button
                          onClick={editTransactionHandler(transaction)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button> */}
                      </TableCell>
                      : null}
                    {RolePermissions?.Transaction_History_Read?.Value === "Transaction_History_Read" && RolePermissions?.Transaction_History_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="View Transaction History">
                          <Button
                            onClick={visibleTransactionHandler(transaction)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allTransactions) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allTransactions: PropTypes.array.isRequired
};

export default Results;