import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateNewseventsAction, uploadNewsEventImage,
} from './../../store/actions/newseventsAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { useLocation } from 'react-router-dom';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const newstypeOption = [
  {
    value: '--Please Select--',
    label: '--Please Select--'
  },
  {
    value: 'NEWS',
    label: 'NEWS'
  },
  {
    value: 'EVENT',
    label: 'EVENT'
  }
];
const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditNewsevent = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  let { state } = location;

  const errorMessage = useSelector(state => state.newsevents.newseventUpdate.error);
  const isSubmited = useSelector(state => state.newsevents.newseventUpdate.isLoading);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Description || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Description);

  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // Richtext editor
  const contentStateMeta = ContentState.createFromBlockArray(htmlToDraft(state.Meta_Description || "").contentBlocks);
  const [editorStateMeta, setEditorStateMeta] = useState(EditorState.createWithContent(contentStateMeta));
  const [rhDiscriptionMeta, setRhDiscriptionMeta] = useState(state.Meta_Description);
  const [rhDiscriptionMetaMin, setRhDiscriptionMetasMin] = useState(20);
  const onEditorStateChangeMeta = (editorStateMeta) => {
    setEditorStateMeta(editorStateMeta);
    setRhDiscriptionMeta(draftToHtml(convertToRaw(editorStateMeta.getCurrentContent())));
  };

  // img upload
  const [image, setImage] = useState({ preview: state.Image, raw: state.Image });
  const [imgUrl, setImgUrl] = useState(state.Image);
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImage = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadNewsEventImage(new Blob([e.target.files[0]]), callBackUploadImage)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        News_Events_Id: state.News_Events_Id ? state.News_Events_Id : "",
        Author: state.Author ? state.Author : "",
        Modified_By: modifiedBy,
        Description: state.Description ? state.Description : "",
        Meta_Description: state.Meta_Description ? state.Meta_Description : "",
        Meta_Keywords: state.Meta_Keywords ? state.Meta_Keywords : "",
        Short_Description: state.Short_Description ? state.Short_Description : "",
        Title: state.Title ? state.Title : "",
        Status: state.Status ? state.Status : "",
        Type: state.Type ? state.Type : "",
        Image: imgUrl,
      }}
      validationSchema={
        Yup.object().shape({
          Author: Yup.string().min(3, "Author should have atleast 3 characters.").required(" Author is required."),
          Title: Yup.string().required(' Title is required.'),
          Meta_Keywords: Yup.string().required(' Meta Keywords are required.'),
          Short_Description: Yup.string().required(' Short Description is required.'),
          Status: Yup.string().required('Status is required.'),
          Type: Yup.string().required('Type  is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        let submitTest = true;
        const errors = {};
        values.Image = imgUrl;
        values.Description = rhDiscription;
        values.Meta_Description = rhDiscriptionMeta;

        if (!values.Image) {
          submitTest = false;
          errors.Image = "Image is required.";
        }
        if (!values.Image) {
          submitTest = false;
          errors.Image = "Image is required.";
        }
        if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
        if (!values.Meta_Description || values.Meta_Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Meta_Description = "Meta Description is required.";
        }
        else if (values.Meta_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMetaMin) {
          submitTest = false;
          errors.Meta_Description = "Please provide at least " + rhDiscriptionMetaMin + " characters.";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(updateNewseventsAction(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}
        >
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Author && errors.Author)}
                        fullWidth
                        helperText={touched.Author && errors.Author}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Author"
                        name="Author"
                        value={values.Author}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Title && errors.Title)}
                        fullWidth
                        helperText={touched.Title && errors.Title}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Title"
                        name="Title"
                        value={values.Title}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Short_Description && errors.Short_Description)}
                        fullWidth
                        helperText={touched.Short_Description && errors.Short_Description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Short Description"
                        name="Short_Description"
                        value={values.Short_Description}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Discription  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                      </span>
                    </Grid>
                    {/* Meta  Description  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Meta Description
                        <EditorContainer editorState={editorStateMeta} onEditorStateChange={onEditorStateChangeMeta} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscriptionMeta || rhDiscriptionMeta.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMetaMin) && touched.Meta_Description && errors.Meta_Description}
                      </span>
                    </Grid>
                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Meta_Keywords && errors.Meta_Keywords)}
                        fullWidth
                        helperText={touched.Meta_Keywords && errors.Meta_Keywords}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Meta Keywords"
                        name="Meta_Keywords"
                        value={values.Meta_Keywords}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Type */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Type && errors.Type)}
                        fullWidth
                        helperText={touched.Type && errors.Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Type"
                        name="Type"
                        select
                        SelectProps={{ native: true }}
                        value={values.Type}
                        variant="outlined"
                      >
                        {newstypeOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                  </Grid>
                </Grid>
                {/* upload photo */}
                <Grid item md={4} xs={12}>
                  <Card>
                    &nbsp;Image
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Image Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>

                    </CardContent>
                  </Card><br />
                  <span style={{ color: "red" }}>
                    {!imgUrl && touched.Image && errors.Image}
                  </span>
                  <div style={{ marginBottom: '5%' }}></div>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )
      }
    </Formik>
  )
}

EditNewsevent.propTypes = {
  className: PropTypes.string
};

export default EditNewsevent;