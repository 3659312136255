import { toast } from 'react-toastify';
import { paginationCorporateTicket } from './node-actions/nodepagination';
import { nodeCorpMongoApi } from './commonAxios';

export const CORP_TICKET_GET_REQUEST = "CORP_TICKET_GET_REQUEST";
export const CORP_TICKET_GET_SUCCESS = "CORP_TICKET_GET_SUCCESS";
export const CORP_TICKET_GET_FAIL = "CORP_TICKET_GET_FAIL";

export const CORP_TICKET_CREATE_REQUEST = "CORP_TICKET_CREATE_REQUEST";
export const CORP_TICKET_CREATE_SUCCESS = "CORP_TICKET_CREATE_SUCCESS";
export const CORP_TICKET_CREATE_FAIL = "CORP_TICKET_CREATE_FAIL";

export const CORP_TICKET_UPDATE_REQUEST = "CORP_TICKET_UPDATE_REQUEST";
export const CORP_TICKET_UPDATE_SUCCESS = "CORP_TICKET_UPDATE_SUCCESS";
export const CORP_TICKET_UPDATE_FAIL = "CORP_TICKET_UPDATE_FAIL";

export const CORP_PROFILES_REQUEST = "CORP_PROFILES_REQUEST";
export const CORP_PROFILES_SUCCESS = "CORP_PROFILES_SUCCESS";
export const CORP_PROFILES_FAIL = "CORP_PROFILES_FAIL";


export const createCorpTicketAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: CORP_TICKET_CREATE_REQUEST
  });
  nodeCorpMongoApi.post(`/corpTicket/create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse?.code === 201 && successResponse?.message === "ticket created succssfully") {
        dispatch({
          type: CORP_TICKET_CREATE_SUCCESS
        });
        toast('Corporate Ticket created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/corporate-ticket-management');
      } else {
        dispatch({
          type: CORP_TICKET_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: CORP_TICKET_CREATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

export const updatedCorpTicketAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: CORP_TICKET_UPDATE_REQUEST
  });
  nodeCorpMongoApi.post(`/corpTicket/update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse?.code === 200 && successResponse?.message === "successfully updated the ticket") {
        dispatch({
          type: CORP_TICKET_UPDATE_SUCCESS
        });
        toast('Corporate Ticket updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/corporate-ticket-management');
      } else {
        dispatch({
          type: CORP_TICKET_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: CORP_TICKET_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};


export const deletedCorpTicketAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCorpMongoApi.post(`/corpTicket/delete`, formData);
    if (data) {
      toast('Corporate Ticket deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationCorporateTicket(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};


//Get users
export const getAllCorpProfilesAction = (emailid) => async dispatch => {
  try {
    let formData = {
      "Search_By_Filter": emailid,
      "Search": "",
      "Is_SA_Approved": true,
      "Is_SA_Rejected": false
    };
    dispatch({
      type: CORP_PROFILES_REQUEST
    });
    
    let { data } = await nodeCorpMongoApi.post(`/corpEnquiry/get`, formData);
    if (data) {
      if (data.status < 200 || data.status >= 300) {
        dispatch({
          type: CORP_PROFILES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CORP_PROFILES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again", 3000);
    dispatch({
      type: CORP_PROFILES_FAIL,
      payload: err
    });
  }
};

//Get Ticket Action
// export const getCorpTicketAction = (formData, callBackTicketData) => async dispatch => {
//   try {
//     let { data } = await nodeCorpMongoApi.post(`/Delivery_Ticket/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Del_Ticket_Id&SortOrder=ASC`, formData);
//     if (data) {
//         callBackTicketData(data)
//     }
//   } catch (err) {
//   }
// };