
import config from './config';
import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi,boomiAdminApi } from './commonAxios';

export const SERVICES_PARTNER_REQUEST = "SERVICES_PARTNER_REQUEST";
export const SERVICES_PARTNER_SUCCESS = "SERVICES_PARTNER_SUCCESS";
export const SERVICES_PARTNER_FAIL = "SERVICES_PARTNER_FAIL";
export const SERVICES_PARTNER_GET_REQUEST = "SERVICES_PARTNER_GET_REQUEST";
export const SERVICES_PARTNER_GET_SUCCESS = "SERVICES_PARTNER_GET_SUCCESS";
export const SERVICES_PARTNER_GET_FAIL = "SERVICES_PARTNER_GET_FAIL";

export const SERVICES_PARTNER_CREATE_REQUEST = "SERVICES_PARTNER_CREATE_REQUEST";
export const SERVICES_PARTNER_CREATE_SUCCESS = "SERVICES_PARTNER_CREATE_SUCCESS";
export const SERVICES_PARTNER_CREATE_FAIL = "SERVICES_PARTNER_CREATE_FAIL";

export const SERVICES_PARTNER_UPDATE_REQUEST = "SERVICES_PARTNER_UPDATE_REQUEST";
export const SERVICES_PARTNER_UPDATE_SUCCESS = "SERVICES_PARTNER_UPDATE_SUCCESS";
export const SERVICES_PARTNER_UPDATE_FAIL = "SERVICES_PARTNER_UPDATE_FAIL";

export const PARTNERS_BUSINESSTYPE_REQUEST = "PARTNERS_BUSINESSTYPE_REQUEST";
export const PARTNERS_BUSINESSTYPE_SUCCESS = "PARTNERS_BUSINESSTYPE_SUCCESS";
export const PARTNERS_BUSINESSTYPE_FAIL = "PARTNERS_BUSINESSTYPE_FAIL";

export const PARTNERS_SERVICE_REQUEST = "PARTNERS_SERVICE_REQUEST";
export const PARTNERS_SERVICE_SUCCESS = "PARTNERS_SERVICE_SUCCESS";
export const PARTNERS_SERVICE_FAIL = "PARTNERS_SERVICE_FAIL";



/*=============================================================
                  Update Partner Service Action
===============================================================*/
export const updatepartnerServiceAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: SERVICES_PARTNER_UPDATE_REQUEST
  });
  setErrorMessage({ global: { key: '', message: '' } });
  nodeCudApi.post(`/Partner_Service/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICES_PARTNER_UPDATE_SUCCESS
        });
        toast('Partner Services updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partnerservices-management');
      } else {
        const errors = {};
        setErrors(errors)
        setErrorMessage({ global: { key: '', message: successResponse.UI_Display_Message ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: SERVICES_PARTNER_UPDATE_SUCCESS,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: SERVICES_PARTNER_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                  Get All  Partner service Action
===============================================================*/
export const getAllPartnerServicesAction = formData => async dispatch => {
  try {
    dispatch({
      type: SERVICES_PARTNER_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partner_Service/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_SERVICE_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SERVICES_PARTNER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({

          type: SERVICES_PARTNER_SUCCESS,
          payload: data.results

        });
      }
    }
  } catch (err) {
    dispatch({
      type: SERVICES_PARTNER_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  Add Partner Service Action
===============================================================*/
export const addpartnerServiceAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: SERVICES_PARTNER_CREATE_REQUEST
  });
  setErrorMessage({ global: { key: '', message: '' } });
  await nodeCudApi.post(`/Partner_Service/Create`,
    formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICES_PARTNER_CREATE_SUCCESS
        });
        toast('Partner Services added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partnerservices-management');
      } else {
        const errors = {};
        setErrors(errors)
        setErrorMessage({ global: { key: '', message: successResponse.UI_Display_Message ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: SERVICES_PARTNER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: SERVICES_PARTNER_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
               Image Upload Action
===============================================================*/
export const uploadPartnerServiceImage = (
  formData,
  callBackUploadImgae
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=PartnerService&fileextension=png&filetype=Images&filename=PartnerService`,
      formData
    );
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) {
  }
};

export const getbussinessubtypeAction = (formData, callBacksubtypeData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Partner_Registration/Get?PageNo=0&PageSize=${config.pageSize}&SortBy=BUSINESS_TYPE&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBacksubtypeData(data.results)
    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};

/*=============================================================
                 Get all details
===============================================================*/
export const getAllPartnerDetails = () => async dispatch => {
  try {
    let formData = {
      "ExtraCashBack": "",
      "Name": "",
      "Partner_Details_Id": "",
      "Records_Filter": "FILTER",
      "Status": "",
      "Top_Stores": "",
      "Buisness_Type": "SERVICES"
    };
    dispatch({
      type: PARTNERS_SERVICE_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partners/Physical_Partner_Profile_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.successResponse?.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNERS_SERVICE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNERS_SERVICE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PARTNERS_SERVICE_FAIL,
      payload: err
    });
  }
};

//Get Partnet Service Action
export const getPartnerServiceAction = (formData, callBackPartnerServiceData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Partner_Service/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_SERVICE_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBackPartnerServiceData(data)
    }
  } catch (err) {
  }
};