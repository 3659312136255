import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Button
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  RazorpayTransactionFilter
} from '../../../store/actions/razorpaytransactionAction';
const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  formFilterControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const Toolbar = ({ className, state, RolePermissions, roleUserType, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  let razorpaytransactionMenu = useSelector(state => state.razorpaytransaction.razorpaytransactionsFilter.select);
  let razorpaytransactionSearch = useSelector(state => state.razorpaytransaction.razorpaytransactionsFilter.search);

  const handleChange = event => {
    dispatch(RazorpayTransactionFilter('select', event.target.value));
  };
  const handleSearch = event => {
    dispatch(RazorpayTransactionFilter('search', event.target.value));
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" flexWrap="wrap" mb={2}>
          <FormControl variant="outlined"
            className={classes.formFilterControl}>
            <Select
              value={razorpaytransactionMenu}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="ALL">All</MenuItem>
              <MenuItem value="Id">ID</MenuItem>
              <MenuItem value="Amount">Amount</MenuItem>
              <MenuItem value="Purpose">Purpose</MenuItem>
              <MenuItem value="Status">Status</MenuItem>
              <MenuItem value="Contact_Id">Contact ID</MenuItem>
            </Select>
          </FormControl>
          <Box width={500}>
            <TextField onChange={handleSearch}
              className={classes.formControl}
              value={razorpaytransactionSearch}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search Razorpay Transaction History"
              variant="outlined"
            />
          </Box>
        </Box>
        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
         (RolePermissions?.Razorpay_Payments_History_Write?.Value === "Razorpay_Payments_History_Write" && RolePermissions?.Razorpay_Payments_History_Write?.Is_Write === 1) ?
        <Button
          component={RouterLink}
          to="/app/add-razorpayhistory"
          color="primary"
          style={{ backgroundColor: '#27B6CC', marginTop: '-1.5%' }}
          variant="contained"
          state={state}
        >
          <span style={{ color: "white" }}>
            + ADD TRANSACTIONS
          </span>
        </Button>
       :null}
      </Box>
    </div>
  );
};
Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
