import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useSelector } from 'react-redux';
import Page from 'src/components/Page';
import AddPartneraddress from './AddPartneraddress';
import isEmpty from './../../store/validations/is-empty';
import { useLocation } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AddPartneraddressView = () => {
  const location = useLocation();
  const { state } = location;
  const classes = useStyles();

  const [allpartneraddress, setPartneraddress] = useState([]);

  let partnersaddress = useSelector(state => state.partneraddress.allpartneraddress);
  useEffect(() => {
    if (!isEmpty(partnersaddress)) {
      setPartneraddress(partnersaddress.partneraddress);
    }
  }, [partnersaddress]);

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/partneraddress-management"
          variant="text"
          size="small"
          color="inherit"
          state={state}
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Add Partner Address
          </Typography>
        </Box>
        <AddPartneraddress allpartneraddress={allpartneraddress} state={state} />
      </Container>
    </Page>
  );
};

export default AddPartneraddressView;