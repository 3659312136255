
import config from './config';
import { toast } from 'react-toastify';
import {  nodeGetApi,boomiAdminApi } from './commonAxios';

export const WALLETS_PARTNER_REQUEST = "WALLETS_PARTNER_REQUEST";
export const WALLETS_PARTNER_SUCCESS = "WALLETS_PARTNER_SUCCESS";
export const WALLETS_PARTNER_FAIL = "WALLETS_PARTNER_FAIL";
export const WALLET_PARTNER_GET_REQUEST = "WALLET_PARTNER_GET_REQUEST";
export const WALLET_PARTNER_GET_SUCCESS = "WALLET_PARTNER_GET_SUCCESS";
export const WALLET_PARTNER_GET_FAIL = "WALLET_PARTNER_GET_FAIL";
export const WALLET_PARTNER_CREATE_REQUEST = "WALLET_PARTNER_CREATE_REQUEST";
export const WALLET_PARTNER_CREATE_SUCCESS = "WALLET_PARTNER_CREATE_SUCCESS";
export const WALLET_PARTNER_CREATE_FAIL = "WALLET_PARTNER_CREATE_FAIL";
export const WALLET_PARTNER_UPDATE_REQUEST = "WALLET_PARTNER_UPDATE_REQUEST";
export const WALLET_PARTNER_UPDATE_SUCCESS = "WALLET_PARTNER_UPDATE_SUCCESS";
export const WALLET_PARTNER_UPDATE_FAIL = "WALLET_PARTNER_UPDATE_FAIL";

export const WALLET_USER_EMAIL_REQUEST = "WALLET_USER_EMAIL_REQUEST";
export const WALLET_USER_EMAIL_SUCCESS = "WALLET_USER_EMAIL_SUCCESS";
export const WALLET_USER_EMAIL_FAIL = "WALLET_USER_EMAIL_FAIL";


/*=============================================================
                  Add Wallet Action
===============================================================*/
export const createPartnerWalletAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
    dispatch({
        type: WALLET_PARTNER_CREATE_REQUEST
    });
    setErrorMessage({ global: { key: '', message: '' } });
    boomiAdminApi.post(`/Partner_Wallet/Create`,
        formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: WALLET_PARTNER_CREATE_SUCCESS
                });
                toast('Partner Wallet added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/partnerwallet-management');
            } else {
                const errors = {};
                setErrors(errors)
                setErrorMessage({ global: { key: '', message: successResponse.UI_Display_Message ? successResponse.UI_Display_Message : '' } });
                dispatch({
                    type: WALLETS_PARTNER_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: WALLETS_PARTNER_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

/*=============================================================
                Update Wallet Action
===============================================================*/
export const updatepartnerWalletAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: WALLET_PARTNER_UPDATE_REQUEST
    });
    boomiAdminApi.put(`/Partner_Wallet/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: WALLET_PARTNER_UPDATE_SUCCESS
                });
                toast('Partner Wallet updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/partnerwallet-management');
            } else {

                dispatch({
                    type: WALLET_PARTNER_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: WALLET_PARTNER_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

/*=============================================================
                Get All Wallet Action
===============================================================*/
export const getAllPartnerWalletAction = formData => async dispatch => {
    try {
        dispatch({
            type: WALLETS_PARTNER_REQUEST
        });
        let { data } = await nodeGetApi.post(
            `/Partner_Wallet/Wallet_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=WALLET_ID&SortOrder=ASC
        `,
            formData
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: WALLETS_PARTNER_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: WALLETS_PARTNER_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: WALLETS_PARTNER_FAIL,
            payload: err
        });
    }
};

/*=============================================================
            Get All Emails Action
===============================================================*/
export const getAllPartnerEmails = (formData) => async dispatch => {
    try {
        dispatch({
            type: WALLET_USER_EMAIL_REQUEST
        });
        let { data } = await nodeGetApi.post(
            `/Partners/Physical_Partner_Profile_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Partner_Details_Id&SortOrder=ASC`,
            formData
        );
        if (data) {
            if (data.Success_Response.Is_Data_Exist === '0') {
                dispatch({
                    type: WALLET_USER_EMAIL_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: WALLET_USER_EMAIL_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: WALLET_USER_EMAIL_FAIL,
            payload: err
        });
    }
};

//  Get Partner Wallet Action
export const getSinglePartnerWallet = (formData, callBackPartnerWalletData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(
            `/Partner_Wallet/Wallet_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=WALLET_OWNER&SortOrder=ASC`,
            formData
        );
        if (data) {
            callBackPartnerWalletData(data)
        }
    } catch (err) {
    }
};