
import {
  DELIVERY_REQUEST,
  DELIVERY_SUCCESS,
  DELIVERY_FAIL,
  DELIVERY_CREATE_REQUEST,
  DELIVERY_CREATE_SUCCESS,
  DELIVERY_CREATE_FAIL,
  DELIVERY_AGENT_APPROVAL_REQUEST,
  DELIVERY_AGENT_APPROVAL_SUCCESS,
  DELIVERY_AGENT_APPROVAL_FAIL,
  DELIVERY_AGENT_REJECT_REQUEST,
  DELIVERY_AGENT_REJECT_SUCCESS,
  DELIVERY_AGENT_REJECT_FAIL,
  DELIVERY_UPDATES_REQUEST,
  DELIVERY_UPDATES_SUCCESS,
  DELIVERY_UPDATES_FAIL,
  DELIVERY_AGENT_CURRENT_ADDRESS_REQUEST,
  DELIVERY_AGENT_CURRENT_ADDRESS_SUCCESS,
  DELIVERY_AGENT_CURRENT_ADDRESS_FAIL,
  DELIVERY_AGENT_WORK_ADDRESS_REQUEST,
  DELIVERY_AGENT_WORK_ADDRESS_SUCCESS,
  DELIVERY_AGENT_WORK_ADDRESS_FAIL,
  DELIVERY_AGENT_PARMANENT_ADDRESS_REQUEST,
  DELIVERY_AGENT_PARMANENT_ADDRESS_SUCCESS,
  DELIVERY_AGENT_PARMANENT_ADDRESS_FAIL,
  DELIVERY_AGENT_VEHICAL_DETAILS_REQUEST,
  DELIVERY_AGENT_VEHICAL_DETAILS_SUCCESS,
  DELIVERY_AGENT_VEHICAL_DETAILS_FAIL,
  RESET,
  DELIVERY_PROFILE_VEHICAL_DETAILS_REQUEST,
  DELIVERY_PROFILE_VEHICAL_DETAILS_SUCCESS,
  DELIVERY_PROFILE_VEHICAL_DETAILS_FAIL,

  DELIVERYAGENT_CREATE_REQUEST,
  DELIVERYAGENT_CREATE_SUCCESS,
  DELIVERYAGENT_CREATE_FAIL
} from '../actions/deliveryAgentAction'

const initialState = {
  deliverys: { all: [], error: '', isLoading: false },
  deliveryagentcurrentaddress: { all: [], error: '', isLoading: false },
  deliveryagentworkaddress: { all: [], error: '', isLoading: false },
  deliveryagentparmanentaddress: { all: [], error: '', isLoading: false },
  deliveryagentvehicals: { all: [], error: '', isLoading: false },
  deliveryagentprofile: { all: [], error: '', isLoading: false },
  delivery: { delivery: [], error: '', isLoading: false },
  deliverysUpdate: { delivery: {}, error: '', isLoading: false },
  deliveryCreate: { delivery: {}, error: '', isLoading: false },
  deliveryapproval: { delivery: {}, error: '', isLoading: false },
  deliveryreject: { delivery: {}, error: '', isLoading: false },
  deliveryDelete: { delivery: {}, error: '', isLoading: false },

  deliveryagentCreate: { delivery: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DELIVERY_REQUEST:
      return { ...state, deliverys: { all: state.deliverys.all, error: '', isLoading: true } };
    case DELIVERY_SUCCESS:
      return { ...state, deliverys: { all: action.payload, error: '', isLoading: false } };
    case DELIVERY_FAIL:
      return { ...state, deliverys: { all: [], error: action.payload, isLoading: false } };
    case DELIVERY_AGENT_CURRENT_ADDRESS_REQUEST:
      return { ...state, deliveryagentcurrentaddress: { all: state.deliveryagentcurrentaddress.all, error: '', isLoading: true } };
    case DELIVERY_AGENT_CURRENT_ADDRESS_SUCCESS:
      return { ...state, deliveryagentcurrentaddress: { all: action.payload, error: '', isLoading: false } };
    case DELIVERY_AGENT_CURRENT_ADDRESS_FAIL:
      return { ...state, deliveryagentcurrentaddress: { all: [], error: action.payload, isLoading: false } };
    case DELIVERY_AGENT_WORK_ADDRESS_REQUEST:
      return { ...state, deliveryagentworkaddress: { all: state.deliveryagentworkaddress.all, error: '', isLoading: true } };
    case DELIVERY_AGENT_WORK_ADDRESS_SUCCESS:
      return { ...state, deliveryagentworkaddress: { all: action.payload, error: '', isLoading: false } };
    case DELIVERY_AGENT_WORK_ADDRESS_FAIL:
      return { ...state, deliveryagentworkaddress: { all: [], error: action.payload, isLoading: false } };
    case DELIVERY_AGENT_PARMANENT_ADDRESS_REQUEST:
      return { ...state, deliveryagentparmanentaddress: { all: state.deliveryagentparmanentaddress.all, error: '', isLoading: true } };
    case DELIVERY_AGENT_PARMANENT_ADDRESS_SUCCESS:
      return { ...state, deliveryagentparmanentaddress: { all: action.payload, error: '', isLoading: false } };
    case DELIVERY_AGENT_PARMANENT_ADDRESS_FAIL:
      return { ...state, deliveryagentaddress: { all: [], error: action.payload, isLoading: false } };
    case DELIVERY_AGENT_VEHICAL_DETAILS_REQUEST:
      return { ...state, deliveryagentvehicals: { all: state.deliveryagentvehicals.all, error: '', isLoading: true } };
    case DELIVERY_AGENT_VEHICAL_DETAILS_SUCCESS:
      return { ...state, deliveryagentvehicals: { all: action.payload, error: '', isLoading: false } };
    case DELIVERY_AGENT_VEHICAL_DETAILS_FAIL:
      return { ...state, deliveryagentvehicals: { all: [], error: action.payload, isLoading: false } };

    case DELIVERY_PROFILE_VEHICAL_DETAILS_REQUEST:
      return { ...state, deliveryagentprofile: { all: state.deliveryagentprofile.all, error: '', isLoading: true } };
    case DELIVERY_PROFILE_VEHICAL_DETAILS_SUCCESS:
      return { ...state, deliveryagentprofile: { all: action.payload, error: '', isLoading: false } };
    case DELIVERY_PROFILE_VEHICAL_DETAILS_FAIL:
      return { ...state, deliveryagentprofile: { all: [], error: action.payload, isLoading: false } };

    case DELIVERY_CREATE_REQUEST:
      return { ...state, deliveryCreate: { delivery: {}, error: '', isLoading: true } };
    case DELIVERY_CREATE_SUCCESS:
      return { ...state, deliveryCreate: { delivery: state.deliveryCreate.delivery, error: '', isLoading: false } };
    case DELIVERY_CREATE_FAIL:
      return { ...state, deliveryCreate: { delivery: {}, error: action.payload, isLoading: false } };
    case DELIVERY_AGENT_APPROVAL_REQUEST:
      return { ...state, deliveryapproval: { delivery: {}, error: '', isLoading: true } };
    case DELIVERY_AGENT_APPROVAL_SUCCESS:
      return { ...state, deliveryapproval: { delivery: action.payload, error: '', isLoading: false } };
    case DELIVERY_AGENT_APPROVAL_FAIL:
      return { ...state, deliveryapproval: { delivery: {}, error: action.payload, isLoading: false } };
    case DELIVERY_AGENT_REJECT_REQUEST:
      return { ...state, deliveryreject: { delivery: {}, error: '', isLoading: true } };
    case DELIVERY_AGENT_REJECT_SUCCESS:
      return { ...state, deliveryreject: { delivery: action.payload, error: '', isLoading: false } };
    case DELIVERY_AGENT_REJECT_FAIL:
      return { ...state, deliveryreject: { delivery: {}, error: action.payload, isLoading: false } };
    case DELIVERY_UPDATES_REQUEST:
      return { ...state, deliverysUpdate: { delivery: {}, error: '', isLoading: true } };
    case DELIVERY_UPDATES_SUCCESS:
      return { ...state, deliverysUpdate: { delivery: action.payload, error: '', isLoading: false } };
    case DELIVERY_UPDATES_FAIL:
      return { ...state, deliverysUpdate: { delivery: {}, error: action.payload, isLoading: false } };

    case DELIVERYAGENT_CREATE_REQUEST:
      return { ...state, deliveryagentCreate: { delivery: {}, error: '', isLoading: true } };
    case DELIVERYAGENT_CREATE_SUCCESS:
      return { ...state, deliveryagentCreate: { delivery: state.deliveryagentCreate.delivery, error: '', isLoading: false } };
    case DELIVERYAGENT_CREATE_FAIL:
      return { ...state, deliveryagentCreate: { delivery: {}, error: action.payload, isLoading: false } };

    case RESET:
      return { ...state, deliveryCreate: { delivery: {}, error: '', isLoading: false } };

    default:
      return state;
  }
}