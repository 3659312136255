import {
    ESIMAINCENTERDATEANDCOUNT_REQUEST,
    ESIMAINCENTERDATEANDCOUNT_SUCCESS,
    ESIMAINCENTERDATEANDCOUNT_FAIL,

} from '../actions/esimaincenterActions'

const initialState = {
    esimaincenterdateandcounts: { all: [], error: '', isLoading: false },
};
export default function (state = initialState, action) {
    switch (action.type) {
        case ESIMAINCENTERDATEANDCOUNT_REQUEST:
            return { ...state, esimaincenterdateandcounts: { all: state.esimaincenterdateandcounts.all, error: '', isLoading: true } };
        case ESIMAINCENTERDATEANDCOUNT_SUCCESS:
            return { ...state, esimaincenterdateandcounts: { all: action.payload, error: '', isLoading: false } };
        case ESIMAINCENTERDATEANDCOUNT_FAIL:
            return { ...state, esimaincenterdateandcounts: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}

