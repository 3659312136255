import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/admin-user-management',
    createdAt: '',
    description:
      'Users are the people who interact with a company through digital media and technology. For many products the users can be divided into segments based on their needs.',
    media: '/static/Dashboard/admin_user_management.jpg',
    title: 'User Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/user-profile-management',
    createdAt: '',
    description:
      'User Profile Management is a collection of information associated with a user. It contains critical information that is used to identify an individual, such as their name, age, photograph.',
    media: '/static/Dashboard/user_profile_management.jpg',
    title: 'User Profile Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/missing-cashback',
    createdAt: '',
    description:
      'Missing Cashback is a secure mechanism whereby the payment platform validates that an in-app purchase indeed occurred as reported.',
    media: '/static/Dashboard/Bill_Receipt_Validation.jpg',
    title: 'Missing Cashback',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/ticketing-management',
    createdAt: '',
    description:
      'A ticket management system can be defined as a robust software used to register, organize, prioritize, and resolve support tickets. These tickets can be issues or requests requested by your employees or your customers. ',
    media: '/static/Dashboard/ticketing_management.jpg',
    title: 'User Ticketing Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/wallet-management',
    createdAt: '',
    description:
      'Wallet Management module allows the seller to create wallets for the customer in POS Session. Customer can get their wallet recharge to add wallet credits at any time and ... Maintain the translation history at the backend.',
    media: '/static/Dashboard/wallet_management.jpg',
    title: 'Wallet Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/user-financial-transactions',
    createdAt: '',
    description:
      'A Financial transaction is an agreement, or communication, between a buyer and seller to exchange goods, services, or assets for payment. Any transaction involves a change in the status of the finances of two or more businesses or individuals.',
    media: '/static/Dashboard/user_visit.jpg',
    title: 'User Financial Transactions',
    total: ''
  },
];