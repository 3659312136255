import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
} from '@material-ui/core';
import React, { useState, } from 'react';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik'
import EditorContainer from '../../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { addcorporateNEAction } from 'src/store/actions/corporatenewsandeventsAction';

const neOptions = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddCorporateNE = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSubmited = useSelector(state => state.corporatefaq.corporatefaqCreate.isLoading);
  const errorMessage = useSelector(state => state.corporatefaq.corporatefaqCreate.error);
  const emailId = useSelector(state => state.auth.user.Email_Id);

  const [descError, setDescError] = useState()

  // Richtext editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [rhDiscription, setRhDiscription] = useState('');
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const values = {
    Title: "",
    Description: '',
    Created_By: emailId,
    Status: ""
  }
  const Form_Validation = Yup.object().shape({
    Title: Yup.string().matches(/^[a-zA-Z][\sa-zA-Z]*/, "Title should not start with Spaces and Special Characters.").required('Title is required.'),
    Status: Yup.string().required('Status is required.')
  })

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};

    const plainTextDescription = rhDiscription.replace(/(<([^>]+)>)/ig, '').trim();
    const onlySpacesRegex = /^(|\s|&nbsp;)*$/;

    if (!plainTextDescription) {
      setDescError(!plainTextDescription)
      submitTest = false;
      errors.Description = "Description is required.";
    }
    else if (plainTextDescription.length < rhDiscriptionMin) {
      setDescError(plainTextDescription.length < rhDiscriptionMin)
      submitTest = false;
      errors.Description = `Please provide at least ${rhDiscriptionMin} characters.`;
    }
    else if (onlySpacesRegex.test(plainTextDescription)) {
      setDescError(onlySpacesRegex.test(plainTextDescription))
      submitTest = false;
      errors.Description = "Description cannot consist of only spaces.";
    }

    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      formValues.Description = plainTextDescription
      dispatch(addcorporateNEAction(formValues, navigate));
    }
  }

  return (
    <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
      }) => (
        <Form>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Title && errors.Title)}
                        fullWidth
                        helperText={touched.Title && errors.Title}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Title"
                        name="Title"
                        value={values.Title}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>{
                        descError && touched.Description && errors.Description
                      }
                      </span>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        placeholder="Status"
                        name="Status"
                        onChange={handleChange}
                        label="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        helperText={<ErrorMessage name="Status" />}
                      >
                        {neOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>

            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default AddCorporateNE;