import config from './config';
import { toast } from 'react-toastify';
import { boomiAdminApi, nodeCudApi, nodeGetApi } from './commonAxios';

export const BIDDING_PRODUCT_CREATE_REQUEST = "BIDDING_PRODUCT_CREATE_REQUEST";
export const BIDDING_PRODUCT_CREATE_SUCCESS = "BIDDING_PRODUCT_CREATE_SUCCESS";
export const BIDDING_PRODUCT_CREATE_FAIL = "BIDDING_PRODUCT_CREATE_FAIL";

export const BIDDING_PRODUCT_UPDATE_REQUEST = "BIDDING_PRODUCT_UPDATE_REQUEST";
export const BIDDING_PRODUCT_UPDATE_SUCCESS = "BIDDING_PRODUCT_UPDATE_SUCCESS";
export const BIDDING_PRODUCT_UPDATE_FAIL = "BIDDING_PRODUCT_UPDATE_FAIL";

export const BIDDING_PRODUCT_CATEGORIES_REQUEST = "BIDDING_PRODUCT_CATEGORIES_REQUEST";
export const BIDDING_PRODUCT_CATEGORIES_SUCCESS = "BIDDING_PRODUCT_CATEGORIES_SUCCESS";
export const BIDDING_PRODUCT_CATEGORIES_FAIL = "BIDDING_PRODUCT_CATEGORIES_FAIL";

/*=============================================================
                  Add Bidding Products Action
===============================================================*/
export const createBiddingProductsAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: BIDDING_PRODUCT_CREATE_REQUEST
  });
  setErrorMessage({ Product_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  boomiAdminApi.post(`/Bidding_Products/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BIDDING_PRODUCT_CREATE_SUCCESS
        });
        toast('Bidding Products added sucessfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/bidding-product');
      } else {
        const errors = {};
        if (successResponse.Product_Name) {
          errors.Product_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Product_Name: { key: successResponse.Product_Name ? successResponse.Product_Name : '', message: successResponse.Product_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Product_Name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: BIDDING_PRODUCT_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: BIDDING_PRODUCT_CREATE_FAIL,
        payload: "Please Try Sometime Later.Currently Server is Not Working."
      });
    })
};

/*=============================================================
                  Update Product Action
===============================================================*/
export const updateBiddingProductsAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: BIDDING_PRODUCT_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Bidding_Products/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BIDDING_PRODUCT_UPDATE_SUCCESS
        });
        toast('Bidding Products updated successfully', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/bidding-product');
      } else {
        dispatch({
          type: BIDDING_PRODUCT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: BIDDING_PRODUCT_CREATE_FAIL,
        payload: "Please Try Sometime Later.Currently Server is Not Working"
      });
    })
};

/*=============================================================
                 singleImageUploadCreate Action
===============================================================*/
export const singleImageUploadCreate = (image, callBackfunction, key) => async dispatch => {
  boomiAdminApi.post(`/SA_Upload/Upload?functionality=BiddingProduct&fileextension=png&filetype=Images&filename=BiddingProduct`, image)
    .then(response => {
      let data = response.data;
      if (data.File_URL) {
        callBackfunction(true, data.File_URL, key);
      }
      else {
        callBackfunction(false, data.File_URL, key);
      }
    }).catch(error => {
      callBackfunction(false, "", key);
    })
}

/*=============================================================
               Get All Categories Action
===============================================================*/
export const getAllCategories = () => async dispatch => {
  try {
    let formData = {
      // Records_Filter: "FILTER",
      // Status: "Active",
      search_by_filter: "",
      search: "",
    };
    dispatch({
      type: BIDDING_PRODUCT_CATEGORIES_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Bidding_Products_Category/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Bidding_Products_Category_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BIDDING_PRODUCT_CATEGORIES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BIDDING_PRODUCT_CATEGORIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: BIDDING_PRODUCT_CATEGORIES_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  Product Image Delete Action
===============================================================*/
export const imageDelete = (input, callBackfunction, key) => async dispatch => {
  let payload = {
    "Bidding_Product_Image_Id": input,
    "Record_Filter": "H"
  };
  boomiAdminApi.post(`/Bidding_Product_Image/Delete`, JSON.stringify(payload))
    .then(response => {
      let data = response.data;
      callBackfunction(input, data.Response_Status === "Success" ? true : false, key);
    })
}

/*=============================================================
                 imageDefaultUpdate Action
===============================================================*/
export const imageDefaultUpdate = (biddingproductId, modifiedBy, Bidding_Product_Image_Id, callBackfunction) => async dispatch => {
  boomiAdminApi.put(`/Bidding_Product_Image/Update`,
    {
      "Is_Default": 1,
      "Modified_by": modifiedBy,
      "Bidding_Product_Id": biddingproductId,
      "Bidding_Product_Image_Id": Bidding_Product_Image_Id,
    })
    .then(response => {
      let data = response.data;
      if (data.Response_Status === "Success") {
        callBackfunction(true);
      }
      else {
        callBackfunction(false);
      }
    }).catch(error => {
      callBackfunction(false);
    })
}
/*=============================================================
                 getProductImages Action
===============================================================*/
export const getProductImages = (biddingproductId, callBackfunction, key) => async dispatch => {
  let payload = {
    "Records_Filter": "FILTER",
    "Bidding_Product_Image_Id": "",
    "Bidding_Product_Id": biddingproductId,
    "Created_By": "",
    "Created_Date": "",
  };
  nodeGetApi.post(`/Super_Admin/Products_Multiple_Images?PageNo=1&PageSize=${config.pageSize}&SortBy=CREATED_BY&SortOrder=ASC`, payload)
    .then(response => {
      let data = response.data;
      if (data.Success_Response.Is_Data_Exist === "0" && data.Success_Response.Response_Status === "Success") {
        callBackfunction([])
      }
      else if (data.Success_Response.Is_Data_Exist === "1" && data.Success_Response.Response_Status === "Success") {
        callBackfunction(data.results[0].Bidding_Product_Image_Id, key);
      } else {
        callBackfunction([]);
      }
    })
}

/*=============================================================
                 singleImageUploadUpdate Action
===============================================================*/
export const singleImageUploadUpdate = (image, biddingproductId, modifiedBy, key, callBackfunction) => async dispatch => {
  boomiAdminApi.post(`/SA_Upload/Upload?functionality=Products&fileextension=png&filetype=Images&filename=Products`, image)
    .then(response => {
      let successResponse = response.data;
      let data = response.data;
      if (successResponse.Response_Status === "Success") {
        let imageCreate = {
          "Created_By": modifiedBy,
          "Bidding_Product_Id": biddingproductId
        }
        let Images = [];
        Images.push({
          "Product_Image": data.File_URL,
          "Is_Default": "0",
          "Image_Sort": key
        });
        imageCreate.Image = Images;
        boomiAdminApi.post(`/Bidding_Product_Image/MultiProduct_Image_Upload`, imageCreate)
          .then(response => {
            callBackfunction(true);
          }).catch(error => {
            callBackfunction(false);
          })
      }
    }).catch(error => {
      callBackfunction(false);
    })
}