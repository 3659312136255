import {
    SUB_PACK_LOC_REQUEST,
    SUB_PACK_LOC_SUCCESS,
    SUB_PACK_LOC_FAIL,

    SUB_PACK_LOC_CREATE_REQUEST,
    SUB_PACK_LOC_CREATE_SUCCESS,
    SUB_PACK_LOC_CREATE_FAIL,

    SUB_PACK_LOC_UPDATE_REQUEST,
    SUB_PACK_LOC_UPDATE_SUCCESS,
    SUB_PACK_LOC_UPDATE_FAIL,

    SUB_PACK_LOC_FILTER,
} from '../actions/subspacklocationsAction'

const initialState = {
    subspacklocations: { all: [], error: '', isLoading: false },
    subspacklocationCreate: { subspacklocation: {}, error: '', isLoading: false },
    subspacklocationUpdate: { subspacklocation: {}, error: '', isLoading: false },
    subspacklocationFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SUB_PACK_LOC_REQUEST:
            return { ...state, subspacklocations: { all: state.subspacklocations.all, error: '', isLoading: true } };
        case SUB_PACK_LOC_SUCCESS:
            return { ...state, subspacklocations: { all: action.payload, error: '', isLoading: false } };
        case SUB_PACK_LOC_FAIL:
            return { ...state, subspacklocations: { all: [], error: action.payload, isLoading: false } };

        case SUB_PACK_LOC_CREATE_REQUEST:
            return { ...state, subspacklocationCreate: { subspacklocation: {}, error: '', isLoading: true } };
        case SUB_PACK_LOC_CREATE_SUCCESS:
            return { ...state, subspacklocationCreate: { subspacklocation: state.subspacklocationCreate.subspacklocation, error: '', isLoading: false } };
        case SUB_PACK_LOC_CREATE_FAIL:
            return { ...state, subspacklocationCreate: { subspacklocation: {}, error: action.payload, isLoading: false } };

        case SUB_PACK_LOC_UPDATE_REQUEST:
            return { ...state, subspacklocationUpdate: { subspacklocation: {}, error: '', isLoading: true } };
        case SUB_PACK_LOC_UPDATE_SUCCESS:
            return { ...state, subspacklocationUpdate: { subspacklocation: action.payload, error: '', isLoading: false } };
        case SUB_PACK_LOC_UPDATE_FAIL:
            return { ...state, subspacklocationUpdate: { subspacklocation: {}, error: action.payload, isLoading: false } };

        case SUB_PACK_LOC_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, subspacklocationFilter: { search: state.subspacklocationFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, subspacklocationFilter: { select: state.subspacklocationFilter.select, search: action.value } };
            }
            return res;

        default:
            return state;
    }
}