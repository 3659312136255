import {
  PARTNERS_REQUEST,
  PARTNERS_SUCCESS,
  PARTNERS_FAIL,
  PARTNER_CREATE_REQUEST,
  PARTNER_CREATE_SUCCESS,
  PARTNER_CREATE_FAIL,
  PARTNER_UPDATE_REQUEST,
  PARTNER_UPDATE_SUCCESS,
  PARTNER_UPDATE_FAIL,
  ONLINEPARTNER_CREATE_REQUEST,
  ONLINEPARTNER_CREATE_SUCCESS,
  ONLINEPARTNER_CREATE_FAIL,
  ONLINEPARTNER_UPDATE_REQUEST,
  ONLINEPARTNER_UPDATE_SUCCESS,
  ONLINEPARTNER_UPDATE_FAIL,
  PHYSICALPARTNER_CREATE_REQUEST,
  PHYSICALPARTNER_CREATE_SUCCESS,
  PHYSICALPARTNER_CREATE_FAIL,
  PHYSICALPARTNER_UPDATE_REQUEST,
  PHYSICALPARTNER_UPDATE_SUCCESS,
  PHYSICALPARTNER_UPDATE_FAIL,
  PARTNERS_APPROVE_REQUEST,
  PARTNERS_APPROVE_SUCCESS,
  PARTNERS_APPROVE_FAIL,
} from '../actions/partnerAction'

const initialState = {
  partners: { all: [], error: '', isLoading: false },
  partner: { partner: {}, error: '', isLoading: false },
  onlinepartnerCreate: { onlinepartner: {}, error: '', isLoading: false },
  onlinepartnerUpdate: { onlinepartner: {}, error: '', isLoading: false },
  partnerCreate: { partner: {}, error: '', isLoading: false },
  partnerUpdate: { partner: {}, error: '', isLoading: false },


  physicalpartnerCreate: { physicalpartner: {}, error: '', isLoading: false },
  physicalpartnerUpdate: { physicalpartner: {}, error: '', isLoading: false },
  physicalpartnerbusiness: { physicalpartner: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PARTNERS_REQUEST:
      return { ...state, partners: { all: state.partners.all, error: '', isLoading: true } };
    case PARTNERS_SUCCESS:
      return { ...state, partners: { all: action.payload, error: '', isLoading: false } };
    case PARTNERS_FAIL:
      return { ...state, partners: { all: [], error: action.payload, isLoading: false } };

    case PARTNER_CREATE_REQUEST:
      return { ...state, partnerCreate: { partner: {}, error: '', isLoading: true } };
    case PARTNER_CREATE_SUCCESS:
      return { ...state, partnerCreate: { partner: action.payload, error: '', isLoading: false } };
    case PARTNER_CREATE_FAIL:
      return { ...state, partnerCreate: { partner: {}, error: action.payload, isLoading: false } };

    case PARTNER_UPDATE_REQUEST:
      return { ...state, partnerUpdate: { partner: {}, error: '', isLoading: true } };
    case PARTNER_UPDATE_SUCCESS:
      return { ...state, partnerUpdate: { partner: state.partnerUpdate.partner, error: '', isLoading: false } };
    case PARTNER_UPDATE_FAIL:
      return { ...state, partnerUpdate: { partner: {}, error: action.payload, isLoading: false } };

    case PARTNER_CREATE_REQUEST:
      return { ...state, partnerCreate: { partner: {}, error: '', isLoading: true } };
    case PARTNER_CREATE_SUCCESS:
      return { ...state, partnerCreate: { partner: action.payload, error: '', isLoading: false } };
    case PARTNER_CREATE_FAIL:
      return { ...state, partnerCreate: { partner: {}, error: action.payload, isLoading: false } };
    case PARTNER_UPDATE_REQUEST:
      return { ...state, partnerUpdate: { partner: {}, error: '', isLoading: true } };
    case PARTNER_UPDATE_SUCCESS:
      return { ...state, partnerUpdate: { partner: state.partnerUpdate.partner, error: '', isLoading: false } };
    case PARTNER_UPDATE_FAIL:
      return { ...state, partnerUpdate: { partner: {}, error: action.payload, isLoading: false } };
    case ONLINEPARTNER_CREATE_REQUEST:
      return { ...state, onlinepartnerCreate: { onlinepartner: {}, error: '', isLoading: true } };
    case ONLINEPARTNER_CREATE_SUCCESS:
      return { ...state, onlinepartnerCreate: { onlinepartner: state.onlinepartnerCreate.partners, error: '', isLoading: false } };
    case ONLINEPARTNER_CREATE_FAIL:
      return { ...state, onlinepartnerCreate: { onlinepartner: {}, error: action.payload, isLoading: false } };
    case ONLINEPARTNER_UPDATE_REQUEST:
      return { ...state, onlinepartnerUpdate: { onlinepartner: {}, error: '', isLoading: true } };
    case ONLINEPARTNER_UPDATE_SUCCESS:
      return { ...state, onlinepartnerUpdate: { onlinepartner: state.onlinepartnerUpdate.partners, error: '', isLoading: false } };
    case ONLINEPARTNER_UPDATE_FAIL:
      return { ...state, onlinepartnerUpdate: { onlinepartner: {}, error: action.payload, isLoading: false } };

    case PHYSICALPARTNER_CREATE_REQUEST:
      return { ...state, physicalpartnerCreate: { physicalpartner: {}, error: '', isLoading: true } };
    case PHYSICALPARTNER_CREATE_SUCCESS:
      return { ...state, physicalpartnerCreate: { physicalpartner: state.physicalpartnerCreate.partners, error: '', isLoading: false } };
    case PHYSICALPARTNER_CREATE_FAIL:
      return { ...state, physicalpartnerCreate: { physicalpartner: {}, error: action.payload, isLoading: false } };
    case PHYSICALPARTNER_UPDATE_REQUEST:
      return { ...state, physicalpartnerUpdate: { physicalpartner: {}, error: '', isLoading: true } };
    case PHYSICALPARTNER_UPDATE_SUCCESS:
      return { ...state, physicalpartnerUpdate: { physicalpartner: state.physicalpartnerUpdate.partners, error: '', isLoading: false } };
    case PHYSICALPARTNER_UPDATE_FAIL:
      return { ...state, physicalpartnerUpdate: { physicalpartner: {}, error: action.payload, isLoading: false } };
    case PARTNERS_APPROVE_REQUEST:
      return { ...state, partnersapproval: { all: state.partnersapproval.all, error: '', isLoading: true } };
    case PARTNERS_APPROVE_SUCCESS:
      return { ...state, partnersapproval: { all: action.payload, error: '', isLoading: false } };
    case PARTNERS_APPROVE_FAIL:
      return { ...state, partnersapproval: { all: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}