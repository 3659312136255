import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    makeStyles,
    Typography,
    Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';
import { paginationCorpOrderInfoGet } from 'src/store/actions/node-actions/nodepagination';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const CorporOrderDoorStepListView = () => {
    const classes = useStyles();
    const [corpOrderDoorStep, setCorpOrderDoorStep] = useState([]);
    const dispatch = useDispatch();
    const location = useLocation();
    const { state } = location;

    //permissions
    const RolePermissions = useSelector(state => state.rolepermission.Roles);
    const roleUserType = useSelector(state => state.auth.user);
    const Login_Details = useSelector(state => state.auth.user);

    const [filters, setFilters] = React.useState({ Search: '', Search_By_Filter: 'All', Order_Id: state?._id ? state?._id : state?.state?._id });
    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = useState({ page: 1, limit: 10, sortBy: 'createdAt:desc' })

    const handleLimitChange = event => {
        setPagination({ ...pagination, limit: +event.target.value, page: 1 });
    };

    const handlePageChange = (event, newPage) => {
        setPagination({ ...pagination, limit: pagination.limit, page: newPage + 1 });
    };
    const callBackPagination = async (status, data, pagination) => {
        if (status) {
            setCorpOrderDoorStep(data);
        }
        setPageOld(true);
        if (pagination) {
            pagination.sortBy = 'createdAt:desc'
            setPagination(pagination);
        } else {
            setPagination({ page: 1, limit: 10, sortBy: 'createdAt:desc', });
        }
    }

    useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            corpOrderDoorStepCall();
        }
    }, [pagination])

    const [testFilters, setTestFilters] = useState({});
    useEffect(() => {
        if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
            setTestFilters(filters);
            searchFunction()
        }
    }, [filters]);

    const searchFunction = () => {
        const searchpagination = {
            page: 1, limit: 10, sortBy: 'createdAt:desc', Search_By_Filter: filters.Search_By_Filter, Search: filters.Search, sortBy: "createdAt:desc"
        }
        dispatch(paginationCorpOrderInfoGet(filters, searchpagination, callBackPagination))
    }
    const corpOrderDoorStepCall = () => {
        dispatch(paginationCorpOrderInfoGet(filters, pagination, callBackPagination))
    };

    useEffect(() => {
        dispatch(getRolePermissions(Login_Details));
        dispatch(paginationCorpOrderInfoGet(filters, pagination, callBackPagination))
    }, []);

    // Sorting 
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('_id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPagination({ page: 1, limit: pagination?.limit, sortBy: property + `${order === 'asc' ? ":asc" : ":desc"}` })
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/corporate-order-management"
                    variant="text"
                    size="small"
                    color="inherit"
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        Order info for doorstep delivery
                    </Typography>
                </Box>
                <Toolbar state={state} filters={filters} setfilters={setFilters} />
                <Box mt={3}>
                    <Results
                        allCorpOrderDoorStep={corpOrderDoorStep}
                        allCorpOrderDoorStepNoFilter={corpOrderDoorStep}
                        pagination={pagination}
                        setPagination={setPagination}
                        handleLimitChange={handleLimitChange}
                        handlePageChange={handlePageChange}
                        order={order}
                        orderBy={orderBy}
                        createSortHandler={createSortHandler}
                        RolePermissions={RolePermissions}
                        roleUserType={roleUserType}
                        state={state}
                        filters={filters}
                        callBackPagination={callBackPagination}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default CorporOrderDoorStepListView;