import config from './config';
import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi, boomiAdminApi } from './commonAxios';

export const GIFT_REQUEST = "GIFT_REQUEST";
export const GIFT_SUCCESS = "GIFT_SUCCESS";
export const GIFT_FAIL = "GIFT_FAIL";
export const GIFT_GET_REQUEST = "GIFT_GET_REQUEST";
export const GIFT_GET_SUCCESS = "GIFT_GET_SUCCESS";
export const GIFT_GET_FAIL = "GIFT_GET_FAIL";
export const GIFT_CREATE_REQUEST = "GIFT_CREATE_REQUEST";
export const GIFT_CREATE_SUCCESS = "GIFT_CREATE_SUCCESS";
export const GIFT_CREATE_FAIL = "GIFT_CREATE_FAIL";
export const GIFT_UPDATE_REQUEST = "GIFT_UPDATE_REQUEST";
export const GIFT_UPDATE_SUCCESS = "GIFT_UPDATE_SUCCESS";
export const GIFT_UPDATE_FAIL = "GIFT_UPDATE_FAIL";

export const GIFT_VENDORID_REQUEST = "GIFT_VENDORID_REQUEST";
export const GIFT_VENDORID_SUCCESS = "GIFT_VENDORID_SUCCESS";
export const GIFT_VENDORID_FAIL = "GIFT_VENDORID_FAIL";

export const GIFT_IMAGEID_REQUEST = "GIFT_IMAGEID_REQUEST";
export const GIFT_IMAGEID_SUCCESS = "GIFT_IMAGEID_SUCCESS";
export const GIFT_IMAGEID_FAIL = "GIFT_IMAGEID_FAIL";

/*=============================================================
                  Add gift voucher Action
===============================================================*/
export const createGiftAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: GIFT_CREATE_REQUEST
  });
  nodeCudApi.post(`/Gift_Voucher/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: GIFT_CREATE_SUCCESS
        });
        toast('User gift added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/user-gifts');
      } else {
        dispatch({
          type: GIFT_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: GIFT_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Update gift voucher Action
===============================================================*/
export const updateGiftAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: GIFT_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Gift_Voucher/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: GIFT_UPDATE_SUCCESS
        });
        toast('User gift updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/user-gifts');
      } else {
        dispatch({
          type: GIFT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: GIFT_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Get All Gift Voucher Action
===============================================================*/
export const getAllGiftAction = formData => async dispatch => {
  try {
    dispatch({
      type: GIFT_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Gift_Voucher/Get_Admin?PageNo=0&PageSize=${config.pageSize}&SortBy=VOUCHER_NAME&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: GIFT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: GIFT_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again.", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    dispatch({
      type: GIFT_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                gift Delete Action
 ===============================================================*/
export const deleteGiftAction = formData => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/Gift_Voucher/Delete`, formData);
    if (data) {
      toast('User gift deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      const formData = {
        search: '',
        search_by_filter: '',
        Records_Filter: 'ALL'
      };
      dispatch(getAllGiftAction(formData));
    }
  } catch (err) {
  }
};

//node API
export const getAllGiftvendorids = (Gift_Card_Vendor_Id) => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Vendor_Id: Gift_Card_Vendor_Id,
      search: '',
      search_by_filter: ''
    };
    dispatch({
      type: GIFT_VENDORID_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Gift_Voucher/Vendor_Get_Admin?PageNo=1&PageSize=20&SortBy=VENDOR_NAME&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: GIFT_VENDORID_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: GIFT_VENDORID_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: GIFT_VENDORID_FAIL,
      payload: JSON.stringify(err)
    });
  }
};

//node API
export const getAllUserimageids = (Gift_Image_Id) => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Gift_Image_Id: Gift_Image_Id,
      search: '',
      search_by_filter: ''
    };
    dispatch({
      type: GIFT_IMAGEID_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Gift_Voucher/Image_Get_Admin?PageNo=1&PageSize=20&SortBy=VENDOR_NAME&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: GIFT_IMAGEID_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: GIFT_IMAGEID_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: GIFT_IMAGEID_FAIL,
      payload: err
    });
  }
};

//Get Gift Vochers
export const getgiftvochersAction = (formData, callBackGiftVocherData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Gift_Voucher/Get_Admin`, formData);
    if (data) {
      callBackGiftVocherData(data)
    }
  } catch (err) {
  }
};