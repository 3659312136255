import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  buttonRoot: {
    minWidth: '7px',
    padding: '6px'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));

const headCells = [
  { id: 'User_Payment_Settlement_Id', numeric: false, disablePadding: false, label: 'Settlement ID ' },
  { id: 'Total_Order_Amount', numeric: false, disablePadding: false, label: 'Total Order Amount' },
  { id: 'Esi_Commission_Amount', numeric: false, disablePadding: false, label: 'ESI Commission Amount' },
  { id: 'Final_Order_Amount', numeric: true, disablePadding: false, label: 'Final Order Amount' },
  { id: 'Payment_Status', numeric: false, disablePadding: false, label: 'Payment Status' },
];

const Results = ({
  className,
  allUserpayments,
  deleteUserpaymentHandler,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allUserpaymentsNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedUserpaymentIds, setSelectedUserpaymentIds] = useState([]);

  const editUserpaymentHandler = userpaymentData => e => {
    navigate('/app/user-payment-settlements-edit', { state: userpaymentData });
  };
  const visibleUserpaymentHandler = userpaymentData => e => {
    navigate("/app/user-payment-settlements-details", { state: userpaymentData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions
                  {selectedUserpaymentIds.filter(record => {
                    for (let i = 0; i < allUserpaymentsNoFilter.length; i++) {
                      if (record == allUserpaymentsNoFilter[i].User_Payment_Settlement_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedUserpaymentIds.filter(record => {
                    for (let i = 0; i < allUserpaymentsNoFilter.length; i++) {
                      if (record == allUserpaymentsNoFilter[i].User_Payment_Settlement_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0}
                  >
                    </Button>}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allUserpayments) &&
                (allUserpayments).map(userpayment => (
                  <TableRow
                    hover
                    key={userpayment.User_Payment_Settlement_Id}
                    selected={
                      selectedUserpaymentIds.indexOf(userpayment.User_Payment_Settlement_Id) !== -1
                    }
                  >
                    <TableCell>{userpayment.User_Payment_Settlement_Id}</TableCell>
                    <TableCell>{userpayment.Total_Order_Amount}</TableCell>
                    <TableCell>{userpayment.Esi_Commission_Amount}</TableCell>
                    <TableCell>{userpayment.Final_Order_Amount}</TableCell>
                    <TableCell>{userpayment.Payment_Status}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.User_Payments_Settlements_Write?.Value === "User_Payments_Settlements_Write" && RolePermissions?.User_Payments_Settlements_Write?.Is_Write === 1) ?
                      <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                          to="/app/user-payment-settlements-details"
                          onClick={visibleUserpaymentHandler(userpayment)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button
                          onClick={editUserpaymentHandler(userpayment)}
                          to="/app/user-payment-settlements-edit"
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                    :null}
                    {RolePermissions?.User_Payments_Settlements_Read?.Value === "User_Payments_Settlements_Read" && RolePermissions?.User_Payments_Settlements_Read?.Is_Read === 1 &&
                      <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                          to="/app/user-payment-settlements-details"
                          onClick={visibleUserpaymentHandler(userpayment)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allUserpayments) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allUserpayments: PropTypes.array.isRequired
};

export default Results;