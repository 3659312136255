
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createProductMasterAction,
  getAllBrands,
  getAllCategories,
  getAllSubCategories,
} from '../../../../store/actions/productmasterAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EditorContainer from '../../../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import PhoneInput from 'react-phone-input-2';
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddProductMaster = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  // componentDidMount
  useEffect(() => {
    dispatch(getAllBrands());
    dispatch(getAllCategories());
    // dispatch(getAllSubCategories());
  }, []);


  const [subcategorydata, setSubcategoryData] = React.useState([]);
  let USERADDRESS = []
  if (subcategorydata) {
      for (let i = 0; i < subcategorydata?.length; i++) {
          USERADDRESS.push(
              {
                  label: subcategorydata[i].Category_Id,
                  value: subcategorydata[i].Category_Id,
              })
      }
  }

  const callBackUserSubData = (data) => {
    setSubcategoryData(data)
  }

  const setSubcategoryType = (value) => {
      if (value !== "") {
          let data = {
              search_by_filter: "",
              search: "",
              Records_Filter: "FILTER",
              Status: "Active",
              Category_Id: value

          }
          dispatch(getAllSubCategories(data, callBackUserSubData))
      }
  }
 

  //mobile filed
  const [mobile, setMobile] = useState();

  const categories = useSelector(state => state.productmaster.productCategories.categories);
  const subcategories = useSelector(state => state.productmaster.productSubCategories.subcategories);
  const isSubmited = useSelector(state => state.productmaster.productmasterCreate.isLoading);
  const errorMessage = useSelector(state => state.productmaster.productmasterCreate.error);
  const brands = useSelector(state => state.productmaster.productBrands.brands);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);


  // Richtext editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [rhDiscription, setRhDiscription] = useState('');
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // Richtext editor (Features)
  const [editorStateFeatures, setEditorStateFeatures] = useState(EditorState.createEmpty());
  const [rhDiscriptionFeaturesMin, setRhDiscriptionFeaturesMin] = useState(20);
  const [rhDiscriptionFeatures, setRhDiscriptionFeatures] = useState('');
  const onEditorStateChangeFeatures = (editorStateFeatures) => {
    setEditorStateFeatures(editorStateFeatures);
    setRhDiscriptionFeatures(draftToHtml(convertToRaw(editorStateFeatures.getCurrentContent())));
  };
  // Richtext editor (Specifications)
  const [editorStateSpecifications, setEditorStateSpecifications] = useState(EditorState.createEmpty());
  const [rhDiscriptionSpecificationsMin, setRhDiscriptionSpecificationsMin] = useState(20);
  const [rhDiscriptionSpecifications, setRhDiscriptionSpecifications] = useState('');
  const onEditorStateChangeSpecifications = (editorStateSpecifications) => {
    setEditorStateSpecifications(editorStateSpecifications);
    setRhDiscriptionSpecifications(draftToHtml(convertToRaw(editorStateSpecifications.getCurrentContent())));
  };

  let Product
  const Change = (value) => {
    Product = value
  }

  return (
    <Formik
      initialValues={{
        Sku: '',
        Category: '',
        Sub_Category: '',
        Brand: '',
        Product_Name: '',
        Product_Type: '',
        // Pattern_Name: '',
        // Sales_Start_Date: '',
        Upc_Code: '',
        // Height: '',
        // Weight: '',
        // Width: '',
        // Length: '',
        Manufacturer: '',
        Manufacturer_Contact: '',
        // Manufacturer_Cost: '',
        // Max_Mrp: '',
        // Min_Mrp: '',
        Mrp: '',
        Status: '',
        Short_Description: '',
        Long_Description: '',
        Features: '',
        Specifications: '',
        Warranty_Period: '',
        Is_Product_Active: false,
        Created_By: loginEmail,
      }}
      validationSchema={
        Yup.object().shape({
          Category: Yup.string().required('Category Name is required.'),
          Sub_Category: Yup.string().required('SubCategory Name is required.'),
          Brand: Yup.string().required('Brand Name is required.'),
          Product_Name: Yup.string().required('Product Master Name is required.'),
          Manufacturer_Contact: Yup.string().required('Manufacturer Contact is required.'),
          Mrp: Yup.number().min(0, "MRP should not be less than 0.").required('MRP is required.'),
          Status: Yup.string().required('Status is required.'),
          Short_Description: Yup.string().required('Short Description is required.'),
          Warranty_Period: Yup.string().required('Warranty Period is required.'),
          // Sku: Yup.string().required('SKU ID is required.'),
          // Product_Type: Yup.string().required('Product Type is required.'),
          // Pattern_Name: Yup.string().required('Pattern Name is required.'),
          // Sales_Start_Date: Yup.date().min(Date(), "Date must not be past date.").required('Sales Start Date is required.'),
          // Upc_Code: Yup.string().required('UPC Code is required.'),
          // Height: Yup.string().required('Height is required in cm.'),
          // Weight: Yup.string().required('Weight is required in g.'),
          // Width: Yup.string().required('Width is required in cm.'),
          // Length: Yup.string().required('Length is required in cm.'),
          // Manufacturer: Yup.string().required('Manufacturer is required.'),
          // Manufacturer_Cost: Yup.string().required('Manufacturer Cost is required.'),
          // Max_Mrp: Yup.string().required('Max MRP is required.'),
          // Min_Mrp: Yup.string().required('Min MRP is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        // setImageError("");
        const errors = {};
        values.Long_Description = rhDiscription;
        values.Features = rhDiscriptionFeatures;
        values.Specifications = rhDiscriptionSpecifications;
        if (!values.Long_Description || values.Long_Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Long_Description = "Description is required.";
        }
        else if (values.Long_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Long_Description = "Please provide at least " + rhDiscriptionMin + " characters";
        }
        if (!values.Features || values.Features.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Features = "Features are required.";
        }
        else if (values.Features.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionFeaturesMin) {
          submitTest = false;
          errors.Features = "Please provide at least " + rhDiscriptionFeaturesMin + " characters";
        }
        if (!values.Specifications || values.Specifications.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Specifications = "Specifications are required.";
        }
        else if (values.Specifications.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionSpecificationsMin) {
          submitTest = false;
          errors.Specifications = "Please provide at least " + rhDiscriptionSpecificationsMin + " characters";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          if (values.Is_Product_Active) {
            formValues.Is_Product_Active = 1;
          } else {
            formValues.Is_Product_Active = 0;
          }
          dispatch(createProductMasterAction(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    {/* sku */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        // error={Boolean(touched.Sku && errors.Sku)}
                        fullWidth
                        // helperText={touched.Sku && errors.Sku}
                        onBlur={handleBlur}
                        label="SKU ID"
                        name="Sku"
                        onChange={handleChange}
                        value={values.Sku}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Cetegory */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Category && errors.Category)}
                        fullWidth
                        helperText={touched.Category && errors.Category}
                        onBlur={handleBlur}
                        onChange={(e) => { handleChange(e); setSubcategoryType(e.target.value); }}
                        label="Category Name"
                        name="Category"
                        value={values.Category}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {categories?.sort((a, b) => a.Category_Name?.localeCompare(b.Category_Name))?.map(option => (
                          <option key={option.Category_Id} value={option.Category_Id}>
                            {option.Category_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Subcategory */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Sub_Category && errors.Sub_Category)}
                        fullWidth
                        helperText={touched.Sub_Category && errors.Sub_Category}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="SubCategory Name"
                        name="Sub_Category"
                        value={values.Sub_Category}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {subcategorydata.map(option => (
                          <option value={option.Sub_Category_Id} key={option.Sub_Category_Id}>{option.Sub_Category_Name}</option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Brand */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Brand && errors.Brand)}
                        fullWidth
                        helperText={touched.Brand && errors.Brand}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Brand Name"
                        name="Brand"
                        value={values.Brand}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {brands.sort((a, b) => a.Brand_Name.localeCompare(b.Brand_Name)).map(option => (
                          <option key={option.Brand_Id} value={option.Brand_Id}>
                            {option.Brand_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Product Name */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Product_Name && errors.Product_Name)}
                        fullWidth
                        helperText={touched.Product_Name && errors.Product_Name}
                        onBlur={handleBlur}
                        label="Product Master Name"
                        name="Product_Name"
                        onChange={handleChange}
                        value={values.Product_Name}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Product Type */}
                    {/* <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Product_Type && errors.Product_Type)}
                        fullWidth
                        helperText={touched.Product_Type && errors.Product_Type}
                        onBlur={handleBlur}
                        label="Product Type"
                        name="Product_Type"
                        onChange={handleChange}
                        value={values.Product_Type}
                        variant="outlined"
                      />
                    </Grid> */}
                    {/* Pattren Name */}
                    {/* <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Pattern_Name && errors.Pattern_Name)}
                        fullWidth
                        helperText={touched.Pattern_Name && errors.Pattern_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Pattern Name"
                        name="Pattern_Name"
                        value={values.Pattern_Name}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid> */}
                    {/* Upc_Code */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        // error={Boolean(touched.Upc_Code && errors.Upc_Code)}
                        fullWidth
                        // helperText={touched.Upc_Code && errors.Upc_Code}
                        onBlur={handleBlur}
                        label="UPC Code"
                        name="Upc_Code"
                        onChange={handleChange}
                        value={values.Upc_Code}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Height */}
                    {/* <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Height && errors.Height)}
                        fullWidth
                        helperText={touched.Height && errors.Height}
                        onBlur={handleBlur}
                        label="Height"
                        name="Height"
                        placeholder="cm"
                        onChange={handleChange}
                        value={values.Height}
                        variant="outlined"
                      />
                    </Grid> */}
                    {/* Weight */}
                    {/* <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Weight && errors.Weight)}
                        fullWidth
                        helperText={touched.Weight && errors.Weight}
                        onBlur={handleBlur}
                        label="Weight"
                        name="Weight"
                        placeholder="g"
                        onChange={handleChange}
                        value={values.Weight}
                        variant="outlined"
                      />
                    </Grid> */}
                    {/* Width */}
                    {/* <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Width && errors.Width)}
                        fullWidth
                        helperText={touched.Width && errors.Width}
                        onBlur={handleBlur}
                        label="Width"
                        name="Width"
                        placeholder="cm"
                        onChange={handleChange}
                        value={values.Width}
                        variant="outlined"
                      />
                    </Grid> */}
                    {/* Length */}
                    {/* <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Length && errors.Length)}
                        fullWidth
                        helperText={touched.Length && errors.Length}
                        onBlur={handleBlur}
                        label="Length"
                        placeholder="cm"
                        name="Length"
                        // type="number"
                        onChange={handleChange}
                        value={values.Length}
                        variant="outlined"
                      />
                    </Grid> */}
                    {/* Manufacturer */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        // error={Boolean(touched.Manufacturer && errors.Manufacturer)}
                        fullWidth
                        // helperText={touched.Manufacturer && errors.Manufacturer}
                        onBlur={handleBlur}
                        label="Manufacturer"
                        name="Manufacturer"
                        onChange={handleChange}
                        value={values.Manufacturer}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Manufacturer Contact */}
                    <Grid item md={6} xs={12}>
                      <PhoneInput
                        containerClass={touched.Manufacturer_Contact && errors.Manufacturer_Contact ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                        inputClass={"form-control MuiOutlinedInput-input"}
                        error={true}
                        country={"in"}
                        fullWidth
                        inputStyle={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px"
                        }}
                        inputProps={{
                          name: "Manufacturer_Contact",
                        }}
                        specialLabel="Manufacturer Contact"
                        name="Manufacturer_Contact"
                        onBlur={handleBlur}
                        placeholder="+91 XXXXX XXXXX"
                        onChange={(value, country, e, formattedValue) => {
                          setFieldValue("Manufacturer_Contact", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                          setMobile(formattedValue)
                        }}
                        value={mobile}
                      />
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Manufacturer_Contact && errors.Manufacturer_Contact}</p>
                    </Grid>
                    {/* Manufacturer_Cost */}
                    {/* <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Manufacturer_Cost && errors.Manufacturer_Cost)}
                        fullWidth
                        helperText={touched.Manufacturer_Cost && errors.Manufacturer_Cost}
                        onBlur={handleBlur}
                        label="Manufacturer Cost"
                        name="Manufacturer_Cost"
                        type="number"
                        onChange={handleChange}
                        value={values.Manufacturer_Cost}
                        variant="outlined"
                      />
                    </Grid> */}
                    {/*  Mrp */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Mrp && errors.Mrp)}
                        fullWidth
                        helperText={touched.Mrp && errors.Mrp}
                        onBlur={handleBlur}
                        label="MRP"
                        name="Mrp"
                        type="number"
                        onChange={handleChange}
                        value={values.Mrp}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Max MRP */}
                    {/* <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Max_Mrp && errors.Max_Mrp)}
                        fullWidth
                        helperText={touched.Max_Mrp && errors.Max_Mrp}
                        onBlur={handleBlur}
                        label="Max MRP"
                        name="Max_Mrp"
                        type="number"
                        onChange={handleChange}
                        value={values.Max_Mrp}
                        variant="outlined"
                      />
                    </Grid> */}
                    {/* Min Mrp */}
                    {/* <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Min_Mrp && errors.Min_Mrp)}
                        fullWidth
                        helperText={touched.Min_Mrp && errors.Min_Mrp}
                        onBlur={handleBlur}
                        label="Min MRP"
                        name="Min_Mrp"
                        type="number"
                        onChange={handleChange}
                        value={values.Min_Mrp}
                        variant="outlined"
                      />
                    </Grid> */}
                    {/* Sales Start Date */}
                    {/* <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Sales_Start_Date && errors.Sales_Start_Date)}
                        fullWidth
                        helperText={touched.Sales_Start_Date && errors.Sales_Start_Date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // type="date"
                        type="datetime-local"
                        label="Sales Start Date"
                        name="Sales_Start_Date"
                        value={values.Sales_Start_Date}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                      </TextField>
                    </Grid> */}
                    {/*  Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Warranty_Period && errors.Warranty_Period)}
                        fullWidth
                        multiline
                        helperText={touched.Warranty_Period && errors.Warranty_Period}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Warranty Period"
                        name="Warranty_Period"
                        value={values.Warranty_Period}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Short_Description && errors.Short_Description)}
                        fullWidth
                        helperText={touched.Short_Description && errors.Short_Description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Short Description"
                        name="Short_Description"
                        value={values.Short_Description}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                    {/* Description  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Long_Description && errors.Long_Description}
                      </span>

                    </Grid>
                    {/* Features  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Features
                        <EditorContainer editorState={editorStateFeatures} onEditorStateChange={onEditorStateChangeFeatures} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscriptionFeatures || rhDiscriptionFeatures.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionFeaturesMin) && touched.Features && errors.Features}
                      </span>

                    </Grid>
                    {/* Specifications  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Specifications
                        <EditorContainer editorState={editorStateSpecifications} onEditorStateChange={onEditorStateChangeSpecifications} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscriptionSpecifications || rhDiscriptionSpecifications.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionSpecificationsMin) && touched.Specifications && errors.Specifications}
                      </span>
                    </Grid>
                    {/* Is_Product_Active */}
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Product_Active}
                            onChange={handleChange}
                            name="Is_Product_Active"
                            color="primary"
                          />
                        }
                        label="Is Product Master Active"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>
            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddProductMaster.propTypes = {
  className: PropTypes.string
};

export default AddProductMaster;