import { nodeGetApi } from './commonAxios';

export const STORE_DATEANDCOUNT_REQUEST = 'STORE_DATEANDCOUNT_REQUEST';
export const STORE_DATEANDCOUNT_SUCCESS = "STORE_DATEANDCOUNT_SUCCESS";
export const STORE_DATEANDCOUNT_FAIL = "STORE_DATEANDCOUNT_FAIL";

//Get Store Date and Time
export const getStoreDateandCount = () => async dispatch => {
    try {
        dispatch({
            type: STORE_DATEANDCOUNT_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Total_Count_And_Date/Store_Type`);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: STORE_DATEANDCOUNT_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: STORE_DATEANDCOUNT_SUCCESS,
                    payload: data.results[0]
                });
            }
        }
    } catch (err) {
        dispatch({
            type: STORE_DATEANDCOUNT_FAIL,
            payload: err
        });
    }
};