import {

    BIDDING_VEHICLE_CREATE_REQUEST,
    BIDDING_VEHICLE_CREATE_SUCCESS,
    BIDDING_VEHICLE_CREATE_FAIL,

    BIDDING_VEHICLE_UPDATE_REQUEST,
    BIDDING_VEHICLE_UPDATE_SUCCESS,
    BIDDING_VEHICLE_UPDATE_FAIL,

} from '../actions/biddingridevehiclesAction'

const initialState = {
    biddingvehicleCreate: { biddingvehicle: {}, error: '', isLoading: false },
    biddingvehicleUpdate: { biddingvehicle: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case BIDDING_VEHICLE_CREATE_REQUEST:
            return { ...state, biddingvehicleCreate: { biddingvehicle: {}, error: '', isLoading: true } };
        case BIDDING_VEHICLE_CREATE_SUCCESS:
            return { ...state, biddingvehicleCreate: { biddingvehicle: state.biddingvehicleCreate.biddingvehicle, error: '', isLoading: false } };
        case BIDDING_VEHICLE_CREATE_FAIL:
            return { ...state, biddingvehicleCreate: { biddingvehicle: {}, error: action.payload, isLoading: false } };

        case BIDDING_VEHICLE_UPDATE_REQUEST:
            return { ...state, biddingvehicleUpdate: { biddingvehicle: {}, error: '', isLoading: true } };
        case BIDDING_VEHICLE_UPDATE_SUCCESS:
            return { ...state, biddingvehicleUpdate: { biddingvehicle: state.biddingvehicleUpdate.biddingvehicle, error: '', isLoading: false } };
        case BIDDING_VEHICLE_UPDATE_FAIL:
            return { ...state, biddingvehicleUpdate: { biddingvehicle: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}