import { toast } from 'react-toastify';
import config from './config';
import { boomipaginationgetMeepaisaZoneThemeAction } from './boomi-actions/boomipagination';
import {boomiAdminApi } from './commonAxios';
export const MEEPAISA_ZONE_THEME_CREATE_REQUEST = "MEEPAISA_ZONE_THEME_CREATE_REQUEST";
export const MEEPAISA_ZONE_THEME_CREATE_SUCCESS = "MEEPAISA_ZONE_THEME_CREATE_SUCCESS";
export const MEEPAISA_ZONE_THEME_CREATE_FAIL = "MEEPAISA_ZONE_THEME_CREATE_FAIL";
export const MEEPAISA_ZONE_THEME_UPDATE_REQUEST = "MEEPAISA_ZONE_THEME_UPDATE_REQUEST";
export const MEEPAISA_ZONE_THEME_UPDATE_SUCCESS = "MEEPAISA_ZONE_THEME_UPDATE_SUCCESS";
export const MEEPAISA_ZONE_THEME_UPDATE_FAIL = "MEEPAISA_ZONE_THEME_UPDATE_FAIL";
export const MEEPAISA_ZONE_REQUEST = "MEEPAISA_ZONE_REQUEST";
export const MEEPAISA_ZONE_SUCCESS = "MEEPAISA_ZONE_SUCCESS";
export const MEEPAISA_ZONE_FAIL = "MEEPAISA_ZONE_FAIL";
export const MEEPAISA_THEME_REQUEST = "MEEPAISA_THEME_REQUEST";
export const MEEPAISA_THEME_SUCCESS = "MEEPAISA_THEME_SUCCESS";
export const MEEPAISA_THEME_FAIL = "MEEPAISA_THEME_FAIL";

/*=============================================================
                  Add Meepaisa Zone Theme Action
===============================================================*/

export const addMeepaisazonethemeAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: MEEPAISA_ZONE_THEME_CREATE_REQUEST
  });
  setErrorMessage({ Zone_Id: { key: '', message: '' }, global: { key: '', message: '' } });
  boomiAdminApi.post(`/Meepaisa_Zone_Theme/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: MEEPAISA_ZONE_THEME_CREATE_SUCCESS
        });
        toast('Meepaisa Zone Theme added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/meepaisa-zone-theme');
      } else {
        const errors = {};
        if (successResponse) {
          errors.Zone_Id = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Zone_Id: { key: successResponse.Zone_Id ? successResponse.Zone_Id : '', message: successResponse.Zone_Id ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Zone_Id) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: MEEPAISA_ZONE_THEME_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Zone_Id: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: MEEPAISA_ZONE_THEME_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};

/*=============================================================
                  Delete Zone Theme Action
===============================================================*/
export const deleteZoneTheme = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/Meepaisa_Zone_Theme/Delete`,
      formData);
    if (data) {
      toast('Meepaisa Zone Theme deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(boomipaginationgetMeepaisaZoneThemeAction(filters, pagination, callBackPagination));
    }
  } catch (err) { }
};
/*=============================================================
                Update Meepaisa Zone Action
===============================================================*/

export const updateMeepaisazonetheme = (formData, navigate) => async dispatch => {
  dispatch({
    type: MEEPAISA_ZONE_THEME_UPDATE_REQUEST
  });

  boomiAdminApi.post(`/Meepaisa_Zone_Theme/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: MEEPAISA_ZONE_THEME_UPDATE_SUCCESS
        });
        toast('Meepaisa Zone Theme updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/meepaisa-zone-theme');
      } else {
        dispatch({
          type: MEEPAISA_ZONE_THEME_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: MEEPAISA_ZONE_THEME_UPDATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};

/*=============================================================
               Get All Meepaisa Zone Action
===============================================================*/
export const getAllMeepaisaZones = (formData) => async dispatch => {
  try {
    let formData = {
      Records_Filter: "",
      Zone_Id: "",
      search: "",
      search_by_filter: ""
    };
    dispatch({
      type: MEEPAISA_ZONE_REQUEST
    });
    let { data } = await boomiAdminApi.post(`${config.adminurl}/Meepaisa_Zone/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=ZONE_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: MEEPAISA_ZONE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: MEEPAISA_ZONE_SUCCESS,
          payload: data.results
         
        });
      }
    }
  } catch (err) {
    dispatch({
      type: MEEPAISA_ZONE_FAIL,
      payload: err
    });
  }
};

/*=============================================================
               Get All Meepaisa Zone Action
===============================================================*/
export const getAllMeepaisaTheme = (formData) => async dispatch => {
  try {
    let formData = {
      Records_Filter: " ",
      Zone_Theme_Id:"",
      search: "",
      search_by_filter: ""
    };
    dispatch({
      type: MEEPAISA_THEME_REQUEST
    });
    let { data } = await boomiAdminApi.post(`/Meepaisa_Theme/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=MEEPAISA_THEME_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: MEEPAISA_THEME_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: MEEPAISA_THEME_SUCCESS,
          payload: data.results
         
        });
      }
    }
  } catch (err) {
    dispatch({
      type: MEEPAISA_THEME_FAIL,
      payload: err
    });
  }
};



