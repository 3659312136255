import {
    B2B_USER_PROD_BID_CREATE_REQUEST,
    B2B_USER_PROD_BID_CREATE_SUCCESS,
    B2B_USER_PROD_BID_CREATE_FAIL,

    B2B_USER_PROD_BID_UPDATE_REQUEST,
    B2B_USER_PROD_BID_UPDATE_SUCCESS,
    B2B_USER_PROD_BID_UPDATE_FAIL,

    B2B_PARTNER_DETAILS_GET_REQUEST,
    B2B_PARTNER_DETAILS_GET_SUCCESS,
    B2B_PARTNER_DETAILS_GET_FAIL,

    B2B_BIDDING_PRODUCTS_GET_REQUEST,
    B2B_BIDDING_PRODUCTS_GET_SUCCESS,
    B2B_BIDDING_PRODUCTS_GET_FAIL,

    B2B_BID_QUOTES_GET_REQUEST,
    B2B_BID_QUOTES_GET_SUCCESS,
    B2B_BID_QUOTES_GET_FAIL,

    B2B_BID_PARTNER_PAYMENT_REQUEST,
    B2B_BID_PARTNER_PAYMENT_SUCCESS,
    B2B_BID_PARTNER_PAYMENT_FAIL
} from '../actions/b2buserproductsbiddingAction'

const initialState = {
    b2buserprodbidCreate: { b2buserprodbid: {}, error: '', isLoading: false },
    b2buserprodbidUpdate: { b2buserprodbid: {}, error: '', isLoading: false },
    b2bbidpartnerdetails: { all: [], error: '', isLoading: false },
    b2bbidproducts: { all: [], error: '', isLoading: false },
    b2bbidquotes: { all: [], error: '', isLoading: false },
    b2bbidpartnerpayment: { b2bbidpartpay: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case B2B_USER_PROD_BID_CREATE_REQUEST:
            return { ...state, b2buserprodbidCreate: { b2buserprodbid: {}, error: '', isLoading: true } };
        case B2B_USER_PROD_BID_CREATE_SUCCESS:
            return { ...state, b2buserprodbidCreate: { b2buserprodbid: state.b2buserprodbidCreate.b2buserprodbid, error: '', isLoading: false } };
        case B2B_USER_PROD_BID_CREATE_FAIL:
            return { ...state, b2buserprodbidCreate: { b2buserprodbid: {}, error: action.payload, isLoading: false } };

        case B2B_USER_PROD_BID_UPDATE_REQUEST:
            return { ...state, b2buserprodbidUpdate: { b2buserprodbid: {}, error: '', isLoading: true } };
        case B2B_USER_PROD_BID_UPDATE_SUCCESS:
            return { ...state, b2buserprodbidUpdate: { b2buserprodbid: state.b2buserprodbidUpdate.b2buserprodbid, error: '', isLoading: false } };
        case B2B_USER_PROD_BID_UPDATE_FAIL:
            return { ...state, b2buserprodbidUpdate: { b2buserprodbid: {}, error: action.payload, isLoading: false } };

        case B2B_PARTNER_DETAILS_GET_REQUEST:
            return { ...state, b2bbidpartnerdetails: { all: state.b2bbidpartnerdetails.all, error: '', isLoading: true } };
        case B2B_PARTNER_DETAILS_GET_SUCCESS:
            return { ...state, b2bbidpartnerdetails: { all: action.payload, error: '', isLoading: false } };
        case B2B_PARTNER_DETAILS_GET_FAIL:
            return { ...state, b2bbidpartnerdetails: { all: [], error: action.payload, isLoading: false } };

        case B2B_BIDDING_PRODUCTS_GET_REQUEST:
            return { ...state, b2bbidproducts: { all: state.b2bbidproducts.all, error: '', isLoading: true } };
        case B2B_BIDDING_PRODUCTS_GET_SUCCESS:
            return { ...state, b2bbidproducts: { all: action.payload, error: '', isLoading: false } };
        case B2B_BIDDING_PRODUCTS_GET_FAIL:
            return { ...state, b2bbidproducts: { all: [], error: action.payload, isLoading: false } };

        case B2B_BID_QUOTES_GET_REQUEST:
            return { ...state, b2bbidquotes: { all: state.b2bbidquotes.all, error: '', isLoading: true } };
        case B2B_BID_QUOTES_GET_SUCCESS:
            return { ...state, b2bbidquotes: { all: action.payload, error: '', isLoading: false } };
        case B2B_BID_QUOTES_GET_FAIL:
            return { ...state, b2bbidquotes: { all: [], error: action.payload, isLoading: false } };

        case B2B_BID_PARTNER_PAYMENT_REQUEST:
            return { ...state, b2bbidpartnerpayment: { b2bbidpartpay: {}, error: '', isLoading: true } };
        case B2B_BID_PARTNER_PAYMENT_SUCCESS:
            return { ...state, b2bbidpartnerpayment: { b2bbidpartpay: state.b2bbidpartnerpayment.b2bbidpartpay, error: '', isLoading: false } };
        case B2B_BID_PARTNER_PAYMENT_FAIL:
            return { ...state, b2bbidpartnerpayment: { b2bbidpartpay: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}