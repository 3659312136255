
import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { nodeServiceApi, urlGenarator } from 'src/store/actions/commonAxios';

const ESIStates = (props) => {
    const { countrycode, Country_Id, ...rest } = props;
    const [state, setState] = useState([]);

    useEffect(() => {
        let statesData = {};
        if (Country_Id) {
            statesData['Country_Id'] = Country_Id;
        }
        getStates(statesData);
    }, [Country_Id]);

    const getStates = (statesData) => {
        nodeServiceApi.post(urlGenarator(`/admin/csc_states/Get`, { PageNo: 1, PageSize: 1000 }), statesData)
            .then(response => {
                const sortedStates = response?.data?.results.sort((a, b) =>
                    a.Name.localeCompare(b.Name)
                );
                setState(sortedStates);
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <TextField
            {...props}
            select // Added this to indicate that TextField is being used as a select dropdown
        >
            <option key="" value="">--Please Select--</option>
            {state?.map(option => (
                <option key={option.Id} value={option.Id}>
                    {option.Name}
                </option>
            ))}
        </TextField>
    );
};

export default ESIStates;
