import axios from 'axios';
import config from './config';
import { toast } from 'react-toastify';

export const SUBSCRIPTION_USER_REQUEST = "SUBSCRIPTION_USER_REQUEST";
export const SUBSCRIPTION_USER_SUCCESS = "SUBSCRIPTION_USER_SUCCESS";
export const SUBSCRIPTION_USER_FAIL = "SUBSCRIPTION_USER_FAIL";
export const SUBSCRIPTION_USER_GET_REQUEST = "SUBSCRIPTION_USER_GET_REQUEST";
export const SUBSCRIPTION_USER_GET_SUCCESS = "SUBSCRIPTION_USER_GET_SUCCESS";
export const SUBSCRIPTION_USER_GET_FAIL = "SUBSCRIPTION_USER_GET_FAIL";

export const SUBSCRIPTION_USER_CREATE_REQUEST = "SUBSCRIPTION_USER_CREATE_REQUEST";
export const SUBSCRIPTION_USER_CREATE_SUCCESS = "SUBSCRIPTION_USER_CREATE_SUCCESS";
export const SUBSCRIPTION_USER_CREATE_FAIL = "SUBSCRIPTION_USER_CREATE_FAIL";

export const SUBSCRIPTION_USER_UPDATE_REQUEST = "SUBSCRIPTION_USER_UPDATE_REQUEST";
export const SUBSCRIPTION_USER_UPDATE_SUCCESS = "SUBSCRIPTION_USER_UPDATE_SUCCESS";
export const SUBSCRIPTION_USER_UPDATE_FAIL = "SUBSCRIPTION_USER_UPDATE_FAIL";


export const SUBSCRIPTION_USER_FILTER = "SUBSCRIPTION_USER_FILTER";
const headers = config.headersCommon;
const nodeheaders = config.nodeheadersCommon;
/*=============================================================
                  Update Subscription User Action
===============================================================*/

export const UpdateSubscriptionUserAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: SUBSCRIPTION_USER_UPDATE_REQUEST
  });
  axios.put(`${config.url}/Super_Admin_Subscription_User/Super_Admin_Subscription_User_Update`, formData, {
    headers: headers
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SUBSCRIPTION_USER_UPDATE_SUCCESS
        });
        toast('User Subscription updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/subscription-user-management');
      } else {

        dispatch({
          type: SUBSCRIPTION_USER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: SUBSCRIPTION_USER_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};


/*=============================================================
                  Get All Subscription User Action
===============================================================*/

export const getAllSubscriptionUserAction = formData => async dispatch => {
  try {
    dispatch({
      type: SUBSCRIPTION_USER_REQUEST
    });
    let { data } = await axios.post(
      `${config.url}/Super_Admin_Subscription_User/Super_Admin_Subscription_User_Get?PageNo=0&PageSize=${config.pageSize}&SortBy=USER_SERVICE_REQUEST_ID&SortOrder=ASC`,
      formData,
      {
        headers: headers
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SUBSCRIPTION_USER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({

          type: SUBSCRIPTION_USER_SUCCESS,
          payload: data.SA_Subscription_User_Response

        });
      }
    }
  } catch (err) {
    dispatch({
      type: SUBSCRIPTION_USER_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  Add Subscription User Action
===============================================================*/

export const AddSubscriptionUserAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: SUBSCRIPTION_USER_CREATE_REQUEST
  });
  axios.post(`${config.url}/Super_Admin_Subscription_User/Super_Admin_Subscription_User_Create`, formData, {
    headers: headers
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SUBSCRIPTION_USER_CREATE_SUCCESS
        });
        toast('User Subscription added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/subscription-user-management');
      } else {
        dispatch({
          type: SUBSCRIPTION_USER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: SUBSCRIPTION_USER_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

export const getAllUserAddress = (formData, callBacksubtypeData) => async dispatch => {
  try {
    let { data } = await axios.post(
      `${config.nodeurl}/User/Address_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=USER_EMAIL_ID&SortOrder=ASC`,
      formData,
      {
        // headers: headers
        headers: nodeheaders
      }
    );
    if (data) {
      callBacksubtypeData(data.results)
    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};
/*=============================================================
                 Subscription User Filter Action
===============================================================*/

export const SubscriptionUserFilter = (fType, value) => {
  return function (dispatch) {
    dispatch({
      type: SUBSCRIPTION_USER_FILTER,
      fType: fType,
      value: value,
    });
  }
}