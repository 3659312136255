import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles, TextareaAutosize
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { createBidProductsSuccessStoriesAction, getAllPartnerDetails } from 'src/store/actions/biddingproductssuccessstoriesAction';
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';
import Rating from '@material-ui/lab/Rating';


const useStyles = makeStyles(() => ({
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    },
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const AddBiddingProductsSuccessStories = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        dispatch(getAllPartnerDetails())
    }, []);

    const isSubmitted = useSelector(state => state.bidproductsSuccessStories.biddingproductsuccessstoriesCreate.isLoading);
    const errorMessage = useSelector(state => state.bidproductsSuccessStories.biddingproductsuccessstoriesCreate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const partners = useSelector(state => state.bidproductsSuccessStories.PhysicalPartners.partners);

    // Richtext editor
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
    const [rhDiscription, setRhDiscription] = useState('');
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    return (
        <Formik
            initialValues={{
                Meepaisa_Rating_For_Partner: 0,
                Meepaisa_Review_For_Partner: "",
                Partner_Details_Id: "",
                Usecase_Descrition: "",
                Usecase_Short_Description: "",
                Usecase_Video_Url: "",
                Created_By: loginEmail,
            }}
            validationSchema={
                Yup.object().shape({
                    Partner_Details_Id: Yup.string().required('Partner Details ID is required.'),
                    Meepaisa_Review_For_Partner: Yup.string().required('Meepaisa Review for Partner is required.'),
                    Usecase_Short_Description: Yup.string().required('Usecase Short Description is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                values.Usecase_Descrition = rhDiscription;
                if (!values.Usecase_Descrition || values.Usecase_Descrition.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
                    submitTest = false;
                    errors.Usecase_Descrition = "Usecase Description is required.";
                }
                else if (values.Usecase_Descrition.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
                    submitTest = false;
                    errors.Usecase_Descrition = "Please provide at least " + rhDiscriptionMin + " characters.";
                }

                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(createBidProductsSuccessStoriesAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Details_Id && errors.Partner_Details_Id)}
                                                fullWidth
                                                helperText={touched.Partner_Details_Id && errors.Partner_Details_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Details ID"
                                                name="Partner_Details_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Partner_Details_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {partners?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                                                    <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                                                        {option.Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Usecase Video URL"
                                                name="Usecase_Video_Url"
                                                value={values.Usecase_Video_Url}
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            Meepaisa Review for Partner
                                            <TextareaAutosize
                                                value={values.Meepaisa_Review_For_Partner}
                                                onChange={handleChange}
                                                name="Meepaisa_Review_For_Partner"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Meepaisa Review for Partner"
                                            >
                                            </TextareaAutosize>
                                            <span style={{ color: "red" }}>
                                                {((touched.Meepaisa_Review_For_Partner && errors.Meepaisa_Review_For_Partner))}
                                            </span>
                                        </Grid>


                                        <Grid item md={12} xs={12}>
                                            <Box className={classes.description}>
                                                Usecase Description
                                                <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                                            </Box>
                                            <span style={{ color: "red" }}>
                                                {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Usecase_Descrition && errors.Usecase_Descrition}
                                            </span>
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            Usecase Short Description
                                            <TextareaAutosize
                                                value={values.Usecase_Short_Description}
                                                onChange={handleChange}
                                                name="Usecase_Short_Description"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Usecase Short Description"
                                            >
                                            </TextareaAutosize>
                                            <span style={{ color: "red" }}>
                                                {((touched.Usecase_Short_Description && errors.Usecase_Short_Description))}
                                            </span>
                                        </Grid>

                                        {/* Meepaise_Rating_For_Partner  */}
                                        <Grid item md={4} xs={4}>
                                            <Box>Meepaisa Rating</Box>
                                        </Grid>
                                        <Grid item md={2} xs={4}>
                                            <Rating
                                                name="Meepaisa_Rating_For_Partner"
                                                onChange={(event, newValue) => {
                                                    values.Meepaisa_Rating_For_Partner = newValue;
                                                    setFieldValue(newValue);
                                                }}
                                                value={values.Meepaisa_Rating_For_Partner}
                                                size="medium"
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddBiddingProductsSuccessStories.propTypes = {
    className: PropTypes.string
};

export default AddBiddingProductsSuccessStories;