import {

    BID_VEHICLE_BRAND_CREATE_REQUEST,
    BID_VEHICLE_BRAND_CREATE_SUCCESS,
    BID_VEHICLE_BRAND_CREATE_FAIL,

    BID_VEHICLE_BRAND_UPDATE_REQUEST,
    BID_VEHICLE_BRAND_UPDATE_SUCCESS,
    BID_VEHICLE_BRAND_UPDATE_FAIL,

} from '../actions/biddingvehiclebrandsAction'

const initialState = {
    bidvehiclebrandCreate: { bidvehiclebrand: {}, error: '', isLoading: false },
    bidvehiclebrandUpdate: { bidvehiclebrand: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case BID_VEHICLE_BRAND_CREATE_REQUEST:
            return { ...state, bidvehiclebrandCreate: { bidvehiclebrand: {}, error: '', isLoading: true } };
        case BID_VEHICLE_BRAND_CREATE_SUCCESS:
            return { ...state, bidvehiclebrandCreate: { bidvehiclebrand: state.bidvehiclebrandCreate.bidvehiclebrand, error: '', isLoading: false } };
        case BID_VEHICLE_BRAND_CREATE_FAIL:
            return { ...state, bidvehiclebrandCreate: { bidvehiclebrand: {}, error: action.payload, isLoading: false } };

        case BID_VEHICLE_BRAND_UPDATE_REQUEST:
            return { ...state, bidvehiclebrandUpdate: { bidvehiclebrand: {}, error: '', isLoading: true } };
        case BID_VEHICLE_BRAND_UPDATE_SUCCESS:
            return { ...state, bidvehiclebrandUpdate: { bidvehiclebrand: state.bidvehiclebrandUpdate.bidvehiclebrand, error: '', isLoading: false } };
        case BID_VEHICLE_BRAND_UPDATE_FAIL:
            return { ...state, bidvehiclebrandUpdate: { bidvehiclebrand: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}