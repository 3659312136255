import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from 'src/components/dialogs/confirmDialog';
import ConfirmDialogone from 'src/components/dialogs/confirmDialog';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';
import { paginationCorpPendingUsers } from 'src/store/actions/node-actions/nodepagination';
import { CorpUserApprovalAction, CorpUserRejectedAction } from 'src/store/actions/corporateusersAction';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const PendingCorporateUserListView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  //permissions
  const RolePermissions = useSelector(state => state.rolepermission.Roles);
  const roleUserType = useSelector(state => state.auth.user);
  const Login_Details = useSelector(state => state.auth.user);
  const updatedBy = useSelector(state => state.auth.user.Email_Id);

  const [corpuser, setCorpuser] = useState([]);
  const [filters, setFilters] = React.useState({
    Search: '',
    Search_By_Filter: 'All',
    Is_SA_Approved: false,
    Is_SA_Rejected: false
  });

  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = useState({ page: 1, limit: 10, sortBy: "createdAt:desc" })

  const handleLimitChange = event => {
    setPagination({ ...pagination, limit: +event.target.value, page: 1, sortBy: "createdAt:desc" });
  };

  const handlePageChange = (event, newPage) => {
    setPagination({ ...pagination, limit: pagination.limit, page: newPage + 1, sortBy: "createdAt:desc" });
  };

  const callBackPagination = async (status, data, pagination) => {
    if (status) {
      setCorpuser(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({ page: 1, limit: 10, sortBy: "createdAt:desc" });
    }
  }

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      corpUserCall();
    }
  }, [pagination])

  const [testFilters, setTestFilters] = useState({});
  useEffect(() => {
    if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
      setTestFilters(filters);
      searchFunction();
    }
  }, [filters]);
  const searchFunction = () => {
    const searchpagination = {
      page: 1, limit: 10, sortBy: "createdAt:desc"
    }
    dispatch(paginationCorpPendingUsers(filters, searchpagination, callBackPagination))
  }

  const corpUserCall = () => {
    dispatch(paginationCorpPendingUsers(filters, pagination, callBackPagination))
  };
  // confirm dailog box
  const [deliveryrejectappopen, setDeliveryrejectappopen] = useState(false);
  const [deliveryapptitle, setDeliveryrejectapptitle] = useState('');
  const [deliveryappmessage, setDeliveryrejectappmessage] = useState('');
  const [deliveryappIdle, setDeliveryrejectAppIdle] = useState('');

  const [deliveryapproveappopens, setDeliveryapproveappopens] = useState(false);
  const [deliveryapptitles, setDeliveryapptitles] = useState('');
  const [deliveryappmessages, setDeliveryappmessages] = useState('');
  const [corpappIdles, setCorpAppIdles] = useState('');

  // couponapphandleClose contains update popup action
  const deliveryapphandleClose = (value) => {
    setDeliveryrejectappopen(false);
    if (value) {
      const RejectData = {
        corpEnquiryId: deliveryappIdle.id,
        Is_SA_Approved: false,
        Modified_By: updatedBy,
        Rejected_By: updatedBy,
        Is_SA_Rejected: true
      }
      dispatch(CorpUserRejectedAction(RejectData,navigate));
    }
  };
  
  const deliveryapphandleCloses = (value) => {
    setDeliveryapproveappopens(false);

    if (value) {
      const approveData = {
        corpEnquiryId: corpappIdles.id,
        Is_SA_Approved: true,
        Modified_By: updatedBy,
        Approved_By: updatedBy,
        Is_SA_Rejected: false
      }
      dispatch(CorpUserApprovalAction(approveData,navigate));
    }
  };
  //Approve handler
  const CorpUserApprovelHandler = approveData => async e => {
    setDeliveryapproveappopens(true);
    setDeliveryapptitles("Alert!");
    setDeliveryappmessages("Are you sure to Approve?");
    setCorpAppIdles(approveData);
  };
  //Reject handler
  const CorpUserRejectHandlers = RejectData => async e => {
    setDeliveryrejectappopen(true);
    setDeliveryrejectapptitle("Alert!");
    setDeliveryrejectappmessage("Are you sure to Reject?");
    setDeliveryrejectAppIdle(RejectData);
  };

  // Sorting 
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPagination({ page: 1, limit: pagination?.limit, sortBy: order === 'asc' ? "createdAt:asc" : "createdAt:desc" })
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  // componentDidMount
  useEffect(() => {
    dispatch(paginationCorpPendingUsers(filters, pagination, callBackPagination));
    dispatch(getRolePermissions(Login_Details));
  }, []);

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/corporate-management"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Pending Corporate Users
          </Typography>
        </Box>
        <Toolbar filters={filters} setfilters={setFilters} />
        <Box mt={3}>
          <Results
            allcorpUser={corpuser}
            allcorpNoFilter={corpuser}
            CorpUserApprovelHandler={CorpUserApprovelHandler}
            CorpUserRejectHandlers={CorpUserRejectHandlers}
            handleLimitChange={handleLimitChange}
            handlePageChange={handlePageChange}
            pagination={pagination}
            setPagination={setPagination}
            order={order}
            orderBy={orderBy}
            createSortHandler={createSortHandler}
            RolePermissions={RolePermissions}
            roleUserType={roleUserType}
          />
        </Box>
      </Container>
      <ConfirmDialog inputMessage={deliveryappmessage} titleMsg={deliveryapptitle} open={deliveryrejectappopen} onClose={deliveryapphandleClose} />
      <ConfirmDialogone inputMessage={deliveryappmessages} titleMsg={deliveryapptitles} open={deliveryapproveappopens} onClose={deliveryapphandleCloses} />
    </Page>
  );
};

export default PendingCorporateUserListView;