import {
  PARTNERS_REQUEST,
  PARTNERS_SUCCESS,
  PARTNERS_FAIL,

  PARTNER_CREATE_REQUEST,
  PARTNER_CREATE_SUCCESS,
  PARTNER_CREATE_FAIL,

  PARTNER_UPDATE_REQUEST,
  PARTNER_UPDATE_SUCCESS,
  PARTNER_UPDATE_FAIL,

  PHYSICALPARTNER_ADDRESS_REQUEST,
  PHYSICALPARTNER_ADDRESS_SUCCESS,
  PHYSICALPARTNER_ADDRESS_FAIL,

  PARTNERPRODUCT_APPROVE_REQUEST,
  PARTNERPRODUCT_APPROVE_SUCCESS,
  PARTNERPRODUCT_APPROVE_FAIL,

  PHYSICALPARTNER_CREATE_REQUEST,
  PHYSICALPARTNER_CREATE_SUCCESS,
  PHYSICALPARTNER_CREATE_FAIL,
} from '../actions/productpartnerAction'

const initialState = {
  partnerCreate: { partner: {}, error: '', isLoading: false },
  partnerUpdate: { partner: {}, error: '', isLoading: false },
  partners: { all: [], error: '', isLoading: false },
  partnerAddress: { address: [], error: '', isLoading: false },
  partnerproductCreate: { partner: {}, error: '', isLoading: false },
  partprodapproval: { all: [], error: '', isLoading: false },
};
export default function (state = initialState, action) {
  switch (action.type) {
    case PARTNERS_REQUEST:
      return { ...state, partners: { all: state.partners.all, error: '', isLoading: true } };
    case PARTNERS_SUCCESS:
      return { ...state, partners: { all: action.payload, error: '', isLoading: false } };
    case PARTNERS_FAIL:
      return { ...state, partners: { all: [], error: action.payload, isLoading: false } };

    case PARTNER_CREATE_REQUEST:
      return { ...state, partnerCreate: { partner: {}, error: '', isLoading: true } };
    case PARTNER_CREATE_SUCCESS:
      return { ...state, partnerCreate: { partner: state.partnerCreate.partner, error: '', isLoading: false } };
    case PARTNER_CREATE_FAIL:
      return { ...state, partnerCreate: { partner: {}, error: action.payload, isLoading: false } };
    case PHYSICALPARTNER_CREATE_REQUEST:
      return { ...state, partnerproductCreate: { partner: {}, error: '', isLoading: true } };
    case PHYSICALPARTNER_CREATE_SUCCESS:
      return { ...state, partnerproductCreate: { partner: state.partnerproductCreate.partner, error: '', isLoading: false } };
    case PHYSICALPARTNER_CREATE_FAIL:
      return { ...state, partnerproductCreate: { partner: {}, error: action.payload, isLoading: false } };
    case PARTNER_UPDATE_REQUEST:
      return { ...state, partnerUpdate: { partner: {}, error: '', isLoading: true } };
    case PARTNER_UPDATE_SUCCESS:
      return { ...state, partnerUpdate: { partner: state.partnerUpdate.partner, error: '', isLoading: false } };
    case PARTNER_UPDATE_FAIL:
      return { ...state, partnerUpdate: { partner: {}, error: action.payload, isLoading: false } };

    case PHYSICALPARTNER_ADDRESS_REQUEST:
      return { ...state, partnerAddress: { address: state.partnerAddress.address, error: '', isLoading: true }, };
    case PHYSICALPARTNER_ADDRESS_SUCCESS:
      return { ...state, partnerAddress: { address: action.payload, error: '', isLoading: false } };
    case PHYSICALPARTNER_ADDRESS_FAIL:
      return { ...state, partnerAddress: { address: state.partnerAddress.address, error: action.payload, isLoading: false } };

    case PARTNERPRODUCT_APPROVE_REQUEST:
      return { ...state, partprodapproval: { all: state.partprodapproval.all, error: '', isLoading: true } };
    case PARTNERPRODUCT_APPROVE_SUCCESS:
      return { ...state, partprodapproval: { all: action.payload, error: '', isLoading: false } };
    case PARTNERPRODUCT_APPROVE_FAIL:
      return { ...state, partprodapproval: { all: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}