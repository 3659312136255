import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { ConnectedFocusError } from "focus-formik-error";
import { updatedeliveryCommentTicketAction } from 'src/store/actions/deliverycommentsActions';
import { CircularProgress } from '@mui/material';

const usertypeOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'ADMIN',
    label: 'ADMIN'
  },
  {
    value: 'DELIVERY',
    label: 'DELIVERY'
  },
];

const statusOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  TextareaAutosize: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditdeliveryComment = ({ className, data, state, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const isSubmitted = useSelector(state => state.deliverycomments.deliverycommentUpdate.isLoading);
  const errorMessage = useSelector(state => state.deliverycomments.deliverycommentUpdate.error);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        Modified_By: modifiedBy,
        Del_Ticket_Id: data.Del_Ticket_Id ? data.Del_Ticket_Id : "",
        Comment: data.Comment ? data.Comment : "",
        User_Type: data.User_Type ? data.User_Type : "",
        Del_Ticket_Comment_id: data.Del_Ticket_Comment_Id ? data.Del_Ticket_Comment_Id : "",
        Status: data.Status ? data.Status : "",
      }}
      validationSchema={
        Yup.object().shape({
          Comment: Yup.string().min(2, "comments must be at least 2 characters.").required('Comments is required.')
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        let submitTest = true;
        const errors = {};

        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(updatedeliveryCommentTicketAction(formValues, navigate, state));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    {/* Del_Ticket_Id*/}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        disabled
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ticket ID"
                        name="Del_Ticket_Id"
                        value={values.Del_Ticket_Id}
                        variant="outlined"
                      />
                    </Grid>

                    {/* User_Type*/}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="User Type"
                        name="User_Type"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        SelectProps={{ native: true }}
                        value={values.User_Type}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {usertypeOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Comment  */}
                    <Grid item md={12} xs={12}>
                      Comments
                      <TextareaAutosize
                        value={values.Comment}
                        onChange={handleChange}
                        name="Comment"
                        onBlur={handleBlur}
                        variant="outlined"
                        className={classes.TextareaAutosize}
                        minRows={10}
                        placeholder="Purpose"
                      >
                      </TextareaAutosize>
                      <span style={{ color: "red" }}>
                        {((touched.Comment && errors.Comment))}
                      </span>
                    </Grid>

                    <Grid item md={12} xs={12}>
                      {/* Status */}
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmitted ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmitted}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditdeliveryComment.propTypes = {
  className: PropTypes.string
};

export default EditdeliveryComment;