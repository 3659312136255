import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/product-management/product-masters',
    createdAt: '',
    description:
      'Product Master defines the product specifications  , features , colors etc., to define a product.',
    media: '/static/Dashboard/products_mgt.jpg',
    title: 'Product Masters',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/product-management/products',
    createdAt: '',
    description:
      'product is an object or system made available for consumer use , here we are creating and updating products with respective information about particular product.',
    media: '/static/Dashboard/product.png',
    title: 'Products',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/product-management/product-pos',
    createdAt: '',
    description:
      'A Point of Sale (POS) system is a combination of hardware and software that allows businesses to conduct sales transactions.',
    media: '/static/Dashboard/product.png',
    title: 'Product POS',
    total: ''
  }
];