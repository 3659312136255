import {
  USERSREVIEW_REQUEST,
  USERSREVIEW_SUCCESS,
  USERSREVIEW_FAIL,
  USERREVIEW_GET_REQUEST,
  USERREVIEW_GET_SUCCESS,
  USERREVIEW_GET_FAIL,
  USERREVIEW_CREATE_REQUEST,
  USERREVIEW_CREATE_SUCCESS,
  USERREVIEW_CREATE_FAIL,
  USERREVIEW_UPDATE_REQUEST,
  USERREVIEW_UPDATE_SUCCESS,
  USERREVIEW_UPDATE_FAIL,

  USERREVIEW_USER_GET_REQUEST,
  USERREVIEW_USER_GET_SUCCESS,
  USERREVIEW_USER_GET_FAIL,
} from '../actions/userreviewAction'

const initialState = {
  users: { all: [], error: '', isLoading: false },
  user: { user: {}, error: '', isLoading: false },
  userCreate: { user: {}, error: '', isLoading: false },
  userUpdate: { user: {}, error: '', isLoading: false },
  usersget: { all: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USERSREVIEW_REQUEST:
      return { ...state, users: { all: state.users.all, error: '', isLoading: true } };
    case USERSREVIEW_SUCCESS:
      return { ...state, users: { all: action.payload, error: '', isLoading: false } };
    case USERSREVIEW_FAIL:
      return { ...state, users: { all: [], error: action.payload, isLoading: false } };
    case USERREVIEW_GET_REQUEST:
      return { ...state, user: { user: {}, error: '', isLoading: true } };
    case USERREVIEW_GET_SUCCESS:
      return { ...state, user: { user: action.payload, error: '', isLoading: false } };
    case USERREVIEW_GET_FAIL:
      return { ...state, user: { user: {}, error: action.payload, isLoading: false } };
    case USERREVIEW_CREATE_REQUEST:
      return { ...state, userCreate: { user: {}, error: '', isLoading: true } };
    case USERREVIEW_CREATE_SUCCESS:
      return { ...state, userCreate: { user: state.userCreate.user, error: '', isLoading: false } };
    case USERREVIEW_CREATE_FAIL:
      return { ...state, userCreate: { user: {}, error: action.payload, isLoading: false } };
    case USERREVIEW_UPDATE_REQUEST:
      return { ...state, userUpdate: { user: {}, error: '', isLoading: true } };
    case USERREVIEW_UPDATE_SUCCESS:
      return { ...state, userUpdate: { user: state.userUpdate.user, error: '', isLoading: false } };
    case USERREVIEW_UPDATE_FAIL:
      return { ...state, userUpdate: { user: {}, error: action.payload, isLoading: false } };
    case USERREVIEW_USER_GET_REQUEST:
      return { ...state, usersget: { all: state.usersget.all, error: '', isLoading: true } };
    case USERREVIEW_USER_GET_SUCCESS:
      return { ...state, usersget: { all: action.payload, error: '', isLoading: false } };
    case USERREVIEW_USER_GET_FAIL:
      return { ...state, usersget: { all: [], error: action.payload, isLoading: false } };
    default:
      return state;
  }
}