import {
    COMPANYSETTINGSDATEANDCOUNT_REQUEST,
    COMPANYSETTINGSDATEANDCOUNT_SUCCESS,
    COMPANYSETTINGSDATEANDCOUNT_FAIL,

} from '../actions/companysettingsActions';

const initialState = {
    companysettingsdateandcounts: { all: [], error: '', isLoading: false },
};
export default function (state = initialState, action) {
    switch (action.type) {
        case COMPANYSETTINGSDATEANDCOUNT_REQUEST:
            return { ...state, companysettingsdateandcounts: { all: state.companysettingsdateandcounts.all, error: '', isLoading: true } };
        case COMPANYSETTINGSDATEANDCOUNT_SUCCESS:
            return { ...state, companysettingsdateandcounts: { all: action.payload, error: '', isLoading: false } };
        case COMPANYSETTINGSDATEANDCOUNT_FAIL:
            return { ...state, companysettingsdateandcounts: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}

