import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    TextField,
    makeStyles,
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    formFilterControl: {
        margin: theme.spacing(1),
        minWidth: 200
    }
}));

const Toolbar = ({ className, filters, setfilters, selectedDate, setSelectedDate, ...rest }) => {
    const classes = useStyles();

    const handleDateChange = (event) => {
        const date = moment(event.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY');
        setSelectedDate(date);
        setfilters({
            "Selected_Date": date,
        });
    };

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                alignItems="center"
                mb={2}
            >
                <Box display="flex" flexWrap="wrap" mb={2}>
                    <TextField
                        fullWidth
                        onChange={handleDateChange}
                        type="date"
                        value={moment(selectedDate, 'DD/MM/YYYY').format('YYYY-MM-DD')}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
            </Box>
        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;