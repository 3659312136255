import React, { useEffect, useRef, useState } from 'react';
import {
  Container, Grid, Typography, TextField, FormControl, RadioGroup, Radio, FormControlLabel,
  Button, Box, Divider, CardMedia, Card, Autocomplete
} from '@mui/material';
import { CardActions, CardContent, FormLabel, styled } from '@material-ui/core';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCorpProfilesAction } from 'src/store/actions/corporateticketAction';
import { createCorpCouponAction, getAllCorpSubscriptionAction, getcorpCouponImages, uploadCorpCouponImage } from 'src/store/actions/corporatecouponAction';
import { useNavigate } from 'react-router-dom';
import { ConnectedFocusError } from 'focus-formik-error';

const AddCorporateCoupon = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const users = useSelector(state => state.corptickets.corpusers.users);
  const couponImage = useSelector((state) => state.corpcoupons.corpcouponImages?.couponImage?.results || []);
  const corpsubscriptions = useSelector(state => state.corpcoupons.corpsubscriptions.subscriptions);
  const initialImagesCount = 7;

  const CustomUploadLabel = styled('label')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '100%',
    height: "120px",
    border: '1px dashed gray',
    borderRadius: 4,
    backgroundColor: '#f5f5f5',
    '& img': {
      width: '50%',
    }
  });

  const imagesToShow = couponImage && couponImage?.slice(0, initialImagesCount);
  const [uploadedImage, setUploadedImage] = useState({ preview: '', raw: '' });
  const [redemptionLimit, setRedemptionLimit] = useState('User Specific');
  const [isExpirableCoupon, setIsExpirableCoupon] = useState(true);
  const [isManuallySelectEmployees, setIsManuallySelectEmployees] = useState(true);
  const [isSelectDepartments, setIsSelectDepartments] = useState(false);
  const [isAllempoyees, setIsallempoyees] = useState(false);
  const [isUserspecific, setUserspecific] = useState(true);
  const [issingleuse, setIssingleuse] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedOption, setSelectedOption] = useState('individual');
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [discount, setDiscount] = useState('');
  const [message, setMessage] = useState('');
  const [imgId, setImageId] = useState('');

  useEffect(() => {
    if (couponImage.length > 0) {
      setSelectedImage(couponImage[0]?.Image_Url || null);
      setImageId(couponImage[0]?.id)
    }
  }, [couponImage]);

  useEffect(() => {
    if (formikRef.current) {
      if (redemptionLimit === "Single Use") {
        formikRef.current.setFieldValue('Times_Of_Use', 1);
      }
    }
  }, [redemptionLimit, formikRef]);

  useEffect(() => {
    dispatch(getAllCorpProfilesAction());
    dispatch(getAllCorpSubscriptionAction());
    dispatch(getcorpCouponImages());
  }, [dispatch]);

  const handleRadioChange = (event, setFieldValue) => {
    const isExpirable = event.target.value === 'true';
    setIsExpirableCoupon(isExpirable);
    setFieldValue('Is_Expirable_Coupon', isExpirable);
    if (!isExpirable) {
      setFieldValue('Valid_To_Date', '');
    }
  };

  const handleRadioChangeRedumption = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "User Specific") {
      setRedemptionLimit("User Specific");
      setUserspecific(true)
      setIssingleuse(false);
    } else if (selectedValue === "Single Use") {
      setRedemptionLimit("Single Use");
      setUserspecific(false)
      setIssingleuse(true);
    }
  };

  const callBackUploadImgae = data => {
    if (data) {
      setSelectedImage(data.path);
      setImageId(data.id)
    }
  };
  const handleFileUpload = e => {
    e.preventDefault();
    const file = e.target.files[0]
    if (file) {
      setUploadedImage({
        preview: URL.createObjectURL(file),
        raw: file
      });
      const data = new FormData();
      data.append('file', file);
      dispatch(uploadCorpCouponImage(data, callBackUploadImgae))
    }
  };

  const handleOptionChange = (event) => {
    const option = event.target.value;
    setSelectedOption(option);
    setSelectedEmployees([]);
    setSelectedDepartments([]);

    switch (option) {
      case 'individual':
        setIsManuallySelectEmployees(true);
        setIsSelectDepartments(false);
        setIsallempoyees(false)
        break;
      case 'subscription':
        setIsallempoyees(false)
        setIsManuallySelectEmployees(false);
        setIsSelectDepartments(true);
        break;
      case 'all':
        setIsallempoyees(true)
        setIsManuallySelectEmployees(false);
        setIsSelectDepartments(false);
        break;
      default:
        setIsallempoyees(false)
        setIsManuallySelectEmployees(false);
        setIsSelectDepartments(false);
    }
  };

  let giftedEmployees = [];
  let giftedDepartments = [];

  if (selectedOption === 'all') {
    giftedEmployees = users?.map((employee) => employee.Company_Name);
  } else if (selectedOption === 'subscription') {
    giftedDepartments = selectedDepartments
  } else if (selectedOption === 'individual') {
    giftedEmployees = selectedEmployees;
  }

  Yup.addMethod(Yup.string, 'maxWords', function (max, message) {
    return this.test({
      name: 'maxWords',
      exclusive: true,
      message: message,
      test: (value) => {
        if (!value) return true;
        const wordCount = value.split(/\s+/).filter((word) => word.length > 0).length;
        return wordCount <= max;
      },
    });
  });

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>1. Select your coupon style</Typography>
          {selectedImage && (
            <Grid
              container
              sx={{
                display: 'flex',
                minWidth: '360px',
                height: '308px',
                alignItems: 'flex-start',
                alignContent: 'flex-start',
                flexWrap: 'wrap',
                gap: '24px',
                flex: '1 0 0',
              }}
            >
              {selectedImage && (
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    height: '171px',
                    padding: '10px',
                    maxWidth: '368px',
                    minWidth: '328px',
                    gap: '10px',
                    flexDirection: 'column',
                  }}
                >
                  <CardMedia
                    component="img"
                    height="194"
                    image={selectedImage}
                    alt="Selected Image"
                  />
                  <Grid
                    container
                    item
                    sx={{ display: 'flex', gap: '12px', flexDirection: 'row', justifyContent: 'space-between' }}
                  >
                    <Grid item>
                      <Typography variant='body1' sx={{ color: '#00008', fontSize: '20px', fontWeight: 400, letterSpacing: '0.25px' }}>Meepaisa</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0.15px' }}>{discount ? discount : 0}% off on selected products</Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ margin: 0 }} />
                  <Typography variant="caption" color="text.secondary" sx={{ color: '#00008', fontSize: '12px', fontWeight: 400, lineHeight: '19.92px', letterSpacing: '0.4px' }}>
                    {message ? message : "“Thank you for your hard work and dedication! Please enjoy this special coupon as a token of our appreciation."}
                  </Typography>
                </Grid>
              )}
              <Grid container item sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-end', gap: '20px', flex: '1 0 0' }}>

                <Grid container item spacing={2}>
                  {imagesToShow.map((img, i) => (
                    <Grid item xs={3} key={i}>
                      <Card
                        sx={{
                          display: 'flex',
                          height: '120px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSelectedImage(img.Image_Url);
                          setImageId(img.id)
                          setUploadedImage({ preview: '', raw: '' });
                        }}
                      >
                        <CardMedia
                          component="img"
                          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                          image={img.Image_Url}
                          alt="Paella dish"
                        />
                      </Card>
                    </Grid>
                  ))}
                  <Grid item xs={3}>
                    <CustomUploadLabel htmlFor="file-upload">
                      {uploadedImage.preview ? (
                        <img src={uploadedImage.preview} alt="Uploaded" style={{ width: '100%', height: '120px' }} />
                      ) : (
                        <UploadFileIcon />
                      )}
                    </CustomUploadLabel>
                    <input
                      style={{ display: 'none', height: '120px ' }}
                      id="file-upload"
                      name="uploadedImage"
                      accept="image/*"
                      type="file"
                      onChange={handleFileUpload}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" gutterBottom>2. Enter coupon details</Typography>
          <Formik
            innerRef={formikRef}
            initialValues={{
              Coupon_Title: "",
              Coupon_Prefix: "",
              Discount_Percentage: '',
              Description: "",
              Times_Of_Use: '',
              Is_User_Specific: true,
              Is_Single_use: false,
              Is_Expirable_Coupon: isExpirableCoupon,
              Valid_To_Date: '',
              Is_Manually_Select_Employees: isManuallySelectEmployees,
              Is_Select_Departments: isSelectDepartments,
              Is_All_Employees: isAllempoyees,
              Image_Path: selectedImage,
              Created_By: loginEmail,
              employees: [],
              // Department_Id: giftedDepartments ? giftedDepartments : [],
              Department_Id: giftedEmployees ? giftedEmployees : [],
              Subscription_Type: giftedDepartments ? giftedDepartments : [],
              corp_Coupon_Static_Image_Id: imgId
            }}
            validationSchema={Yup.object().shape({
              Coupon_Title: Yup.string().matches(/^[a-zA-Z][\sa-zA-Z]*/, "Coupon title should not start with Spaces and Special Characters.").required('Coupon Name is required.'),
              Coupon_Prefix: Yup.string().required('Coupon Prefix is required.'),
              Discount_Percentage: Yup.number().min(1, "Discount Percentage must be at least 1.").max(100, "Discount Percentage must be less than 100.").required('Discount Percentage is required.'),
              Times_Of_Use: Yup.string()
                .when('Is_User_Specific', {
                  is: true,
                  then: Yup.string().required('Times Of Use is required'),
                  otherwise: Yup.string(),
                }),
              Is_Expirable_Coupon: Yup.boolean().required('Required'),
              Valid_To_Date: Yup.date()
                .when('Is_Expirable_Coupon', {
                  is: true,
                  then: Yup.date().required('Expiry Date is required').nullable(),
                  otherwise: Yup.date().nullable(),
                }),
              Is_Manually_Select_Employees: Yup.boolean().required('Required'),
              Is_Select_Departments: Yup.boolean().required('Required'),
              // employees: Yup.array().when(['Is_Manually_Select_Employees', 'Is_Select_Departments', 'Is_All_Employees'], {
              //   is: (Is_Manually_Select_Employees, Is_Select_Departments, Is_All_Employees) => Is_Manually_Select_Employees && !Is_Select_Departments && !Is_All_Employees,
              //   then: Yup.array().min(1, 'Please select at least one company.'),
              //   otherwise: Yup.array(),
              // }),
              // Department_Id: Yup.array().when(['Is_Select_Departments', 'Is_Manually_Select_Employees', 'Is_All_Employees'], {
              //   is: (Is_Select_Departments, Is_Manually_Select_Employees, Is_All_Employees) => Is_Select_Departments && !Is_Manually_Select_Employees && !Is_All_Employees,
              //   then: Yup.array().min(1, 'Please select at least one subscription.'),
              //   otherwise: Yup.array(),
              // }),
              Description: Yup.string()
                .required('Description is required.')
                .maxWords(15, 'Description must be 15 words or less.'),
              Department_Id: Yup.array().when(['Is_Manually_Select_Employees', 'Is_Select_Departments', 'Is_All_Employees'], {
                is: (Is_Manually_Select_Employees, Is_Select_Departments, Is_All_Employees) => Is_Manually_Select_Employees && !Is_Select_Departments && !Is_All_Employees,
                then: Yup.array().min(1, 'Please select at least one company.'),
                otherwise: Yup.array(),
              }),
              Subscription_Type: Yup.array().when(['Is_Select_Departments', 'Is_Manually_Select_Employees', 'Is_All_Employees'], {
                is: (Is_Select_Departments, Is_Manually_Select_Employees, Is_All_Employees) => Is_Select_Departments && !Is_Manually_Select_Employees && !Is_All_Employees,
                then: Yup.array().min(1, 'Please select at least one subscription.'),
                otherwise: Yup.array(),
              }),
            })}
            onSubmit={(values, { setSubmitting }) => {
              let submitTest = true;
              values.employees = [];
              // values.Department_Id = giftedDepartments ? giftedDepartments : [];
              values.Department_Id = giftedEmployees ? giftedEmployees : [];
              values.Subscription_Type = giftedDepartments ? giftedDepartments : [];
              values.Image_Path = selectedImage;
              values.corp_Coupon_Static_Image_Id = imgId;
              values.Is_Expirable_Coupon = isExpirableCoupon;
              values.Is_Manually_Select_Employees = isManuallySelectEmployees;
              values.Is_Select_Departments = isSelectDepartments;
              values.Is_All_Employees = isAllempoyees;
              values.Is_User_Specific = isUserspecific;
              values.Is_Single_use = issingleuse;
              setSubmitting(false);
              setIsSubmitted(true);
              if (submitTest) {
                if (values) {
                  let formValues = JSON.parse(JSON.stringify(values));
                  dispatch(createCorpCouponAction(formValues, navigate));
                }
              }
            }}
          >
            {({
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              touched,
            }) => (
              <form onSubmit={handleSubmit} >
                <ConnectedFocusError />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        size="medium"
                        label='Coupon Title'
                        name='Coupon_Title'
                        value={values.Coupon_Title}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        error={Boolean(touched.Coupon_Title && errors.Coupon_Title)}
                        helperText={touched.Coupon_Title && errors.Coupon_Title}
                        InputLabelProps={{
                          sx: {
                            '&.MuiInputLabel-root': {
                              '&::after': {
                                content: "'*'",
                                color: 'red',
                                marginLeft: '2px',
                              },
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        size="medium"
                        label='Coupon Prefix'
                        value={values.Coupon_Prefix}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          const noSpacesValue = value.replace(/\s+/g, '');
                          setFieldValue(name, noSpacesValue);
                        }}
                        name='Coupon_Prefix'
                        fullWidth
                        error={Boolean(touched.Coupon_Prefix && errors.Coupon_Prefix)}
                        helperText={touched.Coupon_Prefix && errors.Coupon_Prefix}
                        InputLabelProps={{
                          sx: {
                            '&.MuiInputLabel-root': {
                              '&::after': {
                                content: "'*'",
                                color: 'red',
                                marginLeft: '2px',
                              },
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        size="medium"
                        label='Discount Percentage'
                        name='Discount_Percentage'
                        type='number'
                        fullWidth
                        inputProps={{
                          min: 0,
                          max: 99,
                          step: 1,
                        }}
                        onChange={(e) => {
                          let inputValue = e.target.value.trim();
                          inputValue = inputValue.replace(/\D/g, '');
                          inputValue = inputValue.slice(0, 2);

                          if (inputValue === '' || (parseInt(inputValue, 10) >= 0 && parseInt(inputValue, 10) <= 99)) {
                            setDiscount(inputValue);
                            handleChange({
                              target: {
                                id: 'Discount_Percentage',
                                value: inputValue,
                              }
                            });
                          }
                        }}
                        error={Boolean(touched.Discount_Percentage && errors.Discount_Percentage)}
                        helperText={touched.Discount_Percentage && errors.Discount_Percentage}
                        InputLabelProps={{
                          sx: {
                            '&.MuiInputLabel-root': {
                              '&::after': {
                                content: "'*'",
                                color: 'red',
                                marginLeft: '2px',
                              },
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label='Your message'
                        name='Description'
                        value={values.Description}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          const pastedText = e.nativeEvent.inputType === 'insertFromPaste';
                          const trimmedValue = pastedText || !value.startsWith(' ') ? value : value.trim();
                          const normalizedValue = trimmedValue.replace(/\s+/g, ' ');

                          const words = normalizedValue.split(' ');

                          const maxWords = 15;

                          if (trimmedValue.length === 0) {
                            setFieldValue(name, '');
                          } else if (words.length <= maxWords) {
                            setFieldValue(name, normalizedValue.slice(0, 150));
                            setMessage(normalizedValue.slice(0, 150))
                          } else {
                            const truncatedValue = words.slice(0, maxWords).join(' ');
                            setFieldValue(name, truncatedValue);
                            setMessage(truncatedValue)
                          }
                        }}
                        error={Boolean(touched.Description && errors.Description)}
                        helperText={touched.Description && errors.Description}
                        multiline={true}
                        InputLabelProps={{
                          sx: {
                            '&.MuiInputLabel-root': {
                              '&::after': {
                                content: "'*'",
                                color: 'red',
                                marginLeft: '2px',
                              },
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Redemption Limit</FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          aria-label="redemptionLimit"
                          value={redemptionLimit}
                          onChange={handleRadioChangeRedumption}
                        >
                          <FormControlLabel value="User Specific" control={<Radio />} label="User Specific" />
                          <FormControlLabel value="Single Use" control={<Radio />} label="Single Use" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {isUserspecific && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label='Times Of Use'
                          value={values.Times_Of_Use}
                          onBlur={handleBlur}
                          onChange={(event) => {
                            const { value, name } = event.target;
                            if (/^(?!0+$)\d*$/.test(value)) {
                              handleChange(event);
                            }
                          }}
                          error={Boolean(touched.Times_Of_Use && errors.Times_Of_Use)}
                          helperText={touched.Times_Of_Use && errors.Times_Of_Use}
                          name='Times_Of_Use'
                          type='text'
                        />
                      </Grid>
                    )}
                    <Typography sx={{ p: 2 }} variant='body1' gutterBottom={false}>Redemption fee of 2% will be charged on the product MRP when someone redeems the coupon</Typography>
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Coupon Expiry</FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          value={String(isExpirableCoupon)}
                          onChange={(event) => handleRadioChange(event, setFieldValue)}>
                          <FormControlLabel value="true" control={<Radio />} label="Yes" />
                          <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {isExpirableCoupon && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Expiry Date"
                          value={values.Valid_To_Date}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name='Valid_To_Date'
                          type="date"
                          variant="outlined"
                          error={Boolean(touched.Valid_To_Date && errors.Valid_To_Date)}
                          helperText={touched.Valid_To_Date && errors.Valid_To_Date}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{
                            min: new Date().toISOString().split('T')[0]
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Recipient</FormLabel>
                        <RadioGroup value={selectedOption}
                          onChange={(e) => {
                            handleOptionChange(e);
                            setFieldValue('Is_Manually_Select_Employees', e.target.value === 'individual');
                            setFieldValue('Is_Select_Departments', e.target.value === 'subscription');
                            setFieldValue('Is_All_Employees', e.target.value === 'all');
                          }}
                        >
                          <FormControlLabel value="individual" control={<Radio />} label="Individual Companies" />
                          <FormControlLabel value="subscription" control={<Radio />} label="Subscription" />
                          <FormControlLabel value="all" control={<Radio />} label="All Companies" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {isManuallySelectEmployees && (
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          multiple
                          options={users}
                          getOptionLabel={(option) => option.Company_Name}
                          isOptionEqualToValue={(option, value) => option.Company_Name === value.Company_Name}
                          onChange={(event, value) => {
                            const selectedEmails = value.map((employee) => employee.Company_Name);
                            setSelectedEmployees(selectedEmails);
                            // setFieldValue('employees', selectedEmails);
                            setFieldValue('Department_Id', selectedEmails);
                          }}
                          value={users.filter((employee) => selectedEmployees.includes(employee.Company_Name))}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Companies"
                              name="Department_Id"
                              placeholder="Select Companies"
                              // error={Boolean(touched.employees && errors.employees)}
                              // helperText={touched.employees && errors.employees}
                              error={Boolean(touched.Department_Id && errors.Department_Id)}
                              helperText={touched.Department_Id && errors.Department_Id}
                              onBlur={handleBlur}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {isSelectDepartments && (
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          multiple
                          options={corpsubscriptions}
                          getOptionLabel={(option) => option.Subscription_Name}
                          isOptionEqualToValue={(option, value) => option.Subscription_Name === value.Subscription_Name}
                          onChange={(event, value) => {
                            const selectedDepartmentNames = value.map((department) => department.Subscription_Name);
                            setSelectedDepartments(selectedDepartmentNames);
                            setFieldValue('Subscription_Type', selectedDepartmentNames);
                          }}
                          value={corpsubscriptions.filter((department) => selectedDepartments.includes(department.Subscription_Name))}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Subscription"
                              name="Subscription_Type"
                              placeholder="Select Subscription"
                              error={Boolean(touched.Subscription_Type && errors.Subscription_Type)}
                              helperText={touched.Subscription_Type && errors.Subscription_Type}
                              onBlur={handleBlur}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {selectedOption === 'all' && (
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="All Companies"
                          value={users.map(user => user.Company_Name).join(', ')}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                          multiline
                          rows={4}
                          // error={Boolean(touched.employees && errors.employees)}
                          // helperText={touched.employees && errors.employees}
                          error={Boolean(touched.Department_Id && errors.Department_Id)}
                          helperText={touched.Department_Id && errors.Department_Id}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
                <Divider sx={{ margin: 0 }} />
                <CardActions>
                  <Box sx={{ width: '728px', height: '108px', padding: '36px 0px 36px 0px', gap: '24px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                      <Button type='submit' variant="outlined" color="primary" size="medium" disabled={isSubmitted}>
                        Send Coupon
                      </Button>
                    </Box>
                  </Box>
                </CardActions>
              </form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>Facebook Ads</Typography>
          <div>
            <img src="path/to/your/facebook_ad_image.png" alt="Facebook Ads" style={{ width: '100%' }} />
          </div>
          <Typography variant="h6" gutterBottom>ADS</Typography>
          <div>
            <img src="path/to/your/ads_image.png" alt="ADS" style={{ width: '100%' }} />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddCorporateCoupon;