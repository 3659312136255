import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
  FormControlLabel,
  Checkbox, Avatar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { updatedeliveryvehicleAction, uploadRCFrontImage, uploadRCBackImage, uploadVehicleImage } from 'src/store/actions/deliveryvehicledetailsAction';
import ESICountry from 'src/components/country-state-city/country';
import ESIState from 'src/components/country-state-city/state';
import ESICity from 'src/components/country-state-city/city';
import { getAllUserRideFaresAction } from 'src/store/actions/userridefaresAction';
import { getAllRidesVehiTypesAction } from 'src/store/actions/ridevehicletypesAction';

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  }
}));

const EditDeliveryVehicleDetails = ({ className, data, state, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  //get all vehicle fares
  useEffect(() => {
    const formData = {
      search_by_filter: "",
      search: "",
      Record_Filter: 'FILTER',
      Status: 'Active'
    };
    const formData1 = {
      Record_Filter: 'ALL',
      search_by_filter: "",
      search: "",
    };
    dispatch(getAllUserRideFaresAction(formData));
    dispatch(getAllRidesVehiTypesAction(formData1));
  }, []);

  const isSubmited = useSelector(state => state.deliveryvehicledetail.deliveryvehicledetailUpdate.isLoading);
  const errorMessage = useSelector(state => state.deliveryvehicledetail.deliveryvehicledetailUpdate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const userridefares = useSelector(state => state.userrideFares.userrideFares.all);
  const ridevehitypes = useSelector(state => state?.ridevehicletypes?.ridevehitypes?.all);

  // img upload
  const [image, setImage] = useState({ preview: data.RC_Pic_Front_Path, raw: data.RC_Pic_Front_Path });
  const [imgUrl, setImgUrl] = useState(data.RC_Pic_Front_Path);
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadRCFrontImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  // img upload1
  const [image1, setImage1] = useState({ preview: data.RC_Pic_Back_Path, raw: data.RC_Pic_Back_Path });
  const [imgUrl1, setImgUrl1] = useState(data.RC_Pic_Back_Path);
  const [uploadPic1, setUploadPic1] = useState(false);
  const callBackUploadImgae1 = data => {
    if (data) {
      setImgUrl1(data.File_URL);
    }
    setUploadPic1(false);
  };

  const handleImage1 = e => {
    setUploadPic1(true);
    e.preventDefault();
    setImage1({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadRCBackImage(new Blob([e.target.files[0]]), callBackUploadImgae1)
    );
  };

  const handleRemoveImage1 = e => {
    setImgUrl1('');
    setImage1({
      preview: '',
      raw: ''
    });
  };

  //Image 3
  const [image2, setImage2] = useState({ preview: data.Vehcle_Image_Path, raw: data.Vehcle_Image_Path });
  const [imgUrl2, setImgUrl2] = useState(data.Vehcle_Image_Path);
  const [uploadPic2, setUploadPic2] = useState(false);
  const callBackUploadVehicleImage = data => {
    if (data) {
      setImgUrl2(data.File_URL);
    }
    setUploadPic2(false);
  };

  const handleImage2 = e => {
    setUploadPic2(true);
    e.preventDefault();
    setImage2({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadVehicleImage(new Blob([e.target.files[0]]), callBackUploadVehicleImage)
    );
  };

  const handleRemoveImage2 = e => {
    setImgUrl2('');
    setImage2({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        Del_User_Email_Id: data.Del_User_Email_Id ? data.Del_User_Email_Id : "",
        State: data.State ? data.State : "",
        Country: data.Country ? data.Country : "",
        Vehicle_Number: data.Vehicle_Number ? data.Vehicle_Number : "",
        Vehicle_Availability_City_Id: data.Vehicle_Availability_City_Id ? data.Vehicle_Availability_City_Id : "",
        Vehicle_Type: data.Type ? data.Type : "",
        Vehicle_Color: data.Vehicle_Color ? data.Vehicle_Color : "",
        Vehicle_Seating_Capacity: data.Vehicle_Seating_Capacity ? data.Vehicle_Seating_Capacity : "",
        Is_Active_Vehicle: data.Is_Active_Vehicle > 0 ? true : false,
        Del_Vehicle_Detail_Id: data.Del_Vehicle_Detail_Id ? data.Del_Vehicle_Detail_Id : "",
        Vehicle_Comfort_Type: data.Vehicle_Comfort_Type ? data.Vehicle_Comfort_Type : "",
        Vehicle_RC_Number: data.RC_Number ? data.RC_Number : "",
        Vehicle_RC_Front_Image: imgUrl,
        Vehicle_RC_Back_Image: imgUrl1,
        Vehicle_Picture_Path: imgUrl2,
        Insurance_File_Path: data.Insurance_File_Path ? data.Insurance_File_Path : "",
        Regular_Fare_Per_Kilometer: data.Regular_Fare_Per_Kilometer ? data.Regular_Fare_Per_Kilometer : "",
        Insurance_Number: data.Insurance_Number ? data.Insurance_Number : "",
        User_Ride_Vehicle_Fare_Id: data.User_Ride_Vehicle_Fare_Id ? data.User_Ride_Vehicle_Fare_Id : "",
        Updated_By: loginEmail
      }}
      validationSchema={
        Yup.object().shape({
          Del_User_Email_Id: Yup.string().required('Delivery User Email ID is required.'),
          Vehicle_Type: Yup.string().required('Vehicle Type is required.'),
          Vehicle_Color: Yup.string().required('Vehicle Color is required.'),
          Insurance_File_Path: Yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
            'Please provide valid URL.'
          ).required('Insurance File Path is required.'),
          Vehicle_Availability_City_Id: Yup.string().required('City is required.'),
          Vehicle_Comfort_Type: Yup.string().required('Comfort Type is required.'),
          Vehicle_Seating_Capacity: Yup.number().min(1, "Seating Capacity must be at least 1.").max(50, "Seating Capacity must be less than 50.").required('Seating Capacity is required.'),
          Regular_Fare_Per_Kilometer: Yup.string().required('Regular Fare/KM is required.'),
          Insurance_Number: Yup.string().required('Insurance Number is required.'),
          State: Yup.string().required('State is required.'),
          Country: Yup.string().required('Country is required.'),
          Vehicle_Number: Yup.string().required('Vehicle Number is required.'),
          Vehicle_RC_Number: Yup.string().required('Vehicle RC Number is required.'),
          User_Ride_Vehicle_Fare_Id: Yup.string().required('User Ride Vehicle Fare is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        values.Vehicle_RC_Front_Image = imgUrl;
        values.Vehicle_RC_Back_Image = imgUrl1;
        values.Vehicle_Picture_Path = imgUrl2;

        if (!values.Vehicle_RC_Front_Image) {
          submitTest = false;
          errors.Vehicle_RC_Front_Image = "RC Front Image is required.";
        }
        if (!values.Vehicle_RC_Back_Image) {
          submitTest = false;
          errors.Vehicle_RC_Back_Image = "RC Back Image is required.";
        }
        if (!values.Vehicle_Picture_Path) {
          submitTest = false;
          errors.Vehicle_Picture_Path = "Vehicle Image is required.";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          if (values.Is_Active_Vehicle) {
            formValues.Is_Active_Vehicle = 1;
          } else {
            formValues.Is_Active_Vehicle = 0;
          }
          dispatch(updatedeliveryvehicleAction(formValues, navigate, state));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    {/*Del_User_Mail_Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Del_User_Email_Id && errors.Del_User_Email_Id)}
                        fullWidth
                        helperText={touched.Del_User_Email_Id && errors.Del_User_Email_Id}
                        disabled
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Del User Email ID"
                        name="Del_User_Email_Id"
                        value={values.Del_User_Email_Id}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Country Field */}
                    <Grid item md={6} xs={12}>
                      <ESICountry
                        error={Boolean(touched.Country && errors.Country)}
                        fullWidth
                        helperText={touched.Country && errors.Country}
                        placeholder="Country"
                        label="Country"
                        name="Country"
                        onChange={handleChange}
                        select
                        SelectProps={{ native: true }}
                        value={values.Country}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    {/* State field */}
                    <Grid item md={6} xs={12}>
                      <ESIState
                        error={Boolean(touched.State && errors.State)}
                        fullWidth
                        helperText={touched.State && errors.State}
                        label="State"
                        name="State"
                        onChange={handleChange}
                        value={values.State}
                        countrycode={values.Country}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    {/*City */}
                    <Grid item md={6} xs={12}>
                      <ESICity
                        error={Boolean(touched.Vehicle_Availability_City_Id && errors.Vehicle_Availability_City_Id)}
                        fullWidth
                        helperText={touched.Vehicle_Availability_City_Id && errors.Vehicle_Availability_City_Id}
                        label="City"
                        name="Vehicle_Availability_City_Id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.Vehicle_Availability_City_Id}
                        countrycode={values.Country}
                        statecode={values.State}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    {/*Vehicle_Type */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Vehicle_Type && errors.Vehicle_Type)}
                        fullWidth
                        helperText={touched.Vehicle_Type && errors.Vehicle_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Vehicle Type"
                        name="Vehicle_Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Vehicle_Type}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {ridevehitypes.map(option => (
                          <option key={option.Ride_Vehicle_Type_Id} value={option.Vehicle_Type}>
                            {option.Vehicle_Type}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/*Vehicle_Comfort_Type */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Vehicle_Comfort_Type && errors.Vehicle_Comfort_Type)}
                        fullWidth
                        helperText={touched.Vehicle_Comfort_Type && errors.Vehicle_Comfort_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Vehicle Comfort Type"
                        name="Vehicle_Comfort_Type"
                        value={values.Vehicle_Comfort_Type}
                        variant="outlined"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                      ><option key="" value="">--Please Select--</option>
                        {ridevehitypes.map(option => (
                          <option key={option.Vehicle_Comfort_Type} value={option.Vehicle_Comfort_Type}>
                            {option.Vehicle_Comfort_Type}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.User_Ride_Vehicle_Fare_Id && errors.User_Ride_Vehicle_Fare_Id)}
                        fullWidth
                        helperText={touched.User_Ride_Vehicle_Fare_Id && errors.User_Ride_Vehicle_Fare_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="User Ride Vehicle Fare"
                        name="User_Ride_Vehicle_Fare_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.User_Ride_Vehicle_Fare_Id}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {userridefares?.sort((a, b) => a.Store_Type_Name?.localeCompare(b.Store_Type_Name))?.map(option => (
                          <option key={option.User_Ride_Vehicle_Fare_Id} value={option.User_Ride_Vehicle_Fare_Id}>
                            {option.Vehicle_Type + " - " + option.Vehicle_Comfort_Type + " - " + option.City_Name + " - " + option.Minimum_Fare}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/*Vehicle Number */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Vehicle_Number && errors.Vehicle_Number)}
                        fullWidth
                        helperText={touched.Vehicle_Number && errors.Vehicle_Number}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Vehicle Number"
                        name="Vehicle_Number"
                        value={values.Vehicle_Number}
                        variant="outlined"
                      />
                    </Grid>

                    {/*Vehicle_Color */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Vehicle_Color && errors.Vehicle_Color)}
                        fullWidth
                        helperText={touched.Vehicle_Color && errors.Vehicle_Color}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Vehicle Color"
                        name="Vehicle_Color"
                        value={values.Vehicle_Color}
                        variant="outlined"
                      />
                    </Grid>

                    {/*Vehicle_Seating_Capacity */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Vehicle_Seating_Capacity && errors.Vehicle_Seating_Capacity)}
                        fullWidth
                        helperText={touched.Vehicle_Seating_Capacity && errors.Vehicle_Seating_Capacity}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Vehicle Seating Capacity"
                        name="Vehicle_Seating_Capacity"
                        value={values.Vehicle_Seating_Capacity}
                        variant="outlined"
                        type="number"
                      />
                    </Grid>

                    {/*Vehicle_RC_Number */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Vehicle_RC_Number && errors.Vehicle_RC_Number)}
                        fullWidth
                        helperText={touched.Vehicle_RC_Number && errors.Vehicle_RC_Number}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Vehicle RC Number"
                        name="Vehicle_RC_Number"
                        value={values.Vehicle_RC_Number}
                        variant="outlined"
                      />
                    </Grid>

                    {/*Regular_Fare_Per_Kilometer */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Regular_Fare_Per_Kilometer && errors.Regular_Fare_Per_Kilometer)}
                        fullWidth
                        helperText={touched.Regular_Fare_Per_Kilometer && errors.Regular_Fare_Per_Kilometer}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Regular Fare Per Kilometer"
                        name="Regular_Fare_Per_Kilometer"
                        value={values.Regular_Fare_Per_Kilometer}
                        variant="outlined"
                      />
                    </Grid>

                    {/*Insurance_Number */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Insurance_Number && errors.Insurance_Number)}
                        fullWidth
                        helperText={touched.Insurance_Number && errors.Insurance_Number}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Insurance Number"
                        name="Insurance_Number"
                        value={values.Insurance_Number}
                        variant="outlined"
                      />
                    </Grid>

                    {/*Insurance_File_Path */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Insurance_File_Path && errors.Insurance_File_Path)}
                        fullWidth
                        helperText={touched.Insurance_File_Path && errors.Insurance_File_Path}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Insurance File Path"
                        name="Insurance_File_Path"
                        value={values.Insurance_File_Path}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Is_Active_Vehicle */}
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Active_Vehicle}
                            onChange={handleChange}
                            name="Is_Active_Vehicle"
                            color="primary"
                          />
                        }
                        label="Is Active Vehicle"
                      />
                    </Grid>

                  </Grid>
                </Grid>

                <Grid item md={4} xs={12}>
                  <Card>
                    &nbsp;Vehicle RC Front Image
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Brand Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>

                    </CardContent>
                  </Card><br />
                  <span style={{ color: "red" }}>
                    {!imgUrl && touched.Vehicle_RC_Front_Image && errors.Vehicle_RC_Front_Image}
                  </span>
                  <div style={{ marginBottom: '5%' }}></div>
                  <Card>
                    &nbsp;Vehicle RC Back Image
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image1.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Brand Upload"
                            src={image1.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image1.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage1}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage1}
                        />}
                        <br />
                      </Box>

                    </CardContent>
                  </Card><br />
                  <span style={{ color: "red" }}>
                    {!imgUrl1 && touched.Vehicle_RC_Back_Image && errors.Vehicle_RC_Back_Image}
                  </span>
                  <div style={{ marginBottom: '5%' }}></div>
                  <Card>
                    &nbsp;Vehicle Image
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image2.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Brand Upload"
                            src={image2.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image2.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage2}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage2}
                        />}
                        <br />
                      </Box>

                    </CardContent>
                  </Card><br />
                  <span style={{ color: "red" }}>
                    {!imgUrl2 && touched.Vehicle_Picture_Path && errors.Vehicle_Picture_Path}
                  </span>
                </Grid>

              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              <Button
                disabled={isSubmited || uploadPic || uploadPic1 || uploadPic2}
                type="submit"
                style={{ backgroundColor: '#27B6CC' }}
                variant="contained"
              >
                <span style={{ color: 'white' }}>Save details</span>
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditDeliveryVehicleDetails.propTypes = {
  className: PropTypes.string
};

export default EditDeliveryVehicleDetails;