import {
    USER_RIDE_BIDDING_CREATE_REQUEST,
    USER_RIDE_BIDDING_CREATE_SUCCESS,
    USER_RIDE_BIDDING_CREATE_FAIL,

    USER_RIDE_BIDDING_UPDATE_REQUEST,
    USER_RIDE_BIDDING_UPDATE_SUCCESS,
    USER_RIDE_BIDDING_UPDATE_FAIL,

    BIDDING_CITIES_GET_REQUEST,
    BIDDING_CITIES_GET_SUCCESS,
    BIDDING_CITIES_GET_FAIL,

    BID_BRAND_VEHICLES_GET_REQUEST,
    BID_BRAND_VEHICLES_GET_SUCCESS,
    BID_BRAND_VEHICLES_GET_FAIL,

    BID_VEHICLES_GET_REQUEST,
    BID_VEHICLES_GET_SUCCESS,
    BID_VEHICLES_GET_FAIL,

    BID_RIDE_QUOTES_GET_REQUEST,
    BID_RIDE_QUOTES_GET_SUCCESS,
    BID_RIDE_QUOTES_GET_FAIL,

    BID_USER_SEARCH_GET_REQUEST,
    BID_USER_SEARCH_GET_SUCCESS,
    BID_USER_SEARCH_GET_FAIL,

    BID_RIDE_DELI_PAYMENT_REQUEST,
    BID_RIDE_DELI_PAYMENT_SUCCESS,
    BID_RIDE_DELI_PAYMENT_FAIL,
} from '../actions/userridebiddingsAction'

const initialState = {
    userridebiddingCreate: { userridebidding: {}, error: '', isLoading: false },
    userridebiddingUpdate: { userridebidding: {}, error: '', isLoading: false },
    biddingcities: { all: [], error: '', isLoading: false },
    bidridebrandvehicles: { all: [], error: '', isLoading: false },
    bidridevehicles: { all: [], error: '', isLoading: false },
    bidridequotes: { all: [], error: '', isLoading: false },
    bidusersSearch: { bidusers: [], error: '', isLoading: false },
    ridebiddeliverypayment: { ridebiddelipay: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case USER_RIDE_BIDDING_CREATE_REQUEST:
            return { ...state, userridebiddingCreate: { userridebidding: {}, error: '', isLoading: true } };
        case USER_RIDE_BIDDING_CREATE_SUCCESS:
            return { ...state, userridebiddingCreate: { userridebidding: state.userridebiddingCreate.userridebidding, error: '', isLoading: false } };
        case USER_RIDE_BIDDING_CREATE_FAIL:
            return { ...state, userridebiddingCreate: { userridebidding: {}, error: action.payload, isLoading: false } };

        case USER_RIDE_BIDDING_UPDATE_REQUEST:
            return { ...state, userridebiddingUpdate: { userridebidding: {}, error: '', isLoading: true } };
        case USER_RIDE_BIDDING_UPDATE_SUCCESS:
            return { ...state, userridebiddingUpdate: { userridebidding: state.userridebiddingUpdate.userridebidding, error: '', isLoading: false } };
        case USER_RIDE_BIDDING_UPDATE_FAIL:
            return { ...state, userridebiddingUpdate: { userridebidding: {}, error: action.payload, isLoading: false } };

        case BIDDING_CITIES_GET_REQUEST:
            return { ...state, biddingcities: { all: state.biddingcities.all, error: '', isLoading: true } };
        case BIDDING_CITIES_GET_SUCCESS:
            return { ...state, biddingcities: { all: action.payload, error: '', isLoading: false } };
        case BIDDING_CITIES_GET_FAIL:
            return { ...state, biddingcities: { all: [], error: action.payload, isLoading: false } };

        case BID_BRAND_VEHICLES_GET_REQUEST:
            return { ...state, bidridebrandvehicles: { all: state.bidridebrandvehicles.all, error: '', isLoading: true } };
        case BID_BRAND_VEHICLES_GET_SUCCESS:
            return { ...state, bidridebrandvehicles: { all: action.payload, error: '', isLoading: false } };
        case BID_BRAND_VEHICLES_GET_FAIL:
            return { ...state, bidridebrandvehicles: { all: [], error: action.payload, isLoading: false } };

        case BID_VEHICLES_GET_REQUEST:
            return { ...state, bidridevehicles: { all: state.bidridevehicles.all, error: '', isLoading: true } };
        case BID_VEHICLES_GET_SUCCESS:
            return { ...state, bidridevehicles: { all: action.payload, error: '', isLoading: false } };
        case BID_VEHICLES_GET_FAIL:
            return { ...state, bidridevehicles: { all: [], error: action.payload, isLoading: false } };

        case BID_RIDE_QUOTES_GET_REQUEST:
            return { ...state, bidridequotes: { all: state.bidridequotes.all, error: '', isLoading: true } };
        case BID_RIDE_QUOTES_GET_SUCCESS:
            return { ...state, bidridequotes: { all: action.payload, error: '', isLoading: false } };
        case BID_RIDE_QUOTES_GET_FAIL:
            return { ...state, bidridequotes: { all: [], error: action.payload, isLoading: false } };

        case BID_USER_SEARCH_GET_REQUEST:
            return { ...state, bidusersSearch: { bidusers: state.bidusersSearch.bidusers, error: '', isLoading: true }, };
        case BID_USER_SEARCH_GET_SUCCESS:
            return { ...state, bidusersSearch: { bidusers: action.payload, error: '', isLoading: false } };
        case BID_USER_SEARCH_GET_FAIL:
            return { ...state, bidusersSearch: { bidusers: [], error: action.payload, isLoading: false } };

        case BID_RIDE_DELI_PAYMENT_REQUEST:
            return { ...state, ridebiddeliverypayment: { ridebiddelipay: {}, error: '', isLoading: true } };
        case BID_RIDE_DELI_PAYMENT_SUCCESS:
            return { ...state, ridebiddeliverypayment: { ridebiddelipay: state.ridebiddeliverypayment.ridebiddelipay, error: '', isLoading: false } };
        case BID_RIDE_DELI_PAYMENT_FAIL:
            return { ...state, ridebiddeliverypayment: { ridebiddelipay: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}