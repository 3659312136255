import config from './config';
import { toast } from 'react-toastify';
import { paginationAllUserBiddingProductsGet } from './node-actions/nodepagination';
import { boomiAdminApi, nodeCudApi, nodeGetApi } from './commonAxios';

export const BIDDING_USER_PRODUCTS_CREATE_REQUEST = "BIDDING_USER_PRODUCTS_CREATE_REQUEST";
export const BIDDING_USER_PRODUCTS_CREATE_SUCCESS = "BIDDING_USER_PRODUCTS_CREATE_SUCCESS";
export const BIDDING_USER_PRODUCTS_CREATE_FAIL = "BIDDING_USER_PRODUCTS_CREATE_FAIL";

export const BIDDING_USER_PRODUCTS_UPDATE_REQUEST = "BIDDING_USER_PRODUCTS_UPDATE_REQUEST";
export const BIDDING_USER_PRODUCTS_UPDATE_SUCCESS = "BIDDING_USER_PRODUCTS_UPDATE_SUCCESS";
export const BIDDING_USER_PRODUCTS_UPDATE_FAIL = "BIDDING_USER_PRODUCTS_UPDATE_FAIL";

export const BIDDING_PRODUCTS_REQUEST = "BIDDING_PRODUCTS_REQUEST";
export const BIDDING_PRODUCTS_SUCCESS = "BIDDING_PRODUCTS_SUCCESS";
export const BIDDING_PRODUCTS_FAIL = "BIDDING_PRODUCTS_FAIL";

export const USER_EMAIL_REQUEST = "USER_EMAIL_REQUEST";
export const USER_EMAIL_SUCCESS = "USER_EMAIL_SUCCESS";
export const USER_EMAIL_FAIL = "USER_EMAIL_FAIL";

export const USER_EMAIL_SEARCH_REQUEST = "USER_EMAIL_SEARCH_REQUEST";
export const USER_EMAIL_SEARCH_SUCCESS = "USER_EMAIL_SEARCH_SUCCESS";
export const USER_EMAIL_SEARCH_FAIL = "USER_EMAIL_SEARCH_FAIL";

export const PARTNER_BIDDING_QUOTES_REQUEST = "PARTNER_BIDDING_QUOTES_REQUEST";
export const PARTNER_BIDDING_QUOTES_SUCCESS = "PARTNER_BIDDING_QUOTES_SUCCESS";
export const PARTNER_BIDDING_QUOTES_FAIL = "PARTNER_BIDDING_QUOTES_FAIL";

export const PARTNER_PAYMNETS_REQUEST = "PARTNER_PAYMNETS_REQUEST";
export const PARTNER_PAYMNETS_SUCCESS = "PARTNER_PAYMNETS_SUCCESS";
export const PARTNER_PAYMNETS_FAIL = "PARTNER_PAYMNETS_FAIL";

export const BID_PRODUCTS_USER_EMAIL_REQUEST = "BID_PRODUCTS_USER_EMAIL_REQUEST";
export const BID_PRODUCTS_USER_EMAIL_SUCCESS = "BID_PRODUCTS_USER_EMAIL_SUCCESS";
export const BID_PRODUCTS_USER_EMAIL_FAIL = "BID_PRODUCTS_USER_EMAIL_FAIL";

//create Bidding User Product Action
export const createBiddingUserProductAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: BIDDING_USER_PRODUCTS_CREATE_REQUEST
  });
  nodeCudApi.post(`/User_Products_Bidding/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BIDDING_USER_PRODUCTS_CREATE_SUCCESS
        });
        toast('Bidding User Product created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/user-bidding-products');
      } else {
        dispatch({
          type: BIDDING_USER_PRODUCTS_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: BIDDING_USER_PRODUCTS_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

//update Bidding User Product Action
export const updateBiddingUserProductAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: BIDDING_USER_PRODUCTS_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/User_Products_Bidding/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BIDDING_USER_PRODUCTS_UPDATE_SUCCESS
        });
        toast('Bidding User Product updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/user-bidding-products');
      } else {

        dispatch({
          type: BIDDING_USER_PRODUCTS_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: BIDDING_USER_PRODUCTS_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

//Get All User Address Get
export const getAllUserAddress = (formData, callBacksubtypeData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/User/Address_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=USER_EMAIL_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === "0") {
        callBacksubtypeData([])
      } else {
        callBacksubtypeData(data.results)
      }
    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};


//get All bidding products
export const getAllBiddingProducts = () => async dispatch => {
  try {
    let formData = {
      search_by_filter: "",
      search: "",
      Bidding_Product_Id: "",
      Status: "",
      Records_Filter: ""
    };
    dispatch({
      type: BIDDING_PRODUCTS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin/Bidding_Products?PageNo=1&PageSize=${config.pageSize}&SortBy=BIDDING_PRODUCT_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BIDDING_PRODUCTS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BIDDING_PRODUCTS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: BIDDING_PRODUCTS_FAIL,
      payload: err
    });
  }
};

//Get All User Email Get
export const getAllUserEmails = (emailid) => async dispatch => {
  try {
    let formData = {
      "Email_Id": emailid,
      "Records_Filter": "FILTER"
    };
    dispatch({
      type: BID_PRODUCTS_USER_EMAIL_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin/User_Get_dropdown_search?PageNo=1&PageSize=${config.pageSize}&SortBy=Email_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: BID_PRODUCTS_USER_EMAIL_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BID_PRODUCTS_USER_EMAIL_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: BID_PRODUCTS_USER_EMAIL_FAIL,
      payload: err
    });
  }
};


//Get All Partner Bidding quotes
export const getAllPartnerBiddingQuotes = (formData) => async dispatch => {
  try {
    dispatch({
      type: PARTNER_BIDDING_QUOTES_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin/Partner_Bidding_Quotes?PageNo=1&PageSize=${config.pageSize}&SortBy=Partner_Bidding_Quote_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNER_BIDDING_QUOTES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNER_BIDDING_QUOTES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PARTNER_BIDDING_QUOTES_FAIL,
      payload: err
    });
  }
};

//Get All Bidding Products
export const getAllbiddingproducts = (formData, callBacksubtypeproductsData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Super_Admin/Bidding_Products?PageNo=1&PageSize=${config.pageSize}&SortBy=BIDDING_PRODUCT_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === "0") {
        callBacksubtypeproductsData([])
      } else {
        callBacksubtypeproductsData(data.results)
      }
    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};

// Alert Notification for Products Action
export const AlertNotifyProductsAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/Notifications/Products`, formData)
    if (data) {
      dispatch(paginationAllUserBiddingProductsGet(filters, pagination, callBackPagination));
      toast('Trigger the notification successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    }
  } catch (err) {
  }
};

//PartnerPayments Action
export const UserProductsPartnerPayAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: PARTNER_PAYMNETS_REQUEST
  });
  boomiAdminApi.post(`/Bidding_Products/Payment_to_Partner`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNER_PAYMNETS_SUCCESS
        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/user-bidding-products');
      } else {
        dispatch({
          type: PARTNER_PAYMNETS_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_PAYMNETS_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

//Get Bidding User Products
export const getBiddingUserProductAction = (formData, callBackBiddingProductsData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Super_Admin/User_Products_Bidding?PageNo=1&PageSize=${config.pageSize}&SortBy=User_Products_Bidding_Id&SortOrder=ASC`, formData);
    if (data) {
      callBackBiddingProductsData(data)
    }
  } catch (err) {
  }
};