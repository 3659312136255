import {
  TEMPLATE_CREATE_REQUEST,
  TEMPLATE_CREATE_SUCCESS,
  TEMPLATE_CREATE_FAIL,
  TEMPLATE_UPDATE_REQUEST,
  TEMPLATE_UPDATE_SUCCESS,
  TEMPLATE_UPDATE_FAIL,
} from '../actions/templateAction'

const initialState = {
  templateCreate: { template: {}, error: '', isLoading: false },
  templateUpdate: { template: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TEMPLATE_CREATE_REQUEST:
      return { ...state, templateCreate: { template: {}, error: '', isLoading: true } };
    case TEMPLATE_CREATE_SUCCESS:
      return { ...state, templateCreate: { template: state.templateCreate.template, error: '', isLoading: false } };
    case TEMPLATE_CREATE_FAIL:
      return { ...state, templateCreate: { template: {}, error: action.payload, isLoading: false } };
    case TEMPLATE_UPDATE_REQUEST:
      return { ...state, templateUpdate: { template: {}, error: '', isLoading: true } };
    case TEMPLATE_UPDATE_SUCCESS:
      return { ...state, templateUpdate: { template: state.templateUpdate.template, error: '', isLoading: false } };
    case TEMPLATE_UPDATE_FAIL:
      return { ...state, templateUpdate: { template: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}