import {
  SBCM_PRODUCTS_REQUEST,
  SBCM_PRODUCTS_SUCCESS,
  SBCM_PRODUCTS_FAIL,
  SBCM_PRODUCT_GET_REQUEST,
  SBCM_PRODUCT_GET_SUCCESS,
  SBCM_PRODUCT_GET_FAIL,
  SBCM_PRODUCT_CREATE_REQUEST,
  SBCM_PRODUCT_CREATE_SUCCESS,
  SBCM_PRODUCT_CREATE_FAIL,
  SBCM_PRODUCT_UPDATE_REQUEST,
  SBCM_PRODUCT_UPDATE_SUCCESS,
  SBCM_PRODUCT_UPDATE_FAIL,
} from '../actions/sbcmProductAction';

const initialState = {
  products: { all: [], error: '', isLoading: false },
  product: { product: {}, error: '', isLoading: false },
  productCreate: { product: {}, error: '', isLoading: false },
  productUpdate: { product: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SBCM_PRODUCTS_REQUEST:
      return { ...state, products: { all: state.products.all, error: '', isLoading: true } };
    case SBCM_PRODUCTS_SUCCESS:
      return { ...state, products: { all: action.payload, error: '', isLoading: false } };
    case SBCM_PRODUCTS_FAIL:
      return { ...state, products: { all: [], error: action.payload, isLoading: false } };
    case SBCM_PRODUCT_GET_REQUEST:
      return { ...state, product: { product: {}, error: '', isLoading: true } };
    case SBCM_PRODUCT_GET_SUCCESS:
      return { ...state, product: { product: action.payload, error: '', isLoading: false } };
    case SBCM_PRODUCT_GET_FAIL:
      return { ...state, product: { product: {}, error: action.payload, isLoading: false } };
    case SBCM_PRODUCT_CREATE_REQUEST:
      return { ...state, productCreate: { product: {}, error: '', isLoading: true } };
    case SBCM_PRODUCT_CREATE_SUCCESS:
      return { ...state, productCreate: { product: state.productCreate.product, error: '', isLoading: false } };
    case SBCM_PRODUCT_CREATE_FAIL:
      return { ...state, productCreate: { product: {}, error: action.payload, isLoading: false } };
    case SBCM_PRODUCT_UPDATE_REQUEST:
      return { ...state, productUpdate: { product: {}, error: '', isLoading: true } };
    case SBCM_PRODUCT_UPDATE_SUCCESS:
      return { ...state, productUpdate: { product: state.productCreate.product, error: '', isLoading: false } };
    case SBCM_PRODUCT_UPDATE_FAIL:
      return { ...state, productUpdate: { product: {}, error: action.payload, isLoading: false } };
    default:
      return state;
  }
}
