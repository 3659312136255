import React from 'react';
import {
  Box,
  InputAdornment,
  SvgIcon,
  TextField,
  makeStyles,
  Button
} from '@material-ui/core';
import clsx from 'clsx';
import { Search as SearchIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  formFilterControl: {
    margin: theme.spacing(1),
    minWidth: 200
  }
}));

const Toolbar = ({ className, filters, setfilters, RolePermissions, roleUserType, ...rest }) => {
  const classes = useStyles();

  const handleChange = event => {
    setfilters({
      search: filters?.search,
      search_by_filter: event.target.value
    })
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" flexWrap="wrap" mb={2}>
          <FormControl variant="outlined" className={classes.formFilterControl}>
            <Select
              value={filters.search_by_filter}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Partner_Business_Type_Id">Service Subtype ID</MenuItem>
              <MenuItem value="Business_Type">Service Type</MenuItem>
              <MenuItem value="Business_Sub_Type">Service Subtype </MenuItem>
              <MenuItem value="Status">Status</MenuItem>
            </Select>
          </FormControl>

          <Box width={500}>
            <TextField
              onChange={(event) => {
                setfilters({
                  search: event.target.value,
                  search_by_filter: filters.search_by_filter
                })
              }}
              className={classes.formControl}
              value={filters?.search}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search Business Service Subtypes"
              variant="outlined"
            />
          </Box>
        </Box>
        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
          (RolePermissions?.Bussiness_Services_Subtypes_Write?.Value === "Bussiness_Services_Subtypes_Write" && RolePermissions?.Bussiness_Services_Subtypes_Write?.Is_Write === 1) ?
          <Button
            component={RouterLink}
            to="/app/add-bussinessSubtype"
            color="primary"
            variant="contained"
            style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}>
            <span style={{ color: "white" }}>
              + ADD BUSINESS SUBTYPE
            </span>
          </Button>
          : null}
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;