import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  topDeals
} from '../../../../store/actions/reports/dashboardActions';
import isEmpty from 'src/store/validations/is-empty';

const useStyles = makeStyles({
  root: {
    height: '100%'
  },
  image: {
    height: 48,
    width: 48
  }
});

const TopDeals = ({ className, RolePermissions, setSeconds, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const products = useSelector(state => state.dash.TDeals.data);
  let Top_Deals_Hide = RolePermissions?.Top_Deals_Hide?.Is_Hide

  // componentDidMount
  useEffect(() => {
    dispatch(topDeals());
    setSeconds(5);
    if (RolePermissions?.Top_Deals_Hide?.Value === "Top_Deals_Hide" && RolePermissions?.Top_Deals_Hide?.Is_Hide === 1) {
      Top_Deals_Hide = RolePermissions?.Top_Deals_Hide?.Is_Hide
    }
  }, []);

  return (
    <>
      {Top_Deals_Hide !== 1 &&
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardHeader
            title="Top Deals"
          />
          <Divider />
          {isEmpty(products) &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', paddingBottom: '15px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >
              No top deals found!
            </div>
          }
          <List>
            {products.map((product, i) => (
              <ListItem divider={i < products.length - 1} key={product.Deal_Id}>
                <ListItemAvatar>
                  <img
                    alt="Deals"
                    className={classes.image}
                    src={product.Image}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={product.Title}
                  secondary={`Partner : ${product.Partner_Name}`}
                />
                <> {product.Grab_Count} </>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Card>
      }
    </>
  );
};

TopDeals.propTypes = {
  className: PropTypes.string
};

export default TopDeals;