import {
  DELIVERY_FAQ_CREATE_REQUEST,
  DELIVERY_FAQ_CREATE_SUCCESS,
  DELIVERY_FAQ_CREATE_FAIL,
  DELIVERY_FAQ_UPDATE_REQUEST,
  DELIVERY_FAQ_UPDATE_SUCCESS,
  DELIVERY_FAQ_UPDATE_FAIL,
  RESET
} from '../actions/DeliveryFAQActions'

const initialState = {
  deliveryfaqCreate: { deliveryfaq: {}, error: '', isLoading: false },
  deliveryfaqUpdate: { deliveryfaq: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DELIVERY_FAQ_CREATE_REQUEST:
      return { ...state, deliveryfaqCreate: { deliveryfaq: {}, error: '', isLoading: true } };
    case DELIVERY_FAQ_CREATE_SUCCESS:
      return { ...state, deliveryfaqCreate: { deliveryfaq: state.deliveryfaqCreate.deliveryfaq, error: '', isLoading: false } };
    case DELIVERY_FAQ_CREATE_FAIL:
      return { ...state, deliveryfaqCreate: { deliveryfaq: {}, error: action.payload, isLoading: false } };
    case DELIVERY_FAQ_UPDATE_REQUEST:
      return { ...state, deliveryfaqUpdate: { deliveryfaq: {}, error: '', isLoading: true } };
    case DELIVERY_FAQ_UPDATE_SUCCESS:
      return { ...state, deliveryfaqUpdate: { deliveryfaq: action.payload, error: '', isLoading: false } };
    case DELIVERY_FAQ_UPDATE_FAIL:
      return { ...state, deliveryfaqUpdate: { deliveryfaq: {}, error: action.payload, isLoading: false } };

    case RESET:
      return { ...state, faqCreate: { faq: {}, error: '', isLoading: false } };

    default:
      return state;
  }
}