import { Card, CardContent, Divider, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

// useStyles we use a hook to consume the styles
const useStyles = makeStyles(() => ({
    images: {
        width: 100,
        height: 100
    },
}));

const CategoryDetails = ({ className, ...rest }) => {
    //useLocation returns the location object that contains information about the current URL
    const location = useLocation();
    // classes contains the styles
    const classes = useStyles();
    //state represent an information about the component's current situation
    let { state } = location;

    return (
        <Card>
            <CardContent>
                {/* Grid creates visual consistency between layouts and adapts to screen size */}
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Category ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state.Category_Id}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Category Name
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state.Category_Name}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Store Type Name
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state.Store_Type_Name}
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Sub Categories Count
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state.Sub_Categories_Count}
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Farmer Category
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state.Is_Farmer_Category}
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Category For
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state.Category_For}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Category Image Mobile
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <a target="_blank" href={state.Category_Image_Mobile}>
                                        <img
                                            className={classes.images}
                                            alt="Vehicle Image Path"
                                            src={state.Category_Image_Mobile}
                                            variant="square"
                                        />
                                    </a>
                                </Typography>
                            </Grid>

                            <Grid item xs={2} />
                            <Grid item xs={2} />
                            <Grid item xs={7} />
                        </Grid>
                    </Grid>

                    {/*  column 2 */}
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Status
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state.Status}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Category Type
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state.Category_Type}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state.Created_Date}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created By
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state.Created_By}> {state.Created_By?.length > 25 ?
                                        <span>{state.Created_By?.substring(0, 25)}...</span> : state.Created_By}</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state.Modified_Date}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified By
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state.Modified_By}> {state.Modified_By?.length > 25 ?
                                        <span>{state.Modified_By?.substring(0, 25)}...</span> : state.Modified_By}</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Category Image Web
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <a target="_blank" href={state.Category_Image_Web}>
                                        <img
                                            className={classes.images}
                                            alt="Vehicle Image Path"
                                            src={state.Category_Image_Web}
                                            variant="square"
                                        />
                                    </a>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider />
                {/* Description */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A' }}
                    >
                        Description
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ paddingBottom: '1rem' }}
                    >
                        {/* dangerouslySetInnerHTML is a property that you can use on HTML elements in a React application to programmatically set their content. */}
                        <span dangerouslySetInnerHTML={{ __html: state.Description }}></span>
                    </Typography>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CategoryDetails;