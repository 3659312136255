import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    Avatar,
    FormControlLabel,
    Checkbox,
    Typography,
    TextareaAutosize
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress, Rating } from '@mui/material';
import { createBiddingRideVehicleAction, singleRideImageCreate } from 'src/store/actions/biddingridevehiclesAction';
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';

const vehicletypeOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'CAR',
        label: 'CAR'
    },
    {
        value: 'TEMPO',
        label: 'TEMPO'
    },
    {
        value: 'BUS',
        label: 'BUS'
    }
];

const carcomforttypes = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'ECONOMY',
        label: 'ECONOMY'
    },
    {
        value: 'STANDARD',
        label: 'STANDARD'
    },
    {
        value: 'PREMIUM',
        label: 'PREMIUM'
    },
    {
        value: 'LUXURY',
        label: 'LUXURY'
    },
];

const tempoOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'TEMPO',
        label: 'TEMPO'
    },
];

const busOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'BUS',
        label: 'BUS'
    },
];

const esicommissionType = [
    {
        value: '',
        label: '-Please Select-'
    },
    {
        value: 'A',
        label: 'Amount'
    },
    {
        value: 'P',
        label: 'Percentage'
    },
];

const statusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const useStyles = makeStyles(() => ({
    images: {
        width: "100%",
        height: 100,
        borderRadius: 5,
        marginBottom: 15
    },
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    },
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const AddBiddingVehicle = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    const isSubmitted = useSelector(state => state.biddingvehicles.biddingvehicleCreate.isLoading);
    const errorMessage = useSelector(state => state.biddingvehicles.biddingvehicleCreate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    // Richtext editor
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
    const [rhDiscription, setRhDiscription] = useState('');
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    // Multiple pic upload
    const [blobArrayPics, setBlobArrayPics] = useState([{
        key: 1, isdefault: 0, preview: null
    },
    {
        key: 2, isdefault: 0, preview: null
    },
    {
        key: 3, isdefault: 0, preview: null
    },
    {
        key: 4, isdefault: 0, preview: null
    },
    {
        key: 5, isdefault: 0, preview: null
    }]);

    const [imageError, setImageError] = useState();

    const callBackUploadImage = (status, imageUrl, key) => {
        if (status) {
            const blobArrayPicsTemp = JSON.parse(JSON.stringify(blobArrayPics));
            blobArrayPicsTemp[key - 1].preview = imageUrl;
            if (key === 1) {
                blobArrayPicsTemp[key - 1].isdefault = 1;
            }
            setBlobArrayPics(blobArrayPicsTemp);
        }
        else {
            setImageError("Unable to upload image please try again.");
        }
    };

    const handleImage = (e, key) => {
        setImageError("");
        dispatch(
            singleRideImageCreate(new Blob([e.target.files[0]]), callBackUploadImage, key)
        );
    };

    function handleDefaultChange(status, key) {
        const blobArrayPicsTemp = JSON.parse(JSON.stringify(blobArrayPics));
        for (let i = 0; i < blobArrayPicsTemp.length; i++) {
            if (status && key === blobArrayPicsTemp[i].key) {
                blobArrayPicsTemp[i].isdefault = 1;
            } else {
                blobArrayPicsTemp[i].isdefault = 0;
            }
        }
        setBlobArrayPics(blobArrayPicsTemp);
    };

    function handleRemoveImage(e, key) {
        const blobArrayPicsTemp = JSON.parse(JSON.stringify(blobArrayPics));
        blobArrayPicsTemp[key - 1].blob = null;
        blobArrayPicsTemp[key - 1].preview = null;
        for (let i = key; i < 5; i++) {
            let a = blobArrayPicsTemp[i - 1];
            let b = blobArrayPicsTemp[i];
            b.key = i;
            a.key = i + 1;
            blobArrayPicsTemp[i - 1] = b;
            blobArrayPicsTemp[i] = a;
        }
        setBlobArrayPics(blobArrayPicsTemp);
    };

  //Images array data
  let ImagesData = [];
  blobArrayPics.filter(item => { return !(!item.preview) }).map(item => {
    ImagesData.push({
        "Vehicle_Image": item.preview,
        "Is_Default": item.isdefault,
        "Image_Sort": item.key
    });
  })

    return (
        <Formik
            initialValues={{
                Vehicle_Type: '',
                Vehicle_Comfort_Type: '',
                Seating_Capacity: '',
                Esi_Commission_Type: '',
                Esi_Commission: '',
                Meepaisa_Review_For_Vehicle: '',
                Meepaisa_Rating_For_Vehicle: '',
                Description: '',
                Status: '',
                Created_By: loginEmail,
            }}
            validationSchema={
                Yup.object().shape({
                    Vehicle_Type: Yup.string().required('Vehicle Type is required.'),
                    Vehicle_Comfort_Type: Yup.string().required('Vehicle Comfort Type is required.'),
                    Seating_Capacity: Yup.number().min(0, "Seating Capacity should not be less than 0.").required('Seating Capacity is required.'),
                    Esi_Commission_Type: Yup.string().required('ESI Commission Type is required.'),
                    Meepaisa_Review_For_Vehicle: Yup.string().required('Meepaisa Review for Vehicle is required.'),
                    Esi_Commission: Yup.number().when("Esi_Commission_Type", (Esi_Commission_Type, schema) => {
                        if (Esi_Commission_Type === "A") {
                            return schema.min(1, "ESI Commission Amount must be at least 1.").required('ESI Commission Amount is required.');
                        } else if (Esi_Commission_Type === "P") {
                            return schema.min(1, "ESI Commission Percentage must be at least 1.").max(100, "ESI Commission Percentage must be less then 100.").required('ESI Commission Percentage is required.');
                        }
                    }),
                    Status: Yup.string().required('Status is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                values.Description = rhDiscription;

                if (blobArrayPics.filter(item => { return !(!item.preview) }).length <= 0) {
                    submitTest = false;
                    setImageError("Image is required.");
                }

                if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
                    submitTest = false;
                    errors.Description = "Description is required.";
                }
                else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
                    submitTest = false;
                    errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
                }
                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    formValues.Images = ImagesData
                    dispatch(createBiddingRideVehicleAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Vehicle_Type && errors.Vehicle_Type)}
                                                fullWidth
                                                helperText={touched.Vehicle_Type && errors.Vehicle_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Vehicle Type"
                                                name="Vehicle_Type"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Vehicle_Type}
                                                variant="outlined"
                                            >
                                                {vehicletypeOption?.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Vehicle_Comfort_Type && errors.Vehicle_Comfort_Type)}
                                                fullWidth
                                                helperText={touched.Vehicle_Comfort_Type && errors.Vehicle_Comfort_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Vehicle Comfort Type"
                                                name="Vehicle_Comfort_Type"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Vehicle_Comfort_Type}
                                                variant="outlined"
                                            >
                                                {values.Vehicle_Type === "TEMPO" ?
                                                    tempoOption?.map(option => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))
                                                    :
                                                    values.Vehicle_Type === "BUS" ?
                                                        busOption?.map(option => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))
                                                        :
                                                        carcomforttypes?.map(option => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))
                                                }
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Seating_Capacity && errors.Seating_Capacity)}
                                                fullWidth
                                                helperText={touched.Seating_Capacity && errors.Seating_Capacity}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Seating Capacity"
                                                name="Seating_Capacity"
                                                value={values.Seating_Capacity}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Esi_Commission_Type && errors.Esi_Commission_Type)}
                                                fullWidth
                                                helperText={touched.Esi_Commission_Type && errors.Esi_Commission_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ESI Commission Type"
                                                name="Esi_Commission_Type"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Esi_Commission_Type}
                                                variant="outlined"
                                            >
                                                {esicommissionType?.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Esi_Commission && errors.Esi_Commission)}
                                                fullWidth
                                                disabled={values.Esi_Commission_Type !== "" ? false : true}
                                                helperText={touched.Esi_Commission && errors.Esi_Commission}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label={"ESI Commission " + (values.Esi_Commission_Type === "A" ? "Amount ₹" : values.Esi_Commission_Type === "P" ? "Percentage %" : "")}
                                                name="Esi_Commission"
                                                value={values.Esi_Commission}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <Box className={classes.description}>
                                                Description
                                                <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                                            </Box>
                                            <span style={{ color: "red" }}>
                                                {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                                            </span>
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            Meepaisa Review for Vehicle
                                            <TextareaAutosize
                                                value={values.Meepaisa_Review_For_Vehicle}
                                                onChange={handleChange}
                                                name="Meepaisa_Review_For_Vehicle"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Meepaisa Review for Vehicle"
                                            >
                                            </TextareaAutosize>
                                            <span style={{ color: "red" }}>
                                                {((touched.Meepaisa_Review_For_Vehicle && errors.Meepaisa_Review_For_Vehicle))}
                                            </span>
                                        </Grid>

                                        {/* Meepaisa_Rating_For_Vehicle  */}
                                        <Grid item md={4} xs={4}>
                                            <Box>Meepaisa Rating for Vehicle</Box>
                                        </Grid>
                                        <Grid item md={2} xs={4}>
                                            <Rating
                                                name="Meepaisa_Rating_For_Vehicle"
                                                onChange={(event, newValue) => {
                                                    values.Meepaisa_Rating_For_Vehicle = newValue;
                                                    setFieldValue(newValue);
                                                }}
                                                value={values.Meepaisa_Rating_For_Vehicle}
                                                size="medium"
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>

                                {/* upload photo */}
                                <Grid item md={4} xs={12}>
                                    {blobArrayPics.filter(item => {
                                        if (item.key === 1) {
                                            return true;
                                        } else {
                                            return blobArrayPics[item.key - 2].preview !== null;
                                        }
                                    }).map(option => (
                                        <Card style={{ marginBottom: "5px", marginTop: "5px" }} key={"image-card" + option.key}>
                                            <CardContent>
                                                <Box display="flex" justifyContent="center">
                                                    {option.preview ? (
                                                        <Avatar
                                                            className={classes.images}
                                                            alt="Brand Upload"
                                                            src={option.preview}
                                                            variant="square"
                                                        />
                                                    ) : (
                                                        <>
                                                            <Avatar
                                                                className={classes.images}
                                                                alt="Brand Upload"
                                                                src="/static/images/image-placeholder.png"
                                                                variant="square"
                                                            />
                                                        </>
                                                    )}
                                                </Box>

                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                >
                                                    {option.preview ? <> <FormControlLabel
                                                        key={"imageUpload" + option.key}
                                                        disabled={option.isdefault === 1 ? true : false}
                                                        control={
                                                            <Checkbox
                                                                onBlur={handleBlur}
                                                                checked={option.isdefault === 1 ? true : false}
                                                                onChange={(e, status) => handleDefaultChange(status, option.key)}
                                                                name="Is_Default"
                                                                color="primary"
                                                            />
                                                        }
                                                        label={<Typography style={{ fontSize: "0.875rem" }}>Is Default</Typography>}
                                                    /> <Button
                                                        style={{ fontSize: '10', color: ' #425A70', textTransform: 'none' }}
                                                        onClick={(e) => handleRemoveImage(e, option.key)}
                                                        disabled={option.isdefault === 1 ? true : false}
                                                    >
                                                            Remove Picture
                                                        </Button> </> : <input
                                                        style={{ display: 'block', width: '80%' }}
                                                        id={"upload-photo" + option.key}
                                                        name="image"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => handleImage(e, option.key)}
                                                    />}
                                                    <br />
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    ))}
                                    <span style={{ color: "red" }}>
                                        {blobArrayPics.filter(item => { return !(!item.preview) }).length <= 0 && imageError}
                                    </span>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddBiddingVehicle.propTypes = {
    className: PropTypes.string
};

export default AddBiddingVehicle;