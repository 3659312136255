import {
  CATEGORY_CREATE_REQUEST,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_CREATE_FAIL,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_FAIL,

  STORETYPE_GET_REQUEST,
  STORETYPE_GET_SUCCESS,
  STORETYPE_GET_FAIL
} from '../actions/categoryAction'

const initialState = {
  categoryCreate: { category: {}, error: '', isLoading: false },
  categoryUpdate: { category: {}, error: '', isLoading: false },
  storetypes: { all: [], error: '', isLoading: false },
};
export default function (state = initialState, action) {
  switch (action.type) {
    case CATEGORY_CREATE_REQUEST:
      return { ...state, categoryCreate: { category: {}, error: '', isLoading: true } };
    case CATEGORY_CREATE_SUCCESS:
      return { ...state, categoryCreate: { category: action.payload, error: '', isLoading: false } };
    case CATEGORY_CREATE_FAIL:
      return { ...state, categoryCreate: { category: {}, error: action.payload, isLoading: false } };

    case CATEGORY_UPDATE_REQUEST:
      return { ...state, categoryUpdate: { category: {}, error: '', isLoading: true } };
    case CATEGORY_UPDATE_SUCCESS:
      return { ...state, categoryUpdate: { category: action.payload, error: '', isLoading: false } };
    case CATEGORY_UPDATE_FAIL:
      return { ...state, categoryUpdate: { category: {}, error: action.payload, isLoading: false } };

    case STORETYPE_GET_REQUEST:
      return { ...state, storetypes: { all: state.storetypes.all, error: '', isLoading: true } };
    case STORETYPE_GET_SUCCESS:
      return { ...state, storetypes: { all: action.payload, error: '', isLoading: false } };
    case STORETYPE_GET_FAIL:
      return { ...state, storetypes: { all: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}