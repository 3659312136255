import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createCouponAction,
  uploadCouponImage,
  getAllPartners,
  getAllBrands
} from './../../store/actions/couponAction';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import {
  getAllCategories
} from './../../store/actions/dealAction';
import { CircularProgress } from '@mui/material';

const partnerTypeOption = [
  {
    value: 'Online',
    label: 'Online Partner'
  },
  {
    value: 'Physical',
    label: 'Physical Partner'
  }
];

const createdTypes = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'BOGO',
    label: 'BOGO'
  },
  {
    value: 'Multi-buys',
    label: 'Multi-buys'
  },
  {
    value: 'Multi-save',
    label: 'Multi-save'
  },
  {
    value: 'Percentage discounts',
    label: 'Percentage discounts'
  },
  {
    value: 'Try before you buy',
    label: 'Try before you buy'
  }
];

const discountType = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'A',
    label: 'Amount'
  },
  {
    value: 'P',
    label: 'Percentage'
  },
];

const esiCashbackTypeOption = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'A',
    label: 'Amount'
  },
  {
    value: 'P',
    label: 'Percentage'
  },
  {
    value: 'R',
    label: 'Reward Points'
  },
];

const statusOption = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const CashbacklabelOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'UPTO',
    label: 'UPTO'
  },
  {
    value: 'FLAT',
    label: 'FLAT'
  }
];

const DiscountlabelOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'UPTO',
    label: 'UPTO'
  },
  {
    value: 'FLAT',
    label: 'FLAT'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddCoupon = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  // componentDidMount
  useEffect(() => {
    dispatch(getAllPartners());
    dispatch(getAllBrands());
    dispatch(getAllCategories());
  }, []);

  const [errorMessage, setErrorMessage] = useState({ Coupon_Code: { key: '', message: '' }, Coupon_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.coupon.couponCreate.isLoading);
  const partners = useSelector(state => state.coupon.couponPartners.partners);
  const brands = useSelector(state => state.coupon.couponBrands.brands);
  const categories = useSelector(state => state.deals.Categories.categories);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [rhDiscription, setRhDiscription] = useState('');
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // img upload
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadCouponImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };
  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        Partner_Type: '',
        Code: '',
        Name: '',
        Category_Id: '',
        Brand_Id: '',
        Partner_Details_Id: '',
        Description: '',
        Image_Path: imgUrl,
        Discount_Type: '',
        Discount_Amount: 0,
        ESI_Cashback_Type: '',
        ESI_Cashback_Amount: 0,
        Coupon_Max_Count: 0,
        Valid_From_Date: '',
        Valid_Through_Date: '',
        Verified: false,
        Exclusive: false,
        Whats_Hot: false,
        Extra_Cashback: false,
        Is_Top_Coupon: false,
        Is_Upcoming: false,
        Created_By: loginEmail,
        Type: '',
        Web_Redirect_Url: '',
        Mobile_Redirect_Url: '',
        Status: '',
        Cashback_Label: '',
        Discount_Label: '',
        Today_Recommendations: false,
        Trending_Today: false,
        Is_Influencer: false,
        Coupon_Pending_Count: 0,
        User_Limit: 0,
      }}
      validationSchema={Yup.object().shape({
        Partner_Type: Yup.string().required('Partner Type is required.'),
        Partner_Details_Id: Yup.string().required('Partner Name is required.'),
        Code: Yup.string().matches(/^[A-Za-z0-9]*$/, 'Please provide valid Coupon Code.').min(4, "Coupon Code must be at least 4 characters.").notOneOf([errorMessage.Coupon_Code.key, null], errorMessage.Coupon_Code.message).required('Coupon Code is required.'),
        Name: Yup.string().min(2, "Coupon Name must be at least 2 characters.").notOneOf([errorMessage.Coupon_Name.key, null], errorMessage.Coupon_Name.message).required('Coupon Name is required.'),
        Discount_Type: Yup.string().test(
          'oneOfRequired',
          'Discount Type / ESI Cashback Type is required.',
          function (item) {
            return (this.parent.Discount_Type || this.parent.ESI_Cashback_Type)
          }
        ),
        Discount_Amount: Yup.number().when("Discount_Type", (Discount_Type, schema) => {
          if (Discount_Type === "A") {
            return schema.min(1, "Discount Amount must be at least 1.").required('Discount Amount is required.');
          } else if (Discount_Type === "P") {
            return schema.min(1, "Discount Percentage must be at least 1.").max(100, "Discount Percentage must be less then 100.").required('Discount Percentage is required.');
          }
        }),
        ESI_Cashback_Type: Yup.string().test(
          'oneOfRequired',
          'Discount Type / ESI Cashback Type is required.',
          function (item) {
            return (this.parent.Discount_Type || this.parent.ESI_Cashback_Type)
          }
        ),
        ESI_Cashback_Amount: Yup.number().when("ESI_Cashback_Type", (ESI_Cashback_Type, schema) => {
          if (ESI_Cashback_Type === "A") {
            return schema.min(1, "ESI Cashback Amount must be at least 1.").required('ESI Cashback Amount is required.');
          } else if (ESI_Cashback_Type === "P") {
            return schema.min(1, "ESI Cashback Percentage must be at least 1.").max(100, "ESI Cashback Percentage must be less than 100.").required('ESI Cashback Percentage is required.');
          } else if (ESI_Cashback_Type === "R") {
            return schema.min(1, "ESI Cashback Reward Points must be at least 1.").max(100, "ESI Cashback Reward Points must be less than 100.").required('ESI Cashback Reward Points are required.');
          }
        }),
        Coupon_Max_Count: Yup.number().positive('Coupon Max Count must be a positive number.')
          .required('Coupon Max Count is required.')
          .min(Yup.ref('Coupon_Pending_Count'), 'Coupon Max Count must be greater than Coupon Pending Count'),
        Coupon_Pending_Count: Yup.number().positive('Coupon Pending Count must be a positive number.')
          .required('Coupon Pending Count is required.'),
        Type: Yup.string().required('Type is required.'),
        User_Limit: Yup.number().positive('User Limit must be a positive number.').min(1, "User Limit must be at least 1.").required('User Limit is required.'),
        Valid_From_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require From Date greater than or equal to today.").required("From Date is required."),
        Valid_Through_Date: Yup.date().when("Valid_From_Date", (Valid_From_Date, schema) => Valid_From_Date && schema.min(Valid_From_Date, "To date can't be before From date.")).required("To Date is required."),
        Web_Redirect_Url: Yup.string().when("Partner_Type", (Partner_Type, schema) => Partner_Type === "Online" ? schema.url('Please provide valid URL.').required("Web Redirection URL is required.") : schema.url('Please provide valid URL.')),
        Mobile_Redirect_Url: Yup.string().when("Partner_Type", (Partner_Type, schema) => Partner_Type === "Online" ? schema.url('Please provide valid URL.').required("Mobile Redirect URL is required.") : schema.url('Please provide valid URL.')),
        Status: Yup.string().required('Status is required.'),
        Discount_Label: Yup.string().when("Discount_Amount", (Discount_Amount, schema) => {
          if (Discount_Amount !== "") {
            return schema.required('Discount Label is required.');
          }
        }),
        Cashback_Label: Yup.string().when("ESI_Cashback_Amount", (ESI_Cashback_Amount, schema) => {
          if (ESI_Cashback_Amount !== "") {
            return schema.required('ESI Cashback Label is required.');
          }
        }),
      })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        if (values.Category_Id === "" && values.Brand_Id === "") {
          submitTest = false;
          errors.Category_Id = "Category / Brand Name is required.";
          errors.Brand_Id = "Category / Brand  Name is required.";
        }
        values.Image_Path = imgUrl;
        values.Description = rhDiscription;
        if (!values.Image_Path) {
          submitTest = false;
          errors.Image_Path = "Image is required.";
        }
        if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          if (values.Verified) {
            formValues.Verified = 1;
          } else {
            formValues.Verified = 0;
          }
          if (values.Exclusive) {
            formValues.Exclusive = 1;
          } else {
            formValues.Exclusive = 0;
          }
          if (values.Whats_Hot) {
            formValues.Whats_Hot = 1;
          } else {
            formValues.Whats_Hot = 0;
          }
          if (values.Is_Top_Coupon) {
            formValues.Is_Top_Coupon = 1;
          } else {
            formValues.Is_Top_Coupon = 0;
          }
          if (values.Is_Upcoming) {
            formValues.Is_Upcoming = 1;
          } else {
            formValues.Is_Upcoming = 0;
          }
          if (values.Extra_Cashback) {
            formValues.Extra_Cashback = 1;
          } else {
            formValues.Extra_Cashback = 0;
          }
          if (values.Trending_Today) {
            formValues.Trending_Today = 1;
          } else {
            formValues.Trending_Today = 0;
          }
          if (values.Today_Recommendations) {
            formValues.Today_Recommendations = 1;
          } else {
            formValues.Today_Recommendations = 0;
          }
          if (values.Is_Influencer) {
            formValues.Is_Influencer = 1;
          } else {
            formValues.Is_Influencer = 0;
          }
          if (values.Discount_Type === "") {
            formValues.Discount_Amount = 0;
          }
          if (values.ESI_Cashback_Type === "") {
            formValues.ESI_Cashback_Amount = 0;
          }
          dispatch(createCouponAction(formValues, navigate, setErrorMessage, setErrors));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      {/* patner Type */}
                      <TextField
                        error={Boolean(touched.Partner_Type && errors.Partner_Type)}
                        fullWidth
                        helperText={touched.Partner_Type && errors.Partner_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Partner Type"
                        InputLabelProps={{ shrink: true }}
                        name="Partner_Type"
                        select
                        SelectProps={{ native: true }}
                        value={values.Partner_Type}
                        variant="outlined"
                      ><option key="" value="">-Please Select-</option>
                        {partnerTypeOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      {/* patner name */}
                      <TextField
                        error={Boolean(touched.Partner_Details_Id && errors.Partner_Details_Id)}
                        fullWidth
                        helperText={touched.Partner_Details_Id && errors.Partner_Details_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Partner Name"
                        InputLabelProps={{ shrink: true }}
                        name="Partner_Details_Id"
                        select
                        SelectProps={{ native: true }}
                        value={values.Partner_Details_Id}
                        variant="outlined"
                      ><option key="" value="">-Please Select-</option>
                        {partners?.filter(option => values.Partner_Type === option.Type
                        )?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                          <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                            {option.Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {/* Category name */}
                      <TextField
                        error={Boolean(touched.Category_Id && errors.Category_Id)}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Category Name"
                        InputLabelProps={{ shrink: true }}
                        name="Category_Id"
                        select
                        SelectProps={{ native: true }}
                        value={values.Category_Id}
                        variant="outlined"
                      ><option key="" value="">-Please Select-</option>
                        {categories?.sort((a, b) => a.Category_Name?.localeCompare(b.Category_Name))?.map(option => (
                          <option key={option.Category_Id} value={option.Category_Id}>
                            {option.Category_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Brand Name */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Brand_Id && errors.Brand_Id)}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Brand Name"
                        name="Brand_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Brand_Id}
                        variant="outlined"
                      >
                        <option key="" value="">-Please Select-</option>
                        {brands?.sort((a, b) => a.Brand_Name?.localeCompare(b.Brand_Name))?.map(option => (
                          <option key={option.Brand_Id} value={option.Brand_Id}>
                            {option.Brand_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {((touched.Brand_Id || touched.Category_Id) && errors.Brand_Id) &&
                      <Grid item xs={12}>
                        <ErrorMessage name="Brand_Id">
                          {msg => <div style={{ color: 'red', marginTop: "-10px", textAlign: "center" }}>{msg}</div>}
                        </ErrorMessage>
                      </Grid>
                    }

                    {/* coupon code */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Code && errors.Code)}
                        fullWidth
                        helperText={touched.Code && errors.Code}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Coupon Code"
                        name="Code"
                        value={values.Code}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {/* coupon name */}
                      <TextField
                        error={Boolean(touched.Name && errors.Name)}
                        fullWidth
                        helperText={touched.Name && errors.Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Coupon Name"
                        name="Name"
                        value={values.Name}
                        variant="outlined"
                      />
                    </Grid>

                    {/*  discount Type */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Discount_Type && errors.Discount_Type)}
                        fullWidth
                        helperText={touched.Discount_Type && errors.Discount_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Discount Type"
                        name="Discount_Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Discount_Type}
                        variant="outlined"
                      >
                        {discountType.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Coupon Discount Amt/ % */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Discount_Amount && errors.Discount_Amount)}
                        fullWidth
                        disabled={values.Discount_Type !== "" ? false : true}
                        helperText={touched.Discount_Amount && errors.Discount_Amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        label={"Discount " + (values.Discount_Type === "A" ? "Amount ₹" : values.Discount_Type === "P" ? "Percentage %" : values.Discount_Type === "R" ? "Reward Points" : "")}
                        name="Discount_Amount"
                        value={values.Discount_Amount}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Discount Label input field */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Discount_Label && errors.Discount_Label)}
                        fullWidth
                        helperText={touched.Discount_Label && errors.Discount_Label}
                        disabled={values.Discount_Amount !== "" ? false : true}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Discount Label"
                        name="Discount_Label"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        value={values.Discount_Label}
                        variant="outlined"
                      >
                        {DiscountlabelOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* ESI Cashback Type */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.ESI_Cashback_Type && errors.ESI_Cashback_Type)}
                        fullWidth
                        helperText={touched.ESI_Cashback_Type && errors.ESI_Cashback_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="ESI Cashback Type"
                        name="ESI_Cashback_Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.ESI_Cashback_Type}
                        variant="outlined"
                      >
                        {esiCashbackTypeOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* ESI Cashback AMT/% */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.ESI_Cashback_Amount && errors.ESI_Cashback_Amount)}
                        fullWidth
                        disabled={values.ESI_Cashback_Type !== "" ? false : true}
                        helperText={touched.ESI_Cashback_Amount && errors.ESI_Cashback_Amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        label={"ESI Cashback " + (values.ESI_Cashback_Type === "A" ? "Amount ₹" : values.ESI_Cashback_Type === "P" ? "Percentage %" : values.ESI_Cashback_Type === "R" ? "Reward Points" : "")}
                        name="ESI_Cashback_Amount"
                        value={values.ESI_Cashback_Amount}
                        variant="outlined"
                      />
                    </Grid>

                    {/* ESI Cashback Label input field */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Cashback_Label && errors.Cashback_Label)}
                        fullWidth
                        helperText={touched.Cashback_Label && errors.Cashback_Label}
                        disabled={values.ESI_Cashback_Amount !== "" ? false : true}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="ESI Cashback Label"
                        name="Cashback_Label"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        value={values.Cashback_Label}
                        variant="outlined"
                      >
                        {CashbacklabelOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Coupon Max Count  */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Coupon_Max_Count && errors.Coupon_Max_Count)}
                        fullWidth
                        helperText={touched.Coupon_Max_Count && errors.Coupon_Max_Count}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        label="Coupon Max Count "
                        name="Coupon_Max_Count"
                        value={values.Coupon_Max_Count}
                        variant="outlined"
                      />
                    </Grid>

                    {/* type */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Type && errors.Type)}
                        fullWidth
                        helperText={touched.Type && errors.Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Type"
                        name="Type"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        value={values.Type}
                        variant="outlined"
                      >
                        {createdTypes.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/*  valid from date */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Valid_From_Date && errors.Valid_From_Date)}
                        fullWidth
                        helperText={touched.Valid_From_Date && errors.Valid_From_Date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="From Date"
                        type="date"
                        name="Valid_From_Date"
                        defaultValue={values.Valid_From_Date}
                        variant="outlined"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {/* valid through date/ % */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Valid_Through_Date && errors.Valid_Through_Date)}
                        fullWidth
                        helperText={touched.Valid_Through_Date && errors.Valid_Through_Date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="To Date"
                        type="date"
                        name="Valid_Through_Date"
                        defaultValue={values.Valid_Through_Date}
                        variant="outlined"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {/* Discription  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                      </span>
                    </Grid>

                    {/* Extra Cash Back */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Extra_Cashback}
                            onChange={handleChange}
                            name="Extra_Cashback"
                            color="primary"
                          />
                        }
                        label="Extra Cashback"
                      />
                    </Grid>

                    {/* Varified */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Verified}
                            onChange={handleChange}
                            name="Verified"
                            color="primary"
                          />
                        }
                        label="Verified"
                      />
                    </Grid>
                    {/* Exclusive */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Exclusive}
                            onChange={handleChange}
                            name="Exclusive"
                            color="primary"
                          />
                        }
                        label="Exclusive"
                      />
                    </Grid>

                    {/* Whats_Hot */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Whats_Hot}
                            onChange={handleChange}
                            name="Whats_Hot"
                            color="primary"
                          />
                        }
                        label="Whats Hot"
                      />
                    </Grid>
                    {/* Is_Top_Coupon */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Top_Coupon}
                            onChange={handleChange}
                            name="Is_Top_Coupon"
                            color="primary"
                          />
                        }
                        label="Is Top Coupon"
                      />
                    </Grid>
                    {/* Is_Upcoming */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Upcoming}
                            onChange={handleChange}
                            name="Is_Upcoming"
                            color="primary"
                          />
                        }
                        label="Is Upcoming"
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Trending_Today}
                            onChange={handleChange}
                            name="Trending_Today"
                            color="primary"
                          />
                        }
                        label="Trending Today"
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Today_Recommendations}
                            onChange={handleChange}
                            name="Today_Recommendations"
                            color="primary"
                          />
                        }
                        label="Recommendations"
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Influencer}
                            onChange={handleChange}
                            name="Is_Influencer"
                            color="primary"
                          />
                        }
                        label="Is Influencer"
                      />
                    </Grid>

                    {/* Web_Redirect_Url */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Web_Redirect_Url && errors.Web_Redirect_Url)}
                        fullWidth
                        helperText={touched.Web_Redirect_Url && errors.Web_Redirect_Url}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Web Redirect URL"
                        name="Web_Redirect_Url"
                        value={values.Web_Redirect_Url}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Mobile_Redirect_Url */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Mobile_Redirect_Url && errors.Mobile_Redirect_Url)}
                        fullWidth
                        helperText={touched.Mobile_Redirect_Url && errors.Mobile_Redirect_Url}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Mobile Redirect URL"
                        name="Mobile_Redirect_Url"
                        value={values.Mobile_Redirect_Url}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Coupon Pending Count  */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Coupon_Pending_Count && errors.Coupon_Pending_Count)}
                        fullWidth
                        helperText={touched.Coupon_Pending_Count && errors.Coupon_Pending_Count}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        label="Coupon Pending Count"
                        name="Coupon_Pending_Count"
                        value={values.Coupon_Pending_Count}
                        variant="outlined"
                      />
                    </Grid>
                    {/* User_Limit  */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.User_Limit && errors.User_Limit)}
                        fullWidth
                        helperText={touched.User_Limit && errors.User_Limit}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        label="User Limit"
                        name="User_Limit"
                        value={values.User_Limit}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* upload photo */}
                <Grid item md={4} xs={12}>
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Brand Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                  <span style={{ color: "red" }}>
                    {!imgUrl && touched.Image_Path && errors.Image_Path}
                  </span>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited || uploadPic}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddCoupon.propTypes = {
  className: PropTypes.string
};

export default AddCoupon;