import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createAdsAction,
  uploadAdsImage,
} from './../../store/actions/adsAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddPageAd = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState({ Ads_Title: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.pagead.pageadCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [rhDiscription, setRhDiscription] = useState('');
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // img upload
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);

  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadAdsImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        Ads_Title: '',
        Ads_Description: '',
        Ads_From_Date: '',
        Ads_To_Date: '',
        Ads_Image: imgUrl,
        Ads_Link: '',
        Ads_Status: '',
        Created_By: loginEmail,

      }}
      validationSchema={
        Yup.object().shape({
          Ads_Title: Yup.string().min(5, "Ads Title must be atleast 5 characters.").notOneOf([errorMessage.Ads_Title.key, null], errorMessage.Ads_Title.message).required('Ads Title is required.'),
          Ads_From_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Please provide Ads From Date greater than or equal to today.").required("Ads From Date is required."),
          Ads_To_Date: Yup.date().when("Ads_From_Date", (Ads_From_Date, schema) => Ads_From_Date && schema.min(Ads_From_Date, "Ads To Date can't be before Ads From Date.")).required("Ads To Date is required."),
          Ads_Link: Yup.string().max(255, "Please provide Ads Link less than or equal to 255 characters.").matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
            'Please provide valid Ads Link.'
          ).required('Ads Link is required.'),
          Ads_Status: Yup.string().required('Status is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};

        values.Ads_Image = imgUrl;
        values.Ads_Description = rhDiscription;
        if (!values.Ads_Image) {
          errors.Ads_Image = "Image is required.";
          submitTest = false;
        }
        if (!values.Ads_Description || values.Ads_Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Ads_Description = "Description is required.";
        }
        else if (values.Ads_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Ads_Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
        setErrors(errors);

        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(createAdsAction(formValues, navigate, setErrorMessage, setErrors));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    {/* Ads_Title */}
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Ads_Title && errors.Ads_Title)}
                        fullWidth
                        helperText={touched.Ads_Title && errors.Ads_Title}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ads Title"
                        name="Ads_Title"
                        value={values.Ads_Title}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Description  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Ads_Description && errors.Ads_Description}
                      </span>
                    </Grid>

                    {/*  Ads_From_Date */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Ads_From_Date && errors.Ads_From_Date)}
                        fullWidth
                        helperText={touched.Ads_From_Date && errors.Ads_From_Date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ads From Date"
                        type="date"
                        name="Ads_From_Date"
                        defaultValue={values.Ads_From_Date}
                        variant="outlined"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    {/* Ads_To_Date */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Ads_To_Date && errors.Ads_To_Date)}
                        fullWidth
                        helperText={touched.Ads_To_Date && errors.Ads_To_Date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ads To Date"
                        type="date"
                        name="Ads_To_Date"
                        defaultValue={values.Ads_To_Date}
                        variant="outlined"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    {/* Ads_Link */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Ads_Link && errors.Ads_Link)}
                        fullWidth
                        helperText={touched.Ads_Link && errors.Ads_Link}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ads Link"
                        name="Ads_Link"
                        value={values.Ads_Link}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Ads_Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Ads_Status && errors.Ads_Status)}
                        fullWidth
                        helperText={touched.Ads_Status && errors.Ads_Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Ads_Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Ads_Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>

                {/* upload photo */}
                <Grid item md={4} xs={12}>
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Ad Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Ad Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>

                    </CardContent>
                  </Card>
                  <span style={{ color: "red" }}>
                    {!imgUrl && touched.Ads_Image && errors.Ads_Image}
                  </span>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited || uploadPic}
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#27B6CC' }}

                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddPageAd.propTypes = {
  className: PropTypes.string
};

export default AddPageAd;