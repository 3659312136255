import {
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    Button,
    Box,
    Avatar
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
    AddBannertypeAction,
    BanneruploadImagepath,
    getservicesbyid,
    getsubservicesbyid,
    getvarientsbyid
} from 'src/store/actions/servicebannersAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';


const useStyles = makeStyles(() => ({
    images: {
        width: '100%',
        height: 100,
        borderRadius: 5,
        marginBottom: 15
    },
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const statusOption = [
    {
        value: '',
        label: '-Please Select-'
    },
    {
        value: 'ACTIVE',
        label: 'ACTIVE'
    },
    {
        value: 'IN-ACTIVE',
        label: 'IN-ACTIVE'
    }
];

const BannerTypes = [
    {
        value: 'Service',
        label: 'Service'
    },
    {
        value: 'Sub Service',
        label: 'Sub Service'
    },
    {
        value: 'Variant',
        label: 'Variant'
    },

];


const AddBannerService = ({ className, ...rest }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [errorMessage, setErrorMessage] = useState({ Title: { key: '', message: '' }, global: { key: '', message: '' } });
    const isSubmited = useSelector(state => state.banner.bannerCreate.isLoading);
    const createdBy = useSelector(state => state.auth.user.Email_Id);

    const { ServiceCategory } = useSelector(state => state.servicebanner.Servicecategories);
        const serviceSubcategories = useSelector(state => state.servicebanner?.Servicesubcategories || {});
        const { ServiceSubCategory = [], error = '', isLoading = false } = serviceSubcategories;
        // const { ServiceVarient} = useSelector(state => state.servicebanner.Servicevarients);
        const { ServiceVarient = [] } = useSelector(state => state.servicebanner?.Servicevarients || {});
       console.log(ServiceVarient,'1234567')
     
        useEffect(() => {
            dispatch(getservicesbyid());
            dispatch(getsubservicesbyid());
            dispatch(getvarientsbyid());
        }, [dispatch]);

    // Richtext editor
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
    const [rhDiscription, setRhDiscription] = useState('');
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    /* ====================================
                   Validations
      ====================================*/

    const Form_Validation = Yup.object().shape({
        Title: Yup.string().matches(/^[a-zA-Z][\sa-zA-Z]*/, "Please remove special characters & white spaces at the beginning for Banner Title.").min(3, "Banner Title requires atleast 3 characters.").notOneOf([errorMessage.Title.key, null], errorMessage.Title.message).required("Banner Title is required."),
        Offer_Id: Yup.string().when("Offer_Type", (Offer_Type, schema) => {
            return Offer_Type ? schema.required('Banner Offer By ' + Offer_Type + ' is required.') : schema.required('Banner Offer By is required.')
        }),
        Offer_Type: Yup.string().required('Banner Offer Type is required.'),
        Status: Yup.string().required('Status is required.'),
        Banner_From_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Required From Date greater than or equal to today.").required("From Date is required."),
        Banner_To_Date: Yup.date().min(Yup.ref('Banner_From_Date'), "To date can't be before From Date.").required("To Date is required."),
        Banner_Priority_Sequence: Yup.number().min(1, "Banner Priority Sequence must be at least 1.").required('Banner Priority Sequence is required.'),
    })

    /* ====================================
        InitialValues
       ====================================*/

    const values = {
        Title: '',
        Offer_Type: '',
        Offer_Id: '',
        Description: '',
        Image: '',
        Status: '',
        Banner_From_Date: '',
        Banner_To_Date: '',
        Created_By: '',
        Banner_Priority_Sequence: '',
    }

    /*====================================
           Image Upload API
    ====================================*/
    const [image, setImage] = useState({ preview: '', raw: '' });
    const [imgUrl, setImgUrl] = useState('');
    const [uploadPic, setUploadPic] = useState(false);

    const callBackUploadImgae = data => {
        if (data) {
            setImgUrl(data.File_URL);
        }
        setUploadPic(false);
    };

    const handleImage = e => {
        setUploadPic(true);
        e.preventDefault();
        setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0]
        });
        const data = new FormData();
        data.append('file', e.target.files[0]);
        dispatch(
            BanneruploadImagepath(new Blob([e.target.files[0]]), callBackUploadImgae)
        );
    };

    const handleRemoveImage = e => {
        setImgUrl('');
        setImage({
            preview: '',
            raw: ''
        });
    };

    const onSubmit = (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        let submitTest = true;
        const errors = {};
        values.Image = imgUrl;
        values.Description = rhDiscription;
        if (!values.Image) {
            errors.Image = "Image is required.";
            submitTest = false;
        } if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
            submitTest = false;
            errors.Description = "Description is required.";
        }
        else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
            submitTest = false;
            errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
        setErrors(errors);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            formValues.Created_By = createdBy;
            console.log(formValues)
            dispatch(AddBannertypeAction(formValues, navigate, setErrorMessage, setErrors));
        }
    }

    return (
        <Formik initialValues={{ ...values }} validationSchema={Form_Validation} onSubmit={onSubmit}>
            {({
                errors,
                handleBlur,
                handleChange,
                values,
                touched,
                setFieldValue
            }) => (
                <Form>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                error={Boolean(touched.Title && errors.Title)}
                                                label="Title"
                                                name="Title"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.Title}
                                                variant="outlined"
                                                helperText={<ErrorMessage name="Title" />}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Offer_Type && errors.Offer_Type)}
                                                fullWidth
                                                helperText={touched.Offer_Type && errors.Offer_Type}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setFieldValue("Offer_Id", "");
                                                }}
                                                label="Banner Offer Type"
                                                name="Offer_Type"
                                                select
                                                SelectProps={{ native: true }}
                                                InputLabelProps={{ shrink: true }}
                                                value={values.Offer_Type}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {BannerTypes.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Offer_Id && errors.Offer_Id)}
                                                fullWidth
                                                helperText={touched.Offer_Id && errors.Offer_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Offer Name"
                                                name="Offer_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Offer_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {values.Offer_Type === "Service" && ServiceCategory?.sort((a, b) => a.Service_Name?.localeCompare(b.Service_Name))?.map(option => (
                                                    <option key={option.Service_Id} value={option.Service_Id}>
                                                        {option.Service_Name}
                                                    </option>
                                                ))}
                                                {values.Offer_Type === "Sub Service" && ServiceSubCategory?.sort((a, b) => a.Ss_Name?.localeCompare(b.Ss_Name))?.map(option => (
                                                    <option key={option.Ss_Id} value={option.Ss_Id}>
                                                        {option.Ss_Name}
                                                    </option>
                                                ))}
                                                {values.Offer_Type === "Variant" && ServiceVarient?.sort((a, b) => a.Sv_Name?.localeCompare(b.Sv_Name))?.map(option => (
                                                    <option key={option.Sv_Id} value={option.Sv_Id}>
                                                        {option.Sv_Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
 
                                        <Grid item md={12} xs={12}>
                                            <Box className={classes.description}>
                                                Description
                                                <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                                            </Box>
                                            <span style={{ color: "red" }}>
                                                {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                                            </span>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                error={Boolean(touched.Banner_From_Date && errors.Banner_From_Date)}
                                                type="datetimelocal"
                                                label="Banner From Date"
                                                name="Banner_From_Date"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.Banner_From_Date}
                                                variant="outlined"
                                                helperText={<ErrorMessage name="Banner_From_Date" />}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Field as={TextField}
                                                fullWidth
                                                error={Boolean(touched.Banner_To_Date && errors.Banner_To_Date)}
                                                type="datetimelocal"
                                                label="Banner To Date"
                                                name="Banner_To_Date"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.Banner_To_Date}
                                                variant="outlined"
                                                helperText={<ErrorMessage name="Banner_To_Date" />}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Banner_Priority_Sequence && errors.Banner_Priority_Sequence)}
                                                fullWidth
                                                helperText={touched.Banner_Priority_Sequence && errors.Banner_Priority_Sequence}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Banner Priority Sequence"
                                                name="Banner_Priority_Sequence"
                                                value={values.Banner_Priority_Sequence}
                                                variant="outlined"
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.Status && errors.Status)}
                                                label="Status"
                                                name="Status"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                                helperText={<ErrorMessage name="Status" />}
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Card>
                                        <CardContent>
                                            <Box display="flex" justifyContent="center">
                                                {image.preview ? (
                                                    <Avatar
                                                        className={classes.images}
                                                        alt="Brand Upload"
                                                        src={image.preview}
                                                        variant="square"
                                                    />
                                                ) : (
                                                    <>
                                                        <Avatar
                                                            className={classes.images}
                                                            alt="Brand Upload"
                                                            src="/static/images/image-placeholder.png"
                                                            variant="square"
                                                        />
                                                    </>
                                                )}
                                            </Box>

                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            >
                                                {image.preview ? <Button
                                                    style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                                                    onClick={handleRemoveImage}
                                                >
                                                    Remove Picture
                                                </Button> : <input
                                                    style={{ display: 'block', width: '80%' }}
                                                    id="upload-photo"
                                                    name="image"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImage}
                                                />}
                                                <br />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                    <span style={{ color: "red" }}>
                                        {!imgUrl && touched.Banner_Image && errors.Banner_Image}
                                    </span>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage.global.message}</span>
                        </Box>
                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button disabled={isSubmited || uploadPic}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained" >
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </Form>
            )}
        </Formik>
    );
};

AddBannerService.propTypes = {
    className: PropTypes.string
};

export default AddBannerService;






// import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import {
//     Card,
//     CardContent,
//     Grid,
//     TextField,
//     makeStyles,
//     Button,
//     Box,
//     Avatar,
//     CircularProgress
// } from '@material-ui/core';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import * as Yup from 'yup';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';
// import draftToHtml from 'draftjs-to-html';
// import { EditorState, convertToRaw } from 'draft-js';
// import {
//     AddBannertypeAction,
//     BanneruploadImagepath,
//     getservicesbyid,
//     getsubservicesbyid,
//     getvarientsbyid
// } from 'src/store/actions/servicebannersAction';
// import { ConnectedFocusError } from 'focus-formik-error';
 
// // Styles
// const useStyles = makeStyles(() => ({
//     images: {
//         width: '100%',
//         height: 100,
//         borderRadius: 5,
//         marginBottom: 15
//     },
//     description: {
//         width: '100%',
//         height: '20rem',
//         padding: '1rem',
//         overflow: 'auto',
//         border: '1px solid grey'
//     }
// }));
 
// // Options
// const statusOption = [
//     { value: '', label: '-Please Select-' },
//     { value: 'ACTIVE', label: 'ACTIVE' },
//     { value: 'IN-ACTIVE', label: 'IN-ACTIVE' }
// ];
 
// const BannerTypes = [
//     { value: 'Service', label: 'Service' },
//     { value: 'Sub Service', label: 'Sub Service' },
//     { value: 'Variant', label: 'Variant' }
// ];
 
// // Component
// const AddBannerService = ({ className, ...rest }) => {
//     const classes = useStyles();
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
 
//     const [editorState, setEditorState] = useState(EditorState.createEmpty());
//     const [rhDiscription, setRhDiscription] = useState('');
//     const [image, setImage] = useState({ preview: '', raw: '' });
//     const [imgUrl, setImgUrl] = useState('');
//     const [uploadPic, setUploadPic] = useState(false);
//     const [errorMessage, setErrorMessage] = useState({ Title: { key: '', message: '' }, global: { key: '', message: '' } });
 
//     // Redux State
//     const isSubmited = useSelector(state => state.banner.bannerCreate.isLoading);
//     const createdBy = useSelector(state => state.auth.user.Email_Id);
   
//     const { ServiceCategory } = useSelector(state => state.servicebanner.Servicecategories);
//     const serviceSubcategories = useSelector(state => state.servicebanner?.Servicesubcategories || {});
//     const { ServiceSubCategory = [], error = '', isLoading = false } = serviceSubcategories;
//     // const { ServiceVarient} = useSelector(state => state.servicebanner.Servicevarients);
//     const { ServiceVarient = [] } = useSelector(state => state.servicebanner?.Servicevarients || {});
//    console.log(ServiceVarient)
 
//     useEffect(() => {
//         dispatch(getservicesbyid());
//         dispatch(getsubservicesbyid());
//         dispatch(getvarientsbyid());
//     }, [dispatch]);
 
//     const onEditorStateChange = (editorState) => {
//         setEditorState(editorState);
//         setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
//     };
 
//     // Validation Schema
//     const Form_Validation = Yup.object().shape({
//         Title: Yup.string().matches(/^[a-zA-Z][\sa-zA-Z]*/, "Please remove special characters & white spaces at the beginning for Banner Title.")
//             .min(3, "Banner Title requires at least 3 characters.")
//             .notOneOf([errorMessage.Title.key, null], errorMessage.Title.message)
//             .required("Banner Title is required."),
//         Offer_Id: Yup.string().when("Offer_Type", {
//             is: val => !!val,
//             then: Yup.string().required('Banner Offer By is required.'),
//         }),
//         Offer_Type: Yup.string().required('Banner Offer Type is required.'),
//         Status: Yup.string().required('Status is required.'),
//         Banner_From_Date: Yup.date().min(new Date(), "Required From Date greater than or equal to today.").required("From Date is required."),
//         Banner_To_Date: Yup.date().min(Yup.ref('Banner_From_Date'), "To date can't be before From Date.").required("To Date is required."),
//         Banner_Priority_Sequence: Yup.number().min(1, "Banner Priority Sequence must be at least 1.").required('Banner Priority Sequence is required.'),
//     });
 
//     const values = {
//         Title: '',
//         Offer_Type: '',
//         Offer_Id: '',
//         Description: '',
//         Image: '',
//         Status: '',
//         Banner_From_Date: '',
//         Banner_To_Date: '',
//         Created_By: '',
//         Banner_Priority_Sequence: '',
//     };
 
//     // Handle Image Upload
//     const handleImage = (e) => {
//         setUploadPic(true);
//         const file = e.target.files[0];
//         setImage({
//             preview: URL.createObjectURL(file),
//             raw: file
//         });
//         const data = new FormData();
//         data.append('file', file);
//         console.log(data)
//         dispatch(BanneruploadImagepath(data, (response) => {
//             if (response) {
//                 setImgUrl(response.File_URL);
//             }
//             setUploadPic(false);
//         }));
//     };
 
//     const handleRemoveImage = () => {
//         setImgUrl('');
//         setImage({
//             preview: '',
//             raw: ''
//         });
//     };
 
//     const onSubmit = (values, { setSubmitting, setErrors }) => {
//         setSubmitting(false);
//         let submitTest = true;
//         const errors = {};
//         values.Image = imgUrl;
//         values.Description = rhDiscription;
 
//         if (!values.Image) {
//             errors.Image = "Image is required.";
//             submitTest = false;
//         }
//         if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
//             submitTest = false;
//             errors.Description = "Description is required.";
//         } else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < 20) {
//             submitTest = false;
//             errors.Description = "Please provide at least 20 characters.";
//         }
//         setErrors(errors);
//         if (submitTest) {
//             const formValues = { ...values, Created_By: createdBy };
//             dispatch(AddBannertypeAction(formValues, navigate, setErrorMessage, setErrors));
//         }
//     };
 
//     return (
//         <Formik initialValues={values} validationSchema={Form_Validation} onSubmit={onSubmit}>
//             {({
//                 errors,
//                 handleBlur,
//                 handleChange,
//                 values,
//                 touched,
//                 setFieldValue
//             }) => (
//                 <Form>
//                     <ConnectedFocusError />
//                     <Card>
//                         <CardContent>
//                             <Grid container spacing={2}>
//                                 <Grid item md={8} xs={12}>
//                                     <Grid container spacing={3}>
//                                         <Grid item xs={12}>
//                                             <Field
//                                                 as={TextField}
//                                                 fullWidth
//                                                 error={Boolean(touched.Title && errors.Title)}
//                                                 label="Title"
//                                                 name="Title"
//                                                 onChange={handleChange}
//                                                 onBlur={handleBlur}
//                                                 value={values.Title}
//                                                 variant="outlined"
//                                                 helperText={<ErrorMessage name="Title" />}
//                                             />
//                                         </Grid>
//                                         <Grid item md={6} xs={12}>
//                                             <TextField
//                                                 error={Boolean(touched.Offer_Type && errors.Offer_Type)}
//                                                 fullWidth
//                                                 helperText={touched.Offer_Type && errors.Offer_Type}
//                                                 onBlur={handleBlur}
//                                                 onChange={(e) => {
//                                                     handleChange(e);
//                                                     setFieldValue("Offer_Id", "");
//                                                 }}
//                                                 label="Banner Offer Type"
//                                                 name="Offer_Type"
//                                                 select
//                                                 SelectProps={{ native: true }}
//                                                 InputLabelProps={{ shrink: true }}
//                                                 value={values.Offer_Type}
//                                                 variant="outlined"
//                                             >
//                                                 <option key="" value="">--Please Select--</option>
//                                                 {BannerTypes.map(option => (
//                                                     <option key={option.value} value={option.value}>
//                                                         {option.label}
//                                                     </option>
//                                                 ))}
//                                             </TextField>
//                                         </Grid>
//                                         <Grid item md={6} xs={12}>
//                                             <TextField
//                                                 error={Boolean(touched.Offer_Id && errors.Offer_Id)}
//                                                 fullWidth
//                                                 helperText={touched.Offer_Id && errors.Offer_Id}
//                                                 onBlur={handleBlur}
//                                                 onChange={handleChange}
//                                                 label="Offer Name"
//                                                 name="Offer_Id"
//                                                 select
//                                                 InputLabelProps={{ shrink: true }}
//                                                 SelectProps={{ native: true }}
//                                                 value={values.Offer_Id}
//                                                 variant="outlined"
//                                             >
//                                                 <option key="" value="">--Please Select--</option>
//                                                 {values.Offer_Type === "Service" && ServiceCategory?.sort((a, b) => a.Service_Name?.localeCompare(b.Service_Name))?.map(option => (
//                                                     <option key={option.Service_Id} value={option.Service_Id}>
//                                                         {option.Service_Name}
//                                                     </option>
//                                                 ))}
//                                                 {values.Offer_Type === "Sub Service" && ServiceSubCategory?.sort((a, b) => a.Ss_Name?.localeCompare(b.Ss_Name))?.map(option => (
//                                                     <option key={option.Ss_Id} value={option.Ss_Id}>
//                                                         {option.Ss_Name}
//                                                     </option>
//                                                 ))}
//                                                 {values.Offer_Type === "Variant" && ServiceVarient?.sort((a, b) => a.Sv_Name?.localeCompare(b.Sv_Name))?.map(option => (
//                                                     <option key={option.Sv_Id} value={option.Sv_Id}>
//                                                         {option.Sv_Name}
//                                                     </option>
//                                                 ))}
//                                             </TextField>
//                                         </Grid>
 
//                                         <Grid item md={12} xs={12}>
//                                             <Box className={classes.description}>
//                                                 Description
//                                                 <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
//                                             </Box>
//                                             <span style={{ color: "red" }}>
//                                                 {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < 20) && touched.Description && errors.Description}
//                                             </span>
//                                         </Grid>
//                                         <Grid item md={6} xs={12}>
//                                             <Field
//                                                 as={TextField}
//                                                 fullWidth
//                                                 error={Boolean(touched.Banner_From_Date && errors.Banner_From_Date)}
//                                                 type="date"
//                                                 label="Banner From Date"
//                                                 name="Banner_From_Date"
//                                                 InputLabelProps={{ shrink: true }}
//                                                 onChange={handleChange}
//                                                 onBlur={handleBlur}
//                                                 value={values.Banner_From_Date}
//                                                 variant="outlined"
//                                                 helperText={<ErrorMessage name="Banner_From_Date" />}
//                                             />
//                                         </Grid>
//                                         <Grid item md={6} xs={12}>
//                                             <Field
//                                                 as={TextField}
//                                                 fullWidth
//                                                 error={Boolean(touched.Banner_To_Date && errors.Banner_To_Date)}
//                                                 type="date"
//                                                 label="Banner To Date"
//                                                 name="Banner_To_Date"
//                                                 InputLabelProps={{ shrink: true }}
//                                                 onChange={handleChange}
//                                                 onBlur={handleBlur}
//                                                 value={values.Banner_To_Date}
//                                                 variant="outlined"
//                                                 helperText={<ErrorMessage name="Banner_To_Date" />}
//                                             />
//                                         </Grid>
//                                         <Grid item md={6} xs={12}>
//                                             <TextField
//                                                 error={Boolean(touched.Banner_Priority_Sequence && errors.Banner_Priority_Sequence)}
//                                                 fullWidth
//                                                 helperText={touched.Banner_Priority_Sequence && errors.Banner_Priority_Sequence}
//                                                 onBlur={handleBlur}
//                                                 onChange={handleChange}
//                                                 label="Banner Priority Sequence"
//                                                 name="Banner_Priority_Sequence"
//                                                 value={values.Banner_Priority_Sequence}
//                                                 variant="outlined"
//                                                 type="number"
//                                             />
//                                         </Grid>
//                                         <Grid item md={6} xs={12}>
//                                             <TextField
//                                                 fullWidth
//                                                 error={Boolean(touched.Status && errors.Status)}
//                                                 label="Status"
//                                                 name="Status"
//                                                 onChange={handleChange}
//                                                 onBlur={handleBlur}
//                                                 select
//                                                 InputLabelProps={{ shrink: true }}
//                                                 SelectProps={{ native: true }}
//                                                 value={values.Status}
//                                                 variant="outlined"
//                                                 helperText={<ErrorMessage name="Status" />}
//                                             >
//                                                 {statusOption.map(option => (
//                                                     <option key={option.value} value={option.value}>
//                                                         {option.label}
//                                                     </option>
//                                                 ))}
//                                             </TextField>
//                                         </Grid>
//                                     </Grid>
//                                 </Grid>
//                                 <Grid item md={4} xs={12}>
//                                     <Card>
//                                         <CardContent>
//                                             <Box display="flex" justifyContent="center">
//                                                 <Avatar
//                                                     className={classes.images}
//                                                     alt="Brand Upload"
//                                                     src={image.preview || "/static/images/image-placeholder.png"}
//                                                     variant="square"
//                                                 />
//                                             </Box>
//                                             <Box display="flex" justifyContent="center" alignItems="center">
//                                                 {image.preview ? (
//                                                     <Button
//                                                         style={{ fontSize: '10px', color: '#425A70', textTransform: 'none' }}
//                                                         onClick={handleRemoveImage}
//                                                     >
//                                                         Remove Picture
//                                                     </Button>
//                                                 ) : (
//                                                     <input
//                                                         style={{ display: 'block', width: '80%' }}
//                                                         id="upload-photo"
//                                                         name="image"
//                                                         type="file"
//                                                         accept="image/*"
//                                                         onChange={handleImage}
//                                                     />
//                                                 )}
//                                             </Box>
//                                         </CardContent>
//                                     </Card>
//                                     <span style={{ color: "red" }}>
//                                         {!imgUrl && touched.Image && errors.Image}
//                                     </span>
//                                 </Grid>
//                             </Grid>
//                         </CardContent>
//                         <Box display="flex" pl={2}>
//                             <span style={{ color: "red" }}>{errorMessage.global.message}</span>
//                         </Box>
//                         <Box display="flex" p={2}>
//                             {isSubmited ? (
//                                 <CircularProgress />
//                             ) : (
//                                 <Button
//                                     disabled={isSubmited || uploadPic}
//                                     type="submit"
//                                     style={{ backgroundColor: '#27B6CC' }}
//                                     variant="contained"
//                                 >
//                                     <span style={{ color: 'white' }}>Save details</span>
//                                 </Button>
//                             )}
//                         </Box>
//                     </Card>
//                 </Form>
//             )}
//         </Formik>
//     );
// };
 
// AddBannerService.propTypes = {
//     className: PropTypes.string
// };
 
// export default AddBannerService;