import config from './config';
import { toast } from 'react-toastify';
import {  nodeServiceApi } from './commonAxios';

export const SERVICE_REQUEST_CREATE_REQUEST = "SERVICE_REQUEST_CREATE_REQUEST";
export const SERVICE_REQUEST_CREATE_SUCCESS = "SERVICE_REQUEST_CREATE_SUCCESS";
export const SERVICE_REQUEST_CREATE_FAIL = "SERVICE_REQUEST_CREATE_FAIL ";
export const SERVICE_REQUEST_UPDATE_REQUEST = "SERVICE_REQUEST_UPDATE_REQUEST";
export const SERVICE_REQUEST_UPDATE_SUCCESS = "SERVICE_REQUEST_UPDATE_SUCCESS";
export const SERVICE_REQUEST_UPDATE_FAIL = "SERVICE_REQUEST_UPDATE_FAIL ";
export const SERVICE_CATEGORY_TYPE_CREATE_REQUEST = "SERVICE_CATEGORYTYPE_CREATE_REQUEST";
export const SERVICE_CATEGORY_TYPE_CREATE_SUCCESS = "SERVICE_CATEGORY_TYPE_CREATE_SUCCESS";
export const SERVICE_CATEGORY_TYPE_CREATE_FAIL = "SERVICE_CATEGORY_TYPE_CREATE_FAIL";

/*=============================================================
                  Add Request type Action
==============================================================*/
export const AddServiceRequestAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: SERVICE_REQUEST_CREATE_REQUEST
  });
  nodeServiceApi.post(`/admin/service_request/Create`, formData)
  .then(response => {
    let successResponse = response.data;
    if (successResponse.Response_Status === "Success") {
      dispatch({
        type: SERVICE_REQUEST_CREATE_SUCCESS
      });
      toast('Service Request created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      navigate('/app/ServiceRequest-management');
    } else {
      dispatch({
        type: SERVICE_REQUEST_CREATE_FAIL,
        payload: successResponse.UI_Display_Message
      });
      toast(successResponse.UI_Display_Message+"Please change Service Request and Try Again", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    }
  })
  .catch(error => {
    dispatch({
      type:SERVICE_REQUEST_CREATE_FAIL,
      payload: "Currently server is not working. Please try again later."
    });
  })
};
/*=============================================================
                Update PARTNER_SERVICEREQUEST Action
===============================================================*/
export const EditPartnerServiceRequestAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: SERVICE_REQUEST_UPDATE_REQUEST
  });
  nodeServiceApi.put(`/admin/service_request/Update/${formData.Request_Id}`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICE_REQUEST_UPDATE_SUCCESS
        });
        toast('Status Updated Successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/ServiceRequest-management');
      } else {  
        dispatch({
          type: SERVICE_REQUEST_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
          
        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }
    })
    .catch(error => {
      dispatch({
        type: SERVICE_REQUEST_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};


export const getAllServiceCategories = () => async dispatch => {
  try {
    let formData = {
      Status: "Active",
      SearchFor: "",
      Search: "",
    };
    dispatch({
      type: SERVICE_CATEGORY_TYPE_CREATE_REQUEST
    });
    let { data } = await nodeServiceApi.post(
      `/admin/services/get/?PageNo=1&PageSize=${config.pageSize}&SortBy=Ss_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SERVICE_CATEGORY_TYPE_CREATE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SERVICE_CATEGORY_TYPE_CREATE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SERVICE_CATEGORY_TYPE_CREATE_FAIL,
      payload: err
    });
  }
};

