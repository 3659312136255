import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Avatar,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  addOnlinePartnerAction,
  addPhysicalPartnerAction,
  uploadPartnerlogoImage,
  uploadPartnerbannerImage,
  getpartnerbusinesstype,
  getbussinessubtypeAction,
} from './../../store/actions/partnerAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import Rating from '@material-ui/lab/Rating';
import PropTypes from 'prop-types';
import { ConnectedFocusError } from "focus-formik-error";
import PhoneInput from 'react-phone-input-2'
import "../../App.scss"
import ESICountry from 'src/components/country-state-city/country';
import ESICity from 'src/components/country-state-city/city';
import ESIState from 'src/components/country-state-city/state';
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const partnerTypeOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Online',
    label: 'Online'
  },
  {
    value: 'Physical',
    label: 'Physical'
  }
];

const AddresstypeOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Home',
    label: 'Home'
  },
  {
    value: 'Business',
    label: 'Business'
  },
  {
    value: 'Office',
    label: 'Office'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  },
}));

const AddPartner = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobile, setMobile] = useState();

  useEffect(() => {
    const data = {
      "Business_Type": "",
      "Records_Filter": "ALL"
    }
    dispatch(getpartnerbusinesstype(data, callBacktypeData))
  }, []);

  //data reload
  const [typedata, setTypeData] = React.useState([]);
  let BUSINESSTYPE = []
  if (typedata) {
    for (let i = 0; i < typedata?.length; i++) {
      BUSINESSTYPE.push(
        {
          label: typedata[i].BUSINESS_TYPE,
          value: typedata[i].BUSINESS_TYPE
        })
    }
  }

  const callBacktypeData = (data) => {
    setTypeData(data)
  }

  const [subtypedata, setsubtypeData] = React.useState([]);
  let BUSINESSSUBTYPE = []
  if (subtypedata) {
    for (let i = 0; i < subtypedata?.length; i++) {
      BUSINESSSUBTYPE.push(
        {
          label: subtypedata[i].BUSINESS_SUB_TYPE,
          value: subtypedata[i].BUSINESS_SUB_TYPE
        })
    }
  }

  const callBacksubtypeData = (data) => {
    setsubtypeData(data)
  }

  const setType = (value) => {
    if (value !== "") {
      let data = {
        "Business_Type": value,
        "Records_Filter": "FILTER"
      }
      dispatch(getbussinessubtypeAction(data, callBacksubtypeData))
    }
  }

  const isSubmited = useSelector(state => state.partner.partnerCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const [errorMessage, setErrorMessage] = useState({ global: { key: '', message: '' } });

  // Richtext editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [rhDiscription, setRhDiscription] = useState('');
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // Richtext editor (ESI Reviews)
  const [editorStateReview, setEditorStateReview] = useState(EditorState.createEmpty());
  const [rhDiscriptionReviewMin, setRhDiscriptionReviewMin] = useState(20);
  const [rhDiscriptionReview, setRhDiscriptionReview] = useState('');
  const onEditorStateChangeReview = (editorStateReview) => {
    setEditorStateReview(editorStateReview);
    setRhDiscriptionReview(draftToHtml(convertToRaw(editorStateReview.getCurrentContent())));
  };

  //Logo img upload
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);

  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadPartnerlogoImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  //Banner img1 upload
  const [image1, setImage1] = useState({ preview: '', raw: '' });
  const [imgUrl1, setImgUrl1] = useState('');
  const [uploadPic1, setUploadPic1] = useState(false);

  const callBackUploadImgae1 = data => {
    if (data) {
      setImgUrl1(data.File_URL);
    }
    setUploadPic1(false);
  };

  const handleImage1 = e => {
    setUploadPic1(true);
    e.preventDefault();
    setImage1({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadPartnerbannerImage(new Blob([e.target.files[0]]), callBackUploadImgae1)
    );
  };

  const handleRemoveImage1 = e => {
    setImgUrl1('');
    setImage1({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        Email_Id: '',
        Name: '',
        Website_Url: '',
        Mobile: '',
        Land_Phone: '',
        Gst: '',
        Pan: '',
        Tips: '',
        Short_Description: '',
        Description: '',
        Is_Popular: false,
        Todays_Hot: false,
        Whats_Hot: false,
        Extra_Cash_Back: false,
        Top_Stores: false,
        Esi_Review: '',
        Esi_Rating: 0,
        Esi_Commission: 0,
        Status: '',
        Type: '',
        Logo_Path: '',
        Banner_Path: '',
        Web_Redirection_Url: '',
        Mobile_Redirect_Url: '',

        Company_Name: '',
        Address_Type: '',
        Location: '',
        Google_Map_Location: '',
        Door_No: '',
        Street: '',
        City: '',
        State: '',
        Country: '',
        Zip: '',
        Land_Mark: '',
        Is_Default: false,
        Is_Active: false,
        Is_Pickup_Available: false,
        Is_Shipping_Available: false,
        Created_By: '',
        Business_Type: '',
        Business_Sub_Type: '',
        // PAN_Card_Image: '',
        // GST_Image: '',
        Estimated_Delivery_Time: '',
        Is_Email_Verified: false,
        Is_Open: false,
        Partner_Priority_Sequence: '',
        Authorised_Person_Name: '',
        Billing_Email: '',
        IS_Blocked_By_Admin: false,
      }}

      validationSchema={
        Yup.object().shape({
          Name: Yup.string().min(2, "Partner Name must be at least 2 characters.").required('Partner Name is required.'),
          Website_Url: Yup.string().when('Type', { is: "Online", then: Yup.string().required('Website URL is required.') })
            .matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
              'Please provide valid URL.'),
          Type: Yup.string().required('Partner Type is required.'),
          Mobile: Yup.string()
            .min(14, "Please provide a valid Mobile Number.")
            .required('Mobile Number is required.'),
          Land_Phone: Yup.string().min(6, "Landline number must contain at least 6 digits.").max(12, "Landline number should not be more than 12 digits."),
          Gst: Yup.string().length(15, 'GSTIN must be of 15 characters.')
            .matches("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z{1}$]",
              'Please provide valid GSTIN without any spaces and special characters.'
            ),
          Pan: Yup.string()
            .length(10, 'PAN Number must be of 10 characters.')
            .matches("[A-Z]{5}[0-9]{4}[A-Z]{1}",
              'Please provide valid PAN Number without any spaces and special characters.'
            ),
          Short_Description: Yup.string().required('Short Description is required.'),
          Tips: Yup.string().required('User Tips are required.'),
          Esi_Commission: Yup.number().min(0, "ESI Commission should not be less than 0.").required('ESI Commission is required.'),
          Status: Yup.string().required('Status is required.'),
          Mobile_Redirect_Url: Yup.string().when('Type', {
            is: "Online",
            then: Yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
              'Please provide valid URL.'
            ).required('Mobile Redirection URL is required.'),
          }),
          Web_Redirection_Url: Yup.string().when('Type', {
            is: "Online",
            then: Yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
              'Please provide valid URL.'
            ).required('Web Redirection URL is required.'),
          }),
          Door_No: Yup.string().when('Type', { is: "Physical", then: Yup.string().required('Door No is required.'), }),
          Address_Type: Yup.string().when('Type', { is: "Physical", then: Yup.string().required('Address Type is required.'), }),
          Location: Yup.string().when('Type', { is: "Physical", then: Yup.string().required('Location is required.'), }),
          Google_Map_Location: Yup.string().when('Type', {
            is: "Physical", then: Yup.string().matches(/^https?\:\/\/(www\.)?google\.[a-z]+\/maps\b/,
              'Please provide valid URL.').required('Google Map Location URL is required.'),
          }),
          Street: Yup.string().when('Type', { is: "Physical", then: Yup.string().required('Street is required.'), }),
          City: Yup.string().when('Type', { is: "Physical", then: Yup.string().required('City is required.'), }),
          State: Yup.string().when('Type', { is: "Physical", then: Yup.string().required('State is required.'), }),
          Country: Yup.string().when('Type', { is: "Physical", then: Yup.string().required('Country is required.'), }),
          Zip: Yup.string()
            .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/,
              'Please provide valid Pin Code.'
            )
            .when('Type', { is: "Physical", then: Yup.string().required('Pin Code is required.'), }),
          Land_Mark: Yup.string().when('Type', {
            is: "Physical", then: Yup.string().required('Land Mark is required.'),
          }),
          Business_Type: Yup.string().when('Type', {
            is: "Physical", then: Yup.string().required('Business Type is required.'),
          }),
          Business_Sub_Type: Yup.string().when('Type', {
            is: "Physical", then: Yup.string().required('Business Sub Type is required.'),
          }),
          Estimated_Delivery_Time: Yup.string().when('Type', {
            is: "Physical", then: Yup.string().required('Estimated Delivery Time is required.'),
          }),
          Partner_Priority_Sequence: Yup.number().when('Type', {
            is: "Physical", then: Yup.number().min(1, "Partner Priority Sequence must be at least 1.").required('Partner Priority Sequence is required.'),
          }),
          Email_Id: Yup.string().email("Email ID must be valid.").required('Email ID is required.'),
          Authorised_Person_Name: Yup.string().when('Type', {
            is: "Physical", then: Yup.string().required('Authorised Person Name is required.'),
          }),
          Billing_Email: Yup.string().when('Type', {
            is: "Physical", then: Yup.string().email("Email ID must be valid.").required('Billing Email is required.'),
          }),
          Company_Name: Yup.string().when('Type', {
            is: "Physical", then: Yup.string().required('Company Name is required.'),
          }),
        })}

      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};

        values.Logo_Path = imgUrl;
        values.Banner_Path = imgUrl1;
        values.Description = rhDiscription;
        values.Esi_Review = rhDiscriptionReview;

        if (!values.Logo_Path) {
          handleRemoveImage()
          errors.Logo_Path = "Logo Path or Logo Image is required.";
          submitTest = false;
        }

        if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
        if (!values.Esi_Review || values.Esi_Review.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Esi_Review = "ESI Reviews is required.";
        }
        else if (values.Esi_Review.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionReviewMin) {
          submitTest = false;
          errors.Esi_Review = "Please provide at least " + rhDiscriptionReviewMin + " characters.";
        }
        setErrors(errors);
        if (submitTest)
          if (1 === 1) {
            let formValues = JSON.parse(JSON.stringify(values));
            if (values.Is_Popular) {
              formValues.Is_Popular = 1;
            } else {
              formValues.Is_Popular = 0;
            }
            if (values.Is_Default) {
              formValues.Is_Default = 1;
            } else {
              formValues.Is_Default = 0;
            }
            if (values.Is_Active) {
              formValues.Is_Active = 1;
            } else {
              formValues.Is_Active = 0;
            }
            if (values.Whats_Hot) {
              formValues.Whats_Hot = 1;
            } else {
              formValues.Whats_Hot = 0;
            }
            if (values.Todays_Hot) {
              formValues.Todays_Hot = 1;
            } else {
              formValues.Todays_Hot = 0;
            }
            if (values.Top_Stores) {
              formValues.Top_Stores = 1;
            } else {
              formValues.Top_Stores = 0;
            }
            if (values.Extra_Cash_Back) {
              formValues.Extra_Cash_Back = 1;
            } else {
              formValues.Extra_Cash_Back = 0;
            }
            if (values.Is_Pickup_Available) {
              formValues.Is_Pickup_Available = 1;
            } else {
              formValues.Is_Pickup_Available = 0;
            }
            if (values.Is_Shipping_Available) {
              formValues.Is_Shipping_Available = 1;
            } else {
              formValues.Is_Shipping_Available = 0;
            }
            if (values.Is_Email_Verified) {
              formValues.Is_Email_Verified = 1;
            } else {
              formValues.Is_Email_Verified = 0;
            }
            if (values.Is_Open) {
              formValues.Is_Open = 1;
            } else {
              formValues.Is_Open = 0;
            }
            if (values.IS_Blocked_By_Admin) {
              formValues.IS_Blocked_By_Admin = 1;
            } else {
              formValues.IS_Blocked_By_Admin = 0;
            }

            if (values.Type === 'Online') {
              const formData = {
                Email_Id: values.Email_Id,
                Name: values.Name,
                Logo_Path: values.Logo_Path,
                Website_Url: values.Website_Url,
                Type: values.Type,
                Mobile: values.Mobile,
                Land_Phone: values.Land_Phone,
                Gst: values.Gst,
                Pan: values.Pan,
                Short_Description: values.Short_Description,
                Description: values.Description,
                Tips: values.Tips,
                Banner_Path: values.Banner_Path,
                Is_Popular: formValues.Is_Popular,
                Whats_Hot: formValues.Whats_Hot,
                Todays_Hot: formValues.Todays_Hot,
                Extra_Cash_Back: formValues.Extra_Cash_Back,
                Top_Stores: formValues.Top_Stores,
                Esi_Review: values.Esi_Review,
                Esi_Rating: values.Esi_Rating,
                Esi_Commission: values.Esi_Commission,
                Web_Redirection_Url: values.Web_Redirection_Url,
                Mobile_Redirect_Url: values.Mobile_Redirect_Url,
                Status: values.Status,
              };
              let formvalues = JSON.parse(JSON.stringify(formData));
              dispatch(addOnlinePartnerAction(formvalues, navigate, setErrorMessage, setErrors));

            } else {
              const formData = {
                Name: values.Name,
                Address: [
                  {
                    Address_Type: values.Address_Type,
                    Location: values.Location,
                    Google_Map_Location: values.Google_Map_Location,
                    Door_No: values.Door_No,
                    Street: values.Street,
                    City: values.City,
                    State: values.State,
                    Country: values.Country,
                    Zip: values.Zip,
                    Land_Mark: values.Land_Mark,
                    Is_Default: formValues.Is_Default,
                    Is_Active: formValues.Is_Active,
                    Is_Pickup_Available: formValues.Is_Pickup_Available,
                    Is_Shipping_Available: formValues.Is_Shipping_Available,
                  }
                ],
                Website_Url: values.Website_Url,
                Mobile_Number: values.Mobile,
                Land_Phone: values.Land_Phone,
                Email_Id: values.Email_Id,
                GST: values.Gst,
                PAN: values.Pan,
                Short_Description: values.Short_Description,
                Description: values.Description,
                User_Tips: values.Tips,
                Is_Popular: formValues.Is_Popular,
                Whats_Hot: formValues.Whats_Hot,
                Todays_Hot: formValues.Todays_Hot,
                Extra_Cashback: formValues.Extra_Cash_Back,
                Top_Stories: formValues.Top_Stores,
                Esi_Reviews: values.Esi_Review,
                Esi_Ratting: values.Esi_Rating,
                Esi_Comission: values.Esi_Commission,
                Banner_Path: values.Banner_Path,
                Logo_Path: values.Logo_Path,
                Created_By: loginEmail,
                Status: values.Status,
                Authorised_Person_Name: values.Authorised_Person_Name,
                Billing_Email: values.Billing_Email,
                Company_Name: values.Company_Name,
                Business_Type: values.Business_Type,
                Business_Sub_Type: values.Business_Sub_Type,
                Estimated_Delivery_Time: values.Estimated_Delivery_Time,
                Is_Email_Verified: formValues.Is_Email_Verified,
                Is_Open: formValues.Is_Open,
                IS_Blocked_By_Admin: formValues.IS_Blocked_By_Admin,
                Partner_Priority_Sequence: values.Partner_Priority_Sequence,
              };
              let formvalues = JSON.parse(JSON.stringify(formData));
              dispatch(addPhysicalPartnerAction(formvalues, navigate, setErrorMessage, setErrors));
            }
          }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (<form onSubmit={handleSubmit}
      >
        <ConnectedFocusError />
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={8} xs={12}>
                <Grid container spacing={3}>

                  <Grid item md={6} xs={12}>
                    {/* partner type */}
                    <TextField
                      error={Boolean(touched.Type && errors.Type)}
                      fullWidth
                      helperText={touched.Type && errors.Type}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Partner Type"
                      name="Type"
                      select
                      SelectProps={{ native: true }}
                      value={values.Type}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    >
                      {partnerTypeOption.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>

                  {/* partner name */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Name && errors.Name)}
                      fullWidth
                      helperText={touched.Name && errors.Name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Partner Name"
                      name="Name"
                      value={values.Name}
                      variant="outlined"
                    />
                  </Grid>

                  {/* Phone number */}
                  <Grid item md={6} xs={12}>
                    <PhoneInput
                      containerClass={touched.Mobile && errors.Mobile ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                      inputClass={"form-control MuiOutlinedInput-input"}
                      error={true}
                      country={"in"}
                      fullWidth
                      inputStyle={{
                        width: "100%",
                        height: "30%",
                        borderRadius: "5px"
                      }}
                      inputProps={{
                        name: "Mobile",
                      }}
                      specialLabel="Mobile Number"
                      name="Mobile"
                      placeholder="+91 XXXXX XXXXX"
                      onBlur={handleBlur}
                      onChange={(value, country, e, formattedValue) => {
                        setFieldValue("Mobile", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                        setMobile(formattedValue)
                      }}
                      value={mobile}
                    />
                    <p style={{ color: "red" }} className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Mobile && errors.Mobile}​​​​​​​​</p>
                  </Grid>

                  {/* Land_Phone */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Land_Phone && errors.Land_Phone)}
                      fullWidth
                      helperText={touched.Land_Phone && errors.Land_Phone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      label="Landline"
                      name="Land_Phone"
                      value={values.Land_Phone}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  {/* Gst% */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Gst && errors.Gst)}
                      fullWidth
                      helperText={touched.Gst && errors.Gst}
                      label="GSTIN"
                      name="Gst"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Gst}
                      variant="outlined"
                      inputProps={{ maxLength: 15 }}
                    />
                  </Grid>

                  {/* Pan */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Pan && errors.Pan)}
                      fullWidth
                      helperText={touched.Pan && errors.Pan}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="PAN Number"
                      name="Pan"
                      value={values.Pan}
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                    />
                  </Grid>

                  {/* Logo Path */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Logo_Path && errors.Logo_Path)}
                      fullWidth
                      helperText={touched.Logo_Path && errors.Logo_Path}
                      onBlur={handleBlur}
                      onChange={(data) => {
                        setImgUrl(data.target.value);
                      }}
                      label="Logo Path"
                      name="Logo_Path"
                      value={imgUrl}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  {/*Banner Path */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      onBlur={handleBlur}
                      onChange={(data) => {
                        setImgUrl1(data.target.value);
                      }}
                      label="Banner Path"
                      name="Banner_Path"
                      value={imgUrl1}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  {values.Type === 'Physical' ? (
                    <>
                      {/* author name */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Authorised_Person_Name && errors.Authorised_Person_Name)}
                          fullWidth
                          helperText={touched.Authorised_Person_Name && errors.Authorised_Person_Name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Authorised Person Name"
                          name="Authorised_Person_Name"
                          value={values.Authorised_Person_Name}
                          variant="outlined"
                        />
                      </Grid>

                      {/* {Billing Address} */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Billing_Email && errors.Billing_Email)}
                          fullWidth
                          helperText={touched.Billing_Email && errors.Billing_Email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Billing Email"
                          name="Billing_Email"
                          value={values.Billing_Email}
                          variant="outlined"
                        />
                      </Grid>

                      {/* {Company_Name} */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Company_Name && errors.Company_Name)}
                          fullWidth
                          helperText={touched.Company_Name && errors.Company_Name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Company Name"
                          name="Company_Name"
                          value={values.Company_Name}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Address_Type && errors.Address_Type)}
                          fullWidth
                          helperText={touched.Address_Type && errors.Address_Type}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          select
                          SelectProps={{ native: true }}
                          label="Address Type"
                          name="Address_Type"
                          value={values.Address_Type}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        >
                          {AddresstypeOption.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <ESICountry
                          error={Boolean(touched.Country && errors.Country)}
                          fullWidth
                          helperText={touched.Country && errors.Country}
                          placeholder="Country"
                          label="Country"
                          name="Country"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          select
                          SelectProps={{ native: true }}
                          value={values.Country}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <ESIState
                          error={Boolean(touched.State && errors.State)}
                          fullWidth
                          helperText={touched.State && errors.State}
                          label="State"
                          name="State"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.State}
                          countrycode={values.Country}
                          select
                          SelectProps={{ native: true }}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <ESICity
                          error={Boolean(touched.City && errors.City)}
                          fullWidth
                          helperText={touched.City && errors.City}
                          label="City"
                          name="City"
                          onChange={handleChange}
                          value={values.City}
                          countrycode={values.Country}
                          statecode={values.State}
                          select
                          SelectProps={{ native: true }}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Street && errors.Street)}
                          fullWidth
                          helperText={touched.Street && errors.Street}
                          label="Street"
                          name="Street"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.Street}
                          variant="outlined"
                        />
                      </Grid>

                      {/* Location */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Location && errors.Location)}
                          fullWidth
                          helperText={touched.Location && errors.Location}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Location"
                          name="Location"
                          value={values.Location}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Land_Mark && errors.Land_Mark)}
                          fullWidth
                          helperText={touched.Land_Mark && errors.Land_Mark}
                          label="Land Mark"
                          name="Land_Mark"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.Land_Mark}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Door_No && errors.Door_No)}
                          fullWidth
                          helperText={touched.Door_No && errors.Door_No}
                          label="Door No"
                          name="Door_No"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.Door_No}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Zip && errors.Zip)}
                          fullWidth
                          helperText={touched.Zip && errors.Zip}
                          label="Pin Code"
                          name="Zip"
                          type="number"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.Zip}
                          variant="outlined"
                          onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                          }}
                        />
                      </Grid>

                      {/* Google_Map_Location */}
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={Boolean(touched.Google_Map_Location && errors.Google_Map_Location)}
                          fullWidth
                          helperText={touched.Google_Map_Location && errors.Google_Map_Location}
                          onBlur={handleBlur}
                          type="url"
                          onChange={handleChange}
                          label="Google Map Location URL"
                          name="Google_Map_Location"
                          value={values.Google_Map_Location}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Business_Type && errors.Business_Type)}
                          fullWidth
                          helperText={touched.Business_Type && errors.Business_Type}
                          onBlur={handleBlur}
                          onChange={(e) => { handleChange(e); setType(e.target.value) }}
                          select
                          SelectProps={{ native: true }}
                          label="Business Type"
                          name="Business_Type"
                          value={values.Business_Type}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        >
                          <option key="" value="">--Please Select--</option>
                          {typedata?.sort((a, b) => a.BUSINESS_TYPE?.localeCompare(b.BUSINESS_TYPE))?.map(option => (
                            <option key={option.BUSINESS_TYPE} value={option.BUSINESS_TYPE}>
                              {option.BUSINESS_TYPE}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Business_Sub_Type && errors.Business_Sub_Type)}
                          fullWidth
                          helperText={touched.Business_Sub_Type && errors.Business_Sub_Type}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          select
                          SelectProps={{ native: true }}
                          label="Business Sub Type"
                          name="Business_Sub_Type"
                          value={values.Business_Sub_Type}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        >
                          <option key="" value="">--Please Select--</option>
                          {subtypedata?.sort((a, b) => a.BUSINESS_SUB_TYPE?.localeCompare(b.BUSINESS_SUB_TYPE))?.map(option => (
                            <option key={option.BUSINESS_SUB_TYPE} value={option.BUSINESS_SUB_TYPE}>
                              {option.BUSINESS_SUB_TYPE}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      {/* Estimated_Delivery_Time */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Estimated_Delivery_Time && errors.Estimated_Delivery_Time)}
                          fullWidth
                          helperText={touched.Estimated_Delivery_Time && errors.Estimated_Delivery_Time}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Estimated Delivery Time"
                          name="Estimated_Delivery_Time"
                          value={values.Estimated_Delivery_Time}
                          variant="outlined"
                        />
                      </Grid>

                      {/* Partner_Priority_Sequence */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Partner_Priority_Sequence && errors.Partner_Priority_Sequence)}
                          fullWidth
                          helperText={touched.Partner_Priority_Sequence && errors.Partner_Priority_Sequence}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Partner Priority Sequence"
                          name="Partner_Priority_Sequence"
                          value={values.Partner_Priority_Sequence}
                          variant="outlined"
                          type="number"
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Default}
                              onChange={handleChange}
                              name="Is_Default"
                              color="primary"
                            />
                          }
                          label="Is Address Default"
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Active}
                              onChange={handleChange}
                              name="Is_Active"
                              color="primary"
                            />
                          }
                          label="Is Address Active"
                        />
                      </Grid>

                      {/* Is_Pickup_Available */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Pickup_Available}
                              onChange={handleChange}
                              name="Is_Pickup_Available"
                              color="primary"
                            />
                          }
                          label="Is Pickup Available"
                        />
                      </Grid>

                      {/* Is_Shipping_Available */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Shipping_Available}
                              onChange={handleChange}
                              name="Is_Shipping_Available"
                              color="primary"
                            />
                          }
                          label="Is Shipping Available"
                        />
                      </Grid>

                      {/* Is_Email_Verified */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Email_Verified}
                              onChange={handleChange}
                              name="Is_Email_Verified"
                              color="primary"
                            />
                          }
                          label="Is Email Verified"
                        />
                      </Grid>

                      {/* Is_Open */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Open}
                              onChange={handleChange}
                              name="Is_Open"
                              color="primary"
                            />
                          }
                          label="Is Open"
                        />
                      </Grid>

                      {/* IS_Blocked_By_Admin */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.IS_Blocked_By_Admin}
                              onChange={handleChange}
                              name="IS_Blocked_By_Admin"
                              color="primary"
                            />
                          }
                          label="Is Blocked By Admin"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}></Grid>

                      {/*Email_Id*/}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Email_Id && errors.Email_Id)}
                          fullWidth
                          helperText={touched.Email_Id && errors.Email_Id}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Email ID"
                          name="Email_Id"
                          value={values.Email_Id}
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      {/* Redirection_Url */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Web_Redirection_Url && errors.Web_Redirection_Url)}
                          fullWidth
                          helperText={touched.Web_Redirection_Url && errors.Web_Redirection_Url}
                          onBlur={handleBlur}
                          type="url"
                          onChange={handleChange}
                          label="Web Redirection URL"
                          name="Web_Redirection_Url"
                          value={values.Web_Redirection_Url}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      {/* Mobile_Redirect_Url */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Mobile_Redirect_Url && errors.Mobile_Redirect_Url)}
                          fullWidth
                          helperText={touched.Mobile_Redirect_Url && errors.Mobile_Redirect_Url}
                          onBlur={handleBlur}
                          type="url"
                          onChange={handleChange}
                          label="Mobile Redirection URL"
                          name="Mobile_Redirect_Url"
                          value={values.Mobile_Redirect_Url}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      {/*Email_Id*/}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Email_Id && errors.Email_Id)}
                          fullWidth
                          helperText={touched.Email_Id && errors.Email_Id}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Email ID"
                          name="Email_Id"
                          value={values.Email_Id}
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  )}

                  {/*Esi commission*/}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Esi_Commission && errors.Esi_Commission)}
                      fullWidth
                      helperText={touched.Esi_Commission && errors.Esi_Commission}
                      onBlur={handleBlur}
                      //type="url"
                      onChange={handleChange}
                      label="ESI Commission"
                      name="Esi_Commission"
                      value={values.Esi_Commission}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>

                  {/* Short Description */}
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.Short_Description && errors.Short_Description)}
                      fullWidth
                      helperText={touched.Short_Description && errors.Short_Description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Short Description"
                      name="Short_Description"
                      value={values.Short_Description}
                      variant="outlined"
                    />
                  </Grid>

                  {/* Description  */}
                  <Grid item md={12} xs={12}>
                    <Box className={classes.description}>
                      Description
                      <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                    </Box>
                    <span style={{ color: "red" }}>
                      {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                    </span>
                  </Grid>

                  {/*Esi rating*/}
                  <Grid item md={2} xs={12}>
                    <Box>
                      ESI Rating</Box>
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <Rating
                      name="Esi_Rating"
                      onChange={(event, newValue) => {
                        values.Esi_Rating = newValue;
                        setFieldValue(newValue);
                      }}
                      value={values.Esi_Rating}
                      size="small"
                    />
                  </Grid>

                  {/* ESI Reviews  */}
                  <Grid item md={12} xs={12}>
                    <Box className={classes.description}>
                      ESI Reviews
                      <EditorContainer editorState={editorStateReview} onEditorStateChange={onEditorStateChangeReview} />
                    </Box>
                    <span style={{ color: "red" }}>
                      {(!rhDiscriptionReview || rhDiscriptionReview.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionReviewMin) && touched.Esi_Review && errors.Esi_Review}
                    </span>
                  </Grid>

                  {/* Is Popular */}
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onBlur={handleBlur}
                          checked={values.Is_Popular}
                          onChange={handleChange}
                          name="Is_Popular"
                          color="primary"
                        />
                      }
                      label="Is Popular"
                    />
                  </Grid>

                  {/* Whats Hot */}
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onBlur={handleBlur}
                          checked={values.Whats_Hot}
                          onChange={handleChange}
                          name="Whats_Hot"
                          color="primary"
                        />
                      }
                      label="Whats Hot"
                    />
                  </Grid>

                  {/* Todays Hot */}
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onBlur={handleBlur}
                          checked={values.Todays_Hot}
                          onChange={handleChange}
                          name="Todays_Hot"
                          color="primary"
                        />
                      }
                      label="Todays Hot"
                    />
                  </Grid>

                  {/* Extra Cash Back */}
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onBlur={handleBlur}
                          checked={values.Extra_Cash_Back}
                          onChange={handleChange}
                          name="Extra_Cash_Back"
                          color="primary"
                        />
                      }
                      label="Extra Cashback"
                    />
                  </Grid>

                  {/* Top_Stores */}
                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onBlur={handleBlur}
                          checked={values.Top_Stores}
                          onChange={handleChange}
                          name="Top_Stores"
                          color="primary"
                        />
                      }
                      label="Top Store"
                    />
                  </Grid>

                  {/* Tips */}
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Tips && errors.Tips)}
                      fullWidth
                      helperText={touched.Tips && errors.Tips}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="User Tips"
                      name="Tips"
                      value={values.Tips}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.Status && errors.Status)}
                      fullWidth
                      helperText={touched.Status && errors.Status}
                      placeholder="Status"
                      label="Status"
                      name="Status"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.Status}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {statusOption.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>

                  {/* Website Url */}
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.Website_Url && errors.Website_Url)}
                      fullWidth
                      helperText={touched.Website_Url && errors.Website_Url}
                      onBlur={handleBlur}
                      type="url"
                      onChange={handleChange}
                      label="Website URL"
                      name="Website_Url"
                      value={values.Website_Url}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                </Grid>
              </Grid>

              {/* logo upload */}
              <Grid item md={4} xs={12}>
                <Card>
                  &nbsp;Logo Image
                  <CardContent>
                    <Box display="flex" justifyContent="center" >
                      {image.preview ? (
                        <Avatar
                          className={classes.images}
                          alt="logo Upload"
                          src={image.preview}
                          variant="square"
                        />
                      ) : (
                        <>
                          <Avatar
                            className={classes.images}
                            alt="logo Upload"
                            src="/static/images/image-placeholder.png"
                            variant="square"
                          />
                        </>
                      )}
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      {image.preview ? <Button
                        style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                        onClick={handleRemoveImage}
                      >
                        Remove Picture
                      </Button> : <input
                        style={{ display: 'block', width: '80%' }}
                        id="upload-photo"
                        name="image"
                        type="file"
                        accept="image/*"
                        onChange={handleImage}
                      />}
                      <br />
                    </Box>

                  </CardContent>
                </Card>
                <br />
                {/* Banner upload */}
                <Card>
                  &nbsp;Banner Image
                  <CardContent>
                    <Box display="flex" justifyContent="center">
                      {image1.preview ? (
                        <Avatar
                          className={classes.images}
                          alt="banner Upload"
                          src={image1.preview}
                          variant="square"
                        />
                      ) : (
                        <>
                          <Avatar
                            className={classes.images}
                            alt="banner Upload"
                            src="/static/images/image-placeholder.png"
                            variant="square"
                          />
                        </>
                      )}
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      {image1.preview ? <Button
                        style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                        onClick={handleRemoveImage1}
                      >
                        Remove Picture
                      </Button> : <input
                        style={{ display: 'block', width: '80%' }}
                        id="upload-photo"
                        name="image"
                        type="file"
                        accept="image/*"
                        onChange={handleImage1}
                      />}
                      <br />
                    </Box>

                  </CardContent>
                </Card>

              </Grid>
            </Grid>
          </CardContent>
          <Box display="flex" pl={2} >
            <span style={{ color: "red" }}>{errorMessage.global.message}</span>
          </Box>

          <Box display="flex" p={2}>
            {isSubmited ?
              <CircularProgress />
              :
              <Button
                disabled={isSubmited || uploadPic || uploadPic1}
                type="submit"
                style={{ backgroundColor: '#27B6CC' }}
                variant="contained"
              >
                {/* Save details */}
                <span style={{ color: 'white' }}>Save details</span>
              </Button>
            }
          </Box>
        </Card>
      </form>
      )}
    </Formik>
  );
};

AddPartner.propTypes = {
  className: PropTypes.string
};

export default AddPartner;