import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  images: {
    width: 70,
    height: 70,
    cursor: 'pointer'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));

const headCells = [
  { id: 'Partner_Service_Id', numeric: false, disablePadding: false, label: 'Partner Service ID' },
  { id: 'Resource_Email_Id', numeric: false, disablePadding: false, label: 'Resource Email ID' },
  { id: 'Service_Name', numeric: false, disablePadding: false, label: 'Service Name' },
  { id: 'Partner_Name', numeric: true, disablePadding: false, label: 'Partner Name' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allPartnerService,
  deleteServiceHandler,
  setPagination,
  pagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  setPage,
  allPartnerServicesNoFilter,
  createSortHandler,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedTestimonialIds, setSelectedTestimonialIds] = useState([]);

  const visiblePartnerserviceHandler = serviceData => e => {
    navigate("/app/view-partnerservices", { state: serviceData });
  };
  const editPartnerServiceHandler = serviceData => e => {
    navigate('/app/edit-partnerservice', { state: serviceData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.id !== "Profile_Pic" ? (
                      <TableSortLabel style={{ fontSize: "13px", fontWeight: "bold" }}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>) : (<span style={{ fontSize: "13px", fontWeight: "bold" }} > {headCell.label}</span>)
                    }
                  </TableCell>
                ))}
                <TableCell
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  className={classes.headerColumn}> Actions &nbsp;&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allPartnerService) &&
                (allPartnerService).map(partnerservice => (
                  <TableRow
                    hover
                    key={partnerservice.Partner_Service_Id}
                    selected={
                      selectedTestimonialIds.indexOf(partnerservice.Partner_Service_Id) !== -1
                    }
                  >
                    <TableCell>{partnerservice.Partner_Service_Id}</TableCell>
                    <TableCell>
                        {partnerservice.Resource_Email_Id?.substring(0, 12)}...
                    </TableCell>
                    <TableCell>{partnerservice.Service_Name}</TableCell>
                    <TableCell>{partnerservice.Partner_Name}</TableCell>
                    <TableCell>{partnerservice.Status}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Partner_Services_Management_Write?.Value === "Partner_Services_Management_Write" && RolePermissions?.Partner_Services_Management_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Tooltip title="View Partner Service History">
                          <Button
                            onClick={visiblePartnerserviceHandler(partnerservice)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        <Button
                          onClick={editPartnerServiceHandler(partnerservice)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Partner_Services_Management_Read?.Value === "Partner_Services_Management_Read" && RolePermissions?.Partner_Services_Management_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="View Partner Service History">
                          <Button
                            onClick={visiblePartnerserviceHandler(partnerservice)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allPartnerService) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;