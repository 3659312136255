import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Box, ListItemText
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { addServiceAction, getAllBussinessSubtype, getAllUserAddress, getAllUserAddressServices, } from 'src/store/actions/servicesAction';
import { getServiceEmailUserAction } from 'src/store/actions/servicesAction';
import { CircularProgress } from '@mui/material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";

const AddUserServices = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        let data = {
            "search_by_filter": "",
            "search": "",
            "Records_Filter": "ALL"
        }
        let data1 = {
            "Records_Filter": "ALL"
        }
        dispatch(getAllBussinessSubtype(data))
        dispatch(getServiceEmailUserAction())
        dispatch(getAllUserAddress(data1, callBackUserAddrData))
    }, []);

    const isSubmited = useSelector(state => state.service.serviceCreate.isLoading);
    const errorMessage = useSelector(state => state.service.serviceCreate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const emailuserServices = useSelector(state => state.service.EmailUserServices.all)

    const [addressdata, setAddressData] = React.useState([]);
    let USERADDRESS = []
    if (addressdata) {
        for (let i = 0; i < addressdata?.length; i++) {
            USERADDRESS.push(
                {
                    label: addressdata[i].User_Address_Id,
                    value: addressdata[i].User_Address_Id,
                    CITY_ID: addressdata[i].City_Id
                })
        }
    }

    const callBackUserAddrData = (data) => {
        setAddressData(data)
    }

    const setAddrType = (value) => {
        if (value !== "") {
            let data = {
                Records_Filter: 'FILTER',
                User_Email_Id: value
            }
            dispatch(getAllUserAddress(data, callBackUserAddrData))
        }
    }

    const [addressservicedata, setAddressServiceData] = React.useState([]);
    let USERADDRESSSERVICES = []
    if (addressservicedata) {
        for (let i = 0; i < addressservicedata?.length; i++) {
            USERADDRESSSERVICES.push(
                {
                    label: addressservicedata[i].Service_Type,
                    value: addressservicedata[i].Service_Type
                })
        }
    }

    const callBackUserAddrserviceData = (data) => {
        setAddressServiceData(data)
    }

    const setAddrserviceType = (value) => {
        if (value !== "") {
            let data = {
                "search_by_filter": "",
                "search": "",
                Records_Filter: 'FILTER',
                User_Address_Id: value
            }
            dispatch(getAllUserAddressServices(data, callBackUserAddrserviceData))
        }
    }
    const [cityname, setCityname] = useState([])

    // Schedule Call
    let nDate = new Date();
    let month = String(nDate.getMonth() + 1).length > 1 ? String(nDate.getMonth() + 1) : "0" + String(nDate.getMonth() + 1);
    let date = String(nDate.getDate()).length > 1 ? String(nDate.getDate()) : "0" + String(nDate.getDate());
    const [dos, setDos] = useState(nDate.getFullYear() + "-" + month +
        "-" + date);

    return (
        <Formik
            initialValues={{
                Service_Required_Address_ID: '',
                Service_Request_Total_Time_In_Days: '',
                Service_Request_Total_Time_In_Hours: '',
                Service_Required_Date: dos,
                Service_Type: '',
                User_Email_ID: '',
                Price_Per_Day: '',
                Price_Per_Hour: '',
                Service_Price: '',
                Advance_Amount: '',
                Created_By: loginEmail,
                City_Id: '',
            }}
            validationSchema={
                Yup.object().shape({
                    User_Email_ID: Yup.string().required('User Email ID is required.'),
                    Service_Type: Yup.string().required('Service Type is required.'),
                    Service_Required_Date: Yup.string().test("date-of-service", "Service Date cannot be past date.", (value) => {
                        if (value) {
                            let date1 = new Date(value).getTime();
                            let date2 = new Date(dos).getTime();
                            if (date1 >= date2) {
                                return true;
                            }
                        }
                        return false;
                    }),
                    Service_Required_Address_ID: Yup.string().required('Service Required Address ID is required.'),
                    Service_Request_Total_Time_In_Days: Yup.number().min(0, "Service Request Total Time in Days should not be less than 0.").required('Service Request Total Time In Days is required.'),
                    Service_Request_Total_Time_In_Hours: Yup.number().min(0, "Service Request Total Time in Hours  should not be less than 0.").required('Service Request Total Time In Hours is required.'),
                    Price_Per_Day: Yup.number().min(0, "Price Per Day should not be less than 0.").required('Price Per Day is required.'),
                    Price_Per_Hour: Yup.number().min(0, "Price Per Day should not be less than 0.").required('Price Per Day is required.'),
                    Service_Price: Yup.number().min(0, "Service Price should not be less than 0.").required('Service Price is required.'),
                    Advance_Amount: Yup.number().min(0, "Advance Amount should not be less than 0.").required('Advance Amount is required.'),
                })
            }
            onSubmit={(values) => {
                if (values) {
                    let formData = JSON.parse(JSON.stringify(values));
                    dispatch(addServiceAction(formData, navigate));
                }
            }
            }
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            {/* <TextField
                                                error={Boolean(touched.User_Email_ID && errors.User_Email_ID)}
                                                fullWidth
                                                helperText={touched.User_Email_ID && errors.User_Email_ID}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setAddrType(e.target.value)
                                                }}
                                                select
                                                SelectProps={{ native: true }}
                                                label="User Email ID"
                                                name="User_Email_ID"
                                                value={values.User_Email_ID}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {emailuserServices?.sort((a, b) => a.Email_Id?.localeCompare(b.Email_Id))?.map(option => (
                                                    <option key={option.Email_Id} value={option.Email_Id}>
                                                        {option.Email_Id}
                                                    </option>
                                                ))}
                                            </TextField> */}
                                            <Autocomplete
                                                freeSolo
                                                options={emailuserServices}
                                                groupBy={(option) => option.firstLetter}
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option.Email_Id}
                                                renderInput={(params) => <TextField {...params}
                                                    error={Boolean(touched.User_Email_ID && errors.User_Email_ID)}
                                                    helperText={touched.User_Email_ID && errors.User_Email_ID}
                                                    onBlur={handleBlur}
                                                    label="User Email ID"
                                                    name="User_Email_ID"
                                                    variant="outlined"
                                                    value={values.User_Email_ID}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setAddrType(e.target.value)
                                                        dispatch(getServiceEmailUserAction(params.inputProps.value))
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill,
                                                        startAdornment: (
                                                            <React.Fragment>
                                                                <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                {params.InputProps.startAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />}
                                                onChange={(e, value) => {
                                                    setFieldValue("User_Email_ID", value?.Email_Id ? value.Email_Id : "");
                                                }}
                                            />
                                        </Grid>
                                        {/* Service_Required_Address_ID */}
                                        {/* <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Required_Address_ID && errors.Service_Required_Address_ID)}
                                                fullWidth
                                                helperText={touched.Service_Required_Address_ID && errors.Service_Required_Address_ID}
                                                onBlur={handleBlur}
                                                label="Service Required Address ID"
                                                name="Service_Required_Address_ID"
                                                // onChange={handleChange}
                                                // onChange={(e, value) => {
                                                //     setType(value?.User_Address_Id ? value?.User_Address_Id : "");
                                                //     setFieldValue("Service_Required_Address_ID", value?.User_Address_Id ? value?.User_Address_Id : "");

                                                // }}
                                                onChange={(e, value) => {
                                                    handleChange(e);
                                                    setCityname(e.target.value)
                                                    setAddrserviceType(e.target.value)
                                                    setCity(value?.Service_Required_Address_ID ? value.Service_Required_Address_ID : "");
                                                }}
                                                value={values.Service_Required_Address_ID}
                                                select
                                                SelectProps={{ native: true }}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {addressdata?.map(option => (
                                                    <option key={option?.User_Address_Id} value={option}>
                                                        {option?.Address_Type + " - " + option?.Street + " - " + option?.City + " - " + option?.State + " - " + option?.Country + " - " + option?.Zip}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid> */}
                                        <Grid item md={6} xs={12}>
                                            <Autocomplete
                                                freeSolo
                                                options={addressdata}
                                                groupBy={(option) => option?.firstLetter}
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option?.Location || ""}
                                                onChange={(e, value) => {
                                                    setAddrserviceType(value?.User_Address_Id)
                                                    setCityname(value.City_Id)
                                                    setFieldValue('City_Id', value.City_Id)
                                                    setFieldValue("Service_Required_Address_ID", value?.User_Address_Id ? value?.User_Address_Id : "");
                                                }}
                                                value={addressdata?.length > 0 ? addressdata?.filter((item) => item?.User_Address_Id === values?.Service_Required_Address_ID)[0] : ""}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                        <ListItemText
                                                            primary={option?.Location}
                                                            secondary={option?.Address_Type + " - " + option?.Street + " - " + option?.City + " - " + option?.State + " - " + option?.Country + " - " + option?.Zip}
                                                        />
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        error={Boolean(touched.Service_Required_Address_ID && errors.Service_Required_Address_ID)}
                                                        fullWidth
                                                        helperText={touched.Service_Required_Address_ID && errors.Service_Required_Address_ID}
                                                        {...params}
                                                        placeholder="Search"
                                                        variant="outlined"
                                                        label="Service Required Address ID"
                                                        name="Service_Required_Address_ID"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off', // disable autocomplete and autofill,
                                                            startAdornment: (
                                                                <React.Fragment>
                                                                    <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                    {params.InputProps.startAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />

                                        </Grid>
                                        {/* <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.City_Id && errors.City_Id)}
                                                fullWidth
                                                helperText={touched.City_Id && errors.City_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="City Name"
                                                name="City_Id"
                                                value={values.City_Id}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                select
                                                SelectProps={{ native: true }}
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {addressdata.filter(option => option.Service_Required_Address_ID === City).map(option => (
                                                    <option value={option.City_Id} key={option.City_Id}>{option.City}</option>
                                                ))}
                                            </TextField>
                                        </Grid> */}

                                        {/* <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Type && errors.Service_Type)}
                                                fullWidth
                                                helperText={touched.Service_Type && errors.Service_Type}
                                                onBlur={handleBlur}
                                                // onChange={handleChange}
                                                onChange={(e, value) => {
                                                    // setFieldValue("Service_Type", value?.Service_Type ? value.Service_Type : "");
                                                    setFieldValue("Price_Per_Day", value?.Per_Daye_Rate ? value.Per_Daye_Rate : "");
                                                    setFieldValue("Price_Per_Hour", value?.Per_Hour_Rate ? value?.Per_Hour_Rate : "");
                                                }}
                                                select
                                                SelectProps={{ native: true }}
                                                label="Service Type"
                                                name="Service_Type"
                                                value={values.Service_Type}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {genericServices?.sort((a, b) => a.Service_Type?.localeCompare(b.Service_Type))?.map(option => (
                                                    <option key={option.Service_Type} value={option.Service_Type}>
                                                        {option.Service_Type}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid> */}
                                        <Grid item md={6} xs={12}>
                                            <Autocomplete
                                                options={addressservicedata}
                                                groupBy={(option) => option?.firstLetter}
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option?.Service_Type || ""}
                                                onChange={(e, value) => {
                                                    // setType(value?.User_Address_Id ? value?.User_Address_Id : "");
                                                    setFieldValue("Service_Type", value?.Service_Type ? value.Service_Type : "");
                                                    setFieldValue("Price_Per_Day", value?.Per_Day_Rate ? value?.Per_Day_Rate : "");
                                                    setFieldValue("Price_Per_Hour", value?.Price_Per_Hour ? value?.Price_Per_Hour : "");
                                                    setFieldValue("Advance_Amount", value?.Advance_Amount ? value?.Advance_Amount : "");
                                                }}
                                                value={addressservicedata?.length > 0 ? addressservicedata?.filter((item) => item?.Service_Type === values?.Service_Type)[0] : ""}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                        <ListItemText
                                                            primary={option?.Service_Type}
                                                        />
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Search"
                                                        variant="outlined"
                                                        label="Service Type"
                                                        name="Service_Type"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off', // disable autocomplete and autofill,
                                                            startAdornment: (
                                                                <React.Fragment>
                                                                    <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                    {params.InputProps.startAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Required_Date && errors.Service_Required_Date)}
                                                fullWidth
                                                helperText={touched.Service_Required_Date && errors.Service_Required_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="datetime-local"
                                                label="Service Required Date"
                                                name="Service_Required_Date"
                                                value={values.Service_Required_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days)}
                                                fullWidth
                                                helperText={touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days}
                                                onBlur={handleBlur}
                                                // onChange={handleChange}
                                                onChange={e => {
                                                    let SP1 = Number(values.Price_Per_Day) * Number(e.target.value) + Number(values.Price_Per_Hour) * Number(values.Service_Request_Total_Time_In_Hours)
                                                    setFieldValue("Service_Request_Total_Time_In_Days", e.target.value);
                                                    setFieldValue("Service_Price", SP1);
                                                }}
                                                label="Service Request Total Time In Days"
                                                name="Service_Request_Total_Time_In_Days"
                                                value={values.Service_Request_Total_Time_In_Days}
                                                variant="outlined"
                                                type='number'
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Request_Total_Time_In_Hours && errors.Service_Request_Total_Time_In_Hours)}
                                                fullWidth
                                                helperText={touched.Service_Request_Total_Time_In_Hours && errors.Service_Request_Total_Time_In_Hours}
                                                onBlur={handleBlur}
                                                // onChange={handleChange}
                                                onChange={e => {
                                                    let SP2 = Number(values.Price_Per_Day) * Number(values.Service_Request_Total_Time_In_Days) + Number(values.Price_Per_Hour) * Number(e.target.value)
                                                    setFieldValue("Service_Request_Total_Time_In_Hours", e.target.value);
                                                    setFieldValue("Service_Price", SP2);
                                                }}
                                                label="Service Request Total Time In Hours"
                                                name="Service_Request_Total_Time_In_Hours"
                                                value={values.Service_Request_Total_Time_In_Hours}
                                                variant="outlined"
                                                type='number'
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Price_Per_Day && errors.Price_Per_Day)}
                                                fullWidth
                                                helperText={touched.Price_Per_Day && errors.Price_Per_Day}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Price Per Day"
                                                name="Price_Per_Day"
                                                disabled
                                                value={values.Price_Per_Day}
                                                variant="outlined"
                                                type='number'
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Price_Per_Hour && errors.Price_Per_Hour)}
                                                fullWidth
                                                helperText={touched.Price_Per_Hour && errors.Price_Per_Hour}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Price Per Hour"
                                                name="Price_Per_Hour"
                                                disabled
                                                value={values.Price_Per_Hour}
                                                variant="outlined"
                                                type='number'
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Advance_Amount && errors.Advance_Amount)}
                                                fullWidth
                                                helperText={touched.Advance_Amount && errors.Advance_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Amount"
                                                name="Advance_Amount"
                                                disabled
                                                value={values.Advance_Amount}
                                                variant="outlined"
                                                type='number'
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Price && errors.Service_Price)}
                                                fullWidth
                                                helperText={touched.Service_Price && errors.Service_Price}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Service Price"
                                                name="Service_Price"
                                                value={values.Service_Price}
                                                variant="outlined"
                                                type='number'
                                            >
                                            </TextField>
                                        </Grid>
                                        {/* <Box display="flex" pl={2} >
                           
                                <span style={{ color: 'green' }}>Serice Price Amount: ₹{(Number(values.Price_Per_Day) ? Number(values.Price_Per_Day) * Number(values.Service_Request_Total_Time_In_Days) : 0) + (Number(values.Price_Per_Hour) ? Number(values.Price_Per_Hour) * Number(values.Service_Request_Total_Time_In_Hours) : 0)}/-</span>
                        </Box> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>

                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddUserServices.propTypes = {
    className: PropTypes.string
};

export default AddUserServices;