import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    Avatar,
    Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { updateProductRequestAction } from 'src/store/actions/corporateproductAction';

const useStyles = makeStyles(() => ({
    images: {
        width: "100%",
        height: 200,
        borderRadius: 5,
        marginBottom: 15
    },
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    },
    card: {
        maxWidth: '40%',
        marginBottom: '1rem',
    },
}));

const EditProductRequest = ({ className, ...rest }) => {
    const location = useLocation();
    let { state } = location;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const [isDecline, setIsDecline] = useState(false);

    // img upload
    const [image, setImage] = useState({ preview: state?.Product_Refference_Image, raw: '' });

    return (
        <>
            <Card className={classes.card}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={5}>
                                    <Typography
                                        variant="h5"
                                        component="h2"
                                        gutterBottom
                                        style={{ color: '#66788A', textAlign: 'left', display: 'flex', alignItems: 'center' }}
                                    >
                                        Company Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                                    :
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography
                                        variant="h5"
                                        component="h2"
                                        gutterBottom
                                        style={{ textAlign: 'right', display: 'flex', alignItems: 'center', paddingLeft: '3rem' }}
                                    >
                                        {state?.Company_Name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography
                                        variant="h5"
                                        component="h2"
                                        gutterBottom
                                        style={{ color: '#66788A', textAlign: 'left', display: 'flex', alignItems: 'center' }}
                                    >
                                        Send by
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                                    :
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography
                                        variant="h5"
                                        component="h2"
                                        gutterBottom
                                        style={{ textAlign: 'right', display: 'flex', alignItems: 'center', paddingLeft: '3rem' }}
                                    >
                                        {state?.Created_By}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography
                                        variant="h5"
                                        component="h2"
                                        gutterBottom
                                        style={{ color: '#66788A', textAlign: 'left', display: 'flex', alignItems: 'center' }}
                                    >
                                        Date
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                                    :
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography
                                        variant="h5"
                                        component="h2"
                                        gutterBottom
                                        style={{ textAlign: 'right', display: 'flex', alignItems: 'center', paddingLeft: '3rem' }}
                                    >
                                        {state?.Created_Date}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Formik
                initialValues={{
                    Product_Request_Id: state?._id,
                    Product_Name: state?.Product_Name,
                    Company_Name: state?.Company_Name,
                    Category_Id: state?.Category_Id,
                    Category_Name: state?.Category_Name,
                    Quantity: state?.Quantity,
                    Budget: state?.Budget,
                    Product_Description: state?.Product_Description,
                    Product_Additional_Description: state?.Product_Additional_Description,
                    Product_Refference_Image: state?.Product_Refference_Image,
                    Modified_By: loginEmail,
                    Is_Approved: true,
                    Is_Declined: false,
                    Corp_Detail_Id: state.Corp_Detail_Id,
                    Company_Name: state.Company_Name,
                    Corp_User_Id: state.Corp_User_Id,
                    Full_Name: state.Full_Name,
                    Role_Id: state.Role_Id,
                    Role_Name: state.Role_Name,
                }}

                onSubmit={(values, { setSubmitting }) => {
                    values.Is_Approved = !isDecline;
                    values.Is_Declined = isDecline;
                    setSubmitting(false);
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(updateProductRequestAction(formValues, navigate));
                }}
            >
                {({
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Product Name *"
                                                    name="Product_Name"
                                                    value={values.Product_Name}
                                                    variant="outlined"
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Product Category *"
                                                    name="Category_Name"
                                                    value={values.Category_Name}
                                                    variant="outlined"
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Quantity *"
                                                    name="Quantity"
                                                    value={values.Quantity}
                                                    variant="outlined"
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Budget/Unit *"
                                                    name="Budget"
                                                    value={values.Budget}
                                                    variant="outlined"
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Product Description"
                                                    name="Product_Description"
                                                    value={values.Product_Description}
                                                    variant="outlined"
                                                    disabled
                                                    multiline
                                                    rows={5}
                                                    rowsMax={5}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Additional Comments"
                                                    name="Product_Additional_Description"
                                                    value={values.Product_Additional_Description}
                                                    variant="outlined"
                                                    disabled
                                                    multiline
                                                    rows={5}
                                                    rowsMax={5}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Card style={{
                                            border: `2px dashed blue`,
                                            textAlign: 'center',
                                            background: 'rgba(33, 150, 243, 0.08)',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            maxWidth: '516px',
                                            height: '275px',
                                            minWidth: '360px'
                                        }}>
                                            <CardContent >
                                                <Box display="flex" justifyContent="center"  >
                                                    {image.preview ? (
                                                        <Avatar
                                                            className={classes.images}
                                                            alt="Brand Upload"
                                                            src={image.preview}
                                                            variant="square"
                                                        />
                                                    ) : (
                                                        <>
                                                            <Avatar
                                                                className={classes.images}
                                                                alt="Brand Upload"
                                                                src="/static/images/image-placeholder.png"
                                                                variant="square"
                                                            />
                                                        </>
                                                    )}
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
                                        {state?.Is_Approved === false && state?.Is_Declined === false ? (
                                            <>
                                                <Button
                                                    variant="outlined"
                                                    type="submit"
                                                    style={{ marginRight: 20, color: "#2196F3", borderColor: "#2196F3" }}
                                                    onClick={() => setIsDecline(true)}
                                                >
                                                    DECLINE
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
                                                    onClick={() => setIsDecline(false)}
                                                >
                                                    APPROVE
                                                </Button>
                                            </>
                                        ) : state?.Is_Approved === true ? (
                                            <Button
                                                variant="contained"
                                                style={{ backgroundColor: "#2E7D32", color: "#ffffff" }}
                                            >
                                                APPROVED
                                            </Button>
                                        ) : state?.Is_Declined === true ? (
                                            <Button
                                                variant="contained"
                                                style={{ backgroundColor: "#D32F2F", color: "#ffffff" }}
                                            >
                                                DECLINED
                                            </Button>
                                        ) : null}
                                    </Box>
                                </Grid>

                            </CardContent>
                        </Card>
                    </form>
                )}
            </Formik>
        </>
    );
};

EditProductRequest.propTypes = {
    className: PropTypes.string
};

export default EditProductRequest;
