import {
    BIDDING_SERVICE_UPDATE_REQUEST,
    BIDDING_SERVICE_UPDATE_SUCCESS,
    BIDDING_SERVICE_UPDATE_FAIL,
    BIDDING_SERVICE_CREATE_REQUEST,
    BIDDING_SERVICE_CREATE_SUCCESS,
    BIDDING_SERVICE_CREATE_FAIL,
} from '../actions/biddingservicesAction';

const initialState = {
    biddingserviceUpdate: { biddingservices: {}, error: '', isLoading: false },
    biddingserviceCreate: { biddingservices: {}, error: '', isLoading: false },
};


export default function (state = initialState, action) {
    switch (action.type) {
        case BIDDING_SERVICE_CREATE_REQUEST:
            return { ...state, servicetypeCreate: { biddingservice: {}, error: '', isLoading: true } };
        case BIDDING_SERVICE_CREATE_SUCCESS:
            return { ...state, servicetypeCreate: { biddingservice: state.biddingserviceCreate.biddingservice, error: '', isLoading: false } };
        case BIDDING_SERVICE_CREATE_FAIL:
            return { ...state, servicetypeCreate: { biddingservice: {}, error: action.payload, isLoading: false } };
        case BIDDING_SERVICE_UPDATE_REQUEST:
            return { ...state, servicetypeUpdate: { biddingservice: {}, error: '', isLoading: true } };
        case BIDDING_SERVICE_UPDATE_SUCCESS:
            return { ...state, servicetypeUpdate: { biddingservice: state.biddingserviceUpdate.biddingservice, error: '', isLoading: false } };
        case BIDDING_SERVICE_UPDATE_FAIL:
            return { ...state, servicetypeUpdate: { biddingservice: {}, error: action.payload, isLoading: false } };
        default:
            return state;
    }
}