import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateTicketAction
} from './../../store/actions/ticketingActions';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const userType = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'User',
    label: 'User'
  },
];
const statusOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'New',
    label: 'New'
  },
  {
    value: 'In Progress',
    label: 'In Progress'
  },
  {
    value: 'Hold',
    label: 'Hold'
  },
  {
    value: 'Closed',
    label: 'Closed'
  },
];

const IssuerelatedOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Order',
    label: 'Order'
  },
  {
    value: 'Payment',
    label: 'Payment'
  },
  {
    value: 'Delivery',
    label: 'Delivery'
  },
  {
    value: 'Product',
    label: 'Product'
  },
  {
    value: 'Others',
    label: 'Others'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: 120,
    height: 120,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  },
  TextareaAutosize: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditTicket = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  let { state } = location;

  const errorMessage = useSelector(state => state.tickets.ticketUpdate.error);
  const isSubmited = useSelector(state => state.tickets.ticketUpdate.isLoading);
  const createdBy = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        Ticket_Id: state.Ticket_Id ? state.Ticket_Id : "",
        Ticket_Subject: state.Ticket_Subject ? state.Ticket_Subject : "",
        Ticket_Description: state.Ticket_Description ? state.Ticket_Description : "",
        Modified_By: createdBy,
        Ticket_User_Type: state.Ticket_User_Type ? state.Ticket_User_Type : "",
        Ticket_Status: state.Ticket_Status ? state.Ticket_Status : "",
        Attachment: state.Ticket_Attachment ? state.Ticket_Attachment : "",
        Support_Owner: createdBy,
        Ticket_Owner: state.Ticket_Owner ? state.Ticket_Owner : "",
        Issue_Related_To: state.Issue_Related_To ? state.Issue_Related_To : "",
      }}
      validationSchema={
        Yup.object().shape({
          Ticket_Subject: Yup.string().min(3, "Ticket Subject should have atleast 3 characters.").required("Ticket Subject is required."),
          Ticket_User_Type: Yup.string().required('Ticket User Type  is required.'),
          Ticket_Status: Yup.string().required('Ticket Status is required.'),
          Attachment: Yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
            'Please provide valid URL.'
          ).required('Attachment URL is required.'),
          Issue_Related_To: Yup.string().required('Issue Related To is required.'),
          Ticket_Description: Yup.string().required('Ticket Description is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        let submitTest = true;
        const errors = {};
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(updateTicketAction(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Ticket_Owner && errors.Ticket_Owner)}
                        fullWidth
                        helperText={touched.Ticket_Owner && errors.Ticket_Owner}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ticket Owner"
                        name="Ticket_Owner"
                        value={values.Ticket_Owner}
                        disabled
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Ticket_Subject && errors.Ticket_Subject)}
                        fullWidth
                        helperText={touched.Ticket_Subject && errors.Ticket_Subject}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ticket Subject"
                        name="Ticket_Subject"
                        value={values.Ticket_Subject}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Ticket_User_Type && errors.Ticket_User_Type)}
                        fullWidth
                        helperText={touched.Ticket_User_Type && errors.Ticket_User_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ticket User Type"
                        name="Ticket_User_Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Ticket_User_Type}
                        variant="outlined"
                      >
                        {userType.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Issue_Related_To && errors.Issue_Related_To)}
                        fullWidth
                        helperText={touched.Issue_Related_To && errors.Issue_Related_To}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Issue Related To"
                        name="Issue_Related_To"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Issue_Related_To}
                        variant="outlined"
                      >
                        {IssuerelatedOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={12} xs={12}>
                      Description
                      <TextareaAutosize
                        className={classes.TextareaAutosize}
                        minRows={10}
                        placeholder="Description"
                        value={values.Ticket_Description}
                        onChange={handleChange}
                        name="Ticket_Description"
                        onBlur={handleBlur}
                        variant="outlined"
                      >
                      </TextareaAutosize>
                      <span style={{ color: "red" }}>
                        {((touched.Ticket_Description && errors.Ticket_Description))}
                      </span>
                    </Grid>

                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Ticket_Status && errors.Ticket_Status)}
                        fullWidth
                        helperText={touched.Ticket_Status && errors.Ticket_Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ticket Status"
                        name="Ticket_Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Ticket_Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Online_Redirect_Url */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Attachment && errors.Attachment)}
                        fullWidth
                        helperText={touched.Attachment && errors.Attachment}
                        onBlur={handleBlur}
                        type="url"
                        onChange={handleChange}
                        label="Attachment URL"
                        name="Attachment"
                        value={values.Attachment}
                        variant="outlined"
                      />
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
}

EditTicket.propTypes = {
  className: PropTypes.string
};

export default EditTicket;