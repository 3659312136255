
import config from './config';
import { toast } from 'react-toastify';
import {  nodeGetApi,boomiAdminApi } from './commonAxios';

export const BIDDING_SERVICE_QUOTE_CREATE_REQUEST = "BIDDING_SERVICE_QUOTE_CREATE_REQUEST";
export const BIDDING_SERVICE_QUOTE_CREATE_SUCCESS = "BIDDING_SERVICE_QUOTE_CREATE_SUCCESS";
export const BIDDING_SERVICE_QUOTE_CREATE_FAIL = "BIDDING_SERVICE_QUOTE_CREATE_FAIL";
export const BIDDING_SERVICE_QUOTE_UPDATE_REQUEST = "BIDDING_SERVICE_QUOTE_UPDATE_REQUEST";
export const BIDDING_SERVICE_QUOTE_UPDATE_SUCCESS = "BIDDING_SERVICE_QUOTE_UPDATE_SUCCESS";
export const BIDDING_SERVICE_QUOTE_UPDATE_FAIL = "BIDDING_SERVICE_QUOTE_UPDATE_FAIL";

export const BIDDING_USER_SERVICE_REQUEST = "BIDDING_USER_SERVICE_REQUEST";
export const BIDDING_USER_SERVICE_SUCCESS = "BIDDING_USER_SERVICE_SUCCESS";
export const BIDDING_USER_SERVICE_FAIL = "BIDDING_USER_SERVICE_FAIL";

export const PHYSICAL_PARTNERS_REQUEST = "PHYSICAL_PARTNERS_REQUEST";
export const PHYSICAL_PARTNERS_SUCCESS = "PHYSICAL_PARTNERS_SUCCESS";
export const PHYSICAL_PARTNERS_FAIL = "PHYSICAL_PARTNERS_FAIL";

export const BIDDING_PARTNER_AVAIL_SERVICES_REQUEST = "BIDDING_PARTNER_AVAIL_SERVICES_REQUEST";
export const BIDDING_PARTNER_AVAIL_SERVICES_SUCCESS = "BIDDING_PARTNER_AVAIL_SERVICES_SUCCESS";
export const BIDDING_PARTNER_AVAIL_SERVICES_FAIL = "BIDDING_PARTNER_AVAIL_SERVICES_FAIL";


//   Add Partner Bidding Service Quotes Action
export const AddPartnerBiddingServiceQuoteAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: BIDDING_SERVICE_QUOTE_CREATE_REQUEST
  });
  boomiAdminApi.post(`/Bidding_Service_Quotes/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BIDDING_SERVICE_QUOTE_CREATE_SUCCESS
        });
        toast('Partner Bidding Service Quote added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partner-bidding-service-quotes', state = { state });
      } else {
        dispatch({
          type: BIDDING_SERVICE_QUOTE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: BIDDING_SERVICE_QUOTE_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

//   Update Partner Bidding Service Quotes Action
export const UpdatePartnerBiddingServiceQuoteAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: BIDDING_SERVICE_QUOTE_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Bidding_Service_Quotes/Update`, formData
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: BIDDING_SERVICE_QUOTE_UPDATE_SUCCESS
        });
        toast('Partner Bidding Service Quote updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partner-bidding-service-quotes', state = { state });
      } else {

        dispatch({
          type: BIDDING_SERVICE_QUOTE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: BIDDING_SERVICE_QUOTE_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

//get All Bidding User Services
export const getAllBiddingUserServices = () => async dispatch => {
  try {
    let formData = {
      Created_By: "",
      User_Service_Bidding_Id: "",
      User_Email_Id: "",
      Records_Filter: ""
    };
    dispatch({
      type: BIDDING_USER_SERVICE_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Super_Admin/User_Services_Bidding?PageNo=1&PageSize=${config.pageSize}&SortBy=USER_SERVICE_BIDDING_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BIDDING_USER_SERVICE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BIDDING_USER_SERVICE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: BIDDING_USER_SERVICE_FAIL,
      payload: err
    });
  }
};

//Get All Partner Address Get
export const getAllPartnerAddress = (formData, callBacksubtypeData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Partners/Partner_Address_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_ADDRESS_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Success_Response?.Is_Data_Exist === "0") {
        callBacksubtypeData([])
      } else {
        callBacksubtypeData(data.results)
      }
    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};

export const getAllPartnerAvailServices = (formData) => async dispatch => {
  try {
    dispatch({
      type: BIDDING_PARTNER_AVAIL_SERVICES_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Super_Admin/Partner_Bidding_Available_Services?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_BIDDING_AVAILABLE_SERVICE_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BIDDING_PARTNER_AVAIL_SERVICES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: BIDDING_PARTNER_AVAIL_SERVICES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: BIDDING_PARTNER_AVAIL_SERVICES_FAIL,
      payload: err
    });
  }
};

//Get ALL Bidding Service Quote
export const getBiddingServiceQuoteAction = (formData, callBackBiddingServiceQuote) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `${config.nodeurl}/Super_Admin/Partner_Bidding_Service_Quotes?PageNo=1&PageSize=${config.pageSize}&SortBy=Partner_Bidding_Service_Quote_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBackBiddingServiceQuote(data)
    }
  } catch (err) {
  }
};