import config from './config';
import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi, boomiAdminApi } from './commonAxios';

export const REFERANDEARN_CREATE_REQUEST = "REFERANDEARN_CREATE_REQUEST";
export const REFERANDEARN_CREATE_SUCCESS = "REFERANDEARN_CREATE_SUCCESS";
export const REFERANDEARN_CREATE_FAIL = "REFERANDEARN_CREATE_FAIL";
export const REFERANDEARN_UPDATE_REQUEST = "REFERANDEARN_UPDATE_REQUEST";
export const REFERANDEARN_UPDATE_SUCCESS = "REFERANDEARN_UPDATE_SUCCESS";
export const REFERANDEARN_UPDATE_FAIL = "REFERANDEARN_UPDATE_FAIL";

export const USER_EMAIL_REQUEST = "USER_EMAIL_REQUEST";
export const USER_EMAIL_SUCCESS = "USER_EMAIL_SUCCESS";
export const USER_EMAIL_FAIL = "USER_EMAIL_FAIL";

export const createReferandEarnAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: REFERANDEARN_CREATE_REQUEST
  });
  boomiAdminApi.post(`/Refer_Earn/Create`,
    formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: REFERANDEARN_CREATE_SUCCESS
        });
        toast('Refer&Earn added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/refer-earn');
      } else {
        dispatch({
          type: REFERANDEARN_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: REFERANDEARN_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
             update ReferandEarn Action
===============================================================*/
export const updateReferandEarnAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: REFERANDEARN_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Refer_Earn/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: REFERANDEARN_UPDATE_SUCCESS
        });
        toast('Refer&Earn updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/refer-earn');
      } else {
        dispatch({
          type: REFERANDEARN_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: REFERANDEARN_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
               getAllEmails Action
===============================================================*/
export const getAllEmails = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "ALL",
    };
    dispatch({
      type: USER_EMAIL_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/User/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=EMAIL_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: USER_EMAIL_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: USER_EMAIL_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: USER_EMAIL_FAIL,
      payload: err
    });
  }
};

// Get Refer and Earn Action
export const getSingleReferandEarn = (formData, callBackReferandEarnData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `${config.nodeurl}/Refer_Earn/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Reference_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBackReferandEarnData(data)
    }
  } catch (err) {
  }
};