import {
    MYCOMPANY_PROFILE_REQUEST,
    MYCOMPANY_PROFILE_SUCCESS,
    MYCOMPANY_PROFILE_FAIL,
    MYCOMPANY_PROFILE_GET_REQUEST,
    MYCOMPANY_PROFILE_GET_SUCCESS,
    MYCOMPANY_PROFILE_GET_FAIL,
    MYCOMPANY_PROFILE_UPDATE_REQUEST,
    MYCOMPANY_PROFILE_UPDATE_SUCCESS,
    MYCOMPANY_PROFILE_UPDATE_FAIL,


} from '../actions/mycompanyprofileAction';

const initialState = {
  myprofiles: { all: [], error: '', isLoading: false },
  myprofile: { myprofile: {}, error: '', isLoading: false },
  myprofileUpdate: { myprofile: {}, error: '', isLoading: false },

};

export default function (state = initialState, action) {
    switch (action.type) {
        case MYCOMPANY_PROFILE_REQUEST:
            return { ...state, myprofiles: { all: state.myprofiles.all, error: '', isLoading: true } };
        case MYCOMPANY_PROFILE_SUCCESS:
            return { ...state, myprofiles: { all: action.payload, error: '', isLoading: false } };
        case MYCOMPANY_PROFILE_FAIL:
            return { ...state, myprofiles: { all: [], error: action.payload, isLoading: false } };

        case MYCOMPANY_PROFILE_GET_REQUEST:
            return { ...state, myprofile: { myprofile: {}, error: '', isLoading: true } };
        case MYCOMPANY_PROFILE_GET_SUCCESS:
            return { ...state, myprofile: { myprofile: action.payload, error: '', isLoading: false } };
        case MYCOMPANY_PROFILE_GET_FAIL:
            return { ...state, myprofile: { myprofile: {}, error: action.payload, isLoading: false } };

        case MYCOMPANY_PROFILE_UPDATE_REQUEST:
            return { ...state, myprofileUpdate: { myprofile: {}, error: '', isLoading: true } };
        case MYCOMPANY_PROFILE_UPDATE_SUCCESS:
            return { ...state, myprofileUpdate: { myprofile: state.myprofileUpdate.myprofile, error: '', isLoading: false } };
        case MYCOMPANY_PROFILE_UPDATE_FAIL:
            return { ...state, myprofileUpdate: { myprofile: {}, error: action.payload, isLoading: false } };
        default:
            return state;
    }
}