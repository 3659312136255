import { toast } from 'react-toastify';
import { nodeCudApi } from './commonAxios';
import { paginationBrandUserReviewGet } from './node-actions/nodepagination';

export const USERREVIEW_CREATE_REQUEST = "USERREVIEW_CREATE_REQUEST";
export const USERREVIEW_CREATE_SUCCESS = "USERREVIEW_CREATE_SUCCESS";
export const USERREVIEW_CREATE_FAIL = "USERREVIEW_CREATE_FAIL";
export const USERREVIEW_UPDATE_REQUEST = "USERREVIEW_UPDATE_REQUEST";
export const USERREVIEW_UPDATE_SUCCESS = "USERREVIEW_UPDATE_SUCCESS";
export const USERREVIEW_UPDATE_FAIL = "USERREVIEW_UPDATE_FAIL";

/*=============================================================
                  Add Brand Review Action
 ===============================================================*/
export const addBrandReviewAction = (formData, state, navigate) => async dispatch => {
  dispatch({
    type: USERREVIEW_CREATE_REQUEST
  });
  nodeCudApi.post(`/Reviews/Brand_Reviews_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USERREVIEW_CREATE_SUCCESS
        });
        toast('User Brand Review added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/brand/user-reviews', state = { state });
      } else {
        dispatch({
          type: USERREVIEW_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USERREVIEW_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                    Update User Action
 ===============================================================*/
export const updateUserAction = (formData, state, navigate) => async dispatch => {
  dispatch({
    type: USERREVIEW_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Reviews/Brand_Reviews_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USERREVIEW_UPDATE_SUCCESS
        });
        toast('User Brand Review updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/brand/user-reviews', state = { state });
      } else {
        dispatch({
          type: USERREVIEW_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USERREVIEW_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Delete User Action 
 ===============================================================*/
export const deleteUserAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Reviews/Brand_Reviews_Delete`, formData);
    if (data) {
      toast('User review deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationBrandUserReviewGet(filters, pagination, callBackPagination));
    }
  } catch (err) { }
};