
import { toast } from 'react-toastify';
import config from './config';
import { nodeGetApi,boomiAdminApi } from './commonAxios';

export const PARTNER_VEHICLE_REQUEST = "PARTNER_VEHICLE_REQUEST";
export const PARTNER_VEHICLE_SUCCESS = "PARTNER_VEHICLE_SUCCESS";
export const PARTNER_VEHICLE_FAIL = "PARTNER_VEHICLE_FAIL";
export const PARTNER_VEHICLE_GET_REQUEST = "PARTNER_VEHICLE_GET_REQUEST";
export const PARTNER_VEHICLE_GET_SUCCESS = "PARTNER_VEHICLE_GET_SUCCESS";
export const PARTNER_VEHICLE_GET_FAIL = "PARTNER_VEHICLE_GET_FAIL";
export const PARTNER_VEHICLE_CREATE_REQUEST = "PARTNER_VEHICLE_CREATE_REQUEST";
export const PARTNER_VEHICLE_CREATE_SUCCESS = "PARTNER_VEHICLE_CREATE_SUCCESS";
export const PARTNER_VEHICLE_CREATE_FAIL = "PARTNER_VEHICLE_CREATE_FAIL";
export const PARTNER_VEHICLE_UPDATE_REQUEST = "PARTNER_VEHICLE_UPDATE_REQUEST";
export const PARTNER_VEHICLE_UPDATE_SUCCESS = "PARTNER_VEHICLE_UPDATE_SUCCESS";
export const PARTNER_VEHICLE_UPDATE_FAIL = "PARTNER_VEHICLE_UPDATE_FAIL";



/*=============================================================
                  Add Partner vehicle Action
===============================================================*/
export const createpartnervehicleAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: PARTNER_VEHICLE_CREATE_REQUEST
  });
  boomiAdminApi.post(`/Partner_Delivery_Vehicle_Details/Vehicle_Details_Create`, formData
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNER_VEHICLE_CREATE_SUCCESS
        });
        toast('Partner Vehicle Details added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });

        navigate('/app/partner-vehicle-details', state = { state });
      } else {
        dispatch({
          type: PARTNER_VEHICLE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_VEHICLE_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                  Get All Partner Vehicle Action
===============================================================*/
export const getAllpartnervehicleAction = formData => async dispatch => {
  try {
    dispatch({
      type: PARTNER_VEHICLE_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partner_Delivery_Vehicle_Details/Vehicle_Details_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Del_Vehicle_Detail_Id&SortOrder=DESC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNER_VEHICLE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNER_VEHICLE_SUCCESS,
          payload: data?.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PARTNER_VEHICLE_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                   Update Partner Vehicle Action
===============================================================*/
export const updatepartnervehicleAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: PARTNER_VEHICLE_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Partner_Delivery_Vehicle_Details/Vehicle_Details_Update`, formData
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNER_VEHICLE_UPDATE_SUCCESS
        });
        navigate('/app/partner-vehicle-details', state = { state });
        toast('Delivery Vehicle Details updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });

      } else {
        dispatch({
          type: PARTNER_VEHICLE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_VEHICLE_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

/*=============================================================
             RC Front Image Upload Action
===============================================================*/
export const uploadRCFrontImage = (
  formData,
  callBackUploadImgae,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=PartnerVehicleRCFront&fileextension=png&filetype=Images&filename=PartnerVehicleRCFront`,
      formData
    );
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) {
  }
};

/*=============================================================
              RC Back Image Upload Action
===============================================================*/
export const uploadRCBackImage = (
  formData,
  callBackUploadImgae1,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=PartnerVehicleRCBack&fileextension=png&filetype=Images&filename=PartnerVehicleRCBack`,
      formData
    );
    if (data) {
      callBackUploadImgae1(data);
    }
  } catch (err) { }
};

/*=============================================================
           Vehicle Image Upload Action
===============================================================*/
export const uploadVehicleImage = (
  formData,
  callBackUploadVehicleImage,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=PartnerVehicle&fileextension=png&filetype=Images&filename=PartnerVehicle`,
      formData
    );
    if (data) {
      callBackUploadVehicleImage(data);
    }
  } catch (err) { }
};

//Get Delivery Agent Action
export const getPartnerVehicleDataAction = (formData, callBackPartnerVehicleData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Partner_Delivery_Vehicle_Details/Vehicle_Details_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Del_Vehicle_Detail_Id&SortOrder=DESC`,
      formData
    );
    if (data) {
      callBackPartnerVehicleData(data)
    }
  } catch (err) {
  }
};