import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createDeliveryAgentAction } from 'src/store/actions/deliveryAgentAction';
import { CircularProgress } from '@mui/material';
import PhoneInput from 'react-phone-input-2'

const GenderOptions = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Male',
    label: 'Male'
  },
  {
    value: 'Female',
    label: 'Female'
  }
];

const DeliveryOptions = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'DELIVERY',
    label: 'DELIVERY'
  },
  {
    value: 'DELIVERY_ESI',
    label: 'DELIVERY_ESI'
  }
];

const delipersontypeOptions = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'DELIVERY',
    label: 'DELIVERY'
  },
  {
    value: 'RIDER',
    label: 'RIDER'
  }
];

const AddDeliveryPersonDetails = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState({ Del_user_email_id: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.delivery.deliveryagentCreate.isLoading);
  const createdBy = useSelector(state => state.auth.user.Email_Id);
  const [mobile, setMobile] = useState();

  const values = {
    DOB: "",
    Del_user_email_id: "",
    Del_User_Type: "",
    First_name: "",
    Gender: "",
    Is_Approved: false,
    Is_active: false,
    Is_email_verified: false,
    Last_name: "",
    Full_name: "",
    Mobile: "",
    Marketing_Agreed: false,
    Role: "",
    T_and_C_Agreed: false,
    IS_Blocked_By_Admin: false,
    Created_By: createdBy
  }

  const Form_Validation = Yup.object().shape({
    First_name: Yup.string().required('First Name is required.'),
    Last_name: Yup.string().required('Last Name is required.'),
    Full_name: Yup.string().required('Full Name is required.'),
    Del_User_Type: Yup.string().required('Delivery User Type is required.'),
    Mobile: Yup.string().min(14, "Please provide a valid Mobile Number.").required('Mobile Number is required.'),
    Del_user_email_id: Yup.string().email("Please provide a valid Delivery Agent Email ID. ").notOneOf([errorMessage.Del_user_email_id.key, null], errorMessage.Del_user_email_id.message).required('Delivery Agent Email ID is required.'),
    Gender: Yup.string().required('Gender is required.'),
    Role: Yup.string().required('Role is required.'),
    DOB: Yup.date()
      .max(new Date(Date.now() - 567648000000), "Delivery Person must be at least 18 years old.")
      .required("Date of Birth is required."),
  })

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};

    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      if (values.T_and_C_Agreed) {
        formValues.T_and_C_Agreed = 1;
      } else {
        formValues.T_and_C_Agreed = 0;
      }
      if (values.Is_Approved) {
        formValues.Is_Approved = 1;
      } else {
        formValues.Is_Approved = 0;
      }
      if (values.Is_active) {
        formValues.Is_active = 1;
      } else {
        formValues.Is_active = 0;
      }
      if (values.Is_email_verified) {
        formValues.Is_email_verified = 1;
      } else {
        formValues.Is_email_verified = 0;
      }
      if (values.Marketing_Agreed) {
        formValues.Marketing_Agreed = 1;
      } else {
        formValues.Marketing_Agreed = 0;
      }
      if (values.IS_Blocked_By_Admin) {
        formValues.IS_Blocked_By_Admin = 1;
      } else {
        formValues.IS_Blocked_By_Admin = 0;
      }
      formValues.Created_By = createdBy;
      dispatch(createDeliveryAgentAction(formValues, navigate, setErrorMessage, setErrors));
    }
  }

  return (
    <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.First_name && errors.First_name)}
                        label="First Name"
                        name="First_name"
                        onChange={handleChange}
                        value={values.First_name}
                        variant="outlined"
                        helperText={<ErrorMessage name="First_name" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Last_name && errors.Last_name)}
                        label="Last Name"
                        name="Last_name"
                        onChange={handleChange}
                        value={values.Last_name}
                        variant="outlined"
                        helperText={<ErrorMessage name="Last_name" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Full_name && errors.Full_name)}
                        label="Full Name"
                        name="Full_name"
                        onChange={handleChange}
                        value={values.Full_name}
                        variant="outlined"
                        helperText={<ErrorMessage name="Full_name" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Del_user_email_id && errors.Del_user_email_id)}
                        label="Delivery Agent Email ID"
                        name="Del_user_email_id"
                        onChange={handleChange}
                        value={values.Del_user_email_id}
                        variant="outlined"
                        helperText={<ErrorMessage name="Del_user_email_id" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.DOB && errors.DOB)}
                        fullWidth
                        helperText={touched.DOB && errors.DOB}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="date"
                        label="Date of Birth"
                        name="DOB"
                        value={values.DOB}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Gender && errors.Gender)}
                        fullWidth
                        placeholder="Gender"
                        name="Gender"
                        onChange={handleChange}
                        label="Gender"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Gender}
                        variant="outlined"
                        helperText={<ErrorMessage name="Gender" />}
                      >
                        {GenderOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Role && errors.Role)}
                        fullWidth
                        placeholder="Role"
                        name="Role"
                        onChange={handleChange}
                        label="Role"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Role}
                        variant="outlined"
                        helperText={<ErrorMessage name="Role" />}
                      >
                        {DeliveryOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Del_User_Type && errors.Del_User_Type)}
                        fullWidth
                        placeholder="Del User Type"
                        name="Del_User_Type"
                        onChange={handleChange}
                        label="Delivery User Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Del_User_Type}
                        variant="outlined"
                        helperText={<ErrorMessage name="Del_User_Type" />}
                      >
                        {delipersontypeOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <PhoneInput
                        containerClass={touched.Mobile && errors.Mobile ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                        inputClass={"form-control MuiOutlinedInput-input"}
                        error={true}
                        country={"in"}
                        fullWidth
                        inputStyle={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px"
                        }}
                        inputProps={{
                          name: "Mobile",
                        }}
                        specialLabel="Mobile Number"
                        name="Mobile"
                        onBlur={handleBlur}
                        placeholder="+91 XXXXX XXXXX"
                        onChange={(value, country, e, formattedValue) => {
                          setFieldValue("Mobile", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                          setMobile(formattedValue)
                        }}
                        value={mobile}
                      />
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Mobile && errors.Mobile}</p>
                    </Grid>
                    <Grid item md={6} xs={12}></Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.T_and_C_Agreed}
                            onChange={handleChange}
                            name="T_and_C_Agreed"
                            color="primary"
                          />
                        }
                        label="T&C Agreed"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_active}
                            onChange={handleChange}
                            name="Is_active"
                            color="primary"
                          />
                        }
                        label="Is Active"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Approved}
                            onChange={handleChange}
                            name="Is_Approved"
                            color="primary"
                          />
                        }
                        label="Is Approved"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Marketing_Agreed}
                            onChange={handleChange}
                            name="Marketing_Agreed"
                            color="primary"
                          />
                        }
                        label="Marketing Agreed"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_email_verified}
                            onChange={handleChange}
                            name="Is_email_verified"
                            color="primary"
                          />
                        }
                        label="Is Email Verified"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.IS_Blocked_By_Admin}
                            onChange={handleChange}
                            name="IS_Blocked_By_Admin"
                            color="primary"
                          />
                        }
                        label="Is Blocked By Admin"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save Details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>

  );
};

export default AddDeliveryPersonDetails;