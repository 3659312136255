import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  makeStyles,
  TextField,
  Tooltip,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../store/validations/is-empty';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CorpPagination from 'src/components/pagination/corppagination';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  images: {
    width: 100,
    height: 50
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
  textField: {
    width: '91px',
    height: '30px',
    borderRadius: '50px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      height: '30px',
      '& fieldset': {
        borderColor: 'inherit',
      },
      '&:hover fieldset': {
        borderColor: 'inherit',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'inherit',
      },
    },
    '& .MuiSelect-icon': {
      color: 'inherit',
      left: '75%',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: '16px',
      width: '2em',
      height: '2em',
    },
  },
}));

const getStatusStyles = (status) => {
  switch (status) {
    case 'Active':
      return {
        borderColor: '#2E7D32',
        color: '#2E7D32',
      };
    default:
      return {
        borderColor: '#D32F2F',
        color: '#D32F2F',
      };
  }
};

const headCells = [
  { id: '_id', numeric: false, disablePadding: true, label: 'Award ID' },
  { id: 'Template_Name', numeric: false, disablePadding: false, label: 'Award Title' },
  { id: 'Created_Date', numeric: false, disablePadding: false, label: 'Created on' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allcorporateAwards,
  order,
  orderBy,
  pagination,
  setPagination,
  createSortHandler,
  handleLimitChange,
  handlePageChange,
  deletecorporateAwardHandler,
  allcorporateAwardsNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedcorporateAwardIds, setSelectedcorporateAwardIds] = useState([]);

  const handleSelectAll = event => {
    let newSelectedcorporateAwardIds;
    if (event.target.checked) {
      newSelectedcorporateAwardIds = allcorporateAwards.map(corporateaward => corporateaward._id);
    } else {
      newSelectedcorporateAwardIds = [];
    }
    setSelectedcorporateAwardIds(newSelectedcorporateAwardIds);
  };

  const handleSelectOne = (event, id) => {
    setSelectedcorporateAwardIds(selectedcorporateAwardIds.filter(record => {
      for (let i = 0; i < allcorporateAwardsNoFilter.length; i++) {
        if (record == allcorporateAwardsNoFilter[i]._id) {
          return true;
        }
      }
      return false;
    }))

    const selectedIndex = selectedcorporateAwardIds.indexOf(id);
    let newSelectedcorporateAwardIds = [];
    if (selectedIndex === -1) {
      newSelectedcorporateAwardIds = newSelectedcorporateAwardIds.concat(selectedcorporateAwardIds, id);
    } else if (selectedIndex === 0) {
      newSelectedcorporateAwardIds = newSelectedcorporateAwardIds.concat(
        selectedcorporateAwardIds.slice(1)
      );
    } else if (selectedIndex === selectedcorporateAwardIds.length - 1) {
      newSelectedcorporateAwardIds = newSelectedcorporateAwardIds.concat(
        selectedcorporateAwardIds.slice(0, -1)
      );
    }
    else if (selectedIndex > 0) {
      newSelectedcorporateAwardIds = newSelectedcorporateAwardIds.concat(
        selectedcorporateAwardIds.slice(0, selectedIndex),
        selectedcorporateAwardIds.slice(selectedIndex + 1)
      );
    }
    setSelectedcorporateAwardIds(newSelectedcorporateAwardIds);
  };

  const editcorporateAwardHandler = corporateawardData => e => {
    navigate('/app/edit-corporate-award', { state: corporateawardData });
  };

  const viewcorporateAwardHandler = corporateawardData => e => {
    navigate('/app/view-corporate-award', { state: corporateawardData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedcorporateAwardIds.filter(record => {
                      for (let i = 0; i < allcorporateAwardsNoFilter.length; i++) {
                        if (record == allcorporateAwardsNoFilter[i]._id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allcorporateAwards.length}
                    color="primary"
                    indeterminate={
                      selectedcorporateAwardIds.filter(record => {
                        for (let i = 0; i < allcorporateAwardsNoFilter.length; i++) {
                          if (record == allcorporateAwardsNoFilter[i]._id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedcorporateAwardIds.filter(record => {
                        for (let i = 0; i < allcorporateAwardsNoFilter.length; i++) {
                          if (record == allcorporateAwardsNoFilter[i]._id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allcorporateAwards.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.id !== "_Image" ? (
                      <TableSortLabel style={{ fontSize: "13px", fontWeight: "bold" }}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>) : (<span style={{ fontSize: "13px", fontWeight: "bold" }} > {headCell.label}</span>)
                    }
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions &nbsp;&nbsp;
                  {selectedcorporateAwardIds.filter(record => {
                    for (let i = 0; i < allcorporateAwardsNoFilter.length; i++) {
                      if (record == allcorporateAwardsNoFilter[i]._id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedcorporateAwardIds.filter(record => {
                    for (let i = 0; i < allcorporateAwardsNoFilter.length; i++) {
                      if (record == allcorporateAwardsNoFilter[i]._id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0}
                    onClick={deletecorporateAwardHandler(selectedcorporateAwardIds.map(record => (record)))}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allcorporateAwards) &&
                (allcorporateAwards).map(corporateaward => {
                  const statusStyles = getStatusStyles(corporateaward?.Status);
                  return (
                    <TableRow
                      hover
                      key={corporateaward._id}
                      selected={selectedcorporateAwardIds.indexOf(corporateaward._id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={
                            selectedcorporateAwardIds.indexOf(corporateaward._id) !== -1
                          }
                          onChange={event =>
                            handleSelectOne(event, corporateaward._id)
                          }
                          value="true"
                        />
                      </TableCell>
                      <TableCell>{corporateaward._id}</TableCell>
                      <TableCell style={{ color: '#1665D8' }} >
                        {corporateaward?.Template_Name}
                      </TableCell>
                      <TableCell>{corporateaward?.Created_Date}</TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          value={corporateaward.Status}
                          variant="outlined"
                          className={classes.textField}
                          InputProps={{
                            style: {
                              borderColor: statusStyles.borderColor,
                              color: statusStyles.color,
                            },
                          }}
                          SelectProps={{
                            native: true,
                            style: {
                              color: statusStyles.color,
                            },
                            classes: {
                              icon: classes.textField,
                            },
                          }}
                        >
                        </TextField>
                      </TableCell>
                      {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                        (RolePermissions?.Corporate_Award_Write?.Value === "Corporate_Award_Write" && RolePermissions?.Corporate_Award_Write?.Is_Write === 1) ?
                        <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Tooltip title="Template View">
                            <Button
                              onClick={viewcorporateAwardHandler(corporateaward)}
                              color="primary" >
                              <VisibilityIcon style={{ color: '#9EA0A5' }} />
                            </Button>
                          </Tooltip>
                          <Button
                            onClick={editcorporateAwardHandler(corporateaward)}
                          >
                            <CreateIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                          <Button>
                            <DeleteIcon
                              onClick={deletecorporateAwardHandler(corporateaward)}
                              style={{ color: '#9EA0A5' }}
                            />
                          </Button>
                        </TableCell>
                        : null}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allcorporateAwards) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <CorpPagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;