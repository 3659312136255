import { v4 as uuid } from 'uuid';

export default [
    {
        id: uuid(),
        hide: 0,
        link: '/app/razorpaypayments-management',
        createdAt: '',
        description:
            'A Razorpay is a payment mode founded by the group of IIT Roorkee alumni. It is a kind of payment mode that combines more than one banking system. It is an online payment system that can access all your credit, debit cards, UPI, and popular mobile wallets.',
        media: '/static/Dashboard/catergory.png',
        title: 'Razorpay Payments',
        total: ''
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/razorpaytransaction-management',
        createdAt: '',
        description:
            'A Razorpay is a payment mode founded by the group of IIT Roorkee alumni. It is a kind of payment mode that combines more than one banking system. It is an online payment system that can access all your credit, debit cards, UPI, and popular mobile wallets.',
        media: '/static/Dashboard/catergory.png',
        title: 'Razorpay Transactions',
        total: ''
    },
];