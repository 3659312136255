// api.js
import axios from 'axios';
import dConfig from './config';
import CryptoJS from 'crypto-js';

const boomiAdminApi = axios.create({
    baseURL: dConfig.adminurl,
    headers: dConfig.adminheadersCommon,
    // timeout: 10000, // Adjust as needed
});

// Add a request interceptor
boomiAdminApi.interceptors.request.use(
    async (config) => {
        // Modify the request config here, such as adding headers
        try {
            const encryptedToken = localStorage.getItem('Token');
            const bytes = CryptoJS.AES.decrypt(encryptedToken, dConfig.cryptoKay);
            const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            if (decryptedToken) {
                config.headers["Bearer-Token"] = `Bearer ${decryptedToken.accessToken}`;
            }
        } catch (error) {

        }
        return config;
    },
    (error) => {
        // Handle errors
        return Promise.reject(error);
    }
);

const boomiUserApi = axios.create({
    baseURL: dConfig.url,
    headers: dConfig.headersCommon,
    // timeout: 10000, // Adjust as needed
});

// Add a request interceptor
boomiUserApi.interceptors.request.use(
    async (config) => {
        // Modify the request config here, such as adding headers
        try {
            const encryptedToken = localStorage.getItem('Token');
            const bytes = CryptoJS.AES.decrypt(encryptedToken, dConfig.cryptoKay);
            const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            if (decryptedToken) {
                config.headers["Bearer-Token"] = `Bearer ${decryptedToken.accessToken}`;
            }
        } catch (error) {

        }
        return config;
    },
    (error) => {
        // Handle errors
        return Promise.reject(error);
    }
);

const boomiDelApi = axios.create({
    baseURL: dConfig.delurl,
    headers: dConfig.adminheadersCommon,
    // timeout: 10000, // Adjust as needed
});

// Add a request interceptor
boomiDelApi.interceptors.request.use(
    async (config) => {
        // Modify the request config here, such as adding headers
        try {
            const encryptedToken = localStorage.getItem('Token');
            const bytes = CryptoJS.AES.decrypt(encryptedToken, dConfig.cryptoKay);
            const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            if (decryptedToken) {
                config.headers["Bearer-Token"] = `Bearer ${decryptedToken.accessToken}`;
            }
        } catch (error) {

        }
        return config;
    },
    (error) => {
        // Handle errors
        return Promise.reject(error);
    }
);

const boomiPartnerApi = axios.create({
    baseURL: dConfig.parturl,
    headers: dConfig.adminheadersCommon,
    // timeout: 10000, // Adjust as needed
});

// Add a request interceptor
boomiPartnerApi.interceptors.request.use(
    async (config) => {
        // Modify the request config here, such as adding headers
        try {
            const encryptedToken = localStorage.getItem('Token');
            const bytes = CryptoJS.AES.decrypt(encryptedToken, dConfig.cryptoKay);
            const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            if (decryptedToken) {
                config.headers["Bearer-Token"] = `Bearer ${decryptedToken.accessToken}`;
            }
        } catch (error) {

        }
        return config;
    },
    (error) => {
        // Handle errors
        return Promise.reject(error);
    }
);

const boomiCorpApi = axios.create({
    baseURL: dConfig.corpurl,
    headers: dConfig.adminheadersCommon,
    // timeout: 10000, // Adjust as needed
});

// Add a request interceptor
boomiCorpApi.interceptors.request.use(
    async (config) => {
        // Modify the request config here, such as adding headers
        try {
            const encryptedToken = localStorage.getItem('Token');
            const bytes = CryptoJS.AES.decrypt(encryptedToken, dConfig.cryptoKay);
            const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            if (decryptedToken) {
                config.headers["Bearer-Token"] = `Bearer ${decryptedToken.accessToken}`;
            }
        } catch (error) {

        }
        return config;
    },
    (error) => {
        // Handle errors
        return Promise.reject(error);
    }
);

const nodeCudApi = axios.create({
    baseURL: dConfig.nodecudurl,
    // timeout: 10000, // Adjust as needed
});

// Add a request interceptor
nodeCudApi.interceptors.request.use(
    async (config) => {
        // Modify the request config here, such as adding headers
        try {
            const encryptedToken = localStorage.getItem('Token');
            const bytes = CryptoJS.AES.decrypt(encryptedToken, dConfig.cryptoKay);
            const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            if (decryptedToken) {
                config.headers.Authorization = `Bearer ${decryptedToken.accessToken}`;
            }
        } catch (error) {

        }
        return config;
    },
    (error) => {
        // Handle errors
        return Promise.reject(error);
    }
);

const nodeGetApi = axios.create({
    baseURL: dConfig.nodeurl,
    timeout: 10000, // Adjust as needed
});

// Add a request interceptor
nodeGetApi.interceptors.request.use(
    async (config) => {
        // Modify the request config here, such as adding headers
        try {
            const encryptedToken = localStorage.getItem('Token');
            const bytes = CryptoJS.AES.decrypt(encryptedToken, dConfig.cryptoKay);
            const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            if (decryptedToken) {
                config.headers.Authorization = `Bearer ${decryptedToken.accessToken}`;
            }
        } catch (error) {
        }
        return config;
    },
    (error) => {
        // Handle errors
        return Promise.reject(error);
    }
);

const nodeUserGetApi = axios.create({
    baseURL: dConfig.nodeUserUrl,
    timeout: 10000, // Adjust as needed
});

// Add a request interceptor
nodeUserGetApi.interceptors.request.use(
    async (config) => {
        // Modify the request config here, such as adding headers
        try {
            const encryptedToken = localStorage.getItem('Token');
            const bytes = CryptoJS.AES.decrypt(encryptedToken, dConfig.cryptoKay);
            const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            if (decryptedToken) {
                config.headers.Authorization = `Bearer ${decryptedToken.accessToken}`;
            }
        } catch (error) {

        }
        return config;
    },
    (error) => {
        // Handle errors
        return Promise.reject(error);
    }
);

const nodeCorpMongoApi = axios.create({
    baseURL: dConfig.corpmongourl,
    timeout: 10000, // Adjust as needed
    headers: dConfig.corpMongoheadersCommon
});

const corpimageuploadApi = axios.create({
    baseURL: dConfig.corpfileuploadbaseurl,
    headers: dConfig.corpMongoImageheadersCommon,
    timeout: 20000,
});

const nodeServiceApi = axios.create({
    baseURL: dConfig.servieNodeApi,
    timeout: 10000,
})

nodeServiceApi.interceptors.request.use(
    async (config) => {
        // console.log( dConfig.servieNodeApi)
        // Modify the request config here, such as adding headers
        try {
            const encryptedToken = localStorage.getItem('Token');
            const bytes = CryptoJS.AES.decrypt(encryptedToken, dConfig.cryptoKay);
            const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            if (decryptedToken) {
                config.headers.Authorization = `Bearer ${decryptedToken.accessToken}`;
            }
        } catch (error) {

        }
        return config;
    },
    (error) => {
        // Handle errors
        return Promise.reject(error);
    }
)

const urlGenarator = (url, pagination) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
}

export { boomiAdminApi, boomiUserApi, boomiDelApi, boomiPartnerApi, boomiCorpApi, nodeCudApi, nodeGetApi, nodeUserGetApi, nodeCorpMongoApi, corpimageuploadApi, urlGenarator, nodeServiceApi }