import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Results from './Results';
import Toolbar from './Toolbar';
import {
  deleteProductAction, CopyProductAction
} from '../../../store/actions/productAction';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from '../../../components/dialogs/confirmDialog'
import { paginationProducts } from 'src/store/actions/node-actions/nodepagination';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ProductListView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  //permissions
  const RolePermissions = useSelector(state => state.rolepermission.Roles);
  const roleUserType = useSelector(state => state.auth.user);
  const Login_Details = useSelector(state => state.auth.user);

  const [products, setProducts] = useState([]);
  const [filters, setFilters] = React.useState({
    "search": '',
    "search_by_filter": 'All',
    Records_Filter: "FILTER",
    Is_Approved: "1"
  });

  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = useState({ PageNo: 1, PageSize: 10, SortBy: "Product_Id", SortOrder: "ASC" })

  const handleLimitChange = event => {
    setPagination({ ...pagination, PageSize: +event.target.value, PageNo: 1 });
  };

  const handlePageChange = (event, newPage) => {
    setPagination({ ...pagination, PageSize: pagination.PageSize, PageNo: newPage + 1 });
  };

  const callBackPagination = async (status, data, pagination) => {
    if (status) {
      setProducts(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({ PageNo: 1, PageSize: 10, SortBy: "Product_Id", SortOrder: "ASC" });
    }
  }

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      productsCall();
    }
  }, [pagination])

  const [testFilters, setTestFilters] = useState({});
  useEffect(() => {
    if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
      setTestFilters(filters);
      searchFunction();
    }
  }, [filters]);

  const searchFunction = () => {
    const searchpagination = {
      PageNo: 1, PageSize: 10, SortBy: "Product_Id", SortOrder: "ASC"
    }
    dispatch(paginationProducts(filters, searchpagination, callBackPagination))
  }

  const productsCall = () => {
    dispatch(paginationProducts(filters, pagination, callBackPagination))
  };

  const [product1, setProduct1] = useState(false);
  const [ptitle, setPtitle] = useState('');
  const [pmessage, setPmessage] = useState('');
  const [productIdle, setProductIdle] = useState('');

  const [copyproduct1, setCopyProduct1] = useState(false);
  const [ctitle, setCtitle] = useState('');
  const [cmessage, setCmessage] = useState('');
  const [copyproductIdle, setCopyProductIdle] = useState('');

  const phandleClose = (value) => {
    setProduct1(false);
    if (value) {
      const deleteData = {
        Product_Id: productIdle.Product_Id
      };
      dispatch(deleteProductAction(deleteData, filters, pagination, callBackPagination));
    }
  };

  const chandleClose = (value) => {
    setCopyProduct1(false);
    if (value) {
      const copyproductData = {
        Product_Id: copyproductIdle.Product_Id,
        Created_By: loginEmail
      };
      dispatch(CopyProductAction(copyproductData, filters, pagination, callBackPagination));
    }
  };

  useEffect(() => {
    dispatch(getRolePermissions(Login_Details));
    dispatch(paginationProducts(filters, pagination, callBackPagination))
  }, []);

  //Delete handler
  const deleteProductHandler = productData => async e => {
    setProduct1(true);
    setPtitle("Alert!");
    setPmessage("Are you sure to delete?");
    setProductIdle(productData);
  };

  //Copy handler
  const copyProductHandler = copyData => async e => {
    setCopyProduct1(true);
    setCtitle("Alert!");
    setCmessage("Are you sure to create?");
    setCopyProductIdle(copyData);
  };

  // Sorting 
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Product_Id');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPagination({ PageNo: 1, PageSize: pagination?.PageSize, SortBy: property, SortOrder: order === 'asc' ? "ASC" : "DESC" })
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/product-management"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Products
          </Typography>
        </Box>
        <Toolbar filters={filters} setfilters={setFilters} callBackPagination={callBackPagination}
          pagination={pagination} RolePermissions={RolePermissions} roleUserType={roleUserType} />
        <Box mt={3}>
          <Results
            allProducts={products}
            allProductsNoFilter={products}
            deleteProductHandler={deleteProductHandler}
            copyProductHandler={copyProductHandler}
            handleLimitChange={handleLimitChange}
            handlePageChange={handlePageChange}
            order={order}
            pagination={pagination}
            setPagination={setPagination}
            orderBy={orderBy}
            createSortHandler={createSortHandler}
            RolePermissions={RolePermissions}
            roleUserType={roleUserType}
          />
        </Box>
      </Container>
      <ConfirmDialog inputMessage={pmessage} titleMsg={ptitle} open={product1} onClose={phandleClose} />
      <ConfirmDialog inputMessage={cmessage} titleMsg={ctitle} open={copyproduct1} onClose={chandleClose} />
    </Page>
  );
};

export default ProductListView;