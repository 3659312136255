import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateUserPaymentAction,
} from './../../store/actions/userpaymentsettlementsAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const paymentOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'NEW',
    label: 'NEW'
  },
  {
    value: 'PAID',
    label: 'PAID'
  },
  {
    value: 'UNPAID',
    label: 'UNPAID'
  },
  {
    value: 'PENDING',
    label: 'PENDING'
  },
  {
    value: 'REFUND',
    label: 'REFUND'
  },
  {
    value: 'CANCELLED',
    label: 'CANCELLED'
  },
];

const UserPaymentSettlementsEdit = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = location;

  const errorMessage = useSelector(state => state.userpayment.userpaymentUpdate.error);
  const isSubmited = useSelector(state => state.userpayment.userpaymentUpdate.isLoading);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        User_Payment_Settlement_Id: state.User_Payment_Settlement_Id ? state.User_Payment_Settlement_Id : "",
        Order_Id: state.Order_Id ? state.Order_Id : "",
        Partner_Id: state.Partner_Id ? state.Partner_Id : "",
        Transaction_Id: state.Transaction_Id ? state.Transaction_Id : "",
        Order_Details: state.Order_Details ? state.Order_Details : "",
        Partner_Type: state.Partner_Type ? state.Partner_Type : "",
        Total_Order_Amount: state.Total_Order_Amount ? state.Total_Order_Amount : "",
        Esi_Commission_Amount: state.Esi_Commission_Amount ? state.Esi_Commission_Amount : "",
        Final_Order_Amount: state.Final_Order_Amount ? state.Final_Order_Amount : "",
        Order_Line_Item_Amount: state.Order_Line_Items[0].Order_Line_Item_Amount ? state.Order_Line_Items[0].Order_Line_Item_Amount : "",
        Order_Product_Id: state.Order_Line_Items[0].Order_Product_Id ? state.Order_Line_Items[0].Order_Product_Id : "",
        Modified_By: modifiedBy,
        Payment_Status: state.Payment_Status ? state.Payment_Status : "",
      }}

      validationSchema={
        Yup.object().shape({
          Order_Line_Item_Amount: Yup.number().min(0, 'Order Line Item Amount should not be less than 0.').required('Order Line Item Amount is required.'),
          Total_Order_Amount: Yup.number().min(0, 'Total Order Amount should not be less than 0.').required('Total Order Amount is required.'),
          Esi_Commission_Amount: Yup.number().min(0, 'ESI Commission Amount should not be less than 0.').required('ESI Commission Amount is required.'),
          Final_Order_Amount: Yup.number().min(0, 'Final Settlement Amount should not be less than 0.').required('Final Settlement Amount is required.'),
          Payment_Status: Yup.string().required('Payment Status is required.'),
        })
      }
      onSubmit={(values) => {
        if (values) {
          let formData = JSON.parse(JSON.stringify(values));
          dispatch(updateUserPaymentAction(formData, navigate));
        }
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.User_Payment_Settlement_Id && errors.User_Payment_Settlement_Id)}
                        fullWidth
                        helperText={touched.User_Payment_Settlement_Id && errors.User_Payment_Settlement_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={true}
                        label="User Payment Settlement ID"
                        name="User_Payment_Settlement_Id"
                        value={values.User_Payment_Settlement_Id}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Order_Id && errors.Order_Id)}
                        fullWidth
                        helperText={touched.Order_Id && errors.Order_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={true}
                        label="Order ID"
                        name="Order_Id"
                        value={values.Order_Id}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Transaction_Id && errors.Transaction_Id)}
                        fullWidth
                        helperText={touched.Transaction_Id && errors.Transaction_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={true}
                        label="Transaction ID"
                        name="Transaction_Id"
                        value={values.Transaction_Id}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Order_Product_Id && errors.Order_Product_Id)}
                        fullWidth
                        helperText={touched.Order_Product_Id && errors.Order_Product_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={true}
                        label="Order Product ID"
                        name="Order_Product_Id"
                        value={values.Order_Product_Id}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Order_Line_Item_Amount && errors.Order_Line_Item_Amount)}
                        fullWidth
                        helperText={touched.Order_Line_Item_Amount && errors.Order_Line_Item_Amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Order Line Item Amount"
                        name="Order_Line_Item_Amount"
                        value={values.Order_Line_Item_Amount}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Total_Order_Amount && errors.Total_Order_Amount)}
                        fullWidth
                        helperText={touched.Total_Order_Amount && errors.Total_Order_Amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Total Order Amount"
                        name="Total_Order_Amount"
                        value={values.Total_Order_Amount}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Esi_Commission_Amount && errors.Esi_Commission_Amount)}
                        fullWidth
                        helperText={touched.Esi_Commission_Amount && errors.Esi_Commission_Amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="ESI Commission Amount"
                        name="Esi_Commission_Amount"
                        value={values.Esi_Commission_Amount}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Final_Order_Amount && errors.Final_Order_Amount)}
                        fullWidth
                        helperText={touched.Final_Order_Amount && errors.Final_Order_Amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Final Settlement Amount"
                        name="Final_Order_Amount"
                        value={values.Final_Order_Amount}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Payment_Status && errors.Payment_Status)}
                        fullWidth
                        placeholder="Payment_Status"
                        name="Payment_Status"
                        onChange={handleChange}
                        label="Payment Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Payment_Status}
                        variant="outlined"
                        helperText={touched.Payment_Status && errors.Payment_Status}
                      >
                        {paymentOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

UserPaymentSettlementsEdit.propTypes = {
  className: PropTypes.string
};

export default UserPaymentSettlementsEdit;