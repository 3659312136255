import {
    CORP_ORDER_UPDATE_REQUEST,
    CORP_ORDER_UPDATE_SUCCESS,
    CORP_ORDER_UPDATE_FAIL,

    CORP_ORDER_GET_REQUEST,
    CORP_ORDER_GET_SUCCESS,
    CORP_ORDER_GET_FAIL,

    CORP_DOOR_ORDER_GET_REQUEST,
    CORP_DOOR_ORDER_GET_SUCCESS,
    CORP_DOOR_ORDER_GET_FAIL
} from '../actions/corporderAction'

const initialState = {
    corporderUpdate: { corporder: {}, error: '', isLoading: false },
    corpsingleorder: { all: [], error: '', isLoading: false },
    corpdoorsteporder: { all: [], error: '', isLoading: false }
};

export default function (state = initialState, action) {
    switch (action.type) {

        case CORP_ORDER_UPDATE_REQUEST:
            return { ...state, corporderUpdate: { corporder: {}, error: '', isLoading: true } };
        case CORP_ORDER_UPDATE_SUCCESS:
            return { ...state, corporderUpdate: { corporder: action.payload, error: '', isLoading: false } };
        case CORP_ORDER_UPDATE_FAIL:
            return { ...state, corporderUpdate: { corporder: {}, error: action.payload, isLoading: false } };

        case CORP_ORDER_GET_REQUEST:
            return { ...state, corpsingleorder: { all: state.corpsingleorder.all, error: '', isLoading: true } };
        case CORP_ORDER_GET_SUCCESS:
            return { ...state, corpsingleorder: { all: action.payload, error: '', isLoading: false } };
        case CORP_ORDER_GET_FAIL:
            return { ...state, corpsingleorder: { all: [], error: action.payload, isLoading: false } };

        case CORP_DOOR_ORDER_GET_REQUEST:
            return { ...state, corpdoorsteporder: { all: state.corpdoorsteporder.all, error: '', isLoading: true } };
        case CORP_DOOR_ORDER_GET_SUCCESS:
            return { ...state, corpdoorsteporder: { all: action.payload, error: '', isLoading: false } };
        case CORP_DOOR_ORDER_GET_FAIL:
            return { ...state, corpdoorsteporder: { all: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}