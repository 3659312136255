import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createSubCategoryAction,
  uploadsubmobileimage,
  uploadSubCategoryImage,
  getAllCategories
} from './../../store/actions/subcategoryAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddSubCategory = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  const [errorMessage, setErrorMessage] = useState({ Sub_Category_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmitted = useSelector(state => state.subcategory.subcategoryCreate.isLoading);
  const categories = useSelector(state => state.subcategory.Categories.categories);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [rhDiscription, setRhDiscription] = useState('');
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // img upload
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadSubCategoryImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };
  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  // img1 upload1
  const [image1, setImage1] = useState({ preview: '', raw: '' });
  const [imgUrl1, setImgUrl1] = useState('');
  const [uploadPic1, setUploadPic1] = useState(false);

  const callBackUploadImgae1 = data => {
    if (data) {
      setImgUrl1(data.File_URL);
    }
    setUploadPic1(false);
  };

  const handleImage1 = e => {
    setUploadPic1(true);
    e.preventDefault();
    setImage1({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadsubmobileimage(new Blob([e.target.files[0]]), callBackUploadImgae1)
    );
  };

  const handleRemoveImage1 = e => {
    setImgUrl1('');
    setImage1({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        Sub_Category_Name: '',
        Category_Id: '',
        Created_By: loginEmail,
        Description: '',
        Is_Popular: false,
        Is_Farmer_Sub_Category: false,
        Status: '',
        Sub_Category_Image_Web: imgUrl,
        Sub_Category_Image_Mobile: imgUrl1,
        IGST_Percentage: '',
        CGST_Percentage: '',
        SGST_Percentage: '',
        VAT_Percentage: '',
      }}
      validationSchema={
        Yup.object().shape({
          Sub_Category_Name: Yup.string().min(3, "SubCategory Name must be at least 3 characters.").notOneOf([errorMessage.Sub_Category_Name.key, null], errorMessage.Sub_Category_Name.message).required('SubCategory Name is required.'),
          Category_Id: Yup.string().required('Category Name is required.'),
          Status: Yup.string().required('Status is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};

        values.Sub_Category_Image_Web = imgUrl;
        values.Sub_Category_Image_Mobile = imgUrl1;
        values.Description = rhDiscription;

        if (!values.Sub_Category_Image_Web) {
          submitTest = false;
          errors.Sub_Category_Image_Web = "Web Image is required.";
        }
        if (!values.Sub_Category_Image_Mobile) {
          submitTest = false;
          errors.Sub_Category_Image_Mobile = "Mobile Image is required.";
        }

        if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          if (values.Is_Popular) {
            formValues.Is_Popular = 1;
          } else {
            formValues.Is_Popular = 0;
          }
          if (values.Is_Farmer_Sub_Category) {
            formValues.Is_Farmer_Sub_Category = 1;
          } else {
            formValues.Is_Farmer_Sub_Category = 0;
          }
          dispatch(createSubCategoryAction(formValues, navigate, setErrorMessage, setErrors));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    <Grid item md={6} xs={12}>
                      {/* category name */}
                      <TextField
                        error={Boolean(touched.Category_Id && errors.Category_Id)}
                        fullWidth
                        helperText={touched.Category_Id && errors.Category_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Category Name"
                        name="Category_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Category_Id}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {categories?.sort((a, b) => a.Category_Name?.localeCompare(b.Category_Name))?.map(option => (
                          <option key={option.Category_Id} value={option.Category_Id}>
                            {option.Category_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Subcategory name */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Sub_Category_Name && errors.Sub_Category_Name)}
                        fullWidth
                        helperText={touched.Sub_Category_Name && errors.Sub_Category_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="SubCategory Name"
                        name="Sub_Category_Name"
                        value={values.Sub_Category_Name}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Description  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                      </span>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="IGST"
                        type="number"
                        name="IGST_Percentage"
                        value={values.IGST_Percentage}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="CGST"
                        type="number"
                        name="CGST_Percentage"
                        value={values.CGST_Percentage}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="SGST"
                        type="number"
                        name="SGST_Percentage"
                        value={values.SGST_Percentage}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="VAT"
                        type="number"
                        name="VAT_Percentage"
                        value={values.VAT_Percentage}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Status */}
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Is_popular*/}
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Popular}
                            onChange={handleChange}
                            name="Is_Popular"
                            color="primary"
                          />
                        }
                        label="Is Popular"
                      />
                    </Grid>

                    {/* Is_Farmer_Sub_Category*/}
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Farmer_Sub_Category}
                            onChange={handleChange}
                            name="Is_Farmer_Sub_Category"
                            color="primary"
                          />
                        }
                        label="Is Farmer SubCategory"
                      />
                    </Grid>

                  </Grid>
                </Grid>

                {/* upload photo */}
                <Grid item md={4} xs={12}>
                  <Card>
                    &nbsp;Web Image
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Brand Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card><br />
                  <span style={{ color: "red" }}>
                    {!imgUrl && touched.Sub_Category_Image_Web && errors.Sub_Category_Image_Web}
                  </span>

                  <div style={{ marginBottom: '5%' }}></div>
                  <Card>
                    &nbsp;Mobile Image
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image1.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Brand Upload"
                            src={image1.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image1.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage1}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage1}
                        />}
                        <br />
                      </Box>

                    </CardContent>
                  </Card> <br />
                  <span style={{ color: "red" }}>
                    {!imgUrl1 && touched.Sub_Category_Image_Mobile && errors.Sub_Category_Image_Mobile}
                  </span>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmitted ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmitted || uploadPic || uploadPic1}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddSubCategory.propTypes = {
  className: PropTypes.string
};

export default AddSubCategory;