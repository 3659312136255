import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
} from '@material-ui/core';
import isEmpty from 'src/store/validations/is-empty';
import CorpPagination from 'src/components/pagination/corppagination';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Corporate ID' },
  { id: 'Company_Name', numeric: false, disablePadding: false, label: 'Organization name' },
  { id: 'Gift_Cards_Brought', numeric: true, disablePadding: false, label: 'Gift cards bought' },
  { id: 'Last_Brought', numeric: true, disablePadding: false, label: 'Last bought' },
  { id: 'Credits_Spend', numeric: true, disablePadding: false, label: 'Credits spent' },
  { id: 'Subscription_Level', numeric: false, disablePadding: false, label: 'Subscription level' }
];

const Results = ({
  className,
  allcorpUser,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allcorpNoFilter,
  RolePermissions,
  roleUserType,
  pagination,
  setPagination,
  ...rest
}) => {

  const classes = useStyles();
  const navigate = useNavigate();
  
  const OpenGiftsHandler = GiftData => e => {
    navigate('/app/corporate-gifting-history', { state: GiftData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}>
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}>
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-35%' }} >Actions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allcorpUser) &&
                (allcorpUser)?.map(corp => (
                  <TableRow
                    hover
                    key={corp.id}>
                    <TableCell>{corp.id}</TableCell>
                    < TableCell>{corp.Company_Name}</ TableCell >
                    <TableCell >{corp.Gift_Cards_Brought}</TableCell>
                    <TableCell>{corp.Last_Brought}</TableCell>
                    <TableCell >{corp.Credits_Spend}</TableCell>
                    <TableCell>{corp.Subscription_Level}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Pending_Delivery_Agents_Write?.Value === "Pending_Delivery_Agents_Write" && RolePermissions?.Pending_Delivery_Agents_Write?.Is_Write === 1) ?
                      <TableCell><div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
                        <Button
                          onClick={OpenGiftsHandler(corp)}
                          size='small'
                          variant='outlined'
                          color="primary"
                        >
                          <RemoveRedEyeIcon /> {" "}Gift History
                        </Button>
                      </div>
                      </TableCell>
                      : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allcorpUser) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <CorpPagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allcorpUser: PropTypes.array.isRequired
};

export default Results;