
// import {
//   SERVICE_BANNER_CREATE_REQUEST,
//   SERVICE_BANNER_CREATE_SUCCESS,
//   SERVICE_BANNER_CREATE_FAIL,

//   SERVICE_BANNER_UPDATE_REQUEST,
//   SERVICE_BANNER_UPDATE_SUCCESS,
//   SERVICE_BANNER_UPDATE_FAIL,

//   SERVICE_BANNER_GET_REQUEST,
//   SERVICE_BANNER_GET_SUCCESS,
//   SERVICE_BANNER_GET_FAIL,

//   SERVICE_BANNER_REQUEST,
//   SERVICE_BANNER_SUCCESS,
//   SERVICE_BANNER_FAIL,

//   SERVICE_CATEGORY_REQUEST,
//   SERVICE_CATEGORY_SUCCESS,
//   SERVICE_CATEGORY_FAIL,


//   SERVICE_SUBCATEGORY_REQUEST,
//   SERVICE_SUBCATEGORY_SUCCESS,
//   SERVICE_SUBCATEGORY_FAIL,


//   SERVICE_VARIENT_REQUEST,
//   SERVICE_VARIENT_SUCCESS,
//   SERVICE_VARIENT_FAIL,


// } from '../actions/servicebannersAction';
// const initialState = {
//   ServiceBannerCreate: { ServiceBanner: {}, error: '', isLoading: false },
//   ServiceBannerUpdate: { ServiceBanner: {}, error: '', isLoading: false },
//   // ServiceBanner: { ServiceBanner: {}, error: '', isLoading: false },
//   // ServiceBanners: { all: [], error: '', isLoading: false },
//   Servicecategories: { Servicecategory: [], error: '', isLoading: false },
//   Servicesubcategories: { Servicesubcategory: [], error: '', isLoading: false },
//   Servicevarients: { Servicevarient: [], error: '', isLoading: false },


// };
// export default function (state = initialState, action) {
//   switch (action.type) {
//     case SERVICE_BANNER_CREATE_REQUEST:
//       return { ...state, serviceBannerCreate: { ServiceBanner: {}, error: '', isLoading: true } };
//     case SERVICE_BANNER_CREATE_SUCCESS:
//       return { ...state, serviceBannerCreate: { ServiceBanner: action.payload, error: '', isLoading: false } };
//     case SERVICE_BANNER_CREATE_FAIL:
//       return { ...state, serviceBannerCreate: { ServiceBanner: {}, error: action.payload, isLoading: false } };

//     case SERVICE_BANNER_UPDATE_REQUEST:
//       return { ...state, ServiceBannerUpdate: { ServiceBanner: {}, error: '', isLoading: true } };
//     case SERVICE_BANNER_UPDATE_SUCCESS:
//       return { ...state, ServiceBannerUpdate: { ServiceBanner: action.payload, error: '', isLoading: false } };
//     case SERVICE_BANNER_UPDATE_FAIL:
//       return { ...state, ServiceBannerUpdate: { ServiceBanner: {}, error: action.payload, isLoading: false } };


//     case SERVICE_BANNER_REQUEST:
//       return { ...state, ServiceBanners: { all: state.ServiceBanners.all, error: '', isLoading: true } };
//     case SERVICE_BANNER_SUCCESS:
//       return { ...state, ServiceBanners: { all: action.payload, error: '', isLoading: false } };
//     case SERVICE_BANNER_FAIL:
//       return { ...state, ServiceBanners: { all: [], error: action.payload, isLoading: false } };

//     case SERVICE_BANNER_GET_REQUEST:
//       return { ...state, ServiceBanner: { ServiceBanner: {}, error: '', isLoading: true } };
//     case SERVICE_BANNER_GET_SUCCESS:
//       return { ...state, ServiceBanner: { ServiceBanner: action.payload, error: '', isLoading: false } };
//     case SERVICE_BANNER_GET_FAIL:
//       return { ...state, ServiceBanner: { ServiceBanner: {}, error: action.payload, isLoading: false } };


//     case SERVICE_CATEGORY_REQUEST:
//       return { ...state, Servicecategories: { ServiceCategory: [], error: '', isLoading: true } };
//     case SERVICE_CATEGORY_SUCCESS:
//       return { ...state, Servicecategories: { ServiceCategory: action.payload, error: '', isLoading: false } };
//     case SERVICE_CATEGORY_FAIL:
//       return { ...state, Servicecategories: { ServiceCategory: [], error: action.payload, isLoading: false } };

//     // case SERVICE_CATEGORY_REQUEST:
//     //   return { ...state, Servicecategories: { Servicecategory: state.Servicecategories.Servicecategory, error: '', isLoading: true }, };
//     // case SERVICE_CATEGORY_SUCCESS:
//     //   return { ...state, Servicecategories: { Servicecategory: action.payload, error: '', isLoading: false } };
//     // case SERVICE_CATEGORY_FAIL:
//     //   return { ...state, Servicecategories: { Servicecategory: [], error: action.payload, isLoading: false } };
 

//     case SERVICE_SUBCATEGORY_REQUEST:
//       return { ...state, Servicesubcategories: { ServiceSubCategory: [], error: '', isLoading: true } };
//     case SERVICE_SUBCATEGORY_SUCCESS:
//       return { ...state, Servicesubcategories: { ServiceSubCategory: action.payload, error: '', isLoading: false } };
//     case SERVICE_SUBCATEGORY_FAIL:
//       return { ...state, Servicesubcategories: { ServiceSubCategory: [], error: action.payload, isLoading: false } };


//     case SERVICE_VARIENT_REQUEST:
//       return { ...state, Servicevarients: { ServiceVarient: [], error: '', isLoading: true } };
//     case SERVICE_VARIENT_SUCCESS:
//       return { ...state, Servicevarients: { ServiceVarient: action.payload, error: '', isLoading: false } };
//     case SERVICE_VARIENT_FAIL:
//       return { ...state, Servicevarients: { ServiceVarient: [], error: action.payload, isLoading: false } };




//     default:
//       return state;
//   }
// }


import {
  SERVICE_BANNER_CREATE_REQUEST,
  SERVICE_BANNER_CREATE_SUCCESS,
  SERVICE_BANNER_CREATE_FAIL,
 
  SERVICE_BANNER_UPDATE_REQUEST,
  SERVICE_BANNER_UPDATE_SUCCESS,
  SERVICE_BANNER_UPDATE_FAIL,
 
  SERVICE_BANNER_GET_REQUEST,
  SERVICE_BANNER_GET_SUCCESS,
  SERVICE_BANNER_GET_FAIL,
 
  SERVICE_BANNER_REQUEST,
  SERVICE_BANNER_SUCCESS,
  SERVICE_BANNER_FAIL,
 
  SERVICE_CATEGORY_REQUEST,
  SERVICE_CATEGORY_SUCCESS,
  SERVICE_CATEGORY_FAIL,
 
  SERVICE_SUBCATEGORY_REQUEST,
  SERVICE_SUBCATEGORY_SUCCESS,
  SERVICE_SUBCATEGORY_FAIL,
 
  SERVICE_VARIENT_REQUEST,
  SERVICE_VARIENT_SUCCESS,
  SERVICE_VARIENT_FAIL,
 
 
} from '../actions/servicebannersAction';
const initialState = {
  ServiceBannerCreate: { ServiceBanner: {}, error: '', isLoading: false },
  ServiceBannerUpdate: { ServiceBanner: {}, error: '', isLoading: false },
  Servicecategories: { Servicecategory: [], error: '', isLoading: false },
  ServiceSubCategory: { Servicesubcategory: [], error: '', isLoading: false },
  Servicevarients: { ServiceVarient: [], error: '', isLoading: false },
 
 
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SERVICE_BANNER_CREATE_REQUEST:
      return { ...state, serviceBannerCreate: { ServiceBanner: {}, error: '', isLoading: true } };
    case SERVICE_BANNER_CREATE_SUCCESS:
      return { ...state, serviceBannerCreate: { ServiceBanner: action.payload, error: '', isLoading: false } };
    case SERVICE_BANNER_CREATE_FAIL:
      return { ...state, serviceBannerCreate: { ServiceBanner: {}, error: action.payload, isLoading: false } };
 
    case SERVICE_BANNER_UPDATE_REQUEST:
      return { ...state, ServiceBannerUpdate: { ServiceBanner: {}, error: '', isLoading: true } };
    case SERVICE_BANNER_UPDATE_SUCCESS:
      return { ...state, ServiceBannerUpdate: { ServiceBanner: action.payload, error: '', isLoading: false } };
    case SERVICE_BANNER_UPDATE_FAIL:
      return { ...state, ServiceBannerUpdate: { ServiceBanner: {}, error: action.payload, isLoading: false } };
 
 
    case SERVICE_BANNER_REQUEST:
      return { ...state, ServiceBanners: { all: state.ServiceBanners.all, error: '', isLoading: true } };
    case SERVICE_BANNER_SUCCESS:
      return { ...state, ServiceBanners: { all: action.payload, error: '', isLoading: false } };
    case SERVICE_BANNER_FAIL:
      return { ...state, ServiceBanners: { all: [], error: action.payload, isLoading: false } };
 
    case SERVICE_BANNER_GET_REQUEST:
      return { ...state, ServiceBanner: { ServiceBanner: {}, error: '', isLoading: true } };
    case SERVICE_BANNER_GET_SUCCESS:
      return { ...state, ServiceBanner: { ServiceBanner: action.payload, error: '', isLoading: false } };
    case SERVICE_BANNER_GET_FAIL:
      return { ...state, ServiceBanner: { ServiceBanner: {}, error: action.payload, isLoading: false } };
 
    case SERVICE_CATEGORY_REQUEST:
      return { ...state, Servicecategories: { ServiceCategory: [], error: '', isLoading: true } };
    case SERVICE_CATEGORY_SUCCESS:
      return { ...state, Servicecategories: { ServiceCategory: action.payload, error: '', isLoading: false } };
    case SERVICE_CATEGORY_FAIL:
      return { ...state, Servicecategories: { ServiceCategory: [], error: action.payload, isLoading: false } };
 
    case SERVICE_SUBCATEGORY_REQUEST:
      return { ...state, Servicesubcategories: { ServiceSubCategory: [], error: '', isLoading: true } };
    case SERVICE_SUBCATEGORY_SUCCESS:
      return { ...state, Servicesubcategories: { ServiceSubCategory: action.payload, error: '', isLoading: false } };
    case SERVICE_SUBCATEGORY_FAIL:
      return { ...state, Servicesubcategories: { ServiceSubCategory: [], error: action.payload, isLoading: false } };
 
 
    case SERVICE_VARIENT_REQUEST:
      return { ...state, Servicevarients: { ...state.Servicevarients, isLoading: true  } };
    case SERVICE_VARIENT_SUCCESS:
      return {  ...state,  Servicevarients: { ServiceVarient: action.payload,  error: '',  isLoading: false   }  };
    case SERVICE_VARIENT_FAIL:
      return {  ...state, Servicevarients: { ServiceVarient: [], error: action.payload, isLoading: false  } };
    default:
      return state;
  }
}