import {
    CORPORATEDATEANDCOUNT_REQUEST,
    CORPORATEDATEANDCOUNT_SUCCESS,
    CORPORATEDATEANDCOUNT_FAIL,

} from '../actions/corporatedynamicActions'

const initialState = {
    corporatedateandcounts: { all: [], error: '', isLoading: false },
};
export default function (state = initialState, action) {
    switch (action.type) {
        case CORPORATEDATEANDCOUNT_REQUEST:
            return { ...state, corporatedateandcounts: { all: state.corporatedateandcounts.all, error: '', isLoading: true } };
        case CORPORATEDATEANDCOUNT_SUCCESS:
            return { ...state, corporatedateandcounts: { all: action.payload, error: '', isLoading: false } };
        case CORPORATEDATEANDCOUNT_FAIL:
            return { ...state, corporatedateandcounts: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}

