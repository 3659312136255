import { nodeGetApi } from './../commonAxios';
export const STATISTICS_ORDER = "STATISTICS_ORDER";
export const STATISTICS_PROFIT = "STATISTICS_PROFIT";
export const STATISTICS_CASHBACK = "STATISTICS_CASHBACK";
export const STATISTICS_USERS = "STATISTICS_USERS";
export const STATISTICS_PARTNERS = "STATISTICS_PARTNERS";
export const STATISTICS_BRANDS = "STATISTICS_BRANDS";
export const STATISTICS_COUPONS = "STATISTICS_COUPONS";
export const STATISTICS_DEALS = "STATISTICS_DEALS";
export const STATISTICS_PRODUCTS = "STATISTICS_PRODUCTS";

/*=============================================================
                  orderStatistics Action
===============================================================*/
export const orderStatistics = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "",
            "Year": ""
        };
        nodeGetApi.post(`/Reports/Orders/Statistics`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: STATISTICS_ORDER,
                        data: data.results,
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: STATISTICS_ORDER,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: STATISTICS_ORDER,
                    data: String(error),
                    succress: false
                });
            })
    }
}

/*=============================================================
                  profitStatistics Action
===============================================================*/
export const profitStatistics = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "",
            "Year": ""
        };
        nodeGetApi.post(`/Reports/ESI_Profit/Statistics`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: STATISTICS_PROFIT,
                        data: data.results,
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: STATISTICS_PROFIT,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: STATISTICS_PROFIT,
                    data: String(error),
                    succress: false
                });
            })
    }
}

/*=============================================================
                  cashbackStatistics Action
===============================================================*/
export const cashbackStatistics = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "",
            "Year": ""
        };
        nodeGetApi.post(`/Reports/Users_Profit/Statistics`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: STATISTICS_CASHBACK,
                        data: data.results,
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: STATISTICS_CASHBACK,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: STATISTICS_CASHBACK,
                    data: String(error),
                    succress: false
                });
            })
    }
}

/*=============================================================
                  usersStatistics Action
===============================================================*/
export const usersStatistics = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "",
            "Year": ""
        };
        nodeGetApi.post(`/Reports/Users/Statistics`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: STATISTICS_USERS,
                        data: data.results,
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: STATISTICS_USERS,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: STATISTICS_USERS,
                    data: String(error),
                    succress: false
                });
            })
    }
}

/*=============================================================
                  partnersStatistics Action
===============================================================*/
export const partnersStatistics = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "",
            "Year": ""
        };
        nodeGetApi.post(`/Reports/Partners/Statistics`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: STATISTICS_PARTNERS,
                        data: data.results,
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: STATISTICS_PARTNERS,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: STATISTICS_PARTNERS,
                    data: String(error),
                    succress: false
                });
            })
    }
}

/*=============================================================
                 brandsStatistics Action
===============================================================*/
export const brandsStatistics = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "",
            "Year": ""
        };
        nodeGetApi.post(`/Reports/Brands/Statistics`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: STATISTICS_BRANDS,
                        data: data.results,
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: STATISTICS_BRANDS,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: STATISTICS_BRANDS,
                    data: String(error),
                    succress: false
                });
            })
    }
}

/*=============================================================
                 couponsStatistics Action
===============================================================*/
export const couponsStatistics = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "",
            "Year": ""
        };
        nodeGetApi.post(`/Reports/Coupons/Statistics`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: STATISTICS_COUPONS,
                        data: data.results,
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: STATISTICS_COUPONS,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: STATISTICS_COUPONS,
                    data: String(error),
                    succress: false
                });
            })
    }
}

/*=============================================================
                 dealsStatistics Action
===============================================================*/
export const dealsStatistics = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "",
            "Year": ""
        };
        nodeGetApi.post(`/Reports/Deals/Statistics`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: STATISTICS_DEALS,
                        data: data.results,
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: STATISTICS_DEALS,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: STATISTICS_DEALS,
                    data: String(error),
                    succress: false
                });
            })
    }
}

/*=============================================================
                 productsStatistics Action
===============================================================*/
export const productsStatistics = () => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "",
            "Year": ""
        };
        nodeGetApi.post(`/Reports/Products/Statistics`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Is_Data_Exist === '1') {
                    dispatch({
                        type: STATISTICS_PRODUCTS,
                        data: data.results,
                        succress: true
                    });
                }
                else {
                    dispatch({
                        type: STATISTICS_PRODUCTS,
                        data: data.Success_Response.UI_Display_Message,
                        succress: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: STATISTICS_PRODUCTS,
                    data: String(error),
                    succress: false
                });
            })
    }
}