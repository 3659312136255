import { toast } from 'react-toastify';
import config from './config';
import { paginationDeliveryFaqGet } from './node-actions/nodepagination';
import { nodeCudApi} from './commonAxios';
export const DELIVERY_FAQ_CREATE_REQUEST = "DELIVERY_FAQ_CREATE_REQUEST";
export const DELIVERY_FAQ_CREATE_SUCCESS = "DELIVERY_FAQ_CREATE_SUCCESS";
export const DELIVERY_FAQ_CREATE_FAIL = "DELIVERY_FAQ_CREATE_FAIL";
export const DELIVERY_FAQ_UPDATE_REQUEST = "DELIVERY_FAQ_UPDATE_REQUEST";
export const DELIVERY_FAQ_UPDATE_SUCCESS = "DELIVERY_FAQ_UPDATE_SUCCESS";
export const DELIVERY_FAQ_UPDATE_FAIL = "DELIVERY_FAQ_UPDATE_FAIL";
export const RESET = "RESET";
/*=============================================================
                  Add FAQ Action
===============================================================*/

export const adddeliveryFaqAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: DELIVERY_FAQ_CREATE_REQUEST
  });
  nodeCudApi.post(`/Delivery_FAQ/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DELIVERY_FAQ_CREATE_SUCCESS
        });
        toast('Delivery FAQ added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/deliveryfaq-management');
      } else {
        dispatch({
          type: DELIVERY_FAQ_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: DELIVERY_FAQ_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                   Update Delivery Faq Action
===============================================================*/
export const updatedeliveryFaqAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: DELIVERY_FAQ_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Delivery_FAQ/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DELIVERY_FAQ_UPDATE_SUCCESS
        });
        toast('Delivery FAQ updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/deliveryfaq-management');
      } else {
        dispatch({
          type: DELIVERY_FAQ_UPDATE_SUCCESS,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: DELIVERY_FAQ_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

/*=============================================================
               Delete delivery FAQ ById Action
===============================================================*/
export const deletedeliveryFaqById = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`${config.nodecudurl}/Delivery_FAQ/Delete`, formData);
    if (data) {
      toast('Delivery FAQ deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationDeliveryFaqGet(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};