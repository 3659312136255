import config from './config';
import { toast } from 'react-toastify';
import { boomiAdminApi, nodeGetApi } from './commonAxios';

export const USER_RIDE_BIDDING_CREATE_REQUEST = "USER_RIDE_BIDDING_CREATE_REQUEST";
export const USER_RIDE_BIDDING_CREATE_SUCCESS = "USER_RIDE_BIDDING_CREATE_SUCCESS";
export const USER_RIDE_BIDDING_CREATE_FAIL = "USER_RIDE_BIDDING_CREATE_FAIL";

export const USER_RIDE_BIDDING_UPDATE_REQUEST = "USER_RIDE_BIDDING_UPDATE_REQUEST";
export const USER_RIDE_BIDDING_UPDATE_SUCCESS = "USER_RIDE_BIDDING_UPDATE_SUCCESS";
export const USER_RIDE_BIDDING_UPDATE_FAIL = "USER_RIDE_BIDDING_UPDATE_FAIL";

export const BIDDING_CITIES_GET_REQUEST = "BIDDING_CITIES_GET_REQUEST";
export const BIDDING_CITIES_GET_SUCCESS = "BIDDING_CITIES_GET_SUCCESS";
export const BIDDING_CITIES_GET_FAIL = "BIDDING_CITIES_GET_FAIL";

export const BID_BRAND_VEHICLES_GET_REQUEST = "BID_BRAND_VEHICLES_GET_REQUEST";
export const BID_BRAND_VEHICLES_GET_SUCCESS = "BID_BRAND_VEHICLES_GET_SUCCESS";
export const BID_BRAND_VEHICLES_GET_FAIL = "BID_BRAND_VEHICLES_GET_FAIL";

export const BID_VEHICLES_GET_REQUEST = "BID_VEHICLES_GET_REQUEST";
export const BID_VEHICLES_GET_SUCCESS = "BID_VEHICLES_GET_SUCCESS";
export const BID_VEHICLES_GET_FAIL = "BID_VEHICLES_GET_FAIL";

export const BID_RIDE_QUOTES_GET_REQUEST = "BID_RIDE_QUOTES_GET_REQUEST";
export const BID_RIDE_QUOTES_GET_SUCCESS = "BID_RIDE_QUOTES_GET_SUCCESS";
export const BID_RIDE_QUOTES_GET_FAIL = "BID_RIDE_QUOTES_GET_FAIL";

export const BID_USER_SEARCH_GET_REQUEST = "BID_USER_SEARCH_GET_REQUEST";
export const BID_USER_SEARCH_GET_SUCCESS = "BID_USER_SEARCH_GET_SUCCESS";
export const BID_USER_SEARCH_GET_FAIL = "BID_USER_SEARCH_GET_FAIL";

export const BID_RIDE_DELI_PAYMENT_REQUEST = "BID_RIDE_DELI_PAYMENT_REQUEST";
export const BID_RIDE_DELI_PAYMENT_SUCCESS = "BID_RIDE_DELI_PAYMENT_SUCCESS";
export const BID_RIDE_DELI_PAYMENT_FAIL = "BID_RIDE_DELI_PAYMENT_FAIL";

//create User Ride Bidding Action
export const createUserRideBidding = (formData, navigate) => async dispatch => {
    dispatch({
        type: USER_RIDE_BIDDING_CREATE_REQUEST
    });
    boomiAdminApi.post(`/User_Rides_Bidding/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: USER_RIDE_BIDDING_CREATE_SUCCESS
                });
                toast('User Ride Bidding created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/user-ride-biddings');
            } else {
                dispatch({
                    type: USER_RIDE_BIDDING_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: USER_RIDE_BIDDING_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

//update User Ride Bidding Action
export const updateUserRideBidding = (formData, navigate) => async dispatch => {
    dispatch({
        type: USER_RIDE_BIDDING_UPDATE_REQUEST
    });
    boomiAdminApi.put(`/User_Rides_Bidding/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: USER_RIDE_BIDDING_UPDATE_SUCCESS
                });
                toast('User Ride Bidding updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/user-ride-biddings');
            } else {

                dispatch({
                    type: USER_RIDE_BIDDING_UPDATE_FAIL,
                    payload: successResponse.Response_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: USER_RIDE_BIDDING_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//get all Bidding Ride User Address
export const getAllBidRideUserAddress = (formData, callBackData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/User/Address_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=User_Address_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.Is_Data_Exist === "0") {
                callBackData([])
            } else {
                callBackData(data.results)
            }
        }
    } catch (err) {
        dispatch({
            payload: err
        });
    }
};

//get all Bidding cities
export const getAllBiddingCities = formData => async dispatch => {
    try {
        dispatch({
            type: BIDDING_CITIES_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Shop_By_City/City_List_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=City_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: BIDDING_CITIES_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BIDDING_CITIES_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BIDDING_CITIES_GET_FAIL,
            payload: err
        });
    }
};

//get all Bidding Brand Vehicles
export const getAllBidRideBrandVehicles = formData => async dispatch => {
    try {
        dispatch({
            type: BID_BRAND_VEHICLES_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Bidding_Ride_Brand_Vehicle/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Bidding_Ride_Brand_Vehicle_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: BID_BRAND_VEHICLES_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BID_BRAND_VEHICLES_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BID_BRAND_VEHICLES_GET_FAIL,
            payload: err
        });
    }
};

//get all Bidding Vehicles
export const getAllBidRideVehicles = formData => async dispatch => {
    try {
        dispatch({
            type: BID_VEHICLES_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Bidding_Vehicle_Details/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Bidding_Vehicle_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: BID_VEHICLES_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BID_VEHICLES_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BID_VEHICLES_GET_FAIL,
            payload: err
        });
    }
};

//get all Bidding Ride Quotes
export const getAllBidRideQuotes = formData => async dispatch => {
    try {
        dispatch({
            type: BID_RIDE_QUOTES_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Bidding_Rides_Quotes/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Delivery_Bidding_Quote_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: BID_RIDE_QUOTES_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BID_RIDE_QUOTES_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BID_RIDE_QUOTES_GET_FAIL,
            payload: err
        });
    }
};

//user search
export const getAllBidUsersSearchAction = (emailid) => async dispatch => {
    try {
        let formData = {
            Email_Id: emailid,
            Records_Filter: "FILTER"
        };
        dispatch({
            type: BID_USER_SEARCH_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Super_Admin/User_Get_dropdown_search?PageNo=1&PageSize=${config.pageSize}&SortBy=Email_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.Success_Response.Is_Data_Exist === '0') {
                dispatch({
                    type: BID_USER_SEARCH_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BID_USER_SEARCH_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BID_USER_SEARCH_GET_FAIL,
            payload: err
        });
    }
};

//ride bidding delivery payments
export const createBidRideDeliPaymentAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: BID_RIDE_DELI_PAYMENT_REQUEST
    });
    boomiAdminApi.post(`/User_Rides_Bidding/Payment_Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: BID_RIDE_DELI_PAYMENT_SUCCESS
                });
                toast('Payment created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/user-ride-biddings');
            } else {
                dispatch({
                    type: BID_RIDE_DELI_PAYMENT_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: BID_RIDE_DELI_PAYMENT_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

//Get User Bidding Rides
export const getBiddingUserRideAction = (formData, callBackBiddingRidesData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/User_Rides_Bidding/Get`, formData);
        if (data) {
            callBackBiddingRidesData(data)
        }
    } catch (err) {
    }
};