import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    makeStyles,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    images: {
        width: 70,
        height: 70,
        cursor: 'pointer'
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    },
}));

const headCells = [
    { id: 'Label', numeric: false, disablePadding: false, label: 'Label' },
    { id: 'Value', numeric: false, disablePadding: false, label: 'Value' },
    { id: 'Is_Write', numeric: false, disablePadding: false, label: 'Is Write' },
    { id: 'Is_Read', numeric: false, disablePadding: false, label: 'Is Read' },
    { id: 'Is_Hide', numeric: false, disablePadding: false, label: 'Is Hide' },
];

const Results = ({
    className,
    allPermissions,
    pagination,
    setPagination,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    allPermissionsNoFilter,
    createSortHandler,
    RolePermissions,
    roleUserType,
    ...rest
}) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);

    const visiblePermissionHandler = permissionData => e => {
        navigate("/app/view-permission", { state: permissionData });
    };
    const editPermissionHandler = permissionData => e => {
        navigate('/app/edit-permission', { state: permissionData });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel style={{ fontSize: "13px", fontWeight: "bold" }}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: "-5rem" }} className={classes.headerColumn}> Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allPermissions) &&
                                (allPermissions).map(permission => (
                                    <TableRow
                                        hover
                                        key={permission.Label}
                                        selected={
                                            selectedPermissionIds.indexOf(permission.Label) !== -1
                                        }>
                                        <TableCell>{permission.Label}</TableCell>
                                        <TableCell>{permission.Value}</TableCell>
                                        <TableCell>{permission.Is_Write}</TableCell>
                                        <TableCell>{permission.Is_Read}</TableCell>
                                        <TableCell>{permission.Is_Hide}</TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Permissions_Management_Write?.Value === "Permissions_Management_Write" && RolePermissions?.Permissions_Management_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="View Permission">
                                                    <Button
                                                        onClick={visiblePermissionHandler(permission)}
                                                        color="primary"
                                                    >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                                <Button
                                                    onClick={editPermissionHandler(permission)}
                                                >
                                                    <CreateIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                            </TableCell>
                                            : null}
                                        {RolePermissions?.Permissions_Management_Read?.Value === "Permissions_Management_Read" && RolePermissions?.Permissions_Management_Read?.Is_Read === 1 &&
                                            <TableCell>
                                                <Tooltip title="View Permission">
                                                    <Button
                                                        onClick={visiblePermissionHandler(permission)}
                                                        color="primary"
                                                    >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allPermissions) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <Pagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
};

export default Results;