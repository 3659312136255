import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/partner-payment-settlements',
    createdAt: 'Updated 2 hr ago',
    description:
      'Partner payment settlements provides the information about the order amount, cashback amount and the payment status related to partner payments.',
    media: '/static/Dashboard/partner_payment.jpg',
    title: 'Partners Payment Settlements',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/user-payment-settlements',
    createdAt: 'Updated 2 hr ago',
    description:
      'User payment settlements provides the information about the order amount, cashback amount and the payment status related to user payments.',
    media: '/static/Dashboard/partner_payment.jpg',
    title: 'Users Payment Settlements',
    total: ''
  }
];