import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    TextField,
    InputAdornment,
    SvgIcon,
    makeStyles,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    formFilterControl: {
        margin: theme.spacing(1),
        minWidth: 200
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

const Toolbar = ({ className, filters, setfilters, ...rest }) => {
    const classes = useStyles();

    const handleChange = event => {
        setfilters({
            search: filters?.search,
            search_by_filter: event.target.value,
            Records_Filter: "FILTER",
            Is_Schedule_Ride: "1"
        })
    };

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                alignItems="center"
                mb={2}
            >
                <Box display="flex" flexWrap="wrap" mb={2}>
                    <FormControl variant="outlined" className={classes.formFilterControl}>
                        <Select
                            value={filters.search_by_filter}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="User_Ride_Id">User Ride ID</MenuItem>
                            <MenuItem value="Driver_email_Id">Driver Email ID</MenuItem>
                            <MenuItem value="User_Email_Id">User Email ID</MenuItem>
                            <MenuItem value="Ride_Status">Ride Status</MenuItem>
                            <MenuItem value="Payment_Status">Payment Status</MenuItem>
                        </Select>
                    </FormControl>

                    <Box width={500}>
                        <TextField
                            onChange={(event) => {
                                setfilters({
                                    search: event.target.value,
                                    search_by_filter: filters.search_by_filter,
                                    Records_Filter: "FILTER",
                                    Is_Schedule_Ride: "1"
                                })
                            }}
                            className={classes.formControl}
                            value={filters?.search}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon fontSize="small" color="action">
                                            <SearchIcon />
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}
                            placeholder="Search User Rides"
                            variant="outlined"
                        />
                    </Box>
                </Box>

                <Box display="flex" flexWrap="wrap" mb={2}>
                    <FormControl variant="outlined" className={classes.formFilterControl}>
                        <Select
                            value="sched_user_rides"
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="user_rides" href="/app/user-rides" component="a">All Rides</MenuItem>
                            <MenuItem value="new_user_rides" href="/app/new-user-rides" component="a">New Rides</MenuItem>
                            <MenuItem value="comp_user_rides" href="/app/completed-user-rides" component="a">Completed Rides</MenuItem>
                            <MenuItem value="sched_user_rides" href="/app/scheduled-user-rides" component="a">Scheduled Rides</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

            </Box>
        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;