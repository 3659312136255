
import config from './config';
import { toast } from 'react-toastify';
import { paginationCities } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const CITYS_REQUEST = "CITYS_REQUEST";
export const CITYS_SUCCESS = "CITYS_SUCCESS";
export const CITYS_FAIL = "CITYS_FAIL";
export const CITY_GET_REQUEST = "CITY_GET_REQUEST";
export const CITY_GET_SUCCESS = "CITY_GET_SUCCESS";
export const CITY_GET_FAIL = "CITY_GET_FAIL";
export const CITY_CREATE_REQUEST = "CITY_CREATE_REQUEST";
export const CITY_CREATE_SUCCESS = "CITY_CREATE_SUCCESS";
export const CITY_CREATE_FAIL = "CITY_CREATE_FAIL";
export const CITY_UPDATE_REQUEST = "CITY_UPDATE_REQUEST";
export const CITY_UPDATE_SUCCESS = "CITY_UPDATE_SUCCESS";
export const CITY_UPDATE_FAIL = "CITY_UPDATE_FAIL";



export const createCityAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: CITY_CREATE_REQUEST
  });
  setErrorMessage({ Name: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Shop_By_City/City_List_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: CITY_CREATE_SUCCESS
        });
        toast('City added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/sbcm/city-management');
      } else {
        const errors = {};
        if (successResponse.Brand_Name) {
          errors.Brand_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Name: { key: successResponse.Name ? successResponse.Name : '', message: successResponse.Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: CITY_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: CITY_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                  Update City Action
===============================================================*/
export const updateCityAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: CITY_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Shop_By_City/City_List_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: CITY_UPDATE_SUCCESS
        });
        toast('City updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/sbcm/city-management');
      } else {
        dispatch({
          type: CITY_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: CITY_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};


/*=============================================================
                  Get All Citys Action
===============================================================*/
export const getAllCityAction = formData => async dispatch => {
  try {
    dispatch({
      type: CITYS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Super_Admin_City/Super_Admin_City_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=CITY_ID&SortOrder=ASC`,
      formData
     
    );
    if (data) {
      if (data.Success_Response?.Is_Data_Exist === '0') {
        dispatch({
          type: CITYS_SUCCESS,
          payload: []
        });
      } else {
        // let cityData = data.City_List_Get
        // if (cityData) {
        //   for (let i = 0; i < cityData.length; i++) {
        //     let con=csc.getCountryByCode(cityData[i].Country);
        //     cityData[i].Country_Name = con?con.name:'';
        //     let sta=csc.getStateByCodeAndCountry(cityData[i].State,cityData[i].Country);
        //     cityData[i].State_Name = sta?sta.name:'';
        //   }
        // }
        dispatch({
          type: CITYS_SUCCESS,
          // payload: cityData
          // payload: data.City_List_Get
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CITYS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Delete City Action
===============================================================*/
export const deleteCityAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Shop_By_City/City_List_Delete`, formData
      // Axios looks for the `auth` option, and, if it is set, formats a
      // basic auth header for you automatically.
  );
    if (data) {
      toast('City deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationCities(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

//Node API
export const getCityAction = (formData, callBackCityData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Super_Admin_City/Super_Admin_City_Get`,
      formData
    );
    if (data) {
      callBackCityData(data)
    }
  } catch (err) {
  }
};