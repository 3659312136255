import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateDealAction,
  uploadDealImage,
  getOnlinePhysicalPartners,
  getAllCategories,
  getAllBrands
} from './../../store/actions/dealAction';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { useLocation } from 'react-router-dom';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const discountType = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'A',
    label: 'Amount'
  },
  {
    value: 'P',
    label: 'Percentage'
  },

];

const esiCashbackTypeOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'A',
    label: 'Amount'
  },
  {
    value: 'P',
    label: 'Percentage'
  },
  {
    value: 'R',
    label: 'Reward Points'
  },
];

const statusOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const CashbacklabelOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'UPTO',
    label: 'UPTO'
  },
  {
    value: 'FLAT',
    label: 'FLAT'
  }
];

const DiscountlabelOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'UPTO',
    label: 'UPTO'
  },
  {
    value: 'FLAT',
    label: 'FLAT'
  }
];

const useStyles = makeStyles(() => ({
  root: {},
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditDeal = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  let { state } = location;

  // componentDidMount
  useEffect(() => {
    dispatch(getOnlinePhysicalPartners());
    dispatch(getAllBrands());
    dispatch(getAllCategories());
  }, []);

  const [errorMessage, setErrorMessage] = useState({ Title: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.deals.dealUpdate.isLoading);

  const partners = useSelector(state => state.deals.dealPartners.partners);
  const brands = useSelector(state => state.deals.dealBrands.brands);
  const categories = useSelector(state => state.deals.Categories.categories);
  const createdBy = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Deal_Description || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Deal_Description);

  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // img upload
  const [image, setImage] = useState({ preview: state.Image, raw: state.Image });
  const [imgUrl, setImgUrl] = useState(state.Image);
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadDealImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };
  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        Brand_Id: state.Brand_Id ? state.Brand_Id : "",
        Category_Id: state.Category_Id ? state.Category_Id : "",
        Category_Name: state.Category_Name ? state.Category_Name : "",
        Deal_Description: state.Deal_Description ? state.Deal_Description : "",
        Deal_Id: state.Deal_Id ? state.Deal_Id : "",
        Discount: state.Discount ? state.Discount : "",
        Discount_Type: state.Discount_Type ? state.Discount_Type : "",
        End_Date: state.End_Date ? state.End_Date : "",
        Esi_Cashback_Amount: state.Esi_Cashback_Amount ? state.Esi_Cashback_Amount : "",
        Esi_Cashback_Type: state.Esi_Cashback_Type ? state.Esi_Cashback_Type : "",
        Exclusive: state.Exclusive > 0 ? true : false,
        Extra_Cashback: state.Extra_Cashback > 0 ? true : false,
        Image: imgUrl,
        Is_Top_Deal: state.Is_Top_Deal > 0 ? true : false,
        Mobile_Redirect_Url: state.Mobile_Redirect_Url ? state.Mobile_Redirect_Url : "",
        Modified_By: createdBy,
        Partner_Details_Id: state.Partner_Details_Id ? state.Partner_Details_Id : "",
        Start_Date: state.Start_Date ? state.Start_Date : "",
        Status: state.Status ? state.Status : "",
        Title: state.Title ? state.Title : "",
        Type: state.Type ? state.Type : "",
        Verified_Deal: state.Verified_Deal > 0 ? true : false,
        Web_Redirect_Url: state.Web_Redirect_Url ? state.Web_Redirect_Url : "",
        Whats_Hot: state.Whats_Hot > 0 ? true : false,
        Cashback_Label: state.Cashback_Label ? state.Cashback_Label : "",
        Discount_Label: state.Discount_Label ? state.Discount_Label : "",
        Today_recommendations: state.Today_Recommendations > 0 ? true : false,
        Trending_Today: state.Trending_Today > 0 ? true : false
      }}
      validationSchema={
        Yup.object().shape({
          Partner_Details_Id: Yup.string().required('Partner Name is required.'),
          Title: Yup.string().min(5).notOneOf([errorMessage.Title.key, null], errorMessage.Title.message).required('Title is required.'),
          Category_Id: Yup.string().test(
            'oneOfRequired',
            'Category / Brand Name is required.',
            function (item) {
              return (this.parent.Category_Id || this.parent.Brand_Id)
            }
          ),
          Brand_Id: Yup.string().test(
            'oneOfRequired',
            'Category / Brand Name is required.',
            function (item) {
              return (this.parent.Category_Id || this.parent.Brand_Id)
            }
          ),
          Discount_Type: Yup.string().test(
            'oneOfRequired',
            'Discount Type / ESI Cashback Type is required.',
            function (item) {
              return (this.parent.Discount_Type || this.parent.Esi_Cashback_Type)
            }
          ),
          Discount: Yup.number().when("Discount_Type", (Discount_Type, schema) => {
            if (Discount_Type === "A") {
              return schema.min(1, "Discount Amount must be at least 1.").required('Discount Amount is required.');
            } else if (Discount_Type === "P") {
              return schema.min(1, "Discount Percentage must be at least 1.").max(100, "Discount Percentage must be <= 100.").required('Discount Percentage is required.');
            }
          }),
          Esi_Cashback_Type: Yup.string().test(
            'oneOfRequired',
            'Discount Type / ESI Cashback Type is required.',
            function (item) {
              return (this.parent.Discount_Type || this.parent.Esi_Cashback_Type)
            }
          ),
          Esi_Cashback_Amount: Yup.number().when("Esi_Cashback_Type", (Esi_Cashback_Type, schema) => {
            if (Esi_Cashback_Type === "A") {
              return schema.min(1, "ESI Cashback Amount must be at least 1.").required('ESI Cashback Amount is required.');
            } else if (Esi_Cashback_Type === "P") {
              return schema.min(1, "ESI Cashback Percentage must be at least 1.").max(100, "ESI Cashback Percentage must be <= 100.").required('ESI Cashback Percentage is required.');
            } else if (Esi_Cashback_Type === "R") {
              return schema.min(1, "ESI Cashback Reward Points must be at least 1.").max(100, "ESI Cashback Reward Points must be <= 100.").required('ESI Cashback Reward Points are required.');
            }
          }),
          Extra_Cashback: Yup.string().required('Extra Cashback Type is required.'),
          Status: Yup.string().required('Status is required.'),
          Start_Date: Yup.date().min(new Date(state.Start_Date) < new Date(Date.now() - 1 * 24 * 60 * 60 * 1000) ? new Date(new Date(state.Start_Date) - 1 * 24 * 60 * 60 * 1000) : new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), new Date(state.Start_Date) < new Date(Date.now() - 1 * 24 * 60 * 60 * 1000) ? "Start Date greater than or equal to " + ((new Date(state.Start_Date).getMonth() > 8) ? (new Date(state.Start_Date).getMonth() + 1) : ('0' + (new Date(state.Start_Date).getMonth() + 1))) + '/' + ((new Date(state.Satrt_Date).getDate() > 9) ? new Date(state.Start_Date).getDate() : ('0' + new Date(state.Start_Date).getDate())) + '/' + new Date(state.Start_Date).getFullYear() + "." : "Require start Date greater than or equal to today.").required("Start Date is required."),
          End_Date: Yup.date().when("Start_Date", (Start_Date, schema) => Start_Date && schema.min(Start_Date, "End date can't be before Start date.")).required("End Date is required."),
          Web_Redirect_Url: Yup.string().when("Type", (Type, schema) => Type === "Online" ? schema.url('Please provide valid URL.').required("Web Redirection URL is required.") : schema.url('Please provide valid URL.')),
          Mobile_Redirect_Url: Yup.string().when("Type", (Type, schema) => Type === "Online" ? schema.url('Please provide valid URL.').required("Mobile Redirect URL is required.") : schema.url('Please provide valid URL.')),
          Discount_Label: Yup.string().when("Discount", (Discount, schema) => {
            if (Discount !== "") {
              return schema.required('Discount Label is required.');
            }
          }),
          Cashback_Label: Yup.string().when("Esi_Cashback_Amount", (Esi_Cashback_Amount, schema) => {
            if (Esi_Cashback_Amount !== "") {
              return schema.required('ESI Cashback Label is required.');
            }
          }),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};

        values.Image = imgUrl;
        values.Deal_Description = rhDiscription;
        if (!values.Image) {
          submitTest = false;
          errors.Image = "Image is required.";
        }
        if (!values.Deal_Description || values.Deal_Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Deal_Description = "Description is required.";
        }
        else if (values.Deal_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Deal_Description = "Please provide at least " + rhDiscriptionMin + " characters";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          if (values.Verified_Deal) {
            formValues.Verified_Deal = 1;
          } else {
            formValues.Verified_Deal = 0;
          }
          if (values.Exclusive) {
            formValues.Exclusive = 1;
          } else {
            formValues.Exclusive = 0;
          }
          if (values.Whats_Hot) {
            formValues.Whats_Hot = 1;
          } else {
            formValues.Whats_Hot = 0;
          }
          if (values.Is_Top_Deal) {
            formValues.Is_Top_Deal = 1;
          } else {
            formValues.Is_Top_Deal = 0;
          }
          if (values.Extra_Cashback) {
            formValues.Extra_Cashback = 1;
          } else {
            formValues.Extra_Cashback = 0;
          }
          if (values.Discount_Type === "") {
            formValues.Discount = 0;
          }
          if (values.Esi_Cashback_Type === "") {
            formValues.Esi_Cashback_Amount = 0;
          }
          if (values.Trending_Today) {
            formValues.Trending_Today = 1;
          } else {
            formValues.Trending_Today = 0;
          }
          if (values.Today_recommendations) {
            formValues.Today_recommendations = 1;
          } else {
            formValues.Today_recommendations = 0;
          }
          dispatch(updateDealAction(formValues, navigate, setErrorMessage, setErrors));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Partner Type"
                        name="Type"
                        value={values.Type}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {/* patner name */}
                      <TextField
                        disabled={true}
                        error={Boolean(touched.Partner_Details_Id && errors.Partner_Details_Id)}
                        fullWidth
                        helperText={touched.Partner_Details_Id && errors.Partner_Details_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Partner Name"
                        InputLabelProps={{ shrink: true }}
                        name="Partner_Details_Id"
                        select
                        SelectProps={{ native: true }}
                        value={partners.length > 0 ? values.Partner_Details_Id : ""}
                        variant="outlined"
                      ><option key="" value="">-Please Select-</option>
                        {partners.filter(option => values.Type === option.Type
                        ).map(option => (
                          <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                            {option.Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {/* Category name */}
                      <TextField
                        error={Boolean(touched.Category_Id && errors.Category_Id)}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Category Name"
                        InputLabelProps={{ shrink: true }}
                        name="Category_Id"
                        select
                        SelectProps={{ native: true }}
                        value={values.Category_Id}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {categories?.sort((a, b) => a.Category_Name?.localeCompare(b.Category_Name))?.map(option => (
                          <option key={option.Category_Id} value={option.Category_Id}>
                            {option.Category_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Brand Name */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Brand_Id && errors.Brand_Id)}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Brand Name"
                        name="Brand_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Brand_Id}
                        variant="outlined"
                      >
                        <option key="" value="">--Please Select--</option>
                        {brands?.sort((a, b) => a.Brand_Name?.localeCompare(b.Brand_Name))?.map(option => (
                          <option key={option.Brand_Id} value={option.Brand_Id}>
                            {option.Brand_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {((touched.Brand_Id || touched.Category_Id) && errors.Brand_Id) &&
                      <Grid item xs={12}>
                        <ErrorMessage name="Brand_Id">
                          {msg => <div style={{ color: 'red', marginTop: "-10px", textAlign: "center" }}>{msg}</div>}
                        </ErrorMessage>
                      </Grid>
                    }

                    <Grid item md={6} xs={12}>
                      {/* coupon name */}
                      <TextField
                        error={Boolean(touched.Discount_Type && errors.Discount_Type)}
                        fullWidth
                        helperText={touched.Discount_Type && errors.Discount_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Discount Type"
                        name="Discount_Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Discount_Type}
                        variant="outlined"
                      >
                        {discountType.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/*  discount Type */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Discount && errors.Discount)}
                        fullWidth
                        helperText={touched.Discount && errors.Discount}
                        disabled={values.Discount_Type !== "" ? false : true}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        label={"Discount " + (values.Discount_Type === "A" ? "Amount ₹" : values.Discount_Type === "P" ? "Percentage %" : "")}
                        name="Discount"
                        value={values.Discount}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Discount Label input field */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Discount_Label && errors.Discount_Label)}
                        fullWidth
                        helperText={touched.Discount_Label && errors.Discount_Label}
                        disabled={values.Discount !== "" ? false : true}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Discount Label"
                        name="Discount_Label"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        value={values.Discount_Label}
                        variant="outlined"
                      >
                        {DiscountlabelOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Coupon Discount Amt/ % */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Esi_Cashback_Type && errors.Esi_Cashback_Type)}
                        fullWidth
                        helperText={touched.Esi_Cashback_Type && errors.Esi_Cashback_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="ESI Cashback Type"
                        name="Esi_Cashback_Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Esi_Cashback_Type}
                        variant="outlined"
                      >
                        {esiCashbackTypeOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* ESI Cashback AMT/% */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Esi_Cashback_Amount && errors.Esi_Cashback_Amount)}
                        fullWidth
                        disabled={values.Esi_Cashback_Type !== "" ? false : true}
                        helperText={touched.Esi_Cashback_Amount && errors.Esi_Cashback_Amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        label={"ESI Cashback " + (values.Esi_Cashback_Type === "A" ? "Amount ₹" : values.Esi_Cashback_Type === "P" ? "Percentage %" : values.Esi_Cashback_Type === "R" ? "Reward Points" : "")}
                        name="Esi_Cashback_Amount"
                        value={values.Esi_Cashback_Amount}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Cashback Label input field */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Cashback_Label && errors.Cashback_Label)}
                        fullWidth
                        helperText={touched.Cashback_Label && errors.Cashback_Label}
                        disabled={values.Esi_Cashback_Amount !== "" ? false : true}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="ESI Cashback Label"
                        name="Cashback_Label"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        value={values.Cashback_Label}
                        variant="outlined"
                      >
                        {CashbacklabelOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Web_Redirect_Url && errors.Web_Redirect_Url)}
                        fullWidth
                        helperText={touched.Web_Redirect_Url && errors.Web_Redirect_Url}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Web Redirect URL"
                        name="Web_Redirect_Url"
                        value={values.Web_Redirect_Url}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Mobile_Redirect_Url */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Mobile_Redirect_Url && errors.Mobile_Redirect_Url)}
                        fullWidth
                        helperText={touched.Mobile_Redirect_Url && errors.Mobile_Redirect_Url}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Mobile Redirect URL"
                        name="Mobile_Redirect_Url"
                        value={values.Mobile_Redirect_Url}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Discription  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Deal_Description && errors.Deal_Description}
                      </span>
                    </Grid>
                    {/*  valid from date */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Start_Date && errors.Start_Date)}
                        fullWidth
                        helperText={touched.Start_Date && errors.Start_Date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Start Date"
                        type= "datetime-local"
                        name="Start_Date"
                        defaultValue={values.Start_Date}
                        variant="outlined"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {/* valid through date/ % */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.End_Date && errors.End_Date)}
                        fullWidth
                        helperText={touched.End_Date && errors.End_Date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="End Date"
                        type= "datetime-local"
                        name="End_Date"
                        defaultValue={values.End_Date}
                        variant="outlined"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {/* Varified */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Verified_Deal}
                            onChange={handleChange}
                            name="Verified_Deal"
                            color="primary"
                          />
                        }
                        label="Verified"
                      />
                    </Grid>

                    {/* Exclusive */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Exclusive}
                            onChange={handleChange}
                            name="Exclusive"
                            color="primary"
                          />
                        }
                        label="Exclusive"
                      />

                    </Grid>
                    {/* Extra Cash Back */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Extra_Cashback}
                            onChange={handleChange}
                            name="Extra_Cashback"
                            color="primary"
                          />
                        }
                        label="Extra CashBack"
                      />
                    </Grid>
                    {/* Whats_Hot */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Whats_Hot}
                            onChange={handleChange}
                            name="Whats_Hot"
                            color="primary"
                          />
                        }
                        label="Whats Hot"
                      />
                    </Grid>
                    {/* Is_Top_Coupon */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Top_Deal}
                            onChange={handleChange}
                            name="Is_Top_Deal"
                            color="primary"
                          />
                        }
                        label="Is Top Deal"
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Trending_Today}
                            onChange={handleChange}
                            name="Trending_Today"
                            color="primary"
                          />
                        }
                        label="Trending Today"
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Today_recommendations}
                            onChange={handleChange}
                            name="Today_recommendations"
                            color="primary"
                          />
                        }
                        label="Today Recommendations"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Title && errors.Title)}
                        fullWidth
                        helperText={touched.Title && errors.Title}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Title"
                        name="Title"
                        value={values.Title}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                  </Grid>
                </Grid>
                {/* upload photo */}
                <Grid item md={4} xs={12}>
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Brand Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                  <span style={{ color: "red" }}>
                    {!imgUrl && touched.Image && errors.Image}
                  </span>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited || uploadPic}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditDeal.propTypes = {
  className: PropTypes.string
};

export default EditDeal;