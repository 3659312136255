import {
    ORDERDATEANDCOUNT_REQUEST,
    ORDERDATEANDCOUNT_SUCCESS,
    ORDERDATEANDCOUNT_FAIL,

} from '../actions/orderdynamicActions'

const initialState = {
    orderdateandcounts: { all: [], error: '', isLoading: false },
};
export default function (state = initialState, action) {
    switch (action.type) {
        case ORDERDATEANDCOUNT_REQUEST:
            return { ...state, orderdateandcounts: { all: state.orderdateandcounts.all, error: '', isLoading: true } };
        case ORDERDATEANDCOUNT_SUCCESS:
            return { ...state, orderdateandcounts: { all: action.payload, error: '', isLoading: false } };
        case ORDERDATEANDCOUNT_FAIL:
            return { ...state, orderdateandcounts: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}

