
import { toast } from 'react-toastify';
import config from './config';
import { paginationRelatedProducts } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const RPRODUCTS_REQUEST = "RPRODUCTS_REQUEST";
export const RPRODUCTS_SUCCESS = "RPRODUCTS_SUCCESS";
export const RPRODUCTS_FAIL = "RPRODUCTS_FAIL";

export const RELATED_PRODUCT_CREATE_REQUEST = "RELATED_PRODUCT_CREATE_REQUEST";
export const RELATED_PRODUCT_CREATE_SUCCESS = "RELATED_PRODUCT_CREATE_SUCCESS";
export const RELATED_PRODUCT_CREATE_FAIL = "RELATED_PRODUCT_CREATE_FAIL";

export const RELATED_PRODUCT_UPDATE_REQUEST = "PARTNER_UPDATE_REQUEST";
export const RELATED_PRODUCT_UPDATE_SUCCESS = "PARTNER_UPDATE_SUCCESS";
export const RELATED_PRODUCT_UPDATE_FAIL = "PARTNER_UPDATE_FAIL";
export const PRODUCTS_FILTER_REQUEST = "PRODUCTS_FILTER_REQUEST";
export const PRODUCTS_FILTER_SUCCESS = "PRODUCTS_FILTER_SUCCESS";
export const PRODUCTS_FILTER_FAIL = "PRODUCTS_FILTER_FAIL";

/*=============================================================
                Editrelatedproduct Action
===============================================================*/
export const EditrelatedproductAction = (formData, Product_Id, navigate, state) => async dispatch => {
  dispatch({
    type: RELATED_PRODUCT_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Products/Related_Product_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: RELATED_PRODUCT_UPDATE_SUCCESS
        });
        toast('Related Product updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/product/related-products-management', { state: state });
        const formData = {
          Records_Filter: 'FILTER',
          Product_Id: Product_Id
        };
        dispatch(getAllRProductAction(formData));
      } else {
        dispatch({
          type: RELATED_PRODUCT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: RELATED_PRODUCT_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working."
      });
    })
};

/*=============================================================
                create related product  Action
===============================================================*/
export const createRelatedProductAction = (formData, state, navigate,) => async dispatch => {
  dispatch({
    type: RELATED_PRODUCT_CREATE_REQUEST
  });
  nodeCudApi.post(`/Products/Related_Product_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: RELATED_PRODUCT_CREATE_SUCCESS
        });
        toast('Related Product added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/product/related-products-management', { state: state });
      } else {
        dispatch({
          type: RELATED_PRODUCT_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: RELATED_PRODUCT_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
              Get All Related Products Action
===============================================================*/
export const getAllRProductAction = formData => async dispatch => {
  try {
    dispatch({
      type: RPRODUCTS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Products/Related_Product_Get?PageNo=0&PageSize=${config.pageSize}&SortBy=RELATED_PRODUCT_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: RPRODUCTS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: RPRODUCTS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: RPRODUCTS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
               deleteRProduct Action
===============================================================*/
export const deleteRProductAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Products/Related_Product_Delete`, formData
    );
    if (data) {
      toast('Related Product deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationRelatedProducts(filters, pagination, callBackPagination));
    }
  } catch (err) { }
};
/*=============================================================
                Get All Product Action
===============================================================*/
export const getAllProductsFilterAction = (ProductName) => async dispatch => {
  try {
    let formData = {
      "Product_Name": ProductName,
      "Records_Filter": "FILTER"
    };
    dispatch({
      type: PRODUCTS_FILTER_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `${config.nodeurl}/Products/Product_Admin_Get_Dropdown_Search?PageNo=0&PageSize=${config.pageSize}&SortBy=PRODUCT_NAME&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCTS_FILTER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCTS_FILTER_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCTS_FILTER_FAIL,
      payload: err
    });
  }
};