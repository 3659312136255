import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Tooltip from '@material-ui/core/Tooltip';
import CommentIcon from '@material-ui/icons/Comment';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Ticket_Id', numeric: false, disablePadding: false, label: 'Ticket ID' },
  { id: 'Ticket_Subject', numeric: false, disablePadding: false, label: 'Ticket Subject' },
  { id: 'Ticket_Owner', numeric: false, disablePadding: false, label: 'Ticket Owner' },
  { id: 'Support_Owner', numeric: false, disablePadding: false, label: 'Support Owner' },
];

const Results = ({
  className,
  allTicket,
  deleteTicketHandler,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allTicketNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {

  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedTicketIds, setSelectedTicketIds] = useState([]);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedTicketIds;
    if (event.target.checked) {
      newSelectedTicketIds = allTicket.map(ticket => ticket.Ticket_Id);
    } else {
      newSelectedTicketIds = [];
    }
    setSelectedTicketIds(newSelectedTicketIds);
  };

  const handleSelectOne = (event, id) => {
    setSelectedTicketIds(selectedTicketIds.filter(record => {
      for (let i = 0; i < allTicketNoFilter.length; i++) {
        if (record === allTicketNoFilter[i].Ticket_Id) {
          return true;
        }
      }
      return false;
    }))
    const selectedIndex = selectedTicketIds.indexOf(id);
    let newSelectedTicketIds = [];
    if (selectedIndex === -1) {
      newSelectedTicketIds = newSelectedTicketIds.concat(selectedTicketIds, id);
    } else if (selectedIndex === 0) {
      newSelectedTicketIds = newSelectedTicketIds.concat(
        selectedTicketIds.slice(1)
      );
    } else if (selectedIndex === selectedTicketIds.length - 1) {
      newSelectedTicketIds = newSelectedTicketIds.concat(
        selectedTicketIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedTicketIds = newSelectedTicketIds.concat(
        selectedTicketIds.slice(0, selectedIndex),
        selectedTicketIds.slice(selectedIndex + 1)
      );
    }
    setSelectedTicketIds(newSelectedTicketIds);
  };

  const deleteSelectedTicketHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const editTicketHandler = ticketData => e => {
    navigate('/app/edit-partnerticket', { state: ticketData });
  };
  const TicketHandler = ticketData => e => {
    navigate('/app/partner-ticketing-management-comments', { state: ticketData });
  };
  const ViewTicketHandler = ticketData => e => {
    navigate('/app/partner-ticketing-management-view', { state: ticketData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedTicketIds.filter(record => {
                      for (let i = 0; i < allTicketNoFilter.length; i++) {
                        if (record === allTicketNoFilter[i].Ticket_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allTicket.length}
                    color="primary"
                    indeterminate={
                      selectedTicketIds.filter(record => {
                        for (let i = 0; i < allTicketNoFilter.length; i++) {
                          if (record === allTicketNoFilter[i].Ticket_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedTicketIds.filter(record => {
                        for (let i = 0; i < allTicketNoFilter.length; i++) {
                          if (record === allTicketNoFilter[i].Ticket_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allTicket.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Partner_Ticketing_Management_Write?.Value === "Partner_Ticketing_Management_Write" && RolePermissions?.Partner_Ticketing_Management_Write?.Is_Write === 1) ?
                  <TableCell >Comments</TableCell>
                  : null}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>&nbsp;&nbsp;&nbsp;&nbsp;Actions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedTicketIds.filter(record => {
                    for (let i = 0; i < allTicketNoFilter.length; i++) {
                      if (record === allTicketNoFilter[i].Ticket_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedTicketIds.filter(record => {
                    for (let i = 0; i < allTicketNoFilter.length; i++) {
                      if (record === allTicketNoFilter[i].Ticket_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedTicketHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allTicket) &&
                (allTicket).map(ticket => (

                  <TableRow
                    hover
                    key={ticket.Ticket_Id}
                    selected={
                      selectedTicketIds.indexOf(ticket.Ticket_Id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedTicketIds.indexOf(ticket.Ticket_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, ticket.Ticket_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell width="10%">{ticket.Ticket_Id}</TableCell>
                    <TableCell>{ticket.Ticket_Subject}</TableCell>
                    <TableCell>
                      {ticket.Ticket_Owner?.substring(0, 18)}...
                    </TableCell>
                    <TableCell>
                      {ticket.Support_Owner?.substring(0, 18)}...
                    </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Partner_Ticketing_Management_Write?.Value === "Partner_Ticketing_Management_Write" && RolePermissions?.Partner_Ticketing_Management_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Tooltip title="Comments">
                          <Button>
                            <CommentIcon
                              onClick={TicketHandler(ticket)}
                              style={{ display: 'flex', justifyContent: 'center', color: '#9EA0A5', fontSize: 20, }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                      : null}
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Partner_Ticketing_Management_Write?.Value === "Partner_Ticketing_Management_Write" && RolePermissions?.Partner_Ticketing_Management_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Button
                          onClick={editTicketHandler(ticket)}>
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Tooltip title="Ticket Details">
                          <Button
                            onClick={ViewTicketHandler(ticket)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        <Button>
                          <DeleteIcon
                            onClick={deleteTicketHandler(ticket)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Partner_Ticketing_Management_Read?.Value === "Partner_Ticketing_Management_Read" && RolePermissions?.Partner_Ticketing_Management_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="Ticket Details">
                          <Button
                            onClick={ViewTicketHandler(ticket)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allTicket) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allTicket: PropTypes.array.isRequired
};

export default Results;