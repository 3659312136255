import { toast } from 'react-toastify';
import config from './config';
import { nodeGetApi, boomiAdminApi } from './commonAxios';

export const DELIVERY_VEHICLE_REQUEST = "DELIVERY_VEHICLE_REQUEST";
export const DELIVERY_VEHICLE_SUCCESS = "DELIVERY_VEHICLE_SUCCESS";
export const DELIVERY_VEHICLE_FAIL = "DELIVERY_VEHICLE_FAIL";
export const DELIVERY_VEHICLE_GET_REQUEST = "DELIVERY_VEHICLE_GET_REQUEST";
export const DELIVERY_VEHICLE_GET_SUCCESS = "DELIVERY_VEHICLE_GET_SUCCESS";
export const DELIVERY_VEHICLE_GET_FAIL = "DELIVERY_VEHICLE_GET_FAIL";
export const DELIVERY_VEHICLE_CREATE_REQUEST = "DELIVERY_VEHICLE_CREATE_REQUEST";
export const DELIVERY_VEHICLE_CREATE_SUCCESS = "DELIVERY_VEHICLE_CREATE_SUCCESS";
export const DELIVERY_VEHICLE_CREATE_FAIL = "DELIVERY_VEHICLE_CREATE_FAIL";
export const DELIVERY_VEHICLE_UPDATE_REQUEST = "DELIVERY_VEHICLE_UPDATE_REQUEST";
export const DELIVERY_VEHICLE_UPDATE_SUCCESS = "DELIVERY_VEHICLE_UPDATE_SUCCESS";
export const DELIVERY_VEHICLE_UPDATE_FAIL = "DELIVERY_VEHICLE_UPDATE_FAIL";

/*=============================================================
                  Add delivery vehicle Action
===============================================================*/
export const createdeliveryvehicleAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: DELIVERY_VEHICLE_CREATE_REQUEST
  });
  boomiAdminApi.post(`/Super_Admin_Vehicle_Details/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DELIVERY_VEHICLE_CREATE_SUCCESS
        });
        toast('Delivery Vehicle Details added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/delivery-vehicle-details', state = { state });
      } else {
        dispatch({
          type: DELIVERY_VEHICLE_GET_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: DELIVERY_VEHICLE_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                  Get All Delivery Vehicle Action
===============================================================*/
export const getAlldeliveryvehicleAction = formData => async dispatch => {
  try {
    dispatch({
      type: DELIVERY_VEHICLE_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin_Vehicle_Details/Get?PageNo=0&PageSize=${config.pageSize}&SortBy=Del_Vehicle_Detail_Id&SortOrder=DESC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: DELIVERY_VEHICLE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: DELIVERY_VEHICLE_SUCCESS,
          payload: data.Del_Vehicle_Details_Get_Response
        });
      }
    }
  } catch (err) {
    dispatch({
      type: DELIVERY_VEHICLE_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                   Update Delivery Vehicle Action
===============================================================*/
export const updatedeliveryvehicleAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: DELIVERY_VEHICLE_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Super_Admin_Vehicle_Details/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: DELIVERY_VEHICLE_UPDATE_SUCCESS
        });
        const formValue = {
          Records_Filter: "FILTER",
          Del_User_Email_Id: formData?.Del_User_Email_Id,
        };
        dispatch(getAlldeliveryvehicleAction(formValue))
        toast('Delivery Vehicle Details updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/delivery-vehicle-details', state = { state });
      } else {
        dispatch({
          type: DELIVERY_VEHICLE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: DELIVERY_VEHICLE_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

/*=============================================================
             RC Front Image Upload Action
===============================================================*/
export const uploadRCFrontImage = (
  formData,
  callBackUploadImgae,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=DelRCFront&fileextension=png&filetype=Images&filename=DelRCFront`, formData);
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};

/*=============================================================
              RC Back Image Upload Action
===============================================================*/
export const uploadRCBackImage = (
  formData,
  callBackUploadImgae1,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=DelRCBack&fileextension=png&filetype=Images&filename=DelRCBack`, formData);
    if (data) {
      callBackUploadImgae1(data);
    }
  } catch (err) { }
};
/*=============================================================
           Vehicle Image Upload Action
===============================================================*/
export const uploadVehicleImage = (
  formData,
  callBackUploadVehicleImage,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=DelVehicle&fileextension=png&filetype=Images&filename=DelVehicle`, formData);
    if (data) {
      callBackUploadVehicleImage(data);
    }
  } catch (err) { }
};

/*=============================================================
             Insurance Upload Action
===============================================================*/
export const uploadInsuranceImage = (
  formData, setSizeError,
  callBackUploadInsuranceImage, fileData
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=DelInsurance&fileextension=png&filetype=Images&filename=DelInsurance`, formData);
    if (data) {
      if (data.Response_Status === "Success") {
        callBackUploadInsuranceImage(data);
      } else {
        setSizeError(data.UI_Display_Message + ".")
      }
    }
  } catch (err) {
  }
};

//Get Ticket Action
export const getDeliveryVehicleAction = (formData, callBackDeliveryVehicleData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Super_Admin_Vehicle_Details/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Del_Vehicle_Detail_Id&SortOrder=DESC`, formData);
    if (data) {
      callBackDeliveryVehicleData(data)
    }
  } catch (err) {
  }
};