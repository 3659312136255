import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    makeStyles,
    TextareaAutosize,
    TextField,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";
import { createUserRideBidding, getAllBidRideVehicles, getAllBidUsersSearchAction } from 'src/store/actions/userridebiddingsAction';
import BidRideBrandVehicle from 'src/components/brand-vehicle/brandVehicleGet';

const bidstatusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'INITIATED',
        label: 'INITIATED'
    },
    {
        value: 'PROCESSING',
        label: 'PROCESSING'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
    {
        value: 'COMPLETED',
        label: 'COMPLETED'
    }
];

const useStyles = makeStyles(() => ({
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const AddUserRideBidding = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        const formData = {
            search: "",
            search_by_filter: ""
        };
        dispatch(getAllBidRideVehicles(formData));
        dispatch(getAllBidUsersSearchAction())
    }, []);

    const [data, setData] = useState([{ Bidding_Vehicle_Id: '', Bidding_Ride_Brand_Vehicle_Id: '', Quantity: '' }]);

    const handleVehicleIdChange = (event, index) => {
        const newData = [...data];
        newData[index].Bidding_Vehicle_Id = event.target.value;
        setData(newData);
    };

    const handleBrandVehiIDChange = (event, index) => {
        const newData = [...data];
        newData[index].Bidding_Ride_Brand_Vehicle_Id = event.target.value;
        setData(newData);
    };

    const handleQuantityChange = (event, index) => {
        const newData = [...data];
        newData[index].Quantity = event.target.value;
        setData(newData);
    };

    const addData = () => {
        setData([...data, { Bidding_Vehicle_Id: '', Bidding_Ride_Brand_Vehicle_Id: '', Quantity: '' }]);
    };

    const handleRemoveFieldValue = (index, field) => {
        const newData = [...data];
        newData[index][field] = '';
        setData(newData);

        // Check if both values are empty
        const { Bidding_Ride_Brand_Vehicle_Id, Bidding_Vehicle_Id } = newData[index];
        if (!Bidding_Ride_Brand_Vehicle_Id && !Bidding_Vehicle_Id) {
            // Remove the object from the array
            const newData = [...data];
            newData[index][field] = '';
            newData.splice(index, 1);
            setData(newData);
        }
    };

    const isSubmited = useSelector(state => state.userridebidding.userridebiddingCreate.isLoading);
    const errorMessage = useSelector(state => state.userridebidding.userridebiddingCreate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const bidridevehi = useSelector(state => state.userridebidding.bidridevehicles.all)
    const bidsearchusers = useSelector(state => state.userridebidding.bidusersSearch.bidusers);

    return (
        <Formik
            initialValues={{
                User_Email_Id: '',
                Pincode: '',
                Bidding_Status: '',
                Description: '',
                Bidding_Expiry_Date: '',
                Distance: 0,
                No_of_Days: 0,
                IsMultiple: false,
                Created_By: loginEmail,
                Ride_Start_Date: '',
            }}
            validationSchema={
                Yup.object().shape({
                    User_Email_Id: Yup.string().required('User Email ID is required.'),
                    Pincode: Yup.string().matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, 'Please provide valid Pincode.').required('Pincode is required.'),
                    Bidding_Expiry_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require Bidding Expiry Date greater than or equal to today.").required("Bidding Expiry Date is required."),
                    Bidding_Status: Yup.string().required('Bidding Status is required.'),
                    No_of_Days: Yup.string().required('No of Days is required.'),
                    Ride_Start_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require Ride Start Date greater than or equal to today.").required("Ride Start Date is required."),
                })
            }
            onSubmit={(values) => {
                let submitTest = true;
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    if (values.IsMultiple) {
                        formValues.IsMultiple = 1;
                    } else {
                        formValues.IsMultiple = 0;
                    }

                    const formData = {
                        Bidding_Expiry_Date: values.Bidding_Expiry_Date,
                        Ride_Start_Date: values.Ride_Start_Date,
                        Bidding_Status: values.Bidding_Status,
                        Created_By: loginEmail,
                        Description: values.Description,
                        IsMultiple: formValues.IsMultiple,
                        Pincode: values.Pincode,
                        No_of_Days: values.No_of_Days,
                        Distance: values.Distance,
                        User_Email_Id: values.User_Email_Id,
                        data: data
                    };

                    let formValues1 = JSON.stringify(formData);
                    dispatch(createUserRideBidding(formValues1, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={6} xs={12}>
                                            <Autocomplete
                                                freeSolo
                                                options={bidsearchusers}
                                                groupBy={(option) => option.firstLetter}
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option.Email_Id}
                                                renderInput={(params) => <TextField {...params}
                                                    error={Boolean(touched.User_Email_Id && errors.User_Email_Id)}
                                                    helperText={touched.User_Email_Id && errors.User_Email_Id}
                                                    onBlur={handleBlur}
                                                    label="User Email ID"
                                                    name="User_Email_Id"
                                                    variant="outlined"
                                                    value={values.User_Email_Id}
                                                    onChange={() => {
                                                        dispatch(getAllBidUsersSearchAction(params.inputProps.value))
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill,
                                                        startAdornment: (
                                                            <React.Fragment>
                                                                <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                {params.InputProps.startAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />}
                                                onChange={(e, value) => {
                                                    setFieldValue("User_Email_Id", value?.Email_Id ? value.Email_Id : "");
                                                }}
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Pincode && errors.Pincode)}
                                                fullWidth
                                                helperText={touched.Pincode && errors.Pincode}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Pincode"
                                                name="Pincode"
                                                value={values.Pincode}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                                                }}
                                                min={0}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.No_of_Days && errors.No_of_Days)}
                                                fullWidth
                                                helperText={touched.No_of_Days && errors.No_of_Days}
                                                name="No_of_Days"
                                                label="No of Days"
                                                value={values.No_of_Days}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                name="Distance"
                                                label="Distance(in Km)"
                                                value={values.Distance}
                                                onChange={handleChange}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Ride_Start_Date && errors.Ride_Start_Date)}
                                                fullWidth
                                                helperText={touched.Ride_Start_Date && errors.Ride_Start_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Ride Start Date"
                                                name="Ride_Start_Date"
                                                value={values.Ride_Start_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="date"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}
                                                fullWidth
                                                helperText={touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Expiry Date"
                                                name="Bidding_Expiry_Date"
                                                value={values.Bidding_Expiry_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="date"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Status && errors.Bidding_Status)}
                                                fullWidth
                                                helperText={touched.Bidding_Status && errors.Bidding_Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Status"
                                                name="Bidding_Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Status}
                                                variant="outlined"
                                            >
                                                {bidstatusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {/* IsMultiple */}
                                        <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.IsMultiple}
                                                        onChange={handleChange}
                                                        name="IsMultiple"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Multiple"
                                            />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            Description
                                            <TextareaAutosize
                                                value={values.Description}
                                                onChange={handleChange}
                                                name="Description"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Description"
                                            >
                                            </TextareaAutosize>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} alignItems="center" >
                                {data.map((item, index) => (
                                    <>
                                        <Grid item md={4}>
                                            <TextField
                                                fullWidth
                                                name="Bidding_Vehicle_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                label="Bidding Ride Vehicle"
                                                value={item.Bidding_Vehicle_Id}
                                                variant="outlined"
                                                onChange={(event, value) => {
                                                    handleVehicleIdChange(event, index)
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={() => handleRemoveFieldValue(index, 'Bidding_Vehicle_Id')}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    )
                                                }}
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {bidridevehi?.map(option => (
                                                    <option key={option.Bidding_Vehicle_Id} value={option.Bidding_Vehicle_Id}>
                                                        {option.Vehicle_Type + " - " + option.Vehicle_Comfort_Type}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={4}>
                                            <BidRideBrandVehicle
                                                fullWidth
                                                label="Bidding Ride Brand Vehicle"
                                                name="Bidding_Ride_Brand_Vehicle_Id"
                                                onBlur={handleBlur}
                                                value={item.Bidding_Ride_Brand_Vehicle_Id}
                                                vehicleid={item.Bidding_Vehicle_Id}
                                                select
                                                SelectProps={{ native: true }}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(event) => handleBrandVehiIDChange(event, index)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={() => handleRemoveFieldValue(index, 'Bidding_Ride_Brand_Vehicle_Id')}
                                                            style={{ cursor: 'pointer', size: 'small' }}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    )
                                                }}
                                            >
                                            </BidRideBrandVehicle>
                                        </Grid>

                                        <Grid item md={4}>
                                            <TextField
                                                fullWidth
                                                name="Quantity"
                                                label="Quantity"
                                                value={item.Quantity}
                                                onChange={(event) => handleQuantityChange(event, index)}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                            >
                                            </TextField>
                                        </Grid>
                                    </>
                                ))}

                                <Grid item md={4}>
                                    <Tooltip title="Add Another Vehicle">
                                        <Button
                                            onClick={addData}
                                            color="primary"
                                        >
                                            <AddCircleIcon style={{ color: '#9EA0A5' }} />
                                        </Button>
                                    </Tooltip>
                                </Grid>

                            </Grid>
                        </CardContent>

                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddUserRideBidding.propTypes = {
    className: PropTypes.string
};

export default AddUserRideBidding;