import {
    SERVICE_CATEGORY_CREATE_REQUEST,
    SERVICE_CATEGORY_CREATE_SUCCESS,
    SERVICE_CATEGORY_CREATE_FAIL,
    SERVICE_CATEGORY_UPDATE_REQUEST,
    SERVICE_CATEGORY_UPDATE_SUCCESS,
    SERVICE_CATEGORY_UPDATE_FAIL,
  
  } from '../actions/servicecategoriesAction'
  
  const initialState = {
    ServicecategoryCreate: { Servicecategory: {}, error: '', isLoading: false },
    ServicecategoryUpdate: { Servicecategory: {}, error: '', isLoading: false },
   
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case SERVICE_CATEGORY_CREATE_REQUEST:
        return { ...state, servicecategoryCreate: { Servicecategory: {}, error: '', isLoading: true } };
      case SERVICE_CATEGORY_CREATE_SUCCESS:
        return { ...state, servicecategoryCreate: { Servicecategory: action.payload, error: '', isLoading: false } };
      case SERVICE_CATEGORY_CREATE_FAIL:
        return { ...state, servicecategoryCreate: { Servicecategory: {}, error: action.payload, isLoading: false } };
  
      case SERVICE_CATEGORY_UPDATE_REQUEST:
        return { ...state, ServicecategoryUpdate: { Servicecategory: {}, error: '', isLoading: true } };
      case SERVICE_CATEGORY_UPDATE_SUCCESS:
        return { ...state, ServicecategoryUpdate: { Servicecategory: action.payload, error: '', isLoading: false } };
      case SERVICE_CATEGORY_UPDATE_FAIL:
        return { ...state, ServicecategoryUpdate: { Servicecategory: {}, error: action.payload, isLoading: false } };
  
      
  
      default:
        return state;
    }
  }