import {
    PARTNER_VEHICLE_REQUEST,
    PARTNER_VEHICLE_SUCCESS,
    PARTNER_VEHICLE_FAIL,
    PARTNER_VEHICLE_GET_REQUEST,
    PARTNER_VEHICLE_GET_SUCCESS,
    PARTNER_VEHICLE_GET_FAIL,
    PARTNER_VEHICLE_CREATE_REQUEST,
    PARTNER_VEHICLE_CREATE_SUCCESS,
    PARTNER_VEHICLE_CREATE_FAIL,
    PARTNER_VEHICLE_UPDATE_REQUEST,
    PARTNER_VEHICLE_UPDATE_SUCCESS,
    PARTNER_VEHICLE_UPDATE_FAIL,
} from '../actions/partnervehicledetailsAction';

const initialState = {
    partnervehicledetails: { all: [], error: '', isLoading: false },
    partnervehicledetail: { partnervehicledetail: {}, error: '', isLoading: false },
    partnervehicledetailCreate: { partnervehicledetail: {}, error: '', isLoading: false },
    partnervehicledetailUpdate: { partnervehicledetail: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PARTNER_VEHICLE_REQUEST:
            return { ...state, partnervehicledetails: { all: state.partnervehicledetails.all, error: '', isLoading: true } };
        case PARTNER_VEHICLE_SUCCESS:
            return { ...state, partnervehicledetails: { all: action.payload, error: '', isLoading: false } };
        case PARTNER_VEHICLE_FAIL:
            return { ...state, partnervehicledetails: { all: [], error: action.payload, isLoading: false } };
        case PARTNER_VEHICLE_GET_REQUEST:
            return { ...state, partnervehicledetail: { partnervehicledetail: {}, error: '', isLoading: true } };
        case PARTNER_VEHICLE_GET_SUCCESS:
            return { ...state, partnervehicledetail: { partnervehicledetail: action.payload, error: '', isLoading: false } };
        case PARTNER_VEHICLE_GET_FAIL:
            return { ...state, partnervehicledetail: { partnervehicledetail: {}, error: action.payload, isLoading: false } };
        case PARTNER_VEHICLE_CREATE_REQUEST:
            return { ...state, partnervehicledetailCreate: { partnervehicledetail: {}, error: '', isLoading: true } };
        case PARTNER_VEHICLE_CREATE_SUCCESS:
            return { ...state, partnervehicledetailCreate: { partnervehicledetail: state.partnervehicledetailCreate.partnervehicledetail, error: '', isLoading: false } };
        case PARTNER_VEHICLE_CREATE_FAIL:
            return { ...state, partnervehicledetailCreate: { partnervehicledetail: {}, error: action.payload, isLoading: false } };
        case PARTNER_VEHICLE_UPDATE_REQUEST:
            return { ...state, partnervehicledetailUpdate: { partnervehicledetail: {}, error: '', isLoading: true } };
        case PARTNER_VEHICLE_UPDATE_SUCCESS:
            return { ...state, partnervehicledetailUpdate: { partnervehicledetail: state.partnervehicledetailUpdate.partnervehicledetail, error: '', isLoading: false } };
        case PARTNER_VEHICLE_UPDATE_FAIL:
            return { ...state, partnervehicledetailUpdate: { partnervehicledetail: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}