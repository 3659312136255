
import config from './config';
import { nodeGetApi } from './commonAxios';

export const PRODUCTS_REQUEST = "PRODUCTS_REQUEST";
export const PRODUCTS_SUCCESS = "PRODUCTS_SUCCESS";
export const PRODUCTS_FAIL = "PRODUCTS_FAIL";
export const PRODUCTS_FILTER = "PRODUCTS_FILTER";

/*=============================================================
                  Get All Products Action
===============================================================*/
export const getAllProductAction = formData => async dispatch => {
  try {
    dispatch({
      type: PRODUCTS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Products/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PRODUCT_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCTS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCTS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCTS_FAIL,
      payload: err
    });
  }
};