import { toast } from 'react-toastify';
import { boomiAdminApi, nodeCudApi, nodeGetApi } from './commonAxios';
import config from './config';

export const USERS_REQUEST = "USERS_REQUEST";
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_FAIL = "USERS_FAIL";
export const USER_GET_REQUEST = "USER_GET_REQUEST";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_FAIL = "USER_GET_FAIL";
export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAIL = "USER_CREATE_FAIL";
export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_EMAILS_REQUEST = "USER_EMAILS_REQUEST";
export const USER_EMAILS_SUCCESS = "USER_EMAILS_SUCCESS";
export const USER_EMAILS_FAIL = "USER_EMAILS_FAIL";

/*=============================================================
                  Add User Action
===============================================================*/
export const createUserAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: USER_CREATE_REQUEST
  });
  setErrorMessage({ Email_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/User/Profile_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USER_CREATE_SUCCESS
        });
        toast('User profile added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/user-profile-management');
      } else {
        const errors = {};
        if (successResponse.Email_Id) {
          errors.Email_Id = successResponse.UI_Display_Message;
        }
        if (successResponse.Mobile) {
          errors.Mobile = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Email_Id: { key: successResponse.Email_Id ? successResponse.Email_Id : '', message: successResponse.Email_Id ? successResponse.UI_Display_Message : '' }, Mobile: { key: successResponse.Mobile ? successResponse.Mobile : '', message: successResponse.Mobile ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Mobile || successResponse.Email_Id) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: USER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Email_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: USER_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Update User Action
===============================================================*/
export const updateUserAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: USER_UPDATE_REQUEST
  });
  setErrorMessage({ Email_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/User/Profile_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USER_UPDATE_SUCCESS
        });
        toast('User profile updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/user-profile-management');
      } else {
        const errors = {};
        if (successResponse.Email_Id) {
          errors.Email_Id = successResponse.UI_Display_Message;
        }
        if (successResponse.Mobile) {
          errors.Mobile = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Email_Id: { key: successResponse.Email_Id ? successResponse.Email_Id : '', message: successResponse.Email_Id ? successResponse.UI_Display_Message : '' }, Mobile: { key: successResponse.Mobile ? successResponse.Mobile : '', message: successResponse.Mobile ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Email_Id || successResponse.Mobile) ? successResponse.UI_Display_Message : '' } });

        dispatch({
          type: USER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Email_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: USER_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
              User Image Upload Action
===============================================================*/
export const uploadUserImage = (
  formData,
  callBackUploadImgae
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=UserProfile&fileextension=png&filetype=Images&filename=UserProfile`, formData);
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) {
  }
};

/*=============================================================
                Get All User Action
===============================================================*/
export const getAllUserAction = formData => async dispatch => {
  try {
    dispatch({
      type: USERS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/User/Profile_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=EMAIL_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: USERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: USERS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: USERS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Get All Emails Action
===============================================================*/
export const getAllEmails = (formData) => async dispatch => {
  try {
    dispatch({
      type: USER_EMAILS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/User/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=EMAIL_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: USER_EMAILS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: USER_EMAILS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: USER_EMAILS_FAIL,
      payload: err
    });
  }
};

//Node API
export const getUserProfileAction = (formData, callBackProfileData) => async dispatch => {
  try {
    let { data, status } = await nodeGetApi.post(`/User/Profile_Get_Admin`, formData);
    if (data) {
      callBackProfileData(data)
    }
  } catch (err) {
  }
};