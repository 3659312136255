import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    ListItemText,
    makeStyles,
    TextareaAutosize,
    TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress, Rating } from '@mui/material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";
import { getAllBiddingCities, getAllBidRideQuotes, getAllBidRideUserAddress, getAllBidUsersSearchAction, updateUserRideBidding } from 'src/store/actions/userridebiddingsAction';

const useStyles = makeStyles(() => ({
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const bidstatusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'INITIATED',
        label: 'INITIATED'
    },
    {
        value: 'PROCESSING',
        label: 'PROCESSING'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
    {
        value: 'COMPLETED',
        label: 'COMPLETED'
    }
];

const EditUserRideBidding = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const location = useLocation();
    let { state } = location;

    useEffect(() => {
        const qformData = {
            search: "",
            search_by_filter: "",
            Records_Filter: "FILTER",
            User_Ride_Bidding_Id: state.User_Ride_Bidding_Id
        };
        const formData = {
            search: "",
            search_by_filter: ""
        };
        const formData1 = {
            Records_Filter: "FILTER",
            User_Email_Id: state.User_Email_Id
        };
        dispatch(getAllBiddingCities(formData));
        dispatch(getAllBidRideUserAddress(formData1, callBackUserAddressData));
        dispatch(getAllBidRideQuotes(qformData))
        dispatch(getAllBidUsersSearchAction(state.User_Email_Id))
    }, []);

    const [useraddressdata, setUserAddressData] = React.useState([]);
    let USERADDRESS = []
    if (useraddressdata) {
        for (let i = 0; i < useraddressdata?.length; i++) {
            USERADDRESS.push(
                {
                    label: useraddressdata[i].User_Address_Id,
                    value: useraddressdata[i].User_Address_Id,
                })
        }
    }

    const callBackUserAddressData = (data) => {
        setUserAddressData(data)
    }

    const setUserAddressType = (value) => {
        if (value !== "") {
            let data = {
                Records_Filter: "FILTER",
                User_Email_Id: value
            }
            dispatch(getAllBidRideUserAddress(data, callBackUserAddressData))
        }
    }

    const isSubmited = useSelector(state => state.userridebidding.userridebiddingUpdate.isLoading);
    const errorMessage = useSelector(state => state.userridebidding.userridebiddingUpdate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const biddingcities = useSelector(state => state.userridebidding.biddingcities.all)
    const biddingridequotes = useSelector(state => state.userridebidding.bidridequotes.all)
    const bidsearchusers = useSelector(state => state.userridebidding.bidusersSearch.bidusers);

    return (
        <Formik
            initialValues={{
                User_Ride_Bidding_Id: state.User_Ride_Bidding_Id ? state.User_Ride_Bidding_Id : "",
                User_Email_Id: state.User_Email_Id ? state.User_Email_Id : "",
                User_Address_Id: state.User_Address_Id ? state.User_Address_Id : "",
                City_Id: state.City_Id ? state.City_Id : "",
                Ride_Start_Date: state.Ride_Start_Date ? state.Ride_Start_Date : "",
                Ride_End_Date: state.Ride_End_Date ? state.Ride_End_Date : "",
                Bidding_Expiry_Date: state.Bidding_Expiry_Date ? state.Bidding_Expiry_Date : "",
                Distance: state.Distance ? state.Distance : "",
                No_Of_Days: state.No_Of_Days ? state.No_Of_Days : "",
                Extra_Days_Per_Ride: state.Extra_Days_Per_Ride ? state.Extra_Days_Per_Ride : "",
                Extra_Kms_Per_Ride: state.Extra_Kms_Per_Ride ? state.Extra_Kms_Per_Ride : "",
                Ride_Total_Amount: state.Ride_Total_Amount ? state.Ride_Total_Amount : "",
                Ride_Balance_Amount: state.Ride_Balance_Amount ? state.Ride_Balance_Amount : "",
                Total_Advance_Amount: state.Total_Advance_Amount ? state.Total_Advance_Amount : "",
                Delivery_Advance_Amount: state.Delivery_Advance_Amount ? state.Delivery_Advance_Amount : "",
                Wallet_Amount: state.Wallet_Amount ? state.Wallet_Amount : "",
                Online_Transaction_Amount: state.Online_Transaction_Amount ? state.Online_Transaction_Amount : "",
                Advance_Online_Amount: state.Advance_Online_Amount ? state.Advance_Online_Amount : "",
                Advance_Wallet_Amount: state.Advance_Wallet_Amount ? state.Advance_Wallet_Amount : "",
                Final_Amount_Including_Charges: state.Final_Amount_Including_Charges ? state.Final_Amount_Including_Charges : "",
                Delivery_Advance_Online_Amount: state.Delivery_Advance_Online_Amount ? state.Delivery_Advance_Online_Amount : "",
                Delivery_Advance_Wallet_Amount: state.Delivery_Advance_Wallet_Amount ? state.Delivery_Advance_Wallet_Amount : "",
                Delivery_Final_Online_Amount: state.Delivery_Final_Online_Amount ? state.Delivery_Final_Online_Amount : "",
                Delivery_Final_Wallet_Amount: state.Delivery_Final_Wallet_Amount ? state.Delivery_Final_Wallet_Amount : "",
                Advance_Online_Transaction_Id: state.Advance_Online_Transaction_Id ? state.Advance_Online_Transaction_Id : "",
                Delivery_Advance_Wallet_History_Id: state.Delivery_Advance_Wallet_History_Id ? state.Delivery_Advance_Wallet_History_Id : "",
                Delivery_Final_Wallet_History_Id: state.Delivery_Final_Wallet_History_Id ? state.Delivery_Final_Wallet_History_Id : "",
                Payment_Mode: state.Payment_Mode ? state.Payment_Mode : "",
                Advance_Payment_Mode: state.Advance_Payment_Mode ? state.Advance_Payment_Mode : "",
                Payment_Transaction_Id: state.Payment_Transaction_Id ? state.Payment_Transaction_Id : "",
                Pickup_Address: state.Pickup_Address ? state.Pickup_Address : "",
                ESI_Commision_Amount: state.ESI_Commision_Amount ? state.ESI_Commision_Amount : "",
                Esi_Commission_Amount_After_Tax: state.ESI_Commision_Amount_After_Tax ? state.ESI_Commision_Amount_After_Tax : "",
                Esi_Commission_Amount_Before_Tax: state.ESI_Commision_Amount_Before_Tax ? state.ESI_Commision_Amount_Before_Tax : "",
                Esi_Commission_Tax_Amount: state.ESI_Commision_Tax_Amount ? state.ESI_Commision_Tax_Amount : "",
                Esi_Commission_Tax_Percentage: state.ESI_Commision_Tax_Percentage ? state.ESI_Commision_Tax_Percentage : "",
                Finalize_Delivery_Bidding_Quote_Id: state.Finalize_Delivery_Bidding_Quote_Id ? state.Finalize_Delivery_Bidding_Quote_Id : "",
                Ride_Payment_Transaction_Id: state.Ride_Payment_Transaction_Id ? state.Ride_Payment_Transaction_Id : "",
                Pickup_Zip_Code: state.Pickup_Zip_Code ? state.Pickup_Zip_Code : "",
                Is_Ride_Advance_Amount_Settled: state.Is_Ride_Advance_Amount_Settled > 0 ? true : false,
                Is_Ride_Amount_Settled: state.Is_Ride_Amount_Settled > 0 ? true : false,
                Advance_Payment_Status: state.Advance_Payment_Status > 0 ? true : false,
                Final_Payment_Status: state.Final_Payment_Status > 0 ? true : false,
                Bidding_Status: state.Bidding_Status ? state.Bidding_Status : "",
                Description: state.Description ? state.Description : "",
                Cancelled_Reason: state.Cancelled_Reason ? state.Cancelled_Reason : "",
                User_Ratings: state.User_Ratings ? state.User_Ratings : "",
                User_Reviews: state.User_Reviews ? state.User_Reviews : "",
                Vehicle_Ratings: state.Vehicle_Ratings ? state.Vehicle_Ratings : "",
                Vehicle_Reviews: state.Vehicle_Reviews ? state.Vehicle_Reviews : "",
                Modified_By: loginEmail,
                Cashback_Amount: state.Cashback_Amount ? state.Cashback_Amount : "",
            }}
            validationSchema={
                Yup.object().shape({
                    // User_Email_Id: Yup.string().required('User Email is required.'),
                    City_Id: Yup.string().required('City is required.'),
                    Ride_Start_Date: Yup.date().min(new Date(state.Ride_Start_Date) < new Date(Date.now() - 1 * 24 * 60 * 60 * 1000) ? new Date(new Date(state.Ride_Start_Date) - 1 * 24 * 60 * 60 * 1000) : new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), new Date(state.Ride_Start_Date) < new Date(Date.now() - 1 * 24 * 60 * 60 * 1000) ? "Ride Start Date greater than or equal to " + ((new Date(state.Ride_Start_Date).getMonth() > 8) ? (new Date(state.Ride_Start_Date).getMonth() + 1) : ('0' + (new Date(state.Ride_Start_Date).getMonth() + 1))) + '/' + ((new Date(state.Ride_Start_Date).getDate() > 9) ? new Date(state.Ride_Start_Date).getDate() : ('0' + new Date(state.Ride_Start_Date).getDate())) + '/' + new Date(state.Ride_Start_Date).getFullYear() + "." : "Require Ride Start Date greater than or equal to today."),
                    Ride_End_Date: Yup.date().when("Ride_Start_Date", (Ride_Start_Date, schema) => Ride_Start_Date && schema.min(Ride_Start_Date, "Ride End Date can't be before Ride Start Date.")),
                    Bidding_Expiry_Date: Yup.string().required('Bidding Expiry Date is required.'),
                    Ride_Total_Amount: Yup.number().min(0, "Ride Total Amount should not be less than 0."),
                    Ride_Balance_Amount: Yup.number().min(0, "Ride Balance Amount should not be less than 0."),
                    Total_Advance_Amount: Yup.number().min(0, "Total Advance Amount should not be less than 0."),
                    Delivery_Advance_Amount: Yup.number().min(0, "Delivery Advance Amount should not be less than 0."),
                    Wallet_Amount: Yup.number().min(0, "Wallet Amount should not be less than 0."),
                    Online_Transaction_Amount: Yup.number().min(0, "Online Transaction Amount should not be less than 0."),
                    Advance_Online_Amount: Yup.number().min(0, "Advance Online Amount should not be less than 0."),
                    Advance_Wallet_Amount: Yup.number().min(0, "Advance Wallet Amount should not be less than 0."),
                    Final_Amount_Including_Charges: Yup.number().min(0, "Final Amount Including Charges should not be less than 0."),
                    ESI_Commision_Amount: Yup.number().min(0, "ESI Commission Amount Tax should not be less than 0."),
                    Esi_Commission_Amount_After_Tax: Yup.number().min(0, "ESI Commission Amount After Tax should not be less than 0."),
                    Esi_Commission_Amount_Before_Tax: Yup.number().min(0, "ESI Commission Amount Before Tax should not be less than 0."),
                    Esi_Commission_Tax_Amount: Yup.number().min(0, "ESI Commission Tax Amount should not be less than 0."),
                    Esi_Commission_Tax_Percentage: Yup.number().min(0, "ESI Commission Tax Percentage should not be less than 0.").max(100, 'ESI Commission Tax Percentage must be less than 100.'),
                    Pickup_Zip_Code: Yup.string().matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, 'Please provide valid Pickup Zip Code.').required('Pickup Zip Code is required.'),
                    Bidding_Status: Yup.string().required('Bidding Status is required.'),
                    No_Of_Days: Yup.number().min(0, "No of Days should not be less than 0.").required('No of Days is required.'),
                    Distance: Yup.number().min(0, "Distance should not be less than 0."),
                    Delivery_Advance_Online_Amount: Yup.number().min(0, "Delivery Advance Online Amount should not be less than 0."),
                    Delivery_Advance_Wallet_Amount: Yup.number().min(0, "Delivery Advance Wallet Amount should not be less than 0."),
                    Delivery_Final_Online_Amount: Yup.number().min(0, "Delivery Final Online Amount should not be less than 0."),
                    Delivery_Final_Wallet_Amount: Yup.number().min(0, "Delivery Final Wallet Amount should not be less than 0."),
                    Extra_Days_Per_Ride: Yup.number().min(0, "Extra Days Per Ride should not be less than 0."),
                    Extra_Kms_Per_Ride: Yup.number().min(0, "Extra Kms Per Ride should not be less than 0."),
                })
            }
            onSubmit={(values) => {
                let submitTest = true;
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    if (values.Is_Ride_Amount_Settled) {
                        formValues.Is_Ride_Amount_Settled = 1;
                    } else {
                        formValues.Is_Ride_Amount_Settled = 0;
                    }
                    if (values.Advance_Payment_Status) {
                        formValues.Advance_Payment_Status = 1;
                    } else {
                        formValues.Advance_Payment_Status = 0;
                    }
                    if (values.Final_Payment_Status) {
                        formValues.Final_Payment_Status = 1;
                    } else {
                        formValues.Final_Payment_Status = 0;
                    }
                    dispatch(updateUserRideBidding(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={6} xs={12}>
                                            {/* <Autocomplete
                                                freeSolo
                                                options={bidsearchusers}
                                                groupBy={(option) => option?.firstLetter}
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option?.Email_Id || ""}
                                                onChange={(e, value) => {
                                                    setFieldValue("User_Email_Id", value?.Email_Id ? value?.Email_Id : "");
                                                    setUserAddressType(e.target.value)
                                                }}
                                                value={bidsearchusers?.length > 0 ? bidsearchusers?.filter((item) => item?.Email_Id === values?.User_Email_Id)[0] : ""}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                        <ListItemText
                                                            primary={option?.Email_Id}
                                                        />
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        error={Boolean(touched.User_Email_Id && errors.User_Email_Id)}
                                                        fullWidth
                                                        helperText={touched.User_Email_Id && errors.User_Email_Id}
                                                        {...params}
                                                        placeholder="Search"
                                                        variant="outlined"
                                                        label="User Email"
                                                        name="User_Email_Id"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off', // disable autocomplete and autofill,
                                                            startAdornment: (
                                                                <React.Fragment>
                                                                    <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                    {params.InputProps.startAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            /> */}
                                            <TextField
                                                error={Boolean(touched.User_Email_Id && errors.User_Email_Id)}
                                                fullWidth
                                                helperText={touched.User_Email_Id && errors.User_Email_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="User Email ID"
                                                name="User_Email_Id"
                                                disabled
                                                value={values.User_Email_Id}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.User_Address_Id && errors.User_Address_Id)}
                                                fullWidth
                                                helperText={touched.User_Address_Id && errors.User_Address_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="User Address"
                                                name="User_Address_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.User_Address_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {useraddressdata?.map(option => (
                                                    <option key={option.User_Address_Id} value={option.User_Address_Id}>
                                                        {option.Location}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.City_Id && errors.City_Id)}
                                                fullWidth
                                                helperText={touched.City_Id && errors.City_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="City"
                                                name="City_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.City_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {biddingcities?.map(option => (
                                                    <option key={option.City_Id} value={option.City_Id}>
                                                        {option.Name + " - " + option.State + " - " + option.Country}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}
                                                fullWidth
                                                helperText={touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Expiry Date"
                                                name="Bidding_Expiry_Date"
                                                value={values.Bidding_Expiry_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="date"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Ride_Start_Date && errors.Ride_Start_Date)}
                                                fullWidth
                                                helperText={touched.Ride_Start_Date && errors.Ride_Start_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Ride Start Date"
                                                name="Ride_Start_Date"
                                                value={values.Ride_Start_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="date"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Ride_End_Date && errors.Ride_End_Date)}
                                                fullWidth
                                                helperText={touched.Ride_End_Date && errors.Ride_End_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Ride End Date"
                                                name="Ride_End_Date"
                                                value={values.Ride_End_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="date"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Distance && errors.Distance)}
                                                fullWidth
                                                helperText={touched.Distance && errors.Distance}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Distance(km)"
                                                name="Distance"
                                                value={values.Distance}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.No_Of_Days && errors.No_Of_Days)}
                                                fullWidth
                                                helperText={touched.No_Of_Days && errors.No_Of_Days}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="No of Days"
                                                name="No_Of_Days"
                                                value={values.No_Of_Days}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Extra_Days_Per_Ride && errors.Extra_Days_Per_Ride)}
                                                fullWidth
                                                helperText={touched.Extra_Days_Per_Ride && errors.Extra_Days_Per_Ride}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Extra Days Per Ride"
                                                name="Extra_Days_Per_Ride"
                                                value={values.Extra_Days_Per_Ride}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Extra_Kms_Per_Ride && errors.Extra_Kms_Per_Ride)}
                                                fullWidth
                                                helperText={touched.Extra_Kms_Per_Ride && errors.Extra_Kms_Per_Ride}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Extra Kms Per Ride"
                                                name="Extra_Kms_Per_Ride"
                                                value={values.Extra_Kms_Per_Ride}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Ride_Total_Amount && errors.Ride_Total_Amount)}
                                                fullWidth
                                                helperText={touched.Ride_Total_Amount && errors.Ride_Total_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Ride Total Amount"
                                                name="Ride_Total_Amount"
                                                value={values.Ride_Total_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Ride_Balance_Amount && errors.Ride_Balance_Amount)}
                                                fullWidth
                                                helperText={touched.Ride_Balance_Amount && errors.Ride_Balance_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Ride Balance Amount"
                                                name="Ride_Balance_Amount"
                                                value={values.Ride_Balance_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Total_Advance_Amount && errors.Total_Advance_Amount)}
                                                fullWidth
                                                helperText={touched.Total_Advance_Amount && errors.Total_Advance_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Total Advance Amount"
                                                name="Total_Advance_Amount"
                                                value={values.Total_Advance_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Delivery_Advance_Amount && errors.Delivery_Advance_Amount)}
                                                fullWidth
                                                helperText={touched.Delivery_Advance_Amount && errors.Delivery_Advance_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Delivery Advance Amount"
                                                name="Delivery_Advance_Amount"
                                                value={values.Delivery_Advance_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Wallet_Amount && errors.Wallet_Amount)}
                                                fullWidth
                                                helperText={touched.Wallet_Amount && errors.Wallet_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Wallet Amount"
                                                name="Wallet_Amount"
                                                value={values.Wallet_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Online_Transaction_Amount && errors.Online_Transaction_Amount)}
                                                fullWidth
                                                helperText={touched.Online_Transaction_Amount && errors.Online_Transaction_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Online Transaction Amount"
                                                name="Online_Transaction_Amount"
                                                value={values.Online_Transaction_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Advance_Online_Amount && errors.Advance_Online_Amount)}
                                                fullWidth
                                                helperText={touched.Advance_Online_Amount && errors.Advance_Online_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Advance Online Amount"
                                                name="Advance_Online_Amount"
                                                value={values.Advance_Online_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Final_Amount_Including_Charges && errors.Final_Amount_Including_Charges)}
                                                fullWidth
                                                helperText={touched.Final_Amount_Including_Charges && errors.Final_Amount_Including_Charges}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Final Amount Including Charges"
                                                name="Final_Amount_Including_Charges"
                                                value={values.Final_Amount_Including_Charges}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Advance_Wallet_Amount && errors.Advance_Wallet_Amount)}
                                                fullWidth
                                                helperText={touched.Advance_Wallet_Amount && errors.Advance_Wallet_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Advance Wallet Amount"
                                                name="Advance_Wallet_Amount"
                                                value={values.Advance_Wallet_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Delivery_Advance_Online_Amount && errors.Delivery_Advance_Online_Amount)}
                                                fullWidth
                                                helperText={touched.Delivery_Advance_Online_Amount && errors.Delivery_Advance_Online_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Delivery Advance Online Amount"
                                                name="Delivery_Advance_Online_Amount"
                                                value={values.Delivery_Advance_Online_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Delivery_Advance_Wallet_Amount && errors.Delivery_Advance_Wallet_Amount)}
                                                fullWidth
                                                helperText={touched.Delivery_Advance_Wallet_Amount && errors.Delivery_Advance_Wallet_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Delivery Advance Wallet Amount"
                                                name="Delivery_Advance_Wallet_Amount"
                                                value={values.Delivery_Advance_Wallet_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Delivery_Final_Online_Amount && errors.Delivery_Final_Online_Amount)}
                                                fullWidth
                                                helperText={touched.Delivery_Final_Online_Amount && errors.Delivery_Final_Online_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Delivery Final Online Amount"
                                                name="Delivery_Final_Online_Amount"
                                                value={values.Delivery_Final_Online_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Delivery_Final_Wallet_Amount && errors.Delivery_Final_Wallet_Amount)}
                                                fullWidth
                                                helperText={touched.Delivery_Final_Wallet_Amount && errors.Delivery_Final_Wallet_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Delivery Final Wallet Amount"
                                                name="Delivery_Final_Wallet_Amount"
                                                value={values.Delivery_Final_Wallet_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Online Transaction ID"
                                                name="Advance_Online_Transaction_Id"
                                                value={values.Advance_Online_Transaction_Id}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Delivery Advance Wallet History ID"
                                                name="Delivery_Advance_Wallet_History_Id"
                                                value={values.Delivery_Advance_Wallet_History_Id}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Delivery Final Wallet History ID"
                                                name="Delivery_Final_Wallet_History_Id"
                                                value={values.Delivery_Final_Wallet_History_Id}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Payment Mode"
                                                name="Payment_Mode"
                                                value={values.Payment_Mode}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Payment Mode"
                                                name="Advance_Payment_Mode"
                                                value={values.Advance_Payment_Mode}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Payment Transaction ID"
                                                name="Payment_Transaction_Id"
                                                value={values.Payment_Transaction_Id}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.ESI_Commision_Amount && errors.ESI_Commision_Amount)}
                                                fullWidth
                                                helperText={touched.ESI_Commision_Amount && errors.ESI_Commision_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="ESI Commission Amount"
                                                name="ESI_Commision_Amount"
                                                value={values.ESI_Commision_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Esi_Commission_Amount_After_Tax && errors.Esi_Commission_Amount_After_Tax)}
                                                fullWidth
                                                helperText={touched.Esi_Commission_Amount_After_Tax && errors.Esi_Commission_Amount_After_Tax}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="ESI Commission Amount After Tax"
                                                name="Esi_Commission_Amount_After_Tax"
                                                value={values.Esi_Commission_Amount_After_Tax}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Esi_Commission_Amount_Before_Tax && errors.Esi_Commission_Amount_Before_Tax)}
                                                fullWidth
                                                helperText={touched.Esi_Commission_Amount_Before_Tax && errors.Esi_Commission_Amount_Before_Tax}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="ESI Commission Amount Before Tax"
                                                name="Esi_Commission_Amount_Before_Tax"
                                                value={values.Esi_Commission_Amount_Before_Tax}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Esi_Commission_Tax_Amount && errors.Esi_Commission_Tax_Amount)}
                                                fullWidth
                                                helperText={touched.Esi_Commission_Tax_Amount && errors.Esi_Commission_Tax_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="ESI Commission Tax Amount"
                                                name="Esi_Commission_Tax_Amount"
                                                value={values.Esi_Commission_Tax_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Esi_Commission_Tax_Percentage && errors.Esi_Commission_Tax_Percentage)}
                                                fullWidth
                                                helperText={touched.Esi_Commission_Tax_Percentage && errors.Esi_Commission_Tax_Percentage}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="ESI Commission Tax Percentage"
                                                name="Esi_Commission_Tax_Percentage"
                                                value={values.Esi_Commission_Tax_Percentage}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Finalize Delivery Bidding Quote ID"
                                                name="Finalize_Delivery_Bidding_Quote_Id"
                                                value={values.Finalize_Delivery_Bidding_Quote_Id}
                                                variant="outlined"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {biddingridequotes?.map(option => (
                                                    <option key={option.Delivery_Bidding_Quote_Id} value={option.Delivery_Bidding_Quote_Id}>
                                                        {option.Delivery_Bidding_Quote_Id}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Ride Payment Transaction ID"
                                                name="Ride_Payment_Transaction_Id"
                                                value={values.Ride_Payment_Transaction_Id}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Pickup_Zip_Code && errors.Pickup_Zip_Code)}
                                                fullWidth
                                                helperText={touched.Pickup_Zip_Code && errors.Pickup_Zip_Code}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Pickup Zip Code"
                                                name="Pickup_Zip_Code"
                                                value={values.Pickup_Zip_Code}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                                                }}
                                                min={0}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Status && errors.Bidding_Status)}
                                                fullWidth
                                                helperText={touched.Bidding_Status && errors.Bidding_Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Status"
                                                name="Bidding_Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Status}
                                                variant="outlined"
                                            >
                                                {bidstatusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Pickup Address"
                                                name="Pickup_Address"
                                                value={values.Pickup_Address}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        {/* Is_Ride_Advance_Amount_Settled*/}
                                        <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.Is_Ride_Advance_Amount_Settled}
                                                        onChange={handleChange}
                                                        name="Is_Ride_Advance_Amount_Settled"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Ride Advance Amount Settled"
                                            />
                                        </Grid>

                                        {/* Is_Ride_Amount_Settled*/}
                                        <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.Is_Ride_Amount_Settled}
                                                        onChange={handleChange}
                                                        name="Is_Ride_Amount_Settled"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Ride Amount Settled"
                                            />
                                        </Grid>

                                        {/* Advance_Payment_Status*/}
                                        <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.Advance_Payment_Status}
                                                        onChange={handleChange}
                                                        name="Advance_Payment_Status"
                                                        color="primary"
                                                    />
                                                }
                                                label="Advance Payment Status"
                                            />
                                        </Grid>

                                        {/* Final_Payment_Status*/}
                                        <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.Final_Payment_Status}
                                                        onChange={handleChange}
                                                        name="Final_Payment_Status"
                                                        color="primary"
                                                    />
                                                }
                                                label="Final Payment Status"
                                            />
                                        </Grid>

                                        <Box display="flex" pl={2} >
                                            <span style={{ color: '#27B6CC', marginTop: "15%" }}>Cashback Amount: {state.Cashback_Amount}</span>
                                        </Box>
                                        <Grid item md={12} xs={12}>
                                            Description
                                            <TextareaAutosize
                                                value={values.Description}
                                                onChange={handleChange}
                                                name="Description"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Description"
                                            >
                                            </TextareaAutosize>
                                        </Grid>

                                        {values?.Bidding_Status === "CANCELLED" ?
                                            <Grid item md={12} xs={12}>
                                                Cancelled Reason
                                                <TextareaAutosize
                                                    value={values.Cancelled_Reason}
                                                    onChange={handleChange}
                                                    name="Cancelled_Reason"
                                                    onBlur={handleBlur}
                                                    variant="outlined"
                                                    className={classes.TextareaAutosize}
                                                    minRows={5}
                                                    placeholder="Cancelled Reason"
                                                >
                                                </TextareaAutosize>
                                            </Grid>
                                            : null}

                                        {values?.Bidding_Status === "COMPLETED" ?
                                            <>
                                                <Grid item md={12} xs={12}>
                                                    User Review for Ride
                                                    <TextareaAutosize
                                                        value={values.User_Reviews}
                                                        onChange={handleChange}
                                                        name="User_Reviews"
                                                        onBlur={handleBlur}
                                                        variant="outlined"
                                                        className={classes.TextareaAutosize}
                                                        minRows={5}
                                                        placeholder="User Review for Ride"
                                                    >
                                                    </TextareaAutosize>
                                                </Grid>

                                                <Grid item md={12} xs={12}>
                                                    User Review for Vehicle
                                                    <TextareaAutosize
                                                        value={values.Vehicle_Reviews}
                                                        onChange={handleChange}
                                                        name="Vehicle_Reviews"
                                                        onBlur={handleBlur}
                                                        variant="outlined"
                                                        className={classes.TextareaAutosize}
                                                        minRows={5}
                                                        placeholder="User Review for Vehicle"
                                                    >
                                                    </TextareaAutosize>
                                                </Grid>

                                                <Grid item md={6} xs={4}>
                                                    <Box>User Rating for Ride</Box>
                                                </Grid>
                                                <Grid item md={2} xs={4}>
                                                    <Rating
                                                        name="User_Ratings"
                                                        onChange={(event, newValue) => {
                                                            values.User_Ratings = newValue;
                                                            setFieldValue(newValue);
                                                        }}
                                                        value={values.User_Ratings}
                                                        size="medium"
                                                    />
                                                </Grid>

                                                <Grid item md={6} xs={4}>
                                                    <Box>User Rating for Vehicle</Box>
                                                </Grid>
                                                <Grid item md={2} xs={4}>
                                                    <Rating
                                                        name="Vehicle_Ratings"
                                                        onChange={(event, newValue) => {
                                                            values.Vehicle_Ratings = newValue;
                                                            setFieldValue(newValue);
                                                        }}
                                                        value={values.Vehicle_Ratings}
                                                        size="medium"
                                                    />
                                                </Grid>
                                            </>
                                            : null}

                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>
                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditUserRideBidding.propTypes = {
    className: PropTypes.string
};

export default EditUserRideBidding;