import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import TotalOrders from './count/TotalOrders';
import DeliveredOrders from './count/DeliveredOrders';
import TopdelPersons from './top/TopdeliveryPersons';
import ReturnedOrders from './count/ReturnedOrders';
import CancelledOrders from './count/CancelledOrders';
import OutfordeliveryOrders from './count/OutfordeliveryOrders';
import { useDispatch, useSelector } from 'react-redux';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DeliveryDashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const [seconds, setSeconds] = React.useState(-1);

  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  const RolePermissions = useSelector(state => state.rolepermission.Roles);
  useEffect(() => {
    dispatch(getRolePermissions(user));
    setSeconds(5);
  }, []);

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Button style={{ marginBottom: "10px" }}
          component={RouterLink}
          to="/app/delivery-management"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go Back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Dashboard
          </Typography>
        </Box>
        <Grid
          container
          spacing={3}
        >
          {/* Total Orders */}
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <TotalOrders setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

          {/* Delivered Orders */}
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <DeliveredOrders setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

          {/* Outfordelivery Orders */}
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <OutfordeliveryOrders setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

          {/* Cancelled Orders */}
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <CancelledOrders setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

          {/* Returned Orders */}
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <ReturnedOrders setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>
        </Grid>

        <Grid container spacing={3} >
          {/* Top Delivery Persons */}
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <TopdelPersons setSeconds={setSeconds} RolePermissions={RolePermissions} />
          </Grid>

        </Grid>
      </Container>
    </Page>
  );
};

export default DeliveryDashboard;