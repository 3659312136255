import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateTestimonialAction
} from './../../store/actions/testimonialAction';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import Rating from '@material-ui/lab/Rating';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import { useLocation } from 'react-router-dom';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '--Select Status--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  root: {},
  images: {
    width: 120,
    height: 120,
    borderRadius: 5,
    marginBottom: 15
  },
  review: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid black'
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditTestimonial = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  let { state } = location;

  const errorMessage = useSelector(state => state.testimonials.testimonialUpdate.isLoading);
  const isSubmited = useSelector(state => state.testimonials.testimonialUpdate.isLoading);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);

  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Description || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Description);

  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(2);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <Formik
      initialValues={{
        Description: state.Description ? state.Description : "",
        Modified_By: modifiedBy,
        Rating: parseInt(state.Rating),
        Status: state.Status ? state.Status : "",
        Testimonial_Id: state.Testmonial_Id ? state.Testmonial_Id : "",
        Title: state.Title ? state.Title : "",
        User_Email_Id: state.User_Email_Id ? state.User_Email_Id : "",
      }}
      validationSchema={
        Yup.object().shape({
          Title: Yup.string().required('Title is required.'),
          User_Email_Id: Yup.string().email("Enter proper Email ID").required("Email ID is Required."),
          Status: Yup.string().required('Status is required.'),
          Rating: Yup.number().required('Please provide Rating.').nullable(),
        })
      }

      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        let submitTest = true;
        const errors = {};
        values.Description = rhDiscription;
        if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
        setErrors(errors)
        if (submitTest) {
          let formData = JSON.parse(JSON.stringify(values));
          dispatch(updateTestimonialAction(formData, navigate));
        }
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} >
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    {/*Input Field  Title */}
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Title && errors.Title)}
                        fullWidth
                        helperText={touched.Title && errors.Title}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Title"
                        name="Title"
                        value={values.Title}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Description */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                      </span>
                    </Grid>

                    {/* User Email ID */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.User_Email_Id && errors.User_Email_Id)}
                        fullWidth
                        helperText={touched.User_Email_Id && errors.User_Email_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Email ID"
                        name="User_Email_Id"
                        value={values.User_Email_Id}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        placeholder="Status"
                        name="Status"
                        onChange={handleChange}
                        label="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        helperText={<ErrorMessage name="Status" />}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* {Rating} */}
                    <Grid item md={1} xs={1}>
                      <Box>
                        Rating</Box></Grid>
                    <Grid item md={3} xs={3}>
                      <Rating
                        name="Rating"
                        onChange={(event, newValue) => {
                          values.Rating = newValue;
                          setFieldValue(newValue);
                        }}
                        value={values.Rating}
                        size="large"
                      />
                      <span style={{ color: "red" }}>
                        {<ErrorMessage name="Rating" />}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

updateTestimonialAction.propTypes = {
  className: PropTypes.string
};

export default EditTestimonial;