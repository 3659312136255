import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  InputAdornment,
  SvgIcon,
  TextField,
  makeStyles,
  Button
} from '@material-ui/core';
import { Link as RouterLink,useLocation,useNavigate } from 'react-router-dom';
import { Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  formFilterControl: {
    margin: theme.spacing(1),
    minWidth: 200
  }
}));

const Toolbar = ({ className, RolePermissions, roleUserType, filters, setfilters, ...rest }) => {
  const classes = useStyles();
  const [keyName,setKeyName]=useState('')
  const location = useLocation();
    let { state } = location;
    const navigate=useNavigate();
  const handleChange = event => {
    setfilters({
      ...filters,
      SearchFor:event.target.value,

      
    });
   
  };

  const handleSearchChange = event => {
    setfilters({
      ...filters,
      Search:event.target.value,
    });
  };

  const AddService = serviceData => e => {
    navigate("/app/add-serviceCategoryCity", { state: serviceData });
  };
  

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" flexWrap="wrap" mb={2}>
          <FormControl variant="outlined" className={classes.formFilterControl}>
            <Select
              value={filters.SearchFor}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Svcc_Id">Svcc Id</MenuItem>
              <MenuItem value="Sv_Name">Sv Name</MenuItem>
              <MenuItem value="Status">Status</MenuItem>
              <MenuItem value="Country_Name">Country</MenuItem>

            </Select>
          </FormControl>
          <Box width={300}>
            <TextField
              onChange={handleSearchChange}
              className={classes.formControl}
              value={filters.search}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder={`Search Services`}
              variant="outlined"
            />
          </Box>
        </Box>
        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
          (RolePermissions?.User_Services_Management_Write?.Value === "User_Services_Management_Write" && RolePermissions?.User_Services_Management_Write?.Is_Write === 1) ?
          <Button
            component={RouterLink}
            // onClick={AddService(state)}
            to="/app/add-serviceCategoryCity"
            state={state}
            color="primary"
            variant="contained"
            style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}>
            <span style={{ color: "white" }}>
              + ADD City Category SERVICES
            </span>
          </Button>
          : null}
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  RolePermissions: PropTypes.object,
  roleUserType: PropTypes.object,
  filters: PropTypes.object,
  setfilters: PropTypes.func
};

export default Toolbar;














