import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles, TextareaAutosize
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { createBiddingUserProductAction, getAllbiddingproducts, getAllBiddingProducts, getAllUserEmails } from 'src/store/actions/biddinguserproductsAction';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";

const bidstatusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'INITIATED',
        label: 'INITIATED'
    },
    {
        value: 'PROCESSING',
        label: 'PROCESSING'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
    {
        value: 'COMPLETED',
        label: 'COMPLETED'
    },
];


const useStyles = makeStyles(() => ({
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    },
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const AddBiddingUserProducts = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        dispatch(getAllUserEmails())
        dispatch(getAllBiddingProducts())
    }, []);

    const isSubmitted = useSelector(state => state.biddinguserproducts.biddinguserproductCreate.isLoading);
    const errorMessage = useSelector(state => state.biddinguserproducts.biddinguserproductCreate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const useremailssearch = useSelector(state => state.biddinguserproducts.BidUserEmails.biduseremails);
    const products = useSelector(state => state.biddinguserproducts.Biddingproducts.products);


   

    const [productsdata, setProductsData] = React.useState([]);
    const callBacksubtypeproductsData = (data) => {
        setProductsData(data[0].Measurement_Type.split(",").map(Values=>({Measurement_Type:Values})))
    }

    const setproductType = (value) => {
        if (value !== "") {
            let data = {
                search: "",
                search_by_filter: "",
                Records_Filter: 'FILTER',
                Product_Name: "",
                Status: "",
                Bidding_Product_Id: value,
            }
            dispatch(getAllbiddingproducts(data, callBacksubtypeproductsData))
        }
    }

    // Schedule Call
    let nDate = new Date();
    let month = String(nDate.getMonth() + 1).length > 1 ? String(nDate.getMonth() + 1) : "0" + String(nDate.getMonth() + 1);
    let date = String(nDate.getDate()).length > 1 ? String(nDate.getDate()) : "0" + String(nDate.getDate());
    const [dos, setDos] = useState(nDate.getFullYear() + "-" + month +
        "-" + date);

    return (
        <Formik
            initialValues={{
                Bidding_Expiry_Date: "",
                Bidding_Product_Id: "",
                Description: "",
                Measurement_Type: "",
                Quantity: "",
                User_Address_Id: "",
                User_Email_Id: "",
                Created_By: loginEmail,
                User_Pincode: "",
                Bidding_Status:""

            }}
            validationSchema={
                Yup.object().shape({
                    Bidding_Product_Id: Yup.string().required('Bidding Product ID is required.'),
                    Quantity:Yup.number().min(0, "Qunatity should not be less than 0.").required('Quantity is required.'),
                    Bidding_Status: Yup.string().required('Bidding Status is required.'),
                    Measurement_Type: Yup.string().required('Measurement Type is required.'),
                    User_Email_Id: Yup.string().required('User Email ID is required.'),
                    User_Pincode: Yup.string().matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/,
                    'Please provide valid Pin Code.')
                    .required('Pin Code is required.'),
                    Bidding_Expiry_Date: Yup.string().test("date-of-service", "Bidding Expiry Date cannot be past date.", (value) => {
                        if (value) {
                            let date1 = new Date(value).getTime();
                            let date2 = new Date(dos).getTime();
                            if (date1 >= date2) {
                                return true;
                            }
                        }
                        return false;
                    }),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(createBiddingUserProductAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        

                                        <Grid item md={6} xs={12}>
                                            <Autocomplete
                                                freeSolo
                                                options={useremailssearch}
                                                groupBy={(option) => option.firstLetter}
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option.Email_Id}
                                                renderInput={(params) => <TextField {...params}
                                                    error={Boolean(touched.User_Email_Id && errors.User_Email_Id)}
                                                    helperText={touched.User_Email_Id && errors.User_Email_Id}
                                                    onBlur={handleBlur}
                                                    label="User Email ID"
                                                    name="User_Email_Id"
                                                    variant="outlined"
                                                    value={values.User_Email_Id}
                                                    onChange={() => {
                                                        dispatch(getAllUserEmails(params.inputProps.value))
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill,
                                                        startAdornment: (
                                                            <React.Fragment>
                                                                <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                {params.InputProps.startAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />}
                                                onChange={(e, value) => {
                                                    setFieldValue("User_Email_Id", value?.Email_Id ? value.Email_Id : "");
                                                }}
                                            />
                                        </Grid>


                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.User_Pincode && errors.User_Pincode)}
                                                fullWidth
                                                helperText={touched.User_Pincode && errors.User_Pincode}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="User Pincode"
                                                name="User_Pincode"
                                                value={values.User_Pincode}
                                                variant="outlined"
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                                                }}
                                                min={0}
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Quantity && errors.Quantity)}
                                                fullWidth
                                                helperText={touched.Quantity && errors.Quantity}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Quantity"
                                                name="Quantity"
                                                value={values.Quantity}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Product_Id && errors.Bidding_Product_Id)}
                                                fullWidth
                                                helperText={touched.Bidding_Product_Id && errors.Bidding_Product_Id}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setproductType(e.target.value)
                                                }}
                                                label="Bidding Product Name"
                                                name="Bidding_Product_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Product_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">-Please Select-</option>
                                                {products?.sort((a, b) => a.Product_Name?.localeCompare(b.Product_Name))?.map(option => (
                                                    <option key={option.Bidding_Product_Id} value={option.Bidding_Product_Id}>
                                                         {option.Product_Name.substring(0, 35)}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>


                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Measurement_Type && errors.Measurement_Type)}
                                                fullWidth
                                                helperText={touched.Measurement_Type && errors.Measurement_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Measurement Type"
                                                name="Measurement_Type"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Measurement_Type}
                                                variant="outlined"
                                            >

                                                <option key="" value="">-Please Select-</option>
                                                {productsdata?.map((option,i) => (
                                                    <option key={i} value={option.Measurement_Type}>
                                                        {option.Measurement_Type}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}
                                                fullWidth
                                                helperText={touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="date"
                                                label="Bidding Expiry Date"
                                                name="Bidding_Expiry_Date"
                                                value={values.Bidding_Expiry_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Status && errors.Bidding_Status)}
                                                fullWidth
                                                helperText={touched.Bidding_Status && errors.Bidding_Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Status"
                                                name="Bidding_Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Status}
                                                variant="outlined"
                                            >
                                                {bidstatusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            Description
                                            <TextareaAutosize
                                                value={values.Description}
                                                onChange={handleChange}
                                                name="Description"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Description"
                                            >
                                            </TextareaAutosize>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddBiddingUserProducts.propTypes = {
    className: PropTypes.string
};

export default AddBiddingUserProducts;