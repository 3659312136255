import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Popup_Id', numeric: false, disablePadding: false, label: 'Popup ID' },
  { id: 'Title', numeric: false, disablePadding: false, label: 'Title' },
  { id: 'Content', numeric: false, disablePadding: false, label: 'Content' },
  { id: 'Created_Date', numeric: false, disablePadding: false, label: ' Created Date' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allPopup,
  deletePopupHandler,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allPopupNoFilter,
  RolePermissions,
  roleUserType,
  pagination,
  setPagination,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedPopupIds, setSelectedPopupIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const handleClose = (value) => {
    setOpen(false);
  };

  /*===============================
              handler     
   =================================*/

  const handleSelectAll = event => {
    let newSelectedPopupIds;
    if (event.target.checked) {
      newSelectedPopupIds = allPopup.map(popup => popup.Popup_Id);
    } else {
      newSelectedPopupIds = [];
    }
    setSelectedPopupIds(newSelectedPopupIds);
  };

  const handleSelectOne = (event, id) => {
    setSelectedPopupIds(selectedPopupIds.filter(record => {
      for (let i = 0; i < allPopupNoFilter.length; i++) {
        if (record === allPopupNoFilter[i].Popup_Id) {
          return true;
        }
      }
      return false;
    }))
    const selectedIndex = selectedPopupIds.indexOf(id);
    let newSelectedPopupIds = [];
    if (selectedIndex === -1) {
      newSelectedPopupIds = newSelectedPopupIds.concat(selectedPopupIds, id);
    } else if (selectedIndex === 0) {
      newSelectedPopupIds = newSelectedPopupIds.concat(
        selectedPopupIds.slice(1)
      );
    } else if (selectedIndex === selectedPopupIds.length - 1) {
      newSelectedPopupIds = newSelectedPopupIds.concat(
        selectedPopupIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedPopupIds = newSelectedPopupIds.concat(
        selectedPopupIds.slice(0, selectedIndex),
        selectedPopupIds.slice(selectedIndex + 1)
      );
    }
    setSelectedPopupIds(newSelectedPopupIds);
  };

  const deleteSelectedPopupHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const editPopupHandler = popupData => e => {
    navigate('/app/edit-popup', { state: popupData });
  };


  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedPopupIds.filter(record => {
                      for (let i = 0; i < allPopupNoFilter.length; i++) {
                        if (record === allPopupNoFilter[i].Popup_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allPopup.length}
                    color="primary"
                    indeterminate={
                      selectedPopupIds.filter(record => {
                        for (let i = 0; i < allPopupNoFilter.length; i++) {
                          if (record == allPopupNoFilter[i].Popup_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedPopupIds.filter(record => {
                        for (let i = 0; i < allPopupNoFilter.length; i++) {
                          if (record === allPopupNoFilter[i].Popup_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allPopup.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell align="center"
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell align="center" className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions
                  &nbsp;&nbsp;
                  {selectedPopupIds.filter(record => {
                    for (let i = 0; i < allPopupNoFilter.length; i++) {
                      if (record === allPopupNoFilter[i].Popup_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedPopupIds.filter(record => {
                    for (let i = 0; i < allPopupNoFilter.length; i++) {
                      if (record === allPopupNoFilter[i].Popup_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedPopupHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allPopup) &&
                (allPopup).map(popup => (
                  <TableRow
                    hover
                    key={popup.Popup_Id}
                    selected={
                      selectedPopupIds.indexOf(popup.Popup_Id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedPopupIds.indexOf(popup.Popup_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, popup.Popup_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell align="center" width="12%">{popup.Popup_Id}</TableCell>
                    <TableCell align="center" width="17%">{popup.Title?.substring(0, 15)}...</TableCell>
                    <TableCell align="center" width="25%" ><div style={{ maxHeight: "40px", overflowY: "hidden", lor: '#1665D8' }} dangerouslySetInnerHTML={{ __html: popup.Content }}></div></TableCell>
                    <TableCell align="center" width="12%">{popup.Created_Date}</TableCell>
                    <TableCell align="center" style={{ width: "10%", color: '#1665D8' }} >{popup.Status}
                    </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Popup_Management_Write?.Value === "Popup_Management_Write" && RolePermissions?.Popup_Management_Write?.Is_Write === 1) ?
                      <TableCell align="center">
                        <Button
                          onClick={editPopupHandler(popup)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                          <DeleteIcon
                            onClick={deletePopupHandler(popup)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                      : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allPopup) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allPopup: PropTypes.array.isRequired
};

export default Results;