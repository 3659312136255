import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    makeStyles,
    TextareaAutosize,
    TextField,
    Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { createBidRideDeliPaymentAction } from 'src/store/actions/userridebiddingsAction';

const useStyles = makeStyles(() => ({
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const paymentmodeOptions = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'WALLET',
        label: 'WALLET'
    },
    {
        value: 'ONLINE',
        label: 'ONLINE'
    },
    {
        value: 'BANK',
        label: 'BANK'
    }
];

const paymenttypeOptions = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'ADVANCE',
        label: 'ADVANCE'
    },
    {
        value: 'FINAL',
        label: 'FINAL'
    }
];

const UserRideDeliveryPayment = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const location = useLocation();
    let { state } = location;


    const isSubmited = useSelector(state => state.userridebidding.ridebiddeliverypayment.isLoading);
    const errorMessage = useSelector(state => state.userridebidding.ridebiddeliverypayment.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    return (
        <Formik
            initialValues={{
                User_Ride_Bidding_Id: state.User_Ride_Bidding_Id ? state.User_Ride_Bidding_Id : "",
                Email_Id: state.Del_User_Email_Id ? state.Del_User_Email_Id : "",
                Name: state.Delivery_Person_Name ? state.Delivery_Person_Name : "",
                Mobile: state.Delivery_Mobile ? state.Delivery_Mobile : "",
                Payment_Mode: "",
                Payment_Type: "",
                Amount: "",
                UPI: "",
                Account_Number: "",
                IFSC_Code: "",
                Bank_Transaction_ID: "",
                Bank_Transaction_Date: "",
                Bank_Fee: "",
                Bank_Tranascation_Status: "",
                Purpose: "",
                Created_By: loginEmail,
            }}
            validationSchema={
                Yup.object().shape({
                    Payment_Mode: Yup.string().required('Payment Mode is required.'),
                    Payment_Type: Yup.string().required('Payment Type is required.'),
                    Amount: Yup.string().matches(/^\d+(\.\d{1,2})?$/,'Please enter valid Amount').required('Amount is required.'),
                    UPI: Yup.string().when('Payment_Mode', {
                        is: "ONLINE", then: Yup.string().matches(/[a-zA-Z0-9_]{5,}@[a-zA-Z]{3,}/, " UPI ID is not valid.").required('UPI ID is required.'),
                    }),
                    Account_Number: Yup.string().when('Payment_Mode', {
                        is: "BANK", then: Yup.string().min(9, 'Account Number should not be less than 9 characters.').required('Account Number is required.'),
                    }),
                    IFSC_Code: Yup.string().when('Payment_Mode', {
                        is: "BANK", then: Yup.string().matches(/^(?=.*[A-Z])(?=.*[0-9])/, "IFSC Code is not valid.").required('IFSC Code is required.'),
                    }),
                    Bank_Transaction_ID: Yup.string().when('Payment_Mode', {
                        is: "BANK", then: Yup.string().required('Bank Transaction ID is required.'),
                    }),
                    Bank_Transaction_Date: Yup.date().when('Payment_Mode', {
                        is: "BANK", then: Yup.date().min(new Date(state.Ride_Start_Date) < new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)
                            ? new Date(new Date(state.Ride_Start_Date) - 1 * 24 * 60 * 60 * 1000)
                            : new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), new Date(state.Ride_Start_Date) < new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)
                            ? "Bank Transaction Date greater than or equal to " + ((new Date(state.Ride_Start_Date).getMonth() > 8) ? (new Date(state.Ride_Start_Date).getMonth() + 1)
                                : ('0' + (new Date(state.Ride_Start_Date).getMonth() + 1))) + '/' + ((new Date(state.Ride_Start_Date).getDate() > 9)
                                    ? new Date(state.Ride_Start_Date).getDate()
                                    : ('0' + new Date(state.Ride_Start_Date).getDate())) + '/' + new Date(state.Ride_Start_Date).getFullYear() + "."
                            : "Require Bank Transaction Date greater than or equal to today.").required('Bank Transaction Date is required.'),
                    }),
                })
            }
            onSubmit={(values) => {
                let submitTest = true;
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(createBidRideDeliPaymentAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={6} xs={12}>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                gutterBottom
                                                style={{ color: '#66788A' }}
                                            >
                                                User Ride Bidding ID: {values.User_Ride_Bidding_Id}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                gutterBottom
                                                style={{ color: '#66788A' }}
                                            >
                                                Email ID: {values.Email_Id}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                gutterBottom
                                                style={{ color: '#66788A' }}
                                            >
                                                Name: {values.Name}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                gutterBottom
                                                style={{ color: '#66788A' }}
                                            >
                                                Mobile: {values.Mobile}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                gutterBottom
                                                style={{ color: '#66788A' }}
                                            >
                                                Advance Amount: {state.Delivery_Advance_Amount}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                gutterBottom
                                                style={{ color: '#66788A' }}
                                            >
                                                Final Amount: {state.Ride_Balance_Amount}
                                            </Typography>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Payment_Mode && errors.Payment_Mode)}
                                                fullWidth
                                                helperText={touched.Payment_Mode && errors.Payment_Mode}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Payment Mode"
                                                name="Payment_Mode"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Payment_Mode}
                                                variant="outlined"
                                            >
                                                {paymentmodeOptions?.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Payment_Type && errors.Payment_Type)}
                                                fullWidth
                                                helperText={touched.Payment_Type && errors.Payment_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Payment Type"
                                                name="Payment_Type"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Payment_Type}
                                                variant="outlined"
                                            >
                                                {
                                                    paymenttypeOptions?.map(option => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))
                                                }
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Amount && errors.Amount)}
                                                fullWidth
                                                helperText={touched.Amount && errors.Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                label="Amount"
                                                name="Amount"
                                                value={values.Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        {values.Payment_Mode === "ONLINE" ?
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    error={Boolean(touched.UPI && errors.UPI)}
                                                    fullWidth
                                                    helperText={touched.UPI && errors.UPI}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="UPI ID"
                                                    name="UPI"
                                                    value={values.UPI}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            : null}

                                        {values.Payment_Mode === "BANK" ?
                                            <>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.Account_Number && errors.Account_Number)}
                                                        fullWidth
                                                        helperText={touched.Account_Number && errors.Account_Number}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        label="Account Number"
                                                        name="Account_Number"
                                                        value={values.Account_Number}
                                                        variant="outlined"
                                                        inputProps={{ maxLength: 18 }}
                                                    />
                                                </Grid>

                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.IFSC_Code && errors.IFSC_Code)}
                                                        fullWidth
                                                        helperText={touched.IFSC_Code && errors.IFSC_Code}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        label="IFSC Code"
                                                        name="IFSC_Code"
                                                        value={values.IFSC_Code}
                                                        variant="outlined"
                                                        inputProps={{ maxLength: 21 }}
                                                    />
                                                </Grid>

                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.Bank_Transaction_Date && errors.Bank_Transaction_Date)}
                                                        fullWidth
                                                        helperText={touched.Bank_Transaction_Date && errors.Bank_Transaction_Date}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        label="Bank Transaction Date"
                                                        name="Bank_Transaction_Date"
                                                        value={values.Bank_Transaction_Date}
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        type="datetime-local"
                                                    >
                                                    </TextField>
                                                </Grid>

                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.Bank_Transaction_ID && errors.Bank_Transaction_ID)}
                                                        fullWidth
                                                        helperText={touched.Bank_Transaction_ID && errors.Bank_Transaction_ID}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        label="Bank Transaction ID"
                                                        name="Bank_Transaction_ID"
                                                        value={values.Bank_Transaction_ID}
                                                        variant="outlined"
                                                    />
                                                </Grid>

                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        type="number"
                                                        label="Bank Fee"
                                                        name="Bank_Fee"
                                                        value={values.Bank_Fee}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        type="number"
                                                        label="Bank Tranascation Status"
                                                        name="Bank_Tranascation_Status"
                                                        value={values.Bank_Tranascation_Status}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </>
                                            : null}

                                        <Grid item md={12} xs={12}>
                                            Purpose
                                            <TextareaAutosize
                                                value={values.Purpose}
                                                onChange={handleChange}
                                                name="Purpose"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={5}
                                                placeholder="Purpose"
                                            >
                                            </TextareaAutosize>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

UserRideDeliveryPayment.propTypes = {
    className: PropTypes.string
};

export default UserRideDeliveryPayment;