import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WalletIcon from '@mui/icons-material/Wallet';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  buttonRoot: {
    minWidth: '7px',
    padding: '6px'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));

const headCells = [
  { id: 'Wallet_Id', numeric: false, disablePadding: false, label: 'Partner Wallet ID' },
  { id: 'Partner_Name', numeric: false, disablePadding: false, label: 'Wallet Owner' },
  { id: 'Total_Amount', numeric: false, disablePadding: false, label: 'Total Amount' },
  { id: 'Remaining_Balance', numeric: false, disablePadding: false, label: 'Remaining Amount' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allWallets,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  allWalletsNoFilter,
  createSortHandler,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedWalletIds, setSelectedWalletIds] = useState([]);

  const editWalletHandler = walletData => e => {
    navigate('/app/edit-partnerwallet', { state: walletData });
  };
  const visibleWalletHandler = walletData => e => {
    navigate("/app/partnerwallethistory-management", { state: walletData });
  };
  const viewWalletHandler = walletData => e => {
    navigate("/app/view-partner-wallet", { state: walletData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Partner_Wallet_Management_Write?.Value === "Partner_Wallet_Management_Write" && RolePermissions?.Partner_Wallet_Management_Write?.Is_Write === 1) ?
                  <TableCell>Wallet History</TableCell>
                  : null}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allWallets) &&
                (allWallets).map(wallet => (
                  <TableRow
                    hover
                    key={wallet.Wallet_Id}
                    selected={
                      selectedWalletIds.indexOf(wallet.Wallet_Id) !== -1
                    }
                  >
                    <TableCell>{wallet.Wallet_Id}</TableCell>
                    <TableCell>{wallet.Partner_Name}</TableCell>
                    <TableCell>{wallet.Total_Amount}</TableCell>
                    <TableCell>{wallet.Remaining_Balance}</TableCell>
                    <TableCell>{wallet.Status}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Partner_Wallet_Management_Write?.Value === "Partner_Wallet_Management_Write" && RolePermissions?.Partner_Wallet_Management_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Tooltip title="Wallet History">
                          <Button
                            onClick={visibleWalletHandler(wallet)}
                            color="primary"
                          >
                            <WalletIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                      : null}
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Partner_Wallet_Management_Write?.Value === "Partner_Wallet_Management_Write" && RolePermissions?.Partner_Wallet_Management_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Button
                          onClick={viewWalletHandler(wallet)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button
                          onClick={editWalletHandler(wallet)}>
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Partner_Wallet_Management_Read?.Value === "Partner_Wallet_Management_Read" && RolePermissions?.Partner_Wallet_Management_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Button
                          onClick={viewWalletHandler(wallet)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allWallets) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;