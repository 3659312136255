import {
  SHIPPING_REQUEST,
  SHIPPING_SUCCESS,
  SHIPPING_FAIL,

  SHIPPING_GET_REQUEST,
  SHIPPING_GET_SUCCESS,
  SHIPPING_GET_FAIL,

  SHIPPING_FILTER,
} from '../actions/shippingAction'

const initialState = {
  shippings: { all: [], error: '', isLoading: false },
  shipping: { shipping: {}, error: '', isLoading: false },
  shippingFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHIPPING_REQUEST:
      return { ...state, shippings: { all: state.shippings.all, error: '', isLoading: true } };
    case SHIPPING_SUCCESS:
      return { ...state, shippings: { all: action.payload, error: '', isLoading: false } };
    case SHIPPING_FAIL:
      return { ...state, shipping: { all: [], error: action.payload, isLoading: false } };

    case SHIPPING_GET_REQUEST:
      return { ...state, shipping: { shipping: {}, error: '', isLoading: true } };
    case SHIPPING_GET_SUCCESS:
      return { ...state, shipping: { shipping: action.payload, error: '', isLoading: false } };
    case SHIPPING_GET_FAIL:
      return { ...state, shipping: { shipping: {}, error: action.payload, isLoading: false } };

    case SHIPPING_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, shippingFilter: { search: state.shippingFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, shippingFilter: { select: state.shippingFilter.select, search: action.value } };
      }
      return res;

    default:
      return state;
  }
}