import config from './config';
import { toast } from 'react-toastify';
import { boomiAdminApi, nodeGetApi } from './commonAxios';

export const SINGLE_B2B_ORDER_REQUEST = 'SINGLE_B2B_ORDER_REQUEST';
export const SINGLE_B2B_ORDER_SUCCESS = "SINGLE_B2B_ORDER_SUCCESS";
export const SINGLE_B2B_ORDER_FAIL = "SINGLE_B2B_ORDER_FAIL";

export const B2B_ORDER_UPDATE_REQUEST = "B2B_ORDER_UPDATE_REQUEST";
export const B2B_ORDER_UPDATE_SUCCESS = "B2B_ORDER_UPDATE_SUCCESS";
export const B2B_ORDER_UPDATE_FAIL = "B2B_ORDER_UPDATE_FAIL";

export const B2B_ORDER_ESIDELIPERSONS_REQUEST = "B2B_ORDER_ESIDELIPERSONS_REQUEST";
export const B2B_ORDER_ESIDELIPERSONS_SUCCESS = "B2B_ORDER_ESIDELIPERSONS_SUCCESS";
export const B2B_ORDER_ESIDELIPERSONS_FAIL = "B2B_ORDER_ESIDELIPERSONS_FAIL";

export const B2B_ORDER_PARTNERDELIPERSONS_REQUEST = "B2B_ORDER_PARTNERDELIPERSONS_REQUEST";
export const B2B_ORDER_PARTNERDELIPERSONS_SUCCESS = "B2B_ORDER_PARTNERDELIPERSONS_SUCCESS";
export const B2B_ORDER_PARTNERDELIPERSONS_FAIL = "B2B_ORDER_PARTNERDELIPERSONS_FAIL";

//Get single B2B Order Action
export const getsingleB2BOrderAction = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: SINGLE_B2B_ORDER_REQUEST
        });
        let { data } = await nodeGetApi.post(`/B2B_Order/Get_By_Itemmaster_Product?PageNo=1&PageSize=${config.pageSize}&SortBy=SHOPPER_ORDER_ID&SortOrder=ASC`, formData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: SINGLE_B2B_ORDER_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: SINGLE_B2B_ORDER_SUCCESS,
                    payload: data?.results[0]
                });
            }
        }
    } catch (err) {
        dispatch({
            type: SINGLE_B2B_ORDER_FAIL,
            payload: err
        });
    }
};

//update B2B order status
export const updateB2BOrderStatusAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
    dispatch({
        type: B2B_ORDER_UPDATE_REQUEST
    });
    setErrorMessage({ global: { key: '', message: '' } });
    boomiAdminApi.put(`/B2B_Order/B2B_SuperAdmin_Order_Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: B2B_ORDER_UPDATE_SUCCESS
                });
                toast('Details updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/b2b-order-history')
            } else {
                const errors = {};
                setErrors(errors)
                setErrorMessage({ global: { key: '', message: successResponse.UI_Display_Message ? successResponse.UI_Display_Message : '' } });
                dispatch({
                    type: B2B_ORDER_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: B2B_ORDER_UPDATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};

//get all B2B ESI delivery persons
export const getAllB2BESIDeliveryPersonsAction = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: B2B_ORDER_ESIDELIPERSONS_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Super_Admin/Delivery_Person_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=DEL_USER_EMAIL_ID&SortOrder=ASC`, formData);
        if (data) {
            if (data?.Success_Response?.Is_Data_Exist === '0') {
                dispatch({
                    type: B2B_ORDER_ESIDELIPERSONS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: B2B_ORDER_ESIDELIPERSONS_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: B2B_ORDER_ESIDELIPERSONS_FAIL,
            payload: err
        });
    }
};

//get all B2B partner delivery persons
export const getAllB2BPartnerDeliveryPersonsAction = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: B2B_ORDER_PARTNERDELIPERSONS_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Super_Admin/Delivery_Person_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=DEL_USER_EMAIL_ID&SortOrder=ASC`, formData);
        if (data) {
            if (data?.Success_Response?.Is_Data_Exist === '0') {
                dispatch({
                    type: B2B_ORDER_PARTNERDELIPERSONS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: B2B_ORDER_PARTNERDELIPERSONS_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: B2B_ORDER_PARTNERDELIPERSONS_FAIL,
            payload: err
        });
    }
};

export const getSingleb2bOrderAction = (formData, callBackb2borderData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/B2B_Order/Get_By_Itemmaster_Product?PageNo=1&PageSize=${config.pageSize}&SortBy=SHOPPER_ORDER_ID&SortOrder=ASC`, formData);
        if (data) {
            callBackb2borderData(data)
        }
    } catch (err) {
    }
};