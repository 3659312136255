import { v4 as uuid } from 'uuid';

export default [
    {
        id: uuid(),
        hide: 0,
        link: '/app/services-bidding',
        createdAt: '',
        description: 'Bidding is an offer to set a price tag by an individual or business for a Service that something be done. Bidding is used to determine the cost or value of something.',
        media: '/static/Dashboard/bidding.png',
        title: "Bidding Services",
        total: ""
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/bidding-partner-avail-services',
        createdAt: '',
        description: 'Bidding is an offer to set a price tag by an individual or business for a product that something be done. Bidding is used to determine the cost or value of something.',
        media: '/static/Dashboard/bidding.png',
        title: 'Partner Bidding Available Services',
        total: ''
    },
    
    {
        id: uuid(),
        hide: 0,
        link: '/app/bidding-services-success-stories',
        createdAt: '',
        description: 'Bidding is an offer to set a price tag by an individual or business for a Ride that something be done. Bidding is used to determine the cost or value of something.',
        media: '/static/Dashboard/bidding.png',
        title: 'Bidding Services Success Stories',
        total: ''
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/bidding-user-services',
        createdAt: '',
        description: 'Bidding is an offer to set a price tag by an individual or business for a Ride that something be done. Bidding is used to determine the cost or value of something.',
        media: '/static/Dashboard/bidding.png',
        title: 'Bidding User Services',
        total: ''
    },
];