import React, { useState, useEffect } from 'react';
import { Link as RouterLink ,useLocation} from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';
import { paginationrequestServices } from 'src/store/actions/node-actions/nodepagination';
import { deletevarientActions } from 'src/store/actions/servicesvarientAction';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const NewServicerequest = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  // const location = useLocation();
  // const { state } = location;

  //permissions
  const RolePermissions = useSelector(state => state.rolepermission.Roles);
  const roleUserType = useSelector(state => state.auth.user);
  const Login_Details = useSelector(state => state.auth.user);
  const [services, setnewServices] = useState([]);
  const [filters, setFilters] = React.useState({
    Search: '',
    SearchFor: 'All',
  });
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = useState({
    PageNo: 1,
    PageSize: 10,
    SortBy: "Request_Id",
    SortOrder: "ASC"
  });

  const handleLimitChange = event => {
    setPagination({ ...pagination, PageSize: +event.target.value, PageNo: 1 });
  };

  const handlePageChange = (event, newPage) => {
    setPagination({
      ...pagination,
      PageSize: pagination.PageSize,
      PageNo: newPage + 1
    });
  };
  const callBackPagination = async (status, data, pagination) => {
    if (status) {
      setnewServices(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({
        PageNo: 1,
        PageSize: 10,
        SortBy: "Request_Id",
        SortOrder: "ASC"
      });
    }
  }

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      serviceCall();
    }
  }, [pagination]);

  const [testFilters, setTestFilters] = useState({});
  useEffect(() => {
    if (JSON.stringify(testFilters) != JSON.stringify(filters)) {
      setTestFilters(filters);
      searchFunction();
    }
  }, [filters]);

  const searchFunction = () => {
    const searchpagination = {
      PageNo: 1,
      PageSize: 10,
      SortBy: "Request_Id",
      SortOrder: "ASC"
    }
    dispatch(
      paginationrequestServices(filters, searchpagination, callBackPagination)
    )
  }
  const serviceCall = () => {
    dispatch(
      paginationrequestServices(filters, pagination, callBackPagination)
    )
  }

  useEffect(() => {
    dispatch(getRolePermissions(Login_Details));
    dispatch(
      paginationrequestServices(filters, pagination, callBackPagination)
    )
  }, []);

  // Sorting
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Request_Id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPagination({
      PageNo: 1,
      PageSize: pagination?.PageSize,
      SortBy: property,
      SortOrder: order === 'asc' ? "ASC" : "DESC"
    })
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/newservices-maincentre"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
        <Typography variant="h2" gutterBottom>
             Service Request Management
          </Typography>
        </Box>
        <Toolbar
          RolePermissions={RolePermissions}
          roleUserType={roleUserType}
          filters={filters}
          setfilters={setFilters}
        />
        <Box mt={3}>
          <Results
            allnewService={services}
            allnewServicesNoFilter={services}
            handleLimitChange={handleLimitChange}
            handlePageChange={handlePageChange}
            order={order}
            orderBy={orderBy}
            // state={state}
            createSortHandler={createSortHandler}
            RolePermissions={RolePermissions}
            roleUserType={roleUserType}
            pagination={pagination}
            setPagination={setPagination}
            // Delete={handledelete}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default NewServicerequest;
