import { v4 as uuid } from 'uuid';

export default [
    {
        id: uuid(),
        hide: 0,
        link: '/app/meepaisa-zone',
        createdAt: '',
        description:
            'meepaisa zone contains all the data related to Products which are available in meepaisa Hubs.',
        media: '/static/Dashboard/catergory.png',
        title: 'Meepaisa Zone',
        total: ''
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/meepaisa-theme',
        createdAt: '',
        description:
            'meepaisa theme contains all the data related to Products which are available in meepaisa Hubs.',
        media: '/static/Dashboard/catergory.png',
        title: 'Meepaisa Theme',
        total: ''
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/meepaisa-zone-theme',
        createdAt: '',
        description:
            'meepaisa zone contains all the data related to Products which are available in meepaisa Hubs.',
        media: '/static/Dashboard/catergory.png',
        title: 'Meepaisa Zone Theme',
        total: ''
    },
    {
        id: uuid(),
        hide: 0,
        link: '/app/meepaisa-zone-theme-products',
        createdAt: '',
        description:
            'meepaisa zone theme product contains all the data related to Products which are available in meepaisa Hubs.',
        media: '/static/Dashboard/catergory.png',
        title: 'Meepaisa Zone Theme Products',
        total: ''
    },
];