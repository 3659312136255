// import { toast } from 'react-toastify';
// import { paginationServicesBanners, paginationServicescategory } from './node-actions/nodepagination';
// import { boomiAdminApi, nodeServiceApi } from './commonAxios';

// export const SERVICE_BANNER_CREATE_REQUEST = "SERVICE_BANNER_CREATE_REQUEST";
// export const SERVICE_BANNER_CREATE_SUCCESS = "SERVICE_BANNER_CREATE_SUCCESS";
// export const SERVICE_BANNER_CREATE_FAIL = "SERVICE_BANNER_CREATE_FAIL";
// export const SERVICE_BANNER_UPDATE_REQUEST = "SERVICE_BANNER_UPDATE_REQUEST";
// export const SERVICE_BANNER_UPDATE_SUCCESS = "SERVICE_BANNER_UPDATE_SUCCESS";
// export const SERVICE_BANNER_UPDATE_FAIL = "SERVICE_BANNER_UPDATE_FAIL ";

// export const SERVICE_BANNER_GET_REQUEST ="SERVICE_BANNER_GET_REQUEST";
// export const SERVICE_BANNER_GET_SUCCESS ="SERVICE_BANNER_GET_SUCCESS";
// export const SERVICE_BANNER_GET_FAIL ="SERVICE_BANNER_GET_FAIL";

// export const SERVICE_BANNER_REQUEST ="SERVICE_BANNER_REQUEST";
// export const SERVICE_BANNER_SUCCESS ="SERVICE_BANNER_SUCCESS";
// export const SERVICE_BANNER_FAIL ="SERVICE_BANNER_FAIL";

// export const SERVICE_CATEGORY_REQUEST ="SERVICE_CATEGORY_REQUEST";
// export const SERVICE_CATEGORY_SUCCESS ="SERVICE_CATEGORY_SUCCESS";
// export const SERVICE_CATEGORY_FAIL ="SERVICE_CATEGORY_FAIL";


// export const SERVICE_SUBCATEGORY_REQUEST ="SERVICE_SUBCATEGORY_REQUEST"
// export const SERVICE_SUBCATEGORY_SUCCESS ="SERVICE_SUBCATEGORY_SUCCESS"
// export const SERVICE_SUBCATEGORY_FAIL ="SERVICE_SUBCATEGORY_FAIL"

// export const SERVICE_VARIENT_REQUEST ="SERVICE_VARIENT_REQUEST"
// export const SERVICE_VARIENT_SUCCESS ="SERVICE_VARIENT_SUCCESS"
// export const SERVICE_VARIENT_FAIL ="SERVICE_VARIENT_FAIL"



// /*=============================================================
//                   Add ServiceCategory Action
// ==============================================================*/
// export const AddBannertypeAction = (formData, navigate) => async dispatch => {
//   console.log(formData)
//   dispatch({
//     type: SERVICE_BANNER_CREATE_REQUEST
//   });
//   nodeServiceApi.post(`/admin/mps_banners/Create`, formData)
//     .then(response => {
//       let successResponse = response.data;
//       if (successResponse.Response_Status === "Success") {
//         dispatch({
//           type: SERVICE_BANNER_CREATE_SUCCESS
//         });
//         toast('Service Category Created Successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
//         navigate('/app/servicebanners-management');
//       } else {
//         dispatch({
//           type: SERVICE_BANNER_CREATE_FAIL,
//           payload: successResponse.UI_Display_Message
//         });
//         toast(successResponse.UI_Display_Message+"please change Service Name and Try Again ", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
//       }
//     })
//     .catch(error => {
//       dispatch({
//         type:SERVICE_BANNER_CREATE_FAIL,
//         payload: "Currently server is not working. Please try again later."
//       });
//     })
// };

// /*=============================================================
//                 Update ServiceCategory Action
// ===============================================================*/
// export const EditBannertypeAction = (formData, navigate) => async dispatch => {
//   dispatch({
//     type: SERVICE_BANNER_UPDATE_REQUEST
//   });
//   nodeServiceApi.put(`/admin/mps_banners/Update/${formData.Banner_Id}`, formData)
//     .then(response => {
//       let successResponse = response.data;
//       if (successResponse.Response_Status === "Success") {
//         dispatch({
//           type: SERVICE_BANNER_UPDATE_SUCCESS
//         });
//         toast('Service Category Updated Successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
//         navigate('/app/servicebanners-management');
//       } else {  
//         dispatch({
//           type: SERVICE_BANNER_UPDATE_FAIL,
//           payload: successResponse.UI_Display_Message
          
//         });
//         toast(successResponse.UI_Display_Message+"please change Service Name and Try Again ", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
//       }
//     })
//     .catch(error => {
//       dispatch({
//         type: SERVICE_BANNER_UPDATE_FAIL,
//         payload: "Currently server is not working. Please try again later."
//       });
//     })
// };


// /*=============================================================
//               Delete  ServiceCategory Action
//  ===============================================================*/


// export const deleteBannertypeAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
//   try {
//     let { data } = await nodeServiceApi.delete(`/admin/mps_banners/Delete/${formData.id}`,);
//     if (data) {
//       console.log(data)
//       toast(' Service Banner deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
//     }
//     dispatch(paginationServicesBanners(filters, pagination, callBackPagination));
//   } 
// catch (err) {
//   console.log(err)
//   }
// };


// /*=============================================================
//            ServiceCategory logo Image Upload Action
// ===============================================================*/
// export const BanneruploadImagepath = (
//   formData,
//   callBackUploadImgae,
// ) => async dispatch => {
//   try {
//     let { data } = await boomiAdminApi.post(
//       `/SA_Upload/Upload?functionality=ServiceCategoryType&fileextension=png&filetype=Images&filename=ServiceCategoryType`,
//       formData
//     );
//     if (data) {
//       callBackUploadImgae(data);
//     }
//   } catch (err) {
//   }
// };


// // export const getservicesbyid = (formData, callBackData) => async dispatch => {
// //   try {
// //       let { data } = await nodeServiceApi.post(`/admin/services/get/`, formData);
// //       console.log(data)
// //       if (data) {
// //         console.log(data)
// //           if (data.Success_Response?.Is_Data_Exist === "0") {
// //             console.log(data.Success_Response)
// //               callBackData([])
// //           } else {
// //               callBackData(data.results)
// //           }
// //       }
// //   } catch (err) {
// //     console.log(err)
// //       dispatch({
// //           payload: err
// //       });
// //   }
// // };

// // export const getservicesbyid = () => async dispatch => {
// //   try {
// //     let formData = {
// //       SearchFor: "All",
// //       Search: "",
// //     };
// //     dispatch({
// //       type: SERVICE_CATEGORY_REQUEST
// //     });
// //     let { data } = await nodeServiceApi.post(`/admin/services/get/`, formData);
    
// //     if (data) {
// //       if (data.Is_Data_Exist === '0') {
// //         dispatch({
// //           type: SERVICE_CATEGORY_SUCCESS,
// //           payload: []
// //         });
// //       } else {
// //         dispatch({
// //           type: SERVICE_CATEGORY_SUCCESS,
// //           payload: data.results
// //         });
// //       }
// //     }
// //   } catch (err) {
// //     dispatch({
// //       type: SERVICE_CATEGORY_FAIL,
// //       payload: err
// //     });
// //   }
// // };
// export const getservicesbyid = () => async (dispatch) => {
//   try {
//     let formData = {
//       SearchFor: "All",
//       Search: "",
//     };
//     dispatch({
//       type: SERVICE_CATEGORY_REQUEST,
//     });
    
//     let { data } = await nodeServiceApi.post(`/admin/services/get/`, formData, {
//       timeout: 5000, // Increase timeout to 5 seconds
//     });
    
//     if (data) {
//       if (data.Is_Data_Exist === '0') {
//         dispatch({
//           type: SERVICE_CATEGORY_SUCCESS,
//           payload: [],
//         });
//       } else {
//         dispatch({
//           type: SERVICE_CATEGORY_SUCCESS,
//           payload: data.results,
//         });
//       }
//     }
//   } catch (err) {
//     console.error("Error fetching services:", err); // Log the error for debugging
//     dispatch({
//       type: SERVICE_CATEGORY_FAIL,
//       payload: err.message || 'Request failed',
//     });
//   }
// };



// export const getsubservicesbyid = () => async dispatch => {
//   try {
//     let formData = {
//       SearchFor: "All",
//       Search: "",
//     };
//     dispatch({
//       type: SERVICE_SUBCATEGORY_REQUEST
//     });
//     let { data } = await nodeServiceApi.post(`/admin/sub_service/Get/`, formData);
//     if (data) {
//       if (data.Is_Data_Exist === '0') {
//         dispatch({
//           type: SERVICE_SUBCATEGORY_SUCCESS,
//           payload: []
//         });
//       } else {
//         dispatch({
//           type: SERVICE_SUBCATEGORY_SUCCESS,
//           payload: data.results
//         });
//       }
//     }
//   } catch (err) {
//     dispatch({
//       type: SERVICE_SUBCATEGORY_FAIL,
//       payload: err
//     });
//   }
// };

// export const getvarientsbyid = () => async dispatch => {
//   try {
//     let formData = {
//       SearchFor: "All",
//       Search: "",
      
//     };
//     dispatch({
//       type: SERVICE_VARIENT_REQUEST
//     });
//     let { data } = await nodeServiceApi.post(`/admin/service_variant/Get/`, formData);
//     if (data) {
//       if (data.Is_Data_Exist === '0') {
//         dispatch({
//           type: SERVICE_VARIENT_SUCCESS,
//           payload: []
//         });
//       } else {
//         dispatch({
//           type: SERVICE_VARIENT_SUCCESS,
//           payload: data.results
//         });
//       }
//     }
//   } catch (err) {
//     dispatch({
//       type: SERVICE_VARIENT_FAIL,
//       payload: err
//     });
//   }
// };



import { toast } from 'react-toastify';
import { paginationServicesBanners } from './node-actions/nodepagination';
import { boomiAdminApi, nodeServiceApi } from './commonAxios';
 
export const SERVICE_BANNER_CREATE_REQUEST = "SERVICE_BANNER_CREATE_REQUEST";
export const SERVICE_BANNER_CREATE_SUCCESS = "SERVICE_BANNER_CREATE_SUCCESS";
export const SERVICE_BANNER_CREATE_FAIL = "SERVICE_BANNER_CREATE_FAIL";
export const SERVICE_BANNER_UPDATE_REQUEST = "SERVICE_BANNER_UPDATE_REQUEST";
export const SERVICE_BANNER_UPDATE_SUCCESS = "SERVICE_BANNER_UPDATE_SUCCESS";
export const SERVICE_BANNER_UPDATE_FAIL = "SERVICE_BANNER_UPDATE_FAIL ";
 
export const SERVICE_BANNER_GET_REQUEST ="SERVICE_BANNER_GET_REQUEST";
export const SERVICE_BANNER_GET_SUCCESS ="SERVICE_BANNER_GET_SUCCESS";
export const SERVICE_BANNER_GET_FAIL ="SERVICE_BANNER_GET_FAIL";
 
export const SERVICE_BANNER_REQUEST ="SERVICE_BANNER_REQUEST";
export const SERVICE_BANNER_SUCCESS ="SERVICE_BANNER_SUCCESS";
export const SERVICE_BANNER_FAIL ="SERVICE_BANNER_FAIL";
 
export const SERVICE_CATEGORY_REQUEST ="SERVICE_CATEGORY_REQUEST";
export const SERVICE_CATEGORY_SUCCESS ="SERVICE_CATEGORY_SUCCESS";
export const SERVICE_CATEGORY_FAIL ="SERVICE_CATEGORY_FAIL";
 
export const SERVICE_SUBCATEGORY_REQUEST ="SERVICE_SUBCATEGORY_REQUEST"
export const SERVICE_SUBCATEGORY_SUCCESS ="SERVICE_SUBCATEGORY_SUCCESS"
export const SERVICE_SUBCATEGORY_FAIL ="SERVICE_SUBCATEGORY_FAIL"
 
export const SERVICE_VARIENT_REQUEST ="SERVICE_VARIENT_REQUEST"
export const SERVICE_VARIENT_SUCCESS ="SERVICE_VARIENT_SUCCESS"
export const SERVICE_VARIENT_FAIL ="SERVICE_VARIENT_FAIL"
 
/*=============================================================
                  Add ServiceCategory Action
==============================================================*/
export const AddBannertypeAction = (formData, navigate) => async dispatch => {
  console.log(formData)
  dispatch({
    type: SERVICE_BANNER_CREATE_REQUEST
  });
  nodeServiceApi.post(`/admin/mps_banners/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICE_BANNER_CREATE_SUCCESS
        });
        toast('Service Category Created Successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/servicebanners-management');
      } else {
        dispatch({
          type: SERVICE_BANNER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
        toast(successResponse.UI_Display_Message+"please change Service Name and Try Again ", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }
    })
    .catch(error => {
      dispatch({
        type:SERVICE_BANNER_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};
 
/*=============================================================
                Update ServiceCategory Action
===============================================================*/
export const EditBannertypeAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: SERVICE_BANNER_UPDATE_REQUEST
  });
  nodeServiceApi.put(`/admin/mps_banners/Update/${formData.Banner_Id}`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICE_BANNER_UPDATE_SUCCESS
        });
        toast('Service Banner Updated Successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/servicebanners-management');
      } else {  
        dispatch({
          type: SERVICE_BANNER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
         
        });
        toast(successResponse.UI_Display_Message+"please change Service Name and Try Again ", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }

      
    })
    .catch(error => {
      dispatch({
        type: SERVICE_BANNER_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
    console.log(formData,'2345432');
};
 
/*=============================================================
              Delete  ServiceCategory Action
 ===============================================================*/
export const deleteBannertypeAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeServiceApi.delete(`/admin/mps_banners/Delete/${formData.Banner_Id}`,);
    if (data) {
      console.log(data,'111111111111111')
      toast(' Service Banner deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    }
    dispatch(paginationServicesBanners(filters, pagination, callBackPagination));
  }
catch (err) {
  console.log(err)
  }
};
 
/*=============================================================
           ServiceCategory logo Image Upload Action
===============================================================*/
export const BanneruploadImagepath = (
  formData,
  callBackUploadImgae,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=ServiceCategoryType&fileextension=png&filetype=Images&filename=ServiceCategoryType`,
      formData
    );
    if (data) {
      console.log(data)
      callBackUploadImgae(data);
    }
  } catch (err) {
    console.error('Image upload failed:', err);
    // console.log(err)
  }
};
 
export const getservicesbyid = () => async (dispatch) => {
  try {
    let formData = {
      SearchFor: "All",
      Search: "",
    };
    dispatch({
      type: SERVICE_CATEGORY_REQUEST,
    });
   
    let { data } = await nodeServiceApi.post(`/admin/services/get/`, formData, {
      timeout: 5000, // Increase timeout to 5 seconds
    });
   
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SERVICE_CATEGORY_SUCCESS,
          payload: [],
        });
      } else {
        dispatch({
          type: SERVICE_CATEGORY_SUCCESS,
          payload: data.results,
        });
      }
    }
  } catch (err) {
    console.error("Error fetching services:", err); // Log the error for debugging
    dispatch({
      type: SERVICE_CATEGORY_FAIL,
      payload: err.message || 'Request failed',
    });
  }
};
 
 
// Action creator for fetching sub-services
export const getsubservicesbyid = () => async dispatch => {
  try {
    const formData = {
      SearchFor: "All",
      Search: "",
    };
 
    // Dispatch request action
    dispatch({
      type: SERVICE_SUBCATEGORY_REQUEST
    });
 
    // Make the API request
    const { data } = await nodeServiceApi.post(`/admin/sub_service/Get/`, formData, {
      // timeout: 5000, // Increase timeout to 5 seconds
    });
 
    // Handle the response
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SERVICE_SUBCATEGORY_SUCCESS,
          payload: [], // Empty array if no data
        });
      } else {
        dispatch({
          type: SERVICE_SUBCATEGORY_SUCCESS,
          payload: data.results, // The actual data
        });
      }
    }
  } catch (err) {
    console.error("Error fetching sub-services:", err); // Log the error for debugging
    dispatch({
      type: SERVICE_SUBCATEGORY_FAIL,
      payload: err.message || 'Request failed',
    });
  }
};
 
 
export const getvarientsbyid = () => async dispatch => {
  try {
    const formData = {
      SearchFor: "All",
      Search: "",
    };
 
    dispatch({ type: SERVICE_VARIENT_REQUEST });
 
    const { data } = await nodeServiceApi.post(`/admin/service_variant/Get/`, formData, {
     
      // timeout: 5000, // Increase timeout to 5 seconds
    });
    // console.log('API Response:', data);
 
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({ type: SERVICE_VARIENT_SUCCESS, payload: [] });
      } else {
        dispatch({ type: SERVICE_VARIENT_SUCCESS, payload: data.results });
      }
    }
  } catch (err) {
    console.error("Error fetching variants:", err); // Log the error for debugging
    dispatch({ type: SERVICE_VARIENT_FAIL, payload: err.message || 'Request failed' });
  }
};