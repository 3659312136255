import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi, boomiAdminApi } from './commonAxios';

export const MYCOMPANY_PROFILE_REQUEST = "MYCOMPANY_PROFILE_REQUEST";
export const MYCOMPANY_PROFILE_SUCCESS = "MYCOMPANY_PROFILE_SUCCESS";
export const MYCOMPANY_PROFILE_FAIL = "MYCOMPANY_PROFILE_FAIL";

export const MYCOMPANY_PROFILE_GET_REQUEST = "MYCOMPANY_PROFILE_GET_REQUEST";
export const MYCOMPANY_PROFILE_GET_SUCCESS = "MYCOMPANY_PROFILE_GET_SUCCESS";
export const MYCOMPANY_PROFILE_GET_FAIL = "MYCOMPANY_PROFILE_GET_FAIL";

export const MYCOMPANY_PROFILE_UPDATE_REQUEST = "MYCOMPANY_PROFILE_UPDATE_REQUEST";
export const MYCOMPANY_PROFILE_UPDATE_SUCCESS = "MYCOMPANY_PROFILE_UPDATE_SUCCESS";
export const MYCOMPANY_PROFILE_UPDATE_FAIL = "MYCOMPANY_PROFILE_UPDATE_FAIL";

/*=============================================================
                  Update MyCompany Profile Action
===============================================================*/
export const updateMyCompanyProfileAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: MYCOMPANY_PROFILE_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Company_Profile/Company_Profile_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: MYCOMPANY_PROFILE_UPDATE_SUCCESS
        });
        toast('Company Profile updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/mycompanyprofile');
      } else {
        dispatch({
          type: MYCOMPANY_PROFILE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: MYCOMPANY_PROFILE_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

//node get
export const getAllMyCompanyProfileAction = (formData) => async dispatch => {
  try {
    dispatch({
      type: MYCOMPANY_PROFILE_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin_Profile/Company_Profile_Get`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: MYCOMPANY_PROFILE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: MYCOMPANY_PROFILE_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: MYCOMPANY_PROFILE_FAIL,
      payload: err
    });
  }
};
/*=============================================================
               Image Upload Action
===============================================================*/

export const uploadMyProfileImage = (
  formData,
  callBackUploadImgae
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=CompanyProfile&fileextension=png&filetype=Images&filename=CompanyProfile`, formData);
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) {
  }
};

//   Get All MyCompany Profile Action
export const getMyCompanyProfileAction = (formData, callBackCompanyProfileData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Super_Admin_Profile/Company_Profile_Get`, formData);
    if (data) {
      callBackCompanyProfileData(data)
    }
  } catch (err) {
  }
};