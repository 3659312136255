
import { toast } from 'react-toastify';
import config from './config';
import { boomiAdminApi } from './commonAxios';


export const RAZORPAY_PAYMENTS_REQUEST = "RAZORPAY_PAYMENTS_REQUEST";
export const RAZORPAY_PAYMENTS_SUCCESS = "RAZORPAY_PAYMENTS_SUCCESS";
export const RAZORPAY_PAYMENTS_FAIL = "RAZORPAY_PAYMENTS_FAIL";
export const RAZORPAY_PAYMENTS_GET_REQUEST = "RAZORPAY_PAYMENTS_GET_REQUEST";
export const RAZORPAY_PAYMENTS_GET_SUCCESS = "RAZORPAY_PAYMENTS_GET_SUCCESS";
export const RAZORPAY_PAYMENTS_GET_FAIL = "RAZORPAY_PAYMENTS_GET_FAIL";
export const RAZORPAY_PAYMENTS_CREATE_REQUEST = "RAZORPAY_PAYMENTS_CREATE_REQUEST";
export const RAZORPAY_PAYMENTS_CREATE_SUCCESS = "RAZORPAY_PAYMENTS_CREATE_SUCCESS";
export const RAZORPAY_PAYMENTS_CREATE_FAIL = "RAZORPAY_PAYMENTS_CREATE_FAIL";
export const RAZORPAY_PAYMENTS_FILTER = "RAZORPAY_PAYMENTS_FILTER";


/*=============================================================
                  Add Razorpay Payments Action
===============================================================*/
export const createRazorpayPaymentsAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: RAZORPAY_PAYMENTS_CREATE_REQUEST
    });
    boomiAdminApi.post(`/Razorpay/Transaction_Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: RAZORPAY_PAYMENTS_CREATE_SUCCESS
                });
                toast('Razorpay Contact added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/razorpaypayments-management');
            } else {
                dispatch({
                    type: RAZORPAY_PAYMENTS_FAIL,
                    payload: successResponse.UI_Display_Message
                });
                toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            }
        })
        .catch(error => {
            dispatch({
                type: RAZORPAY_PAYMENTS_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

/*=============================================================
                  Get All Razorpay Payments Action
===============================================================*/
export const getAllRazorpayPaymentsAction = formData => async dispatch => {
    try {
        dispatch({
            type: RAZORPAY_PAYMENTS_REQUEST
        });
        let { data } = await boomiAdminApi.post(
            `/Razorpay/Contacts_Get`,
            formData
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: RAZORPAY_PAYMENTS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: RAZORPAY_PAYMENTS_SUCCESS,
                    payload: data.Contact
                });
            }
        }
    } catch (err) {
        dispatch({
            type: RAZORPAY_PAYMENTS_FAIL,
            payload: err
        });
    }
};
/*=============================================================
                Razorpay Payments Filter  Action
===============================================================*/
export const RazorpaypaymentsFilter = (fType, value) => {
    return function (dispatch) {
        dispatch({
            type: RAZORPAY_PAYMENTS_FILTER,
            fType: fType,
            value: value,
        });
    }
}