import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableSortLabel,
    TableRow,
    makeStyles,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'src/components/pagination/pagination';
import CommentIcon from '@material-ui/icons/Comment';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    images: {
        width: 100,
        height: 50
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    }
}));

const headCells = [
    { id: 'Partner_Bidding_Quote_Id', numeric: false, disablePadding: true, label: 'Partner Bidding Quote ID' },
    { id: 'User_Products_Bidding_Id', numeric: false, disablePadding: false, label: 'User Products Bidding ID' },
    { id: 'Partner_Name', numeric: false, disablePadding: false, label: 'Partner Name' },
    { id: 'Bidding_Status', numeric: false, disablePadding: false, label: 'Bidding Status' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
    className,
    allPartnerBiddingQuotes,
    order,
    orderBy,
    createSortHandler,
    handleLimitChange,
    handlePageChange,
    allPartnerBiddingQuotesNoFilter,
    pagination,
    setPagination,
    RolePermissions,
    roleUserType,
    state,
    ...rest
}) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [selectedPartnerBiddingQuoteIds, setSelectedPartnerBiddingQuoteIds] = useState([]);

    const viewPartnerBiddingQuotesHandler = partnerbiddingquotesData => e => {
        navigate("/app/partner-bidding-quote-view", { state: { state: state, data: partnerbiddingquotesData } });
    };
    const editPartnerBiddingQuotesHandler = partnerbiddingquotesData => e => {
        navigate('/app/edit-partner-bidding-quote', { state: { state: state, data: partnerbiddingquotesData } });
    };

    const OpenPartnerBiddingCommentsHandler = partnerbiddingquotesData => e => {
        navigate('/app/partner-bidding-comments', { state: partnerbiddingquotesData });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1250}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                    (RolePermissions?.Partner_Bidding_Quotes_Write?.Value === "Partner_Bidding_Quotes_Write" && RolePermissions?.Partner_Bidding_Quotes_Write?.Is_Write === 1) ?
                                    <TableCell >Bidding Comments</TableCell>
                                    : null}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-4rem' }} >Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allPartnerBiddingQuotes) &&
                                (allPartnerBiddingQuotes).map(Partnerbidquote => (
                                    <TableRow
                                        hover
                                        key={Partnerbidquote.Partner_Bidding_Quote_Id}
                                        selected={selectedPartnerBiddingQuoteIds.indexOf(Partnerbidquote.Partner_Bidding_Quote_Id) !== -1}
                                    >
                                        <TableCell>{Partnerbidquote.Partner_Bidding_Quote_Id}</TableCell>
                                        <TableCell>{Partnerbidquote.User_Products_Bidding_Id}</TableCell>
                                        <TableCell>{Partnerbidquote.Partner_Name}</TableCell>
                                        <TableCell>{Partnerbidquote.Bidding_Status}</TableCell>
                                        <TableCell>{Partnerbidquote.Status}</TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Partner_Bidding_Quotes_Write?.Value === "Partner_Bidding_Quotes_Write" && RolePermissions?.Partner_Bidding_Quotes_Write?.Is_Write === 1) ?
                                            <TableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Tooltip title="Bidding Comments">
                                                    <Button>
                                                        <CommentIcon
                                                            onClick={OpenPartnerBiddingCommentsHandler(Partnerbidquote)}
                                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#9EA0A5', fontSize: 20, }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            : null}
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Partner_Bidding_Quotes_Write?.Value === "Partner_Bidding_Quotes_Write" && RolePermissions?.Partner_Bidding_Quotes_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="Partner Bidding Quotes">
                                                    <Button
                                                        onClick={viewPartnerBiddingQuotesHandler(Partnerbidquote)}
                                                        color="primary" >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                                <Button
                                                    onClick={editPartnerBiddingQuotesHandler(Partnerbidquote)}
                                                >
                                                    <CreateIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                            </TableCell>
                                            : null}
                                        {RolePermissions?.Partner_Bidding_Quotes_Read?.Value === "Partner_Bidding_Quotes_Read" && RolePermissions?.Partner_Bidding_Quotes_Read?.Is_Read === 1 &&
                                            <TableCell>
                                                <Tooltip title="Partner Bidding Quotes">
                                                    <Button
                                                        onClick={viewPartnerBiddingQuotesHandler(Partnerbidquote)}
                                                        color="primary" >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allPartnerBiddingQuotes) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <Pagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
};

export default Results;