import config from './config';
import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const BID_RIDE_BRAND_VEHI_CREATE_REQUEST = "BID_RIDE_BRAND_VEHI_CREATE_REQUEST";
export const BID_RIDE_BRAND_VEHI_CREATE_SUCCESS = "BID_RIDE_BRAND_VEHI_CREATE_SUCCESS";
export const BID_RIDE_BRAND_VEHI_CREATE_FAIL = "BID_RIDE_BRAND_VEHI_CREATE_FAIL";

export const BID_RIDE_BRAND_VEHI_UPDATE_REQUEST = "BID_RIDE_BRAND_VEHI_UPDATE_REQUEST";
export const BID_RIDE_BRAND_VEHI_UPDATE_SUCCESS = "BID_RIDE_BRAND_VEHI_UPDATE_SUCCESS";
export const BID_RIDE_BRAND_VEHI_UPDATE_FAIL = "BID_RIDE_BRAND_VEHI_UPDATE_FAIL";

export const BID_RIDE_VEHICLES_GET_REQUEST = "BID_RIDE_VEHICLES_GET_REQUEST";
export const BID_RIDE_VEHICLES_GET_SUCCESS = "BID_RIDE_VEHICLES_GET_SUCCESS";
export const BID_RIDE_VEHICLES_GET_FAIL = "BID_RIDE_VEHICLES_GET_FAIL";

export const BID_RIDE_VEHI_BRANDS_GET_REQUEST = "BID_RIDE_VEHI_BRANDS_GET_REQUEST";
export const BID_RIDE_VEHI_BRANDS_GET_SUCCESS = "BID_RIDE_VEHI_BRANDS_GET_SUCCESS";
export const BID_RIDE_VEHI_BRANDS_GET_FAIL = "BID_RIDE_VEHI_BRANDS_GET_FAIL";

//create Bidding Ride Brand Vehicle Action
export const createBidRideBrandVehicles = (formData, navigate) => async dispatch => {
    dispatch({
        type: BID_RIDE_BRAND_VEHI_CREATE_REQUEST
    });
    nodeCudApi.post(`/Bidding_Ride_Brand_Vehicles/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: BID_RIDE_BRAND_VEHI_CREATE_SUCCESS
                });
                toast('Bidding Ride Brand Vehicle created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/bidding-ride-brand-vehicles');
            } else {
                dispatch({
                    type: BID_RIDE_BRAND_VEHI_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: BID_RIDE_BRAND_VEHI_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

//update Bidding Ride Brand Vehicle Action
export const updateBidRideBrandVehicles = (formData, navigate) => async dispatch => {
    dispatch({
        type: BID_RIDE_BRAND_VEHI_UPDATE_REQUEST
    });
    nodeCudApi.post(`/Bidding_Ride_Brand_Vehicles/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: BID_RIDE_BRAND_VEHI_UPDATE_SUCCESS
                });
                toast('Bidding Ride Brand Vehicle updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/bidding-ride-brand-vehicles');
            } else {

                dispatch({
                    type: BID_RIDE_BRAND_VEHI_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: BID_RIDE_BRAND_VEHI_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//get all Bidding Ride Vehicles
export const getAllBiddingRideVehicles = (formData) => async dispatch => {
    try {
        dispatch({
            type: BID_RIDE_VEHICLES_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Bidding_Vehicle_Details/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Bidding_Vehicle_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: BID_RIDE_VEHICLES_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BID_RIDE_VEHICLES_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BID_RIDE_VEHICLES_GET_FAIL,
            payload: err
        });
    }
};

//get all Bidding Ride Vehicle Brands
export const getAllBidRideVehicleBrands = formData => async dispatch => {
    try {
        dispatch({
            type: BID_RIDE_VEHI_BRANDS_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Bidding_Rides_Vehicle_Brand/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Bidding_Ride_Vehicle_Brand_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: BID_RIDE_VEHI_BRANDS_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BID_RIDE_VEHI_BRANDS_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BID_RIDE_VEHI_BRANDS_GET_FAIL,
            payload: err
        });
    }
};