import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Box,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { updatepartnerWalletAction, getAllPartnerEmails } from 'src/store/actions/partnerwalletAction';
import { CircularProgress } from '@mui/material';

const statusOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const EditPartnerWallet = ({ className, ...rest }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = location;

    // componentDidMount
    useEffect(() => {
        const formData = {
            Records_Filter: 'ALL'
        };
        dispatch(getAllPartnerEmails(formData));
    }, []);

    const isSubmited = useSelector(state => state.partnerwallet.partnerwalletUpdate.isLoading)
    const errorMessage = useSelector(state => state.partnerwallet.partnerwalletUpdate.error);;
    const emails = useSelector(state => state.partnerwallet.partnerEmails.emails);
    const modifiedBy = useSelector(state => state.auth.user.Email_Id);

    return (
        <Formik
            initialValues={{
                Is_Eligible_For_Withdraw: state.Is_Eligible_For_Withdraw > 0 ? true : false,
                Modified_By: modifiedBy,
                Remaining_Balanace: state.Remaining_Balanace ? state.Remaining_Balanace : "",
                Status: state.Status ? state.Status : "",
                Total_Amount: state.Total_Amount ? state.Total_Amount : "",
                Wallet_Id: state.Wallet_Id ? state.Wallet_Id : "",
                Wallet_Owner: state.Wallet_Owner ? state.Wallet_Owner : "",
                Withdraw_Amount: state.Withdraw_Amount ? state.Withdraw_Amount : "",
            }}

            validationSchema={
                Yup.object().shape({
                    Wallet_Owner: Yup.string().required('Partner Name is required.'),
                    Status: Yup.string().required('Status is required.'),
                })
            }
            onSubmit={(values) => {
                let formValues = JSON.parse(JSON.stringify(values));
                if (values.Is_Eligible_For_Withdraw) {
                    formValues.Is_Eligible_For_Withdraw = 1;
                } else {
                    formValues.Is_Eligible_For_Withdraw = 0;
                }
                dispatch(updatepartnerWalletAction(formValues, navigate));
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        {/* Wallet_Owner */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Wallet_Owner && errors.Wallet_Owner)}
                                                fullWidth
                                                helperText={touched.Wallet_Owner && errors.Wallet_Owner}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Name"
                                                name="Wallet_Owner"
                                                value={values.Wallet_Owner}
                                                variant="outlined"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {emails?.map(option => (
                                                    <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                                                        {option.Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        {/* Status */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        {/* Is Eligible For Withdraw */}
                                        <Grid item md={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.Is_Eligible_For_Withdraw}
                                                        onChange={handleChange}
                                                        name="Is_Eligible_For_Withdraw"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Eligible for Withdrawl"
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>

                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditPartnerWallet.propTypes = {
    className: PropTypes.string
};

export default EditPartnerWallet;