import { Card, CardContent, Grid } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const RazorpayhistoryViewList = ({ className, state, data, ...rest }) => {

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            {/* id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data.Id}
                                </Typography>
                            </Grid>

                            {/* entity */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Entity
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data.Entity}
                                </Typography>
                            </Grid>

                            {/* fees */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Fees
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data.Fees}
                                </Typography>
                            </Grid>

                            {/* tax */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Tax
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data.Tax}
                                </Typography>
                            </Grid>

                            {/* internal_status */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Internal Status
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data.Internal_Status}
                                </Typography>
                            </Grid>

                            {/* mode */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Mode
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data.Mode}
                                </Typography>
                            </Grid>

                            {/* created_at */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created At
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data.Created_At}
                                </Typography>
                            </Grid>

                            <Grid item xs={2} />
                            <Grid item xs={2} />
                            <Grid item xs={7} />
                        </Grid>
                    </Grid>

                    {/*  column 2 */}
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            {/* fund_account_id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Fund Account ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data.Fund_Account_Id}
                                </Typography>
                            </Grid>

                            {/* amount */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Amount
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data.Amount}
                                </Typography>
                            </Grid>

                            {/* currency */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Currency
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data.Currency}
                                </Typography>
                            </Grid>

                            {/* Status */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Status
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data.Status}
                                </Typography>
                            </Grid>

                            {/* purpose */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Purpose
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data.Purpose}
                                </Typography>
                            </Grid>

                            {/* Narration */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Narration
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data.Narration}
                                </Typography>
                            </Grid>

                            {/* fee_type */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Fee Type
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data.Fee_Type}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default RazorpayhistoryViewList;