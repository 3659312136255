import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    TextareaAutosize,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress, Rating } from '@mui/material';
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';
import { AddBiddingServiceSucessStoriesAction, getAllPartnerDetails, getAllBiddingServices } from 'src/store/actions/biddingservicesuccessstoriesAction';

const useStyles = makeStyles(() => ({
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    },
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const AddBidServiceSuccessStory = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    //get
    useEffect(() => {
        dispatch(getAllPartnerDetails());
        dispatch(getAllBiddingServices());
    }, []);

    const isSubmitted = useSelector(state => state.bidservicesuccessstory.biddingservicesuccessstoryCreate.isLoading);
    const errorMessage = useSelector(state => state.bidservicesuccessstory.biddingservicesuccessstoryCreate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const partners = useSelector(state => state.bidservicesuccessstory.PhysicalPartners.partners);
    const services = useSelector(state => state.bidservicesuccessstory.BiddingServices.services);
    // Richtext editor
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
    const [rhDiscription, setRhDiscription] = useState('');
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    return (
        <Formik
            initialValues={{
                Bidding_Service_Id: "",
                Created_By: loginEmail,
                Meepaisa_Rating_For_Service: "",
                Meepaisa_Review_For_Service: "",
                Partner_Detail_Id: "",
                Usecase_Service_Description: "",
                Usecase_Service_Short_Description: "",
                Usecase_Service_Video_Url: ""
            }}
            validationSchema={
                Yup.object().shape({
                    Bidding_Service_Id: Yup.string().required('Bidding Service Name is required.'),
                    Partner_Detail_Id: Yup.string().required('Partner Name is required.'),
                    Meepaisa_Review_For_Service: Yup.string().required('Meepaisa Review for Service is required.'),
                    Usecase_Service_Short_Description: Yup.string().required('Usecase Short Description is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                values.Usecase_Service_Description = rhDiscription;

                if (!values.Usecase_Service_Description || values.Usecase_Service_Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
                    submitTest = false;
                    errors.Usecase_Service_Description = "Usecase Description is required.";
                }
                else if (values.Usecase_Service_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
                    submitTest = false;
                    errors.Usecase_Service_Description = "Please provide at least " + rhDiscriptionMin + " characters.";
                }
                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(AddBiddingServiceSucessStoriesAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Service_Id && errors.Bidding_Service_Id)}
                                                fullWidth
                                                helperText={touched.Bidding_Service_Id && errors.Bidding_Service_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Service Name"
                                                name="Bidding_Service_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Service_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">-Please Select-</option>
                                                {services?.sort((a, b) => a.Service_Type?.localeCompare(b.Service_Type))?.map(option => (
                                                    <option key={option.Bidding_service_Id} value={option.Bidding_service_Id}>
                                                        {option.Service_Type}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Detail_Id && errors.Partner_Detail_Id)}
                                                fullWidth
                                                helperText={touched.Partner_Detail_Id && errors.Partner_Detail_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Name"
                                                name="Partner_Detail_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Partner_Detail_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {partners?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                                                    <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                                                        {option.Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            Meepaisa Review for Service
                                            <TextareaAutosize
                                                value={values.Meepaisa_Review_For_Service}
                                                onChange={handleChange}
                                                name="Meepaisa_Review_For_Service"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Meepaisa Review for Service"
                                            >
                                            </TextareaAutosize>
                                            <span style={{ color: "red" }}>
                                                {((touched.Meepaisa_Review_For_Service && errors.Meepaisa_Review_For_Service))}
                                            </span>
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            Usecase Service Short Description
                                            <TextareaAutosize
                                                value={values.Usecase_Service_Short_Description}
                                                onChange={handleChange}
                                                name="Usecase_Service_Short_Description"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Usecase Short Description"
                                            >
                                            </TextareaAutosize>
                                            <span style={{ color: "red" }}>
                                                {((touched.Usecase_Service_Short_Description && errors.Usecase_Service_Short_Description))}
                                            </span>
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <Box className={classes.description}>
                                                Usecase Service Description
                                                <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                                            </Box>
                                            <span style={{ color: "red" }}>
                                                {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Usecase_Service_Description && errors.Usecase_Service_Description}
                                            </span>
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Usecase Service Video URL"
                                                name="Usecase_Service_Video_Url"
                                                value={values.Usecase_Service_Video_Url}
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </Grid>

                                        {/* Meepaise_Rating_For_Partner  */}
                                        <Grid item md={4} xs={4}>
                                            <Box>Meepaisa Rating</Box>
                                        </Grid>
                                        <Grid item md={2} xs={4}>
                                            <Rating
                                                name="Meepaisa_Rating_For_Service"
                                                onChange={(event, newValue) => {
                                                    values.Meepaisa_Rating_For_Service = newValue;
                                                    setFieldValue(newValue);
                                                }}
                                                value={values.Meepaisa_Rating_For_Service}
                                                size="medium"
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddBidServiceSuccessStory.propTypes = {
    className: PropTypes.string
};

export default AddBidServiceSuccessStory;