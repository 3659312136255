import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updatePromotionalAction,
  uploadPromotionalImage,
} from './../../store/actions/promotionalAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { useLocation } from 'react-router-dom';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid black'
  },
  TextareaAutosize: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditPromotional = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  let { state } = location;

  const [errorMessage, setErrorMessage] = useState({ Campaign_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.promotional.promotionalUpdate.isLoading);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor  
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Mail_Description || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Mail_Description);
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // Richtext editor (Specifications)  
  const contentStateSpecifications = ContentState.createFromBlockArray(htmlToDraft(state.Whatsapp_Description || "").contentBlocks);
  const [editorStateSpecifications, setEditorStateSpecifications] = useState(EditorState.createWithContent(contentStateSpecifications));
  const [rhDiscriptionSpecifications, setRhDiscriptionSpecifications] = useState(state.Whatsapp_Description);
  const [rhDiscriptionSpecificationsMin, setRhDiscriptionSpecificationsMin] = useState(20);
  const onEditorStateChangeSpecifications = (editorStateSpecifications) => {
    setEditorStateSpecifications(editorStateSpecifications);
    setRhDiscriptionSpecifications(draftToHtml(convertToRaw(editorStateSpecifications.getCurrentContent())));
  };

  const [image, setImage] = useState({ preview: state.Campaign_Image, raw: state.Campaign_Image });
  const [imgUrl, setImgUrl] = useState(state.Campaign_Image);
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    let data = new FormData();
    data.append('file', new Blob([e.target.files[0]]));
    dispatch(
      uploadPromotionalImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };
  const regex = /(<([^>]+)>)/ig;

  return (
    <Formik
      initialValues={{
        Promotional_Campaign_Id: state.Promotional_Campaign_Id ? state.Promotional_Campaign_Id : "",
        Campaign_Image: imgUrl,
        Campaign_Name: state.Campaign_Name ? state.Campaign_Name : "",
        Campaign_Purpose: state.Campaign_Purpose ? state.Campaign_Purpose : "",
        Mail_Description: state.Mail_Description ? state.Mail_Description : "",
        Sms_Description: state.SMS_Description ? state.SMS_Description : "",
        Whatsapp_Description: state.Whatsapp_Description ? state.Whatsapp_Description : "",
        Campaign_Type: state.Campaign_Type ? state.Campaign_Type : "",
        Mail_Subject: state.Mail_Subject ? state.Mail_Subject : "",
        Status: state.Status ? state.Status : "",
        Modified_By: modifiedBy
      }}
      validationSchema={
        Yup.object().shape({
          Campaign_Name: Yup.string().min(5, "Campaign Name must be at least 5 characters.").notOneOf([errorMessage.Campaign_Name.key, null], errorMessage.Campaign_Name.message).required('Campaign Name is required.'),
          Campaign_Type: Yup.string().max(15, "Campaign Type should be of 15 characters only.").notOneOf([errorMessage.Campaign_Name.key, null], errorMessage.Campaign_Name.message).required('Campaign Type is required.'),
          Sms_Description: Yup.string().min(20, 'SMS Description must be at least 20 characters.').required('SMS Description is required.'),
          Mail_Subject: Yup.string().max(15, "Mail Subject should be of 15 characters only.").notOneOf([errorMessage.Campaign_Name.key, null], errorMessage.Campaign_Name.message).required('Mail Subject is required.'),
          Campaign_Purpose: Yup.string().min(5, "Campaign Purpose must be at least 5 characters.").notOneOf([errorMessage.Campaign_Name.key, null], errorMessage.Campaign_Name.message).required('Campaign Purpose is required.'),
          Status: Yup.string().required('Status is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        let submitTest = true;
        const errors = {};
        values.Mail_Description = rhDiscription;
        values.Campaign_Image = imgUrl;
        values.Whatsapp_Description = rhDiscriptionSpecifications;

        if (!values.Campaign_Image) {
          submitTest = false;
          errors.Campaign_Image = "Image is required.";
        }
        if (!values.Mail_Description || values.Mail_Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Mail_Description = "Mail Description is required.";
        }
        else if (values.Mail_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Mail_Description = "Please provide at least " + rhDiscriptionMin + " characters";
        }
        if (!values.Whatsapp_Description || values.Whatsapp_Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Whatsapp_Description = "WhatsApp Description is required.";
        }
        else if (values.Whatsapp_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionSpecificationsMin) {
          submitTest = false;
          errors.Whatsapp_Description = "Please provide at least " + rhDiscriptionSpecificationsMin + " characters";
        }

        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(updatePromotionalAction(formValues, navigate, setErrorMessage, setErrors));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Campaign_Name && errors.Campaign_Name)}
                        fullWidth
                        helperText={touched.Campaign_Name && errors.Campaign_Name}
                        onBlur={handleBlur}
                        label="Campaign Name"
                        name="Campaign_Name"
                        onChange={handleChange}
                        value={values.Campaign_Name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Campaign_Purpose && errors.Campaign_Purpose)}
                        fullWidth
                        helperText={touched.Campaign_Purpose && errors.Campaign_Purpose}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Campaign Purpose"
                        name="Campaign_Purpose"
                        value={values.Campaign_Purpose}
                        variant="outlined"
                        inputProps={{ maxLength: 30 }}
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Campaign_Type && errors.Campaign_Type)}
                        fullWidth
                        helperText={touched.Campaign_Type && errors.Campaign_Type}
                        onBlur={handleBlur}
                        label="Campaign Type"
                        name="Campaign_Type"
                        onChange={handleChange}
                        value={values.Campaign_Type}
                        inputProps={{ maxLength: 15 }}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Mail_Subject && errors.Mail_Subject)}
                        fullWidth
                        helperText={touched.Mail_Subject && errors.Mail_Subject}
                        onBlur={handleBlur}
                        label="Mail Subject"
                        name="Mail_Subject"
                        onChange={handleChange}
                        value={values.Mail_Subject}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Mail Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Mail_Description && errors.Mail_Description}
                      </span>

                    </Grid>
                    <Grid item md={12} xs={12}>
                      SMS Description
                      <TextareaAutosize
                        className={classes.TextareaAutosize}
                        minRows={10}
                        placeholder="SMS Description"
                        value={values.Sms_Description.replace(regex, '')}
                        onChange={handleChange}
                        name="Sms_Description"
                        onBlur={handleBlur}
                        variant="outlined"
                      >
                      </TextareaAutosize>
                      <span style={{ color: "red" }}>
                        {((touched.Sms_Description && errors.Sms_Description))}
                      </span>
                    </Grid>
                    {/* Specifications  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        WhatsApp Description
                        <EditorContainer editorState={editorStateSpecifications} onEditorStateChange={onEditorStateChangeSpecifications} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscriptionSpecifications || rhDiscriptionSpecifications.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionSpecificationsMin) && touched.Whatsapp_Description && errors.Whatsapp_Description}
                      </span>
                    </Grid>

                    {/* Status */}
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                  </Grid>
                </Grid>
                {/* upload photo */}
                <Grid item md={4} xs={12}>
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Campaign Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Campaign Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                  <span style={{ color: "red" }}>
                    {!imgUrl && touched.Campaign_Image && errors.Campaign_Image}
                  </span>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited || uploadPic}
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#27B6CC' }}
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditPromotional.propTypes = {
  className: PropTypes.string
};

export default EditPromotional;