import {
    GLOBAL_COUPON_REQUEST,
    GLOBAL_COUPON_SUCCESS,
    GLOBAL_COUPON_FAIL,

    GLOBAL_COUPON_CREATE_REQUEST,
    GLOBAL_COUPON_CREATE_SUCCESS,
    GLOBAL_COUPON_CREATE_FAIL,

    GLOBAL_COUPON_UPDATE_REQUEST,
    GLOBAL_COUPON_UPDATE_SUCCESS,
    GLOBAL_COUPON_UPDATE_FAIL,
} from '../actions/globalcouponAction'

const initialState = {
    globalcoupons: { all: [], error: '', isLoading: false },
    globalcouponshistory: { all: [], error: '', isLoading: false },
    globalcouponCreate: { globalcoupon: {}, error: '', isLoading: false },
    globalcouponUpdate: { globalcoupon: {}, error: '', isLoading: false },
};
export default function (state = initialState, action) {
    switch (action.type) {
        case GLOBAL_COUPON_REQUEST:
            return { ...state, globalcoupons: { all: state.globalcoupons.all, error: '', isLoading: true } };
        case GLOBAL_COUPON_SUCCESS:
            return { ...state, globalcoupons: { all: action.payload, error: '', isLoading: false } };
        case GLOBAL_COUPON_FAIL:
            return { ...state, globalcoupons: { all: [], error: action.payload, isLoading: false } };

        case GLOBAL_COUPON_CREATE_REQUEST:
            return { ...state, globalcouponCreate: { globalcoupon: {}, error: '', isLoading: true } };
        case GLOBAL_COUPON_CREATE_SUCCESS:
            return { ...state, globalcouponCreate: { globalcoupon: action.payload, error: '', isLoading: false } };
        case GLOBAL_COUPON_CREATE_FAIL:
            return { ...state, globalcouponCreate: { globalcoupon: {}, error: action.payload, isLoading: false } };

        case GLOBAL_COUPON_UPDATE_REQUEST:
            return { ...state, globalcouponUpdate: { globalcoupon: {}, error: '', isLoading: true } };
        case GLOBAL_COUPON_UPDATE_SUCCESS:
            return { ...state, globalcouponUpdate: { globalcoupon: action.payload, error: '', isLoading: false } };
        case GLOBAL_COUPON_UPDATE_FAIL:
            return { ...state, globalcouponUpdate: { globalcoupon: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}