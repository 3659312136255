import {
  REFERANDEARN_CREATE_REQUEST,
  REFERANDEARN_CREATE_SUCCESS,
  REFERANDEARN_CREATE_FAIL,
  REFERANDEARN_UPDATE_REQUEST,
  REFERANDEARN_UPDATE_SUCCESS,
  REFERANDEARN_UPDATE_FAIL,
  USER_EMAIL_REQUEST,
  USER_EMAIL_SUCCESS,
  USER_EMAIL_FAIL,

} from '../actions/referandearnAction'

const initialState = {
  referandearnCreate: { referandearn: {}, error: '', isLoading: false },
  referandearnsUpdate: { referandearn: {}, error: '', isLoading: false },
  userEmails: { emails: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REFERANDEARN_CREATE_REQUEST:
      return { ...state, referandearnCreate: { referandearn: {}, error: '', isLoading: true } };
    case REFERANDEARN_CREATE_SUCCESS:
      return { ...state, referandearnCreate: { referandearn: state.referandearnCreate.referandearn, error: '', isLoading: false } };
    case REFERANDEARN_CREATE_FAIL:
      return { ...state, referandearnCreate: { referandearn: {}, error: action.payload, isLoading: false } };
    case REFERANDEARN_UPDATE_REQUEST:
      return { ...state, referandearnUpdate: { referandearn: {}, error: '', isLoading: true } };
    case REFERANDEARN_UPDATE_SUCCESS:
      return { ...state, referandearnUpdate: { referandearn: state.referandearnUpdate.referandearn, error: '', isLoading: false } };
    case REFERANDEARN_UPDATE_FAIL:
      return { ...state, referandearnUpdate: { referandearn: {}, error: action.payload, isLoading: false } };

    case USER_EMAIL_REQUEST:
      return { ...state, userEmails: { emails: state.userEmails.emails, error: '', isLoading: true }, };
    case USER_EMAIL_SUCCESS:
      return { ...state, userEmails: { emails: action.payload, error: '', isLoading: false } };
    case USER_EMAIL_FAIL:
      return { ...state, userEmails: { emails: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}