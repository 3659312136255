import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from 'src/components/dialogs/confirmDialog';
import { updateDealApprovalAction } from 'src/store/actions/dealAction';
import { paginationPendingDeals } from 'src/store/actions/node-actions/nodepagination';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';

// useStyles we use a hook to consume the styles
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const DealsApproval = () => {
    // useDispatch hook is used to dispatch an action
    const dispatch = useDispatch();
    // classes contains the styles
    const classes = useStyles();

    //permissions
    const RolePermissions = useSelector(state => state.rolepermission.Roles);
    const roleUserType = useSelector(state => state.auth.user);
    const Login_Details = useSelector(state => state.auth.user);

    const [dealapprovals, setDealapprovals] = useState([]);
    const [filters, setFilters] = React.useState({
        search: '',
        search_by_filter: 'All',
        Records_Filter: "FILTER",
        Is_Approved: "0"
    });

    // createdBy contains the logged in user email id
    const createdBy = useSelector(state => state.auth.user.Email_Id);

    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = useState({ PageNo: 1, PageSize: 10, SortBy: "Deal_Id", SortOrder: "ASC" })

    const handleLimitChange = event => {
        setPagination({ ...pagination, PageSize: +event.target.value, PageNo: 1 });
    };

    const handlePageChange = (event, newPage) => {
        setPagination({ ...pagination, PageSize: pagination.PageSize, PageNo: newPage + 1 });
    };

    const callBackPagination = async (status, data, pagination) => {
        if (status) {
            setDealapprovals(data);
        }
        setPageOld(true);
        if (pagination) {
            setPagination(pagination);
        } else {
            setPagination({ PageNo: 1, PageSize: 10, SortBy: "Deal_Id", SortOrder: "ASC" });
        }
    }

    useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            dealapprovalsCall();
        }
    }, [pagination])

    const [testFilters, setTestFilters] = useState({});
    useEffect(() => {
        if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
            setTestFilters(filters);
            searchFunction();
        }
    }, [filters]);
    const searchFunction = () => {
        const searchpagination = {
            PageNo: 1, PageSize: 10, SortBy: "Deal_Id", SortOrder: "ASC"
        }
        dispatch(paginationPendingDeals(filters, searchpagination, callBackPagination))
    }
    const dealapprovalsCall = () => {
        dispatch(paginationPendingDeals(filters, pagination, callBackPagination))
    };

    // confirm dialog box
    const [dealappopen, setDealappopen] = useState(false);
    const [dealapptitle, setDealapptitle] = useState('');
    const [dealappmessage, setDealappmessage] = useState('');
    const [dealappIdle, setDealAppIdle] = useState('');

    // dealapphandleClose contains update popup action
    const dealapphandleClose = (value) => {
        setDealappopen(false);

        if (value) {
            // alert("testing of alert");
            const approveData = {
                Deal_Id: dealappIdle.Deal_Id,
                Brand_Id: dealappIdle.Brand_Id,
                Category_Id: dealappIdle.Category_Id,
                Category_Name: dealappIdle.Category_Name,
                Deal_Description: dealappIdle.Deal_Description,
                Discount: dealappIdle.Discount,
                Discount_Type: dealappIdle.Discount_Type ? dealappIdle.Discount_Type : "",
                End_Date: dealappIdle.End_Date,
                Esi_Cashback_Amount: dealappIdle.Esi_Cashback_Amount,
                Esi_Cashback_Type: dealappIdle.Esi_Cashback_Type ? dealappIdle.Esi_Cashback_Type : "",
                Exclusive: dealappIdle.Exclusive,
                Extra_Cashback: dealappIdle.Extra_Cashback,
                Image: dealappIdle.Image,
                Is_Top_Deal: dealappIdle.Is_Top_Deal,
                Mobile_Redirect_Url: dealappIdle.Mobile_Redirect_Url,
                Modified_By: createdBy,
                Partner_Details_Id: dealappIdle.Partner_Details_Id,
                Start_Date: dealappIdle.Start_Date,
                Title: dealappIdle.Title,
                Type: dealappIdle.Type,
                Verified_Deal: dealappIdle.Verified_Deal,
                Web_Redirect_Url: dealappIdle.Web_Redirect_Url,
                Whats_Hot: dealappIdle.Whats_Hot,
                Cashback_Label: dealappIdle.Cashback_Label,
                Discount_Label: dealappIdle.Discount_Label,
                //Static data
                Status: "Active",
                Is_Approved: "1"
            };

            //approvedealData used for filter action after update
            const approvedealData = {
                Records_Filter: 'FILTER',
                Is_Approved: "1"
            }
            dispatch(updateDealApprovalAction(approveData, approvedealData, filters, pagination, callBackPagination));
        }
    };

    //Approve handler
    const updateDealApprovalHandler = approveData => async e => {
        setDealappopen(true);
        setDealapptitle("Alert!");
        setDealappmessage("Are you sure to Accept?");
        setDealAppIdle(approveData);
    };
    useEffect(() => {
        dispatch(getRolePermissions(Login_Details));
        dispatch(paginationPendingDeals(filters, pagination, callBackPagination))
    }, []);

    // Sorting 
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Deal_Id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPagination({ PageNo: 1, PageSize: pagination?.PageSize, SortBy: property, SortOrder: order === 'asc' ? "ASC" : "DESC" })
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/deal-management"
                    variant="text"
                    size="small"
                    color="inherit"
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        Pending Deals
                    </Typography>
                </Box>
                <Toolbar filters={filters} setfilters={setFilters} />
                <Box mt={3}>
                    <Results
                        allDealsApproval={dealapprovals}
                        allDealsApprovalNoFilter={dealapprovals}
                        updateDealApprovalHandler={updateDealApprovalHandler}
                        handleLimitChange={handleLimitChange}
                        handlePageChange={handlePageChange}
                        order={order}
                        orderBy={orderBy}
                        createSortHandler={createSortHandler}
                        pagination={pagination}
                        setPagination={setPagination}
                        RolePermissions={RolePermissions}
                        roleUserType={roleUserType}
                    />
                </Box>
            </Container>
            <ConfirmDialog inputMessage={dealappmessage} titleMsg={dealapptitle} open={dealappopen} onClose={dealapphandleClose} />
        </Page>
    );
};

export default DealsApproval;