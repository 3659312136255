import axios from 'axios';
import config from './config';
import { toast } from 'react-toastify';

export const SUBSCRIPTION_USER_PRODUCT_REQUEST = "SUBSCRIPTION_USER_PRODUCT_REQUEST";
export const SUBSCRIPTION_USER_PRODUCT_SUCCESS = "SUBSCRIPTION_USER_PRODUCT_SUCCESS";
export const SUBSCRIPTION_USER_PRODUCT_FAIL = "SUBSCRIPTION_USER_PRODUCT_FAIL";
export const SUBSCRIPTION_USER_PRODUCT_GET_REQUEST = "SUBSCRIPTION_USER_PRODUCT_GET_REQUEST";
export const SUBSCRIPTION_USER_PRODUCT_GET_SUCCESS = "SUBSCRIPTION_USER_PRODUCT_GET_SUCCESS";
export const SUBSCRIPTION_USER_PRODUCT_GET_FAIL = "SUBSCRIPTION_USER_PRODUCT_GET_FAIL";

export const SUBSCRIPTION_USER_PRODUCT_CREATE_REQUEST = "SUBSCRIPTION_USER_PRODUCT_CREATE_REQUEST";
export const SUBSCRIPTION_USER_PRODUCT_CREATE_SUCCESS = "SUBSCRIPTION_USER_PRODUCT_CREATE_SUCCESS";
export const SUBSCRIPTION_USER_PRODUCT_CREATE_FAIL = "SUBSCRIPTION_USER_PRODUCT_CREATE_FAIL";

export const SUBSCRIPTION_USER_PRODUCT_UPDATE_REQUEST = "SUBSCRIPTION_USER_PRODUCT_UPDATE_REQUEST";
export const SUBSCRIPTION_USER_PRODUCT_UPDATE_SUCCESS = "SUBSCRIPTION_USER_PRODUCT_UPDATE_SUCCESS";
export const SUBSCRIPTION_USER_PRODUCT_UPDATE_FAIL = "SUBSCRIPTION_USER_PRODUCT_UPDATE_FAIL";

export const SUBSCRIPTION_USER_PRODUCT_FILTER = "SUBSCRIPTION_USER_PRODUCT_FILTER";

export const SUBSCRIPTION_PARTNERS_REQUEST = "SUBSCRIPTION_PARTNERS_REQUEST";
export const SUBSCRIPTION_PARTNERS_SUCCESS = "SUBSCRIPTION_PARTNERS_SUCCESS";
export const SUBSCRIPTION_PARTNERS_FAIL = "SUBSCRIPTION_PARTNERS_FAIL";

export const SUBSCRIPTION_PRODUCTS_REQUEST = "SUBSCRIPTION_PRODUCTS_REQUEST";
export const SUBSCRIPTION_PRODUCTS_SUCCESS = "SUBSCRIPTION_PRODUCTS_SUCCESS";
export const SUBSCRIPTION_PRODUCTS_FAIL = "SUBSCRIPTION_PRODUCTS_FAIL";

export const SUBSCRIPTION_PARTNER_PRODUCTS_REQUEST = "SUBSCRIPTION_PARTNER_PRODUCTS_REQUEST";
export const SUBSCRIPTION_PARTNER_PRODUCTS_SUCCESS = "SUBSCRIPTION_PARTNER_PRODUCTS_SUCCESS";
export const SUBSCRIPTION_PARTNER_PRODUCTS_FAIL = "SUBSCRIPTION_PARTNER_PRODUCTS_FAIL";

const headers = config.headersCommon;
const nodeheaders = config.nodeheadersCommon;
/*=============================================================
                  Update Subscription User Product Action
===============================================================*/

export const UpdateSubscriptionUserProductAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: SUBSCRIPTION_USER_PRODUCT_UPDATE_REQUEST
  });
  axios.put(`${config.url}/Super_Admin_Subscription_User_Product/Super_Admin_Subscription_User_Product_Update`, formData, {
    headers: headers
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SUBSCRIPTION_USER_PRODUCT_UPDATE_SUCCESS
        });
        toast('User Subscription updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/subscription-user-management');
      } else {

        dispatch({
          type: SUBSCRIPTION_USER_PRODUCT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: SUBSCRIPTION_USER_PRODUCT_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};


/*=============================================================
                  Get All Subscription User Product Action
===============================================================*/

export const getAllSubscriptionUserProductAction = formData => async dispatch => {
  try {
    dispatch({
      type: SUBSCRIPTION_USER_PRODUCT_REQUEST
    });
    let { data } = await axios.post(
      `${config.url}/Super_Admin_Subscription_User_Product/Super_Admin_Subscription_User_Product_Get?PageNo=0&PageSize=${config.pageSize}&SortBy=USER_SERVICE_REQUEST_ID&SortOrder=ASC`,
      formData,
      {
        headers: headers
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SUBSCRIPTION_USER_PRODUCT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({

          type: SUBSCRIPTION_USER_PRODUCT_SUCCESS,
          payload: data.Entry1

        });
      }
    }
  } catch (err) {
    dispatch({
      type: SUBSCRIPTION_USER_PRODUCT_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  Add Subscription User Product Action
===============================================================*/

export const AddSubscriptionUserProductAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: SUBSCRIPTION_USER_PRODUCT_CREATE_REQUEST
  });
  axios.post(`${config.url}/Super_Admin_Subscription_User_Product/Super_Admin_Subscription_User_Product_Create`, formData, {
    headers: headers
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SUBSCRIPTION_USER_PRODUCT_CREATE_SUCCESS
        });
        toast('User Subscription added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/subscription-user-management');
      } else {
        dispatch({
          type: SUBSCRIPTION_USER_PRODUCT_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: SUBSCRIPTION_USER_PRODUCT_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

export const getAllUserAddress = (formData, callBacksubtypeData) => async dispatch => {
  try {
    let { data } = await axios.post(
      `${config.nodeurl}/User/Address_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=USER_EMAIL_ID&SortOrder=ASC`,
      formData,
      {
        // headers: headers
        headers: nodeheaders
      }
    );
    if (data) {
      callBacksubtypeData(data.results)
    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};
/*=============================================================
                 Subscription User Filter Action
===============================================================*/

export const SubscriptionUserProductFilter = (fType, value) => {
  return function (dispatch) {
    dispatch({
      type: SUBSCRIPTION_USER_PRODUCT_FILTER,
      fType: fType,
      value: value,
    });
  }
}
/*=============================================================
                 Get all details
===============================================================*/

export const getAllPartnerDetails = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: 'FILTER',
      Status: "Active"
    };
    dispatch({
      type: SUBSCRIPTION_PARTNERS_REQUEST
    });
    let { data } = await axios.post(
      // `${config.url}/Partners/Physical_Partner_Profile_Get_Admin?PageNo=0&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`,
      `${config.nodeurl}/Partners/Physical_Partner_Profile_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`,
      formData,
      {
        // headers: headers
        headers: nodeheaders
      }
    );
    if (data) {
      // if (data.Is_Data_Exist === '0') {
        if (data.successResponse?.Is_Data_Exist === '0') {
        dispatch({
          type: SUBSCRIPTION_PARTNERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SUBSCRIPTION_PARTNERS_SUCCESS,
          // payload: data.Partner_Details
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SUBSCRIPTION_PARTNERS_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                 Get all details
===============================================================*/

export const getAllProductDetails = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: 'FILTER',
      Status: "Active"
    };
    dispatch({
      type: SUBSCRIPTION_PRODUCTS_REQUEST
    });
    let { data } = await axios.post(
      `${config.nodeurl}/Products/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PRODUCT_ID&SortOrder=ASC`,
      formData,
      {
        // headers: headers
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SUBSCRIPTION_PARTNERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SUBSCRIPTION_PRODUCTS_SUCCESS,
          // payload: data.Product
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SUBSCRIPTION_PRODUCTS_FAIL,
      payload: err
    });
  }
}
/*=============================================================
                  Get All PARTERS Action
===============================================================*/

export const getAllPartnerProductsAction = formData => async dispatch => {
  try {
    let formData = {
      Records_Filter: 'FILTER',
      Status: "Active",
      Product_Id:'productid'
    };
    dispatch({
      type: SUBSCRIPTION_PARTNER_PRODUCTS_REQUEST
    });
    let { data } = await axios.post(
      `${config.url}/Partners/All_Partner_Product_Address_Get_Admin?PageNo=0&PageSize=${config.pageSize}&SortBy=PARTNER_PRODUCT_ID&SortOrder=ASC`,
      formData,
      {
        headers: headers
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
       dispatch({
          type: SUBSCRIPTION_PARTNER_PRODUCTS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SUBSCRIPTION_PARTNER_PRODUCTS_SUCCESS,
          payload: data.Partner_Product
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SUBSCRIPTION_PARTNER_PRODUCTS_FAIL,
      payload: err
    });
  }
};
