import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import {
  getPartnearProductAction,
  createProductAction,
  getAllProductAction
} from '../../../store/actions/sbcmProductAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const AddCityProduct = ({ className, state, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState();
  const isSubmited = useSelector(state => state.sbcmProduct.productCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  const [partnearProducts, setPartnearProducts] = useState([]);
  const responseOfProducts = (response) => {
    setPartnearProducts(response.data);
  };

  const allProducts = useSelector(state => state.sbcmProduct.products.all);
  useEffect(() => {
    const formData = {
      Partner_Details_Id: state.Partner_Details_Id,
      Records_Filter: "FILTER",
    };
    dispatch(getPartnearProductAction(formData, responseOfProducts));
    if (allProducts.length <= 0) {
      const formData1 = {
        Partner_Service_City_Products_Id: state.Partner_Service_City_Products_Id,
        Records_Filter: "FILTER",
      };
      dispatch(getAllProductAction(formData1));
    }
  }, []);

  return (
    <Formik
      initialValues={{
        Product_Id: '',
        Status: '',
        Partner_Service_City_Id: state.Partner_Service_City_Id,
        Partner_Details_Id: state.Partner_Details_Id,
        Partner_Name: state.Partner_Name,
        City_Name: state.City_Name,
        City_Id: state.City_Id,
        Created_By: loginEmail,
      }}

      validationSchema={
        Yup.object().shape({
          Product_Id: Yup.string().required('Product Name is required.'),
          Status: Yup.string().required('Status is required.'),
        })}

      onSubmit={(values, { setErrors, setSubmitting }) => {
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        let formValues = JSON.parse(JSON.stringify(values));
        dispatch(createProductAction(formValues, state, navigate, setErrorMessage));
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} >
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Grid container spacing={3}>
                    {/* Products Field */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Product_Id && errors.Product_Id)}
                        fullWidth
                        helperText={touched.Product_Id && errors.Product_Id}
                        label="Product Name"
                        name="Product_Id"
                        onChange={handleChange}
                        value={values.Product_Id}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {partnearProducts?.filter(item => item.Partner_Details_Id === state.Partner_Details_Id)?.sort((a, b) => a.Product_Name?.localeCompare(b.Product_Name))?.map(option => (
                          <option key={option.Partner_Product_Id} value={option.Product_Id}>
                            {option.Product_Name}
                          </option>
                        ))}

                      </TextField>
                    </Grid>
                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {errorMessage && <Box display="flex" pl={2} >
            <span style={{ color: "red" }}>{errorMessage}</span>
          </Box>
          }

          <Box display="flex" p={2}>
            {isSubmited ?
              <CircularProgress />
              :
              <Button
                disabled={isSubmited}
                type="submit"
                style={{ backgroundColor: '#27B6CC' }}
                variant="contained"
              >
                {/* Save details */}
                <span style={{ color: 'white' }}>Save details</span>
              </Button>
            }
          </Box>
        </form>
      )}
    </Formik>
  );
};

AddCityProduct.propTypes = {
  className: PropTypes.string
};

export default AddCityProduct;