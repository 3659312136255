import {
  PRODUCTSIZE_CREATE_REQUEST,
  PRODUCTSIZE_CREATE_SUCCESS,
  PRODUCTSIZE_CREATE_FAIL,
  PRODUCTSIZE_UPDATE_REQUEST,
  PRODUCTSIZE_UPDATE_SUCCESS,
  PRODUCTSIZE_UPDATE_FAIL,

} from './../actions/productsizeAction'

const initialState = {
  productsizeCreate: { productsize: {}, error: '', isLoading: false },
  productsizeUpdate: { productsize: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PRODUCTSIZE_CREATE_REQUEST:
      return { ...state, productsizeCreate: { productsize: {}, error: '', isLoading: true } };
    case PRODUCTSIZE_CREATE_SUCCESS:
      return { ...state, productsizeCreate: { productsize: state.productsizeCreate.productsize, error: '', isLoading: false } };
    case PRODUCTSIZE_CREATE_FAIL:
      return { ...state, productsizeCreate: { productsize: {}, error: action.payload, isLoading: false } };
    case PRODUCTSIZE_UPDATE_REQUEST:
      return { ...state, productsizeUpdate: { productsize: {}, error: '', isLoading: true } };
    case PRODUCTSIZE_UPDATE_SUCCESS:
      return { ...state, productsizeUpdate: { productsize: state.productsizeUpdate.productsize, error: '', isLoading: false } };
    case PRODUCTSIZE_UPDATE_FAIL:
      return { ...state, productsizeUpdate: { productsize: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}