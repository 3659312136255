import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Box, makeStyles,
    FormControlLabel,
    Checkbox, Avatar
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { addpartnerServiceAction, uploadPartnerServiceImage, getAllPartnerDetails } from 'src/store/actions/partnerserviceAction';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/material.css'
import EditorContainer from '../../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import ESICity from 'src/components/country-state-city/city';
import ESIState from 'src/components/country-state-city/state';
import ESICountry from 'src/components/country-state-city/country';
import { getAllBussinessSubtype } from 'src/store/actions/servicesAction';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles(() => ({
    images: {
        width: '100%',
        height: 100,
        borderRadius: 5,
        marginBottom: 15
    },
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const StatusOptions = [
    {
        value: '',
        label: '-Please Select-'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const AddPartnerServices = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        let data = {
            "Records_Filter": "ALL"
        }
        dispatch(getAllBussinessSubtype(data))
        dispatch(getAllPartnerDetails())
    }, []);

    const [errorMessage, setErrorMessage] = useState({ global: { key: '', message: '' } });

    // img upload
    const [image, setImage] = useState({ preview: '', raw: '' });
    const [imgUrl, setImgUrl] = useState('');
    const [uploadPic, setUploadPic] = useState(false);
    const callBackUploadImgae = data => {
        if (data) {
            setImgUrl(data.File_URL);
        }
        setUploadPic(false);
    };

    const handleImage = e => {
        setUploadPic(true);
        e.preventDefault();
        setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0]
        });
        const data = new FormData();
        data.append('file', e.target.files[0]);
        dispatch(
            uploadPartnerServiceImage(new Blob([e.target.files[0]]), callBackUploadImgae)
        );
    };

    const handleRemoveImage = e => {
        setImgUrl('');
        setImage({
            preview: '',
            raw: ''
        });
    };

    //mobile
    const [mobile, setMobile] = useState();
    const isSubmited = useSelector(state => state.partnerservice.partnerserviceCreate.isLoading);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const partners = useSelector(state => state.partnerservice.PhysicalPartners.partners);
    const BusinessSubtypeServices = useSelector(state => state.service.BussinesssubtypeServices.bussinesssubtypeServices);

    // richtext editor
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
    const [rhDiscription, setRhDiscription] = useState('');
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    return (
        <Formik
            initialValues={{
                City: '',
                Country: '',
                Created_By: loginEmail,
                Expected_Service_Fee: "",
                Is_Avaliable_For_Services: false,
                Is_Custome_Service_Fee: false,
                Is_Home_Service_Avaliable: false,
                Location: "",
                Partner_Detail_Id: "",
                Resource_Email_Id: "",
                Resource_Mobile: "",
                Resource_Name: "",
                Sample_Service_Image_URL: imgUrl,
                Sample_Service_Video_URL: "",
                Service_Description: "",
                Service_Name: "",
                Service_Price_Day: "",
                Service_Price_Per_Hour: "",
                Service_Type: "",
                State: "",
                Status: "",
                Zip: ''
            }}
            validationSchema={
                Yup.object().shape({
                    Service_Type: Yup.string().required('Service Type is required.'),
                    Partner_Detail_Id: Yup.string().required('Partner Name is required.'),
                    Resource_Name: Yup.string().required('Resource Name is required.'),
                    Service_Name: Yup.string().required('Service Name is required.'),
                    Resource_Email_Id: Yup.string().required('Resource Email ID is required.'),
                    State: Yup.string().required('State is required.'),
                    Country: Yup.string().required('Country is required.'),
                    City: Yup.string().required('City is required.'),
                    Location: Yup.string().required('Location is required.'),
                    Zip: Yup.string().required('Pincode is required.'),
                    Status: Yup.string().required('Status is required.'),
                    Expected_Service_Fee: Yup.number().min(0, "Expected Service Fee should not be less than 0.").required('Expected Service Fee is required.'),
                    Service_Price_Per_Hour: Yup.number().min(0, "Service Price Per Hour should not be less than 0.").required('Service Price Per Hour is required.'),
                    Service_Price_Day: Yup.number().min(0, "Service Price Per Day should not be less than 0.").required('Service Price Per Day is required.'),
                    Resource_Mobile: Yup.string().min(14, "Please provide a valid Mobile Number.").required('Mobile Number is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                values.Sample_Service_Image_URL = imgUrl;
                values.Service_Description = rhDiscription;
                if (!values.Sample_Service_Image_URL) {
                    submitTest = false;
                    errors.Sample_Service_Image_URL = "Image is required.";
                }
                if (!values.Service_Description || values.Service_Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
                    submitTest = false;
                    errors.Service_Description = "Description is required.";
                }
                else if (values.Service_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
                    submitTest = false;
                    errors.Service_Description = "Please provide at least " + rhDiscriptionMin + " characters.";
                }
                setErrors(errors);
                if (submitTest) {
                    let formData = JSON.parse(JSON.stringify(values));
                    if (values.Is_Avaliable_For_Services) {
                        formData.Is_Avaliable_For_Services = 1;
                    } else {
                        formData.Is_Avaliable_For_Services = 0;
                    }
                    if (values.Is_Custome_Service_Fee) {
                        formData.Is_Custome_Service_Fee = 1;
                    } else {
                        formData.Is_Custome_Service_Fee = 0;
                    }
                    if (values.Is_Home_Service_Avaliable) {
                        formData.Is_Home_Service_Avaliable = 1;
                    } else {
                        formData.Is_Home_Service_Avaliable = 0;
                    }
                    dispatch(addpartnerServiceAction(formData, navigate, setErrorMessage, setErrors));
                }
            }
            }
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Type && errors.Service_Type)}
                                                fullWidth
                                                helperText={touched.Service_Type && errors.Service_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                select
                                                SelectProps={{ native: true }}
                                                label="Service Type"
                                                name="Service_Type"
                                                value={values.Service_Type}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {BusinessSubtypeServices?.sort((a, b) => a.Business_Sub_Type?.localeCompare(b.Business_Sub_Type))?.map(option => (
                                                    <option key={option.Service_Type} value={option.Service_Type}>
                                                        {option.Business_Sub_Type}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Detail_Id && errors.Partner_Detail_Id)}
                                                fullWidth
                                                helperText={touched.Partner_Detail_Id && errors.Partner_Detail_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Name"
                                                name="Partner_Detail_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Partner_Detail_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {partners?.sort((a, b) => a.Name?.localeCompare(b.Name))?.map(option => (
                                                    <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                                                        {option.Name}
                                                    </option>
                                                ))}
                                            </TextField>

                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Name && errors.Service_Name)}
                                                fullWidth
                                                helperText={touched.Service_Name && errors.Service_Name}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Service Name"
                                                name="Service_Name"
                                                defaultValue={values.Service_Name}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Resource_Name && errors.Resource_Name)}
                                                fullWidth
                                                helperText={touched.Resource_Name && errors.Resource_Name}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Resource Name"
                                                name="Resource_Name"
                                                defaultValue={values.Resource_Name}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Resource_Email_Id && errors.Resource_Email_Id)}
                                                fullWidth
                                                helperText={touched.Resource_Email_Id && errors.Resource_Email_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Resource Email ID"
                                                name="Resource_Email_Id"
                                                defaultValue={values.Resource_Email_Id}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <PhoneInput
                                                containerClass={touched.Resource_Mobile && errors.Resource_Mobile ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                                                inputClass={"form-control MuiOutlinedInput-input"}
                                                error={true}
                                                country={"in"}
                                                fullWidth
                                                inputStyle={{
                                                    width: "100%",
                                                    height: "100%",
                                                    borderRadius: "5px"
                                                }}
                                                inputProps={{
                                                    name: "Resource_Mobile",
                                                }}
                                                specialLabel="Resource Mobile Number"
                                                name="Resource_Mobile"
                                                onBlur={handleBlur}
                                                placeholder="+91 XXXXX XXXXX"
                                                onChange={(value, country, e, formattedValue) => {
                                                    setFieldValue("Resource_Mobile", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                                                    setMobile(formattedValue)
                                                }}
                                                value={mobile}
                                            />
                                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Resource_Mobile && errors.Resource_Mobile}</p>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <ESICountry
                                                error={Boolean(touched.Country && errors.Country)}
                                                fullWidth
                                                helperText={touched.Country && errors.Country}
                                                placeholder="Country"
                                                label="Country"
                                                name="Country"
                                                onChange={handleChange}
                                                select
                                                SelectProps={{ native: true }}
                                                value={values.Country}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        {/* State field */}
                                        <Grid item md={6} xs={12}>
                                            <ESIState
                                                error={Boolean(touched.State && errors.State)}
                                                fullWidth
                                                helperText={touched.State && errors.State}
                                                label="State"
                                                name="State"
                                                onChange={handleChange}
                                                value={values.State}
                                                countrycode={values.Country}
                                                select
                                                SelectProps={{ native: true }}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        {/* City Field */}
                                        <Grid item md={6} xs={12}>
                                            <ESICity
                                                error={Boolean(touched.City && errors.City)}
                                                fullWidth
                                                helperText={touched.City && errors.City}
                                                label="City"
                                                name="City"
                                                onChange={handleChange}
                                                value={values.City}
                                                countrycode={values.Country}
                                                statecode={values.State}
                                                select
                                                SelectProps={{ native: true }}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Location && errors.Location)}
                                                fullWidth
                                                helperText={touched.Location && errors.Location}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Location"
                                                name="Location"
                                                defaultValue={values.Location}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Zip && errors.Zip)}
                                                fullWidth
                                                helperText={touched.Zip && errors.Zip}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Pincode"
                                                name="Zip"
                                                defaultValue={values.Zip}
                                                variant="outlined"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                                                }}
                                                min={0}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Expected_Service_Fee && errors.Expected_Service_Fee)}
                                                fullWidth
                                                helperText={touched.Expected_Service_Fee && errors.Expected_Service_Fee}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Expected Service Fee"
                                                name="Expected_Service_Fee"
                                                defaultValue={values.Expected_Service_Fee}
                                                variant="outlined"
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Price_Day && errors.Service_Price_Day)}
                                                fullWidth
                                                helperText={touched.Service_Price_Day && errors.Service_Price_Day}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Service Price Per Day"
                                                name="Service_Price_Day"
                                                defaultValue={values.Service_Price_Day}
                                                variant="outlined"
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Service_Price_Per_Hour && errors.Service_Price_Per_Hour)}
                                                fullWidth
                                                helperText={touched.Service_Price_Per_Hour && errors.Service_Price_Per_Hour}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Service Price Per Hour"
                                                name="Service_Price_Per_Hour"
                                                defaultValue={values.Service_Price_Per_Hour}
                                                variant="outlined"
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Box className={classes.description}>
                                                Description
                                                <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                                            </Box>
                                            <span style={{ color: "red" }}>
                                                {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Service_Description && errors.Service_Description}
                                            </span>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Sample Service Video URL"
                                                name="Sample_Service_Video_URL"
                                                defaultValue={values.Sample_Service_Video_URL}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {StatusOptions.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onBlur={handleBlur}
                                                            checked={values.Is_Avaliable_For_Services}
                                                            onChange={handleChange}
                                                            name="Is_Avaliable_For_Services"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Is Avaliable for Service"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onBlur={handleBlur}
                                                            checked={values.Is_Custome_Service_Fee}
                                                            onChange={handleChange}
                                                            name="Is_Custome_Service_Fee"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Is Customer Service Fee"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onBlur={handleBlur}
                                                            checked={values.Is_Home_Service_Avaliable}
                                                            onChange={handleChange}
                                                            name="Is_Home_Service_Avaliable"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Is Home Service Available"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Card>
                                        &nbsp;Sample Service Image
                                        <CardContent>
                                            <Box display="flex" justifyContent="center" >
                                                {image.preview ? (
                                                    <Avatar
                                                        className={classes.images}
                                                        alt="logo Upload"
                                                        src={image.preview}
                                                        variant="square"
                                                    />
                                                ) : (
                                                    <>
                                                        <Avatar
                                                            className={classes.images}
                                                            alt="logo Upload"
                                                            src="/static/images/image-placeholder.png"
                                                            variant="square"
                                                        />
                                                    </>
                                                )}
                                            </Box>

                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            >
                                                {image.preview ? <Button
                                                    style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                                                    onClick={handleRemoveImage}
                                                >
                                                    Remove Picture
                                                </Button> : <input
                                                    style={{ display: 'block', width: '80%' }}
                                                    id="upload-photo"
                                                    name="image"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImage}
                                                />}
                                                <br />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                    <span style={{ color: "red" }}>
                                        {!imgUrl && touched.Sample_Service_Image_URL && errors.Sample_Service_Image_URL}
                                    </span>
                                </Grid>
                            </Grid>
                        </CardContent>

                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage.global.message}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited || uploadPic}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddPartnerServices.propTypes = {
    className: PropTypes.string
};

export default AddPartnerServices;