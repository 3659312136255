import {
  GIFTVENDOR_REQUEST,
  GIFTVENDOR_SUCCESS,
  GIFTVENDOR_FAIL,
  GIFTVENDOR_CREATE_REQUEST,
  GIFTVENDOR_CREATE_SUCCESS,
  GIFTVENDOR_CREATE_FAIL,
  GIFTVENDOR_UPDATE_REQUEST,
  GIFTVENDOR_UPDATE_SUCCESS,
  GIFTVENDOR_UPDATE_FAIL,

  GIFT_PARTNERID_REQUEST,
  GIFT_PARTNERID_SUCCESS,
  GIFT_PARTNERID_FAIL,

} from '../actions/giftvendorAction'

const initialState = {
  giftvendors: { all: [], error: '', isLoading: false },
  giftvendor: { giftvendor: {}, error: '', isLoading: false },
  giftvendorCreate: { giftvendor: {}, error: '', isLoading: false },
  giftvendorUpdate: { giftvendor: {}, error: '', isLoading: false },
  giftPartnerid: { partnerid: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GIFTVENDOR_REQUEST:
      return { ...state, giftvendors: { all: state.giftvendors.all, error: '', isLoading: true } };
    case GIFTVENDOR_SUCCESS:
      return { ...state, giftvendors: { all: action.payload, error: '', isLoading: false } };
    case GIFTVENDOR_FAIL:
      return { ...state, giftvendors: { all: [], error: action.payload, isLoading: false } };
    case GIFTVENDOR_CREATE_REQUEST:
      return { ...state, giftvendorCreate: { giftvendor: {}, error: '', isLoading: true } };
    case GIFTVENDOR_CREATE_SUCCESS:
      return { ...state, giftvendorCreate: { giftvendor: action.payload, error: '', isLoading: false } };
    case GIFTVENDOR_CREATE_FAIL:
      return { ...state, giftvendorCreate: { giftvendor: {}, error: action.payload, isLoading: false } };
    case GIFTVENDOR_UPDATE_REQUEST:
      return { ...state, giftvendorUpdate: { giftvendor: {}, error: '', isLoading: true } };
    case GIFTVENDOR_UPDATE_SUCCESS:
      return { ...state, giftvendorUpdate: { giftvendor: action.payload, error: '', isLoading: false } };
    case GIFTVENDOR_UPDATE_FAIL:
      return { ...state, giftvendorUpdate: { giftvendor: {}, error: action.payload, isLoading: false } };

    case GIFT_PARTNERID_REQUEST:
      return { ...state, giftPartnerid: { partnerid: state.giftPartnerid.partnerid, error: '', isLoading: true }, };
    case GIFT_PARTNERID_SUCCESS:
      return { ...state, giftPartnerid: { partnerid: action.payload, error: '', isLoading: false } };
    case GIFT_PARTNERID_FAIL:
      return { ...state, giftPartnerid: { partnerid: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}