
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createProductSizeAction,
} from '../../../../store/actions/productsizeAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const messure = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Millimeter(mm)',
    label: 'Millimeter(mm)'
  },
  {
    value: 'Centimeter(cm)',
    label: 'Centimeter(cm)'
  },
  {
    value: 'Inches(Inch)',
    label: 'Inches(Inch)'
  },
  {
    value: 'Foot(ft)',
    label: 'Foot(ft)'
  },
  {
    value: 'Meter(m)',
    label: 'Meter(m)'
  },
  {
    value: 'Kilo gram(Kg)',
    label: 'Kilo gram(Kg)'
  },
  {
    value: 'Gram(gm)',
    label: 'Gram(gm)'
  },
  {
    value: 'Liter(lit)',
    label: 'Liter(lit)'
  },
  {
    value: 'Milli liter(ml)',
    label: 'Milli liter(ml)'
  },
  {
    value: '(0-1)months',
    label: '(0-1)months'
  },
  {
    value: '(0-2)months',
    label: '(0-2)months'
  },
  {
    value: '(0-3)months',
    label: '(0-3)months'
  },
  {
    value: '(0-4)months',
    label: '(0-4)months'
  },
  {
    value: '(0-5)months',
    label: '(0-5)months'
  },
  {
    value: '(0-6)months',
    label: '(0-6)months'
  },
  {
    value: '(0-7)months',
    label: '(0-7)months'
  },
  {
    value: '(0-8)months',
    label: '(0-8)months'
  },
  {
    value: '(0-9)months',
    label: '(0-9)months'
  },
  {
    value: '(0-10)months',
    label: '(0-10)months'
  },
  {
    value: '(0-11)months',
    label: '(0-11)months'
  },
  {
    value: '(0-1)years',
    label: '(0-1)years'
  },
  {
    value: '(0-2)years',
    label: '(0-2)years'
  },
  {
    value: '(0-3)years',
    label: '(0-3)years'
  },
  {
    value: '(0-4)years',
    label: '(0-4)years'
  },
  {
    value: '(0-5)years',
    label: '(0-5)years'
  },
  {
    value: '(0-6)years',
    label: '(0-6)years'
  },
  {
    value: '(0-7)years',
    label: '(0-7)years'
  },
  {
    value: '(0-8)years',
    label: '(0-8)years'
  },
  {
    value: '(0-9)years',
    label: '(0-9)years'
  },
  {
    value: '(0-10)years',
    label: '(0-10)years'
  },
  {
    value: '(0-11)years',
    label: '(0-11)years'
  },
  {
    value: '(0-12)years',
    label: '(0-12)years'
  },
  {
    value: '(0-13)years',
    label: '(0-13)years'
  },
  {
    value: '(0-14)years',
    label: '(0-14)years'
  },
];

const AddProductSize = ({ className, state, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSubmited = useSelector(state => state.productsize.productsizeCreate.isLoading);
  const errorMessage = useSelector(state => state.productsize.productsizeCreate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        Product_Master_Id: state.Product_Master_Id,
        Size_Measurement: '',
        Size: '',
        Created_By: loginEmail,
        Size_Type: '',
        Status: ''
      }}
      validationSchema={
        Yup.object().shape({
          Size_Measurement: Yup.string().required('Size Measurement is required.'),
          Size: Yup.number().min(0, "Size should not be less than 0.").required('Size is required.'),
          Size_Type: Yup.string().required('Size Type is required.'),
          Status: Yup.string().required('Status is required.'),
        })
      }
      onSubmit={(values) => {
        if (values) {
          let formData = JSON.parse(JSON.stringify(values));
          dispatch(createProductSizeAction(formData, state, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    {/* Size_Measurement */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Size_Measurement && errors.Size_Measurement)}
                        fullWidth
                        helperText={touched.Size_Measurement && errors.Size_Measurement}
                        onBlur={handleBlur}
                        label="Size Measurement"
                        name="Size_Measurement"
                        onChange={handleChange}
                        value={values.Size_Measurement}
                        variant="outlined"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                      >
                        {messure.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Size */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Size && errors.Size)}
                        fullWidth
                        helperText={touched.Size && errors.Size}
                        onBlur={handleBlur}
                        label="Size"
                        name="Size"
                        type="number"
                        onChange={handleChange}
                        value={values.Size}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Size_In */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Size_Type && errors.Size_Type)}
                        fullWidth
                        helperText={touched.Size_Type && errors.Size_Type}
                        onBlur={handleBlur}
                        label="Size Type"
                        name="Size_Type"
                        onChange={handleChange}
                        value={values.Size_Type}
                        variant="outlined"
                      />
                    </Grid>
                    {/*  Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" p={2}>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddProductSize.propTypes = {
  className: PropTypes.string
};

export default AddProductSize;