import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletestoretypelinkingAction,
} from '../../store/actions/storetypelinkingActions';
import ConfirmDialog from '../../components/dialogs/confirmDialog';
import { paginationStoreTypeLinking } from 'src/store/actions/node-actions/nodepagination';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const StoreLinkingView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const { state } = location;
  //permissions
  const RolePermissions = useSelector(state => state.rolepermission.Roles);
  const roleUserType = useSelector(state => state.auth.user);
  const Login_Details = useSelector(state => state.auth.user);

  const [storetypelinking, setStoretypelinking] = useState([]);

  const [filters, setFilters] = React.useState({
    search: '',
    search_by_filter: 'All',
    Store_Type_Name: state.Store_Type_Name,
    Records_Filter: "FILTER"

  });
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = useState({ PageNo: 1, PageSize: 10, SortBy: "Store_Type_Linking_Id", SortOrder: "ASC" })

  const handleLimitChange = event => {
    setPagination({ ...pagination, PageSize: +event.target.value, PageNo: 1 });
  };

  const handlePageChange = (event, newPage) => {
    setPagination({ ...pagination, PageSize: pagination.PageSize, PageNo: newPage + 1 });
  };
  const callBackPagination = async (status, data, pagination) => {
    if (status) {
      setStoretypelinking(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({ PageNo: 1, PageSize: 10, SortBy: "Store_Type_Linking_Id", SortOrder: "ASC" });
    }
  }

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      storetypelinkingCall();
    }
  }, [pagination])

  const [testFilters, setTestFilters] = useState({});
  useEffect(() => {
    if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
      setTestFilters(filters);
      searchFunction();
    }
  }, [filters]);

  const searchFunction = () => {
    const searchpagination = {
      PageNo: 1, PageSize: 10, SortBy: "Store_Type_Linking_Id", SortOrder: "ASC"
    }
    dispatch(paginationStoreTypeLinking(filters, searchpagination, callBackPagination))
  }

  const storetypelinkingCall = () => {
    dispatch(paginationStoreTypeLinking(filters, pagination, callBackPagination))
  };

  useEffect(() => {
    dispatch(paginationStoreTypeLinking(filters, pagination, callBackPagination))
    dispatch(getRolePermissions(Login_Details));
  }, []);


  // confirm dailogbox
  const [storetypelink, setStoretypelink] = useState(false);
  const [ttitle, setTtitle] = useState('');
  const [tmessage, setTmessage] = useState('');
  const [storetypelinkIdle, setStoretypelinkIdle] = useState('');

  const chandleClose = (value) => {
    setStoretypelink(false);
    if (value) {

      const deleteData = {
        Store_Type_Linking_Id: storetypelinkIdle?.Store_Type_Linking_Id,
        Modified_By: Login_Details.Email_Id
      };
      dispatch(deletestoretypelinkingAction(deleteData,filters, pagination, callBackPagination));
    }
  };

  //Delete handler
  const deleteStoretypelinkHandler = storetypelinkData => async e => {
    setStoretypelink(true);
    setTtitle("Alert!");
    setTmessage("Are you sure to delete?");
    setStoretypelinkIdle(storetypelinkData);
  };

  // Sorting 
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Store_Type_Linking_Id');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPagination({ PageNo: 1, PageSize: pagination?.PageSize, SortBy: property, SortOrder: order === 'asc' ? "ASC" : "DESC" })
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };


  return (
    <Page className={classes.root} title="Store Type">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/store-type"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Store Type Categories
          </Typography>
        </Box>

        <Toolbar state={state} filters={filters} setfilters={setFilters} RolePermissions={RolePermissions} roleUserType={roleUserType} />
        <Box mt={3}>
          <Results
            allStoretypeslink={storetypelinking}
            deleteStoretypelinkHandler={deleteStoretypelinkHandler}
            allStoretypeslinkNoFilter={storetypelinking}
            pagination={pagination}
            setPagination={setPagination}
            handleLimitChange={handleLimitChange}
            handlePageChange={handlePageChange}
            order={order}
            orderBy={orderBy}
            createSortHandler={createSortHandler}
            state={state}
            RolePermissions={RolePermissions}
            roleUserType={roleUserType}
          />
        </Box>
      </Container>
      <ConfirmDialog inputMessage={tmessage} titleMsg={ttitle} open={storetypelink} onClose={chandleClose} />

    </Page>
  );
};

export default StoreLinkingView;