import {
    SUBSCRIPTION_USER_REQUEST,
    SUBSCRIPTION_USER_SUCCESS,
    SUBSCRIPTION_USER_FAIL,
    SUBSCRIPTION_USER_GET_REQUEST,
    SUBSCRIPTION_USER_GET_SUCCESS,
    SUBSCRIPTION_USER_GET_FAIL,
    SUBSCRIPTION_USER_UPDATE_REQUEST,
    SUBSCRIPTION_USER_UPDATE_SUCCESS,
    SUBSCRIPTION_USER_UPDATE_FAIL,
    SUBSCRIPTION_USER_FILTER,
    SUBSCRIPTION_USER_CREATE_REQUEST,
    SUBSCRIPTION_USER_CREATE_SUCCESS,
    SUBSCRIPTION_USER_CREATE_FAIL,
} from '../actions/subscriptionuserAction';

const initialState = {
    subscriptions: { all: [], error: '', isLoading: false },
    subscription: { subscriptions: {}, error: '', isLoading: false },
    subscriptionUpdate: { subscriptions: {}, error: '', isLoading: false },
    subscriptionCreate: { subscriptions: {}, error: '', isLoading: false },
    subscriptionsFilter: { select: 'All', search: '' },
};


export default function (state = initialState, action) {
    switch (action.type) {
        case SUBSCRIPTION_USER_REQUEST:
            return { ...state, subscriptions: { all: state.subscriptions.all, error: '', isLoading: true } };
        case SUBSCRIPTION_USER_SUCCESS:
            return { ...state, subscriptions: { all: action.payload, error: '', isLoading: false } };
        case SUBSCRIPTION_USER_FAIL:
            return { ...state, subscriptions: { all: [], error: action.payload, isLoading: false } };
        case SUBSCRIPTION_USER_GET_REQUEST:
            return { ...state, subscription: { subscription: {}, error: '', isLoading: true } };
        case SUBSCRIPTION_USER_GET_SUCCESS:
            return { ...state, subscription: { subscription: action.payload, error: '', isLoading: false } };
        case SUBSCRIPTION_USER_GET_FAIL:
            return { ...state, subscription: { subscription: {}, error: action.payload, isLoading: false } };
        case SUBSCRIPTION_USER_CREATE_REQUEST:
            return { ...state, subscriptionCreate: { subscription: {}, error: '', isLoading: true } };
        case SUBSCRIPTION_USER_CREATE_SUCCESS:
            return { ...state, subscriptionCreate: { subscription: state.subscriptionCreate.subscription, error: '', isLoading: false } };
        case SUBSCRIPTION_USER_CREATE_FAIL:
            return { ...state, subscriptionCreate: { subscription: {}, error: action.payload, isLoading: false } };
        case SUBSCRIPTION_USER_UPDATE_REQUEST:
            return { ...state, subscriptionUpdate: { subscription: {}, error: '', isLoading: true } };
        case SUBSCRIPTION_USER_UPDATE_SUCCESS:
            return { ...state, subscriptionUpdate: { subscription: state.subscriptionUpdate.subscription, error: '', isLoading: false } };
        case SUBSCRIPTION_USER_UPDATE_FAIL:
            return { ...state, subscriptionUpdate: { subscription: {}, error: action.payload, isLoading: false } };
        case SUBSCRIPTION_USER_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, subscriptionsFilter: { search: state.subscriptionsFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, subscriptionsFilter: { select: state.subscriptionsFilter.select, search: action.value } };
            }
            return res;
        default:
            return state;
    }
}