import { Card, CardContent, Grid } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const CityLocationView = ({ className, state, data, ...rest }) => {

    return (
        <Card>
            <CardContent>
                <Grid container spacing={10}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            {/* City_ID */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    City ID
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.City_ID}
                                </Typography>
                            </Grid>
                            {/* Name */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Name
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Location_Name}
                                </Typography>
                            </Grid>
                            {/* Created By */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created By
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={data?.Created_By}> {data?.Created_By?.length > 25 ?
                                        <span>{data?.Created_By?.substring(0, 25)}...</span> : data?.Created_By}</span>
                                </Typography>
                            </Grid>
                            
                            {/* Modified By */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified By
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={data?.Updated_By}> {data?.Updated_By?.length > 25 ?
                                        <span>{data?.Updated_By?.substring(0, 25)}...</span> : data?.Updated_By}</span>
                                </Typography>
                            </Grid>

                            {/* Location_Id */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Location ID
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Location_Id}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} />
                            <Grid item xs={7} />
                        </Grid>
                    </Grid>

                    {/*  column 2 */}
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            {/* Created Date */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created Date
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Created_Date}
                                </Typography>
                            </Grid>

                            {/*Modified Date */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified Date
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Updated_Date}
                                </Typography>
                            </Grid>

                            {/* Zipcode */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Zip Code
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Zipcode}
                                </Typography>
                            </Grid>

                            {/* Is_Active  */}
                            <Grid item xs={4}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Is Active
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {data?.Is_Active}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CityLocationView;