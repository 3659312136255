import config from './config';
import { toast } from 'react-toastify';
import { boomiAdminApi, nodeGetApi } from './commonAxios';

export const WALLET_HISTORY_REQUEST = "WALLET_HISTORY_REQUEST";
export const WALLET_HISTORY_SUCCESS = "WALLET_HISTORY_SUCCESS";
export const WALLET_HISTORY_FAIL = "WALLET_HISTORY_FAIL";
export const WALLET_HISTORY_CREATE_REQUEST = "WALLET_HISTORY_CREATE_REQUEST";
export const WALLET_HISTORY_CREATE_SUCCESS = "WALLET_HISTORY_CREATE_SUCCESS";
export const WALLET_HISTORY_CREATE_FAIL = "WALLET_HISTORY_CREATE_FAIL";
export const WALLET_HISTORY_GET_REQUEST = "    WALLET_HISTORY_GET_REQUEST";
export const WALLET_HISTORY_GET_FAIL = " WALLET_HISTORY_GET_FAIL";
export const WALLET_HISTORY_GET_SUCCESS = " WALLET_HISTORY_GET_SUCCESS";

/*=============================================================
                  Add Wallet Action
===============================================================*/
export const createWallethistoryAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: WALLET_HISTORY_CREATE_REQUEST
  });
  boomiAdminApi.post(`/wallet/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: WALLET_HISTORY_CREATE_SUCCESS
        });
        const formValue = {
          "search": "",
          "search_by_filter": "",
          "Records_Filter": "FILTER",
          "Wallet_Id": formData?.Wallet_Id,
        };
        dispatch(getAllWallethistoryAction(formValue))
        toast('Wallet history added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/wallet-history', state = { state });
      } else {
        dispatch({
          type: WALLET_HISTORY_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: WALLET_HISTORY_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
             get All Wallethistory Action
===============================================================*/
export const getAllWallethistoryAction = formData => async dispatch => {
  try {
    dispatch({
      type: WALLET_HISTORY_REQUEST
    });
    let { data } = await nodeGetApi.post(`/User_Wallet/Admin_Wallet_History_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=WALLET_HISTORY_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: WALLET_HISTORY_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: WALLET_HISTORY_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: WALLET_HISTORY_FAIL,
      payload: err
    });
  }
};

// Get WalletHistory Action
export const getWalletHistoryAction = (formData, callBackWalletHistoryData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/User_Wallet/Admin_Wallet_History_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Wallet_History_Id&SortOrder=ASC`, formData);
    if (data) {
      callBackWalletHistoryData(data)
    }
  } catch (err) {
  }
};