import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel, Checkbox
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import isEmpty from '../../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'src/components/pagination/pagination';
import SimpleDialog from '../../../components/dialogs/dialog';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Meepaisa_Theme_Id', numeric: false, disablePadding: true, label: 'Meepaisa Theme ID' },
  { id: 'Meepaisa_Theme_Name', numeric: false, disablePadding: false, label: 'Meepaisa Theme Name' },
  { id: 'Created_By', numeric: false, disablePadding: false, label: 'Created By' },
  { id: 'Is_Active', numeric: false, disablePadding: false, label: 'Is Active' },
  ,
];

const Results = ({
  className,
  allThemes,
  deleteThemeHandler,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allThemesNoFilter,
  pagination,
  setPagination,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedthemesIds, setSelectedThemesIds] = useState([]);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedThemeIds;
    if (event.target.checked) {
      newSelectedThemeIds = allThemes.map(themes => themes.Meepaisa_Theme_Id);
    } else {
      newSelectedThemeIds = [];
    }
    setSelectedThemesIds(newSelectedThemeIds);
  };

  const handleSelectOne = (event, id) => {
    setSelectedThemesIds(selectedthemesIds.filter(record => {
      for (let i = 0; i < allThemesNoFilter.length; i++) {
        if (record === allThemesNoFilter[i].Meepaisa_Theme_Id) {
          return true;
        }
      }
      return false;
    }))
    const selectedIndex = selectedthemesIds.indexOf(id);
    let newSelectedThemeIds = [];
    if (selectedIndex === -1) {
      newSelectedThemeIds = newSelectedThemeIds.concat(selectedthemesIds, id);
    } else if (selectedIndex === 0) {
      newSelectedThemeIds = newSelectedThemeIds.concat(
        selectedthemesIds.slice(1)
      );
    } else if (selectedIndex === selectedthemesIds.length - 1) {
      newSelectedThemeIds = newSelectedThemeIds.concat(
        selectedthemesIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedThemeIds = newSelectedThemeIds.concat(
        selectedthemesIds.slice(0, selectedIndex),
        selectedthemesIds.slice(selectedIndex + 1)
      );
    }
    setSelectedThemesIds(newSelectedThemeIds);
  };

  const deleteMeepaisaThemeHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const EditThemeHandler = themeData => e => {
    navigate('/app/edit-meepaisa-theme', { state: themeData });
  };
  const ViewThemeHandler = themeData => e => {
    navigate('/app/view-meepaisa-theme', { state: themeData });
  };
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedthemesIds.filter(record => {
                      for (let i = 0; i < allThemesNoFilter.length; i++) {
                        if (record === allThemesNoFilter[i].Meepaisa_Theme_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allThemes?.length}
                    color="primary"
                    indeterminate={
                      selectedthemesIds.filter(record => {
                        for (let i = 0; i < allThemesNoFilter.length; i++) {
                          if (record === allThemesNoFilter[i].Meepaisa_Theme_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedthemesIds.filter(record => {
                        for (let i = 0; i < allThemesNoFilter.length; i++) {
                          if (record === allThemesNoFilter[i].Meepaisa_Theme_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allThemes.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-120px' }}>Actions
                  {selectedthemesIds.filter(record => {
                    for (let i = 0; i < allThemesNoFilter.length; i++) {
                      if (record === allThemesNoFilter[i].Meepaisa_Theme_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedthemesIds.filter(record => {
                    for (let i = 0; i < allThemesNoFilter.length; i++) {
                      if (record === allThemesNoFilter[i].Meepaisa_Theme_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteMeepaisaThemeHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allThemes) &&
                (allThemes).map(themes => (
                  <TableRow
                    hover
                    key={themes?.Meepaisa_Theme_Id}
                    selected={
                      selectedthemesIds.indexOf(themes.Meepaisa_Theme_Id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedthemesIds.indexOf(themes?.Meepaisa_Theme_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, themes?.Meepaisa_Theme_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell >{themes?.Meepaisa_Theme_Id}</TableCell>
                    <TableCell >{themes?.Meepaisa_Theme_Name}</TableCell>
                    <TableCell style={{ color: '#1665D8' }}> {themes.Created_By?.substring(0, 18)}...</TableCell>
                    <TableCell >{themes?.Is_Active}</TableCell>

                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Meepaisa_Theme_Management_Write?.Value === "Meepaisa_Theme_Management_Write" && RolePermissions?.Meepaisa_Theme_Management_Write?.Is_Write === 1) ? <TableCell>
                      <Button
                        onClick={EditThemeHandler(themes)}>
                        <CreateIcon style={{ color: '#9EA0A5' }} />
                      </Button>
                      <Button>
                        <DeleteIcon
                          onClick={deleteThemeHandler(themes)}
                          style={{ color: '#9EA0A5' }}
                        />
                      </Button>

                      <Tooltip title="Theme Details">
                        <Button
                          onClick={ViewThemeHandler(themes)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                      : null}
                       {RolePermissions?.Meepaisa_Theme_Management_Read?.Value === "Meepaisa_Theme_Management_Read" && RolePermissions?.Meepaisa_Theme_Management_Read?.Is_Read === 1 &&
                      <TableCell>
                       <Tooltip title="Theme Details">
                        <Button
                          onClick={ViewThemeHandler(themes)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allThemes) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allThemes: PropTypes.array.isRequired
};

export default Results;