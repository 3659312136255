import React from 'react';
import clsx from 'clsx';
import {
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Button
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import { Link as RouterLink } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  formFilterControl: {
    margin: theme.spacing(1),
    minWidth: 200
  }
}));

const Toolbar = ({ className, filters, setfilters, ...rest }) => {
  const classes = useStyles();

  const handleChange = event => {
    setfilters({
      search: filters?.search,
      search_by_filter: event.target.value
    })
  };


  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" flexWrap="wrap" mb={2}>
          <FormControl variant="outlined" className={classes.formFilterControl}>
            <Select
              value={filters.search_by_filter}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}

            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Order_Id">Order ID</MenuItem>
              <MenuItem value="User_Billing_Address_Full.USER_EMAIL_ID">User Email ID</MenuItem>
              {/* <MenuItem value="Is_Approved">Is Approved</MenuItem> */}
              <MenuItem value="Order_Status">Order Status</MenuItem>
              {/* <MenuItem value="Order_Type">Order Type</MenuItem> */}
            </Select>
          </FormControl>
          <Box width={500}>
            <TextField
              onChange={(event) => {
                setfilters({
                  search: event.target.value,
                  search_by_filter: filters.search_by_filter
                })
              }}
              className={classes.formControl}
              value={filters?.search}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search Orders"
              variant="outlined"
            />
          </Box>
          <FormControl variant="outlined" className={classes.formFilterControl}>
  <Select
    value={filters.search_by_filter}
    onChange={handleChange}
    inputProps={{ 'aria-label': 'Without label' }}
  >
    <MenuItem component={RouterLink} to="/app/cancel-orders-list" value="NEW">NEW</MenuItem>
    <MenuItem value="DELIVERED">DELIVERED</MenuItem>
    <MenuItem value="ACCEPTED">ACCEPTED</MenuItem>
    <MenuItem component={RouterLink} to="/app/return-orders-list" value="SHIPPED">SHIPPED</MenuItem>
    <MenuItem value="CANCELLED">CANCELLED</MenuItem>
  </Select>
</FormControl>
        </Box>
      </Box>
    </div>
  );
};

export default Toolbar;