import {
  WALLETS_REQUEST,
  WALLETS_SUCCESS,
  WALLETS_FAIL,
  WALLET_GET_REQUEST,
  WALLET_GET_SUCCESS,
  WALLET_GET_FAIL,
  WALLET_CREATE_REQUEST,
  WALLET_CREATE_SUCCESS,
  WALLET_CREATE_FAIL,
  WALLET_UPDATE_REQUEST,
  WALLET_UPDATE_SUCCESS,
  WALLET_UPDATE_FAIL,
  WALLET_USER_EMAIL_REQUEST,
  WALLET_USER_EMAIL_SUCCESS,
  WALLET_USER_EMAIL_FAIL,
} from '../actions/walletAction'

const initialState = {
  wallets: { all: [], error: '', isLoading: false },
  wallet: { wallet: {}, error: '', isLoading: false },
  walletCreate: { wallet: {}, error: '', isLoading: false },
  walletUpdate: { wallet: {}, error: '', isLoading: false },
  walletDelete: { wallet: {}, error: '', isLoading: false },
  userEmails: { emails: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case WALLETS_REQUEST:
      return { ...state, wallets: { all: state.wallets.all, error: '', isLoading: true } };
    case WALLETS_SUCCESS:
      return { ...state, wallets: { all: action.payload, error: '', isLoading: false } };
    case WALLETS_FAIL:
      return { ...state, wallets: { all: [], error: action.payload, isLoading: false } };
    case WALLET_GET_REQUEST:
      return { ...state, wallet: { wallet: {}, error: '', isLoading: true } };
    case WALLET_GET_SUCCESS:
      return { ...state, wallet: { wallet: action.payload, error: '', isLoading: false } };
    case WALLET_GET_FAIL:
      return { ...state, wallet: { wallet: {}, error: action.payload, isLoading: false } };
    case WALLET_CREATE_REQUEST:
      return { ...state, walletCreate: { wallet: {}, error: '', isLoading: true } };
    case WALLET_CREATE_SUCCESS:
      return { ...state, walletCreate: { wallet: state.walletCreate.wallet, error: '', isLoading: false } };
    case WALLET_CREATE_FAIL:
      return { ...state, walletCreate: { wallet: {}, error: action.payload, isLoading: false } };
    case WALLET_UPDATE_REQUEST:
      return { ...state, walletUpdate: { wallet: {}, error: '', isLoading: true } };
    case WALLET_UPDATE_SUCCESS:
      return { ...state, walletUpdate: { wallet: state.walletUpdate.wallet, error: '', isLoading: false } };
    case WALLET_UPDATE_FAIL:
      return { ...state, walletUpdate: { wallet: {}, error: action.payload, isLoading: false } };

    case WALLET_USER_EMAIL_REQUEST:
      return { ...state, userEmails: { emails: state.userEmails.emails, error: '', isLoading: true }, };
    case WALLET_USER_EMAIL_SUCCESS:
      return { ...state, userEmails: { emails: action.payload, error: '', isLoading: false } };
    case WALLET_USER_EMAIL_FAIL:
      return { ...state, userEmails: { emails: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}