
import config from './config';
import { toast } from 'react-toastify';
import { paginationStoreTypeLinking } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi} from './commonAxios';

export const STORETYPE_LINKING_REQUEST = "STORETYPE_LINKING_REQUEST ";
export const STORETYPE_LINKING_SUCCESS = "STORETYPE_LINKING_SUCCESS";
export const STORETYPE_LINKING_FAIL = "STORETYPE_LINKING_FAIL";
export const STORETYPE_LINKING_GET_REQUEST = "STORETYPE_LINKING_GET_REQUEST ";
export const STORETYPE_LINKING_GET_SUCCESS = "STORETYPE_LINKING_GET_SUCCESS";
export const STORETYPE_LINKING_GET_FAIL = "STORETYPE_LINKING_GET_FAIL";
export const STORETYPE_LINKING_CREATE_REQUEST = "STORETYPE_LINKING_CREATE_REQUEST";
export const STORETYPE_LINKING_CREATE_SUCCESS = "STORETYPE_LINKING_CREATE_SUCCESS";
export const STORETYPE_LINKING_CREATE_FAIL = "STORETYPE_LINKING_CREATE_FAIL";
export const STORETYPE_LINKING_UPDATE_REQUEST = "STORETYPE_LINKING_UPDATE_REQUEST";
export const STORETYPE_LINKING_UPDATE_SUCCESS = "STORETYPE_LINKING_UPDATE_SUCCESS";
export const STORETYPE_LINKING_UPDATE_FAIL = "STORETYPE_LINKING_UPDATE_FAIL ";

export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";


/*=============================================================
                  Add Store type Action
===============================================================*/
export const AddStoretypelinkingAction = (formData, navigate, state, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: STORETYPE_LINKING_CREATE_REQUEST
  });
  setErrorMessage({ Category_ID: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Store_Type_Linking/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: STORETYPE_LINKING_CREATE_SUCCESS
        });
        toast('Store Type Category created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/store-type-linking', state = { state });
      } else {
        const errors = {};
        if (successResponse.Category_ID) {
          errors.Category_ID = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Category_ID: { key: successResponse.Category_ID ? successResponse.Category_ID : '', message: successResponse.Category_ID ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Category_ID) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: STORETYPE_LINKING_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Category_ID: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: STORETYPE_LINKING_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Update Store type Action
===============================================================*/

export const EditStoretypelinkingAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: STORETYPE_LINKING_UPDATE_REQUEST
  });

  nodeCudApi.post(`/Store_Type_Linking/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: STORETYPE_LINKING_UPDATE_SUCCESS
        });
        toast('Store Type Category updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/store-type-linking', state = { state });
      } else {
        dispatch({
          type: STORETYPE_LINKING_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: STORETYPE_LINKING_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Get All Store type Action
 ===============================================================*/
export const getAllStoretypelinkingAction = formData => async dispatch => {
  try {
    dispatch({
      type: STORETYPE_LINKING_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Store_Type_Linking/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=STORE_TYPE_LINKING_ID&SortOrder=ASC `,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: STORETYPE_LINKING_SUCCESS,
          payload: []
        });
      } else {
        dispatch({

          type: STORETYPE_LINKING_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: STORETYPE_LINKING_FAIL,
      payload: err
    });
  }
};

/*=============================================================
              Delete  Delivery Payment Action
 ===============================================================*/
export const deletestoretypelinkingAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Store_Type_Linking/Delete`, formData);
    if (data) {
      toast(' Store Type Category deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationStoreTypeLinking(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};
/*=============================================================
               Get All Categories Action
===============================================================*/
export const getAllCategories = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Status: "Active",
      search_by_filter: "",
      search: "",
    };
    dispatch({
      type: GET_CATEGORIES_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Categories/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=CATEGORY_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: GET_CATEGORIES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: GET_CATEGORIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: GET_CATEGORIES_FAIL,
      payload: err
    });
  }
};