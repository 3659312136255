import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
  Avatar
} from '@material-ui/core';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { updateBiddingProductsCategory, uploadCategoryImage } from 'src/store/actions/biddingproductscategoryAction';

const categoryOptions = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  }
}));

const EditBidProductsCategory = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  let { state } = location;

  const isSubmited = useSelector(state => state.biddingproductscategory.biddingproductscategoryUpdate.isLoading);
  const errorMessage = useSelector(state => state.biddingproductscategory.biddingproductscategoryUpdate.error);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);

  // img upload
  const [image, setImage] = useState({ preview: state.Bidding_Products_Category_Image, raw: state.Bidding_Products_Category_Image });
  const [imgUrl, setImgUrl] = useState(state.Bidding_Products_Category_Image);
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    let data = new FormData();
    data.append('file', new Blob([e.target.files[0]]));
    dispatch(
      uploadCategoryImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  const values = {
    Bidding_Products_Category_Id: state.Bidding_Products_Category_Id ? state.Bidding_Products_Category_Id : "",
    Bidding_Products_Category_Name: state.Bidding_Products_Category_Name ? state.Bidding_Products_Category_Name : "",
    Bidding_Products_Category_Image: imgUrl,
    Modified_By: "",
    Status: state.Status ? state.Status : "",
  }

  const Form_Validation = Yup.object().shape({
    Bidding_Products_Category_Name: Yup.string().required('Product Category Name is required.'),
    Status: Yup.string().required('Status is required.'),
  })

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};
    values.Bidding_Products_Category_Image = imgUrl;

    if (!values.Bidding_Products_Category_Image) {
      submitTest = false;
      errors.Bidding_Products_Category_Image = "Image is required.";
    }

    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      formValues.Modified_By = modifiedBy;
      dispatch(updateBiddingProductsCategory(formValues, navigate, setErrors));
    }
  }

  return (
    <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
      }) => (
        <Form>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={6}>
                  <Grid container spacing={3}>

                    {/* Bidding Products Category Name */}
                    <Grid item md={8} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.Bidding_Products_Category_Name && errors.Bidding_Products_Category_Name)}
                        label="Product Category Name"
                        name="Bidding_Products_Category_Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Bidding_Products_Category_Name}
                        variant="outlined"
                        disabled
                        helperText={<ErrorMessage name="Bidding_Products_Category_Name" />}
                      />
                    </Grid>

                    {/* Status */}
                    <Grid item md={8} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        placeholder="Status"
                        name="Status"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        helperText={<ErrorMessage name="Status" />}
                      >
                        {categoryOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Image upload */}
                <Grid item md={4} xs={12}>
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Category Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>
                      {/* Remove picture */}
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>

                  <span style={{ color: "red" }}>
                    {!imgUrl && touched.Bidding_Products_Category_Image && errors.Bidding_Products_Category_Image}
                  </span>

                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited || uploadPic}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save Details */}
                  <span style={{ color: 'white' }}>Save Details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>

  );
};

export default EditBidProductsCategory;