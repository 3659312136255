import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
  Tooltip,
} from '@material-ui/core';
import isEmpty from '../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Wallet_History_Id', numeric: false, disablePadding: true, label: 'Wallet History ID' },
  { id: 'Wallet_Id', numeric: false, disablePadding: false, label: 'Wallet ID' },
  { id: 'Wallet_Owner_Id', numeric: false, disablePadding: false, label: 'Wallet Owner ID' },
  { id: 'Balance', numeric: false, disablePadding: false, label: 'Balance' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allwallethistorys,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  setPage,
  createSortHandler,
  allwallethistorysNoFilter,
  state,
  RolePermissions,
  roleUserType,
  ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [selectedWallethistoryIds, setSelectedWallethistoryIds] = useState([]);

  const viewDeliWalletHistory = WalletHistoryData => e => {
    navigate("/app/view-wallet-history", { state: { state: state, data: WalletHistoryData } });
  };

  return (
    <Card className={clsx(classes.root, className)}
      {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-3rem' }}>Actions
                </TableCell>
                {selectedWallethistoryIds.filter(record => {
                  for (let i = 0; i < allwallethistorysNoFilter.length; i++) {
                    if (record == allwallethistorysNoFilter[i].Wallet_History_Id) {
                      return true;
                    }
                  }
                  return false;
                }).length > 0 &&
                  <Button disabled={selectedWallethistoryIds.filter(record => {
                    for (let i = 0; i < allwallethistorysNoFilter.length; i++) {
                      if (record == allwallethistorysNoFilter[i].Wallet_History_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0}
                  >
                  </Button>}
              </TableRow>
            </TableHead>
            <TableBody>
              {(allwallethistorys).map(wallethistory => (
                <TableRow
                  hover
                  key={wallethistory.Wallet_History_Id}
                  selected={
                    selectedWallethistoryIds.indexOf(wallethistory.Wallet_History_Id) !== -1
                  }
                >
                  <TableCell> {wallethistory.Wallet_History_Id}</TableCell>
                  <TableCell >{wallethistory.Wallet_Id}</TableCell>
                  <TableCell >{wallethistory.Wallet_Owner_Id} </TableCell>
                  <TableCell >{wallethistory.Balance} </TableCell>
                  <TableCell >{wallethistory.Status} </TableCell>
                  {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                    (RolePermissions?.User_Wallet_History_Write?.Value === "User_Wallet_History_Write" && RolePermissions?.User_Wallet_History_Write?.Is_Write === 1) ?
                    <TableCell>
                      <Tooltip title="Wallet History View">
                        <Button
                          onClick={viewDeliWalletHistory(wallethistory)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                    : null}
                  {RolePermissions?.User_Wallet_History_Read?.Value === "User_Wallet_History_Read" && RolePermissions?.User_Wallet_History_Read?.Is_Read === 1 &&
                    <TableCell>
                      <Tooltip title="Wallet History View">
                        <Button
                          onClick={viewDeliWalletHistory(wallethistory)}
                          color="primary"
                        >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allwallethistorys) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allwallethistorys: PropTypes.array.isRequired
};

export default Results;