import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateUserAction,
} from './../../store/actions/partneruserreviewAction';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import Rating from '@material-ui/lab/Rating';
import * as Yup from 'yup';
import { Formik, ErrorMessage, Form } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '--Select Status--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  root: {},
  images: {
    width: 120,
    height: 120,
    borderRadius: 5,
    marginBottom: 15
  },
  review: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid black'
  }
}));

const EditPartnerReview = ({ className, state, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const errorMessage = useSelector(state => state.partneruserreview.userUpdate.error);
  const isSubmited = useSelector(state => state.partneruserreview.userUpdate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor (ESI Reviews)
  const contentStateReviews = ContentState.createFromBlockArray(htmlToDraft(state.Comments || "").contentBlocks);
  const [editorStateReviews, setEditorStateReviews] = useState(EditorState.createWithContent(contentStateReviews));
  const [rhDiscriptionReviews, setRhDiscriptionReviews] = useState(state.Comments);
  const [rhDiscriptionReviewsMin, setRhDiscriptionReviewsMin] = useState(2);
  const onEditorStateChangeReviews = (editorStateReviews) => {
    setEditorStateReviews(editorStateReviews);
    setRhDiscriptionReviews(draftToHtml(convertToRaw(editorStateReviews.getCurrentContent())));
  };

  return (
    <Formik
      initialValues={{
        Partner_Review_Id: state.Partner_Review_Id ? state.Partner_Review_Id : "",
        Partner_Details_Id: state.Partner_Details_Id ? state.Partner_Details_Id : "",
        Reviewer_Name: state.Reviewer_Name ? state.Reviewer_Name : "",
        Reviewer_Email_Id: state.Reviewer_Email_Id ? state.Reviewer_Email_Id : "",
        Partner_Email_Id: state.Partner_Email_Id ? state.Partner_Email_Id : "",
        Comments: state.Comments ? state.Comments : "",
        Modified_By: loginEmail,
        Rating: parseInt(state.Rating),
        Status: state.Status ? state.Status : "",
      }}
      validationSchema={
        Yup.object().shape({
          Reviewer_Name: Yup.string().min(2, "Name should have atleast 2 characters.").required("Reviewer Name is required."),
          Status: Yup.string().required('Status is required.'),
          Rating: Yup.number().required('Please provide Rating.').nullable(),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        let submitTest = true;
        const errors = {};
        values.Comments = rhDiscriptionReviews;

        if (!values.Comments || values.Comments.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Comments = "Partner Review is required.";
        }
        else if (values.Comments.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionReviewsMin) {
          submitTest = false;
          errors.Comments = "Please provide at least " + rhDiscriptionReviewsMin + " characters.";
        }
        setErrors(errors)
        if (submitTest) {
          let formData = JSON.parse(JSON.stringify(values));
          dispatch(updateUserAction(formData, state, navigate));
        }
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit} >
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    {/* First Name */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Reviewer_Name && errors.Reviewer_Name)}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Reviewer Name"
                        name="Reviewer_Name"
                        value={values.Reviewer_Name}
                        variant="outlined"
                        helperText={touched.Reviewer_Name && errors.Reviewer_Name}
                      />
                    </Grid>

                    {/* Reviewer_Email_Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Reviewer Email ID"
                        name="Reviewer_Email_Id"
                        value={values.Reviewer_Email_Id}
                        variant="outlined"
                        disabled
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Box className={classes.review}>
                        Partner Review
                        <EditorContainer editorState={editorStateReviews} onEditorStateChange={onEditorStateChangeReviews} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscriptionReviews || rhDiscriptionReviews.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionReviewsMin) && touched.Comments && errors.Comments}
                      </span>
                    </Grid>

                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        placeholder="Status"
                        name="Status"
                        onChange={handleChange}
                        label="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        helperText={touched.Status && errors.Status}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={2} xs={12}>
                      <Box>Rating</Box>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <Rating
                        name="Rating"
                        onChange={(event, newValue) => {
                          values.Rating = newValue;
                          setFieldValue(newValue);
                        }}
                        value={values.Rating}
                        size="medium"
                      />
                      <Grid item xs={10} />
                      <span style={{ color: "red" }}>
                        {<ErrorMessage name="Rating" />}
                      </span>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

EditPartnerReview.propTypes = {
  className: PropTypes.string
};

export default EditPartnerReview;