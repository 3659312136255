import React, { useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Button
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Sales from '../Sales';
import { useDispatch, useSelector } from 'react-redux';
import { deliveryorderStatistics } from 'src/store/actions/delivery-reports/deliverystatisticsActions';

//useStyles contains the styles
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TotalOrderReports = () => {
  // classes contains the styles
  const classes = useStyles();
  // useDispatch hook is used to dispatch an action
  const dispatch = useDispatch();
  //data contains the data from API using state call
  const data = useSelector(state => state.deliverydashboard.DeliveryOrderStat.data);

  // componentDidMount
  //useEffect Hook allows you to perform side effects in your components
  //dispatch is used to call the API Action 
  useEffect(() => {
    let formData = {
      "Records_Filter": "ALL",
      "Status": ""
    }
    dispatch(deliveryorderStatistics(formData));
  }, []);

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Button style={{ marginBottom: "10px" }}
          component={RouterLink}
          to="/app/delivery/reports"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go Back
        </Button>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={9}
            xs={12}
          >
            <Sales
              statistics={data}
              title={"Total Orders"}
            />
          </Grid>

        </Grid>
      </Container>
    </Page>
  );
};

export default TotalOrderReports;