
import config from './config';
import { toast } from 'react-toastify';
import { paginationPartnerAddress } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const PARTNERSADDRESS_REQUEST = "PARTNERSADDRESS_REQUEST";
export const PARTNERSADDRESS_SUCCESS = "PARTNERSADDRESS_SUCCESS";
export const PARTNERSADDRESS_FAIL = "PARTNERSADDRESS_FAIL";
export const PARTNERADDRESS_GET_REQUEST = "PARTNERADDRESS_GET_REQUEST";
export const PARTNERADDRESS_GET_SUCCESS = "PARTNERADDRESS_GET_SUCCESS";
export const PARTNERADDRESS_GET_FAIL = "PARTNERADDRESS_GET_FAIL";
export const PARTNERADDRESS_CREATE_REQUEST = "PARTNERADDRESS_CREATE_REQUEST";
export const PARTNERADDRESS_CREATE_SUCCESS = "PARTNERADDRESS_CREATE_SUCCESS";
export const PARTNERADDRESS_CREATE_FAIL = "PARTNERADDRESS_CREATE_FAIL";
export const PARTNERADDRESS_UPDATE_REQUEST = "PARTNERADDRESS_UPDATE_REQUEST";
export const PARTNERADDRESS_UPDATE_SUCCESS = "PARTNERADDRESS_UPDATE_SUCCESS";
export const PARTNERADDRESS_UPDATE_FAIL = "PARTNERADDRESS_UPDATE_FAIL";

/*=============================================================
                 create partner address Action
===============================================================*/

export const createPartneraddressAction = (formData, navigate, state, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: PARTNERADDRESS_CREATE_REQUEST
  });
  setErrorMessage({ global: { key: '', message: '' } });
  nodeCudApi.post(`/Partners/Partner_Address_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNERADDRESS_GET_SUCCESS
        });
        toast('Partner address added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });

        navigate('/app/partneraddress-management', state = { state });
      } else {
        const errors = {};
        setErrors(errors)
        setErrorMessage({ global: { key: '', message: successResponse.UI_Display_Message ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: PARTNERADDRESS_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNERADDRESS_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                Update partner address Action
===============================================================*/
export const updatePartneraddressAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: PARTNERADDRESS_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Partners/Partner_Address_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNERADDRESS_UPDATE_SUCCESS
        });
        toast('Partner address updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partneraddress-management', state = { state });
      } else {

        dispatch({
          type: PARTNERADDRESS_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNERADDRESS_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Get All partner address Action
===============================================================*/
export const getAllPartneraddressAction = (formData) => async dispatch => {
  try {
    dispatch({
      type: PARTNERSADDRESS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partners/Partner_Address_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_ADDRESS_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNERSADDRESS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNERSADDRESS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again.", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    dispatch({
      type: PARTNERSADDRESS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
            Delete Partner address Action
===============================================================*/
export const deletePartneraddressAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Partners/Partner_Address_Delete`, formData
      // Axios looks for the `auth` option, and, if it is set, formats a
      // basic auth header for you automatically
    );
    if (data) {
      toast('Partner address deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationPartnerAddress(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

//Get Partner Address Action
export const getPartnerAddressAction = (formData, callBackPartnerAddressData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Partners/Partner_Address_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_ADDRESS_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBackPartnerAddressData(data)
    }
  } catch (err) {
  }
};