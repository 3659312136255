import {
    SERVICES_PARTNER_GENERIC_CREATE_REQUEST,
    SERVICES_PARTNER_GENERIC_CREATE_SUCCESS,
    SERVICES_PARTNER_GENERIC_CREATE_FAIL,
    SERVICES_PARTNER_GENERIC_UPDATE_REQUEST,
    SERVICES_PARTNER_GENERIC_UPDATE_SUCCESS,
    SERVICES_PARTNER_GENERIC_UPDATE_FAIL,
    SERVICES_PARTNER_GENERIC_FILTER,
    CITIES_REQUEST,
    CITIES_SUCCESS,
    CITIES_FAIL,

} from '../actions/servicepartnercityAction';

const initialState = {
    genericservices: { all: [], error: '', isLoading: false },
    genericservice: { genericservice: {}, error: '', isLoading: false },
    serviceUpdate: { genericservice: {}, error: '', isLoading: false },
    serviceCreate: { genericservice: {}, error: '', isLoading: false },
    partnergenericFilter: { select: 'All', search: '' },
    citypartnercitynames: { citynames: [], error: '', isLoading: false },
	
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SERVICES_PARTNER_GENERIC_CREATE_REQUEST:
            return { ...state, serviceCreate: { genericservice: {}, error: '', isLoading: true } };
        case SERVICES_PARTNER_GENERIC_CREATE_SUCCESS:
            return { ...state, serviceCreate: { genericservice: state.serviceCreate.genericservice, error: '', isLoading: false } };
        case SERVICES_PARTNER_GENERIC_CREATE_FAIL:
            return { ...state, serviceCreate: { genericservice: {}, error: action.payload, isLoading: false } };
        case SERVICES_PARTNER_GENERIC_UPDATE_REQUEST:
            return { ...state, serviceUpdate: { genericservice: {}, error: '', isLoading: true } };
        case SERVICES_PARTNER_GENERIC_UPDATE_SUCCESS:
            return { ...state, serviceUpdate: { genericservice: state.serviceUpdate.genericservice, error: '', isLoading: false } };
        case SERVICES_PARTNER_GENERIC_UPDATE_FAIL:
            return { ...state, serviceUpdate: { genericservice: {}, error: action.payload, isLoading: false } };
            case CITIES_REQUEST:
                return { ...state, citypartnercitynames: { citynames: state.citypartnercitynames.citynames, error: '', isLoading: true }, };
              case CITIES_SUCCESS:
                return { ...state, citypartnercitynames: { citynames: action.payload, error: '', isLoading: false } };
              case CITIES_FAIL:
                return { ...state, citypartnercitynames: { citynames: [], error: action.payload, isLoading: false } };
        case SERVICES_PARTNER_GENERIC_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, partnergenericFilter: { search: state.partnergenericFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, partnergenericFilter: { select: state.partnergenericFilter.select, search: action.value } };
            }
            return res;

        default:
            return state;
    }
}

























