import React from 'react';
import { useRoutes } from 'react-router';
import routes from 'src/routes';
import CryptoJS from 'crypto-js';
import { useDispatch, useSelector } from 'react-redux';
import { authTokenGenerate, loginOutSuccess, USER_SESSION_REFRESH_DATE } from './store/actions/authActions';
import config from './store/actions/config';
import moment from 'moment';

const Auth = () => {
    const routing = useRoutes(routes);

    const isAuthenticated = useSelector(state => state.auth?.isAuthenticated || false);
    const authInterverl = useSelector(state => state.auth?.sessionExpiredTime);
    const sessionExpiredDate = useSelector(state => state.auth?.sessionExpiredDate);

    const [sessionRecal, setSessionRecal] = React.useState(false);
    const dispatch = useDispatch();
    const [sessionLoading, setSessionLoading] = React.useState(true);
    const [sessionExLoading, setSessionExLoading] = React.useState(false);
    const [secondsToReload, setSecondsToReload] = React.useState(-1);
    const [refreshCall, setRefreshCall] = React.useState(false);

    const getToken = () => {
        try {
            const encript = localStorage.getItem('Token')
            const bytesData = CryptoJS.AES.decrypt(encript, config.cryptoKay);
            const token = bytesData.toString(CryptoJS.enc.Utf8);
            return JSON.parse(token)
        } catch (error) {
            return {}
        }

    }

    const setToken = (token) => {
        try {
            let data = JSON.parse(JSON.stringify(token));
            data.expireDate = new Date().setSeconds(new Date().getSeconds() + (data.exp - (5 * 60))).valueOf();
            const encryptedToken = CryptoJS.AES.encrypt(JSON.stringify(data), config.cryptoKay).toString();
            dispatch({
                type: USER_SESSION_REFRESH_DATE,
                expireDate: data.expireDate,
                expireTime: token.exp - (5 * 60)
            })
            localStorage.setItem('Token', encryptedToken)
        } catch (error) {
        }


    }

    const refreshAccessToken = () => {
        try {
            if (!sessionExLoading) {
                const token = getToken();
                if (token?.refreshToken) {
                    setSessionExLoading(true);
                    dispatch(authTokenGenerate({ refreshToken: token.refreshToken }, (data) => {
                        setSessionRecal(false);
                        setSessionExLoading(false);
                        setRefreshCall(false);
                        if (data.Tokens) {
                            setSessionLoading(false);
                            setToken(data.Tokens);
                        }
                        else if (data.message) {
                            setSessionLoading(false);
                            dispatch(loginOutSuccess());
                            localStorage.removeItem('signIn');
                            localStorage.removeItem('Token');
                        }
                        else {
                            setSessionRecal(true);
                        }
                    }));
                }
            }
        } catch (error) {
            setSessionRecal(true)
        }
    };


    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (secondsToReload == 0) {
                setRefreshCall(true)
            } else if (secondsToReload > 0)
                setSecondsToReload(secondsToReload - 1)
        }, 1000);
        return () => clearTimeout(timer);
    }, [secondsToReload]);


    React.useEffect(() => {


        if (isAuthenticated) {
            // refreshAccessToken();
            try {
                const current = moment(new Date());
                const sED = moment(sessionExpiredDate);
                const difDates = sED.diff(current, 'seconds')
                const token = getToken();
                if (token.refreshToken) {
                    if ((difDates <= 0) || sessionRecal) {
                        if (sessionRecal) {
                            setSessionRecal(false)
                        }
                        if (difDates <= 0) {
                            setSessionLoading(true);
                        }
                        refreshAccessToken()
                    } else {
                        setSessionLoading(false);
                        setSecondsToReload((difDates < 0) ? authInterverl : difDates)
                    }
                }
                else {
                    setSessionLoading(false)
                    dispatch(loginOutSuccess());
                    localStorage.removeItem('Token');
                    localStorage.removeItem('signIn');
                }
            } catch (error) {
            }

            // Cleanup the interval when the component unmounts
        } else {
            setSessionLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionExpiredDate, sessionRecal, isAuthenticated]);


    React.useEffect(() => {

        if (refreshCall) {
            refreshAccessToken()
        }
        else
            setRefreshCall(false)
    }, [refreshCall])

    return sessionLoading ? "loading" : routing
}

export default Auth;