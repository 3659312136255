import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { useLocation } from 'react-router-dom';
import { ConnectedFocusError } from "focus-formik-error";
import { updatedeliveryTicketAction, getAlldeliveryprofilesAction } from 'src/store/actions/deliveryticketActions';
import { CircularProgress } from '@mui/material';

const userType = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'DELIVERY',
    label: 'DELIVERY'
  },
];

const statusopt = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'NEW',
    label: 'NEW'
  },
  {
    value: 'IN PROGRESS',
    label: 'IN PROGRESS'
  },
  {
    value: 'HOLD',
    label: 'HOLD'
  },
  {
    value: 'CLOSED',
    label: 'CLOSED'
  },
];

const IssuerelatedOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Order',
    label: 'Order'
  },
  {
    value: 'Payment',
    label: 'Payment'
  },
  {
    value: 'Delivery',
    label: 'Delivery'
  },
  {
    value: 'Product',
    label: 'Product'
  },
  {
    value: 'Others',
    label: 'Others'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: 120,
    height: 120,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditdeliveryTicket = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  let { state } = location;

  // componentDidMount
  useEffect(() => {
    dispatch(getAlldeliveryprofilesAction());
  }, []);

  const errorMessage = useSelector(state => state.deliverytickets.deliveryticketUpdate.error);
  const isSubmited = useSelector(state => state.deliverytickets.deliveryticketUpdate.isLoading);
  const users = useSelector(state => state.deliverytickets.deliveryusers.users);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Description || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Description);

  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(2);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <Formik
      initialValues={{
        Del_Ticket_Id: state.Del_Ticket_Id ? state.Del_Ticket_Id : "",
        Subject: state.Subject ? state.Subject : "",
        Description: state.Description ? state.Description : "",
        Issue_Related_To: state.Issue_Related_To ? state.Issue_Related_To : "",
        User_Type: state.User_Type ? state.User_Type : "",
        Del_User_Id: state.Del_User_Id ? state.Del_User_Id : "",
        Status: state.Status ? state.Status : "",
        Attachment: state.Attachment ? state.Attachment : "",
        Support_Owner: modifiedBy,
        Modified_By: modifiedBy,
      }}
      validationSchema={
        Yup.object().shape({
          Subject: Yup.string().min(2, "Ticket Subject should have atleast 2 characters.").required("Ticket Subject is required."),
          User_Type: Yup.string().required('Ticket User Type  is required.'),
          Status: Yup.string().required('Ticket Status  is required.'),
          Attachment: Yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
            'Please provide valid URL.'
          ).required('Attachment URL is required.'),
          Issue_Related_To: Yup.string().required('Issue Related To is required.'),
          Del_User_Id: Yup.string().required('Ticket Owner is required.'),
          Issue_Related_To: Yup.string().required('Issue Related To is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        let submitTest = true;
        const errors = {};
        values.Description = rhDiscription;

        if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Description = "Please provide at least " + rhDiscriptionMin + " characters";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(updatedeliveryTicketAction(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Del_User_Id && errors.Del_User_Id)}
                        fullWidth
                        helperText={touched.Del_User_Id && errors.Del_User_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ticket Owner"
                        name="Del_User_Id"
                        // select
                        // InputLabelProps={{ shrink: true }}
                        // SelectProps={{ native: true }}
                        value={values.Del_User_Id}
                        variant="outlined"
                        disabled
                      >
                        {/* <option key="" value="">--Please Select--</option>
                        {users?.sort((a, b) => a.Del_User_Email_Id?.localeCompare(b.Del_User_Email_Id))?.map(option => (
                          <option key={option.Del_User_Detail_Id} value={option.Del_User_Email_Id}>
                            {option.Del_User_Email_Id}
                          </option>
                        ))} */}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Subject && errors.Subject)}
                        fullWidth
                        helperText={touched.Subject && errors.Subject}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Subject"
                        name="Subject"
                        value={values.Subject}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Ticket User Type */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.User_Type && errors.User_Type)}
                        fullWidth
                        helperText={touched.User_Type && errors.User_Type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Ticket User Type"
                        name="User_Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.User_Type}
                        variant="outlined"
                      >
                        {userType.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Issue_Related_To && errors.Issue_Related_To)}
                        fullWidth
                        helperText={touched.Issue_Related_To && errors.Issue_Related_To}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Issue Related To"
                        name="Issue_Related_To"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Issue_Related_To}
                        variant="outlined"
                      >
                        {IssuerelatedOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Description  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                      </span>
                    </Grid>
                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusopt.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Attachment && errors.Attachment)}
                        fullWidth
                        helperText={touched.Attachment && errors.Attachment}
                        onBlur={handleBlur}
                        type="url"
                        onChange={handleChange}
                        label="Attachment URL"
                        name="Attachment"
                        value={values.Attachment}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
}

EditdeliveryTicket.propTypes = {
  className: PropTypes.string
};

export default EditdeliveryTicket;