import { loginCheckRoles } from './authActions';
import { nodeGetApi } from './commonAxios';

export const ROLEPERM_REQUEST = 'ROLEPERM_REQUEST';
export const ROLEPERM_SUCCESS = "ROLEPERM_SUCCESS";
export const ROLEPERM_FAIL = "ROLEPERM_FAIL";

//get role permissions
export const getRolePermissions = (UserType) => {
  return function (dispatch) {
    nodeGetApi.post(`/Admin/Roles_Get`,
      {
        Records_Filter: 'FILTER',
        Role_Name: UserType?.User_Type,
        search_by_filter: "",
        search: "",
      },
    )
      .then(response => {
        let successResponse = response.data.results[0]?.Permission;
        const getroles = eval(successResponse);
        const roleObj = getroles => {
          let res = {}
          for (let i = 0; i < getroles?.length; i++) {
            const key = getroles[i]['Value']
            res[key] = getroles[i]
          }
          return res;

        };
        dispatch(loginCheckRoles(roleObj(getroles)));
      })
      .catch(error => {
      })
  }
}