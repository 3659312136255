import {
  TICKET_CREATE_REQUEST,
  TICKET_CREATE_SUCCESS,
  TICKET_CREATE_FAIL,
  TICKET_UPDATE_REQUEST,
  TICKET_UPDATE_SUCCESS,
  TICKET_UPDATE_FAIL,
  TICKET_USERS_REQUEST,
  TICKET_USERS_SUCCESS,
  TICKET_USERS_FAIL,
} from '../actions/ticketingActions'

const initialState = {
  ticketCreate: { ticket: {}, error: '', isLoading: false },
  ticketUpdate: { ticket: {}, error: '', isLoading: false },
  Users: { users: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TICKET_CREATE_REQUEST:
      return { ...state, ticketCreate: { ticket: {}, error: '', isLoading: true } };
    case TICKET_CREATE_SUCCESS:
      return { ...state, ticketCreate: { ticket: state.ticketCreate.ticket, error: '', isLoading: false } };
    case TICKET_CREATE_FAIL:
      return { ...state, ticketCreate: { ticket: {}, error: action.payload, isLoading: false } };
    case TICKET_UPDATE_REQUEST:
      return { ...state, ticketUpdate: { ticket: {}, error: '', isLoading: true } };
    case TICKET_UPDATE_SUCCESS:
      return { ...state, ticketUpdate: { ticket: state.ticketCreate.ticket, error: '', isLoading: false } };
    case TICKET_UPDATE_FAIL:
      return { ...state, ticketUpdate: { ticket: {}, error: action.payload, isLoading: false } };

    case TICKET_USERS_REQUEST:
      return { ...state, Users: { users: state.Users.users, error: '', isLoading: true }, };
    case TICKET_USERS_SUCCESS:
      return { ...state, Users: { users: action.payload, error: '', isLoading: false } };
    case TICKET_USERS_FAIL:
      return { ...state, Users: { users: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}