import config from './config';
import { toast } from 'react-toastify';
import { paginationComments } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const ALL_COMMENTS_REQUEST = "ALL_COMMENTS_REQUEST";
export const ALL_COMMENTS_SUCCESS = "ALL_COMMENTS_SUCCESS";
export const ALL_COMMENTS_FAIL = "ALL_COMMENTS_FAIL";

export const COMMENTS_REQUEST = "COMMENTS_REQUEST";
export const COMMENTS_SUCCESS = "COMMENTS_SUCCESS";
export const COMMENTS_FAIL = "COMMENTS_FAIL";

export const COMMENTS_CREATE_REQUEST = "COMMENTS_CREATE_REQUEST";
export const COMMENTS_CREATE_SUCCESS = "COMMENTS_CREATE_SUCCESS";
export const COMMENTS_CREATE_FAIL = "COMMENTS_CREATE_FAIL";

export const COMMENTS_UPDATE_REQUEST = "COMMENTS_UPDATE_REQUEST";
export const COMMENTS_UPDATE_SUCCESS = "COMMENTS_UPDATE_SUCCESS";
export const COMMENTS_UPDATE_FAIL = "COMMENTS_UPDATE_FAIL";

/*=============================================================
                  Add Comment Ticket Action
===============================================================*/
export const createCommentTicketAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: COMMENTS_CREATE_REQUEST
  });
  nodeCudApi.post(`/Tickets/Comment_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: COMMENTS_CREATE_SUCCESS
        });
        toast('Comment added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/ticketing-management-comments', state = { state });

      } else {
        dispatch({

          type: COMMENTS_CREATE_SUCCESS,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: COMMENTS_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                edit comment Ticket Action
===============================================================*/
export const updateCommentTicketAction = (formData, navigate, state) => async dispatch => {
  dispatch({
    type: COMMENTS_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Tickets/Comment_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: COMMENTS_UPDATE_SUCCESS
        });
        toast('Comment updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/ticketing-management-comments', state = { state });

      } else {
        dispatch({
          type: COMMENTS_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: COMMENTS_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

/*=============================================================
                  Get All comment Action
===============================================================*/
export const getAllCommentAction = formData => async dispatch => {
  try {
    dispatch({
      type: COMMENTS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Tickets/Comment_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=TICKET_COMMENT_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: COMMENTS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: COMMENTS_SUCCESS,
          payload: data.results

        });
      }
    }
  } catch (err) {
    dispatch({
      type: COMMENTS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Delete Comment Action
===============================================================*/
export const deleteCommentAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Tickets/Comment_Delete`, formData);
    if (data) {
      toast('Comment deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationComments(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

// Get UserComment Action
export const getUserCommentAction = (formData, callBackUserCommentData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Tickets/Comment_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=TICKET_COMMENT_ID&SortOrder=ASC`, formData);
    if (data) {
      callBackUserCommentData(data)
    }
  } catch (err) {
  }
};