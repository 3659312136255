

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  InputAdornment,
  SvgIcon,
  TextField,
  makeStyles,
  Button,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  formFilterControl: {
    margin: theme.spacing(1),
    minWidth: 200
  }
}));

const Toolbar = ({ className, state, RolePermissions, roleUserType, filters, setfilters, ...rest }) => {
  const classes = useStyles();

  const handleChange = event => {
    setfilters({
      search: filters?.search,
      search_by_filter: event.target.value,
      Records_Filter: 'FILTER',
      Product_Id: state.Product_Id,
    })
  };

  return (
    <div className={clsx(classes.root, className)}
      {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        mb={2}
      >
        <Box display="flex"
          flexWrap="wrap" mb={2}>
          <FormControl variant="outlined"
            className={classes.formFilterControl}>
            <Select
              value={filters?.search_by_filter}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Related_Prod_Id">Related Product ID</MenuItem>
              <MenuItem value="Related_Product_Of_Product_Id">Product ID</MenuItem>
              <MenuItem value="Created_Date">Created Date</MenuItem>

            </Select>
          </FormControl>
          <Box width={500}>
            <TextField
              onChange={(event) => {
                setfilters({
                  search: event.target.value,
                  search_by_filter: filters?.search_by_filter,
                  Records_Filter: 'FILTER',
                  Product_Id: state.Product_Id,
                })
              }}
              className={classes.formControl}
              value={filters?.search}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search Related Products"
              variant="outlined"
            />
          </Box>
        </Box>
        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
          (RolePermissions?.Related_Products_Write?.Value === "Related_Products_Write" && RolePermissions?.Related_Products_Write?.Is_Write === 1) ?
          <Button
            component={RouterLink}
            to="/app/add-relatedproducts"
            style={{ backgroundColor: '#27B6CC' }}
            variant="contained"
            state={state}
          >
            <span style={{ color: "white" }}>
              + Add Related Product
            </span>
          </Button>
          : null}
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;