import { toast } from 'react-toastify';
import config from './config';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const GLOBAL_COUPON_REQUEST = 'GLOBAL_COUPON_REQUEST';
export const GLOBAL_COUPON_SUCCESS = "GLOBAL_COUPON_SUCCESS";
export const GLOBAL_COUPON_FAIL = "GLOBAL_COUPON_FAIL";

export const GLOBAL_COUPON_CREATE_REQUEST = "GLOBAL_COUPON_CREATE_REQUEST";
export const GLOBAL_COUPON_CREATE_SUCCESS = "GLOBAL_COUPON_CREATE_SUCCESS";
export const GLOBAL_COUPON_CREATE_FAIL = "GLOBAL_COUPON_CREATE_FAIL";

export const GLOBAL_COUPON_UPDATE_REQUEST = "GLOBAL_COUPON_UPDATE_REQUEST";
export const GLOBAL_COUPON_UPDATE_SUCCESS = "GLOBAL_COUPON_UPDATE_SUCCESS";
export const GLOBAL_COUPON_UPDATE_FAIL = "GLOBAL_COUPON_UPDATE_FAIL";

//get global coupon action
export const getAllGlobalCouponsAction = formData => async dispatch => {
    try {
        dispatch({
            type: GLOBAL_COUPON_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Meepaisa_Coupon/meepaisa_coupon_get?PageNo=1&PageSize=${config.pageSize}&SortBy=MP_COUPON_ID&SortOrder=ASC`, formData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: GLOBAL_COUPON_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: GLOBAL_COUPON_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: GLOBAL_COUPON_FAIL,
            payload: err
        });
    }
};

//create global coupon action
export const createGlobalCouponAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: GLOBAL_COUPON_CREATE_REQUEST
    });
    nodeCudApi.post(`/Meepaisa_Coupon/meepaisa_coupon_create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: GLOBAL_COUPON_CREATE_SUCCESS
                });
                toast('Global Coupon Created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/global-coupon-management');
            } else {
                dispatch({
                    type: GLOBAL_COUPON_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: GLOBAL_COUPON_CREATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};

//update global coupon action
export const updateGlobalCouponAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: GLOBAL_COUPON_UPDATE_REQUEST
    });
    nodeCudApi.post(`/Meepaisa_Coupon/Meepaisa_Coupon_Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: GLOBAL_COUPON_UPDATE_SUCCESS
                });
                toast('Global Coupon Updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/global-coupon-management');
            } else {
                dispatch({
                    type: GLOBAL_COUPON_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: GLOBAL_COUPON_UPDATE_FAIL,
                payload: "Currently server is not working.Please try again later."
            });
        })
};