
import config from './config';
import { toast } from 'react-toastify';
import { paginationProductSizes } from './node-actions/nodepagination';
import { nodeCudApi } from './commonAxios';


export const PRODUCTSIZE_CREATE_REQUEST = "PRODUCTSIZE_CREATE_REQUEST";
export const PRODUCTSIZE_CREATE_SUCCESS = "PRODUCTSIZE_CREATE_SUCCESS";
export const PRODUCTSIZE_CREATE_FAIL = "PRODUCTSIZE_CREATE_FAIL";
export const PRODUCTSIZE_UPDATE_REQUEST = "PRODUCTSIZE_UPDATE_REQUEST";
export const PRODUCTSIZE_UPDATE_SUCCESS = "PRODUCTSIZE_UPDATE_SUCCESS";
export const PRODUCTSIZE_UPDATE_FAIL = "PRODUCTSIZE_UPDATE_FAIL";

/*=============================================================
             create Product Size Action
 ===============================================================*/


export const createProductSizeAction = (formData, state, navigate) => async dispatch => {
  dispatch({
    type: PRODUCTSIZE_CREATE_REQUEST
  });
  nodeCudApi.post(`/Products/SizeCreate`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PRODUCTSIZE_CREATE_SUCCESS
        });
        toast('Product size added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });

        navigate('/app/product-management/product-sizes', state = { state });
      } else {
        dispatch({
          type: PRODUCTSIZE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PRODUCTSIZE_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                update Product Size Action
===============================================================*/
export const updateProductSizeAction = (formData, state, navigate) => async dispatch => {
  dispatch({
    type: PRODUCTSIZE_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Products/SizeUpdate`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PRODUCTSIZE_UPDATE_SUCCESS
        });
        toast('Product size updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/product-management/product-sizes', state = { state });
      } else {
        dispatch({
          type: PRODUCTSIZE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PRODUCTSIZE_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
               Delete ProductMaster Action
===============================================================*/
export const deleteProductMasterAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Products/SizeDelete`, formData);
    if (data) {
      toast('Product size deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationProductSizes(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};