import {
  DELIVERY_PAYMENT_CREATE_REQUEST,
  DELIVERY_PAYMENT_CREATE_SUCCESS,
  DELIVERY_PAYMENT_CREATE_FAIL,
  DELIVERY_PAYMENT_UPDATE_REQUEST,
  DELIVERY_PAYMENT_UPDATE_SUCCESS,
  DELIVERY_PAYMENT_UPDATE_FAIL,
} from '../actions/deliverypaymentsettlementsAction'

const initialState = {
  deliverypaymentCreate: { deliverypayment: {}, error: '', isLoading: false },
  deliverypaymentUpdate: { deliverypayment: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DELIVERY_PAYMENT_CREATE_REQUEST:
      return { ...state, deliverypaymentCreate: { deliverypayment: {}, error: '', isLoading: true } };
    case DELIVERY_PAYMENT_CREATE_SUCCESS:
      return { ...state, deliverypaymentCreate: { deliverypayment: state.deliverypaymentCreate.deliverypayment, error: '', isLoading: false } };
    case DELIVERY_PAYMENT_CREATE_FAIL:
      return { ...state, deliverypaymentCreate: { deliverypayment: {}, error: action.payload, isLoading: false } };

    case DELIVERY_PAYMENT_UPDATE_REQUEST:
      return { ...state, deliverypaymentUpdate: { deliverypayment: {}, error: '', isLoading: true } };
    case DELIVERY_PAYMENT_UPDATE_SUCCESS:
      return { ...state, deliverypaymentUpdate: { deliverypayment: state.deliverypaymentUpdate.deliverypayment, error: '', isLoading: false } };
    case DELIVERY_PAYMENT_UPDATE_FAIL:
      return { ...state, deliverypaymentUpdate: { deliverypayment: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}