import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/sbcm/city-management',
    createdAt: 'Updated 2 hr ago',
    description:
      'City Management refers to the creation and updation of the Cities.',
    media: '/static/Dashboard/city_management.jpg',
    title: 'City Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/sbcm/city-partner-management',
    createdAt: 'Updated 2 hr ago',
    description:
      'Citys Partner Management refers to the creation and updation of the Partners related to cities.',
    media: '/static/Dashboard/citys_partner.png',
    title: "Cities Partner Management",
    total: ''
  }
];