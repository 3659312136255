import { Card, CardContent, Divider, Grid } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const B2BUserProdBidQuotesView = ({ className, data, state, ...rest }) => {

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            {/* B2B_Partner_Bidding_Quote_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    B2B User Product Bidding Quote ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.B2B_Partner_Bidding_Quote_Id ? state?.B2B_Partner_Bidding_Quote_Id : data?.B2B_Partner_Bidding_Quote_Id}
                                </Typography>
                            </Grid>

                            {/* B2B_User_Products_Bidding_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    B2B User Products Bidding ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.B2B_User_Products_Bidding_Id ? state?.B2B_User_Products_Bidding_Id : data?.B2B_User_Products_Bidding_Id}
                                </Typography>
                            </Grid>

                            {/* Partner_Detail_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Partner Detail ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Partner_Detail_Id ? state?.Partner_Detail_Id : data?.Partner_Detail_Id}
                                </Typography>
                            </Grid>

                            {/* Partner_Name */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Partner Name
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Partner_Name ? state?.Partner_Name : data?.Partner_Name}
                                </Typography>
                            </Grid>

                            {/* Partner_Location */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Partner Location
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Partner_Location ? state?.Partner_Location : data?.Partner_Location}
                                </Typography>
                            </Grid>

                            {/* Advance_Token_Amount */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Advance Token Amount
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Advance_Token_Amount ? state?.Advance_Token_Amount : data?.Advance_Token_Amount}
                                </Typography>
                            </Grid>

                            {/* Quotation_Amount */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Quotation Amount
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Quotation_Amount ? state?.Quotation_Amount : data?.Quotation_Amount}
                                </Typography>
                            </Grid>

                            {/* Status */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Status
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Status ? state?.Status : data?.Status}
                                </Typography>
                            </Grid>

                            {/* Bidding_Status */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Bidding Status
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Bidding_Status ? state?.Bidding_Status : data?.Bidding_Status}
                                </Typography>
                            </Grid>

                            {/* Delivery_Fee */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Delivery Fee
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Delivery_Fee ? state?.Delivery_Fee : data?.Delivery_Fee}
                                </Typography>
                            </Grid>

                            {/* Estimated_Delivery_Date */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Estimated Delivery Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Estimated_Delivery_Date ? state?.Estimated_Delivery_Date : data?.Estimated_Delivery_Date}
                                </Typography>
                            </Grid>

                            {/* No_Of_Delivery_Days */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    No. of Delivery Days
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.No_Of_Delivery_Days ? state?.No_Of_Delivery_Days : data?.No_Of_Delivery_Days}
                                </Typography>
                            </Grid>

                            {/* Delivery_Managed_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Delivery Managed by
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state?.Delivery_Managed_By ? state?.Delivery_Managed_By : data?.Delivery_Managed_By}> {state?.No_Of_Delivery_Days ? state?.No_Of_Delivery_Days : data?.Delivery_Managed_By?.length > 25 ?
                                        <span>{state?.Delivery_Managed_By ? state?.Delivery_Managed_By : data?.Delivery_Managed_By?.substring(0, 25)}...</span> : state?.No_Of_Delivery_Days ? state?.No_Of_Delivery_Days : data?.Delivery_Managed_By}</span>
                                </Typography>
                            </Grid>

                            {/* Created_Date */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Created_Date ? state?.Created_Date : data?.Created_Date}
                                </Typography>
                            </Grid>

                            {/* Created_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state?.Created_By ? state?.Created_By : data?.Created_By}> {state?.Created_By ? state?.Created_By : data?.Created_By?.length > 25 ?
                                        <span>{state?.Created_By ? state?.Created_By : data?.Created_By?.substring(0, 25)}...</span> : state?.Created_By ? state?.Created_By : data?.Created_By}</span>
                                </Typography>
                            </Grid>

                            {/* Modified_Date */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Modified_Date ? state?.Modified_Date : data?.Modified_Date}
                                </Typography>
                            </Grid>

                            {/* Modified_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem', paddingBottom: '1rem' }}
                                >
                                    <span title={state?.Modified_By ? state?.Modified_By : data?.Modified_By}> {state?.Modified_Date ? state?.Modified_By : data?.Modified_By?.length > 25 ?
                                        <span>{state?.Modified_By ? state?.Modified_By : data?.Modified_By?.substring(0, 25)}...</span> : state?.Modified_By ? state?.Modified_By : data?.Modified_By}</span>
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Divider />
                {/* Partner_Comments */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A', paddingBottom: '1rem' }}
                    >
                        Partner Comments
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                    >
                        <span dangerouslySetInnerHTML={{ __html: state?.Partner_Comments ? state?.Partner_Comments : data?.Partner_Comments }}></span>
                    </Typography>
                </Grid>

            </CardContent>
        </Card>
    );
};

export default B2BUserProdBidQuotesView;