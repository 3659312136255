import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Category_Id', numeric: false, disablePadding: true, label: 'Category ID' },
  { id: 'Category_Name', numeric: false, disablePadding: false, label: 'Category Name' },
  { id: 'Description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'Store_Type_Name', numeric: false, disablePadding: false, label: 'Store Type Name' },
];

const Results = ({
  className,
  allCategory,
  deleteCategoryHandler,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allCategoryNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedCategoryIds;
    if (event.target.checked) {
      newSelectedCategoryIds = allCategory.map(category => category.Category_Id);
    } else {
      newSelectedCategoryIds = [];
    }
    setSelectedCategoryIds(newSelectedCategoryIds);
  };

  const handleSelectOne = (event, id) => {
    setSelectedCategoryIds(selectedCategoryIds.filter(record => {
      for (let i = 0; i < allCategoryNoFilter.length; i++) {
        if (record === allCategoryNoFilter[i].Category_Id) {
          return true;
        }
      }
      return false;
    }))

    const selectedIndex = selectedCategoryIds.indexOf(id);
    let newSelectedCategoryIds = [];
    if (selectedIndex === -1) {
      newSelectedCategoryIds = newSelectedCategoryIds.concat(selectedCategoryIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCategoryIds = newSelectedCategoryIds.concat(
        selectedCategoryIds.slice(1)
      );
    } else if (selectedIndex === selectedCategoryIds.length - 1) {
      newSelectedCategoryIds = newSelectedCategoryIds.concat(
        selectedCategoryIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCategoryIds = newSelectedCategoryIds.concat(
        selectedCategoryIds.slice(0, selectedIndex),
        selectedCategoryIds.slice(selectedIndex + 1)
      );
    }
    setSelectedCategoryIds(newSelectedCategoryIds);
  };

  const deleteSelectedCategoryHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const editCategoryHandler = categoryData => e => {
    navigate('/app/edit-category', { state: categoryData });
  };

  const visibleCategoryHandler = categoryData => e => {
    navigate("/app/category-details", { state: categoryData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCategoryIds.filter(record => {
                      for (let i = 0; i < allCategoryNoFilter.length; i++) {
                        if (record === allCategoryNoFilter[i].Category_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allCategory.length}
                    color="primary"
                    indeterminate={
                      selectedCategoryIds.filter(record => {
                        for (let i = 0; i < allCategoryNoFilter.length; i++) {
                          if (record === allCategoryNoFilter[i].Category_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedCategoryIds.filter(record => {
                        for (let i = 0; i < allCategoryNoFilter.length; i++) {
                          if (record === allCategoryNoFilter[i].Category_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allCategory.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.id !== "Description" ? (
                      <TableSortLabel className={classes.headerColumn}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>) : (<span style={{ fontSize: "13px", fontWeight: "bold" }} > {headCell.label}</span>)
                    }
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedCategoryIds.filter(record => {
                    for (let i = 0; i < allCategoryNoFilter.length; i++) {
                      if (record === allCategoryNoFilter[i].Category_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedCategoryIds.filter(record => {
                    for (let i = 0; i < allCategoryNoFilter.length; i++) {
                      if (record === allCategoryNoFilter[i].Category_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedCategoryHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allCategory) &&
                (allCategory).map(category => (
                  <TableRow
                    hover
                    key={category.Category_Id}
                    selected={
                      selectedCategoryIds.indexOf(category.Category_Id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedCategoryIds.indexOf(category.Category_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, category.Category_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell >{category.Category_Id}</TableCell>
                    <TableCell >{category.Category_Name}</TableCell>
                    <TableCell width="16%" ><div style={{ maxHeight: "40px", overflowY: "hidden", lor: '#1665D8' }} dangerouslySetInnerHTML={{ __html: category.Description }}></div>
                    </TableCell>
                    <TableCell style={{ color: '#1665D8' }}>{category.Status} </TableCell>
                    <TableCell style={{ justifyContent: 'center' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{category.Store_Type_Name}  </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Category_Management_Write?.Value === "Category_Management_Write" && RolePermissions?.Category_Management_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Tooltip title="Category Details">
                          <Button
                            onClick={visibleCategoryHandler(category)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        <Button
                          onClick={editCategoryHandler(category)}>
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                          <DeleteIcon onClick={deleteCategoryHandler(category)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Category_Management_Read?.Value === "Category_Management_Read" && RolePermissions?.Category_Management_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="Category Details">
                          <Button
                            onClick={visibleCategoryHandler(category)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allCategory) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allCategory: PropTypes.array.isRequired
};

export default Results;