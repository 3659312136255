import { toast } from 'react-toastify';
import { paginationFAQManagement } from './node-actions/nodepagination';
import { nodeCudApi} from './commonAxios';
export const FAQ_CREATE_REQUEST = "FAQ_CREATE_REQUEST";
export const FAQ_CREATE_SUCCESS = "FAQ_CREATE_SUCCESS";
export const FAQ_CREATE_FAIL = "FAQ_CREATE_FAIL";
export const FAQ_UPDATE_REQUEST = "FAQ_UPDATE_REQUEST";
export const FAQ_UPDATE_SUCCESS = "FAQ_UPDATE_SUCCESS";
export const FAQ_UPDATE_FAIL = "FAQ_UPDATE_FAIL";
export const RESET = "RESET";


export const invalid_data = () => {
  return {
    type: RESET
  }
}


/*=============================================================
                  Add FAQ Action
===============================================================*/
export const addFaqAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: FAQ_CREATE_REQUEST
  });
  nodeCudApi.post(`/FAQ/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: FAQ_CREATE_SUCCESS
        });
        toast('FAQ added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/faq-management');
      } else {
        dispatch({
          type: FAQ_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: FAQ_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                    Update Faq Action
===============================================================*/
export const updateFaqAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: FAQ_UPDATE_REQUEST
  });
  nodeCudApi.post(`/FAQ/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: FAQ_UPDATE_SUCCESS
        });
        toast('FAQ updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/faq-management');
      } else {
        dispatch({
          type: FAQ_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: FAQ_CREATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

/*=============================================================
                  Delete FAQ ByID Action
===============================================================*/
export const deleteFaqById = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/FAQ/Delete`, formData);
    if (data) {
      toast('FAQ deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationFAQManagement(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};