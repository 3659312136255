
import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi, boomiAdminApi } from './commonAxios';

export const getUserProfileAction =
  (updateStates, formData) => async (dispatch) => {
    try {
      let { data } = await nodeGetApi.post(`/Super_Admin/User_Get`, formData);
      if (data) {
        updateStates(data, false, false);
      }
    } catch (error) {
      updateStates([], false, true);
    }
  };
/*=============================================================
               createProfile Action
===============================================================*/
export const createProfileAction =
  (updateStates, formData) => async (dispatch) => {
    try {
      let { data } = await nodeGetApi.post(`/User/Profile_Create`, formData);

      if (data) {
        updateStates(data, false);
      }
    } catch (error) {
    }
  };

/*=============================================================
            ChangePassword Action
===============================================================*/
export const ChangePasswordAction =
  (updateStates, formData) => async (dispatch) => {
    try {
      let { data } = await boomiAdminApi.post(`/User/Change_Password`, formData
      );

      if (data) {
        updateStates(data, false);
      }
    } catch (error) {
    }
  };

/*=============================================================
           uploadProfile Action
===============================================================*/
export const uploadProfileAction =
  (updateStates, formData, fileData) => async (dispatch) => {
    try {
      let { data } = await boomiAdminApi.post(
        `/User/Image?filename=${fileData.fileName}&file_extension=${fileData.ext}&filetype=image&user_details_id=${fileData.userDetailsId}`,
        formData
      );
      if (data) {
        updateStates(data, false);
      }
    } catch (error) {
    }
  };

/*=============================================================
        edit Profile Action
===============================================================*/
export const editProfileAction =
  (updateStates, formData) => async (dispatch) => {
    try {
      let { data } = await nodeCudApi.post(`/User/Profile_Update`, formData);

      if (data) {
        updateStates(data, false);
      }
    } catch (error) {
    }
  };

/*=============================================================
        change Password Action
 ===============================================================*/
export const changePasswordAction =
  (formData, changePasswordResponse, resetForm) => async (dispatch) => {
    try {
      let { data } = await boomiAdminApi.post(`/User/Change_Password`, formData);

      if (data) {
        if (data.Response_Status === "Success") {
          changePasswordResponse(true, "");
          resetForm();
          toast('New password updated successfully.', {
            position: toast.POSITION.TOP_CENTER, autoClose: 3000,
          });
        } else {
          changePasswordResponse(false, data.UI_Display_Message);
        }
      }
    } catch (error) {
      changePasswordResponse(true, "Please try again.");
    }
  };