import {
    WALLETS_PARTNER_REQUEST,
    WALLETS_PARTNER_SUCCESS,
    WALLETS_PARTNER_FAIL,
    WALLET_PARTNER_GET_REQUEST,
    WALLET_PARTNER_GET_SUCCESS,
    WALLET_PARTNER_GET_FAIL,
    WALLET_PARTNER_CREATE_REQUEST,
    WALLET_PARTNER_CREATE_SUCCESS,
    WALLET_PARTNER_CREATE_FAIL,
    WALLET_PARTNER_UPDATE_REQUEST,
    WALLET_PARTNER_UPDATE_SUCCESS,
    WALLET_PARTNER_UPDATE_FAIL,
    WALLET_USER_EMAIL_REQUEST,
    WALLET_USER_EMAIL_SUCCESS,
    WALLET_USER_EMAIL_FAIL,
} from '../actions/partnerwalletAction';

const initialState = {
    partnerwallets: { all: [], error: '', isLoading: false },
    partnerwallet: { partnerwallet: {}, error: '', isLoading: false },
    partnerwalletCreate: { partnerwallet: {}, error: '', isLoading: false },
    partnerwalletUpdate: { partnerwallet: {}, error: '', isLoading: false },
    walletDelete: { partnerwallet: {}, error: '', isLoading: false },
    partnerEmails: { emails: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case WALLETS_PARTNER_REQUEST:
            return { ...state, partnerwallets: { all: state.partnerwallets.all, error: '', isLoading: true } };
        case WALLETS_PARTNER_SUCCESS:
            return { ...state, partnerwallets: { all: action.payload, error: '', isLoading: false } };
        case WALLETS_PARTNER_FAIL:
            return { ...state, partnerwallets: { all: [], error: action.payload, isLoading: false } };
        case WALLET_PARTNER_GET_REQUEST:
            return { ...state, partnerwallet: { partnerwallet: {}, error: '', isLoading: true } };
        case WALLET_PARTNER_GET_SUCCESS:
            return { ...state, partnerwallet: { partnerwallet: action.payload, error: '', isLoading: false } };
        case WALLET_PARTNER_GET_FAIL:
            return { ...state, partnerwallet: { partnerwallet: {}, error: action.payload, isLoading: false } };
        case WALLET_PARTNER_CREATE_REQUEST:
            return { ...state, partnerwalletCreate: { partnerwallet: {}, error: '', isLoading: true } };
        case WALLET_PARTNER_CREATE_SUCCESS:
            return { ...state, partnerwalletCreate: { partnerwallet: state.partnerwalletCreate.partnerwallet, error: '', isLoading: false } };
        case WALLET_PARTNER_CREATE_FAIL:
            return { ...state, partnerwalletCreate: { partnerwallet: {}, error: action.payload, isLoading: false } };
        case WALLET_PARTNER_UPDATE_REQUEST:
            return { ...state, partnerwalletUpdate: { partnerwallet: {}, error: '', isLoading: true } };
        case WALLET_PARTNER_UPDATE_SUCCESS:
            return { ...state, partnerwalletUpdate: { partnerwallet: state.partnerwalletUpdate.partnerwallet, error: '', isLoading: false } };
        case WALLET_PARTNER_UPDATE_FAIL:
            return { ...state, partnerwalletUpdate: { partnerwallet: {}, error: action.payload, isLoading: false } };

        case WALLET_USER_EMAIL_REQUEST:
            return { ...state, partnerEmails: { emails: state.partnerEmails.emails, error: '', isLoading: true }, };
        case WALLET_USER_EMAIL_SUCCESS:
            return { ...state, partnerEmails: { emails: action.payload, error: '', isLoading: false } };
        case WALLET_USER_EMAIL_FAIL:
            return { ...state, partnerEmails: { emails: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}