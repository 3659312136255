import config from './config';
import { toast } from 'react-toastify';
import { boomiAdminApi, nodeGetApi } from './commonAxios';

export const PART_BID_AVAIL_PROD_CREATE_REQUEST = "PART_BID_AVAIL_PROD_CREATE_REQUEST";
export const PART_BID_AVAIL_PROD_CREATE_SUCCESS = "PART_BID_AVAIL_PROD_CREATE_SUCCESS";
export const PART_BID_AVAIL_PROD_CREATE_FAIL = "PART_BID_AVAIL_PROD_CREATE_FAIL";

export const PART_BID_AVAIL_PROD_UPDATE_REQUEST = "PART_BID_AVAIL_PROD_UPDATE_REQUEST";
export const PART_BID_AVAIL_PROD_UPDATE_SUCCESS = "PART_BID_AVAIL_PROD_UPDATE_SUCCESS";
export const PART_BID_AVAIL_PROD_UPDATE_FAIL = "PART_BID_AVAIL_PROD_UPDATE_FAIL";

export const PART_BID_PRODUCTS_GET_REQUEST = "PART_BID_PRODUCTS_GET_REQUEST";
export const PART_BID_PRODUCTS_GET_SUCCESS = "PART_BID_PRODUCTS_GET_SUCCESS";
export const PART_BID_PRODUCTS_GET_FAIL = "PART_BID_PRODUCTS_GET_FAIL";

export const PART_BID_PARTNERS_GET_REQUEST = "PART_BID_PARTNERS_GET_REQUEST";
export const PART_BID_PARTNERS_GET_SUCCESS = "PART_BID_PARTNERS_GET_SUCCESS";
export const PART_BID_PARTNERS_GET_FAIL = "PART_BID_PARTNERS_GET_FAIL";

//create Partner Bidding Available Products Action
export const createPartnerBidAvailProducts = (formData, navigate) => async dispatch => {
    dispatch({
        type: PART_BID_AVAIL_PROD_CREATE_REQUEST
    });
    boomiAdminApi.post(`/Partner_Bidding_Available_Products/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: PART_BID_AVAIL_PROD_CREATE_SUCCESS
                });
                toast('Bidding Available Products created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/partner-bidding-available-products');
            } else {
                dispatch({
                    type: PART_BID_AVAIL_PROD_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: PART_BID_AVAIL_PROD_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

//update Partner Bidding Available Products Action
export const updatePartnerBidAvailProducts = (formData, navigate) => async dispatch => {
    dispatch({
        type: PART_BID_AVAIL_PROD_UPDATE_REQUEST
    });
    boomiAdminApi.put(`/Partner_Bidding_Available_Products/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: PART_BID_AVAIL_PROD_UPDATE_SUCCESS
                });
                toast('Bidding Available Products updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/partner-bidding-available-products');
            } else {

                dispatch({
                    type: PART_BID_AVAIL_PROD_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: PART_BID_AVAIL_PROD_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//get all Bidding Products
export const getAllBiddingProducts = (formData) => async dispatch => {
    try {
        dispatch({
            type: PART_BID_PRODUCTS_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Super_Admin/Bidding_Products?PageNo=1&PageSize=${config.pageSize}&SortBy=Bidding_Product_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: PART_BID_PRODUCTS_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: PART_BID_PRODUCTS_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: PART_BID_PRODUCTS_GET_FAIL,
            payload: err
        });
    }
};

//get all Bidding Partners
export const getAllBiddingPartners = () => async dispatch => {
    let formData = {
        "ExtraCashBack": "",
        "Name": "",
        "Partner_Details_Id": "",
        "Records_Filter": "FILTER",
        "Status": "",
        "Top_Stores": "",
        "Buisness_Type": "RETAIL"
    };
    try {
        dispatch({
            type: PART_BID_PARTNERS_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Partners/Physical_Partner_Profile_Get_Admin_Retail?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: PART_BID_PARTNERS_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: PART_BID_PARTNERS_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: PART_BID_PARTNERS_GET_FAIL,
            payload: err
        });
    }
};

//get all Bidding Partner Address
export const getAllBidPartnerAddress = (formData, callBackData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/Partners/Partner_Address_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_ADDRESS_ID&SortOrder=ASC`, formData);
        if (data) {
            if (data.Success_Response?.Is_Data_Exist === "0") {
                callBackData([])
            } else {
                callBackData(data.results)
            }
        }
    } catch (err) {
        dispatch({
            payload: err
        });
    }
};

//Get  Partner Bidding Available Products 
export const getBiddingAvailProductAction = (formData, callBackBiddingAvailProduct) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/Super_Admin/Partner_Bidding_Available_Products`, formData);
        if (data) {
            callBackBiddingAvailProduct(data)
        }
    } catch (err) {
    }
};