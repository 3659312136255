import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    TextareaAutosize,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress, Rating } from '@mui/material';
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';
import { createBiddingRideSuccessStory, getAllBidDeliveryEmails, getAllDeliBiddingVehicles } from 'src/store/actions/biddingridesuccessstoriesAction';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(() => ({
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    },
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const AddDeliveryBidSuccessStory = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    //get
    useEffect(() => {
        const formData = {
            Records_Filter: ''
        };
        dispatch(getAllDeliBiddingVehicles(formData));
        dispatch(getAllBidDeliveryEmails());
    }, []);

    const isSubmitted = useSelector(state => state.bidrideSuccessStories.bidsuccessstoryCreate.isLoading);
    const errorMessage = useSelector(state => state.bidrideSuccessStories.bidsuccessstoryCreate.error);
    const biddingdelvehicles = useSelector(state => state.bidrideSuccessStories.bidssvehicles.all);
    const biddingdelusers = useSelector(state => state.bidrideSuccessStories.bidssdeliusers.all);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    // Richtext editor
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
    const [rhDiscription, setRhDiscription] = useState('');
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    return (
        <Formik
            initialValues={{
                Bidding_Vehicle_Id: '',
                Delivery_User_Email_Id: '',
                Meepaise_Review_For_Ride: '',
                Meepaise_Rating_For_Ride: '',
                Usecase_Short_Description: '',
                Usecase_Description: '',
                Usecase_Video_Url: '',
                Created_By: loginEmail,
            }}
            validationSchema={
                Yup.object().shape({
                    Bidding_Vehicle_Id: Yup.string().required('Bidding Vehicle is required.'),
                    Delivery_User_Email_Id: Yup.string().required('Delivery User Email ID is required.'),
                    Meepaise_Review_For_Ride: Yup.string().required('Meepaisa Review for Ride is required.'),
                    Usecase_Short_Description: Yup.string().required('Usecase Short Description is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                values.Usecase_Description = rhDiscription;

                if (!values.Usecase_Description || values.Usecase_Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
                    submitTest = false;
                    errors.Usecase_Description = "Usecase Description is required.";
                }
                else if (values.Usecase_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
                    submitTest = false;
                    errors.Usecase_Description = "Please provide at least " + rhDiscriptionMin + " characters.";
                }
                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(createBiddingRideSuccessStory(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Vehicle_Id && errors.Bidding_Vehicle_Id)}
                                                fullWidth
                                                helperText={touched.Bidding_Vehicle_Id && errors.Bidding_Vehicle_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Vehicle"
                                                name="Bidding_Vehicle_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Vehicle_Id}
                                                variant="outlined"
                                            ><option key="" value="">-Please Select-</option>
                                                {biddingdelvehicles?.map(option => (
                                                    <option key={option.Bidding_Vehicle_Id} value={option.Bidding_Vehicle_Id}>
                                                        {option.Vehicle_Type + " - " + option.Vehicle_Comfort_Type}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            {/* <TextField
                                                error={Boolean(touched.Delivery_User_Email_Id && errors.Delivery_User_Email_Id)}
                                                fullWidth
                                                helperText={touched.Delivery_User_Email_Id && errors.Delivery_User_Email_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Delivery User Email ID"
                                                name="Delivery_User_Email_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Delivery_User_Email_Id}
                                                variant="outlined"
                                            ><option key="" value="">-Please Select-</option>
                                                {biddingdelusers?.map(option => (
                                                    <option key={option.Del_User_Detail_Id} value={option.Del_User_Email_Id}>
                                                        {option.Del_User_Email_Id}
                                                    </option>
                                                ))}
                                            </TextField> */}
                                             <Autocomplete
                                                freeSolo
                                                options={biddingdelusers}
                                                groupBy={(option) => option.firstLetter}
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option.Del_User_Email_Id}
                                                renderInput={(params) => <TextField {...params}
                                                    error={Boolean(touched.Delivery_User_Email_Id && errors.Delivery_User_Email_Id)}
                                                    helperText={touched.Delivery_User_Email_Id && errors.Delivery_User_Email_Id}
                                                    onBlur={handleBlur}
                                                    label="Del User Email ID"
                                                    name="Delivery_User_Email_Id"
                                                    variant="outlined"
                                                    value={values.Delivery_User_Email_Id}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        dispatch(getAllBidDeliveryEmails(params.inputProps.value))
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill,
                                                        startAdornment: (
                                                            <React.Fragment>
                                                                <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                {params.InputProps.startAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />}
                                                onChange={(e, value) => {
                                                    setFieldValue("Delivery_User_Email_Id", value?.Del_User_Email_Id ? value.Del_User_Email_Id : "");
                                                }}
                                            />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            Meepaisa Review for Ride
                                            <TextareaAutosize
                                                value={values.Meepaise_Review_For_Ride}
                                                onChange={handleChange}
                                                name="Meepaise_Review_For_Ride"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Meepaisa Review for Ride"
                                            >
                                            </TextareaAutosize>
                                            <span style={{ color: "red" }}>
                                                {((touched.Meepaise_Review_For_Ride && errors.Meepaise_Review_For_Ride))}
                                            </span>
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            Usecase Short Description
                                            <TextareaAutosize
                                                value={values.Usecase_Short_Description}
                                                onChange={handleChange}
                                                name="Usecase_Short_Description"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Usecase Short Description"
                                            >
                                            </TextareaAutosize>
                                            <span style={{ color: "red" }}>
                                                {((touched.Usecase_Short_Description && errors.Usecase_Short_Description))}
                                            </span>
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <Box className={classes.description}>
                                                Usecase Description
                                                <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                                            </Box>
                                            <span style={{ color: "red" }}>
                                                {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Usecase_Description && errors.Usecase_Description}
                                            </span>
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Usecase Video URL"
                                                name="Usecase_Video_Url"
                                                value={values.Usecase_Video_Url}
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </Grid>

                                        {/* Meepaise_Rating_For_Ride  */}
                                        <Grid item md={4} xs={4}>
                                            <Box>Meepaisa Rating for Ride</Box>
                                        </Grid>
                                        <Grid item md={2} xs={4}>
                                            <Rating
                                                name="Meepaise_Rating_For_Ride"
                                                onChange={(event, newValue) => {
                                                    values.Meepaise_Rating_For_Ride = newValue;
                                                    setFieldValue(newValue);
                                                }}
                                                value={values.Meepaise_Rating_For_Ride}
                                                size="medium"
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddDeliveryBidSuccessStory.propTypes = {
    className: PropTypes.string
};

export default AddDeliveryBidSuccessStory;