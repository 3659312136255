import React, { useState, useEffect } from 'react';
import {
    Box, Card, CardContent, Grid, TextField, makeStyles, CardHeader, Divider, Button, Dialog, DialogActions,
    DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Checkbox, FormControlLabel
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    getsingleOrderActionNew, OrderShippingStatus, Order_Status_Tracking, Partner_Adrs_Get,
} from 'src/store/actions/ordersAction';
import { Partner_Adrss_Get } from 'src/store/actions/node-actions/nodepagination';
import { Step, StepLabel, Stepper, Typography, Paper, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';

const EditInPrgsOrderStatusHistory = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const classes = useStyles();
    const location = useLocation();
    const { orderId } = location.state || {};
    const Orders = useSelector(state => state?.orders?.singleorder?.all);
    const [open, setOpen] = useState(false);
    const [openShip, setShipOpen] = useState(false);
    const [openDelivery, setOpenDelivery] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [partnerAdrss, setPartnerAdrss] = useState([]);
    const [partnerAdrs, setPartnerAdrs] = useState([])
    const [weight, setWeight] = useState('');
    const [length, setLength] = useState('');
    const [breadth, setBreadth] = useState('');
    const [height, setHeight] = useState('');
    const [loading, setLoading] = useState(false);

    const [orderStatusTrack, setorderStatusTrack] = useState({
        data: {
            order_number: "",
            order_id: "",
            awb_number: "",
            history: []
        }
    });
    //dispatch actions
    useEffect(() => {
        // if (!isEmpty(orderId)) {
        const formData = {
            "Records_Filter": "FILTER",
            "Order_Id": orderId
        };
        dispatch(getsingleOrderActionNew(formData));
        // }
    }, []);

    useEffect(() => {
        Partner_Addrs_Get_Call();
    }, [Orders])

    useEffect(() => {
        Partner_Adrs_Call(orderId);
    }, [])

    useEffect(() => {
        if (orderId) {
            Order_Status_Tracking_Call(orderId);
        }
    }, [orderId]);

    //for billing address......
    const address = Orders?.User_Billing_Address_Full;
    let billAddress = null;

    if (address) {
        try {
            billAddress = JSON.parse(address);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

    //for shipping address......
    const shippingAddress = Orders?.User_Shipping_Address_Full;
    let shipAddress = null;

    if (shippingAddress) {
        try {
            shipAddress = JSON.parse(shippingAddress);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleOpen = () => {
        setShipOpen(true);
    };

    const handleOpenDelivery = () => {
        setOpenDelivery(true);
    };

    const handleClose = () => {
        setOpen(false);
        setShipOpen(false);
        setOpenDelivery(false);
        setIsChecked(false);
        setSelectedAddress('')
    };

    const handleSelectChange = (event) => {
        const selectedAddressId = event.target.value;
        setSelectedAddress(selectedAddressId);
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    }

    const Partner_Addrs_Get_Call = async () => {
        try {
            const formData = {
                "search": '',
                "search_by_filter": 'ALL',
                Records_Filter: "FILTER",
                Status: "Active",
                Partner_Details_Id: Orders.Partner_Detail_Id
            }
            const response = await Partner_Adrss_Get(formData);
            if (response.status) {
                setPartnerAdrss(response.data);
            } else {
                setPartnerAdrss([]);
            }
        } catch (error) {
            setPartnerAdrss([]);
        }
    };

    const Partner_Adrs_Call = async (orderId) => {
        try {
            const response = await Partner_Adrs_Get(orderId);
            if (response.status) {
                setPartnerAdrs(response.data);
            } else {
                setPartnerAdrs([]);
            }
        } catch (error) {
            setPartnerAdrs([]);
        }
    };

    const order_Id = Orders?.Order_Id || null;

    const shippingOrder = async (order_Id) => {
        setLoading(true);
        const formData = {
            "Is_rto_different": isChecked,
            "rto_address_Id": selectedAddress || '',
            "Order_Status": "READY_FOR_SHIPPING",
            "Package_Weight": weight || '',
            "Package_Length": length || '',
            "Package_Breadth": breadth || '',
            "Package_Height": height || ''
        };
        const result = await dispatch(OrderShippingStatus(order_Id, formData, navigate));
        setLoading(false);
        if (result.data.code === "Success") {
            navigate('/app/inprogress-order-history')
            // handleClose();
        } else {
            toast(result.data.UI_Display_Message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
    };

    const shipmentOrder = async (order_Id) => {
        setLoading(true);
        const formData = {
            // "Is_rto_different": isChecked,
            "Order_Status": "SHIPPED",
        };
        const result = await dispatch(OrderShippingStatus(order_Id, formData, navigate));
        setLoading(false);
        if (result.data.code === "Success") {
            navigate('/app/inprogress-order-history')
            // handleClose();
        } else {
            toast(result.data.UI_Display_Message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
    };

    const deliveredOrder = async (order_Id) => {
        setLoading(true);
        const formData = {
            // "Is_rto_different": isChecked,
            "Order_Status": "DELIVERED",
        };
        const result = await dispatch(OrderShippingStatus(order_Id, formData, navigate));
        setLoading(false);
        if (result.data.code === "Success") {
            navigate('/app/cmpltd-order-history')
            // handleClose();
        } else {
            toast(result.data.UI_Display_Message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
    };

    const Order_Status_Tracking_Call = async (orderId) => {
        try {
            const response = await Order_Status_Tracking(orderId);
            if (response) {
                setorderStatusTrack(response); // Make sure response contains data
            } else {
                setorderStatusTrack({
                    data: {
                        order_number: "",
                        order_id: "",
                        awb_number: "",
                        history: []
                    }
                });
            }
        } catch (error) {
            setorderStatusTrack({
                data: {
                    order_number: "",
                    order_id: "",
                    awb_number: "",
                    history: []
                }
            });
        }
    };

    const orderDates = Orders?.Order_Status_Dates_Json
    const OrderStepper = ({ orderDates }) => {
        return (
            <Stepper orientation="vertical" style={{ padding: '20px' }}>
                {orderDates && Object.entries(orderDates).map(([key, { Date, BY, ID }]) => (
                    <Step key={key} active>
                        <StepLabel>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '8px' }}>
                                {/* <CalendarTodayIcon color="primary" /> */}
                                <strong>Status: {key}</strong>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'gray' }}>
                                <CalendarTodayIcon fontSize="small" />
                                <span>Date: {Date}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'gray' }}>
                                <PersonIcon fontSize="small" />
                                <span>By: {BY}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'gray' }}>
                                <BadgeIcon fontSize="small" />
                                <span>ID: {ID}</span>
                            </div>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        );
    };

    const ShipmentStepper = ({ response }) => {
        // Check if orderStatusTrack.data exists and has history
        if (!response?.data?.history || response.data.history.length === 0) {
            return <Typography>No shipment history available</Typography>;
        }

        const { history } = response.data;

        return (
            <Box sx={{ width: '100%', padding: '20px' }}>
                <Typography variant="h6" gutterBottom>
                    Shipment Tracking - Order No: {response.data.order_number},
                    Order Id: {response.data.order_id},
                    AWB No: {response.data.awb_number}
                </Typography>
                <Stepper activeStep={history.length} orientation="vertical">
                    {history.map((event, index) => (
                        <Step key={index}>
                            <StepLabel>
                                <Typography variant="body1">
                                    {event.message} ({event.status_code})
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    {event.location} - {event.event_time}
                                </Typography>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        );
    };


    return (
        <>
            <Card>
                <CardHeader title="Order Details" />
                <Box display="flex" style={{ marginTop: "-50px" }} justifyContent="flex-end" p={1}>

                    {Orders?.Order_Status == "ACCEPTED" ?
                        <Button
                            style={{ backgroundColor: '#27B6CC', margin: "1%" }}
                            variant="contained"
                            onClick={handleClickOpen}
                        >
                            <span style={{ color: 'white' }}>Ready for Shipping</span>
                        </Button> : <></>
                    }

                    {Orders?.Order_Status == "READY_FOR_SHIPPING" ?
                        <Button
                            style={{ backgroundColor: '#27B6CC', margin: "1%" }}
                            variant="contained"
                            onClick={handleOpen}
                        >
                            <span style={{ color: 'white' }}>SHIPPED</span>
                        </Button>
                        : <></>
                    }

                    {Orders?.Order_Status == "SHIPPED" ?
                        <Button
                            style={{ backgroundColor: '#27B6CC', margin: "1%" }}
                            variant="contained"
                            onClick={handleOpenDelivery}
                        >
                            <span style={{ color: 'white' }}>DELIVERED</span>
                        </Button> : <></>
                    }

                </Box>

                <Divider />

                <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                    {/* Order Information */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Order Information
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                                <Typography variant="body1" style={{ marginRight: '1rem' }}>ID: {Orders?.Order_Id}</Typography>
                                {Orders?.Exchange_Reference_Retail_Order_Id && (
                                    <Typography variant="body1" style={{ fontWeight: 'bold', color: 'orange' }}>EXCHANGED ORDER</Typography>
                                )}
                            </div>
                            <Typography variant="body2">
                                Status: <span style={{ fontWeight: 'bold' }}>{Orders?.Order_Status}</span>
                            </Typography>
                            <Typography variant="body2">User Email ID: {Orders?.Email_Id}</Typography>
                            <Typography variant="body2">Partner Detail ID: {Orders?.Partner_Detail_Id}</Typography>
                            <Typography variant="body2">Partner Name: {Orders?.Name}</Typography>

                            {Orders?.Partner_Product_Id &&
                                <Typography variant="body2">Partner Product Id: {Orders?.Partner_Product_Id}</Typography>}
                            {Orders?.Gift_History_Id &&
                                <Typography variant="body2">Gift History Id: {Orders?.Gift_History_Id}</Typography>}

                            {Orders?.Gift_Wrap_Message &&
                                <Typography variant="body2">Gift Wrap Msg: {Orders?.Gift_Wrap_Message}</Typography>}

                            <OrderStepper orderDates={orderDates} />

                        </Paper>
                    </Grid>

                    {/* Billing Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Billing Details
                            </Typography>
                            <Typography variant="body2">Address ID: {billAddress?.USER_ADDRESS_ID}</Typography>
                            <Typography variant="body2">Email ID: {billAddress?.USER_EMAIL_ID}</Typography>
                            <Typography variant="body2">Address Type: {billAddress?.ADDRESS_TYPE}</Typography>
                            <Typography variant="body2">Landmark: {billAddress?.LAND_MARK}</Typography>
                            <Typography variant="body2">City: {billAddress?.CITY}</Typography>
                            <Typography variant="body2">State: {billAddress?.STATE}</Typography>
                            <Typography variant="body2">Country: {billAddress?.COUNTRY}</Typography>
                            <Typography variant="body2">ZIP: {billAddress?.ZIP}</Typography>
                        </Paper>
                    </Grid>

                    {/* Payment Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Payment Details
                            </Typography>
                            {Orders?.Total_Order_Value &&
                                <Typography variant="body2">Total Order Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Total_Order_Value}</span></Typography>}
                            {Orders?.Total_Tax ? (
                                <Typography variant="body2">
                                    Total Tax Amount: <span style={{ fontWeight: 'bold' }}>{Orders.Total_Tax}</span>
                                </Typography>
                            ) : null}

                            <Typography variant="body2">VAT Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Vat}</span></Typography>
                            {Orders?.Payment_Type &&
                                <Typography variant="body2">Payment Method: <span style={{ fontWeight: 'bold' }}>{Orders?.Payment_Type}</span></Typography>}
                            {Orders?.Payment_Transaction_Id &&
                                <Typography variant="body2">Payment Reference ID: <span style={{ fontWeight: 'bold' }}>{Orders?.Payment_Transaction_Id}</span></Typography>}
                            <Typography variant="body2">Sgst: <span style={{ fontWeight: 'bold' }}>{Orders?.Sgst}</span></Typography>

                            {Orders?.Wallet_Amount ? (
                                <Typography variant="body2">Wallet Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Wallet_Amount}</span></Typography>
                            ) : null}

                            {Orders?.Wallet_History_Id &&
                                <Typography variant="body2">Wallet History Id: <span style={{ fontWeight: 'bold' }}>{Orders?.Wallet_History_Id}</span></Typography>}

                            {Orders?.Exchange_Reference_Retail_Order_Id ?
                                <span style={{ fontWeight: 'bold', color: 'green' }}>Payment Status : SUCCESS</span>
                                :
                                <Typography variant="body2" style={{ color: Orders?.Payment_Transaction_Status === "SUCCESS" ? 'green' : 'red' }}>
                                    Payment Status: <span style={{ fontWeight: 'bold' }}>{Orders?.Payment_Transaction_Status === "SUCCESS" ? "SUCCESS" : "FAIL"}</span>
                                </Typography>
                            }
                            {Orders?.Partner_Settlement_Amount &&
                                <Typography variant="body2">Partner Settlement Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Partner_Settlement_Amount}</span></Typography>}

                            {Orders?.Gift_Wrap_Amount > 0 ? (
                                <Typography variant="body2">Gift Wrap Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Gift_Wrap_Amount}</span></Typography>
                            ) : null}

                            {Orders?.Gift_Amount ?
                                (<Typography variant="body2">Gift Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Gift_Amount}</span></Typography>) : null}
                            {Orders?.Wallet_History_Id &&
                                <Typography variant="body2">Wallet History Id: <span style={{ fontWeight: 'bold' }}>{Orders?.Wallet_History_Id}</span></Typography>}
                            {/* {Orders?.Exchange_Reference_Retail_Order_Id ?
                                <span style={{ fontWeight: 'bold', color: 'green' }}>Payment Status : SUCCESS</span>
                                :
                                <Typography variant="body2" style={{ color: Orders?.Payment_Transaction_Status === "SUCCESS" ? 'green' : 'red' }}>
                                    Payment Status: <span style={{ fontWeight: 'bold' }}>{Orders?.Payment_Transaction_Status === "SUCCESS" ? "SUCCESS" : "FAIL"}</span>
                                </Typography>
                            } */}
                            <Typography variant="body2">Shipping Fee: <span style={{ fontWeight: 'bold' }}>{Orders?.Shipping_Amount}</span></Typography>
                            <Typography variant="body2">Cashback Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Cashback}</span></Typography>
                            {Orders?.Transaction_Id &&
                                <Typography variant="body2">Transaction Id: <span style={{ fontWeight: 'bold' }}>{Orders?.Transaction_Id}</span></Typography>}

                        </Paper>
                    </Grid>

                    {/* Shipping Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Shipping Details
                            </Typography>
                            <Typography variant="body2">Address ID: {shipAddress?.USER_ADDRESS_ID}</Typography>
                            <Typography variant="body2">Email ID: {shipAddress?.USER_EMAIL_ID}</Typography>
                            <Typography variant="body2">Address Type: {shipAddress?.ADDRESS_TYPE}</Typography>
                            <Typography variant="body2">Landmark: {shipAddress?.LAND_MARK}</Typography>
                            <Typography variant="body2">City: {shipAddress?.CITY}</Typography>
                            <Typography variant="body2">State: {shipAddress?.STATE}</Typography>
                            <Typography variant="body2">Country: {shipAddress?.COUNTRY}</Typography>
                            <Typography variant="body2">ZIP: {shipAddress?.ZIP}</Typography>
                        </Paper>
                    </Grid>

                    {/* Product Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Product Details
                            </Typography>
                            <Typography variant="body2">Product Name: <span style={{ fontWeight: 'bold' }}>{Orders.Product_Name}</span></Typography>
                            <Typography variant="body2">Quantity: <span style={{ fontWeight: 'bold' }}>{Orders.Quantity}</span></Typography>
                            <Typography variant="body2">Price Per Quantity: <span style={{ fontWeight: 'bold' }}>{Orders.Quantity_Selling_Price}</span></Typography>
                            <Typography variant="body2">Selling Price: <span style={{ fontWeight: 'bold' }}>{Orders.Base_Selling_Price}</span></Typography>
                            <Typography variant="body2">MRP: <span style={{ fontWeight: 'bold' }}>{Orders.Mrp}</span></Typography>
                            <Typography variant="body2">Size: <span style={{ fontWeight: 'bold' }}>{Orders.Size} {Orders.Size_Measurement}</span></Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <Divider />

                {/* for Order Status == "ACCEPTED" */}
                <Dialog open={open}>
                    <DialogTitle>Check Address and Confirm</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Partner Address: <Typography color={"#27b6cc"}> {partnerAdrs[0]?.Address_Type}, {partnerAdrs[0]?.Location}, {partnerAdrs[0]?.Street}, {partnerAdrs[0]?.City_Id}, {partnerAdrs[0]?.State}</Typography>
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="RTO & pickup address are different"
                        />
                        {isChecked &&
                            <FormControl fullWidth style={{ marginTop: '16px' }}>
                                <InputLabel id="address-select-label">Select Address</InputLabel>
                                <Select
                                    labelId="address-select-label"
                                    value={selectedAddress}
                                    onChange={handleSelectChange}
                                >
                                    {partnerAdrss.map((address) => (
                                        <MenuItem key={address.Partner_Address_Id} value={address.Partner_Address_Id}>
                                            {address.Address_Type}, {address.Location}, {address.City}, {address.State}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }

                        <Grid container spacing={2} style={{ marginTop: '16px' }}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Package Weight in Kgs"
                                    type="number"
                                    value={weight}
                                    onChange={(e) => setWeight(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Package Length in cms"
                                    type="number"
                                    value={length}
                                    onChange={(e) => setLength(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Package Breadth in cms"
                                    type="number"
                                    value={breadth}
                                    onChange={(e) => setBreadth(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Package Height in cms"
                                    type="number"
                                    value={height}
                                    onChange={(e) => setHeight(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} style={{ backgroundColor: 'gray', margin: "1%" }}>
                            Cancel
                        </Button>
                        <Button onClick={() => shippingOrder(order_Id)} disabled={loading}
                            style={{ backgroundColor: '#27B6CC', margin: "1%" }}>
                            {loading ? (
                                <CircularProgress size={24} style={{ color: 'white' }} />
                            ) : (
                                <span style={{ color: 'white' }}>Confirm</span>
                            )}
                            {/* <span style={{ color: 'white' }}> Confirm </span> */}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* for Order Status == "READY_FOR_SHIPPING" */}
                <Dialog open={openShip}>
                    <DialogTitle>Confirm Shipment</DialogTitle>
                    <DialogContent>
                        <div>Confirm that delivery agent have picked this order: {order_Id}?</div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} style={{ backgroundColor: 'gray', margin: "1%" }}>
                            Cancel
                        </Button>
                        <Button onClick={() => shipmentOrder(order_Id)} disabled={loading} style={{ backgroundColor: '#27B6CC', margin: "1%" }}>
                            {/* <span style={{ color: 'white' }}>Confirm Shipment </span> */}
                            {loading ? (
                                <CircularProgress size={24} style={{ color: 'white' }} />
                            ) : (
                                <span style={{ color: 'white' }}>Confirm Shipment</span>
                            )}
                        </Button>

                    </DialogActions>
                </Dialog>

                {/* for Order Status == "SHIPPED" */}
                <Dialog open={openDelivery}>
                    <DialogTitle>Confirm Shipment</DialogTitle>
                    <DialogContent>
                        <div>Are you sure that this order: {order_Id} is delivered?</div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} style={{ backgroundColor: 'gray', margin: "1%" }}>
                            Cancel
                        </Button>
                        <Button onClick={() => deliveredOrder(order_Id)} disabled={loading} style={{ backgroundColor: '#27B6CC', margin: "1%" }}>
                            {/* <span style={{ color: 'white' }}>Confirm Delivery</span> */}
                            {loading ? (
                                <CircularProgress size={24} style={{ color: 'white' }} />
                            ) : (
                                <span style={{ color: 'white' }}>Confirm Delivery</span>
                            )}
                        </Button>

                    </DialogActions>
                </Dialog>

                {Orders?.Order_Status == "SHIPPED" ?
                    <div style={{ marginTop: '20px' }}>
                        <ShipmentStepper response={orderStatusTrack} />
                    </div> : <></>
                }
            </Card>
        </>
    )
}

export default EditInPrgsOrderStatusHistory;
