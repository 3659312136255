import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../store/validations/is-empty';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import SimpleDialog from '../../components/dialogs/dialog';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  images: {
    width: 100,
    height: 50
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const Results = ({
  className,
  allFaqs,
  order,
  orderBy,
  pagination,
  setPagination,
  createSortHandler,
  deleteFaqHandler,
  allFaqsNoFilter,
  handleLimitChange,
  handlePageChange,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedFaqIds, setSelectedFaqIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const handleClose = (value) => {
    setOpen(false);
  };
  const navigate = useNavigate();

  const headCells = [
    { id: 'Feq_Asked_Quest_Id', numeric: false, disablePadding: true, label: "FAQ ID" },
    { id: 'Question_Posted_By', numeric: false, disablePadding: false, label: 'Posted By' },
    { id: 'Question', numeric: true, disablePadding: false, label: 'Question' },
    { id: 'Module_Type', numeric: false, disablePadding: false, label: 'Type' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
  ];

  /*===============================
             handler     
  =================================*/
  const handleSelectAll = event => {
    let newSelectedFaqIds;
    if (event.target.checked) {
      newSelectedFaqIds = allFaqs.map(faq => faq.Feq_Asked_Quest_Id);
    } else {
      newSelectedFaqIds = [];
    }
    setSelectedFaqIds(newSelectedFaqIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedFaqIds.indexOf(id);
    let newselectedFaqIds = [];
    if (selectedIndex === -1) {
      newselectedFaqIds = newselectedFaqIds.concat(selectedFaqIds, id);
    } else if (selectedIndex === 0) {
      newselectedFaqIds = newselectedFaqIds.concat(
        selectedFaqIds.slice(1)
      );
    } else if (selectedIndex > 0) {
      newselectedFaqIds = newselectedFaqIds.concat(
        selectedFaqIds.slice(0, selectedIndex),
        selectedFaqIds.slice(selectedIndex + 1)
      );
    }
    setSelectedFaqIds(newselectedFaqIds);
  };

  const editFaqHandler = faqData => e => {
    navigate('/app/edit-faq', { state: faqData });
  };

  const deleteSelectedFaqHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is coming soon...");
  };

  /*=================================
              handler end
  =================================*/
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedFaqIds.filter(record => {
                      for (let i = 0; i < allFaqsNoFilter.length; i++) {
                        if (record == allFaqsNoFilter[i].Feq_Asked_Quest_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allFaqs.length}
                    color="primary"
                    indeterminate={
                      selectedFaqIds.filter(record => {
                        for (let i = 0; i < allFaqsNoFilter.length; i++) {
                          if (record == allFaqsNoFilter[i].Feq_Asked_Quest_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedFaqIds.filter(record => {
                        for (let i = 0; i < allFaqsNoFilter.length; i++) {
                          if (record == allFaqsNoFilter[i].Feq_Asked_Quest_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allFaqs.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.id !== "Question" ? (
                      <TableSortLabel style={{ fontSize: "13px", fontWeight: "bold" }}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>) : (<span style={{ fontSize: "13px", fontWeight: "bold" }} > {headCell.label}</span>)
                    }

                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions &nbsp;&nbsp;
                  {selectedFaqIds.filter(record => {
                    for (let i = 0; i < allFaqsNoFilter.length; i++) {
                      if (record == allFaqsNoFilter[i].Feq_Asked_Quest_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedFaqIds.filter(record => {
                    for (let i = 0; i < allFaqsNoFilter.length; i++) {
                      if (record == allFaqsNoFilter[i].Feq_Asked_Quest_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0}
                    onClick={deleteSelectedFaqHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allFaqs) &&
                (allFaqs).map(faq => (
                  <TableRow
                    hover
                    key={faq.Feq_Asked_Quest_Id}
                    selected={selectedFaqIds.indexOf(faq.Feq_Asked_Quest_Id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedFaqIds.indexOf(faq.Feq_Asked_Quest_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, faq.Feq_Asked_Quest_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell width="10%">{faq.Feq_Asked_Quest_Id}</TableCell>
                    <TableCell style={{ color: '#1665D8' }} >
                      {faq.Question_Posted_By?.substring(0, 15)}...
                    </TableCell>
                    <TableCell ><div dangerouslySetInnerHTML={{ __html: faq.Question?.substring(0, 30) }}></div></TableCell>
                    <TableCell>{faq.Module_Type}</TableCell>
                    <TableCell style={{ color: '#1665D8' }}>
                      {faq.Status}
                    </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.FAQ_Management_Write?.Value === "FAQ_Management_Write" && RolePermissions?.FAQ_Management_Write?.Is_Write === 1) ?
                      <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                          onClick={editFaqHandler(faq)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                          <DeleteIcon
                            onClick={deleteFaqHandler(faq)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                      : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allFaqs) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;