import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    SvgIcon,
    makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    formFilterControl: {
        margin: theme.spacing(1),
        minWidth: 200
    }
}));

const Toolbar = ({ className, filters, setfilters, RolePermissions, roleUserType, ...rest }) => {
    const classes = useStyles();

    const handleChange = event => {
        setfilters({
            Search: filters?.Search,
            Search_By_Filter: event.target.value
        })
    };

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                alignItems="center"
                mb={2}
            >
                <Box display="flex" flexWrap="wrap" mb={2}>
                    <FormControl variant="outlined" className={classes.formFilterControl}>
                        <Select
                            value={filters.Search_By_Filter}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="id">Corp Popup ID</MenuItem>
                            <MenuItem value="Page_Name">Page Name</MenuItem>
                            <MenuItem value="Status"> Status</MenuItem>
                        </Select>
                    </FormControl>
                    <Box width={500}>
                        <TextField
                            onChange={(event) => {
                                setfilters({
                                    Search: event.target.value,
                                    Search_By_Filter: filters.Search_By_Filter
                                })
                            }}
                            className={classes.formControl}
                            value={filters?.Search}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon fontSize="small" color="action">
                                            <SearchIcon />
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}
                            placeholder="Search popup"
                            variant="outlined"
                        />
                    </Box>
                </Box>
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                    (RolePermissions?.Corporate_Popup_Write?.Value === "Corporate_Popup_Write" && RolePermissions?.Corporate_Popup_Write?.Is_Write === 1) ?
                    <Button
                        component={RouterLink}
                        to="/app/add-corporate-popup"
                        color="primary"
                        variant="contained"
                        style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                    >
                        <span style={{ color: "white" }}>
                            + Add Popup
                        </span>
                    </Button>
                    : null}
            </Box>
        </div >
    );
};

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;