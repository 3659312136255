import {
    RIDE_VEHI_TYPE_REQUEST,
    RIDE_VEHI_TYPE_SUCCESS,
    RIDE_VEHI_TYPE_FAIL,

    RIDE_VEHI_TYPE_CREATE_REQUEST,
    RIDE_VEHI_TYPE_CREATE_SUCCESS,
    RIDE_VEHI_TYPE_CREATE_FAIL,

    RIDE_VEHI_TYPE_UPDATE_REQUEST,
    RIDE_VEHI_TYPE_UPDATE_SUCCESS,
    RIDE_VEHI_TYPE_UPDATE_FAIL,
} from '../actions/ridevehicletypesAction'

const initialState = {
    ridevehitypes: { all: [], error: '', isLoading: false },
    ridevehitypeCreate: { ridevehitype: {}, error: '', isLoading: false },
    ridevehitypeUpdate: { ridevehitype: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case RIDE_VEHI_TYPE_REQUEST:
            return { ...state, ridevehitypes: { all: state.ridevehitypes.all, error: '', isLoading: true } };
        case RIDE_VEHI_TYPE_SUCCESS:
            return { ...state, ridevehitypes: { all: action.payload, error: '', isLoading: false } };
        case RIDE_VEHI_TYPE_FAIL:
            return { ...state, ridevehitypes: { all: [], error: action.payload, isLoading: false } };

        case RIDE_VEHI_TYPE_CREATE_REQUEST:
            return { ...state, ridevehitypeCreate: { ridevehitype: {}, error: '', isLoading: true } };
        case RIDE_VEHI_TYPE_CREATE_SUCCESS:
            return { ...state, ridevehitypeCreate: { ridevehitype: state.ridevehitypeCreate.ridevehitype, error: '', isLoading: false } };
        case RIDE_VEHI_TYPE_CREATE_FAIL:
            return { ...state, ridevehitypeCreate: { ridevehitype: {}, error: action.payload, isLoading: false } };

        case RIDE_VEHI_TYPE_UPDATE_REQUEST:
            return { ...state, ridevehitypeUpdate: { ridevehitype: {}, error: '', isLoading: true } };
        case RIDE_VEHI_TYPE_UPDATE_SUCCESS:
            return { ...state, ridevehitypeUpdate: { ridevehitype: state.ridevehitypeUpdate.ridevehitype, error: '', isLoading: false } };
        case RIDE_VEHI_TYPE_UPDATE_FAIL:
            return { ...state, ridevehitypeUpdate: { ridevehitype: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}