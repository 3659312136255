import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel, Checkbox
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import isEmpty from '../../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'src/components/pagination/pagination';
import SimpleDialog from '../../../components/dialogs/dialog';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Zone_Theme_Product_Id', numeric: false, disablePadding: true, label: 'Zone Theme Product ID' },
  { id: 'Zone_Theme_Name', numeric: false, disablePadding: false, label: 'Zone Theme Name' },
  { id: 'Product_Name', numeric: false, disablePadding: false, label: 'Product Name' },
  { id: 'Is_Active', numeric: false, disablePadding: false, label: 'Is Active' },
];

const Results = ({
  className,
  allZoneThemeProducts,
  deleteZoneThemeProductHandler,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allZoneThemeProductsNoFilter,
  pagination,
  setPagination,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedzonethemeproductsIds, setSelectedZoneThemeProductsIds] = useState([]);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedZoneThemeProductIds;
    if (event.target.checked) {
      newSelectedZoneThemeProductIds = allZoneThemeProducts.map(zonethemeproducts => zonethemeproducts.Zone_Theme_Product_Id);
    } else {
      newSelectedZoneThemeProductIds = [];
    }
    setSelectedZoneThemeProductsIds(newSelectedZoneThemeProductIds);
  };

  const handleSelectOne = (event, id) => {
    setSelectedZoneThemeProductsIds(selectedzonethemeproductsIds.filter(record => {
      for (let i = 0; i < allZoneThemeProductsNoFilter.length; i++) {
        if (record === allZoneThemeProductsNoFilter[i].Zone_Theme_Product_Id) {
          return true;
        }
      }
      return false;
    }))
    const selectedIndex = selectedzonethemeproductsIds.indexOf(id);
    let newSelectedZoneThemeProductIds = [];
    if (selectedIndex === -1) {
      newSelectedZoneThemeProductIds = newSelectedZoneThemeProductIds.concat(selectedzonethemeproductsIds, id);
    } else if (selectedIndex === 0) {
      newSelectedZoneThemeProductIds = newSelectedZoneThemeProductIds.concat(
        selectedzonethemeproductsIds.slice(1)
      );
    } else if (selectedIndex === selectedzonethemeproductsIds.length - 1) {
      newSelectedZoneThemeProductIds = newSelectedZoneThemeProductIds.concat(
        selectedzonethemeproductsIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedZoneThemeProductIds = newSelectedZoneThemeProductIds.concat(
        selectedzonethemeproductsIds.slice(0, selectedIndex),
        selectedzonethemeproductsIds.slice(selectedIndex + 1)
      );
    }
    setSelectedZoneThemeProductsIds(newSelectedZoneThemeProductIds);
  };

  const deleteMeepaisaZoneThemeProductHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const EditZoneThemeProductHandler = zonethemeproductData => e => {
    navigate('/app/edit-zone-theme-products', { state: zonethemeproductData });
  };
  const ViewZoneThemeProductHandler = zonethemeproductData => e => {
    navigate('/app/view-zone-theme-products', { state: zonethemeproductData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedzonethemeproductsIds.filter(record => {
                      for (let i = 0; i < allZoneThemeProductsNoFilter.length; i++) {
                        if (record === allZoneThemeProductsNoFilter[i].Zone_Theme_Product_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allZoneThemeProducts?.length}
                    color="primary"
                    indeterminate={
                      selectedzonethemeproductsIds.filter(record => {
                        for (let i = 0; i < allZoneThemeProductsNoFilter.length; i++) {
                          if (record === allZoneThemeProductsNoFilter[i].Zone_Theme_Product_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedzonethemeproductsIds.filter(record => {
                        for (let i = 0; i < allZoneThemeProductsNoFilter.length; i++) {
                          if (record === allZoneThemeProductsNoFilter[i].Zone_Theme_Product_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allZoneThemeProducts.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-120px' }}>Actions
                  {selectedzonethemeproductsIds.filter(record => {
                    for (let i = 0; i < allZoneThemeProductsNoFilter.length; i++) {
                      if (record === allZoneThemeProductsNoFilter[i].Zone_Theme_Product_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedzonethemeproductsIds.filter(record => {
                    for (let i = 0; i < allZoneThemeProductsNoFilter.length; i++) {
                      if (record === allZoneThemeProductsNoFilter[i].Zone_Theme_Product_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteMeepaisaZoneThemeProductHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allZoneThemeProducts) &&
                (allZoneThemeProducts).map(zonethemeproducts => (
                  <TableRow
                    hover
                    key={zonethemeproducts?.Zone_Theme_Product_Id}
                    selected={
                      selectedzonethemeproductsIds.indexOf(zonethemeproducts.Zone_Theme_Product_Id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedzonethemeproductsIds.indexOf(zonethemeproducts?.Zone_Theme_Product_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, zonethemeproducts?.Zone_Theme_Product_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell >{zonethemeproducts?.Zone_Theme_Product_Id}</TableCell>
                    <TableCell >{zonethemeproducts?.Zone_Theme_Name}</TableCell>
                    <TableCell> {zonethemeproducts.Product_Name?.substring(0, 20)}...</TableCell>
                    <TableCell >{zonethemeproducts?.Is_Active}</TableCell>

                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Zone_Theme_Product_Management_Write?.Value === "Zone_Theme_Product_Management_Write" && RolePermissions?.Zone_Theme_Product_Management_Write?.Is_Write === 1) ? <TableCell>
                      <Button
                        onClick={EditZoneThemeProductHandler(zonethemeproducts)}>
                        <CreateIcon style={{ color: '#9EA0A5' }} />
                      </Button>
                      <Button>
                        <DeleteIcon
                          onClick={deleteZoneThemeProductHandler(zonethemeproducts)}
                          style={{ color: '#9EA0A5' }}
                        />
                      </Button>

                      <Tooltip title="Theme Details">
                        <Button
                          onClick={ViewZoneThemeProductHandler(zonethemeproducts)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                      : null}
                    {RolePermissions?.Zone_Theme_Product_Management_Read?.Value === "Zone_Theme_Product_Management_Read" && RolePermissions?.Zone_Theme_Product_Management_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="Theme Details">
                          <Button
                            onClick={ViewZoneThemeProductHandler(zonethemeproducts)}
                            color="primary" >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allZoneThemeProducts) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allZoneThemeProducts: PropTypes.array.isRequired
};

export default Results;