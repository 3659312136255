import {
  RPRODUCTS_REQUEST,
  RPRODUCTS_SUCCESS,
  RPRODUCTS_FAIL,

  RELATED_PRODUCT_CREATE_REQUEST,
  RELATED_PRODUCT_CREATE_SUCCESS,
  RELATED_PRODUCT_CREATE_FAIL,
  RELATED_PRODUCT_UPDATE_REQUEST,
  RELATED_PRODUCT_UPDATE_SUCCESS,
  RELATED_PRODUCT_UPDATE_FAIL,
  PRODUCTS_FILTER_REQUEST,
  PRODUCTS_FILTER_SUCCESS,
  PRODUCTS_FILTER_FAIL,
} from '../actions/relatedProductAction'
const initialState = {
  rproducts: { all: [], error: '', isLoading: false },
  relatedproductscreate: { partner: {}, error: '', isLoading: false },
  relatedproductUpdate: { partner: {}, error: '', isLoading: false },
  productfilters: { filterproducts: [], error: '', isLoading: false },

};
export default function (state = initialState, action) {
  switch (action.type) {
    case RELATED_PRODUCT_UPDATE_REQUEST:
      return { ...state, relatedproductUpdate: { partner: {}, error: '', isLoading: true } };
    case RELATED_PRODUCT_UPDATE_SUCCESS:
      return { ...state, relatedproductUpdate: { partner: state.relatedproductUpdate.partner, error: '', isLoading: false } };
    case RELATED_PRODUCT_UPDATE_FAIL:
      return { ...state, relatedproductUpdate: { partner: {}, error: action.payload, isLoading: false } };

    case RPRODUCTS_REQUEST:
      return { ...state, rproducts: { all: state.rproducts.all, error: '', isLoading: true } };
    case RPRODUCTS_SUCCESS:
      return { ...state, rproducts: { all: action.payload, error: '', isLoading: false } };
    case RPRODUCTS_FAIL:
      return { ...state, rproducts: { all: [], error: action.payload, isLoading: false } };
    case RELATED_PRODUCT_CREATE_REQUEST:
      return { ...state, relatedproductscreate: { all: state.relatedproductscreate.all, error: '', isLoading: true } };
    case RELATED_PRODUCT_CREATE_SUCCESS:
      return { ...state, relatedproductscreate: { all: action.payload, error: '', isLoading: false } };
    case RELATED_PRODUCT_CREATE_FAIL:
      return { ...state, relatedproductscreate: { all: [], error: action.payload, isLoading: false } };
    case PRODUCTS_FILTER_REQUEST:
      return { ...state, productfilters: { filterproducts: state.productfilters.productfilters, error: '', isLoading: true }, };
    case PRODUCTS_FILTER_SUCCESS:
      return { ...state, productfilters: { filterproducts: action.payload, error: '', isLoading: false } };
    case PRODUCTS_FILTER_FAIL:
      return { ...state, productfilters: { filterproducts: [], error: action.payload, isLoading: false } };
    default:
      return state;
  }
}