import React, { useState, useEffect } from 'react';
import { Link as RouterLink,useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllSubscriptionUserProductAction,
  SubscriptionUserProductFilter
} from '../../store/actions/subscriptionuserproductAction';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SubscriptionProductsUsersListView = () => {
  const location = useLocation();
   let { state } = location;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = event => {
    setLimit(+event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // componentDidMount
  useEffect(() => {
    const formData = {
      Records_Filter: "FILTER",
      Subscription_User_Auto_Id:state?.Subscription_User_Auto_Id,
    };
    dispatch(getAllSubscriptionUserProductAction(formData));
    dispatch(SubscriptionUserProductFilter('search', ''));
    dispatch(SubscriptionUserProductFilter('select', 'All'));
  }, []);

  let subscriptionproductMenu = useSelector(state => state.subscriptionproduct.subscriptionproductFilter.select);
  let subscriptionproductSearch = useSelector(state => state.subscriptionproduct.subscriptionproductFilter.search);

  //static getDerivedStateFromProps

  const allSubscriptionuserproducts = useSelector(state => state.subscriptionproduct.subscriptionproducts.all);
  const [oldSearch, setOldSearch] = useState(0);

  const checkSubscriptionUserProductForTable = () => {
   if (subscriptionproductSearch) {
      try {
        if (subscriptionproductMenu === "All") {
          return allSubscriptionuserproducts.filter(x => {
            let colummns = ["Subscription_User_Products_Id", "Product_Id", "Partner_Product_Id", "Partner_Details_Id","Quantity" ];
            for (let i = 0; i < colummns.length; i++) {
              const mainString = typeof x[colummns[i]] === "string" ? x[colummns[i]]?.toLowerCase() : x[colummns[i]];
              if (typeof x[colummns[i]] === "string") {
                if (mainString.includes(subscriptionproductSearch?.toLowerCase())) {
                  return true;
                }
              }
              else {
                if (mainString === subscriptionproductSearch) {
                  return true;
                }
              }
            }
            return false;
          });

        } else {
          return allSubscriptionuserproducts.filter(x => {
            let mainString = typeof x[subscriptionproductMenu] == "string" ? x[subscriptionproductMenu]?.toLowerCase() : x[subscriptionproductMenu];
            let subString = typeof x[subscriptionproductMenu] == "string" ? subscriptionproductSearch?.toLowerCase() : subscriptionproductSearch;
            if (typeof x[subscriptionproductMenu] === "string") {
              return mainString.includes(subString);
            }
            else {
              if (mainString === subscriptionproductSearch) {
                return true;
              }
            }
          })
        }
      } finally {
        if (page > 0) {
          if (oldSearch !== subscriptionproductSearch.length) {
            setOldSearch(subscriptionproductSearch.length);
            setPage(0);
          }
        }
        else if (oldSearch !== subscriptionproductSearch.length) {
          setOldSearch(subscriptionproductSearch.length);
          setPage(0);
        }
      }
    }
    else {
      return allSubscriptionuserproducts
    }
  }

  // Sorting 
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Subscription_User_Products_Id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  function descendingComparator(a, b, orderBy) {
    try {
      if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
        return -1;
      }
      if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
        return 1;
      }
      return 0;
    } catch (error) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }

  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {

      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <Page className={classes.root} title="ESI Admin Panel">
      <Container maxWidth={false}>
        <Button
          component={RouterLink}
          to="/app/subscription-management"
          variant="text"
          size="small"
          color="inherit"
        >
          <KeyboardBackspaceIcon />
          Go back
        </Button>
        <Box py={2}>
          <Typography variant="h2" gutterBottom>
            Subscription User Products Details
          </Typography>
        </Box>

        <Toolbar state={state} />
        <Box mt={3}>
          <Results
            allSubscriptionuserproducts={stableSort(checkSubscriptionUserProductForTable(), getComparator(order, orderBy))}
            allSubscriptionuserproductsNoFilter={allSubscriptionuserproducts}
            page={page}
            limit={limit}
            handleLimitChange={handleLimitChange}
            handlePageChange={handlePageChange}
            setPage={setPage}
            order={order}
            orderBy={orderBy}
            createSortHandler={createSortHandler}
          />
        </Box>
      </Container>
    </Page>
  );
};


export default SubscriptionProductsUsersListView;