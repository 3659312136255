
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import isEmpty from '../../store/validations/is-empty';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Product_Id', numeric: false, disablePadding: true, label: 'Product ID' },
  { id: 'Product_Name', numeric: false, disablePadding: false, label: 'Product Name' },
  { id: 'Esi_Reviews', numeric: false, disablePadding: false, label: 'ESI Reviews' },
  { id: 'Esi_Rating', numeric: false, disablePadding: false, label: 'ESI Rating' },
];

const Results = ({
  className,
  allProducts,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  const OpenUserReviewHandler = ProductData => e => {
    navigate('/app/product/user-reviews', { state: ProductData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center'
                }} >Reviews  &nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allProducts) &&
                (allProducts).map(product => (
                  <TableRow
                    hover
                    key={product.Product_Id}
                    selected={
                      selectedProductIds.indexOf(product.Product_Id) !== -1
                    }
                  >
                    <TableCell width="15%">{product.Product_Id}</TableCell>
                    <TableCell width="15%" >{product.Product_Name?.substring(0, 15)}</TableCell>
                    <TableCell width="40%"><div dangerouslySetInnerHTML={{ __html: product.Esi_Reviews?.substring(0, 60) }} ></div></TableCell>
                    <TableCell width="15%" > &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{product.Esi_Rating}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Product_Reviews_Ratings_Write?.Value === "Product_Reviews_Ratings_Write" && RolePermissions?.Product_Reviews_Ratings_Write?.Is_Write === 1) ?
                      <TableCell style={{ textAlign: "center", }}>
                        <Button
                          onClick={OpenUserReviewHandler(product)}
                          style={{ color: '#1665D8', fontSize: 13, textAlign: "right", }}
                        >User Reviews
                          {product.reviews}
                        </Button>
                      </TableCell>
                      : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allProducts) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allProducts: PropTypes.array.isRequired
};

export default Results;