import config from './config';
import { toast } from 'react-toastify';
import { paginationAllB2BUserProductsBidGet } from './node-actions/nodepagination';
import { boomiAdminApi, nodeCudApi, nodeGetApi } from './commonAxios';

export const B2B_USER_PROD_BID_CREATE_REQUEST = "B2B_USER_PROD_BID_CREATE_REQUEST";
export const B2B_USER_PROD_BID_CREATE_SUCCESS = "B2B_USER_PROD_BID_CREATE_SUCCESS";
export const B2B_USER_PROD_BID_CREATE_FAIL = "B2B_USER_PROD_BID_CREATE_FAIL";

export const B2B_USER_PROD_BID_UPDATE_REQUEST = "B2B_USER_PROD_BID_UPDATE_REQUEST";
export const B2B_USER_PROD_BID_UPDATE_SUCCESS = "B2B_USER_PROD_BID_UPDATE_SUCCESS";
export const B2B_USER_PROD_BID_UPDATE_FAIL = "B2B_USER_PROD_BID_UPDATE_FAIL";

export const B2B_PARTNER_DETAILS_GET_REQUEST = "B2B_PARTNER_DETAILS_GET_REQUEST";
export const B2B_PARTNER_DETAILS_GET_SUCCESS = "B2B_PARTNER_DETAILS_GET_SUCCESS";
export const B2B_PARTNER_DETAILS_GET_FAIL = "B2B_PARTNER_DETAILS_GET_FAIL";

export const B2B_BIDDING_PRODUCTS_GET_REQUEST = "B2B_BIDDING_PRODUCTS_GET_REQUEST";
export const B2B_BIDDING_PRODUCTS_GET_SUCCESS = "B2B_BIDDING_PRODUCTS_GET_SUCCESS";
export const B2B_BIDDING_PRODUCTS_GET_FAIL = "B2B_BIDDING_PRODUCTS_GET_FAIL";

export const B2B_BID_QUOTES_GET_REQUEST = "B2B_BID_QUOTES_GET_REQUEST";
export const B2B_BID_QUOTES_GET_SUCCESS = "B2B_BID_QUOTES_GET_SUCCESS";
export const B2B_BID_QUOTES_GET_FAIL = "B2B_BID_QUOTES_GET_FAIL";

export const B2B_BID_PARTNER_PAYMENT_REQUEST = "B2B_BID_PARTNER_PAYMENT_REQUEST";
export const B2B_BID_PARTNER_PAYMENT_SUCCESS = "B2B_BID_PARTNER_PAYMENT_SUCCESS";
export const B2B_BID_PARTNER_PAYMENT_FAIL = "B2B_BID_PARTNER_PAYMENT_FAIL";

//create B2B User Products Bidding Action
export const createB2BUserProductsBidding = (formData, navigate) => async dispatch => {
    dispatch({
        type: B2B_USER_PROD_BID_CREATE_REQUEST
    });
    nodeCudApi.post(`/B2B_User_Products/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: B2B_USER_PROD_BID_CREATE_SUCCESS
                });
                toast('B2B User Products Bidding created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/b2b-user-products-bidding');
            } else {
                dispatch({
                    type: B2B_USER_PROD_BID_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: B2B_USER_PROD_BID_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

//update B2B User Products Bidding Action
export const updateB2BUserProductsBidding = (formData, navigate) => async dispatch => {
    dispatch({
        type: B2B_USER_PROD_BID_UPDATE_REQUEST
    });
    boomiAdminApi.put(`/B2B_User_Products/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: B2B_USER_PROD_BID_UPDATE_SUCCESS
                });
                toast('B2B User Products Bidding updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/b2b-user-products-bidding');
            } else {

                dispatch({
                    type: B2B_USER_PROD_BID_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: B2B_USER_PROD_BID_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//get all Bidding Partner Details
export const getAllBiddingPartnerDetails = formData => async dispatch => {
    try {
        dispatch({
            type: B2B_PARTNER_DETAILS_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Partners/Physical_Partner_Profile_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: B2B_PARTNER_DETAILS_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: B2B_PARTNER_DETAILS_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: B2B_PARTNER_DETAILS_GET_FAIL,
            payload: err
        });
    }
};

//get all Bidding Products
export const getAllB2BBiddingProducts = formData => async dispatch => {
    try {
        dispatch({
            type: B2B_BIDDING_PRODUCTS_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Super_Admin/Bidding_Products?PageNo=1&PageSize=${config.pageSize}&SortBy=Bidding_Product_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: B2B_BIDDING_PRODUCTS_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: B2B_BIDDING_PRODUCTS_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: B2B_BIDDING_PRODUCTS_GET_FAIL,
            payload: err
        });
    }
};

//get all B2B Bidding User Address
export const getAllB2BBidUserAddress = (formData, callBackData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/Partners/Partner_Address_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Partner_Address_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.Is_Data_Exist === "0") {
                callBackData([])
            } else {
                callBackData(data.results)
            }
        }
    } catch (err) {
        dispatch({
            payload: err
        });
    }
};

//get all B2B product Bidding Quotes
export const getAllB2BProdBiddingQuotes = formData => async dispatch => {
    try {
        dispatch({
            type: B2B_BID_QUOTES_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/B2B/Bidding_Quotes?PageNo=1&PageSize=${config.pageSize}&SortBy=B2B_Partner_Bidding_Quote_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: B2B_BID_QUOTES_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: B2B_BID_QUOTES_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: B2B_BID_QUOTES_GET_FAIL,
            payload: err
        });
    }
};

//get measurement types
export const getAllbidprodmeasuretypes = (formData, callBackMeasureTypesData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/Super_Admin/Bidding_Products?PageNo=1&PageSize=${config.pageSize}&SortBy=BIDDING_PRODUCT_ID&SortOrder=ASC`, formData);
        if (data) {
            if (data.Is_Data_Exist === "0") {
                callBackMeasureTypesData([])
            } else {
                callBackMeasureTypesData(data.results)
            }
        }
    } catch (err) {
        dispatch({
            payload: err
        });
    }
};

// Alert Notification for B2B Product Partners Action
export const AlertB2BProductPartnersAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
    try {
        let { data } = await boomiAdminApi.post(`/Notifications/Products`, formData)
        if (data) {
            dispatch(paginationAllB2BUserProductsBidGet(filters, pagination, callBackPagination));
            toast('Notification for Partners Triggered successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        }
    } catch (err) {
    }
};

//b2b product bidding partner payments
export const createB2BProdBidPartnerPaymentAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: B2B_BID_PARTNER_PAYMENT_REQUEST
    });
    boomiAdminApi.post(`/B2B_User_Products/Payment_Settlement`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: B2B_BID_PARTNER_PAYMENT_SUCCESS
                });
                toast('Payment created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/b2b-user-products-bidding');
            } else {
                dispatch({
                    type: B2B_BID_PARTNER_PAYMENT_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: B2B_BID_PARTNER_PAYMENT_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

//Get B2B User Products Bidding Action
export const getB2BBiddingUserProductsAction = (formData, callBackB2BBiddingProducts) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/B2B_User_Products_Bidding/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=B2B_User_Products_Bidding_Id&SortOrder=ASC`, formData);
        if (data) {
            callBackB2BBiddingProducts(data)
        }
    } catch (err) {
    }
};