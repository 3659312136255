import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import isEmpty from '../../../store/validations/is-empty';
import SimpleDialog from '../../../components/dialogs/dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Partner_Service_City_Products_Id', numeric: false, disablePadding: false, label: 'Product ID' },
  { id: 'Product_Name', numeric: false, disablePadding: false, label: 'Product Name' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allProducts,
  deleteProductHandler,
  page,
  limit,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  setPage,
  createSortHandler,
  allProductsNoFilter,
  state,
  pagination,
  setPagination,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedProductIds;
    if (event.target.checked) {
      newSelectedProductIds = allProducts.map(product => product.Partner_Service_City_Products_Id);
    } else {
      newSelectedProductIds = [];
    }
    setSelectedProductIds(newSelectedProductIds);
  };

  const handleSelectOne = (event, id) => {
    setSelectedProductIds(selectedProductIds.filter(record => {
      for (let i = 0; i < allProductsNoFilter.length; i++) {
        if (record == allProductsNoFilter[i].Partner_Service_City_Products_Id) {
          return true;
        }
      }
      return false;
    }))
    const selectedIndex = selectedProductIds.indexOf(id);
    let newSelectedProductIds = [];
    if (selectedIndex === -1) {
      newSelectedProductIds = newSelectedProductIds.concat(selectedProductIds, id);
    } else if (selectedIndex === 0) {
      newSelectedProductIds = newSelectedProductIds.concat(
        selectedProductIds.slice(1)
      );
    } else if (selectedIndex === selectedProductIds.length - 1) {
      newSelectedProductIds = newSelectedProductIds.concat(
        selectedProductIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedProductIds = newSelectedProductIds.concat(
        selectedProductIds.slice(0, selectedIndex),
        selectedProductIds.slice(selectedIndex + 1)
      );
    }
    setSelectedProductIds(newSelectedProductIds);
  };

  const deleteSelectedProductHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const editProductHandler = productData => e => {
    navigate('/app/sbcm/edit-product', { state: { state: state, data: productData } });
  };
  const viewProductHandler = productData => e => {
    navigate('/app/view-partnercityproduct', { state: { state: state, data: productData } });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedProductIds.filter(record => {
                      for (let i = 0; i < allProductsNoFilter.length; i++) {
                        if (record == allProductsNoFilter[i].Partner_Service_City_Products_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allProducts.length}
                    color="primary"
                    indeterminate={
                      selectedProductIds.filter(record => {
                        for (let i = 0; i < allProductsNoFilter.length; i++) {
                          if (record == allProductsNoFilter[i].Partner_Service_City_Products_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedProductIds.filter(record => {
                        for (let i = 0; i < allProductsNoFilter.length; i++) {
                          if (record == allProductsNoFilter[i].Product_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allProducts.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Actions&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedProductIds.filter(record => {
                    for (let i = 0; i < allProductsNoFilter.length; i++) {
                      if (record == allProductsNoFilter[i].Partner_Service_City_Products_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedProductIds.filter(record => {
                    for (let i = 0; i < allProductsNoFilter.length; i++) {
                      if (record == allProductsNoFilter[i].Partner_Service_City_Products_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedProductHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allProducts) &&
                (allProducts).map(product => (
                  <TableRow
                    hover
                    key={product.Partner_Service_City_Products_Id}
                    selected={
                      selectedProductIds.indexOf(product.Partner_Service_City_Products_Id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedProductIds.indexOf(product.Partner_Service_City_Products_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, product.Partner_Service_City_Products_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell width="15%">{product.Partner_Service_City_Products_Id}</TableCell>
                    <TableCell style={{ overflowX: "hidden" }} ><span title={product.Product_Name}>{product.Product_Name?.length > 70 ? product.Product_Name?.substring(0, 70) : product.Product_Name}</span></TableCell>
                    <TableCell width="10%" style={{ color: '#1665D8' }}>
                      {product.Status}
                    </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.City_Product_Management_Write?.Value === "City_Product_Management_Write" && RolePermissions?.City_Product_Management_Write?.Is_Write === 1) ?
                      <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        &nbsp;&nbsp;&nbsp;<Button
                          onClick={editProductHandler(product)}                      >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                          <DeleteIcon
                            onClick={deleteProductHandler(product)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                        <Button
                          onClick={viewProductHandler(product)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.City_Product_Management_Read?.Value === "City_Product_Management_Read" && RolePermissions?.City_Product_Management_Read?.Is_Read === 1 &&
                      <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                          onClick={viewProductHandler(product)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allProducts) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}

      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allProducts: PropTypes.array.isRequired
};

export default Results;