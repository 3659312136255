
import { toast } from 'react-toastify';
import config from './config';
import { paginationPendingProductPartners, paginationProductPartners } from './node-actions/nodepagination';
import {  nodeGetApi,boomiAdminApi } from './commonAxios';

export const PARTNERS_REQUEST = "PARTNERS_REQUEST";
export const PARTNERS_SUCCESS = "PARTNERS_SUCCESS";
export const PARTNERS_FAIL = "PARTNERS_FAIL";

export const PARTNER_CREATE_REQUEST = "PARTNER_CREATE_REQUEST";
export const PARTNER_CREATE_SUCCESS = "PARTNER_CREATE_SUCCESS";
export const PARTNER_CREATE_FAIL = "PARTNER_CREATE_FAIL";

export const PARTNER_UPDATE_REQUEST = "PARTNER_UPDATE_REQUEST";
export const PARTNER_UPDATE_SUCCESS = "PARTNER_UPDATE_SUCCESS";
export const PARTNER_UPDATE_FAIL = "PARTNER_UPDATE_FAIL";

export const PHYSICALPARTNER_ADDRESS_REQUEST = "PHYSICALPARTNER_ADDRESS_REQUEST";
export const PHYSICALPARTNER_ADDRESS_SUCCESS = "PHYSICALPARTNER_ADDRESS_SUCCESS";
export const PHYSICALPARTNER_ADDRESS_FAIL = "PHYSICALPARTNER_ADDRESS_FAIL";

export const PARTNERPRODUCT_APPROVE_REQUEST = "PARTNERPRODUCT_APPROVE_REQUEST";
export const PARTNERPRODUCT_APPROVE_SUCCESS = "PARTNERPRODUCT_APPROVE_SUCCESS";
export const PARTNERPRODUCT_APPROVE_FAIL = "PARTNERPRODUCT_APPROVE_FAIL";

export const PHYSICALPARTNER_CREATE_REQUEST = "PHYSICALPARTNER_CREATE_REQUEST";
export const PHYSICALPARTNER_CREATE_SUCCESS = "PHYSICALPARTNER_CREATE_SUCCESS";
export const PHYSICALPARTNER_CREATE_FAIL = "PHYSICALPARTNER_CREATE_FAIL";




export const addOnlinePartnerAction = (formData, navigate, state, setErrorMessage) => async dispatch => {
  dispatch({
    type: PARTNER_CREATE_REQUEST
  });
  boomiAdminApi.post(`/Partners/Online_Partner_Product_Create`, formData
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNER_CREATE_SUCCESS
        });
        toast('Online partner added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/product/partner-management', { state: state });
      } else {
        setErrorMessage(successResponse.UI_Display_Message);
        dispatch({
          type: PARTNER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage("Currently server is not working.Please try again later.");
      dispatch({
        type: PARTNER_CREATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};

/*====================================================================
               Add Physical Product Partner Action
=======================================================================*/
export const addPhysicalPartnerAction = (formData, navigate, state, setErrorMessage) => async dispatch => {
  dispatch({
    type: PARTNER_CREATE_REQUEST
  });
  // axios.post(`${config.adminurl}/Partners/Physical_Partner_Product_Create`, formData, {
    boomiAdminApi.post(`/Physical_Partner_Product/Create`, formData
   
  
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNER_CREATE_SUCCESS
        });
        toast('Physical partner added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/product/partner-management', { state: state });
      } else {
        setErrorMessage(successResponse.UI_Display_Message);
        dispatch({
          type: PARTNER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage("Currently server is not working.Please try again later.");
      dispatch({
        type: PARTNER_CREATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};


/*=============================================================
              update Online ProductPartner Action
===============================================================*/
export const EditOnlinePartnerAction = (formData, navigate, state, setErrorMessage) => async dispatch => {
  dispatch({
    type: PARTNER_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Partners/Online_Partner_Product_Update`, formData
   
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNER_UPDATE_SUCCESS
        });
        toast('Online partner updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/product/partner-management', { state: state });
      } else {
        setErrorMessage(successResponse.UI_Display_Message);
        dispatch({
          type: PARTNER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage("Please try sometime later.Currently server is not working.");
      dispatch({
        type: PARTNER_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working."
      });
    })
};

/*=============================================================
          Update Physical Partner By Id Action
===============================================================*/
export const EditPhysicalPartner = (formData, navigate, state, setErrorMessage) => async dispatch => {
  dispatch({
    type: PARTNER_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Partners/Physical_Partner_Product_Update`, formData
    
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNER_UPDATE_SUCCESS
        });
        toast('Physical partner updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/product/partner-management', { state: state });
      } else {
        setErrorMessage(successResponse.UI_Display_Message);
        dispatch({
          type: PARTNER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage("Please try sometime later.Currently server is not working.");
      dispatch({
        type: PARTNER_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working."
      });
    })
};

/*=============================================================
                Get All partners address Action
===============================================================*/
export const getAllPartnersAddress = (formData) => async dispatch => {
  try {
    // let formData = {
    //   Records_Filter: "ALL",
    // };
    dispatch({
      type: PHYSICALPARTNER_ADDRESS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partners/Partner_Address_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_ADDRESS_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PHYSICALPARTNER_ADDRESS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PHYSICALPARTNER_ADDRESS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PHYSICALPARTNER_ADDRESS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  Get All PARTERS Action
===============================================================*/
export const getAllPartnerAction = formData => async dispatch => {
  try {
    dispatch({
      type: PARTNERS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partners/All_Partner_Product_Address_Get_Admin?PageNo=1&PageSize=10&SortBy=PARTNER_PRODUCT_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNERS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PARTNERS_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                  deletePartner Action
===============================================================*/
export const deletePartnerAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/Partners/Online_Partner_Product_Delete`, formData
    );
    if (data) {
      toast('Partner deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationProductPartners(filters, pagination, callBackPagination));
    }
  } catch (err) { }
};

/*=============================================================
                  Get All approved PARTNERPRODUCT Action
 =============================================================*/
export const getAllApprovalPartnerProdAction = formData => async dispatch => {
  try {
    dispatch({
      type: PARTNERPRODUCT_APPROVE_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partners/All_Partner_Product_Address_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_PRODUCT_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNERPRODUCT_APPROVE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNERPRODUCT_APPROVE_SUCCESS,
          payload: data.Partner_Product
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PARTNERPRODUCT_APPROVE_FAIL,
      payload: err
    });
  }
};

// Copy Partner Product
export const CopyPartnerproductAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  dispatch({
    type: PHYSICALPARTNER_CREATE_REQUEST
  });
  boomiAdminApi.post(`/Partner_Product_Copy/Partner_Product_Create`, formData
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PHYSICALPARTNER_CREATE_SUCCESS
        });
        dispatch(paginationPendingProductPartners(filters, pagination, callBackPagination))
        toast('Product partner added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      } else {
        dispatch({
          type: PHYSICALPARTNER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PHYSICALPARTNER_CREATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};

//Get Partner Product Action
export const getPartnerProductAction = (formData, callBackPartnerProductData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Partners/All_Partner_Product_Address_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Partner_Product_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBackPartnerProductData(data)
    }
  } catch (err) {
  }
};