import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EditOfferVisitAction } from '../../store/actions/offersvisitorAction';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Purchased',
    label: 'Purchased'
  },
  {
    value: 'Sold',
    label: 'Sold'
  },
  {
    value: 'Active',
    label: 'Active'
  },
];

const useStyles = makeStyles(() => ({
  root: {},
  images: {
    width: 120,
    height: 120,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditOffersVisitor = ({ className, ...rest }) => {
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = location;

  const errorMessage = useSelector(state => state.offer.offerUpdate.error);
  const isSubmited = useSelector(state => state.offer.offerUpdate.isLoading);

  return (
    <Formik
      initialValues={{
        User_Visit_Id: state.User_Visit_Id ? state.User_Visit_Id : "",
        User_Email_Id: state.User_Email_Id ? state.User_Email_Id : "",
        Ip_Address: state.Ip_Address ? state.Ip_Address : "",
        Offer_Type: state.Offer_Type ? state.Offer_Type : "",
        Offer_Id: state.Offer_Id ? state.Offer_Id : "",
        Store_Type: state.Store_Type ? state.Store_Type : "",
        Store_Id: state.Store_Id ? state.Store_Id : "",
        Visited_Date: state.Visited_Date ? state.Visited_Date : "",
        Ip_Count: state.Ip_Count ? state.Ip_Count : "",
        Esi_Cashback: state.Esi_Cashback ? state.Esi_Cashback : "",
        Status: state.Status ? state.Status : "",
        Redirection_Url: state.Redirection_Url ? state.Redirection_Url : ""
      }}
      validationSchema={
        Yup.object().shape({
          Status: Yup.string().required('Status is required.'),
        })
      }
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        let formValues = JSON.parse(JSON.stringify(values));
        if (values.Cashback_Confirmed) {
          formValues.Cashback_Confirmed = 1;
        } else {
          formValues.Cashback_Confirmed = 0;
        }
        if (values.Cashback_Settled) {
          formValues.Cashback_Settled = 1;
        } else {
          formValues.Cashback_Settled = 0;
        }
        dispatch(EditOfferVisitAction(formValues, navigate));
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="User Email ID"
                        name="User_Email_Id"
                        value={values.User_Email_Id}
                        variant="outlined"
                        disabled  >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="IP Address"
                        name="Ip_Address"
                        value={values.Ip_Address}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Offer Type"
                        name="Offer_Type"
                        value={values.Offer_Type}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Offer ID"
                        name="Offer_Id"
                        value={values.Offer_Id}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Store Type"
                        name="Store_Type"
                        value={values.Store_Type}
                        variant="outlined"
                        disabled
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Store ID"
                        name="Store_Id"
                        value={values.Store_Id}
                        variant="outlined"
                        disabled  >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Visited Date"
                        type="date"
                        name="Visited_Date"
                        defaultValue={values.Visited_Date}
                        variant="outlined"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled >
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="IP Count"
                        name="Ip_Count"
                        value={values.Ip_Count}
                        variant="outlined"
                        disabled >
                      </TextField>
                    </Grid>
                    {/*Esi_Cashback*/}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="ESI Cashback"
                        name="Esi_Cashback"
                        value={values.Esi_Cashback}
                        variant="outlined"
                        disabled >
                      </TextField>
                    </Grid>

                    {/*Redirection_Url*/}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Redirection URL"
                        name="Redirection_Url"
                        value={values.Redirection_Url}
                        variant="outlined"
                        disabled >
                      </TextField>
                    </Grid>


                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" p={2}>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#27B6CC' }}
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditOffersVisitor.propTypes = {
  className: PropTypes.string
};

export default EditOffersVisitor;