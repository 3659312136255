import {
    USER_COMFORT_FAIL,
    USER_COMFORT_REQUEST,
    USER_COMFORT_SUCCESS,
    USER_RIDE_FARE_CREATE_FAIL,
    USER_RIDE_FARE_CREATE_REQUEST,
    USER_RIDE_FARE_CREATE_SUCCESS,
    USER_RIDE_FARE_FAIL,
    USER_RIDE_FARE_REQUEST,
    USER_RIDE_FARE_SUCCESS,
    USER_RIDE_FARE_UPDATE_FAIL,
    USER_RIDE_FARE_UPDATE_REQUEST,
    USER_RIDE_FARE_UPDATE_SUCCESS
} from "../actions/userridefaresAction";


const initialState = {
    userrideFares: { all: [], error: '', isLoading: false },
    userrideFare: { userrideFare: [], error: '', isLoading: false },
    userrideFareCreate: { userrideFare: {}, error: '', isLoading: false },
    userrideFareUpdate: { userrideFare: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_RIDE_FARE_REQUEST:
            return { ...state, userrideFares: { all: state.userrideFares.all, error: '', isLoading: true } };
        case USER_RIDE_FARE_SUCCESS:
            return { ...state, userrideFares: { all: action.payload, error: '', isLoading: false } };
        case USER_RIDE_FARE_FAIL:
            return { ...state, userrideFares: { all: [], error: action.payload, isLoading: false } };

        case USER_COMFORT_REQUEST:
            return { ...state, userrideFare: { userrideFare: state.userrideFare.userrideFare, error: '', isLoading: true } };
        case USER_COMFORT_SUCCESS:
            return { ...state, userrideFare: { userrideFare: action.payload, error: '', isLoading: false } };
        case USER_COMFORT_FAIL:
            return { ...state, userrideFare: { userrideFare: [], error: action.payload, isLoading: false } };

        case USER_RIDE_FARE_CREATE_REQUEST:
            return { ...state, userrideFareCreate: { userrideFare: {}, error: '', isLoading: true } };
        case USER_RIDE_FARE_CREATE_SUCCESS:
            return { ...state, userrideFareCreate: { userrideFare: state.userrideFareCreate.userrideFare, error: '', isLoading: false } };
        case USER_RIDE_FARE_CREATE_FAIL:
            return { ...state, userrideFareCreate: { userrideFare: {}, error: action.payload, isLoading: false } };

        case USER_RIDE_FARE_UPDATE_REQUEST:
            return { ...state, userrideFareUpdate: { userrideFare: {}, error: '', isLoading: true } };
        case USER_RIDE_FARE_UPDATE_SUCCESS:
            return { ...state, userrideFareUpdate: { userrideFare: state.userrideFareUpdate.userrideFare, error: '', isLoading: false } };
        case USER_RIDE_FARE_UPDATE_FAIL:
            return { ...state, userrideFareUpdate: { userrideFare: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}