
import config from './config';
import { toast } from 'react-toastify';
import { paginationSettings } from './node-actions/nodepagination';
import { nodeCudApi } from './commonAxios';

export const SETTING_CREATE_REQUEST = "SETTING_CREATE_REQUEST";
export const SETTING_CREATE_SUCCESS = "SETTING_CREATE_SUCCESS";
export const SETTING_CREATE_FAIL = "SETTING_CREATE_FAIL";
export const SETTING_UPDATE_REQUEST = "SETTING_UPDATE_REQUEST";
export const SETTING_UPDATE_SUCCESS = "SETTING_UPDATE_SUCCESS";
export const SETTING_UPDATE_FAIL = "SETTING_UPDATE_FAIL";


/*=============================================================
                   Add Setting Action
 ===============================================================*/
export const AddSettingAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: SETTING_CREATE_REQUEST
  });
  setErrorMessage({ Setting_Field_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Setting/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SETTING_CREATE_SUCCESS
        });
        toast('Setting added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/setting');
      } else {
        const errors = {};
        if (successResponse.Setting_Field_Name) {
          errors.Field_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Setting_Field_Name: { key: successResponse.Setting_Field_Name ? successResponse.Setting_Field_Name : '', message: successResponse.Setting_Field_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Setting_Field_Name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: SETTING_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Setting_Field_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });

      dispatch({
        type: SETTING_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};


/*=============================================================
                 Update Setting Action
===============================================================*/
export const EditSettingAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: SETTING_UPDATE_REQUEST
  });
  setErrorMessage({ Setting_Field_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`${config.nodecudurl}/Setting/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SETTING_UPDATE_SUCCESS
        });
        toast('Setting updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/setting');
      } else {
        const errors = {};
        if (successResponse.Setting_Field_Name) {
          errors.Field_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Setting_Field_Name: { key: successResponse.Setting_Field_Name ? successResponse.Setting_Field_Name : '', message: successResponse.Setting_Field_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Setting_Field_Name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: SETTING_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Setting_Field_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: SETTING_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working."
      });
    })
};

/*=============================================================
                    Delete Setting Action
===============================================================*/
export const deleteSettingAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Setting/Delete`, formData);
    if (data) {
      toast('Setting deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationSettings(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};