import config from './config';
import { toast } from 'react-toastify';
import { paginationGiftVendor } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi, boomiAdminApi } from './commonAxios';

export const GIFTVENDOR_REQUEST = "GIFTVENDOR_REQUEST";
export const GIFTVENDOR_SUCCESS = "GIFTVENDOR_SUCCESS";
export const GIFTVENDOR_FAIL = "GIFTVENDOR_FAIL";
export const GIFTVENDOR_CREATE_REQUEST = "GIFTVENDOR_CREATE_REQUEST";
export const GIFTVENDOR_CREATE_SUCCESS = "GIFTVENDOR_CREATE_SUCCESS";
export const GIFTVENDOR_CREATE_FAIL = "GIFTVENDOR_CREATE_FAIL";
export const GIFTVENDOR_UPDATE_REQUEST = "GIFTVENDOR_UPDATE_REQUEST";
export const GIFTVENDOR_UPDATE_SUCCESS = "GIFTVENDOR_UPDATE_SUCCESS";
export const GIFTVENDOR_UPDATE_FAIL = "GIFTVENDOR_UPDATE_FAIL";

export const GIFT_PARTNERID_REQUEST = "GIFT_PARTNERID_REQUEST";
export const GIFT_PARTNERID_SUCCESS = "GIFT_PARTNERID_SUCCESS";
export const GIFT_PARTNERID_FAIL = "GIFT_PARTNERID_FAIL";

export const createGiftVendorAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: GIFTVENDOR_CREATE_REQUEST
  });
  setErrorMessage({ Vendor_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Gift_Voucher/Vendor_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: GIFTVENDOR_CREATE_SUCCESS
        });
        toast('Gift vendor added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/gift-vendors');
      } else {
        const errors = {};

        if (successResponse.Vendor_Name) {
          errors.Vendor_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Vendor_Name: { key: successResponse.Vendor_Name ? successResponse.Vendor_Name : '', message: successResponse.Vendor_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Vendor_Name) ? successResponse.UI_Display_Message : '' } });

        dispatch({
          type: GIFTVENDOR_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Vendor_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: GIFTVENDOR_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Update gift vendor Action
===============================================================*/
export const updateGiftVendorAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: GIFTVENDOR_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Gift_Voucher/Vendor_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: GIFTVENDOR_UPDATE_SUCCESS
        });
        toast('Gift vendor updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/gift-vendors');
      } else {
        dispatch({
          type: GIFTVENDOR_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: GIFTVENDOR_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

//node API
export const getAllGiftVendorsAction = formData => async dispatch => {
  try {
    dispatch({
      type: GIFTVENDOR_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Gift_Voucher/Vendor_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Vendor_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: GIFTVENDOR_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: GIFTVENDOR_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: GIFTVENDOR_FAIL,
      payload: err
    });
  }
};
/*=============================================================
              Delete  gift vendor Action
===============================================================*/
export const deleteGiftVendorAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Gift_Voucher/Vendor_Delete`, formData);
    if (data) {
      toast('Gift vendor deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationGiftVendor(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

/*=============================================================
                  GIFT_PARTNERID_REQUEST Action
===============================================================*/
export const getAllPartnerids = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: 'FILTER',
      Status: "Active",
      search: "",
      search_by_filter: "",
    };
    dispatch({
      type: GIFT_PARTNERID_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Partners/OnlineAndPhysical_Details_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: GIFT_PARTNERID_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: GIFT_PARTNERID_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: GIFT_PARTNERID_FAIL,
      payload: JSON.stringify(err)
    });
  }
};


/*=============================================================
              UploadGiftVendorImage Action
===============================================================*/
export const uploadGiftVendorImage = (formData, callBackUploadImgae) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=GiftVendor&fileextension=png&filetype=Images&filename=GiftVendor`, formData);
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) {
  }
};

//  Node API
export const getGiftVoucherVendorAction = (formData, callBackGiftVoucherVendorData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Gift_Voucher/Vendor_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Vendor_Id&SortOrder=ASC`, formData);
    if (data) {
      callBackGiftVoucherVendorData(data)
    }
  } catch (err) {
  }
};