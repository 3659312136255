import { nodeGetApi } from './commonAxios';

export const BID_PROD_DATEANDCOUNT_REQUEST = "BID_PROD_DATEANDCOUNT_REQUEST";
export const BID_PROD_DATEANDCOUNT_SUCCESS = "BID_PROD_DATEANDCOUNT_SUCCESS";
export const BID_PROD_DATEANDCOUNT_FAIL = "BID_PROD_DATEANDCOUNT_FAIL";

export const BID_SERV_DATEANDCOUNT_REQUEST = "BID_SERV_DATEANDCOUNT_REQUEST";
export const BID_SERV_DATEANDCOUNT_SUCCESS = "BID_SERV_DATEANDCOUNT_SUCCESS";
export const BID_SERV_DATEANDCOUNT_FAIL = "BID_SERV_DATEANDCOUNT_FAIL";

export const BID_RIDE_DATEANDCOUNT_REQUEST = "BID_RIDE_DATEANDCOUNT_REQUEST";
export const BID_RIDE_DATEANDCOUNT_SUCCESS = "BID_RIDE_DATEANDCOUNT_SUCCESS";
export const BID_RIDE_DATEANDCOUNT_FAIL = "BID_RIDE_DATEANDCOUNT_FAIL";

export const BID_B2B_PROD_DATEANDCOUNT_REQUEST = "BID_B2B_PROD_DATEANDCOUNT_REQUEST";
export const BID_B2B_PROD_DATEANDCOUNT_SUCCESS = "BID_B2B_PROD_DATEANDCOUNT_SUCCESS";
export const BID_B2B_PROD_DATEANDCOUNT_FAIL = "BID_B2B_PROD_DATEANDCOUNT_FAIL";

//Get Bidding Products Date and Count
export const getBiddingProductsDateandCount = () => async dispatch => {
    try {
        dispatch({
            type: BID_PROD_DATEANDCOUNT_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Total_Count_And_Date/Bidding_Products`);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: BID_PROD_DATEANDCOUNT_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BID_PROD_DATEANDCOUNT_SUCCESS,
                    payload: data.results[0]
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BID_PROD_DATEANDCOUNT_FAIL,
            payload: err
        });
    }
};

//Get Bidding Services Date and Count
export const getBiddingServicesDateandCount = () => async dispatch => {
    try {
        dispatch({
            type: BID_SERV_DATEANDCOUNT_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Total_Count_And_Date/Bidding_Services`, {});
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: BID_SERV_DATEANDCOUNT_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BID_SERV_DATEANDCOUNT_SUCCESS,
                    payload: data.results[0]
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BID_SERV_DATEANDCOUNT_FAIL,
            payload: err
        });
    }
};

//Get Bidding Rides Date and Count
export const getBiddingRidesDateandCount = () => async dispatch => {
    try {
        dispatch({
            type: BID_RIDE_DATEANDCOUNT_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Total_Count_And_Date/Bidding_Rides`, {});
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: BID_RIDE_DATEANDCOUNT_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BID_RIDE_DATEANDCOUNT_SUCCESS,
                    payload: data.results[0]
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BID_RIDE_DATEANDCOUNT_FAIL,
            payload: err
        });
    }
};

//Get Bidding B2B Products Date and Count
export const getBiddingB2BProductsDateandCount = () => async dispatch => {
    try {
        dispatch({
            type: BID_B2B_PROD_DATEANDCOUNT_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Total_Count_And_Date/B2B_User_Products_Bidding`, {});
        if (data) {
            if (data.Success_Response?.Is_Data_Exist === '0') {
                dispatch({
                    type: BID_B2B_PROD_DATEANDCOUNT_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BID_B2B_PROD_DATEANDCOUNT_SUCCESS,
                    payload: data.results[0]
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BID_B2B_PROD_DATEANDCOUNT_FAIL,
            payload: err
        });
    }
};