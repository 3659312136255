import {
  BILL_UPDATE_REQUEST,
  BILL_UPDATE_SUCCESS,
  BILL_UPDATE_FAIL,
} from '../actions/missingcashbackAction'

const initialState = {
  billUpdate: { bill: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {

    case BILL_UPDATE_REQUEST:
      return { ...state, billUpdate: { bill: {}, error: '', isLoading: true } };
    case BILL_UPDATE_SUCCESS:
      return { ...state, billUpdate: { bill: action.payload, error: '', isLoading: false } };
    case BILL_UPDATE_FAIL:
      return { ...state, billUpdate: { bill: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}