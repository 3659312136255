import config from './config';
import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const BID_SUCCESS_STORY_CREATE_REQUEST = "BID_SUCCESS_STORY_CREATE_REQUEST";
export const BID_SUCCESS_STORY_CREATE_SUCCESS = "BID_SUCCESS_STORY_CREATE_SUCCESS";
export const BID_SUCCESS_STORY_CREATE_FAIL = "BID_SUCCESS_STORY_CREATE_FAIL";

export const BID_SUCCESS_STORY_UPDATE_REQUEST = "BID_SUCCESS_STORY_UPDATE_REQUEST";
export const BID_SUCCESS_STORY_UPDATE_SUCCESS = "BID_SUCCESS_STORY_UPDATE_SUCCESS";
export const BID_SUCCESS_STORY_UPDATE_FAIL = "BID_SUCCESS_STORY_UPDATE_FAIL";

export const DEL_BID_SS_VEHICLES_GET_REQUEST = "DEL_BID_SS_VEHICLES_GET_REQUEST";
export const DEL_BID_SS_VEHICLES_GET_SUCCESS = "DEL_BID_SS_VEHICLES_GET_SUCCESS";
export const DEL_BID_SS_VEHICLES_GET_FAIL = "DEL_BID_SS_VEHICLES_GET_FAIL";

export const DEL_BID_SS_DELUSERS_GET_REQUEST = "DEL_BID_SS_DELUSERS_GET_REQUEST";
export const DEL_BID_SS_DELUSERS_GET_SUCCESS = "DEL_BID_SS_DELUSERS_GET_SUCCESS";
export const DEL_BID_SS_DELUSERS_GET_FAIL = "DEL_BID_SS_DELUSERS_GET_FAIL";

//create Bidding Ride Success Stories Action
export const createBiddingRideSuccessStory = (formData, navigate) => async dispatch => {
    dispatch({
        type: BID_SUCCESS_STORY_CREATE_REQUEST
    });
    nodeCudApi.post(`/Delivery_Bidding_Success_Stories/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: BID_SUCCESS_STORY_CREATE_SUCCESS
                });
                toast('Bidding Ride Success Story created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/delivery-bidding-success-stories');
            } else {
                dispatch({
                    type: BID_SUCCESS_STORY_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: BID_SUCCESS_STORY_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

//update Bidding Ride Success Stories Action
export const updateBiddingRideSuccessStory = (formData, navigate) => async dispatch => {
    dispatch({
        type: BID_SUCCESS_STORY_UPDATE_REQUEST
    });
    nodeCudApi.post(`/Delivery_Bidding_Success_Stories/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: BID_SUCCESS_STORY_UPDATE_SUCCESS
                });
                toast('Bidding Ride Success Story updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/delivery-bidding-success-stories');
            } else {

                dispatch({
                    type: BID_SUCCESS_STORY_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: BID_SUCCESS_STORY_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//get all delivery bidding vehicles
export const getAllDeliBiddingVehicles = (formData) => async dispatch => {
    try {
        dispatch({
            type: DEL_BID_SS_VEHICLES_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Bidding_Vehicle_Details/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Bidding_Vehicle_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.successResponse?.Is_Data_Exist === '0') {
                dispatch({
                    type: DEL_BID_SS_VEHICLES_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: DEL_BID_SS_VEHICLES_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: DEL_BID_SS_VEHICLES_GET_FAIL,
            payload: err
        });
    }
};

//get all delivery emails
export const getAllBidDeliveryEmails = (emailid) => async dispatch => {
    try {
        let formData = {
            "Del_User_Email_Id": emailid,
             "Records_Filter": "FILTER"
          };
        dispatch({
            type: DEL_BID_SS_DELUSERS_GET_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Delivery_Profile/Get_All_Profile_dropdown_search?PageNo=1&PageSize=${config.pageSize}&SortBy=Del_User_Email_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.Success_Response.Is_Data_Exist === '0') {
                dispatch({
                    type: DEL_BID_SS_DELUSERS_GET_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: DEL_BID_SS_DELUSERS_GET_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: DEL_BID_SS_DELUSERS_GET_FAIL,
            payload: err
        });
    }
};