import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  makeStyles
} from '@material-ui/core';
import * as Yup from "yup";
import {
  changePasswordAction
} from "../../../store/actions/profileActions";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "10px"
  },
  avatar: {
    height: 100,
    width: 100
  },
  vsubmit: {
    margin: theme.spacing(2, 0, 1),
    width: "60%",
    marginLeft: "auto",
    marginRight: "auto",
    display: 'block'
  },
  margin: {
    margin: theme.spacing(1, 0, -1),
  }
}));

const ChangePassword = ({ className, profiletemp, callgetuser, loading, setLoading, ...rest }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const logedInEmail = useSelector((state) => state.auth.user.Email_Id);
  const [errorMessage, setErrorMessage] = useState('');

  //new password
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //old password
  const [showOldPassword, setShowOldPassword] = useState(false);
  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };
  const handleMouseDownOldPassword = (event) => {
    event.preventDefault();
  };

  const changePasswordResponse = (status, errorMessage) => {
    if (!status) {
      setErrorMessage(errorMessage);
    }
    setLoading(false);
  }
  const copyPasteCutChange = (e) => {
    e.preventDefault();
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <center><b>Change Password</b></center>
        <Formik
          initialValues={{
            Email_Id: logedInEmail,
            Old_Password: '',
            New_Password: '',
            cpassword: ''
          }}

          validationSchema={Yup.object().shape({
            Old_Password: Yup.string().required('Old Password is required.'),
            New_Password: Yup.string()
              .max(255)
              .matches(/[a-z]+/, "At least one lowercase letter is required.")
              .matches(/[A-Z]+/, "At least one uppercase letter is required.")
              .matches(/[^\da-zA-Z]+/, "At least one special character is required.")
              .matches(/.*\d+/, "At least one number is required.")
              .min(8, "At least minimum 8 characters are required.")
              .notOneOf([Yup.ref('Old_Password'), null], "Don't use Old Password.")
              .required('New Password is required.'),
            cpassword: Yup.string()
              .max(255).oneOf([Yup.ref('New_Password'), null], 'Passwords must match.')
              .required('Confirm Password is required.')
          })}

          onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
            setSubmitting(false);
            setErrorMessage("");
            dispatch(changePasswordAction(values, changePasswordResponse, resetForm));
            setLoading(true);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit} className={classes.vform}>
              <ConnectedFocusError />
              <Grid container>

                <Grid item xs={12} lg={12}>
                  <FormControl fullWidth variant="outlined" className={clsx(classes.margin)}
                    error={Boolean(touched.Old_Password && errors.Old_Password)}
                  >
                    <InputLabel htmlFor="Old_Password">Old Password</InputLabel>
                    <OutlinedInput
                      id="Old_Password"
                      type={showOldPassword ? 'text' : 'password'}
                      value={values.Old_Password}
                      onBlur={handleBlur}
                      onChange={(event) => setFieldValue("Old_Password", event.target.value)}
                      onCut={copyPasteCutChange}
                      onCopy={copyPasteCutChange}
                      onPaste={copyPasteCutChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowOldPassword}
                            onMouseDown={handleMouseDownOldPassword}
                            edge="end"
                          >
                            {showOldPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={110}
                      style={{ marginBottom: '0.5rem' }}
                    />
                    <FormHelperText >{touched.Old_Password && errors.Old_Password}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <FormControl fullWidth variant="outlined" className={clsx(classes.margin)}
                    error={Boolean(touched.New_Password && errors.New_Password)}
                  >
                    <InputLabel htmlFor="New_Password">New Password</InputLabel>
                    <OutlinedInput
                      id="New_Password"
                      type={showPassword ? 'text' : 'password'}
                      value={values.New_Password}
                      onBlur={handleBlur}
                      onChange={(event) => setFieldValue("New_Password", event.target.value)}
                      onCut={copyPasteCutChange}
                      onCopy={copyPasteCutChange}
                      onPaste={copyPasteCutChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={110}
                    />
                    <FormHelperText >{touched.New_Password && errors.New_Password}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <TextField
                    error={Boolean(touched.cpassword && errors.cpassword)}
                    fullWidth
                    helperText={touched.cpassword && errors.cpassword}
                    label="Confirm Password"
                    margin="normal"
                    name="cpassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.cpassword}
                    variant="outlined"
                    onCut={copyPasteCutChange}
                    onCopy={copyPasteCutChange}
                    onPaste={copyPasteCutChange}
                  />
                </Grid>
                <Grid item ml={2} xs={12} lg={12}>
                  <div>
                    <small style={{ color: "red" }}>{errorMessage}</small>
                  </div>

                </Grid>
                <Grid item ml={2} xs={12} lg={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    // color="primary"
                    style={{ backgroundColor: '#27B6CC' }}
                    className={classes.vsubmit}
                    disabled={loading}
                  ><span style={{ color: 'white' }}>Confirm</span></Button>
                </Grid>
              </Grid>

            </form>
          )}
        </Formik>

      </CardContent>
    </Card >
  );
};

ChangePassword.propTypes = {
  className: PropTypes.string,
  profiletemp: PropTypes.object,
  callgetuser: PropTypes.func
};

export default ChangePassword;