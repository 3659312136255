import {
  Card,
  CardContent,
  Grid,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { addMeepaisazonethemeAction, getAllMeepaisaTheme, getAllMeepaisaZones } from 'src/store/actions/meepaisazonethemeAction';


const AddMeepaisaZoneTheme = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllMeepaisaTheme());
    dispatch(getAllMeepaisaZones());
  }, []);

  const [errorMessage, setErrorMessage] = useState({ Zone_Id: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.zonetheme.zonethemeCreate.isLoading);
  const zones = useSelector(state => state.zonetheme.Zones.zones);
  const themes = useSelector(state => state.zonetheme.Themes.themes);
  const createdBy = useSelector(state => state.auth.user.Email_Id);

  const values = {
    Zone_Id: "",
    Meepaisa_Theme_Id: "",
    Is_Active: false,
    Created_By: "",
  }
  const Form_Validation = Yup.object().shape({
    Zone_Id: Yup.string().required('Zone Name is required.'),
    Meepaisa_Theme_Id: Yup.string().required('Meepaisa Theme Name is required.'),
  })
  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      if (values.Is_Active) {
        formValues.Is_Active = 1;
      } else {
        formValues.Is_Active = 0;
      }
      formValues.Created_By = createdBy;
      dispatch(addMeepaisazonethemeAction(formValues, navigate, setErrorMessage, setErrors));
    }
  }

  return (
    <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
      }) => (
        <Form>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Zone_Id && errors.Zone_Id)}
                        fullWidth
                        helperText={touched.Zone_Id && errors.Zone_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Zone Name"
                        name="Zone_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Zone_Id}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {zones?.sort((a, b) => a.Zone_Name?.localeCompare(b.Zone_Name))?.map(option => (
                          <option key={option.Zone_Id} value={option.Zone_Id}>
                            {option.Zone_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Meepaisa_Theme_Id && errors.Meepaisa_Theme_Id)}
                        fullWidth
                        helperText={touched.Meepaisa_Theme_Id && errors.Meepaisa_Theme_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Meepaisa Theme Name"
                        name="Meepaisa_Theme_Id"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Meepaisa_Theme_Id}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {themes?.sort((a, b) => a.Meepaisa_Theme_Name?.localeCompare(b.Meepaisa_Theme_Name))?.map(option => (
                          <option key={option.Meepaisa_Theme_Id} value={option.Meepaisa_Theme_Id}>
                            {option.Meepaisa_Theme_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Active}
                              onChange={handleChange}
                              name="Is_Active"
                              color="primary" />
                          }
                          label="Is Active"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save Details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default AddMeepaisaZoneTheme;