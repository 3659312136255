import {

    DELI_BID_COMMENTS_UPDATE_REQUEST,
    DELI_BID_COMMENTS_UPDATE_SUCCESS,
    DELI_BID_COMMENTS_UPDATE_FAIL,

} from '../actions/deliverybiddingcommentsAction'

const initialState = {
    deliverybidcommentUpdate: { deliverybidcomment: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case DELI_BID_COMMENTS_UPDATE_REQUEST:
            return { ...state, deliverybidcommentUpdate: { deliverybidcomment: {}, error: '', isLoading: true } };
        case DELI_BID_COMMENTS_UPDATE_SUCCESS:
            return { ...state, deliverybidcommentUpdate: { deliverybidcomment: state.deliverybidcommentUpdate.deliverybidcomment, error: '', isLoading: false } };
        case DELI_BID_COMMENTS_UPDATE_FAIL:
            return { ...state, deliverybidcommentUpdate: { deliverybidcomment: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}