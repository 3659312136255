import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import {
    Box,
    Container,
    makeStyles,
    Typography,
    Button
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { paginationProductsRequestCorp } from 'src/store/actions/node-actions/nodepagination';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';
import Results from './Results';
import Toolbar from './Toolbar';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const CorporateProductRequestListView = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    // Permissions
    const RolePermissions = useSelector(state => state.rolepermission.Roles);
    const roleUserType = useSelector(state => state.auth.user);
    const Login_Details = useSelector(state => state.auth.user);

    const [productrequest, setProductRequest] = useState([]);
    const currentDate = moment(new Date()).format('DD/MM/YYYY');
    const [selectedDate, setSelectedDate] = useState(currentDate);
    const [filters, setFilters] = useState({
        "Selected_Date": selectedDate,
    });

    const [pageOld, setPageOld] = useState(false);
    const [pagination, setPagination] = useState({ page: 1, limit: 10, sortBy: 'createdAt:desc' });

    const handleLimitChange = event => {
        setPagination({ ...pagination, limit: +event.target.value, page: 1 });
    };

    const handlePageChange = (event, newPage) => {
        setPagination({ ...pagination, limit: pagination.limit, page: newPage + 1 });
    };

    const callBackPagination = async (status, data, pagination) => {
        if (status) {
            setProductRequest(data);
        }
        setPageOld(true);
        if (pagination) {
            pagination.sortBy = 'createdAt:desc';
            setPagination(pagination);
        } else {
            setPagination({ page: 1, limit: 10, sortBy: 'createdAt:desc' });
        }
    }

    useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            productrequestCall();
        }
    }, [pagination]);

    useEffect(() => {
        productrequestCall();
    }, [filters]);

    const productrequestCall = () => {
        dispatch(paginationProductsRequestCorp(filters, pagination, callBackPagination));
    };

    useEffect(() => {
        dispatch(getRolePermissions(Login_Details));
        productrequestCall();
    }, []);

    // Sorting 
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('createdAt');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPagination({ page: 1, limit: pagination?.limit, sortBy: property + (order === 'asc' ? ':asc' : ':desc') });
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/corporate-product-management"
                    variant="text"
                    size="small"
                    color="inherit"
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        Corporate Product Requests
                    </Typography>
                </Box>
                <Toolbar
                    filters={filters}
                    setfilters={setFilters}
                    callBackPagination={callBackPagination}
                    pagination={pagination}
                    RolePermissions={RolePermissions}
                    roleUserType={roleUserType}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                />
                <Box mt={3}>
                    <Results
                        allProductRequest={productrequest}
                        allProductRequestNoFilter={productrequest}
                        handleLimitChange={handleLimitChange}
                        handlePageChange={handlePageChange}
                        order={order}
                        pagination={pagination}
                        setPagination={setPagination}
                        orderBy={orderBy}
                        createSortHandler={createSortHandler}
                        RolePermissions={RolePermissions}
                        roleUserType={roleUserType}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default CorporateProductRequestListView;
