import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent } from '@mui/material';

// Utility function to decode HTML entities
const decodeHTML = (input) => {
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
};

const CertificatePreview = ({ htmlContent, open, onClose }) => {
    const [decodedHTML, setDecodedHTML] = useState('');

    useEffect(() => {
        // Decode the HTML content you receive from the API or props
        const decoded = decodeHTML(htmlContent);
        setDecodedHTML(decoded);
    }, [htmlContent]); // Effect will run when htmlContent changes

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogContent>
                {/* Render the decoded HTML */}
                <div dangerouslySetInnerHTML={{ __html: decodedHTML }} />
            </DialogContent>
        </Dialog>
    );
};

export default CertificatePreview;
