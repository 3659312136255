import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Box,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { UserServicesPartnerPayAction, getAllServices, getAllPartnerDetails } from 'src/store/actions/servicesAction';


const TransferTypeOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'CR',
        label: 'Credit'
    },
    {
        value: 'DB',
        label: 'Debit'
    }
];

const ServiceCompletedPartnerPayment = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let { state } = location;
    
    useEffect(() => {
        dispatch(getAllServices());
        dispatch(getAllPartnerDetails());
    }, []);

    const isSubmited = useSelector(state => state?.services?.userservicePartnerPay?.isLoading);
    const errorMessage = useSelector(state => state?.services?.userservicePartnerPay?.error);
    const bussinessServices = useSelector(state => state.service.BussinessServices.bussinessServices);
    const partners = useSelector(state => state.service.PhysicalPartners.partners);

    return (
        <Formik
            initialValues={{
                Credited_Reason: "",
                Debited_reason: "",
                Number_Of_Days: state.Service_Request_Total_Time_In_Days ? state.Service_Request_Total_Time_In_Days : "",
                Number_Of_Hours: state.Service_Request_Total_Time_In_Hours ? state.Service_Request_Total_Time_In_Hours : "",
                Transfer_Type: "",
                Partner_Service_Id: state.Partner_Service_Id ? state.Partner_Service_Id : "",
                Partner_Detail_Id: state.Partner_Detail_Id ? state.Partner_Detail_Id : "",
            }}

            validationSchema={
                Yup.object().shape({
                    Credited_Reason: Yup.string().when('Transfer_Type', { is: "CR", then: Yup.string().required('Credited Reason is required.'), }),
                    Debited_reason: Yup.string().when('Transfer_Type', { is: "DB", then: Yup.string().required('Debited Reason is required.'), }),
                    Number_Of_Days:Yup.string().required('Number Of Days is required.'),
                    Number_Of_Hours:Yup.string().required('Number Of Hours is required.'),
                })}

            onSubmit={(values, { setSubmitting, setErrors }) => {
                setSubmitting(false);
                const errors = {};
                setErrors(errors);
                if (values) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(UserServicesPartnerPayAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        {/* Partner_Detail_Id */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Detail_Id && errors.Partner_Detail_Id)}
                                                fullWidth
                                                helperText={touched.Partner_Detail_Id && errors.Partner_Detail_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Name"
                                                InputLabelProps={{ shrink: true }}
                                                name="Partner_Detail_Id"
                                                select
                                                SelectProps={{ native: true }}
                                                value={values.Partner_Detail_Id}
                                                variant="outlined"
                                                disabled
                                            ><option key="" value="">--Please Select--</option>
                                                {partners
                                                    .map(option => (
                                                        <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                                                            {option.Name}
                                                        </option>
                                                    ))}
                                            </TextField>
                                        </Grid>

                                        {/* Partner_Service_Id */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Service_Id && errors.Partner_Service_Id)}
                                                fullWidth
                                                helperText={touched.Partner_Service_Id && errors.Partner_Service_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Service Name"
                                                name="Partner_Service_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Partner_Service_Id}
                                                variant="outlined"
                                                disabled
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {bussinessServices?.sort((a, b) => a.Service_Type?.localeCompare(b.Service_Type))?.map(option => (
                                                    <option key={option.Partner_Service_Id} value={option.Partner_Service_Id}>
                                                        {option.Service_Type}
                                                    </option>
                                                ))}

                                            </TextField>
                                        </Grid>

                                        {/* Transfer_Type */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Transfer_Type && errors.Transfer_Type)}
                                                fullWidth
                                                helperText={touched.Transfer_Type && errors.Transfer_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                select
                                                SelectProps={{ native: true }}
                                                label="Transfer Type"
                                                name="Transfer_Type"
                                                value={values.Transfer_Type}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                {TransferTypeOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {values.Transfer_Type === 'DB' ? (
                                            <>
                                                {/* Debited_reason */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.Debited_reason && errors.Debited_reason)}
                                                        fullWidth
                                                        helperText={touched.Debited_reason && errors.Debited_reason}
                                                        label="Debited Reason"
                                                        name="Debited_reason"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.Debited_reason}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </>
                                        ) : (
                                            <>

                                                {/* Credited_Reason */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.Credited_Reason && errors.Credited_Reason)}
                                                        fullWidth
                                                        helperText={touched.Credited_Reason && errors.Credited_Reason}
                                                        label="Credited Reason"
                                                        name="Credited_Reason"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.Credited_Reason}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </>
                                        )}

                                        {/* Number_Of_Hours */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Number Of Hours"
                                                name="Number_Of_Hours"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.Number_Of_Hours}
                                                variant="outlined"
                                                disabled
                                            />
                                        </Grid>

                                        {/* Number_Of_Days */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Number Of Days"
                                                name="Number_Of_Days"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.Number_Of_Days}
                                                variant="outlined"
                                                disabled
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Box display="flex" pl={2} >
                        <span style={{ color: "red" }}>{errorMessage}</span>
                    </Box>

                    <Box display="flex" p={2}>
                        {isSubmited ?
                            <CircularProgress />
                            :
                            <Button
                                disabled={isSubmited}
                                type="submit"
                                style={{ backgroundColor: '#27B6CC' }}
                                variant="contained"
                            >
                                <span style={{ color: 'white' }}>Save details</span>
                            </Button>
                        }
                    </Box>

                </form>
            )}
        </Formik>
    );
};

ServiceCompletedPartnerPayment.propTypes = {
    className: PropTypes.string
};

export default ServiceCompletedPartnerPayment;