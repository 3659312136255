
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
  TextareaAutosize,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { updateMeepaisazone, uploadZoneImage } from 'src/store/actions/meepaisazoneAction';

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  TextareaAutosize: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditMeepaisaZone = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  let { state } = location;

  const isSubmited = useSelector(state => state.zone.zoneUpdate.isLoading);
  const errorMessage = useSelector(state => state.zone.zoneUpdate.error);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);


  // img upload
  const [image, setImage] = useState({ preview: state.Zone_Image, raw: state.Zone_Image });
  const [imgUrl, setImgUrl] = useState(state.Zone_Image);
  const [uploadPic, setUploadPic] = useState(false);

  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadZoneImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        Zone_Id: state.Zone_Id ? state.Zone_Id : "",
        Zone_Name: state.Zone_Name ? state.Zone_Name : "",
        Zone_Video: state.Zone_Video ? state.Zone_Video : "",
        Zone_Description: state.Zone_Description ? state.Zone_Description : "",
        Zone_Image: imgUrl,
        Is_Active: state.Is_Active > 0 ? true : false,
        Modified_By: modifiedBy,
      }}
      validationSchema={Yup.object().shape({
        Zone_Description: Yup.string().required('Zone Description is required.'),
      })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        const errors = {};
        setSubmitting(false);
        setErrors(errors);
        values.Zone_Image = imgUrl


        if (!values.Zone_Image) {
          submitTest = false;
          errors.Zone_Image = "Image is required.";
        }

        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(updateMeepaisazone(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container>
                <Grid container spacing={3}>
                  <Grid item md={8} xs={12}>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Zone Name"
                          name="Zone_Name"
                          value={values.Zone_Name}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      {/*  Description  */}
                      <Grid item md={12} xs={12}>
                        Zone Description
                        <TextareaAutosize
                          value={values.Zone_Description}
                          onChange={handleChange}
                          name="Zone_Description"
                          onBlur={handleBlur}
                          variant="outlined"
                          className={classes.TextareaAutosize}
                          minRows={10}
                          placeholder="Zone Description"
                        >
                        </TextareaAutosize>
                        <span style={{ color: "red" }}>
                          {((touched.Zone_Description && errors.Zone_Description))}
                        </span>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Zone Video URL"
                          name="Zone_Video"
                          onChange={handleChange}
                          value={values.Zone_Video}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Box display="flex" alignItems="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                onBlur={handleBlur}
                                checked={values.Is_Active}
                                onChange={handleChange}
                                name="Is_Active"
                                color="primary"
                              />
                            }
                            label="Is Active"
                          />
                        </Box>
                      </Grid>

                    </Grid>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Card>
                      &nbsp;Image Path
                      <CardContent>
                        <Box display="flex" justifyContent="center" >
                          {image.preview ? (
                            <Avatar
                              className={classes.images}
                              alt="logo Upload"
                              src={image.preview}
                              variant="square"
                            />
                          ) : (
                            <>
                              <Avatar
                                className={classes.images}
                                alt="logo Upload"
                                src="/static/images/image-placeholder.png"
                                variant="square"
                              />
                            </>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          {image.preview ? <Button
                            style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                            onClick={handleRemoveImage}
                          >
                            Remove Picture
                          </Button> : <input
                            style={{ display: 'block', width: '80%' }}
                            id="upload-photo"
                            name="image"
                            type="file"
                            accept="image/*"
                            onChange={handleImage}
                          />}
                          <br />
                        </Box>

                      </CardContent>
                    </Card>
                    <span style={{ color: "red" }}>
                      {!imgUrl && touched.Zone_Image && errors.Zone_Image}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2}>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" pl={2} pt={1} pb={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited || uploadPic}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditMeepaisaZone.propTypes = {
  className: PropTypes.string
};

export default EditMeepaisaZone;