import { toast } from 'react-toastify';
import { corpimageuploadApi, nodeCorpMongoApi } from './commonAxios';

import { paginationCorpCategories } from './node-actions/nodepagination';
export const CORP_CATEGORY_CREATE_REQUEST = "CORP_CATEGORY_CREATE_REQUEST";
export const CORP_CATEGORY_CREATE_SUCCESS = "CORP_CATEGORY_CREATE_SUCCESS";
export const CORP_CATEGORY_CREATE_FAIL = "CORP_CATEGORY_CREATE_FAIL";
export const CORP_CATEGORY_UPDATE_REQUEST = "CORP_CATEGORY_UPDATE_REQUEST";
export const CORP_CATEGORY_UPDATE_SUCCESS = "CORP_CATEGORY_UPDATE_SUCCESS";
export const CORP_CATEGORY_UPDATE_FAIL = "CORP_CATEGORY_UPDATE_FAIL";

export const CATEGORY_UNIT_GET_REQUEST = "CATEGORY_UNIT_GET_REQUEST";
export const CATEGORY_UNIT_GET_SUCCESS = "CATEGORY_UNIT_GET_SUCCESS";
export const CATEGORY_UNIT_GET_FAIL = "CATEGORY_UNIT_GET_FAIL";
/*=============================================================
                  Add Category Action
===============================================================*/
export const addCategoryAction = (formData, navigate, setErrorMessage, setErrors) => async (dispatch) => {
  dispatch({
    type: CORP_CATEGORY_CREATE_REQUEST
  });
  setErrorMessage({ global: { key: '', message: '' } });
  try {
    const response = await nodeCorpMongoApi.post(`/corpSAProductCategory/create`, formData);
    let successResponse = response.data;
    if (successResponse.code === 201) {
      dispatch({
        type: CORP_CATEGORY_CREATE_SUCCESS
      });
      toast('Category added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      navigate('/app/corporate-category-management');
    } else {
      const errors = {};
      if (successResponse.message) {
        errors.global = successResponse.message;
      }
      setErrors(errors);
      setErrorMessage({ global: { key: '', message: successResponse.message || '' } });
      dispatch({
        type: CORP_CATEGORY_CREATE_FAIL,
        payload: successResponse.message
      });
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || "Currently server is not working. Please try again later.";
    setErrorMessage({ global: { key: '', message: errorMessage } });
    dispatch({
      type: CORP_CATEGORY_CREATE_FAIL,
      payload: errorMessage
    });
  }
};

/*=============================================================
                  Delete Category Action
===============================================================*/
export const deleteCategory = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCorpMongoApi.post(`/corpSAProductCategory/delete`, formData);
    if (data) {
      toast('Category deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationCorpCategories(filters, pagination, callBackPagination));
    }
  } catch (err) { }
};

/*=============================================================
                Update Category Action
===============================================================*/
export const updateCategory = (formData, navigate, setErrorMessage, setErrors) => async (dispatch) => {
  dispatch({
    type: CORP_CATEGORY_UPDATE_REQUEST
  });
  setErrorMessage({ global: { key: '', message: '' } });
  try {
    const response = await nodeCorpMongoApi.post(`/corpSAProductCategory/update`, formData);
    let successResponse = response.data;
    if (successResponse.code === 200) {
      dispatch({
        type: CORP_CATEGORY_UPDATE_SUCCESS
      });
      toast('Category updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      navigate('/app/corporate-category-management');
    } else {
      const errors = {};
      if (successResponse.message) {
        errors.global = successResponse.message;
      }
      setErrors(errors);
      setErrorMessage({ global: { key: '', message: successResponse.message || '' } });
      dispatch({
        type: CORP_CATEGORY_UPDATE_FAIL,
        payload: successResponse.message
      });
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || "Currently server is not working. Please try again later.";
    setErrorMessage({ global: { key: '', message: errorMessage } });
    dispatch({
      type: CORP_CATEGORY_UPDATE_FAIL,
      payload: errorMessage
    });
  }
};

/*=============================================================
              UploadCategoryImage Action
===============================================================*/
export const uploadCategoryImage = (
  formData,
  callBackUploadImgae,
) => async dispatch => {
  try {
    let { data } = await corpimageuploadApi.post(`/`, formData);
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};


//node API
export const getAllStoretypes = () => async dispatch => {
  try {
    let formData = {};
    dispatch({
      type: CATEGORY_UNIT_GET_REQUEST
    });
    let { data } = await nodeCorpMongoApi.get(`/corpSAProductCategory/measurementUnitsGet`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CATEGORY_UNIT_GET_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CATEGORY_UNIT_GET_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again", 3000);
    dispatch({
      type: CATEGORY_UNIT_GET_FAIL,
      payload: err
    });
  }
};