import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    ListItemText, TextareaAutosize, Checkbox, FormControlLabel
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress, Rating } from '@mui/material';
import { updateBiddingUserProductAction, getAllUserAddress, getAllBiddingProducts, getAllUserEmails, getAllbiddingproducts, getAllPartnerBiddingQuotes } from 'src/store/actions/biddinguserproductsAction';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";


const paymentmodeOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'ONLINE',
        label: 'ONLINE'
    },
    {
        value: 'COD',
        label: 'COD'
    }
];

const BidstatusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'INITIATED',
        label: 'INITIATED'
    },
    {
        value: 'PROCESSING',
        label: 'PROCESSING'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
    {
        value: 'COMPLETED',
        label: 'COMPLETED'
    },
];

const useStyles = makeStyles(() => ({
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    },
    TextareaAutosize: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const EditBiddingUserProducts = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const location = useLocation();
    let { state } = location;

    useEffect(() => {
        const quoteformData = {
            search: '',
            search_by_filter: '',
            Records_Filter: "FILTER",
            User_Products_Bidding_Id: state?.User_Products_Bidding_Id,

        };
        let data = {
            Records_Filter: 'FILTER',
            User_Email_Id: state.User_Email_Id
        }
        let formdata = {
            Records_Filter: 'FILTER',
            Bidding_Product_Id: state.Bidding_Product_Id,
            search: "",
            search_by_filter: "",
            Product_Name: "",
            Status: "",
        }
        dispatch(getAllBiddingProducts())
        dispatch(getAllUserAddress(data, callBackUserAddrData))
        dispatch(getAllbiddingproducts(formdata, callBacksubtypeproductsData))
        dispatch(getAllUserEmails(state.User_Email_Id))
        dispatch(getAllPartnerBiddingQuotes(quoteformData))
    }, []);


    const isSubmitted = useSelector(state => state.biddinguserproducts.biddinguserproductUpdate.isLoading);
    const errorMessage = useSelector(state => state.biddinguserproducts.biddinguserproductUpdate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const useremailssearch = useSelector(state => state.biddinguserproducts.BidUserEmails.biduseremails);
    const products = useSelector(state => state.biddinguserproducts.Biddingproducts.products);
    const quotes = useSelector(state => state.biddinguserproducts.PartnerBiddingQuotes.quotes);


    const [addressdata, setAddressData] = React.useState([]);
    let USERADDRESS = []
    if (addressdata) {
        for (let i = 0; i < addressdata?.length; i++) {
            USERADDRESS.push(
                {
                    label: addressdata[i].User_Address_Id,
                    value: addressdata[i].User_Address_Id,
                })
        }
    }

    const callBackUserAddrData = (data) => {
        setAddressData(data)
    }

    const setAddrType = (value) => {
        if (value !== "") {
            let data = {
                Records_Filter: 'FILTER',
                User_Email_Id: value
            }
            dispatch(getAllUserAddress(data, callBackUserAddrData))
        }
    }

    const [productsdata, setProductsData] = React.useState([]);
    const callBacksubtypeproductsData = (data) => {
        setProductsData(data[0].Measurement_Type.split(",").map(Values => ({ Measurement_Type: Values })))
    }

    const setproductType = (value) => {
        if (value !== "") {
            let data = {
                search: "",
                search_by_filter: "",
                Records_Filter: 'FILTER',
                Product_Name: "",
                Status: "",
                Bidding_Product_Id: value,
            }
            dispatch(getAllbiddingproducts(data, callBacksubtypeproductsData))
        }
    }

    // Schedule Call
    let nDate = new Date();
    let month = String(nDate.getMonth() + 1).length > 1 ? String(nDate.getMonth() + 1) : "0" + String(nDate.getMonth() + 1);
    let date = String(nDate.getDate()).length > 1 ? String(nDate.getDate()) : "0" + String(nDate.getDate());
    const [dos, setDos] = useState(nDate.getFullYear() + "-" + month +
        "-" + date);

    return (
        <Formik
            initialValues={{
                Bidding_Expiry_Date: state.Bidding_Expiry_Date ? state.Bidding_Expiry_Date : "",
                Bidding_Product_Id: state.Bidding_Product_Id ? state.Bidding_Product_Id : "",
                Measurement_Type: state.Measurement_Type ? state.Measurement_Type : "",
                Description: state.Description ? state.Description : "",
                Modified_By: loginEmail,
                Quantity: state.Quantity ? state.Quantity : "",
                User_Pincode: state.User_Pincode ? state.User_Pincode : "",
                User_Email_Id: state.User_Email_Id ? state.User_Email_Id : "",
                User_Products_Bidding_Id: state.User_Products_Bidding_Id ? state.User_Products_Bidding_Id : "",
                Bidding_Status: state.Bidding_Status ? state.Bidding_Status : "",
                Cancelled_Reason: state.Cancelled_Reason ? state.Cancelled_Reason : "",
                Esi_Commision_Amount_After_Tax: state.Esi_Commision_Amount_After_Tax ? state.Esi_Commision_Amount_After_Tax : "",
                Esi_Commision_Amount_Before_Tax: state.Esi_Commision_Amount_Before_Tax ? state.Esi_Commision_Amount_Before_Tax : "",
                Esi_Commision_Tax_Amount: state.Esi_Commision_Tax_Amount ? state.Esi_Commision_Tax_Amount : "",
                Esi_Commision_Tax_Percentage: state.Esi_Commision_Tax_Percentage ? state.Esi_Commision_Tax_Percentage : "",
                Finalize_Partner_bidding_quote_id: state.Finalize_Partner_Bidding_Quote_Id ? state.Finalize_Partner_Bidding_Quote_Id : "",
                Is_Partner_Amount_Settled: state.Is_Partner_Amount_Settled ? state.Is_Partner_Amount_Settled : "",
                Online_Transaction_Amount: state.Online_Transaction_Amount ? state.Online_Transaction_Amount : "",
                Partner_Advance_Amount: state.Partner_Advance_Amount ? state.Partner_Advance_Amount : "",
                Partner_Balance_Amount: state.Partner_Balance_Amount ? state.Partner_Balance_Amount : "",
                Partner_Payment_Transaction_Id: state.Partner_Payment_Transaction_Id ? state.Partner_Payment_Transaction_Id : "",
                Partner_Total_Amount: state.Partner_Total_Amount ? state.Partner_Total_Amount : "",
                Payment_Mode: state.Payment_Mode ? state.Payment_Mode : "",
                Payment_Transaction_Id: state.Payment_Transaction_Id ? state.Payment_Transaction_Id : "",
                Total_Advance_Amount: state.Total_Advance_Amount ? state.Total_Advance_Amount : "",
                Wallet_Amount: state.Wallet_Amount ? state.Wallet_Amount : "",
                User_Rating_For_Partner: state.User_Rating_For_Partner ? state.User_Rating_For_Partner : "",
                User_Rating_For_Product: state.User_Rating_For_Product ? state.User_Rating_For_Product : "",
                User_Review_For_Partner: state.User_Review_For_Partner ? state.User_Review_For_Partner : "",
                User_Review_For_Product: state.User_Review_For_Product ? state.User_Review_For_Product : "",
                User_Address_Id: state.User_Address_Id ? state.User_Address_Id : "",
                Advance_Online_Amount: state.Advance_Online_Amount ? state.Advance_Online_Amount : "",
                Advance_Online_Transaction_Id: state.Advance_Online_Transaction_Id ? state.Advance_Online_Transaction_Id : "",
                Advance_Payment_Status_From_User: state.Advance_Payment_Status_From_User > 0 ? true : false,
                Advance_Wallet_Amount: state.Advance_Wallet_Amount ? state.Advance_Wallet_Amount : "",
                ESI_Commission_Amount: state.Esi_Commision_Amount ? state.Esi_Commision_Amount : "",
                Online_Amount: state.Online_Amount ? state.Online_Amount : "",
                Online_Transaction_Id: state.Online_Transaction_Id ? state.Online_Transaction_Id : "",
                Remaining_Amount_After_Advance_Amount: state.Remaining_Amount_After_Advance_Amount ? state.Remaining_Amount_After_Advance_Amount : "",
                Is_Partner_Amount_Settled: state.Is_Partner_Amount_Settled > 0 ? true : false,
                Final_Payment_Status_From_User: state.Final_Payment_Status_From_User > 0 ? true : false,
                Partner_Advance_Online_Amount: state.Partner_Advance_Online_Amount ? state.Partner_Advance_Online_Amount : "",
                Partner_Advance_Wallet_Amount: state.Partner_Advance_Wallet_Amount ? state.Partner_Advance_Wallet_Amount : "",
                Partner_Final_Online_Amount: state.Partner_Final_Online_Amount ? state.Partner_Final_Online_Amount : "",
                Partner_Final_Wallet_Amount: state.Partner_Final_Wallet_Amount ? state.Partner_Final_Wallet_Amount : "",
                Is_Partner_Advance_Amount_Settled: state.Is_Partner_Advance_Amount_Settled > 0 ? true : false,
                Advance_Bank_Fee: state.Advance_Bank_Fee ? state.Advance_Bank_Fee : "",
                Advance_Wallet_History_Id: state.Advance_Wallet_History_Id ? state.Advance_Wallet_History_Id : "",
                Bidding_Completion_Date: state.Bidding_Completion_Date ? state.Bidding_Completion_Date : "",
                Cashback_Amount: state.Cashback_Amount ? state.Cashback_Amount : "",
                Final_Wallet_History_Id: state.Final_Wallet_History_Id ? state.Final_Wallet_History_Id : "",
                Is_Cashback_Settled: state.Is_Cashback_Settled > 0 ? true : false,
                Partner_Advance_Wallet_History_Id: state.Partner_Advance_Wallet_History_Id ? state.Partner_Advance_Wallet_History_Id : "",
                Partner_Final_Wallet_History_Id: state.Partner_Final_Wallet_History_Id ? state.Partner_Final_Wallet_History_Id : "",
                Final_Bank_Fee: state.Final_Bank_Fee ? state.Final_Bank_Fee : "",
            }}

            validationSchema={
                Yup.object().shape({
                    Bidding_Product_Id: Yup.string().required('Bidding Product ID is required.'),
                    Quantity: Yup.number().min(0, "Qunatity should not be less than 0.").required('Quantity is required.'),
                    // User_Email_Id: Yup.string().required('User Email ID is required.'),
                    User_Pincode: Yup.string().matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, 'Please provide valid Pin Code.').required('Pin Code is required.'),
                    Total_Advance_Amount: Yup.number().min(0, "Total Advance Amount should not be less than 0."),
                    Wallet_Amount: Yup.number().min(0, "Wallet Amount should not be less than 0."),
                    Online_Transaction_Amount: Yup.number().min(0, "Online Transaction Amount should not be less than 0."),
                    Partner_Advance_Amount: Yup.number().min(0, "Partner Advance Amount should not be less than 0."),
                    Partner_Balance_Amount: Yup.number().min(0, "Partner Balance Amount should not be less than 0."),
                    Esi_Commision_Tax_Amount: Yup.number().min(0, "Esi Commision Tax Amount should not be less than 0."),
                    Partner_Total_Amount: Yup.number().min(0, "Partner Total Amount should not be less than 0."),
                    Partner_Advance_Online_Amount: Yup.number().min(0, "Partner Advance Online Amount should not be less than 0."),
                    Partner_Advance_Wallet_Amount: Yup.number().min(0, "Partner Advance Wallet Amount should not be less than 0."),
                    Partner_Final_Online_Amount: Yup.number().min(0, "Partner Final Online Amount should not be less than 0."),
                    Partner_Final_Wallet_Amount: Yup.number().min(0, "Partner Final Wallet Amount should not be less than 0."),
                    Cashback_Amount: Yup.number().min(0, "Partner Final Wallet Amount should not be less than 0."),
                    Bidding_Expiry_Date: Yup.string().test("date-of-service", "Bidding Expiry Date cannot be past date.", (value) => {
                        if (value) {
                            let date1 = new Date(value).getTime();
                            let date2 = new Date(dos).getTime();
                            if (date1 >= date2) {
                                return true;
                            }
                        }
                        return false;
                    }),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};

                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(updateBiddingUserProductAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={6} xs={12}>
                                            {/* <Autocomplete
                                                freeSolo
                                                options={useremailssearch}
                                                groupBy={(option) => option?.firstLetter}
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option?.Email_Id || ""}
                                                onChange={(e, value) => {
                                                    setFieldValue("User_Email_Id", value?.Email_Id ? value?.Email_Id : "");
                                                    setAddrType(e.target.value)
                                                }}
                                                value={useremailssearch?.length > 0 ? useremailssearch?.filter((item) => item?.Email_Id === values?.User_Email_Id)[0] : ""}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                        <ListItemText
                                                            primary={option?.Email_Id}
                                                        />
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        error={Boolean(touched.User_Email_Id && errors.User_Email_Id)}
                                                        fullWidth
                                                        helperText={touched.User_Email_Id && errors.User_Email_Id}
                                                        {...params}
                                                        placeholder="Search"
                                                        variant="outlined"
                                                        label="User Email"
                                                        name="User_Email_Id"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off', // disable autocomplete and autofill,
                                                            startAdornment: (
                                                                <React.Fragment>
                                                                    <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                    {params.InputProps.startAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            /> */}
                                            <TextField
                                                error={Boolean(touched.User_Email_Id && errors.User_Email_Id)}
                                                fullWidth
                                                helperText={touched.User_Email_Id && errors.User_Email_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="User Email ID"
                                                name="User_Email_Id"
                                                disabled
                                                value={values.User_Email_Id}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <Autocomplete
                                                freeSolo
                                                options={addressdata}
                                                groupBy={(option) => option?.firstLetter}
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option?.Location || ""}
                                                onChange={(e, value) => {
                                                    setFieldValue("User_Address_Id", value?.User_Address_Id ? value?.User_Address_Id : "");
                                                }}
                                                value={addressdata?.length > 0 ? addressdata?.filter((item) => item?.User_Address_Id === values?.User_Address_Id)[0] : ""}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                        <ListItemText
                                                            primary={option?.Location}
                                                            secondary={option?.Address_Type + " - " + option?.Street + " - " + option?.City + " - " + option?.State + " - " + option?.Country + " - " + option?.Zip}
                                                        />
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        error={Boolean(touched.User_Address_Id && errors.User_Address_Id)}
                                                        fullWidth
                                                        helperText={touched.User_Address_Id && errors.User_Address_Id}
                                                        {...params}
                                                        placeholder="Search"
                                                        variant="outlined"
                                                        label="User Address ID"
                                                        name="User_Address_Id"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off', // disable autocomplete and autofill,
                                                            startAdornment: (
                                                                <React.Fragment>
                                                                    <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                    {params.InputProps.startAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />

                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.User_Pincode && errors.User_Pincode)}
                                                fullWidth
                                                helperText={touched.User_Pincode && errors.User_Pincode}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="User Pincode"
                                                name="User_Pincode"
                                                value={values.User_Pincode}
                                                variant="outlined"
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                                                }}
                                                min={0}
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Product_Id && errors.Bidding_Product_Id)}
                                                fullWidth
                                                helperText={touched.Bidding_Product_Id && errors.Bidding_Product_Id}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setproductType(e.target.value)
                                                }}
                                                label="Bidding Product Name"
                                                name="Bidding_Product_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Product_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">-Please Select-</option>
                                                {products?.sort((a, b) => a.Product_Name?.localeCompare(b.Product_Name))?.map(option => (
                                                    <option key={option.Bidding_Product_Id} value={option.Bidding_Product_Id}>
                                                        {option.Product_Name.substring(0, 35)}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>


                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Quantity && errors.Quantity)}
                                                fullWidth
                                                helperText={touched.Quantity && errors.Quantity}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Quantity"
                                                name="Quantity"
                                                value={values.Quantity}
                                                variant="outlined"
                                                type='number'
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Measurement_Type && errors.Measurement_Type)}
                                                fullWidth
                                                helperText={touched.Measurement_Type && errors.Measurement_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Measurement Type"
                                                name="Measurement_Type"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Measurement_Type}
                                                variant="outlined"
                                            >
                                                <option key="" value="">-Please Select-</option>
                                                {productsdata?.map((option, i) => (
                                                    <option key={i} value={option.Measurement_Type}>
                                                        {option.Measurement_Type}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}
                                                fullWidth
                                                helperText={touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="date"
                                                label="Bidding Expiry Date"
                                                name="Bidding_Expiry_Date"
                                                value={values.Bidding_Expiry_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Status && errors.Bidding_Status)}
                                                fullWidth
                                                helperText={touched.Bidding_Status && errors.Bidding_Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Status"
                                                name="Bidding_Status"
                                                value={values.Bidding_Status}
                                                variant="outlined"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                            >
                                                {BidstatusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {values.Bidding_Status === 'CANCELLED' && (
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    error={Boolean(touched.Cancelled_Reason && errors.Cancelled_Reason)}
                                                    fullWidth
                                                    helperText={touched.Cancelled_Reason && errors.Cancelled_Reason}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Cancelled Reason"
                                                    name="Cancelled_Reason"
                                                    value={values.Cancelled_Reason}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        )}

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.ESI_Commission_Amount && errors.ESI_Commission_Amount)}
                                                fullWidth
                                                helperText={touched.ESI_Commission_Amount && errors.ESI_Commission_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ESI Commission Amount"
                                                name="ESI_Commission_Amount"
                                                value={values.ESI_Commission_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Esi_Commision_Tax_Amount && errors.Esi_Commision_Tax_Amount)}
                                                fullWidth
                                                helperText={touched.Esi_Commision_Tax_Amount && errors.Esi_Commision_Tax_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ESI Commision Tax Amount"
                                                name="Esi_Commision_Tax_Amount"
                                                value={values.Esi_Commision_Tax_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Esi_Commision_Tax_Percentage && errors.Esi_Commision_Tax_Percentage)}
                                                fullWidth
                                                helperText={touched.Esi_Commision_Tax_Percentage && errors.Esi_Commision_Tax_Percentage}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ESI Commision Tax Percentage"
                                                name="Esi_Commision_Tax_Percentage"
                                                value={values.Esi_Commision_Tax_Percentage}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Esi_Commision_Amount_Before_Tax && errors.Esi_Commision_Amount_Before_Tax)}
                                                fullWidth
                                                helperText={touched.Esi_Commision_Amount_Before_Tax && errors.Esi_Commision_Amount_Before_Tax}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ESI Commision Amount Before Tax"
                                                name="Esi_Commision_Amount_Before_Tax"
                                                value={values.Esi_Commision_Amount_Before_Tax}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Esi_Commision_Amount_After_Tax && errors.Esi_Commision_Amount_After_Tax)}
                                                fullWidth
                                                helperText={touched.Esi_Commision_Amount_After_Tax && errors.Esi_Commision_Amount_After_Tax}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="ESI Commision Amount After Tax"
                                                name="Esi_Commision_Amount_After_Tax"
                                                value={values.Esi_Commision_Amount_After_Tax}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Online_Transaction_Amount && errors.Online_Transaction_Amount)}
                                                fullWidth
                                                helperText={touched.Online_Transaction_Amount && errors.Online_Transaction_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Online Transaction Amount"
                                                name="Online_Transaction_Amount"
                                                value={values.Online_Transaction_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Advance_Amount && errors.Partner_Advance_Amount)}
                                                fullWidth
                                                helperText={touched.Partner_Advance_Amount && errors.Partner_Advance_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Advance Amount"
                                                name="Partner_Advance_Amount"
                                                value={values.Partner_Advance_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Balance_Amount && errors.Partner_Balance_Amount)}
                                                fullWidth
                                                helperText={touched.Partner_Balance_Amount && errors.Partner_Balance_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Balance Amount"
                                                name="Partner_Balance_Amount"
                                                value={values.Partner_Balance_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Total_Amount && errors.Partner_Total_Amount)}
                                                fullWidth
                                                helperText={touched.Partner_Total_Amount && errors.Partner_Total_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Total Amount"
                                                name="Partner_Total_Amount"
                                                value={values.Partner_Total_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Total_Advance_Amount && errors.Total_Advance_Amount)}
                                                fullWidth
                                                helperText={touched.Total_Advance_Amount && errors.Total_Advance_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Total Advance Amount"
                                                name="Total_Advance_Amount"
                                                value={values.Total_Advance_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Advance_Online_Amount && errors.Advance_Online_Amount)}
                                                fullWidth
                                                helperText={touched.Advance_Online_Amount && errors.Advance_Online_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Online Amount"
                                                name="Advance_Online_Amount"
                                                value={values.Advance_Online_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Online_Amount && errors.Online_Amount)}
                                                fullWidth
                                                helperText={touched.Online_Amount && errors.Online_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Online Amount"
                                                name="Online_Amount"
                                                value={values.Online_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Advance_Wallet_Amount && errors.Advance_Wallet_Amount)}
                                                fullWidth
                                                helperText={touched.Advance_Wallet_Amount && errors.Advance_Wallet_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Wallet Amount"
                                                name="Advance_Wallet_Amount"
                                                value={values.Advance_Wallet_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Wallet_Amount && errors.Wallet_Amount)}
                                                fullWidth
                                                helperText={touched.Wallet_Amount && errors.Wallet_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Wallet Amount"
                                                name="Wallet_Amount"
                                                value={values.Wallet_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Payment_Transaction_Id && errors.Partner_Payment_Transaction_Id)}
                                                fullWidth
                                                helperText={touched.Partner_Payment_Transaction_Id && errors.Partner_Payment_Transaction_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Payment Transaction ID"
                                                name="Partner_Payment_Transaction_Id"
                                                disabled
                                                value={values.Partner_Payment_Transaction_Id}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Payment_Transaction_Id && errors.Payment_Transaction_Id)}
                                                fullWidth
                                                helperText={touched.Payment_Transaction_Id && errors.Payment_Transaction_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Payment Transaction ID"
                                                name="Payment_Transaction_Id"
                                                disabled
                                                value={values.Payment_Transaction_Id}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Advance_Online_Transaction_Id && errors.Advance_Online_Transaction_Id)}
                                                fullWidth
                                                helperText={touched.Advance_Online_Transaction_Id && errors.Advance_Online_Transaction_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Online Transaction ID"
                                                name="Advance_Online_Transaction_Id"
                                                disabled
                                                value={values.Advance_Online_Transaction_Id}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Online_Transaction_Id && errors.Online_Transaction_Id)}
                                                fullWidth
                                                helperText={touched.Online_Transaction_Id && errors.Online_Transaction_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Online Transaction ID"
                                                name="Online_Transaction_Id"
                                                disabled
                                                value={values.Online_Transaction_Id}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Payment_Mode && errors.Payment_Mode)}
                                                fullWidth
                                                helperText={touched.Payment_Mode && errors.Payment_Mode}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Payment Mode"
                                                name="Payment_Mode"
                                                value={values.Payment_Mode}
                                                variant="outlined"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                            >
                                                {paymentmodeOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Finalize Partner Bidding Quote ID"
                                                name="Finalize_Partner_bidding_quote_id"
                                                value={values.Finalize_Partner_bidding_quote_id}
                                                variant="outlined"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {quotes?.map(option => (
                                                    <option key={option.Partner_Bidding_Quote_Id} value={option.Partner_Bidding_Quote_Id}>
                                                        {option.Partner_Bidding_Quote_Id + " - " + option.Partner_Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>



                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Remaining_Amount_After_Advance_Amount && errors.Remaining_Amount_After_Advance_Amount)}
                                                fullWidth
                                                helperText={touched.Remaining_Amount_After_Advance_Amount && errors.Remaining_Amount_After_Advance_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Remaining Amount After Advance Amount"
                                                name="Remaining_Amount_After_Advance_Amount"
                                                value={values.Remaining_Amount_After_Advance_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Advance_Online_Amount && errors.Partner_Advance_Online_Amount)}
                                                fullWidth
                                                helperText={touched.Partner_Advance_Online_Amount && errors.Partner_Advance_Online_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Advance Online Amount"
                                                name="Partner_Advance_Online_Amount"
                                                value={values.Partner_Advance_Online_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Advance_Wallet_Amount && errors.Partner_Advance_Wallet_Amount)}
                                                fullWidth
                                                helperText={touched.Partner_Advance_Wallet_Amount && errors.Partner_Advance_Wallet_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Advance Wallet Amount"
                                                name="Partner_Advance_Wallet_Amount"
                                                value={values.Partner_Advance_Wallet_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Final_Online_Amount && errors.Partner_Final_Online_Amount)}
                                                fullWidth
                                                helperText={touched.Partner_Final_Online_Amount && errors.Partner_Final_Online_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Final Online Amount"
                                                name="Partner_Final_Online_Amount"
                                                value={values.Partner_Final_Online_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Final_Wallet_Amount && errors.Partner_Final_Wallet_Amount)}
                                                fullWidth
                                                helperText={touched.Partner_Final_Wallet_Amount && errors.Partner_Final_Wallet_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Final Wallet Amount"
                                                name="Partner_Final_Wallet_Amount"
                                                value={values.Partner_Final_Wallet_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Advance_Wallet_History_Id && errors.Partner_Advance_Wallet_History_Id)}
                                                fullWidth
                                                helperText={touched.Partner_Advance_Wallet_History_Id && errors.Partner_Advance_Wallet_History_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Advance Wallet History ID"
                                                name="Partner_Advance_Wallet_History_Id"
                                                value={values.Partner_Advance_Wallet_History_Id}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Partner_Final_Wallet_History_Id && errors.Partner_Final_Wallet_History_Id)}
                                                fullWidth
                                                helperText={touched.Partner_Final_Wallet_History_Id && errors.Partner_Final_Wallet_History_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Final Wallet History ID"
                                                name="Partner_Final_Wallet_History_Id"
                                                value={values.Partner_Final_Wallet_History_Id}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Advance_Wallet_History_Id && errors.Advance_Wallet_History_Id)}
                                                fullWidth
                                                helperText={touched.Advance_Wallet_History_Id && errors.Advance_Wallet_History_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Partner Advance Wallet History ID"
                                                name="Advance_Wallet_History_Id"
                                                value={values.Advance_Wallet_History_Id}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Final_Wallet_History_Id && errors.Final_Wallet_History_Id)}
                                                fullWidth
                                                helperText={touched.Final_Wallet_History_Id && errors.Final_Wallet_History_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Final Wallet History ID"
                                                name="Final_Wallet_History_Id"
                                                value={values.Final_Wallet_History_Id}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Advance_Bank_Fee && errors.Advance_Bank_Fee)}
                                                fullWidth
                                                helperText={touched.Advance_Bank_Fee && errors.Advance_Bank_Fee}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Bank Fee"
                                                name="Advance_Bank_Fee"
                                                value={values.Advance_Bank_Fee}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Final_Bank_Fee && errors.Final_Bank_Fee)}
                                                fullWidth
                                                helperText={touched.Final_Bank_Fee && errors.Final_Bank_Fee}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Final Bank Fee"
                                                name="Final_Bank_Fee"
                                                value={values.Final_Bank_Fee}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Cashback_Amount && errors.Cashback_Amount)}
                                                fullWidth
                                                helperText={touched.Cashback_Amount && errors.Cashback_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Cashback Amount"
                                                name="Cashback_Amount"
                                                value={values.Cashback_Amount}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Advance_Payment_Mode && errors.Advance_Payment_Mode)}
                                                fullWidth
                                                helperText={touched.Advance_Payment_Mode && errors.Advance_Payment_Mode}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Payment Mode"
                                                name="Advance_Payment_Mode"
                                                value={values.Advance_Payment_Mode}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Completion_Date && errors.Bidding_Completion_Date)}
                                                fullWidth
                                                helperText={touched.Bidding_Completion_Date && errors.Bidding_Completion_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="datetime-local"
                                                label="Bidding Completion Date"
                                                name="Bidding_Completion_Date"
                                                value={values.Bidding_Completion_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onBlur={handleBlur}
                                                            checked={values.Is_Partner_Amount_Settled}
                                                            onChange={handleChange}
                                                            name="Is_Partner_Amount_Settled"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Is Partner Amount Settled"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onBlur={handleBlur}
                                                            checked={values.Advance_Payment_Status_From_User}
                                                            onChange={handleChange}
                                                            name="Advance_Payment_Status_From_User"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Advance Payment Status From User"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onBlur={handleBlur}
                                                            checked={values.Final_Payment_Status_From_User}
                                                            onChange={handleChange}
                                                            name="Final_Payment_Status_From_User"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Final Payment Status From User"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onBlur={handleBlur}
                                                            checked={values.Is_Partner_Advance_Amount_Settled}
                                                            onChange={handleChange}
                                                            name="Is_Partner_Advance_Amount_Settled"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Is Partner Advance Amount Settled"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onBlur={handleBlur}
                                                            checked={values.Is_Cashback_Settled}
                                                            onChange={handleChange}
                                                            name="Is_Cashback_Settled"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Is Cashback Settled"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            Description
                                            <TextareaAutosize
                                                value={values.Description}
                                                onChange={handleChange}
                                                name="Description"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className={classes.TextareaAutosize}
                                                minRows={10}
                                                placeholder="Description"
                                            >
                                            </TextareaAutosize>
                                        </Grid>
                                        {values?.Bidding_Status === "COMPLETED" ?
                                            <>
                                                <Grid item md={12} xs={12}>
                                                    User Review For Partner
                                                    <TextareaAutosize
                                                        value={values.User_Review_For_Partner}
                                                        onChange={handleChange}
                                                        name="User_Review_For_Partner"
                                                        onBlur={handleBlur}
                                                        variant="outlined"
                                                        className={classes.TextareaAutosize}
                                                        minRows={10}
                                                        placeholder="User Review For Partner"
                                                    >
                                                    </TextareaAutosize>
                                                    {/* <span style={{ color: "red" }}>
                                                {((touched.Meepaise_Review_For_Partner && errors.Meepaise_Review_For_Partner))}
                                            </span> */}
                                                </Grid>

                                                <Grid item md={12} xs={12}>
                                                    User Review For Product
                                                    <TextareaAutosize
                                                        value={values.User_Review_For_Product}
                                                        onChange={handleChange}
                                                        name="User_Review_For_Product"
                                                        onBlur={handleBlur}
                                                        variant="outlined"
                                                        className={classes.TextareaAutosize}
                                                        minRows={10}
                                                        placeholder="User Review For Service"
                                                    >
                                                    </TextareaAutosize>
                                                    {/* <span style={{ color: "red" }}>
                                                {((touched.Meepaise_Review_For_Partner && errors.Meepaise_Review_For_Partner))}
                                            </span> */}
                                                </Grid>

                                                {/* User_Rating_For_Partner  */}
                                                <Grid item md={6} xs={6}>
                                                    <Box>User Rating For Partner</Box>
                                                </Grid>
                                                <Grid item md={2} xs={4}>
                                                    <Rating
                                                        name="User_Rating_For_Partner"
                                                        onChange={(event, newValue) => {
                                                            values.User_Rating_For_Partner = newValue;
                                                            setFieldValue(newValue);
                                                        }}
                                                        value={values.User_Rating_For_Partner}
                                                        size="medium"
                                                    />
                                                </Grid>

                                                {/* User_Rating_For_Product  */}
                                                <Grid item md={6} xs={6}>
                                                    <Box>User Rating For Product</Box>
                                                </Grid>
                                                <Grid item md={2} xs={4}>
                                                    <Rating
                                                        name="User_Rating_For_Product"
                                                        onChange={(event, newValue) => {
                                                            values.User_Rating_For_Product = newValue;
                                                            setFieldValue(newValue);
                                                        }}
                                                        value={values.User_Rating_For_Product}
                                                        size="medium"
                                                    />
                                                </Grid>
                                            </>
                                            : null}
                                    </Grid>
                                </Grid>


                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditBiddingUserProducts.propTypes = {
    className: PropTypes.string
};

export default EditBiddingUserProducts;