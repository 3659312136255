import {

  NEWSLETTER_CREATE_REQUEST,
  NEWSLETTER_CREATE_SUCCESS,
  NEWSLETTER_CREATE_FAIL,
  NEWSLETTER_UPDATE_REQUEST,
  NEWSLETTER_UPDATE_SUCCESS,
  NEWSLETTER_UPDATE_FAIL,
} from '../actions/newsletteraction'

const initialState = {
  newsletterCreate: { newsletter: {}, error: '', isLoading: false },
  newsletterUpdate: { newsletter: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NEWSLETTER_CREATE_REQUEST:
      return { ...state, newsletterCreate: { newsletter: {}, error: '', isLoading: true } };
    case NEWSLETTER_CREATE_SUCCESS:
      return { ...state, newsletterCreate: { newsletter: state.newsletterCreate.newsletter, error: '', isLoading: false } };
    case NEWSLETTER_CREATE_FAIL:
      return { ...state, newsletterCreate: { newsletter: {}, error: action.payload, isLoading: false } };
    case NEWSLETTER_UPDATE_REQUEST:
      return { ...state, newsletterUpdate: { newsletter: {}, error: '', isLoading: true } };
    case NEWSLETTER_UPDATE_SUCCESS:
      return { ...state, newsletterUpdate: { newsletter: state.newsletterUpdate.newsletter, error: '', isLoading: false } };
    case NEWSLETTER_UPDATE_FAIL:
      return { ...state, newsletterUpdate: { newsletter: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}