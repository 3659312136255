import config from './config';
import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const USER_RIDE_FARE_REQUEST = "USER_RIDE_FARE_REQUEST";
export const USER_RIDE_FARE_SUCCESS = "USER_RIDE_FARE_SUCCESS";
export const USER_RIDE_FARE_FAIL = "USER_RIDE_FARE_FAIL";

export const USER_RIDE_FARE_CREATE_REQUEST = "USER_RIDE_FARE_CREATE_REQUEST";
export const USER_RIDE_FARE_CREATE_SUCCESS = "USER_RIDE_FARE_CREATE_SUCCESS";
export const USER_RIDE_FARE_CREATE_FAIL = "USER_RIDE_FARE_CREATE_FAIL";

export const USER_RIDE_FARE_UPDATE_REQUEST = "USER_RIDE_FARE_UPDATE_REQUEST";
export const USER_RIDE_FARE_UPDATE_SUCCESS = "USER_RIDE_FARE_UPDATE_SUCCESS";
export const USER_RIDE_FARE_UPDATE_FAIL = "USER_RIDE_FARE_UPDATE_FAIL";

export const USER_COMFORT_REQUEST = "USER_COMFORT_REQUEST";
export const USER_COMFORT_SUCCESS = "USER_COMFORT_SUCCESS";
export const USER_COMFORT_FAIL = "USER_COMFORT_FAIL";

//get All User Rides Action
export const getAllUserRideFaresAction = formData => async dispatch => {
  try {
    dispatch({
      type: USER_RIDE_FARE_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin_User_Ride_Vehicle_Fare/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=USER_RIDE_VEHICLE_FARE_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: USER_RIDE_FARE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({

          type: USER_RIDE_FARE_SUCCESS,
          payload: data.results

        });
      }
    }
  } catch (err) {
    dispatch({
      type: USER_RIDE_FARE_FAIL,
      payload: err
    });
  }
};

//Create User Ride Action
export const createUserRideFaresAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: USER_RIDE_FARE_CREATE_REQUEST
  });
  nodeCudApi.post(`/Super_Admin_User_Ride_Vehicle_Fare/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USER_RIDE_FARE_CREATE_SUCCESS
        });
        toast('User Ride Fare added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/user-ride-fares');
      } else {
        dispatch({
          type: USER_RIDE_FARE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USER_RIDE_FARE_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

//update User Ride Action
export const updateUserRideFareAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: USER_RIDE_FARE_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Super_Admin_User_Ride_Vehicle_Fare/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USER_RIDE_FARE_UPDATE_SUCCESS
        });
        toast('User Ride Fare updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/user-ride-fares');
      } else {

        dispatch({
          type: USER_RIDE_FARE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USER_RIDE_FARE_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

//user ride fares comfort type
export const getAllUserRideComforfttypeAction = (formData) => async dispatch => {
  try {
    dispatch({
      type: USER_COMFORT_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Rides_Vehicle_Types/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Ride_Vehicle_Type_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: USER_COMFORT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: USER_COMFORT_SUCCESS,
          payload: data.results

        });
      }
    }
  } catch (err) {
    dispatch({
      type: USER_COMFORT_FAIL,
      payload: err
    });
  }
};

//Get User Ride Fare Action
export const getUserRideFareAction = (formData, callBackUserRideFareData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Super_Admin_User_Ride_Vehicle_Fare/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Ride_Vehicle_Type_Id&SortOrder=ASC`, formData);
    if (data) {
      callBackUserRideFareData(data)
    }
  } catch (err) {
  }
};