import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../store/validations/is-empty';
import { getAllSubPackagesAction, subPackageFilter } from 'src/store/actions/subscriptionpackagesAction';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const SubsPackagesListView = () => {
    const classes = useStyles();
    const [allSubsPackages, setSubsPackages] = useState([]);
    const dispatch = useDispatch();
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);

    const handleLimitChange = event => {
        setLimit(+event.target.value);
        setPage(0);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    let subPackagesList = useSelector(state => state.subscriptionpackages.subspackageFilter.select);
    let subPackagesSearch = useSelector(state => state.subscriptionpackages.subspackageFilter.search);

    // Sorting 
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Subscription_Package_ID');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {
        try {
            if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
                return -1;
            }
            if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
                return 1;
            }
            return 0;
        } catch (error) {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        }

    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {

            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    // componentDidMount
    useEffect(() => {
        const formData = {
            Records_Filter: 'ALL',
            Subscription_Package_ID: "",
        };
        dispatch(getAllSubPackagesAction(formData));
        dispatch(subPackageFilter('search', ''));
        dispatch(subPackageFilter('select', 'All'));
    }, []);

    const subPackages = useSelector(state => state.subscriptionpackages.subspackages.all);
    const [oldSearch, setOldSearch] = useState(0);

    useEffect(() => {
        if (!isEmpty(subPackages)) {
            setSubsPackages(subPackages);
        } else {
            setSubsPackages([]);
        }
    }, [subPackages]);

    const checkSubsPackagesForTable = () => {
        if (subPackagesSearch) {
            try {
                if (subPackagesList === "All") {
                    return allSubsPackages.filter(x => {
                        let colummns = ["Subscription_Package_ID", "Package_Name", "Package_Advance_Amount", "Is_Active"];
                        for (let i = 0; i < colummns.length; i++) {
                            const mainString = typeof x[colummns[i]] === "string" ? x[colummns[i]]?.toLowerCase() : x[colummns[i]];
                            if (typeof x[colummns[i]] === "string") {
                                if (mainString.includes(subPackagesSearch?.toLowerCase())) {
                                    return true;
                                }
                            }
                            else {
                                if (mainString === subPackagesSearch) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    });
                } else {
                    return allSubsPackages.filter(x => {
                        let mainString = typeof x[subPackagesList] == "string" ? x[subPackagesList]?.toLowerCase() : x[subPackagesList];
                        let subString = typeof x[subPackagesList] == "string" ? subPackagesSearch?.toLowerCase() : subPackagesSearch;
                        if (typeof x[subPackagesList] === "string") {
                            return mainString.includes(subString);
                        }
                        else {
                            if (mainString === subPackagesSearch) {
                                return true;
                            }
                        }
                    })
                }
            } finally {
                if (page > 0) {
                    if (oldSearch !== subPackagesSearch.length) {
                        setOldSearch(subPackagesSearch.length);
                        setPage(0);
                    }
                }
                else if (oldSearch !== subPackagesSearch.length) {
                    setOldSearch(subPackagesSearch.length);
                    setPage(0);
                }
            }
        }
        else {
            return allSubsPackages
        }
    }

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/subscription-management"
                    variant="text"
                    size="small"
                    color="inherit"
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        Subscription Packages
                    </Typography>
                </Box>

                <Toolbar />
                <Box mt={3}>
                    <Results
                        allSubsPackages={stableSort(
                            checkSubsPackagesForTable(), getComparator(order, orderBy))}
                        allSubsPackagesNoFilter={allSubsPackages}
                        page={page}
                        limit={limit}
                        setPage={setPage}
                        handleLimitChange={handleLimitChange}
                        handlePageChange={handlePageChange}
                        order={order}
                        orderBy={orderBy}
                        createSortHandler={createSortHandler}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default SubsPackagesListView;