import {
  POPUP_CREATE_REQUEST,
  POPUP_CREATE_SUCCESS,
  POPUP_CREATE_FAIL,
  POPUP_UPDATE_REQUEST,
  POPUP_UPDATE_SUCCESS,
  POPUP_UPDATE_FAIL,
} from '../actions/popupAction'

const initialState = {
  popupCreate: { popup: {}, error: '', isLoading: false },
  popupUpdate: { popup: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case POPUP_CREATE_REQUEST:
      return { ...state, popupCreate: { popup: {}, error: '', isLoading: true } };
    case POPUP_CREATE_SUCCESS:
      return { ...state, popupCreate: { popup: state.popupCreate.popup, error: '', isLoading: false } };
    case POPUP_CREATE_FAIL:
      return { ...state, popupCreate: { popup: {}, error: action.payload, isLoading: false } };
    case POPUP_UPDATE_REQUEST:
      return { ...state, popupUpdate: { popup: {}, error: '', isLoading: true } };
    case POPUP_UPDATE_SUCCESS:
      return { ...state, popupUpdate: { popup: state.popupUpdate.popup, error: '', isLoading: false } };
    case POPUP_UPDATE_FAIL:
      return { ...state, popupUpdate: { popup: {}, error: action.payload, isLoading: false } };
    default:
      return state;
  }
}