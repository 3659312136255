import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createGiftVendorAction,
  getAllGiftVendorsAction,
  getAllPartnerids, uploadGiftVendorImage
} from './../../store/actions/giftvendorAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
}));

const AddGiftvendor = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  // componentDidMount
  useEffect(() => {
    const formData = {
      search: "",
      search_by_filter: ""
    };
    dispatch(getAllPartnerids());
    dispatch(getAllGiftVendorsAction(formData));
  }, []);

  const [errorMessage, setErrorMessage] = useState({ Vendor_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  const giftPartnerid = useSelector(state => state.giftvendor.giftPartnerid.partnerid);
  const isSubmitted = useSelector(state => state.giftvendor.giftvendorCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const allGiftvendors = useSelector(state => state.giftvendor.giftvendors.all);

  //Image
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };
  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadGiftVendorImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };
  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        Vendor_Name: '',
        Partner_Details_Id: '',
        Vendor_Email_Id: '',
        Status: '',
        Created_By: loginEmail,
        Gift_Vendor_Image: imgUrl
      }}

      validationSchema={
        Yup.object().shape({
          Vendor_Name: Yup.string().min(3, "Vendor Name must be at least 3 characters.")
            .notOneOf(allGiftvendors.map(item => item['Vendor_Name']), "Vendor Name is already exist.")
            .required('Vendor Name is required.'),
          Partner_Details_Id: Yup.string()
            .notOneOf(allGiftvendors.map(item => item['Partner_Details_Id']), "Partner Name is already exist.")
            .required('Partner Name is required.'),
          Vendor_Email_Id: Yup.string().email('Please provide valid Vendor Email ID.')
            .notOneOf(allGiftvendors.map(item => item['Vendor_Email_Id']), "Vendor Email ID is already exist.")
            .required('Vendor Email ID is required.'),
          Status: Yup.string().required('Status is required.'),
        })
      }

      onSubmit={(values, { setErrors, setSubmitting }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        values.Gift_Vendor_Image = imgUrl;
        if (!values.Gift_Vendor_Image) {
          submitTest = false;
          errors.Gift_Vendor_Image = "Image is required.";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(createGiftVendorAction(formValues, navigate, setErrorMessage, setErrors));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}
        >
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      {/* Vendor_Name */}
                      <TextField
                        error={Boolean(touched.Vendor_Name && errors.Vendor_Name)}
                        fullWidth
                        helperText={touched.Vendor_Name && errors.Vendor_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Vendor Name"
                        name="Vendor_Name"
                        value={values.Vendor_Name}
                        variant="outlined"
                      />
                    </Grid>

                    {/*Partner_Details_Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Partner_Details_Id && errors.Partner_Details_Id)}
                        fullWidth
                        helperText={touched.Partner_Details_Id && errors.Partner_Details_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Partner Name"
                        InputLabelProps={{ shrink: true }}
                        name="Partner_Details_Id"
                        select
                        SelectProps={{ native: true }}
                        value={values.Partner_Details_Id}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {giftPartnerid
                          ?.filter(item => !(allGiftvendors?.map(item => item['Partner_Details_Id']).includes(item.Partner_Details_Id)))?.sort((a, b) => a.Name?.localeCompare(b.Name))
                          ?.map(option => (
                            <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                              {option.Name}
                            </option>
                          ))}
                      </TextField>
                    </Grid>

                    {/* Vendor_Email_Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Vendor_Email_Id && errors.Vendor_Email_Id)}
                        fullWidth
                        helperText={touched.Vendor_Email_Id && errors.Vendor_Email_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Vendor Email ID"
                        name="Vendor_Email_Id"
                        InputLabelProps={{ shrink: true }}
                        value={values.Vendor_Email_Id}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>

                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        placeholder="Status"
                        label="Status"
                        name="Status"
                        onChange={handleChange}
                        select
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Card>
                    &nbsp;Gift Vendor Image
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="logo Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="logo Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                  <span style={{ color: "red" }}>
                    {!imgUrl && touched.Gift_Vendor_Image && errors.Gift_Vendor_Image}
                  </span>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>
            <Box display="flex" p={2}>
              {isSubmitted ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmitted || uploadPic}
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#27B6CC' }}
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddGiftvendor.propTypes = {
  className: PropTypes.string
};

export default AddGiftvendor;