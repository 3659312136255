import {
  USER_RIDE_REQUEST,
  USER_RIDE_SUCCESS,
  USER_RIDE_FAIL,

  USER_RIDE_CREATE_REQUEST,
  USER_RIDE_CREATE_SUCCESS,
  USER_RIDE_CREATE_FAIL,

  USER_RIDE_UPDATE_REQUEST,
  USER_RIDE_UPDATE_SUCCESS,
  USER_RIDE_UPDATE_FAIL,

  USER_RIDE_EMAILS_REQUEST,
  USER_RIDE_EMAILS_SUCCESS,
  USER_RIDE_EMAILS_FAIL,

  USER_RIDE_VEHICLE_COMFORT_REQUEST,
  USER_RIDE_VEHICLE_COMFORT_SUCCESS,
  USER_RIDE_VEHICLE_COMFORT_FAIL,

  USER_RIDE_USERPAY_REQUEST,
  USER_RIDE_USERPAY_SUCCESS,
  USER_RIDE_USERPAY_FAIL,

  USER_RIDE_DELIPAY_REQUEST,
  USER_RIDE_DELIPAY_SUCCESS,
  USER_RIDE_DELIPAY_FAIL

} from '../actions/userridesAction'

const initialState = {
  userrides: { all: [], error: '', isLoading: false },
  userrideCreate: { userride: {}, error: '', isLoading: false },
  userrideUpdate: { userride: {}, error: '', isLoading: false },
  userrideemails: { all: [], error: '', isLoading: false },
  userridevehiclecomfort: { all: [], error: '', isLoading: false },
  userrideUserPay: { rideuserpay: {}, error: '', isLoading: false },
  userrideDeliPay: { ridedelipay: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_RIDE_REQUEST:
      return { ...state, userrides: { all: state.userrides.all, error: '', isLoading: true } };
    case USER_RIDE_SUCCESS:
      return { ...state, userrides: { all: action.payload, error: '', isLoading: false } };
    case USER_RIDE_FAIL:
      return { ...state, userrides: { all: [], error: action.payload, isLoading: false } };

    case USER_RIDE_CREATE_REQUEST:
      return { ...state, userrideCreate: { userride: {}, error: '', isLoading: true } };
    case USER_RIDE_CREATE_SUCCESS:
      return { ...state, userrideCreate: { userride: state.userrideCreate.userride, error: '', isLoading: false } };
    case USER_RIDE_CREATE_FAIL:
      return { ...state, userrideCreate: { userride: {}, error: action.payload, isLoading: false } };

    case USER_RIDE_UPDATE_REQUEST:
      return { ...state, userrideUpdate: { userride: {}, error: '', isLoading: true } };
    case USER_RIDE_UPDATE_SUCCESS:
      return { ...state, userrideUpdate: { userride: state.userrideUpdate.userride, error: '', isLoading: false } };
    case USER_RIDE_UPDATE_FAIL:
      return { ...state, userrideUpdate: { userride: {}, error: action.payload, isLoading: false } };

    case USER_RIDE_EMAILS_REQUEST:
      return { ...state, userrideemails: { all: state.userrideemails.all, error: '', isLoading: true } };
    case USER_RIDE_EMAILS_SUCCESS:
      return { ...state, userrideemails: { all: action.payload, error: '', isLoading: false } };
    case USER_RIDE_EMAILS_FAIL:
      return { ...state, userrideemails: { all: [], error: action.payload, isLoading: false } };

    case USER_RIDE_VEHICLE_COMFORT_REQUEST:
      return { ...state, userridevehiclecomfort: { all: state.userridevehiclecomfort.all, error: '', isLoading: true } };
    case USER_RIDE_VEHICLE_COMFORT_SUCCESS:
      return { ...state, userridevehiclecomfort: { all: action.payload, error: '', isLoading: false } };
    case USER_RIDE_VEHICLE_COMFORT_FAIL:
      return { ...state, userridevehiclecomfort: { all: [], error: action.payload, isLoading: false } };

    case USER_RIDE_USERPAY_REQUEST:
      return { ...state, userrideUserPay: { rideuserpay: {}, error: '', isLoading: true } };
    case USER_RIDE_USERPAY_SUCCESS:
      return { ...state, userrideUserPay: { rideuserpay: state.userrideUserPay.rideuserpay, error: '', isLoading: false } };
    case USER_RIDE_USERPAY_FAIL:
      return { ...state, userrideUserPay: { rideuserpay: {}, error: action.payload, isLoading: false } };

    case USER_RIDE_DELIPAY_REQUEST:
      return { ...state, userrideDeliPay: { ridedelipay: {}, error: '', isLoading: true } };
    case USER_RIDE_DELIPAY_SUCCESS:
      return { ...state, userrideDeliPay: { ridedelipay: state.userrideDeliPay.ridedelipay, error: '', isLoading: false } };
    case USER_RIDE_DELIPAY_FAIL:
      return { ...state, userrideDeliPay: { ridedelipay: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}