import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { updatePartnerCitylocation, getAllCitylocationsid } from 'src/store/actions/partnercitylocationAction';
import { getAllPartneraddressAction } from 'src/store/actions/partneraddressAction';
import { CircularProgress } from '@mui/material';

const EditCitypartnerlocation = ({ className, state, data, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let formData = {
      "search_by_filter": "",
      "search": "",
      "Partner_Details_Id": data?.Partner_Detail_Id,
      "Records_Filter": "FILTER"
    }
    let formValues = {
      "City_ID": data?.City_Id,
      "Records_Filter": "ALL",
      search: '',
      search_by_filter: ''
    }
    dispatch(getAllPartneraddressAction(formData));
    dispatch(getAllCitylocationsid(formValues));
  }, []);

  const Partnerid = useSelector(state => state.partneraddress?.partnersaddress?.all);
  const locations = useSelector(state => state.partnercitylocation?.citylocationids?.locationids);
  const isSubmitted = useSelector(state => state.partnercitylocation?.partnercitylocationUpdate?.isLoading);
  const errorMessage = useSelector(state => state.partnercitylocation?.partnercitylocationUpdate?.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        Partner_Detail_Id: data?.Partner_Detail_Id,
        Location_Id: data?.Location_Id,
        City_Id: data?.City_Id ? data?.City_Id : "",
        Updated_By: loginEmail,
        Is_Active: data?.Is_Active > 0 ? true : false,
        Is_Managed_By_Meepaisa: data?.Is_Managed_By_Meepaisa > 0 ? true : false,
        Is_Partner_Delivery_Available: data?.Is_Partner_Delivery_Available > 0 ? true : false,
        Is_Pickup_Available: data?.Is_Pickup_Available > 0 ? true : false,
        Partner_Address_Id: data?.Partner_Address_Id,
        Shop_By_Location_In_City_Id: data?.Shop_By_Location_In_City_Id,
      }}

      onSubmit={(values, { setErrors, setSubmitting }) => {
        setSubmitting(false);
        const errors = {};
        setErrors(errors);

        let formValues = JSON.parse(JSON.stringify(values));
        if (values.Is_Active) {
          formValues.Is_Active = 1;
        } else {
          formValues.Is_Active = 0;
        } if (values.Is_Managed_By_Meepaisa) {
          formValues.Is_Managed_By_Meepaisa = 1;
        } else {
          formValues.Is_Managed_By_Meepaisa = 0;
        } if (values.Is_Partner_Delivery_Available) {
          formValues.Is_Partner_Delivery_Available = 1;
        } else {
          formValues.Is_Partner_Delivery_Available = 0;
        }
        if (values.Is_Pickup_Available) {
          formValues.Is_Pickup_Available = 1;
        } else {
          formValues.Is_Pickup_Available = 0;
        }
        dispatch(updatePartnerCitylocation(formValues, navigate, state));
      }}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  {/* Partner_Address_Id */}
                  <TextField
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Partner Address"
                    InputLabelProps={{ shrink: true }}
                    name="Partner_Address_Id"
                    select
                    SelectProps={{ native: true }}
                    value={values.Partner_Address_Id}
                    variant="outlined"
                    disabled
                  >
                    <option key="" value="">--Please Select--</option>
                    {Partnerid?.sort((a, b) => a.City?.localeCompare(b.City))?.map(option => (
                      <option key={option.Partner_Address_Id} value={option.Partner_Address_Id}>
                        {option.City + " " + "-" + " " + option.Location + " " + "-" + " " + option.Address_Type}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={6} xs={12}>
                  {/* City_Name */}
                  <TextField
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Location Name"
                    InputLabelProps={{ shrink: true }}
                    name="Location_Id"
                    select
                    SelectProps={{ native: true }}
                    value={values.Location_Id}
                    variant="outlined"
                    disabled
                  >
                    <option key="" value="">--Please Select--</option>
                    {locations?.sort((a, b) => a.Location_Name?.localeCompare(b.Location_Name))?.map(option => (
                      <option key={option.Location_Id} value={option.Location_Id}>
                        {option.Location_Name}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onBlur={handleBlur}
                        checked={values.Is_Active}
                        onChange={handleChange}
                        name="Is_Active"
                        color="primary"
                      />
                    }
                    label="Is Active"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onBlur={handleBlur}
                        checked={values.Is_Managed_By_Meepaisa}
                        onChange={handleChange}
                        name="Is_Managed_By_Meepaisa"
                        color="primary"
                      />
                    }
                    label="Is Managed by Meepaisa"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onBlur={handleBlur}
                        checked={values.Is_Partner_Delivery_Available}
                        onChange={handleChange}
                        name="Is_Partner_Delivery_Available"
                        color="primary"
                      />
                    }
                    label="Is Partner Delivery Available"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onBlur={handleBlur}
                        checked={values.Is_Pickup_Available}
                        onChange={handleChange}
                        name="Is_Pickup_Available"
                        color="primary"
                      />
                    }
                    label="Is Pickup Available"
                  />
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2}>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmitted ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmitted}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditCitypartnerlocation.propTypes = {
  className: PropTypes.string
};

export default EditCitypartnerlocation;