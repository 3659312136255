import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Box, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minHeight: '200px',
        maxHeight: '500px',
        width: '400px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    cancelButton: {
        color: "#27B6CC",
        backgroundColor: "white",
        border: "1px solid grey",
    },
    acceptButton: {
        color: "white",
        backgroundColor: "#27B6CC",
    },
}));
function ConfirmDialog(props) {
    const { onClose, inputMessage, titleMsg, open } = props;
    const classes = useStyles();
    const handleClose = () => {
        onClose(false);
    };
    const handleAccept = () => {
        onClose(true);
    };

    return (
        <Dialog open={open} fullWidth={true}
            maxWidth={'sm'} classes={{ paper: classes.dialogPaper }}
            aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle ><Box display="flex" alignItems="center" style={{ fontSize: 20 + 'px', marginBottom: "5px", marginTop: "-5px" }} >

                <HelpOutlineOutlinedIcon style={{ fontSize: 30 + 'px', paddingRight: "5px", color: "#3f51b5" }} />

                <span>{titleMsg}</span>
                <IconButton style={{ marginTop: "-5px" }} onClick={handleClose} className={classes.closeButton}>
                    <CloseIcon />
                </IconButton>
            </Box></DialogTitle>
            <Divider style={{ marginTop: "-15px" }} />
            <DialogContent>
                <DialogContentText style={{ color: 'black' }} >
                    {inputMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button onClick={handleClose} variant="contained" className={classes.cancelButton}>
                    Cancel
                </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                <Button onClick={handleAccept} variant="contained" className={classes.acceptButton}>
                    &nbsp;&nbsp;&nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;&nbsp;
                </Button>
            </DialogActions>

        </Dialog>
    );
}

ConfirmDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    inputMessage: PropTypes.string.isRequired,
    titleMsg: PropTypes.string.isRequired
};

export default ConfirmDialog;