import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { updateBidRideBrandVehicles, getAllBiddingRideVehicles, getAllBidRideVehicleBrands } from 'src/store/actions/biddingridebrandvehiclesAction';

const statusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const EditBidRideBrandVehicle = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let { state } = location;

    useEffect(() => {
        let formData = {
            search_by_filter: "",
            search: "",
            Records_Filter: '',
        };
        dispatch(getAllBiddingRideVehicles(formData));
        dispatch(getAllBidRideVehicleBrands(formData));
    }, []);

    const isSubmitted = useSelector(state => state.bidridebrandvehicles.bidridebrandvehicleUpdate.isLoading);
    const errorMessage = useSelector(state => state.bidridebrandvehicles.bidridebrandvehicleUpdate.error);
    const bidridevehicles = useSelector(state => state.bidridebrandvehicles.bidridevehicles.all);
    const bidridevehibrands = useSelector(state => state.bidridebrandvehicles.bidridevehibrands.all);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    return (
        <Formik
            initialValues={{
                Bidding_Ride_Brand_Vehicle_Id: state.Bidding_Ride_Brand_Vehicle_Id ? state.Bidding_Ride_Brand_Vehicle_Id : "",
                Bidding_Vehicle_Id: state.Bidding_Vehicle_Id ? state.Bidding_Vehicle_Id : "",
                Bidding_Ride_Vehicle_Brand_Id: state.Bidding_Ride_Vehicle_Brand_Id ? state.Bidding_Ride_Vehicle_Brand_Id : "",
                Status: state.Status ? state.Status : "",
                Modified_By: loginEmail,
            }}
            validationSchema={
                Yup.object().shape({
                    Bidding_Vehicle_Id: Yup.string().required('Bidding Vehicle is required.'),
                    Bidding_Ride_Vehicle_Brand_Id: Yup.string().required('Bidding Ride Vehicle Brand is required.'),
                    Status: Yup.string().required('Status is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);
                const errors = {};
                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(updateBidRideBrandVehicles(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Vehicle_Id && errors.Bidding_Vehicle_Id)}
                                                fullWidth
                                                helperText={touched.Bidding_Vehicle_Id && errors.Bidding_Vehicle_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Vehicle"
                                                name="Bidding_Vehicle_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Vehicle_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {bidridevehicles?.map(option => (
                                                    <option key={option.Bidding_Vehicle_Id} value={option.Bidding_Vehicle_Id}>
                                                        {option.Vehicle_Type + " - " + option.Vehicle_Comfort_Type}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Bidding_Ride_Vehicle_Brand_Id && errors.Bidding_Ride_Vehicle_Brand_Id)}
                                                fullWidth
                                                helperText={touched.Bidding_Ride_Vehicle_Brand_Id && errors.Bidding_Ride_Vehicle_Brand_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Bidding Ride Vehicle Brand"
                                                name="Bidding_Ride_Vehicle_Brand_Id"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Bidding_Ride_Vehicle_Brand_Id}
                                                variant="outlined"
                                            >
                                                <option key="" value="">--Please Select--</option>
                                                {bidridevehibrands?.sort((a, b) => a.Product_Name?.localeCompare(b.Product_Name))?.map(option => (
                                                    <option key={option.Bidding_Ride_Vehicle_Brand_Id} value={option.Bidding_Ride_Vehicle_Brand_Id}>
                                                        {option.Brand_Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                                >
                                    <span style={{ color: 'white' }}>Save Details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditBidRideBrandVehicle.propTypes = {
    className: PropTypes.string
};

export default EditBidRideBrandVehicle;