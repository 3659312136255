import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/new-order-history',
    createdAt: '',
    description:
      'Order history is a convenient way for a buyer to keep track of all current and past orders and their status in the order process. It allows the buyer to get up-to-date information on each order placed online, including shipping updates, delivery, and payment details.',
    media: '/static/Dashboard/orderhistory.png',
    title: 'New Orders',
    total: ''
  },

  {
    id: uuid(),
    hide: 0,
    link: '/app/inprogress-order-history',
    createdAt: '',
    description:
      'Order history is a convenient way for a buyer to keep track of all current and past orders and their status in the order process. It allows the buyer to get up-to-date information on each order placed online, including shipping updates, delivery, and payment details.',
    media: '/static/Dashboard/orderhistory.png',
    title: 'In-Progress Orders',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/cmpltd-order-history',
    createdAt: '',
    description:
      'Order history is a convenient way for a buyer to keep track of all current and past orders and their status in the order process. It allows the buyer to get up-to-date information on each order placed online, including shipping updates, delivery, and payment details.',
    media: '/static/Dashboard/orderhistory.png',
    title: 'Completed Orders',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/cancelled-order-history',
    createdAt: '',
    description:
      'Order history is a convenient way for a buyer to keep track of all current and past orders and their status in the order process. It allows the buyer to get up-to-date information on each order placed online, including shipping updates, delivery, and payment details.',
    media: '/static/Dashboard/orderhistory.png',
    title: 'Cancelled Orders',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/returned-order-history',
    createdAt: '',
    description:
      'Order history is a convenient way for a buyer to keep track of all current and past orders and their status in the order process. It allows the buyer to get up-to-date information on each order placed online, including shipping updates, delivery, and payment details.',
    media: '/static/Dashboard/orderhistory.png',
    title: 'Returned Orders',
    total: ''
  },
  // {
  //   id: uuid(),
  //   hide: 0,
  //   link: '/app/b2b-order-history',
  //   createdAt: '',
  //   description:
  //     'B2B Order history is a convenient way for a buyer to keep track of all current and past orders and their status in the order process. It allows the buyer to get up-to-date information on each order placed online, including shipping updates, delivery, and payment details.',
  //   media: '/static/Dashboard/orderhistory.png',
  //   title: 'B2B Order History',
  //   total: ''
  // },
  // {
  //   id: uuid(),
  //   hide: 0,
  //   link: '/app/reviews-and-ratings',
  //   createdAt: '',
  //   description:
  //     'Review module includes to write a review that lets users sign in, assign a rating, and write a review of a product. This module also lets users edit a rating or review that they previously submitted.',
  //   media: '/static/Dashboard/reviews_and_ratings.png',
  //   title: 'Reviews & Ratings',
  //   total: ''
  // },
  // {
  //   id: uuid(),
  //   hide: 0,
  //   link: '/app/transaction-history',
  //   createdAt: '',
  //   description:
  //     'Transaction history differs from a bank statement in that it is a record of all transactions for that bank account for a set period that you have chosen.  Unlike a bank statement, a transaction history allows you to choose the period you want reflected.',
  //   media: '/static/Dashboard/transcation_history.png',
  //   title: 'Transaction History',
  //   total: ''
  // },
  // {
  //   id: uuid(),
  //   hide: 0,
  //   link: '/app/payment-and-settlements',
  //   createdAt: '',
  //   description:
  //     'Payment is the process of giving money that is owed. Settlement is an agreement to resolve a dispute. A common form of settlement is a set, monetary amount.',
  //   media: '/static/Dashboard/payment_and_settlemet.png',
  //   title: 'Payments & Settlements',
  //   total: ''
  // },
  // {
  //   id: uuid(),
  //   hide: 0,
  //   link: '/app/Shipping',
  //   createdAt: '',
  //   description:
  //     'The Order Shipping module, located in the sales group, facilitates the process of packing and shipping items that have already been picked. Typically, orders are packed before they are shipped, but these steps can be performed simultaneously in Fishbowl if necessary. ',
  //   media: '/static/Dashboard/order_shipping.jpg',
  //   title: 'Order Shipping',
  //   total: ''
  // },
  // {
  //   id: uuid(),
  //   hide: 0,
  //   link: '/app/offers-visitor',
  //   createdAt: '',
  //   description:
  //     'Offer Visitors module defines that website visitors to hurry up, buy now, or place their ... Limited-​time offers work like a charm on online shoppers  because they give best shopping experience by giving offers on products.',
  //   media: '/static/Dashboard/user_visit.jpg',
  //   title: 'Offer Visitors',
  //   total: ''
  // },
];