import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Container,
    makeStyles,
    Button,
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import { useLocation } from 'react-router-dom';
import OrderInvoiceView from './invoicePrint';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const CorpViewOrderInvoice = () => {
    const location = useLocation();
    const { state } = location;
    const classes = useStyles();

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/corp-order-info"
                    variant="text"
                    size="small"
                    color="inherit"
                    state={state}
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <OrderInvoiceView
                    state={state}
                />
            </Container>
        </Page>
    );
};

export default CorpViewOrderInvoice;