import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import isEmpty from '../../../../store/validations/is-empty';
import Pagination from 'src/components/pagination/pagination';


const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  buttonRoot: {
    minWidth: '7px',
    padding: '6px'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
    width: '150px'
  },
}));

const headCells = [
  { id: 'User_Statistics_Id', numeric: false, disablePadding: false, label: 'User Statistics ID' },
  { id: 'Time', numeric: false, disablePadding: false, label: 'Time' },
  { id: 'User_Full_Name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'Mobile_Number', numeric: false, disablePadding: false, label: 'Mobile' },
  { id: 'Email_Id', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'Event_Context', numeric: false, disablePadding: false, label: 'Event Context' },
  { id: 'Component', numeric: false, disablePadding: false, label: 'Component' },
  { id: 'Origin', numeric: false, disablePadding: false, label: 'Origin' },
  { id: 'Ip_Address', numeric: false, disablePadding: false, label: 'Ip Address' },
  { id: 'Event_Name', numeric: false, disablePadding: false, label: 'Event Name' },
  { id: 'Description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'Last_Login_Time', numeric: false, disablePadding: false, label: 'Last Login Time' },
];

const Results = ({
  className,
  allUserStatistics,
  deleteTemplateHandler,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  allUserStatisticsNoFilter,
  createSortHandler,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedStatisticsIds, setSelectedStatisticsIds] = useState([]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allUserStatistics) &&
                (allUserStatistics).map(userstatistics => (
                  <TableRow
                    hover
                    key={userstatistics.User_Statistics_Id}
                    selected={
                      selectedStatisticsIds.indexOf(userstatistics.User_Statistics_Id) !== -1
                    }
                  >
                    <TableCell>{userstatistics.User_Statistics_Id}</TableCell>
                    <TableCell>{userstatistics.Time}</TableCell>
                    <TableCell>{userstatistics.User_Full_Name}</TableCell>
                    <TableCell>{userstatistics.Mobile_Number}</TableCell>
                    <TableCell style={{ color: '#1665D8' }} >
                      {userstatistics.Email_Id?.substring(0, 20)}...
                    </TableCell>
                    <TableCell>{userstatistics.Event_Context}</TableCell>
                    <TableCell>{userstatistics.Component}</TableCell>
                    <TableCell>{userstatistics.Origin}</TableCell>
                    <TableCell>{userstatistics.Ip_Address}</TableCell>
                    <TableCell>{userstatistics.Event_Name}</TableCell>
                    <TableCell>{userstatistics.Description}</TableCell>
                    <TableCell>{userstatistics.Last_Login_Time}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allUserStatistics) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allUserStatistics: PropTypes.array.isRequired
};

export default Results;