import { boomiAdminApi } from "../commonAxios";

const urlGenerator = (url, pagination) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
}

//Get All Orders
export const boomipaginationOrder = (
    formData = {}, pagination = {}, responseFunction
) => async (dispatch) => {
    boomiAdminApi.post(urlGenerator('/Super_Admin/OrderAll_Get_Flow', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


//Get All B2B Orders
export const boomipaginationB2BOrders = (
    formData = {}, pagination = {}, responseFunction
) => async (dispatch) => {
    boomiAdminApi.post(urlGenerator('B2B_Order/Get_By_ItemMaster_Product_Get_New', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Delivery Person Vehicles
export const boomipaginationGetDeliveryPersonVehicles = (
    formData = {}, pagination = {}, responseFunction
) => async (dispatch) => {
    boomiAdminApi.post(urlGenerator('/Super_Admin_Vehicle_Details/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Delivery Person Approval Get
export const boomipaginationGetDeliveryPersonsApproval = (
    formData = {}, pagination = {}, responseFunction
) => async (dispatch) => {
    boomiAdminApi.post(urlGenerator('/Delivery_Registration/Get_Approval', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Delivery Persons
export const boomipaginationgetAllDeliveryAction = (
    formData = {}, pagination = {}, responseFunction
) => async (dispatch) => {
    boomiAdminApi.post(urlGenerator('/Delivery_Registration/Get_Approval', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All meepaisa combo products
export const boomipaginationgetAllProductCombos = (
    formData = {}, pagination = {}, responseFunction
) => async (dispatch) => {
    boomiAdminApi.post(urlGenerator('/Meepaisa_Combo_Products/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Meepaisa Zone
export const boomipaginationgetMeepaisaZoneAction = (
    formData = {}, pagination = {}, responseFunction
) => async (dispatch) => {
    boomiAdminApi.post(urlGenerator('/Meepaisa_Zone/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


//Get All Meepaisa Zone
export const boomipaginationgetMeepaisaZoneThemeAction = (
    formData = {}, pagination = {}, responseFunction
) => async (dispatch) => {
    boomiAdminApi.post(urlGenerator('/Meepaisa_Zone_Theme/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

	  //Get All Zone Theme
      export const boomipaginationTheme = (
        formData = {}, pagination = {}, responseFunction
    ) => async (dispatch) => {
        boomiAdminApi.post(urlGenerator('/Meepaisa_Theme/Get', pagination),
            formData,
        ).then(response => {
            let data = response.data;
            if (data.Success_Response.Is_Data_Exist === "0") {
                responseFunction(true, []);
            } else {
                try {
                    responseFunction(true, data.results, data.Pagination);
                } catch (error) {
                    responseFunction(true, data.results);
                }
            }
        }).catch(error => {
            responseFunction(false, error);
        });
    };
    
    //Get All Zone Theme Products
    export const boomipaginationZoneThemeProducts = (
        formData = {}, pagination = {}, responseFunction
    ) => async (dispatch) => {
        boomiAdminApi.post(urlGenerator('/Meepaisa_Zone_Theme_Products/Get', pagination),
            formData,
        ).then(response => {
            let data = response.data;
            if (data.Success_Response.Is_Data_Exist === "0") {
                responseFunction(true, []);
            } else {
                try {
                    responseFunction(true, data.results, data.Pagination);
                } catch (error) {
                    responseFunction(true, data.results);
                }
            }
        }).catch(error => {
            responseFunction(false, error);
        });
    };