
import config from './config';
import { nodeGetApi } from './commonAxios';


export const SHIPPING_REQUEST = "SHIPPING_REQUEST";
export const SHIPPING_SUCCESS = "SHIPPING_SUCCESS";
export const SHIPPING_FAIL = "SHIPPING_FAIL";

export const SHIPPING_GET_REQUEST = "SHIPPING_GET_REQUEST";
export const SHIPPING_GET_SUCCESS = "SHIPPING_GET_SUCCESS";
export const SHIPPING_GET_FAIL = "SHIPPING_GET_FAIL";

export const SHIPPING_FILTER = "SHIPPING_FILTER";


/*=============================================================
                  Get All Shipping Action
===============================================================*/
export const getAllShippingAction = formData => async dispatch => {
  try {
    dispatch({
      type: SHIPPING_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Order/Shipping_Get?PageNo=0&PageSize=${config.pageSize}&SortBy=SHIPPING_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SHIPPING_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SHIPPING_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SHIPPING_FAIL,
      payload: err
    });
  }
};

export const shippingFilter = (fType, value) => {
  return function (dispatch) {
    dispatch({
      type: SHIPPING_FILTER,
      fType: fType,
      value: value,
    });
  }
}