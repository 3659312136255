import { toast } from 'react-toastify';
import { paginationAds } from './node-actions/nodepagination';
import { boomiAdminApi, nodeCudApi } from './commonAxios';

export const ADS_CREATE_REQUEST = "ADS_CREATE_REQUEST";
export const ADS_CREATE_SUCCESS = "ADS_CREATE_SUCCESS";
export const ADS_CREATE_FAIL = "ADS_CREATE_FAIL";
export const ADS_UPDATE_REQUEST = "ADS_UPDATE_REQUEST";
export const ADS_UPDATE_SUCCESS = "ADS_UPDATE_SUCCESS";
export const ADS_UPDATE_FAIL = "ADS_UPDATE_FAIL";

/*=============================================================
                  Add ADS Action
===============================================================*/
export const createAdsAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: ADS_CREATE_REQUEST
  });
  setErrorMessage({ Ads_Title: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Advertisement/PageAds_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: ADS_CREATE_SUCCESS
        });
        toast('Ads added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/ads-management');
      } else {
        const errors = {};
        if (successResponse.Ads_Title) {
          errors.Ads_Title = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Ads_Title: { key: successResponse.Ads_Title ? successResponse.Ads_Title : '', message: successResponse.Ads_Title ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Ads_Title) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: ADS_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Ads_Title: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: ADS_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};

/*=============================================================
                edit ads Action
  =============================================================*/
export const updateAdsAction = (formData, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: ADS_UPDATE_REQUEST
  });
  setErrorMessage({ Ads_Title: { key: '', message: '' }, global: { key: '', message: '' } });
  nodeCudApi.post(`/Advertisement/PageAds_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: ADS_UPDATE_SUCCESS
        });
        toast('Ads updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/ads-management');
      } else {
        const errors = {};
        if (successResponse.Ads_Title) {
          errors.Ads_Title = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Ads_Title: { key: successResponse.Ads_Title ? successResponse.Ads_Title : '', message: successResponse.Ads_Title ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Ads_Title) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: ADS_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Ads_Title: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: ADS_UPDATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};

/*=============================================================
                Ads Image Upload Action
===============================================================*/
export const uploadAdsImage = (
  formData,
  callBackUploadImgae,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=Ads&fileextension=png&filetype=Images&filename=Ads`,
      formData,
    );
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};

/*=============================================================
                 Delete ADS Action
  =============================================================*/
export const deleteAdsAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Advertisement/PageAds_Delete`, formData);
    if (data) {
      toast('Ads deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationAds(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};