import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  images: {
    width: 70,
    height: 70,
    cursor: 'pointer'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },

}));

const headCells = [
  { id: 'User_Service_Request_Id', numeric: false, disablePadding: false, label: 'User Service Request ID' },
  { id: 'User_Email_Id', numeric: false, disablePadding: false, label: 'User Email ID' },
  { id: 'User_Service_Request_Status', numeric: false, disablePadding: false, label: 'User Service Status' },
  { id: 'Payment_Status', numeric: true, disablePadding: false, label: 'Payment Status' },
  { id: 'Payment_Method', numeric: true, disablePadding: false, label: 'Payment Method' },
];

const Results = ({
  className,
  allService,
  deleteServiceHandler,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  setPage,
  allServicesNoFilter,
  createSortHandler,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedServicesIds, setSelectedServicesIds] = useState([]);

  const visibleServicesHandler = serviceData => e => {
    navigate("/app/view-services", { state: serviceData });
  };
  const editServicesHandler = serviceData => e => {
    navigate('/app/edit-service', { state: serviceData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel style={{ fontSize: "13px", fontWeight: "bold" }}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell
                  className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-30px' }} > Actions &nbsp;&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allService) &&
                (allService).map(service => (
                  <TableRow
                    hover
                    key={service.User_Service_Request_Id}
                    selected={
                      selectedServicesIds.indexOf(service.User_Service_Request_Id) !== -1
                    }
                  >
                    <TableCell>{service.User_Service_Request_Id}</TableCell>
                    <TableCell style={{ color: '#1665D8' }}>
                      {service.User_Email_Id?.substring(0, 18)}...
                    </TableCell>
                    <TableCell>{service.User_Service_Request_Status}</TableCell>
                    <TableCell>{service.Payment_Status}</TableCell>
                    <TableCell>{service.Payment_Method}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.User_Services_Management_Write?.Value === "User_Services_Management_Write" && RolePermissions?.User_Services_Management_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Tooltip title="View Service History">
                          <Button
                            onClick={visibleServicesHandler(service)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        <Button
                          // disabled={(service.Payment_Status === "SUCCESS" && service.Payment_Method === "COD") ? true : false}
                          onClick={editServicesHandler(service)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.User_Services_Management_Read?.Value === "User_Services_Management_Read" && RolePermissions?.User_Services_Management_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="View Service History">
                          <Button
                            onClick={visibleServicesHandler(service)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allService) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allService: PropTypes.array.isRequired
};

export default Results;