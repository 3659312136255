import { Card, CardContent, Grid } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

const PartnerPaymentSettlementsDetails = ({ className, ...rest }) => {
  const location = useLocation();
  let { state } = location;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6}>

            <Grid container spacing={3}>
              {/* Order ID */}
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Order ID
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Order_Id}
                </Typography>
              </Grid>
              {/* Payment_Status */}
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Payment Status
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Payment_Status}
                </Typography>
              </Grid>
              {/* Partner_Payment_Settlement_Id */}
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Partner Payment Settlement ID
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Partner_Payment_Settlement_Id}
                </Typography>
              </Grid>
              {/* Final Settlement Amount */}
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Final Settlement Amount
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Final_Settlement_Amount}
                </Typography>
              </Grid>
              {/* Order_Product_Id */}
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Order Product ID
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Order_Product_Id}
                </Typography>
              </Grid>
              {/* Partner_Email_Id */}
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Partner Email ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  <span title={state?.Partner_Email_Id}> {state?.Partner_Email_Id?.length > 20 ?
                    <span>{state?.Partner_Email_Id?.substring(0, 20)}...</span> : state?.Partner_Email_Id}</span>
                </Typography>
              </Grid>
              {/* Created_Date */}
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Created Date
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Created_Date}
                </Typography>
              </Grid>
              {/*Partner_Type */}
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Partner Type
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Partner_Type}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/*  column 2 */}
          <Grid item md={6}>
            <Grid container spacing={3}>
              {/* Partner_Id*/}
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Partner ID
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Partner_Id}
                </Typography>
              </Grid>
              {/* Total Order Amount*/}
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Total Order Amount
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Total_Order_Amount}
                </Typography>
              </Grid>
              {/* ESI Commission Amount */}
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  ESI Commission Amount
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.ESI_Commisssion_Amount}
                </Typography>
              </Grid>
              {/*Order_Line_Item_Id */}
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Order Line Item ID
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Order_Line_Item_Id}
                </Typography>
              </Grid>
              {/*Order_Line_Item_Amount */}
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Order Line Item Amount
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Order_Line_Item_Amount}
                </Typography>
              </Grid>
              {/* Modified_By */}
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Modified By
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  <span title={state?.Modified_By}> {state?.Modified_By?.length > 20 ?
                    <span>{state?.Modified_By?.substring(0, 20)}...</span> : state?.Modified_By}</span>
                </Typography>
              </Grid>
              {/*Modified_Date */}
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ color: '#66788A' }}
                >
                  Modified Date
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ paddingLeft: '3rem' }}
                >
                  {state.Modified_Date}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PartnerPaymentSettlementsDetails;