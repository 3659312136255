import {
  CITYPARTNER_CREATE_REQUEST,
  CITYPARTNER_CREATE_SUCCESS,
  CITYPARTNER_CREATE_FAIL,
  CITYPARTNER_UPDATE_REQUEST,
  CITYPARTNER_UPDATE_SUCCESS,
  CITYPARTNER_UPDATE_FAIL,

  CITYPARTNER_PARTNERID_REQUEST,
  CITYPARTNER_PARTNERID_SUCCESS,
  CITYPARTNER_PARTNERID_FAIL,

  CITYPARTNER_CITYNAMES_REQUEST,
  CITYPARTNER_CITYNAMES_SUCCESS,
  CITYPARTNER_CITYNAMES_FAIL

} from '../actions/citypartnerAction'

const initialState = {
  citypartnerCreate: { citypartner: {}, error: '', isLoading: false },
  citypartnerUpdate: { citypartner: {}, error: '', isLoading: false },

  citypartnerid: { partnerid: [], error: '', isLoading: false },
  citypartnercitynames: { citynames: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CITYPARTNER_CREATE_REQUEST:
      return { ...state, citypartnerCreate: { citypartner: {}, error: '', isLoading: true } };
    case CITYPARTNER_CREATE_SUCCESS:
      return { ...state, citypartnerCreate: { citypartner: action.payload, error: '', isLoading: false } };
    case CITYPARTNER_CREATE_FAIL:
      return { ...state, citypartnerCreate: { citypartner: {}, error: action.payload, isLoading: false } };
    case CITYPARTNER_UPDATE_REQUEST:
      return { ...state, citypartnerUpdate: { citypartner: {}, error: '', isLoading: true } };
    case CITYPARTNER_UPDATE_SUCCESS:
      return { ...state, citypartnerUpdate: { citypartner: action.payload, error: '', isLoading: false } };
    case CITYPARTNER_UPDATE_FAIL:
      return { ...state, citypartnerUpdate: { citypartner: {}, error: action.payload, isLoading: false } };

    case CITYPARTNER_PARTNERID_REQUEST:
      return { ...state, citypartnerid: { partnerid: state.citypartnerid.partnerid, error: '', isLoading: true }, };
    case CITYPARTNER_PARTNERID_SUCCESS:
      return { ...state, citypartnerid: { partnerid: action.payload, error: '', isLoading: false } };
    case CITYPARTNER_PARTNERID_FAIL:
      return { ...state, citypartnerid: { partnerid: [], error: action.payload, isLoading: false } };

    case CITYPARTNER_CITYNAMES_REQUEST:
      return { ...state, citypartnercitynames: { citynames: state.citypartnercitynames.citynames, error: '', isLoading: true }, };
    case CITYPARTNER_CITYNAMES_SUCCESS:
      return { ...state, citypartnercitynames: { citynames: action.payload, error: '', isLoading: false } };
    case CITYPARTNER_CITYNAMES_FAIL:
      return { ...state, citypartnercitynames: { citynames: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}