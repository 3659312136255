
import {
  CORP_PRODUCTS_REQUEST,
  CORP_PRODUCTS_SUCCESS,
  CORP_PRODUCTS_FAIL,
  CORP_PRODUCTS_GET_REQUEST,
  CORP_PRODUCTS_GET_SUCCESS,
  CORP_PRODUCTS_GET_FAIL,
  CORP_PRODUCTS_CREATE_REQUEST,
  CORP_PRODUCTS_CREATE_SUCCESS,
  CORP_PRODUCTS_CREATE_FAIL,
  CORP_PRODUCTS_UPDATE_REQUEST,
  CORP_PRODUCTS_UPDATE_SUCCESS,
  CORP_PRODUCTS_UPDATE_FAIL,
  CORP_PRODUCTS_DELETE_REQUEST,
  CORP_PRODUCTS_DELETE_SUCCESS,
  CORP_PRODUCTS_DELETE_FAIL,
  CORP_PRODUCTS_BRANDS_REQUEST,
  CORP_PRODUCTS_BRANDS_SUCCESS,
  CORP_PRODUCTS_COLOURS_FAIL,
  CORP_PRODUCTS_COLOURS_REQUEST,
  CORP_PRODUCTS_COLOURS_SUCCESS,
  CORP_PRODUCTS_MASTER_FAIL,
  CORP_PRODUCTS_MASTER_REQUEST,
  CORP_PRODUCTS_MASTER_SUCCESS,
  CORP_PRODUCTS_BRANDS_FAIL,
  CORP_PRODUCTS_CATEGORIES_REQUEST,
  CORP_PRODUCTS_CATEGORIES_SUCCESS,
  CORP_PRODUCTS_CATEGORIES_FAIL,
  CORP_PRODUCTS_SUBCATEGORIES_REQUEST,
  CORP_PRODUCTS_SUBCATEGORIES_SUCCESS,
  CORP_PRODUCTS_SUBCATEGORIES_FAIL,
  CORP_PRODUCTS_ROLES_REQUEST,
  CORP_PRODUCTS_ROLES_SUCCESS,
  CORP_PRODUCTS_ROLES_FAIL,
  CORP_PRODUCTS_SIZE_REQUEST,
  CORP_PRODUCTS_SIZE_SUCCESS,
  CORP_PRODUCTS_SIZE_FAIL,

  CORP_PRODUCTS_APPROVE_REQUEST,
  CORP_PRODUCTS_APPROVE_SUCCESS,
  CORP_PRODUCTS_APPROVE_FAIL,
  CORP_PRODUCTS_MASTER_FILTER_REQUEST,
  CORP_PRODUCTS_MASTER_FILTER_SUCCESS,
  CORP_PRODUCTS_MASTER_FILTER_FAIL,
  CORP_PRODUCTS_FILTER_REQUEST,
  CORP_PRODUCTS_FILTER_SUCCESS,
  CORP_PRODUCTS_FILTER_FAIL,

  CORP_PRODUCTS_REQUEST_UPDATE_REQUEST,
  CORP_PRODUCTS_REQUEST_UPDATE_SUCCESS,
  CORP_PRODUCTS_REQUEST_UPDATE_FAIL,
} from '../actions/corporateproductAction'

const initialState = {
  products: { all: [], error: '', isLoading: false },
  product: { product: {}, error: '', isLoading: false },
  productCreate: { product: {}, error: '', isLoading: false },
  productUpdate: { product: {}, error: '', isLoading: false },
  productDelete: { product: {}, error: '', isLoading: false },
  productBrands: { brands: [], error: '', isLoading: false },
  productCategories: { categories: [], error: '', isLoading: false },
  productSubCategories: { subcategories: [], error: '', isLoading: false },
  productRoles: { roles: [], error: '', isLoading: false },
  productColours: { colours: [], error: '', isLoading: false },
  productMasters: { masters: [], error: '', isLoading: false },
  productMasterfilters: { filtermasters: [], error: '', isLoading: false },
  productSizes: { sizes: [], error: '', isLoading: false },
  relateproductsearches: { rproducts: [], error: '', isLoading: false },

  productsapproval: { all: [], error: '', isLoading: false },

  productRequestUpdate: { productrequest: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CORP_PRODUCTS_REQUEST:
      return { ...state, products: { all: state.products.all, error: '', isLoading: true } };
    case CORP_PRODUCTS_SUCCESS:
      return { ...state, products: { all: action.payload, error: '', isLoading: false } };
    case CORP_PRODUCTS_FAIL:
      return { ...state, products: { all: [], error: action.payload, isLoading: false } };
    case CORP_PRODUCTS_GET_REQUEST:
      return { ...state, product: { product: {}, error: '', isLoading: true } };
    case CORP_PRODUCTS_GET_SUCCESS:
      return { ...state, product: { product: action.payload, error: '', isLoading: false } };
    case CORP_PRODUCTS_GET_FAIL:
      return { ...state, product: { product: {}, error: action.payload, isLoading: false } };
    case CORP_PRODUCTS_CREATE_REQUEST:
      return { ...state, productCreate: { product: {}, error: '', isLoading: true } };
    case CORP_PRODUCTS_CREATE_SUCCESS:
      return { ...state, productCreate: { product: state.productCreate.product, error: '', isLoading: false } };
    case CORP_PRODUCTS_CREATE_FAIL:
      return { ...state, productCreate: { product: {}, error: action.payload, isLoading: false } };
    case CORP_PRODUCTS_UPDATE_REQUEST:
      return { ...state, productUpdate: { product: {}, error: '', isLoading: true } };
    case CORP_PRODUCTS_UPDATE_SUCCESS:
      return { ...state, productUpdate: { product: action.payload, error: '', isLoading: false } };
    case CORP_PRODUCTS_UPDATE_FAIL:
      return { ...state, productUpdate: { product: {}, error: action.payload, isLoading: false } };
    case CORP_PRODUCTS_DELETE_REQUEST:
      return { ...state, productDelete: { product: {}, error: '', isLoading: true } };
    case CORP_PRODUCTS_DELETE_SUCCESS:
      return { ...state, productDelete: { product: action.payload, error: '', isLoading: false } };
    case CORP_PRODUCTS_DELETE_FAIL:
      return { ...state, productDelete: { product: {}, error: action.payload, isLoading: false } };
    case CORP_PRODUCTS_BRANDS_REQUEST:
      return { ...state, productBrands: { brands: state.productBrands.brands, error: '', isLoading: true }, };
    case CORP_PRODUCTS_BRANDS_SUCCESS:
      return { ...state, productBrands: { brands: action.payload, error: '', isLoading: false } };
    case CORP_PRODUCTS_BRANDS_FAIL:
      return { ...state, productBrands: { brands: [], error: action.payload, isLoading: false } };
    case CORP_PRODUCTS_CATEGORIES_REQUEST:
      return { ...state, productCategories: { categories: state.productCategories.categories, error: '', isLoading: true }, };
    case CORP_PRODUCTS_CATEGORIES_SUCCESS:
      return { ...state, productCategories: { categories: action.payload, error: '', isLoading: false } };
    case CORP_PRODUCTS_CATEGORIES_FAIL:
      return { ...state, productCategories: { categories: [], error: action.payload, isLoading: false } };
    case CORP_PRODUCTS_SUBCATEGORIES_REQUEST:
      return { ...state, productSubCategories: { subcategories: state.productSubCategories.subcategories, error: '', isLoading: true }, };
    case CORP_PRODUCTS_SUBCATEGORIES_SUCCESS:
      return { ...state, productSubCategories: { subcategories: action.payload, error: '', isLoading: false } };
    case CORP_PRODUCTS_SUBCATEGORIES_FAIL:
      return { ...state, productSubCategories: { subcategories: [], error: action.payload, isLoading: false } };
    case CORP_PRODUCTS_ROLES_REQUEST:
      return { ...state, productRoles: { roles: state.productRoles.roles, error: '', isLoading: true }, };
    case CORP_PRODUCTS_ROLES_SUCCESS:
      return { ...state, productRoles: { roles: action.payload, error: '', isLoading: false } };
    case CORP_PRODUCTS_ROLES_FAIL:
      return { ...state, productRoles: { roles: [], error: action.payload, isLoading: false } };
    case CORP_PRODUCTS_MASTER_REQUEST:
      return { ...state, productMasters: { masters: state.productMasters.masters, error: '', isLoading: true }, };
    case CORP_PRODUCTS_MASTER_SUCCESS:
      return { ...state, productMasters: { masters: action.payload, error: '', isLoading: false } };
    case CORP_PRODUCTS_MASTER_FAIL:
      return { ...state, productMasters: { masters: [], error: action.payload, isLoading: false } };

    case CORP_PRODUCTS_MASTER_FILTER_REQUEST:
      return { ...state, productMasterfilters: { filtermasters: state.productMasterfilters.filtermasters, error: '', isLoading: true }, };
    case CORP_PRODUCTS_MASTER_FILTER_SUCCESS:
      return { ...state, productMasterfilters: { filtermasters: action.payload, error: '', isLoading: false } };
    case CORP_PRODUCTS_MASTER_FILTER_FAIL:
      return { ...state, productMasterfilters: { filtermasters: [], error: action.payload, isLoading: false } };

    case CORP_PRODUCTS_FILTER_REQUEST:
      return { ...state, relateproductsearches: { rproducts: state.relateproductsearches.rproducts, error: '', isLoading: true }, };
    case CORP_PRODUCTS_FILTER_SUCCESS:
      return { ...state, relateproductsearches: { rproducts: action.payload, error: '', isLoading: false } };
    case CORP_PRODUCTS_FILTER_FAIL:
      return { ...state, relateproductsearches: { rproducts: [], error: action.payload, isLoading: false } };

    case CORP_PRODUCTS_COLOURS_REQUEST:
      return { ...state, productColours: { colours: state.productColours.colours, error: '', isLoading: true }, };
    case CORP_PRODUCTS_COLOURS_SUCCESS:
      return { ...state, productColours: { colours: action.payload, error: '', isLoading: false } };
    case CORP_PRODUCTS_COLOURS_FAIL:
      return { ...state, productColours: { colours: [], error: action.payload, isLoading: false } };
    case CORP_PRODUCTS_SIZE_REQUEST:
      return { ...state, productSizes: { sizes: state.productSizes.sizes, error: '', isLoading: true }, };
    case CORP_PRODUCTS_SIZE_SUCCESS:
      return { ...state, productSizes: { sizes: action.payload, error: '', isLoading: false } };
    case CORP_PRODUCTS_SIZE_FAIL:
      return { ...state, productSizes: { sizes: [], error: action.payload, isLoading: false } };

    case CORP_PRODUCTS_APPROVE_REQUEST:
      return { ...state, productsapproval: { all: state.productsapproval.all, error: '', isLoading: true } };
    case CORP_PRODUCTS_APPROVE_SUCCESS:
      return { ...state, productsapproval: { all: action.payload, error: '', isLoading: false } };
    case CORP_PRODUCTS_APPROVE_FAIL:
      return { ...state, productsapproval: { all: [], error: action.payload, isLoading: false } };


    case CORP_PRODUCTS_REQUEST_UPDATE_REQUEST:
      return { ...state, productRequestUpdate: { productrequest: {}, error: '', isLoading: true } };
    case CORP_PRODUCTS_REQUEST_UPDATE_SUCCESS:
      return { ...state, productRequestUpdate: { productrequest: action.payload, error: '', isLoading: false } };
    case CORP_PRODUCTS_REQUEST_UPDATE_FAIL:
      return { ...state, productRequestUpdate: { productrequest: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}