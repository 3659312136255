import {
    USERRIDESDATEANDCOUNT_REQUEST,
    USERRIDESDATEANDCOUNT_SUCCESS,
    USERRIDESDATEANDCOUNT_FAIL,
} from '../actions/ridesdynamicdateandcountActions';

const initialState = {
    ridesdateandcounts: { all: [], error: '', isLoading: false },
};
export default function (state = initialState, action) {
    switch (action.type) {
        case USERRIDESDATEANDCOUNT_REQUEST:
            return { ...state, ridesdateandcounts: { all: state.ridesdateandcounts.all, error: '', isLoading: true } };
        case USERRIDESDATEANDCOUNT_SUCCESS:
            return { ...state, ridesdateandcounts: { all: action.payload, error: '', isLoading: false } };
        case USERRIDESDATEANDCOUNT_FAIL:
            return { ...state, ridesdateandcounts: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}

