
import config from './config';
import { toast } from 'react-toastify';
import { paginationPartnerserviceproduct } from './node-actions/nodepagination';
import {  nodeGetApi,boomiAdminApi } from './commonAxios';


export const SBCM_PRODUCTS_REQUEST = "SBCM_PRODUCTS_REQUEST";
export const SBCM_PRODUCTS_SUCCESS = "SBCM_PRODUCTS_SUCCESS";
export const SBCM_PRODUCTS_FAIL = "SBCM_PRODUCTS_FAIL";
export const SBCM_PRODUCT_GET_REQUEST = "SBCM_PRODUCT_GET_REQUEST";
export const SBCM_PRODUCT_GET_SUCCESS = "SBCM_PRODUCT_GET_SUCCESS";
export const SBCM_PRODUCT_GET_FAIL = "SBCM_PRODUCT_GET_FAIL";
export const SBCM_PRODUCT_CREATE_REQUEST = "SBCM_PRODUCT_CREATE_REQUEST";
export const SBCM_PRODUCT_CREATE_SUCCESS = "SBCM_PRODUCT_CREATE_SUCCESS";
export const SBCM_PRODUCT_CREATE_FAIL = "SBCM_PRODUCT_CREATE_FAIL";
export const SBCM_PRODUCT_UPDATE_REQUEST = "SBCM_PRODUCT_UPDATE_REQUEST";
export const SBCM_PRODUCT_UPDATE_SUCCESS = "SBCM_PRODUCT_UPDATE_SUCCESS";
export const SBCM_PRODUCT_UPDATE_FAIL = "SBCM_PRODUCT_UPDATE_FAIL";

/*=============================================================
                  Add Product Action
===============================================================*/


export const createProductAction = (formData, state, navigate, setErrorMessage) => async dispatch => {
  setErrorMessage('');
  dispatch({
    type: SBCM_PRODUCT_CREATE_REQUEST
  });
  boomiAdminApi.post(`/Shop_By_City/Partner_Service_City_Products_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SBCM_PRODUCT_CREATE_SUCCESS
        });
        toast('City product added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/sbcm/product-management', state = { state });
      } else {
        setErrorMessage(successResponse.UI_Display_Message);
        dispatch({
          type: SBCM_PRODUCT_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage("Currently server is not working. Please try again later.");
      dispatch({
        type: SBCM_PRODUCT_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                  Update Product Action
===============================================================*/
export const updateProductAction = (formData, state, navigate) => async dispatch => {
  dispatch({
    type: SBCM_PRODUCT_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Shop_By_City/Partner_Service_City_Products_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SBCM_PRODUCT_UPDATE_SUCCESS
        });
        toast('City product updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/sbcm/product-management', state = { state });
      } else {
        dispatch({
          type: SBCM_PRODUCT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: SBCM_PRODUCT_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                  Get All products Action
===============================================================*/
export const getAllProductAction = formData => async dispatch => {
  try {
    dispatch({
      type: SBCM_PRODUCTS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Shop_By_City/Partner_Service_City_Products_Get?PageNo=0&PageSize=${config.pageSize}&SortBy=CITY_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Success_Response?.Is_Data_Exist === '0' ? data.Success_Response?.Is_Data_Exist === '0' : data.Is_Data_Exist === '0') {
        dispatch({
          type: SBCM_PRODUCTS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SBCM_PRODUCTS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SBCM_PRODUCTS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                   Delete Product Action
===============================================================*/
export const deleteProductAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/Shop_By_City/Partner_Service_City_Products_Delete`, formData);
    if (data) {
      toast('City product deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationPartnerserviceproduct(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};
/*=============================================================
               get PartnerProduct Action
===============================================================*/
export const getPartnearProductAction = (formData, returnFunction) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Partners/Partner_Product_Online_Physical?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_PRODUCT_ID&SortOrder=ASC`,
      formData
     
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        returnFunction({ data: [] })
      } else {
        returnFunction({ data: data.results })
      }
    }
  } catch (err) {
    returnFunction({ data: [], error: true })
  }
};

//Get city partner product Action
export const getPartnerServiceCityProductAction = (formData, callBackPartnerServiceCityProductData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Shop_By_City/Partner_Service_City_Products_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Partner_Service_City_Products_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBackPartnerServiceCityProductData(data)
    }
  } catch (err) {
  }
};