import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
  CircularProgress
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { ConnectedFocusError } from 'focus-formik-error';
import { AddCategorytypeAction, categoryuploadImagepath } from 'src/store/actions/servicecategoriesAction';

const StatusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'ACTIVE',
    label: 'ACTIVE'
},
{
    value: 'IN-ACTIVE',
    label: 'IN-ACTIVE'
}
];

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  Description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddServiceCategory = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState({ Service_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmitted = useSelector(state => state.subcategory.subcategoryCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  // img upload
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };
  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0],
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      categoryuploadImagepath(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };
  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        Service_Name: '',
        Created_By: loginEmail,
        Description: '',
        Status: '',
        GST: '',
        Comission: '',
        Image: imgUrl
      }}
      validationSchema={Yup.object().shape({
        Service_Name: Yup.string().min(5, 'Service Name must be at least 5 characters.').notOneOf([errorMessage.Service_Name.key, null], errorMessage.Service_Name.message).required('Service Name is required.'),
        Status: Yup.string().required('Status is required.'),
        Description: Yup.string().min(20, 'Description must be at least 20 characters.').required('Description is required.'),
        GST: Yup.number().typeError('GST must be a number').required('GST is required.').min(5, 'GST must be at least 5 %.').max(100, 'GST must be at most 100 % only.'),
        Comission: Yup.number().typeError('Comission must be a number').required('Comission is required.') .min(5, 'Comission must be at least 5 %.').max(100, 'Comission must be at most 100 % only.')
      })}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};

        values.Image = imgUrl;

        if (!values.Image) {
          submitTest = false;
          errors.Image = 'Image is required.';
        }

        setErrors(errors);

        if (submitTest) {
          dispatch(AddCategorytypeAction(values, navigate, setErrorMessage, setErrors));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    {/* Service Name */}
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Service_Name && errors.Service_Name)}
                        fullWidth
                        helperText={touched.Service_Name && errors.Service_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Service Name"
                        name="Service_Name"
                        value={values.Service_Name}
                        variant="outlined"
                      />
                    </Grid>
                    {/* GST */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.GST && errors.GST)}
                        fullWidth
                        helperText={touched.GST && errors.GST}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                              handleChange(e);
                          }
                      }}
                        label="GST %"
                        name="GST"
                        value={values.GST}
                        variant="outlined"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      />
                    </Grid>
                    {/* Comission */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Comission && errors.Comission)}
                        fullWidth
                        helperText={touched.Comission && errors.Comission}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                              handleChange(e);
                          }
                      }}
                        label="Comission %"
                        name="Comission"
                        value={values.Comission}
                        variant="outlined"               
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      />
                    </Grid>
                    {/* Description */}
                    <Grid item md={12} xs={12}>
                      <Box>
                        <TextareaAutosize
                          className={classes.Description}
                          minRows={10}
                          placeholder="Description"
                          value={values.Description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="Description"
                        />
                      </Box>
                      {touched.Description && errors.Description && (
                        <span style={{ color: 'red' }}>{errors.Description}</span>
                      )}
                    </Grid>
                    {/* Status */}
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {StatusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Upload Photo */}
                <Grid item md={4} xs={12}>
                  <Card>
                    &nbsp;Web Image
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Brand Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>
                      <Box display="flex" justifyContent="space-between" alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {image.preview ? (
                          <Button
                            style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                            onClick={handleRemoveImage}
                          >
                            Remove Picture
                          </Button>
                        ) : (
                          <input
                            style={{ display: 'block', width: '80%' }}
                            id="upload-photo"
                            name="image"
                            type="file"
                            accept="image/*"
                            onChange={handleImage}
                          />
                        )}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                  <br />
                  <span style={{ color: 'red' }}>
                    {!imgUrl && touched.Image && errors.Image}
                  </span>
                  <div style={{ marginBottom: '5%' }}></div>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" pl={2}>
              <span style={{ color: 'red' }}>{errorMessage.global.message}</span>
            </Box>
            <Box display="flex" p={2}>
              {isSubmitted ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={isSubmitted || uploadPic}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              )}
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddServiceCategory.propTypes = {
  className: PropTypes.string
};

export default AddServiceCategory;
