import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
    makeStyles
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'src/store/validations/is-empty';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';
import { getBiddingServicesDateandCount } from 'src/store/actions/biddingdateandcountAction';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    avatar: {
        cursor: 'pointer',
        width: 58,
        height: 58
    },
    statsItem: {
        alignItems: 'center',
        display: 'flex'
    },
    statsIcon: {
        marginRight: theme.spacing(1)
    }
}));

const ProductCard = ({ className, product, rolePermission, ...rest }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    let dynamicvalue = useSelector((state) => state.biddingdateandcount.bidservdateandcounts.all);
    const Login_Details = useSelector(state => state.auth.user);

    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    useEffect(() => {
        if (isEmpty(dynamicvalue)) {
            dispatch(getRolePermissions(Login_Details));
            dispatch(getBiddingServicesDateandCount());
        } else {
            setSeconds(5);
            if (product.title === "Bidding Services") {
                product.hide = rolePermission.Bidding_Services_Hide?.Is_Hide;
                product.total = dynamicvalue?.Bidding_Services?.Bidding_Services_Count;
                product.createdAt = dynamicvalue?.Bidding_Services?.Bidding_Services_Date;
            } else if (product.title === "Partner Bidding Available Services") {
                product.hide = rolePermission.Partner_Bidding_Avail_Services_Hide?.Is_Hide;
                product.total = dynamicvalue?.partner_bidding_available_services?.partner_bidding_available_services_Count;
                product.createdAt = dynamicvalue?.partner_bidding_available_services?.partner_bidding_available_services_Date;
            } else if (product.title === "Bidding Services Success Stories") {
                product.hide = rolePermission.Services_Bidding_Success_Story_Hide?.Is_Hide;
                product.total = dynamicvalue?.partner_bidding_services_success_stories?.partner_bidding_services_success_stories_Count;
                product.createdAt = dynamicvalue?.partner_bidding_services_success_stories?.partner_bidding_services_success_stories_Date;
            } else if (product.title === "Bidding User Services") {
                product.hide = rolePermission.Bidding_User_Services_Hide?.Is_Hide;
                product.total = dynamicvalue?.user_services_bidding?.user_services_bidding_Count;
                product.createdAt = dynamicvalue?.user_services_bidding?.user_services_bidding_Date;
            }
        }
    }, [dynamicvalue]);

    return (
        <>
            {product.hide !== 1 &&
                <Card className={clsx(classes.root, className)} {...rest}>
                    <CardContent>
                        <Box display="flex" justifyContent="center" mb={3}>
                            <Avatar
                                className={classes.avatar}
                                component={RouterLink}
                                alt="Product"
                                src={product.media}
                                variant="square"
                                to={product.link}
                            />
                        </Box>
                        <Typography
                            align="center"
                            color="textPrimary"
                            gutterBottom
                            variant="h4"
                        >
                            {product.title}
                        </Typography>
                        <Typography style={{ paddingTop: "5px" }} align="justify" color="textPrimary" variant="body1">
                            {product.description}
                        </Typography>
                    </CardContent>
                    <Box flexGrow={1} />
                    <Divider />
                    <Box p={2}>
                        <Grid container justifyContent="space-between" spacing={2}>
                            <Grid className={classes.statsItem} item>
                                <AccessTimeIcon className={classes.statsIcon} color="action" />
                                <Typography color="textSecondary" display="inline" variant="body2">
                                    {product.createdAt}
                                </Typography>
                            </Grid>
                            <Grid className={classes.statsItem} item>
                                <Typography color="textSecondary" display="inline" variant="body2">
                                    {product.total}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            }
        </>
    );
};

ProductCard.propTypes = {
    className: PropTypes.string,
    product: PropTypes.object.isRequired
};

export default ProductCard;