import { Card, CardContent, Grid, makeStyles, Divider } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const BiddingServicesView = ({ className, ...rest }) => {
    const location = useLocation();
    const classes = useStyles();
    let { state } = location;

    return (
        <Card className={clsx(classes.root)}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Grid container spacing={3}>

                            {/* Bidding_service_Id */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Bidding Service ID
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Bidding_service_Id}
                                </Typography>
                            </Grid>

                            {/* Esi_Commission */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    ESI Commission
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Esi_Commission}
                                </Typography>
                            </Grid>

                            {/* Status */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Status
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Status}
                                </Typography>
                            </Grid>
                            {/* Created_Date */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Created_Date}
                                </Typography>
                            </Grid>

                            {/* Created_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Created By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state?.Created_By}> {state?.Created_By?.length > 25 ?
                                        <span>{state?.Created_By?.substring(0, 25)}...</span> : state?.Created_By}</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={2} />
                            <Grid item xs={2} />
                            <Grid item xs={7} />
                        </Grid>
                    </Grid>

                    {/*  column 2 */}
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            {/* Service_Type */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Service Type
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Service_Type}
                                </Typography>
                            </Grid>

                            {/* Esi_Commission_Type */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    ESI Commission Type
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Esi_Commission_Type}
                                </Typography>
                            </Grid>
                            {/* Modified_Date */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified Date
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    {state?.Modified_Date}
                                </Typography>
                            </Grid>

                            {/* Modified_By */}
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ color: '#66788A' }}
                                >
                                    Modified By
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                    style={{ paddingLeft: '3rem' }}
                                >
                                    <span title={state?.Modified_By}> {state?.Modified_By?.length > 25 ?
                                        <span>{state?.Modified_By?.substring(0, 25)}...</span> : state?.Modified_By}</span>
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                <Divider />

                {/*  Description */}
                <Grid item xs={4}>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ color: '#66788A', paddingBottom: '1rem' }}
                    >
                        Description
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        style={{ paddingLeft: '1rem' }}
                    >
                        {/* dangerouslySetInnerHTML is a property that you can use on HTML elements in a React application to programmatically set their content. */}
                        <span dangerouslySetInnerHTML={{ __html: state?.Description }}></span>
                    </Typography>
                </Grid>
            </CardContent>
        </Card>
    );
};


export default BiddingServicesView;