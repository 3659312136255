import {
  BIDDING_PRODUCTS_CATEGORY_CREATE_REQUEST,
  BIDDING_PRODUCTS_CATEGORY_CREATE_SUCCESS,
  BIDDING_PRODUCTS_CATEGORY_CREATE_FAIL,
  BIDDING_PRODUCTS_CATEGORY_UPDATE_REQUEST,
  BIDDING_PRODUCTS_CATEGORY_UPDATE_SUCCESS,
  BIDDING_PRODUCTS_CATEGORY_UPDATE_FAIL,
} from '../actions/biddingproductscategoryAction'

const initialState = {
  biddingproductscategoryCreate: { biddingproductscategory: {}, error: '', isLoading: false },
  biddingproductscategoryUpdate: { biddingproductscategory: {}, error: '', isLoading: false },
};
export default function (state = initialState, action) {
  switch (action.type) {

    case BIDDING_PRODUCTS_CATEGORY_CREATE_REQUEST:
      return { ...state, biddingproductscategoryCreate: { biddingproductscategory: {}, error: '', isLoading: true } };
    case BIDDING_PRODUCTS_CATEGORY_CREATE_SUCCESS:
      return { ...state, biddingproductscategoryCreate: { biddingproductscategory: action.payload, error: '', isLoading: false } };
    case BIDDING_PRODUCTS_CATEGORY_CREATE_FAIL:
      return { ...state, biddingproductscategoryCreate: { biddingproductscategory: {}, error: action.payload, isLoading: false } };

    case BIDDING_PRODUCTS_CATEGORY_UPDATE_REQUEST:
      return { ...state, biddingproductscategoryUpdate: { biddingproductscategory: {}, error: '', isLoading: true } };
    case BIDDING_PRODUCTS_CATEGORY_UPDATE_SUCCESS:
      return { ...state, biddingproductscategoryUpdate: { biddingproductscategory: action.payload, error: '', isLoading: false } };
    case BIDDING_PRODUCTS_CATEGORY_UPDATE_FAIL:
      return { ...state, biddingproductscategoryUpdate: { biddingproductscategory: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}