import {

    DELI_BID_QUOTES_CREATE_REQUEST,
    DELI_BID_QUOTES_CREATE_SUCCESS,
    DELI_BID_QUOTES_CREATE_FAIL,

    DELI_BID_QUOTES_UPDATE_REQUEST,
    DELI_BID_QUOTES_UPDATE_SUCCESS,
    DELI_BID_QUOTES_UPDATE_FAIL,

    DELI_USERS_GET_REQUEST,
    DELI_USERS_GET_SUCCESS,
    DELI_USERS_GET_FAIL,

    BID_DELI_MULTI_VEHI_GET_REQUEST,
    BID_DELI_MULTI_VEHI_GET_SUCCESS,
    BID_DELI_MULTI_VEHI_GET_FAIL,

    BID_DELI_MULTI_QUOTE_GET_REQUEST,
    BID_DELI_MULTI_QUOTE_GET_SUCCESS,
    BID_DELI_MULTI_QUOTE_GET_FAIL,

    BID_DELI_MULTI_BIDS_GET_REQUEST,
    BID_DELI_MULTI_BIDS_GET_SUCCESS,
    BID_DELI_MULTI_BIDS_GET_FAIL,

    BID_DELI_BIDS_COMMISSION_GET_REQUEST,
    BID_DELI_BIDS_COMMISSION_GET_SUCCESS,
    BID_DELI_BIDS_COMMISSION_GET_FAIL,

} from '../actions/deliverybiddingquotesAction'

const initialState = {
    deliverybidquoteCreate: { deliverybidquote: {}, error: '', isLoading: false },
    deliverybidquoteUpdate: { deliverybidquote: {}, error: '', isLoading: false },
    biddeliveryusers: { all: [], error: '', isLoading: false },
    biddelimultivehicles: { all: [], error: '', isLoading: false },
    biddelimultiquotes: { all: [], error: '', isLoading: false },
    biddelimultibids: { all: [], error: '', isLoading: false },
    biddelibidsCommision: { all: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case DELI_BID_QUOTES_CREATE_REQUEST:
            return { ...state, deliverybidquoteCreate: { deliverybidquote: {}, error: '', isLoading: true } };
        case DELI_BID_QUOTES_CREATE_SUCCESS:
            return { ...state, deliverybidquoteCreate: { deliverybidquote: state.deliverybidquoteCreate.partbidavailprod, error: '', isLoading: false } };
        case DELI_BID_QUOTES_CREATE_FAIL:
            return { ...state, deliverybidquoteCreate: { deliverybidquote: {}, error: action.payload, isLoading: false } };

        case DELI_BID_QUOTES_UPDATE_REQUEST:
            return { ...state, deliverybidquoteUpdate: { deliverybidquote: {}, error: '', isLoading: true } };
        case DELI_BID_QUOTES_UPDATE_SUCCESS:
            return { ...state, deliverybidquoteUpdate: { deliverybidquote: state.deliverybidquoteUpdate.partbidavailprod, error: '', isLoading: false } };
        case DELI_BID_QUOTES_UPDATE_FAIL:
            return { ...state, deliverybidquoteUpdate: { deliverybidquote: {}, error: action.payload, isLoading: false } };

        case DELI_USERS_GET_REQUEST:
            return { ...state, biddeliveryusers: { all: state.biddeliveryusers.all, error: '', isLoading: true } };
        case DELI_USERS_GET_SUCCESS:
            return { ...state, biddeliveryusers: { all: action.payload, error: '', isLoading: false } };
        case DELI_USERS_GET_FAIL:
            return { ...state, biddeliveryusers: { all: [], error: action.payload, isLoading: false } };

        case BID_DELI_MULTI_VEHI_GET_REQUEST:
            return { ...state, biddelimultivehicles: { all: state.biddelimultivehicles.all, error: '', isLoading: true } };
        case BID_DELI_MULTI_VEHI_GET_SUCCESS:
            return { ...state, biddelimultivehicles: { all: action.payload, error: '', isLoading: false } };
        case BID_DELI_MULTI_VEHI_GET_FAIL:
            return { ...state, biddelimultivehicles: { all: [], error: action.payload, isLoading: false } };

        case BID_DELI_MULTI_QUOTE_GET_REQUEST:
            return { ...state, biddelimultiquotes: { all: state.biddelimultiquotes.all, error: '', isLoading: true } };
        case BID_DELI_MULTI_QUOTE_GET_SUCCESS:
            return { ...state, biddelimultiquotes: { all: action.payload, error: '', isLoading: false } };
        case BID_DELI_MULTI_QUOTE_GET_FAIL:
            return { ...state, biddelimultiquotes: { all: [], error: action.payload, isLoading: false } };

        case BID_DELI_MULTI_BIDS_GET_REQUEST:
            return { ...state, biddelimultibids: { all: state.biddelimultibids.all, error: '', isLoading: true } };
        case BID_DELI_MULTI_BIDS_GET_SUCCESS:
            return { ...state, biddelimultibids: { all: action.payload, error: '', isLoading: false } };
        case BID_DELI_MULTI_BIDS_GET_FAIL:
            return { ...state, biddelimultibids: { all: [], error: action.payload, isLoading: false } };

        case BID_DELI_BIDS_COMMISSION_GET_REQUEST:
            return { ...state, biddelibidsCommision: { all: state.biddelibidsCommision.all, error: '', isLoading: true } };
        case BID_DELI_BIDS_COMMISSION_GET_SUCCESS:
            return { ...state, biddelibidsCommision: { all: action.payload, error: '', isLoading: false } };
        case BID_DELI_BIDS_COMMISSION_GET_FAIL:
            return { ...state, biddelibidsCommision: { all: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}