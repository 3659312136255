import {
    USER_STATISTICS_REQUEST,
    USER_STATISTICS_SUCCESS,
    USER_STATISTICS_FAIL,
} from '../actions/userstatisticsAction';

const initialState = {
    userstatistics: { all: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_STATISTICS_REQUEST:
            return { ...state, userstatistics: { all: state.userstatistics.all, error: '', isLoading: true } };
        case USER_STATISTICS_SUCCESS:
            return { ...state, userstatistics: { all: action.payload, error: '', isLoading: false } };
        case USER_STATISTICS_FAIL:
            return { ...state, userstatistics: { all: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}