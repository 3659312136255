import {
  PARTNER_EMPLOYEE_REQUEST,
  PARTNER_EMPLOYEE_SUCCESS,
  PARTNER_EMPLOYEE_FAIL,

  PARTNER_EMPLOYEE_GET_REQUEST,
  PARTNER_EMPLOYEE_GET_SUCCESS,
  PARTNER_EMPLOYEE_GET_FAIL,
  PARTNER_EMPLOYEE_CREATE_REQUEST,
  PARTNER_EMPLOYEE_CREATE_SUCCESS,
  PARTNER_EMPLOYEE_CREATE_FAIL,
  PARTNER_EMPLOYEE_UPDATE_REQUEST,
  PARTNER_EMPLOYEE_UPDATE_SUCCESS,
  PARTNER_EMPLOYEE_UPDATE_FAIL,

} from '../actions/partneremployeeAction'

const initialState = {
  partneremployees: { all: [], error: '', isLoading: false },
  partneremployee: { partneremployee: {}, error: '', isLoading: false },
  partneremployeeCreate: { partneremployee: {}, error: '', isLoading: false },
  partneremployeeUpdate: { partneremployee: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PARTNER_EMPLOYEE_REQUEST:
      return { ...state, partneremployees: { all: state.partneremployees.all, error: '', isLoading: true } };
    case PARTNER_EMPLOYEE_SUCCESS:
      return { ...state, partneremployees: { all: action.payload, error: '', isLoading: false } };
    case PARTNER_EMPLOYEE_FAIL:
      return { ...state, partneremployees: { all: [], error: action.payload, isLoading: false } };

    case PARTNER_EMPLOYEE_GET_REQUEST:
      return { ...state, partnerdeliveryagent: { partneremployee: {}, error: '', isLoading: true } };
    case PARTNER_EMPLOYEE_GET_SUCCESS:
      return { ...state, partnerdeliveryagent: { partneremployee: action.payload, error: '', isLoading: false } };
    case PARTNER_EMPLOYEE_GET_FAIL:
      return { ...state, partnerdeliveryagent: { partneremployee: {}, error: action.payload, isLoading: false } };

    case PARTNER_EMPLOYEE_CREATE_REQUEST:
      return { ...state, partneremployeeCreate: { partneremployee: {}, error: '', isLoading: true } };
    case PARTNER_EMPLOYEE_CREATE_SUCCESS:
      return { ...state, partneremployeeCreate: { partneremployee: state.partneremployeeCreate.partneremployee, error: '', isLoading: false } };
    case PARTNER_EMPLOYEE_CREATE_FAIL:
      return { ...state, partneremployeeCreate: { partneremployee: {}, error: action.payload, isLoading: false } };
    case PARTNER_EMPLOYEE_UPDATE_REQUEST:
      return { ...state, partneremployeeUpdate: { partneremployee: {}, error: '', isLoading: true } };
    case PARTNER_EMPLOYEE_UPDATE_SUCCESS:
      return { ...state, partneremployeeUpdate: { partneremployee: state.partneremployeeUpdate.partneremployee, error: '', isLoading: false } };
    case PARTNER_EMPLOYEE_UPDATE_FAIL:
      return { ...state, partneremployeeUpdate: { partneremployee: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}