
import config from './config';
import { toast } from 'react-toastify';
import { paginationPopup } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi,boomiAdminApi } from './commonAxios';

export const POPUP_CREATE_REQUEST = "POPUP_CREATE_REQUEST";
export const POPUP_CREATE_SUCCESS = "POPUP_CREATE_SUCCESS";
export const POPUP_CREATE_FAIL = "POPUP_CREATE_FAIL";
export const POPUP_UPDATE_REQUEST = "POPUP_UPDATE_REQUEST";
export const POPUP_UPDATE_SUCCESS = "POPUP_UPDATE_SUCCESS";
export const POPUP_UPDATE_FAIL = "POPUP_UPDATE_FAIL";



/*=============================================================
                   Add Popup Action
 ===============================================================*/
export const AddPopupAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: POPUP_CREATE_REQUEST
  });

  nodeCudApi.post(`/Advertisement/Popup_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: POPUP_CREATE_SUCCESS
        });
        toast('Popup added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/popup-management');
      } else {
        dispatch({
          type: POPUP_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: POPUP_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                 Update Popup Action
===============================================================*/
export const EditPopupAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: POPUP_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Advertisement/Popup_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: POPUP_UPDATE_SUCCESS
        });
        toast('Popup updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/popup-management');
      } else {
        dispatch({
          type: POPUP_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: POPUP_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working."
      });
    })
};

/*=============================================================
                  Delete Popup Action
===============================================================*/
export const deletePopupAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Advertisement/Popup_Delete`, formData);
    if (data) {
      toast('Popup deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationPopup(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};