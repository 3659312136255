import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
  Avatar,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  images: {
    width: 70,
    height: 70,
    cursor: 'pointer'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
}));

const headCells = [
  { id: 'Testmonial_Id', numeric: false, disablePadding: false, label: 'Testimonial ID' },
  { id: 'User_Name', numeric: false, disablePadding: false, label: 'User Name' },
  { id: 'User_Email_Id', numeric: false, disablePadding: false, label: 'User Email ID' },
  { id: 'Rating', numeric: true, disablePadding: false, label: 'Rating' },
  { id: 'Title', numeric: false, disablePadding: false, label: 'Title' },
  { id: 'Profile_Pic', numeric: false, disablePadding: false, label: 'Profile Picture' },
];

const Results = ({
  className,
  allTestimonial,
  page,
  limit,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  pagination,
  setPagination,
  allTestimonialsNoFilter,
  createSortHandler,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedTestimonialIds, setSelectedTestimonialIds] = useState([]);

  const visibleTestimonialHandler = testimonialData => e => {
    navigate("/app/testimonial-history-details", { state: testimonialData });
  };
  const editTestimonialHandler = testimonialData => e => {
    navigate('/app/edit-testimonial', { state: testimonialData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.id !== "Profile_Pic" ? (
                      <TableSortLabel style={{ fontSize: "13px", fontWeight: "bold" }}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>) : (<span style={{ fontSize: "13px", fontWeight: "bold" }} > {headCell.label}</span>)
                    }
                  </TableCell>
                ))}
                <TableCell
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  className={classes.headerColumn}> Actions &nbsp;&nbsp;&nbsp;&nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allTestimonial) &&
                (allTestimonial).map(testimonial => (
                  <TableRow
                    hover
                    key={testimonial.Testmonial_Id}
                    selected={
                      selectedTestimonialIds.indexOf(testimonial.Testmonial_Id) !== -1
                    }
                  >
                    <TableCell>{testimonial.Testmonial_Id}</TableCell>
                    <TableCell>{testimonial.User_Name}</TableCell>
                    <TableCell style={{ color: '#1665D8' }}>
                      {testimonial.User_Email_Id?.substring(0, 12)}...
                    </TableCell>
                    <TableCell>{testimonial.Rating}</TableCell>
                    <TableCell>{testimonial.Title?.substring(0, 12)}...</TableCell>
                    <TableCell>
                      <Avatar
                        className={classes.images}
                        alt="user"
                        src={testimonial.Profile_Pic_Path}
                        variant="circular"
                      />
                    </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Testimonial_Management_Write?.Value === "Testimonial_Management_Write" && RolePermissions?.Testimonial_Management_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Tooltip title="View Testimonial History">
                          <Button
                            onClick={visibleTestimonialHandler(testimonial)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        <Button
                          onClick={editTestimonialHandler(testimonial)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Testimonial_Management_Read?.Value === "Testimonial_Management_Read" && RolePermissions?.Testimonial_Management_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="View Testimonial History">
                          <Button
                            onClick={visibleTestimonialHandler(testimonial)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allTestimonial) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;