import { toast } from 'react-toastify';
import config from './config';
import { nodeGetApi, boomiAdminApi } from './commonAxios';
import { boomipaginationZoneThemeProducts } from './boomi-actions/boomipagination';

export const ZONE_THEME_PRODUCTS_CREATE_REQUEST = "ZONE_THEME_PRODUCTS_CREATE_REQUEST";
export const ZONE_THEME_PRODUCTS_CREATE_SUCCESS = "ZONE_THEME_PRODUCTS_CREATE_SUCCESS";
export const ZONE_THEME_PRODUCTS_CREATE_FAIL = "ZONE_THEME_PRODUCTS_CREATE_FAIL";
export const ZONE_THEME_PRODUCTS_UPDATE_REQUEST = "ZONE_THEME_PRODUCTS_UPDATE_REQUEST";
export const ZONE_THEME_PRODUCTS_UPDATE_SUCCESS = "ZONE_THEME_PRODUCTS_UPDATE_SUCCESS";
export const ZONE_THEME_PRODUCTS_UPDATE_FAIL = "ZONE_THEME_PRODUCTS_UPDATE_FAIL";
export const ZONE_THEME_GET_REQUEST = "ZONE_THEME_GET_REQUEST";
export const ZONE_THEME_GET_SUCCESS = "ZONE_THEME_GET_SUCCESS";
export const ZONE_THEME_GET_FAIL = "ZONE_THEME_GET_FAIL";
export const MFH_PRODUCTS_GET_REQUEST = "MFH_PRODUCTS_GET_REQUEST";
export const MFH_PRODUCTS_GET_SUCCESS = "MFH_PRODUCTS_GET_SUCCESS";
export const MFH_PRODUCTS_GET_FAIL = "MFH_PRODUCTS_GET_FAIL";

//   Add Zone Theme Products Action
export const createZoneThemeProductsAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: ZONE_THEME_PRODUCTS_CREATE_REQUEST
  });
  boomiAdminApi.post(`/Meepaisa_Zone_Theme_Products/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: ZONE_THEME_PRODUCTS_UPDATE_SUCCESS
        });
        toast('Zone Theme Product added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/meepaisa-zone-theme-products');
      } else {
        dispatch({
          type: ZONE_THEME_PRODUCTS_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }
    })
    .catch(error => {
      dispatch({
        type: ZONE_THEME_PRODUCTS_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

// Edit Zone Theme Products Action
export const updateZoneThemeProductsAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: ZONE_THEME_PRODUCTS_UPDATE_REQUEST
  });
  boomiAdminApi.post(`/Meepaisa_Zone_Theme_Products/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: ZONE_THEME_PRODUCTS_UPDATE_SUCCESS
        });
        toast('Zone Theme Product updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/meepaisa-zone-theme-products');
      } else {
        dispatch({
          type: ZONE_THEME_PRODUCTS_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }
    })
    .catch(error => {
      dispatch({
        type: ZONE_THEME_PRODUCTS_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};


//  Delete Zone Theme Product Action

export const deleteZoneThemeProductAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/Meepaisa_Zone_Theme_Products/Delete`, formData);
    if (data) {
      toast('Zone Theme Product deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(boomipaginationZoneThemeProducts(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

//  Get All MFH Products Action
export const getAllMFHProducts = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Is_Mfh_Combo: "1",
      search_by_filter: "",
      search: "",
      Product_For: "MFH",
      Status: "Active",
    };
    dispatch({
      type: MFH_PRODUCTS_GET_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Products/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Product_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: MFH_PRODUCTS_GET_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: MFH_PRODUCTS_GET_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: MFH_PRODUCTS_GET_FAIL,
      payload: err
    });
  }
};

//  Get All Zone Theme Action
export const getAllZoneTheme = () => async dispatch => {
  try {
    let formData = {
      "Is_Active": "",
      "Records_Filter": "",
      "Theme_Id": "",
      "Zone_Id": "",
      "Zone_Theme_Id": "",
      "search": "",
      "search_by_filter": ""
    };
    dispatch({
      type: ZONE_THEME_GET_REQUEST
    });
    let { data } = await boomiAdminApi.post(`/Meepaisa_Zone_Theme/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Zone_Theme_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ZONE_THEME_GET_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: ZONE_THEME_GET_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: ZONE_THEME_GET_FAIL,
      payload: err
    });
  }
};