import config from './config';
import { toast } from 'react-toastify';
import { nodeGetApi, boomiAdminApi } from './commonAxios';

export const MP_PRODUCT_COMBO_CREATE_REQUEST = "MP_PRODUCT_COMBO_CREATE_REQUEST";
export const MP_PRODUCT_COMBO_CREATE_SUCCESS = "MP_PRODUCT_COMBO_CREATE_SUCCESS";
export const MP_PRODUCT_COMBO_CREATE_FAIL = "MP_PRODUCT_COMBO_CREATE_FAIL";

export const MP_PRODUCT_COMBO_UPDATE_REQUEST = "MP_PRODUCT_COMBO_UPDATE_REQUEST";
export const MP_PRODUCT_COMBO_UPDATE_SUCCESS = "MP_PRODUCT_COMBO_UPDATE_SUCCESS";
export const MP_PRODUCT_COMBO_UPDATE_FAIL = "MP_PRODUCT_COMBO_UPDATE_FAIL";

export const MEEPAISA_PRODUCTS_REQUEST = "MEEPAISA_PRODUCTS_REQUEST";
export const MEEPAISA_PRODUCTS_SUCCESS = "MEEPAISA_PRODUCTS_SUCCESS";
export const MEEPAISA_PRODUCTS_FAIL = "MEEPAISA_PRODUCTS_FAIL";

export const MEEPAISA_COMBO_PRODUCTS_REQUEST = "MEEPAISA_COMBO_PRODUCTS_REQUEST";
export const MEEPAISA_COMBO_PRODUCTS_SUCCESS = "MEEPAISA_COMBO_PRODUCTS_SUCCESS";
export const MEEPAISA_COMBO_PRODUCTS_FAIL = "MEEPAISA_COMBO_PRODUCTS_FAIL";

//create product combo
export const createMPProductComboAction = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: MP_PRODUCT_COMBO_CREATE_REQUEST
    });
    boomiAdminApi.post(`/Meepaisa_Combo_Products/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: MP_PRODUCT_COMBO_CREATE_SUCCESS
                });
                toast('Product Combo added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/meepaisa-product-combo', state = { state });
            } else {
                dispatch({
                    type: MP_PRODUCT_COMBO_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: MP_PRODUCT_COMBO_CREATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//update product combo
export const updateMPProductComboAction = (formData, navigate, state) => async dispatch => {
    dispatch({
        type: MP_PRODUCT_COMBO_UPDATE_REQUEST
    });
    boomiAdminApi.post(`/Meepaisa_Combo_Products/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: MP_PRODUCT_COMBO_UPDATE_SUCCESS
                });
                toast('Product Combo updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/meepaisa-product-combo', state = { state });
            } else {
                dispatch({
                    type: MP_PRODUCT_COMBO_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: MP_PRODUCT_COMBO_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//Delete product combo
export const deleteMPProductCombo = (formData, getData) => async dispatch => {
    try {
        let { data } = await boomiAdminApi.post(`/Meepaisa_Combo_Products/Delete`, formData);
        if (data) {
            dispatch(getAllMeepaisaComboProducts(getData));
        }
    } catch (err) {
    }
};

//get all products
export const getAllMeepaisaProducts = (ProductName) => async dispatch => {
    try {
        let formData = {
            Product_Name: ProductName,
            Records_Filter: "FILTER",
            Product_For: "MFH"
        };
        dispatch({
            type: MEEPAISA_PRODUCTS_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Products/Product_Admin_Get_Dropdown_Search?PageNo=1&PageSize=100&SortBy=Product_Id&SortOrder=ASC`,
            formData);
        if (data) {
            if (data.Success_Response.Is_Data_Exist === '0') {
                dispatch({
                    type: MEEPAISA_PRODUCTS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: MEEPAISA_PRODUCTS_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: MEEPAISA_PRODUCTS_FAIL,
            payload: err
        });
    }
};

//get all meepaisa combo products
export const getAllMeepaisaComboProducts = (formData) => async dispatch => {
    try {
        dispatch({
            type: MEEPAISA_COMBO_PRODUCTS_REQUEST
        });
        let { data } = await boomiAdminApi.post(`/Meepaisa_Combo_Products/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Base_Product_Id&SortOrder=ASC`, formData);
        if (data) {
            if (data.Success_Response.Is_Data_Exist === '0') {
                dispatch({
                    type: MEEPAISA_COMBO_PRODUCTS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: MEEPAISA_COMBO_PRODUCTS_SUCCESS,
                    payload: data.results[0]?.Sub_Products_Details
                });
            }
        }
    } catch (err) {
        dispatch({
            type: MEEPAISA_COMBO_PRODUCTS_FAIL,
            payload: err
        });
    }
};