import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateProductAction,
  getAllBrands,
  getAllCategories,
  getAllSubCategories,
  getAllMasters,
  getAllColours,
  imageDelete,
  getProductImages,
  getAllSizes,
  singleImageUploadUpdate,
  imageDefaultUpdate
} from './../../store/actions/productAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import Typography from '@material-ui/core/Typography';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { useLocation } from 'react-router-dom';
import { ConnectedFocusError } from "focus-formik-error";
import csc from 'country-state-city';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const productforOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'REGULAR',
    label: 'REGULAR'
  },
  {
    value: 'FARMER',
    label: 'FARMER'
  },
  {
    value: 'MFH',
    label: 'MFH'
  },
];

const useStyles = makeStyles(() => ({
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid black'
  }
}));

const EditProduct = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  let { state } = location;
  const [Category, setCategory] = useState("");
  const [name1, setName] = useState("");
  let countries = csc.getAllCountries();

  // Multiple pic upload
  const [blobArrayPics, setBlobArrayPics] = useState([{
    key: 1, preview: null, Product_Image_Id: null, Product_Default: 0
  },
  {
    key: 2, preview: null, Product_Image_Id: null, Product_Default: 0
  },
  {
    key: 3, preview: null, Product_Image_Id: null, Product_Default: 0
  },
  {
    key: 4, preview: null, Product_Image_Id: null, Product_Default: 0
  },
  {
    key: 5, preview: null, Product_Image_Id: null, Product_Default: 0
  }]);

  const [imageError, setImageError] = useState();

  const callBackUploadImage = (status) => {
    setImageDefault(true);
    if (status) {
      dispatch(getProductImages(state.Product_Id, callBackGetImages));
    }
    else {
      setImageError("Unable to upload image please try again.");
    }
  };
  const [imageDefault, setImageDefault] = useState(false);

  function handleDefaultChange(status, key, imageId) {
    setImageDefault(true);
    dispatch(imageDefaultUpdate(state.Product_Id, createdBy, imageId, callBackUploadImage));
  };

  const handleImage = (e, key) => {
    setImageError("");
    dispatch(
      singleImageUploadUpdate(new Blob([e.target.files[0]]), state.Product_Id, createdBy, key, callBackUploadImage)
    );
  };

  function handleRemoveImage(e, key, imageId) {
    if (imageId) {
      dispatch(imageDelete(imageId, callBackDeleteImage, key));
    }
    else {
      callBackDeleteImage(imageId, true, key);
    }
  };

  const callBackDeleteImage = (imageId, status, key) => {
    if (status) {
      dispatch(getProductImages(state.Product_Id, callBackGetImages, key));
    }
  };

  const callBackGetImages = (images, key) => {
    setImageDefault(false);
    let count = 0;
    const blobArrayPicsTemp = JSON.parse(JSON.stringify(blobArrayPics.map((record) => key ? { ...record, preview: null, Product_Image_Id: null } : record)));
    setBlobArrayPics([]);

    if (images.length > 5) {
      for (let i = 0; i <= images.length - 6; i++) {
        blobArrayPicsTemp.push({
          key: i + 6, preview: null, Product_Image_Id: null, Product_Default: 0
        })
      }
    }
    images.map(image => {
      blobArrayPicsTemp[count].preview = image.Product_Image;
      blobArrayPicsTemp[count].Product_Image_Id = image.Product_Image_Id;
      blobArrayPicsTemp[count].Product_Default = image.Is_Default;
      count++
    })
    setBlobArrayPics(blobArrayPicsTemp);
  };

  // componentDidMount   
  useEffect(() => {
    dispatch(getAllBrands());
    dispatch(getAllCategories());
    dispatch(getAllSubCategories());
    dispatch(getAllMasters(state.Product_Master_Id));
    dispatch(getAllColours(state.Product_Master_Id));
    dispatch(getAllSizes(state.Product_Master_Id));
    dispatch(getProductImages(state.Product_Id, callBackGetImages));
    csc.getCountryByCode();
    csc.getAllCountries();

    if (state) {
      setCategory(state.Product_Master_Id)
    }
  }, []);


  const categories = useSelector(state => state.product.productCategories.categories);
  const subcategories = useSelector(state => state.product.productSubCategories.subcategories);
  const isSubmited = useSelector(state => state.product.productUpdate.isLoading);
  const colours = useSelector(state => state.product.productColours.colours);
  const masters = useSelector(state => state.product.productMasters.masters);
  const brands = useSelector(state => state.product.productBrands.brands);
  const sizes = useSelector(state => state.product.productSizes.sizes);
  const createdBy = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor  
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Product_Description || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Product_Description);
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // Richtext editor (Features)  
  const contentStateFeature = ContentState.createFromBlockArray(htmlToDraft(state.Features || "").contentBlocks);
  const [editorStateFeatures, setEditorStateFeatures] = useState(EditorState.createWithContent(contentStateFeature));
  const [rhDiscriptionFeatures, setRhDiscriptionFeatures] = useState(state.Features);
  const [rhDiscriptionFeaturesMin, setRhDiscriptionFeaturesMin] = useState(20);
  const onEditorStateChangeFeatures = (editorStateFeatures) => {
    setEditorStateFeatures(editorStateFeatures);
    setRhDiscriptionFeatures(draftToHtml(convertToRaw(editorStateFeatures.getCurrentContent())));
  };

  // Richtext editor (Specifications)  
  const contentStateSpecifications = ContentState.createFromBlockArray(htmlToDraft(state.Specifications || "").contentBlocks);
  const [editorStateSpecifications, setEditorStateSpecifications] = useState(EditorState.createWithContent(contentStateSpecifications));
  const [rhDiscriptionSpecifications, setRhDiscriptionSpecifications] = useState(state.Specifications);
  const [rhDiscriptionSpecificationsMin, setRhDiscriptionSpecificationsMin] = useState(20);
  const onEditorStateChangeSpecifications = (editorStateSpecifications) => {
    setEditorStateSpecifications(editorStateSpecifications);
    setRhDiscriptionSpecifications(draftToHtml(convertToRaw(editorStateSpecifications.getCurrentContent())));
  };

  //  Richtext editor (Reviews)  
  const contentStateReviews = ContentState.createFromBlockArray(htmlToDraft(state.Esi_Reviews || "").contentBlocks);
  const [editorStateReviews, setEditorStateReviews] = useState(EditorState.createWithContent(contentStateReviews));
  const [rhDiscriptionReviews, setRhDiscriptionReviews] = useState(state.Esi_Reviews);
  const [rhDiscriptionReviewsMin, setRhDiscriptionReviewsMin] = useState(20);
  const onEditorStateChangeReviews = (editorStateReviews) => {
    setEditorStateReviews(editorStateReviews);
    setRhDiscriptionReviews(draftToHtml(convertToRaw(editorStateReviews.getCurrentContent())));
  };

  const Change = (value) => {
    setCategory(value)
  }

  return (
    <Formik
      initialValues={{
        Product_Name: state.Product_Name ? state.Product_Name : "",
        Category_Id: state.Category_Id ? state.Category_Id : "",
        Product_Id: state.Product_Id ? state.Product_Id : "",
        Brand_Id: state.Brand_Id ? state.Brand_Id : "",
        Product_Short_Description: state.Product_Short_Description ? state.Product_Short_Description : "",
        Product_Description: state.Product_Description ? state.Product_Description : "",
        Features: state.Features ? state.Features : "",
        Specifications: state.Specifications ? state.Specifications : "",
        Product_Price: state.Price ? state.Price : "",
        Product_Size_Id: state.Product_Size_Id ? state.Product_Size_Id : "",
        Product_Color_Variant_Id: state.Product_Color_Variant_Id ? state.Product_Color_Variant_Id : "",
        Product_Master_Id: state.Product_Master_Id ? state.Product_Master_Id : "",
        Is_Popular: state.Is_Popular > 0 ? true : false,
        Whats_Hot: state.Whats_Hot > 0 ? true : false,
        Is_Top_Product: state.Is_Top_Product > 0 ? true : false,
        Esi_Review: state.Esi_Reviews ? state.Esi_Reviews : "",
        Esi_Rating: parseInt(state.Esi_Rating),
        Country_Of_Origin: state.Country_Of_Origin ? state.Country_Of_Origin : "",
        Status: state.Status ? state.Status : "",
        Is_Upcoming: state.Is_Upcoming > 0 ? true : false,
        Is_Verified: state.Is_Verified > 0 ? true : false,
        Sub_Category_Id: state.Sub_Category_Id ? state.Sub_Category_Id : "",
        Warranty_Period: state.Warranty_Period ? state.Warranty_Period : "",
        Modified_By_Email: createdBy,
        Today_Recommendations: state.Today_Recommendations > 0 ? true : false,
        Trending_Today: state.Trending_Today > 0 ? true : false,
        Is_MFH_Combo: state.Is_Mfh_Combo > 0 ? true : false,
        Product_For: state.Product_For ? state.Product_For : "",
      }}
      validationSchema={
        Yup.object().shape({
          Product_Name: Yup.string().min(5)
            // .notOneOf([errorMessage.Product_Name.key, null], errorMessage.Product_Name.message)
            .required('Product Name is required.'),
          Product_Price: Yup.number().min(0, "Product Price should not be less than 0.").required('Product Price is required.'),
          Sub_Category_Id: Yup.string().required('SubCategory Name is required.'),
          Product_Color_Variant_Id: Yup.string().required('Product Color is required.'),
          Product_Master_Id: Yup.string().required('Product Type is required.'),
          Brand_Id: Yup.string().required('Brand Name is required.'),
          Category_Id: Yup.string().required('Category Name is required.'),
          Product_Size_Id: Yup.string().required('Product Size is required.'),
          Country_Of_Origin: Yup.string().required('Country Of Origin is required.'),
          Product_Short_Description: Yup.string().required('Product Short Description is required.'),
          Warranty_Period: Yup.string().required('Warranty Period is required.'),
          Status: Yup.string().required('Status is required.'),
          Product_For: Yup.string().required('Product For is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        let submitTest = true;
        const errors = {};
        values.Product_Description = rhDiscription;
        values.Features = rhDiscriptionFeatures;
        values.Specifications = rhDiscriptionSpecifications;
        values.Esi_Review = rhDiscriptionReviews;
        if (!values.Product_Description || values.Product_Description?.replace(/(<([^>]+)>)/ig, '')?.trim().length === 0) {
          submitTest = false;
          errors.Product_Description = "Description is required.";
        }
        else if (values.Product_Description?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Product_Description = "Please provide at least " + rhDiscriptionMin + " characters";
        }

        if (!values.Features || values.Features?.replace(/(<([^>]+)>)/ig, '')?.trim().length === 0) {
          submitTest = false;
          errors.Features = "Features are required.";
        }
        else if (values.Features?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionFeaturesMin) {
          submitTest = false;
          errors.Features = "Please provide at least " + rhDiscriptionFeaturesMin + " characters";
        }

        if (!values.Specifications || values.Specifications?.replace(/(<([^>]+)>)/ig, '')?.trim().length === 0) {
          submitTest = false;
          errors.Specifications = "Specifications are required.";
        }
        else if (values.Specifications?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionSpecificationsMin) {
          submitTest = false;
          errors.Specifications = "Please provide at least " + rhDiscriptionSpecificationsMin + " characters";
        }

        if (!values.Esi_Review || values.Esi_Review?.replace(/(<([^>]+)>)/ig, '')?.trim().length === 0) {
          submitTest = false;
          errors.Esi_Review = "ESI Reviews is required.";
        }
        else if (values.Esi_Review?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionReviewsMin) {
          submitTest = false;
          errors.Esi_Review = "Please provide at least " + rhDiscriptionReviewsMin + " characters";
        }
        if (blobArrayPics.filter(item => { return !(!item.preview) }).length <= 0) {
          submitTest = false;
          setImageError("Image is required.");
        }

        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          if (values.Is_Popular) {
            formValues.Is_Popular = 1;
          } else {
            formValues.Is_Popular = 0;
          }
          if (values.Is_Upcoming) {
            formValues.Is_Upcoming = 1;
          } else {
            formValues.Is_Upcoming = 0;
          }
          if (values.Whats_Hot) {
            formValues.Whats_Hot = 1;
          } else {
            formValues.Whats_Hot = 0;
          }
          if (values.Is_Top_Product) {
            formValues.Is_Top_Product = 1;
          } else {
            formValues.Is_Top_Product = 0;
          }
          if (values.Is_Verified) {
            formValues.Is_Verified = 1;
          } else {
            formValues.Is_Verified = 0;
          }
          if (values.Trending_Today) {
            formValues.Trending_Today = 1;
          } else {
            formValues.Trending_Today = 0;
          }
          if (values.Today_Recommendations) {
            formValues.Today_Recommendations = 1;
          } else {
            formValues.Today_Recommendations = 0;
          }
          if (values.Is_MFH_Combo) {
            formValues.Is_MFH_Combo = 1;
          } else {
            formValues.Is_MFH_Combo = 0;
          }
          dispatch(updateProductAction(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Product_Name && errors.Product_Name)}
                        fullWidth
                        helperText={touched.Product_Name && errors.Product_Name}
                        onBlur={handleBlur}
                        label="Product Name"
                        name="Product_Name"
                        onChange={handleChange}
                        value={values.Product_Name}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Product_Master_Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Product_Master_Id && errors.Product_Master_Id)}
                        fullWidth
                        helperText={touched.Product_Master_Id && errors.Product_Master_Id}
                        onBlur={handleBlur}
                        label="Product Master Name"
                        name="Product_Master_Id"
                        onChange={(e) => { handleChange(e); Change(e.target.value); }}
                        value={values.Product_Master_Id}
                        select
                        disabled
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {masters?.map(option => (
                          <option key={option.Product_Master_Id} value={option.Product_Master_Id}>
                            {option.Product_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Category_Id && errors.Category_Id)}
                        fullWidth
                        helperText={touched.Category_Id && errors.Category_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Category Name"
                        name="Category_Id"
                        disabled
                        value={values.Category_Id}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {categories.map(option => (
                          <option value={option.Category_Id} key={option.Category_Id}>
                            {option.Category_Name}</option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Sub_Category_Id && errors.Sub_Category_Id)}
                        fullWidth
                        helperText={touched.Sub_Category_Id && errors.Sub_Category_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="SubCategory Name"
                        name="Sub_Category_Id"
                        disabled
                        value={values.Sub_Category_Id}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {subcategories.map(option => (
                          <option value={option.Sub_Category_Id} key={option.Sub_Category_Id}>{option.Sub_Category_Name}</option>
                        ))}

                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Brand_Id && errors.Brand_Id)}
                        fullWidth
                        helperText={touched.Brand_Id && errors.Brand_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Brand Name"
                        name="Brand_Id"
                        value={values.Brand_Id}
                        disabled
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {brands.map(option => (
                          <option value={option.Brand_Id} key={option.Brand_Id}>{option.Brand_Name}</option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Product_Color_Variant_Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Product_Color_Variant_Id && errors.Product_Color_Variant_Id)}
                        fullWidth
                        helperText={touched.Product_Color_Variant_Id && errors.Product_Color_Variant_Id}
                        onBlur={handleBlur}
                        label="Product Color"
                        name="Product_Color_Variant_Id"
                        onChange={handleChange}
                        value={values.Product_Color_Variant_Id}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {colours?.sort((a, b) => a.Color?.localeCompare(b.Color))?.map(option => (
                          <option key={option.Product_Color_Variant_Id} value={option.Product_Color_Variant_Id}>
                            {option?.Color}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Product_Size_Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Product_Size_Id && errors.Product_Size_Id)}
                        fullWidth
                        helperText={touched.Product_Size_Id && errors.Product_Size_Id}
                        onBlur={handleBlur}
                        label="Product Size"
                        name="Product_Size_Id"
                        onChange={handleChange}
                        value={values.Product_Size_Id}
                        variant="outlined"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {sizes.map(option => (
                          <option value={option.Product_Size_Id} key={option.Product_Size_Id}>
                            {option.Size}</option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Product_Price && errors.Product_Price)}
                        fullWidth
                        helperText={touched.Product_Price && errors.Product_Price}
                        onBlur={handleBlur}
                        type="number"
                        label="Product Price"
                        name="Product_Price"
                        onChange={handleChange}
                        value={values.Product_Price}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Country_Of_Origin */}
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        id="Country_Of_Origin"
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params}
                          label="Country Of Origin"
                          error={Boolean(touched.Country_Of_Origin && errors.Country_Of_Origin)}
                          helperText={touched.Country_Of_Origin && errors.Country_Of_Origin}
                          onBlur={handleBlur}
                          name="Country_Of_Origin"
                          variant="outlined"
                        />}
                        onChange={(event, value) => {
                          setFieldValue("Country_Of_Origin", (value?.isoCode ? value.isoCode : ""));
                          setName(value?.isoCode ? value.isoCode : "");
                        }}
                        value={values.Country_Of_Origin ? csc.getCountryByCode(values.Country_Of_Origin) : ""}
                      />
                    </Grid>
                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Warranty_Period && errors.Warranty_Period)}
                        fullWidth
                        multiline
                        helperText={touched.Warranty_Period && errors.Warranty_Period}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Warranty Period"
                        name="Warranty_Period"
                        value={values.Warranty_Period}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>

                    {/* Product_For */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Product_For && errors.Product_For)}
                        fullWidth
                        helperText={touched.Product_For && errors.Product_For}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Product For"
                        name="Product_For"
                        select
                        SelectProps={{ native: true }}
                        value={values.Product_For}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {productforOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Product_Short_Description && errors.Product_Short_Description)}
                        fullWidth
                        helperText={touched.Product_Short_Description && errors.Product_Short_Description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Product Short Description"
                        name="Product_Short_Description"
                        value={values.Product_Short_Description}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                    {/* Discription  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionMin) && touched.Product_Description && errors.Product_Description}
                      </span>
                    </Grid>

                    {/* Features  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Features
                        <EditorContainer editorState={editorStateFeatures} onEditorStateChange={onEditorStateChangeFeatures} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscriptionFeatures || rhDiscriptionFeatures?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionFeaturesMin) && touched.Features && errors.Features}
                      </span>
                    </Grid>

                    {/* Specifications  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Specifications
                        <EditorContainer editorState={editorStateSpecifications} onEditorStateChange={onEditorStateChangeSpecifications} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscriptionSpecifications || rhDiscriptionSpecifications?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionSpecificationsMin) && touched.Specifications && errors.Specifications}
                      </span>
                    </Grid>
                    <Grid item md={2} xs={4}>
                      <Box>
                        ESI Rating</Box></Grid>
                    <Grid item md={2} xs={4}>
                      <Rating
                        name="Esi_Rating"
                        onChange={(event, newValue) => {
                          values.Esi_Rating = newValue;
                          setFieldValue(newValue);
                        }}
                        value={values.Esi_Rating}
                        size="small"
                      />
                    </Grid>

                    {/* ESI Reviews  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        ESI Reviews
                        <EditorContainer editorState={editorStateReviews} onEditorStateChange={onEditorStateChangeReviews} readOnly={true} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscriptionReviews || rhDiscriptionReviews?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionReviewsMin) && touched.Esi_Review && errors.Esi_Review}
                      </span>
                    </Grid>

                    {/* Is_Popular */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Popular}
                            onChange={handleChange}
                            name="Is_Popular"
                            color="primary"
                          />
                        }
                        label="Is Popular"
                      />
                    </Grid>
                    {/* Is_Upcoming */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Upcoming}
                            onChange={handleChange}
                            name="Is_Upcoming"
                            color="primary"
                          />
                        }
                        label="Is Upcoming"
                      />
                    </Grid>
                    {/* Whats_Hot */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Whats_Hot}
                            onChange={handleChange}
                            name="Whats_Hot"
                            color="primary"
                          />
                        }
                        label="What's Hot"
                      />
                    </Grid>
                    {/* Is_Top_Product */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Top_Product}
                            onChange={handleChange}
                            name="Is_Top_Product"
                            color="primary"
                          />
                        }
                        label="Is Top Product"
                      />
                    </Grid>
                    {/* Is_Verified */}
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_Verified}
                            onChange={handleChange}
                            name="Is_Verified"
                            color="primary"
                          />
                        }
                        label="Is Verified"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Trending_Today}
                            onChange={handleChange}
                            name="Trending_Today"
                            color="primary"
                          />
                        }
                        label="Trending Today"
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Today_Recommendations}
                            onChange={handleChange}
                            name="Today_Recommendations"
                            color="primary"
                          />
                        }
                        label="Recommendations"
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            checked={values.Is_MFH_Combo}
                            onChange={handleChange}
                            name="Is_MFH_Combo"
                            color="primary"
                          />
                        }
                        label="Is MFH Combo"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* upload photo */}
                <Grid item md={4} xs={12}  >
                  {blobArrayPics.filter(item => {
                    if (item.key === 1) {
                      return true;
                    } else {
                      return blobArrayPics[item.key - 2].preview !== null;
                    }
                  }).map(option => (
                    <Card style={{ marginBottom: "5px", marginTop: "5px" }} key={"image-card" + option.key}>
                      <CardContent>
                        <Box display="flex" justifyContent="center">
                          {option.preview ? (
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src={option.preview}
                              variant="square"
                            />
                          ) : (
                            <>
                              <Avatar
                                className={classes.images}
                                alt="Brand Upload"
                                src="/static/images/image-placeholder.png"
                                variant="square"
                              />
                            </>
                          )}
                        </Box>

                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          {option.preview ?
                            <> <FormControlLabel
                              disabled={Number(option.Product_Default) === 1 ? true : false}
                              key={"imageUpload" + option.key}
                              control={
                                <Checkbox
                                  onBlur={handleBlur}
                                  checked={Number(option.Product_Default) === 1 ? true : false}
                                  onChange={(e, status) => handleDefaultChange(status, option.key, option.Product_Image_Id)}
                                  name="Is_Default"
                                  color="primary"
                                />
                              }
                              label={<Typography style={{ fontSize: "0.875rem" }}>Is Default</Typography>}
                            /> <Button
                              style={{ fontSize: '10', color: ' #425A70', textTransform: 'none' }}
                              onClick={(e) => handleRemoveImage(e, option.key, option.Product_Image_Id)}
                              disabled={option.Product_Default === 1 ? true : false}
                            >
                                Remove Picture
                              </Button> </>
                            : <input
                              style={{ display: 'block', width: '80%' }}
                              id={"upload-photo" + option.key}
                              name="image"
                              type="file"
                              accept="image/*"
                              onChange={(e) => handleImage(e, option.key)}
                            />}
                          <br />
                        </Box>

                      </CardContent>
                    </Card>
                  ))}
                  <span style={{ color: "red" }}>
                    {blobArrayPics.filter(item => { return !(!item.preview) }).length <= 0 && imageError}
                  </span>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditProduct.propTypes = {
  className: PropTypes.string
};

export default EditProduct;