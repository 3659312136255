import React, { useState, useEffect } from 'react';
import {
    Box, Button, Card, CardContent, Grid, TextField, makeStyles, CardHeader, Divider,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getsingleOrderActionNew, OrderReturn, Order_ReturnStatus_Tracking } from 'src/store/actions/ordersAction';
import { toast } from 'react-toastify'
import isEmpty from 'src/store/validations/is-empty';
import { Step, StepLabel, Stepper, Typography, Paper, CircularProgress } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';

const EditRetrndOrderStatusHistory = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const classes = useStyles();
    const location = useLocation();
    const { orderId } = location.state || {};
    const Orders = useSelector(state => state?.orders?.singleorder?.all);
    const order_Id = Orders?.Order_Id || null;
    const awbNo = Orders?.Delivery_Tracking_Id || null;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openDecl, setOpenDecl] = useState(false);
    const [openFulfill, setOpenFulfill] = useState(false);
    const [reason, setReason] = useState('');
    //dispatch actions
    useEffect(() => {
        if (!isEmpty(orderId)) {
            const formData = {
                "Records_Filter": "FILTER",
                "Order_Id": orderId
            };
            dispatch(getsingleOrderActionNew(formData));
        }
    }, []);

    useEffect(() => {
        if (awbNo) {
            Order_Status_Tracking_Call(awbNo);
        }
    }, [awbNo]);

    //for billing address......
    const address = Orders?.User_Billing_Address_Full;
    let billAddress = null;
    if (address) {
        try {
            billAddress = JSON.parse(address);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

    //for shipping address......
    const shippingAddress = Orders?.User_Shipping_Address_Full;
    let shipAddress = null;
    if (shippingAddress) {
        try {
            shipAddress = JSON.parse(shippingAddress);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

    const deliveryAddress = Orders?.Delivery_Tracking_Payload_Json;
    let delAddress = null;
    if (deliveryAddress) {
        try {
            delAddress = JSON.parse(deliveryAddress);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

    const [orderStatusTrack, setorderStatusTrack] = useState({
        data: {
            order_number: "",
            order_id: "",
            awb_number: "",
            history: []
        }
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleDecClickOpen = () => {
        setOpenDecl(true);
    };

    // const handleExOpen = () => {
    //     setExOpen(true);
    // };

    const handleClose = () => {
        setOpen(false);
        setOpenDecl(false);
        setOpenFulfill(false);
    };

    const handleOpen = () => {
        setOpenConfirm(true);
    };

    const handleFulfillOpen = () => {
        setOpenFulfill(true);
    };

    const Close = () => {
        setOpenConfirm(false);
    };

    const handleConfirm = async (order_Id) => {
        setLoading(true);
        const formData = {
            "Order_Status": "ACCEPTED",
        };
        const result = await dispatch(OrderReturn(order_Id, formData, navigate));
        setLoading(false);
        if (result.data.code === "Success") {
            navigate('/app/returned-order-history')
        } else {
            toast(result.data.UI_Display_Message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
        setOpen(false);
    };

    const handleConfirmReached = async (order_Id) => {
        setLoading(true);
        const formData = {
            "Order_Status": "REACHED",
        };
        const result = await dispatch(OrderReturn(order_Id, formData, navigate));
        setLoading(false);
        if (result.data.code === "Success") {
            navigate('/app/returned-order-history')
        } else {
            toast(result.data.UI_Display_Message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
        setOpen(false);
    };

    const handleFulfillConfirm = async (order_Id) => {
        setLoading(true);
        const formData = {
            "Order_Status": "ORDER_FULFILLED",
        };
        const result = await dispatch(OrderReturn(order_Id, formData, navigate));
        setLoading(false);
        if (result.data.code === "Success") {
            navigate('/app/returned-order-history')
        } else {
            toast(result.data.UI_Display_Message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
        setOpenFulfill(false);
    };

    const decOrder = async (Order_Id) => {
        setLoading(true);
        const formData = {
            "Order_Status": "DECLINED",
            "Decline_Reason": reason,
        };
        const result = await dispatch(OrderReturn(Order_Id, formData));
        setLoading(false);
        if (result.data.code === "Success") {
            navigate('/app/returned-order-history')
        } else {
            toast(result.data.UI_Display_Message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
        setOpenDecl(false);
    };

    const orderDates = Orders?.Order_Status_Dates_Json
    const OrderStepper = ({ orderDates }) => {
        return (
            <Stepper orientation="vertical" style={{ padding: '20px' }}>
                {orderDates && Object.entries(orderDates).map(([key, { Date, BY, ID }]) => (
                    <Step key={key} active>
                        <StepLabel>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '8px' }}>
                                {/* <CalendarTodayIcon color="primary" /> */}
                                <strong>Status: {key}</strong>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'gray' }}>
                                <CalendarTodayIcon fontSize="small" />
                                <span>Date: {Date}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'gray' }}>
                                <PersonIcon fontSize="small" />
                                <span>By: {BY}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'gray' }}>
                                <BadgeIcon fontSize="small" />
                                <span>ID: {ID}</span>
                            </div>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        );
    };

    const isBeforeReturnDate = (Created_Date, Return_Days) => {
        const date = new Date(Created_Date?.Date);
        const compareDate = new Date(date.setDate(date.getDate() + Return_Days));
        const currentDate = new Date();
        // console.log(currentDate, compareDate, Created_Date, Return_Days)
        return ((currentDate >= compareDate));
    }

    const Order_Status_Tracking_Call = async (awbNo) => {
        try {
            const response = await Order_ReturnStatus_Tracking(awbNo);
            if (response) {
                setorderStatusTrack(response); // Make sure response contains data
            } else {
                setorderStatusTrack({
                    data: {
                        order_number: "",
                        order_id: "",
                        awb_number: "",
                        history: []
                    }
                });
            }
        } catch (error) {
            setorderStatusTrack({
                data: {
                    order_number: "",
                    order_id: "",
                    awb_number: "",
                    history: []
                }
            });
        }
    };

    const ShipmentStepper = ({ response }) => {
        // Check if orderStatusTrack.data exists and has history
        if (!response?.data?.history || response.data.history.length === 0) {
            return <Typography>No shipment history available</Typography>;
        }

        const { history } = response.data;
        return (
            <Box sx={{ width: '100%', padding: '20px' }}>
                <Typography variant="h6" gutterBottom>
                    Return Shipment Tracking - Order No: {response.data.order_number},
                    Order Id: {response.data.order_id},
                    AWB No: {response.data.awb_number}
                </Typography>
                <Stepper activeStep={history.length} orientation="vertical">
                    {history.map((event, index) => (
                        <Step key={index}>
                            <StepLabel>
                                <Typography variant="body1">
                                    {event.message} ({event.status_code})
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    {event.location} - {event.event_time}
                                </Typography>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        );
    };


    return (
        <>
            <Card>
                <CardHeader title="Order Details" />
                <Box display="flex" style={{ marginTop: "-20px" }} justifyContent="flex-end" p={1}>

                    {/* <Box display="flex" style={{ marginTop: "-50px" }} justifyContent="flex-end" p={1}> */}

                    {(Orders?.Order_Status === "RETURN" && !Orders?.Return_Status) ?
                        (
                            <Button
                                style={{ backgroundColor: '#27B6CC', marginLeft: 'auto', height: '30px', width: '175px' }}
                                variant="contained"
                                onClick={handleClickOpen}
                            >
                                <span style={{ color: 'white' }}>ACCEPT RETURN</span>
                            </Button>
                        ) :
                        // <Button
                        //     style={{ backgroundColor: '#27B6CC', marginLeft: 'auto', height: '30px', width: '175px' }}
                        //     variant="contained"
                        //     onClick={handleExOpen}
                        // >
                        //     <span style={{ color: 'white', fontWeight: 'bold' }}>ACCEPT EXCHANGE</span>
                        // </Button>
                        <></>
                    }

                    {(Orders?.Order_Status === "RETURN" && !Orders?.Return_Status) ?
                        (
                            <Button
                                style={{ backgroundColor: '#27B6CC', marginLeft: '20px', height: '30px', width: '175px' }}
                                variant="contained"
                                onClick={handleDecClickOpen}
                            >
                                <span style={{ color: 'white' }}>DECLINE RETURN</span>
                            </Button>
                        ) :

                        <></>
                    }

                    {/* </Box> */}
                    {Orders?.Return_Status === "ACCEPTED" ?
                        (
                            <Button
                                style={{ backgroundColor: '#27B6CC', marginLeft: 'auto', height: '30px', width: '175px' }}
                                variant="contained"
                                onClick={handleOpen}
                            >
                                <span style={{ color: 'white' }}>REACHED</span>
                            </Button>
                        ) : null
                    }

                    {Orders?.Return_Status === "DECLINED" ?
                        (
                            <span style={{ color: 'red', fontWeight: 'bold' }}>DECLINED RETURN ORDER</span>
                        ) : null
                    }

                    {(Orders?.Return_Status === "DECLINED" && isBeforeReturnDate(Orders?.Order_Status_Dates_Json?.DELIVERED, Orders?.Return_Days) && Orders?.Is_Order_Fulfilled === 0) ?
                        (
                            <Button
                                style={{ backgroundColor: '#27B6CC', marginLeft: 'auto', height: '30px', width: '175px' }}
                                variant="contained"
                                onClick={handleFulfillOpen}
                            >
                                <span style={{ color: 'white' }}>FULFILL</span>
                            </Button>
                        ) : null
                    }

                </Box>
                <Divider />

                <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                    {/* Order Information */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Order Information
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                                <Typography variant="body1" style={{ marginRight: '1rem' }}>Order ID: {Orders?.Order_Id}</Typography>
                                {Orders?.Exchange_Reference_Retail_Order_Id && (
                                    <Typography variant="body1" style={{ fontWeight: 'bold', color: 'orange' }}>EXCHANGED ORDER</Typography>
                                )}
                            </div>
                            <Typography variant="body2">
                                Order Status: <span style={{ fontWeight: 'bold' }}>{Orders?.Order_Status}</span>
                            </Typography>
                            {Orders?.Return_Status &&
                                <Typography variant="body2">
                                    Return Status: <span style={{ fontWeight: 'bold' }}>{Orders?.Return_Status}</span>
                                </Typography>
                            }
                            <Typography variant="body2">User Email ID: {Orders?.Email_Id}</Typography>
                            <Typography variant="body2">Partner Detail ID: {Orders?.Partner_Detail_Id}</Typography>
                            <Typography variant="body2">Partner Name: {Orders?.Name}</Typography>
                            {/* <Typography variant="body2">Created Date: {Orders?.Created_Date}</Typography>
                            <Typography variant="body2">Modified Date: {Orders?.Modified_Date}</Typography> */}
                            {Orders?.Partner_Product_Id &&
                                <Typography variant="body2">Partner Product Id: {Orders?.Partner_Product_Id}</Typography>}

                            {Orders?.Gift_History_Id &&
                                <Typography variant="body2">Gift History Id: {Orders?.Gift_History_Id}</Typography>}

                            {Orders?.Gift_Wrap_Message &&
                                <Typography variant="body2">Gift Wrap Msg: {Orders?.Gift_Wrap_Message}</Typography>}

                            {Orders?.Cancel_Return_Exchange_Reason_For &&
                                <Typography variant="body2">
                                    Return/Exchanged Reason For:
                                    <Typography component="span" variant="body1" style={{ fontWeight: 'bold', color: 'red', marginLeft: '7px' }}>
                                        {Orders?.Cancel_Return_Exchange_Reason_For}
                                    </Typography>
                                </Typography>
                            }

                            {Orders?.Cancel_Return_Exchange_Reason_Info &&
                                <Typography variant="body2">
                                    Return/Exchanged Reason Info:
                                    <Typography component="span" variant="body1" style={{ fontWeight: 'bold', color: 'red', marginLeft: '7px' }}>
                                        {Orders?.Cancel_Return_Exchange_Reason_Info}
                                    </Typography>
                                </Typography>
                            }

                            <OrderStepper orderDates={orderDates} />
                        </Paper>
                    </Grid>

                    {/* Billing Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Billing Details
                            </Typography>
                            <Typography variant="body2">Address ID: {billAddress?.USER_ADDRESS_ID}</Typography>
                            <Typography variant="body2">Email ID: {billAddress?.USER_EMAIL_ID}</Typography>
                            <Typography variant="body2">Address Type: {billAddress?.ADDRESS_TYPE}</Typography>
                            <Typography variant="body2">Landmark: {billAddress?.LAND_MARK}</Typography>
                            <Typography variant="body2">City: {billAddress?.CITY}</Typography>
                            <Typography variant="body2">State: {billAddress?.STATE}</Typography>
                            <Typography variant="body2">Country: {billAddress?.COUNTRY}</Typography>
                            <Typography variant="body2">ZIP: {billAddress?.ZIP}</Typography>
                        </Paper>
                    </Grid>

                    {/* Payment Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Payment Details
                            </Typography>
                            <Typography variant="body2">Total Order Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Total_Order_Value}</span></Typography>
                            <Typography variant="body2">Total Tax Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Total_Tax}</span></Typography>
                            <Typography variant="body2">VAT Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Vat}</span></Typography>
                            <Typography variant="body2">Payment Method: <span style={{ fontWeight: 'bold' }}>{Orders?.Payment_Type}</span></Typography>
                            <Typography variant="body2">Payment Reference ID: <span style={{ fontWeight: 'bold' }}>{Orders?.Payment_Transaction_Id}</span></Typography>
                            <Typography variant="body2">Sgst: <span style={{ fontWeight: 'bold' }}>{Orders?.Sgst}</span></Typography>
                            <Typography variant="body2">Wallet Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Wallet_Amount}</span></Typography>
                            <Typography variant="body2">Wallet History Id: <span style={{ fontWeight: 'bold' }}>{Orders?.Wallet_History_Id}</span></Typography>

                            {Orders?.Exchange_Reference_Retail_Order_Id ?
                                <span style={{ fontWeight: 'bold', color: 'green' }}>Payment Status : SUCCESS</span>
                                :
                                <Typography variant="body2" style={{ color: Orders?.Payment_Transaction_Status === "SUCCESS" ? 'green' : 'red' }}>
                                    Payment Status: <span style={{ fontWeight: 'bold' }}>{Orders?.Payment_Transaction_Status === "SUCCESS" ? "SUCCESS" : "FAIL"}</span>
                                </Typography>
                            }
                            <Typography variant="body2">Wallet Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Wallet_Amount}</span></Typography>
                            <Typography variant="body2">Shipping Fee: <span style={{ fontWeight: 'bold' }}>{Orders?.Shipping_Amount}</span></Typography>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                {(Orders.Refund_Online_Amount == null) || (Orders.Refund_Online_Amount == 0) ?
                                    <></>
                                    :
                                    <div>Refunded Online Amount: <span style={{ fontWeight: 'bold', color: '#27b6cc' }}>{Orders.Refund_Online_Amount}</span> </div>
                                }
                                {(Orders.Refund_Wallet_Amount == null) || (Orders.Refund_Wallet_Amount == 0) ?
                                    <></>
                                    :
                                    <div>Refunded Wallet Amount: <span style={{ fontWeight: 'bold', color: '#27b6cc' }}>{Orders.Refund_Wallet_Amount}</span></div>
                                }

                            </div>
                            <Typography variant="body2">Partner Coupon Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Partner_Coupon_Amount}</span></Typography>
                            {Orders?.Partner_Settlement_Amount &&
                                <Typography variant="body2">Partner Settlement Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Partner_Settlement_Amount}</span></Typography>}
                            <Typography variant="body2">Gift Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Gift_Amount}</span></Typography>
                            {Orders?.Gift_Wrap_Amount &&
                                <Typography variant="body2">Gift Wrap Amount: <span style={{ fontWeight: 'bold' }}>{Orders?.Gift_Wrap_Amount}</span></Typography>}
                            <Typography variant="body2">Transaction Id: <span style={{ fontWeight: 'bold' }}>{Orders?.Transaction_Id}</span></Typography>

                        </Paper>
                    </Grid>

                    {/* Shipping Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Shipping Details
                            </Typography>
                            <Typography variant="body2">Address ID: {shipAddress?.USER_ADDRESS_ID}</Typography>
                            <Typography variant="body2">Email ID: {shipAddress?.USER_EMAIL_ID}</Typography>
                            <Typography variant="body2">Address Type: {shipAddress?.ADDRESS_TYPE}</Typography>
                            <Typography variant="body2">Landmark: {shipAddress?.LAND_MARK}</Typography>
                            <Typography variant="body2">City: {shipAddress?.CITY}</Typography>
                            <Typography variant="body2">State: {shipAddress?.STATE}</Typography>
                            <Typography variant="body2">Country: {shipAddress?.COUNTRY}</Typography>
                            <Typography variant="body2">ZIP: {shipAddress?.ZIP}</Typography>
                        </Paper>
                    </Grid>

                    {/* Delivery Details */}

                    {delAddress &&
                        <Grid item xs={12} md={6}>
                            <Paper elevation={3} style={{ padding: '1rem' }}>
                                <Typography variant="h6" gutterBottom>
                                    Delivery Details
                                </Typography>
                                <Typography variant="body2">Order ID: {delAddress?.order_number}</Typography>
                                <Typography variant="body2">Amount: {delAddress?.order_items[0]?.price}</Typography>
                                <Typography variant="body2">Payment Type: {delAddress?.payment_type}</Typography>
                                <Typography variant="body2">User Name: {delAddress?.consignee?.name}</Typography>
                                <Typography variant="body2">Delivery Address: {delAddress?.consignee?.address}</Typography>
                                <Typography variant="body2">Pickup Address: {delAddress?.pickup?.address}</Typography>
                                <Typography variant="body2">User Mob No: {delAddress?.consignee?.phone}</Typography>
                            </Paper>
                        </Grid>
                    }

                    {/* Product Details */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '1rem' }}>
                            <Typography variant="h6" gutterBottom>
                                Product Details
                            </Typography>
                            <Typography variant="body2">Name: <span style={{ fontWeight: 'bold' }}>{Orders.Product_Name}</span> </Typography>
                            <Typography variant="body2">Quantity: <span style={{ fontWeight: 'bold' }}>{Orders.Quantity}</span></Typography>
                            <Typography variant="body2">Price Per Quantity: <span style={{ fontWeight: 'bold' }}>{Orders.Quantity_Selling_Price}</span></Typography>
                            <Typography variant="body2">Selling Price: <span style={{ fontWeight: 'bold' }}>{Orders.Base_Selling_Price}</span></Typography>
                            <Typography variant="body2">MRP: <span style={{ fontWeight: 'bold' }}>{Orders.Mrp}</span></Typography>
                            <Typography variant="body2">Size: <span style={{ fontWeight: 'bold' }}>{Orders.Size} {Orders.Size_Measurement}</span></Typography>
                        </Paper>
                    </Grid>
                </Grid>
                <Divider />
                {Orders?.Order_Status == "RETURN" || Orders?.Order_Status == "EXCHANGE" ?
                    <div style={{ marginTop: '20px' }}>
                        <ShipmentStepper response={orderStatusTrack} />
                    </div>
                    :
                    <></>
                }

                <Dialog open={open}>
                    <DialogTitle>Order Return</DialogTitle>
                    <DialogContent>
                        <div>Accept the RETURN for this order: {order_Id}?</div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleConfirm(order_Id)} disabled={loading}
                            style={{ backgroundColor: '#27B6CC', margin: "1%" }} variant="contained">
                            {/* Confirm */}
                            {loading ? (
                                <CircularProgress size={24} style={{ color: 'white' }} />
                            ) : (
                                <span style={{ color: 'white' }}>Confirm</span>
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* <Dialog open={openEx}>
                    <DialogTitle>Order Exchange</DialogTitle>
                    <DialogContent>
                        <div>Accept the EXCHANGE for this order: {order_Id}?</div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleConfirm(order_Id)} color="primary" variant="contained">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog> */}

                {/* for accepting return order */}

                <Dialog open={openConfirm}>
                    <DialogTitle>Order Reached</DialogTitle>
                    <DialogContent>
                        <div>Are you sure that Order - {order_Id} has Reached ?</div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={Close} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleConfirmReached(order_Id)} disabled={loading}
                            style={{ backgroundColor: '#27B6CC', margin: "1%" }} variant="contained">
                            {/* Confirm */}
                            {loading ? (
                                <CircularProgress size={24} style={{ color: 'white' }} />
                            ) : (
                                <span style={{ color: 'white' }}>Confirm</span>
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* for decling return order */}
                <Dialog open={openDecl}>
                    <DialogTitle>Decline Order</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Sure to decline this order - {orderId} ?
                        </DialogContentText>

                        <TextField
                            autoFocus
                            margin="dense"
                            id="reason"
                            label="Reason for decline"
                            type="text"
                            fullWidth
                            style={{ width: '400px' }}
                            required
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            variant="outlined"
                        />

                    </DialogContent>
                    <DialogActions>
                        {/* Cancel Button */}
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        {reason &&
                            <Button
                                style={{ backgroundColor: '#27B6CC', margin: "1%" }}
                                variant="contained"
                                disabled={loading}
                                onClick={() => decOrder(Orders?.Order_Id)}
                            >
                                {loading ? (
                                    <CircularProgress size={24} style={{ color: 'white' }} />
                                ) : (
                                    <span style={{ color: 'white' }}>Confirm</span>
                                )}
                                {/* <span style={{ color: 'white' }}>Decline </span> */}
                            </Button>
                        }
                    </DialogActions>
                </Dialog>

                <Dialog open={openFulfill}>
                    <DialogTitle>Order Fulfill</DialogTitle>
                    <DialogContent>
                        <div>Are you sure to FULFILL this order: {order_Id} to USER?</div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={loading} onClick={() => handleFulfillConfirm(order_Id)}
                            style={{ backgroundColor: '#27B6CC', margin: "1%" }} variant="contained">
                            {/* Confirm */}
                            {loading ? (
                                <CircularProgress size={24} style={{ color: 'white' }} />
                            ) : (
                                <span style={{ color: 'white' }}>Confirm</span>
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>

            </Card>

        </>
    )
}

export default EditRetrndOrderStatusHistory;
