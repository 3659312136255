import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import CreateIcon from '@material-ui/icons/Create';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import SimpleDialog from './../../../components/dialogs/dialog'
import DeleteIcon from '@material-ui/icons/Delete';
import { HiUserGroup } from "react-icons/hi";
import isEmpty from '../../../store/validations/is-empty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';
import HubIcon from '@mui/icons-material/Hub';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
    width: '90px'
  }
}));

const headCells = [
  { id: 'Product_Id', numeric: false, disablePadding: true, label: 'Product ID' },
  { id: 'Product_Name', numeric: false, disablePadding: false, label: 'Product Name' },
  { id: 'Price', numeric: true, disablePadding: false, label: 'Price' },
  { id: 'Product_For', numeric: true, disablePadding: false, label: 'Product For' },
  { id: 'Is_Mfh_Combo', numeric: true, disablePadding: false, label: 'Is MFH Combo' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allProducts,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  deleteProductHandler,
  copyProductHandler,
  allProductsNoFilter,
  pagination,
  setPagination,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const handleClose = (value) => {
    setOpen(false);
  };
  /*===============================
              handler     
   =================================*/

  const handleSelectAll = event => {
    let newSelectedProductIds;
    if (event.target.checked) {
      newSelectedProductIds = allProducts.map(product => product.Product_Id);
    }
    else {
      newSelectedProductIds = [];
    }
    setSelectedProductIds(newSelectedProductIds);
  };
  const handleSelectOne = (event, Id) => {
    const selectedIndex = selectedProductIds.indexOf(Id);
    let newselectedProductIds = [];
    if (selectedIndex === -1) {
      newselectedProductIds = newselectedProductIds.concat(selectedProductIds, Id);
    } else if (selectedIndex === 0) {
      newselectedProductIds = newselectedProductIds.concat(
        selectedProductIds.slice(1)
      );
    } else if (selectedIndex === selectedProductIds.length - 1) {
      newselectedProductIds = newselectedProductIds.concat(
        selectedProductIds.slice(0, -1)
      );
    }
    else if (selectedIndex > 0) {
      newselectedProductIds = newselectedProductIds.concat(
        selectedProductIds.slice(0, selectedIndex),
        selectedProductIds.slice(selectedIndex + 1)
      );
    }
    setSelectedProductIds(newselectedProductIds);
  };

  const deleteSelectedProductHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };
  const editProductHandler = productData => e => {
    navigate('/app/edit-product', { state: productData });
  };
  const viewProductHandler = productData => e => {
    navigate('/app/view-products', { state: productData });
  };

  const OpenPartnerHandler = ProductData => e => {
    navigate('/app/product/partner-management', { state: ProductData });
  };
  const RelatedProductHandler = ProductData => e => {
    navigate('/app/product/related-products-management', { state: ProductData });
  };
  
  const OpenMFHProductHandler = ProductData => e => {
    navigate('/app/meepaisa-product-combo', { state: ProductData });
  };

  /*=================================
              handler end
  =================================*/
  return (
    <Card className={clsx(classes.root, className)}
      {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1500}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedProductIds.filter(record => {
                      for (let i = 0; i < allProductsNoFilter.length; i++) {
                        if (record == allProductsNoFilter[i].Product_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allProducts.length}
                    color="primary"
                    indeterminate={
                      selectedProductIds.filter(record => {
                        for (let i = 0; i < allProductsNoFilter.length; i++) {
                          if (record == allProductsNoFilter[i].Product_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedProductIds.filter(record => {
                        for (let i = 0; i < allProductsNoFilter.length; i++) {
                          if (record == allProductsNoFilter[i].Product_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allProducts.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>

                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Product_Management_Write?.Value === "Product_Management_Write" && RolePermissions?.Product_Management_Write?.Is_Write === 1) ?
                  <TableCell >MFH Products</TableCell>
                  : null}
                 {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Product_Management_Write?.Value === "Product_Management_Write" && RolePermissions?.Product_Management_Write?.Is_Write === 1) ?
                  <TableCell >Product Partners</TableCell>
                  : null}
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Product_Management_Write?.Value === "Product_Management_Write" && RolePermissions?.Product_Management_Write?.Is_Write === 1) ?
                  <TableCell >Related Products</TableCell>
                  : null}
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Copy_Products_Write?.Value === "Copy_Products_Write" && RolePermissions?.Copy_Products_Write?.Is_Write === 1) ?
                  <TableCell >Copy Products</TableCell>
                  : null}
                <TableCell className={classes.headerColumn}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                  Actions &nbsp; &nbsp;&nbsp; &nbsp;
                  {selectedProductIds.filter(record => {
                    for (let i = 0; i < allProductsNoFilter.length; i++) {
                      if (record === allProductsNoFilter[i].Product_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 &&
                    <Button disabled={selectedProductIds.filter(record => {
                      for (let i = 0; i < allProductsNoFilter.length; i++) {
                        if (record === allProductsNoFilter[i].Product_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length <= 0}
                      onClick={deleteSelectedProductHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allProducts) && (allProducts).map(product => (
                <TableRow
                  hover
                  key={product.Product_Id}
                  selected={
                    selectedProductIds.indexOf(product.Product_Id) !== -1
                  }
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedProductIds.indexOf(product.Product_Id) !== -1
                      }
                      onChange={event =>
                        handleSelectOne(event, product.Product_Id)
                      }
                      value="true"
                    />
                  </TableCell>
                  <TableCell width="10%">{product.Product_Id}</TableCell>
                  <TableCell width="20%" style={{ maxHeight: "20px", overflowY: "hidden" }}>{product.Product_Name}</TableCell>
                  <TableCell width="10%">₹{product.Price}</TableCell>
                  <TableCell width="10%">{product.Product_For}</TableCell>
                  <TableCell width="10%">{product.Is_Mfh_Combo}</TableCell>
                  <TableCell style={{ color: '#1665D8' }}>{product.Status}</TableCell>
                  {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                    (RolePermissions?.Product_Management_Write?.Value === "Product_Management_Write" && RolePermissions?.Product_Management_Write?.Is_Write === 1) ?
                    <TableCell>
                      <Button disabled={product.Is_Mfh_Combo  === 0 ? true : false}>
                      <Tooltip title="MFH Products">
                          <HubIcon
                            onClick={OpenMFHProductHandler(product)}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#9EA0A5', fontSize: 20, }} />               
                      </Tooltip>
                        </Button>
                    </TableCell>
                    : null}
                  {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                    (RolePermissions?.Product_Management_Write?.Value === "Product_Management_Write" && RolePermissions?.Product_Management_Write?.Is_Write === 1) ?
                    <TableCell>
                      <Tooltip title="Product Partners">
                        <Button>
                          <HiUserGroup
                            onClick={OpenPartnerHandler(product)}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#9EA0A5', fontSize: 20, }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                    : null}
                  {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                    (RolePermissions?.Product_Management_Write?.Value === "Product_Management_Write" && RolePermissions?.Product_Management_Write?.Is_Write === 1) ?
                    <TableCell >
                      <Tooltip title="Related Products">
                        <Button style={{ marginLeft: '17%' }}>
                          < HorizontalSplitIcon
                            onClick={RelatedProductHandler(product)}
                            style={{ display: 'flex', justifyContent: 'center', color: '#9EA0A5', fontSize: 20, }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                    : null}
                  {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                    (RolePermissions?.Copy_Products_Write?.Value === "Copy_Products_Write" && RolePermissions?.Copy_Products_Write?.Is_Write === 1) ?
                    <TableCell>
                      <Button
                        style={{ backgroundColor: '#27B6CC' }}
                        variant="contained" onClick={copyProductHandler(product)}
                      >
                        <span style={{ color: "white" }}>
                          Copy
                        </span>
                      </Button>
                    </TableCell>
                    : null}
                  {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                    (RolePermissions?.Product_Management_Write?.Value === "Product_Management_Write" && RolePermissions?.Product_Management_Write?.Is_Write === 1) ?
                    <TableCell style={{ textAlign: "center", }}>
                      <Button
                        onClick={editProductHandler(product)}
                      >
                        <CreateIcon style={{ color: '#9EA0A5' }} />
                      </Button>
                      <Button>
                        <DeleteIcon
                          onClick={deleteProductHandler(product)}
                          style={{ color: '#9EA0A5', }}
                        />
                      </Button>
                      <Tooltip title="Product Details">
                        <Button
                          onClick={viewProductHandler(product)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                    : null}
                  {RolePermissions?.Product_Management_Read?.Value === "Product_Management_Read" && RolePermissions?.Product_Management_Read?.Is_Read === 1 &&
                    <TableCell style={{ textAlign: "center", }}>
                      <Tooltip title="Product Details">
                        <Button
                          onClick={viewProductHandler(product)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allProducts) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allProducts: PropTypes.array.isRequired
};

export default Results;