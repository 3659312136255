
import config from './config';
import { toast } from 'react-toastify';
import { nodeCudApi} from './commonAxios';

export const PARTNER_PAYMENT_UPDATE_REQUEST = "PARTNER_PAYMENT_UPDATE_REQUEST";
export const PARTNER_PAYMENT_UPDATE_SUCCESS = "PARTNER_PAYMENT_UPDATE_SUCCESS";
export const PARTNER_PAYMENT_UPDATE_FAIL = "PARTNER_PAYMENT_UPDATE_FAIL";



export const updatePartnerPaymentAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: PARTNER_PAYMENT_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Payments/PPS_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNER_PAYMENT_UPDATE_SUCCESS
        });
        toast('Partner Payment updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partner-payment-settlements');
      } else {

        dispatch({
          type: PARTNER_PAYMENT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {

      dispatch({
        type: PARTNER_PAYMENT_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};