import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateRoleAction
} from './../../../store/actions/roleActions';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EditorContainer from '../../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useLocation } from 'react-router-dom';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import { getAllPermissionsAction } from 'src/store/actions/permissionsAction';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";

const statusOption = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'ADMIN',
    label: 'ADMIN'
  },
  {
    value: 'USER',
    label: 'USER'
  },
  {
    value: 'DELIVERY',
    label: 'DELIVERY'
  },
  {
    value: 'PARTNER',
    label: 'PARTNER'
  },
  {
    value: 'EMPLOYEE',
    label: 'EMPLOYEE'
  },
  {
    value: 'HR',
    label: 'HR'
  },
  {
    value: 'SALES',
    label: 'SALES'
  },
  {
    value: 'DELIVERY_ESI',
    label: 'DELIVERY_ESI'
  },
  {
    value: 'QA',
    label: 'QA'
  },
  {
    value: 'CMS',
    label: 'CMS'
  },
  {
    value: 'PARTNER_ADMIN',
    label: 'PARTNER_ADMIN'
  },
  {
    value: 'PARTNER_DELIVERY',
    label: 'PARTNER_DELIVERY'
  },
  {
    value: 'PARTNER_EMPLOYEE',
    label: 'PARTNER_EMPLOYEE'
  },
  {
    value: 'SUPER_ADMIN',
    label: 'SUPER_ADMIN'
  },
];

const statusfieldOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: 120,
    height: 120,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditRole = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  let { state } = location;

  useEffect(() => {
    const formData = {
      Records_Filter: 'ALL',
      search_by_filter: "",
      search: ""
    };
    dispatch(getAllPermissionsAction(formData));
  }, []);

  const isSubmited = useSelector(state => state.role.roleUpdate.isLoading);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);
  const allPermissions = useSelector(state => state.permission.permissions.all);
  const [grant, setGrant] = useState(eval(state.Permission));

  // Richtext editor
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Role_Description || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Role_Description);
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <Formik
      initialValues={{
        Role_Description: state.Role_Description ? state.Role_Description : "",
        Role_Name: state.Role_Name ? state.Role_Name : "",
        Permission: state.Permission ? state.Permission : "",
        Status: state.Status ? state.Status : "",
        Modified_By: modifiedBy,
      }}
      validationSchema={Yup.object().shape({
        Role_Name: Yup.string().required('Role Name is required.'),
        Status: Yup.string().required('Status is required.'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        values.Role_Description = rhDiscription;

        if (!values.Role_Description || values.Role_Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Role_Description = "Role Description is required.";
        }
        else if (values.Role_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Role_Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(updateRoleAction(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}
        >
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    {/*  Role_Name */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Role_Name && errors.Role_Name)}
                        fullWidth
                        helperText={touched.Role_Name && errors.Role_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Role Name"
                        name="Role_Name"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Role_Name}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusfieldOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={allPermissions}
                        disableCloseOnSelect
                        groupBy={(option) => option.firstLetter}
                        variant="outlined"
                        value={grant}
                        getOptionLabel={(option) => option.Label}
                        onChange={(e, value) => {
                          setFieldValue("Permission", (JSON.stringify(value)) ? (JSON.stringify(value)) : "");
                          setGrant(value);
                        }}
                        getOptionSelected={(option, value) => option.Label === value.Label}
                        renderOption={(props, option) => (
                          <div {...props}>
                            <Checkbox
                              checked={option.selected}
                            />
                            {props.Label}
                          </div>
                        )}
                        renderInput={(params) => <TextField {...params}
                          error={Boolean(touched.Permission && errors.Permission)}
                          helperText={touched.Permission && errors.Permission}
                          onBlur={handleBlur}
                          label="Permissons"
                          name="Permission"
                          variant="outlined"
                          onChange={() => {
                            dispatch(getAllPermissionsAction(params.inputProps.value))
                          }}
                          InputProps={{
                            ...params.InputProps,
                            autoComplete: 'off', // disable autocomplete and autofill,
                            startAdornment: (
                              <React.Fragment>
                                <SearchIcon color="inherit" size={20}></SearchIcon>
                                {params.InputProps.startAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />}
                      />
                    </Grid>

                    {/* Purpose Description  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Role Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Role_Description && errors.Role_Description}
                      </span>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} pt={1} pb={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditRole.propTypes = {
  className: PropTypes.string
};

export default EditRole;