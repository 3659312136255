import { toast } from 'react-toastify';
import { boomiAdminApi, nodeCudApi, nodeGetApi } from './commonAxios';

export const BID_VEHICLE_BRAND_CREATE_REQUEST = "BID_VEHICLE_BRAND_CREATE_REQUEST";
export const BID_VEHICLE_BRAND_CREATE_SUCCESS = "BID_VEHICLE_BRAND_CREATE_SUCCESS";
export const BID_VEHICLE_BRAND_CREATE_FAIL = "BID_VEHICLE_BRAND_CREATE_FAIL";

export const BID_VEHICLE_BRAND_UPDATE_REQUEST = "BID_VEHICLE_BRAND_UPDATE_REQUEST";
export const BID_VEHICLE_BRAND_UPDATE_SUCCESS = "BID_VEHICLE_BRAND_UPDATE_SUCCESS";
export const BID_VEHICLE_BRAND_UPDATE_FAIL = "BID_VEHICLE_BRAND_UPDATE_FAIL";

//create Bidding Ride Vehicle Brand Action
export const createBidVehicleBrandAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: BID_VEHICLE_BRAND_CREATE_REQUEST
    });
    nodeCudApi.post(`/Bidding_Ride_Vehicle_Brands/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: BID_VEHICLE_BRAND_CREATE_SUCCESS
                });
                toast('Bidding Vehicle Brand created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/bidding-ride-vehicle-brands');
            } else {
                dispatch({
                    type: BID_VEHICLE_BRAND_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: BID_VEHICLE_BRAND_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

//update Bidding Ride Vehicle Brand Action
export const updateBidVehicleBrandAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: BID_VEHICLE_BRAND_UPDATE_REQUEST
    });
    nodeCudApi.post(`/Bidding_Ride_Vehicle_Brands/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: BID_VEHICLE_BRAND_UPDATE_SUCCESS
                });
                toast('Bidding Vehicle Brand updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/bidding-ride-vehicle-brands');
            } else {

                dispatch({
                    type: BID_VEHICLE_BRAND_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: BID_VEHICLE_BRAND_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//Bidding Ride Vehicle Brand Image Upload
export const uploadBiddingVehicleBrandImage = (
    formData,
    callBackUploadImage,
) => async dispatch => {
    try {
        let { data } = await boomiAdminApi.post(`/SA_Upload/Upload?functionality=VehicleBrand&fileextension=png&filetype=Images&filename=VehicleBrand`, formData);
        if (data) {
            callBackUploadImage(data);
        }
    } catch (err) { }
};

//Get  Bidding Ride Vehicle Brands
export const getBiddingVehicleBrandAction = (formData, callBackBiddingVehicleBrand) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(`/Bidding_Rides_Vehicle_Brand/Get`, formData);
        if (data) {
            callBackBiddingVehicleBrand(data)
        }
    } catch (err) {
    }
};