import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Avatar
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import {
  updateOnlinePartner,
  updatePhysicalPartner,
  uploadPartnerlogoImage,
  uploadPartnerbannerImage,
  getpartnerbusinesstype,
  getbussinessubtypeAction,
  uploadPartnerGstImage,
  uploadPartnerPanImage
} from './../../store/actions/partnerAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import Rating from '@material-ui/lab/Rating';
import PropTypes from 'prop-types';
import { ConnectedFocusError } from "focus-formik-error";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/material.css'
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditPartner = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [mobile, setMobile] = useState(state?.Mobile);

  useEffect(() => {
    let data = {
      "Business_Type": "",
      "Records_Filter": "ALL"
    }
    if (state.Business_Type !== '') {
      let formData = {
        "Business_Type": state.Business_Type,
        "Records_Filter": "FILTER"
      }
      dispatch(getbussinessubtypeAction(formData, callBacksubtypeData))
    }
    dispatch(getpartnerbusinesstype(data, callBacktypeData))
  }, []);

  const setType = (value) => {
    if (value !== "") {
      let formData = {
        "Business_Type": value,
        "Records_Filter": "FILTER"
      }
      dispatch(getbussinessubtypeAction(formData, callBacksubtypeData))
    }
  }

  const [typedata, setTypeData] = React.useState([]);
  let BUSINESSTYPE = []
  if (typedata) {
    for (let i = 0; i < typedata?.length; i++) {
      BUSINESSTYPE.push(
        {
          label: typedata[i].BUSINESS_TYPE,
          value: typedata[i].BUSINESS_TYPE
        })
    }
  }
  const callBacktypeData = (data) => {
    setTypeData(data)
  }

  const [subtypedata, setsubtypeData] = React.useState([]);
  let BUSINESSSUBTYPE = []
  if (subtypedata) {
    for (let i = 0; i < subtypedata?.length; i++) {
      BUSINESSSUBTYPE.push(
        {
          label: subtypedata[i].BUSINESS_SUB_TYPE,
          value: subtypedata[i].BUSINESS_SUB_TYPE
        });
    }
  }

  const callBacksubtypeData = (data) => {
    setsubtypeData(data)
  }

  const isSubmited = useSelector(state => state.partner.partnerUpdate.isLoading);
  const errorMessage = useSelector(state => state.partner.physicalpartnerUpdate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor  
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Description || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Description);
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // Richtext editor (ESI Reviews) 
  const contentStateReviews = ContentState.createFromBlockArray(htmlToDraft(state.Esi_Reviews || "").contentBlocks);
  const [editorStateReviews, setEditorStateReviews] = useState(EditorState.createWithContent(contentStateReviews));
  const [rhDiscriptionReviews, setRhDiscriptionReviews] = useState(state.Esi_Reviews);
  const [rhDiscriptionReviewsMin, setRhDiscriptionReviewsMin] = useState(20);
  const onEditorStateChangeReviews = (editorStateReviews) => {
    setEditorStateReviews(editorStateReviews);
    setRhDiscriptionReviews(draftToHtml(convertToRaw(editorStateReviews.getCurrentContent())));
  };

  //logo img upload
  const [image, setImage] = useState({ preview: state.Logo_Path, raw: state.Logo_Path });
  const [imgUrl, setImgUrl] = useState(state.Logo_Path);
  const [uploadPic, setUploadPic] = useState(false);

  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadPartnerlogoImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  //banner img1 upload
  const [image1, setImage1] = useState({ preview: state.Banner_Path, raw: state.Banner_Path });
  const [imgUrl1, setImgUrl1] = useState(state.Banner_Path);
  const [uploadPic1, setUploadPic1] = useState(false);

  const callBackUploadImgae1 = data => {
    if (data) {
      setImgUrl1(data.File_URL);
    }
    setUploadPic1(false);
  };

  const handleImage1 = e => {
    setUploadPic1(true);
    e.preventDefault();
    setImage1({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadPartnerbannerImage(new Blob([e.target.files[0]]), callBackUploadImgae1)
    );
  };

  const handleRemoveImage1 = e => {
    setImgUrl1('');
    setImage1({
      preview: '',
      raw: ''
    });
  };

  // Pan Card Img Upload
  const [panimage, setPanImage] = useState({ preview: state.PAN_Card_Image, raw: state.PAN_Card_Image });
  const [panimgUrl, setPanImgUrl] = useState(state.PAN_Card_Image);
  // const [uploadPanPic, setUploadPanPic] = useState(false);
  const [panError, setPanError] = useState();

  const callBackUploadPanImage = data => {
    if (data) {
      setPanImgUrl(data.File_URL);
    }
    // setUploadPanPic(false);
  };

  const handlePanImage = e => {
    // setUploadPanPic(true);
    e.preventDefault();
    setPanImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadPartnerPanImage(new Blob([e.target.files[0]]), callBackUploadPanImage, state?.Partner_Details_Id)
    );
  };

  const handleRemovePanImage = e => {
    setPanImgUrl('');
    setPanImage({
      preview: '',
      raw: ''
    });
  };

  // GST Card Img Upload
  const [gstimage, setGstImage] = useState({ preview: state.GST_Image, raw: state.GST_Image });
  const [gstimgUrl, setGstImgUrl] = useState(state.GST_Image);
  // const [uploadGstPic, setUploadGstPic] = useState(false);
  const [gstError, setGstError] = useState();

  const callBackUploadGstImage = data => {
    if (data) {
      setGstImgUrl(data.File_URL);
    }
    // setUploadGstPic(false);
  };

  const handleGstImage = e => {
    // setUploadGstPic(true);
    e.preventDefault();
    setGstImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadPartnerGstImage(new Blob([e.target.files[0]]), callBackUploadGstImage, state?.Partner_Details_Id)
    );
  };

  const handleRemoveGstImage = e => {
    setGstImgUrl('');
    setGstImage({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        Partner_Details_Id: state.Partner_Details_Id ? state.Partner_Details_Id : "",
        Email_Id: state.Email_Id ? state.Email_Id : "",
        Name: state.Name ? state.Name : "",
        Website_Url: state.Website_Url ? state.Website_Url : "",
        Type: state.Type ? state.Type : "",
        Mobile: state.Mobile ? state.Mobile : "",
        Land_Phone: state.Land_Phone ? state.Land_Phone : "",
        Gst: state.GST ? state.GST : "",
        Pan: state.PAN ? state.PAN : "",
        Short_Description: state.Short_Description ? state.Short_Description : "",
        Description: state.Description ? state.Description : "",
        Tips: state.User_Tips ? state.User_Tips : "",
        Logo_Path: imgUrl,
        Banner_Path: imgUrl1,
        Is_Popular: state.Is_Popular > 0 ? true : false,
        Is_Cashback_Available: state.Is_Cashback_Available > 0 ? true : false,
        Whats_Hot: state.Whats_Hot > 0 ? true : false,
        Todays_Hot: state.Todays_Hot > 0 ? true : false,
        Extra_Cash_Back: state.Extra_Cashback > 0 ? true : false,
        Top_Stores: state.Top_Stories > 0 ? true : false,
        Esi_Review: state.Esi_Reviews ? state.Esi_Reviews : "",
        Esi_Rating: parseInt(state.Esi_Ratting),
        Esi_Commission: state.Esi_Comission ? state.Esi_Comission : "",
        Web_Redirection_Url: state.Web_Redirection_Url ? state.Web_Redirection_Url : "",
        Mobile_Redirect_Url: state.Mobile_Redirect_Url ? state.Mobile_Redirect_Url : "",
        Status: state.Status ? state.Status : "",

        Partner_Address_Id: state.Partner_Address_Id ? state.Partner_Address_Id : "",
        Modified_By: loginEmail,
        Mobile_Number: state.Mobile ? state.Mobile : "",
        GST: state.GST ? state.GST : "",
        PAN: state.PAN ? state.PAN : "",
        User_Tips: state.User_Tips ? state.User_Tips : "",
        Extra_Cashback: state.Extra_Cashback > 0 ? true : false,
        Esi_Ratting: parseInt(state.Esi_Ratting),
        Esi_Comission: state.Esi_Comission ? state.Esi_Comission : "",
        Created_By: loginEmail,
        Billing_Email: state.Billing_Email ? state.Billing_Email : "",
        Authorised_Person_Name: state.Authorised_Person_Name ? state.Authorised_Person_Name : "",
        Company_Name: state.Company_Name ? state.Company_Name : "",
        Business_Type: state.Business_Type ? state.Business_Type : "",
        Business_Sub_Type: state.Business_Sub_Type ? state.Business_Sub_Type : "",
        PAN_Card_Image: panimgUrl,
        GST_Image: gstimgUrl,
        Estimated_Delivery_Time: state.Estimated_Delivery_Time ? state.Estimated_Delivery_Time : "",
        Partner_Priority_Sequence: state.Partner_Priority_Sequence ? state.Partner_Priority_Sequence : "",
        Is_Email_Verified: state.Is_Email_Verified > 0 ? true : false,
        Is_Open: state.Is_Open > 0 ? true : false,
        IS_Blocked_By_Admin: state.IS_Blocked_By_Admin > 0 ? true : false,
      }}

      validationSchema={
        Yup.object().shape({
          // Name: Yup.string().min(3).notOneOf([errorMessage.Name.key, null], errorMessage.Name.message).required('Partner Name is required.'),
          Name: Yup.string().min(2, "Partner Name must be at least 2 characters.").required('Partner Name is required.'),
          Website_Url: Yup.string().when('Type', { is: "Online", then: Yup.string().required('Website URL is required.') })
            .matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
              'Please provide valid URL.'),
          Type: Yup.string().required('Partner Type is required.'),
          Mobile: Yup.string().when('Type', {
            is: "Online",
            then: Yup.string()
              .min(14, "Please provide a valid Mobile Number.").required('Mobile Number is required.'),
          }),
          Mobile_Number: Yup.string().when('Type', {
            is: "Physical",
            then: Yup.string()
              .min(14, "Please provide a valid Mobile Number.").required('Mobile Number is required.'),
          }),
          Land_Phone: Yup.string().min(6, "Landline number must contain at least 6 digits.").max(12, "Landline number should not be more than 12 digits."),
          Gst: Yup.string().when('Type', {
            is: "Online",
            then: Yup.string().length(15, 'GSTIN must be of 15 characters.')
              .matches("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z{1}$]",
                'Please provide valid GSTIN without any spaces and special characters.'
              )
          }),
          Pan: Yup.string().when('Type', {
            is: "Online",
            then: Yup.string()
              .length(10, 'PAN Number must be of 10 characters.')
              .matches("[A-Z]{5}[0-9]{4}[A-Z]{1}",
                'Please provide valid PAN Number without any spaces and special characters.'
              )
          }),
          GST: Yup.string().when('Type', {
            is: "Physical",
            then: Yup.string().length(15, 'GSTIN must be of 15 characters.')
              .matches("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z{1}$]",
                'Please provide valid GSTIN without any spaces and special characters.'
              )
          }),
          PAN: Yup.string().when('Type', {
            is: "Physical",
            then: Yup.string()
              .length(10, 'PAN Number must be of 10 characters.')
              .matches("[A-Z]{5}[0-9]{4}[A-Z]{1}",
                'Please provide valid PAN Number without any spaces and special characters.'
              )
          }),
          Tips: Yup.string().when('Type', {
            is: "Online",
            then: Yup.string().required('User Tips are required.'),
          }),
          Esi_Commission: Yup.number().when('Type', {
            is: "Online",
            then: Yup.number().min(0, "ESI Commission should not be less than 0.").required('ESI Commission is required.'),
          }),
          User_Tips: Yup.string().when('Type', {
            is: "Physical",
            then: Yup.string().required('User Tips are required.'),
          }),
          Esi_Comission: Yup.number().when('Type', {
            is: "Physical",
            then: Yup.number().min(0, "ESI Commission should not be less than 0.").required('ESI Commission is required.'),
          }),
          Short_Description: Yup.string().required('Short Description is required.'),
          Status: Yup.string().required('Status is required.'),
          Mobile_Redirect_Url: Yup.string().when('Type', {
            is: "Online",
            then: Yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
              'Please provide valid URL.'
            ).required('Mobile Redirection URL is required.'),
          }),
          Web_Redirection_Url: Yup.string().when('Type', {
            is: "Online",
            then: Yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
              'Please provide valid URL.'
            ).required('Web Redirection URL is required.'),
          }),
          Authorised_Person_Name: Yup.string().when('Type', {
            is: "Physical",
            then: Yup.string().required('Authorised Person Name are required.'),
          }),
          //  Email_Id: Yup.string().email("Email ID must be valid.").required('Email ID is required.'),
          // Billing_Email: Yup.string().when('Type', {
          //   is: "Physical", then: Yup.string().email("Email ID must be valid.").required('Billing Email is required.'),
          // }),
          Company_Name: Yup.string().when('Type', {
            is: "Physical", then: Yup.string().required('Company Name is required.'),
          }),
          Estimated_Delivery_Time: Yup.string().when('Type', {
            is: "Physical", then: Yup.string().required('Estimated Delivery Time is required.'),
          }),
          Partner_Priority_Sequence: Yup.number().when('Type', {
            is: "Physical", then: Yup.number().min(1, "Partner Priority Sequence must be at least 1.").required('Partner Priority Sequence is required.'),
          }),
          Business_Type: Yup.string().when('Type', {
            is: "Physical", then: Yup.string().required('Business Type is required.'),
          }),
          Business_Sub_Type: Yup.string().when('Type', {
            is: "Physical", then: Yup.string().required('Business Sub Type is required.'),
          }),
        })}

      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        setGstError("");
        setPanError("");
        const errors = {};
        values.Description = rhDiscription;
        values.Esi_Review = rhDiscriptionReviews;
        values.Logo_Path = imgUrl;
        values.Banner_Path = imgUrl1;
        values.PAN_Card_Image = panimgUrl;
        values.GST_Image = gstimgUrl;

        if (!values.Logo_Path) {
          handleRemoveImage()
          errors.Logo_Path = "Logo Path or Logo Image is required.";
          submitTest = false;
        }

        if (values.Type === "Physical" && values.GST && !gstimgUrl) {
          setGstError("GST Image is required.");
          submitTest = false;
        }

        if (values.Type === "Physical" && values.PAN && !panimgUrl) {
          setPanError("PAN Card Image is required.");
          submitTest = false;
        }

        if (!values.Description || values.Description?.replace(/(<([^>]+)>)/ig, '')?.trim().length === 0) {
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (values.Description?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
        if (!values.Esi_Review || values.Esi_Review.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Esi_Review = "ESI Reviews is required.";
        }
        else if (values.Esi_Review.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionReviewsMin) {
          submitTest = false;
          errors.Esi_Review = "Please provide at least " + rhDiscriptionReviewsMin + " characters.";
        }
        setErrors(errors);
        if (submitTest)
          if (1 > 0) {
            let formValues = JSON.parse(JSON.stringify(values));
            if (values.Is_Popular) {
              formValues.Is_Popular = 1;
            } else {
              formValues.Is_Popular = 0;
            }
            if (values.Whats_Hot) {
              formValues.Whats_Hot = 1;
            } else {
              formValues.Whats_Hot = 0;
            }
            if (values.Todays_Hot) {
              formValues.Todays_Hot = 1;
            } else {
              formValues.Todays_Hot = 0;
            }
            if (values.Top_Stores) {
              formValues.Top_Stores = 1;
            } else {
              formValues.Top_Stores = 0;
            }
            if (values.Extra_Cash_Back) {
              formValues.Extra_Cash_Back = 1;
            } else {
              formValues.Extra_Cash_Back = 0;
            }
            if (values.Is_Cashback_Available) {
              formValues.Is_Cashback_Available = 1;
            } else {
              formValues.Is_Cashback_Available = 0;
            }
            if (values.Extra_Cashback) {
              formValues.Extra_Cashback = 1;
            } else {
              formValues.Extra_Cashback = 0;
            }
            if (values.Is_Email_Verified) {
              formValues.Is_Email_Verified = 1;
            } else {
              formValues.Is_Email_Verified = 0;
            }
            if (values.Is_Open) {
              formValues.Is_Open = 1;
            } else {
              formValues.Is_Open = 0;
            }
            if (values.IS_Blocked_By_Admin) {
              formValues.IS_Blocked_By_Admin = 1;
            } else {
              formValues.IS_Blocked_By_Admin = 0;
            }

            if (values.Type === 'Online') {
              const formData = {
                Partner_Details_Id: values.Partner_Details_Id,
                Email_Id: values.Email_Id,
                Name: values.Name,
                Logo_Path: values.Logo_Path,
                Website_Url: values.Website_Url,
                Type: values.Type,
                Mobile: values.Mobile,
                Land_Phone: values.Land_Phone,
                Gst: values.Gst,
                Pan: values.Pan,
                Short_Description: values.Short_Description,
                Description: values.Description,
                Tips: values.Tips,
                Banner_Path: values.Banner_Path,
                Is_Popular: formValues.Is_Popular,
                Is_Cashback_Available: formValues.Is_Cashback_Available,
                Whats_Hot: formValues.Whats_Hot,
                Todays_Hot: formValues.Todays_Hot,
                Extra_Cash_Back: formValues.Extra_Cash_Back,
                Top_Stores: formValues.Top_Stores,
                Esi_Review: values.Esi_Review,
                Esi_Rating: values.Esi_Rating,
                Esi_Commission: values.Esi_Commission,
                Web_Redirection_Url: values.Web_Redirection_Url,
                Mobile_Redirect_Url: values.Mobile_Redirect_Url,
                Status: values.Status,
              };
              let formvalues = JSON.parse(JSON.stringify(formData));
              dispatch(updateOnlinePartner(formvalues, navigate));
            } else {
              const formData = {
                Partner_Details_Id: values.Partner_Details_Id,
                Name: values.Name,
                Website_Url: values.Website_Url,
                Mobile_Number: values.Mobile_Number,
                Land_Phone: values.Land_Phone,
                Email_Id: values.Email_Id,
                GST: values.GST,
                PAN: values.PAN,
                Short_Description: values.Short_Description,
                Description: values.Description,
                User_Tips: values.User_Tips,
                Is_Popular: formValues.Is_Popular,
                Is_Cashback_Available: formValues.Is_Cashback_Available,
                Whats_Hot: formValues.Whats_Hot,
                Todays_Hot: formValues.Todays_Hot,
                Extra_Cashback: formValues.Extra_Cashback,
                Top_Stores: formValues.Top_Stores,
                Esi_Review: values.Esi_Review,
                Esi_Ratting: values.Esi_Ratting,
                Esi_Comission: values.Esi_Comission,
                Banner_Path: values.Banner_Path,
                Logo_Path: values.Logo_Path,
                Modified_By: loginEmail,
                Status: values.Status,
                Authorised_Person_Name: values.Authorised_Person_Name,
                Billing_Email: values.Billing_Email,
                Company_Name: values.Company_Name,
                Business_Type: values.Business_Type,
                Business_Sub_Type: values.Business_Sub_Type,
                Estimated_Delivery_Time: values.Estimated_Delivery_Time,
                Is_Email_Verified: formValues.Is_Email_Verified,
                Is_Open: formValues.Is_Open,
                IS_Blocked_By_Admin: formValues.IS_Blocked_By_Admin,
                Partner_Priority_Sequence: values.Partner_Priority_Sequence,
                PAN_Card_Image: values.PAN_Card_Image,
                GST_Image: values.GST_Image,
              };
              let formvalues = JSON.parse(JSON.stringify(formData));
              dispatch(updatePhysicalPartner(formvalues, navigate));
            }
          }

      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (<form onSubmit={handleSubmit}
      >
        <ConnectedFocusError />
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={8} xs={12}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    {/* partner Type */}
                    <TextField
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Partner Type"
                      name="Type"
                      value={values.Type}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  {values.Type === 'Physical' ? (
                    <>
                      <Grid item md={6} xs={12}>
                        {/* partner name */}
                        <TextField
                          error={Boolean(touched.Name && errors.Name)}
                          fullWidth
                          helperText={touched.Name && errors.Name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Partner Name"
                          name="Name"
                          value={values.Name}
                          variant="outlined"
                        />
                      </Grid>
                      {/* author name */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Authorised_Person_Name && errors.Authorised_Person_Name)}
                          fullWidth
                          helperText={touched.Authorised_Person_Name && errors.Authorised_Person_Name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Authorised Person Name"
                          name="Authorised_Person_Name"
                          value={values.Authorised_Person_Name}
                          variant="outlined"
                        />
                      </Grid>
                      {/* {Billing Address} */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Billing_Email && errors.Billing_Email)}
                          fullWidth
                          helperText={touched.Billing_Email && errors.Billing_Email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Billing Email"
                          name="Billing_Email"
                          value={values.Billing_Email}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      {/* {Company_Name} */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Company Legal Name"
                          name="Company_Name"
                          value={values.Company_Name}
                          variant="outlined"
                        />
                      </Grid>

                      {/*Email_Id*/}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Email_Id && errors.Email_Id)}
                          fullWidth
                          helperText={touched.Email_Id && errors.Email_Id}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Email ID"
                          name="Email_Id"
                          value={values.Email_Id}
                          variant="outlined"
                          disabled
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <PhoneInput
                          containerClass={touched.Mobile_Number && errors.Mobile_Number ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                          inputClass={"form-control MuiOutlinedInput-input"}
                          error={true}
                          country={"in"}
                          fullWidth
                          inputStyle={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "5px"
                          }}
                          inputProps={{
                            name: "Mobile_Number",
                          }}
                          specialLabel="Mobile Number"
                          name="Mobile_Number"
                          placeholder="+91 XXXXX XXXXX"
                          onBlur={handleBlur}
                          onChange={(value, country, e, formattedValue) => {
                            setFieldValue("Mobile_Number", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                            setMobile(formattedValue)
                          }}
                          value={mobile}
                        />
                        <p style={{ color: "red" }} className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Mobile_Number && errors.Mobile_Number}​​​​​​​​</p>
                      </Grid>

                      {/* Land_Phone */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Land_Phone && errors.Land_Phone)}
                          fullWidth
                          helperText={touched.Land_Phone && errors.Land_Phone}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="number"
                          label="Landline"
                          name="Land_Phone"
                          value={values.Land_Phone}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      {/* Gst% */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.GST && errors.GST)}
                          fullWidth
                          helperText={touched.GST && errors.GST}
                          label="GSTIN"
                          name="GST"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.GST}
                          variant="outlined"
                          inputProps={{ maxLength: 15 }}
                        />
                      </Grid>

                      {/* Pan */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.PAN && errors.PAN)}
                          fullWidth
                          helperText={touched.PAN && errors.PAN}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="PAN Number"
                          name="PAN"
                          value={values.PAN}
                          variant="outlined"
                          inputProps={{ maxLength: 10 }}
                        />
                      </Grid>

                      {/* Logo Path */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Logo_Path && errors.Logo_Path)}
                          fullWidth
                          helperText={touched.Logo_Path && errors.Logo_Path}
                          onBlur={handleBlur}
                          type="url"
                          onChange={(data) => {
                            setImgUrl(data.target.value);
                          }}
                          label="Logo Path"
                          name="Logo_Path"
                          value={imgUrl}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      {/*Banner Path */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          onBlur={handleBlur}
                          type="url"
                          onChange={(data) => {
                            setImgUrl1(data.target.value);
                          }}
                          label="Banner Path"
                          name="Banner_Path"
                          value={imgUrl1}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Business_Type && errors.Business_Type)}
                          fullWidth
                          helperText={touched.Business_Type && errors.Business_Type}
                          onBlur={handleBlur}
                          onChange={(e) => { handleChange(e); setType(e.target.value) }}
                          select
                          SelectProps={{ native: true }}
                          label="Business Type"
                          name="Business_Type"
                          value={values.Business_Type}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        >
                          <option key="" value="">--Please Select--</option>
                          {typedata?.map(option => (
                            <option key={option.BUSINESS_TYPE} value={option.BUSINESS_TYPE}>
                              {option.BUSINESS_TYPE}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Business_Sub_Type && errors.Business_Sub_Type)}
                          fullWidth
                          helperText={touched.Business_Sub_Type && errors.Business_Sub_Type}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          select
                          SelectProps={{ native: true }}
                          label="Business Sub Type"
                          name="Business_Sub_Type"
                          value={values.Business_Sub_Type}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        >
                          <option key="" value="">--Please Select--</option>
                          {subtypedata?.map(option => (
                            <option key={option.BUSINESS_SUB_TYPE} value={option.BUSINESS_SUB_TYPE}>
                              {option.BUSINESS_SUB_TYPE}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      {/* Estimated_Delivery_Time */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Estimated_Delivery_Time && errors.Estimated_Delivery_Time)}
                          fullWidth
                          helperText={touched.Estimated_Delivery_Time && errors.Estimated_Delivery_Time}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Estimated Delivery Time"
                          name="Estimated_Delivery_Time"
                          value={values.Estimated_Delivery_Time}
                          variant="outlined"
                        />
                      </Grid>

                      {/* Partner_Priority_Sequence */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Partner_Priority_Sequence && errors.Partner_Priority_Sequence)}
                          fullWidth
                          helperText={touched.Partner_Priority_Sequence && errors.Partner_Priority_Sequence}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Partner Priority Sequence"
                          name="Partner_Priority_Sequence"
                          value={values.Partner_Priority_Sequence}
                          variant="outlined"
                          type="number"
                        />
                      </Grid>

                      {/*Esi commission*/}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Esi_Comission && errors.Esi_Comission)}
                          fullWidth
                          helperText={touched.Esi_Comission && errors.Esi_Comission}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="ESI Commission"
                          name="Esi_Comission"
                          value={values.Esi_Comission}
                          variant="outlined"
                        />
                      </Grid>

                      {/* Short Description */}
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={Boolean(touched.Short_Description && errors.Short_Description)}
                          fullWidth
                          helperText={touched.Short_Description && errors.Short_Description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Short Description"
                          name="Short_Description"
                          value={values.Short_Description}
                          variant="outlined"
                        />
                      </Grid>

                      {/* Description  */}
                      <Grid item md={12} xs={12}>
                        <Box className={classes.description}>
                          Description
                          <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                        </Box>
                        <span style={{ color: "red" }}>
                          {(!rhDiscription || rhDiscription?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                        </span>
                      </Grid>

                      {/*Esi rating*/}
                      <Grid item md={12} xs={12}>
                        <Box>
                          ESI Rating</Box></Grid>
                      <Grid item md={12} xs={12}>
                        <Rating
                          name="Esi_Ratting"
                          onChange={(event, newValue) => {
                            values.Esi_Ratting = newValue;
                            setFieldValue(newValue);
                          }}
                          value={values.Esi_Ratting}
                          size="small"
                        />
                      </Grid>

                      {/* ESI Reviews  */}
                      <Grid item md={12} xs={12}>
                        <Box className={classes.description}>
                          ESI Reviews
                          <EditorContainer editorState={editorStateReviews} onEditorStateChange={onEditorStateChangeReviews} />
                        </Box>
                        <span style={{ color: "red" }}>
                          {(!rhDiscriptionReviews || rhDiscriptionReviews?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionReviewsMin) && touched.Esi_Review && errors.Esi_Review}
                        </span>
                      </Grid>

                      {/* Is Popular */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Popular}
                              onChange={handleChange}
                              name="Is_Popular"
                              color="primary"
                            />
                          }
                          label="Is Popular"
                        />
                      </Grid>

                      {/* What Hot */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Whats_Hot}
                              onChange={handleChange}
                              name="Whats_Hot"
                              color="primary"
                            />
                          }
                          label="Whats Hot"
                        />
                      </Grid>

                      {/* Todays Hot */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Todays_Hot}
                              onChange={handleChange}
                              name="Todays_Hot"
                              color="primary"
                            />
                          }
                          label="Todays Hot"
                        />
                      </Grid>

                      {/* Extra Cash Back */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Extra_Cashback}
                              onChange={handleChange}
                              name="Extra_Cashback"
                              color="primary"
                            />
                          }
                          label="Extra Cashback"
                        />
                      </Grid>

                      {/* Top_Stores */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Top_Stores}
                              onChange={handleChange}
                              name="Top_Stores"
                              color="primary"
                            />
                          }
                          label="Top Store"
                        />
                      </Grid>

                      {/* Is_Cashback_Available */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Cashback_Available}
                              onChange={handleChange}
                              name="Is_Cashback_Available"
                              color="primary"
                            />
                          }
                          label="Is Cashback Availble"
                        />
                      </Grid>

                      {/* Is_Email_Verified */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Email_Verified}
                              onChange={handleChange}
                              name="Is_Email_Verified"
                              color="primary"
                            />
                          }
                          label="Is Email Verified"
                        />
                      </Grid>

                      {/* Is_Open */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Open}
                              onChange={handleChange}
                              name="Is_Open"
                              color="primary"
                            />
                          }
                          label="Is Open"
                        />
                      </Grid>

                      {/* IS_Blocked_By_Admin */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.IS_Blocked_By_Admin}
                              onChange={handleChange}
                              name="IS_Blocked_By_Admin"
                              color="primary"
                            />
                          }
                          label="Is Blocked By Admin"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}></Grid>

                      {/* Tips */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.User_Tips && errors.User_Tips)}
                          fullWidth
                          helperText={touched.User_Tips && errors.User_Tips}
                          onBlur={handleBlur}
                          //type="url"
                          onChange={handleChange}
                          label="User Tips"
                          name="User_Tips"
                          value={values.User_Tips}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Status && errors.Status)}
                          fullWidth
                          helperText={touched.Status && errors.Status}
                          placeholder="Status"
                          label="Status"
                          name="Status"
                          onChange={handleChange}
                          select
                          SelectProps={{ native: true }}
                          value={values.Status}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }} >
                          {statusOption.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      {/* Website Url */}
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={Boolean(touched.Website_Url && errors.Website_Url)}
                          fullWidth
                          helperText={touched.Website_Url && errors.Website_Url}
                          onBlur={handleBlur}
                          type="url"
                          onChange={handleChange}
                          label="Website URL"
                          name="Website_Url"
                          value={values.Website_Url}
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item md={6} xs={12}>
                        {/* partner name */}
                        <TextField
                          error={Boolean(touched.Name && errors.Name)}
                          fullWidth
                          helperText={touched.Name && errors.Name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Partner Name"
                          name="Name"
                          value={values.Name}
                          variant="outlined"
                        // disabled
                        />
                      </Grid>

                      {/* Mobile */}
                      <Grid item md={6} xs={12}>
                        <PhoneInput
                          containerClass={touched.Mobile && errors.Mobile ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                          inputClass={"form-control MuiOutlinedInput-input"}
                          error={true}
                          country={"in"}
                          fullWidth
                          inputStyle={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "5px"
                          }}
                          inputProps={{
                            name: "Mobile",
                          }}
                          specialLabel="Mobile Number"
                          name="Mobile"
                          placeholder="+91 XXXXX XXXXX"
                          onBlur={handleBlur}
                          onChange={(value, country, e, formattedValue) => {
                            setFieldValue("Mobile", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                            setMobile(formattedValue)
                          }}
                          value={mobile}
                        />
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Mobile && errors.Mobile}​​​​​​​​</p>
                      </Grid>

                      {/* Land_Phone */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Land_Phone && errors.Land_Phone)}
                          fullWidth
                          helperText={touched.Land_Phone && errors.Land_Phone}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="number"
                          label="Landline"
                          name="Land_Phone"
                          value={values.Land_Phone}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      {/* Gst% */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Gst && errors.Gst)}
                          fullWidth
                          helperText={touched.Gst && errors.Gst}
                          label="GSTIN"
                          name="Gst"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.Gst}
                          variant="outlined"
                          inputProps={{ maxLength: 15 }}
                        />
                      </Grid>

                      {/* Pan */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Pan && errors.Pan)}
                          fullWidth
                          helperText={touched.Pan && errors.Pan}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="PAN Number"
                          name="Pan"
                          value={values.Pan}
                          variant="outlined"
                          inputProps={{ maxLength: 10 }}
                        />
                      </Grid>

                      {/* Logo Path */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Logo_Path && errors.Logo_Path)}
                          fullWidth
                          helperText={touched.Logo_Path && errors.Logo_Path}
                          onBlur={handleBlur}
                          type="url"
                          onChange={(data) => {
                            setImgUrl(data.target.value);
                          }}
                          label="Logo Path"
                          name="Logo_Path"
                          value={imgUrl}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      {/*Banner Path */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          onBlur={handleBlur}
                          type="url"
                          onChange={(data) => {
                            setImgUrl1(data.target.value);
                          }}
                          label="Banner Path"
                          name="Banner_Path"
                          value={imgUrl1}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      {/* Redirection_Url */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Web_Redirection_Url && errors.Web_Redirection_Url)}
                          fullWidth
                          helperText={touched.Web_Redirection_Url && errors.Web_Redirection_Url}
                          onBlur={handleBlur}
                          type="url"
                          onChange={handleChange}
                          label="Web Redirection URL"
                          name="Web_Redirection_Url"
                          value={values.Web_Redirection_Url}
                          variant="outlined"
                        />
                      </Grid>

                      {/* Mobile_Redirect_Url */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Mobile_Redirect_Url && errors.Mobile_Redirect_Url)}
                          fullWidth
                          helperText={touched.Mobile_Redirect_Url && errors.Mobile_Redirect_Url}
                          onBlur={handleBlur}
                          type="url"
                          onChange={handleChange}
                          label="Mobile Redirection URL"
                          name="Mobile_Redirect_Url"
                          value={values.Mobile_Redirect_Url}
                          variant="outlined"
                        />
                      </Grid>

                      {/*Email_Id*/}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Email_Id && errors.Email_Id)}
                          fullWidth
                          helperText={touched.Email_Id && errors.Email_Id}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Email ID"
                          name="Email_Id"
                          value={values.Email_Id}
                          variant="outlined"
                        />
                      </Grid>

                      {/*Esi commission*/}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Esi_Commission && errors.Esi_Commission)}
                          fullWidth
                          helperText={touched.Esi_Commission && errors.Esi_Commission}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="ESI Commission"
                          name="Esi_Commission"
                          value={values.Esi_Commission}
                          variant="outlined"
                        />
                      </Grid>

                      {/* Short Description */}
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={Boolean(touched.Short_Description && errors.Short_Description)}
                          fullWidth
                          helperText={touched.Short_Description && errors.Short_Description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Short Description"
                          name="Short_Description"
                          value={values.Short_Description}
                          variant="outlined"
                        />
                      </Grid>

                      {/* Description  */}
                      <Grid item md={12} xs={12}>
                        <Box className={classes.description}>
                          Description
                          <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                        </Box>
                        <span style={{ color: "red" }}>
                          {(!rhDiscription || rhDiscription?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                        </span>
                      </Grid>

                      {/*Esi rating*/}
                      <Grid item md={2} xs={12}>
                        <Box>
                          ESI Rating</Box></Grid>
                      <Grid item md={2} xs={12}>
                        <Rating
                          name="Esi_Rating"
                          onChange={(event, newValue) => {
                            values.Esi_Rating = newValue;
                            setFieldValue(newValue);
                          }}
                          value={values.Esi_Rating}
                          size="small"
                        />
                      </Grid>

                      {/* ESI Reviews  */}
                      <Grid item md={12} xs={12}>
                        <Box className={classes.description}>
                          ESI Reviews
                          <EditorContainer editorState={editorStateReviews} onEditorStateChange={onEditorStateChangeReviews} />
                        </Box>
                        <span style={{ color: "red" }}>
                          {(!rhDiscriptionReviews || rhDiscriptionReviews?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionReviewsMin) && touched.Esi_Review && errors.Esi_Review}
                        </span>
                      </Grid>

                      {/* Is Popular */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Popular}
                              onChange={handleChange}
                              name="Is_Popular"
                              color="primary"
                            />
                          }
                          label="Is Popular"
                        />
                      </Grid>

                      {/* What Hot */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Whats_Hot}
                              onChange={handleChange}
                              name="Whats_Hot"
                              color="primary"
                            />
                          }
                          label="Whats Hot"
                        />
                      </Grid>

                      {/* Todays Hot */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Todays_Hot}
                              onChange={handleChange}
                              name="Todays_Hot"
                              color="primary"
                            />
                          }
                          label="Todays Hot"
                        />
                      </Grid>

                      {/* Extra Cash Back */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Extra_Cash_Back}
                              onChange={handleChange}
                              name="Extra_Cash_Back"
                              color="primary"
                            />
                          }
                          label="Extra Cashback"
                        />
                      </Grid>

                      {/* Top_Stores */}
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Top_Stores}
                              onChange={handleChange}
                              name="Top_Stores"
                              color="primary"
                            />
                          }
                          label="Top Store"
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Cashback_Available}
                              onChange={handleChange}
                              name="Is_Cashback_Available"
                              color="primary"
                            />
                          }
                          label="Is Cashback Available"
                        />
                      </Grid>

                      {/* Tips */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Tips && errors.Tips)}
                          fullWidth
                          helperText={touched.Tips && errors.Tips}
                          onBlur={handleBlur}
                          //type="url"
                          onChange={handleChange}
                          label="User Tips"
                          name="Tips"
                          value={values.Tips}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.Status && errors.Status)}
                          fullWidth
                          helperText={touched.Status && errors.Status}
                          placeholder="Status"
                          label="Status"
                          name="Status"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          select
                          SelectProps={{ native: true }}
                          value={values.Status}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        >
                          {statusOption.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      {/* Website Url */}
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={Boolean(touched.Website_Url && errors.Website_Url)}
                          fullWidth
                          helperText={touched.Website_Url && errors.Website_Url}
                          onBlur={handleBlur}
                          type="url"
                          onChange={handleChange}
                          label="Website URL"
                          name="Website_Url"
                          value={values.Website_Url}
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>

              {/* upload photo */}
              <Grid item md={4} xs={12}>
                <Card>
                  &nbsp;Logo Image
                  <CardContent>
                    <Box display="flex" justifyContent="center">
                      {image.preview ? (
                        <Avatar
                          className={classes.images}
                          alt="logo Upload"
                          src={image.preview}
                          variant="square"
                        />
                      ) : (
                        <>
                          <Avatar
                            className={classes.images}
                            alt="logo Upload"
                            src="/static/images/image-placeholder.png"
                            variant="square"
                          />
                        </>
                      )}
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      {image.preview ? <Button
                        style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                        onClick={handleRemoveImage}
                      >
                        Remove Picture
                      </Button> : <input
                        style={{ display: 'block', width: '80%' }}
                        id="upload-photo"
                        name="image"
                        type="file"
                        accept="image/*"
                        onChange={handleImage}
                      />}
                      <br />
                    </Box>

                  </CardContent>
                </Card>
                <br />
                <Card>
                  &nbsp;Banner Image
                  <CardContent>
                    <Box display="flex" justifyContent="center">
                      {image1.preview ? (
                        <Avatar
                          className={classes.images}
                          alt="banner Upload"
                          src={image1.preview}
                          variant="square"
                        />
                      ) : (
                        <>
                          <Avatar
                            className={classes.images}
                            alt="banner Upload"
                            src="/static/images/image-placeholder.png"
                            variant="square"
                          />
                        </>
                      )}
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      {image1.preview ? <Button
                        style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                        onClick={handleRemoveImage1}
                      >
                        Remove Picture
                      </Button> : <input
                        style={{ display: 'block', width: '80%' }}
                        id="upload-photo"
                        name="image"
                        type="file"
                        accept="image/*"
                        onChange={handleImage1}
                      />}
                      <br />
                    </Box>
                  </CardContent>
                </Card>
                <br />

                {values.Type === 'Physical' ? (
                  <>
                    <Card>
                      &nbsp;Pan Card Image
                      <CardContent>
                        <Box display="flex" justifyContent="center">
                          {panimage.preview ? (
                            <Avatar
                              className={classes.images}
                              alt="Pan Upload"
                              src={panimage.preview}
                              variant="square"
                            />
                          ) : (
                            <>
                              <Avatar
                                className={classes.images}
                                alt="Pan Upload"
                                src="/static/images/image-placeholder.png"
                                variant="square"
                              />
                            </>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          {panimage.preview ? <Button
                            style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                            onClick={handleRemovePanImage}
                          >
                            Remove Picture
                          </Button> : <input
                            style={{ display: 'block', width: '80%' }}
                            id="upload-photo"
                            name="image"
                            type="file"
                            accept="image/*"
                            onChange={handlePanImage}
                          />}
                          <br />
                        </Box>
                      </CardContent>
                    </Card>
                    <span style={{ color: "red" }}>
                      {!panimgUrl && panError}<br />
                    </span>
                    <br />
                    <Card>
                      &nbsp;GST Image
                      <CardContent>
                        <Box display="flex" justifyContent="center">
                          {gstimage.preview ? (
                            <Avatar
                              className={classes.images}
                              alt="gst Upload"
                              src={gstimage.preview}
                              variant="square"
                            />
                          ) : (
                            <>
                              <Avatar
                                className={classes.images}
                                alt="gst Upload"
                                src="/static/images/image-placeholder.png"
                                variant="square"
                              />
                            </>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          {gstimage.preview ? <Button
                            style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                            onClick={handleRemoveGstImage}
                          >
                            Remove Picture
                          </Button> : <input
                            style={{ display: 'block', width: '80%' }}
                            id="upload-photo"
                            name="image"
                            type="file"
                            accept="image/*"
                            onChange={handleGstImage}
                          />}
                          <br />
                        </Box>
                      </CardContent>
                    </Card>
                    <span style={{ color: "red" }}>
                      {!gstimgUrl && gstError}
                    </span>
                  </>
                ) : null
                }

              </Grid>
            </Grid>
          </CardContent>
          <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>
          <Box display="flex" p={2}>
            {isSubmited ?
              <CircularProgress />
              :
              <Button
                disabled={isSubmited || uploadPic || uploadPic1}
                type="submit"
                style={{ backgroundColor: '#27B6CC' }}
                variant="contained"
              >
                {/* Save details */}
                <span style={{ color: 'white' }}>Save details</span>
              </Button>
            }
          </Box>
        </Card>
      </form>
      )}
    </Formik>
  );
};

EditPartner.propTypes = {
  className: PropTypes.string
};

export default EditPartner;