import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
  CircularProgress,
  MenuItem
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { ConnectedFocusError } from 'focus-formik-error';
import { paginationcitycategoryServices, paginationsubcategoryServices, paginationvarientServices } from 'src/store/actions/node-actions/nodepagination';

import { AddCityCategorytypeAction } from 'src/store/actions/servicescitycategories'


import ESICountries from 'src/components/common/countries';
import ESICities from 'src/components/common/cities';
import ESIStates from 'src/components/common/states';

const StatusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'ACTIVE',
    label: 'ACTIVE'
  },
  {
    value: 'IN-ACTIVE',
    label: 'IN-ACTIVE'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  Description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddServiceCategoryCity = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const { state } = location;
  // const [contriesStates,setContriesStates]=useState({countries:null,states})

  const [errorMessage, setErrorMessage] = useState({ Sv_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmitted = useSelector(state => state.subcategory.subcategoryCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  // img upload
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);
  const [citycategory, setCitycategory] = useState([])
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };



  useEffect(() => {
    dispatch(paginationvarientServices({}, {}, (status, data) => {
      if (status) {
        console.log(data)
        setCitycategory(data)
      }
      else
        setCitycategory([])
    }));
  }, []);

  return (
    <Formik
      initialValues={{
     

        // Sv_Id: "",
        Sv_Id: state.Sv_Id ? state.Sv_Id:"",
        Country_Id: "",
        State_Id: "",
        City_Id: "",
        Service_Charge_For_Timeslot: "",
        Service_Charge_For_Weekend_Timeslot: "",
        Service_Charge_For_Special_Days_Timeslot: "",
        Status: ""

      }}
      validationSchema={Yup.object().shape({
       

       
        Sv_Id: Yup.string().required('Sv_Name is required.'),
        Status: Yup.string().required('Status is required.'),
        Country_Id: Yup.string().required('Country is required.'),
        State_Id: Yup.string().required('State is required.'),
        City_Id: Yup.string().required('City is required.'),
        Service_Charge_For_Timeslot: Yup.number().required('Service is required.'),
        Service_Charge_For_Weekend_Timeslot: Yup.number().required('Service is required.'),
        Service_Charge_For_Special_Days_Timeslot: Yup.number().required('Service is required.')


      })}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
       
        
        dispatch(AddCityCategorytypeAction(values, navigate, state));
        setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    {/* Service Name */}
                    <Grid item md={12} xs={12}>

                      <TextField
                        error={Boolean(touched.Sv_Id && errors.Sv_Id)}
                        fullWidth
                        helperText={touched.Sv_Id && errors.Sv_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Sv_Name"
                        name="Sv_Id"
                        value={citycategory.find(city => city.Sv_Id === state.Sv_Id)?.Sv_Name || ''}
                        variant="outlined"
                      >
                       
                      </TextField>

                    </Grid>
                   

                    <Grid item md={6} xs={12}>

                      <ESICountries

                        error={Boolean(touched.Country_Id && errors.Country_Id)}
                        fullWidth
                        helperText={touched.Country_Id && errors.Country_Id}
                        label="Country"
                        name="Country_Id"
                        onBlur={handleBlur}
                        onChange={(value) => {
                          console.log(value.currentTarget.value)
                          setFieldValue("Country_Id", value.currentTarget.value)
                        }}
                        value={values.Country_Id}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />

                    </Grid>
                    <Grid item md={6} xs={12}>
                      <ESIStates
                        Country_Id={values.Country_Id}
                        error={Boolean(touched.State_Id && errors.State_Id)}
                        fullWidth
                        helperText={touched.State_Id && errors.State_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        label="State"
                        name="State_Id"
                        value={values.State_Id}
                        variant="outlined"
                        SelectProps={{ native: true }}

                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <ESICities
                        State_Id={values.State_Id}
                        error={Boolean(touched.City_Id && errors.City_Id)}
                        fullWidth
                        helperText={touched.City_Id && errors.City_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        label="City"
                        name="City_Id"
                        value={values.City_Id}
                        variant="outlined"
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Service_Charge_For_Timeslot && errors.Service_Charge_For_Timeslot)}
                        fullWidth
                        helperText={touched.Service_Charge_For_Timeslot && errors.Service_Charge_For_Timeslot}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="ServiceChargeForTimeSlot"
                        name="Service_Charge_For_Timeslot"
                        value={values.Service_Charge_For_Timeslot}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Service_Charge_For_Weekend_Timeslot && errors.Service_Charge_For_Weekend_Timeslot)}
                        fullWidth
                        helperText={touched.Service_Charge_For_Weekend_Timeslot && errors.Service_Charge_For_Weekend_Timeslot}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="ServiceChargeForWeekendTimeSlot"
                        name="Service_Charge_For_Weekend_Timeslot"
                        value={values.Service_Charge_For_Weekend_Timeslot}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Service_Charge_For_Special_Days_Timeslot && errors.Service_Charge_For_Special_Days_Timeslot)}
                        fullWidth
                        helperText={touched.Service_Charge_For_Special_Days_Timeslot && errors.Service_Charge_For_Special_Days_Timeslot}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="ServiceChargeForSpecialDaysTimeSlot"
                        name="Service_Charge_For_Special_Days_Timeslot"
                        value={values.Service_Charge_For_Special_Days_Timeslot}
                        variant="outlined"
                      />
                    </Grid>

                    
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {StatusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" pl={2}>
              <span style={{ color: 'red' }}>{errorMessage.global.message}</span>
            </Box>
            <Box display="flex" p={2}>
              {isSubmitted ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={isSubmitted || uploadPic}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              )}
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddServiceCategoryCity.propTypes = {
  className: PropTypes.string
};

export default AddServiceCategoryCity;
