import {
CORPORATE_NE_CREATE_FAIL,
CORPORATE_NE_CREATE_REQUEST,
CORPORATE_NE_CREATE_SUCCESS,
CORPORATE_NE_UPDATE_FAIL,
CORPORATE_NE_UPDATE_REQUEST,
CORPORATE_NE_UPDATE_SUCCESS,
RESET
} from "../actions/corporatenewsandeventsAction";

const initialState = {
    corporateneCreate: { corporatene: {}, error: '', isLoading: false },
    corporateneUpdate: { corporatene: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CORPORATE_NE_CREATE_REQUEST:
            return { ...state, corporateneCreate: { corporatene: {}, error: '', isLoading: true } };
        case CORPORATE_NE_CREATE_SUCCESS:
            return { ...state, corporateneCreate: { corporatene: state.corporateneCreate.corporatene, error: '', isLoading: false } };
        case CORPORATE_NE_CREATE_FAIL:
            return { ...state, corporateneCreate: { corporatene: {}, error: action.payload, isLoading: false } };
        case CORPORATE_NE_UPDATE_REQUEST:
            return { ...state, corporateneUpdate: { corporatene: {}, error: '', isLoading: true } };
        case CORPORATE_NE_UPDATE_SUCCESS:
            return { ...state, corporateneUpdate: { corporatene: action.payload, error: '', isLoading: false } };
        case CORPORATE_NE_UPDATE_FAIL:
            return { ...state, corporateneUpdate: { corporatene: {}, error: action.payload, isLoading: false } };

        case RESET:
            return { ...state, neCreate: { ne: {}, error: '', isLoading: false } };

        default:
            return state;
    }
}