import {

    B2B_USER_BID_QUOTES_CREATE_REQUEST,
    B2B_USER_BID_QUOTES_CREATE_SUCCESS,
    B2B_USER_BID_QUOTES_CREATE_FAIL,

    B2B_USER_BID_QUOTES_UPDATE_REQUEST,
    B2B_USER_BID_QUOTES_UPDATE_SUCCESS,
    B2B_USER_BID_QUOTES_UPDATE_FAIL,

    B2B_BID_QUOTE_PARTNERS_GET_REQUEST,
    B2B_BID_QUOTE_PARTNERS_GET_SUCCESS,
    B2B_BID_QUOTE_PARTNERS_GET_FAIL,

} from '../actions/b2buserprodbidquotesAction'

const initialState = {
    b2buserprodbidquoteCreate: { b2buserprodbidquote: {}, error: '', isLoading: false },
    b2buserprodbidquoteUpdate: { b2buserprodbidquote: {}, error: '', isLoading: false },
    b2buserprodbidquotepartners: { all: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case B2B_USER_BID_QUOTES_CREATE_REQUEST:
            return { ...state, b2buserprodbidquoteCreate: { b2buserprodbidquote: {}, error: '', isLoading: true } };
        case B2B_USER_BID_QUOTES_CREATE_SUCCESS:
            return { ...state, b2buserprodbidquoteCreate: { b2buserprodbidquote: state.b2buserprodbidquoteCreate.partbidavailprod, error: '', isLoading: false } };
        case B2B_USER_BID_QUOTES_CREATE_FAIL:
            return { ...state, b2buserprodbidquoteCreate: { b2buserprodbidquote: {}, error: action.payload, isLoading: false } };

        case B2B_USER_BID_QUOTES_UPDATE_REQUEST:
            return { ...state, b2buserprodbidquoteUpdate: { b2buserprodbidquote: {}, error: '', isLoading: true } };
        case B2B_USER_BID_QUOTES_UPDATE_SUCCESS:
            return { ...state, b2buserprodbidquoteUpdate: { b2buserprodbidquote: state.b2buserprodbidquoteUpdate.partbidavailprod, error: '', isLoading: false } };
        case B2B_USER_BID_QUOTES_UPDATE_FAIL:
            return { ...state, b2buserprodbidquoteUpdate: { b2buserprodbidquote: {}, error: action.payload, isLoading: false } };

        case B2B_BID_QUOTE_PARTNERS_GET_REQUEST:
            return { ...state, b2buserprodbidquotepartners: { all: state.b2buserprodbidquotepartners.all, error: '', isLoading: true } };
        case B2B_BID_QUOTE_PARTNERS_GET_SUCCESS:
            return { ...state, b2buserprodbidquotepartners: { all: action.payload, error: '', isLoading: false } };
        case B2B_BID_QUOTE_PARTNERS_GET_FAIL:
            return { ...state, b2buserprodbidquotepartners: { all: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}