import {
  Grid,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import CertificatePreview from '../CertificatePreview';
import { addcorporateAwardAction } from 'src/store/actions/corporateawardAction';
import Base64 from "src/hooks/Base64";

const statusOptions = [
  { value: '', label: '--Please Select--' },
  { value: 'Active', label: 'Active' },
  { value: 'In-Active', label: 'In-Active' },
];

const AddCorporateAward = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSubmited = useSelector(state => state.corpaward.corporateawardCreate.isLoading);
  const errorMessage = useSelector(state => state.corpaward.corporateawardCreate.error);
  const user = useSelector(state => state.auth.user);

  const [inputValue, setInputValue] = useState('');
  const [inputAsValue, setInputAsValue] = useState('');
  const [templateLoop, setTemplateLoop] = useState([]);
  const [templateError, setTemplateError] = useState({});
  const [open, setOpen] = useState(false);
  const [htmlString, setHtmlString] = useState('')

  function formatHtmlString(htmlString) {
    const escapedString = htmlString
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;");
    const formattedString = escapedString.replace(/\n/g, '<br/>');
    return formattedString;
  }

  const formattedContent = formatHtmlString(htmlString);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isOnlySpaces = (value) => !value.trim();

  const handleAddField = () => {
    let errors = {};

    if (!inputValue) {
      errors.template_attribute_name = `Template attribute name ${templateLoop.length + 1} is required`;
    } else if (isOnlySpaces(inputValue)) {
      errors.template_attribute_name = `Template attribute name ${templateLoop.length + 1} cannot contain only spaces`;
    }

    if (!inputAsValue) {
      errors.template_attribute_name_as = `Template attribute name as ${templateLoop.length + 1} is required`;
    } else if (isOnlySpaces(inputAsValue)) {
      errors.template_attribute_name_as = `Template attribute name as ${templateLoop.length + 1} cannot contain only spaces`;
    }

    if (Object.keys(errors).length > 0) {
      setTemplateError(errors);
      return;
    }

    setTemplateLoop([
      ...templateLoop,
      { template_attribute_name: inputValue, template_attribute_name_as: inputAsValue },
    ]);

    setInputValue('');
    setInputAsValue('');
    setTemplateError({});
  };

  const handleRemoveField = (index) => {
    const newTemplateLoop = [...templateLoop];
    newTemplateLoop.splice(index, 1);
    setTemplateLoop(newTemplateLoop);
  };

  const handleFieldChange = (index, field, value) => {
    const updatedLoop = [...templateLoop];
    updatedLoop[index][field] = value;
    setTemplateLoop(updatedLoop);
  };

  const values = {
    Embeded_Template_Code: '',
    Created_By: user?.Email_Id,
    Template_Name: '',
    Template_Loop: [],
    Status: '',
  };

  const Form_Validation = Yup.object().shape({
    Template_Name: Yup.string()
      .matches(/^[a-zA-Z][\sa-zA-Z]*/, 'Template Name should not start with Spaces and Special Characters.')
      .required('Template Name is required.'),
    Status: Yup.string().required('Status is required.'),
    Embeded_Template_Code: Yup.string().matches(/^(?!\s*$).+/, 'Embedded Template Code cannot be only spaces.').required('Embedded Template Code is required.'),
  });

  const onSubmit = async (values, { setSubmitting, setErrors, validateForm }) => {
    setSubmitting(true);

    const validationErrors = await validateForm();

    if (Object.keys(validationErrors).length > 0 || templateLoop.length === 0) {
      setTemplateError({
        template_attribute_name: 'Please add at least one template attribute name',
        template_attribute_name_as: 'Please add at least one template attribute name as',
      });
      setErrors(validationErrors);
      setSubmitting(false);
      return;
    }

    let formValues = { ...values };
    formValues.Embeded_Template_Code = Base64.btoa(values.Embeded_Template_Code);
    formValues.Template_Loop = templateLoop;
    dispatch(addcorporateAwardAction(formValues, navigate));
    setSubmitting(false);
  };

  return (
    <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
      {({ errors, handleBlur, handleChange, touched, values }) => (
        <Form>
          <ConnectedFocusError />
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.Template_Name && errors.Template_Name)}
                    fullWidth
                    helperText={touched.Template_Name && errors.Template_Name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Template Name *"
                    name="Template_Name"
                    value={values.Template_Name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.Status && errors.Status)}
                    fullWidth
                    placeholder="Status"
                    name="Status"
                    onChange={handleChange}
                    label="Status"
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: true }}
                    value={values.Status}
                    variant="outlined"
                    helperText={<ErrorMessage name="Status" />}
                  >
                    {statusOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.Embeded_Template_Code && errors.Embeded_Template_Code)}
                    fullWidth
                    helperText={touched.Embeded_Template_Code && errors.Embeded_Template_Code}
                    onBlur={handleBlur}
                    onChange={(e) => { handleChange(e); setHtmlString(e.target.value) }}
                    multiline
                    minRows={20}
                    maxRows={30}
                    label="Embedded Template Code *"
                    name="Embeded_Template_Code"
                    value={values.Embeded_Template_Code}
                    variant="outlined"
                  />
                </Grid>

                {/* Dynamically Render Template Fields */}
                {templateLoop.map((field, index) => (
                  <React.Fragment key={index}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label={`Template Attribute Name * ${index + 1}`}
                        value={field.template_attribute_name}
                        onChange={(e) => handleFieldChange(index, 'template_attribute_name', e.target.value)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box display="flex" alignItems="center">
                        <TextField
                          fullWidth
                          label={`Template Attribute Name As * ${index + 1}`}
                          value={field.template_attribute_name_as}
                          onChange={(e) => handleFieldChange(index, 'template_attribute_name_as', e.target.value)}
                          variant="outlined"
                        />
                        <Button
                          type="button"
                          onClick={() => handleRemoveField(index)}
                          variant="contained"
                          color="secondary"
                          style={{
                            height: '55px',
                            borderRadius: '0px var(--borderRadius, 4px) var(--borderRadius, 4px) 0px',
                            background: 'var(--primary-main, #2196F3)',
                          }}
                        >
                          Remove
                        </Button>
                      </Box>
                    </Grid>
                  </React.Fragment>
                ))}
                {/* Initial Input Fields */}
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={`Template Attribute Name * ${templateLoop.length + 1}`}
                    value={inputValue}
                    helperText={templateError?.template_attribute_name}
                    error={Boolean(templateError.template_attribute_name && templateError.template_attribute_name)}
                    onChange={(e) => setInputValue(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box display="flex" alignItems="center">
                    <TextField
                      fullWidth
                      label={`Template Attribute Name As * ${templateLoop.length + 1}`}
                      value={inputAsValue}
                      helperText={templateError?.template_attribute_name_as}
                      error={Boolean(templateError.template_attribute_name_as && templateError.template_attribute_name_as)}
                      onChange={(e) => setInputAsValue(e.target.value)}
                      variant="outlined"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddField}
                      style={{
                        marginBottom: templateError.template_attribute_name_as ? '25px' : '0px',
                        height: '55px',
                        flex: 'none',
                        width: '80px',
                        borderRadius: '0px var(--borderRadius, 4px) var(--borderRadius, 4px) 0px',
                        background: 'var(--primary-main, #2196F3)',
                      }}
                    >+ ADD</Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
          <Box sx={{ justifyContent: 'flex-end', display: 'flex', paddingTop: '16px', gap: '8px' }}>
            <Button onClick={handleClickOpen} variant="outlined" style={{ border: '1px solid #27b6cc' }}>
              <span style={{ color: '#27B6CC' }}>Preview</span>
            </Button>
            <Button variant="outlined" style={{ border: '1px solid #27b6cc' }} component={RouterLink} to='/app/corporate-award-management'>
              <span style={{ color: '#27B6CC' }}>Cancel</span>
            </Button>
            <Button disabled={isSubmited} type="submit" variant="contained" style={{ backgroundColor: '#27b6cc' }}>
              {isSubmited ? <CircularProgress size={24} /> : <span style={{ color: 'white' }}>Save</span>}
            </Button>
          </Box>
          <CertificatePreview htmlContent={formattedContent} open={open} onClose={handleClose} />
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </Form>
      )}
    </Formik>
  );
};

export default AddCorporateAward;
