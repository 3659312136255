import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/delivery/dashboard',
    createdAt: '',
    description: 'A retail dashboard is a performance tool to visualize and report all important retail KPIs in one central interface and turn this collected data into actionable insights.',
    media: '/static/Dashboard/dashboard.jpg',
    title: 'Dashboard',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/delivery/reports',
    createdAt: '',
    description: 'Report aggregates data from each individual that is used during a specific date range.',
    media: '/static/Dashboard/dashboard.jpg',
    title: 'Reports',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/deliveryfaq-management',
    createdAt: '',
    description: 'It stands for frequently-asked questions, and it is a page on a website that gives quick answers to customer questions. The idea is to keep the answers short and direct so that people find info quickly.',
    media: '/static/Dashboard/faq.jpg',
    title: 'Delivery FAQs',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/delivery-content-management',
    createdAt: '',
    description: 'A content management system (CMS) is an application that is used to manage web content, allowing multiple contributors to create, edit and publish. Content in a CMS is typically stored in a database and displayed in a presentation layer based on a set of templates. ',
    media: '/static/Dashboard/Cms.jpg',
    title: 'Delivery Content Management',
    total: ''
  },

  {
    id: uuid(),
    hide: 0,
    link: '/app/approved-delivery-agent-view',
    createdAt: '',
    description:
      ' Deliver a wide variety of items to different addresses and through different routes Follow routes and time schedule Load, unload, prepare, inspect and operate a delivery vehicle',
    media: '/static/Dashboard/approve.png',
    title: 'Approved Delivery Agents',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/pending-delivery-agents-view',
    createdAt: '',
    description: ' Deliver a wide variety of items to different addresses and through different routes Follow routes and time schedule Load, unload, prepare, inspect and operate a delivery vehicle',
    media: '/static/Dashboard/delivery.png',
    title: 'Pending Delivery Agents',
    total: ''
  },

  {
    id: uuid(),
    hide: 0,
    link: '/app/Delivery-payment-settlements',
    createdAt: '',
    description: 'Delivery payment settlements provides the information about the order amount, cashback amount and the payment status related to user payments.',
    media: '/static/Dashboard/partner_payment.jpg',
    title: 'Delivery Payment Settlements',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/delivery-tickets',
    createdAt: '',
    description: ' A Ticket management system can be defined as a robust software used to register, organize, prioritize, and resolve support tickets. These tickets can be issues or requests requested by your employees or your customers. ',
    media: '/static/Dashboard/ticketing_management.jpg',
    title: 'Delivery Tickets',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/delivery-wallet-management',
    createdAt: '',
    description: 'Delivery Wallet Management system can be defined as a robust software used to organize delivery person transaction history.',
    media: '/static/Dashboard/ticketing_management.jpg',
    title: 'Delivery Wallet',
    total: ''
  }
];