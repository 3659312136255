import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../store/validations/is-empty';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import SimpleDialog from '../../components/dialogs/dialog';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'src/components/pagination/pagination';


const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Partner_Address_Id', numeric: false, disablePadding: false, label: 'Partner Address ID' },
  { id: 'Address_Type', numeric: false, disablePadding: false, label: 'Address Type' },
  { id: 'State', numeric: false, disablePadding: false, label: 'State' },
  { id: 'Country', numeric: false, disablePadding: false, label: 'Country' },
  { id: 'Zip', numeric: false, disablePadding: false, label: 'Pin Code' },
  { id: 'Is_Shipping_Available', numeric: false, disablePadding: false, label: 'Is Shipping Available' },
];

const Results = ({
  className,
  allPartnersaddress,
  deletePartneraddressHandler,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  allPartnersaddressNoFilter,
  state,
  RolePermissions,
  roleUserType,
  pagination,
  setPagination,
  ...rest
}) => {

  const classes = useStyles();
  const navigate = useNavigate();

  const [selectedPartneraddressIds, setSelectedPartneraddressIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedPartneraddressIds;
    if (event.target.checked) {
      newSelectedPartneraddressIds = allPartnersaddress.map(partneraddress => partneraddress.Partner_Address_Id);
    } else {
      newSelectedPartneraddressIds = [];
    }
    setSelectedPartneraddressIds(newSelectedPartneraddressIds);
  };

  const handleSelectOne = (event, id) => {

    const selectedIndex = selectedPartneraddressIds.indexOf(id);
    let newSelectedPartneraddressIds = [];
    if (selectedIndex === -1) {
      newSelectedPartneraddressIds = newSelectedPartneraddressIds.concat(selectedPartneraddressIds, id);
    } else if (selectedIndex === 0) {
      newSelectedPartneraddressIds = newSelectedPartneraddressIds.concat(
        selectedPartneraddressIds.slice(1)
      );
    } else if (selectedIndex === selectedPartneraddressIds.length - 1) {
      newSelectedPartneraddressIds = newSelectedPartneraddressIds.concat(
        selectedPartneraddressIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedPartneraddressIds = newSelectedPartneraddressIds.concat(
        selectedPartneraddressIds.slice(0, selectedIndex),
        selectedPartneraddressIds.slice(selectedIndex + 1)
      );
    }
    setSelectedPartneraddressIds(newSelectedPartneraddressIds);
  };

  const deleteSelectedPartneraddressHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };

  const visiblePartneraddress = partneraddressData => e => {
    navigate("/app/partneraddress-view", { state: { state: state, data: partneraddressData } });
  };

  const editPartneraddressHandler = partneraddressData => e => {
    navigate('/app/edit-partneraddress', { state: { state: state, data: partneraddressData } });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedPartneraddressIds.filter(record => {
                      for (let i = 0; i < allPartnersaddressNoFilter.length; i++) {
                        if (record == allPartnersaddressNoFilter[i].Partner_Address_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allPartnersaddress.length}
                    color="primary"
                    indeterminate={
                      selectedPartneraddressIds.filter(record => {
                        for (let i = 0; i < allPartnersaddressNoFilter.length; i++) {
                          if (record == allPartnersaddressNoFilter[i].Partner_Address_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedPartneraddressIds.filter(record => {
                        for (let i = 0; i < allPartnersaddressNoFilter.length; i++) {
                          if (record == allPartnersaddressNoFilter[i].Partner_Address_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allPartnersaddress.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >&nbsp;&nbsp;&nbsp;Actions
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedPartneraddressIds.filter(record => {
                    for (let i = 0; i < allPartnersaddressNoFilter.length; i++) {
                      if (record == allPartnersaddressNoFilter[i].Partner_Address_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedPartneraddressIds.filter(record => {
                    for (let i = 0; i < allPartnersaddressNoFilter.length; i++) {
                      if (record == allPartnersaddressNoFilter[i].Partner_Address_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0} onClick={deleteSelectedPartneraddressHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allPartnersaddress) &&
                (allPartnersaddress).map(partneraddress => (
                  <TableRow
                    hover
                    key={partneraddress.Partner_Address_Id}
                    selected={
                      selectedPartneraddressIds.indexOf(partneraddress.Partner_Address_Id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedPartneraddressIds.indexOf(partneraddress.Partner_Address_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, partneraddress.Partner_Address_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell>{partneraddress.Partner_Address_Id}</TableCell>
                    <TableCell>{partneraddress.Address_Type}</TableCell>
                    <TableCell width="14%">{partneraddress.State}</TableCell>
                    <TableCell >{partneraddress.Country}</TableCell>
                    <TableCell>{partneraddress.Zip}</TableCell>
                    <TableCell >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {partneraddress.Is_Shipping_Available}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Partner_Address_Write?.Value === "Partner_Address_Write" && RolePermissions?.Partner_Address_Write?.Is_Write === 1) ?
                      <TableCell>
                        <Tooltip title="Address View">
                          <Button
                            onClick={visiblePartneraddress(partneraddress)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                        <Button
                          onClick={editPartneraddressHandler(partneraddress)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                          <DeleteIcon
                            onClick={deletePartneraddressHandler(partneraddress)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Partner_Address_Read?.Value === "Partner_Address_Read" && RolePermissions?.Partner_Address_Read?.Is_Read === 1 &&
                      <TableCell>
                        <Tooltip title="Address View">
                          <Button
                            onClick={visiblePartneraddress(partneraddress)}
                            color="primary"
                          >
                            <VisibilityIcon style={{ color: '#9EA0A5' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allPartnersaddress) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allPartnersaddress: PropTypes.array.isRequired
};

export default Results;