import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createRazorpayTransactionAction } from 'src/store/actions/razorpaytransactionAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/material.css'
import { CircularProgress } from '@mui/material';

const AddRazorpayhistory = ({ className, state, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSubmited = useSelector(state => state.razorpaytransaction.razorpaytransactionsCreate.isLoading);
  const errorMessage = useSelector(state => state.razorpaytransaction.razorpaytransactionsCreate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        Name: state?.Name,
        Email: state?.Email,
        Mobile: state?.Mobile,
        Amount: '',
        UPI_ID: '',
        Created_By: loginEmail,
        Reason: '',
        Contact_Id: state?.Contact_Id

      }}
      validationSchema={Yup.object().shape({
        Name: Yup.string().required('Name is required.'),
        Email: Yup.string().email("Please provide a valid Email ID. ").required('Email ID is required.'),
        Mobile: Yup.string().min(14, "Please provide a valid Mobile Number.").required('Mobile Number is required.'),
        UPI_ID: Yup.string().required('UPI ID is required.'),
        Amount: Yup.number().min(0, "Amount should not be less than 0.").required('Amount is required.'),
        Reason: Yup.string().required('Reason is required.'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        if (values) {
          let formData = JSON.parse(JSON.stringify(values));
          dispatch(createRazorpayTransactionAction(formData, navigate, state));
        }
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Name && errors.Name)}
                        fullWidth
                        helperText={touched.Name && errors.Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Name"
                        name="Name"
                        value={values.Name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Email && errors.Email)}
                        fullWidth
                        helperText={touched.Email && errors.Email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Email"
                        name="Email"
                        value={values.Email}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Mobile */}
                    <Grid item md={6} xs={12}>
                      <PhoneInput
                        containerClass={touched.Mobile && errors.Mobile ? "Mui-error-custom react-tel-input" : "MuiInputBase-formControl Mui-success-custom react-tel-input"}
                        inputClass={"form-control MuiOutlinedInput-input"}
                        error={true}
                        country={"in"}
                        fullWidth
                        inputStyle={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px"
                        }}
                        inputProps={{
                          name: "Mobile",
                        }}
                        specialLabel="Mobile Number"
                        placeholder="+91 XXXXX XXXXX"
                        name="Mobile"
                        onBlur={handleBlur}
                        onChange={(value, country, e) => {
                          setFieldValue("Mobile", "+" + country.dialCode + " " + value.replace(country.dialCode, ''));
                        }}
                        value={values.Mobile}
                      />
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">{touched.Mobile && errors.Mobile}</p>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Amount && errors.Amount)}
                        fullWidth
                        helperText={touched.Amount && errors.Amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Amount"
                        name="Amount"
                        type={Number}
                        value={values.Amount}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.UPI_ID && errors.UPI_ID)}
                        fullWidth
                        helperText={touched.UPI_ID && errors.UPI_ID}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="UPI ID"
                        name="UPI_ID"
                        value={values.UPI_ID}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Reason && errors.Reason)}
                        fullWidth
                        helperText={touched.Reason && errors.Reason}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Reason"
                        name="Reason"
                        value={values.Reason}
                        variant="outlined"
                      />
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddRazorpayhistory.propTypes = {
  className: PropTypes.string
};

export default AddRazorpayhistory;