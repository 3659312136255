import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  InputLabel,
  Card,
  CardContent,
  Grid,
  TextField,
  Avatar,
  FormControl,
  makeStyles,
  Select,
  MenuItem,
  Button,
  TextareaAutosize,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { VarientuploadImagepath } from 'src/store/actions/servicesvarientAction';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { EditPartnerServiceRequestAction } from 'src/store/actions/servicepartnerRequestAction';
import { useNavigate, useLocation } from 'react-router';
import {
  paginationsubcategoryServices,
  paginationServicescategory
} from 'src/store/actions/node-actions/nodepagination';
import CircularProgress from '@mui/material/CircularProgress';
import { useRef } from "react";
import { Autocomplete, FormHelperText } from '@mui/material';


const StatusOption = [

  {
    value: 'success',
    label: 'success'
  },
  {
    value: 'pending',
    label: 'pending'
  },
  {
    value: 'hold',
    label: 'hold'
  },
  {
    value: 'rejected',
    label: 'rejected'
  }
];


const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  Description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditServiceRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  let { state } = location;
  const [image, setImage] = useState({ preview: state.Image, raw: state.Image });
  const [imgUrl, setImgUrl] = useState(state.Image);
  const [uploadPic, setUploadPic] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ Request_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  const [Requests, setRequest] = useState([]);
  const [services, setServices] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [hasMore1, setHasMore1] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [pageno, setpageno] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [ServiceId, SetServiceId] = useState();
  const isSubmitted = useSelector(
    state => state.category.categoryUpdate.isLoading
  );
  const categories = useSelector(state => state.subcategory.Categories.categories);


  const initialValues = {
    Image: imgUrl,
    Request_Id: state.Request_Id ? state.Request_Id : '',
    Request_Type: state.Request_Type ? state.Request_Type : '',
    Request_Name: state.Request_Name ? state.Request_Name : '',
    Description: state.Description ? state.Description : '',
    Status: state.Status ? state.Status : '',
    Status_Feedback: state.Status_Feedback ? state.Status_Feedback : '',
    Service_Name: state.Service_Name ? state.Service_Name : "",
    Service_Id: state.Service_Id ? state.Service_Id : "",
    Ss_Name: state.Ss_Name ? state.Ss_Name : "",
    Ss_Id: state.Ss_Id ? state.Ss_Id : "",
    serviceName: state.Request_Name || '',
    subServiceName: state.Request_Name || '',
    variantName: state.Request_Name || ''
  };

  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = (e) => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    // Simulate async image upload
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      VarientuploadImagepath(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = () => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };


  useEffect(() => {
    const fetchSubcategoryServices = async () => {
      if (state.Service_Id) {
        try {

          setLoading1(true);
          await dispatch(
            paginationsubcategoryServices(
              { Service_Id: state.Service_Id },
              { PageNo: 1, PageSize: 14 },
              (status, data, pagination1) => {

                if (status) {
                  if (Array.isArray(data)) {
                    setRequest(data);
                    setHasMore1(currentPage1 < pagination1?.TotalPages);

                  } else {

                  }
                }
              }
            )
          );
        } catch (error) {

        } finally {

          setLoading1(false);
        }
      }
    };

    fetchSubcategoryServices();
  }, [state.Service_Id,]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        setLoading(true);
        await dispatch(
          paginationServicescategory(
            {},
            { PageNo: currentPage, PageSize: 21 },
            (status, data, pagination) => {
              if (status) {
                if (Array.isArray(data)) {
                  setServices((prev) => [...prev, ...data]);
                  setHasMore(currentPage < pagination.TotalPages);
                } else {

                }
              }
            }
          )
        );
      } catch (error) {

      } finally {
        // End loading state
        setLoading(false);
      }
    };

    fetchServices();
  }, [currentPage, dispatch]);


  const previousServiceId = useRef(ServiceId);
  useEffect(() => {
    if (previousServiceId.current !== ServiceId) {
      setRequest([]);
      setCurrentPage1(1);
      previousServiceId.current = ServiceId;
    }
  }, [ServiceId]);

  useEffect(() => {
    const fetchSubcategoryServices = async () => {
      if (ServiceId) {
        try {
          setLoading1(true);
          await dispatch(
            paginationsubcategoryServices(
              { Service_Id: ServiceId },
              { PageNo: currentPage1, PageSize: 14 },
              (status, data, pagination1) => {

                if (status) {
                  if (Array.isArray(data)) {
                    setRequest((prev) => [...prev, ...data]);
                    setHasMore1(currentPage1 < pagination1?.TotalPages);

                  } else {

                  }
                }
              }
            )
          );
        } catch (error) {

        } finally {

          setLoading1(false);
        }
      }
    };

    fetchSubcategoryServices();
  }, [ServiceId, currentPage1, dispatch]);

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
      setCurrentPage((prevPage) => prevPage + 1);

    }
  };

  const handleScroll1 = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore1 && !loading1) {
      setCurrentPage1((prevPage) => prevPage + 1);
    }
  };

  const validationSchema = Yup.object().shape({
    Request_Type: Yup.string().required('Type is required'),
    Request_Name: Yup.string().required("name is required"),
    serviceName: Yup.string().when('type', {
      is: 'Services',
      then: Yup.string().required('Service Name is required')
    }),
    subServiceName: Yup.string().when('type', {
      is: 'Sub Services',
      then: Yup.string().required('Sub Service Name is required')
    }),
    variantName: Yup.string().when('type', {
      is: 'Varients',
      then: Yup.string().required('Variant Name is required')
    }),
    Status_Feedback: Yup.string().required('Status is required'),
    Description: Yup.string().required('Description is required'),
  })


  const onSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(false);
    let submitTest = true;
    const errors = {};
    values.Image = imgUrl;

    if (!values.Image) {
      errors.Image = "Image is required.";
      submitTest = false;
    }
    setErrors(errors);
    if (submitTest) {
      // let formValues = JSON.parse(JSON.stringify(values));
      let { Service_Name, Ss_Name, variantName, subServiceName, serviceName, Ss_Id, Service_Id, ...formValues } = values;

      dispatch(EditPartnerServiceRequestAction(formValues, navigate, setErrorMessage, setErrors));
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, handleBlur, handleChange, setFieldValue, errors, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardContent>
                <Box>
                  <Grid container spacing={4}>
                    {/* Form Fields - Left Side */}
                    <Grid item xs={12} md={8}>
                      <Box>
                        <Box
                          sx={{
                            minWidth: 120,
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 4
                          }}
                        >
                          {/* Type Select */}
                          <Box sx={{ minWidth: '50%' }}>
                            <Autocomplete
                              options={["Services", "Sub Services", "Variants"]}
                              getOptionLabel={(option) => option}
                              value={values.Request_Type || ""}
                              onChange={(event, newValue) => {
                                setFieldValue("Request_Type", newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Type"
                                  variant="outlined"
                                  error={touched.Request_Type && Boolean(errors.Request_Type)}
                                  helperText={touched.Request_Type && errors.Request_Type}
                                  fullWidth
                                />
                              )}
                            />
                          </Box>

                          {/* Conditionally Rendered Fields */}
                          {values.Request_Type === 'Services' && (
                            <Box sx={{ flex: 1 }}>
                              <TextField
                                id="serviceName"
                                label="Service Name"
                                variant="outlined"
                                fullWidth
                                name="serviceName"
                                value={values.serviceName}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldValue("Request_Name", e.target.value);
                                }}

                              />
                              {errors.Request_Name && touched.Request_Name && (
                                <div style={{ color: 'red' }}>{errors.Request_Name}</div>
                              )}
                            </Box>
                          )}
                          {values.Request_Type === 'Sub Services' && (
                            <Box sx={{ flex: 1 }}>
                              <TextField
                                id="subServiceName"
                                label="Sub Service Name"
                                variant="outlined"
                                fullWidth
                                name="subServiceName"
                                value={values.subServiceName}
                                onChange={(e) => {
                                  setFieldValue("Request_Name", e.target.value);
                                }}

                              />
                              {errors.Request_Name && touched.Request_Name && (
                                <div style={{ color: 'red' }}>{errors.Request_Name}</div>
                              )}
                            </Box>
                          )}
                          {values.Request_Type === 'Variants' && (
                            <Box sx={{ flex: 1 }}>
                              <TextField
                                id="variantName"
                                label="Variant Name"
                                variant="outlined"
                                fullWidth
                                name="variantName"
                                value={values.variantName}

                                onChange={(e) => {
                                  setFieldValue("Request_Name", e.target.value);
                                }}
                              />
                              {errors.Request_Name && touched.Request_Name && (
                                <Box style={{ color: 'red' }}>{errors.Request_Name}</Box>
                              )}
                            </Box>
                          )}
                        </Box>
                        <Box
                          sx={{
                            minWidth: 120,
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 4,
                            marginTop: 12,
                            marginBottom: "12px"
                          }}>

                          {(values.Request_Type === 'Sub Services' || values.Request_Type === 'Variants') && (
                            <Box sx={{ flex: 1, minWidth: '50%', marginTop: "-16px" }}>

                              <Autocomplete
                                options={services}
                                getOptionLabel={(option) => option.Service_Name || ""}
                                value={
                                  services.find((service) => service.Service_Id === values.Service_Id) || null
                                }
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    SetServiceId(newValue.Service_Id);
                                    setFieldValue("Service_Id", newValue.Service_Id);
                                    setFieldValue("Service_Name", newValue.Service_Name);
                                  }
                                }}
                                onScroll={handleScroll}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Service Name"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    error={Boolean(touched.Service_Id && errors.Service_Id)}
                                    helperText={touched.Service_Id && errors.Service_Id}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                )}
                                loading={loading}
                                sx={{
                                  width: "100%",
                                  ".MuiAutocomplete-listbox": {
                                    maxHeight: 50,
                                    overflow: "auto",
                                  },
                                }}
                                ListboxProps={{
                                  onScroll: handleScroll,
                                }}
                              />

                            </Box>
                          )}

                          {values.Request_Type === 'Variants' && (
                            <Box sx={{ flex: 1, minWidth: '50%', marginTop: '-16px' }}>
                              <Autocomplete
                                options={Requests}
                                getOptionLabel={(option) => option.Ss_Name || ""}
                                value={Requests.find((req) => req.Ss_Id === values.Ss_Id) || null}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    setFieldValue("Ss_Id", newValue.Ss_Id);
                                    setFieldValue("Ss_Name", newValue.Ss_Name);
                                  }
                                }}
                                onBlur={handleBlur}
                                loading={loading}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Sub Service Name"
                                    variant="outlined"
                                    margin="normal"
                                    error={Boolean(touched.Ss_Id && errors.Ss_Id)}
                                    helperText={touched.Ss_Id && errors.Ss_Id}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                )}
                                ListboxProps={{
                                  onScroll: handleScroll1,
                                  sx: { maxHeight: 250, overflow: "auto" },
                                }}
                                sx={{ width: "100%" }}
                              />
                            </Box>
                          )}

                        </Box>

                        {/* Description Field */}
                        <Grid item md={12} xs={12}>
                          <Box>
                            <TextareaAutosize
                              className={classes.Description}
                              placeholder="Description"
                              minRows={10}
                              name="Description"
                              value={values.Description}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Box>
                          {errors.Description && touched.Description && (
                            <Box style={{ color: 'red' }}>{errors.Description}</Box>
                          )}
                        </Grid>

                        {/* Status Feedback Field */}
                        <Grid item md={12} xs={12}>
                          <Box>
                            <FormControl fullWidth variant="outlined" style={{ marginTop: '10px' }}>
                              <TextareaAutosize
                                className={classes.Status_Feedback}
                                style={{ padding: '10px' }}
                                placeholder="Status Feedback"
                                minRows={10}
                                name="Status_Feedback"
                                value={values.Status_Feedback}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </FormControl>
                          </Box>
                          {errors.Status_Feedback && touched.Status_Feedback && (
                            <Box style={{ color: 'red' }}>{errors.Status_Feedback}</Box>
                          )}
                        </Grid>

                        {/* Status Field */}
                        <Grid item md={12} xs={12}>
                          <FormControl fullWidth variant="outlined" style={{ marginTop: '10px' }}>

                            <Autocomplete
                              options={StatusOption}
                              getOptionLabel={(option) => option.label || ""}
                              value={StatusOption.find((status) => status.value === values.Status) || null}
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  setFieldValue("Status", newValue.value);
                                }
                              }}
                              onBlur={handleBlur}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Status"
                                  variant="outlined"
                                  margin="normal"
                                  error={Boolean(touched.Status && errors.Status)}
                                  helperText={touched.Status && errors.Status}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                />
                              )}
                              sx={{ width: "100%" }}
                            />
                          </FormControl>
                        </Grid>
                      </Box>
                    </Grid>

                    {/* Form Fields - Right Side */}
                    <Grid item md={4} xs={12}>
                      <Card>
                        &nbsp;Web Image
                        <CardContent>
                          <Box display="flex" justifyContent="center">
                            {image.preview ? (
                              <Avatar
                                className={classes.images}
                                alt="Brand Upload"
                                src={image.preview}
                                variant="square"
                              />
                            ) : (
                              <>
                                <Avatar
                                  className={classes.images}
                                  alt="Brand Upload"
                                  src="/static/images/image-placeholder.png"
                                  variant="square"
                                />
                              </>
                            )}
                          </Box>

                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                          >
                            {image.preview ? (<Button
                              style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                              onClick={handleRemoveImage}
                            >
                              Remove Picture
                            </Button>) : (<input
                              style={{ display: 'block', width: '80%' }}
                              id="upload-photo"
                              name="image"
                              type="file"
                              accept="image/*"
                              onChange={handleImage}
                            />)}
                            <br />
                          </Box>
                        </CardContent>
                      </Card>
                      {/* <span style={{ color: "red" }}>
                      {!imgUrl && touched.Image && errors.Image}
                    </span> */}
                      <FormHelperText error>
                        {!imgUrl && touched.Image && errors.Image}
                      </FormHelperText>

                    </Grid>
                  </Grid>
                  {/* <Box display="flex" pl={2} >
                  <span style={{ color: "red" }}>{errorMessage.global.message}</span>
                </Box> */}
                  <Box display="flex" pl={2}>
                    <Typography color="error">
                      {errorMessage.global.message}
                    </Typography>
                  </Box>

                  {/* Submit Button */}
                  <Box display="flex" marginTop={4}>
                    {isSubmitted ? (
                      <CircularProgress />
                    ) : (

                      <Button
                        disabled={isSubmitted || uploadPic}
                        type="submit"
                        variant="contained"
                        style={{ backgroundColor: '#27B6CC' }}>
                        <span style={{ color: 'white' }}>Submit</span>

                      </Button>
                    )}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </>
  );
};

EditServiceRequest.propTypes = {
  className: PropTypes.string
};

export default EditServiceRequest;
