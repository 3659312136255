import {

    USER_FINANCE_TRANSACTION_UPDATE_REQUEST,
    USER_FINANCE_TRANSACTION_UPDATE_SUCCESS,
    USER_FINANCE_TRANSACTION_UPDATE_FAIL,
} from '../actions/userfinancialtransactionsAction'

const initialState = {
    userfinancialtransaction: { userfinancialtransaction: {}, error: '', isLoading: false },
    userfinancialtransactionUpdate: { userfinancialtransaction: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_FINANCE_TRANSACTION_UPDATE_REQUEST:
            return { ...state, userfinancialtransactionUpdate: { userfinancialtransaction: {}, error: '', isLoading: true } };
        case USER_FINANCE_TRANSACTION_UPDATE_SUCCESS:
            return { ...state, userfinancialtransactionUpdate: { userfinancialtransaction: state.userfinancialtransaction.userfinancialtransaction, error: '', isLoading: false } };
        case USER_FINANCE_TRANSACTION_UPDATE_FAIL:
            return { ...state, userfinancialtransactionUpdate: { userfinancialtransaction: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}  