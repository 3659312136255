import {
    CORP_USER_APPROVAL_FAIL,
    CORP_USER_APPROVAL_REQUEST,
    CORP_USER_APPROVAL_SUCCESS,
    CORP_USER_REJECTED_FAIL,
    CORP_USER_REJECTED_REQUEST,
    CORP_USER_REJECTED_SUCCESS
} from "../actions/corporateusersAction";


const initialState = {
    corporateuserapprovalUpdate: { corporateapprovaluser: {}, error: '', isLoading: false },
    corporateuserrejectedUpdate: { corporaterejecteduser: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case CORP_USER_APPROVAL_REQUEST:
            return { ...state, corporateuserapprovalUpdate: { corporateapprovaluser: {}, error: '', isLoading: true } };
        case CORP_USER_APPROVAL_SUCCESS:
            return { ...state, corporateuserapprovalUpdate: { corporateapprovaluser: action.payload, error: '', isLoading: false } };
        case CORP_USER_APPROVAL_FAIL:
            return { ...state, corporateuserapprovalUpdate: { corporateapprovaluser: {}, error: action.payload, isLoading: false } };

        case CORP_USER_REJECTED_REQUEST:
            return { ...state, corporateuserrejectedUpdate: { corporaterejecteduser: {}, error: '', isLoading: true } };
        case CORP_USER_REJECTED_SUCCESS:
            return { ...state, corporateuserrejectedUpdate: { corporaterejecteduser: action.payload, error: '', isLoading: false } };
        case CORP_USER_REJECTED_FAIL:
            return { ...state, corporateuserrejectedUpdate: { corporaterejecteduser: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}