import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { boomiAdminApi } from 'src/store/actions/commonAxios';

const ESICountry = (props) => {
    const { valueUpdate, ...rest } = props
    const [country, setCountry] = useState();

    React.useEffect(() => {
        let country = { "Filter_Type": "COUNTRY" }
        getCountry(country)
    }, [])

    const getCountry = (country) => {
        boomiAdminApi.post(`/Country_State_City/Get`, country)
            .then(response => {
                setCountry(response?.data?.Country
                );
            })
            .catch(error => {
            })
    }

    return (
        <TextField {...props}>
            <option key="" value="">--Please Select--</option>
            {country?.map(option => (
                <option key={option.COUNTRY_CODE} value={option.COUNTRY_CODE}>
                    {option.NAME}
                </option>
            ))}
        </TextField>
    );
};

export default ESICountry;