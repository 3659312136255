import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateWalletAction
} from './../../store/actions/walletAction';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const EditWallet = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = location;

  const isSubmited = useSelector(state => state.wallet.walletUpdate.isLoading);
  const errorMessage = useSelector(state => state.wallet.walletUpdate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  return (
    <Formik
      initialValues={{
        Wallet_Id: state.Wallet_Id ? state.Wallet_Id : "",
        User_Id: state.Wallet_Owner ? state.Wallet_Owner : "",
        Is_Eligible_For_Withdraw: state.Is_Eligible_For_Withdraw > 0 ? true : false,
        Whats_Hot: state.Whats_Hot > 0 ? true : false,
        Status: state.Status ? state.Status : "",
        Modified_By: loginEmail,
        Withdraw_Amount: state.Withdraw_Amount ? state.Withdraw_Amount : "",
      }}

      validationSchema={
        Yup.object().shape({
          User_Id: Yup.string().required('Email ID is required.'),
          Status: Yup.string().required('Status is required.'),
        })
      }
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        let formValues = JSON.parse(JSON.stringify(values));
        if (values.Is_Eligible_For_Withdraw) {
          formValues.Is_Eligible_For_Withdraw = 1;
        } else {
          formValues.Is_Eligible_For_Withdraw = 0;
        }
        dispatch(updateWalletAction(formValues, navigate));
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>

                    {/* User Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.User_Id && errors.User_Id)}
                        fullWidth
                        helperText={touched.User_Id && errors.User_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={true}
                        label="Email ID"
                        name="User_Id"
                        value={values.User_Id}
                        variant="outlined"
                      />
                    </Grid>

                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Is Eligible For Withdraw */}
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onBlur={handleBlur}
                            // disabled
                            checked={values.Is_Eligible_For_Withdraw}
                            onChange={handleChange}
                            name="Is_Eligible_For_Withdraw"
                            color="primary"
                          />
                        }
                        label="Is Eligible For Withdrawl"
                      />
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditWallet.propTypes = {
  className: PropTypes.string
};

export default EditWallet;