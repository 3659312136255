import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../../store/validations/is-empty';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  images: {
    width: 100,
    height: 50
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Brand_Id', numeric: false, disablePadding: true, label: 'Brand ID' },
  { id: 'Brand_Name', numeric: false, disablePadding: false, label: 'Brand Name' },
  { id: 'Brand_Esi_Reviews', numeric: false, disablePadding: false, label: 'ESI Reviews' },
  { id: 'Brand_Esi_Rating', numeric: false, disablePadding: false, label: 'ESI Rating' },
];

const Results = ({
  className,
  allBrands,
  pagination,
  setPagination,
  handleLimitChange,
  handlePageChange,
  order,
  orderBy,
  createSortHandler,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedBrandIds, setSelectedBrandIds] = useState([]);
  const navigate = useNavigate();

  const OpenUserReviewHandler = BrandData => e => {
    navigate('/app/brand/user-reviews', { state: BrandData });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell style={{ display: 'flex', alignItems: 'center' }} className={classes.headerColumn} >&nbsp;&nbsp;&nbsp;Reviews
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allBrands) &&
                (allBrands).map(brand => (

                  <TableRow
                    hover
                    key={brand.Brand_Id}
                    selected={selectedBrandIds.indexOf(brand.Brand_Id) !== -1}
                  >
                    <TableCell width="15%">{brand.Brand_Id}</TableCell>
                    <TableCell width="15%">{brand.Brand_Name}</TableCell>
                    <TableCell width="40%"><div dangerouslySetInnerHTML={{ __html: brand.Brand_Esi_Reviews?.substring(0, 60) }} ></div></TableCell>
                    <TableCell width="15%">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{brand.Brand_Esi_Rating}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Brand_Reviews_Ratings_Write?.Value === "Brand_Reviews_Ratings_Write" && RolePermissions?.Brand_Reviews_Ratings_Write?.Is_Write === 1) ?
                      <TableCell style={{ textAlign: "center", }}>
                        <Button
                          onClick={OpenUserReviewHandler(brand)}
                          style={{ color: '#1665D8', fontSize: 13, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >User Reviews
                          {brand.reviews}
                        </Button>
                      </TableCell>
                      : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allBrands) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt="" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allBrands: PropTypes.array.isRequired
};

export default Results;