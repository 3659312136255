
import config from './config';
import { toast } from 'react-toastify';
import { paginationPartnerTicket } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi} from './commonAxios';

export const PARTNER_TICKET_CREATE_REQUEST = "PARTNER_TICKET_CREATE_REQUEST";
export const PARTNER_TICKET_CREATE_SUCCESS = "PARTNER_TICKET_CREATE_SUCCESS";
export const PARTNER_TICKET_CREATE_FAIL = "PARTNER_TICKET_CREATE_FAIL";
export const PARTNER_TICKET_UPDATE_REQUEST = "PARTNER_TICKET_UPDATE_REQUEST";
export const PARTNER_TICKET_UPDATE_SUCCESS = "PARTNER_TICKET_UPDATE_SUCCESS";
export const PARTNER_TICKET_UPDATE_FAIL = "PARTNER_TICKET_UPDATE_FAIL";
export const TICKET_PARTNERS_REQUEST = "TICKET_PARTNERS_REQUEST";
export const TICKET_PARTNERS_SUCCESS = "TICKET_PARTNERS_SUCCESS";
export const TICKET_PARTNERS_FAIL = "TICKET_PARTNERS_FAIL";


/*=============================================================
                  Add Ticket Action
===============================================================*/
export const createTicketAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: PARTNER_TICKET_CREATE_REQUEST
  });
  nodeCudApi.post(`/Partner_Tickets/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNER_TICKET_CREATE_SUCCESS
        });
        toast('Partner Ticket added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partner-ticketing-management');
      } else {
        dispatch({
          type: PARTNER_TICKET_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_TICKET_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                edit Ticket Action
===============================================================*/
export const updateTicketAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: PARTNER_TICKET_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Partner_Tickets/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PARTNER_TICKET_UPDATE_SUCCESS
        });
        toast('Partner Ticket updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/partner-ticketing-management');
      } else {
        dispatch({
          type: PARTNER_TICKET_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_TICKET_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

/*=============================================================
                 Delete Ticket Action
===============================================================*/
export const deleteTicketAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/Partner_Tickets/Delete`, formData
    );
    if (data) {
      toast('Ticket deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationPartnerTicket(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

//Get users
export const getAllPartnersAction = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "ALL",
    };

    dispatch({
      type: TICKET_PARTNERS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Partners/Physical_Partner_Profile_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`,
      formData,
     
    );
    if (data) {
      if (data.successResponse?.Is_Data_Exist === '0') {
        dispatch({
          type: TICKET_PARTNERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: TICKET_PARTNERS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again", 3000);
    dispatch({
      type: TICKET_PARTNERS_FAIL,
      payload: err
    });
  }
};

//Get Ticket Action
export const getPartnerTicketAction = (formData, callBackPartnerTicketData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Partner_Tickets/Get?PageNo=0&PageSize=${config.pageSize}&SortBy=TICKET_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBackPartnerTicketData(data)
    }
  } catch (err) {
  }
};