import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Box
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { ConnectedFocusError } from "focus-formik-error";
import { UpdateSubscriptionUserAction, getAllUserAddress } from 'src/store/actions/subscriptionuserAction';
import { getAllUserAction } from 'src/store/actions/userprofileAction';
import { CircularProgress } from '@mui/material';

const ScheduleTypeOpt = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'DAILY',
        label: 'DAILY'
    },
    {
        value: 'WEEKLY',
        label: 'WEEKLY'
    },
    {
        value: 'MONTHLY',
        label: 'MONTHLY'
    },
];

const EditUserSubscription = ({ className, ...rest }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = location;

    useEffect(() => {
        let formdata = {
            "Records_Filter": "ALL",
            "Status": "Active"
        }
        dispatch(getAllUserAction(formdata))
    }, []);

    const isSubmited = useSelector(state => state.subscription.subscriptionUpdate.isLoading);
    const errorMessage = useSelector(state => state.subscription.subscriptionUpdate.error);
    const updatedBy = useSelector(state => state.auth.user.Email_Id);

    const [addressdata, setAddressData] = React.useState([]);
    let USERADDRESS = []
    if (addressdata) {
        for (let i = 0; i < addressdata?.length; i++) {
            USERADDRESS.push(
                {
                    label: addressdata[i].User_Address_Id,
                    value: addressdata[i].User_Address_Id
                })
        }
    }

    const callBackUserAddrData = (data) => {
        setAddressData(data)
    }

    const setAddrType = (value) => {
        if (value !== "") {
            let data = {
                Records_Filter: 'FILTER',
                User_Email_Id: value
            }
            dispatch(getAllUserAddress(data, callBackUserAddrData))
        }
    }

    // Date
    let nDate = new Date();
    let month = String(nDate.getMonth() + 1).length > 1 ? String(nDate.getMonth() + 1) : "0" + String(nDate.getMonth() + 1);
    let date = String(nDate.getDate()).length > 1 ? String(nDate.getDate()) : "0" + String(nDate.getDate());
    let time = String(nDate.getHours() + ":" + nDate.getMinutes() + ":" + nDate.getSeconds()).length > 1 ? String(nDate.getHours() + ":" + nDate.getMinutes() + ":" + nDate.getSeconds()) : "0" + String(nDate.getHours() + ":" + nDate.getMinutes() + ":" + nDate.getSeconds());
    const [dos, setDos] = useState(nDate.getFullYear() + "-" + month + "-" + date + " " + time);

    //for End date validation
    const [datevalue, setDatevalue] = useState('');
    const setEndDate = (value) => {
        setDatevalue(value);
    };

    return (
        <Formik
            initialValues={{
                Subscription_User_Auto_Id: state.Subscription_User_Auto_Id ? state.Subscription_User_Auto_Id : "",
                User_Email_Id: state.User_Email_Id ? state.User_Email_Id : "",
                Advance_Amount: state.Advance_Amount ? state.Advance_Amount : "",
                Payment_Method: state.Payment_Method ? state.Payment_Method : "",
                Payment_Reference_Number: state.Payment_Reference_Number ? state.Payment_Reference_Number : "",
                Schedule_End_Date: state.Schedule_End_Date ? state.Schedule_End_Date : "",
                Schedule_Start_Date: state.Schedule_Start_Date ? state.Schedule_Start_Date : "",
                Schedule_Type: state.Schedule_Type ? state.Schedule_Type : "",
                Subscription_Id: state.Subscription_Id ? state.Subscription_Id : "",
                Subscription_Package_Id: state.Subscription_Package_Id ? state.Subscription_Package_Id : "",
                Subscription_Status: state.Subscription_Status ? state.Subscription_Status : "",
                User_Shipping_Address_Id: state.User_Shipping_Address_Id ? state.User_Shipping_Address_Id : "",
                Modified_By: updatedBy
            }}
            validationSchema={
                Yup.object().shape({
                    User_Email_Id: Yup.string().required('User Email ID is required.'),
                    Schedule_Type: Yup.string().required('Service Type is required.'),
                    Subscription_Status: Yup.string().required('Status is required.'),
                    Schedule_Start_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require Schedule Start Date greater than or equal to today.").required("Schedule Start Date is required."),
                    Schedule_End_Date: Yup.date().when("Schedule_Start_Date", (Schedule_Start_Date, schema) => Schedule_Start_Date && schema.min(Schedule_Start_Date, "Schedule End Date can't be before Schedule From Date.")).required("Event End Date is required."),
                    User_Shipping_Address_Id: Yup.string().required('Service Required Address ID is required.'),
                    Subscription_Id: Yup.string().required('Service Request Total Time In Days is required.'),
                    Subscription_Package_Id: Yup.string().required('Service Request Total Time In Hours is required.'),
                })
            }

            onSubmit={(values) => {
                if (values) {
                    let formData = JSON.parse(JSON.stringify(values));
                    dispatch(UpdateSubscriptionUserAction(formData, navigate));
                }
            }
            }
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="User Email ID"
                                                name="User_Email_Id"
                                                value={values.User_Email_Id}
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Schedule_Type && errors.Schedule_Type)}
                                                fullWidth
                                                helperText={touched.Schedule_Type && errors.Schedule_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Schedule Type"
                                                name="Schedule_Type"
                                                value={values.Schedule_Type}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                select
                                                SelectProps={{ native: true }}
                                            >
                                                {ScheduleTypeOpt.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        {/* Service_Required_Address_ID */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.User_Shipping_Address_Id && errors.User_Shipping_Address_Id)}
                                                fullWidth
                                                helperText={touched.User_Shipping_Address_Id && errors.User_Shipping_Address_Id}
                                                onBlur={handleBlur}
                                                label="User Shipping Address ID"
                                                name="User_Shipping_Address_Id"
                                                onChange={handleChange}
                                                value={values.User_Shipping_Address_Id}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Subscription_Id && errors.Subscription_Id)}
                                                fullWidth
                                                helperText={touched.Subscription_Id && errors.Subscription_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Subscription ID"
                                                name="Subscription_Id"
                                                value={values.Subscription_Id}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                            </TextField>
                                        </Grid>
                                        {/* Subscription_Package_ID  */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Subscription_Package_Id && errors.Subscription_Package_Id)}
                                                fullWidth
                                                helperText={touched.Subscription_Package_Id && errors.Subscription_Package_Id}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Subscription Package ID"
                                                name="Subscription_Package_Id"
                                                value={values.Subscription_Package_Id}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                            </TextField>
                                        </Grid>
                                        {/* Subscription_Status */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Subscription_Status && errors.Subscription_Status)}
                                                fullWidth
                                                helperText={touched.Subscription_Status && errors.Subscription_Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Subscription Status"
                                                name="Subscription_Status"
                                                value={values.Subscription_Status}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            </TextField>
                                        </Grid>
                                        {/* Schedule_Start_Date */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Schedule_Start_Date && errors.Schedule_Start_Date)}
                                                fullWidth
                                                helperText={touched.Schedule_Start_Date && errors.Schedule_Start_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Schedule Start Date"
                                                type="date"
                                                name="Schedule_Start_Date"
                                                defaultValue={values.Schedule_Start_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>

                                        {/* Advance_Amount */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Schedule_End_Date && errors.Schedule_End_Date)}
                                                fullWidth
                                                helperText={touched.Schedule_End_Date && errors.Schedule_End_Date}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Schedule End Date"
                                                type="date"
                                                name="Schedule_End_Date"
                                                defaultValue={values.Schedule_End_Date}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        {/* Advance_Amount */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Advance_Amount && errors.Advance_Amount)}
                                                fullWidth
                                                helperText={touched.Advance_Amount && errors.Advance_Amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Advance Amount"
                                                name="Advance_Amount"
                                                type="number"
                                                value={values.Advance_Amount}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                            </TextField>
                                        </Grid>
                                        {/* Payment_Reference_Number */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Payment_Reference_Number && errors.Payment_Reference_Number)}
                                                fullWidth
                                                helperText={touched.Payment_Reference_Number && errors.Payment_Reference_Number}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Payment Reference Number"
                                                name="Payment_Reference_Number"
                                                value={values.Payment_Reference_Number}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                            </TextField>
                                        </Grid>
                                        {/* Payment_Method */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Payment_Method && errors.Payment_Method)}
                                                fullWidth
                                                helperText={touched.Payment_Method && errors.Payment_Method}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Payment Method"
                                                name="Payment_Method"
                                                value={values.Payment_Method}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>

                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

EditUserSubscription.propTypes = {
    className: PropTypes.string
};

export default EditUserSubscription;