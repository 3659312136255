import {
  PARTNERSADDRESS_REQUEST,
  PARTNERSADDRESS_SUCCESS,
  PARTNERSADDRESS_FAIL,

  PARTNERADDRESS_GET_REQUEST,
  PARTNERADDRESS_GET_SUCCESS,
  PARTNERADDRESS_GET_FAIL,
  PARTNERADDRESS_CREATE_REQUEST,
  PARTNERADDRESS_CREATE_SUCCESS,
  PARTNERADDRESS_CREATE_FAIL,
  PARTNERADDRESS_UPDATE_REQUEST,
  PARTNERADDRESS_UPDATE_SUCCESS,
  PARTNERADDRESS_UPDATE_FAIL,

} from '../actions/partneraddressAction'

const initialState = {
  partnersaddress: { all: [], error: '', isLoading: false },
  partneraddress: { partneraddress: [], error: '', isLoading: false },
  partneraddressCreate: { partneraddress: {}, error: '', isLoading: false },
  partneraddressUpdate: { partneraddress: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PARTNERSADDRESS_REQUEST:
      return { ...state, partnersaddress: { all: state.partnersaddress.all, error: '', isLoading: true } };
    case PARTNERSADDRESS_SUCCESS:
      return { ...state, partnersaddress: { all: action.payload, error: '', isLoading: false } };
    case PARTNERSADDRESS_FAIL:
      return { ...state, partnersaddress: { all: [], error: action.payload, isLoading: false } };

    case PARTNERADDRESS_GET_REQUEST:
      return { ...state, partneraddress: { partneraddress: [], error: '', isLoading: true } };
    case PARTNERADDRESS_GET_SUCCESS:
      return { ...state, partneraddress: { partneraddress: action.payload, error: '', isLoading: false } };
    case PARTNERADDRESS_GET_FAIL:
      return { ...state, partneraddress: { partneraddress: [], error: action.payload, isLoading: false } };

    case PARTNERADDRESS_CREATE_REQUEST:
      return { ...state, partneraddressCreate: { partneraddress: {}, error: '', isLoading: true } };
    case PARTNERADDRESS_CREATE_SUCCESS:
      return { ...state, partneraddressCreate: { partneraddress: state.partneraddressCreate.partneraddress, error: '', isLoading: false } };
    case PARTNERADDRESS_CREATE_FAIL:
      return { ...state, partneraddressCreate: { partneraddress: {}, error: action.payload, isLoading: false } };
    case PARTNERADDRESS_UPDATE_REQUEST:
      return { ...state, partneraddressUpdate: { partneraddress: {}, error: '', isLoading: true } };
    case PARTNERADDRESS_UPDATE_SUCCESS:
      return { ...state, partneraddressUpdate: { partneraddress: state.partneraddressUpdate.partneraddress, error: '', isLoading: false } };
    case PARTNERADDRESS_UPDATE_FAIL:
      return { ...state, partneraddressUpdate: { partneraddress: {}, error: action.payload, isLoading: false } };

    default:
      return state;
  }
}