import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    updateContentManagementStaticAction,
    getAllContentManagementStaticAction
} from '../../store/actions/privacypolicyTermsconditionsAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const moduleOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'USER',
        label: 'USER'
    },
    {
        value: 'PARTNER',
        label: 'PARTNER'
    },
    {
        value: 'OPIYUM',
        label: 'OPIYUM'
    }
];

const useStyles = makeStyles(() => ({
    images: {
        width: 120,
        height: 120,
        borderRadius: 5,
        marginBottom: 15
    },
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const EditContentManagement = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const location = useLocation();
    let { state } = location;

    useEffect(() => {
        const formData = {
            Records_Filter: 'ALL'
        };
        dispatch(getAllContentManagementStaticAction(formData));
    }, []);

    const [errorMessage, setErrorMessage] = useState({ Cms_Name: { key: '', message: '' }, global: { key: '', message: '' } });
    const isSubmitted = useSelector(state => state.privacypolicytermscondition.privacypolicytermsconditionUpdate.isLoading);
    const modifiedBy = useSelector(state => state.auth.user.Email_Id);

    // Richtext editor
    const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Description || "").contentBlocks);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
    const [rhDiscription, setRhDiscription] = useState(state.Description);

    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    return (
        <Formik
            initialValues={{
                Cms_Static_Id: state.Cms_Static_Id ? state.Cms_Static_Id : "",
                Name: state.Name ? state.Name : "",
                Status: state.Status ? state.Status : "",
                Description: state.Description ? state.Description : "",
                Modified_By: modifiedBy,
                Module_Type: state.Module_Type ? state.Module_Type : "",
            }}
            validationSchema={
                Yup.object().shape({
                    Name: Yup.string().matches(/^[a-zA-Z][\sa-zA-Z]*/, "Please remove special characters & white spaces at the beginning for the Name.").min(5, "Name requires atleast 5 characters.").notOneOf([errorMessage.Cms_Name.key, null], errorMessage.Cms_Name.message).required("Name is required."),
                    Status: Yup.string().required('Status is required'),
                    Module_Type: Yup.string().required('Module Type is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                setSubmitting(false);
                let submitTest = true;
                const errors = {};

                values.Description = rhDiscription;

                if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
                    submitTest = false;
                    errors.Description = "Description is required.";
                }
                else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
                    submitTest = false;
                    errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
                }
                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(updateContentManagementStaticAction(formValues, navigate, setErrorMessage, setErrors));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        {/*  name */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Name && errors.Name)}
                                                fullWidth
                                                helperText={touched.Name && errors.Name}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Name"
                                                name="Name"
                                                value={values.Name}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        {/* Module_Type */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Module_Type && errors.Module_Type)}
                                                fullWidth
                                                helperText={touched.Module_Type && errors.Module_Type}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Module Type"
                                                name="Module_Type"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Module_Type}
                                                variant="outlined"
                                            >
                                                {moduleOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {/* Status */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {/* Description  */}
                                        <Grid item md={12} xs={12}>
                                            <Box className={classes.description}>
                                                Description
                                                <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                                            </Box>
                                            <span style={{ color: "red" }}>
                                                {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent >

                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage.global.message}</span>
                        </Box>

                        <Box display="flex" p={2}>
                            {isSubmitted ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    {/* Save details */}
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card >
                </form >
            )
            }
        </Formik >
    );
};

EditContentManagement.propTypes = {
    className: PropTypes.string
};

export default EditContentManagement;