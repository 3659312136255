import React, { useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, ListItem, makeStyles } from '@material-ui/core';
import isEmpty from 'src/store/validations/is-empty';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: '#27B6CC'
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const NavItem = ({ className, href, icon: Icon, title, item, rolePermission, ...rest }) => {
  const classes = useStyles();

  const [seconds, setSeconds] = React.useState(-1);

  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  useEffect(() => {
    if (!isEmpty(rolePermission)) {
      setSeconds(5)
      if (item?.title === "Reports") {
        item.hide = rolePermission?.Reports_Hide?.Is_Hide
      } else if (item?.title === "Company Settings") {
        item.hide = rolePermission?.Company_Settings_Hide?.Is_Hide
      } else if (item?.title === "ESI Main Centre") {
        item.hide = rolePermission?.ESI_Main_Center_Hide?.Is_Hide
      } else if (item?.title === "Partner Management") {
        item.hide = rolePermission?.Partner_Management_Hide?.Is_Hide
      } else if (item?.title === "Users Management") {
        item.hide = rolePermission?.Users_Management_Hide?.Is_Hide
      } else if (item?.title === "Order Management") {
        item.hide = rolePermission?.Order_Management_Hide?.Is_Hide
      } else if (item?.title === "Promotion Management") {
        item.hide = rolePermission?.Promotion_Management_Hide?.Is_Hide
      } else if (item?.title === "Content Management") {
        item.hide = rolePermission?.Content_Management_Main_Hide?.Is_Hide
      } else if (item?.title === "Delivery Management") {
        item.hide = rolePermission?.Delivery_Management_Hide?.Is_Hide
      } else if (item?.title === "Corporate Management") {
        item.hide = rolePermission?.Corporate_Management_Hide?.Is_Hide
      } else if (item?.title === "Store Type Management") {
        item.hide = rolePermission?.Store_Type_Management_Main_Hide?.Is_Hide
      } else if (item?.title === "User Ride Management") {
        item.hide = rolePermission?.User_Ride_Management_Hide?.Is_Hide
      } else if (item?.title === "Services Management") {
        item.hide = rolePermission?.Services_Hide?.Is_Hide
      } else if (item?.title === "Bidding Management") {
        item.hide = rolePermission?.Bidding_Management_Hide?.Is_Hide
      } else if (item?.title === "Meepaisa Fashion Hub") {
        item.hide = rolePermission?.Meepaisa_Fashion_Hub_Hide?.Is_Hide
      } else if (item?.title === "Razorpay Payments") {
        item.hide = rolePermission?.Razorpay_Payments_Main_Hide?.Is_Hide
      } else if (item?.title === "Meepaisa Global Coupons") {
        item.hide = rolePermission?.Meepaisa_Global_Coupons_Hide?.Is_Hide
      } else if (item?.title === "Settings") {
        item.hide = rolePermission?.Settings_Hide?.Is_Hide
      }
    }
  }, [rolePermission]);

  return (
    <>
      {item?.hide !== 1 &&
        <ListItem
          className={clsx(classes.item, className)}
          disableGutters
          {...rest}
        >
          <Button
            activeclassname={classes.active}
            className={classes.button}
            component={RouterLink}
            to={href}
          >
            {Icon && <Icon className={classes.icon} size="22" />}
            <span className={classes.title}>{title}</span>
          </Button>
        </ListItem>
      }
    </>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;