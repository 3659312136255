import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    makeStyles
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import isEmpty from '../../store/validations/is-empty';
import SimpleDialog from '../../components/dialogs/dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    }
}));

const headCells = [
    { id: 'Ride_Vehicle_Type_Id', numeric: false, disablePadding: true, label: 'Ride Vehicle Type ID' },
    { id: 'Vehicle_Type', numeric: false, disablePadding: false, label: 'Vehicle Type' },
    { id: 'Vehicle_Comfort_Type', numeric: false, disablePadding: false, label: 'Vehicle Comfort Type' },
    { id: 'IGST_Percentage', numeric: false, disablePadding: false, label: 'IGST Percentage' },
];

const Results = ({
    className,
    allridevehitype,
    pagination,
    setPagination,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    createSortHandler,
    allridevehitypeNoFilter,
    RolePermissions,
    roleUserType,
    ...rest
}) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [selectedRideVehiTypeIds, setSelectedRideVehiTypeIds] = useState([]);
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const handleClose = (value) => {
        setOpen(false);
    };

    const handleSelectAll = event => {
        let newSelectedRideVehiTypeIds;
        if (event.target.checked) {
            newSelectedRideVehiTypeIds = allridevehitype.map(ridevehitype => ridevehitype.Ride_Vehicle_Type_Id);
        } else {
            newSelectedRideVehiTypeIds = [];
        }
        setSelectedRideVehiTypeIds(newSelectedRideVehiTypeIds);
    };

    const handleSelectOne = (event, id) => {
        setSelectedRideVehiTypeIds(selectedRideVehiTypeIds.filter(record => {
            for (let i = 0; i < allridevehitypeNoFilter.length; i++) {
                if (record === allridevehitypeNoFilter[i].Ride_Vehicle_Type_Id) {
                    return true;
                }
            }
            return false;
        }))
        const selectedIndex = selectedRideVehiTypeIds.indexOf(id);
        let newSelectedRideVehiTypeIds = [];
        if (selectedIndex === -1) {
            newSelectedRideVehiTypeIds = newSelectedRideVehiTypeIds.concat(selectedRideVehiTypeIds, id);
        } else if (selectedIndex === 0) {
            newSelectedRideVehiTypeIds = newSelectedRideVehiTypeIds.concat(
                selectedRideVehiTypeIds.slice(1)
            );
        } else if (selectedIndex === selectedRideVehiTypeIds.length - 1) {
            newSelectedRideVehiTypeIds = newSelectedRideVehiTypeIds.concat(
                selectedRideVehiTypeIds.slice(0, -1)
            );
        } else if (selectedIndex > 0) {
            newSelectedRideVehiTypeIds = newSelectedRideVehiTypeIds.concat(
                selectedRideVehiTypeIds.slice(0, selectedIndex),
                selectedRideVehiTypeIds.slice(selectedIndex + 1)
            );
        }
        setSelectedRideVehiTypeIds(newSelectedRideVehiTypeIds);
    };

    const deleteSelectedridevehitypeHandler = () => async e => {
        setOpen(true);
        setTitle("Alert!");
        setMessage("This feature is under development.");
    };

    const editridevehitypeHandler = vehicleData => e => {
        navigate('/app/edit-vehicle-type', { state: vehicleData });
    };

    const visibleridevehitypeHandler = vehicleData => e => {
        navigate("/app/view-vehicle-type", { state: vehicleData });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedRideVehiTypeIds.filter(record => {
                                            for (let i = 0; i < allridevehitypeNoFilter.length; i++) {
                                                if (record === allridevehitypeNoFilter[i].Ride_Vehicle_Type_Id) {
                                                    return true;
                                                }
                                            }
                                            return false;
                                        }).length === allridevehitype.length}
                                        color="primary"
                                        indeterminate={
                                            selectedRideVehiTypeIds.filter(record => {
                                                for (let i = 0; i < allridevehitypeNoFilter.length; i++) {
                                                    if (record === allridevehitypeNoFilter[i].Ride_Vehicle_Type_Id) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            }).length > 0 &&
                                            selectedRideVehiTypeIds.filter(record => {
                                                for (let i = 0; i < allridevehitypeNoFilter.length; i++) {
                                                    if (record === allridevehitypeNoFilter[i].Ride_Vehicle_Type_Id) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            }).length < allridevehitype.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-4rem' }} >Actions
                                    {selectedRideVehiTypeIds.filter(record => {
                                        for (let i = 0; i < allridevehitypeNoFilter.length; i++) {
                                            if (record === allridevehitypeNoFilter[i].Ride_Vehicle_Type_Id) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }).length > 0 && <Button disabled={selectedRideVehiTypeIds.filter(record => {
                                        for (let i = 0; i < allridevehitypeNoFilter.length; i++) {
                                            if (record === allridevehitypeNoFilter[i].Ride_Vehicle_Type_Id) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }).length <= 0} onClick={deleteSelectedridevehitypeHandler()}>
                                            <DeleteSweepIcon
                                                style={{ color: '#ff7a7a', fontSize: 25 }}
                                            />
                                        </Button>}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allridevehitype) &&
                                (allridevehitype).map(ridevehitype => (
                                    <TableRow
                                        hover
                                        key={ridevehitype.Ride_Vehicle_Type_Id}
                                        selected={
                                            selectedRideVehiTypeIds.indexOf(ridevehitype.Ride_Vehicle_Type_Id) !== -1
                                        }
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={
                                                    selectedRideVehiTypeIds.indexOf(ridevehitype.Ride_Vehicle_Type_Id) !== -1
                                                }
                                                onChange={event =>
                                                    handleSelectOne(event, ridevehitype.Ride_Vehicle_Type_Id)
                                                }
                                                value="true"
                                            />
                                        </TableCell>
                                        <TableCell >{ridevehitype.Ride_Vehicle_Type_Id}</TableCell>
                                        <TableCell >{ridevehitype.Vehicle_Type}</TableCell>
                                        <TableCell >{ridevehitype.Vehicle_Comfort_Type}</TableCell>
                                        <TableCell >{ridevehitype.IGST_Percentage}</TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.Ride_Vehicle_Types_Write?.Value === "Ride_Vehicle_Types_Write" && RolePermissions?.Ride_Vehicle_Types_Write?.Is_Write === 1) ?
                                            <TableCell>
                                                <Tooltip title="Vehicle Type Details">
                                                    <Button
                                                        onClick={visibleridevehitypeHandler(ridevehitype)}
                                                        color="primary" >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                                <Button
                                                    onClick={editridevehitypeHandler(ridevehitype)}>
                                                    <CreateIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                            </TableCell>
                                            : null}
                                        {RolePermissions?.Ride_Vehicle_Types_Read?.Value === "Ride_Vehicle_Types_Read" && RolePermissions?.Ride_Vehicle_Types_Read?.Is_Read === 1 &&
                                            <TableCell>
                                                <Tooltip title="Vehicle Type Details">
                                                    <Button
                                                        onClick={visibleridevehitypeHandler(ridevehitype)}
                                                        color="primary" >
                                                        <VisibilityIcon style={{ color: '#9EA0A5' }} />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allridevehitype) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <Pagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
            <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    allridevehitype: PropTypes.array.isRequired
};

export default Results;