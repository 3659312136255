import config from './config';
import { toast } from 'react-toastify';
import { paginationvarientServices } from './node-actions/nodepagination';
import { boomiAdminApi, nodeServiceApi } from './commonAxios';

import { urlGenarator } from './commonAxios';
import Axios from 'axios';

export const SERVICE_VARIENT_TYPE_CREATE_REQUEST =
  'SERVICE_VARIENT_TYPE_CREATE_REQUEST';
export const SERVICE_VARIENT_TYPE_CREATE_SUCCESS =
  'SERVICE_VARIENT_TYPE_CREATE_SUCCESS';
export const SERVICE_VARIENT_TYPE_CREATE_FAIL =
  'SERVICE_VARIENT_TYPE_CREATE_FAIL';
export const SERVICE_VARIENT_TYPE_UPDATE_REQUEST =
  'SERVICE_VARIENT_TYPE_UPDATE_REQUEST';
export const SERVICE_VARIENT_TYPE_UPDATE_SUCCESS =
  'SERVICE_VARIENT_TYPE_UPDATE_SUCCESS';
export const SERVICE_VARIENT_TYPE_UPDATE_FAIL =
  'SERVICE_VARIENT_TYPE_UPDATE_FAIL';
export const SERVICE_CATEGORY_TYPE_CREATE_REQUEST =
  'SERVICE_CATEGORYTYPE_CREATE_REQUEST';
export const SERVICE_CATEGORY_TYPE_CREATE_SUCCESS =
  'SERVICE_CATEGORY_TYPE_CREATE_SUCCESS';
export const SERVICE_CATEGORY_TYPE_CREATE_FAIL =
  'SERVICE_CATEGORY_TYPE_CREATE_FAIL';
export const VARIENT_SERVICES_GET_REQUEST = 'VARIENT_SERVICES_GET_REQUEST';
export const VARIENT_SERVICES_GET_SUCCESS = 'VARIENT_SERVICES_GET_SUCCESSFULL';
export const VARIENT_SERVICES_GET_FAILED = 'VARIENT_SERVICES_GET_FAILED';
/*=============================================================
                  Add Store type Action
==============================================================*/
export const AddVarienttypeAction = (formData, navigate,state) => async dispatch => {
  dispatch({
    type: SERVICE_VARIENT_TYPE_CREATE_REQUEST
  });
  try { 
    const response = await nodeServiceApi.post(`/admin/service_variant/Create`, formData)
      const successResponse = response.data;
      if (successResponse.Response_Status === 'Success') {
        dispatch({
          type: SERVICE_VARIENT_TYPE_CREATE_SUCCESS
        });
        toast('Variant Type created successfully.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000
        });
        navigate('/app/servicesvarient-management',state = { state });
      } else {
        dispatch({
          type: SERVICE_VARIENT_TYPE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
        toast(
          successResponse.UI_Display_Message +
            'Please change Variant name and try again',
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000
          }
        );
      }
    }
    catch (error)  {
      dispatch({
        type: SERVICE_VARIENT_TYPE_CREATE_FAIL,
        payload: 'Currently server is not working. Please try again later.'
      });
    }
};

/*=============================================================
                Update Store type Action
===============================================================*/
export const EditServiceVarienttypeAction = (
  formData,
  navigate,state
) => async dispatch => {
  dispatch({
    type: SERVICE_VARIENT_TYPE_UPDATE_REQUEST
  });
  nodeServiceApi
    .put(`/admin/service_variant/Update/${formData.Sv_Id}`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === 'Success') {
        dispatch({
          type: SERVICE_VARIENT_TYPE_UPDATE_SUCCESS
        });
        toast('Service Variant Updated Successfully.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000
        });
        navigate('/app/servicesvarient-management',state = { state });
      } else {
        dispatch({
          type: SERVICE_VARIENT_TYPE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
        toast(successResponse.UI_Display_Message+"please change Service Name and Try Again ", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }
    })
    .catch(error => {
      dispatch({
        type: SERVICE_VARIENT_TYPE_UPDATE_FAIL,
        payload: 'Currently server is not working. Please try again later.'
      });
    });
};



/*=============================================================
                Get All Store type Action
 ===============================================================*/
export const getAllServiceCategories = () => async dispatch => {
  try {
    let formData = {
      Status: 'Active',
      SearchFor: '',
      Search: ''
    };
    dispatch({
      type: SERVICE_CATEGORY_TYPE_CREATE_REQUEST
    });
    let { data } = await nodeServiceApi.post(
      `/admin/services/get/?PageNo=1&PageSize=${config.pageSize}&SortBy=Ss_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SERVICE_CATEGORY_TYPE_CREATE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SERVICE_CATEGORY_TYPE_CREATE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SERVICE_CATEGORY_TYPE_CREATE_FAIL,
      payload: err
    });
  }
};

/*=============================================================
              Delete  Delivery Payment Action
 ===============================================================*/
export const deletevarientActions = (
  formData,
  filters,
  pagination,
  callBackPagination
) => async dispatch => {
  try {
    let { data } = await nodeServiceApi.delete(
      `/admin/service_variant/Delete/${formData.id}`
    );
    if (data) {
      toast(' Variant deleted successfully.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000
      });
    }
    dispatch(
      paginationvarientServices(filters, pagination, callBackPagination)
    );
  } catch (err) {}
};

/*=============================================================
            partner logo Image Upload Action
===============================================================*/
export const VarientuploadImagepath = (
  formData,
  callBackUploadImgae
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=ServiceVarientType&fileextension=png&filetype=Images&filename=ServiceVarientType`,
      formData
    );
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) {}
};

export const getAllVarientServices = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: 'FILTER',
      Status: 'Active',
      search_by_filter: '',
      search: ''
    };
    dispatch({
      type: VARIENT_SERVICES_GET_REQUEST
    });
    let { data } = await nodeServiceApi.post(`/admin/service_variant/Get`, {});
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: VARIENT_SERVICES_GET_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: VARIENT_SERVICES_GET_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: VARIENT_SERVICES_GET_FAILED,
      payload: err
    });
  }
};
