import config from './config';
import { toast } from 'react-toastify';
import { boomiAdminApi, nodeGetApi } from './commonAxios';

export const USER_RIDE_REQUEST = "USER_RIDE_REQUEST";
export const USER_RIDE_SUCCESS = "USER_RIDE_SUCCESS";
export const USER_RIDE_FAIL = "USER_RIDE_FAIL";

export const USER_RIDE_CREATE_REQUEST = "USER_RIDE_CREATE_REQUEST";
export const USER_RIDE_CREATE_SUCCESS = "USER_RIDE_CREATE_SUCCESS";
export const USER_RIDE_CREATE_FAIL = "USER_RIDE_CREATE_FAIL";

export const USER_RIDE_UPDATE_REQUEST = "USER_RIDE_UPDATE_REQUEST";
export const USER_RIDE_UPDATE_SUCCESS = "USER_RIDE_UPDATE_SUCCESS";
export const USER_RIDE_UPDATE_FAIL = "USER_RIDE_UPDATE_FAIL";

export const USER_RIDE_EMAILS_REQUEST = "USER_RIDE_EMAILS_REQUEST";
export const USER_RIDE_EMAILS_SUCCESS = "USER_RIDE_EMAILS_SUCCESS";
export const USER_RIDE_EMAILS_FAIL = "USER_RIDE_EMAILS_FAIL";

export const USER_RIDE_VEHICLE_COMFORT_REQUEST = "USER_RIDE_VEHICLE_COMFORT_REQUEST";
export const USER_RIDE_VEHICLE_COMFORT_SUCCESS = "USER_RIDE_VEHICLE_COMFORT_SUCCESS";
export const USER_RIDE_VEHICLE_COMFORT_FAIL = "USER_RIDE_VEHICLE_COMFORT_FAIL";

export const USER_RIDE_USERPAY_REQUEST = "USER_RIDE_USERPAY_REQUEST";
export const USER_RIDE_USERPAY_SUCCESS = "USER_RIDE_USERPAY_SUCCESS";
export const USER_RIDE_USERPAY_FAIL = "USER_RIDE_USERPAY_FAIL";

export const USER_RIDE_DELIPAY_REQUEST = "USER_RIDE_DELIPAY_REQUEST";
export const USER_RIDE_DELIPAY_SUCCESS = "USER_RIDE_DELIPAY_SUCCESS";
export const USER_RIDE_DELIPAY_FAIL = "USER_RIDE_DELIPAY_FAIL";

//create User Ride Action
export const createUserRideAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: USER_RIDE_CREATE_REQUEST
  });
  boomiAdminApi.post(`/Super_Admin_User_Rides/Super_Admin_User_Rides`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USER_RIDE_CREATE_SUCCESS
        });
        toast('User Ride created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/user-rides');
      } else {
        dispatch({
          type: USER_RIDE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USER_RIDE_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

//update User Ride Action
export const updateUserRideAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: USER_RIDE_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Super_Admin_User_Rides/User_Rides_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USER_RIDE_UPDATE_SUCCESS
        });
        toast('User Ride updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/user-rides');
      } else {

        dispatch({
          type: USER_RIDE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USER_RIDE_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

//get user mails
export const getRideAllUserAction = (emailid) => async dispatch => {
  try {
    let formData = {
      Email_Id: emailid,
      Records_Filter: "FILTER"
    };
    dispatch({
      type: USER_RIDE_EMAILS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Super_Admin/User_Get_dropdown_search?PageNo=1&PageSize=${config.pageSize}&SortBy=Email_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: USER_RIDE_EMAILS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: USER_RIDE_EMAILS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: USER_RIDE_EMAILS_FAIL,
      payload: err
    });
  }
};

//get vehicle comfort types
export const getRideVehicleComfortTypes = (formData) => async dispatch => {
  try {
    dispatch({
      type: USER_RIDE_VEHICLE_COMFORT_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Rides_Vehicle_Types/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Ride_Vehicle_Type_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: USER_RIDE_VEHICLE_COMFORT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: USER_RIDE_VEHICLE_COMFORT_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: USER_RIDE_VEHICLE_COMFORT_FAIL,
      payload: err
    });
  }
};

//Get User Ride Action
export const getUserRideAction = (formData, callBackUserRideData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`/Super_Admin_User_Rides/User_Rides_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=USER_RIDE_ID&SortOrder=ASC`, formData);
    if (data) {
      callBackUserRideData(data)
    }
  } catch (err) {
  }
};

//UserPay User Ride Action
export const userRideUserPayAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: USER_RIDE_USERPAY_REQUEST
  });
  boomiAdminApi.put(`/Super_Admin_User_Service_Request/Payment_To_User_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USER_RIDE_USERPAY_SUCCESS
        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/completed-user-rides');
      } else {
        dispatch({
          type: USER_RIDE_USERPAY_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USER_RIDE_USERPAY_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

//DeliPay User Ride Action
export const userRideDeliPayAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: USER_RIDE_DELIPAY_REQUEST
  });
  boomiAdminApi.put(`/Super_Admin_User_Service_Request/Payment_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USER_RIDE_DELIPAY_SUCCESS
        });
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/completed-user-rides');
      } else {
        dispatch({
          type: USER_RIDE_DELIPAY_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USER_RIDE_DELIPAY_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};