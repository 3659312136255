import { toast } from 'react-toastify';
import { paginationUserFinancialTransGet } from './node-actions/nodepagination';
import { nodeCudApi } from './commonAxios';

export const USER_FINANCE_TRANSACTION_UPDATE_REQUEST = "USER_FINANCE_TRANSACTION_UPDATE_REQUEST";
export const USER_FINANCE_TRANSACTION_UPDATE_SUCCESS = "USER_FINANCE_TRANSACTION_UPDATE_SUCCESS";
export const USER_FINANCE_TRANSACTION_UPDATE_FAIL = "USER_FINANCE_TRANSACTION_UPDATE_FAIL";

//Update User Finance Transaction Action
export const updateUserFinanceTransactionAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: USER_FINANCE_TRANSACTION_UPDATE_REQUEST
    });
    nodeCudApi.post(`/Payments/User_Financial_Transaction_Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: USER_FINANCE_TRANSACTION_UPDATE_SUCCESS
                });
                toast('User Financial Transaction updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/user-financial-transactions');
            } else {
                dispatch({
                    type: USER_FINANCE_TRANSACTION_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: USER_FINANCE_TRANSACTION_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//delete User Finance Transaction Action
export const deleteUserFinanceTransactionAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
    try {
        let { data } = await nodeCudApi.post(`/Payments/User_Financial_Transaction_Delete`, formData);
        if (data) {
            toast('User Financial Transaction deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            dispatch(paginationUserFinancialTransGet(filters, pagination, callBackPagination))
        }
    } catch (err) {
    }
};