import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Checkbox,
    FormControlLabel,
    Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";
import { createMPProductComboAction, getAllMeepaisaProducts } from 'src/store/actions/meepaisaproductcomboAction';

const AddMPProductCombo = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;

    useEffect(() => {
        dispatch(getAllMeepaisaProducts());
    }, []);

    const isSubmited = useSelector(state => state.meepaisaproductcombo.productcomboCreate.isLoading);
    const errorMessage = useSelector(state => state.meepaisaproductcombo.productcomboCreate.error);
    const allProducts = useSelector(state => state.meepaisaproductcombo.meepaisaProducts.all);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    const [product, setProduct] = useState([]);
    const subproducts = product.map((option) => ({
        Sub_Product_Id: option.product_id
    }))

    const allProductsFilter = allProducts.map((option) => ({
        product_id: option.Product_Id,
        product_name: option.Product_Name
    }))

    return (
        <Formik
            initialValues={{
                Base_Product_Id: state?.Product_Id,
                Is_Active: false,
                Created_By: loginEmail,
            }}
            onSubmit={(values) => {
                let submitTest = true;
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    if (values.Is_Active) {
                        formValues.Is_Active = 1;
                    } else {
                        formValues.Is_Active = 0;
                    }
                    const formData = {
                        Base_Product_Id: values.Base_Product_Id,
                        Is_Active: formValues.Is_Active,
                        Created_By: loginEmail,
                        Sub_Products: subproducts
                    };

                    let formValues1 = JSON.stringify(formData);
                    dispatch(createMPProductComboAction(formValues1, navigate, state));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue
            }) => (
                <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item md={12} xs={12}>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                gutterBottom
                                                style={{ color: '#66788A' }}
                                            >
                                                Base Product Name: {state.Product_Name ? state.Product_Name : ""}
                                            </Typography>
                                        </Grid>

                                        {/* Is_Active */}
                                        <Grid item md={12} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.Is_Active}
                                                        onChange={handleChange}
                                                        name="Is_Active"
                                                        color="primary"
                                                    />
                                                }
                                                label="Is Active"
                                            />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <Autocomplete
                                                multiple
                                                id="checkboxes-tags-demo"
                                                options={allProductsFilter}
                                                disableCloseOnSelect
                                                groupBy={(option) => option.firstLetter}
                                                variant="outlined"
                                                value={product}
                                                getOptionLabel={(option) => option.product_name}
                                                getOptionSelected={(option, value) => option.product_id === value.product_id}
                                                onChange={(e, value) => {
                                                    setFieldValue("Sub_Product_Id", value ? value : "");
                                                    setProduct(value);
                                                }}
                                                renderOption={(props, option) => (
                                                    <div {...props}>
                                                        <Checkbox
                                                            checked={option.selected}
                                                        />
                                                        {props.product_name}
                                                    </div>
                                                )}
                                                renderInput={(params) => <TextField {...params}
                                                    error={Boolean(touched.Sub_Products && errors.Sub_Products)}
                                                    helperText={touched.Sub_Products && errors.Sub_Products}
                                                    onBlur={handleBlur}
                                                    label="Sub Products"
                                                    name="Sub_Product_Id"
                                                    variant="outlined"
                                                    onChange={() => {
                                                        dispatch(getAllMeepaisaProducts(params.inputProps.value))
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill,
                                                        startAdornment: (
                                                            <React.Fragment>
                                                                <SearchIcon color="inherit" size={20}></SearchIcon>
                                                                {params.InputProps.startAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />}
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>

                            </Grid>
                        </CardContent>

                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>

                        <Box display="flex" pl={2} pt={1} pb={2}>
                            {isSubmited ?
                                <CircularProgress />
                                :
                                <Button
                                    disabled={isSubmited}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    <span style={{ color: 'white' }}>Save details</span>
                                </Button>
                            }
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

AddMPProductCombo.propTypes = {
    className: PropTypes.string
};

export default AddMPProductCombo;