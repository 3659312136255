import { toast } from 'react-toastify';
import { paginationUserpaymentsandsettlements } from './node-actions/nodepagination';
import { nodeCudApi } from './commonAxios';

export const USER_PAYMENT_UPDATE_REQUEST = "USER_PAYMENT_UPDATE_REQUEST";
export const USER_PAYMENT_UPDATE_SUCCESS = "USER_PAYMENT_UPDATE_SUCCESS";
export const USER_PAYMENT_UPDATE_FAIL = "USER_PAYMENT_UPDATE_FAIL";

export const updateUserPaymentAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: USER_PAYMENT_UPDATE_REQUEST
  });
  nodeCudApi.post(`/User/Payment_Settlement_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: USER_PAYMENT_UPDATE_SUCCESS
        });
        toast('User Payment updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/user-payment-settlements');
      } else {
        dispatch({
          type: USER_PAYMENT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USER_PAYMENT_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
               Delete User Payment Action
===============================================================*/
export const deleteUserPaymentAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/User/Payment_Settlement_Delete`, formData);
    if (data) {
      toast(' User Payment deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationUserpaymentsandsettlements(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};